<template>
    <div>
        <HtFormMultiSelect
            :id="`${companyUserRequirement.id}-multiple`"
            :value="getSelectedResponse"
            class="list-answer-select"
            :is-single="true"
            :placeholder="translate('Select answers')"
            :options="getOptionsResponse"
            :name="`${companyUserRequirement.id}-multiple`"
            :cypress="`${companyUserRequirement.id}-multiple`"
            @input="onSelectResponse"
        />
        <div>
            <!-- Nest the error msg in a div, otherwise there might be a "duplicate kley detected" error -->
            <div
                v-for="(errorMessage, errorIndex) in errors.collect(companyUserRequirement.name)"
                :key="errorIndex"
                class="error-message"
            >
                <t>{{ errorMessage }}</t>
            </div>
        </div>
    </div>
</template>

<script>
import UserRequirementEditMixin from './UserRequirementEditMixin';

export default {
    name: 'UserRequirementSingleSelectEdit',

    mixins: [UserRequirementEditMixin],

    inject: ['$validator'],

    props: {
        index: {
            type: Number,
            default: 0,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            answer: null,
        };
    },

    computed: {
        getSelectedResponse() {
            if (this.companyUserRequirement.value) {
                return {
                    id: this.companyUserRequirement.value.key,
                    name: this.companyUserRequirement.value.value,
                };
            }

            return null;
        },

        getOptionsResponse() {
            return this.companyUserRequirement.question_multiple_value.map((value) => ({
                id: value.key,
                name: value.value,
            }));
        },

        getValue() {
            return (this.companyUserRequirement.value !== null) ? this.companyUserRequirement.value.value : null;
        },
    },

    created() {
        this.companyUserRequirement.question_multiple_value = JSON.parse(this.companyUserRequirement.question_multiple_value);
        this.companyUserRequirement.value = JSON.parse(this.companyUserRequirement.value);
        this.answer = this.companyUserRequirement.value;
    },

    methods: {
        onSelectResponse(value) {
            if (this.isDisabled) {
                return;
            }

            if (!value) {
                this.companyUserRequirement.value = null;
                this.$emit('onChangeValue', this.companyUserRequirement.id, false);
            } else {
                this.companyUserRequirement.value = {
                    key: value.id,
                    value: value.name,
                };
                this.$emit('onChangeValue', this.companyUserRequirement.id, true);
            }

            this.updateRequirement();
        },
    },
};
</script>
<style lang='scss' src='./UserRequirementMultipleSelectEdit.scss' scoped />
