<template>
    <div v-if="companyPurge.isLoaded()">
        <div class="purge-cards">
            <PurgeCard
                :description="descriptionUser"
                :switch-label="translate('Automatic deletion of a profile')"
                :rgpd-date-options="optionsUser"
                :input-number-label="translate('Delete the account after')"
                :nb-months.sync="companyPurge.nb_months_users"
                :rgpd-date.sync="companyPurge.rgpd_date_user"
                @toggle="onToggleUser"
            />
            <PurgeCard
                :description="translate('All requirements filled on the platform will be deleted')"
                :switch-label="translate('Automatic deletion of requirements')"
                :rgpd-date-options="optionsRequirement"
                :input-number-label="translate('Delete the requirement')"
                :nb-months.sync="companyPurge.nb_months_requirements"
                :rgpd-date.sync="companyPurge.rgpd_date_requirement"
                @toggle="onToggleRequirement"
            />
            <PurgeCard
                :description="translate('All programs filled on the platform will be closed')"
                :switch-label="translate('Automatic program closing')"
                :rgpd-date-options="optionsProgram"
                :input-number-label="translate('Close the program')"
                :nb-months.sync="companyPurge.nb_months_programs"
                :rgpd-date.sync="companyPurge.rgpd_date_program"
                @toggle="onToggleProgram"
            />
        </div>
        <Button
            :state="buttonState"
            class="purge-button"
            @click="onConfirm"
        >
            <t>Confirm</t>
        </Button>
        <Modalable
            ref="modalable"
            class="modal modalable-4"
        >
            <div class="title">
                <t>Implementation of automation</t>
            </div>
            <p>
                <template v-if="companyPurge.nb_months_users">
                    {{ getModalText }}
                </template>
                <br>
                <t
                    v-if="companyPurge.nb_months_requirements"
                    :count="companyPurge.nb_months_requirements"
                >
                    Requirements will be deleted {count} months
                    after they are completed on the platform.
                </t>
                <br>
                <t
                    v-if="companyPurge.nb_months_programs"
                    :count="companyPurge.nb_months_programs"
                >
                    Programs will be closed {count} months after their activation.
                </t>
            </p>
            <div class="button-container">
                <Button
                    :state="buttonState"
                    @click="onValidate"
                >
                    <t>Validate</t>
                </Button>
            </div>
        </Modalable>
    </div>
</template>

<script>
import I18n from '@/modules/i18n/I18n';
import CompanyPurge from '@/models/CompanyPurge';
import PurgeCard from '@/components/resources/security/PurgeCard.vue';

export default {
    name: 'PurgeEdit',
    components: {
        PurgeCard,
    },

    inject: ['modal'],

    data() {
        return {
            companyPurge: new CompanyPurge([
                'id',
                'nb_months_users',
                'nb_months_requirements',
                'nb_months_programs',
                'rgpd_date_user',
                'rgpd_date_requirement',
                'rgpd_date_program',
            ]),
            optionsProgram: [
                {
                    name: I18n.translate('After activation'),
                    id: 'company_user_program.activated_at',
                },
            ],
            optionsRequirement: [
                {
                    name: I18n.translate('After completion'),
                    id: 'company_user_program_task.datetime_validation',
                },
            ],
            optionsUser: [
                {
                    name: I18n.translate('on the platform (contract end date)'),
                    id: 'company_user.end_date',
                },
                {
                    name: I18n.translate('on the platform (account creation date)'),
                    id: 'company_user.created_at',
                },
                {
                    name: I18n.translate('of inactivity (last connection date)'),
                    id: 'company_user.last_active_at',
                },
            ],
        };
    },

    computed: {
        descriptionUser() {
            return I18n.translate('User accounts created from a program will be permanently deleted from HeyTeam after the period selected below.');
        },

        getModalText() {
            const beginning = `${I18n.translate(
                'User accounts created from a program will be permanently deleted from HeyTeam after {count} months',
                { count: this.companyPurge.nb_months_users },
            )}`;

            return this.companyPurge.rgpd_date_user === 'company_user.last_active_at'
                ? `${beginning} ${I18n.translate('of inactivity.')}`
                : `${beginning} ${I18n.translate('on the platform.')}`; // rgpd_date_user === 'company_user.created_at'
        },
        buttonState() {
            return this.companyPurge.isSaving() ? 'loading' : 'idle';
        },
    },

    created() {
        this.companyPurge.get();
    },

    methods: {
        async onConfirm() {
            const isValid = await this.$validator.validateAll();
            if (!isValid) {
                return;
            }

            if (
                this.companyPurge.nb_months_users
                || this.companyPurge.nb_months_requirements
                || this.companyPurge.nb_months_programs
            ) {
                this.$refs.modalable.open();
            } else {
                await this.companyPurge.save();
                this.modal.close();
            }
        },

        async onValidate() {
            await this.companyPurge.save();
            this.$refs.modalable.close();
            this.modal.close();
        },

        /**
         * @param {boolean} isToggled
         */
        onToggleUser(isToggled) {
            if (isToggled) {
                this.companyPurge.nb_months_users = 12;
                this.companyPurge.rgpd_date_user = this.optionsUser[0].id;
            } else {
                this.companyPurge.nb_months_users = null;
                this.companyPurge.rgpd_date_user = null;
            }
        },
        /**
         * @param {boolean} isToggled
         */
        onToggleRequirement(isToggled) {
            if (isToggled) {
                this.companyPurge.nb_months_requirements = 12;
                this.companyPurge.rgpd_date_requirement = this.optionsRequirement[0].id;
            } else {
                this.companyPurge.nb_months_requirements = null;
                this.companyPurge.rgpd_date_requirement = null;
            }
        },
        /**
         * @param {boolean} isToggled
         */
        onToggleProgram(isToggled) {
            if (isToggled) {
                this.companyPurge.nb_months_programs = 12;
                this.companyPurge.rgpd_date_program = this.optionsProgram[0].id;
            } else {
                this.companyPurge.nb_months_programs = null;
                this.companyPurge.rgpd_date_program = null;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
.purge-button {
    display: block;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
}
.title {
    font-family: $lato-bold;
    font-size: 2.2rem;
    margin-bottom: 1rem;
    font-weight: bold;
}
.button-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    .button {
        margin-top: 1rem;
        padding: 1.5rem 4rem;
    }
}
.purge-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
</style>
