<template>
    <form
        v-if="companyUserProgramTask.isLoaded()"
        class="form-2 spaced"
        data-cy="action-edit-form"
    >
        <HtFormInput
            v-model.trim="companyUserProgramTask.title"
            v-validate.disable="required"
            :label="translate('Title')"
            :data-vv-as="translate('action title')"
            name="title"
            cypress="title"
            show-asterisk
        />

        <fieldset class="medium">
            <HtFormEditor
                id="description"
                v-model.trim="companyUserProgramTask.description"
                name="description"
                cypress="description"
                :label="translate('Description')"
            />
        </fieldset>

        <fieldset>
            <InputParticipants
                v-model="companyUserProgramTask.company_user_program_task_participant"
                :company-user-id="companyUserId"
                data-cy="participants"
            />
        </fieldset>

        <HtUserProgramList
            v-model="companyUserProgramTask.company_user_program_id"
            :company-user-id="companyUserId"
            :disabled="true"
        />

        <HtKeyDatesInputOrderForDependency
            v-if="hasDependency"
            :user-program-id="companyUserProgramTask.company_user_program_id"
            :resource-id="companyUserProgramTask.id"
            resource-type="company_document"
            :is-business-day="companyUserProgramTask.is_business_day"
            :end-date="companyUserProgramTask.datetime_end"
            :dependents="companyUserProgramTask.dependents.models"
            :dependencies="companyUserProgramTask.dependencies.models"
            :should-show-availability-date="shouldShowAvailabilityDate"
        />

        <HtKeyDatesInputOrder
            v-else-if="companyUserProgramTask"
            :end-label="translate('To deliver')"
            :user-program-id="companyUserProgramTask.company_user_program_id"
            :resource-id="companyUserProgramTask.id"
            :resource-type="'custom'"
            :is-business-day.sync="companyUserProgramTask.is_business_day"
            :offset-key-date.sync="companyUserProgramTask.company_user_program_key_date_id"
            :offset-availability-specific-date.sync="companyUserProgramTask.datetime_availability"
            :offset-availability-number.sync="companyUserProgramTask.offset_availability_number"
            :offset-availability-unit.sync="companyUserProgramTask.offset_availability_unit"
            :offset-end-specific-date.sync="companyUserProgramTask.datetime_end"
            :offset-end-number.sync="companyUserProgramTask.offset_end_number"
            :offset-end-unit.sync="companyUserProgramTask.offset_end_unit"
            can-select-specific-date
            :dependents="companyUserProgramTask.dependents.models"
            :dependencies="companyUserProgramTask.dependencies.models"
            :disabled="companyUserProgramTask && companyUserProgramTask.dependencies.models.length > 0"
        />

        <div class="modal-actions right">
            <Button
                v-if="companyUserProgramTask.permissions.delete"
                class="negative"
                cypress="action-remove-button"
                @click="onRemove()"
            >
                <t>Remove</t>
            </Button>
            <Button
                :state="buttonState"
                cypress="action-save-button"
                @click="onUpdate()"
            >
                <t>Save</t>
            </Button>
        </div>
    </form>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import HtUserProgramList from '@/components/globals/HtUserProgramList.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import HtKeyDatesInputOrderForDependency
    from '@/components/globals/HtKeyDatesInputOrderForDependency.vue';

export default {
    name: 'UserActionEdit',
    components: {
        HtKeyDatesInputOrderForDependency,
        HtUserProgramList,
        HtKeyDatesInputOrder,
    },
    inject: ['modal'],

    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },

    data() {
        return {
            buttonState: 'idle',
            companyUser: new CompanyUser(
                ['id', 'firstname', 'arrival_date'],
            ).where([['id', '=', this.companyUserId]]),
            hasDependency: false,
            companyUserProgramTask: new CompanyUserProgramTask([
                'id',
                'company_user_program_id',
                'title',
                'company_user_id',
                'status',
                'resource',
                'description',
                'is_business_day',
                'company_user_program_key_date_id',
                'offset_availability_number',
                'offset_availability_unit',
                'offset_end_number',
                'offset_end_unit',
                'datetime_availability',
                'datetime_end',
                'permissions',
            ]).with({
                dependencies: (query) => {
                    query.select([
                        'id',
                        'resource',
                    ]);
                },
                dependents: (query) => {
                    query.select([
                        'id',
                    ]);
                },
                recurrence: (query) => {
                    query.select(
                        [
                            'id',
                            'company_user_program_task_id',
                            'recurrence_frequence_value',
                            'recurrence_frequence_unit',
                            'recurrence_end_date',
                        ],
                    );
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'arrival_date']);
                },
                companyUserProgramTaskParticipant: (query) => {
                    query.select(['company_user_id']).with({
                        companyUser: (query) => {
                            query.select(['id', 'firstname', 'lastname', 'image']);
                        },
                    });
                },
                companyUserProgram: (query) => {
                    query
                        .select(['id'])
                        .with({
                            mainKeyDate: (query) => {
                                query.select(['id', 'starts_at']);
                            },
                            companyProgram: (query) => query.with({
                                keyDate: (query) => query.with({
                                    locales: (query) => query.select(['id', 'name', 'language_key']),
                                }),
                            }),
                        });
                },
            }).where([
                ['id', '=', this.id],
            ]),
        };
    },

    created() {
        this.companyUser.get();
        this.companyUserProgramTask.company_user_id = this.companyUserId;
        this.companyUserProgramTask.get().then((companyUserProgramTask) => {
            this.hasDependency = companyUserProgramTask.dependents.models.length > 0;
            this.modal.setTitle(companyUserProgramTask.$.title);
        });
    },

    methods: {
        onRemove() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this action? Once you click on delete, you will no longer be able to access this action.') }).then((result) => {
                if (result) {
                    this.companyUserProgramTask.delete().then(() => {
                        this.modal.close();
                        this.$emit('onDelete');
                    });
                }
            });
        },
        onUpdate() {
            this.$validator.validateAll().then((result) => {
                if (result === true) {
                    this.companyUserProgramTask.save().then(() => {
                        this.modal.close();
                        this.$emit('onUpdate');
                    });
                }
            });
        },

        setRecurrence(value) {
            this.companyUserProgramTask.recurrence = value;
        },
    },
};
</script>
