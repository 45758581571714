var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('HtFormSwitch',{attrs:{"id":"isBusinessDay","value":_vm.isBusinessDay,"label":_vm.translate('Use working days'),"name":"isBusinessDay","disabled":true}}),_c('div',[_c('div',{staticClass:"d-flex justify-content-between mb-4"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.translate('Availability date'))+" ")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.translate('Date on which the resource will be made available and visible in the program')),expression:"translate('Date on which the resource will be made available and visible in the program')",modifiers:{"right":true}}],staticClass:"ml-3 cursor-help"},[_c('FontAwesomeIcon',{attrs:{"icon":"info-circle"}})],1)]),_c('div',{staticClass:"row input-dates-wrapper"},[_c('HtFormSelect',{staticClass:"col",attrs:{"id":"offsetUnit","value":'day_of',"name":"offsetUnit","options":[{
                    id: 'day_of',
                    name: _vm.translate('Day of'),
                }],"open-direction":'bottom',"data-vv-as":_vm.translate('date'),"disabled":true}}),_c('HtFormSelect',{staticClass:"col",attrs:{"id":"offsetKeyDate","value":'resourceDependency',"name":"offsetKeyDate","options":[{
                    id: 'resourceDependency',
                    name: _vm.translate('After completing a task'),
                }],"open-direction":'bottom',"data-vv-as":_vm.translate('date'),"disabled":true}})],1),_c('HtUserTaskDependency',{attrs:{"dependents":_vm.dependents,"dependencies":_vm.dependencies,"company-user-program-id":_vm.userProgramId,"resource-id":_vm.resourceId,"resource-type":_vm.resourceType,"disabled":true}})],1),(_vm.shouldShowAvailabilityDate)?_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"d-flex justify-content-between mb-4"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.translate('Completion date'))+" ")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.translate('The action created in the program has to be done by this date')),expression:"translate('The action created in the program has to be done by this date')",modifiers:{"right":true}}],staticClass:"ml-3 cursor-help"},[_c('FontAwesomeIcon',{attrs:{"icon":"info-circle"}})],1)]),_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$Utils.moment(_vm.endDate).format( (_vm.shared.session.companyUser.company_language.key === 'fr') ? 'DD/MM/YYYY' : 'MM/DD/YYYY' ))+" ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }