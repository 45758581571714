<template>
    <div class="wrapper">
        <UtilsTemplate
            :title="translate('Administrative file')"
            :subtitle="translate('{countDone} / {total} files', {countDone, total})"
            @click.native="openRequirements"
        >
            <template #header>
                <ProgressTemplate :percent="percent" />
            </template>
            <template #footer>
                <HtButton
                    type="tertiary"
                    size="small"
                    icon="arrow-right"
                >
                    <t v-if="percent=== 100">
                        Send my finalized administrative file
                    </t>
                    <t v-else>
                        Fill administrative file
                    </t>
                </HtButton>
            </template>
        </UtilsTemplate>
        <UserRequirementCategoryListModal ref="modalRequirement" />
    </div>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';
import UserRequirementCategoryListModal from '@/components/pages/onboarding/UserRequirementCategoryListModal/UserRequirementCategoryListModal.vue';
import resourceEventBus from '@/eventBus/resourceEventBus';
import ProgressTemplate from '../templates/ProgressTemplate.vue';
import UtilsTemplate from '../templates/UtilsTemplate.vue';

export default {
    name: 'ChecklistWidget',
    components: {
        UtilsTemplate,
        UserRequirementCategoryListModal,
        HtButton,
        ProgressTemplate,
    },
    computed: {
        countDone() {
            return this.$store.state.dashboard.actions.countDoneRequirementCategories;
        },
        total() {
            return this.$store.state.dashboard.actions.countTotalRequirementCategories;
        },
        percent() {
            return this.$store.getters['dashboard/actions/percentRequirementCategoriesDone'];
        },
    },
    created() {
        resourceEventBus.$on('requirement-category-done', () => {
            this.$store.dispatch('dashboard/actions/forceFetchRequirementCategories');
        });
    },
    methods: {
        openRequirements() {
            this.$refs.modalRequirement.open();
        },
    },
};
</script>
<style lang="scss" scoped>
.wrapper{
    height: 100%;
}
</style>
