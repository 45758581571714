import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyOffice from './CompanyOffice';

export default class CompanyOfficeCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyOffice,
        };
    }
}
