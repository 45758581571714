var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.item)?_c('form',{staticClass:"form-2 spaced",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"modal-box"},[_c('CardList',{attrs:{"options":{
                    perRow: 2,
                }}},_vm._l((_vm.availableFileTypes),function(fileType,index){return _c('Card',{key:index,class:{ selected: _vm.item.type === fileType.type },attrs:{"options":{
                        type: 'long',
                        perRow: 2,
                    }},on:{"click":function($event){return _vm.changeTo(fileType.type)}}},[_c('div',{attrs:{"slot":"image"},slot:"image"},[_c('div',{staticClass:"item-image image-small"},[_c('div',{staticClass:"small-image",style:({'background-image': `url(${fileType.img})`})})])]),_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('t',[_vm._v(_vm._s(fileType.label))])],1)])}),1),(_vm.item.type === 'file')?_c('TranslatableFiles',{attrs:{"files":_vm.item.fileTranslations,"editing-lang":_vm.editingLang,"validation-rules":{
                    translation_default: [
                        _vm.item.fileTranslations,
                        _vm.shared.session.company.company_languages.default.key
                    ]
                }}}):_vm._e(),(_vm.item.type === 'link')?_c('div',[_c('transition',{attrs:{"name":"fade"}},[_c('div',[_c('InputField',{attrs:{"type":"text","label":_vm.translate('Url Youtube or Vimeo'),"name":'url',"placeholder":_vm.translate('Write the document url'),"validate":'required|url',"validate-as":_vm.translate('link')},model:{value:(_vm.item.translations.url[_vm.editingLang]),callback:function ($$v) {_vm.$set(_vm.item.translations.url, _vm.editingLang, $$v)},expression:"item.translations.url[editingLang]"}})],1)])],1):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"modal-actions right"},[_c('Button',{directives:[{name:"show",rawName:"v-show",value:(_vm.canDelete),expression:"canDelete"}],staticClass:"negative",on:{"click":function($event){return _vm.onDelete()}}},[_c('t',[_vm._v("Delete")])],1),(_vm.item.type)?_c('Button',{attrs:{"state":_vm.button_state},on:{"click":function($event){return _vm.onValidate()}}},[_vm._v(" "+_vm._s(_vm.labelButton)+" ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }