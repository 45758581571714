import Model from '@tony.caron/node-model-proxy/Model';
import Company from './Company';
import CompanyUser from './CompanyUser';
import CompanyChatChannel from './CompanyChatChannel';
import CompanyChatChannelUserCollection from './CompanyChatChannelUserCollection';

export default class CompanyChatChannelUser extends Model {
    modelConfig() {
        return {
            collection: CompanyChatChannelUserCollection,
        };
    }

    modelRelations() {
        return {
            company: () => this.belongsTo(Company, 'company_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyChatChannel: () => this.belongsTo(CompanyChatChannel, 'company_chat_channel_id', 'id'),
        };
    }

    modelEvents() {
        return {
            saved: () => {},
            updated: () => {},
        };
    }
}
