<template>
    <div class="user-requirement-file-to-fill-edit">
        <Downloadable
            v-if="canDownload"
            :company-file="UserRequirement.company_file"
            :is-requirement="true"
        />
        <div v-else>
            -
        </div>
    </div>
</template>

<script>

export default {
    name: 'UserRequirementFileToFillRead',
    props: {
        UserRequirement: {
            type: Object,
            required: true,
        },
    },

    computed: {
        canDownload() {
            return this.UserRequirement.company_file
                && this.UserRequirement.company_file.id;
        },
    },
};
</script>

<style lang="scss" src="./UserRequirementFileToFillEdit.scss" scoped />
