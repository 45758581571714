<template>
    <HtModal
        ref="modal"
        class="user-requirement-category-show-archives-modal"
    >
        <template #header>
            <div class="title modal-header">
                <t>Archives</t>
            </div>
        </template>
        <template
            #default
        >
            <UserRequirementCategoryShowArchivesModalSkeleton
                v-if="isLoading"
                :loading="isLoading"
            />
            <div v-else>
                <HtH2 class="mb-4">
                    {{ archivedUserRequirementsData.categoryName }}
                </HtH2>

                <UserRequirementCategoryShowArchivesListItem
                    v-for="(item, index) in archivedUserRequirementsData.items"
                    :key="index"
                    :item="item"
                />
            </div>
        </template>
    </HtModal>
</template>

<script>
import HtH2 from '@/components/design-system/typography/header/HtH2.vue';
import api from '@/store/api';
import UserRequirementCategoryShowArchivesModalSkeleton from './UserRequirementCategoryShowArchivesModalSkeleton.vue';
import UserRequirementCategoryShowArchivesListItem from './UserRequirementCategoryShowArchivesListItem.vue';

export default {
    name: 'UserRequirementCategoryShowArchivesModal',

    components: {
        HtH2,
        UserRequirementCategoryShowArchivesModalSkeleton,
        UserRequirementCategoryShowArchivesListItem,
    },

    data() {
        return {
            userId: null,
            categoryId: null,
            isLoading: true,
            archivedUserRequirementsData: { items: [] },
            currentLanguageKey: this.shared.session.companyUser.company_language.key,
            currentPage: 1,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        open(userId, resourceId = null) {
            this.userId = userId;
            this.categoryId = resourceId;
            this.currentPage = 1;
            this.archivedUserRequirementsData = { items: [] }; // Reset data
            this.isLoading = true;

            this.$refs.modal.open();
            this.loadArchivedUserRequirementsData();
        },

        loadArchivedUserRequirementsData() {
            api.user.requirementCategories.getArchived(this.userId, this.categoryId, { params: { page: this.currentPage } }).then((res) => {
                const newData = this.extractRequirementsData(res.data.data, this.currentLanguageKey);
                this.archivedUserRequirementsData.categoryName = newData.categoryName;
                this.archivedUserRequirementsData.items = this.archivedUserRequirementsData.items.concat(newData.items);
                this.currentPage++;
            }).catch(() => {
                this.$Notifier('App').showError(
                    this.$I18n.translate('An error occurred when trying to load the archived user requirements!'),
                );
            }).finally(() => {
                this.isLoading = false;
            });
        },

        handleScroll() {
            const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

            if (bottomOfWindow && !this.isLoading) {
                this.loadArchivedUserRequirementsData();
            }
        },
        extractRequirementsData(userRequirementsData) {
            return {
                categoryName: userRequirementsData[0].name,
                items: userRequirementsData.map((item) => {
                    const user_program = item.tasks[0]?.user_program;
                    const { program } = user_program;
                    const programName = program?.locales.find((locale) => locale.language_key === this.currentLanguageKey)?.name || program.name;
                    const createdAtFormatted = user_program?.created_at ? this.formatDate(user_program.created_at) : null;

                    const userRequirementsGroup = item.company_user_requirement_group.map((group) => ({
                        group: group.id,
                        userRequirements: group.company_user_requirement.map((requirement) => ({
                            name: requirement.name,
                            value: requirement.value,
                            company_file: requirement.company_file,
                            company_user_id: requirement.company_user_id,
                            type: requirement.type,
                            is_mandatory: requirement.is_mandatory,
                        })),
                    }));

                    return {
                        programName: `${programName} ${createdAtFormatted}`,
                        userRequirementsGroup,
                    };
                }),
            };
        },
        formatDate(date) {
            const formatByLocale = {
                en: 'YY.MM.DD',
                fr: 'DD.MM.YY',
            };

            const format = formatByLocale[this.currentLanguageKey] || 'YY.MM.DD';

            return this.$moment(date).format(format);
        },
    },
};
</script>

<style lang="scss" src="./UserRequirementCategoryShowArchivesModal.scss" scoped />
