import CompanySurveyCollection from '@/models/CompanySurveyCollection';
import CompanyResourceProgramCollection from '@/models/CompanyResourceProgramCollection';
import CompanySharedDocumentCollection from '@/models/CompanySharedDocumentCollection';
import CompanyTrainingCollection from '@/models/CompanyTrainingCollection';
import CompanyQuizCollection from '@/models/CompanyQuizCollection';
import CompanyEmailCustomTemplateCollection from '@/models/CompanyEmailCustomTemplateCollection';
import CompanyNudgeCollection from '@/models/CompanyNudgeCollection';
import CompanySoftwareCollection from '@/models/CompanySoftwareCollection';
import CompanyEquipmentCollection from '@/models/CompanyEquipmentCollection';
import CompanyPlanningEventTemplateCollection from '@/models/CompanyPlanningEventTemplateCollection';
import CompanyProgramTaskCollection from '@/models/CompanyProgramTaskCollection';

export default {
    props: {
        companyProgramId: {
            type: Number,
            required: true,
        },
    },

    computed: {
        title() {
            if (this.resourceCollection.models.length === 0) {
                return this.translate(`No ${this.resourceTitleSingular}`);
            }

            return this.translate(`{count} ${this.resourceTitleSingular} | {count} ${this.resourceTitlePlural}`, {
                count: this.resourceCollection.models.length,
            });
        },

        isLoaded() {
            return this.resourceCollection.isLoaded();
        },
    },

    methods: {
        fetchData() {
            this.createCollection();
            this.resourceCollection.get();
        },

        onCreate(row) {
            const item = row ? row.item.active : null;

            this.$emit('on-open-add', item, this.resourceCollection);
        },

        setSearchValue(val) {
            this.search = val;
        },

        /**
         * Add function to main collection
         */
        AddCollectionWhere() { },

        AddCollectionWhereHas() { },

        AddCollectionOrderBy() { },

        createCollection() {
            const collectionClasses = {
                CompanyResourceProgramCollection,
                CompanySurveyCollection,
                CompanySharedDocumentCollection,
                CompanyTrainingCollection,
                CompanyQuizCollection,
                CompanyEmailCustomTemplateCollection,
                CompanyProgramTaskCollection,
                CompanyNudgeCollection,
                CompanySoftwareCollection,
                CompanyEquipmentCollection,
                CompanyPlanningEventTemplateCollection,
            };
            if (this.collectionName in collectionClasses) {
                this.resourceCollection = new collectionClasses[this.collectionName](this.itemFields).with(this.itemRelations);

                this.AddCollectionWhere();
                this.AddCollectionWhereHas();
                this.AddCollectionOrderBy();
            } else {
                throw 'wrong collection name';
            }
        },
    },

    created() {
        this.fetchData();
    },

    data() {
        return {
            resourceTitleSingular: '',
            resourceTitlePlural: '',
            resourceCollection: null,
            search: null,
        };
    },
};
