<template>
    <HtFormGroup
        class="ht-form-multiselect"
        :class="{multiple: !isSingle}"
        :label="label"
        :tooltip="tooltip"
        :error="error"
        :is-required="isRequired"
        :show-asterisk="showAsterisk"
        :has-delete-icon="isDeletable"
        :description="description"
        @onDelete="$emit('onDeleteParent')"
    >
        <multiselect
            ref="testMultiselect"
            :value="currentValue"
            :placeholder="placeholder"
            :disabled="disabled"
            :label="labelOptions"
            :allow-empty="allowEmpty"
            :track-by="trackBy"
            :options="processedOptions"
            :internal-search="false"
            :close-on-select="isSingle === true"
            :multiple="isSingle === false"
            :searchable="isSearchable"
            :show-labels="showLabels"
            :open-direction="openDirection"
            :max-height="maxHeight"
            :data-cy="cypress"
            @search-change="onSearchChange"
            @input="onUpdateValue"
            @remove="onRemoveOption"
            @select="onSelectOption"
            @open="open"
            @close="close"
        >
            <div
                slot="caret"
                class="multiselect-caret"
                @mousedown.prevent.stop="toggle()"
            >
                <FontAwesomeIcon
                    size="1x"
                    :icon="getIconCaret"
                    class="icon"
                />
            </div>
            <template slot="noResult">
                <slot name="noResult">
                    <t>No result found.</t>
                </slot>
            </template>
            <template slot="noOptions">
                <slot name="noOptions">
                    <t>List is empty.</t>
                </slot>
            </template>
            <template
                slot="singleLabel"
                slot-scope="{ option }"
            >
                <slot
                    name="singleLabel"
                    :option="option"
                />
            </template>
            <template
                slot="option"
                slot-scope="scopedSlot"
            >
                <slot
                    name="option"
                    :option="scopedSlot.option"
                />
            </template>
            <template
                slot="selection"
                slot-scope="{ values, isOpen }"
            >
                <slot
                    name="selection"
                    :values="values"
                    :is-open="isOpen"
                />
            </template>
            <template
                slot="tag"
                slot-scope="{ option }"
            >
                <slot
                    v-if="option.is_deletable !== undefined ? option.is_deletable : true"
                    name="tag"
                    :option="option"
                />
                <slot
                    v-else
                    name="tag"
                    :option="option"
                >
                    <span class="multiselect__tag unremovable"><span>{{ option[labelOptions] }}</span></span>
                </slot>
            </template>
        </multiselect>
        <slot name="append" />
    </HtFormGroup>
</template>

<script>
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import HtFormMixin from './HtFormMixin';

export default {
    name: 'HtFormMultiSelect',
    components: {
        HtFormGroup,
    },
    mixins: [
        HtFormMixin,
    ],

    props: {
        value: {},
        options: {
            type: Array,
            default: () => [],
        },
        // Used for emit searching (Needs to be sync)
        searchValue: {
            type: String,
            default: '',
        },
        isSearchable: {
            type: Boolean,
            default: true,
        },
        isDeletable: {
            type: Boolean,
            default: false,
        },
        // Used to have multiple selection of options
        isSingle: {
            type: Boolean,
            default: false,
        },
        // Used to remove labels that is display to the right of each options
        showLabels: {
            type: Boolean,
            default: false,
        },
        // Used to have no option when unselecte one
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        // Used to compare object when options are Object
        trackBy: {
            type: String,
            default: 'id',
        },
        // Used for showing specific label in object of options
        labelOptions: {
            type: String,
            default: 'name',
        },
        specificKey: {
            type: String,
            default: '',
        },
        description: {
            type: String,
        },
        /**
         * Hauteur maximale du select une fois déployé
         */
        maxHeight: {
            type: Number,
        },
        openDirection: {
            type: String,
            default: 'bottom',
        },
        tooltip: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            currentValue: null,
            isToggled: false,
            searchQuery: '',
        };
    },

    computed: {
        getIconCaret() {
            if (this.isSingle === true && this.currentValue !== null && this.isToggled === false && this.allowEmpty === true) {
                return ['fal', 'times'];
            }

            return (this.isToggled === false)
                ? ['fal', 'chevron-down']
                : ['fal', 'chevron-up'];
        },
        processedOptions() {
            if (this.options?.length && this.options[0] && typeof this.options[0] === 'object') {
                return this.options.filter((option) => this.$Utils.isSubstringWithoutDiacritics(this.searchQuery, this.getOptionLabel(option)));
            }
            return this.options;
        },
    },

    watch: {
        value(val) {
            this.setCurrentValue(val);
        },
    },

    created() {
        this.setCurrentValue(this.value);
    },

    methods: {
        getOptionLabel(option) {
            return option[this.labelOptions] ?? option;
        },
        toggle() {
            if (this.currentValue !== null && this.isSingle === true && this.allowEmpty === true) {
                this.currentValue = null;
                this.$emit('input', null);
            } else if (this.isToggled === true) {
                this.customDeactivate();
            } else {
                this.customActivate();
            }
        },

        customDeactivate() {
            this.$refs.testMultiselect.deactivate();
        },

        customActivate() {
            this.$refs.testMultiselect.activate();
        },

        setCurrentValue(val) {
            if ((val !== null && val !== undefined) && this.specificKey !== '') {
                const index = this.$Utils.findIndexInArrayWithAttr(this.options, this.specificKey, val);
                if (index !== -1) this.currentValue = this.options[index];
            } else this.currentValue = (typeof val !== 'undefined') ? val : null;
        },

        onSearchChange(query) {
            if (typeof this.searchValue !== 'undefined' && this.isSearchable === true) {
                this.$emit('update:searchValue', query);
                this.searchQuery = query;
            }
        },

        onUpdateValue(value) {
            this.currentValue = value;

            if (value && this.specificKey !== '') {
                this.$emit('input', value[this.specificKey]);
            } else {
                this.$emit('input', value);
            }
        },

        onRemoveOption(option, id) {
            this.$emit('onRemove', option, id);
        },

        onSelectOption(option, id) {
            this.$emit('onSelect', option, id);
        },

        open() {
            this.isToggled = true;
            this.$emit('open');
        },
        close() {
            this.isToggled = false;
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" src="./HtFormMultiSelect.scss"></style>
