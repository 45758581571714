import Model from '@tony.caron/node-model-proxy/Model';
import Utils from '../Utils';
import CompanyProgramResourceFilter from './CompanyProgramResourceFilter';
import CompanyUser from './CompanyUser';
import CompanyRole from './CompanyRole';
import CompanyUserNudge from './CompanyUserNudge';
import CompanyUserNudgeCollection from './CompanyUserNudgeCollection';
import CompanyNudgeCollection from './CompanyNudgeCollection';
import CompanyResource from './CompanyResource';

export default class CompanyNudge extends Model {
    status() {
        if (this.resource.programs.models[0].is_enabled) {
            return 'Active';
        }

        return 'Inactive';
    }

    modelConfig() {
        return {
            controller: 'CompanyNudgeController',
            collection: CompanyNudgeCollection,
        };
    }

    modelRelations() {
        return {
            resource: () => this.hasOne(CompanyResource),
            companyNudgeInitiatorRole: () => this.belongsTo(CompanyRole, 'company_nudge_initiator_role_id', 'id'),
            companyUserNudge: () => this.hasMany(CompanyUserNudge, 'company_nudge_id', 'id'),
            companyNudgeInitiatorUser: () => this.belongsTo(CompanyUser, 'company_nudge_initiator_user_id', 'id'),
            companyNudgeCreatorUser: () => this.belongsTo(CompanyUser, 'company_nudge_creator_user_id', 'id'),
            companyProgramResourceFilter: () => this.hasMany(CompanyProgramResourceFilter, 'resource_id', 'id').where(['component_name', '=', 'company_nudge']),
        };
    }

    modelCustomAttributes() {
        return {
            isLocked: CompanyUserNudgeCollection,
            display_created_at: null,
        };
    }

    modelAccessors() {
        return {
            isLocked: () => this.company_user_nudge && this.company_user_nudge.length > 0,
            display_created_at: () => {
                if (this.created_at) {
                    return Utils.moment(this.created_at).format('L');
                }
                return null;
            },
        };
    }
}
