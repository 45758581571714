<template>
    <div class="right-content">
        <span> {{ participantInfo.firstname }} {{ participantInfo.lastname }}</span>
        <span v-if="!isTargetingProgram" />
        <span
            v-else-if="isParticipantTheEnrollee"
        >
            <t>Enrollee</t>
        </span>
        <div
            v-else
            class="wrapper-enrollee"
        >
            <div
                v-user-image="$Utils.getCompanyComponentRoleInitials(
                    'user',
                    1,
                    {
                        firstname: enrolleeInfo.firstname,
                        lastname: enrolleeInfo.lastname,
                        image: enrolleeInfo.image,
                    }
                )"
                class="enrollee-img"
            />
            <span>
                {{ enrolleeInfo.firstname }} {{ enrolleeInfo.lastname }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ParticipantItemRightContent',
    props: {
        enrolleeInfo: {
            type: Object, // id, firstname, lastname, image
            required: true,
        },
        participantInfo: {
            type: Object, // id, firstname, lastname, image
            required: true,
        },
        isTargetingProgram: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        isParticipantTheEnrollee() {
            return this.enrolleeInfo.id === this.participantInfo.id;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.right-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.wrapper-enrollee {
    display:flex;
    align-items: center;
    gap: 10px;
}
.enrollee-img {
    width: 20px;
    height: 20px;
    padding: 1px;
    border-radius: $border-radius-5;
    background: $grey;
}
</style>
