<template>
    <div>
        <h3 class="small">
            <t>Company name</t>
        </h3>
        <HtFormInput
            id="company_name"
            v-validate.disable="'required'"
            :value="value"
            type="text"
            class="full-width"
            :name="'company_name'"
            :max-length="50"
            :placeholder="translate('Type here')"
            :data-vv-as="translate('Company name')"
            @input="onInput"
        />
        <div class="save-button">
            <div class="modal-actions right">
                <Button
                    :state="isSavingCompany"
                    @click="$emit('on-update')"
                >
                    <t>Save</t>
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CompanyName',
    inject: ['$validator'],

    props: {
        value: {
            type: String,
            required: true,
        },

        isSavingCompany: {
            type: String,
            required: true,
        },
    },

    methods: {
        onInput(value) {
            this.$emit('update:value', value);
        },
    },
};
</script>

<style scoped>

.save-button {
    padding: 0.75em 0;
    text-align: center;
    width: 100%;
}
</style>
