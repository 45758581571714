import CardVideoLeftSection from './CardVideoLeftSection';

export default {
    name: 'Card video right',
    image: 'card-video-right.png',
    fullPageBorder: false,
    content: CardVideoLeftSection.content,
    css: `
        ${CardVideoLeftSection.css}

        .card {
            flex-direction: row-reverse;
        }
    `,
};
