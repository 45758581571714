<template>
    <HtReadResources
        v-if="nudge"
        :resource="nudge"
        :resource-type="resourceType"
        :resource-data="resourceData"
        :label-category="'Nudge'"
        :has-sender="true"
        :has-recipient="true"
    />
</template>

<script>

import HtReadResources from '@/components/globals/modals/HtReadResources/HtReadResources.vue';

export default {
    name: 'NudgeRead',
    components: { HtReadResources },
    props: {
        resourceType: {
            type: String,
            required: true,
        },
        resourceId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            nudge: null,
            resourceData: {
                type: null,
                data: null,
            },
        };
    },

    async created() {
        const request = await this.$http.get(`/configuration/nudges/${this.resourceId}`);
        this.nudge = request.data.data.resource;

        this.nudge.company_nudge_initiator_role = request.data.data.company_nudge_initiator_role;
    },
};
</script>
