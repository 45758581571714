<template>
    <HtLayoutModal @close="$emit('close')">
        <template #header>
            <p class="font-weight-bold">
                <t>Add a new page</t>
            </p>
        </template>
        <template #main>
            <div class="enterprise-templates-list">
                <div
                    v-for="(value, key) in templates"
                    @click="onSelect(key)"
                    class="enterprise-templates-item"
                >
                    <img
                        :src="`/static/images/settings/enterprisepages/templates/${value.image}`"
                    >
                    <t>{{ value.name }}</t>
                </div>
            </div>
        </template>
    </HtLayoutModal>
</template>

<script>
import HtLayoutModal from '@/components/globals/modals/layouts/HtLayoutModal.vue';
import sectionTemplates from '@/components/settings/EnterprisePage/Templates/templates';

export default {
    name: 'EnterprisePageTemplateModal',

    components: {
        HtLayoutModal,
    },

    data() {
        return {
            templates: sectionTemplates,
        };
    },

    methods: {
        onSelect(key) {
            this.$emit('close', key);
        },
    },
};
</script>

<style lang="scss" scoped>
.enterprise-templates-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;

    .enterprise-templates-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        max-width: 260px;
        cursor: pointer;

        & img {
            transition: transform .2s;
        }

        &:hover {
            img {
                transform: scale(0.97);
            }
        }
    }
}
</style>
