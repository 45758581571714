<template>
    <div
        v-if="isLoaded"
        class="filters"
    >
        <HtButton
            type="secondary"
            :title="translate('Delete all filters')"
            class="button-delete"
            @click.native="clearAllFilters"
        >
            <FontAwesomeIcon :icon="['fas', 'trash']" />
        </HtButton>
        <slot
            name="beforeFilters"
            :layout-modal="layoutModal"
        />
        <HtFormSelector
            v-if="entityFiltersEnabled"
            v-for="entity in entities"
            :id="`entity-${entity.id}`"
            :key="entity.id"
            :show-optional="false"
            :label="layoutModal ? getDefaultSelectorLabel(entity) : undefined"
            :placeholder="layoutModal ? undefined : getDefaultSelectorLabel(entity)"
            :value="selectedValuesByEntity(entity)"
            :options="valuesByEntityId[entity.id]"
            :name="`entity-${entity.id}`"
            track-by="company_entity_value_id"
            :is-single="isSingle"
            open-direction="bottom"
            @input="values => updateEntitiesFilter(entity.id, values ? values : [])"
        />
        <template v-if="shouldShowLibraryMode">
            <HtFormSelector
                id="library_mode"
                :show-optional="false"
                :label="layoutModal ? translate('Personal library') : undefined"
                :placeholder="layoutModal ? undefined : translate('Personal library')"
                :value="selectedLibraryMode"
                name="library_mode"
                specific-key="id"
                :allow-empty="false"
                :options="valuesLibraryMode"
                :is-single="true"
                @input="values => updateLibraryModeFilter(values)"
            />
        </template>

        <HtFormSelector
            v-if="store"
            id="programStatus"
            :placeholder="translate('Status')"
            :options="programStatuses"
            :value="selectedProgramStatus"
            track-by="slug"
            name="userStatus"
            @input="updateUserStatusFilter"
        />

        <slot
            name="afterFilters"
            :layout-modal="layoutModal"
        />
    </div>
</template>

<script>
import groupBy from 'lodash.groupby';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import {
    LIBRARY_ALL_RESOURCES,
    LIBRARY_ONLY_MY_RESOURCES,
    LIBRARY_ONLY_PUBLIC_RESOURCES,
} from '@/store/modules/resources.store';
import HtButton from '../HtButton.vue';

export default {
    name: 'HtEntitiesFilters',
    components: {
        HtFormSelector,
        HtButton,
    },
    props: {
        shouldShowLibraryMode: {
            type: Boolean,
            default: false,
        },
        store: {
            type: String,
            required: false,
            default: '',
        },
        isSingle: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Array,
            default: null,
        },
        entities: {
            type: Array,
            default: () => ([]),
        },
        layoutModal: {
            type: Boolean,
            default: false,
        },
        entityFiltersEnabled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            showFilters: false,
        };
    },
    computed: {
        programStatuses() {
            return this.$store.getters[`${this.store}/getProgramStatusesList`];
        },
        selectedProgramStatus() {
            return this.$store.getters[`${this.store}/getProgramStatuses`];
        },
        selectedFilters() {
            return this.$store.getters[`${this.store}/getFilters`]?.entities
                || this.value;
        },
        isLoaded() {
            return this.$store.state.entities.entitiesLoaded;
        },
        valuesByEntityId() {
            const valuesByEntityId = this.$store.getters['entities/valuesByEntityId'];

            for (const [key, values] of Object.entries(valuesByEntityId)) {
                valuesByEntityId[key] = values.sort((a, b) => a.name.localeCompare(b.name));
            }

            return valuesByEntityId;
        },
        selectedValuesByEntityId() {
            const entities = {};

            const valuesByEntityId = groupBy(this.selectedFilters, 'company_entity_id');

            this.entities.forEach((entity) => {
                entities[entity.id] = valuesByEntityId[entity.id] || [];
            });

            return entities;
        },
        valuesLibraryMode() {
            return [
                { id: LIBRARY_ALL_RESOURCES, name: this.translate('All resources') },
                { id: LIBRARY_ONLY_MY_RESOURCES, name: this.translate('Only my resources') },
                { id: LIBRARY_ONLY_PUBLIC_RESOURCES, name: this.translate('Only public resources') },
            ];
        },
        selectedLibraryMode() {
            let index = -1;
            if (this.store) {
                index = this.valuesLibraryMode
                    .findIndex((valueLibraryMode) => valueLibraryMode.id === this
                        .$store.state[this.store]?.filters.library_mode);
            }
            return index === -1 ? this.valuesLibraryMode[0].id : this.valuesLibraryMode[index].id;
        },
    },
    created() {
        this.$store.dispatch('entities/fetchEntities');
    },
    methods: {
        getDefaultSelectorLabel(entity) {
            return entity.is_heyteam ? this.translate(entity.name) : entity.name;
        },
        clearAllFilters() {
            if (this.store) {
                this.$store.dispatch(`${this.store}/clearAllFilters`);
            }
            this.$emit('input', []);
            this.$emit('on-update-filters');
            this.$emit('on-clear-filters');
        },
        onClearFilters() {
            this.$emit('input', []);
            this.$emit('on-clear-filters');
        },
        updateLibraryModeFilter(value) {
            if (this.store === 'resources') {
                this.$store.dispatch('resources/setLibraryModeFilter', value);
                this.$emit('on-update-filters');
            }
        },
        updateUserStatusFilter(statuses) {
            this.$store.dispatch(`${this.store}/setStatusesFilter`, statuses.map((status) => status.slug));
        },
        updateEntitiesFilter(entityId, values = []) {
            let valuesToReturn;

            if (!values) {
                valuesToReturn = [];
            } else if (!Array.isArray(values)) {
                valuesToReturn = [values];
            } else {
                valuesToReturn = values;
            }

            if (this.store) {
                this.$store.dispatch(`${this.store}/setEntitiesFilter`, { company_entity_id: entityId, values: valuesToReturn });
            } else {
                const entities = this.value.filter(
                    (entity) => entity.company_entity_id !== entityId,
                );
                this.$emit('input', [...entities, ...valuesToReturn]);
            }

            this.$emit('on-update-filters');
        },
        selectedValuesByEntity(entity) {
            let selectedValues;
            if (this.isSingle) {
                selectedValues = (this.selectedValuesByEntityId[entity.id]
                    ? this.selectedValuesByEntityId[entity.id][0] : null);
            } else {
                selectedValues = this.selectedValuesByEntityId[entity.id];
            }
            return selectedValues;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

::v-deep .ht-form {
    margin-bottom: 0;
}

.filters{
    display: flex;
    gap: 16px;

    > div {
        min-width: 250px;
    }
    .ht-button.secondary.button-delete {
        height: 45px;
        width: 45px;
        color: $semantic-error;
    }
    .ht-form-selector {
        flex-grow: 1;
    }
}

@media (max-width: $tablet) {
    .button-delete{
        display: none;
    }
    .filters{
        flex-direction: column;
    }

}
@media (min-width: $tablet) {

    .filters{
        align-items: center;
        margin-top: 32px;
        flex-wrap: wrap;
    }
}
::v-deep .ht-form-selector .multiselect .multiselect__tags .multiselect__tags-wrap{
    flex-wrap: nowrap;
    margin-right: 10px;
}
</style>
