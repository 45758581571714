<template>
    <div
        v-if="item && item.isLoaded()"
        id="survey-edit"
    >
        <form class="form-2 spaced">
            <fieldset class="medium">
                <label>
                    <t>Participant:</t>
                </label>
                <div class="card-6 flex-container">
                    <div class="item">
                        <div
                            v-user-image="$Utils.getCompanyComponentRoleInitials('user',1.25,{
                                firstname: item.company_user_participant.firstname,
                                lastname: item.company_user_participant.lastname,
                                image: item.company_user_participant.image,
                            })"
                            class="item-image role-only"
                            :class="'user'"
                        />
                        <div class="align-center">
                            {{ item.company_user_participant.firstname }}
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset class="medium">
                <label>
                    <t>Supervisors:</t>
                </label>
                <div class="card-6 flex-container">
                    <div
                        v-for="(supervisor, index) in hasSupervisor"
                        :key="index"
                        class="item"
                    >
                        <UserBlock :item="supervisor" />

                        <div
                            v-if="supervisor.company_user && supervisor.company_user.firstname"
                            class="align-center"
                        >
                            {{ supervisor.company_user.firstname }}
                        </div>
                    </div>
                </div>

                <input
                    id="name"
                    v-model="item.company_user_survey_supervisor"
                    v-validate.disable="'required'"
                    v-next-field
                    type="hidden"
                    name="survey_supervisors"
                    class="full-width"
                    :data-vv-as="translate('survey supervisors')"
                    :placeholder="translate('Type here')"
                >
            </fieldset>

            <HtUserProgramList
                v-model="item.company_user_program_id"
                :company-user-id="companyUserId"
                :disabled="true"
            />

            <HtKeyDatesInputOrder
                :user-program-id="item.company_user_program_id"
                :resource-id="item.id"
                :resource-type="'company_survey'"
                :is-business-day.sync="userTask.is_business_day"
                :offset-key-date.sync="userTask.company_user_program_key_date_id"
                :offset-availability-specific-date.sync="userTask.datetime_availability"
                :offset-availability-number.sync="userTask.offset_availability_number"
                :offset-availability-unit.sync="userTask.offset_availability_unit"
                :offset-end-specific-date.sync="userTask.datetime_end"
                :offset-end-number.sync="userTask.offset_end_number"
                :offset-end-unit.sync="userTask.offset_end_unit"
                can-select-specific-date
                :dependents="userTask.dependents.models"
                :dependencies="userTask.dependencies.models"
            />

            <RecurrenceUserConfig
                :recurrence="userTask.recurrence"
                @update="setRecurrence"
            />

            <div class="modal-actions right">
                <Button
                    v-if="item.id"
                    class="negative"
                    cypress="survey-delete-button"
                    @click="onDelete()"
                >
                    <t>Remove</t>
                </Button>
                <Button
                    :state="buttonState"
                    cypress="survey-save-button"
                    @click="onValidate()"
                >
                    <t>Save</t>
                </Button>
            </div>
        </form>

        <modalable
            ref="modalableSearchRolePanelSupervisor"
            class="modalable-1 small"
            :options="{
                title: translate('Supervised by')
            }"
        >
            <SearchRolePanel
                ref="searchRolePanelSupervisor"
                :multiple-mode="true"
                :has-roles="false"
                @onValidate="$refs.modalableSearchRolePanelSupervisor.close()"
            />
        </modalable>
    </div>
</template>
<script>
import CompanyUserSurvey from '@/models/CompanyUserSurvey';
import HtUserProgramList from '@/components/globals/HtUserProgramList.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import RecurrenceUserConfig from '@/components/resources/recurrence/RecurrenceUserConfig.vue';

export default {
    name: 'UserSurveyEdit',
    components: {
        HtUserProgramList,
        HtKeyDatesInputOrder,
        RecurrenceUserConfig,
    },

    inject: ['modal'],

    props: {
        companyUserId: { type: [String, Number], required: true },
        id: { type: [String, Number], required: false },
    },

    data() {
        return {
            userTask: null,
            // FOR LAZY LOADING
            companyUserSurvey: new CompanyUserSurvey([
                'id', 'company_survey_id', 'company_user_program_id', 'company_user_id',
            ]).with({
                companySurvey: (query) => {
                    query
                        .select(['id'])
                        .with({
                            resource: (query) => {
                                query.select([
                                    'name',
                                ]);
                            },
                        });
                },
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'resource_id',
                        'resource',
                        'status',
                        'is_business_day',
                        'company_user_program_key_date_id',
                        'offset_availability_number',
                        'offset_availability_unit',
                        'offset_end_number',
                        'offset_end_unit',
                        'datetime_availability',
                        'datetime_end',
                        'company_user_recurrence_id',
                    ]).with({
                        dependencies: (query) => {
                            query.select([
                                'id',
                                'resource',
                            ]);
                        },
                        dependents: (query) => {
                            query.select([
                                'id',
                            ]);
                        },
                        recurrence: (query) => {
                            query.select([
                                'id',
                                'recurrence_frequence_value',
                                'recurrence_frequence_unit',
                            ]);
                        },
                    });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserParticipant: (query) => {
                    query
                        .select(['id', 'firstname', 'lastname', 'gender', 'image', 'company_language_id'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserSurveySupervisor: (query) => {
                    query.select(['id', 'company_survey_id', 'company_user_participant_id', 'company_user_id']).with({
                        companyUser: (query) => {
                            query.select(['id', 'firstname', 'lastname', 'image']);
                        },
                    });
                },
                companyUserProgram: (query) => query.select(['id'])
                    .with({
                        mainKeyDate: (query) => {
                            query.select(['id', 'starts_at']);
                        },
                        companyProgram: (query) => query.select(['id']).with({
                            keyDate: (query) => query.with({
                                locales: (query) => query.select(['id', 'name', 'language_key']),
                            }),
                        }),
                    }),
            }).where([['id', '=', this.id]]),
            item: null,
            buttonState: 'idle',
        };
    },

    computed: {
        hasSupervisor() {
            return this.item.company_user_survey_supervisor.models.filter(
                (supervisor) => supervisor.company_user || supervisor.company_role,
            );
        },
    },

    watch: {
        'item._state.isSaving': function (val) {
            this.buttonState = val ? 'loading' : 'idle';
        },
    },

    created() {
        this.companyUserSurvey.company_user_id = this.companyUserId;
        this.companyUserSurvey.get().then(() => {
            this.item = this.companyUserSurvey;
            this.userTask = this.item.company_user_program_task;
            const title = this.item.name;
            this.modal.setTitle(title);
        });
    },

    methods: {
        openAddSupervisorsModal() {
            this.$refs.searchRolePanelSupervisor.init({
                onValidate: this.addSupervisors,
                selected: this.item.company_user_survey_supervisor.models,
            });
            this.$refs.modalableSearchRolePanelSupervisor.open();
        },

        addSupervisors(supervisors) {
            this.item.company_user_survey_supervisor = supervisors;
        },

        onRemoveSupervisor(supervisor) {
            this.item.company_user_survey_supervisor.models = this.item.company_user_survey_supervisor.models.filter(
                (item) => (
                    item.company_role_id !== supervisor.company_role_id
                    || item.company_user_id !== supervisor.company_user_id
                ),
            );
        },

        async onValidate() {
            await this.$validator.validateAll();

            if (this.errors.any()) return;

            await this.item.save();

            this.modal.close();
            this.$emit('onUpdate');
        },

        onDelete() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this survey? Once you click on delete, you will no longer be able to access this survey.') }).then((result) => {
                if (result) {
                    this.item.delete().then(() => {
                        this.modal.close();
                        this.$emit('onDelete');
                    });
                }
            });
        },
        setRecurrence(value) {
            this.companyUserSurvey.company_user_program_task.recurrence = value;
        },
    },
};
</script>

<style lang="scss" scoped>
.marginTop {
	margin-top: 20px !important;
}
#survey-edit {
	/*.container-inputs {*/
	/*display: flex;*/
	/*flex-direction: column;*/
	/*color:white;*/
	/*}*/
	fieldset {
		margin-bottom: 1.25em;
	}

	.trash {
		width: 35px;
		height: 35px;
		position: absolute;
		top: -15px;
		right: -15px;
		background: #fff;
		box-sizing: border-box;
		padding: 3px;
		border: 1px solid #ebebeb;
		border-radius: 50%;
		text-align: center;
		padding: 8px;
		color: black;

		svg {
			width: 15px !important;
			height: 15px !important;
		}
	}
}

.order_checkbox {
	display: flex;
	justify-content: flex-end;
	align-content: space-between;
	align-items: flex-end;
}

.empty-participant {
	color: red;
	font-size: 20px;
	padding: 20px;
	border: 1px solid red;
	border-radius: 5px;
	margin-bottom: 20px;
	color: red;
	font-size: 20px;
}
</style>
