export default class ErrorBag {
    constructor() {
        this.nb_error = 0;
        this.observerCache = 0;

        this.scopedError = {};
        this.scopedNode = {};
    }

    _formatScopes(mixed) {
        return Array.isArray(mixed) ? mixed : [mixed];
    }

    _getAllScope() {
        return Object.keys(this.scopedNode);
    }

    _scopedNodeEach(_scope, f) {
        let _return = true;
        for (let i = 0; i < _scope.length; i++) {
  			// eslint-disable-next-line guard-for-in
  			for (const n in this.scopedNode[_scope[i]]) {
                _return = f(this.scopedNode[_scope[i]][n]);
                if (_return === false) return;
            }
  		}
    }

    clear(scope) {
        const _scope = scope ? this._formatScopes(scope) : this._getAllScope();

        this._scopedNodeEach(_scope, (node) => {
            node.showError = false;
        });

        this.updateObserverCache();
    }

    showErrors(scope) {
        const _scope = scope ? this._formatScopes(scope) : this._getAllScope();

        this._scopedNodeEach(_scope, (node) => {
            node.showError = true;
        });

        this.updateObserverCache();
    }

    any(scope) {
        this.getObserverCache(); // This call permit the obeserver to refresh

        const _scope = scope ? this._formatScopes(scope) : this._getAllScope();

        let error_nb = 0;

        this._scopedNodeEach(_scope, (node) => {
            error_nb += node.valid ? 0 : 1;
        });

        return error_nb > 0;
    }

    // Return if an error is actualy visible for the user
    anyShowable(scope) {
        this.getObserverCache(); // This call permit the obeserver to refresh

        const _scope = scope ? this._formatScopes(scope) : this._getAllScope();

        let error_nb = 0;

        this._scopedNodeEach(_scope, (node) => {
            error_nb += node.valid || node.showError === false ? 0 : 1;
        });

        return error_nb > 0;
    }

    // Return if all the errors are actualy visible for the user
    allShowable(scope) {
        this.getObserverCache(); // This call permit the obeserver to refresh

        const _scope = scope ? this._formatScopes(scope) : this._getAllScope();

        let error_nb = 0;
        let max = 0;

        this._scopedNodeEach(_scope, (node) => {
            max += 1;
            error_nb += node.showError === true ? 1 : 0;
        });

        return error_nb == max;
    }

    _addNode(node) {
        node.scope.forEach((scope) => {
            if (!this.scopedNode[scope]) this.scopedNode[scope] = {};
            if (!this.scopedError[scope]) this.scopedError[scope] = 0;

            this.scopedNode[scope][node.name] = node;
            this.scopedError[scope]++;
        });

        this.updateObserverCache();
    }

    removeNode(node) {
        node.scope.forEach((scope) => {
            this.scopedError[scope] = node.valid ? --this.scopedError[scope] : this.scopedError[scope];
            delete this.scopedNode[scope][node.name];
        });
    }

    getMessage(name, scope) {
        const node = this._getNode(name, scope);

        return node ? node.message : '';
    }

    getFirstMessage(scope = '__global__') {
        if (this.scopedError[scope] == 0) return '';

        let message = '';

        // Maybe a problem here due to obejct non consistent order
        this._scopedNodeEach(scope, (node) => {
            if (!node.valid) {
                message = node.message;
                return false;
            }
        });

        return message;
    }

    // Try to find the node with it's name
    _getNode(name, scope = ['__global__']) {
        let node = null;

        const scopes = this._formatScopes(scope);

        for (let i = 0; i < scopes.length; i++) {
            const item = scopes[i];

            if (this.scopedNode[item]) {
                if (this.scopedNode[item][name]) {
                    node = this.scopedNode[item][name];
                }
            }
        }

        return node;
    }

    has(name, scope = ['__global__']) {
        this.getObserverCache(); // This call permit the obeserver to refresh

        const scopes = this._formatScopes(scope);

        const node = this._getNode(name, scopes);
        return node ? !node.valid : false;
    }

    hasShowable(name, scope = ['__global__']) {
        this.getObserverCache();
        // if(this.nb_error == 0) return false; // This call permit the obeserver to refresh

        const scopes = this._formatScopes(scope);

        const node = this._getNode(name, scopes);

        return node ? node.showError && !node.valid : false;
    }

    updateObserverCache() {
        this.observerCache++;
    }

    getObserverCache() {
        return this.observerCache;
    }
}
