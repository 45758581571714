var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"list-actions-event-calendar"},[_c('div',{staticClass:"list-actions-event-calendar-header"},[(_vm.displayedPart === 2)?_c('div',{staticClass:"mobile-button mobile-button-previous branding-bg",style:({
                    gridColumnStart: 1,
                    gridColumnEnd: 2,
                    gridRowStart: 1,
                    gridRowEnd: 2
                }),on:{"click":_vm.displayFirstPart}},[_c('FontAwesomeIcon',{attrs:{"icon":"chevron-left"}})],1):_vm._e(),_vm._l((_vm.eventByDay),function(day,index){return (index + 2 + _vm.calendarOffset > 1)?_c('div',{key:index,staticClass:"day-label cellHeader",style:({
                    gridColumnStart: index + 2 + _vm.calendarOffset,
                    gridColumnEnd: index + 3 + _vm.calendarOffset,
                    gridRowStart: 1,
                    gridRowEnd: 2
                })},[_c('span',{staticClass:"true-label"},[_vm._v(_vm._s(day.label))]),(_vm.displayedPart === 1)?_c('span',{staticClass:"mobile-button mobile-button-next branding-bg",on:{"click":_vm.displaySecondPart}},[_c('FontAwesomeIcon',{attrs:{"icon":"chevron-right"}})],1):_vm._e()]):_vm._e()})],2),_c('div',{ref:"calendar",staticClass:"list-actions-event-calendar-days"},[_c('div',{ref:"timeNeedleDot",staticClass:"time-needle-dot"}),_c('div',{ref:"timeNeedleLine",staticClass:"time-needle-line"}),_vm._l((_vm.eventByDay),function(day,index){return _vm._l((24),function(hour){return _c('div',{key:'hour-' + index + '-' + hour,staticClass:"cell",style:({
                        gridColumnStart: index + 2,
                        gridColumnEnd: index + 3,
                        gridRowStart: (hour - 1) * 4 + 1,
                        gridRowEnd: hour * 4 + 1
                    })})})}),_vm._l((24),function(hour){return _c('div',{key:'hour-' + hour,ref:'hour-' + (hour - 1),refInFor:true,staticClass:"hour-label",style:({
                    gridColumnStart: 1,
                    gridColumnEnd: 2,
                    gridRowStart: (hour - 1) * 4 + 1,
                    gridRowEnd: hour * 4 + 1
                })},[_vm._v(" "+_vm._s(hour - 1)+":00 ")])}),_vm._l((_vm.eventByDay),function(day,dayIndex){return _vm._l((day.events),function(event,eventIndex){return (dayIndex + 2 + _vm.calendarOffset > 1)?_c('div',{key:dayIndex + '-' + eventIndex,staticClass:"event branding-bg",class:_vm.getEventSizeClass(event),style:({
                        gridColumnStart: dayIndex + 2 + _vm.calendarOffset,
                        gridColumnEnd: dayIndex + 3 + _vm.calendarOffset,
                        gridRowStart: _vm.getRowFromTime(_vm.getTimeStart(event)),
                        gridRowEnd: _vm.getRowFromTime(_vm.getTimeEnd(event))
                    }),attrs:{"title":event.name},on:{"click":function($event){return _vm.openItemModal(event)}}},[_c('div'),_c('div',{staticClass:"overflow-hidden"},[_c('div',{staticClass:"event-info"},[_c('div',{staticClass:"event-time"},[_vm._v(" "+_vm._s(_vm.getDate(event))+" ")]),_c('div',{staticClass:"event-title"},[_vm._v(" "+_vm._s(event.name)+" ")])]),(
                                event.attendees
                                    .length && _vm.getEventDuration(event) >= 60
                            )?_c('ParticipantList',{staticClass:"event-participants",attrs:{"users":event.attendees,"limit":2}}):_vm._e()],1)]):_vm._e()})})],2)]),_c('UserPlanningEventItem',{ref:"userItem",attrs:{"from":"dashboard"},on:{"onDelete":function($event){return _vm.onDelete()},"onUpdate":function($event){return _vm.onUpdate()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }