<template>
    <div>
        <IconLoading v-if="loading" />
        <div v-else class="enterprise-pages-list">
            <Draggable
                class="dropzone"
                @end="orderChanged"
            >
                <EnterprisePageItem
                    v-for="page in pages"
                    :key="page.id"
                    :page="page"
                    @on-reload="loadPages"
                />
            </Draggable>
        </div>
    </div>
</template>

<script>
import api from '@/store/api';
import Draggable from 'vuedraggable';
import IconLoading from '@/components/icons/IconLoading.vue';
import EnterprisePageItem from '@/components/settings/EnterprisePage/EnterprisePageItem.vue';
import orderBy from 'lodash.orderby';

export default {
    name: 'EnterprisePageList',

    components: {
        Draggable,
        IconLoading,
        EnterprisePageItem,
    },

    data() {
        return {
            loading: true,
            pages: [],
        };
    },

    created() {
        this.loadPages();
    },

    methods: {
        loadPages() {
            this.loading = true;

            api
                .settings
                .enterprisePages
                .pages
                .all()
                .then((res) => {
                    this.pages = res.data.data;

                    this.loading = false;
                });
        },

        orderChanged(event) {
            const { newIndex, oldIndex } = event;
            if (newIndex === oldIndex) {
                return;
            }

            const movedPages = newIndex > oldIndex
                ? this.handleMoveDown(newIndex, oldIndex)
                : this.handleMoveUp(newIndex, oldIndex);

            this.pages = orderBy(movedPages, (page) => page.order);

            this.loading = true;

            api
                .settings
                .enterprisePages
                .pages
                .saveOrders({
                    orders: this.pages.map(({ id, order }) => ({ id, order })),
                })
                .then(() => {
                    this.loading = false;
                });
        },

        handleMoveDown(newIndex, oldIndex) {
            return this.pages.map((page, index) => {
                if (index === oldIndex) {
                    return {
                        ...page,
                        order: newIndex,
                    };
                }

                if (index > oldIndex && index <= newIndex) {
                    return {
                        ...page,
                        order: index - 1,
                    };
                }

                return {
                    ...page,
                    order: index,
                };
            });
        },

        handleMoveUp(newIndex, oldIndex) {
            return this.pages.map((page, index) => {
                if (index === oldIndex) {
                    return {
                        ...page,
                        order: newIndex,
                    };
                }

                if (index < oldIndex && index >= newIndex) {
                    return {
                        ...page,
                        order: index + 1,
                    };
                }

                return {
                    ...page,
                    order: index,
                };
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.enterprise-pages-list, .dropzone {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
</style>
