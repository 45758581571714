<template>
    <div class="page-content">
        <form
            class="form-2 spaced"
            @submit.prevent=""
        >
            <div class="preboarding-section-title">
                <InputField
                    v-model="collectionCopy['bloc_profile_title']['content']['messages']['message_1'][`message_${lang}`]"
                    :label="translate('Section title')"
                    :type="'text'"
                    :name="'section_title'"
                    :validate="'required'"
                    :validate-as="translate('section title')"
                />
            </div>
            <div
                v-for="(block, key) of blockToDisplay[chatbotSlug]"
                :key="key"
                class="preboarding-container-text"
            >
                <HtFormSwitch
                    :id="key + 'switch'"
                    v-model="collectionCopy[block.slug]['is_enabled']"
                    :label="`${translate(block.name)}`"
                    :disabled="!collectionCopy[block.slug]['is_enabled_editabled']"
                    :name="key + 'switch'"
                    :show-optional="false"
                />
                <RichEditable
                    :key="key"
                    :ref="key"
                    v-model="collectionCopy[block.slug]['content']['messages']['message_1'][`message_${lang}`]"
                    :validate="collectionCopy[block.slug]['is_enabled'] ? 'required' : ''"
                    :validate-as="translate(block.name)"
                    :name="key"
                    :p-key="parseInt(key)"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    resource-name="preboarding"
                    :in-high-light-white-list="flowVariablesFull"
                    class="margin-bottom"
                />
            </div>
        </form>
    </div>
</template>

<script>
import CompanyFlowCollection from '@/models/CompanyFlowCollection';
import FlowHelper from '@/components/mixins/FlowHelper.vue';
import FlowVariable from '@/components/mixins/FlowVariable.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'CustomProfile',
    components: {
        HtFormSwitch,

    },
    mixins: [FlowHelper, FlowVariable],
    props: {
        chatbotSlug: {
            type: String,
            required: true,
        },
        collection: {
            type: CompanyFlowCollection,
            required: true,
        },
        lang: {
            type: String,
            default: 'fr',
        },
        maxStep: {
            type: Number,
            default: 6,
        },
        step: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            blockToDisplay: {
                full: [
                    { slug: 'bloc_profile_a', name: 'Avatar' },
                    { slug: 'bloc_profile_c', name: 'Description' },
                    { slug: 'bloc_profile_e', name: 'Hobbies' },
                    { slug: 'bloc_profile_g', name: 'Skills' },
                    { slug: 'bloc_profile_i', name: 'Next step' },
                ],
                light: [
                    { slug: 'bloc_profile_a', name: 'Avatar' },
                    { slug: 'bloc_profile_c', name: 'Description' },
                    { slug: 'bloc_profile_e', name: 'Hobbies' },
                    { slug: 'bloc_profile_g', name: 'Skills' },
                ],
            },
        };
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
@import "~@/styles/customflow";
</style>
