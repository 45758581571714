<template>
    <div v-if="item && item.isLoaded()">
        <form
            class="form-2 spaced label-underline"
            data-cy="software-form"
            @submit.prevent=""
        >
            <fieldset>
                <h1>{{ item.name }}</h1>
            </fieldset>

            <fieldset v-if="item.description">
                <label><t>Description</t> : </label>
                <p> {{ item.description }} </p>
            </fieldset>

            <fieldset v-if="item.company_user_program_task">
                <label><t>Setup date</t> : </label>
                <div class="item-content">
                    {{ $Utils.moment(item.company_user_program_task.datetime_end).format('ll') }}
                </div>
            </fieldset>

            <fieldset>
                <HtStatusLabel
                    :status="item.company_user_program_task.status"
                    :validator-type="item.company_user_program_task.validator_type"
                    :datetime="item.company_user_program_task.datetime_end"
                />
            </fieldset>

            <div
                v-if="!isCancelled"
                class="modal-actions right"
            >
                <Button
                    v-if="software.permissions.delete"
                    class="negative"
                    cypress="software-delete-button"
                    @click="onDelete()"
                >
                    <t>Remove</t>
                </Button>
                <Button
                    v-if="software.permissions.update"
                    cypress="software-edit-button"
                    @click="openEditModal()"
                >
                    <t>Edit</t>
                </Button>
                <Button
                    v-if="software.company_user_program_task.permissions.can_validate && canValidate"
                    :button_state="buttonState"
                    cypress="software-toggle-status-button"
                    @click="toggleStatus()"
                >
                    <t>{{ toggleButtonLabel }}</t>
                </Button>
            </div>
        </form>
        <modalable
            ref="modalableUserEdit"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserSoftwareEdit
                :id="id"
                ref="userEdit"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onRemove(...arguments)"
            />
        </modalable>
    </div>
</template>
<script>
import CompanyUserSoftwareCollection from '@/models/CompanyUserSoftwareCollection';
import CompanyUserSoftware from '@/models/CompanyUserSoftware';
import HtStatusLabel from '@/pages/HtStatusLabel.vue';
import CompanyUserProgramTask from '../../../../../models/CompanyUserProgramTask';
import UserSoftwareEdit from './UserSoftwareEdit.vue';

export default {
    name: 'UserSoftwareItem',
    components: {
        UserSoftwareEdit,
        HtStatusLabel,
    },
    inject: ['modal'],

    permissions: [
        'ModelCompanyUserSoftware',
    ],

    props: {
        companyUserId: { type: [String, Number], required: true },
        value: { type: CompanyUserSoftware, required: false },
        collection: { type: CompanyUserSoftwareCollection, required: false },
        id: { type: [String, Number], required: false },
        canValidate: { type: Boolean, default: true },
    },

    data() {
        return {
            // FOR LAZY LOADING
            software: new CompanyUserSoftware([
                'id', 'company_user_id', 'company_software_id', 'permissions',
            ]).with({
                companySoftware: (query) => {
                    query.select(['id']).with({
                        resource: (query) => {
                            query.select(['id', 'name', 'description']);
                        },
                    }).withTrashed(true);
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image']);
                },
                companyUserProgramTask: (query) => {
                    query.select(['id', 'status', 'datetime_end', 'validator_type', 'permissions']);
                },
            }).where([['id', '=', this.id]]),
            item: null,
            buttonState: 'idle',
        };
    },

    computed: {
        labelTitle() {
            return this.item.company_software.resource.name;
        },

        toggleButtonLabel() {
            return this.item.status === 'done' ? 'Mark as undone' : 'Mark as done';
        },

        isCancelled() {
            return this.item.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },
    },

    watch: {
        'item._state.isSaving': function (val) {
            this.buttonState = val ? 'loading' : 'idle';
        },
    },

    async created() {
        if (this.value) this.item = this.value.fromSaveState();
        else {
            this.software.company_user_id = this.companyUserId;
            await this.software.get();
            this.item = this.software;
        }

        this.modal.setTitle(this.labelTitle);
    },

    methods: {
        onUpdate() {
            this.modal.close();
            this.$emit('onUpdate');
        },

        onRemove() {
            this.modal.close();
            this.$emit('onDelete');
        },

        openEditModal() {
            this.$refs.modalableUserEdit.open();
        },

        async onDelete() {
            if (await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this software? Once you click on delete, you will no longer be able to access this software.') })) {
                this.item.delete().then(() => {
                    this.onRemove();
                });
            }
        },

        async toggleStatus() {
            if (this.item.status === 'pending_system') {
                this.$Notifier('App').showError(this.translate('The installation of this software is handled automatically by HeyTeam'));
                return Promise.resolve();
            }

            try {
                await this.$handleValidationTasks({
                    id: this.item.company_user_program_task.id,
                    status: (this.item.status === 'done') ? CompanyUserProgramTask.STATUS_PENDING : CompanyUserProgramTask.STATUS_DONE,
                });
                this.modal.close();
                this.$emit('onUpdate');
            } catch (error) {

            }
        },
    },
};
</script>
<style lang="scss" scoped>
    @import "~@/styles/var";

    .image {
        width: 75px;
        height: 75px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .status-icon {
        display: flex;

        .icon {
            margin-right: 1em;
        }
    }

    .color-primary {
        color: $color-primary;
    }

    .color-green {
        color: $color-green;
    }

    .color-grey-lighter {
        color: $color-grey-lighter;
    }
</style>
