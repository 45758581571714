<template>
    <transition name="fade">
        <TranslatedForm v-slot="{editingLang}">
            <div
                v-if="item"
                id="quiz-edit"
            >
                <div class="form-2 spaced">
                    <HtFormInput
                        id="name"
                        ref="nameInput"
                        v-model="item.resource.translations.name[editingLang]"
                        v-validate.continues="{
                            translation_default:[item.resource.translations.name, shared.session.company.company_languages.default.key],
                            max:255,
                        }"
                        name="name"
                        :label="translate('Name')"
                        :data-vv-as="translate('name')"
                        :cypress="'quiz-field-name'"
                        show-asterisk
                    />

                    <HtFormEditor
                        id="message_welcomInput"
                        ref="message_welcomInput"
                        v-model="item.translations.message_welcome[editingLang]"
                        v-validate.disable="'required'"
                        class="no-bottom-margin"
                        :has-toolbar="false"
                        :label="translate('This message will be displayed at the beginning of the quiz')"
                        cypress="quiz-field-message_welcome"
                        name="message_welcom"
                        :data-vv-as="translate('message welcom')"
                        show-asterisk
                    />

                    <HtFormEditor
                        id="message_congratularyInput"
                        ref="message_congratularyInput"
                        v-model="item.translations.message_congratulary[editingLang]"
                        v-validate.disable="'required'"
                        class="no-bottom-margin"
                        :has-toolbar="false"
                        cypress="quiz-field-message_congratulary"
                        :label="translate('This message will be displayed at the end of the quiz')"
                        show-asterisk
                        name="message_congratulary"
                        :data-vv-as="translate('message of congratulation')"
                    />

                    <HtProgramList
                        ref="htProgramListInput"
                        v-model="item.resource.programs[0].company_program_id"
                        :required="false"
                        :with-default="false"
                        :disabled="disabledProgramList"
                    />

                    <section
                        v-if="isResourceTargeted"
                        v-show="editingLang === shared.session.company.company_languages.default.key"
                    >
                        <HtFormSwitch
                            v-show="editingLang === shared.session.company.company_languages.default.key"
                            :id="'enabled'"
                            v-model="item.resource.programs[0].is_enabled"
                            :name="'enabled'"
                            :show-optional="false"
                            :label="translate('Enabled')"
                        />

                        <fieldset :class="[{ error: errors.has('quiz_participants') }, 'medium']">
                            <label for="quiz_participants">
                                <t>Assign to:</t> *
                            </label>
                            <transition-group
                                name="fade"
                                class="card-6 flex-container"
                            >
                                <template v-if="item.resource.programs[0].participants && item.resource.programs[0].participants.length">
                                    <div
                                        v-for="(participant, index) in quizParticipants"
                                        :key="index"
                                        :data-cy="'quiz-field-company_quiz_participant'"
                                        style="display:flex"
                                        class="item"
                                    >
                                        <UserBlock2
                                            v-if="participant.company_role && participant.company_role_id"
                                            :key="index"
                                            v-model="participant.company_role"
                                            :type="'role'"
                                            @onRemove="onRemoveParticipant(participant)"
                                        />
                                        <UserBlock2
                                            v-else-if="participant.company_user && participant.company_user_id"
                                            :key="index"
                                            v-model="participant.company_user"
                                            :type="'user'"
                                            @onRemove="onRemoveParticipant(participant)"
                                        />
                                    </div>
                                </template>
                                <UserBlock2
                                    ref="quiz_participantsInput"
                                    :key="-1200"
                                    data-cy="quiz-field-company_quiz_participant"
                                    @onAdd="openAddUsersModal"
                                />
                            </transition-group>
                            <input
                                id="quiz_participants"
                                v-validate.disable="'array'"
                                v-next-field
                                type="hidden"
                                :value="item.resource.programs[0].participants && item.resource.programs[0].participants.length ? '-' : ''"
                                name="quiz_participants"
                                class="full-width"
                                :data-vv-as="translate('list of participants')"
                                :placeholder="translate('Type here')"
                            >
                            <div
                                v-for="(errorMessage, index) in errors.collect('quiz_participants')"
                                :key="index"
                                class="error-message"
                            >
                                {{ errorMessage }}
                            </div>
                        </fieldset>
                        <HtFormGroup
                            :label="translate('Filters')"
                            :description="translate('Add filters to decide who can access the resource')"
                        >
                            <HtFormCard>
                                <ProgramResourceFilters
                                    v-if="item.resource.programs[0].company_program_id"
                                    v-model="item.resource.programs[0].filters"
                                    :program-id="item.resource.programs[0].company_program_id"
                                    :use-resource-perimeter="useResourcePerimeter"
                                />
                                <div
                                    v-else
                                    class="sentence-filter"
                                >
                                    <t>Select a program to display associated filters</t>
                                </div>
                            </HtFormCard>
                        </HtFormGroup>
                        <HtKeyDatesInputOrder
                            :program-id="item.resource.programs[0].company_program_id"
                            :resource-id="item.id"
                            :resource-type="resourceableClass"
                            :filters="item.resource.programs[0].filters"
                            :is-business-day.sync="item.resource.programs[0].is_business_day"
                            :offset-key-date.sync="item.resource.programs[0].company_program_key_date_id"
                            :offset-availability-number.sync="item.resource.programs[0].offset_availability_number"
                            :offset-availability-unit.sync="item.resource.programs[0].offset_availability_unit"
                            :offset-end-number.sync="item.resource.programs[0].offset_end_number"
                            :offset-end-unit.sync="item.resource.programs[0].offset_end_unit"
                            :dependents.sync="item.resource.programs[0].dependents"
                            :dependencies.sync="item.resource.programs[0].dependencies"
                        />
                    </section>
                </div>

                <div class="modal-actions right">
                    <Button
                        v-if="canDelete"
                        :state="buttonState"
                        class="negative"
                        cypress="quiz-delete"
                        @click="onDestroyModal()"
                    >
                        <t>Delete</t>
                    </Button>
                    <Button
                        :state="buttonState"
                        class="negative"
                        @click="onValidate()"
                    >
                        <t>Save</t>
                    </Button>
                    <Button
                        :cypress="'quiz-form-go-to-questions-button'"
                        :state="buttonState"
                        @click="openQuestion()"
                    >
                        {{ labelButtonQuestions }}
                    </Button>
                </div>

                <modalable
                    ref="modalableEditQuestionQuiz"
                    class="modalable-1 big bg-grey"
                    :mode="2"
                >
                    <QuizQuestionListEdit
                        ref="quizEditQuestion"
                        :item="item"
                        @onSave="onClose"
                    />
                </modalable>
                <modalable
                    ref="modalableSearchRolePanel"
                    class="modalable-1 small"
                    :options="{ title: translate('Done by')}"
                >
                    <SearchRolePanel
                        ref="searchRolePanel"
                        :multiple-mode="true"
                        :is_assignable_onboardee="true"
                        @onValidate="$refs.modalableSearchRolePanel.close()"
                    />
                </modalable>
            </div>
        </TranslatedForm>
    </transition>
</template>

<script>
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import HtProgramList from '@/components/globals/HtProgramList.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import ResourceModalEditMixin from '@/mixins/ResourceModalEditMixin';
import ResourceKeyDateLabelMixin from '@/mixins/ResourceKeyDateLabelMixin';
import TranslatedForm from '@/components/form/TranslatedForm.vue';
import I18n from '@/modules/i18n/I18n';
import ProgramResourceFilters from '@/components/globals/ProgramResourceFilters.vue';
import CompanyQuiz from '@/models/CompanyQuiz';
import api from '@/store/api';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import { HTTP_RESPONSE_UNPROCESSABLE_ENTITY } from '@/plugins/HttpPlugin';
import cloneDeep from 'lodash.clonedeep';
import QuizQuestionListEdit from './QuizQuestionListEdit.vue';

export default {
    components: {
        HtKeyDatesInputOrder,
        ProgramResourceFilters,
        QuizQuestionListEdit,
        HtProgramList,
        HtFormSwitch,
        TranslatedForm,
        HtFormCard,
    },

    mixins: [
        DefaultFiltersMixin,
        DefaultOrderCompletionMixin,
        ResourceModalEditMixin,
        ResourceKeyDateLabelMixin,
    ],

    inject: ['modal', '$validator'],

    data() {
        return {
            item: null,
        };
    },

    computed: {
        userPerimeterEntities() {
            return this.$store.state.user.perimeterEntities;
        },

        useResourcePerimeter() {
            return this.$store.getters['permissions/useResourcePerimeter']('ModelCompanyQuiz');
        },

        isResourceTargeted() {
            return this.item && this.item.resource.programs.length > 0 && !!this.item.resource.programs[0].company_program_id;
        },

        resourceableClass() {
            return CompanyQuiz.RESOURCEABLE_CLASS;
        },

        hasChildren() {
            return this.item.resource.programs[0].dependencies && this.item.resource.programs[0].dependencies.length > 0;
        },

        hasParent() {
            return this.item.resource.programs[0].dependents && this.item.resource.programs[0].dependents.length > 0;
        },

        canDelete() {
            return this.showButtonDelete === true && this.item.id;
        },

        labelTitle() {
            return I18n.translate(this.item.id ? 'Edit quiz' : 'Add quiz');
        },

        labelButtonQuestions() {
            return this.translate(this.item.questions.length ? 'See Questions' : 'Add Questions');
        },

        quizParticipants() {
            const participants = [];

            for (const participant of this.item.resource.programs[0].participants) {
                if (
                    (!participant.company_role && !participant.company_user)
                    && (!participant.role && !participant.user)
                ) {
                    continue;
                }

                participant.company_role = participant.company_role || participant.role;
                participant.company_user = participant.company_user || participant.user;
                participants.push(participant);
            }

            return participants;
        },
    },

    methods: {
        async fetchModelFromId() {
            const quiz = (await api.configuration.quizzes.get(this.id)).data.data;

            const isResourceTargeted = quiz.resource.programs.length > 0;
            if (!isResourceTargeted) {
                // default values for the targeting
                quiz.resource.programs[0] = {
                    company_program_id: null,
                    company_program_key_date_id: null,
                    offset_availability_number: 0,
                    offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                    offset_end_number: 0,
                    offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                    is_business_day: true,
                    is_enabled: true,
                    dependencies: [],
                    dependents: [],
                    participants: [],
                    filters: [],
                };
            }

            return quiz;
        },

        setDefaultOrderCompletion() {},

        createModelFromCollection() {
            this.item = {
                translations: {
                    message_welcome: {},
                    message_congratulary: {},
                },
                resource: {
                    programs: [
                        {
                            company_program_id: this.companyProgramId,
                            company_program_key_date_id: null,
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            is_enabled: true,
                            dependencies: [],
                            dependents: [],
                            participants: [],
                            filters: [],
                        },
                    ],
                    translations: {
                        name: {},
                        description: {},
                    },
                },
                questions: [],
            };

            if (this.useResourcePerimeter) {
                this.item.resource.programs[0].filters = this.userPerimeterEntities;
            }
        },

        async createModelFromId() {
            this.item = await this.fetchModelFromId();
        },

        async duplicateModel() {
            this.item = await this.fetchModelFromId();
            this.item.id = undefined;
            this.item.is_enable = true;

            this.item.resource.id = undefined;
            this.item.resource.name = undefined;
            this.item.resource.description = undefined;
            this.item.resource.programs[0].company_program_id = this.companyProgramId;
            this.item.resource.programs[0].company_program_key_date_id = null;
            this.item.resource.programs[0].id = undefined;
            this.item.resource.programs[0].key_date = {};

            this.item.resource.programs[0].participants = this.item.resource.programs[0].participants.map((participant) => ({ ...participant, id: undefined }));

            this.item.questions = this.item.questions.map((question) => ({
                ...question,

                id: undefined,
                file_translations: {},
                answers: question.answers.map((answer) => ({
                    ...answer,

                    id: undefined,
                    file_translations: {},
                })),
            }));
        },

        openAddUsersModal() {
            this.$refs.searchRolePanel.init({
                onValidate: this.addParticipants,
                selected: this.item.resource.programs[0].participants,
            });
            this.$refs.modalableSearchRolePanel.open();
        },

        addParticipants(participants) {
            this.item.resource.programs[0].participants = participants;
        },

        onRemoveParticipant(participantToRemove) {
            const index = this.item.resource.programs[0].participants.findIndex((participant) => {
                if (participant.company_user_id) {
                    return participant.company_user_id === participantToRemove.company_user_id;
                } if (participant.company_role_id) {
                    return participant.company_role_id === participantToRemove.company_role_id;
                }
            });

            this.item.resource.programs[0].participants.splice(index, 1);
        },

        onClose() {
            this.$emit('onSave');
            this.modal.close();
        },

        async onValidate() {
            await this.$validator.validateAll();

            if (this.item.questions.length === 0) {
                this.$Notifier('App').showError(this.translate('You need to have at least one question!'));
                return;
            }

            if (this.errors.any()) {
                // Todo: Improve it
                const firstField = Object.keys(this.errors.collect())[0];

                if (this.$refs[`${firstField}Input`]) {
                    this.$refs[`${firstField}Input`].$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }

                return;
            }

            this.buttonState = 'loading';

            const resourceToSave = cloneDeep(this.item);
            if (!resourceToSave.resource.programs[0].company_program_id) {
                resourceToSave.resource.programs = [];
            }

            const isExistingResource = this.item.id !== undefined;
            const fetchPromise = isExistingResource
                ? api.configuration.quizzes.update(this.item.id, resourceToSave)
                : api.configuration.quizzes.store(resourceToSave);

            fetchPromise.then(() => {
                this.modal.close();
                if (this.$route.query.id) {
                    // If we go to "Resources/Quizzes?id=..." (via Holding for example), once fetch executed we must redirect to "Ressource/Quizzes" (without ?id=...)
                    // otherwise, we will see the QuizEdit modal that opens again
                    this.$router.push({ name: 'ResourcesQuizzes' });
                }
                this.$emit('onSave');
            }).catch((response) => {
                this.buttonState = 'idle';

                const responseStatus = window.parseInt(response.response.status, 10);
                if (responseStatus !== HTTP_RESPONSE_UNPROCESSABLE_ENTITY) {
                    this.$Notifier('App').showError(this.translate('An error occured'));
                }
            });
        },

        openQuestion() {
            this.$validator.validateAll().then((result) => {
                if (result === true) {
                    this.$refs.modalableEditQuestionQuiz.open();
                } else {
                    this.$Utils.scrollToFirstError(this);
                }
            });
        },

        async onDestroyModal() {
            const isDeleteConfirmed = await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this quiz? Once you click on delete, you will no longer be able to access this quiz.') });
            if (!isDeleteConfirmed) {
                return;
            }

            this.buttonState = 'loading';
            await api.configuration.quizzes.delete(this.item.id);
            this.buttonState = 'idle';
            this.$emit('onDelete');
            this.modal.close();
        },
    },
};
</script>
