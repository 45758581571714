export default {
    props: {
        value: {
            type: [String, Number, Array, Object, Boolean],
            default: undefined,
        },
        options: {
            type: Array,
            default: () => [],
        },
        // Used to have multiple selection of options
        isSingle: {
            type: Boolean,
            default: false,
        },
        // Used to have no option when unselecte one
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        // Used to compare object when options are Object
        trackBy: {
            type: String,
            default: 'id',
        },
        specificKey: {
            type: String,
            default: '',
        },
        /**
         * Hauteur maximale du select une fois déployé
         */
        maxHeight: {
            type: [Number, undefined],
            default: undefined,
        },
        openDirection: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        value(val) {
            this.setCurrentValue(val);
        },
        options() {
            this.setCurrentValue(this.value);
        },
    },

    created() {
        this.setCurrentValue(this.value);
    },
    methods: {
        getOptionTrackedValue(option) {
            if (typeof option !== 'object' || !option) return option;
            if (this.specificKey) return option[this.specificKey];
            if (this.trackBy) return option[this.trackBy];
            return option;
        },

        getValueInOptionArray(value, options) {
            if (value !== null && value !== undefined && options.length) {
                if (this.isSingle) {
                    const optionTrackedValue = this.getOptionTrackedValue(value);
                    return options.find(
                        (option) => this.getOptionTrackedValue(option) === optionTrackedValue,
                    );
                }
                return options.filter((option) => {
                    const optionTrackedValue = this.getOptionTrackedValue(option);
                    return value
                        .map((oneValue) => this.getOptionTrackedValue(oneValue))
                        .includes(optionTrackedValue);
                });
            }
            return value ?? null;
        },
        setCurrentValue(value) {
            const optionsUsed = this.processedOptions ?? this.options;
            this.currentValue = this.getValueInOptionArray(value, optionsUsed);
        },
        resizeInput() {
            if (!this.isSingle && this.value?.length) {
                const input = document.getElementById(this.id);
                input.setAttribute('size', this.searchQuery?.length || 1);
            }
        },
        onUpdateValue(value) {
            if (this.enableSelector) {
                const selector = value.find((v) => v.type === 'selector');
                if (selector) {
                    if (!selector.$isSelected) {
                        const validOptions = this.options.filter((o) => o.$isDisabled !== true);
                        const valuesToKeep = this.currentValue.filter((v) => v.is_deletable === false);
                        if (this.specificKey) {
                            this.$emit('input', [...validOptions.map((option) => option[this.specificKey]), ...valuesToKeep]);
                        } else {
                            this.$emit('input', [...validOptions, ...valuesToKeep]);
                        }
                    } else {
                        this.$emit('input', this.currentValue.filter((v) => v.is_deletable === false));
                    }

                    return;
                }
            }

            let valueToEmit = this.getValueInOptionArray(value, this.options);
            if (this.specificKey && valueToEmit) {
                if (this.isSingle) {
                    valueToEmit = valueToEmit[this.specificKey];
                } else {
                    valueToEmit = valueToEmit.map((option) => option[this.specificKey]);
                }
            }
            this.$emit('input', valueToEmit);
            this.$nextTick(() => {
                this.resizeInput();
            });
        },
    },
};
