<template>
    <div
        v-if="readOnly === false"
        class="answer"
    >
        <div class="answer-input">
            <input
                v-model="message"
                type="hidden"
                :v-validate="rules"
                data-vv-validate-on="none"
                name="message"
            >
            <span
                class="textarea"
                role="textbox"
                contenteditable
                @input="event => (message = event.target.innerText)"
            />
            <div
                v-if="errors.has('message')"
                class="answer-input-error"
            >
                {{ errors.first('message') }}
            </div>
        </div>
        <div class="answer-actions">
            <div
                v-if="isNotMandatory"
                class="answer-actions-skip"
                @click="skip()"
            >
                <t>Skip</t>
            </div>
            <Button
                :state="buttonState"
                @click="sendAnswer()"
            >
                <t>Send</t>
            </Button>
        </div>
    </div>
</template>

<script>
import CompanyUserSurveyQuestionAnswerCollection from '@/models/CompanyUserSurveyQuestionAnswerCollection';
import MessageResourceUserSurveyQuestionMixin from './mixin/MessageResourceUserSurveyQuestionMixin';

export default {
    name: 'MessageResourceUserSurveyQuestionText',
    mixins: [
        MessageResourceUserSurveyQuestionMixin,
    ],
    data() {
        return {
            message: '',
        };
    },
    computed: {
        rules() {
            return {
                required: true,
            };
        },
        buttonState() {
            return this.message && this.message.length > 0 ? 'idle' : 'disabled';
        },
    },
    methods: {
        sendAnswer() {
            if (this.readOnly) return;

            this.$validator.validateAll().then((result) => {
                if (result) {
                    const companyUserSurveyQuestionAnswerCollection = new CompanyUserSurveyQuestionAnswerCollection([
                        'text',
                    ]);

                    const answer = companyUserSurveyQuestionAnswerCollection.new();
                    answer.text = this.message.trim();

                    companyUserSurveyQuestionAnswerCollection.models.push(answer);

                    this.$emit('onAnswers', companyUserSurveyQuestionAnswerCollection);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped src="./MessageResourceUserSurveyQuestionText.scss"></style>
