import Model from '@tony.caron/node-model-proxy/Model';
import CompanyDelegationCollection from './CompanyDelegationCollection';

export default class CompanyDelegation extends Model {
    modelConfig() {
        return {
            collection: CompanyDelegationCollection,
        };
    }

    modelRelations() {
        return {
        };
    }
}
