<template>
    <div v-if="companyUserQuizCollection.isLoaded()">
        <HtAccordion
            :title="translate('Quiz | Quizzes', {count: filteredUserQuiz.length})"
            :count="filteredUserQuiz.length"
            :has-button="filteredUserQuiz.length === 0"
            :button-label="addButtonLabel"
            :is-branding="false"
            cypress="quiz"
            @buttonClick="openSelectModal()"
        >
            <HtSearchField
                v-if="hasQuiz"
                id="searchQuiz"
                v-model.trim="searchValue"
                name="searchQuiz"
                cypress="searchQuiz"
            />
            <template v-if="filteredUserQuiz.length > 0">
                <HtTable
                    :items="filteredUserQuiz"
                    :columns="columns"
                    cypress="quiz-table"
                    :external-search="searchValue"
                    @onCellClicked="openEditModal"
                >
                    <template #cell(participant)="scope">
                        <ParticipantList :users="[{company_user: scope.item.company_user_participant}]" />
                    </template>
                </HtTable>
                <div class="mt-4 text-center">
                    <Button
                        type="clear"
                        cypress="quiz-add-button"
                        @click="openSelectModal()"
                    >
                        <slot name="buttonText">
                            {{ translate(addButtonLabel) }}
                        </slot>
                    </Button>
                </div>
            </template>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No Quiz added</t>
                </strong>
            </div>
        </HtAccordion>

        <modalable
            ref="modalableEdit"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserQuizEdit
                :id="currentId"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </modalable>

        <modalable
            ref="modalableSelect"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserQuizSelect
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                :intelligent-selection="intelligentSelection"
                @onUpdate="onUpdate(...arguments)"
            />
        </modalable>
    </div>
</template>

<script>
import HtSearchField from '@/components/globals/HtSearchField.vue';
import HtAccordion from '@/components/globals/HtAccordion.vue';
import UserQuizEdit from '@/components/pages/dashboard/modals/quiz/UserQuizEdit.vue';
import UserQuizSelect from '@/components/pages/dashboard/modals/quiz/UserQuizSelect.vue';
import CompanyUserQuizCollection from '@/models/CompanyUserQuizCollection';

export default {
    name: 'UserQuizFlow',
    components: {
        UserQuizEdit,
        UserQuizSelect,
        HtSearchField,
        HtAccordion,
    },

    props: {
        companyUserId: {
            type: Number,
            required: false,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
        intelligentSelection: {
            type: Array,
            required: false,
        },
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                    search: true,
                },
                {
                    label: this.translate('Participants'),
                    key: 'participant',
                    sortable: false,
                },
            ],
            currentId: null,
            companyUserQuizCollection: new CompanyUserQuizCollection([
                'id',
                'company_user_program_id',
                'company_user_id',
                'name',
                'company_user_participant_id',
                'permissions',
            ]).with({
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserParticipant: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image']);
                },
                companyQuiz: (query) => {
                    query.select(['id']).with({
                        resource: (query) => {
                            query.select(['id', 'name', 'translations']);
                        },
                    });
                },
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'resource_id',
                        'resource',
                    ]).with({
                        dependencies: (query) => {
                            query.select([
                                'id',
                                'resource',
                            ]);
                        },
                        dependents: (query) => {
                            query.select([
                                'id',
                            ]);
                        },
                    });
                },
            }).where([
                ['company_user_participant_id', '=', this.companyUserId],
                ['company_user_program_id', '=', this.companyUserProgramId],
            ]),
            searchValue: null,
            addButtonLabel: 'Add quizz',
        };
    },

    computed: {
        filteredUserQuiz: {
            get() {
                return this.companyUserQuizCollection.$.models.filter((item) => {
                    if (this.$UserAcl.isOwner(this.companyUserId)) {
                        return this.$Utils.moment().isSameOrAfter(this.$Utils.moment(item.datetime_availability));
                    }

                    return true;
                });
            },

            set(val) {
                this.companyUserQuizCollection.$.models = val;
            },
        },

        hasQuiz() {
            return this.companyUserQuizCollection.$.models.length > 0;
        },
    },

    created() {
        if (this.$canRead('ModelCompanyUserQuiz', { company_user_id: this.companyUserId })) {
            this.companyUserQuizCollection.where([['company_user_id', '=', this.companyUserId]]);
        } else {
            this.companyUserQuizCollection.where([['company_user_participant_id', '=', this.companyUserId]]);
        }

        this.companyUserQuizCollection.get().then(() => {
            this.$emit('on-load', this.companyUserQuizCollection.$.models.length);
        });
    },

    methods: {
        onUpdate() {
            this.companyUserQuizCollection.get();
        },

        onDelete() {
            this.companyUserQuizCollection.get();
        },

        openEditModal(row) {
            this.currentId = row.item.id;
            this.$refs.modalableEdit.open();
        },
        openSelectModal() {
            this.$refs.modalableSelect.open();
        },
    },
};
</script>
