<template>
    <div class="ht-collapse-item">
        <div class="ht-collapse-item-header">
            <div class="ht-collapse-item-header-title">
                <slot></slot>
            </div>

            <div class="ht-collapse-item-header-right">
                <span
                    v-if="collapsable"
                    class="ht-collapse-item-header-right-collapsable"
                    @click="toggle"
                >
                    <template v-if="showRightLabel">
                        <t v-if="isCollapsed">Show details</t>
                        <t v-else>Hide details</t>
                    </template>

                    <FontAwesomeIcon
                        :icon="['fal', isCollapsed ? 'chevron-down' : 'chevron-up']"
                    />
                </span>

                <slot name="header-right"></slot>
            </div>
        </div>

        <div
            v-show="!isCollapsed"
            class="ht-collapse-item-content"
        >
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'HtCollapseItem',

    components: {
        HtButton,
    },

    props: {
        collapsable: {
            type: Boolean,
            default: () => true,
        },

        defaultCollapse: {
            type: Boolean,
            default: () => true,
        },

        showRightLabel: {
            type: Boolean,
            default: () => true,
        },
    },

    data() {
        return {
            isCollapsed: this.defaultCollapse,
        };
    },

    methods: {
        toggle() {
            this.isCollapsed = !this.isCollapsed;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.ht-collapse-item {
    padding: 16px;

    &:not(:last-child) {
        border-bottom: 1px solid $neutral-500;
    }

    &-header {
        display: flex;
        justify-content: space-between;

        &-title, &-right, &-right > button {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
        }

        &-right, &-right-collapsable {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    &-content {
        margin-top: 16px;
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }
}
</style>
