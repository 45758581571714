import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import CompanyRole from './CompanyRole';
import CompanyEmailTemplateRecipientCollection from './CompanyEmailTemplateRecipientCollection';
import CompanyGroup from './CompanyGroup';

export default class CompanyEmailTemplateRecipient extends Model {
    modelConfig() {
        return {
            collection: CompanyEmailTemplateRecipientCollection,
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyRole: () => this.belongsTo(CompanyRole, 'company_role_id', 'id'),
            companyGroup: () => this.belongsTo(CompanyGroup, 'company_group_id', 'id'),
        };
    }
}
