import Model from '@tony.caron/node-model-proxy/Model';

import Company from './Company';
import CompanySurvey from './CompanySurvey';
import CompanyUser from './CompanyUser';
import CompanyUserSurvey from './CompanyUserSurvey';
import CompanySurveyQuestion from './CompanySurveyQuestion';
import CompanyUserSurveyQuestionAnswer from './CompanyUserSurveyQuestionAnswer';
import CompanyUserSurveyQuestionCollection from './CompanyUserSurveyQuestionCollection';

export default class CompanyUserSurveyQuestion extends Model {
    static CUSTOM_STORE_ANSWERS = 'storeAnswers';

    static STATUS_DONE = 'done';

    modelConfig() {
        return {
            collection: CompanyUserSurveyQuestionCollection,
        };
    }

    modelCustomAttributes() {
        return {
            active: false,
        };
    }

    modelRelations() {
        return {
            company: () => this.belongsTo(Company, 'company_id', 'id'),
            companySurvey: () => this.belongsTo(CompanySurvey, 'company_survey_id', 'id'),
            companyUserSurvey: () => this.belongsTo(CompanyUserSurvey, 'company_user_survey_id', 'id'),
            companySurveyQuestion: () => this.belongsTo(CompanySurveyQuestion, 'company_survey_question_id', 'id'),
            companyUserParticipant: () => this.belongsTo(CompanyUser, 'company_user_participant_id', 'id'),
            companyUserSurveyQuestionAnswer: () => this.hasMany(CompanyUserSurveyQuestionAnswer, 'company_user_survey_question_id', 'id'),
        };
    }

    modelEvents() {
        return {
            saved: () => {},
            updated: () => {},
        };
    }
}
