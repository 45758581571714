<template>
    <StepperTabLayout>
        <template #main>
            <IconLoading v-if="isInit === false" />
            <HtFormCard
                v-else
                class="mt-4"
            >
                <h5><t>CAPI: Your personal AI Assistant</t></h5>
                <br>
                <p>
                    Your prompts (inputs) and completions (outputs), your content, your documents, and your training data:
                </p>
                <ul class="mt-3 mb-5">
                    <li>- are NOT available to other customers.</li>
                    <li>- are NOT available to OpenAI.</li>
                    <li>- are NOT used to improve OpenAI models.</li>
                    <li>- are NOT used to improve any 3rd party products or services.</li>
                    <li>- are NOT used for automatically improving models for your use in your resource.</li>
                </ul>

                <p>
                    The Service does NOT interact with any services operated by OpenAI (e.g. ChatGPT, or the OpenAI API).
                </p>

                <div
                    class="mt-5"
                >
                    <HtFormSwitch
                        id="enableCapiBot"
                        v-model="isAIBotEnabled"
                        class="m-0"
                        name="enableCapiBot"
                        :label="translate('Activate / Deactivate')"
                        :on-label="translate('On')"
                        :off-label="translate('Off')"
                        :show-optional="false"
                    />
                </div>
            </HtFormCard>
        </template>

        <template
            v-if="isInit"
            #buttonsFooter
        >
            <Button
                style="white-space: nowrap;"
                @click="toggleAiBot"
            >
                <t>Save</t>
            </Button>
        </template>
    </StepperTabLayout>
</template>

<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import StepperTabLayout from '@/components/globals/StepperTabLayout.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import IconLoading from '@/components/icons/IconLoading.vue';

export default {
    components: {
        IconLoading, HtFormCard, StepperTabLayout, HtFormSwitch,
    },
    data() {
        return {
            isAIBotEnabled: false,
            isInit: false,
        };
    },
    mounted() {
        this.refreshAISettings();
    },
    methods: {
        refreshAISettings() {
            this.$http.get('company/knowledge/settings').then((response) => {
                this.isAIBotEnabled = response?.data?.is_ai_bot_enabled ?? false;
            }).catch(({ response }) => {
                if (response.status !== 409) alert('An error occurred.');
            }).finally(() => {
                this.loading = false;
                this.isInit = true;
            });
        },
        toggleAiBot() {
            this.$http.post('company/knowledge/settings', {
                settings: {
                    is_enabled: this.isAIBotEnabled,
                },
            }).then((response) => {
                if (response.status < 300) {
                    window.location.reload();

                    return;
                }

                this.refreshAISettings();
            }).catch(({ response }) => {
                if (response.status !== 409) alert('An error occurred.');
            }).finally(() => {
                this.loading = false;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.switch-capi-bot {
    font-size: 16px;
    font-weight: bold;
}
</style>
