<template>
    <UploadBar
        v-model="companyFile"
        :is-requirement="isRequirement"
        :with-trash="false"
        :media="['office', 'pdf', 'image']"
    />
</template>
<script>
import CompanyFile from '../../models/CompanyFile';
import UploadBar from './UploadBar/UploadBar.vue';

export default {
    name: 'Downloadable',
    components: { UploadBar },

    props: {
        isRequirement: {
            type: Boolean,
            default: false,
        },
        companyFile: {
            type: [CompanyFile, Object],
            required: true,
        },
    },
};
</script>
