<template>
    <HtCard>
        <IntegrationsTable />
    </HtCard>
</template>

<script>
import IntegrationsTable from '@/components/resources/integration/IntegrationsTable.vue';

export default {
    name: 'Integrations',
    components: {
        IntegrationsTable,
    },
};
</script>
