<template>
    <div
        class="ht-tag"
        :class="getClasses"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'HtTag',
    props: {
        color: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            required: false,
        },
    },

    computed: {
        /**
         * @returns {string[]}
         */
        getClasses() {
            const classes = [this.color];

            if (this.size) {
                classes.push(this.size);
            }
            return classes;
        },
    },
};
</script>

<style lang="scss" scoped src="./HtTag.scss"></style>
