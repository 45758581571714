<template>
    <div
        class="item-text"
        :class="customClass"
    >
        <div
            v-if="isHtml"
            v-dompurify-html="text"
        />
        <div v-else>
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'MessageTextTemplate',
    props: {
        text: {
            type: String,
            required: true,
        },
        isHtml: {
            type: Boolean,
            default: false,
        },
        isMe: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        customClass() {
            return {
                'item-text-me': this.isMe,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.item-text {
    display: inline-block;
    border-radius: 15px;
    padding: 15px;
    word-break: break-word;
    background-color: white;
    white-space: pre-line;
}

.item-text-me {
    background-color: #e0e0e3!important;
}
</style>
