<template>
    <TaskItem
        :timeline-color="timelineColor"
        :task="task"
        :key-date="keyDate"
        :use-key-date="useKeyDate"
        :is-wrapper="isWrapper"
        :is-with-icon="true"
        :is-with-little-padding="true"
        :can-edit="canEdit"
        @onSave="$emit('onSave')"
        @onDelete="$emit('onDelete')"
        @onToggle="(isToggled) => {$emit('onToggle', isToggled)}"
    />
</template>

<script>
import TaskItem from '@/components/resources/task/TaskItem.vue';

export default {
    name: 'TaskSimulation',

    components: {
        TaskItem,
    },
    props: {
        task: {
            type: Object,
            required: true,
        },
        timelineColor: {
            type: String,
            required: true,
        },
        isWrap: {
            type: Boolean,
            default: false,
        },
        isWrapper: {
            type: Boolean,
            default: false,
        },
        keyDate: {
            type: String,
            default: null,
        },
        useKeyDate: {
            type: Boolean,
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: () => true,
        },
    },
};
</script>
