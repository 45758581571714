import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyGroup from './CompanyGroup';

export default class CompanyGroupCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyGroup,
        };
    }
}
