import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import CompanyPlanningEventAttendeeCollection from './CompanyPlanningEventAttendeeCollection';

export default class CompanyPlanningEventAttendee extends Model {
    modelConfig() {
        return {
            collection: CompanyPlanningEventAttendeeCollection,
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
        };
    }
}
