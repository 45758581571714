<template>
    <div
        :id="`question-${questionIndex}`"
        class="question"
    >
        <component
            :is="getComponent"
            v-model="value"
            :contents="contents"
            :current-language="currentLanguage"
            :subtype="getSubtype"
            :question-index="questionIndex"
            :questions="questions"
            :is-completed="isCompleted"
            :is-conversational="isConversational"
            :is-recurrent="isRecurrent"
            @on-delete-content="deleteContent"
        />
    </div>
</template>

<script>
import CompanyLanguage from '@/models/CompanyLanguage';
import CompanySurveyContent from '@/models/CompanySurveyContent';

import SurveyListExtraItem from '@/components/pages/resources/surveys/form/SurveyListExtraItem.vue';
import SurveyListQuestionItem from '@/components/pages/resources/surveys/form/SurveyListQuestionItem.vue';

export default {
    name: 'SurveyListContentItem',
    inject: ['$validator'],

    props: {
        value: {
            type: Object,
            required: true,
        },
        contents: {
            type: Array,
            required: true,
        },
        currentLanguage: {
            type: CompanyLanguage,
            required: true,
        },
        questionIndex: {
            type: Number,
            required: true,
        },
        isCompleted: {
            type: Boolean,
            required: false,
            default: false,
        },
        isConversational: {
            type: Boolean,
            required: true,
        },
        isRecurrent: {
            type: Boolean,
            required: true,
        },
        questions: {
            type: Array,
            required: true,
        },
    },

    shared: {
        session: {},
    },

    computed: {
        getSubtype() {
            switch (this.value.resource) {
            case CompanySurveyContent.RESOURCE_QUESTION:
                return this.value.question.subtype;
            case CompanySurveyContent.RESOURCE_EXTRA:
                return '';
            }
        },

        getComponent() {
            switch (this.value.resource) {
            case CompanySurveyContent.RESOURCE_QUESTION:
                return SurveyListQuestionItem;
            case CompanySurveyContent.RESOURCE_EXTRA:
                return SurveyListExtraItem;
            }
        },
    },

    methods: {
        deleteContent(index) {
            this.$emit('on-delete-content', index);
        },
    },
};
</script>

<style lang="scss" scoped src="./SurveyListContentItem.scss"></style>
