<template>
    <div>
        <form
            v-if="companyUser.isLoaded()"
            :key="renderKey"
            class="form-2"
        >
            <div class="row">
                <HtFormInput
                    :id="'firstname'"
                    v-model.trim="companyUser.firstname"
                    v-validate.disable="'required'"
                    :label="translate('First Name')"
                    :name="'firstname'"
                    :data-vv-as="translate('first name')"
                    class="col-md-6"
                />
                <HtFormInput
                    :id="'lastname'"
                    v-model.trim="companyUser.lastname"
                    v-validate.disable="'required'"
                    :label="translate('Last Name')"
                    :name="'lastname'"
                    :data-vv-as="translate('last name')"
                    class="col-md-6"
                />
            </div>

            <div class="row">
                <fieldset :class="[{ error: errors.has('phone_number') }, 'col-md-6']">
                    <label><t>Phone number</t></label>
                    <div class="row">
                        <HtFormMultiSelect
                            :id="'phoneFlag'"
                            v-model="companyUser.phone_country_code"
                            :options="countryCodeList"
                            :name="'phoneFlag'"
                            :track-by="'name'"
                            :is-single="true"
                            :specific-key="'dial_code'"
                            class="col-md-5"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                            >
                                <div class="d-flex align-items-center">
                                    <country-flag
                                        :country="option.code"
                                        size="small"
                                    />
                                </div>
                            </template>
                            <template
                                slot="option"
                                slot-scope="{ option }"
                            >
                                <div class="d-flex align-items-center">
                                    <country-flag
                                        :country="option.code"
                                        size="small"
                                    />
                                    <span class="ml-10">{{ option.dial_code }} ({{ option.name }})</span>
                                </div>
                            </template>
                        </HtFormMultiSelect>

                        <HtFormInput
                            :id="'phone_number'"
                            v-model.trim="companyUser.phone_number"
                            v-validate.disable="'numeric'"
                            :name="'phone_number'"
                            :data-vv-as="translate('phone number')"
                            :placeholder="'6********'"
                            class="col-md-7"
                        />
                    </div>
                </fieldset>

                <HtFormMaskedInput
                    :id="'id'"
                    v-model="dateOfBirth"
                    v-validate.disable="`date_format:${currentDateFormat}`"
                    :label="translate('Date of birth')"
                    :placeholder="currentDateFormat"
                    :name="'dob'"
                    :mask="[ /\d/, /\d/ , '/', /\d/, /\d/ , '/', /\d/, /\d/, /\d/, /\d/ ]"
                    :data-vv-as="translate('date of birth')"
                    class="col-md-6"
                />
            </div>

            <fieldset :class="{ error: errors.has('gender') }">
                <div class="title-button-container no-margin-bottom">
                    <div class="label-form title">
                        <t>Gender</t> *
                    </div>
                </div>

                <div class="card-1 flex-container to-yellow top_attached">
                    <div class="row-line">
                        <div class="col-md-12">
                            <GenderSelection :company-user="companyUser" />
                            <input
                                v-model="companyUser.gender"
                                type="hidden"
                                name="gender"
                            >
                        </div>
                    </div>
                </div>
                <div
                    v-for="(errorMessage, index) in errors.collect('gender')"
                    :key="index"
                    class="error-message"
                >
                    <span v-if="errorMessage"><t>{{ errorMessage }}</t></span>
                </div>
            </fieldset>

            <div class="row mb-20">
                <HtFormSelect
                    :id="'language'"
                    v-model="companyUser.company_language_id"
                    v-validate.disable="'required'"
                    :label="translate('Language')"
                    :name="'language'"
                    :data-vv-as="translate('language')"
                    :options="getCompanyLanguageEnabledOption"
                    class="col-md-6"
                />

                <HtFormSelect
                    v-if="getNationalityOption.length > 0"
                    :id="'nationality'"
                    v-model="companyUser.ht_nationality_id"
                    :label="translate('Nationality')"
                    :name="'nationality'"
                    class="col-md-6"
                    :options="getNationalityOption"
                />
            </div>

            <div class="row mb-20">
                <div class="col-md-6">
                    <fieldset>
                        <label><t>Timezone</t></label>
                        <timezone-selector
                            id="timezone"
                            v-validate="'required'"
                            :data-vv-as="translate('Timezone')"
                            :value.sync="companyUser.timezone"
                            name="timezone"
                            :error-messages="errors.collect('timezone')"
                        />
                    </fieldset>
                </div>
            </div>

            <fieldset>
                <label><t>Profile picture</t></label>

                <div class="photo-edit-wrapper">
                    <div class="photo-edit full-width">
                        <div class="logo-zone">
                            <Croppable
                                v-show="show_profile_croppable"
                                ref="croppable_profile"
                                style="display: flex;justify-content: center;"
                                :show-width="125"
                                :show-height="125"
                                :dest-width="250"
                                :dest-height="250"
                                :resize-multiple="[0.1]"
                                save-url="company_file"
                                :image="$Utils.resolveS3PublicImage(companyUser.image)"
                                @onChange="onProfileChange"
                                @onUploadEnd="onProfileSave"
                            />

                            <img
                                v-show="!show_profile_croppable"
                                :src="$Utils.resolveS3PublicImage(companyUser.image)"
                                alt=""
                            >
                        </div>
                        <a
                            class="button button-secondary"
                            style="display:inline-block; min-width: 50px;"
                            @click="$refs.croppable_profile.onOpenFileSelector(); show_profile_croppable=true;"
                        ><t>Change</t></a>
                    </div>
                </div>
            </fieldset>

            <div class="row mb-20">
                <div class="col-md-6">
                    <Button
                        class="negative"
                        @click="openBlank({name: 'TermsAndConditions', params: { lang: companyUser.company_language.key }})"
                    >
                        <t :heyteam_label="'Heyteam'">
                            Access to {heyteam_label}'s CGU
                        </t>
                    </Button>
                </div>
                <div class="col-md-6">
                    <Button
                        class="negative"
                        @click="openBlank({name: 'PrivacyPolicy', params: { lang: companyUser.company_language.key }})"
                    >
                        <t :heyteam_label="'Heyteam'">
                            Access to {heyteam_label}'s privacy policy
                        </t>
                    </Button>
                </div>
            </div>

            <fieldset>
                <Button
                    v-if="tos"
                    class="negative"
                    @click="dowloadTos"
                >
                    <t :company_name="shared.session.company.name">
                        Access to {company_name}'s Terms & Conditions
                    </t>
                </Button>
            </fieldset>

            <div class="row-line">
                <Button
                    :state="buttonState"
                    @click="onUpdate()"
                >
                    <t>Save</t>
                </Button>
            </div>
        </form>
        <IconLoading v-else />
    </div>
</template>
<script>
import Company from '@/models/Company';
import CompanyUser from '@/models/CompanyUser';
import CompanyDocumentPublic from '@/models/CompanyDocumentPublic';
import Croppable from '@/components/tools/Croppable.vue';
import CountryCodeList from '@/CountryCode';
import HtNationalityCollection from '@/models/HtNationalityCollection';
import GenderSelection from '@/components/globals/GenderSelection.vue';
import TimezoneSelector from '@/components/globals/timezone/TimezoneSelector.vue';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';

export default {
    name: 'AccountInfo',
    components: {
        Croppable,
        TimezoneSelector,
        GenderSelection,
    },

    shared: {
        session: {
            company: {
                type: Company,
                default: null,
            },
        },
        data: {
            htNationalityCollection: {
                type: HtNationalityCollection,
                default: null,
            },
        },
        queries: {
            htNationalityCollection: {
                type: HtNationalityCollection,
                default: null,
            },
        },
    },

    data() {
        return {
            countryCodeList: [],
            renderKey: 0,
            show_profile_croppable: false,
            avatarChange: false,
            companyUser: new CompanyUser([
                'id',
                'firstname',
                'lastname',
                'email',
                'date_of_birth',
                'gender',
                'ht_nationality_id',
                'company_language_id',
                'company_file_id',
                'image',
                'timezone',
                'phone_country_code',
                'phone_number',
            ]).with({
                companyFile: (query) => {
                    query.select([
                        'id',
                        'original_name',
                        'detected_extension',
                        'uuid',
                    ]);
                },
                companyLanguage: (query) => {
                    query.select(['id', 'key']);
                },
            }).where([
                ['id', '=', this.shared.session.companyUser.id],
            ]),
            companyDocumentPublic: new CompanyDocumentPublic([
                'id', 'name', 'is_tos', 'file_translations',
            ]).with({
                companyFiles: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
                companyFilesFromLocale: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
            }).where([
                ['is_tos', '=', true],
            ]),
        };
    },

    computed: {
        dateOfBirth: {
            get() {
                return this.$Utils.mysqlToDate(this.companyUser.date_of_birth, this.currentDateFormat);
            },

            set(value) {
                this.companyUser.date_of_birth = this.$Utils.dateToMysql(value, false, this.currentDateFormat);
            },
        },

        getCompanyLanguageEnabledOption() {
            return this.shared.session.company.$.company_languages.enabled.map((language) => ({
                name: (this.currentUserLang === 'fr') ? this.translate(language.name) : language.name,
                id: language.id,
            }));
        },

        getNationalityOption() {
            return this.shared.queries.htNationalityCollection.isLoaded() && this.shared.data.htNationalityCollection
                ? this.shared.data.htNationalityCollection.models.map((nationality) => ({
                    name: nationality[`name_${(this.currentUserLang === 'fr') ? 'fr' : 'en'}`],
                    id: nationality.id,
                })).sort((a, b) => a.name.localeCompare(b.name)) : [];
        },

        currentDateFormat() {
            return (this.currentUserLang === 'fr') ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
        },

        currentUserLang() {
            return this.shared.session.companyUser.isLoaded()
                ? this.shared.session.companyUser.company_language.key
                : 'en';
        },

        buttonState() {
            return this.companyUser.isSaving() ? 'loading' : 'idle';
        },
        defaultLanguageKey() {
            return this.shared.session.company.company_languages.models
                .find((language) => language.is_default).key;
        },
        tos() {
            if (this.companyDocumentPublic.company_files_from_locale.models?.length) {
                return this.companyDocumentPublic.company_files_from_locale.models?.[0];
            }
            return this.companyDocumentPublic.file_translations?.[this.defaultLanguageKey];
        },
    },

    async created() {
        if (this.shared.queries.htNationalityCollection.isLoaded() === false) {
            this.shared.queries.htNationalityCollection.get().then((data) => {
                this.shared.data.htNationalityCollection = data;
            });
        }

        this.countryCodeList = CountryCodeList.sort((a, b) => a.name.localeCompare(b.name));
        this.companyDocumentPublic.get();
        this.companyUser.setLookupMethod('lookupPersonal');
        this.companyUser.get().then(() => {
            this.renderKey = 1;
        });
    },

    methods: {
        openBlank(route) {
            const routeData = this.$router.resolve(route);
            window.open(routeData.href, '_blank');
        },

        onProfileChange() {
            this.avatarChange = true;
        },

        onProfileSave(uploadInfo, data) {
            this.companyUser.company_file = data;
            this.avatarChange = false;
            this.onUpdateInfo();
        },

        onUpdate() {
            if (this.avatarChange) {
                this.$refs.croppable_profile.onSave();
            } else {
                this.onUpdateInfo();
            }
        },

        onUpdateInfo() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.companyUser.save().then(() => {
                        this.shared.session.companyUser.fill(this.companyUser);
                        this.$I18n.getTranslationPack(this.companyUser.company_language.key).then(() => {
                            window.localStorage.setItem('language', this.companyUser.company_language.key);
                            this.renderKey++;
                        });
                    });
                }
            });
        },

        dowloadTos() {
            window.location.href = this.tos.link;
        },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	.inline-block {
		display: inline-flex;
		vertical-align: top;
	}

	form {
		max-width: 600px;
		margin: auto;

		.button {
			min-width: 275px;
		}
		fieldset {
			margin-bottom: 1em;
		}
	}

	.photo-edit {
		margin-right: 0;
    }

	.icon {
		position: absolute;
		top: 50%;
        left: 50%;
		transform: translate(-50%, -50%);
		border: none;
        width: 25px;
        height: 25px;
        transition: $transition;
        color: $black;
        cursor: pointer;
	}

	.has-error {
		input, textarea, select {
			border-color: $primary;

			+ label {
				color: $primary !important;
			}
		}
		label {
			color: $primary-darker !important;
		}
	}
</style>
