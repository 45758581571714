import CompanySurveyContent from '@/models/CompanySurveyContent';
import UnauthorizedError from '@/services/Errors/UnauthorizedError';

export default class AISurveyService {
    constructor(env, token) {
        this.env = env;
        this.token = token;
        this.order = 0;
    }

    createSurveyContent(question) {
        const translations = {
            text: question.translations ?? {},
        };

        return {
            order: this.order,
            next_content_order: null,
            resource: CompanySurveyContent.RESOURCE_QUESTION,
            question: {
                type: question.type,
                subtype: question.subtype ?? null,
                params: question.params ?? [],
                translations,
                is_mandatory: (question.is_mandatory === 'true' || question.is_mandatory === true) ?? false,
                resourceable_id: null,
                resourceable_type: null,
                answers: this.createSurveyAnswers(question.answers ?? []),
            },
        };
    }

    // eslint-disable-next-line class-methods-use-this
    createSurveyAnswers(answers) {
        let answerOrder = 1;
        return answers.map((answer) => ({
            order: answerOrder++,
            next_content_order: null,
            company_entity_value_id: null,
            iconPath: answer.iconPath,
            translations: {
                text: answer.translations,
            },
        }));
    }

    async ask(input, currentTone, maxAIQuestions, languages, callback) {
        const url = this.env.getCapiUrl('survey/ask');
        const data = {
            input,
            type: 'survey',
            tone: currentTone.value,
            max_questions: maxAIQuestions,
            languages,
        };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, options);

        if ([401, 403].includes(response.status)) {
            throw new UnauthorizedError();
        }

        return this.readStream(response.body, callback);
    }

    async readStream(stream, callback) {
        const reader = stream.getReader();
        let buffer = '';
        let inBrackets = 0;
        let lastProcessedIndex = 0;
        let isCompleteObject = false;
        const OPEN_BRACKET = '{';
        const CLOSE_BRACKET = '}';

        // eslint-disable-next-line no-constant-condition
        while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { done, value } = await reader.read();
            if (done) break;

            buffer += new TextDecoder().decode(value, { stream: true });

            for (let position = lastProcessedIndex; position < buffer.length; position++) {
                const char = buffer[position];

                if (char === OPEN_BRACKET) {
                    inBrackets += 1;
                } else if (char === CLOSE_BRACKET) {
                    inBrackets -= 1;

                    // si les accolades sont équilibrées, on a un objet JSON complet
                    if (inBrackets === 0) {
                        let jsonStr = '';
                        const firstBrace = buffer.indexOf(OPEN_BRACKET);
                        const lastBrace = position;

                        if (firstBrace !== -1 && lastBrace !== -1) {
                            jsonStr = buffer.substring(firstBrace, lastBrace + 1);
                        }

                        buffer = buffer.substring(position + 1);

                        let question = null;

                        try {
                            question = JSON.parse(jsonStr);
                        } catch (e) {
                            console.warn('Partial or malformed JSON, waiting for more data...', jsonStr);
                        }

                        if (question) {
                            const newContent = this.createSurveyContent(question);

                            this.order += 1;

                            isCompleteObject = true;

                            callback(newContent);
                        }
                    }
                }
            }

            if (isCompleteObject) {
                lastProcessedIndex = 0;
                isCompleteObject = false;
            } else {
                lastProcessedIndex = buffer.length;
            }
        }

        return buffer;
    }
}
