<template>
    <HtLayoutModal @close="$emit('close')">
        <template #header>
            <t>Start a program</t>
        </template>

        <template #main>
            <IconLoading v-if="!programsLoaded" />
            <form
                v-else
                class="form-2 spaced"
                @submit.prevent
            >
                <HtProgramList
                    ref="htProgramListInput"
                    v-model="selectedProgramId"
                    required
                    :disabled="!!programId"
                    :with-default="false"
                />

                <template v-if="program">
                    <HtFormFlatPickr
                        v-for="(keyDate, index) in keyDates"
                        :id="`${keyDate.id}`"
                        :key="keyDate.id"
                        v-model="keyDates[index].starts_at"
                        v-validate.disable="'required'"
                        :name="`key-date${index}`"
                        :label="keyDate.name"
                        :placeholder="keyDate.name"
                        :show-optional="false"
                        :show-asterisk="true"
                        :data-vv-as="keyDate.name"
                        :is-working-day="false"
                        :static="false"
                        :cypress="`${'keyDate'-index}`"
                        is-with-calendar-icon
                    />
                </template>
            </form>
        </template>

        <template #footer>
            <HtButton
                type="secondary"
                @click.native="$emit('close')"
            >
                <t>Close</t>
            </HtButton>

            <HtButton
                @click.native="validate"
            >
                <t>Continue</t>
            </HtButton>
        </template>
    </HtLayoutModal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import IconLoading from '@/components/icons/IconLoading.vue';
import HtProgramList from '@/components/globals/HtProgramList.vue';
import HtFormFlatPickr from '@/components/globals/HtFormFlatPickr.vue';
import HtButton from '@/components/globals/HtButton.vue';
import HtLayoutModal from '@/components/globals/modals/layouts/HtLayoutModal.vue';

export default {
    name: 'ProgramKeyDatesConfiguration',

    components: {
        IconLoading,
        HtLayoutModal,
        HtButton,
        HtFormFlatPickr,
        HtProgramList,
    },

    props: {
        programId: {
            type: Number,
            required: false,
            default: () => null,
        },
    },

    data() {
        return {
            selectedProgramId: this.programId,
            program: null,
            keyDates: [],
        };
    },

    created() {
        if (this.programId) {
            this.program = this.findProgramById(this.programId);
            this.setUpKeyDates();
        }
    },

    methods: {
        setUpKeyDates() {
            this.keyDates = [];

            this.program.key_dates.forEach((keyDate, index) => {
                this.$set(this.keyDates, index, { ...keyDate, starts_at: null });
            });
        },

        validate() {
            this.$validator
                .validateAll()
                .then((result) => {
                    if (!result) {
                        return;
                    }

                    this.$emit('next');
                });
        },
    },

    computed: {
        ...mapState('programs', [
            'programsLoaded',
        ]),

        ...mapGetters('programs', [
            'findProgramById',
        ]),
    },

    watch: {
        selectedProgramId() {
            this.$emit('on-update', 'program_id', this.selectedProgramId);
            this.program = this.findProgramById(this.selectedProgramId);
            this.setUpKeyDates();
        },

        keyDates: {
            deep: true,
            handler() {
                this.$emit('on-update', 'key_dates', this.keyDates);
            },
        },
    },
};
</script>
