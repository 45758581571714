<template>
    <div>
        <HtFormSwitch
            id="isBusinessDay"
            :value="isBusinessDay"
            :label="translate('Use working days')"
            name="isBusinessDay"
            :disabled="true"
        />

        <div>
            <div class="d-flex justify-content-between mb-4">
                <div class="label">
                    {{ translate('Availability date') }}
                </div>
                <span
                    v-tooltip.right="translate('Date on which the resource will be made available and visible in the program')"
                    class="ml-3 cursor-help"
                >
                    <FontAwesomeIcon icon="info-circle" />
                </span>
            </div>

            <div class="row input-dates-wrapper">
                <HtFormSelect
                    id="offsetUnit"
                    :value="'day_of'"
                    class="col"
                    name="offsetUnit"
                    :options="[{
                        id: 'day_of',
                        name: translate('Day of'),
                    }]"
                    :open-direction="'bottom'"
                    :data-vv-as="translate('date')"
                    :disabled="true"
                />

                <HtFormSelect
                    id="offsetKeyDate"
                    :value="'resourceDependency'"
                    class="col"
                    name="offsetKeyDate"
                    :options="[{
                        id: 'resourceDependency',
                        name: translate('After completing a task'),
                    }]"
                    :open-direction="'bottom'"
                    :data-vv-as="translate('date')"
                    :disabled="true"
                />
            </div>

            <HtUserTaskDependency
                :dependents="dependents"
                :dependencies="dependencies"
                :company-user-program-id="userProgramId"
                :resource-id="resourceId"
                :resource-type="resourceType"
                :disabled="true"
            />
        </div>

        <div class="mb-4" v-if="shouldShowAvailabilityDate">
            <div class="d-flex justify-content-between mb-4">
                <div class="label">
                    {{ translate('Completion date') }}
                </div>
                <span
                    v-tooltip.right="translate('The action created in the program has to be done by this date')"
                    class="ml-3 cursor-help"
                >
                    <FontAwesomeIcon icon="info-circle" />
                </span>
            </div>
            <div class="ml-1">
                {{
                    $Utils.moment(endDate).format(
                        (shared.session.companyUser.company_language.key === 'fr') ?
                            'DD/MM/YYYY' : 'MM/DD/YYYY'
                    )
                }}
            </div>
        </div>
    </div>
</template>

<script>
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import HtUserTaskDependency from '@/components/globals/HtUserTaskDependency.vue';
import HtFormSwitch from './HtFormSwitch.vue';

export default {
    name: 'HtKeyDatesInputOrderForDependency',

    components: {
        HtUserTaskDependency,
        HtFormSelect,
        HtFormSwitch,
    },

    props: {
        userProgramId: {
            type: Number,
            default: null,
        },
        isBusinessDay: {
            type: Boolean,
            required: true,
        },
        dependents: {
            type: [Object, Array],
            default: () => ([]),
        },
        dependencies: {
            type: [Object, Array],
            default: () => ([]),
        },
        resourceId: {
            type: Number,
            default: 0,
        },
        resourceType: {
            type: String,
            default: null,
        },
        endDate: {
            type: String,
            default: null,
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },

    computed: {
        offsetUnitOptions() {
            let options = [];

            options = options.concat([
                {
                    id: 'day_of',
                    name: this.translate('Day of'),
                },
            ]);

            return options;
        },
        keyDatesOptions() {
            return [{
                id: 'resourceDependency',
                name: this.translate('After completing a task'),
            }];
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.label {
    font-size: 1.4rem;
    font-family: "latobold", "lato", sans-serif;
}
</style>
