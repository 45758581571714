import Vue from 'vue';

export default {
    getFromProgram(programId) {
        return Vue.prototype.$http.get(`requirements/variables?programId=${programId}`);
    },

    getPreview(userRequirementId) {
        return Vue.prototype.$http.get(`requirements/variables/resolve?companyUserRequirementId=${userRequirementId}`);
    },

    verifyCompletion(userRequirementId) {
        return Vue.prototype.$http.get(`requirements/variables/verify-completion?companyUserRequirementId=${userRequirementId}`);
    },

    getPropertyValues(programId, languageKey) {
        return Vue.prototype.$http.get(`requirements/variables/values?programId=${programId}&languageKey=${languageKey}`);
    },
};
