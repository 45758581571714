<template>
    <CentralConfirmation
        :title="title"
        description=""
        :cancel-label="cancelLabel"
        :accept-label="acceptLabel"
        @accept="accept"
        @cancel="cancel"
    >
        <HtFormMultiSelect
            id="lang-locale"
            v-model="currentLang"
            :options="getLangOptions"
            specific-key="value"
            track-by="value"
            name="lang-locale"
            :is-single="true"
        />
        <HtFormTextarea
            id="textarea-locale"
            v-model="currentLocale[currentLang]"
            :disabled="!Boolean(currentLang)"
            name="textarea-locale"
            class="space-bottom"
        />
    </CentralConfirmation>
</template>
<script>
import CentralConfirmation from '@/components/globals/modals/layouts/CentralConfirmation.vue';

export default {
    name: 'LocaleItemModal',
    components: {
        CentralConfirmation,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        locale: {
            type: Object,
            required: true,
        },
        cancelLabel: {
            type: String,
            required: true,
        },
        acceptLabel: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            currentLang: 'fr',
            keyNotLanguages: ['id', 'created_at', 'key_md5', 'key', 'en', 'component_name', 'route', 'updated_at'],
            currentLocale: {
                fr: null,
            },
        };
    },

    computed: {
        getLangOptions() {
            return Object.keys(this.locale).filter((key) => !this.keyNotLanguages.includes(key)).map((key) => ({
                name: key,
                value: key,
            }));
        },
    },

    created() {
        this.currentLocale = { ...this.locale };
    },

    methods: {
        cancel() {
            this.$emit('close');
        },
        async accept() {
            await this.$I18n.saveTranslation(this.currentLocale);
            this.$emit('close', 'accept');
        },
    },
};
</script>
<style lang="scss" scoped>
    // otherwise we don't see all lang options
    .space-bottom {
        margin-bottom: 70px;
    }
</style>
