<template>
    <div>
        <div
            class="d-flex flex-column mb-5"
            style="gap: 1em;"
        >
            <div
                v-for="(card, index) in cardForms"
                :key="index"
                class="d-flex flex-column align-items-center justify-center card-item"
                @click="selectForm(card)"
            >
                <div class="card-item-title">
                    <FontAwesomeIcon
                        class="mr-3"
                        :icon="card.icon"
                    />
                    <span>{{ card.label }}</span>
                </div>
                <div class="card-item-description">
                    {{ card.description }}
                </div>
            </div>
        </div>
        <Modalable
            ref="reportContent"
            class="modalable-1 medium"
            :mode="2"
        >
            <BugReportContent
                :title="title"
                :default-status="defaultStatus"
                :default-folder="defaultFolder"
                :default-workflow="defaultWorkflow"
            />
        </Modalable>
    </div>
</template>

<script>
import get from 'lodash.get';
import ProductImprovement from './BugReport/ProductImprovement';
import CommonBug from './BugReport/CommonBug';
import ClientBug from './BugReport/ClientBug';
import BugReportContent from './BugReportContent.vue';

export default {
    name: 'BugReport',

    components: {
        BugReportContent,
    },

    inject: ['modal'],

    data() {
        return {
            cardForms: [
                {
                    isSelected: false,
                    label: 'Amélioration produit ?',
                    description: 'La carte sera directement envoyé au produit (dans amélioration produit / action à faire)',
                    icon: ['fad', 'paperclip'],
                    instance: new ProductImprovement('IEADI6F4I4TY7WSU', 'IEADI6F4JMBPFEJO'),
                },
                {
                    isSelected: false,
                    label: 'BUG (Prio client CSM)',
                    description: 'Il faudra ensuite ajouter le dossier client (une fois la finalisation du formulaire tu seras redirigé sur ta carte et tu pourras ajouter le client impacté)',
                    icon: ['fad', 'siren-on'],
                    instance: new ClientBug('IEADI6F4I4Q46UVY', 'IEADI6F4JMBPFBAE'),
                },
                {
                    isSelected: false,
                    label: 'Tu as trouvé un BUG ?',
                    description: 'La carte sera directement envoyé à la QA',
                    icon: ['fad', 'screwdriver'],
                    instance: new CommonBug('IEADI6F4I4Q3ZRGQ', 'IEADI6F4JMBPFA7G'),
                },
            ],
        };
    },

    computed: {
        currentBugReport() {
            return this.cardForms.find((card) => Boolean(card.isSelected));
        },

        title() {
            return get(this.currentBugReport, 'label', null);
        },

        defaultStatus() {
            const instance = get(this.currentBugReport, 'instance', null);

            if (instance) {
                return instance.status;
            }

            return '';
        },

        defaultFolder() {
            const instance = get(this.currentBugReport, 'instance', null);

            if (instance) {
                return instance.folder;
            }

            return '';
        },

        defaultWorkflow() {
            const instance = get(this.currentBugReport, 'instance', null);

            if (instance) {
                return instance.workflow;
            }

            return '';
        },
    },

    created() {
        this.modal.setTitle('Formulaire de demande Wrike');
    },

    methods: {
        selectForm(selectedCard) {
            const current = this.cardForms.find((card) => card.label === selectedCard.label);

            if (this.currentBugReport) {
                this.currentBugReport.isSelected = !this.currentBugReport.isSelected;
            }

            current.isSelected = !current.isSelected;
            this.$nextTick(() => {
                this.$refs.reportContent.open();
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/var";

.card-item {
    background: $grey-8;
    padding: 2em;
    flex: 3;
    gap: 1em;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: 0.3s all;

    &:hover {
        background: $grey-7;
        cursor: pointer;
    }

    &-title {
        color: $black-1;
        font-weight: bold;
        font-size: 14px;
    }

    &-description {
        color: $grey-3;
        font-size: 12px;
    }
}
</style>
