export default class Notifier {
    static instance = [];

    static register(name, instance) {
        Notifier.instance[name] = instance;
    }

    static getInstance(name) {
        return Notifier.instance[name];
    }

    static install(Vue) {
		 Vue.prototype.$Notifier = (name, instance = null) => {
		 	if (instance !== null) { return Notifier.register(name, instance); }

		 	return Notifier.getInstance(name);
		 };
    }
}
