<template>
    <div>
        <ResultYesNoItem
            v-bind="propsToPass(choices[0])"
            class="first-item"
        />
        <ResultYesNoItem v-bind="propsToPass(choices[1])" />
    </div>
</template>

<script>
import choiceResultsProps from '../choiceResultsProps';
import ResultYesNoItem from './ResultYesNoItem.vue';

export default {
    name: 'ResultsYesNo',
    components: { ResultYesNoItem },

    mixins: [
        choiceResultsProps,
    ],
};
</script>

<style lang="scss" scoped>
.first-item {
    margin-bottom: 2%;
}
</style>
