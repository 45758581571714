import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserNotification from './CompanyUserNotification';

export default class CompanyUserNotificationCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserNotification,
        };
    }
}
