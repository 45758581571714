<template>
    <div class="participant-list-wrapper">
        <HtChip
            v-for="(item, index) in getUsers"
            :key="`participant-chip-${index}`"
            :label="getTitle(item, true)"
        >
            <template #image>
                <div
                    v-user-image="getUserImage(item, 1, true)"
                    class="image role-only"
                    :class="getClass(item, true)"
                    :title="getTitle(item, true)"
                />
            </template>
        </HtChip>
    </div>
</template>

<script>
import ParticipantListHelper from '@/components/mixins/ParticipantListHelper.vue';
import HtChip from '@/components/globals/HtChip.vue';

export default {
    name: 'HtParticipantList',
    components: { HtChip },
    mixins: [
        ParticipantListHelper,
    ],

    props: {
        users: {
            type: Array,
            required: true,
        },

    },

    computed: {
        getUsers() {
            const dedupedParticipants = this.users
                .reduce((filteredArray, participant) => {
                    if (filteredArray.some((filteredParticipant) => participant.company_user_id && filteredParticipant.company_user_id === participant.company_user_id)) {
                        return filteredArray;
                    }

                    return [...filteredArray, participant];
                }, [])
                .map((item) => {
                    if (!item.company_user && item.user && typeof item.user === 'object') {
                        item.company_user = item.user;
                    }

                    if (!item.company_role && item.role && typeof item.role === 'object') {
                        item.company_role = item.role;
                    }

                    return item;
                });

            if (this.limit > 0) {
                return dedupedParticipants.slice(0, this.limit);
            }

            return dedupedParticipants;
        },
    },
};
</script>

<style lang="scss" scoped>
.participant-list-wrapper{
    display: flex;
    flex-wrap: wrap;
}

</style>
