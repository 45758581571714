<template>
    <HtTable
        :items="items"
        :columns="columns"
        cypress="action-resource-table"
        :empty-message="'There are no software to display yet...'"
        :external-search="externalSearch"
        @onCellClicked="$emit('onCellClicked', $event)"
    >
        <template #cell(name)="scope">
            <div class="d-flex align-items-center">
                <span>{{ scope.item.resource.name }}</span>
            </div>
        </template>
        <template #cell(filters)="scope">
            <ResourceFilter :resource-filters="scope.item.resource.programs.models[0].filters" />
        </template>
    </HtTable>
</template>
<script>
import HtTable from '@/components/globals/HtTable.vue';
import ParticipantList from '@/components/globals/ParticipantList.vue';
import ResourceListMixin from '@/mixins/ResourceListMixin';
import ResourceFilter from '@/components/resources/ResourceFilter.vue';

export default {
    name: 'SoftwareListModels',
    components: { HtTable, ParticipantList, ResourceFilter },
    mixins: [
        ResourceListMixin,
    ],
    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    search: true,
                },
                {
                    label: this.translate('Filters'),
                    key: 'filters',
                    sortable: false,
                    class: 'd-none d-md-table-cell',
                    search: false,
                },
                {
                    label: this.translate('Program'),
                    key: 'resource.programs.models[0].program.name_translated',
                    sortable: false,
                    class: 'hide-mobile',
                    search: false,
                },
            ],
        };
    },
};

export const softwareConfig = {
    fields: [
        'id',
        'ht_software_type_id',
    ],
    relations: {
        resource: (query) => query
            .select([
                'name',
            ])
            .with({
                programs: (query) => query
                    .select([
                        'id',
                        'order_end',
                    ])
                    .with({
                        program: (query) => query
                            .select(['id'])
                            .with({
                                locales: (query) => {
                                    query.select([
                                        'id',
                                        'name',
                                        'language_key',
                                    ]);
                                },
                                keyDate: (query) => {
                                    query
                                        .select([
                                            'id',
                                        ])
                                        .with({
                                            locales: (query) => {
                                                query.select([
                                                    'id',
                                                    'name',
                                                    'language_key',
                                                ]);
                                            },
                                        });
                                },
                            }),
                    }),
            }),
    },
};

</script>
