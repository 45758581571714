import LayoutResources from '@/layouts/Resources.vue';
import Actions from '@/router/pages/resources/Actions.vue';
import Documents from '@/router/pages/resources/Documents.vue';
import Equipments from '@/router/pages/resources/Equipments.vue';
import Emails from '@/router/pages/resources/Emails.vue';
import Events from '@/router/pages/resources/Events.vue';
import Nudges from '@/router/pages/resources/Nudges.vue';
import Quizzes from '@/router/pages/resources/Quizzes.vue';
import Requirements from '@/router/pages/resources/Requirements.vue';
import Software from '@/router/pages/resources/Software.vue';
import Surveys from '@/router/pages/resources/Surveys.vue';
import Trainings from '@/router/pages/resources/Trainings.vue';
import EventsForm from '@/router/pages/resources/events/PageEventForm.vue';
import PageSurveyForm from '@/router/pages/resources/surveys/PageSurveyForm.vue';
import PageRequirementForm from '@/router/pages/resources/requirements/PageRequirementForm.vue';

export default [
    {
        path: '/Resources/',
        component: LayoutResources,
        name: 'Resources',
        meta: {
            permission: true,
        },
        children: [
            {
                path: 'Actions',
                name: 'ResourcesActions',
                component: Actions,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Documents',
                name: 'ResourcesDocuments',
                component: Documents,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Equipments',
                name: 'ResourcesEquipments',
                component: Equipments,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Emails',
                name: 'ResourcesEmails',
                component: Emails,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Events',
                name: 'ResourcesEvents',
                component: Events,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Nudges',
                name: 'ResourcesNudges',
                component: Nudges,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Quizzes',
                name: 'ResourcesQuizzes',
                component: Quizzes,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Requirements',
                name: 'ResourcesRequirements',
                component: Requirements,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Software',
                name: 'ResourcesSoftware',
                component: Software,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Surveys',
                name: 'ResourcesSurveys',
                component: Surveys,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Trainings',
                name: 'ResourcesTrainings',
                component: Trainings,
                meta: {
                    permission: true,
                },
            },
        ],
    },
    {
        path: 'Resources/Events/Form/:id?',
        name: 'ResourcesEventsForm',
        component: EventsForm,
        meta: {
            permission: true,
        },
    },
    {
        path: 'Resources/Requirements/Form/:id?',
        name: 'ResourcesRequirementsForm',
        component: PageRequirementForm,
        props: (route) => ({ id: Number(route.params.id) }),
        meta: {
            permission: true,
        },
    },
    {
        path: 'Resources/Surveys/Form/:id?',
        name: 'ResourcesSurveysForm',
        component: PageSurveyForm,
        meta: {
            permission: true,
        },
    },
];
