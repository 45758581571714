<template>
    <div
        id="main-container"
        class="generate-onboarding centered_content"
    >
        <HeadBarLight />

        <div>
            <h1 class="align-center">
                {{ getCompanyUserFirstname }} {{ getCompanyUserLastname }}
            </h1>

            <ul
                id="main_menu"
                class="main-menu"
            >
                <li @click="changePage(1)">
                    <a :class="{'router-link-exact-active active': page == 1}"><t>Personal info</t></a>
                </li>
                <li
                    v-if="hasSso === false"
                    @click="changePage(2)"
                >
                    <a :class="{'router-link-exact-active active': page == 2}"><t>Password</t></a>
                </li>
                <li @click="changePage(3)">
                    <a :class="{'router-link-exact-active active': page == 3}"><t>System notifications</t></a>
                </li>
                <li @click="changePage(4)">
                    <a :class="{'router-link-exact-active active': page == 4}"><t>Resource notifications</t></a>
                </li>
            </ul>

            <div class="form-2 wrapper-select-mobile">
                <HtFormSelect
                    :id="'menu-account'"
                    v-model="page"
                    :name="'menu-account'"
                    :options="pages"
                />
            </div>

            <div id="main-page">
                <component
                    :is="currentPage.component"
                    v-if="currentPage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Company from '@/models/Company';
import UserNotificationList from '@/components/resources/notification/UserNotificationList.vue';
import HeadBarLight from '@/components/miscellaneous/HeadBarLight.vue';
import AccountInfo from '@/components/pages/account/AccountInfo.vue';
import AccountPassword from '@/components/pages/account/AccountPassword.vue';
import AccountNotification from '@/components/pages/account/AccountNotification.vue';
import AccountNotificationResource from '@/components/pages/account/AccountNotificationResource.vue';

export default {
    name: 'Account',
    components: {
        UserNotificationList,
        HeadBarLight,
        AccountInfo,
        AccountPassword,
        AccountNotification,
        AccountNotificationResource,
    },

    shared: {
        session: {
            company: {
                type: Company,
                default: null,
            },
        },
    },

    data() {
        return {
            pages: [
                { component: 'AccountInfo', name: this.translate('Personal info'), id: 1 },
                { component: 'AccountPassword', name: this.translate('Password'), id: 2 },
                { component: 'AccountNotification', name: this.translate('System notifications'), id: 3 },
                { component: 'AccountNotificationResource', name: this.translate('Resource notifications'), id: 4 },
            ],
            page: 1,
        };
    },

    computed: {
        currentPage() {
            return this.pages.find((page) => this.page === page.id);
        },

        getCompanyUserFirstname() {
            return this.shared.session.companyUser ? this.shared.session.companyUser.firstname : null;
        },

        getCompanyUserLastname() {
            return this.shared.session.companyUser ? this.shared.session.companyUser.lastname : null;
        },

        hasSso() {
            return this.shared.session.company && this.shared.session.company.hasSso === true
                && this.shared.session.companyUser
                && this.shared.session.companyUser.main_email === 'professional_email'
                && this.shared.session.companyUser.has_sso === true;
        },
    },

    watch: {
        page(val) {
            this.changePage(val);
        },
    },

    methods: {
        changePage(page) {
            this.page = page;
        },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	h1 {
		margin-bottom: 1em;
	}

	a {
		cursor: pointer;
	}

	.wrapper-select-mobile {
		display: none;
		padding: 0 25px;
	}

	@media (max-width: $tablet) {
		.main-menu {
			display: none!important;
		}
		.wrapper-select-mobile {
			display: block;
		}
	}
</style>
