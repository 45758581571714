import LayoutFlow from '@/layouts/Flow.vue';
import FlowHello from '@/components/flow/FlowHello.vue';
import FlowSign from '@/components/flow/FlowSign.vue';
import FlowCompany from '@/components/flow/FlowCompany.vue';
import FlowTeamPackage from '@/components/flow/FlowTeamPackage.vue';
import FlowProfile from '@/components/flow/FlowProfile.vue';
import FlowRequirement from '@/components/flow/FlowRequirement.vue';

export default [
    {
        path: '/Flow/',
        name: 'Flow',
        component: LayoutFlow,
        meta: {
            permission: true,
        },
        children: [
            {
                path: 'Hello',
                name: 'FlowHello',
                component: FlowHello,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Sign',
                name: 'FlowSign',
                component: FlowSign,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Company',
                name: 'FlowCompany',
                component: FlowCompany,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'TeamPackage',
                name: 'FlowTeamPackage',
                component: FlowTeamPackage,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Profile',
                name: 'FlowProfile',
                component: FlowProfile,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Requirement',
                name: 'FlowRequirement',
                component: FlowRequirement,
                meta: {
                    permission: true,
                },
            },
        ],
    },
];
