<template>
    <div v-if="companyUserDoc.isLoaded()">
        <form
            class="form-2 spaced has-padding"
            data-cy="document-edit-form"
            @submit.prevent=""
        >
            <InputField
                v-model="companyUserDoc.name"
                :label="translate('Document name')"
                type="text"
                name="name"
                cypress="name"
                :validate="'required'"
                :validate-as="translate('name')"
                :trim="true"
            />

            <InputField
                v-model.trim="companyUserDoc.specific_information"
                :label="translate('Specific information for {firstname}', { firstname: companyUserDoc.company_user.firstname })"
                type="textarea"
                name="info"
                cypress="info"
            />

            <fieldset :class="{ error: errors.has('original_name') }">
                <label>
                    <t>Upload file</t>
                    <span class="sub-text">(<t>Up to 20 MB</t>)</span></label>

                <UploadBar
                    v-model="companyUserDoc.company_file"
                    :media="['office', 'pdf', 'image']"
                    cypress="edit-document"
                    @onUploadProgress="button_state = 'loading'"
                    @onUploadEnd="button_state = 'idle'"
                />

                <input
                    v-validate.disable="'required'"
                    type="hidden"
                    name="original_name"
                    data-cy="original-name"
                    :value="companyUserDoc.company_file && companyUserDoc.company_file.original_name"
                    :data-vv-as="translate('file')"
                >
            </fieldset>

            <HtUserProgramList
                v-model="companyUserDoc.company_user_program_id"
                :company-user-id="companyUserId"
                :disabled="true"
            />

            <HtKeyDatesInputOrderForDependency
                v-if="hasDependency"
                :user-program-id="companyUserDoc.company_user_program_id"
                :resource-id="companyUserDoc.id"
                resource-type="company_doc"
                :is-business-day="userTask.is_business_day"
                :end-date="userTask.datetime_end"
                :dependents="userTask.dependents.models"
                :dependencies="userTask.dependencies.models"
                :should-show-availability-date="!userTask.dependents.models.length"
            />

            <HtKeyDatesInputOrder
                v-else-if="userTask"
                :end-label="translate('Expected reading date')"
                resource-type="company_doc"
                :resource-id="companyUserDoc.id"
                :user-program-id="companyUserDoc.company_user_program_id"
                :is-business-day.sync="userTask.is_business_day"
                :offset-key-date.sync="userTask.company_user_program_key_date_id"
                :offset-availability-specific-date.sync="userTask.datetime_availability"
                :offset-availability-number.sync="userTask.offset_availability_number"
                :offset-availability-unit.sync="userTask.offset_availability_unit"
                :offset-end-specific-date.sync="userTask.datetime_end"
                :offset-end-number.sync="userTask.offset_end_number"
                :offset-end-unit.sync="userTask.offset_end_unit"
                can-select-specific-date
                :dependents="userTask.dependents.models"
                :dependencies="userTask.dependencies.models"
                :disabled="userTask && userTask.dependencies.models.length > 0"
                @offset-key-date="setUserTaskKeyDateId"
            />

            <div class="modal-actions right">
                <Button
                    class="negative"
                    cypress="document-delete-button"
                    @click="onDelete()"
                >
                    <t>Delete</t>
                </Button>
                <Button
                    :state="button_state"
                    cypress="document-save-button"
                    @click="onUpdate()"
                >
                    {{ labelButton }}
                </Button>
            </div>
        </form>
    </div>
</template>
<script>
import HtUserProgramList from '@/components/globals/HtUserProgramList.vue';
import CompanyUserDoc from '@/models/CompanyUserDoc';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import HtKeyDatesInputOrderForDependency
    from '@/components/globals/HtKeyDatesInputOrderForDependency.vue';

export default {
    name: 'UserDocEdit',
    components: {
        HtKeyDatesInputOrderForDependency,
        HtUserProgramList,
        HtKeyDatesInputOrder,
    },
    inject: ['modal'],

    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },

    data() {
        return {
            hasDependency: false,
            userTask: null,
            companyUserDoc: null,
            button_state: 'idle',
        };
    },

    computed: {
        labelTitle() {
            return this.translate(this.companyUserDoc.isNew() ? 'Add document' : 'Edit document');
        },

        labelButton() {
            return this.translate(this.companyUserDoc.isNew() ? 'Add' : 'Save');
        },
    },

    watch: {
        'companyUserDoc._state.isSaving': function (val) {
            this.button_state = val ? 'loading' : 'idle';
        },
    },

    created() {
        this.companyUserDoc = new CompanyUserDoc([
            'id',
            'company_user_program_id',
            'company_user_id',
            'name',
            'specific_information',
            'company_file_id',
        ]).with({
            companyUser: (query) => {
                query.select(['id', 'firstname']);
            },
            companyUserProgramTask: (query) => {
                query.select([
                    'id',
                    'resource_id',
                    'resource',
                    'is_business_day',
                    'company_user_program_key_date_id',
                    'offset_availability_number',
                    'offset_availability_unit',
                    'offset_end_number',
                    'offset_end_unit',
                    'datetime_availability',
                    'datetime_end',
                ]).with({
                    dependencies: (query) => {
                        query.select([
                            'id',
                            'resource',
                        ]);
                    },
                    dependents: (query) => {
                        query.select([
                            'id',
                        ]);
                    },
                });
            },
            companyUserProgram: (query) => {
                query.select(['id', 'company_program_id'])
                    .with({
                        mainKeyDate: (query) => {
                            query.select(['id', 'starts_at']);
                        },
                        companyProgram: (query) => query.select(['id']).with({
                            keyDate: (query) => query.with({
                                locales: (query) => query.select(['id', 'name', 'language_key']),
                            }),
                        }),
                    });
            },
            companyFile: (query) => {
                query.select(UtilsCompanyFile.allowedFullFields());
            },
        }).where([
            ['id', '=', this.id],
        ]);

        this.companyUserDoc.company_user_id = this.companyUserId;
        this.companyUserDoc.get()
            .then(() => {
                this.userTask = this.companyUserDoc.company_user_program_task;
                this.modal.setTitle(this.labelTitle);
                this.hasDependency = this.userTask.dependents.models.length > 0;
            });
    },

    methods: {
        onDelete() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this document? Once you click on delete, you will no longer be able to access this document.') }).then(
                (response) => {
                    if (response === true) {
                        this.companyUserDoc.delete().then(
                            () => {
                                this.modal.close();
                                this.$emit('onDelete');
                            },
                        );
                    }
                },
            );
        },
        onUpdate() {
            this.$validator.validateAll().then(
                (result) => {
                    if (result === true) {
                        this.companyUserDoc.save().then(
                            () => {
                                this.modal.close();
                                this.$emit('onUpdate');
                            },
                        );
                    }
                },
            );
        },
        setUserTaskKeyDateId(value) {
            if (value) {
                this.userTask.company_user_program_key_date_id = value;
                return;
            }
            this.userTask.company_user_program_key_date_id = this.companyUserDoc.companyUserProgram.mainKeyDate.id;
        },
    },
};
</script>
