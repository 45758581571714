<template>
    <UtilsTemplate
        :title="programs[currentProgramIndex].name"
        :subtitle="getDescription"
        @click.native="$router.push({
            name: 'ProfileProgramDetail',
            params: {
                company_user_id: shared.session.companyUser.id,
                company_user_program_id: programs[currentProgramIndex].id,
            },
        })"
    >
        <template #header>
            <div class="test">
                <div
                    class="progress-container"
                >
                    <div
                        v-for="(program, index) in programs"
                        :ref="`program-percent-${index}`"
                        :key="program.id"
                        @mouseover="setActiveProgram(index)"
                    >
                        <ProgressTemplate
                            :percent="program.enrollee_completion"
                            :is-active="currentProgramIndex === index"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template
            v-if="programs.length > 1"
            #title-extra
        >
            <div class="prev-next">
                <div
                    class="prev-next-button"
                    @click.stop="changeActiveProgram(false)"
                >
                    <font-awesome-icon
                        :icon="['fal', 'chevron-left']"
                        class="prev-next-button-icon"
                    />
                </div>
                <div
                    class="prev-next-button"
                    @click.stop="changeActiveProgram(true)"
                >
                    <font-awesome-icon
                        :icon="['fal', 'chevron-right']"
                        class="prev-next-button-icon"
                    />
                </div>
            </div>
        </template>
        <template #footer>
            <div>
                <HtButton
                    type="tertiary"
                    size="small"
                    icon="arrow-right"
                >
                    <t>Consult my program</t>
                </HtButton>
            </div>
        </template>
    </UtilsTemplate>
</template>

<script>
import Utils from '@/Utils';
import HtButton from '@/components/globals/HtButton.vue';
import UtilsTemplate from '../templates/UtilsTemplate.vue';
import ProgressTemplate from '../templates/ProgressTemplate.vue';

export default {
    name: 'CompletionProgramWidget',

    components: {
        UtilsTemplate,
        HtButton,
        ProgressTemplate,
    },

    data() {
        return {
            currentProgramIndex: 0,
        };
    },

    computed: {
        programs() {
            return this.$store.getters['dashboard/actions/activeProgramsSummary'].sort((a, b) => new Date(a.starts_at) - new Date(b.starts_at));
        },
        getProgramType() {
            return Utils.capitalize(this.programs[this.currentProgramIndex].program_type_slug);
        },
        getTasksRatio() {
            return this.programs[this.currentProgramIndex].enrollee_completion;
        },
        programCompleted() {
            return this.getTasksRatio === 100;
        },
        getDescription() {
            if (!this.programCompleted) {
                return this.translate('Current phase : {phase}', {
                    phase: this.getPhase,
                });
            }
            return this.translate('Program completed and finalized.');
        },
        getPhase() {
            const diffDays = this.$Utils.moment().diff(this.$Utils.moment(this.programs[this.currentProgramIndex].starts_at), 'days');

            if (diffDays < 0) {
                const keyDates = this.$store.getters['config/getKeyDatesBySlug'];
                const keyDateName = this.translate(
                    keyDates[this.programs[this.currentProgramIndex].program_key_date_slug]?.name,
                );
                return this.translate('before {key_date_name}', { key_date_name: keyDateName.toLowerCase() });
            }
            if (diffDays === 0) return this.translate('1st day');
            if (diffDays > 0 && diffDays <= 7) return this.translate('1st week');
            if (diffDays > 7 && diffDays <= 30) return this.translate('1st month');
            if (diffDays > 30) return this.translate('after 1st month');
            return '';
        },
    },

    created() {
        const activePrograms = this.$store.getters['dashboard/actions/activeProgramsSummary'].sort((a, b) => new Date(a.starts_at) - new Date(b.starts_at));

        if (activePrograms.length > 0) {
            const momentDate = this.$Utils.moment(this.$Utils.moment().format('YYYY-MM-DD'));
            const futurePrograms = activePrograms.filter((program) => momentDate.isSameOrBefore(program.starts_at));

            if (futurePrograms.length > 0) {
                const firstFuture = futurePrograms[0];
                this.currentProgramIndex = activePrograms.findIndex((program) => program.id === firstFuture.id);
            }
        }
    },
    methods: {
        changeActiveProgram(isNext) {
            if (isNext && this.currentProgramIndex < this.programs.length - 1) {
                this.currentProgramIndex += 1;
            } else if (!isNext && this.currentProgramIndex > 0) {
                this.currentProgramIndex -= 1;
            } else if (!isNext && this.currentProgramIndex === 0) {
                this.currentProgramIndex = this.programs.length - 1;
            } else {
                this.currentProgramIndex = 0;
            }

            const element = this.$refs[`program-percent-${this.currentProgramIndex}`];
            if (element && element[0]) {
                element[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
        },

        setActiveProgram(index) {
            this.currentProgramIndex = index;
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.prev-next {
    display: flex;
    gap: 2px;
    left: 0px;
    position: relative;

    &-button {
        border: 1px solid $grey-1;
        border-radius: 4px;
        width: 24px;
        height: 24px;
        display:flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;

        &-icon {
            color: $black-2;
            font-size: 10px;
        }
    }
}

.progress-container {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 4px;
    scroll-behavior: smooth;
}
</style>
