<template>
    <div class="main-container">
        <iframe
            class="iframe"
            :src="getExternalUrl"
        />
    </div>
</template>

<script>

export default {
    name: 'External',

    computed: {
        getExternalUrl() {
            // soon, you'll need to retrieve the url from the database
            return 'https://www.finelive.fr/une';
        },
    },
};
</script>

<style lang="scss" scoped>

.main-container {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;

    .iframe {
        overflow: hidden;
        height: 100%;
        width: 100%;
    }
}

</style>
