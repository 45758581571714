<template>
    <div class="main-container">
        <div class="title">
            <HtIconClose @click="onCancel" />
        </div>
        <div class="content">
            <p>
                <t>You are about to delete the requirement</t> <span><strong>{{ companyRequirement.translations.name[editingLang] }}</strong></span> ! <br><br>
            </p>
            <div
                v-if="companyRequirement.id"
            >
                <HtFormSwitch
                    :id="'toggle-hard-delete'"
                    v-model="isHardDelete"
                    :name="'toggle-hard-delete'"
                    :label="translate('Remove this requirement from all users who own it')"
                    :on-label="translate('Yes')"
                    :off-label="translate('No')"
                    :show-optional="false"
                />
            </div>
        </div>
        <div class="actions">
            <Button
                class="active"
                @click="onConfirm"
            >
                <t>Confirm</t>
            </Button>
        </div>
    </div>
</template>

<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import CompanyRequirement from '@/models/CompanyRequirement';
import HtIconClose from '@/components/icons/HtIconClose.vue';

export default {
    name: 'SingleRequirementDelete',
    components: {
        HtFormSwitch,
        HtIconClose,
    },
    props: {
        companyRequirement: { type: CompanyRequirement, required: true },
        editingLang: { type: String, required: true },
    },

    data() {
        return {
            /**
                 * Cette suppression du requirement va aussi supprimer tous les userRequirements associés
                 */
            isHardDelete: false,
        };
    },

    methods: {
        onCancel() {
            this.$emit('close', { confirm: false });
        },
        onConfirm() {
            const hardDeletedRequirementId = this.isHardDelete === true ? this.companyRequirement.id : null;

            this.$emit('close', { confirm: true, hardDeletedRequirementId });
        },
    },
};
</script>

<style lang="scss" scoped>
.main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-radius: .3rem;

    .title {
        padding: 8px;
        display: flex;
        justify-content: end;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px;
        border-top: .5px gray;
        border-bottom: .5px gray;
    }

    .actions {
        display: flex;
        padding: 8px;
        justify-content: center;
    }
}
</style>
