import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyProgramNotification from './CompanyProgramNotification';

export default class CompanyProgramNotificationCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyProgramNotification,
        };
    }
}
