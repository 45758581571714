import Model from '@tony.caron/node-model-proxy/Model';
import CompanyProgramNotificationRecipientCollection from './CompanyProgramNotificationRecipientCollection';
import CompanyRole from './CompanyRole';
import CompanyUser from './CompanyUser';
import CompanyGroup from './CompanyGroup';

export default class CompanyProgramNotificationRecipient extends Model {
    modelConfig() {
        return {
            collection: CompanyProgramNotificationRecipientCollection,
        };
    }

    modelRelations() {
        return {
            companyRole: () => this.belongsTo(CompanyRole),
            companyUser: () => this.belongsTo(CompanyUser),
            companyGroup: () => this.belongsTo(CompanyGroup),
        };
    }
}
