<template>
    <div v-if="isLoaded">
        <HtCard class="mb-5">
            <template #title>
                <HtCardTitleBar
                    :title="title"
                    :show-close="true"
                    :show-button="true"
                    :button-text="translate('Create')"
                    @onAdd="onCreate"
                    @search="setSearchValue"
                    @on-close="$emit('on-change-step', 'SelectResourceTypeModal')"
                />
            </template>
            <template #default>
                <ActionsList
                    :items="resourceCollection.$.models"
                    :external-search="search"
                    @onCellClicked="onCreate"
                />
            </template>
        </HtCard>
    </div>
</template>

<script>
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import ProgramBuilderListResourceMixin from '@/mixins/ProgramBuilderListResourceMixin';
import ActionsList, { actionConfig } from '@/resourcesList/ActionsList.vue';
import HtProgramType from '@/models/HtProgramType';

export default {
    name: 'Action',
    components: {
        HtCardTitleBar,
        ActionsList,
    },

    mixins: [
        ProgramBuilderListResourceMixin,
    ],

    data() {
        return {
            resourceTitleSingular: 'action',
            resourceTitlePlural: 'actions',
            collectionName: 'CompanyProgramTaskCollection',
            itemFields: actionConfig.fields,
            itemRelations: actionConfig.relations,
        };
    },

    methods: {
        AddCollectionWhereHas() {
            this.resourceCollection.whereHas({
                resource: (query) => {
                    query.whereHas({
                        programs: (query) => {
                            query.where([
                                ['company_program_id', '!=', this.companyProgramId],
                            ]).whereHas({
                                program: (query) => {
                                    query.whereHas({
                                        htProgramType: (query) => {
                                            query.where([
                                                ['slug', '!=', HtProgramType.SLUG_DEFAULT],
                                            ]);
                                        },
                                    });
                                },
                            });
                        },
                    });
                },
            });
        },
    },
};
</script>
