<template>
    <div>
        <template v-if="showValidationActions">
            <Button
                :state="buttonStates"
                class="button-validate"
                cypress="validate-button"
                @click="onClickValidate()"
            >
                <t>Validate</t>
            </Button>
            <Button
                :state="buttonStates"
                class="button-refusal"
                cypress="refusal-button"
                @click="onClickRefuse()"
            >
                <t>Refuse</t>
            </Button>
        </template>
        <template v-else>
            <Button
                v-if="showSave"
                :state="disableSave ?
                    'modal-button-disabled':
                    buttonStates"
                cypress="save-button"
                @click="onClickSave()"
            >
                <t>Save</t>
            </Button>
            <Button
                v-if="showNotConcerned"
                :state="buttonStates"
                class="negative"
                cypress="not-concerned-button"
                @click="onClickNotConcerned()"
            >
                <t>Not concerned</t>
            </Button>
        </template>
        <Button
            v-if="showDelete"
            :state="buttonStates"
            class="negative button-delete"
            cypress="delete-button"
            @click="onClickDelete()"
        >
            <t>Delete</t>
        </Button>
    </div>
</template>

<script>
import Button from '@/components/globals/Button.vue';

export default {
    name: 'ModalFooter',
    components: { Button },
    props: {
        disableSave: {
            type: Boolean,
            default: false,
        },
        showSave: {
            type: Boolean,
            required: true,
        },
        showDelete: {
            type: Boolean,
            required: true,
        },
        showValidationActions: {
            type: Boolean,
            required: true,
        },
        showNotConcerned: {
            type: Boolean,
            required: true,
        },
        buttonStates: {
            type: String,
            required: true,
        },
    },

    methods: {
        onClickValidate() {
            this.$emit('on-click-validate');
        },
        onClickRefuse() {
            this.$emit('on-click-refuse');
        },
        onClickSave() {
            this.$emit('on-click-save');
        },
        onClickNotConcerned() {
            this.$emit('on-click-not-concerned');
        },
        onClickDelete() {
            this.$emit('on-click-delete');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.button {
    width: 50%;
    margin-top: 1rem;
    padding: 1.5rem 4rem;
    &-delete {
        color: $primary-darker !important;
    }
    &-validate {
        background: $valid !important;
    }
    &-refusal {
        background: $error !important;
    }
}

.modal-button-disabled {
    opacity: .5;
    &:hover {
        transform: none !important;
        cursor: default;
    }
}
</style>
