<template>
    <div class="wrapper-team">
        <HtH3>
            <t>Members of the team</t>
        </HtH3>
        <div class="wrapper-team-members">
            <div
                v-for="(supervisor, index) in supervisors"
                :key="index"
                class="team-member"
            >
                <div class="badge">
                    <div
                        v-user-image="supervisor.user"
                        class="badge__image"
                    />
                    <div class="badge__body">
                        <div class="badge__body-title">
                            {{ supervisor.user.firstname }} {{ supervisor.user.lastname }}
                        </div>
                        <div class="badge__body-description">
                            {{ supervisor.role.is_heyteam ? translate(supervisor.role.alias) : supervisor.role.alias }}
                        </div>
                    </div>
                    <div
                        class="badge__chat"
                        @click="onDiscuss(supervisor.user.id)"
                    >
                        <FontAwesomeIcon :icon="['far', 'comment-alt']" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HtH3 from '@/components/design-system/typography/header/HtH3.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'MyTeamWidget',
    components: {
        HtH3,
    },
    computed: {
        ...mapGetters({
            supervisors: 'dashboard/actions/getMyTeam',
        }),
    },
    methods: {
        onDiscuss(userId) {
            const oldChannel = this.shared.socket.channelCollection.models.find((channel) => channel.type === 'duo' && channel.company_chat_channel_user.models.some((channelUser) => channelUser.company_user_id === userId));

            if (oldChannel) {
                this.shared.socket.channelCollection.setActive(oldChannel.id);
                this.shared.socket.chatOpened = true;
                return;
            }

            const channel = this.shared.socket.channelCollection.new();

            const channelUser1 = channel.company_chat_channel_user.new();
            channelUser1.company_user_id = userId;

            const channelUser2 = channel.company_chat_channel_user.new();
            channelUser2.company_user_id = this.shared.session.companyUser.id;

            channel.company_chat_channel_user.models.push(channelUser1);
            channel.company_chat_channel_user.models.push(channelUser2);
            channel.created_by_company_user_id = this.shared.session.companyUser.id;

            channel.save().then((data) => {
                const interlocutor = data.company_chat_channel_user.channelUserInterlocutor;
                interlocutor.company_user.isConnected = interlocutor.company_user_id in this.shared.socket.connectedUsers;
                this.shared.socket.channelCollection.setActive(data.id);
                this.shared.socket.chatOpened = true;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.badge {
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 5px;
    background-color: #FFFFFF;
    margin-bottom: 8px;
}
.badge__image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
}
.badge__body {
    font-size: 13px;
    flex-grow: 1;
}
.badge__body-title {
    color: #1B1B1C;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 14px;
}
.badge__body-description {
    color: #ABADAE;
    line-height: 17px;
}
.badge__chat {
    color: #FFFFFF;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background-color: var(--branding-color);
    text-align: center;
    line-height: 24px;
    font-size: 14px;
}
</style>
