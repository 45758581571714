<template>
    <div class="question-list">
        <SurveyListContentItem
            v-for="(item, index) in contents"
            :key="item.uniqueID"
            v-model="contents[index]"
            :current-language="currentLanguage"
            :contents="contents"
            :question-index="index"
            :is-completed="isCompleted"
            :is-conversational="isConversational"
            :is-recurrent="isRecurrent"
            :questions="questions"
            @on-delete-content="deleteContent"
        />
    </div>
</template>

<script>
import CompanyLanguage from '@/models/CompanyLanguage';
import CompanySurveyContent from '@/models/CompanySurveyContent';

import SurveyListContentItem from '@/components/pages/resources/surveys/form/SurveyListContentItem.vue';

export default {
    name: 'SurveyListContent',
    components: {
        SurveyListContentItem,
    },
    inject: ['$validator'],

    props: {
        value: {
            type: Array,
            required: true,
        },
        currentLanguage: {
            type: CompanyLanguage,
            required: true,
        },
        isCompleted: {
            type: Boolean,
            required: false,
            default: false,
        },
        isConversational: {
            type: Boolean,
            required: true,
        },
        isRecurrent: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            contents: [...this.value].sort((a, b) => a.order - b.order),
        };
    },

    computed: {
        questions() {
            return this.contents
                .filter((content) => content.resource === 'company_survey_question')
                .map((content) => content.question);
        },
    },

    watch: {
        contents: {
            deep: true,
            handler() {
                this.$emit('input', this.contents);
            },
        },
        value: {
            deep: true,
            handler() {
                this.contents = this.value;
            },
        },
    },

    methods: {
        deleteContent(index) {
            const oldOrder = this.contents[index].order;

            this.contents.splice(index, 1);

            const maxOrder = this.contents.length - 1;

            // modification des "next_content_order" sur les réponses
            this.contents.forEach((content) => {
                if (content.resource === CompanySurveyContent.RESOURCE_QUESTION) {
                    content.question.answers.forEach((answer) => {
                        if (answer.next_content_order === oldOrder) {
                            answer.next_content_order = null;
                        }

                        if (answer.next_content_order > oldOrder
                            && answer.next_content_order !== CompanySurveyContent.ORDER_END_SURVEY) {
                            answer.next_content_order -= 1;
                        }

                        if (answer.next_content_order > maxOrder
                            && answer.next_content_order !== CompanySurveyContent.ORDER_END_SURVEY) {
                            answer.next_content_order = null;
                        }
                    });
                }
            });

            // modification des "next_content_order" sur les contents
            this.contents
                .filter((content) => content.next_content_order >= oldOrder
                    && content.next_content_order !== CompanySurveyContent.ORDER_END_SURVEY)
                .forEach((content) => {
                    content.next_content_order = content.next_content_order !== oldOrder
                        ? content.next_content_order - 1
                        : null;

                    if (content.next_content_order > maxOrder && answer.next_content_order !== CompanySurveyContent.ORDER_END_SURVEY) {
                        content.next_content_order = null;
                    }
                });

            // modification des "order" sur les contents
            this.contents
                .filter((content) => content.order > oldOrder)
                .forEach((content) => {
                    content.order -= 1;
                });
        },
    },
};
</script>

<style lang="scss" src="./SurveyListContent.scss"></style>
