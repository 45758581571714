import Model from '@tony.caron/node-model-proxy/Model';
import CompanyQuiz from './CompanyQuiz';
import CompanyQuizQuestion from './CompanyQuizQuestion';
import CompanyUserQuiz from './CompanyUserQuiz';
import CompanyUser from './CompanyUser';
import CompanyUserQuizQuestionAnswerCollection from './CompanyUserQuizQuestionAnswerCollection';

export default class CompanyUserQuizQuestionAnswer extends Model {
    modelConfig() {
        return {
            collection: CompanyUserQuizQuestionAnswerCollection,
        };
    }

    modelDefaultValues() {
        return {
            translations: {
                text: {},
            },
            file_translations: {},
        };
    }

    modelRelations() {
        return {
            companyQuiz: () => this.belongsTo(CompanyQuiz, 'company_quiz_id'),
            companyUserQuiz: () => this.belongsTo(CompanyUserQuiz, 'company_user_quiz_id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id'),
            companyQuizQuestion: () => this.belongsTo(CompanyQuizQuestion, 'company_quiz_question_id'),
            companyQuizQuestionAnswer: () => this.belongsTo(CompanyUserQuizQuestionAnswer, 'company_quiz_question_answer_id'),
        };
    }
}
