<template>
    <div class="ht-status-light">
        <span
            class="status"
            :class="status"
        />
        <span class="status-hoverable" />
        <span
            class="status-label"
            :class="status"
        >{{ label }}</span>
    </div>
</template>

<script>
export default {
    name: 'HtStatusLight',
    props: {
        status: {
            required: true,
            validator: (value) => ['success', 'warning', 'danger', 'neutral'].includes(value),
        },
        label: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
    .ht-status-light {
        position: relative;
    }

    .status {
        display: block;
        border-radius: 50%;
        /* Chrome doesn't respect the specified width when the parent flex is on nowrap and needs some space, but it respects the padding */
        padding: 0.4rem;
    }

    .status.success {
        background-color: #3FBC95;
    }
    .status.warning {
        background-color: #FFB858;
    }
    .status.danger {
        background-color: #FF504F;
    }
    .status.neutral {
        background-color: #D8D8D8;
    }

    .status-label {
        display: none;
        position: absolute;
        transform:
            translateX(0.8rem) /* the status icon width */
            translateX(0.8rem) /* the required horizontal gap */
            translateY(0.4rem); /* the required vertical gap */

        padding: 0.4rem 0.8rem;
        font-size: 1.2rem;
        line-height: 1.2rem;
        box-sizing: border-box;
        border-radius: 100px;
        border-width: 1px;
        border-style: solid;
        white-space: nowrap;
        left: -5rem;
        z-index: 1;
    }

    .status-label.success {
        background: #DEF8F0;
        border-color: #5BDDB4;
        color: #3FBC95;
    }
    .status-label.warning {
        background: #FFF1DD;
        border-color: #FFB858;
        color: #D89741;
    }
    .status-label.danger {
        background: #FEDADF;
        border-color:  #F7485E;
        color: #FF504F;
    }
    .status-label.neutral {
        background: white;
        border-color: #6C757D;
        color: #6C757D;
    }

    .status-hoverable {
        position: absolute;
        width: 3rem;
        height: 3rem;
        border-radius: 1rem;
        left: -1.2rem; // (width (3rem) - icon width (0.8rem)) / 2
        top: -1.2rem; // same
    }

    .status-hoverable:hover ~ .status-label, .status-label:hover {
        display: block;
    }
</style>
