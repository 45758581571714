<template>
    <ListItem
        :selected="selected"
        class="item"
        @on-click="onClick"
    >
        <t v-if="noSurveys">
            No result
        </t>
        <t v-else>
            All results
        </t>
    </ListItem>
</template>

<script>
import ListItem from './ListItem.vue';

export default {
    name: 'AllResultRow',
    components: { ListItem },
    props: {
        selected: {
            type: Boolean,
            required: true,
        },
        noSurveys: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        onClick() {
            this.$emit('on-click');
        },
    },
};
</script>

<style>
.item {
    display: flex;
    align-items: center;
}
</style>
