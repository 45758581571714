import ParseDomain from 'parse-domain';
import { isInternalDomain } from '@/helpers/url';

const ENV = {
    qa: 'qa',
    dev: 'dev',
    prod: 'www',
};

const PREFIX = 'VUE_APP_';

export default class Env {
    static get(s, replacement = {}) {
        const sCompany = replacement.company_name ? replacement.company_name : Env.getCompanyDomain(document.location.href);

        if (!window.CONFIG) throw '[Config file not loaded]';
        if (window.CONFIG[s] === undefined) throw `[Env.get] ${s} no found`;

        // eslint-disable-next-line no-template-curly-in-string
        if (typeof window.CONFIG[s] === 'string') return window.CONFIG[s].replace('${COMPANY_NAME}', sCompany);

        return window.CONFIG[s];
    }

    static getFromEnv(s, replacement = {}) {
        const sCompany = replacement.company_name ? replacement.company_name : Env.getCompanyDomain(document.location.href);

        if (process.env[PREFIX + s] === undefined) throw `[Env.get] ${s} no found`;

        return process.env[PREFIX + s].replace('#{COMPANY_NAME}', sCompany);
    }

    static isProd() {
        return Env.get('APP_ENV') === 'production';
    }

    static isQa() {
        return Env.get('APP_ENV') === 'qa';
    }

    static isDev() {
        return Env.get('APP_ENV') === 'local';
    }

    static getCapiUrl(sSuffix = '') {
        const baseUrl = this.getFromEnv('CAPI_BACK_URL');

        return baseUrl + sSuffix;
    }

    // Will get the company domain from te url (domain column on company table)
    // CompanyDomain is the subdomain the more on left
    // http://[test_company3].heyteam.com:8080/login
    // http://[test_company3].dev.heyteam.com:8080/login
    // http://[test_company3].dev-1.heyteam.loc:8080/login
    // https://[welcomeapp.pwc].fr:8080
    // https://[maif-welcomeapp].fr:8080
    // https://[maif-welcomeapp].de:8080

    // OR an encoded full domain in case of external domain
    //  https://welcomeapp.pwc.fr =>  https://[welcomeapp-pwc-fr]

    static getCompanyDomain(sUrl) {
        if (isInternalDomain()) {
            const subDomains = Env._getSubDomains(sUrl);
            if (subDomains === null) return null;

            const tmpSub = subDomains.split('.');

            const subDomain = tmpSub[0];

            return subDomain != ENV[subDomain] ? subDomain : null;
        }

        const parsedUrl = ParseDomain(sUrl, { customTlds: ['loc'] });
        const sDomain = `${parsedUrl.subdomain}-${parsedUrl.domain}-${parsedUrl.tld}`;

        return sDomain;
    }

    static _getSubDomains(sUrl) {
        const parsedUrl = ParseDomain(sUrl, { customTlds: ['loc'] });
        return parsedUrl && parsedUrl.subdomain ? parsedUrl.subdomain : null;
    }

    static install(Vue) {
		 Vue.prototype.$env = Env;
    }
}
