<template>
    <HtTable
        :items="items"
        :columns="columns"
        cypress="action-resource-table"
        :empty-message="'There are no nudge to display yet...'"
        :external-search="externalSearch"
        @onCellClicked="$emit('onCellClicked', $event)"
    >
        <template #cell(participants)="scope">
            <ParticipantList
                :users="scope.item.resource.programs.models[0].participants.models"
                :has-roles="true"
                :limit="3"
            />
        </template>
        <template #cell(status)="scope">
            <HtTag :color="statusColor(scope.item.status())">
                <t>{{ scope.item.status() }}</t>
            </HtTag>
        </template>
    </HtTable>
</template>
<script>
import HtTable from '@/components/globals/HtTable.vue';
import ParticipantList from '@/components/globals/ParticipantList.vue';
import ResourceListMixin from '@/mixins/ResourceListMixin';

export default {
    name: 'NudgesList',
    components: { HtTable, ParticipantList },
    mixins: [
        ResourceListMixin,
    ],
    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'resource.name',
                    class: 'font-weight-bold',
                    search: true,
                },
                {
                    label: this.translate('Members'),
                    key: 'participants',
                    sortable: false,
                    class: 'd-none d-md-table-cell',
                    search: false,
                },
                {
                    label: this.translate('Creation date'),
                    key: 'display_created_at',
                    class: 'd-none d-md-table-cell',
                    search: false,
                },
                {
                    label: this.translate('Status'),
                    key: 'status',
                    class: 'd-none d-md-table-cell',
                    search: false,
                },
                {
                    label: this.translate('Program'),
                    key: 'resource.programs.models[0].program.name_translated',
                    sortable: false,
                    class: 'hide-mobile',
                    search: false,
                },
            ],
        };
    },
};

export const nudgeConfig = {
    fields: [
        'id',
        'time_to_complete',
        'company_nudge_initiator_user_id',
        'company_nudge_initiator_role_id',
        'company_nudge_creator_user_id',
        'created_at',
        'order',
    ],
    relations: {
        resource: (query) => {
            query
                .select(['id', 'name'])
                .with({
                    programs: (query) => {
                        query
                            .select(['id', 'company_program_id', 'is_enabled'])
                            .with({
                                program: (query) => {
                                    query.select(['id']).with({
                                        locales: (query) => {
                                            query.select(['id', 'language_key', 'name']);
                                        },
                                    });
                                },
                                participants: (query) => {
                                    query
                                        .select(['id', 'company_user_id', 'company_role_id'])
                                        .with({
                                            user: (query) => {
                                                query
                                                    .select(['id', 'image', 'fullname']);
                                            },
                                            role: (query) => {
                                                query
                                                    .select(['id', 'name', 'alias']);
                                            },
                                        });
                                },
                            });
                    },
                });
        },
        companyUserNudge: (query) => {
            query.select('id');
        },
        companyNudgeInitiatorRole: (query) => {
            query.select(['id', 'name', 'alias']);
        },
        companyNudgeInitiatorUser: (query) => {
            query.select(['id', 'firstname', 'lastname', 'image']);
        },
        companyNudgeCreatorUser: (query) => {
            query.select(['id', 'firstname', 'lastname', 'image']);
        },
    },
};

</script>
