<template>
    <HtFormCard>
        <HtFormSwitch
            :id="`switch-profile${description}`"
            class="tmp mb-3"
            :name="`switch-profile${description}`"
            :label="switchLabel"
            :show-optional="false"
            :is-branded="true"
            :value="nbMonths !== null"
            @input="onToggle"
        />
        <template v-if="nbMonths !== null">
            <HtTextDescription
                class="mt-3"
                :text="description"
            />
            <div class="ht-form-label my-3">
                <t>{{ inputNumberLabel }}</t>
            </div>
            <div class="nb-of-months">
                <HtFormInput
                    :id="`nb-of-months${description}`"
                    v-validate.disable="'min_value:1'"
                    :name="`nb-of-months${description}`"
                    :type="'number'"
                    :show-optional="false"
                    class="tmp input"
                    :data-vv-as="translate('amount of months')"
                    :value="nbMonths"
                    @input="onInputNumber"
                />
                <div>
                    <t :count="nbMonths">
                        Month | Months
                    </t>
                </div>
            </div>
            <HtFormSelect
                :id="`select-date${description}`"
                v-validate.disabled="'required'"
                class="tmp mt-3"
                :name="`select-date${description}`"
                :options="rgpdDateOptions"
                :placeholder="translate('Select a period')"
                :value="rgpdDate"
                :disabled="rgpdDateOptions.length === 1"
                @input="onSelectRgpdDate"
            />
        </template>
    </HtFormCard>
</template>

<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtTextDescription from '@/components/globals/HtTextDescription.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';

export default {
    name: 'PurgeCard',
    components: {
        HtFormSwitch, HtTextDescription, HtFormSelect,
    },
    inject: ['$validator'],
    props: {
        nbMonths: {
            type: Number,
            default: null,
        },
        description: {
            type: String,
            required: true,
        },
        inputNumberLabel: {
            type: String,
            required: true,
        },
        rgpdDate: {
            type: String,
            default: null,
        },
        rgpdDateOptions: {
            type: Array,
            required: true,
        },
        switchLabel: {
            type: String,
            required: true,
        },
    },
    methods: {
        /**
         * @param {number} number
         * @returns {void}
         */
        onInputNumber(number) {
            this.$emit('update:nb-months', Number(number));
        },
        /**
         * @param {string} rgpdDate
         * @returns {void}
         */
        onSelectRgpdDate(rgpdDate) {
            this.$emit('update:rgpd-date', rgpdDate);
        },
        /**
         * @param {boolean} isToggled
         * @returns {void}
         */
        onToggle(isToggled) {
            this.$emit('toggle', isToggled);
        },
    },
};
</script>
<style lang="scss" scoped>
.tmp {
    margin-bottom: 0px;
}

.nb-of-months {
    display: flex;
    gap: 1rem;
    align-items: center;
    .input {
        width: 20%;
    }
}
</style>
