<template>
    <HtReadResources
        v-if="sharedDocument"
        :resource="sharedDocument"
        :resource-type="resourceType"
        :resource-data="resourceData"
        label-category="Document"
        :has-sender="false"
        :has-recipient="false"
    />
</template>

<script>

import HtReadResources from '@/components/globals/modals/HtReadResources/HtReadResources.vue';

export default {
    name: 'SharedDocumentRead',
    components: { HtReadResources },
    props: {
        resourceType: {
            type: String,
            required: true,
        },
        resourceId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            sharedDocument: null,
            resourceData: {
                type: null,
                data: null,
            },
        };
    },

    async created() {
        const request = await this.$http.get(`/configuration/documents/${this.resourceId}`);
        this.sharedDocument = request.data.data.resource;

        this.resourceData.data = request.data.data.company_file;
        this.resourceData.type = 'other';
    },
};
</script>
