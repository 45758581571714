import CompanyChatChannelMessage from '@/models/CompanyChatChannelMessage';
import CompanyUserSurveyQuestionAnswerCollection from '@/models/CompanyUserSurveyQuestionAnswerCollection';

export default {
    props: {
        companyChatChannelMessage: {
            type: CompanyChatChannelMessage,
            required: true,
        },
    },
    methods: {
        sendAnswer(companySurveyQuestionAnswerId) {
            if (this.readOnly) return;

            const companyUserSurveyQuestionAnswerCollection = new CompanyUserSurveyQuestionAnswerCollection([
                'company_survey_question_answer_id',
            ]);

            const answer = companyUserSurveyQuestionAnswerCollection.new();
            answer.company_survey_question_answer_id = companySurveyQuestionAnswerId;

            companyUserSurveyQuestionAnswerCollection.models.push(answer);

            this.$emit('onAnswers', companyUserSurveyQuestionAnswerCollection);
        },
        isActive(companySurveyQuestionAnswerId) {
            const ids = this.companyUserSurveyQuestionAnswerCollection.models.map((item) => item.company_survey_question_answer_id);
            return ids.includes(companySurveyQuestionAnswerId);
        },
        skip() {
            this.$emit('onSkip');
        },
    },
    computed: {
        companyUserSurveyQuestion() {
            return this.companyChatChannelMessage.company_user_survey_question;
        },
        companySurveyQuestion() {
            return this.companyUserSurveyQuestion.company_survey_question;
        },
        companySurveyQuestionAnswerCollection() {
            return this.companySurveyQuestion.company_survey_question_answer;
        },
        companyUserSurveyQuestionAnswerCollection() {
            return this.companyUserSurveyQuestion.company_user_survey_question_answer;
        },
        companyUserSurveyQuestionOwner() {
            return this.companyUserSurveyQuestion.company_user_id;
        },
        readOnly() {
            return this.companyUserSurveyQuestionAnswerCollection.models.length > 0 || this.companyUserSurveyQuestion.is_skipped === true;
        },
        isNotMandatory() {
            return this.companySurveyQuestion.is_mandatory === false;
        },
    },
};
