<template>
    <HoldingResourceList
        :route-list="'HoldingResourcesNudgesList'"
        :collection="nudges"
        :use-resource-program="false"
    >
        <template #table="holdingResourceList">
            <NudgesList
                :items="holdingResourceList.items"
                :external-search="holdingResourceList.search"
                @onCellClicked="holdingResourceList.onCellClicked"
            />
        </template>
    </HoldingResourceList>
</template>
<script>
import HoldingResourceList from '@/components/globals/HoldingResourceList.vue';
import CompanyNudgeCollection from '@/models/CompanyNudgeCollection';
import NudgesList, { nudgeConfig } from '@/resourcesList/NudgesList.vue';

export default {
    name: 'PageNudgesList',
    components: {
        HoldingResourceList,
        NudgesList,
    },

    data() {
        return {
            nudges: new CompanyNudgeCollection(nudgeConfig.fields).with(nudgeConfig.relations),
        };
    },
    methods: {
        statusColor(status) {
            switch (status) {
            case 'Active':
                return 'green';
            case 'Inactive':
                return 'grey';
            }
        },
    },
};
</script>
