<template>
    <modalable
        ref="modalable"
        class="modal modalable-4"
    >
        <div class="modal-title">
            {{ title }}
        </div>
        <p>{{ sub_title }}</p>
        <div class="row-line middle">
            <div
                class="button button-1 black"
                @click="onCancel"
            >
                <div class="label">
                    <t>Cancel</t>
                </div>
            </div>
            <div
                class="button button-1"
                @click="onConfirm"
            >
                <div class="label">
                    {{ confirm_label }}
                </div>
            </div>
        </div>
    </modalable>
</template>

<script>
export default {
    name: 'GlobalConfirm',

    props: {
        title: { type: String, required: true },
        sub_title: { type: String, required: true },
        confirm_label: { type: String, required: true },
    },

    methods: {
        open() {
            this.$refs.modalable.open();
        },

        onCancel() {
            this.$emit('onCancel');
            this.$refs.modalable.close();
        },

        onConfirm() {
            this.$emit('onConfirm');
            this.$refs.modalable.close();
        },
    },
};
</script>
