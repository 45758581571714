<script>
import CompanyChatbot from '@/models/CompanyChatbot';

export default {
    data() {
        return {
            is_saving: false,
            collectionCopy: {},
        };
    },

    computed: {
        isChatbotFull() {
            return this.chatbotSlug === CompanyChatbot.SLUG_FULL;
        },
    },

    created() {
        this.collection.models.map((model) => {
            if (model.page === this.step) this.collectionCopy[model.subtype] = model;
        });
    },

    methods: {
        async onValidate() {
            this.is_saving = true;
            await this.$validator.validateAll();

            if (this.errors.any()) return;

            await this.collection.save();
            this.is_saving = false;
            this.$emit('onNextPage');
        },
    },
};
</script>
