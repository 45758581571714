import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import CompanyEmailCustomTemplate from './CompanyEmailCustomTemplate';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import CompanyFile from './CompanyFile';
import CompanyUserEmailCustomCollection from './CompanyUserEmailCustomCollection';
import CompanyUserEmailCustomRecipient from './CompanyUserEmailCustomRecipient';

export default class CompanyUserEmailCustom extends Model {
    modelConfig() {
        return {
            controller: 'CompanyUserEmailCustomController',
            collection: CompanyUserEmailCustomCollection,
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyUserSender: () => this.belongsTo(CompanyUser, 'sender_company_user_id', 'id'),
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
            companyEmailCustomTemplate: () => this.belongsTo(CompanyEmailCustomTemplate, 'company_email_custom_template_id', 'id'),
            companyUserEmailCustomRecipient: () => this.hasMany(CompanyUserEmailCustomRecipient),
            companyUserProgramTask: () => this.belongsTo(CompanyUserProgramTask, 'company_user_program_task_id', 'id'),
        };
    }
}
