<template>
    <HtFormGroup
        :label="label"
        :error="error"
        :is-required="true"
        :show-asterisk="false"
        :tooltip="tooltip"
    >
        <fieldset>
            <div class="range-group">
                <div class="range-info">
                    {{ value }}
                </div>
                <input
                    ref="range"
                    class="range-input"
                    type="range"
                    :value="value"
                    :min="min"
                    :max="max"
                    :step="step"
                    :list="`list-range-${id}`"
                    @input="onUpdate()"
                >
            </div>
        </fieldset>
    </HtFormGroup>
</template>

<script>
import HtFormGroup from './HtFormGroup.vue';
import HtFormMixin from './HtFormMixin';

export default {
    name: 'HtFormRange',
    components: { HtFormGroup },
    mixins: [
        HtFormMixin,
    ],
    props: {
        value: {
            type: Number,
            default: null,
        },
        min: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        step: {
            type: Number,
            default: 10,
        },
    },

    methods: {
        onUpdate() {
            this.$emit('input', this.$refs.range.value * 1);
        },
    },
};
</script>
