import utils from '@/store/api/utils';
import holding from '@/store/api/holding';
import endpoints from '@/store/api/endpoints';
import dashboard from '@/store/api/dashboard';
import configuration from '@/store/api/configuration';
import user from '@/store/api/user';
import permissions from '@/store/api/permissions';
import miscellaneous from '@/store/api/miscellaneous';
import userPrograms from '@/store/api/userPrograms';
import pdfForm from '@/store/api/pdfForm';
import tasks from '@/store/api/tasks';
import users from '@/store/api/users';
import sso from '@/store/api/sso';
import variables from '@/store/api/variables';
import chatbot from '@/store/api/chatbot';
import settings from '@/store/api/settings';
import enterprisePages from '@/store/api/enterprisePages';
import surveyResults from '@/store/api/surveyResults';

export default {
    utils,
    holding,
    endpoints,
    dashboard,
    configuration,
    user,
    permissions,
    miscellaneous,
    userPrograms,
    pdfForm,
    tasks,
    users,
    sso,
    variables,
    chatbot,
    settings,
    enterprisePages,
    surveyResults,
};
