<template>
    <keep-alive>
        <component
            :is="currentStepData.component"
            v-bind="currentStepData.props"
            @on-update="onUpdate"
            @previous="previous"
            @next="next"
            @close="close"
        />
    </keep-alive>
</template>

<script>
import { mapState } from 'vuex';
import ProgramKeyDatesConfiguration from '@/components/pages/foc/multiple/ProgramKeyDatesConfiguration.vue';
import ProgramAdditionTypeConfiguration from '@/components/pages/foc/multiple/ProgramAdditionTypeConfiguration.vue';
import ProgramAdditionConfiguration from '@/components/pages/foc/multiple/ProgramAdditionConfiguration.vue';

export default {
    name: 'NewInvitationSessionModal',

    props: {
        programId: {
            type: Number,
            required: false,
            default: () => null,
        },
    },

    data() {
        return {
            programList: [],
            selectedProgramId: this.programId,
            configuration: {
                additionType: 'by_filter',
                keyDates: [],
            },
            currentStepIndex: 0,
        };
    },

    computed: {
        ...mapState('programs', [
            'programsLoaded',
        ]),

        steps() {
            return [
                {
                    component: ProgramKeyDatesConfiguration,
                    props: {
                        programId: this.programId,
                        configuration: this.configuration,
                    },
                },
                {
                    component: ProgramAdditionTypeConfiguration,
                    props: {
                        programId: this.selectedProgramId,
                        configuration: this.configuration,
                    },
                },
                {
                    component: ProgramAdditionConfiguration,
                    props: {
                        programId: this.selectedProgramId,
                        configuration: this.configuration,
                    },
                },
            ];
        },

        currentStepData() {
            return this.steps[this.currentStepIndex];
        },
    },

    created() {
        this.$store.dispatch('programs/fetchPrograms');
    },

    methods: {
        close() {
            this.$emit('close');
        },

        previous() {
            const previousIndex = this.currentStepIndex - 1;
            if (previousIndex < 0) {
                return;
            }

            this.currentStepIndex = previousIndex;
        },

        next() {
            const nextIndex = this.currentStepIndex + 1;
            if (nextIndex > (this.steps.length - 1)) {
                return;
            }

            this.currentStepIndex = nextIndex;
        },

        onUpdate(type, data) {
            switch (type) {
            case 'key_dates':
                this.configuration.keyDates = data;
                break;
            case 'addition_type':
                this.configuration.additionType = data;
                break;
            case 'program_id':
                this.selectedProgramId = data;
                break;
            default:
                break;
            }
        },
    },
};
</script>
