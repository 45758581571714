export default {
    methods: {
        canPreview(mimeType) {
            return this.$Utils.mimeTypeImgAvailable(mimeType) || mimeType.endsWith('pdf');
        },
        isImage(mimeType) {
            if (this.canPreview(mimeType)) {
                return mimeType.startsWith?.('image');
            }

            return false;
        },
        isVideo(mimeType) {
            return mimeType.startsWith?.('video');
        },
    },
};
