<template>
    <div class="crudable-container no-margin row">
        <div class="col d-flex">
            <input
                :id="value.component + '-read'"
                v-model="value.can_read"
                type="checkbox"
                name="consult"
                class="cursor-hand"
                :disabled="disabled || !value.ht_permission.read_enabled"
            >
            <label
                :for="value.component + '-read'"
                class="ml-2 cursor-hand"
            >
                <t>Consult</t>
            </label>
        </div>
        <div class="col d-flex">
            <input
                :id="value.component + '-assign'"
                v-model="value.can_create"
                type="checkbox"
                name="assign"
                class="cursor-hand"
                :disabled="disabled || !value.ht_permission.create_enabled"
            >
            <label
                :for="value.component + '-assign'"
                class="ml-2 cursor-hand"
            >
                <t>Assign</t>
            </label>
        </div>
        <div class="col d-flex">
            <input
                :id="value.component + '-edit'"
                v-model="value.can_update"
                type="checkbox"
                name="edit"
                class="cursor-hand"
                :disabled="disabled || !value.ht_permission.update_enabled"
            >
            <label
                :for="value.component + '-edit'"
                class="ml-2 cursor-hand"
            >
                <t>Edit</t>
            </label>
        </div>
        <div class="col d-flex">
            <input
                :id="value.component + '-delete'"
                v-model="value.can_delete"
                type="checkbox"
                name="delete"
                class="cursor-hand"
                :disabled="disabled || !value.ht_permission.delete_enabled"
            >
            <label
                :for="value.component + '-delete'"
                class="ml-2 cursor-hand"
            >
                <t>Delete</t>
            </label>
        </div>
        <div class="col d-flex">
            <template v-if="value.ht_permission.validate_enabled">
                <input
                    :id="value.component + '-validate'"
                    v-model="value.can_validate"
                    type="checkbox"
                    name="validate"
                    class="cursor-hand"
                    :disabled="disabled"
                >
                <label
                    :for="value.component + '-validate'"
                    class="ml-2 cursor-hand"
                >
                    <t>Validate</t>
                </label>
            </template>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CrudCheckable',
    props: {
        value: { type: Object, required: true },
        disabled: { type: [Boolean, Number], default: false },
    },
};

</script>

<style lang="scss">
	@import '~@/styles/var';

	.crudable-container {
		label {
            margin-bottom: 0;
            font-size:1.4rem;
        }

        input:disabled + label {
            color:lightgrey;
        }
    }

    .cursor-hand {
        cursor: pointer;
    }
</style>
