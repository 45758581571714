<template>
    <transition name="modal-fade">
        <div
            v-if="isVisible"
            class="modal"
            @click.self="close()"
        >
            <transition name="modal-slide">
                <div
                    v-if="isVisible"
                    class="modal-wrapper"
                    :class="size"
                >
                    <slot name="full-header">
                        <div
                            v-show="hasHeaderSlot"
                            class="modal-wrapper-header"
                        >
                            <div class="modal-wrapper-header-title">
                                <slot name="header" />
                            </div>
                            <FontAwesomeLayers
                                class="modal-wrapper-header-icon"
                                @click="close()"
                            >
                                <FontAwesomeIcon
                                    size="2x"
                                    icon="circle"
                                    class="icon-circle"
                                />
                                <FontAwesomeIcon
                                    size="2x"
                                    :icon="['fal', 'times']"
                                    transform="shrink-10"
                                    class="icon-second"
                                />
                            </FontAwesomeLayers>
                        </div>
                    </slot>
                    <div
                        v-show="hasDefaultSlot"
                        class="modal-wrapper-main"
                    >
                        <slot name="default" />
                    </div>
                    <div
                        v-show="hasFooterSlot && showFooter"
                        class="modal-wrapper-footer"
                    >
                        <slot name="footer" />
                    </div>
                    <div
                        v-show="hasFooterActionsSlot && showFooterActions"
                        class="modal-wrapper-footer-actions"
                    >
                        <slot name="footer-actions" />
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'HtModal',
    inject: ['$validator'],

    shared: {
        modals: {},
    },

    props: {
        id: {
            type: String,
            default: () => null,
        },
        size: {
            type: String,
            default: () => 'small',
        },
        showFooter: {
            type: Boolean,
            default: () => true,
        },
        showFooterActions: {
            type: Boolean,
            default: () => true,
        },
    },

    data() {
        return {
            isVisible: false,
        };
    },

    computed: {
        hasDefaultSlot() {
            return 'default' in this.$slots;
        },
        hasHeaderSlot() {
            return 'header' in this.$slots;
        },
        hasFooterSlot() {
            return 'footer' in this.$slots;
        },
        hasFooterActionsSlot() {
            return 'footer-actions' in this.$slots;
        },
    },

    mounted() {
        this.register();
        document.getElementById('app').appendChild(this.$el);
    },

    destroyed() {
        this.unregister();
    },

    methods: {
        sendEvent(name, callback) {
            if (name in this.$listeners) {
                return new Promise((resolve) => {
                    this.$emit(name, resolve);
                }).then(() => {
                    callback();
                });
            }

            callback();
        },
        open() {
            this.sendEvent('beforeOpen', () => {
                this.isVisible = true;
                this.$emit('onOpen');
            });
        },
        close() {
            this.sendEvent('beforeClose', () => {
                this.isVisible = false;
                this.$emit('onClose');
            });
        },
        register() {
            if (this.id !== null && this.shared.modals[this.id] === undefined) {
                this.shared.modals[this.id] = this;
            }
        },
        unregister() {
            this?.$el?.parentNode?.removeChild(this.$el);
        },
    },
};
</script>

<style lang="scss" src="./HtModal.scss" scoped />
