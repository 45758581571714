import Vue from 'vue';

export default {
    getAll(data = {}) {
        return Vue.prototype.$http.get('configuration/survey-results', data);
    },

    get(id, config = {}) {
        return Vue.prototype.$http.get(`configuration/survey-results/${id}`, config);
    },
};
