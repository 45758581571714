var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ParametersBox',{attrs:{"title":_vm.translate('Team')}}),(_vm.hasOtherActivePrograms)?_c('HtToast',{staticClass:"mb-20",attrs:{"title":_vm.translate('Updating a supervisor will impact other programs'),"message":_vm.translate(
                    'If you update a supervisor being used in other programs, the modification will be applied to all programs using this supervisor'
                ),"type":"warning","closable":""}}):_vm._e(),_c('HtCard',{staticClass:"team-wrapper"},[_vm._l((_vm.supervisors),function(supervisor,index){return _c('HtItemSelectCard',{key:index,attrs:{"label":supervisor.role.alias_translated,"button-label":_vm.translate('Add a user'),"item":supervisor,"is-empty":_vm.isSupervisorEmpty,"can-delete":_vm.canDeleteSupervisor,"cypress":'role-'+index},on:{"on-change":_vm.onChange,"on-delete":_vm.onDelete},scopedSlots:_vm._u([{key:"item-icon",fn:function(data){return [_c('div',{directives:[{name:"user-image",rawName:"v-user-image",value:({
                            image: data.value.user.image,
                            firstname: data.value.user.firstname,
                            lastname: data.value.user.lastname,
                        }),expression:"{\n                            image: data.value.user.image,\n                            firstname: data.value.user.firstname,\n                            lastname: data.value.user.lastname,\n                        }"}],staticClass:"user-image mr-3"})]}},{key:"item-label",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getFullname(data.value.user))+" ")]}}],null,true)})}),_c('Button',{attrs:{"cypress":"foc-add-role"},on:{"click":_vm.openRoleModal}},[_c('t',[_vm._v("Add optional roles")])],1)],2),_c('Modalable',{ref:"modalableSearchRole",staticClass:"modalable-1 small no-padding-bottom"},[_c('SearchRolePanel',{ref:"searchRolePanel",attrs:{"multiple-mode":true,"allow-user-search":false,"has-roles":true},on:{"onValidate":_vm.onValidateRoleModal}})],1),(_vm.clickedRoleUser)?_c('SetSupervisorModal',{ref:"modal",attrs:{"role-name":_vm.clickedRoleUser.role.alias_translated,"role-id":_vm.clickedRoleUser.role.id,"is-guest-role":_vm.clickedRoleUser.role.is_guest,"suggestion":_vm.getSuggestion},on:{"user-selected":_vm.onUserSelected,"user-invited":_vm.onUserInvited}}):_vm._e(),_c('HtToast',{staticClass:"mb-20",attrs:{"title":_vm.translate('Resources linked to unset roles won\'t be generated'),"message":_vm.translate(
                    'You could always add coworker on this role later but resources should be added manually'
                ),"type":"info","closable":""}}),_c('EnrollingFooter',[_c('Button',{staticClass:"inner-box branding-color branding-border-color branding-border-color-darken-hover",attrs:{"cypress":"foc-previous-team"},on:{"click":function($event){return _vm.$emit('on-prev')}}},[_c('t',[_vm._v("Previous")])],1),_c('Button',{attrs:{"cypress":"foc-next-team","state":_vm.buttonState},on:{"click":_vm.onSave}},[_c('t',[_vm._v("Next")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }