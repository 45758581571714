/* eslint-disable no-useless-constructor */
/* eslint-disable class-methods-use-this */

import HtProgramType from '@/models/HtProgramType';
import CompanyLanguage from '@/models/CompanyLanguage';
import CompanyRole from '@/models/CompanyRole';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import AbstractTemplateProvider from './AbstractTemplateProvider';

export default class OnboardingTemplateProvider extends AbstractTemplateProvider {
    static TEMPLATE_SLUG = 'onboarding';

    constructor(programTypes, keyDates, roles, employeeDepartmentGroupId) {
        super(programTypes, keyDates, roles, employeeDepartmentGroupId);
    }

    get baseProgramData() {
        return {
            slug: OnboardingTemplateProvider.TEMPLATE_SLUG,
            ht_program_type_id: this.programTypes.find(
                (type) => type.slug === HtProgramType.SLUG_ONBOARDING,
            ).id,
            locales: [
                {
                    name: 'Onboarding',
                    description:
                        'Trop souvent oublié, l’onboarding est pourtant l’étape la plus importante du parcours collaborateur.',
                    language_key: CompanyLanguage.KEY_FR,
                },
                {
                    name: 'Onboarding',
                    description:
                        'Too often neglected, the onboarding is the most important step in the recruiting process.',
                    language_key: CompanyLanguage.KEY_EN,
                },
            ],
            key_dates: [
                {
                    ht_program_key_date: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Arrival date')),
                    ht_program_key_date_id: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Arrival date')).id,
                    is_main: true,
                },
            ],
            actions: [],
            surveys: [],
            emails: [],
            events: [],
        };
    }

    get actions() {
        return [
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Procéder à un premier entretien RH avec le salarié',
                            [CompanyLanguage.KEY_EN]: 'Conduct a first HR interview with the employee',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Mettre un guide d’accueil personnalisé à disposition du salarié',
                            [CompanyLanguage.KEY_EN]: 'Provide a personalized welcome guide to the employee',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Attribuer un parrain au salarié pour l’accompagner durant l’onboarding',
                            [CompanyLanguage.KEY_EN]: 'Assign a buddy to the employee to assist them during the onboarding process',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Organiser un welcome tour des locaux et du quartier avec le salarié',
                            [CompanyLanguage.KEY_EN]: 'Organize a welcome tour of the premises and the neighborhood with the employee',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -1,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Proposer un challenge au collaborateur pour faciliter son intégration',
                            [CompanyLanguage.KEY_EN]: 'Offer a challenge to the employee to make their integration easier',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 15,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Construire un plan de formation sur 1 an avec le salarié',
                            [CompanyLanguage.KEY_EN]: 'Build a 1-year training plan with the employee',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 2,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Préparer une séance de questions/réponses avec le manager',
                            [CompanyLanguage.KEY_EN]: 'Prepare a questions and answers session with the manager',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 5,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Organiser un événement de bienvenue avec l’équipe du salarié',
                            [CompanyLanguage.KEY_EN]: "Organize a welcome event with the employee's team",
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 30,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Partager la réussite du salarié en interne et sur les réseaux sociaux',
                            [CompanyLanguage.KEY_EN]: "Share the employee's success internally and on social networks",
                        },
                    },
                },
            },
        ];
    }

    get emails() {
        return [
            {
                template: 'custom',
                translations: [
                    {
                        subject: 'Une nouvelle arrivée !',
                        content: `<p>Bonjour</p>
                            <p>Aujourd'hui {enrollee_firstname} {enrollee_lastname} rejoint {company_name}</p>
                            <p>Il/elle fera partie de l'équipe {department}</p>
                            <p>Nous comptons sur vous pour l'accueillir de la meilleure des manières.</p>`,
                        signature: `Bien cordialement,
                                    {sender_firstname} {sender_lastname}`,
                        language_key: CompanyLanguage.KEY_FR,
                    },
                    {
                        subject: 'A new arrival!',
                        content: `<p>Hello</p>,
                            <p>Today {enrollee_firstname} {enrollee_lastname} is joining {company_name}</p>
                            <p>They will be part of the {department} team</p>`,
                        signature: `Best regards,
                                    {sender_firstname} {sender_lastname}`,
                        language_key: CompanyLanguage.KEY_EN,
                    },
                ],
                file_translations: [],
                resource: {
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Annoncer l’arrivée du nouveau collaborateur à toute l’entreprise',
                            [CompanyLanguage.KEY_EN]: 'Announce the new employee arrival to the whole company',
                        },
                    },
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -8,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_group_id: this.employeeDepartmentGroupId,
                                },
                            ],
                        },
                    ],
                },
                sender_role_id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
            },
        ];
    }

    get events() {
        return [
            {
                time_start: '14:00',
                time_end: '15:00',
                is_delegated: false,
                timezone: 'Europe/Paris',
                organiser_role: {
                    id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
                },
                email_sending_offset: 2,
                file_translations: {},
                translations: {
                    content: {
                        [CompanyLanguage.KEY_FR]: `<p>Bonjour {enrollee_firstname},</p>
                        <p>Cela fait quelque temps que votre parcours d'onboarding a commencé !</p>
                        <p>Je vous propose un point pour échanger sur votre arrivée dans l'entreprise.</p>`,
                        [CompanyLanguage.KEY_EN]: `<p>Hello {enrollee_firstname},</p>
                        <p>It's been a while since your onboarding journey started!</p>
                        <p>I suggest a meeting to discuss your arrival in the company.</p>`,
                    },
                },
                resource: {
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: "Point d'étape à mi-parcours de la période d'essai",
                            [CompanyLanguage.KEY_EN]: 'Meeting with the talent',
                        },
                        description: {
                            [CompanyLanguage.KEY_FR]: 'Premier échange avec le manager',
                            [CompanyLanguage.KEY_EN]: 'First discussion with the manager',
                        },
                    },
                    programs: [
                        {
                            is_enabled: true,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_availability_number: 0,
                            offset_end_number: 15,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                            filters: [],
                        },
                    ],
                },
            },
            {
                time_start: '12:00',
                time_end: '13:00',
                is_delegated: false,
                timezone: 'Europe/Paris',
                email_sending_offset: 2,
                file_translations: {},
                organiser_role: {
                    id: this.roles[CompanyRole.NAME_MANAGER],
                },
                translations: {
                    content: {
                        [CompanyLanguage.KEY_FR]: `<p>Bonjour {enrollee_firstname}</p>
                        <p>Je vous propose d'échanger au sujet de votre onboarding autour d'un déjeuner.</p>`,
                        [CompanyLanguage.KEY_EN]: `<p>Hello {enrollee_firstname},</p>
                        <p>I would like to invite you to discuss your onboarding over lunch.</p>`,
                    },
                },
                resource: {
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Invitation à un déjeuner',
                            [CompanyLanguage.KEY_EN]: 'Invitation to a lunch',
                        },
                        description: {
                            [CompanyLanguage.KEY_FR]: "Un déjeuner est l'occasion parfaite pour échanger au sujet de votre onboarding.",
                            [CompanyLanguage.KEY_EN]: 'A lunch is the perfect opportunity to talk about your onboarding.',
                        },
                    },
                    programs: [
                        {
                            is_enabled: true,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_availability_number: 0,
                            offset_end_number: 10,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                            filters: [],
                        },
                    ],
                },
            },
        ];
    }
}
