<template>
    <div class="form-files">
        <transition-group
            v-if="loadingFiles.length || loadedFiles.length"
            class="file-bar"
            name="list"
            tag="div"
        >
            <div
                key="add-file"
                class="preview-box add-file"
                @click="addFiles"
            >
                <FontAwesomeIcon
                    icon="plus-circle"
                    class="add-icon"
                />
            </div>
            <div
                v-for="(loadingFile, index) in loadingFiles"
                :key="`skeleton-file-${index}`"
                class="skeleton-file"
            >
                <FontAwesomeIcon
                    icon="minus-circle"
                    class="cancel-icon"
                    @click="cancelUploadingFile(loadingFile.name)"
                />
                <div class="preview-box">
                    <div
                        class="loading-pie"
                        :style="`--p:${loadingFile.percent === 0 ? 4 : loadingFile.percent};`"
                    />
                </div>
            </div>
            <div
                v-for="(loadedFile, index) in loadedFiles"
                :key="`preview-file-${index}`"
                class="preview-file-wrapper"
            >
                <FontAwesomeIcon
                    icon="minus-circle"
                    class="cancel-icon"
                    @click="cancelUploadedFile(loadedFile.id)"
                />
                <div
                    v-if="isImage(loadedFile.detected_mime_type)"
                    class="image-wrapper"
                >
                    <div class="preview-box">
                        <img :src="loadedFile.link_preview">
                    </div>
                </div>
                <DocumentBlock
                    v-else
                    :file="loadedFile"
                    no-dowload
                />
            </div>
        </transition-group>
        <Uploadable
            ref="uploadable"
            :media="['office', 'csv', 'pdf', 'image', 'video']"
            ressource="company_file"
            :max-size="25"
            multiple
            @onUploadStart="onUploadStart"
            @onUploadProgress="onUploadProgress"
            @onUploadEnd="onUploadEnd"
            @onUploadError="onUploadError"
            @onUploadCancel="onUploadCancel"
        />
    </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Uploadable from '../globals/Uploadable.vue';
import filePreviewerMixin from '../globals/FilePreviewer/filePreviewerMixin';
import DocumentBlock from './DocumentBlock.vue';

export default {
    name: 'ChatFormFiles',
    components: { Uploadable, FontAwesomeIcon, DocumentBlock },
    mixins: [filePreviewerMixin],
    props: {
        value: {
            type: Array,
            required: false,
            default: () => ([]),
        },
    },
    data() {
        return {
            loadedFiles: [],
            loadingFiles: [],
            loading: false,
            codeMediaNotSupported: 415,
        };
    },
    watch: {
        value(val) {
            this.loadedFiles = val;
        },
    },
    methods: {
        onUploadStart(uploadInfo) {
            this.loading = true;
            this.loadingFiles.push({ ...uploadInfo, loaded: 0 });
        },
        onUploadProgress(uploadInfo) {
            const indexLoadingFile = this.loadingFiles
                .findIndex((file) => file.name === uploadInfo.name);
            this.$set(this.loadingFiles[indexLoadingFile], 'percent', uploadInfo.percent);
        },
        emitInput() {
            this.$emit('input', this.loadedFiles);
        },
        onUploadEnd(uploadInfo, file) {
            this.loadedFiles.push({ ...file, size: uploadInfo.total });
            this.removeFileLoading(uploadInfo.name);
            this.emitInput();
        },
        onUploadError(code, uploadInfo) {
            let message = '';
            if (code === this.codeMediaNotSupported) {
                message = this.translate('This media is not currently supported.');
                this.$Notifier('App').showError();
            } else {
                message = this.translate('Upload error.');
            }
            this.$Notifier('App').showError(message);
            this.removeFileLoading(uploadInfo.name);
        },
        removeFileLoading(name) {
            const indexLoadingFile = this.loadingFiles.findIndex((file) => file.name === name);
            if (indexLoadingFile !== -1) this.loadingFiles.splice(indexLoadingFile, 1);
        },
        onUploadCancel(uploadInfo) {
            this.removeFileLoading(uploadInfo.name);
        },
        addFiles() {
            this.$refs.uploadable.open();
        },
        cancelUploadingFile(name) {
            this.$refs.uploadable.cancelUpload(name);
            this.removeFileLoading(name);
        },
        cancelUploadedFile(id) {
            const indexLoadedFile = this.loadedFiles.findIndex((file) => file.id === id);
            if (indexLoadedFile !== -1) this.loadedFiles.splice(indexLoadedFile, 1);
            this.emitInput();
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
@import "~@/styles/transition-list";
.file-bar{
    display: flex;
    gap: 12px;
    align-items: center;

    .preview-box {
        height: 48px;
        width: 48px;
        border-radius: 8px;
        overflow: hidden;
    }

    .add-file {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-background;
        color: $primary;
        cursor: pointer;

        .add-icon{
            height: 20px;
            width: 20px;
        }
    }
    .skeleton-file {
        position: relative;
        .preview-box{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $neutral-300;

            .loading-pie{
                height: 100%;
                width: 100%;
                display: inline-grid;
                place-content: center;

                &:before{
                    content: "";
                    position: absolute;
                    inset: 12px;
                    border-radius: 100%;
                    background: conic-gradient($neutral-500 calc(var(--p)*1%),#0000 0);
                }
                &:after{
                    content: "";
                    border-radius: 100%;
                    position: absolute;
                    inset: 8px;
                    border: 2px solid $neutral-500;
                }
            }
        }

    }
    .cancel-icon{
        position: absolute;
        top: 0px;
        right: 0px;
        transform: translate(30%,-30%);
        border: 2px solid $neutral-white;
        border-radius: 100%;
        color: $semantic-error;
        height: 16px;
        width: 16px;
        background: $neutral-white;
        cursor: pointer;
        z-index: 2;
    }
    .preview-file-wrapper{
        position: relative;
    }

}

</style>
