import Model from '@tony.caron/node-model-proxy/Model';
import CompanyTagTemplateCollection from '@/models/CompanyTagTemplateCollection';

export default class CompanyTagTemplate extends Model {
    modelConfig() {
        return {
            collection: CompanyTagTemplateCollection,
        };
    }
}
