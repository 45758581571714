<template>
    <div>
        <form
            v-if="isLoaded"
            class="form-2 spaced"
            data-cy="equipment-add-form"
        >
            <template v-if="companyEquipmentOptions.length">
                <fieldset>
                    <HtFormSelect
                        id="equipment"
                        v-validate.disable="'required'"
                        :label="translate('Name')"
                        name="equipment"
                        cypress="equipment"
                        :options="companyEquipmentOptions"
                        :data-vv-as="translate('Equipment')"
                        @input="onSelectedEquipment(...arguments)"
                    />
                </fieldset>
                <template v-if="selectedCompanyEquipment !== null">
                    <InputField
                        v-model="item.specific_information"
                        :label="translate('Specific information for {firstname}', { firstname: companyUserProgram.company_user.firstname })"
                        type="textarea"
                        name="info"
                        cypress="info"
                    />

                    <HtKeyDatesInputOrder
                        :end-label="translate('To deliver')"
                        :user-program-id="companyUserProgramId"
                        :is-business-day.sync="userTask.is_business_day"
                        :offset-key-date.sync="userTask.company_user_program_key_date_id"
                        :offset-availability-specific-date.sync="userTask.datetime_availability"
                        :offset-availability-number.sync="userTask.offset_availability_number"
                        :offset-availability-unit.sync="userTask.offset_availability_unit"
                        :offset-end-specific-date.sync="userTask.datetime_end"
                        :offset-end-number.sync="userTask.offset_end_number"
                        :offset-end-unit.sync="userTask.offset_end_unit"
                        can-select-specific-date
                    />

                    <div class="modal-actions right">
                        <Button
                            :state="buttonState"
                            cypress="equipment-add-button"
                            @click="onNew()"
                        >
                            <t>Save</t>
                        </Button>
                    </div>
                </template>
            </template>
            <div
                v-else
                class="empty-component"
            >
                <strong><t>All available equipment already selected !</t></strong>
            </div>
        </form>
        <template v-else>
            <IconLoading />
        </template>
    </div>
</template>

<script>
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import CompanyUserEquipmentCollection from '@/models/CompanyUserEquipmentCollection';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import CompanyUser from '@/models/CompanyUser';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import api from '@/store/api';

export default {
    name: 'UserEquipmentSelect',
    components: {
        HtKeyDatesInputOrder,
        HtFormSelect,
    },
    inject: ['modal'],

    props: {
        selectedIds: {
            type: Array,
            default: () => [],
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
    },

    data() {
        return {
            userTask: null,
            buttonState: 'idle',
            item: null,
            fetchedSelectedIds: [], // API RESULT IF selectedIds IS NOT PASSED AS PROP
            isLoaded: false,
            selectedCompanyEquipment: null,
            equipments: null,
            companyUser: new CompanyUser([
                'id',
            ]).with({
                companyLanguage: (query) => {
                    query.select(['id', 'key']);
                },
            }).where([
                ['id', '=', this.companyUserId],
            ]),
            companyUserEquipmentCollection: new CompanyUserEquipmentCollection([
                'id',
                'company_user_program_id',
                'company_user_id',
                'company_equipment_id',
                'specific_information',
            ])
                .with({
                    companyUserProgramTask: (query) => {
                        query.select([
                            'id',
                            'company_user_program_key_date_id',
                            'offset_availability_number',
                            'offset_availability_unit',
                            'offset_end_number',
                            'offset_end_unit',
                            'is_business_day',
                            'datetime_availability',
                            'datetime_end',
                            'status',
                        ]);
                    },
                })
                .where([
                    ['company_user_id', '=', this.companyUserId],
                ]),

            companyUserProgram: new CompanyUserProgram([
                'id',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'firstname']);
                },
                companyProgram: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            keyDate: (query) => {
                                query.with({
                                    locales: (query) => query
                                        .select(['id', 'name', 'language_key']),
                                });
                            },
                        });
                },
            }).where([
                ['id', '=', this.companyUserProgramId],
            ]),
        };
    },

    computed: {
        userLanguage() {
            return this.companyUser?.company_language?.key;
        },
        companyEquipmentOptions() {
            return this.filteredCompanyEquipmentCollection.map((equipment) => ({
                id: equipment,
                name: equipment.resource.translations.name[this.userLanguage]
                    ?? equipment.resource.name,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },

        filteredCompanyEquipmentCollection() {
            return this.equipments.filter((equipment) => {
                if (this.selectedIds.includes(equipment.id)) {
                    return false;
                }

                if (this.fetchedSelectedIds.includes(equipment.id)) {
                    return false;
                }

                return true;
            });
        },
    },

    watch: {
        'companyUserEquipment._state.isSaving': function (value) {
            this.buttonState = value ? 'loading' : 'idle';
        },
    },

    async created() {
        this.companyUser.get();
        this.equipments = (await api.configuration.equipments.getAll({ filters: this.$store.state.resources.filters })).data.data;
        this.companyUserProgram.get();

        this.item = this.companyUserEquipmentCollection.new();
        this.item.company_user_program_task = new CompanyUserProgramTask();
        this.item.company_user_id = this.companyUserId;
        this.item.company_user_program_id = this.companyUserProgramId;
        this.userTask = this.item.company_user_program_task;

        if (!this.selectedIds.length) {
            this.loadSelectedUserResourceIds();
        }
        this.modal.setTitle(this.translate('Add equipment'));
        this.isLoaded = true;
    },

    methods: {
        onSelectedEquipment(value) {
            this.selectedCompanyEquipment = value;

            const firstResourceProgram = this.selectedCompanyEquipment.resource.programs[0] || {
                offset_availability_number: 0,
                offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                offset_end_number: 0,
                offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                is_business_day: true,
            };

            this.item.company_equipment_id = this.selectedCompanyEquipment.id;
            this.item.company_user_program_task.is_business_day = firstResourceProgram.is_business_day;
            this.item.company_user_program_task.offset_availability_number = firstResourceProgram.offset_availability_number;
            this.item.company_user_program_task.offset_availability_unit = firstResourceProgram.offset_availability_unit;
            this.item.company_user_program_task.offset_end_number = firstResourceProgram.offset_end_number;
            this.item.company_user_program_task.offset_end_unit = firstResourceProgram.offset_end_unit;
        },

        onNew() {
            this.item.save().then(() => {
                this.modal.close();
                this.$emit('onNew');
                this.$emit('onUpdate');
            });
        },

        // THIS IS NEEDED FOR ADD ELEMENT MODAL
        loadSelectedUserResourceIds() {
            this.companyUserEquipmentCollection.company_user_id = this.companyUserId;
            this.companyUserEquipmentCollection.get().then(() => {
                this.fetchedSelectedIds = this.companyUserEquipmentCollection.$.models.map((item) => item.company_equipment_id);
            });
        },
    },
};
</script>
