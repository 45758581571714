<template>
    <div>
        <HtFormSelect
            :id="'userProgramList'"
            v-validate.disable="'required'"
            :value="value"
            :label="withLabel ? translate('User\'s Program targeted') : null"
            :options="getCompanyUserProgramList"
            :disabled="disabled"
            :name="'userProgramList'"
            class="flex-1"
            :is-single="true"
            :data-vv-as="translate('user program')"
            cypress="program-list"
            @input="onSelectUserProgram"
        />
    </div>
</template>

<script>
import CompanyUserProgramCollection from '@/models/CompanyUserProgramCollection';
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import HtProgramType from '@/models/HtProgramType';

export default {
    name: 'HtUserProgramList',
    components: {
        HtFormMultiSelect,
    },
    inject: ['$validator'],

    props: {
        value: {
            type: [Number, null],
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        withDefault: {
            type: Boolean,
            default: true,
        },
        withLabel: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            companyUserProgramCollection: new CompanyUserProgramCollection([
                'id',
                'company_program_id',
            ]).with({
                companyProgram: (query) => {
                    query.select(['id']).with({
                        locales: (query) => {
                            query.select(['id', 'language_key', 'name']);
                        },
                    });
                },
            }).where([
                ['company_user_id', '=', this.companyUserId],
            ]),
        };
    },

    computed: {
        getCompanyUserProgramList() {
            return this.companyUserProgramCollection.$.models.map((companyUserProgram) => ({
                id: companyUserProgram.id,
                name: companyUserProgram.company_program.name_translated,
            }));
        },
    },

    created() {
        if (this.withDefault) {
            this.companyUserProgramCollection.whereHas({
                companyProgram: (query) => {
                    query.whereHas({
                        htProgramType: (query) => {
                            query.where([
                                ['slug', '!=', HtProgramType.SLUG_DEFAULT],
                            ]);
                        },
                    });
                },
            });
        }
        this.companyUserProgramCollection.get();
    },

    methods: {
        onSelectUserProgram(id) {
            this.$emit('input', id);
        },
    },
};
</script>
