<template>
    <div>
        <HtCard>
            <template #title>
                <HtCardTitleBar
                    :title="translate('Notification management')"
                    :show-search="true"
                    @search="setSearchValue"
                />
            </template>
            <template #default>
                <div
                    v-if="companyEmailTemplate.isLoaded()"
                    class="notification-container"
                >
                    <div
                        v-for="(groupNotification, index) in getGroupNotification"
                        :key="index"
                        class="notification-container-child"
                    >
                        <t> {{ groupNotification.title }} </t>
                        <HtTable
                            :items="groupNotification.items"
                            :columns="columns"
                            :show-header="false"
                            @onCellClicked="onEdit"
                        >
                            <template #cell(trigger_event_translated)="scope">
                                <span v-if="scope.item.trigger_event">
                                    {{ scope.item.trigger_event_translated }}
                                </span>
                                <div v-else>
                                    <span v-if="scope.item.isOrderMinus">
                                        <t
                                            v-if="isProbationaryPeriod(scope.item)"
                                            :count="scope.item.order"
                                        >
                                            {count} working day before end of probationary period | {count} working days before end of probationary period
                                        </t>
                                        <t
                                            v-else
                                            :count="scope.item.order"
                                        >
                                            {count} working day before start date | {count} working days before start date
                                        </t>
                                    </span>
                                    <t
                                        v-else
                                        :count="scope.item.order"
                                    >
                                        {count} working day after start date | {count} working days after start
                                        date
                                    </t>
                                    <t :hours="scope.item.time">
                                        at {hours}
                                    </t>
                                </div>
                            </template>
                            <template #cell(recipients)="scope">
                                <div class="task-participants">
                                    <ParticipantList
                                        :users="scope.item.company_email_template_recipient.models"
                                        has-roles
                                        has-group
                                    />
                                </div>
                            </template>
                            <template #cell(is_enabled)="scope">
                                <Checkable
                                    :disabled="!scope.item.is_editable_enabled"
                                    :value="scope.item.is_enabled"
                                    :label="translate('Email')"
                                    simple
                                    @onChange="toggleNotification(scope.item.active, 'email', arguments[0])"
                                />
                            </template>
                            <template #cell(is_enabled_sms)="scope">
                                <Checkable
                                    :disabled="!scope.item.is_editable_enabled_sms"
                                    :value="scope.item.is_enabled_sms"
                                    :label="translate('Sms')"
                                    simple
                                    @onChange="toggleNotification(scope.item.active, 'sms', arguments[0])"
                                />
                            </template>
                        </HtTable>
                    </div>
                </div>
                <Modalable
                    ref="modalableEmailEdit"
                    class="modalable-1 medium"
                    :mode="2"
                >
                    <NotificationEdit
                        ref="notificationEdit"
                        v-model="currentItem"
                        :collection="companyEmailTemplate"
                        :slack-channels="slackChannels"
                        @on-close="refreshCollection"
                    />
                </Modalable>
            </template>
        </HtCard>
    </div>
</template>
<script>
import CompanyEmailTemplateCollection from '@/models/CompanyEmailTemplateCollection';
import NotificationEdit from '@/components/resources/notification/NotificationEdit.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import api from '@/store/api';
import CompanyEmailTemplate from '@/models/CompanyEmailTemplate';

export default {
    name: 'NotificationList',
    components: {
        HtCardTitleBar,
        NotificationEdit,
    },

    data() {
        return {
            columns: [
                {
                    key: 'title_translated',
                    size: 8,
                    class: 'full-mobile',
                },
                {
                    key: 'trigger_event_translated',
                    size: 8,
                    class: 'hide-mobile',
                },
                {
                    key: 'recipients',
                    size: 2.5,
                    class: 'full-mobile',
                },
                {
                    key: 'is_enabled',
                    size: 2,
                    class: 'hide-mobile',
                },
                {
                    key: 'is_enabled_sms',
                    size: 2,
                    class: 'hide-mobile',
                },
            ],
            slackChannels: [],
            search: null,
            currentItem: null,
            companyEmailTemplate: new CompanyEmailTemplateCollection([
                'id',
                'title',
                'variables',
                'section',
                'trigger_event',
                'subject_en',
                'content_en',
                'content_sms_en',
                'content_slack_en',
                'subject_fr',
                'content_fr',
                'content_sms_fr',
                'content_slack_fr',
                'template_slug',
                'slack_channel',
                'is_enabled',
                'is_enabled_slack',
                'is_enabled_sms',
                'is_editable_enabled',
                'is_editable_enabled_sms',
                'is_editable_enabled_slack',
                'is_editable_subject',
                'is_editable_content',
                'is_editable_date',
                'is_editable_recipient',
                'is_onboardee_email',
                'company_file_id',
                'order',
                'time',
                'company_sender_id',
                'show_in_group',
            ]).where([
                ['is_viewable', '=', 1],
            ]).with({
                companySender: (query) => {
                    query.select([
                        'id',
                        'firstname',
                        'lastname',
                        'image',
                    ]);
                },
                companyFile: (query) => {
                    query.select([
                        'id',
                        'original_name',
                        'uuid',
                        'link',
                    ]);
                },
                companyEmailTemplateRecipient: (query) => {
                    query
                        .select([
                            'id',
                            'company_email_template_id',
                            'company_group_id',
                            'company_role_id',
                            'company_user_id',
                        ])
                        .with({
                            companyRole: (query) => {
                                query.select([
                                    'id',
                                    'name',
                                    'alias',
                                    'is_heyteam',
                                    'deleted_at',
                                ]).where([
                                    ['deleted_at', '=', null],
                                ]);
                            },
                            companyUser: (query) => {
                                query.select([
                                    'id',
                                    'firstname',
                                    'lastname',
                                    'email',
                                    'image',
                                    'professional_email',
                                ]);
                            },
                            companyGroup: (query) => {
                                query.select([
                                    'id',
                                    'alias',
                                    'name',
                                ]);
                            },
                        });
                },
            }),
            hours: [
                '00:00 h',
                '01:00 h',
                '02:00 h',
                '03:00 h',
                '04:00 h',
                '05:00 h',
                '06:00 h',
                '07:00 h',
                '08:00 h',
                '09:00 h',
                '10:00 h',
                '11:00 h',
                '12:00 h',
                '13:00 h',
                '14:00 h',
                '15:00 h',
                '16:00 h',
                '17:00 h',
                '18:00 h',
                '19:00 h',
                '20:00 h',
                '21:00 h',
                '22:00 h',
                '23:00 h',
            ],
        };
    },
    computed: {
        getGroupNotification() {
            const data = [
                {
                    title: 'Date',
                    items: this.getFilteredNotification('date', this.search),
                },
                {
                    title: 'System',
                    items: this.getFilteredNotification('system', this.search),
                },
                {
                    title: 'Program',
                    items: this.getFilteredNotification('program', this.search),
                },
            ];

            return data.filter((elem) => elem.items.length > 0);
        },
    },

    async created() {
        await this.companyEmailTemplate.get();
    },
    methods: {
        refreshCollection() {
            this.companyEmailTemplate.get();
        },

        setSearchValue(search) {
            this.search = search.toString()
                .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                .toUpperCase();
        },
        toggleNotification(notifItem, type, isActive) {
            api.configuration.emailTemplate.toggle({
                id: notifItem.id,
                type,
                isActive,
            }).then(() => {
                const field = CompanyEmailTemplate.TYPE_FIELD_MAPPING[type];
                notifItem[field] = isActive;
            });
        },
        getFilteredNotification(group, search) {
            return this.companyEmailTemplate.$.models.filter(((elem) => {
                if ((search && elem.title_translated.toUpperCase().indexOf(search) >= 0) || !search) {
                    return elem.show_in_group === group;
                }

                return false;
            }));
        },

        isProbationaryPeriod(item) {
            return ['End of initial probationary period', 'End of renewed probationary period'].includes(item.title);
        },

        getSlackChannels() {
            return new Promise((resolve, reject) => {
                this.API.post('company_software/slack/', {
                    method: 'getDefaultConfig',
                }, {
                    200: ({ public_channels, private_channels }) => {
                        this.slackChannels = public_channels.concat(private_channels);
                        resolve();
                    },
                    onError: () => {
                        reject();
                    },
                });
            });
        },

        getTimeline(item) {
            return (
                this.translate(item.trigger_event)
                || (item.order >= 0 ? `J+${item.order}` : `J${item.order}`)
            );
        },

        // Check and merge participants of grouped emails
        getRecipients(item) {
            return item.company_email_template_recipient.models;
        },

        onEdit(row) {
            this.currentItem = row.item.active;
            this.$refs.modalableEmailEdit.open();
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.notification-container {
    display: flex;
    flex-direction: column;
    gap: 2em;

    &-child {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
}
</style>
