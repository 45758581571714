<template>
    <div :class="['widget-progress', {'active': isActive}, {'filter': !isActive}]">
        <div class="widget-progress-circle">
            <div
                v-if="percent === 100"
                class="done"
            >
                &#129395;
            </div>
            <div
                v-else
                class="in-progress"
            >
                <div
                    class="loading-pie"
                    :style="`--p:${percent};`"
                >
                    <span class="center">
                        {{ Math.round(percent) }} <span class="center-percent">%</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ProgressTemplate',
    props: {
        percent: {
            type: Number,
            default: 0,
        },
        isActive: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.widget-progress {
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
        border-radius: 50px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &.filter {
        filter: opacity(30%);
    }

    &-circle {
        height: 56px;
        width: 56px;
        border-radius: 100%;
        overflow: hidden;
        .done {
            font-size: 24px;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .in-progress{
            background: $neutral-white;
            position: relative;
            .loading-pie{
                height: 56px;
                width: 56px;
                display: inline-grid;
                place-content: center;

                &:before{
                    content: "";
                    position: absolute;
                    inset: 0;
                    border-radius: 100%;
                    background: conic-gradient($semantic-success calc(var(--p)*1%),$semantic-success-light 0);
                }
                .center {
                    border-radius: 100%;
                    color: $semantic-success;
                    background-color: $neutral-white;
                    height: 46px;
                    width: 46px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 100%;
                    letter-spacing: -1px;
                    z-index: 2;

                    &-percent {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
</style>
