<template>
    <ListItem
        class="wrapper"
        :selected="selected"
        @on-click="onClick"
    >
        <div
            v-user-image="
                $Utils.getCompanyComponentRoleInitials('user', 1.5, {
                    firstname: participantInfo.firstname,
                    lastname: participantInfo.lastname,
                    image: participantInfo.image,
                })
            "
            class="participant-img"
        />
        <ParticipantItemRightContent
            :enrollee-info="enrolleeInfo"
            :participant-info="participantInfo"
            :is-targeting-program="isTargetingProgram"
        />
    </ListItem>
</template>

<script>
import ListItem from '../ListItem.vue';
import ParticipantItemRightContent from './ParticipantItemRightContent.vue';

export default {
    name: 'ParticipantItem',
    components: { ParticipantItemRightContent, ListItem },
    props: {
        enrolleeInfo: {
            type: Object,
            required: true,
        },
        participantInfo: {
            type: Object,
            required: true,
        },
        isTargetingProgram: {
            type: Boolean,
            required: true,
        },
        selected: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        onClick() {
            this.$emit('on-click');
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
.participant-img {
    width: 60px;
    height: 60px;
    padding: 15px;
    border-radius: 50%;
    background: $grey;
}
.wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}
</style>
