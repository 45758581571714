import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import HtPermission from './HtPermission';

export default class HtPermissionCollection extends ModelCollection {
    modelConfig() {
        return {
            model: HtPermission,
        };
    }
}
