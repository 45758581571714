<template>
    <form v-if="value">
        <HtFormInput
            id="name"
            ref="nameInput"
            v-model="value.name"
            v-validate.disable="'required|max:128'"
            class="reset-ht-form-bottom-margin"
            name="name"
            :label="translate('Contract name')"
            :disabled="value.is_heyteam"
            :data-vv-as="translate('contract name')"
            :show-optional="false"
        />

        <HtFormSwitch
            id="probation-period"
            v-model="value.has_probation"
            class="reset-ht-form-bottom-margin"
            :label="translate('Probation period')"
            :off-label="translate('No')"
            :on-label="translate('Yes')"
            name="probation-period"
            :show-optional="false"
            :is-branded="true"
            :description="
                translate(
                    `The duration of the probation period will be determined upon the onboarding creation.`,
                )
            "
        />
        <HtFormSelect
            id="contract-type"
            v-model="value.has_end_date"
            v-validate.disable="'required'"
            class="reset-ht-form-bottom-margin"
            name="contract-type"
            :options="contractTypes"
            :placeholder="translate('Contract type')"
            :data-vv-as="translate('contract type')"
            :description="
                translate(
                    `If the contract is short term, its end date will be determined upon the onboarding creation.`,
                )
            "
        />

        <EntityModelCustomFieldGroup :value="value" />
    </form>
</template>

<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import EntityModelCustomFieldGroup from '../EntityModelCustomFieldGroup.vue';

export default {
    name: 'EditContract',

    components: {
        HtFormSwitch,
        EntityModelCustomFieldGroup,
    },
    inject: ['$validator'],

    props: {
        /**
         * @type {import('vue').PropOptions<{
         *   id: number,
         *   name: string,
         *   has_end_date: boolean,
         *   has_probation: boolean,
         *   is_heyteam: boolean,
         *   custom_fields: {
         *        id: number,
         *        company_field_template_id: number,
         *        name: string,
         *        key: string,
         *        type: string,
         *        value: any,
         *        is_editable: boolean
         *    }[]
         *  }>}
         */
        value: {
            type: Object,
            required: true,
        },
    },

    computed: {
        contractTypes() {
            return [
                { id: true, name: this.translate('Short-term') },
                { id: false, name: this.translate('Long-term') },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
form {
    display: flex;
    flex-direction: column;
}

.ht-form-error {
    margin-top: 0.8rem;
}

.reset-ht-form-bottom-margin + .reset-ht-form-bottom-margin,
.ht-form-error + .reset-ht-form-bottom-margin {
    margin-top: 3.2rem;
}

.reset-ht-form-bottom-margin {
    margin-bottom: 0;
}

.button-container {
    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 0.8rem;

    margin-bottom: 3.2rem;
}
</style>
