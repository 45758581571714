<template>
    <div>
        <UserSurveyResultListItem
            v-for="(item, index) in value.models"
            :key="index"
            v-model="value.models[index]"
            :question-index="index"
            :class="[
                'user-survey-result-item-content',
                {
                    'pb-20': (index < value.models.length - 1)
                }
            ]"
        />
    </div>
</template>

<script>
import CompanyUserSurveyQuestionCollection from '@/models/CompanyUserSurveyQuestionCollection';
import UserSurveyResultListItem from './UserSurveyResultListItem.vue';

export default {
    name: 'UserSurveyResultList',
    components: {
        UserSurveyResultListItem,
    },
    props: {
        value: {
            type: CompanyUserSurveyQuestionCollection,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped src="./UserSurveyResult.scss"></style>
