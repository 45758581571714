import Model from '@tony.caron/node-model-proxy/Model';
import CompanyQuiz from './CompanyQuiz';
import CompanyQuizQuestion from './CompanyQuizQuestion';
import CompanyUserQuizQuestionAnswer from './CompanyUserQuizQuestionAnswer';
import CompanyUserQuiz from './CompanyUserQuiz';
import CompanyQuizQuestionAnswerCollection from './CompanyQuizQuestionAnswerCollection';
import CompanyFile from './CompanyFile';

export default class CompanyQuizQuestionAnswer extends Model {
    modelConfig() {
        return {
            collection: CompanyQuizQuestionAnswerCollection,
        };
    }

    modelDefaultValues() {
        return {
            text: '',
            translations: {
                text: {},
            },
            file_translations: {},
        };
    }

    modelCustomAttributes() {
        return {
            tempId: 0,
        };
    }

    modelRelations() {
        return {
            companyQuiz: () => this.belongsTo(CompanyQuiz, 'company_quiz_id', 'id'),
            companyUserQuiz: () => this.belongsTo(CompanyUserQuiz, 'company_user_id'),
            companyQuizQuestion: () => this.belongsTo(CompanyQuizQuestion, 'company_quiz_question_id', 'id'),
            companyUserQuizQuestionAnswer: () => this.hasMany(CompanyUserQuizQuestionAnswer, 'company_quiz_question_answer_id'),
            companyFiles: () => this.hasMany(CompanyFile),
            companyFilesFromLocale: () => this.hasMany(CompanyFile),
        };
    }
}
