var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-requirement-iban-edit"},[_c('div',{staticClass:"mb-10"},[_c('HtFormInput',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
                required: _vm.companyUserRequirement.value.iban.length > 0 || _vm.companyUserRequirement.value.bic.length > 0,
                alpha_spaces_hyphen: true,
            }),expression:"{\n                required: companyUserRequirement.value.iban.length > 0 || companyUserRequirement.value.bic.length > 0,\n                alpha_spaces_hyphen: true,\n            }",modifiers:{"disable":true}}],staticClass:"no-margin",attrs:{"id":`iban-owner-${_vm.index}`,"name":`iban-owner-${_vm.index}`,"cypress":`iban-owner-${_vm.index}`,"type":"text","data-vv-as":_vm.translate('owner'),"placeholder":_vm.translate('Owner'),"disabled":!_vm.isParticipant || _vm.isDisabled},on:{"input":_vm.onChanged},model:{value:(_vm.companyUserRequirement.value.owner),callback:function ($$v) {_vm.$set(_vm.companyUserRequirement.value, "owner", $$v)},expression:"companyUserRequirement.value.owner"}})],1),_c('div',{staticClass:"mb-10"},[_c('HtFormInput',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
                required: _vm.companyUserRequirement.value.owner.length > 0 || _vm.companyUserRequirement.value.bic.length > 0,
                iban: true,
            }),expression:"{\n                required: companyUserRequirement.value.owner.length > 0 || companyUserRequirement.value.bic.length > 0,\n                iban: true,\n            }",modifiers:{"disable":true}}],staticClass:"no-margin",attrs:{"id":`iban-number-${_vm.index}`,"name":`iban-number-${_vm.index}`,"cypress":`iban-number-${_vm.index}`,"type":"text","data-vv-as":_vm.translate('IBAN'),"placeholder":_vm.translate('IBAN'),"disabled":!_vm.isParticipant || _vm.isDisabled,"error-append":_vm.backendIbanError},on:{"input":_vm.onChanged},model:{value:(_vm.companyUserRequirement.value.iban),callback:function ($$v) {_vm.$set(_vm.companyUserRequirement.value, "iban", $$v)},expression:"companyUserRequirement.value.iban"}})],1),_c('div',[_c('HtFormInput',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
                required: _vm.companyUserRequirement.value.owner.length > 0 || _vm.companyUserRequirement.value.iban.length > 0,
                bic: true
            }),expression:"{\n                required: companyUserRequirement.value.owner.length > 0 || companyUserRequirement.value.iban.length > 0,\n                bic: true\n            }",modifiers:{"disable":true}}],staticClass:"no-margin",attrs:{"id":`iban-bic-${_vm.index}`,"name":`iban-bic-${_vm.index}`,"cypress":`iban-bic-${_vm.index}`,"type":"text","data-vv-as":_vm.translate('BIC'),"placeholder":_vm.translate('BIC'),"disabled":!_vm.isParticipant || _vm.isDisabled,"error-append":_vm.backendBicError},on:{"input":_vm.onChanged},model:{value:(_vm.companyUserRequirement.value.bic),callback:function ($$v) {_vm.$set(_vm.companyUserRequirement.value, "bic", $$v)},expression:"companyUserRequirement.value.bic"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }