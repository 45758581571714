<template>
    <div class="resource-info">
        <div class="label">
            <t>{{ label }}</t>
        </div>
        <div class="info">
            <slot />
        </div>
    </div>
</template>

<script>

export default {
    props: {
        label: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.resource-info{
    display: flex;
    font-size: 12px;
    .label {
        flex: 0 0 25%;
        color: $neutral-700;
    }
}

</style>
