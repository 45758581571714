<template>
    <IndexLayout>
        <template #headbar>
            <HeadBar />
        </template>
        <template #extra>
            <ChatModal v-if="shared.config.allowChat" />
            <ChatNotification v-if="shared.config.allowChat" />
        </template>
    </IndexLayout>
</template>

<script>
import ChatModal from '@/components/chat/ChatModal.vue';
import ChatNotification from '@/components/chat/ChatNotification.vue';
import IndexLayout from '@/components/globals/IndexLayout.vue';
import HeadBar from '@/components/miscellaneous/HeadBar.vue';

export default {
    name: 'Index',
    components: {
        ChatModal,
        ChatNotification,
        IndexLayout,
        HeadBar,
    },

    computed: {
        isCompanySNCF() {
            return this.shared.session.companyUser.company_id === 213;
        },
        isCompanySNCFQA() {
            return this.shared.session.companyUser.company_id === 409;
        },
    },

    created() {
        // SET R2H2 CHATBOT SNCF
        if (this.isCompanySNCF) {
            const sncfScript = document.createElement('script');
            sncfScript.setAttribute('src', 'https://chatbox.clevy.io/script.min.js?token=271d4368-1e89-425a-ac14-2f508474279c');
            sncfScript.setAttribute('async', '');
            sncfScript.setAttribute('id', 'clevy-chatbox');
            document.head.appendChild(sncfScript);
        }

        if (this.isCompanySNCFQA) {
            const sncfScript = document.createElement('script');
            sncfScript.setAttribute('src', 'https://chatboxv2.clevy.io/script.min.js?token=271d4368-1e89-425a-ac14-2f508474279c');
            sncfScript.setAttribute('async', '');
            sncfScript.setAttribute('id', 'clevy-chatbox');
            document.head.appendChild(sncfScript);
        }
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.index {
    height: 100%;
    display: flex;
    flex-direction: column;

    &-content {
        padding-top: 45px;
        flex-grow: 1;
    }
}

@media (max-width: $phone) {
    .index-content {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
</style>
