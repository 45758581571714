export default {
    name: 'Carousel',
    image: 'carousel.png',
    fullPageBorder: false,
    content: `
        <section class="ht-section-carousel" aria-label="Gallery">
            <ol class="carousel__viewport">
                <li id="carousel__slide1" class="carousel__slide" tabindex="0">
                    <div class="carousel__snapper">YOUR TEXT HERE 1</div>
                </li>
                <li id="carousel__slide2" class="carousel__slide" tabindex="0">
                    <div class="carousel__snapper">YOUR TEXT HERE 2</div>
                </li>
                <li id="carousel__slide3" class="carousel__slide" tabindex="0">
                    <div class="carousel__snapper">YOUR TEXT HERE 3</div>
                </li>
                <li id="carousel__slide4" class="carousel__slide" tabindex="0">
                    <div class="carousel__snapper">YOUR TEXT HERE 4</div>
                </li>
            </ol>
            <aside class="carousel__navigation">
                <ol class="carousel__navigation-list">
                    <li class="carousel__navigation-item"><a class="carousel__navigation-button" href="#carousel__slide1">&nbsp;</a></li>
                    <li class="carousel__navigation-item"><a class="carousel__navigation-button" href="#carousel__slide2">&nbsp;</a></li>
                    <li class="carousel__navigation-item"><a class="carousel__navigation-button" href="#carousel__slide3">&nbsp;</a></li>
                    <li class="carousel__navigation-item"><a class="carousel__navigation-button" href="#carousel__slide4">&nbsp;</a></li>
                </ol>
            </aside>
        </section>
    `,
    css: `
        * {
            box-sizing: border-box;
            scrollbar-color: transparent transparent;
            scrollbar-width: 0px;
        }

        *::-webkit-scrollbar {
            width: 0;
        }

        *::-webkit-scrollbar-track {
            background: transparent;
        }

        *::-webkit-scrollbar-thumb {
            background: transparent;
            border: none;
        }

        * {
            -ms-overflow-style: none;
        }

        ol, li {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .ht-section-carousel {
            position: relative;
            filter: drop-shadow(0 0 10px #0003);
            perspective: 100px;
            margin: 40px 0;
        }

        .carousel__viewport {
            position: relative;
            display: flex;
            overflow-x: scroll;
            counter-reset: item;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            height: 250px;
            width: 100%;
            border-radius: 12px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .carousel__slide {
            position: relative;
            flex: 0 0 100%;
            width: 100%;
            background-color: #f99;
            counter-increment: item;
        }

        .carousel__slide:nth-child(even) {
            background-color: #99f;
        }

        .carousel__slide:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-40%,70px);
            color: #fff;
            font-size: 2em;
        }

        .carousel__snapper {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            scroll-snap-align: center;
            padding: 20px;
        }

        .carousel__snapper {
            animation-name: tonext, snap;
            animation-timing-function: ease;
            animation-duration: 4s;
            animation-iteration-count: infinite;
        }

        .carousel__slide:last-child .carousel__snapper {
            animation-name: tostart, snap;
        }

        @media (prefers-reduced-motion: reduce) {
            .carousel__snapper {
                animation-name: none;
            }
        }

        .ht-section-carousel:hover .carousel__snapper,
        .ht-section-carousel:focus-within .carousel__snapper {
            animation-name: none;
        }

        .carousel__navigation {
            position: absolute;
            right: 0;
            bottom: 10px;
            left: 0;
            text-align: center;
        }

        .carousel__navigation-list,
        .carousel__navigation-item {
            display: inline-block;
        }

        .carousel__navigation-button {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            background-color: #333;
            background-clip: content-box;
            border: 0.25rem solid transparent;
            border-radius: 50%;
            font-size: 0;
            transition: transform 0.1s;
        }

        .ht-section-carousel::before, .carousel__prev {
            left: -1rem;
        }

        .ht-section-carousel::after, .carousel__next {
            right: -1rem;
        }

        .ht-section-carousel::before, .ht-section-carousel::after {
            content: '';
            z-index: 1;
            background-color: #333;
            background-size: 1.5rem 1.5rem;
            background-repeat: no-repeat;
            background-position: center center;
            color: #fff;
            font-size: 2.5rem;
            line-height: 4rem;
            text-align: center;
            pointer-events: none;
        }

        .ht-section-carousel::before {
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E");
        }

        .ht-section-carousel::after {
            background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E");
        }

        @keyframes tonext {
            75% {
                left: 0;
            }
            95% {
                left: 100%;
            }
            98% {
                left: 100%;
            }
            99% {
                left: 0;
            }
        }

        @keyframes tostart {
            75% {
                left: 0;
            }
            95% {
                left: -300%;
            }
            98% {
                left: -300%;
            }
            99% {
                left: 0;
            }
        }

        @keyframes snap {
            96% {
                scroll-snap-align: center;
            }
            97% {
                scroll-snap-align: none;
            }
            99% {
                scroll-snap-align: none;
            }
            100% {
                scroll-snap-align: center;
            }
        }
    `,
};
