<template>
    <HtReadResources
        v-if="training"
        :resource="training"
        :resource-type="resourceType"
        :resource-data="resourceData"
        :label-category="'Training'"
        :has-sender="false"
        :has-recipient="false"
    />
</template>

<script>

import HtReadResources from '@/components/globals/modals/HtReadResources/HtReadResources.vue';

export default {
    name: 'TrainingRead',
    components: { HtReadResources },
    props: {
        resourceType: {
            type: String,
            required: true,
        },
        resourceId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            training: null,
            resourceData: {
                type: null,
                data: null,
            },
        };
    },

    async created() {
        const request = await this.$http.get(`/configuration/trainings/${this.resourceId}`);
        this.training = request.data.data.resource;
        if (request.data.data.type === 'file') {
            this.resourceData.data = request.data.data.company_file;
            this.resourceData.type = request.data.data.company_file.detected_mime_type.includes('video')
                ? 'video'
                : 'other';
        } else {
            this.resourceData.type = 'url';
            this.resourceData.data = request.data.data.url;
        }
    },
};
</script>
