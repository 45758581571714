<template>
    <div>
        <template v-if="isLoaded">
            <HtTable
                ref="childTable"
                :columns="columns"
                :items="items"
                :empty-message="emptyMessage"
                :clickable="clickable"
                :cypress="cypress"
                :external-sort-key="sortKey"
                :external-sort-direction="sortDirection"
                @onCellClicked="onCellClicked"
                @onRowClicked="onRowClicked"
            >
                <template
                    v-for="(index, slot) in $scopedSlots"
                    #[slot]="props"
                >
                    <slot
                        :name="slot"
                        v-bind="props"
                    />
                </template>

                <template #responsive="scope">
                    <slot
                        name="responsive"
                        v-bind="scope"
                    />
                </template>
            </HtTable>
            <HtPagination
                v-model="page"
                :per-page="perPage"
                :last-page="lastPage"
            />
        </template>
        <IconLoading v-else />
    </div>
</template>

<script>
import CypressMixin from '@/mixins/CypressMixin';
import HtPagination from './HtPagination.vue';
import HtTable from './HtTable.vue';
import htTablePaginationEventBus from '../../eventBus/htTablePagination';

export default {
    name: 'HtTablePaginator',
    components: { HtPagination, HtTable },
    mixins: [
        CypressMixin,
    ],
    props: {
        columns: {
            type: Array,
            required: true,
        },
        perPage: {
            type: Number,
            default: () => 10,
        },
        emptyMessage: {
            type: String,
            default: () => 'There is nothing to display here for now...',
        },
        clickable: {
            type: Boolean,
            default: true,
        },
        url: {
            type: String,
        },
        params: {
            type: Object,
            default: () => {},
        },
        filters: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            page: 1,
            isLoaded: false,
            sortKey: null,
            sortDirection: null,
            /**
             * Résultat de la requête de pagination
             */
            paginationData: null,
        };
    },
    computed: {
        lastPage() {
            return this.paginationData?.meta?.last_page;
        },

        /**
         * Items à afficher dans la table
         * @returns {Array}
         */
        items() {
            return this.paginationData.data;
        },

    },
    watch: {
        page() {
            this.load();
        },
        filters() {
            this.refreshWithoutLoader();
        },
    },
    mounted() {
        this.$watch(
            () => (this.isLoaded ? this.$refs.childTable.sortKey || this.$refs.childTable.sortDirection : null),
            () => {
                if (this.$refs.childTable) {
                    this.sortKey = this.$refs.childTable.sortKey;
                    this.sortDirection = this.$refs.childTable.sortDirection;
                    this.load();
                }
            },
        );
    },
    created() {
        this.refresh();
        htTablePaginationEventBus.$on('refresh', this.refresh);
    },

    beforeDestroy() {
        htTablePaginationEventBus.$off('refresh', this.refresh);
    },
    methods: {
        onCellClicked(data) {
            return this.$emit('onCellClicked', data);
        },

        onRowClicked(data) {
            return this.$emit('onRowClicked', data);
        },

        /**
         * Charge les items à afficher
         * @returns {void}
         */
        async load() {
            this.isLoaded = false;
            await this.fetchData();
            this.isLoaded = true;
        },
        async fetchData() {
            const response = await this.$http.get(
                this.url,
                {
                    params: {
                        page: this.page,
                        sort_key: this.sortKey,
                        sort_direction: this.sortDirection,
                        ...this.params,
                        ...this.filters,
                    },
                },
            );

            this.paginationData = response.data;
        },

        async refresh() {
            this.page = 1;
            await this.load();
            this.$emit('pagination-refreshed', this.paginationData);
        },
        async refreshWithoutLoader() {
            this.page = 1;
            await this.fetchData();
            this.$emit('pagination-refreshed', this.paginationData);
        },
    },
};
</script>
