<template>
    <div v-if="isLoaded">
        <HtCard class="mb-5">
            <template #title>
                <HtCardTitleBar
                    :title="title"
                    :show-close="true"
                    :show-button="true"
                    :button-text="translate('Create')"
                    @onAdd="onCreate"
                    @search="setSearchValue"
                    @on-close="$emit('on-change-step', 'SelectResourceTypeModal')"
                />
            </template>
            <template #default>
                <SoftwareListModels
                    :items="resourceCollection.$.models"
                    :external-search="search"
                    :columns-to-hide="['filters']"
                    @onCellClicked="onCreate"
                />
            </template>
        </HtCard>
    </div>
</template>

<script>
import ResourceFilter from '@/components/resources/ResourceFilter.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import ProgramBuilderListResourceMixin from '@/mixins/ProgramBuilderListResourceMixin';
import SoftwareListModels, { softwareConfig } from '@/resourcesList/SoftwareListModels.vue';

export default {
    name: 'Software',
    components: {
        ResourceFilter,
        HtCardTitleBar,
        SoftwareListModels,
    },

    mixins: [
        ProgramBuilderListResourceMixin,
    ],

    data() {
        return {
            collectionName: 'CompanySoftwareCollection',
            itemFields: softwareConfig.fields,
            itemRelations: softwareConfig.relations,
            resourceTitleSingular: 'software',
            resourceTitlePlural: 'software',
        };
    },

    methods: {
        AddCollectionWhereHas() {
            this.resourceCollection.whereHas({
                resource: (query) => {
                    query.whereHas({
                        programs: (query) => {
                            query.where([
                                ['company_program_id', '!=', this.companyProgramId],
                            ]);
                        },
                    });
                },
            });
        },

        AddCollectionOrderBy() {
            this.resourceCollection.orderBy([
                ['name', 'asc'],
            ]);
        },
    },
};
</script>
