<template>
    <li
        data-cy="item-search-result"
        @click="$emit('select')"
        @mousedown="$emit('select')"
    >
        <UserInitialsOrImage
            :initials="initials(item)"
            :image="itemImage"
        />
        <span>{{ displayLabel }}</span>
        <slot name="right-content" />
    </li>
</template>

<script>
import UserInitialsOrImage from '@/components/miscellaneous/SearchInput/UserInitialsOrImage.vue';

export default {
    name: 'ResultItem',
    components: {
        UserInitialsOrImage,
    },
    props: {
        labelKey: {
            type: [Function, String],
            default: (item) => `${item.firstname} ${item.lastname}`,
        },
        imageKey: {
            type: [String, Boolean],
            required: false,
            default() {
                return 'image';
            },
        },
        initials: {
            type: Function,
            default: (item) => {
                if (!item) {
                    return '';
                }

                let initials = item.firstname[0].toUpperCase();

                if (item.lastname && item.lastname !== '') {
                    initials += item.lastname[0].toUpperCase();
                }
                return initials;
            },
        },
        item: {
            type: Object,
            validator: (item) => 'firstname' in item && 'lastname' in item,
            required: true,
        },
    },
    computed: {
        displayLabel() {
            if (typeof (this.labelKey) === 'string') {
                return this.item[this.labelKey];
            }

            return this.labelKey(this.item);
        },
        itemImage() {
            if (this.imageKey in this.item) {
                return this.item[this.imageKey];
            }

            return null;
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/styles/var';

li {
    border-top: 1px solid $grey-ultra-light;
    border-bottom: 1px solid $grey-ultra-light;
    padding: 15px 20px;
    display: flex;
    position: relative;
    align-items: center;
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: 0 0 6px 0 #ccc;
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        transition: .3s ease-in-out;
    }
    span {
        flex-grow: 1;
        display: flex;
    }
}
</style>
