import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserProgram from './CompanyUserProgram';

export default class CompanyUserProgramCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserProgram,
        };
    }
}
