<template>
    <ul>
        <li
            v-for="(item, index) in items"
            :key="index"
            :class="getClasses(item)"
            @click="onClick(item)"
        >
            {{ item.label }}
        </li>
    </ul>
</template>
<script>
import isEqual from 'lodash.isequal';
// eslint-disable-next-line no-unused-vars
import { MenuListItem } from './MenuListItemClass';

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            /**
             * @type {MenuListItem}
             */
            selectedItem: this.items[0],
        };
    },

    methods: {
        /**
         * set selected item and emit event
         *
         * @param {MenuListItem} clickedItem
         * @returns {void}
         */
        onClick(clickedItem) {
            this.selectedItem = this.items.find((item) => isEqual(item, clickedItem));
            this.$emit('on-select', this.selectedItem);
        },

        /**
         * @param {MenuListItem} item
         * @returns {string[]}
         */
        getClasses(item) {
            if (isEqual(item, this.selectedItem)) {
                return ['selected', 'branding-color'];
            }
            return ['unselected'];
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
li {
    margin-bottom: 4.3rem;
    cursor: pointer;
    position: relative;
    font-size: 1.4rem;
}
.selected {
    font-weight: bold;
    // style of the bullet
    &::before {
        content: "\2022";
        font-size: 1.2rem;
        position: absolute;
        left: -15px;
        top: 10px;
        line-height: 0px;
    }
}
.unselected {
    color: $color-text-grey;
}
</style>
