var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkbox-label",class:{
        disabled: _vm.disabled,
    }},[_c('input',{attrs:{"id":_vm.id,"type":"checkbox","name":_vm.name,"disabled":_vm.disabled},domProps:{"checked":_vm.checked ? 'checked' : ''}}),_c('span',{staticClass:"checkbox-display",class:{
            disabled: _vm.disabled,
            checked: _vm.checked,
            partiallyChecked: _vm.partiallyChecked,
        }},[(_vm.checked)?_c('FontAwesomeIcon',{staticClass:"check",attrs:{"icon":"check"}}):_vm._e()],1),(_vm.label)?_c('div',[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }