import CompanyUserCollection from '@/models/CompanyUserCollection';

export default {
    methods: {
        async createNewChannel(channelUsers, type = 'duo', name = undefined, image = undefined) {
            const users = await new CompanyUserCollection([
                'id',
                'email',
                'firstname',
                'lastname',
                'fullname',
                'is_virtual',
            ])
                .with({
                    companyFile: (query) => {
                        query.select([
                            'id',
                            'original_name',
                            'detected_extension',
                            'uuid',
                        ]);
                    },
                })
                .whereIn([['id', channelUsers]])
                .get();

            // creates the channel model
            const channel = this.shared.socket.channelCollection.new();
            channel.created_by_company_user_id = this.shared.session
                .companyUser.id;
            channel.type = type;
            channel.name = name;
            channel.avatar_image = image;
            channel.avatar_image_id = image?.id;

            // adds all the channel users
            const channelCreator = channel
                .company_chat_channel_user.new();
            channelCreator.company_user_id = this.shared.session.companyUser.id;
            channelCreator.company_user = this.shared.session.companyUser;
            channel.company_chat_channel_user.models.push(channelCreator);

            for (let i = 0; i < users.models.length; i += 1) {
                const channelUser = channel.company_chat_channel_user.new();
                channelUser.company_user_id = users.models[i].id;
                channelUser.company_user = users.models[i];
                channelUser.company_user.isConnected = users.models[i].id in this.shared.socket
                    .connectedUsers;
                channel.company_chat_channel_user.models.push(channelUser);
            }

            // sets id to new to be able to make it active
            channel.id = 'new';

            // removes any other new channel before adding it to the collection
            const indexNewChannel = this.shared.socket.channelCollection.models
                .findIndex((oneChannel) => oneChannel.id === 'new');
            if (indexNewChannel !== -1) {
                this.shared.socket.channelCollection.models.splice(indexNewChannel, 1);
            }
            this.shared.socket.channelCollection.models.unshift(channel);
            this.shared.socket.channelCollection.setActive('new');
            this.$emit('onSwitchPanel');

            return channel;
        },
        duplicateChannel(channel) {
            const duplicatedChannel = this.shared.socket.channelCollection.new();
            duplicatedChannel.created_by_company_user_id = channel.created_by_company_user_id;

            for (let i = 0; i < channel.company_chat_channel_user.models.length; i += 1) {
                const channelUser = channel.company_chat_channel_user.models[i];
                duplicatedChannel.company_chat_channel_user.models.push(channelUser);
            }
            duplicatedChannel.type = channel.type;
            duplicatedChannel.name = channel.name;
            duplicatedChannel.avatar_image_id = channel.avatar_image_id;

            return duplicatedChannel;
        },
        async saveNewChannel() {
            const newChannel = this.duplicateChannel(this.channelActive);
            await newChannel.save();
            const indexChannel = this.shared.socket.channelCollection.models
                .findIndex((channel) => channel.id === 'new');
            this.shared.socket.channelCollection.models.splice(indexChannel, 1);
            this.shared.socket.channelCollection.setActive(newChannel.id);
            return newChannel;
        },
    },
};
