<template>
    <div class="chat-group-manager-see-files">
        <template v-if="!loading">
            <DocumentBlock
                v-for="file in files"
                :key="`file-${file.id}`"
                :file="file"
            />
        </template>
        <IconLoading v-else />
    </div>
</template>

<script>
import filePreviewerMixin from '@/components/globals/FilePreviewer/filePreviewerMixin';
import CompanyChatChannelMessageCollection from '@/models/CompanyChatChannelMessageCollection';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import IconLoading from '@/components/icons/IconLoading.vue';
import DocumentBlock from '../DocumentBlock.vue';

export default {
    name: 'ChatGroupManagerSeeFiles',
    components: { DocumentBlock, IconLoading },
    mixins: [filePreviewerMixin],
    shared: {
        socket: {
            channelMessageCollection: {
                type: Object,
                default: {},
            },
        },
    },
    props: {
        channelId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            messages: [],
            loading: true,
        };
    },
    computed: {
        files() {
            return this.messages.flatMap?.((message) => message.files.models
                .filter((file) => !this.isImage(file.detected_mime_type)));
        },
    },
    created() {
        new CompanyChatChannelMessageCollection(['id', 'type']).with({
            files: (query) => {
                query.select(UtilsCompanyFile.allowedMinimumFields().concat(['detected_mime_type']))
                    .where([['detected_mime_type', 'NOT LIKE', 'image%']]);
            },
        })
            .where([['company_chat_channel_id', '=', this.channelId], ['type', '=', 'file']])
            .orderBy([
                ['created_at', 'desc'], ['id', 'desc'],
            ])
            .get()
            .then((data) => {
                this.messages = data?.models || [];
                this.loading = false;
                this.$emit('fileLoaded', this.files.length);
            });
    },
};
</script>

<style lang="scss" scoped>
.chat-group-manager-see-files{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
</style>
