<template>
    <HoldingResourceList
        :route-list="'HoldingResourcesQuizzesList'"
        :collection="quizzes"
        :use-resource-program="true"
    >
        <template #table="holdingResourceList">
            <QuizzesList
                :items="holdingResourceList.items"
                :external-search="holdingResourceList.search"
                :columns-to-hide="['filters']"
                @onCellClicked="holdingResourceList.onCellClicked"
            />
        </template>
    </HoldingResourceList>
</template>
<script>
import HoldingResourceList from '@/components/globals/HoldingResourceList.vue';
import CompanyQuizCollection from '@/models/CompanyQuizCollection';
import QuizzesList, { quizConfig } from '@/resourcesList/QuizzesList.vue';

export default {
    name: 'PageQuizzesList',
    components: {
        HoldingResourceList,
        QuizzesList,
    },

    data() {
        return {
            quizzes: new CompanyQuizCollection(quizConfig.fields).with(quizConfig.relations),
        };
    },
};
</script>
