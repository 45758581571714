<template>
    <div class="page-content">
        <h1 class="small">
            {{ getTranslatedMessage(companyFlowCopy['bloc_teampackage_title']['content']['messages']['message_1']) }}
        </h1>
        <FlowRow
            v-if="messages['bloc_teampackage_a'] === true && teamMembers.length"
            :company-user="bubbleUser"
            :full="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_teampackage_a']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_teampackage_b'] === true && teamMembers.length"
            :full="true"
            :no-bg="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div class="user-container">
                    <div
                        v-for="(user, index) in teamMembers"
                        :key="index"
                        class="user-wrapper"
                    >
                        <div
                            v-if="user"
                            v-user-image="$Utils.getCompanyComponentRoleInitials(
                                'user', 4,
                                {
                                    firstname: user.firstname,
                                    lastname: user.lastname,
                                    image: user.image,
                                })"
                            class="item-image"
                        />

                        <div class="user-about">
                            <div class="user-name">
                                {{ user.firstname }} {{ user.lastname }}
                            </div>
                            <div
                                v-if="user.company_job_position"
                                class="user-title"
                            >
                                {{ user.company_job_position.name }}
                            </div>
                            <div class="user-email branding-color">
                                {{ user.role }}
                            </div>
                        </div>
                    </div>

                    <div class="empty" />
                    <div class="empty" />
                    <div class="empty" />
                    <div class="empty" />
                </div>
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_teampackage_c'] === true && departmentMembers.length"
            :company-user="bubbleUser"
            :full="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_teampackage_c']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_teampackage_d'] === true && departmentMembers.length"
            :full="true"
            :no-bg="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div class="user-container">
                    <div
                        v-for="(user, index) in departmentMembers"
                        :key="index"
                        class="user-wrapper"
                    >
                        <div
                            v-if="user"
                            v-user-image="$Utils.getCompanyComponentRoleInitials(
                                'user', 4,
                                {
                                    firstname: user.firstname,
                                    lastname: user.lastname,
                                    image: user.image,
                                })"
                            class="item-image"
                        />

                        <div class="user-about">
                            <div class="user-name">
                                {{ user.firstname }} {{ user.lastname }}
                            </div>
                            <div
                                v-if="user.company_job_position"
                                class="user-title branding-color"
                            >
                                {{ user.company_job_position.name }}
                            </div>
                        </div>
                    </div>

                    <div class="empty" />
                    <div class="empty" />
                    <div class="empty" />
                    <div class="empty" />
                </div>
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_teampackage_e'] === true && companyUser.company_user_equipment.models.length"
            :company-user="bubbleUser"
            :full="true"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_teampackage_e']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_teampackage_f'] === true && companyUser.company_user_equipment.models.length"
            :full="false"
            :no-bg="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div class="wrapper-subs">
                    <div
                        v-for="(item, index) in companyUser.company_user_equipment.models"
                        :key="index"
                        class="item-sub"
                    >
                        <div class="sub-icon">
                            <div
                                class="image"
                                :style="{ 'background-image': 'url('+item.company_equipment.image+')' }"
                            />
                        </div>
                        <div class="sub-txt">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_teampackage_g'] === true && companyUser.company_user_software.models.length"
            :company-user="bubbleUser"
            :full="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_teampackage_g']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_teampackage_h'] === true && companyUser.company_user_software.models.length"
            :full="false"
            :no-bg="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div class="wrapper-subs">
                    <div
                        v-for="item in companyUser.company_user_software.models"
                        :key="item.id"
                        :class="['item-sub', {checked:item.status == 'done'}]"
                    >
                        <div class="sub-icon">
                            <div
                                class="image"
                                :style="{ 'background-image': 'url(/static/icons/library/custom_soft.svg)' }"
                            />
                        </div>
                        <div class="sub-txt">
                            <div class="sub-type">
                                {{ item.company_software.resource.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </FlowRow>
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyUserCollection from '@/models/CompanyUserCollection';
import FlowRow from '@/components/flow/FlowRow.vue';
import FlowMixin from '@/components/mixins/FlowMixin.vue';
import CompanyRoleUserCollection from '@/models/CompanyRoleUserCollection';

export default {
    name: 'FlowTeamPackage',
    components: {
        FlowRow,
    },

    mixins: [FlowMixin],

    data() {
        return {
            teamMembers: [],
            teamMembersIds: [],
            departmentMembers: [],
            companyFlowCopy: {},
            messages: {},
            messagesEnabled: [],
            rowLabel: [],
            globalTimeout: null,
            iMessageIndex: 0,
            lastIndex: 0,
            companyRoleUserCollection: new CompanyRoleUserCollection([
                'id',
                'company_user_id',
                'company_role_id',
            ]).with({
                user: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']);
                },
                role: (query) => {
                    query.select(['id', 'name', 'alias', 'is_heyteam']);
                },
            }).whereHas({
                employees: (query) => {
                    query.where([
                        ['company_user_id', '=', this.companyUser.id],
                    ]);
                },
                user: (query) => {
                    query.where([
                        ['status', '!=', CompanyUser.STATUS_INACTIVE],
                    ]);
                },
            }),
            onboardeeDepartmentCollegues: new CompanyUserCollection([
                'id',
                'firstname',
                'lastname',
                'image',
            ]).with({
                companyJobPosition: (query) => {
                    query.select(['id', 'name', 'is_heyteam']);
                },
            }).where([
                ['id', '!=', this.companyUser.id],
                ['status', '!=', CompanyUser.STATUS_DRAFT],
                ['status', '!=', CompanyUser.STATUS_INACTIVE],
                ['company_department_id', '=', this.companyUser.company_department_id],
                ['company_office_id', '=', this.companyUser.company_office_id],
            ]),
        };
    },

    async created() {
        this.contents.map((model) => {
            this.companyFlowCopy[model.subtype] = model;

            if (!model.subtype.includes('_title')) {
                this.$set(this.messages, model.subtype, false);
                if (model.is_enabled) this.messagesEnabled.push(model.subtype);
            }
        });

        if (!this.messagesEnabled.length) {
            if (this.is_forward === true) this.$emit('onNextPage');
            else this.$emit('onPreviousPage');
        }

        await Promise.all([
            this.loadTeam(),
            this.loadOnboardingDepartmentTeam(),
        ]);

        this.rowLabel = Object.keys(this.messages);

        for (let i = this.messagesEnabled.length - 1; i > 0; i--) {
            if ((this.messagesEnabled[i] === 'bloc_teampackage_h' && this.companyUser.company_user_software.models.length)
                || (this.messagesEnabled[i] === 'bloc_teampackage_f' && this.companyUser.company_user_equipment.models.length)
                || (this.messagesEnabled[i] === 'bloc_teampackage_d' && this.departmentMembers.length)
                || (this.messagesEnabled[i] === 'bloc_teampackage_b' && this.teamMembers.length)) {
                this.lastIndex = i;
                break;
            }
        }

        if (this.lastIndex === 0 && this.is_forward === true) this.$emit('onNextPage');
        else if (this.lastIndex === 0 && this.is_forward === false) this.$emit('onPreviousPage');

        this.loadRows();
        this.runLoadRows();
    },

    methods: {
        runLoadRows() {
            this.globalTimeout = setInterval(() => {
                this.loadRows();
            }, 1500);
        },

        loadRows() {
            while (this.iMessageIndex < this.rowLabel.length && this.companyFlowCopy[this.rowLabel[this.iMessageIndex]].is_enabled === false) {
                this.iMessageIndex++;
            }

            if (this.iMessageIndex < this.rowLabel.length) {
                if (this.companyFlowCopy[this.rowLabel[this.iMessageIndex]].is_enabled) this.messages[this.rowLabel[this.iMessageIndex]] = true;

                if (this.messagesEnabled.indexOf(this.rowLabel[this.iMessageIndex]) === (this.messagesEnabled.length - 1)) this.$emit('onLoadPage', true);

                this.iMessageIndex++;
            } else clearInterval(this.globalTimeout);
        },

        loadTeam() {
            return new Promise((resolve, reject) => {
                this.companyRoleUserCollection.get().then(() => {
                    this.companyRoleUserCollection.models.map((supervisor) => {
                        this.teamMembersIds.push(supervisor.company_user_id);

                        const member = this.teamMembers.find((m) => m.id === supervisor.company_user_id);

                        if (typeof member !== 'undefined') {
                            const role = supervisor.role.alias_translated;
                            member.role += `, ${role}`;
                        } else {
                            const role = supervisor.role.alias_translated;
                            this.teamMembers.push({ ...supervisor.user, role });
                        }
                    });

                    resolve();
                }).catch(() => {
                    reject();
                });
            });
        },

        loadOnboardingDepartmentTeam() {
            return new Promise((resolve, reject) => {
                this.onboardeeDepartmentCollegues.get().then(() => {
                    this.departmentMembers = this.onboardeeDepartmentCollegues.models.filter((user) => !this.teamMembersIds.includes(user.id)).filter((user, index) => index < 6);

                    resolve();
                }).catch(() => {
                    reject();
                });
            });
        },
    },
};
</script>
