import Vue from 'vue';

export default {
    // Statistics
    completionRatePercentage(body = {}) {
        return Vue.prototype.$http.post('dashboard/statistics/completion-rate-percentage-widget', body);
    },
    activeProgramsCount(body = {}) {
        return Vue.prototype.$http.post('dashboard/statistics/active-programs-count-widget', body);
    },
    lateProgramsCount(body = {}) {
        return Vue.prototype.$http.post('dashboard/statistics/late-programs-count-widget', body);
    },
    getDeadlines(body = {}) {
        return Vue.prototype.$http.post('dashboard/statistics/deadlines-widget', body);
    },
    getMostActiveMembers(body = {}) {
        return Vue.prototype.$http.post('dashboard/statistics/most-active-members-widget', body);
    },
    getNotJoined(body = {}) {
        return Vue.prototype.$http.post('dashboard/statistics/not-joined-widget', body);
    },
    getNextKeyDates(body = {}) {
        return Vue.prototype.$http.post('dashboard/statistics/next-keydate-widget', body);
    },
    getTodoCount(body = {}) {
        return Vue.prototype.$http.get('dashboard/my-tasks/todo-count', body);
    },

    // Actions
    getProgramSummaries() {
        return Vue.prototype.$http.post('dashboard/statistics/program-summaries');
    },
    getMyTeam() {
        return Vue.prototype.$http.post('dashboard/statistics/my-team');
    },

    // Requirement Categories
    getRequirementCategories() {
        return Vue.prototype.$http.get('enrollee/requirements/all');
    },
    getRequirementCategoriesProgress() {
        return Vue.prototype.$http.get('enrollee/requirements/progress');
    },

    getRequirementsToRemind(page, perPage, filters) {
        return Vue.prototype.$http.get('dashboard/statistics/requirements', {
            params: {
                page,
                per_page: perPage,
                filters,
            },
        });
    },

    sendRequirementsReminders(body = {}) {
        return Vue.prototype.$http.post('dashboard/statistics/requirements/send-reminders', body);
    },

    getRequirementTasksToRemind(requirementId, page, perPage, filters) {
        return Vue.prototype.$http.get(`dashboard/statistics/requirements/${requirementId}/tasks`, {
            params: {
                page,
                per_page: perPage,
                filters,
            },
        });
    },

    sendTasksReminders(requirementId, body = {}) {
        return Vue.prototype.$http.post(`dashboard/statistics/requirements/${requirementId}/tasks/send-reminders`, body);
    },

    // Programs
    programs(body = {}, options = {}) {
        return Vue.prototype.$http.post('dashboard/statistics/programs', body, options);
    },
};
