<template>
    <div class="dropdown">
        <div
            class="drop-select"
            :tabindex="0"
            @click="onOpen"
            @blur="onBlur"
        >
            <span>{{ label }}</span>
            <div :style="{paddingLeft:'1.2rem'}">
                <FontAwesomeIcon :icon="'chevron-down'" />
            </div>
        </div>
        <div
            v-show="show"
            class="drop-options"
        >
            <div
                v-for="option in options"
                :key="option.key"
            >
                <div @mousedown="selectOption(option.key)">
                    {{ option.label }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropDown',
    props: {
        options: {
            type: Array,
            required: true,
        },
        value: {
        },
    },

    data() {
        return {
            show: false,
        };
    },
    created() {
        if (this.value) {
            this.selectOption(this.value);
        } else {
            this.selectOption(this.options[0].key);
        }
    },
    methods: {
        onBlur() {
            this.show = false;
        },
        onOpen() {
            if (this.options.length > 0) {
                this.show = !this.show;
            }
        },
        findByName(name) {
            return this.options.find((option) => option.key === name);
        },
        selectOption(key) {
            const item = this.findByName(key);
            this.label = item.label;
            this.$emit('input', item.key);
        },
    },
};
</script>

<style lang="scss" scoped>
.dropdown {
    background-color: #F5F7F8;
    border-radius: 4px;
    display: inline-block;
    position: relative;
}
.drop-select {
    display: flex;
    justify-content: space-between;
    padding: 1.2rem;
    line-height: 2.2rem;
}
.drop-options {
    padding: 1.2rem;
    position: absolute;
    background-color: #F5F7F8;
}
.drop-options > div {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.dropdown, .drop-options {
    cursor: pointer;
    z-index: 1;
}
</style>
