<template>
    <div v-if="value">
        <HtFormInput
            id="name"
            v-model="value.name"
            v-validate.disable="'required|max:255'"
            name="name"
            :label="translate('Name')"
        />
        <HtFormTextarea
            id="description"
            v-model="value.description"
            name="description"
            :label="translate('Description')"
            class="mb-0"
        />
    </div>
</template>

<script>
export default {
    name: 'EditCustom',

    inject: ['$validator'],

    props: {
        /**
         * @type {import('vue').PropOptions<{
         *  id: number,
         *  name: string,
         *  description: string | null,
         *  key: string,
         *  entity_id: number
         * }>}
         */
        value: {
            type: Object,
            required: true,
        },

        entityId: {
            type: Number,
            required: true,
        },
    },

    methods: {
        beforeSave() {
            this.value.entity_id = this.entityId;
        },
    },
};
</script>
