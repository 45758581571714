import Model from '@tony.caron/node-model-proxy/Model';

export default class CompanySso extends Model {
    static DOMAIN_VALIDATION_REGEX = /^([a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*)@?([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(\.[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*)\.[a-zA-Z]{2,}$/;

    modelConfig() {
        return {
            controller: 'CompanySsoController',
        };
    }
}
