<template>
    <div class="wrapper">
        <div
            :class="['file-name', {'is-clickable': hasPreviewer}]"
            @click="togglePreviewer"
        >
            {{ uploadBar.value.original_name }}
        </div>
        <div style="display: flex">
            <FontAwesomeIcon
                v-if="isAllowedToDownload()"
                :icon="['fal', 'arrow-to-bottom']"
                class="icon"
                size="1x"
                :title="translate('Download')"
                @click="download"
            />
            <FontAwesomeIcon
                v-if="uploadBar.withEdit"
                :icon="['fas','pencil']"
                class="icon"
                size="1x"
                :title="translate('Edit')"
                @click="$emit('on-edit')"
            />
            <FontAwesomeIcon
                v-if="uploadBar.withTrash"
                :icon="['fad','trash']"
                class="icon"
                size="1x"
                :title="translate('Delete')"
                @click="destroy"
            />
        </div>
        <FilePreviewer
            v-if="hasPreviewer"
            ref="file-previewer"
            :files="[uploadBar.value]"
        />
    </div>
</template>
<script>
import FilePreviewer from '@/components/globals/FilePreviewer/FilePreviewer.vue';
import get from 'lodash.get';
import DownloadUploadFileMixin from '@/components/mixins/DownloadUploadFileMixin';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';

export default {

    components: {
        FilePreviewer,
    },

    mixins: [DownloadUploadFileMixin],

    inject: ['uploadBar'],

    computed: {
        hasPreviewer() {
            if (RtwSharedSpace.store.session.storageMode === 'numspot' && this.uploadBar.isRequirement) {
                return false;
            }

            return Boolean(get(this.uploadBar.value, 'detected_mime_type'))
                && Boolean(get(this.uploadBar.value, 'link_preview'))
                && this.isAllowedToDownload();
        },
    },

    methods: {
        download() {
            this.downloadCompanyFile(this.uploadBar.value.id, this.uploadBar.isRequirement);
        },

        destroy() {
            this.removeFile(this.uploadBar.value.size);
            this.uploadBar.$emit('file-deleted');
        },

        /**
        * @param {number} fileSize taille du fichier qui est remove
        * @returns {void}
        */
        removeFile(fileSize) {
            this.uploadBar.$emit('input', null);
            this.uploadBar.widthProgressBar = 0;
            this.uploadBar.$emit('onRemove');

            this.uploadBar.$emit('update:fileSize', -fileSize);
        },

        togglePreviewer() {
            if (!this.hasPreviewer) {
                return;
            }

            if (!this.isAllowedToDownload()) {
                return;
            }

            const extension = get(this.uploadBar.value, 'detected_extension');

            if (extension === 'pdf') {
                window.open(this.uploadBar.value.link_preview, '_blank', 'fullscreen=yes');
                return;
            }
            this.$refs['file-previewer'].togglePreviewer();
        },

        isAllowedToDownload() {
            return this.shared.session.can_onboardee_download_requirements;
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.wrapper {
    display: flex;
    justify-content: space-between;
}

.file-name {
    max-width: 29rem;
    white-space: nowrap;
    word-wrap: break-word;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;

    &.is-clickable {
        text-decoration: underline;
        cursor: pointer;
    }
}

.icon {
    cursor: pointer;
    margin-left: 1.5rem;
}
</style>
