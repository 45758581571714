import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyProgramKeyDate from './CompanyProgramKeyDate';

export default class CompanyProgramKeyDateCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyProgramKeyDate,
        };
    }
}
