<template>
    <TranslatedForm v-slot="{editingLang}">
        <div
            class="quiz-question"
        >
            <div class="container-inputs form-2">
                <div>
                    <QuizQuestionItemEdit
                        v-for="(question, indexQuestion) in currentQuiz.questions"
                        :key="indexQuestion"
                        :editing-lang="editingLang"
                        :question.sync="question"
                        :index-question="indexQuestion"
                        class="quiz-question-wrapper"
                        @deleteQuestion="deleteQuestion"
                    />

                    <div
                        class="button button-8 black"
                        :data-cy="'quizQuestion-add-question'"
                        @click="addQuestion"
                    >
                        <div class="text-btn">
                            <t>Add question</t>
                        </div>
                    </div>
                    <div class="modal-actions right">
                        <Button
                            :state="button_state"
                            class="negative"
                            @click="modal.close()"
                        >
                            <t>Back</t>
                        </Button>
                        <Button
                            :cypress="'quizQuestion-form-submit'"
                            :state="button_state"
                            @click="onValidate()"
                        >
                            <t>Save & Finish</t>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </TranslatedForm>
</template>

<script>
import TranslatedForm from '@/components/form/TranslatedForm.vue';
import api from '@/store/api';
import QuizQuestionItemEdit from './QuizQuestionItemEdit.vue';

export default {
    components: { QuizQuestionItemEdit, TranslatedForm },

    inject: ['modal', '$validator'],

    props: {
        item: { type: Object }, // CompanyQuiz
    },

    data() {
        return {
            button_state: 'idle',
            currentQuiz: null,
            answers: [],
        };
    },

    created() {
        this.currentQuiz = this.item || {};
        this.modal.setTitle('Quiz Questions');
    },

    methods: {
        addQuestion() {
            const newQuestion = {
                translations: {
                    text: {},
                },
                id: undefined,
                tempId: Math.floor(Math.random() * Math.floor(1000)) + this._uid,
                company_id: this.item.company_id,
                company_quiz_id: this.item.id,
                text: '',
                image: '',
                file_translations: {},
            };

            const correctAnswer = {
                tempId: Math.floor(Math.random() * Math.floor(1000)),
                is_correct_answer: true,
                text: '',
                image: '',
                translations: {
                    text: {},
                },
                file_translations: {},
            };
            const wrongAnswer = {
                tempId: Math.floor(Math.random() * Math.floor(1000)),
                is_correct_answer: false,
                text: '',
                image: '',
                translations: {
                    text: {},
                },
                file_translations: {},
            };
            newQuestion.answers = [correctAnswer, wrongAnswer];
            this.currentQuiz.questions.push(newQuestion);
        },

        deleteQuestion(quizQuestion) {
            let findeIndex = -1;
            if (undefined === quizQuestion.id && 'tempId' in quizQuestion) {
                findeIndex = this.currentQuiz.questions.findIndex((answer) => answer.tempId === quizQuestion.tempId);
            } else if (undefined !== quizQuestion.id) {
                findeIndex = this.currentQuiz.questions.findIndex((answer) => answer.id === quizQuestion.id);
            }

            if (findeIndex > -1) {
                this.currentQuiz.questions.splice(findeIndex, 1);
            }
        },

        async onValidate() {
            await this.$validator.validateAll();

            if (this.currentQuiz.questions.length === 0) {
                this.$Notifier('App').showError(this.translate('You need to have at least one question!'));
                return;
            }

            if (this.errors.any()) {
                return;
            }

            this.button_state = 'loading';

            const isExistingResource = this.currentQuiz.id !== undefined;

            const fetchPromise = isExistingResource
                ? api.configuration.quizzes.update(this.currentQuiz.id, this.currentQuiz)
                : api.configuration.quizzes.store(this.currentQuiz);

            fetchPromise.then(() => {
                this.modal.close();
                if (this.$route.query.id) {
                    // If we go to "Resources/Quizzes?id=..." (via Holding for example), once fetch executed we must redirect to "Ressource/Quizzes" (without ?id=...)
                    // otherwise, we will see the QuizEdit modal that opens again
                    this.$router.push({ name: 'ResourcesQuizzes' });
                }
                this.$emit('onSave');
            }).catch(() => {
                this.button_state = 'idle';
            });
        },
    },
};
</script>
<style lang="scss" scoped src="./QuizQuestionListEdit.scss" />
