<template>
    <RoundEmptyBackground>
        <FontAwesomeIcon
            size="1x"
            :icon="['fal', 'times']"
            transform="shrink-1"
        />
    </RoundEmptyBackground>
</template>

<script>
import RoundEmptyBackground from './RoundEmptyBackground.vue';

export default {
    name: 'CheckCancelledIcon',
    components: { RoundEmptyBackground },

};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
</style>
