<template>
    <HoldingDataList
        v-if="companiesLoaded"
        :companies-options="companiesOptions"
        :selected-company-id="selectedCompanyId"
        @select-company="onSelectCompany"
    >
        <template #default>
            <div class="filters">
                <HtSearchField
                    :id="'search'"
                    v-model.trim="search"
                    :name="'search'"
                    class="filter mr-3"
                />
                <HtFormSelect
                    :id="'program'"
                    v-model="selectedProgramId"
                    :name="'program'"
                    :options="programOptions"
                    class="filter temp"
                />
            </div>
            <div>
                <slot
                    name="table"
                    :search="search"
                    :on-cell-clicked="onCellClicked"
                    :items="collection.models"
                />
            </div>
        </template>
    </HoldingDataList>
</template>

<script>
import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import { mapGetters, mapState } from 'vuex';
import HtFormSelect from './HtFormSelect.vue';
import HtSearchField from './HtSearchField.vue';
import HoldingDataList from './HoldingDataList.vue';

export default {
    components: {
        HtFormSelect,
        HtSearchField,
        HoldingDataList,
    },

    props: {
        routeList: {
            type: String,
            required: true,
        },
        collection: {
            type: ModelCollection,
            required: true,
        },
        useResourceProgram: {
            type: Boolean,
            default: () => true,
        },
    },

    data() {
        return {
            search: null,
            selectedCompanyId: Number(this.$route.params.company_id),
            selectedProgramId: null,
        };
    },

    computed: {
        ...mapState('companies', [
            'companiesLoaded',
            'companies',
        ]),
        ...mapGetters('companies', [
            'childCompanies',
        ]),
        ...mapState('programs', [
            'programs',
            'programsLoaded',
        ]),
        isLoaded() {
            return (
                this.programsLoaded
                && this.companiesLoaded
                && this.collection.isLoaded()
            );
        },
        companiesOptions() {
            return this.childCompanies.map((company) => ({
                key: company.id,
                label: company.name,
            }));
        },
        programOptions() {
            return this.programs
                .filter((program) => program.company_id === this.selectedCompanyId)
                .map((program) => ({
                    id: program.id,
                    name: this.oldLocalize(program.locales, 'name'),
                }));
        },

        /**
         * @returns {Company}
         */
        selectedCompany() {
            return this.childCompanies.find((company) => company.id === this.selectedCompanyId);
        },
    },

    watch: {
        $route: {
            immediate: true,
            async handler() {
                this.collection
                    .clean('where')
                    .clean('whereHas')
                    .where([
                        ['company_id', '=', this.selectedCompanyId],
                    ]);

                if (this.selectedProgramId) {
                    if (this.useResourceProgram) {
                        this.collection.whereHas({
                            resourceProgram: (query) => {
                                query.where([
                                    ['company_program_id', '=', this.selectedProgramId],
                                ]);
                            },
                        });
                    } else {
                        this.collection.where([
                            ['company_program_id', '=', this.selectedProgramId],
                        ]);
                    }
                }

                this.collection.setEndPointPrefix('holding/lookup/');

                await this.collection.get();
            },
        },
        /**
         * @returns {void}
         */
        selectedProgramId() {
            this.collection
                .clean('where')
                .clean('whereHas')
                .where([
                    ['company_id', '=', this.selectedCompanyId],
                ]);

            if (this.useResourceProgram) {
                this.collection.whereHas({
                    resourceProgram: (query) => {
                        query.where([
                            ['company_program_id', '=', this.selectedProgramId],
                        ]);
                    },
                });
            } else {
                this.collection.where([
                    ['company_program_id', '=', this.selectedProgramId],
                ]);
            }

            this.collection.setEndPointPrefix('holding/lookup/');

            this.collection.get();
        },
    },

    created() {
        this.$store.dispatch('programs/fetchPrograms');
        this.$store.dispatch('companies/fetchCompanies');
    },

    methods: {
        onCellClicked(cell) {
            this.$http.get(`holding/login/${this.selectedCompanyId}`).then(({ data: { data: { token, url: host } } }) => {
                const url = new URL(`${host}/AutoLogin/${token}`);

                url.searchParams.append('redirect', `/${this.$route.path.split('/').slice(2, -1).join('/')}`);
                url.searchParams.append('id', cell.item.id);

                window.open(url);
            });
        },
        /**
         * @param {number} companyId
         * @returns {void}
         */
        onSelectCompany(companyId) {
            this.selectedCompanyId = companyId;

            this.$router.push({
                name: this.routeList,
                params: { company_id: companyId },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.nb-collaborators {
    font-size: 1.4rem;
    font-weight: 400;
}
.filters {
    display: flex;
}
.filter {
    width: 30%;
}
.temp {
    margin-bottom: 0rem;
}
</style>
