import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyPlanningEventAttendee from './CompanyPlanningEventAttendee';

export default class CompanyPlanningEventAttendeeCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyPlanningEventAttendee,
        };
    }
}
