<script>
export default {
    data() {
        return {
            flowVariablesFull: [
                { key: 'enrollee_firstname', value: 'enrollee_firstname' },
                { key: 'enrollee_lastname', value: 'enrollee_lastname' },
                { key: 'company_name', value: 'company_name' },
                { key: 'speaker_firstname', value: 'speaker_firstname' },
                { key: 'speaker_fullname', value: 'speaker_fullname' },
                { key: 'speaker_job_position', value: 'speaker_job_position' },
            ],
            flowVariablesLight: [
                { key: 'user_firstname', value: 'user_firstname' },
                { key: 'user_lastname', value: 'user_lastname' },
                { key: 'company_name', value: 'company_name' },
                { key: 'speaker_firstname', value: 'speaker_firstname' },
                { key: 'speaker_fullname', value: 'speaker_fullname' },
                { key: 'speaker_job_position', value: 'speaker_job_position' },
            ],
        };
    },
};
</script>
