<template>
    <HtTabStepperModel
        v-if="requirementCategory"
        :id="id"
        :tabs="tabs"
        :default-filters="defaultFilters"
        :default-order-onboarding-start="defaultOrderOnboardingStart"
        :default-order-onboarding-end="defaultOrderOnboardingEnd"
        :company-program-id="companyProgramId"
        :show-button-delete="showButtonDelete"
        :add-default-order-completion="true"
        :show-list-on-end="showListOnEnd"
        :show-toggle-survey="showToggleSurvey"
        @onSave="$emit('onSave')"
        @onDelete="$emit('onDelete')"
        @on-close-stepper="$emit('on-close-stepper')"
    />
</template>

<script>
import RequirementFormCategorySettings
    from '@/components/pages/resources/requirements/form/RequirementFormCategorySettings.vue';
import RequirementFormContent from '@/components/pages/resources/requirements/form/RequirementFormContent.vue';
import RequirementFormTarget from '@/components/pages/resources/requirements/form/RequirementFormTarget.vue';
import RequirementFormPlanning from '@/components/pages/resources/requirements/form/RequirementFormPlanning.vue';
import HtTabStepperModel from '@/layouts/HtTabStepperModel.vue';
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import api from '@/store/api';
import cloneDeep from 'lodash.clonedeep';

export default {
    name: 'RequirementForm',
    components: { HtTabStepperModel },

    mixins: [
        DefaultFiltersMixin,
        DefaultOrderCompletionMixin,
    ],

    props: {
        id: {
            type: Number,
            default: null,
        },
        companyProgramId: {
            type: Number,
            default: null,
        },
        showListOnEnd: {
            type: Boolean,
            default: true,
        },
        showButtonDelete: {
            type: Boolean,
            default: true,
        },
        showToggleSurvey: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            tabs: [
                {
                    ref: 'settings',
                    title: this.translate('Category settings'),
                    component: RequirementFormCategorySettings,
                    disabled: false,
                    noBody: false,
                },
                {
                    ref: 'targets',
                    title: this.translate('Targets'),
                    component: RequirementFormTarget,
                    disabled: !this.id,
                    noBody: false,
                },
                {
                    ref: 'content',
                    title: this.translate('Content'),
                    component: RequirementFormContent,
                    disabled: !this.id,
                    noBody: false,
                },
                {
                    ref: 'planning',
                    title: this.translate('Scheduling'),
                    component: RequirementFormPlanning,
                    disabled: !this.id,
                    noBody: false,
                },
            ],
        };
    },

    computed: {
        requirementCategory: {
            set(requirementCategory) {
                this.$store.dispatch('stepperform/setItem', requirementCategory);
            },
            get() {
                return cloneDeep(this.$store.state.stepperform.item);
            },
        },
    },

    mounted() {
        this.fetchData();
    },

    created() {
        this.$store.dispatch('stepperform/reset');
        this.$store.dispatch('configuration/emailTemplates/fetchEmailTemplates');
    },

    methods: {
        async fetchData() {
            if (!this.id) {
                this.init();
            } else {
                await api.configuration.requirementCategory
                    .get(this.id)
                    .then((res) => {
                        this.$store.dispatch('stepperform/setItem', res.data.data);
                        this.$emit('on-load', this.requirementCategory.resource.translations.name[this.shared.session.companyUser.company_language.key]);
                    });
            }
        },

        init() {
            this.$store.dispatch('stepperform/setItem', {
                is_heyteam: false,
                is_duplicable: false,
                display_survey: false,
                display_chatbot: false,
                survey_question: false,
                is_locked: false,
                company_requirement_category_recipient: [],
                company_program_resource_filter: [],
                hard_deleted_requirements_ids: [],
                resource: {
                    is_editable: true,
                    programs: [],
                    translations: {
                        name: {},
                        description: {},
                    },
                },
            });
            this.$emit('on-load', this.translate('New requirement'));
        },
    },
};
</script>
