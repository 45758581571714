import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';

export default class CompanyUserAudit extends Model {
    modelConfig() {
        return {
            controller: 'CompanyUserAuditController',
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            to: () => this.belongsTo(CompanyUser, 'to_company_user_id', 'id'),
            from: () => this.belongsTo(CompanyUser, 'from_company_user_id', 'id'),
        };
    }
}
