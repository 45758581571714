<template>
    <div v-if="companyFlow.isLoaded()">
        <UploadImage
            ref="upload_image"
            @onUploadEnd="onUploadEnd"
        />
        <div class="wrapper-has-sidebar align-content wrapper-preboarding">
            <aside class="sidebar-info">
                <div
                    v-if="!imageUrl && (blockTitle && blockTitle.company_file_id)"
                    class="content-sidebar"
                    :style="{
                        'background-image': `url(${$Utils.resolveS3PublicImage(
                            blockTitle.company_file.path + blockTitle.company_file.name
                        )})`
                    }"
                >
                    <div
                        class="content-sidebar-upload"
                        @click="$refs.upload_image.openFileSelector()"
                    >
                        <IconBranding icon="#icon-branding-edit" />
                    </div>
                </div>
                <div
                    v-else
                    class="content-sidebar"
                    :style="backgroundImage"
                >
                    <div
                        class="content-sidebar-upload"
                        @click="$refs.upload_image.openFileSelector()"
                    >
                        <IconBranding icon="#icon-branding-edit" />
                    </div>
                </div>
            </aside>
            <div class="content-info full">
                <div class="steps-container">
                    <h1 class="small">
                        <t>Step</t> {{ step }}/{{ maxStep }} : <t>Welcome</t>
                    </h1>
                </div>
                <HtLanguageSelect
                    id="languageSelect"
                    name="languageSelect"
                    :value.sync="currentLanguage"
                />
                <component
                    :is="componentName"
                    ref="customView"
                    :collection="companyFlow"
                    :step="step"
                    :max-step="maxStep"
                    :lang="currentLanguage.key"
                    :chatbot-slug="chatbotSlug"
                    @onNextPage="nextPage()"
                />
                <Button
                    :state="button_state"
                    :type="'flat'"
                    :has-back="step > 1"
                    @click="onValidate()"
                    @clickBack="previousPage()"
                >
                    <template slot="default">
                        <span>
                            <t v-if="step < maxStep">Next</t>
                            <t v-else>Save</t>
                            <svg class="icon"><use xlink:href="#icon-arrow" /></svg>
                        </span>
                    </template>
                </Button>
            </div>
        </div>
    </div>
</template>
<script>
import UploadImage from '@/components/tools/UploadImage.vue';
import IconBranding from '@/components/globals/IconBranding.vue';
import CompanyChatbot from '@/models/CompanyChatbot';
import CompanyFlowCollection from '@/models/CompanyFlowCollection';
import CustomHello from '@/components/resources/customize/preboarding/step/CustomHello.vue';
import CustomSign from '@/components/resources/customize/preboarding/step/CustomSign.vue';
import CustomCompany from '@/components/resources/customize/preboarding/step/CustomCompany.vue';
import CustomTeamPackage from '@/components/resources/customize/preboarding/step/CustomTeamPackage.vue';
import CustomProfile from '@/components/resources/customize/preboarding/step/CustomProfile.vue';
import CustomRequirement from '@/components/resources/customize/preboarding/step/CustomRequirement.vue';
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import HtLanguageSelect from '@/components/globals/HtLanguageSelect.vue';

export default {
    name: 'PreboardingEdit',
    components: {
        CustomHello,
        CustomSign,
        CustomCompany,
        CustomTeamPackage,
        CustomProfile,
        CustomRequirement,
        UploadImage,
        IconBranding,
        HtFormMultiSelect,
        HtLanguageSelect,
    },
    inject: ['modal'],

    props: {
        chatbotSlug: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            step: 1,
            index: 1,
            pages: [],
            lang: 'fr',
            button_state: 'idle',
            maxStep: 0,
            blockTitle: null,
            imageUrl: '',
            currentLanguage: this.shared.session.company.company_language,
            companyLanguage: this.shared.session.company.company_languages,
            allPages: [
                { name: 'CustomHello', types: [CompanyChatbot.SLUG_FULL, CompanyChatbot.SLUG_LIGHT], index: 1 },
                { name: 'CustomSign', index: 2 },
                { name: 'CustomCompany', types: [CompanyChatbot.SLUG_FULL], index: 3 },
                { name: 'CustomTeamPackage', types: [CompanyChatbot.SLUG_FULL], index: 4 },
                { name: 'CustomProfile', types: [CompanyChatbot.SLUG_FULL, CompanyChatbot.SLUG_LIGHT], index: 5 },
                { name: 'CustomRequirement', types: [CompanyChatbot.SLUG_FULL, CompanyChatbot.SLUG_LIGHT], index: 6 },
            ],
            currentItem: null,
            companyFlow: new CompanyFlowCollection([
                'id',
                'company_chatbot_id',
                'subtype',
                'order',
                'page',
                'content',
                'is_enabled',
                'is_enabled_editabled',
                'company_file_id',
            ]).with({
                companyFile: (query) => {
                    query.select([
                        'id',
                        'original_name',
                        'uuid',
                        'path',
                        'name',
                    ]);
                },
            }).whereHas({
                chatbot: (query) => {
                    query.where([
                        ['slug', '=', this.chatbotSlug],
                    ]);
                },
            }).orderBy([
                ['page', 'asc'],
                ['order', 'asc'],
            ]),
        };
    },

    computed: {
        backgroundImage() {
            const backgroundImage = this.imageUrl || `url(/static/images/preboarding/image${this.step}.jpg)`;

            return { backgroundImage };
        },
        componentName() {
            let component;
            switch (this.index) {
            case 1:
                component = 'CustomHello';
                break;
            case 2:
                component = 'CustomSign';
                break;
            case 3:
                component = 'CustomCompany';
                break;
            case 4:
                component = 'CustomTeamPackage';
                break;
            case 5:
                component = 'CustomProfile';
                break;
            case 6:
                component = 'CustomRequirement';
                break;
            default:
                new Error(`Component not found for index [${this.index}]`);
            }

            return component;
        },
    },

    created() {
        if (this.chatbotSlug === CompanyChatbot.SLUG_SIGN_ONLY) {
            this.index = 2;
        }

        this.companyFlow.get().then(() => {
            // PATCH WAITING FOR TONY PUSH NEW FILE HANDLE
            this.companyFlow.models.forEach((element) => {
                if (element.subtype.includes('_title') && element.company_file) element.company_file.uuid = null;
            });

            this.loadPage();
            this.findBlockTitle();
        });
    },

    methods: {
        onUploadEnd(data, url) {
            this.imageUrl = `url(${url})`;

            if (this.blockTitle) {
                this.blockTitle.active.company_file = data;
            }
        },

        switchLang(lang) {
            this.lang = lang;
        },

        loadPage() {
            this.currentItem = this.companyFlow.$.models[0];

            this.pages = this.allPages.filter((item) => !item.types || (item.types && item.types.includes(this.chatbotSlug)));
            this.maxStep = this.pages.length;
        },

        nextPage() {
            this.companyFlow.get().then(() => {
                if (this.step < this.pages.length) this.index = this.pages[this.step].index;

                this.step++;

                this.findBlockTitle();
                if (this.step > this.maxStep) {
                    this.modal.close();
                }
            });
        },

        findBlockTitle() {
            this.blockTitle = this.companyFlow.$.models.find((m) => m.page === this.step && m.subtype.endsWith('title'));
        },

        async onValidate() {
            try {
                this.button_state = 'loading';
                await this.$refs.customView.onValidate();
                this.imageUrl = null;
            } finally {
                this.button_state = 'idle';
            }
        },

        previousPage() {
            this.step--;
            this.index = this.pages[this.step - 1].index;
            this.findBlockTitle();
        },
    },
};
</script>

<style lang="scss" scoped src="./PreboardingEdit.scss"></style>
