export default class HoldingResourceCountItem {
    /**
     * @param {string} companyName
     * @param {number} companyId
     * @param {number} countResources
     * @returns {void}
     */
    constructor(companyName, countResources, companyId) {
        this.companyName = companyName;
        this.countResources = countResources;
        this.companyId = companyId;
    }
}
