<template>
    <div
        id="main-container"
        class="centered_content align-center"
    >
        <header class="header-light justify-content-between">
            <div class="logo">
                <router-link to="/Dashboard">
                    <img :src="$Utils.resolveS3PublicImage(company.image_square)">
                </router-link>
            </div>
            <HtIconClose
                data-cy="exit"
                @click="$router.goBack()"
            />
        </header>

        <div class="invite-head">
            <h1><t>Invite members</t></h1>
        </div>

        <div class="invite-options centered">
            <div class="invite invite-manual">
                <div class="invite-top align-center">
                    <div class="head">
                        <strong><t>Manual import</t></strong>
                    </div>
                    <div><t>Enter manually the e-mail addresses of your teammates and invite them to your HeyTeam workspace</t></div>
                </div>

                <div
                    class="invite-image"
                    style="background-image: url('/static/icons/pencil.svg')"
                />

                <router-link
                    to="/Settings/Members/Invite/Manual"
                    exact
                >
                    <Button
                        class="invite-button"
                        cypress="invite-manually"
                    >
                        <t>Invite member manually</t>
                    </Button>
                </router-link>
            </div>
            <div class="invite invite-gsuite">
                <div class="invite-top align-center">
                    <div class="head">
                        <strong><t>Invite via Gsuite</t></strong>
                    </div>
                    <div><t>Automatically invite your members from your Gsuite directory</t></div>
                </div>

                <div
                    class="invite-image"
                    style="background-image: url('/static/icons/gsuite.svg')"
                />

                <router-link
                    to="/Settings/Members/Invite/Gsuite"
                    exact
                >
                    <Button
                        class="invite-button"
                        cypress="invite-gmail"
                    >
                        <t>Invite Gsuite member</t> >
                    </Button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import HtIconClose from '@/components/icons/HtIconClose.vue';

export default {
    name: 'InviteMembers',
    components: {
        HtIconClose,
    },

    data() {
        return {
            company_user: this.shared.session.companyUser,
            company: this.shared.session.company,
        };
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	.header-light {
		padding: 15px 35px;
		margin-bottom: 7em;

		&.left {
			justify-content: left;
			border-bottom: 1px solid #f3f3f3;
		}
		.logo {
			text-align: left;
		    max-width: 200px;
		    max-height: 30px;
		    img {
		    	height: 30px;
		    }
		}
	}

	.centered {
		margin: 0 auto;
		max-width: 850px;
	}

	.invite-head {
		h1 {
		    font-size: 5rem;
		    margin-bottom: 0.5em;
		}
	}

	.align-left {
		text-align: left;
	}

	.invite-options {
		display: inline-flex;

		img {
			max-width: 275px;
			margin-right: 20px;

			&.no-margin {
				margin-right: 0;
			}
		}
	}

	// Blocks

	.invite {
		width: 275px;
		height: 385px;
		margin: 0 0.75em;
		position: relative;

		&.invite-manual {
			background: $white;
		}

		&.invite-gsuite {
			background: #fde8d7;
		}

		.invite-top {
			margin: 5em 0.5em 3em;

			.head {
			    margin-bottom: 1em;
    			font-size: 1.75rem;
			}
			font-size: 1.5rem;
			height: 50px;
		}

		.invite-image {
			width: 100%;
			height: 115px;
		    background-repeat: no-repeat;
		    background-position: center;
		}

		.invite-button {
			position: absolute;
			bottom: 0;
			left: 0;
		    width: 100%;
    		border-radius: 0;

    		&:active {
    			transform: none;
    		}

    		a {
			    color: #fff;
    			text-decoration: none;
    		}
		}
	}
</style>
