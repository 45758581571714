<template>
    <div
        v-if="Object.keys(item).length"
        v-user-image="$Utils.getCompanyComponentRoleInitials(
            item.company_user ? 'user' : item.company_role.alias,
            1.25,
            item.company_user ? {
                firstname: item.company_user.firstname,
                lastname: item.company_user.lastname,
                image: item.company_user.image,
            } : null,
            null)
        "
        class="item-image role-only"
        :style="{
            'height': `${size}px`,
            'width': `${size}px`,
        }"
        :class="item.company_user
            ? 'user'
            : (
                item.company_role.is_removable
                    ? ''
                    : item.company_role.name
            )"
    />
</template>

<script>
export default {
    name: 'UserBlock',
    props: {
        item: { type: Object, required: true },
        size: { type: Number, default: 82 },
    },
};
</script>
