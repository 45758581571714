<template>
    <div
        v-if="companyMediaCollection.isLoaded()"
        class="full-width"
    >
        <SectionBar
            :title="configs.title"
            :button-class="'button-plus'"
            :button-text="'+'"
            :has-add-button="true"
            @click="openModal()"
        />
        <div
            v-if="companyMediaCollection.$.length"
            class="full-width"
        >
            <div class="component_item flex-container card-1 media">
                <div
                    v-for="item in getMedias"
                    :key="item.id"
                    :class="(item.editable ? '' : 'not-editable')"
                    class="item roll-icon"
                    @click="openModal(item.active)"
                >
                    <div class="item-content">
                        <img
                            v-if="item.type === 'file' && !isVideo(item)"
                            class="image-media"
                            :data-vv-scope="item.id"
                            :src="getLink(item)"
                        >
                        <div
                            v-else-if="item.type === 'file' && isVideo(item)"
                            class="image-video image-media"
                            :data-vv-scope="item.id"
                        >
                            <video
                                width="100%"
                                height="200"
                            >
                                <source :src="getLink(item)">
                            </video>
                        </div>
                        <div
                            v-if="item.type === 'link'"
                            class="image-media video"
                        >
                            <iframe
                                width="420"
                                height="315"
                                :src="$Utils.embedVideo(item.url)"
                            />
                            <div class="overlay" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            :key="0"
            class="empty-component"
        >
            <p> <strong> <t>No media added</t> </strong> </p>
        </div>

        <Modalable
            ref="modalableMediaEdit"
            class="modalable-1 medium"
            :mode="2"
            :element-delete-label="translate('this media')"
        >
            <MediaEdit
                v-model="currentItem"
                :collection="companyMediaCollection"
            />
        </Modalable>
    </div>
</template>

<script>
import CompanyMediaCollection from '@/models/CompanyMediaCollection';
import MediaEdit from '@/components/resources/media/MediaEdit.vue';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';

export default {
    name: 'MediaList',
    components: {
        MediaEdit,
    },

    data() {
        return {
            configs: {
                title: 'Photos & Videos',	// Title of SectionBar
                showAddButton: true,			// Show add button of SectionBar,
                perRow: 3,						// How many cards per row
            },
            linkVideo: [],
            currentItem: null,
            companyMediaCollection: new CompanyMediaCollection([
                'id',
                'editable',
                'description',
                'image',
                'type',
                'url',
                'company_file_id',
                'is_landing',
            ]).with({
                companyFile: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
            }).orderBy([['order', 'asc']]),
        };
    },

    computed: {
        getMedias() {
            return this.companyMediaCollection.$.models.filter((item) => ((item.type === 'file' && item.company_file_id !== null) || item.type === 'link'));
        },
    },

    async created() {
        await this.companyMediaCollection.get();
    },

    methods: {
        getMediaLink(item) {
            let ret = null;
            this.API.get(`company_media/getCompanyMediaFile/${item.id}`, {
                200: (data) => {
                    ret = data.link;
                },
            });

            return ret;
        },

        getLink(item) {
            return this.$Utils.resolveS3PublicImage(item.company_file.path + item.company_file.name);
        },

        openModal(item) {
            this.currentItem = item;
            this.$refs.modalableMediaEdit.open();
        },

        isVideo(item) {
            return item && item.company_file && item.company_file.detected_mime_type.startsWith('video');
        },
    },
};
</script>
<style lang="scss" scoped>

.full {
	width: 100%;
}

.image {
	background-size: contain;
}

.image-video {
	display: flex;
}

.video {
    position: relative;

    .overlay {
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
    }
}
</style>
