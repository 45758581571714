const SET_ITEM = 'set_item';
const SET_LOADING = 'set_loading';

export default {
    namespaced: true,

    state() {
        return {
            loading: false,
            item: null,
        };
    },

    actions: {
        setItem({ commit }, item) {
            commit(SET_ITEM, item);
        },
        enableLoading({ commit }) {
            commit(SET_LOADING, true);
        },
        disableLoading({ commit }) {
            commit(SET_LOADING, false);
        },
        reset({ commit }) {
            commit(SET_ITEM, null);
            commit(SET_LOADING, false);
        },
    },

    mutations: {
        [SET_ITEM](state, item) {
            state.item = item;
        },
        [SET_LOADING](state, loading) {
            state.loading = loading;
        },
    },
};
