import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyDocumentPublic from './CompanyDocumentPublic';

export default class CompanyDocumentPublicCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyDocumentPublic,
        };
    }
}
