<template>
    <div
        key="date-time-2"
        class="planning-time-slots"
        :data-cy="cypress"
    >
        <fieldset
            v-for="slot in slots"
            :key="`slot_${slot.id}`"
            :class="{ error: errors.has(slot.id) }"
        >
            <TimeSlot
                :id="slot.id"
                v-validate.disable="slot.rules"
                :name="slot.id"
                :ref-name="slot.id"
                :label="slot.label"
                :validate-as="slot.datavv"
                :options="slot.options"
                :value.sync="slot.sync"
                :cypress="slot.id"
                @input="v => $emit(`update:${slot.id}`, v)"
            />
        </fieldset>
    </div>
</template>

<script>
import moment from 'moment';
import TimeSlot from '@/components/resources/planningEvent/TimeSlot.vue';
import CypressMixin from '@/mixins/CypressMixin';

export default {
    name: 'PlanningTimeSlots',
    components: {
        TimeSlot,
    },
    mixins: [CypressMixin],
    inject: ['$validator'],
    props: {
        from: { type: String, default: '' },
        to: { type: String, default: '' },
        time_from: { type: Number, default: 1 },
        time_to: { type: Number, default: 23 },
        time_slotsize: { type: Number, default: 15 },
        required: { type: Boolean, default: true },
        cypress: { type: String },
    },
    computed: {
        slots() {
            return [
                {
                    id: 'from',
                    label: this.translate('Starting time'),
                    datavv: this.translate('end hour'),
                    options: this.startingTimeOptions,
                    sync: this.from,
                    rules: {
                        required: true,
                        regex: '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$',
                    },
                },
                {
                    id: 'to',
                    label: this.translate('Ending time'),
                    datavv: this.translate('start hour'),
                    options: this.endingTimeOptions,
                    sync: this.to,
                    rules: {
                        required: true,
                        regex: '^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$',
                        timeslot_after: [this.from, this.translate('start hour')],
                    },
                },
            ];
        },
        startingTimes() {
            return this.generateTimeSlots(this.time_from, this.time_to, this.time_slotsize);
        },
        startingTimeOptions() {
            return this.startingTimes.map((time) => ({
                id: time,
                name: time,
            }));
        },
        endingTimes() {
            if (this.from) {
                const [hours, minutes] = this.from.split(':');
                const minimumDuration = parseInt(hours, 10) * 60 + parseInt(minutes, 10) + this.time_slotsize;
                return this.generateTimeSlots(minimumDuration / 60, this.time_to, this.time_slotsize);
            }
            return this.generateTimeSlots(this.time_from, this.time_to, this.time_slotsize);
        },
        endingTimeOptions() {
            return this.endingTimes.map((time) => {
                const hourLabel = this.translate(`${time} h`);
                const duration = this.duration(time);
                const durationLabel = duration ? ` (${this.translate(duration)})` : '';
                return {
                    id: time,
                    name: `${hourLabel}${durationLabel}`,
                };
            });
        },
    },
    methods: {
        generateTimeSlots(from, to, slotSizeMinutes = 30) {
            const fromMinutes = from * 60;
            const toMinutes = to * 60;
            const diffMinutes = toMinutes - fromMinutes;
            const numberOfSlots = diffMinutes / slotSizeMinutes;
            const result = [];

            const buildHourMinuteStr = (minutes) => {
                const min = minutes % 60;
                // render HH:MM. Using padStart to add 0 on first 9 hours
                const hour = Math.floor(minutes / 60).toString().padStart(2, '0');
                return `${hour}:${min.toString().padStart(2, '0')}`;
            };

            for (let i = 0; i <= numberOfSlots; i++) {
                result.push(buildHourMinuteStr(fromMinutes + i * slotSizeMinutes));
            }

            return result;
        },
        duration(rawTo) {
            // The date doesn't matter, we only want to compute the hours/minutes
            const from = moment(`2013-02-08 ${this.from}`);
            const to = moment(`2013-02-08 ${rawTo}`);

            const diff = to.diff(from);
            const duration = moment.duration(diff);

            let string = '';
            if (duration.hours() > 0) {
                string += `${duration.hours()}h`;
            }
            if (duration.minutes() > 0) {
                string += duration.minutes();
                if (duration.hours() === 0) {
                    string += 'm';
                }
            }

            return string;
        },
    },
};
</script>
<style lang="scss" scoped>

.planning-time-slots {
    display: grid;
    gap: 0.8rem;
    grid-template-columns: 1fr 1fr;
}
</style>
