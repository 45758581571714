import ModelStore from '@tony.caron/node-model-proxy/ModelStore';
import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyChatChannelUser from './CompanyChatChannelUser';

export default class CompanyChatChannelUserCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyChatChannelUser,
        };
    }

    modelCustomAttributes() {
        return {
            channelUserInterlocutor: null,
            channelUserMe: null,
        };
    }

    modelAccessors() {
        return {
            channelUserInterlocutor: () => this.models.find((channelUser) => channelUser.company_user_id !== ModelStore.get('CompanyUser').id),
            channelUserMe: () => this.models.find((channelUser) => channelUser.company_user_id === ModelStore.get('CompanyUser').id),
        };
    }
}
