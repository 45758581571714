<template>
    <div class="question-completion">
        <HtFormTextarea
            id="survey_text"
            class="mb-0"
            :value="currentValue"
            name="comment"
            :disabled="isReadOnly"
            @input="onInput"
        />

        <SurveyAnswerHistory
            v-if="hasPreviousOccurrenceSection"
        >
            <SurveyTextHistory
                :answers="previousOccurrence.question.answers"
            />
        </SurveyAnswerHistory>
    </div>
</template>

<script>
import SurveyQuestionMixin from '@/components/resources/user/surveys/questions/SurveyQuestionMixin';
import HtFormTextarea from '@/components/globals/HtFormTextarea.vue';
import SurveyAnswerHistory from '@/components/resources/user/surveys/questions/SurveyAnswerHistory.vue';
import SurveyTextHistory from '@/components/resources/user/surveys/questions/Text/SurveyTextHistory.vue';

export default {
    name: 'SurveyTextCompletion',

    mixins: [
        SurveyQuestionMixin,
    ],

    components: {
        HtFormTextarea,
        SurveyAnswerHistory,
        SurveyTextHistory,
    },

    computed: {
        currentValue() {
            if (this.value.length < 1) {
                return null;
            }

            return this.value[0].text;
        },
    },

    methods: {
        onInput(value) {
            if (this.isReadOnly) {
                return;
            }

            this.question.is_edited = true;

            this.$emit('input', [
                {
                    id: this.value[0]?.id || null,
                    text: value,
                },
            ]);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../SurveyQuestion';
</style>
