export default {
    computed: {
        chatIsLoaded() {
            return this.shared.socket.chatListChannelComponent !== null
                && this.shared.socket.channelCollection
                && this.shared.socket.channelCollection.isLoaded();
        },
    },

    watch: {
        chatIsLoaded() {
            if (this.chatIsLoaded && this.$route.query && this.$route.query.channelId) {
                const channelId = Number(this.$route.query.channelId);
                this.shared.socket.chatListChannelComponent.setActive(channelId).then(() => {
                    this.shared.socket.chatOpened = true;
                });
            }
        },
    },

    created() {
        this.$router.replace('/');
    },
};
