export default class Modalable {
    static Vue = null;

    // Modal component storage
    // Store opended windows
    static modalables = {};

    static modalCount = 0;

    static registeredWindow = {};

    static oldBodyOverflow = null;

    // Only called when a modal is opened
    static register(obj, id) {
        Modalable.saveBodyOverflow(obj);
        Modalable.modalables[id] = obj;
        Modalable.modalCount++;
    }

    // Only called when a modal is closed
    static unRegister(id) {
        if (Modalable.modalables[id]) {
            delete Modalable.modalables[id];
            Modalable.modalCount--;

            Modalable.restoreBodyOverflow();
        }
    }

    static saveBodyOverflow(obj) {
        if (Modalable.modalCount == 0) {
            Modalable.oldBodyOverflow = document.body.style.overflow;
            document.body.style.overflow = 'hidden';
        }

        // Debugbar patch
        if (document.getElementsByClassName('debug-bar').length) {
            const debugHeight = parseFloat(document.getElementsByClassName('debug-bar').item(0).parentNode.style.height);
            obj.$el.style.height = debugHeight == '25' ? '100%' : (`${100 - debugHeight}%`);
        }
    }

    static restoreBodyOverflow() {
        if (Modalable.modalCount == 0) {
            document.body.style.overflow = Modalable.oldBodyOverflow;
        }
    }

    // Sert plus a rien avec le inject
    static findByChildUid(sUid) {
        for (const n in Modalable.modalables) {
            if (Object.prototype.hasOwnProperty.call(Modalable.modalables, n)) {
                const m = Modalable.modalables[n];

                if (m.$slots && m.$slots.default) {
                    for (let j = 0; j < m.$slots.default.length; j++) {
                        const c = m.$slots.default[j];

                        if (c.componentInstance && c.componentInstance._uid == sUid) {
                            return m;
                        }
                    }
                }
            }
        }

        return false;
    }

    // Allow to register some windows like the deletewindow
    static registerWindow(obj, id) {
        Modalable.registeredWindow[id] = obj;
    }

    static async openRegisteredWindow(id, data, resource = 'element') {
        try {
            if (id === 'Deletewindow') Modalable.registeredWindow[id].setElement(resource);

            if (!Modalable.registeredWindow[id]) {
                throw `Modalable: ${id} doesn't exists.`;
            }

            await Modalable.registeredWindow[id].open(data);
            return true;
        } catch (e) {
            return false;
        }
    }

    static install(Vue) {
        Modalable.Vue = Vue;
        Vue.prototype.$Modalables = Modalable;

        // Mixed with all component code
        Vue.mixin({

            // A VIRER
            props: {
                $Modalable: {
                    type: Object,
                    default() {
                        return {

                            close: () => {
                                const m = Modalable.findByChildUid(this._uid);
                                if (m) {
                                    m.close();
                                }
                            },

                            promptOnChange: (item) => {
                                if (this.modalable_var.unwatch) {
                                    this.modalable_var.unwatch();
                                    this.modalable_var.contentChanged = false;
                                }
                                this.modalable_var.unwatch = this.$watch(item, function () {
                                    this.modalable_var.unwatch();
                                    this.modalable_var.contentChanged = true;
                                }, { deep: true });
                            },
                        };
                    },
                },
            },

            data() {
                return {
                    modalable_var: {
                        unwatch: null,
                        contentChanged: false,
                    },
                };
            },
        });
    }
}
