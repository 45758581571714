<template>
    <div class="d-flex">
        <div
            v-if="countReport.info > 0"
            class="icon-wrapper info"
        >
            <DSIconSuccess
                width="15"
                height="15"
            /> <span class="text">{{ countReport.info }}</span>
        </div>
        <div
            v-if="countReport.warning > 0"
            class="icon-wrapper warning"
        >
            <DSIconWarning
                width="17"
                height="15"
            /> <span class="text">{{ countReport.warning }}</span>
        </div>
        <div
            v-if="countReport.error > 0"
            class="icon-wrapper error"
        >
            <DSIconError
                width="15"
                height="15"
            /> <span class="text">{{ countReport.error }}</span>
        </div>
    </div>
</template>
<script>
import DSIconError from '@/components/design-system/icons/Alerts/DSIconError.vue';
import DSIconSuccess from '@/components/design-system/icons/Alerts/DSIconSuccess.vue';
import DSIconWarning from '@/components/design-system/icons/Alerts/DSIconWarning.vue';

export default {
    name: 'TinyIntegrationReport',
    components: { DSIconWarning, DSIconSuccess, DSIconError },
    mixins: [],
    props: {
        report: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            countReport: {
                error: 0,
                warning: 0,
                info: 0,
            },

        };
    },
    mounted() {
        const counter = [];
        counter.error = 0;
        counter.warning = 0;
        counter.info = 0;
        Object.entries(this.report).forEach(([status, logs]) => {
            Object.entries(logs).forEach(([, count]) => {
                counter[status] += count;
            });
        });

        this.countReport.error = counter.error;
        this.countReport.warning = counter.warning;
        this.countReport.info = counter.info;
    },
};
</script>

<style lang="scss" scoped>
.icon-wrapper{
    border-radius: 24px;
    padding: 2px 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    .text{
        margin-left: 5px;
    }

    &.info{
        background-color: #EBFFE7;
        color: #25B771;
    }

    &.warning{
        background-color: #FCDDB9;
        color: #E46E00;
    }

    &.error{
        background-color: #FFE5E3;
        color: #DC3D1B;
    }
}
</style>
