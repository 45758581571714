import Model from '@tony.caron/node-model-proxy/Model';
import Notifier from '../Notifier';
import I18n from '../modules/i18n/I18n';
import CompanyFile from './CompanyFile';
import CompanyMediaCollection from './CompanyMediaCollection';

export default class CompanyMedia extends Model {
    modelConfig() {
        return {
            collection: CompanyMediaCollection,
            onSave: 'refresh', // Do not send lookable to the query if we refresh TODO
        };
    }

    modelOutConvertor() {
        return {
            is_landing: (n) => {
                const videoExtension = ['mp4', 'ogg', 'webm'];
                if (this.type === 'file' && this.company_file && !videoExtension.includes(this.company_file.detected_extension)) { return false; }
                return n;
            },
        };
    }

    modelRelations() {
        return {
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
        };
    }

    modelEvents() {
        return {
            saved() {
                Notifier.getInstance('App').showInfo(
                    I18n.translate('You have successfully added a media'),
                );
            },

            updated() {
                Notifier.getInstance('App').showInfo(
                    I18n.translate('You have successfully updated the media'),
                );
            },

            deleted() {
                Notifier.getInstance('App').showInfo(
                    I18n.translate('You have successfully deleted'),
                );
            },

            failed() {
                Notifier.getInstance('App').showError(
                    I18n.translate('A problem occurred while trying to update this resource!'),
                );
            },
        };
    }
}
