<template>
    <div class="list-actions">
        <div
            v-for="(a, index) in sortedActions"
            :key="index"
        >
            <template v-if="a.actions.length > 1">
                <ListActionsByActionWrapper
                    :actions="a.actions"
                    :show-wrapper="a.showWrapper"
                    :resource="resource"
                    :show-multi-select="showMultiSelect"
                    :selected-action-list="selectedActionList"
                    :is-history="isHistory"
                />
            </template>
            <template v-else>
                <ListActionsByActionItem
                    :action="a.actions[0]"
                    :show-multi-select="showMultiSelect"
                    :selected-action-list="selectedActionList"
                    :is-history="isHistory"
                    class="mb-10"
                />
            </template>
        </div>
    </div>
</template>

<script>
import ListActionsByActionItem from './ListActionsByActionItem.vue';
import ListActionsByActionWrapper from './ListActionsByActionWrapper.vue';

export default {
    name: 'ListActionsByAction',
    components: {
        ListActionsByActionItem,
        ListActionsByActionWrapper,
    },
    props: {
        resource: {
            type: String,
            default: 'custom',
        },
        actions: {
            type: Array,
            default: () => [],
        },
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        selectedActionList: {
            type: Array,
            required: true,
        },
        isHistory: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        sortedActions() {
            return this.actions.map((action) => {
                action.actions
                    .sort((a, b) => this.sortCondition(
                        a.company_user_program_task.datetime_end,
                        b.company_user_program_task.datetime_end,
                    ));

                action.datetime_end_wrapped = action.actions[0]
                    .company_user_program_task.datetime_end;
                return action;
            })
                .sort((a, b) => this.sortCondition(
                    a.datetime_end_wrapped,
                    b.datetime_end_wrapped,
                ));
        },
    },
    methods: {
        sortCondition(a, b) {
            if (this.isHistory) return this.$Utils.moment(a) > this.$Utils.moment(b) ? -1 : 1;
            return this.$Utils.moment(a) < this.$Utils.moment(b) ? -1 : 1;
        },
    },
};
</script>

<style lang="scss" src="./ListActionsByAction.scss" scoped />
