import { render, staticRenderFns } from "./UserRequirementAddressRead.vue?vue&type=template&id=64ad94c7&scoped=true&"
import script from "./UserRequirementAddressRead.vue?vue&type=script&lang=js&"
export * from "./UserRequirementAddressRead.vue?vue&type=script&lang=js&"
import style0 from "./UserRequirementAddressEdit.scss?vue&type=style&index=0&id=64ad94c7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64ad94c7",
  null
  
)

export default component.exports