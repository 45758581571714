<template>
    <HoldingResourceList
        v-if="trainings.isLoaded()"
        :route-list="'HoldingResourcesTrainingsList'"
        :collection="trainings"
    >
        <template #table="holdingResourceList">
            <TrainingsList
                :items="holdingResourceList.items"
                :external-search="holdingResourceList.search"
                @onCellClicked="holdingResourceList.onCellClicked"
            />
        </template>
    </HoldingResourceList>
</template>
<script>
import HoldingResourceList from '@/components/globals/HoldingResourceList.vue';
import CompanyTrainingCollection from '@/models/CompanyTrainingCollection';
import TrainingsList, { trainingConfig } from '@/resourcesList/TrainingsList.vue';

export default {
    name: 'PageTrainingsList',
    components: {
        HoldingResourceList,
        TrainingsList,
    },

    data() {
        return {
            trainings: new CompanyTrainingCollection(trainingConfig.fields).with(trainingConfig.relations),
        };
    },

    created() {
        this.trainings.setEndPointPrefix('holding/lookup/');
        this.trainings.get();
    },
};
</script>
