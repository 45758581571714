<template>
    <div>
        <LineChart
            v-if="isGlobalUserView"
            :chart-id="chartId"
            :chart-options="chartOptions"
            :chart-data="chartData"
        />
        <ResultSatisfactionScaleNpsItem
            v-else
            v-for="choice in choices"
            :key="choice.id"
            class="item"
            v-bind="propsToPass(choice)"
        />
    </div>
</template>

<script>
import { Line as LineChart } from 'vue-chartjs';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import choiceResultsProps from '../choiceResultsProps';
import ResultSatisfactionScaleNpsItem from './ResultSatisfactionScaleNpsItem.vue';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

export default {
    name: 'ResultsSatisfactionScaleNps',
    components: {
        ResultSatisfactionScaleNpsItem,
        LineChart,
    },

    mixins: [
        choiceResultsProps,
    ],

    data() {
        const brandingColor = document.documentElement.style.getPropertyValue('--branding-color');

        return {
            chartId: this.$Utils.uniqId(),
            chartData: {
                labels: this.occurrences.map((o, i) => `Session ${i + 1}`),
                datasets: [
                    {
                        label: 'Data One',
                        data: this.answers.map((a) => {
                            const choice = this.choices.find((c) => c.id === a.choice_id);

                            return parseInt(choice.label, 10);
                        }),
                        fill: false,
                        borderColor: brandingColor,
                        tension: 0.1,
                    },
                ],
            },
            chartOptions: {
                scales: {
                    y: {
                        type: 'linear',
                        min: 0,
                        max: 10,
                        ticks: {
                            stepSize: 1,
                        },
                        grid: {
                            display: false,
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        };
    },
};
</script>
<style lang="scss" scoped>
.item:not(:last-child) {
    margin-bottom: 2%;
}
</style>
