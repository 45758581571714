<template>
    <div
        v-if="companyDocumentPublics.isLoaded()"
        class="full-width"
    >
        <SectionBar
            :title="'Company documents'"
            :has-add-button="true"
            @click="openModal()"
        />

        <template v-if="companyDocumentPublics.$.length">
            <CardList :options="cardListOptions">
                <Card
                    v-for="(item, index) in companyDocumentPublics.$.models"
                    :key="index"
                    :options="cardOptions"
                    @click="openModal(item.active)"
                >
                    <div slot="image">
                        <div class="item-image image-small">
                            <div
                                class="small-image"
                                :style="{'background-image': `url(${item.image})`}"
                            />
                        </div>
                    </div>
                    <div slot="title">
                        {{ getItemNameTranslation(item) }}
                    </div>
                </Card>
            </CardList>
        </template>
        <template v-else>
            <div class="empty-component">
                <p>
                    <strong>
                        <t>No company document added</t>
                    </strong>
                </p>
            </div>
        </template>

        <Modalable
            ref="modalableDocumentPublicEdit"
            class="modalable-1 medium"
            :mode="2"
        >
            <DocumentPublicEdit
                v-model="currentItem"
                :editing-lang="editingLang"
                :collection="companyDocumentPublics"
            />
        </Modalable>
    </div>
</template>

<script>
import CompanyDocumentPublicCollection from '@/models/CompanyDocumentPublicCollection';
import DocumentPublicEdit from '@/components/resources/documentPublic/DocumentPublicEdit.vue';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';

export default {
    name: 'DocumentPublicList',
    components: {
        DocumentPublicEdit,
    },

    props: {
        editingLang: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            currentItem: null,

            cardListOptions: {
                class: 'no-justify',
            },

            cardOptions: {
                type: 'long',
                perRow: 'auto',
            },

            companyDocumentPublics: new CompanyDocumentPublicCollection([
                'id', 'name', 'editable', 'type', 'url', 'description',
                'image', 'url', 'is_tos', 'translations', 'file_translations',
            ]).with({
                companyFilesFromLocale: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
                companyFiles: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
            }).orderBy([
                ['order', 'asc'],
            ]),
        };
    },

    async created() {
        await this.companyDocumentPublics.get();
    },

    methods: {
        openModal(item) {
            this.currentItem = item;
            this.$refs.modalableDocumentPublicEdit.open();
        },
        getItemNameTranslation(item) {
            if (!item.translations.name[this.editingLang]) {
                return item.translations.name[this.shared.session.company.company_languages.default.key];
            }

            return item.translations.name[this.editingLang];
        },
    },
};
</script>
