<template>
    <HtFormInput
        :id="id"
        :ref="refName"
        :name="id"
        :class="{ 'open': displayOptions}"
        :label="label"
        :data-vv-as="label"
        :show-optional="true"
        :value="value"
        class="suggests"
        :cypress="cypress"
        @blur="blurInput"
        @input="onInput"
    >
        <template #append>
            <FontAwesomeIcon
                size="1x"
                class="caret multiselect-caret"
                :icon="['fal', !displayOptions ? 'chevron-down' : 'chevron-up']"
                @click="displayOptions = !displayOptions"
            />
            <ul
                class="list"
                :class="{ active: displayOptions}"
            >
                <li
                    v-for="option in options"
                    :key="option.id"
                    class="list-item"
                    @click="selectOptions(option)"
                >
                    {{ option.name }}
                </li>
            </ul>
        </template>
    </HtFormInput>
</template>

<script>
import HtFormInput from '@/components/globals/HtFormInput.vue';
import CypressMixin from '@/mixins/CypressMixin';

export default {
    name: 'TimeSlot',
    comments: {
        HtFormInput,
    },
    mixins: [CypressMixin],
    inject: ['$validator'],
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        refName: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            displayOptions: false,
        };
    },
    mounted() {
        this.$refs[this.refName].$el.querySelector('input').addEventListener('keydown', this.handleKeyPress);
        this.$refs[this.refName].$el.querySelector('input').addEventListener('click', this.showOptions);
    },
    beforeDestroy() {
        this.$refs[this.refName].$el.querySelector('input').removeEventListener('keydown', this.handleKeyPress);
        this.$refs[this.refName].$el.querySelector('input').removeEventListener('click', this.showOptions);
    },
    methods: {
        handleKeyPress(event) {
            if (event.key === 'Escape') {
                this.displayOptions = false;
            }
        },
        showOptions() {
            this.displayOptions = true;
        },
        blurInput() {
            this.displayOptions = false;
        },
        onInput(value) {
            this.value = value;
            this.displayOptions = false;
            this.$emit('update:value', value);
            this.$emit('input', value);
        },
        selectOptions(option) {
            this.value = option.id;
            this.displayOptions = false;
            this.$emit('update:value', option.id);
            this.$emit('input', option.id);
        },
    },
};
</script>

<style>
.suggests .ht-form-body {
    position: relative;

}
</style>
<style scoped lang="scss">

.list {
    visibility: hidden;
    z-index: 1;
    overflow: auto;
    position: absolute;
    max-height: 300px;
    opacity: 0;
    width: 100%;
    font-size: 1.4rem;
    border: 1px solid #E5E6E6;
    background-color: white;
    flex-direction: column;
    transition: visibility 0.3s, opacity 0.3s linear;

    > .item-planning:not(:hover) {
        box-shadow: 0 0 0 1px #e5e6e6;
    }
    &.active {
        visibility: visible;
        opacity: 1;
        display: flex;
        transition: visibility 0s, opacity 0.3s linear;
    }
    .list-item {
        font-size: 1.4rem;
        padding: 15px;

        &:hover {
            background-color: rgba(0,0,0,0.1);
            cursor: pointer;
        }
    }
}
    .suggests {
        .caret {
            height: 1.2em;
            font-size: 12px;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translate(-50%, -50%);
            fill: currentColor;
        }
    }
</style>
