import Vue from 'vue';
import api from '@/store/api';

/**
 * @typedef {Object} Training
 * @property {number} id
 * @property {string} url
 * @property {number} order
 * @property {string} type
 * @property {boolean} is_locked
 * @property {{url: Object<string, string>}} translations
 * @property {CompanyFileHT[]} company_files
 * @property {CompanyFileHT} company_file
 * @property {FileTranslation[]} file_translations
 * @property {Resource} resource
 */

/**
 * @typedef {{data: {data: Training}}} TrainingResourceResponse
 * @typedef {{data: {data: Training[]}}} TrainingCollectionResponse
*/

export default {
    /**
     * @param {any} data
     * @returns {Promise<TrainingCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.post('configuration/trainings/list', data);
    },

    /**
     * @param {number|string} trainingId
     * @param {number|string} fileId
     * @returns {Promise<{CompanyFileHT}>}
     */
    duplicateFile(trainingId, fileId) {
        const params = { file: fileId, training: trainingId };

        const endpoint = api.utils.replaceVariables(
            api.endpoints.CONFIGURATION_TRAININGS_DUPLICATION_FILE,
            params,
        );

        return Vue.prototype.$http.post(endpoint);
    },

    /**
     * @param {number|string} id
     * @param {any} config
     * @returns {Promise<TrainingResourceResponse>}
     */
    replicate(id, config = {}) {
        return Vue.prototype.$http.post(`configuration/trainings/${id}/replicate`, config);
    },
};
