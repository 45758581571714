<template>
    <div class="h-100">
        <div class="container-tabs">
            <HtTabs v-if="isLoaded">
                <HtTab :title="translate('Flow of activity')">
                    <NotificationsActivities :company_user_id="companyUserId" />
                </HtTab>
                <HtTab :title="translate('Emails')">
                    <EmailsActivities :company_user_id="companyUserId" />
                </HtTab>
            </HtTabs>
        </div>
    </div>
</template>

<script>
import EmailsActivities from './activities/EmailsActivities.vue';
import NotificationsActivities from './activities/NotificationsActivities.vue';

export default {
    name: 'PageActivities',
    components: {
        NotificationsActivities,
        EmailsActivities,
    },
    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isLoaded: false,
            roleColumns: [
                {
                    label: this.translate('Flow of activity'),
                    key: 'activity_flow',
                },
                {
                    label: this.translate('Emails'),
                    key: 'emails',
                },
            ],
        };
    },

    created() {
        this.isLoaded = true;
    },
    methods: {},

};
</script>
