import Env from '@/Env';

/**
 * Check - via environment variable (.env) - if the current environment is local
 * @returns {boolean}
 */
export function isLocal() {
    return config('APP_ENV') === 'local';
}

/**
 * Get environment variable (from .env)
 * @param {string} variable
 * @returns {string}
 */
export function env(variable) {
    return Env.getFromEnv(variable);
}

/**
 * Get config (from .env.js)
 * @param variable
 * @returns {any}
 */
export function config(variable) {
    return Env.get(variable);
}
