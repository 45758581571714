<template>
    <div
        class="message"
        :class="{'message-with-date': data.date}"
    >
        <div>
            <span class="message-icon">
                <JobLogIcon :icon="data.status" />
            </span>
            <span
                v-if="data.date"
                class="message-date"
            >{{ data.date }}</span>
            <span class="message-content">{{ data.message }}</span>
        </div>
    </div>
</template>
<script>
import JobLogIcon from '@/pages/logs/JobLogIcon.vue';

export default {
    components: { JobLogIcon },
    props: ['data'],
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.message > div {
  display: grid;
  grid-template-columns: 4rem 1fr;
  font-weight: normal;
  margin-bottom: 1rem;
}

.message-with-date > div {
  grid-template-columns: 4rem 13rem 1fr;
}

.message-icon svg {
  max-width: 1.4rem;
}

.message-date {
  color: $color-text-grey;
}
</style>
