import Vue from 'vue';
import Model from '@tony.caron/node-model-proxy/Model';
import CompanyProgramEntity from '@/models/CompanyProgramEntity';
import CompanyProgramCollection from './CompanyProgramCollection';
import CompanyProgramLocale from './CompanyProgramLocale';
import CompanyUserProgram from './CompanyUserProgram';
import HtProgramType from './HtProgramType';
import CompanyProgramKeyDate from './CompanyProgramKeyDate';
import CompanyProgramNotification from './CompanyProgramNotification';
import CompanyProgramTask from './CompanyProgramTask';
import CompanySurvey from './CompanySurvey';
import CompanyEmailCustomTemplate from './CompanyEmailCustomTemplate';
import CompanyPlanningEventTemplate from './CompanyPlanningEventTemplate';
import CompanyRoleProgram from './CompanyRoleProgram';

export default class CompanyProgram extends Model {
    modelConfig() {
        return {
            collection: CompanyProgramCollection,
        };
    }

    modelCustomAttributes() {
        return {
            filters: {},
            locales_by_key: {},
            name_translated: null,
            description_translated: null,
        };
    }

    modelDefaultValues() {
        return {
            is_self_enrollable: false,
            switch_offset: 0,
        };
    }

    modelEvents() {
        return {
            loaded: () => {
                Vue.prototype.$modelUtils.createLocales(this.locales, ['name', 'description']);
            },
        };
    }

    modelRelations() {
        return {
            locales: () => this.hasMany(CompanyProgramLocale, 'company_program_id', 'id'),
            companyUserProgram: () => this.hasMany(CompanyUserProgram),
            htProgramType: () => this.belongsTo(HtProgramType, 'ht_program_type_id', 'id'),
            type: () => this.belongsTo(HtProgramType, 'ht_program_type_id', 'id'),
            keyDate: () => this.hasOne(CompanyProgramKeyDate, 'id', 'company_program_id'),
            keyDates: () => this.hasMany(CompanyProgramKeyDate, 'id', 'company_program_id'),
            entities: () => this.hasMany(CompanyProgramEntity),
            notifications: () => this.hasMany(CompanyProgramNotification),
            actions: () => this.hasMany(CompanyProgramTask),
            surveys: () => this.hasMany(CompanySurvey),
            emails: () => this.hasMany(CompanyEmailCustomTemplate),
            events: () => this.hasMany(CompanyPlanningEventTemplate),
            roleProgram: () => this.hasMany(CompanyRoleProgram, 'company_program_id', 'id'),
        };
    }

    modelAccessors() {
        return {
            locales_by_key: () => Vue.prototype.$modelUtils.createLocalesByKey(this.locales),
            name_translated: () => this.localize('name'),
        };
    }

    localize(field, languageKey, defaultLanguageKey) {
        return Vue.prototype.$modelUtils.localize(this.locales_by_key, field, languageKey, defaultLanguageKey);
    }
}
