<template>
    <div>
        <div class="d-flex justify-content-center pt-5 pb-5">
            <t>Add a resource</t>
        </div>
        <CardList
            :count-empty="1"
            :options="{
                type: 'long',
                perRow: 4
            }"
        >
            <Card
                v-for="(item, index) in resources"
                :key="index"
                :options="{
                    type: 'long',
                    perRow: 4,
                }"
                @click="openListResource(item)"
            >
                <div slot="image">
                    <div class="item-image image-small">
                        <div
                            class="small-image"
                            :style="{'background-image': `url(/static/icons/${item.image})`}"
                        />
                    </div>
                </div>
                <div slot="title">
                    <t>{{ item.label }}</t>
                </div>
            </Card>
        </CardList>
    </div>
</template>
<script>
export default {
    name: 'SelectResourceTypeModal',
    props: {
        resources: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            currentResource: null,
        };
    },

    methods: {
        openListResource(resource) {
            this.$emit('on-change-step', 'ListResourceModal', resource);
        },
    },
};
</script>
