<template>
    <div v-if="companyUserEquipmentCollection.isLoaded()">
        <HtAccordion
            :title="translate('Equipments')"
            :count="companyUserEquipmentCollection.models.length"
        >
            <HtTable
                v-if="companyUserEquipmentCollection.models.length > 0"
                :items="companyUserEquipmentCollection.models"
                :columns="columns"
                cypress="user-activities"
                @onCellClicked="openItemModal"
            >
                <template #cell(display_status)="scope">
                    <HtStatusLabel
                        :status="scope.item.company_user_program_task.status"
                        :tag="true"
                    />
                </template>
            </HtTable>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No Equipment added</t>
                </strong>
            </div>
        </HtAccordion>

        <Modalable
            ref="modalableItem"
            :mode="2"
            class="modalable-1 small"
        >
            <UserEquipmentItem
                :id="id"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate()"
                @onDelete="onDelete()"
            />
        </Modalable>
    </div>
</template>

<script>
import CompanyUserEquipmentCollection from '@/models/CompanyUserEquipmentCollection';
import UserEquipmentItem from '@/components/pages/dashboard/modals/equipment/UserEquipmentItem.vue';
import HtAccordion from '@/components/globals/HtAccordion.vue';
import HtStatusLabel from '@/pages/HtStatusLabel.vue';

export default {
    name: 'UserEquipmentProfile',
    components: {
        UserEquipmentItem,
        HtAccordion,
        HtStatusLabel,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            id: null,
            columns: [
                {
                    label: this.translate('status'),
                    key: 'display_status',
                    sortable: false,
                },
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                },
            ],
            companyUserEquipmentCollection: new CompanyUserEquipmentCollection([
                'id', 'company_user_id', 'company_equipment_id',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyEquipment: (query) => {
                    query.select(['id'])
                        .with({
                            resource: (query) => {
                                query.select([
                                    'id',
                                    'name',
                                ]);
                            },
                        })
                        .withTrashed();
                },
                companyUserProgramTask: (query) => {
                    query.select(['id', 'status'])
                        .where([
                            ['resource', '=', 'company_equipment'],
                        ]);
                },
            }).where([
                ['company_user_id', '=', this.companyUserId],
            ]),
        };
    },

    created() {
        this.companyUserEquipmentCollection.get().then(() => {
            this.$emit('on-load', this.companyUserEquipmentCollection.$.models.length);
        });
    },

    methods: {
        onDelete() {
            this.companyUserEquipmentCollection.get();
        },
        onUpdate() {
            this.companyUserEquipmentCollection.get();
        },
        openItemModal(row) {
            this.id = row.item.id;
            this.$refs.modalableItem.open();
        },
    },
};
</script>
