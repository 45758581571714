<template>
    <div class="container">
        <div class="row">
            <div
                v-if="fetchUserCount > maxUsers"
                class="col-md-12"
                style="margin-bottom: 20px;"
            >
                <HtToast
                    type="danger"
                    :title="translate('Too much participants selected')"
                    :message="translate('Add more filters for a more precise selection.')"
                />
            </div>
            <div class="col-md-12">
                <HtFormMultiSelect
                    id="programs"
                    :show-optional="false"
                    :label="translate('Programs')"
                    :value="selectedPrograms"
                    :options="programOptions"
                    name="programs"
                    :placeholder="translate('Programs')"
                    @input="values => updateFilter('programs', values)"
                />
            </div>

            <div class="col-md-12">
                <HtFormMultiSelect
                    v-if="!hasFilterOffice"
                    id="offices"
                    :show-optional="false"
                    :label="translate('Sites')"
                    :value="selectedValuesByEntityId[officeEntity.id]"
                    :options="valuesByEntityId[officeEntity.id]"
                    name="offices"
                    track-by="company_entity_value_id"
                    :placeholder="translate('Sites')"
                    @input="values => updateFilter(officeEntity.id, values)"
                />
            </div>

            <div class="col-md-12">
                <HtFormMultiSelect
                    v-if="!hasFilterDepartment"
                    id="departments"
                    :show-optional="false"
                    :label="translate('Departments')"
                    :value="selectedValuesByEntityId[departmentEntity.id]"
                    :options="valuesByEntityId[departmentEntity.id]"
                    name="departments"
                    track-by="company_entity_value_id"
                    :placeholder="translate('Departments')"
                    @input="values => updateFilter(departmentEntity.id, values)"
                />
            </div>

            <div class="col-md-12">
                <HtFormMultiSelect
                    id="roles"
                    :show-optional="false"
                    :label="translate('Roles')"
                    :value="selectedRoles"
                    :options="roleOptions"
                    name="roles"
                    :placeholder="translate('Roles')"
                    @input="values => updateFilter('roles', values)"
                />
            </div>

            <div class="col-md-12">
                <HtFormFlatPickr
                    id="datetimeStartfp"
                    :label="translate('Target a promotion')"
                    :show-optional="false"
                    :value="selectedDates.start"
                    :placeholder="translate('Starting date')"
                    :is-working-day="false"
                    name="datetimeStartfp"
                    @input="values => updateFilter('startDate', values)"
                />
            </div>

            <div class="col-md-12">
                <HtFormFlatPickr
                    id="datetimeEndfp"
                    :value="selectedDates.end"
                    :placeholder="translate('Ending date')"
                    :is-working-day="false"
                    name="datetimeEndfp"
                    :min-date="selectedDates.start ? $Utils.moment(selectedDates.start).add(1, 'days').format('YYYY-MM-DD') : null"
                    @input="values => updateFilter('endDate', values)"
                />
            </div>

            <div class="col-md-12">
                <HtFormMultiSelect
                    id="userStatus"
                    :show-optional="false"
                    :label="translate('Status')"
                    :options="userStatus"
                    :value="selectedUserStatus"
                    track-by="slug"
                    name="userStatus"
                    @input="values => updateFilter('status', values)"
                />
            </div>

            <div class="col-md-12">
                <div
                    v-for="entity in entities"
                    :key="entity.id"
                >
                    <HtFormMultiSelect
                        :id="`entity-${entity.id}`"
                        :show-optional="false"
                        :label="entity.is_heyteam ? translate(entity.name) : entity.name"
                        :value="selectedValuesByEntityId[entity.id]"
                        :options="valuesByEntityId[entity.id]"
                        :name="`entity-${entity.id}`"
                        track-by="company_entity_value_id"
                        @input="values => updateFilter(entity.id, values)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import groupBy from 'lodash.groupby';
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import HtFormFlatPickr from '@/components/globals/HtFormFlatPickr.vue';
import api from '@/store/api';
import HtToast from '@/components/globals/HtToast.vue';

export default {
    name: 'ChatGroupManagerFilters',
    components: {
        HtFormMultiSelect,
        HtFormFlatPickr,
        HtToast,
    },
    props: {
        maxUsers: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            filters: {
                entities: [],
                roles: [],
                dates: {
                    start: null,
                    end: null,
                },
                status: [],
                programs: [],
            },
            page: 1,
            hasFilterDepartment: true,
            hasFilterOffice: true,
            fetchUserCount: 0,
        };
    },
    computed: {
        allFiltersEmpty() {
            return !(this.filters.entities.length !== 0
                || this.filters.roles.length !== 0
                || this.filters.dates.start != null
                || this.filters.dates.end != null
                || this.filters.status.length !== 0
                || this.filters.programs.length !== 0);
        },
        programOptions() {
            return this.$store.state.programs.programs.map((program) => ({
                id: program.id,
                name: this.oldLocalize(program.locales, 'name'),
            }));
        },
        roleOptions() {
            return this.$store.state.roles.roles.map((role) => ({
                id: role.id,
                name: this.translate(role.alias),
            }));
        },
        officeEntity() {
            return this.$store.getters['entities/officeEntity'];
        },
        departmentEntity() {
            return this.$store.getters['entities/departmentEntity'];
        },
        entities() {
            return this.$store.state.entities.entities?.filter?.((entity) => [this.officeEntity.id, this.departmentEntity.id].includes(entity.id) === false);
        },
        queryValue() {
            return this.filters.query;
        },
        valuesByEntityId() {
            const valuesByEntityId = this.$store.getters['entities/valuesByEntityId'];

            for (const [key, values] of Object.entries(valuesByEntityId)) {
                valuesByEntityId[key] = values.sort((a, b) => a.name.localeCompare(b.name));
            }

            return valuesByEntityId;
        },
        selectedValuesByEntityId() {
            const entities = {};

            const valuesByEntityId = groupBy(this.filters.entities, 'company_entity_id');

            this.entities.forEach((entity) => {
                entities[entity.id] = valuesByEntityId[entity.id] || [];
            });

            return entities;
        },
        selectedPrograms() {
            return this.filters.programs;
        },
        selectedDates() {
            return this.filters.dates;
        },
        selectedRoles() {
            return this.filters.roles;
        },
        userStatus() {
            return this.$store.getters['team/userStatus'];
        },
        selectedUserStatus() {
            return this.filters.status;
        },
    },
    async created() {
        await this.$store.dispatch('entities/fetchEntities');
        await this.$store.dispatch('programs/fetchPrograms');
        await this.$store.dispatch('roles/fetchRoles');
    },

    methods: {
        async updateFilter(filter, values) {
            switch (filter) {
            case 'programs':
                this.filters.programs = values;
                break;
            case 'startDate':
                this.filters.dates.start = values;
                break;
            case 'endDate':
                this.filters.dates.end = values;
                break;
            case 'status':
                this.filters.status = values;
                break;
            case 'roles':
                this.filters.roles = values;
                break;
            default:
                const entities = this.filters.entities.filter((e) => e.company_entity_id !== filter);
                this.filters.entities = [...entities, ...values];
            }
            if (this.allFiltersEmpty) {
                this.fetchUserCount = 0;
                this.$emit('filtered', { data: [], virtualCount: 0 });
            } else {
                const data = await this.fetchUsers();
                this.$emit('filtered', { data: data.paginator.data, virtualCount: data.paginator.total });
            }
        },
        fetchUsers() {
            return new Promise((resolve, reject) => {
                api.users.all({
                    filters: this.filters,
                    page: this.page,
                    perPage: this.maxUsers,
                    skipRequester: true,
                }).then(({ data }) => {
                    this.hasFilterDepartment = data.hasFilterDepartment;
                    this.hasFilterOffice = data.hasFilterOffice;
                    this.fetchUserCount = data.paginator.total;
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        deleteFilters() {
            this.filters = {
                entities: [],
                roles: [],
                dates: {
                    start: null,
                    end: null,
                },
                status: [],
                programs: [],
            };
            this.hasFilterDepartment = true;
            this.hasFilterOffice = true;
            this.fetchUserCount = 0;
        },
    },
};
</script>

<style lang="scss" scoped>
    .container{
        min-height: calc(100vh - 240px);
    }

    .row {
        margin: 0;
        margin-top: 30px;
    }

    .button {
        background-color: #ffffff;
        height: 48px;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 0 15px;
        margin-bottom: 3rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;

        > .button__description {
            color: #979797;
        }

        > .button__icon {
            color: #35495e;
            font-size: 12px;
        }
    }
</style>
