import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';

import CompanySurveySupervisor from './CompanySurveySupervisor';

export default class CompanySurveySupervisorCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanySurveySupervisor,
        };
    }
}
