<template>
    <fieldset class="quick-actions-list">
        <template v-if="loading">
            <Skeleton
                v-for="index in 3"
                :key="index"
                class="skeleton-line"
            />
        </template>
        <legend v-if="title && actions.length">
            {{ title }}
        </legend>
        <QuickActionsItem
            v-for="(action, index) in actions"
            :key="index"
            :action="action"
            @close="$emit('close')"
        />
    </fieldset>
</template>

<script>
import QuickActionsItem from '@/components/globals/modals/QuickActions/QuickActionsItem.vue';
import { Skeleton } from 'vue-loading-skeleton';

export default {
    name: 'QuickActionsList',

    components: {
        QuickActionsItem,
        Skeleton,
    },

    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },

        actions: {
            type: Array,
            required: true,
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.quick-actions-list {
    margin: 24px 0;

    legend {
        width: 100%;
        padding: 0 24px;
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: #575757;
        margin-bottom: 4px;
    }

    .skeleton-line {
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        height: 44px;
    }
}
</style>
