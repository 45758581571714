<template>
    <DSIconSuccess v-if="info" />
    <DSIconWarning v-else-if="warning" />
    <DSIconError v-else-if="error" />
</template>
<script>

import DSIconSuccess from '@/components/design-system/icons/Alerts/DSIconSuccess.vue';
import DSIconWarning from '@/components/design-system/icons/Alerts/DSIconWarning.vue';
import DSIconError from '@/components/design-system/icons/Alerts/DSIconError.vue';

export default {
    name: 'IntegrationLogIcon',
    components: { DSIconSuccess, DSIconWarning, DSIconError },
    props: {
        icon: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: null,
        },
    },
    computed: {
        error() {
            return this.icon === 'done_with_errors';
        },
        warning() {
            return this.icon === 'done_with_warnings';
        },
        info() {
            return this.icon === 'done';
        },
    },
};
</script>
<style lang="scss" scoped>
</style>
