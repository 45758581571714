import Model from '@tony.caron/node-model-proxy/Model';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';
import CompanyValueCollection from './CompanyValueCollection';

export default class CompanyValue extends Model {
    modelConfig() {
        return {
            collection: CompanyValueCollection,
        };
    }

    modelDefaultValues() {
        return {
            is_heyteam: 0,
            translations: {
                name: {},
                description: {},
            },
        };
    }

    modelAccessors() {
        return {
            name: () => this.translations.name[RtwSharedSpace.store.session.companyUser.company_language.key],
            description: () => this.translations.description[RtwSharedSpace.store.session.companyUser.company_language.key],
        };
    }

    modelRelations() {
        return {
            values: () => this.hasMany(CompanyEntityValue, 'company_entity_id', 'id'),
        };
    }
}
