import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import CompanyEntity from './CompanyEntity';
import CompanyEntityValue from './CompanyEntityValue';
import CompanyUserEntityCollection from './CompanyUserEntityCollection';

export default class CompanyUserEntity extends Model {
    modelConfig() {
        return {
            collection: CompanyUserEntityCollection,
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            entity: () => this.belongsTo(CompanyEntity, 'company_entity_id', 'id'),
            value: () => this.belongsTo(CompanyEntityValue, 'company_entity_value_id', 'id'),
        };
    }
}
