<template>
    <transition name="modal-fade">
        <div
            v-show="isVisible"
            class="modal"
            @click.self="close()"
            @click.stop="() => {}"
        >
            <transition name="modal-slide-up">
                <div
                    v-show="isVisible"
                    ref="scrollable"
                    class="modal-content"
                    :class="size"
                >
                    <div class="modal-content-main">
                        <slot name="default" />
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import Modalable from '@/Modalable';

export default {
    name: 'HtModalBottom',
    inject: ['$validator'],

    shared: {
        modals: {},
    },

    provide() {
        return {
            modal: this,
        };
    },

    props: {
        id: {
            type: String,
            default: () => null,
        },
        size: {
            type: String,
            default: () => 'small',
        },
    },

    data() {
        return {
            isVisible: false,
        };
    },

    computed: {
        hasDefaultSlot() {
            return 'default' in this.$slots;
        },
    },

    mounted() {
        this.register();
    },

    beforeDestroy() {
        this.unregister();
    },

    methods: {
        sendEvent(name, callback) {
            if (name in this.$listeners) {
                return new Promise((resolve) => {
                    this.$emit(name, resolve);
                }).then(() => {
                    callback();
                });
            }

            callback();
        },
        open() {
            this.sendEvent('beforeOpen', () => {
                this.isVisible = true;
                this.$emit('onOpen');
            });

            this.$nextTick(() => this.$refs.scrollable.scrollTo(0, 0));
        },
        close() {
            this.sendEvent('beforeClose', () => {
                this.isVisible = false;
                this.$emit('onClose');
            });
        },
        register() {
            if (this.id !== null && this.shared.modals[this.id] === undefined) {
                this.shared.modals[this.id] = this;
            }
        },
        unregister() {

        },
        async deleteWindow(elementLabel) {
            return Modalable.openRegisteredWindow('Deletewindow', {}, elementLabel);
        },
    },
};
</script>

<style lang="scss" src="./HtModalBottom.scss" scoped />
