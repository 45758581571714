/* eslint-disable no-unused-vars */
export default () => ({
    inserted(el, binding, vnode) {
        const validator = vnode.context.$fvalidate;
        if (validator == undefined) return;

        validator.createNode(el, binding, vnode);
    },
    update(el, { expression, value }, vnode) {
        const validator = vnode.context.$fvalidate;
        if (validator == undefined) return;

        validator.updateNode(el, vnode);
    },
    unbind(el, { value }, vnode) {
        const validator = vnode.context.$fvalidate;
        if (validator == undefined) return;

        validator.removeNode(el, vnode);
    },
});
