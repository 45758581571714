<template>
    <div class="wrapper">
        <Button
            v-if="canEnableTest"
            @click="$emit('on-validate')"
        >
            <t>Enable for testing</t>
        </Button>
        <Button
            v-if="canCallAPI"
            @click="$emit('on-make-api-call')"
        >
            <t>Make all 20 API calls</t>
        </Button>
        <Button
            v-else-if="canEnable"
            @click="$emit('on-validate')"
        >
            <t>Enable</t>
        </Button>
    </div>
</template>

<script>
import get from 'lodash.get';

export default {
    name: 'DocuSignFooterEdit',
    props: {
        integration: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getApiCallStatus() {
            return get(this.integration.custom_validation, 'rules.api_call_status', null);
        },

        canEnableTest() {
            return this.$env.get('APP_ENV') !== 'production' && !this.integration.is_enabled;
        },

        canEnable() {
            return this.getApiCallStatus === 'done' && !this.integration.is_enabled;
        },

        canCallAPI() {
            return this.$env.get('APP_ENV') !== 'sandbox' && this.getApiCallStatus === 'pending';
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    gap: 1em;
}
</style>
