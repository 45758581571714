import Model from '@tony.caron/node-model-proxy/Model';
import Resource from '@/models/Resource';
import Company from './Company';
import CompanyProgramResourceFilter from './CompanyProgramResourceFilter';
import CompanyRequirement from './CompanyRequirement';
import CompanyRequirementCategorySupervisor from './CompanyRequirementCategorySupervisor';
import CompanyResourceProgram from './CompanyResourceProgram';
import CompanyRequirementCategoryCollection from './CompanyRequirementCategoryCollection';
import CompanySurveyQuestion from './CompanySurveyQuestion';
import CompanyResourceProgramParticipant from './CompanyResourceProgramParticipant';

export default class CompanyRequirementCategory extends Model {
    static RESOURCEABLE_CLASS = 'App\\CompanyRequirementCategory';

    modelConfig() {
        return {
            collection: CompanyRequirementCategoryCollection,
        };
    }

    modelDefaultValues() {
        return {
            name: '',
            is_duplicable: false,
            is_mandatory: false,
            description: '',
            order: 0,
            display_chatbot: false,
            display_survey: false,
            is_archivable: false,
        };
    }

    modelInConvertor() {
        return {
            display_chatbot: (value) => Boolean(value),
            display_survey: (n) => Boolean(n),
            is_archivable: (n) => Boolean(n),
        };
    }

    modelCustomAttributes() {
        return {
            /**
            * requirements qui sont supprimés
            * et qui impliquent aussi la suppression des userRequirements associés
            */
            hard_deleted_requirements_ids: [],
            name: null,
            description: null,
            preparerRole: null,
        };
    }

    modelAccessors() {
        return {
            name: () => this.resource?.name || '',
            description: () => this.resource?.description || '',
            preparerRole: () => this.resource?.participants?.models.filter((participant) => CompanyResourceProgramParticipant.TYPE_PREPARER === participant.type),
        };
    }

    modelRelations() {
        return {
            companyRequirement: () => this.hasMany(CompanyRequirement),
            companyProgramResourceFilter: () => this.hasMany(CompanyProgramResourceFilter, 'resource_id', 'id').where(['component_name', '=', 'company_requirement_category']),
            companyRequirementCategorySupervisor: () => this.hasMany(CompanyRequirementCategorySupervisor, 'company_requirement_category_id', 'id'),
            resourceProgram: () => this.hasMany(CompanyResourceProgram, 'resourceable_id', 'id'),
            resource: () => this.hasOne(Resource),
            surveyQuestion: () => this.hasOne(CompanySurveyQuestion),
            company: () => this.belongsTo(Company),
        };
    }
}
