<template>
    <div class="overflow-hidden">
        <TeamFilters v-if="isLoaded" />

        <template v-if="isLoaded && !isLoading">
            <TeamList
                :users="userPaginatorData"
            />

            <HtPagination
                class="mb-5"
                :value="userPaginator.current_page"
                :last-page="userPaginator.last_page"
                @input="fetchUsers"
            />
        </template>

        <IconLoading v-else />
    </div>
</template>

<script>
import HtPagination from '@/components/globals/HtPagination.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import TeamFilters from '@/components/pages/team/widget/TeamFilters.vue';
import CompanyUser from '@/models/CompanyUser';
import TeamList from '@/components/pages/team/TeamList.vue';
import Auth from '@/services/Auth';
import moment from 'moment';

export default {
    name: 'TeamListPage',
    components: {
        TeamList,
        TeamFilters,
        HtPagination,
        IconLoading,
    },

    props: {
        activePage: {
            type: String,
            default: '',
        },
    },

    computed: {
        userPaginatorData() {
            if (this.activePage === 'TeamMyClass') {
                return this.$store.state.team.paginator.data.filter((data) => data.id !== this.shared.session.companyUser.id);
            }

            return this.$store.state.team.paginator.data;
        },

        userPaginator() {
            return this.$store.state.team.paginator;
        },

        isLoaded() {
            return this.$store.state.entities.entitiesLoaded;
        },

        isLoading() {
            return this.$store.state.team.loading
                || !this.$store.state.team.paginator;
        },
    },

    watch: {
        $route() {
            this.changeContext();
        },
    },

    created() {
        this.changeContext();
    },

    methods: {
        fetchUsers(page = null) {
            this.$store.dispatch('team/fetchUsers', page);
        },

        changeContext() {
            const isAllEmployees = this.$route.name === 'TeamAllEmployees';

            this.$store.dispatch('entities/fetchEntities');
            this.$store.commit('team/set_status_filter', this.$store.getters['team/userStatus'].filter((x) => x.slug === CompanyUser.STATUS_ACTIVE));

            if (isAllEmployees) {
                this.$store.commit('team/set_dates_filter', {
                    start: null,
                    end: null,
                });
            } else {
                const user = Auth.getLoggedUser();
                this.$store.commit('team/set_dates_filter', {
                    start: moment(user.active_program.main_key_date.starts_at).subtract(2, 'week').format('YYYY-MM-DD HH:mm:ss'),
                    end: moment(user.active_program.main_key_date.starts_at).add(2, 'week').format('YYYY-MM-DD HH:mm:ss'),
                });
            }

            this.fetchUsers();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/styles/var';

    .ht-page-title {
        margin-bottom: 2rem;
        border-bottom: #E2E2E2 1px solid;
        padding: 2.2rem 2rem;
    }

    .team-directory-item {
        cursor: pointer;

        .team-user-image {
            height: 200px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 20px;
            border-top-left-radius: 0;
        }

        .team-user-wrapper {
            height: 90px;
            padding-top: 1em;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .team-user-name {
                font-family: $lato-bold;
            }

            .team-user-job-title {
                color: $color-text-grey;
                padding-top: 0.5em;
            }

            .team-user-email {
                font-size: 0.8em;
                padding-top: 0.7em;
            }
        }
    }
</style>
