<template>
    <div class="list-answer">
        <div
            v-for="item in companySurveyQuestionAnswerCollection.models"
            :key="item.id"
            :class="customClass(item)"
            class="list-answer-item"
            @click="sendAnswer(item.id)"
        >
            <div
                v-if="displayIcon"
                class="list-answer-item-icon"
            >
                <img :src="item.iconPath">
            </div>
            <div class="list-answer-item-text">
                {{ item.text }}
            </div>
        </div>
    </div>
</template>

<script>
import { ListQuestionSubtype } from '@/models/common/SurveyQuestionType';
import MessageResourceUserSurveyQuestionMixin from './mixin/MessageResourceUserSurveyQuestionMixin';

export default {
    name: 'MessageResourceUserSurveyQuestionSatisfactionScale',
    mixins: [
        MessageResourceUserSurveyQuestionMixin,
    ],
    computed: {
        displayIcon() {
            return this.companySurveyQuestion.subtype === ListQuestionSubtype.QUESTION_SUBTYPE_SMILEY;
        },
    },
    methods: {
        customClass(companySurveyQuestionAnswer) {
            return {
                hover: this.readOnly === false,
                active: this.isActive(companySurveyQuestionAnswer.id),
                nps: this.companySurveyQuestion.subtype === ListQuestionSubtype.QUESTION_SUBTYPE_NPS,
            };
        },
    },
};
</script>

<style lang="scss" scoped src="./MessageResourceUserSurveyQuestionSatisfactionScale.scss"></style>
