<template>
    <HtTabStepperModel
        :id="id"
        :tabs="tabs"
        :item-fields="itemFields"
        :item-relations="itemRelations"
        :model-name="'CompanyRole'"
        :collection-name="'CompanyRoleCollection'"
        @on-close-stepper="$emit('on-close-stepper')"
    />
</template>

<script>
import HtTabStepperModel from '@/layouts/HtTabStepperModel.vue';
import RolesAndPermissionsFormSettings from '@/components/pages/settings/RolesAndPermissions/form/RolesAndPermissionsFormSettings.vue';
import RolesAndPermissionsFormPermissions from '@/components/pages/settings/RolesAndPermissions/form/RolesAndPermissionsFormPermissions.vue';
import RolesAndPermissionsFormUsersAndPerimeters from '@/components/pages/settings/RolesAndPermissions/form/RolesAndPermissionsFormUsersAndPerimeters.vue';

export default {
    name: 'RoleFom',
    components: {
        HtTabStepperModel,
    },

    props: {
        id: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            itemFields: [
                'id',
                'alias',
                'name',
                'description',
                'is_removable',
                'is_editable',
                'is_heyteam',
                'default_sort_dashboard',
                'is_actions_tab_displayed_at_login',
            ],
            itemRelations: {
                companyRolePermission: (query) => {
                    query.select([
                        'id',
                        'component',
                        'use_perimeter',
                        'can_create',
                        'can_read',
                        'can_update',
                        'can_delete',
                        'can_validate',
                        'ht_permission_group_name',
                        'ht_permission_group_id',
                        'is_display',
                    ]).with({
                        htPermission: (query) => {
                            query.select([
                                'id',
                                'name',
                                'description',
                                'order',
                                'type',
                                'create_enabled',
                                'read_enabled',
                                'update_enabled',
                                'delete_enabled',
                                'validate_enabled',
                            ]).with({
                                htPermissionGroup: (query) => {
                                    query.select([
                                        'id',
                                        'name',
                                        'description',
                                        'order',
                                    ]);
                                },
                            });
                        },
                    });
                },
            },
            tabs: [
                {
                    ref: 'settings',
                    title: this.translate('Settings'),
                    component: RolesAndPermissionsFormSettings,
                    disabled: false,
                    noBody: false,
                },
                {
                    ref: 'permissions',
                    title: this.translate('Permissions'),
                    component: RolesAndPermissionsFormPermissions,
                    disabled: !(this.id),
                    noBody: false,
                },
                {
                    ref: 'usersAndPerimeters',
                    title: this.translate('Users and Perimeters'),
                    component: RolesAndPermissionsFormUsersAndPerimeters,
                    disabled: !(this.id),
                    noBody: false,
                },
            ],
        };
    },
};
</script>
