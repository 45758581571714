<template>
    <HtTable
        :items="items"
        :columns="columns"
        cypress="action-resource-table"
        :empty-message="'There are no action to display yet...'"
        :external-search="externalSearch"
        @onCellClicked="$emit('onCellClicked', $event)"
    >
        <template #cell(participants)="cell">
            <ParticipantList
                :users="cell.item.resource.programs.models[0].participants.models"
                :has-roles="true"
                :limit="3"
            />
        </template>
    </HtTable>
</template>
<script>
import HtTable from '@/components/globals/HtTable.vue';
import ParticipantList from '@/components/globals/ParticipantList.vue';
import ResourceListMixin from '@/mixins/ResourceListMixin';

export default {
    name: 'ActionList',
    components: { HtTable, ParticipantList },
    mixins: [
        ResourceListMixin,
    ],
    data() {
        return {
            columns: [
                {
                    label: this.translate('Title'),
                    key: 'resource.name',
                    class: 'action-title font-weight-bold',
                    search: true,
                },
                {
                    label: this.translate('Participants'),
                    key: 'participants',
                    sortable: false,
                    class: 'd-md-table-cell',
                    search: false,
                },
                {
                    label: this.translate('Program'),
                    key: 'resource.programs.models[0].program.name_translated',
                    sortable: false,
                    class: 'hide-mobile',
                    search: false,
                },
            ],
        };
    },
};

export const actionConfig = {
    fields: [
        'id',
        'description',
    ],
    relations: {
        resource: (query) => {
            query
                .select([
                    'id',
                    'name',
                    'description',
                ])
                .with({
                    programs: (query) => {
                        query.select([
                            'id',
                            'company_program_id',
                            'resourceable_id',
                            'resourceable_type',
                            'is_enabled',
                        ]).with({
                            dependencies: (query) => {
                                query.select([
                                    'id',
                                    'resourceable_type',
                                ]);
                            },
                            dependents: (query) => {
                                query.select([
                                    'id',
                                ]);
                            },
                            program: (query) => {
                                query.select(['id']).with({
                                    locales: (query) => {
                                        query.select(['id', 'language_key', 'name']);
                                    },
                                    keyDate: (query) => query.with({
                                        locales: (query) => query
                                            .select(['id', 'name', 'language_key']),
                                    }),
                                });
                            },
                            participants: (query) => {
                                query
                                    .select(['id', 'company_user_id', 'company_role_id'])
                                    .with({
                                        user: (query) => {
                                            query
                                                .select(['id', 'image', 'fullname']);
                                        },
                                        role: (query) => {
                                            query
                                                .select(['id', 'name', 'alias']);
                                        },
                                    });
                            },
                        });
                    },
                });
        },

        companyProgramResourceFilter: (query) => {
            query.select([
                'id',
                'company_entity_id',
                'company_entity_value_id',
                'component_name',
                'resource_id',
                'group',
                'name',
            ]);
            query.with({
                value: (query) => {
                    query
                        .select([
                            'id',
                            'company_entity_id',
                            'resourceable_type',
                            'resourceable_id',
                        ])
                        .with({
                            jobPosition: (query) => {
                                query.select(['id', 'name', 'is_heyteam']);
                            },
                            contract: (query) => {
                                query.select(['id', 'name', 'is_heyteam']);
                            },
                            office: (query) => {
                                query.select(['id', 'name', 'is_heyteam']);
                            },
                            department: (query) => {
                                query.select(['id', 'name', 'is_heyteam']);
                            },
                            custom: (query) => {
                                query.select(['id', 'key', 'name']);
                            },
                        });
                },
                entity: (query) => {
                    query.select([
                        'id',
                        'name',
                        'slug',
                        'is_enabled',
                        'is_heyteam',
                    ]);
                },
            });
        },
    },
};

</script>
