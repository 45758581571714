import Model from '@tony.caron/node-model-proxy/Model';
import CompanyEntityValueCustomCollection from './CompanyEntityValueCustomCollection';
import CompanyEntityValue from './CompanyEntityValue';
import CompanyEntity from './CompanyEntity';

export default class CompanyEntityValueCustom extends Model {
    modelConfig() {
        return {
            collection: CompanyEntityValueCustomCollection,
        };
    }

    modelCustomAttributes() {
        return {
		   name_translated: null,
        };
    }

    modelAccessors() {
        return {
            name_translated: () => this.name,
        };
    }

    modelRelations() {
        return {
            value: () => this.hasOne(CompanyEntityValue, 'resourceable_id', 'id'),
            entity: () => this.belongsTo(CompanyEntity, 'company_entity_id', 'id'),
        };
    }
}
