<template>
    <HtTable
        :items="items"
        :columns="columns"
        cypress="action-resource-table"
        :empty-message="'There are no equipment to display yet...'"
        :external-search="externalSearch"
        @onCellClicked="$emit('onCellClicked', $event)"
    />
</template>
<script>
import HtTable from '@/components/globals/HtTable.vue';
import ResourceListMixin from '@/mixins/ResourceListMixin';

export default {
    name: 'EquipmentsListModels',
    components: { HtTable },
    mixins: [
        ResourceListMixin,
    ],
    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'resource.name',
                    class: 'font-weight-bold',
                    sortDirection: 'asc',
                    search: true,
                },
                {
                    label: this.translate('Launch date'),
                    key: 'order_end',
                    class: 'd-none d-md-table-cell',
                },
                {
                    label: this.translate('Program'),
                    key: 'resource.programs.models[0].program.name_translated',
                    sortable: false,
                    class: 'hide-mobile',
                    search: false,
                },
            ],
        };
    },
};

export const equipmentConfig = {
    fields: [
        'id',
    ],
    relations: {
        resource: (query) => query
            .select([
                'name',
            ])
            .with({
                programs: (query) => query
                    .select([
                        'id',
                        'order_end',
                    ])
                    .with({
                        program: (query) => query
                            .select(['id'])
                            .with({
                                locales: (query) => {
                                    query.select([
                                        'id',
                                        'name',
                                        'language_key',
                                    ]);
                                },
                                keyDate: (query) => {
                                    query
                                        .select([
                                            'id',
                                        ])
                                        .with({
                                            locales: (query) => {
                                                query.select([
                                                    'id',
                                                    'name',
                                                    'language_key',
                                                ]);
                                            },
                                        });
                                },
                            }),
                    }),
            }),
    },
};
</script>
