import Model from '@tony.caron/node-model-proxy/Model';
import CompanyPlanningEventCollection from './CompanyPlanningEventCollection';
import CompanyPlanningEventAttendee from './CompanyPlanningEventAttendee';
import CompanyUser from './CompanyUser';
import CompanyFile from './CompanyFile';
import CompanyUserNote from './CompanyUserNote';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import CompanyUserProgram from './CompanyUserProgram';
import I18n from '../modules/i18n/I18n';

export default class CompanyPlanningEvent extends Model {
    static STATUS_DRAFT = 'draft';

    static STATUS_PENDING = 'pending';

    static STATUS_DONE = 'done';

    modelDefaultValues() {
        return {
            email_sending_offset: 0,
            timezone: 'Europe/Paris',
            is_delegated: false,
        };
    }

    modelCustomAttributes() {
        return {
            statusText: null,
        };
    }

    modelConfig() {
        return {
            collection: CompanyPlanningEventCollection,
        };
    }

    modelRelations() {
        return {
            companyUserProgram: () => this.belongsTo(CompanyUserProgram, 'company_user_program_id', 'id'),
            owner: () => this.belongsTo(CompanyUser, 'owner_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
            companyUserNote: () => this.hasMany(CompanyUserNote, 'resource_id', 'id').where(['resource_name', '=', 'company_planning']),
            companyPlanningEventAttendee: () => this.hasMany(CompanyPlanningEventAttendee, 'company_planning_event_id', 'id'),
            /** @deprecated */
            companyUserProgramTask: () => this.belongsTo(CompanyUserProgramTask, 'resource').where(['resource', '=', 'company_planning']),
            task: () => this.belongsTo(CompanyUserProgramTask, 'resource').where(['resource', '=', 'company_planning']),
        };
    }

    modelAccessors() {
        return {
            statusText: () => {
                switch (this.status) {
                case CompanyPlanningEvent.STATUS_DONE:
                    return I18n.translate('Done');
                case CompanyPlanningEvent.STATUS_PENDING:
                    return I18n.translate('Pending');
                case CompanyPlanningEvent.STATUS_DRAFT:
                    return I18n.translate('Draft');
                default:
                    return '';
                }
            },
        };
    }
}
