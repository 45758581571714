const UPDATE_BYPASS_TWO_FACTOR = 'update_bypass_two_factor';

export default {
    namespaced: true,

    state() {
        return {
            showTwoFactorAuthInformationModal: false,
        };
    },

    getters: {
        showTwoFactorAuthInformationModal: (state) => () => state.showTwoFactorAuthInformationModal,
    },

    actions: {
        updateShowTwoFactorAuthInformationModal({ commit }, value) {
            commit(UPDATE_BYPASS_TWO_FACTOR, value);
        },
    },

    mutations: {
        [UPDATE_BYPASS_TWO_FACTOR](state, value) {
            state.showTwoFactorAuthInformationModal = value;
        },
    },
};
