import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyResourceProgramFilter from '@/models/CompanyResourceProgramFilter';

export default class CompanyResourceProgramFilterCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyResourceProgramFilter,
        };
    }
}
