import Vue from 'vue';
import api from '@/store/api';

export default {
    /**
     * @returns {Promise}
     */
    getCompanies() {
        return Vue.prototype.$http.get(api.endpoints.HOLDING_COMPANIES);
    },

    /**
     * @returns {Promise}
     */
    createUser(form) {
        return Vue.prototype.$http.post(api.endpoints.HOLDING_USERS, form);
    },
    /**
     * @returns {Promise}
     */
    updateUser(userId, form) {
        const endpoint = api.utils.replaceVariables(api.endpoints.HOLDING_USERS_INDEX, {
            user: userId,
        });

        return Vue.prototype.$http.put(endpoint, form);
    },
    /**
     * @returns {Promise}
     */
    deleteUser(userId, form) {
        const endpoint = api.utils.replaceVariables(api.endpoints.HOLDING_USERS_INDEX, {
            user: userId,
        });

        return Vue.prototype.$http.delete(endpoint, form);
    },

    /**
     * Créé le mot de passe de l'utilisateur dans le chatbot
     *
     * @param {number} userId id de l'utilisateur modifiant son mdp
     * @param {string} password mot de passe saisi
     * @returns {Promise}
     */
    createPassword(userId, password) {
        const endpoint = api.utils.replaceVariables(api.endpoints.HOLDING_USERS_PASSWORD, {
            user: userId,
        });

        return Vue.prototype.$http.put(endpoint, { password });
    },

    /**
     * @param {number} companyId
     * @returns {Promise}
     */
    getLoginToken(companyId) {
        const endpoint = api.utils.replaceVariables(api.endpoints.HOLDING_LOGIN, {
            company: companyId,
        });

        return Vue.prototype.$http.get(endpoint);
    },
    /**
     * @returns {Promise}
     */
    getCompanyList() {
        return Vue.prototype.$http.get(api.endpoints.HOLDING_COMPANY_LIST);
    },
};
