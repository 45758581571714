import Model from '@tony.caron/node-model-proxy/Model';
import ResourceCollection from '@/models/ResourceCollection';
import CompanyResourceProgramParticipant from './CompanyResourceProgramParticipant';
import CompanyResourceProgram from './CompanyResourceProgram';

export default class Resource extends Model {
    modelConfig() {
        return {
            collection: ResourceCollection,
        };
    }

    modelDefaultValues() {
        return {
            translations: {
                name: {},
                description: {},
            },
            is_editable: true,
        };
    }

    modelRelations() {
        return {
            participants: () => this.hasMany(CompanyResourceProgramParticipant),
            programs: () => this.hasMany(CompanyResourceProgram),
        };
    }

    modelInConvertor() {
        return {
            is_editable: (n) => Boolean(n),
        };
    }
}
