<template>
    <div
        v-if="companyUserConfidential && companyUserConfidential.isLoaded()"
        class="mb-5"
    >
        <ProfileBloc
            :is-edit.sync="isEdit"
            :can-edit="canUpdate"
            :button-state="buttonState"
            class="mb-0"
            @on-save="onUpdate"
        >
            <div class="profile-section-title d-flex align-items-center">
                <t>Remuneration</t>
                <HtFormSwitch
                    :id="'toggle-show-salary'"
                    v-model="showConfidential"
                    :name="'toggle-show-salary'"
                    :on-label="translate('On')"
                    :off-label="translate('Off')"
                    :show-optional="false"
                    class="mb-0 ml-3"
                    @input="toggleDelete"
                />
            </div>

            <div
                v-show="showConfidential"
                class="profile-row form-2"
            >
                <div class="profile-column-half">
                    <!-- ANNUAL SALARY -->
                    <div class="profile-item">
                        <div class="profile-item-title">
                            <t>Annual salary</t>
                        </div>

                        <div class="profile-item-content">
                            <span v-if="!isEdit">{{ companyUserConfidential.$.salary ? companyUserConfidential.$.salary + currencies[companyUserConfidential.$.salary_currency].symbol : '' | empty }}</span>

                            <div
                                v-else
                                class="row"
                            >
                                <HtFormInput
                                    :id="'salary'"
                                    v-model="companyUserConfidential.salary"
                                    :type="'number'"
                                    :name="'salary'"
                                    class="col-md-6"
                                />
                                <HtFormSelect
                                    :id="'salary_currency'"
                                    v-model="companyUserConfidential.salary_currency"
                                    :name="'salary_currency'"
                                    :options="getCurrentieOptions"
                                    class="col-md-6"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- STOCK OPTIONS -->
                    <div class="profile-item">
                        <div class="profile-item-title">
                            <t>Stock Option</t>
                        </div>

                        <div class="profile-item-content">
                            <span v-if="!isEdit">{{ companyUserConfidential.$.stock_option | empty }}</span>

                            <HtFormTextarea
                                v-else
                                :id="'stock_option'"
                                v-model="companyUserConfidential.stock_option"
                                :name="'stock_option'"
                            />
                        </div>
                    </div>
                </div>

                <div class="profile-column-half">
                    <!-- EXTRA -->
                    <div class="profile-item">
                        <div class="profile-item-title">
                            <t>Extra</t>
                        </div>

                        <div class="profile-item-content">
                            <span v-if="!isEdit">{{ companyUserConfidential.$.extra ? companyUserConfidential.$.extra + currencies[companyUserConfidential.$.extra_currency].symbol : '' | empty }}</span>

                            <div
                                v-else
                                class="row"
                            >
                                <HtFormInput
                                    :id="'extra'"
                                    v-model="companyUserConfidential.extra"
                                    :name="'extra'"
                                    :type="'number'"
                                    class="col-md-6"
                                />
                                <HtFormSelect
                                    :id="'extra_currency'"
                                    v-model="companyUserConfidential.extra_currency"
                                    :name="'extra_currency'"
                                    :options="getCurrentieOptions"
                                    class="col-md-6"
                                />
                            </div>
                        </div>
                    </div>

                    <!-- INFORMATION -->
                    <div class="profile-item">
                        <div class="profile-item-title">
                            <t>Information</t>
                        </div>

                        <div class="profile-item-content">
                            <span v-if="!isEdit">{{ companyUserConfidential.$.information | empty }}</span>

                            <HtFormTextarea
                                v-else
                                :id="'information'"
                                v-model="companyUserConfidential.information"
                                :name="'information'"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ProfileBloc>
        <Modalable
            ref="deleteConfidential"
            class="modalable-1 small"
            :mode="2"
        />
    </div>
</template>

<script>
import ProfileBlocMixin from '@/components/mixins/ProfileBlocMixin';
import CompanyUserConfidential from '@/models/CompanyUserConfidential';
import Currencies from '@/Currencies';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import ProfileBloc from '../ProfileBloc.vue';

export default {
    name: 'ProfileAdministrativeConfidential',
    permissions: [
        'ModelCompanyUserConfidential',
    ],

    components: {
        ProfileBloc,
        HtFormSwitch,
    },

    mixins: [
        ProfileBlocMixin,
    ],

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            companyUserConfidential: null,
            currencies: {},
            saving: false,
            showConfidential: false,
        };
    },

    computed: {
        getCurrentieOptions() {
            return Object.keys(this.currencies).map((currency) => ({
                name: this.currencies[currency].label,
                id: currency,
            })).sort((a, b) => a.name > b.name);
        },

        canUpdate() {
            return this.showConfidential && this.$canUpdate('ModelCompanyUserConfidential', { company_user_id: this.companyUserId });
        },

        canDelete() {
            return this.$canDelete('ModelCompanyUserConfidential', { company_user_id: this.companyUserId });
        },
    },

    created() {
        this.currencies = Currencies.list;
        this.loadCompanyUserConfidential().then((companyUserConfidential) => {
            if (companyUserConfidential.id) {
                this.showConfidential = true;
            } else {
                this.companyUserConfidential.company_user_id = this.companyUserId;
            }
        });
    },

    methods: {
        loadCompanyUserConfidential() {
            return new Promise((resolve, reject) => {
                this.companyUserConfidential = new CompanyUserConfidential([
                    'id',
                    'company_user_id',
                    'salary',
                    'salary_currency',
                    'extra',
                    'extra_currency',
                    'stock_option',
                    'information',
                ]).where([['company_user_id', '=', this.companyUserId]]);

                this.companyUserConfidential.get().then((data) => {
                    resolve(data);
                }).catch(() => {
                    reject();
                });
            });
        },

        onUpdate() {
            this.buttonState = 'loading';
            this.companyUserConfidential.save().then(() => {
                this.isEdit = false;
            }).finally(() => {
                this.buttonState = 'idle';
            });
        },

        toggleDelete(val) {
            if (!val && this.canDelete && this.companyUserConfidential.id) {
                this.$refs.deleteConfidential.deleteWindow({
                    content: this.translate('Are you sure you want to permanently delete the salary information? Once you click on delete, you will no longer be able to restore it.'),
                }).then((result) => {
                    if (result) {
                        this.companyUserConfidential.delete().then(() => {
                            this.loadCompanyUserConfidential();
                            this.isEdit = false;
                        });
                    } else {
                        this.showConfidential = true;
                    }
                });
            } else {
                this.isEdit = false;
            }
        },
    },
};
</script>
