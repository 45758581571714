import CardImgLeftSection from './CardImgLeftSection';

export default {
    name: 'Card video left',
    image: 'card-video-left.png',
    fullPageBorder: false,
    content: `
        <section class="ht-section-card-video">
            <div class="card">
                <div class="card-image">
                    <iframe style="width: 450px; height: 100%; object-fit: cover; border: none;" src="https://www.youtube.com/embed/m4ByvVxLiwc" allowfullscreen="allowfullscreen"></iframe>
                </div>
                <div class="card-content">
                    <h2 class="title">Lorem ipsum dolor....</h2>
                    <p>... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias corporis deserunt ducimus labore non omnis quibusdam sapiente suscipit voluptate?.</p>
                    <a class="more-link" href="#open-modal">Voir plus &rarr;</a>
                </div>
            </div>

            <!-- Modal -->
            <div id="open-modal" class="modal-window">
                <div>
                    <a class="modal-close" title="Close" href="#">Close</a>
                    <h1>Title</h1>
                    <!-- Modal Media --> <iframe style="width: 450px; height: 100%; object-fit: cover; border: none;" src="https://www.youtube.com/embed/m4ByvVxLiwc" allowfullscreen="allowfullscreen"></iframe>
                    <div>Content</div>
                </div>
            </div>
        </section>
    `,
    css: `
        ${CardImgLeftSection.css}

        .ht-section-card-video {
            padding: 40px 0;
        }
    `,
};
