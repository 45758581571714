<template>
    <transition name="fade">
        <TranslatedForm
            v-if="item"
            v-slot="{editingLang}"
        >
            <form
                class="form-2 spaced"
                @submit.prevent=""
            >
                <div class="modal-content-item-wrapper">
                    <div class="modal-content-item no-bg">
                        <HtFormInput
                            id="name"
                            ref="nameInput"
                            v-model="item.resource.translations.name[editingLang]"
                            v-validate.disable="isDefaultLangSelected(editingLang) ? 'required|max:255' : 'max:255'"
                            name="name"
                            :label="translate('Training name')"
                            :data-vv-as="translate('name')"
                            :cypress="'training-field-name'"
                            show-asterisk
                        />

                        <HtFormSwitch
                            v-if="canCreatePublicResource"
                            :id="'is_private'"
                            v-model="item.resource.is_private"
                            :description="translate('Mark this resource as private')"
                            :name="'is_private'"
                            :show-optional="false"
                            :label="translate('In my personal library')"
                            :cypress="'training-field-is-private'"
                        />

                        <LockedToggle
                            v-if="$can('AbstractManageLockedResources')"
                            v-model="item.is_locked"
                        />

                        <fieldset class="medium">
                            <HtFormEditor
                                :id="'description'"
                                v-model="item.resource.translations.description[editingLang]"
                                :name="'description'"
                                :label="translate('Description')"
                                :has-toolbar="false"
                                :cypress="'training-field-description'"
                            />
                        </fieldset>

                        <fieldset v-show="isDefaultLangSelected(editingLang)">
                            <HtFormSelect
                                v-if="priorTrainingOptions.length"
                                id="prior-training"
                                v-model="item.order"
                                :label="translate('Ask after')"
                                name="prior-training"
                                :options="priorTrainingOptions"
                                :allow-empty="true"
                            />
                        </fieldset>

                        <span>
                            <HtFormCard>
                                <div class="mb-4 rounded branding-border-color d-flex justify-content-between">
                                    <div
                                        :class="[
                                            {'branding-bg font-weight-bold': item.type === 'file'},
                                            'w-100 text-center py-2 cursor-pointer',
                                        ]"
                                        @click="item.type = 'file'"
                                    >
                                        <t>Document</t>
                                    </div>
                                    <div
                                        :class="[
                                            {'branding-bg font-weight-bold': item.type === 'link'},
                                            'w-100 text-center py-2 cursor-pointer',
                                        ]"
                                        @click="item.type = 'link'"
                                    >
                                        <t>URL</t>
                                    </div>
                                </div>

                                <template v-if="item.type === 'file'">
                                    <TranslatableFiles
                                        :files="item.file_translations"
                                        :errors-messages="errors"
                                        :editing-lang="editingLang"
                                        :max-size="250"
                                        :media="['office', 'pdf', 'image', 'video']"
                                        :validation-rules="{
                                            translation_default:[item.file_translations, shared.session.company.company_languages.default.key]
                                        }"
                                        @input="v => item.file_translations = v"
                                    />
                                </template>
                                <template v-else>
                                    <label>
                                        <t>Attach an url</t> *
                                    </label>
                                    <HtInputText
                                        :id="'url'"
                                        ref="urlInput"
                                        v-model="item.translations.url[editingLang]"
                                        v-validate.disable="isDefaultLangSelected(editingLang) ? 'required|valid_link' : 'valid_link'"
                                        :name="'url'"
                                        :placeholder="translate('Copy-paste a link')"
                                        :data-vv-as="translate('url')"
                                    />
                                    <div
                                        v-for="(errorMessage, index) in errors.collect('url')"
                                        :key="index"
                                        class="error-message"
                                    >
                                        <span v-if="errorMessage"><t>{{ errorMessage }}</t></span>
                                    </div>
                                </template>
                            </HtFormCard>
                        </span>

                        <div v-show="isDefaultLangSelected(editingLang)">
                            <HtProgramList
                                v-if="item.resource.programs.length > 0"
                                ref="htProgramListInput"
                                v-model="item.resource.programs[0].company_program_id"
                                :required="false"
                                :with-default="false"
                                :disabled="disabledProgramList"
                                class="mt-5"
                            />
                        </div>

                        <section
                            v-if="isResourceTargeted"
                            v-show="isDefaultLangSelected(editingLang)"
                        >
                            <HtFormGroup
                                v-show="!item.resource.is_private"
                                :label="translate('Filters')"
                                :description="translate('Add filters to decide who can access the resource')"
                            >
                                <HtFormCard>
                                    <ProgramResourceFilters
                                        v-if="item.resource.programs[0].company_program_id"
                                        v-model="item.resource.programs[0].filters"
                                        :program-id="item.resource.programs[0].company_program_id"
                                        :use-resource-perimeter="useResourcePerimeter"
                                    />
                                    <div
                                        v-else
                                        class="sentence-filter"
                                    >
                                        <t>Select a program to display associated filters</t>
                                    </div>
                                </HtFormCard>
                            </HtFormGroup>

                            <HtFormCard
                                v-show="!item.resource.is_private"
                                class="mb-5"
                            >
                                <RetroactivityToggle
                                    :is-retroactive.sync="isRetroactive"
                                    :send-notif.sync="sendRetroactiveNotification"
                                    :affected-programs="affectedPrograms"
                                    :resource="'company_document'"
                                    :loading="affectedProgramsLoading"
                                />
                            </HtFormCard>

                            <HtKeyDatesInputOrder
                                :program-id="item.resource.programs[0].company_program_id"
                                :resource-id="item.id"
                                :resource-type="resourceableClass"
                                :filters="item.resource.programs[0].filters"
                                :is-business-day.sync="item.resource.programs[0].is_business_day"
                                :offset-key-date.sync="item.resource.programs[0].company_program_key_date_id"
                                :offset-availability-number.sync="item.resource.programs[0].offset_availability_number"
                                :offset-availability-unit.sync="item.resource.programs[0].offset_availability_unit"
                                :offset-end-number.sync="item.resource.programs[0].offset_end_number"
                                :offset-end-unit.sync="item.resource.programs[0].offset_end_unit"
                                :dependents.sync="item.resource.programs[0].dependents"
                                :dependencies.sync="item.resource.programs[0].dependencies"
                            />
                        </section>
                    </div>
                </div>
            </form>

            <div class="modal-actions right">
                <Button
                    v-show="canDelete"
                    class="negative"
                    cypress="training-delete"
                    @click="onDelete()"
                >
                    <t>Delete</t>
                </Button>
                <Button
                    :state="buttonState"
                    :cypress="'training-form-submit'"
                    @click="onValidate()"
                >
                    {{ labelButton }}
                </Button>
            </div>
        </TranslatedForm>
    </transition>
</template>

<script>
import HtFormCard from '@/components/globals/HtFormCard.vue';
import UploadBar from '@/components/globals/UploadBar/UploadBar.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtTextDescription from '@/components/globals/HtTextDescription.vue';
import HtInputText from '@/components/globals/HtInputText.vue';
import HtProgramList from '@/components/globals/HtProgramList.vue';
import ProgramResourceFilters from '@/components/globals/ProgramResourceFilters.vue';
import RetroactivityToggle from '@/components/globals/RetroactivityToggle.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import LockedToggle from '@/components/globals/LockedToggle.vue';
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import ResourceModalEditMixin from '@/mixins/ResourceModalEditMixin';
import ResourceKeyDateLabelMixin from '@/mixins/ResourceKeyDateLabelMixin';
import TranslatedForm from '@/components/form/TranslatedForm.vue';
import TranslatableFiles from '@/components/form/TranslatableFiles.vue';
import CompanyTraining from '@/models/CompanyTraining';
import I18n from '@/modules/i18n/I18n';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import cloneDeep from 'lodash.clonedeep';

export default {
    name: 'TrainingEdit',
    components: {
        HtFormSwitch,
        HtKeyDatesInputOrder,
        HtFormCard,
        UploadBar,
        HtFormInput,
        HtProgramList,
        HtTextDescription,
        HtInputText,
        ProgramResourceFilters,
        RetroactivityToggle,
        LockedToggle,
        TranslatedForm,
        TranslatableFiles,
    },

    mixins: [
        DefaultFiltersMixin,
        DefaultOrderCompletionMixin,
        ResourceModalEditMixin,
        ResourceKeyDateLabelMixin,
    ],

    inject: ['modal'],

    props: {
        id: {
            type: Number,
            required: false,
        },
        trainings: {
            type: Array,
            required: false,
        },
    },

    data() {
        return {
            currentLanguage: this.shared.session.company.company_language,
            item: null,
            affectedPrograms: 0,
            isRetroactive: false,
            sendRetroactiveNotification: false,
            affectedProgramsLoading: false,
        };
    },
    computed: {
        userPerimeterEntities() {
            return this.$store.state.user.perimeterEntities;
        },

        useResourcePerimeter() {
            return this.$store.getters['permissions/useResourcePerimeter']('ModelCompanyDocument');
        },

        resourceableClass() {
            return CompanyTraining.RESOURCEABLE_CLASS;
        },

        taskType() {
            return CompanyUserProgramTask.TASK_COMPANY_USER_DOCUMENT;
        },

        isResourceTargeted() {
            return this.item && this.item.resource.programs.length > 0 && !!this.item.resource.programs[0].company_program_id;
        },

        canCreatePublicResource() {
            const canCreatePublicResource = this.$canCreate('ModelCompanyDocument');
            const isCreationMode = !this.id;
            return isCreationMode && canCreatePublicResource;
        },

        // TODO handle this function by passing an array of items
        priorTrainingOptions() {
            if (!this.trainings?.length) {
                return [];
            }

            return this.trainings.filter((training) => training.id !== this.item?.id)
                .map((training) => ({
                    id: training.order + 1,
                    name: training.resource.name,
                }))
                .sort((a, b) => a.name.localeCompare(b.name));
        },

        hasChildren() {
            return this.item.resource.programs[0].dependencies && this.item.resource.programs[0].dependencies.length > 0;
        },
        hasParent() {
            return this.item.resource.programs[0].dependents && this.item.resource.programs[0].dependents.length > 0;
        },
        labelTitle() {
            return I18n.translate(this.id ? 'Edit training' : 'Add training');
        },
    },

    watch: {
        'item.company_file': function (val) {
            if (val) this.getIcon(val.detected_extension);
        },
        isRetroactive() {
            this.getUserProgramsAffected();
        },
    },

    beforeDestroy() {
        this.$eventHub.$off('filter-change', this.getUserProgramsAffected);
        this.$eventHub.$off('program-change', this.getUserProgramsAffected);
    },

    methods: {
        async fetchModelFromId() {
            const training = (await this.$http.get(`/configuration/trainings/${this.id}`)).data.data;

            const isResourceTargeted = training.resource.programs.length > 0;
            if (!isResourceTargeted) {
                // default values for the targeting
                training.resource.programs[0] = {
                    company_program_id: null,
                    company_program_key_date_id: null,
                    offset_availability_number: 0,
                    offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                    offset_end_number: 0,
                    offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                    is_business_day: true,
                    is_enabled: true,
                    dependencies: [],
                    dependents: [],
                    participants: [],
                    filters: [],
                };
            }

            return {
                ...training,
                isNew() {
                    return !this.id;
                },
            };
        },

        async duplicateModel() {
            this.item = await this.fetchModelFromId();
            this.item.id = undefined;
            this.item.resource.id = undefined;
            this.item.resource.programs[0].id = undefined;
            this.item.resource.programs[0].company_program_id = this.companyProgramId;
            this.item.resource.programs[0].company_program_key_date_id = null;
            this.item.resource.programs[0].filters = [];
            this.item.resource.programs[0].dependents = [];
            this.item.resource.programs[0].dependencies = [];
            this.item.resource.programs[0].participants = [];
        },

        setDefaultOrderCompletion() {},

        createModelFromCollection() {
            this.item = {
                type: 'file',
                order: 0,
                isNew() {
                    return !this.id;
                },
                file_translations: {

                },
                translations: {
                    url: {},
                },
                resource: {
                    programs: [
                        {
                            company_program_id: this.companyProgramId,
                            company_program_key_date_id: null,
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            is_enabled: true,
                            dependencies: [],
                            dependents: [],
                            participants: [],
                            filters: [],
                        },
                    ],
                    translations: {
                        name: {},
                        description: {},
                    },
                    // if I can't see this field, then the resource is private
                    is_private: !this.canCreatePublicResource,
                },
            };

            if (this.useResourcePerimeter) {
                this.item.resource.programs[0].filters = this.userPerimeterEntities;
            }

            this.affectedPrograms = 0;
            this.affectedProgramsLoaded = true;
            this.listenFiltersOrProgramChange();
        },

        async createModelFromId() {
            this.item = await this.fetchModelFromId();
            this.listenFiltersOrProgramChange();
        },

        getUserProgramsAffected() {
            if (this.isRetroactive === false) return;
            this.affectedProgramsLoading = true;
            const resourceProgram = this.isResourceTargeted ? this.item.resource.programs[0] : {};
            const programIds = this.isResourceTargeted ? [resourceProgram.company_program_id] : [];
            const entities = this.isResourceTargeted ? resourceProgram.filters.map((filter) => ({
                company_entity_id: filter.company_entity_id,
                company_entity_value_id: filter.company_entity_value_id,
            })) : [];

            this.$http.post('affected-programs-retroactivity', {
                task_type: this.taskType,
                resource_id: this.id,
                entities,
                program_ids: programIds,
            }).then((response) => {
                this.affectedPrograms = response.data.count;
            }).finally(() => {
                this.affectedProgramsLoading = false;
            });
        },

        listenFiltersOrProgramChange() {
            this.$eventHub.$on('filter-change', this.getUserProgramsAffected);
            this.$eventHub.$on('program-change', this.getUserProgramsAffected);
        },

        getIcon(type) {
            const typeToIcon = {
                ppt: '/static/icons/ppt.svg',
                pptx: '/static/icons/ppt.svg',
                pdf: '/static/icons/pdf.svg',
                xls: '/static/icons/excel.svg',
                xlsx: '/static/icons/excel.svg',
                doc: '/static/icons/word.svg',
                docx: '/static/icons/word.svg',
            };
            const defaultIcon = '/static/icons/doc.svg';

            this.item.image = typeToIcon[type] || defaultIcon;
        },

        isDefaultLangSelected(editingLang) {
            return editingLang === this.shared.session.company.company_language.key;
        },

        onValidate() {
            // to be sure we can validate the default lang
            this.currentLanguage = this.shared.session.company.company_language;

            this.$nextTick(async () => {
                const result = await this.$validator.validateAll();

                if (result !== true) {
                    this.$Utils.scrollToFirstError(this);
                    return;
                }

                this.buttonState = 'loading';

                const resourceToSave = cloneDeep({
                    ...this.item,
                    is_retroactive: this.isRetroactive,
                    send_retroactive_notif: this.sendRetroactiveNotification,
                });
                if (!resourceToSave.resource.programs[0].company_program_id) {
                    resourceToSave.resource.programs = [];
                }

                await this.$http.post(`/configuration/trainings/${this.item.id || ''}`, resourceToSave);
                this.buttonState = 'idle';

                this.$emit('onSave');
                this.modal.close();
            });
        },

        async onDelete() {
            if (await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this training? Once you click on delete, you will no longer be able to access this training.') })) {
                this.buttonState = 'loading';
                await this.$http.delete(`/configuration/trainings/${this.id}`);
                this.buttonState = 'idle';

                this.$emit('onDelete');
                this.modal.close();
            }
        },
    },
};
</script>
