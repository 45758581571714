<template>
    <div class="box-tabs onboarding-task-action">
        <div class="container-tabs">
            <ul class="tabs-wrapper">
                <li
                    class="menu-item"
                    @click="currentTab = 1"
                >
                    <t>Option</t>
                </li>
            </ul>
        </div>
        <div class="box has-padding">
            <HtFormSwitch
                :id="'toggle-done-tasks'"
                v-model="showDoneTasks"
                :name="'toggle-done-tasks'"
                :label="translate('Display done actions')"
                :show-optional="false"
                class="my-4"
            />
        </div>
    </div>
</template>

<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'UserPRogramTaskAction',
    components: {
        HtFormSwitch,
    },

    shared: {
        program: {
            showDoneTasks: {
                type: Boolean,
                default: true,
            },
        },
    },

    computed: {
        showDoneTasks: {
            get() {
                return this.shared.program.showDoneTasks;
            },
            set(value) {
                this.shared.program.showDoneTasks = value;
            },
        },
    },
};
</script>

<style lang="scss">
    @import '~@/styles/var';

    .onboarding-task-action {
        border-radius: 10px;
    }
</style>
