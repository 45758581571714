<template>
    <HtFormMultiSelect
        :id="'htUserTaskDependent'"
        v-validate.disable="'required'"
        :value="currentDependent"
        :label="translate('Resource to complete to unlock')"
        :placeholder="translate('No action selected')"
        :options="getAvailableTask"
        :name="'htUserTaskDependent'"
        :is-single="true"
        :show-optional="false"
        :data-vv-as="translate('action selection')"
        :max-height="230"
        :open-direction="'above'"
        cypress="dependent-select"
        :disabled="disabled"
        @input="onSelectDependent"
    />
</template>

<script>
import CompanyUserProgramTaskCollection from '@/models/CompanyUserProgramTaskCollection';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';

export default {
    name: 'HtUserTaskDependency',
    inject: ['$validator'],

    props: {
        dependents: {
            type: Array,
        },
        dependencies: {
            type: Array,
        },
        resourceId: {
            type: Number,
            default: 0,
        },
        resourceType: {
            type: String,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            currentDependent: null,
            availableTask: [],
            companyUserProgramTask: new CompanyUserProgramTaskCollection([
                'id',
                'title',
                'resource',
                'resource_id',
            ]).where([
                ['company_user_program_id', '=', this.companyUserProgramId],
            ]).whereIn([
                ['resource', ['company_document', 'custom', 'company_doc', 'company_user_quiz', 'company_survey', 'company_email_custom']],
            ]),
        };
    },

    computed: {
        getAvailableTask() {
            return this.availableTask.filter((task) => {
                const hasChild = this.dependencies.some((dependency) => dependency.id === task.id);

                const userResourceId = (this.resourceType === 'custom') ? task.id : task.resource_id;
                const isCurrent = userResourceId === this.resourceId && task.resource === this.resourceType;

                return !isCurrent && !hasChild;
            }).map((task) => ({
                id: task.id,
                name: task.title,
            }));
        },

        hasDependent() {
            return this.dependents.length > 0;
        },
    },

    created() {
        if (this.companyUserProgramId > 0) {
            this.companyUserProgramTask.get().then(() => {
                this.availableTask = this.companyUserProgramTask.models;
                this.setCurrentDependent();
            });
        }
    },

    beforeDestroy() {
        this.onSelectDependent(null); // Reset dependents when destroyed
    },

    methods: {
        setCurrentDependent() {
            if (this.dependents.length === 0) {
                this.currentDependent = null;
                return;
            }

            this.currentDependent = this.getAvailableTask.find((task) => task.id === this.dependents[0].id);
        },

        onSelectDependent(dependent) {
            if (this.hasDependent) {
                this.dependents.splice(0, this.dependents.length);
            }

            if (dependent) {
                const newDependent = new CompanyUserProgramTask([
                    'id',
                ]);

                newDependent.id = dependent.id;

                this.dependents.push(newDependent);
            }

            this.setCurrentDependent();
        },
    },
};
</script>
