<template>
    <ListTasksByUser />
</template>

<script>
import ListTasksByUser from '../../listTasks/ByUser/ListTasksByUser.vue';

export default {
    name: 'MyActionsByUser',
    components: { ListTasksByUser },
};
</script>
