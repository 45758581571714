import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import Company from './Company';

export default class CompanyCollection extends ModelCollection {
    modelConfig() {
        return {
            model: Company,
        };
    }
}
