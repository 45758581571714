<template>
    <ListTasksByAction
        is-history
        :show-multi-select="false"
    />
</template>

<script>
import ListTasksByAction from '../listTasks/ByAction/ListTasksByAction.vue';

export default {
    name: 'HistoryByAction',
    components: { ListTasksByAction },
};
</script>
