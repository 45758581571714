<template>
    <HtModal ref="modal">
        <template #header>
            <t>Filters</t>
        </template>

        <HtFormFlatPickr
            id="datetimeStartfp"
            :label="translate('Target a promotion')"
            :value="selectedDates.start"
            :placeholder="translate('Starting date')"
            name="datetimeStartfp"
            :is-working-day="false"
            @input="updateStartDatesFilter"
        />

        <HtFormFlatPickr
            id="datetimeEndfp"
            :value="selectedDates.end"
            :placeholder="translate('Ending date')"
            name="datetimeEndfp"
            :min-date="selectedDates.start ? $Utils.moment(selectedDates.start).add(1, 'days').format('YYYY-MM-DD') : null"
            :is-working-day="false"
            @input="updateEndDatesFilter"
        />

        <HtFormSelector
            id="userStatus"
            :label="translate('Status')"
            :options="userStatus"
            :value="selectedUserStatus"
            track-by="slug"
            name="userStatus"
            @input="updateUserStatusFilter"
        />

        <div
            v-for="entity in entities"
            :key="entity.id"
        >
            <HtFormSelector
                :id="`entity-${entity.id}`"
                :show-optional="false"
                :label="entity.is_heyteam ? translate(entity.name) : entity.name"
                :value="selectedValuesByEntityId[entity.id]"
                :options="valuesByEntityId[entity.id]"
                :name="`entity-${entity.id}`"
                track-by="company_entity_value_id"
                @input="values => updateEntitiesFilter(entity, values)"
            />
        </div>

        <template #footer-actions>
            <Button
                class="negative"
                @click="deleteFilters"
            >
                <t>Delete filters</t>
            </Button>
            <Button @click="close">
                <t>See results</t>
            </Button>
        </template>
    </HtModal>
</template>

<script>
import groupBy from 'lodash.groupby';
import HtModal from '@/components/globals/HtModal.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import HtFormFlatPickr from '@/components/globals/HtFormFlatPickr.vue';

export default {
    name: 'TeamFiltersModal',
    components: {
        HtModal,
        HtFormSelector,
        HtFormFlatPickr,
    },
    computed: {
        selectedDates() {
            return this.$store.state.team.filters.dates;
        },
        entities() {
            return this.$store.state.entities.entities.filter((entity) => [this.officeEntity.id, this.departmentEntity.id].includes(entity.id) === false);
        },
        valuesByEntityId() {
            const valuesByEntityId = this.$store.getters['entities/valuesByEntityId'];

            for (const [key, values] of Object.entries(valuesByEntityId)) {
                valuesByEntityId[key] = values.sort((a, b) => a.name.localeCompare(b.name));
            }
            return valuesByEntityId;
        },
        selectedValuesByEntityId() {
            const entities = {};

            const valuesByEntityId = groupBy(this.$store.state.team.filters.entities, 'company_entity_id');

            this.entities.forEach((entity) => {
                entities[entity.id] = valuesByEntityId[entity.id] || [];
            });

            return entities;
        },
        officeEntity() {
            return this.$store.getters['entities/officeEntity'];
        },
        departmentEntity() {
            return this.$store.getters['entities/departmentEntity'];
        },
        userStatus() {
            return this.$store.getters['team/userStatus'];
        },
        selectedUserStatus() {
            return this.$store.state.team.filters.status;
        },
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        updateEntitiesFilter(entity, values = []) {
            this.$store.dispatch('team/setEntitiesFilter', { company_entity_id: entity.id, values });
        },
        updateStartDatesFilter(date) {
            if (this.$store.state.team.filters.dates.start === date) {
                return;
            }

            this.$store.dispatch('team/setDatesFilter', {
                start: date,
                end: this.$store.state.team.filters.dates.end,
            });
        },
        updateEndDatesFilter(date) {
            if (this.$store.state.team.filters.dates.end === date) {
                return;
            }

            this.$store.dispatch('team/setDatesFilter', {
                start: this.$store.state.team.filters.dates.start,
                end: date,
            });
        },
        updateUserStatusFilter(status) {
            this.$store.dispatch('team/setStatusFilter', status);
        },
        deleteFilters() {
            this.entities.forEach((entity) => this.updateEntitiesFilter(entity));
            this.updateStartDatesFilter(null);
            this.updateEndDatesFilter(null);
            this.close();
        },
    },
};
</script>

<style>
    .flatpickr-wrapper {
        display: block !important;
    }
</style>
