import CardImgLeftSection from './CardImgLeftSection';

export default {
    name: 'Double cards image left',
    image: 'double-card-img-left.png',
    fullPageBorder: true,
    content: `
        <section class="ht-section-double-card-img">
            <div class="card">
                <div class="card-image">
                    <img src="https://via.placeholder.com/150x250" alt="Placeholder Image" />
                </div>
                <div class="card-content">
                    <h2 class="title">Lorem ipsum dolor....</h2>
                    <p>... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias corporis deserunt ducimus labore non omnis quibusdam sapiente suscipit voluptate?.</p>
                    <a class="more-link" href="#">Voir plus &rarr;</a>
                </div>
            </div>

            <div class="card">
                <div class="card-image">
                    <img src="https://via.placeholder.com/150x250" alt="Placeholder Image" />
                </div>
                <div class="card-content">
                    <h2 class="title">Lorem ipsum dolor....</h2>
                    <p>... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias corporis deserunt ducimus labore non omnis quibusdam sapiente suscipit voluptate?.</p>
                    <a class="more-link" href="#">Voir plus &rarr;</a>
                </div>
            </div>
        </section>
    `,
    css: `
        ${CardImgLeftSection.css}

        .ht-section-double-card-img {
            display: flex;
            gap: 16px;
            padding: 40px 0;
        }

        .card-image {
            max-width: 200px;
            max-height: 250px;
            justify-content: start;
            align-items: start;
        }

        @media (max-width: 768px) {
            .ht-section-double-card-img {
                flex-direction: column;
            }

            .card {
                flex-direction: row;
            }

            .card-image {
                max-width: 200px;
            }

            .card-content {
                padding: 0 0 0 20px;
            }
        }

        @media (max-width: 376px) {
            .card {
                flex-direction: column;
            }

            .card-image {
                justify-content: center;
                align-items: center;
                max-width: 100%
            }

            .card-content {
                padding: 20px 0 0 0;
            }
        }
    `,
};
