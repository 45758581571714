<template>
    <Button
        :class="sending ? 'cursor-not-allowed' : 'cursor-pointer'"
        @click="resendInvite"
    >
        <t v-if="!sending">
            Resend invitation
        </t>
        <t v-else>
            Sending invitation
        </t>
    </Button>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';

export default {
    name: 'ResendInviteButton',
    props: { userId: { type: Number, required: true } },
    data() {
        return {
            sending: false,
        };
    },
    methods: {
        resendInvite() {
            if (this.sending) return;

            this.sending = true;

            const call = new CompanyUser(['id', 'email']).where([['id', '=', this.userId]]);
            call.setLookupMethod('resendInvite');
            call.get()
                .then(({ email }) => {
                    this.$Notifier('App').showInfo(this.translate('A new invitation has been sent to {email}', { email }));
                    this.$emit('onUpdate');
                })
                .catch(() => {
                    this.$Notifier('App').showError(this.translate('Unable to complete this operation!'));
                })
                .finally(() => {
                    this.sending = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	.cursor-not-allowed {
        cursor: not-allowed !important;
    }
    .cursor-pointer {
        cursor: pointer !important;
    }
</style>
