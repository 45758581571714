<template>
    <div>
        <CardList
            :options="{
                class:'no-justify'
            }"
        >
            <Card
                v-for="(item, index) in optionScale"
                :key="index"
                :data-cy="'survey-question-add-satisfaction-'+item.name"
                :options="{
                    type: 'small',
                    perRow: 2,
                }"
                @click="onSelectSatisfaction(item.name)"
            >
                <div slot="title">
                    <div class="wrapper-title">
                        <t>{{ item.label }}</t>
                    </div>
                </div>
            </Card>
        </CardList>
    </div>
</template>
<script>
import CompanySurveyContent from '@/models/CompanySurveyContent';
import { SurveyQuestionType, SurveyQuestionSubtype } from '@/models/common/SurveyQuestionType';

export default {
    name: 'QuestionAddSatisfaction',
    inject: ['modal'],

    data() {
        return {
            selectedScale: null,
            questionType: SurveyQuestionType,
            optionScale: SurveyQuestionSubtype,
            buttonState: 'idle',
        };
    },

    created() {
        this.modal.setTitle(this.translate('Satisfaction scale settings'));
    },

    methods: {
        onSelectSatisfaction(selectedScale) {
            this.$emit('onValidate', CompanySurveyContent.RESOURCE_QUESTION, this.questionType.QUESTION_TYPE_SATISFACTION_SCALE.name, selectedScale);
            this.modal.close();
        },

        onClose() {
            this.modal.close();
        },

        async onValidate() {
            if (!await this.$validator.validateAll()) return;

            this.$emit('onValidate', CompanySurveyContent.RESOURCE_QUESTION, this.questionType.QUESTION_TYPE_SATISFACTION_SCALE.name, this.selectedScale);
            this.modal.close();
        },
    },
};
</script>
