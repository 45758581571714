import debounce from 'lodash.debounce';
import CompanyUser from '@/models/CompanyUser';
import CompanyChatChannelCollection from '@/models/CompanyChatChannelCollection';
import api from '@/store/api';
import ChatListChannelItemHtml from '../ChatListChannelItemHtml.vue';
import ChatListChannelItem from '../ChatListChannelItem.vue';

export default {
    data() {
        return {
            search: '',
            searchModal: '',
            users: [],
        };
    },
    shared: {
        socket: {
            channelCollection: {
                type: CompanyChatChannelCollection,
                default: null,
            },
            connectedUsers: {
                type: Object,
                Object: {},
            },
        },
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },
    watch: {
        search: debounce(function () { this.fetchUsers(this.search); }, 250),
        searchModal: debounce(function () { this.fetchUsers(this.searchModal); }, 150),
    },
    methods: {
        clear() {
            this.search = '';
            this.searchModal = '';
        },
        async fetchUsers(search) {
            if (search.length > 2) {
                const response = await api.users.all({ filters: { query: search } });
                this.users = response.data.paginator.data;
            } else {
                this.users = [];
            }
        },
    },
    components: {
        ChatListChannelItemHtml,
        ChatListChannelItem,
    },
    unmounted() {
        this.$store.dispatch('team/clear');
    },
};
