import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUserNoteCollection from './CompanyUserNoteCollection';
import CompanyUser from './CompanyUser';
import CompanyFile from './CompanyFile';

export default class CompanyUserNote extends Model {
    modelConfig() {
        return {
            collection: CompanyUserNoteCollection,
        };
    }

    modelRelations() {
        return {
            companyUserOwner: () => this.belongsTo(CompanyUser, 'company_user_owner_id', 'id'),
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
        };
    }
}
