import Vue from 'vue';

export default {
    delete(userId, id, data = {}) {
        return Vue.prototype.$http.delete(`users/${userId}/requirement-groups/${id}`, data);
    },

    duplicate(userId, data = {}) {
        return Vue.prototype.$http.post(`users/${userId}/requirement-groups/duplicate`, data);
    },
};
