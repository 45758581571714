<template>
    <div
        :class="['collapse-wrapper clickable', {'open': value}]"
        @click="onCollapse"
    >
        <span v-show="value">{{ labelLess }}</span>
        <span v-show="!value">{{ labelMore }}</span>
    </div>
</template>

<script>
import I18n from '@/modules/i18n/I18n';

export default {
    name: 'HtLessMore',
    props: {
        value: {
            type: Boolean,
            default: false,
        },

        labelLess: {
            type: String,
            default: () => I18n.translate('See less'),
        },

        labelMore: {
            type: String,
            default: () => I18n.translate('See more'),
        },
    },

    methods: {
        onCollapse() {
            this.$emit('input', !this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.collapse-wrapper {
    display: flex;
    align-items: center;
    background: $white;

    > span {
        color: $primary;
        font-size: 14px;
        font-weight: bold;
    }

    &::before {
        content: '›';
        flex: 0 0 auto;
        position: relative;
        top: 0px;
        display: block;
        margin-right: 7px;
        transform: rotate(90deg);
        transform-origin: 50% 50%;
        color: $primary;
    }

    &.open {
        &::before {
            transform: rotate(270deg) translate3d(0, -4px, 0);
        }
    }
}

</style>
