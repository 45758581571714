<template>
    <div>
        <slot name="default" />
    </div>
</template>

<script>
import stepperFormEventBus from '@/eventBus/stepperFormEventBus';

export default {
    name: 'PageStepperLayout',
    props: {
        pageSubtitles: {
            type: Array,
            default: () => [],
        },
    },

    created() {
        stepperFormEventBus.$on('fetched-item', this.setFormPage);
    },
    methods: {
        /**
         * @param {string} title page title
         * @returns {void}
         */
        setFormPage(title) {
            this.setPage({
                title,
                subtitles: this.pageSubtitles,
            });
            stepperFormEventBus.$off('fetched-item', this.setFormPage);
        },
    },
};
</script>
