import Env from '@/Env';
import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import router from '@/bootstrap/declare-router';

let environment;
let dsn;

try {
    environment = Env.get('APP_ENV');
    dsn = Env.getFromEnv('SENTRY_DSN');
} catch (exception) {
    environment = null;
    dsn = null;
}

if (environment === 'qa') {
    Sentry.init({
        Vue,
        dsn,
        environment,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: [Env.getFromEnv('COMPANY_API_URL'), /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
