<template>
    <div class="row">
        <div class="col-md-3">
            <HtFormInput
                id="search_query"
                name="search_query"
                :value="queryValue"
                :placeholder="translate('User')"
                @input="updateQueryFilter"
            />
        </div>

        <div class="col-md-3">
            <HtFormSelector
                id="offices"
                :value="selectedValuesByEntityId[officeEntity.id]"
                :options="valuesByEntityId[officeEntity.id]"
                name="offices"
                track-by="company_entity_value_id"
                :placeholder="translate('Sites')"
                :disabled="hasFilterOffice"
                :selection-label="translate('{count} site | {count} sites',
                                            {count: selectedValuesByEntityId[officeEntity.id].length})"
                @input="values => updateEntitiesFilter(officeEntity, values)"
            />
        </div>

        <div class="col-md-3">
            <HtFormSelector
                id="departments"
                :value="selectedValuesByEntityId[departmentEntity.id]"
                :options="valuesByEntityId[departmentEntity.id]"
                name="departments"
                track-by="company_entity_value_id"
                :placeholder="translate('Departments')"
                :disabled="hasFilterDepartment"
                :selection-label="translate('{count} department | {count} departments',
                                            {count: selectedValuesByEntityId[departmentEntity.id].length})"
                @input="values => updateEntitiesFilter(departmentEntity, values)"
            />
        </div>

        <div
            class="col-md-3"
            @click="$refs.modal.open()"
        >
            <TeamFiltersModal ref="modal" />

            <div class="button">
                <div class="button__description">
                    {{ otherFiltersLabel }}
                </div>
                <div class="button__icon">
                    <FontAwesomeIcon :icon="['fal', 'plus']" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import groupBy from 'lodash.groupby';
import debounce from 'lodash.debounce';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import TeamFiltersModal from './TeamFiltersModal.vue';

export default {
    name: 'TeamFilters',
    components: {
        TeamFiltersModal,
        HtFormInput,
        HtFormSelector,
    },

    computed: {
        officeEntity() {
            return this.$store.getters['entities/officeEntity'];
        },
        departmentEntity() {
            return this.$store.getters['entities/departmentEntity'];
        },
        entities() {
            return this.$store.state.entities.entities.filter((entity) => [this.officeEntity.id, this.departmentEntity.id].includes(entity.id));
        },
        hasFilterOffice() {
            return this.$store.state.team.hasFilterOffice;
        },
        hasFilterDepartment() {
            return this.$store.state.team.hasFilterDepartment;
        },
        queryValue() {
            return this.$store.state.team.filters.query;
        },
        valuesByEntityId() {
            const valuesByEntityId = this.$store.getters['entities/valuesByEntityId'];

            for (const [key, values] of Object.entries(valuesByEntityId)) {
                valuesByEntityId[key] = values.sort((a, b) => a.name.localeCompare(b.name));
            }

            return valuesByEntityId;
        },
        selectedValuesByEntityId() {
            const entities = {};

            const valuesByEntityId = groupBy(this.$store.state.team.filters.entities, 'company_entity_id');

            this.entities.forEach((entity) => {
                entities[entity.id] = valuesByEntityId[entity.id] || [];
            });

            return entities;
        },
        otherFiltersCount() {
            const values = this.$store.state.team.filters.entities.filter((value) => this.entities.map((e) => e.id).includes(value.company_entity_id) === false);

            return values.length;
        },
        otherFiltersLabel() {
            return this.otherFiltersCount === 0
                ? this.translate('More filters')
                : this.translate('{count} filter | {count} filters', { count: this.otherFiltersCount });
        },
    },

    methods: {
        updateEntitiesFilter(entity, values) {
            this.$store.dispatch('team/setEntitiesFilter', { company_entity_id: entity.id, values });
        },
        updateQueryFilter: debounce(function (query) {
            this.$store.dispatch('team/setQueryFilter', query);
        }, 200),
    },
};
</script>

<style lang="scss" scoped>
    .button {
        background-color: #ffffff;
        height: 48px;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 0 15px;
        margin-bottom: 3rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;

        > .button__description {
            color: #979797;
        }

        > .button__icon {
            color: #35495e;
            font-size: 12px;
        }
    }
</style>
