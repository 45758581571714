<template>
    <ResourceListing
        ref="listing"
        api-name="documents"
        :title="translate('Documents')"
        :columns="columns"
        :formatter="formatter"
        permission="ModelCompanySharedDocument"
        empty-message="There are no documents to display yet..."
        has-quick-actions
        @on-action-clicked="onActionClicked"
    >
        <template #modalable="{ selectedResourceId }">
            <SharedDocumentEdit
                :id="selectedResourceId"
                ref="documentEdit"
                :disabled-program-list="false"
            />
        </template>
    </ResourceListing>
</template>

<script>
import api from '@/store/api';
import ResourceListing from '@/components/resources/generic/ResourceListing.vue';
import SharedDocumentEdit from '@/components/resources/sharedDocument/SharedDocumentEdit.vue';

export default {
    name: 'Documents',

    permissions: [
        'ModelCompanySharedDocument',
    ],

    components: {
        ResourceListing,
        SharedDocumentEdit,
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('Type'),
                    key: 'company_file.detected_extension',
                    class: 'text-uppercase',
                    sortable: false,
                    search: false,
                },
            ],
        };
    },

    methods: {
        fetchData() {
            this.$refs.listing.fetchData();
        },

        formatter(items) {
            return items.map((item) => {
                const quickActions = [];
                if (this.$canCreate('ModelCompanySharedDocument', { id: -1 })) {
                    quickActions.push({ id: 'replicate', label: 'Replicate', icon: ['fal', 'copy'] });
                }

                return {
                    ...item,
                    quick_actions: quickActions,
                };
            });
        },

        replicateDocument(document) {
            api.configuration.documents.replicate(document.id)
                .then(() => {
                    this.$Notifier('App').showInfo(this.translate('The resource << {resource_name} >> is replicated', {
                        resource_name: document.resource.name,
                    }));
                    this.fetchData();
                })
                .catch(() => {
                    this.$Notifier('App').showError(this.translate('The resource << {resource_name} >> is not replicated!', {
                        resource_name: document.resource.name,
                    }));
                });
        },

        onActionClicked({ item, actionId: action }) {
            switch (action) {
            case 'replicate':
                this.replicateDocument(item);
                break;
            }
        },
    },
};
</script>
