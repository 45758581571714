<template>
    <CentralConfirmation
        :title="translate('Two-Factor Authentication (2FA)')"
        description=""
        :cancel-label="translate('Close')"
        @cancel="$emit('close')"
    >
        <p>
            {{ companyName }} <t>
                has recently activated double authentication for all its employees and invites you to enter your number to complete the process, in accordance with
            </t> <a
                href="https://www.cnil.fr/fr/securite-utilisez-lauthentification-multifacteur-pour-vos-comptes-en-ligne"
                target="_blank"
            >
                <t>CNIL recommendations.</t>
            </a>.
        </p>

        <br>

        <div>
            <form
                class="form-2"
                @submit.prevent=""
            >
                <PhoneNumberFreeInput
                    :phone-country-code.sync="phoneCountryCode"
                    :phone-number.sync="phoneNumber"
                    :is-required="true"
                    :error-append="getPhoneNumberError"
                />

                <p class="mt-2">
                    <t>(A code will now be sent to you via this number for your future connections).</t>
                </p>

                <div class="row-line">
                    <HtButton
                        :loading="loading"
                        :disabled="loading"
                        @click.native="onUpdatePhoneNumber"
                    >
                        <t>Save</t>
                    </HtButton>
                </div>
            </form>
        </div>
    </CentralConfirmation>
</template>
<script>
import CentralConfirmation from '@/components/globals/modals/layouts/CentralConfirmation.vue';
import PhoneNumberFreeInput from '@/components/globals/inputs/PhoneNumberFreeInput.vue';
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'TwoFactorAuthInformationModal',
    components: {
        CentralConfirmation,
        PhoneNumberFreeInput,
        HtButton,
    },
    inject: ['$validator'],
    props: {
        companyName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            phoneCountryCode: '',
            phoneNumber: '',
            apiErrors: {},
            loading: false,
        };
    },
    computed: {

        getPhoneNumberError() {
            if (this.apiErrors.phone_country_code) {
                return this.apiErrors.phone_country_code[0] ?? null;
            }
            return this.apiErrors.phone_number?.[0] ?? null;
        },
    },
    methods: {
        onUpdatePhoneNumber() {
            if (this.loading === true) {
                return;
            }
            this.apiErrors = {};

            this.$validator.validateAll().then((result) => {
                if (!result) {
                    return;
                }

                this.loading = true;

                this.$http.put('me/phone-number', {
                    phone_number: this.phoneNumber,
                    phone_country_code: this.phoneCountryCode,
                }).then(() => {
                    this.$Notifier('App').showInfo(this.translate('Phone number successfully updated!'));
                    this.$emit('close');
                }).catch(({ response }) => {
                    if (response.status === 422) {
                        this.apiErrors = response.data.errors;
                    }
                }).finally(() => {
                    this.loading = false;
                });
            });
        },
    },
};
</script>
