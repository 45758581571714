<template>
    <div
        class="full"
        style="position:relative; width:80px; height:80px"
    >
        <template v-if="type=='empty'">
            <slot>
                <transition name="fade">
                    <div class="user-block-item">
                        <svg
                            :data-cy="cypress"
                            class="icon icon-add"
                            @click="$emit('onAdd')"
                        ><use xlink:href="#icon-add" />
                        </svg>
                    </div>
                </transition>
            </slot>
        </template>

        <template v-else>
            <transition name="fade">
                <div
                    v-if="!value"
                    class="user-block-item"
                >
                    <svg
                        :data-cy="cypress"
                        class="icon icon-add"
                        @click="$emit('onAdd')"
                    ><use xlink:href="#icon-add" />
                    </svg>
                </div>
            </transition>

            <transition name="fade">
                <div
                    v-if="value"
                    class="user-block-item user"
                >
                    <div
                        v-user-image="$Utils.getCompanyComponentRoleInitials(
                            roleAlias,
                            1.25,
                            user
                        )"
                        class="role-only"
                        :class="roleName"
                    />
                </div>
            </transition>

            <transition name="fade">
                <div
                    v-if="value"
                    class="trash"
                    :data-cy="cypress"
                    @click="onRemove"
                >
                    <svg class="icon icon-trash"><use xlink:href="#icon-trash" /></svg>
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
export default {

    props: {
        value: { default: null, required: false },
        type: {
            type: String,
            default: 'empty',
            validator: (value) => ['empty', 'role', 'user', 'group'].includes(value),
        },
        cypress: {
            type: String,
            default() {
                return '';
            },
        },
    },

    data() {
        return {
            roleAlias: 'user',
            roleName: '',
            user: null,
        };
    },

    watch: {
        value() {
            this.refresh();
        },
    },

    created() {
        this.refresh();
    },

    methods: {
        onRemove() {
            this.$emit('onRemove');
        },

        refresh() {
            this.user = null;
            this.roleAlias = null;
            if (!this.value) return;

            if (this.type === 'role' || this.type === 'group') {
                this.roleAlias = this.value.alias;
                this.roleName = this.value.name;
            } else {
                this.roleAlias = 'user';
                this.user = this.value;
            }
        },
    },
};
</script>
<style scoped lang="scss">

	.full{
			width: 100%;
		height: 100%;
	}
	.user-block-item{
		width:100%;
		height: 100%;

		&.user{

			margin: 0 0 8px;

			clip-path: url(#itemSmallMask);
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: contain;
			backface-visibility: hidden;
			transform: translate3d(0, 0, 0);

			div{
				 width: 100%;
				height: 100%;
			}
		}

		.user {
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: contain;
		}

		svg{
			width:100%;
			height: 100%;
			fill: #9C9C9C;
		}
	}

	.trash {
	width: 35px;
	height: 35px;
	position: absolute;
	top: -15px;
	right: -15px;
	background: #fff;
	box-sizing: border-box;
	padding: 3px;
	border: 1px solid #ebebeb;
	border-radius: 50%;
	text-align: center;
	padding: 8px;

	svg{
		width: 15px !important;
		height: 15px !important;
	}
}
</style>
