import Model from '@tony.caron/node-model-proxy/Model';
import CompanyChatbot from '@/models/CompanyChatbot';
import CompanyFile from './CompanyFile';
import CompanyFlowCollection from './CompanyFlowCollection';

export default class CompanyFlow extends Model {
    static PAGES = {
        full: {
            welcome: 1,
            sign: 2,
            company: 3,
            team: 4,
            profile: 5,
            requirement: 6,
        },
        light: {
            welcome: 1,
            sign: 2,
            profile: 3,
            requirement: 4,
            company: null,
            team: null,
        },
        sign_only: {
            sign: 1,
            welcome: null,
            company: null,
            team: null,
            profile: null,
            requirement: null,
        },
    };

    modelConfig() {
        return {
            collection: CompanyFlowCollection,
        };
    }

    modelRelations() {
        return {
            chatbot: () => this.belongsTo(CompanyChatbot, 'company_chatbot_id', 'id'),
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
        };
    }

    modelInConvertor() {
        return {
            is_enabled: (n) => Boolean(n),
            is_enabled_editabled: (n) => Boolean(n),
        };
    }
}
