<template>
    <div>
        <HtModal
            ref="modal"
            @onClose="$emit('on-close')"
        >
            <template #header>
                {{ document.original_name }}
            </template>
            <template #default>
                <ParametersBox>
                    <HtFormSelect
                        :id="'documentState'"
                        v-model="document.state"
                        :placeholder="translate('All states...')"
                        :options="stateOptions"
                        :name="'documentState'"
                        :label="translate('State')"
                        :show-optional="false"
                        class="mb-10"
                    />
                </ParametersBox>
            </template>
            <template #footer-actions>
                <div
                    class="actions d-flex w-100"
                >
                    <div>
                        <Button
                            v-if="canRemove"
                            :state="buttonState"
                            class="negative mr-2"
                            @click="onRemoveConfirm()"
                        >
                            <t>Delete</t>
                        </Button>
                        <Button
                            v-if="canRemove"
                            class="black"
                            @click="download()"
                        >
                            <t>Download</t>
                        </Button>
                    </div>
                    <Button
                        :state="buttonState"
                        @click="save()"
                    >
                        <t>Save</t>
                    </Button>
                </div>
            </template>
        </HtModal>

        <modalable
            v-if="canRemove"
            ref="modalableConfirmDeletion"
            class="modal modalable-4 program"
        >
            <p>
                <t>
                    Do you really want to permanently delete this document?
                </t>
            </p>

            <div class="row-line middle">
                <Button
                    class="negative"
                    cypress="cancel-delete"
                    @click="$refs.modalableConfirmDeletion.close()"
                >
                    <t>Cancel</t>
                </Button>
                <Button
                    class="red"
                    :state="buttonState"
                    cypress="confirm-delete"
                    @click="remove()"
                >
                    <t>Delete</t>
                </Button>
            </div>
        </modalable>
    </div>
</template>

<script>
import ParametersBox from '@/components/globals/ParametersBox.vue';
import HtModal from '@/components/globals/HtModal.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import Modalable from '@/components/globals/Modalable.vue';

export default {
    name: 'EditKnowledgeDocumentFormModal',
    components: {
        Modalable,
        HtFormSelect,
        HtModal,
        ParametersBox,
    },

    data() {
        return {
            document: null,
            isLoading: false,
            stateOptions: [
                {
                    id: 'in_review',
                    name: this.translate('In Review'),
                },
                {
                    id: 'published',
                    name: this.translate('Published'),
                },
            ],
        };
    },

    computed: {
        canRemove() {
            return Number.isInteger(this.document?.id);
        },
        buttonState() {
            return this.isLoading ? 'loading' : 'idle';
        },
    },

    methods: {
        async open(id = null) {
            await this.getDocument(id);
        },
        async getDocument(id = null) {
            if (id) {
                this.$http.get(`company/knowledge/document/${id}`).then((data) => {
                    this.document = data.data.data;

                    this.$refs.modal.open();
                }).catch(({ response }) => {
                    if (response?.status !== 409) {
                        this.$Notifier('App').showError(this.translate('An error occured'));
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            } else {
                this.$refs.modal.open();
            }
        },
        async save() {
            const ok = await this.$validator.validateAll();

            if (ok) {
                this.isLoading = true;

                this.$http.post('company/knowledge/document', {
                    id: this.document.id,
                    company_file_id: this.document.company_file_id,
                    state: this.document.state,
                }).then(() => {
                    this.document = null;

                    this.$Notifier('App').showInfo(this.translate('The document has been modified with success!'));

                    this.isLoading = false;

                    this.$refs.modal.close();
                }).catch(({ response }) => {
                    if (response?.status !== 409) {
                        this.$Notifier('App').showError(this.translate('An error occured'));
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        download() {
            this.$http.post(`company/knowledge/document/${this.document.id}/download`).then((response) => {
                const link = document.createElement('a');

                link.href = response.data.link;
                link.setAttribute('download', this.document.original_name);
                document.body.appendChild(link);
                link.click();
            }).catch(({ response }) => {
                if (response?.status !== 409) {
                    this.$Notifier('App').showError(this.translate('An error occured'));
                }
            });
        },
        onRemoveConfirm() {
            this.$refs.modalableConfirmDeletion.open();
        },
        remove() {
            this.isLoading = true;

            this.$http.delete(`company/knowledge/document/${this.document.id}`).then(() => {
                this.$refs.modalableConfirmDeletion.close();

                this.document = null;

                this.$Notifier('App').showInfo(this.translate('The selected document has been deleted with success!'));

                this.isLoading = false;

                this.$refs.modal.close();
            }).catch(({ response }) => {
                if (response?.status !== 409) {
                    this.$Notifier('App').showError(this.translate('An error occured'));
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
    },
};
</script>
<style scoped>
.actions {
    justify-content: space-between;
    padding: 20px 25px 0 25px;
}
</style>
