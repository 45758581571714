<template>
    <div class="">
        <div class="row">
            <div class="col-md">
                <HtFormFlatPickr
                    :id="'date'"
                    :name="'date'"
                    :placeholder="translate('Filter by date')"
                    :max-date="'today'"
                    mode="range"
                    :is-working-day="false"
                    @change="updateDates"
                />
            </div>
        </div>
        <div>
            <HTable
                :columns="columns()"
                :data="data"
                :loading="loading"
                @on-row-click="onRowClick"
                @on-sort="onSort"
            >
                <template #cell(status)="item">
                    <IntegrationLogIcon
                        v-if="!item.row.child"
                        :icon="item.value"
                    />
                    <div v-else />
                </template>
                <template #cell(name)="item">
                    <div v-if="!item.row.child">
                        {{ item.value }}
                    </div>
                    <IntegrationReport
                        v-else-if="item.row.child && item.row.report != null"
                        :report="item.row.report"
                    />
                </template>
                <template #cell(fullStatus)="item">
                    <TinyIntegrationReport
                        v-if="!item.row.child && item.row.report != null"
                        :report="item.row.report"
                    />
                </template>
                <template #cell(date)="item">
                    <div
                        class="table-string"
                        :title="item.row.dateRaw"
                    >
                        {{ item.value }}
                    </div>
                </template>
                <template #cell(actions)="item">
                    <DownloadIntegrationReport
                        v-if="!item.row.child && item.row.excel != null"
                        :file-url="item.row.excel"
                    /><div v-else />
                </template>
            </HTable>
        </div>
        <div>
            <HtPagination
                v-model="page"
                :last-page="lastPage"
                @input="changePage"
            />
        </div>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import { HTable } from '@/components/globals/table';
import HtFormFlatPickr from '@/components/globals/HtFormFlatPickr.vue';
import IntegrationLogIcon from '@/pages/logs/IntegrationLogIcon.vue';
import IntegrationReport from '@/pages/logs/IntegrationReport.vue';
import DSIconDownload from '@/components/design-system/icons/System/DSIconDownload.vue';
import DownloadIntegrationReport from '@/pages/logs/DownloadIntegrationReport.vue';
import TinyIntegrationReport from '@/pages/logs/TinyIntegrationReport.vue';

export default {
    name: 'IntegrationLogs',
    components: {
        TinyIntegrationReport,
        HTable,
        HtFormFlatPickr,
        IntegrationLogIcon,
        IntegrationReport,
        DSIconDownload,
        DownloadIntegrationReport,
    },
    data() {
        return {
            logs: [],
            page: 1,
            lastPage: 2,
            loading: true,
            toggle: null,
            filters: {
                dates: null,
            },
        };
    },
    computed: {
        data() {
            const logs = [];

            this.logs.forEach((log) => {
                // Add the log line itself as data
                logs.push({
                    id: log.id,
                    child: false,
                    status: log.status,
                    name: log.name,
                    report: log.report,
                    excel: log.excel,
                    date: this.$Utils.moment(log.date).fromNow(),
                    dateRaw: this.$Utils.moment(log.date).format('L'),
                    _trStyle: this.parentRowStyle,
                });
                if (this.expanded(log.id)) {
                    logs.push({
                        id: log.id,
                        child: true,
                        report: log.report,
                        status: null,
                        name: null,
                        date: null,
                        dateRaw: null,
                        excel: null,
                        _trStyle: this.childRowStyle,
                    });
                }
            });

            return logs;
        },
    },
    created() {
        this.getPageData();
    },
    methods: {
        getPageData: debounce(function (page) {
            this.$http.post(`configuration/logs/integrations${page ? `?page=${page}` : ''}`, this.filters).then(({ data }) => {
                this.setPage(data);
                this.loading = false;
            });
        }, 500),
        updateDates(dates) {
            if (dates.length === 2 || dates.length === 0) {
                this.filters.dates = dates.length === 2 ? [dates[0], this.$Utils.moment(dates[1]).add(1, 'day')] : null;
                this.loading = true;
                this.getPageData(this.page);
            }
        },
        setPage(logs) {
            this.logs = logs.data;
            this.page = logs.meta.current_page;
            this.lastPage = logs.meta.last_page;
        },
        changePage(page) {
            this.getPageData(page);
        },
        columns() {
            return [
                {
                    key: 'status',
                    thStyle: {
                        width: '50px',
                    },
                    tdStyle: {
                        textAlign: 'center',
                    },
                    showSkeleton: false,
                },
                {
                    key: 'name',
                    label: this.translate('Name'),
                },
                {
                    key: 'fullStatus',
                    label: this.translate('Status'),
                },
                {
                    key: 'date',
                    label: this.translate('Date'),
                    sortable: true,
                    thStyle: {
                        width: '130px',
                    },
                },
                {
                    key: 'actions',
                    thStyle: {
                        width: '50px',
                    },
                },
            ];
        },
        expanded(rowId) {
            return this.toggle == rowId;
        },
        toggleButton(row) {
            if (row.child) return '';
            return this.toggle == row.id ? 'down' : 'up';
        },
        onRowClick(row) {
            if (this.toggle == row.id) {
                this.toggle = null;
            } else {
                this.toggle = row.id;
            }
        },
        parentRowStyle() {
            return {
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '20px',
            };
        },
        childRowStyle() {
            return {
                fontSize: '12px',
                lineHeight: '16px',
            };
        },
        onSort() {
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
