<template>
    <HtReadResources
        v-if="action"
        :resource-type="resourceType"
        :resource="action"
        :label-category="'Action'"
        :label-recipient="'Assign to'"
        :has-sender="false"
    />
</template>

<script>

import HtReadResources from '@/components/globals/modals/HtReadResources/HtReadResources.vue';

export default {
    name: 'ActionRead',
    components: { HtReadResources },
    props: {
        resourceType: {
            type: String,
            required: true,
        },
        resourceId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            action: null,
        };
    },

    async created() {
        const request = await this.$http.get(`/configuration/actions/${this.resourceId}`);
        this.action = request.data.data.resource;
    },
};
</script>
