<template>
    <div>
        <HtFormSwitch
            :id="'switch-retro'"
            :name="'switch-retro'"
            :value="isRetroactive"
            :label="translate('Activate retroactivity')"
            :show-optional="false"
            class="ht-form-counter-margin"
            @input="$emit('update:is-retroactive', $event)"
        />

        <div class="mt-4">
            <p class="mb-3 text-justify">
                <t>This resource will be added or updated on a collaborator's program, even if it has already started.</t>
            </p>
            <p class="mb-3 text-justify">
                <t>Retroactivity will not apply to the Scheduling and Participants fields.</t>
            </p>
        </div>
        <div v-if="isRetroactive">
            <div class="d-flex justify-content-between mb-5 align-items-center">
                <p>
                    <t>This resource will impact</t>
                </p>
                <Skeleton
                    :loading="loading"
                    width="100px"
                    height="30px"
                >
                    <HtTag :color="'purple'">
                        <t :count="affectedPrograms">
                            {count} program | {count} programs
                        </t>
                    </HtTag>
                </Skeleton>
            </div>
        </div>
        <HtFormSwitch
            :id="'switch-notif'"
            :name="'switch-notif'"
            :label="translate('Inform users')"
            :show-optional="false"
            :value="sendNotif"
            :disabled="notifDisabled"
            class="ht-form-counter-margin"
            @input="$emit('update:send-notif', $event)"
        />
        <p class="mt-4 text-justify">
            <t v-if="notifDisabled">
                You must enable the email in the Settings in order to activate this option.
            </t>
            <t v-else>
                HeyTeam will send an email to impacted employees.
            </t>
        </p>
    </div>
</template>

<script>
import I18n from '@/modules/i18n/I18n';
import CompanyEmailTemplate from '@/models/CompanyEmailTemplate';
import { Skeleton } from 'vue-loading-skeleton';
import HtFormSwitch from './HtFormSwitch.vue';
import HtTag from './HtTag.vue';

export default {
    name: 'RetroactivityToggle',
    components: { HtFormSwitch, HtTag, Skeleton },

    props: {
        isRetroactive: {
            type: Boolean,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
        sendNotif: {
            type: Boolean,
            required: true,
        },
        /**
         * Nombre de user programs affectés par la rétroactivité
         */
        affectedPrograms: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            label: I18n.translate('Activate retroactivity'),
            notifDisabled: true,
        };
    },

    watch: {
        isRetroactive(newValue) {
            if (newValue === true) {
                this.$emit('update:send-notif', true);
            }
            if (newValue === false) {
                this.$emit('update:send-notif', false);
            }
        },
    },

    created() {
        new CompanyEmailTemplate([
            'id',
            'is_enabled',
        ]).where([
            ['template_slug', '=', 'resourceUpdatedRetroactively'],
        ]).get().then((email) => {
            this.notifDisabled = !email.is_enabled;
        });
    },
};
</script>
<style lang="scss" scoped>
p {
    font-size: 1.4rem;
}

/** need to remove it when handle ht-form margin-bottom attribute */
.ht-form-counter-margin {
    margin-bottom: 0;
}
</style>
