import UnauthorizedError from '@/services/Errors/UnauthorizedError';

export default class AIChatService {
    constructor(env, token, payload) {
        this.env = env;
        this.token = token;
        this.payload = payload;
        this.order = 0;
    }

    async ask(messagePayload, callback) {
        const url = this.env.getCapiUrl('ask');

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify(messagePayload),
        };

        const response = await fetch(url, options);

        if ([401, 403].includes(response.status)) {
            throw new UnauthorizedError();
        }

        return this.readStream(response.body, callback);
    }

    // eslint-disable-next-line class-methods-use-this
    async readStream(stream, callback) {
        const reader = stream.getReader();

        // eslint-disable-next-line no-constant-condition
        while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { done, value } = await reader.read();
            if (done) break;

            const decodedText = new TextDecoder().decode(value, { stream: true });

            callback(decodedText, this.payload);
        }
    }
}
