import Vue from 'vue';

/**
 * @typedef {Object} JobLog
 * @property {number} id
 * @property {string} script_title
 * @property {string} script_description
 * @property {string} created_at
 * @property {string} updated_at
 * @property {Array<{type: string, message: string, created_at: string, context: Array<string, any>}>} messages
 * @property {User|null} user
 */

/**
 * @typedef {{data: {data: JobLog}}} JobLogResourceResponse
 * @typedef {{data: {data: JobLog[]}}} JobLogCollectionResponse
 */

export default {
    /**
   * @param {number} page
   * @param {{user: string, dates: [string, string]|null}} filters
   * @returns {Promise<JobLogCollectionResponse>}
   */
    getLogs(page, filters) {
        return Vue.prototype.$http.post(`configuration/logs/jobs/query${page ? `?page=${page}` : ''}`, filters);
    },

    /**
    *
    * @param {number} jobLogId
    * @returns {{}}
    */
    rerun(jobLogId) {
        return Vue.prototype.$http.post(`configuration/logs/jobs/${jobLogId}/rerun`);
    },

};
