<template>
    <div
        class="ht-deployable-search-field"
        @click="open"
    >
        <FontAwesomeIcon
            class="search-icon"
            :class="{open: toggled, closed: !toggled}"
            :icon="['far', 'search']"
        />
        <div class="animation-wrapper">
            <!-- Used to prevent the label from overflowing when animating -->
            <transition name="slide-right">
                <span
                    v-if="!toggled"
                    class="label"
                ><!-- The span is necessary in case the slot only contains text, which doesn't work with transition -->
                    <slot />
                </span>
            </transition>
        </div>
        <div class="animation-wrapper input-wrapper">
            <!-- Used to prevent the input from overflowing when animating -->
            <transition name="slide-right">
                <input
                    v-if="toggled"
                    ref="input"
                    v-model="search"
                    type="text"
                    :placeholder="placeholder"
                    @blur="blur"
                    @input="event => $emit('input', event.target.value)"
                >
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HtDeployableSearchField',
    props: {
        value: { type: String },
        placeholder: { type: String },
    },
    data() {
        return {
            toggled: false,
            search: '',
        };
    },
    methods: {
        open() {
            if (!this.toggled) {
                this.toggled = true;
                this.$nextTick(() => this.$refs.input.focus());
            }
        },
        blur() {
            if (!this.search) {
                this.toggled = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "~@/styles/var";

    .ht-deployable-search-field {
        display: flex;
        position: relative;
    }

    .input-wrapper {
        left: -0.9rem;
        top: -1.2rem;
    }

    .search-icon {
        transition: all 0.2s ease;
    }

    .search-icon.closed {
        color: #D8D8D8;
    }

    .search-icon.open {
        color: $grey-ultra-dark;
    }

    input {
        font-family: $lato;
        background: transparent;
        font-size: 1.4rem;
        padding: 1.0rem 2.4rem 1.0rem 3.2rem;
        border-radius: 0.5rem;
        border: 1px solid $grey-ultra-light;
        color: $grey-text;
        outline: none;
        width: 100%;
    }

    .label {
        // Since the label is in position absolute for the animation to work
        // We offset it so that it doesn't overlap with the search icon
        margin-left: 1.8rem;
    }

    /* animation */
    .animation-wrapper {
        display: flex;
        overflow: hidden;
        position: absolute;
    }

    .slide-right-enter-active, .slide-right-leave-active  {
        transition: all 0.2s ease-out;
    }

    .slide-right-enter, .slide-right-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }
</style>
