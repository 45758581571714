/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

/* eslint-disable camelcase */
export default {
    methods: {
        getToolbar() {
            return [
                'undo redo',
                'restoredraft bold italic underline strikethrough',
                'fontfamily fontsize blocks',
                'link',
                'emoticons',
                'code',
                'preview',
                'fullscreen save print',
                'numlist bullist',
                'h1 h2',
                'image',
                'styleselect',
                'forecolor backcolor',
                'alignleft aligncenter alignright alignjustify',
                'removeformat',
                'help',
            ];
        },
        getPlugins() {
            return [
                // NEW
                'directionality visualchars pagebreak nonbreaking insertdatetime advlist help quickbars',

                // OLD
                'autolink link image charmap emoticons lists',
                'searchreplace visualblocks fullscreen preview',
                'anchor media',
                'paste code wordcount table autoresize',
            ];
        },
        onPaste(node) {
            // By default, no processing is applied when pasting
            return node;
        },
        stripHtmlTags(str, allowed_tags) {
            let key = ''; let
                allowed = false;
            let matches = []; let allowed_array = [];
            let allowed_tag = '';
            let i = 0;
            let k = '';
            let html = '';
            const replacer = function (search, replace, str) {
                return str.split(search).join(replace);
            };
            // Build allowes tags associative array
            if (allowed_tags) {
                allowed_array = allowed_tags.match(/([a-zA-Z0-9]+)/gi);
            }
            str += '';

            // Match tags
            matches = str.match(/(<\/?[\S][^>]*>)/gi);
            // Go through all HTML tags
            for (key in matches) {
                if (isNaN(key)) {
                    // IE7 Hack
                    continue;
                }

                // Save HTML tag
                html = matches[key].toString();
                // Is tag not in allowed list? Remove from str!
                allowed = false;

                // Go through all allowed tags
                // eslint-disable-next-line guard-for-in
                for (k in allowed_array) { // Init
                    allowed_tag = allowed_array[k];
                    i = -1;

                    if (i != 0) { i = html.toLowerCase().indexOf(`<${allowed_tag}>`); }
                    if (i != 0) { i = html.toLowerCase().indexOf(`<${allowed_tag} `); }
                    if (i != 0) { i = html.toLowerCase().indexOf(`</${allowed_tag}`); }

                    // Determine
                    if (i == 0) {
                        allowed = true;
                        break;
                    }
                }
                if (!allowed) {
                    str = replacer(html, '', str); // Custom replace. No regexing
                }
            }
            return str;
        },
        setupWebComponent(component, win, doc, editor) {},

        imagesUploadHandler(blobInfo, success, failure) {
            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());

            this.$http.post('company_file/wysiwyg', formData).then(({ data }) => {
                success(data.link);
            }).catch(({ response }) => {
                failure(response.data.message, { remove: true });
            });
        },
    },

    computed: {
        getCustomCss() {
            const disabledStyle = this.disabled
                ? 'body { background-color: #F7F7F7; color: #929292; cursor: default; }'
                : '';

            return `${disabledStyle} ${this.customStyle || ''}`;
        },
        getMarginCss() {
            return '';
        },
        contentStyle() {
            // The following css will be injected into the editor, extending
            // the default styles.
            // In a real world scenario, with much more custom styles for headings
            // links, tables, images etc, it's recommended to use the content_css
            // option to load a separate CSS file. Makes editing easier too.
            // https://www.tiny.cloud/docs/configure/content-appearance/#content_style
            // https://www.tiny.cloud/docs/configure/content-appearance/#content_css
            return `${this.getCustomCss}
                ${this.getMarginCss}
                .mce-content-body conditional-block[contenteditable="true"] {
                    cursor: text;
                }

                /* We remove the default selected outline because it doesn't follow the
                 * border radius and reintroduce it as a box-shadow.
                 */
                .mce-content-body conditional-block[contenteditable=false][data-mce-selected] {
                    outline: none;
                    cursor: default;
                    box-shadow: 0 0 0 3px #b4d7ff;
                }

                .mce-content-body *[contentEditable=false] *[contentEditable=true]:focus {
                    outline: none;
                }

                .mce-content-body *[contentEditable=false] *[contentEditable=true]:hover {
                    cursor: pointer;
                }

                conditional-block {
                    margin: 1rem -6px;
                }
            `;
        },
        setup() {},

        init() {
            // Hauteur de l'éditeur
            // eslint-disable-next-line camelcase
            const max_height = this.height;
            // eslint-disable-next-line camelcase
            const min_height = 100;

            // Plugins
            const plugins = this.hasToolbar === false
                ? ['paste autoresize']
                : this.getPlugins();

            // Barre de menu
            const menubar = this.hasToolbar === false
                ? false
                : 'file edit view insert format tools table help';

            // Barre de statut
            const statusbar = false;

            // Outils par défaut
            const toolbarElement = this.getToolbar();
            if (this.hasConditionalBlock) {
                toolbarElement.splice(1, 0, 'conditionalblock');
            }

            if (this.customStyleEnabled) {
                toolbarElement.splice(1, 0, 'customstyle');
            }

            const fullToolbar = toolbarElement.join('|');
            const toolbar = this.hasToolbar === false ? false : fullToolbar;

            // Handler pour l'upload d'image
            const images_upload_handler = this.imagesUploadHandler;

            // Désactivation du champ "Titre" dans la pop-up des liens
            const link_title = false;

            // Valeur par défaut pour l'ouverture d'un lien
            const default_link_target = '_blank';

            const cleanAttributes = (node, attributes) => {
                node.childNodes.forEach((_node) => {
                    if (typeof _node.removeAttribute === 'function') {
                        attributes.forEach((attribute) => {
                            if (_node.hasAttribute(attribute)) {
                                _node.removeAttribute(attribute);
                            }
                        });
                    }
                    cleanAttributes(_node, attributes);
                });
            };

            const custom_elements = 'conditional-block';

            const { setup } = this;

            const content_style = this.contentStyle;

            // Options
            return {
                min_height,
                max_height,
                menubar,
                plugins,
                images_upload_handler,
                toolbar,
                statusbar,
                language: this.shared.session.companyUser.company_language.codeTinymce,
                link_title,
                default_link_target,
                extended_valid_elements: 'style[type|media|scoped]',
                valid_children: '+body[style]',
                content_style,
                custom_elements,
                setup,
                toolbar_sticky: true,
                toolbar_sticky_offset: 0,
                link_assume_external_targets: 'https',
                pagebreak_separator: '<div style="page-break-before: always; clear:both"/></div>',
                paste_preprocess: (pl, node) => this.onPaste(node),
                paste_postprocess: (plugin, args) => {
                    const attributes = [
                        'data-sheets-value',
                        'data-sheets-userformat',
                        'data-sheets-textstyleruns',
                        'data-sheets-hyperlinkruns',
                    ];

                    cleanAttributes(args.node, attributes);

                    // dirty method to strip html tags for TinyMCE v3 (instead, with TinyMCE v4, use property paste_as_plain_text)
                    args.node.innerHTML = this.stripHtmlTags(args.node.innerHTML, '<b><u><i><p>');
                },
            };
        },
    },
};
