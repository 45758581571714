import Vue from 'vue';

/**
 * @typedef {Object} Nudge
 * @property {number} id
 * @property {number} company_nudge_initiator_role_id
 * @property {number} company_nudge_initiator_user_id
 * @property {string} type
 * @property {number} time_to_complete
 * @property {string} message
 * @property {{message: Object<string, string>}} translations
 * @property {Resource} resource
 */

/**
 * @typedef {{data: {data: Nudge}}} NudgeResourceResponse
 * @typedef {{data: {data: Nudge[]}}} NudgeCollectionResponse
 */

export default {
    /**
     * @param {any} data
     * @returns {Promise<NudgeCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.post('configuration/nudges', data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<NudgeResourceResponse>}
     */
    get(id, config = {}) {
        return Vue.prototype.$http.get(`configuration/nudges/${id}`, config);
    },

    /**
     * @param {any} data
     * @returns {Promise<NudgeResourceResponse>}
     */
    create(data = {}) {
        return Vue.prototype.$http.post('configuration/nudges/new', data);
    },

    /**
     * @param {number|string} id
     * @param {any} data
     * @returns {Promise<NudgeResourceResponse>}
     */
    update(id, data = {}) {
        return Vue.prototype.$http.post(`configuration/nudges/${id}`, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<{}>}
     */
    delete(id, config = {}) {
        return Vue.prototype.$http.delete(`configuration/nudges/${id}`, config);
    },

    /**
     * @param {number|string} id
     * @param {any} config
     * @returns {Promise<NudgeResourceResponse>}
     */
    replicate(id, config = {}) {
        return Vue.prototype.$http.post(`configuration/nudges/${id}/replicate`, config);
    },
};
