<template>
    <div>
        <HtFormInput
            :id="requirement.name"
            v-model="companyUserRequirement.value"
            :name="requirement.name"
            :cypress="requirement.name"
            :is-required="requirement.is_mandatory"
            type="text"
            :disabled="!isParticipant || isDisabled"
            @input="onChanged"
        />
    </div>
</template>

<script>
import UserRequirementEditMixin from './UserRequirementEditMixin';

export default {
    name: 'UserRequirementStringEdit',

    mixins: [UserRequirementEditMixin],

    inject: ['$validator'],

    props: {
        isParticipant: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onChanged() {
            if (this.companyUserRequirement.value !== this.requirement.value) {
                const checkChanged = this.companyUserRequirement.value !== null && this.companyUserRequirement.value.trim() !== '';
                this.updateRequirementWithDebounce(checkChanged);
            }
        },
    },
};
</script>
