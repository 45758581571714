import Vue from 'vue';
import Model from '@tony.caron/node-model-proxy/Model';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import CompanyFile from './CompanyFile';
import CompanyProgramResourceFilter from './CompanyProgramResourceFilter';
import CompanyResource from './CompanyResource';

export default class CompanyTraining extends Model {
    static RESOURCEABLE_CLASS = 'App\\CompanyDocument';

    modelConfig() {
        return {
            controller: 'CompanyDocumentController',
        };
    }

    modelDefaultValues() {
        return {
            type: 'file',
            order: 0,
        };
    }

    modelCustomAttributes() {
        return {
            locales_by_key: {},
            name_translated: null,
            is_retroactive: false,
            send_retroactive_notif: false,
        };
    }

    modelEvents() {
        return {
            loaded: () => {
                Vue.prototype.$modelUtils.createLocales(this.company_training_locale);
            },
        };
    }

    modelAccessors() {
        return {
            locales_by_key: () => Vue.prototype.$modelUtils.createLocalesByKey(this.company_training_locale),
            name_translated: () => this.localize('name'),
        };
    }

    localize(field, languageKey, defaultLanguageKey) {
        return Vue.prototype.$modelUtils.localize(this.locales_by_key, field, languageKey, defaultLanguageKey);
    }

    modelRelations() {
        return {
            resource: () => this.belongsTo(CompanyResource, 'resourceable_id', 'id'),
            dependencies: () => this.belongsToMany(CompanyResourceProgram, 'company_resource_program_dependencies', 'company_resource_program_id', 'dependency_id'),
            companyFiles: () => this.hasMany(CompanyFile),
            companyProgramResourceFilter: () => this.hasMany(CompanyProgramResourceFilter, 'resource_id', 'id').where(['component_name', '=', 'company_document']),
        };
    }
}
