<template>
    <HtFormGroup>
        <div
            v-for="(entity, index) in entities"
            :key="index"
        >
            <HtFormMultiSelect
                :id="'entity'+entity.slug"
                :value="valuesByEntityId[entity.id].data"
                :options="optionsByEntityId[entity.id].data"
                :placeholder="translate(entity.name)"
                :name="'entity'+entity.slug"
                :label="translate(entity.name)"
                :show-optional="false"
                :class="classes(index)"
                @onSelect="onChange"
                @onRemove="onRemove"
            />
        </div>
    </HtFormGroup>
</template>

<script>
import keyBy from 'lodash.keyby';
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';

export default {
    name: 'HtFormFilters',
    components: {
        HtFormMultiSelect,
    },

    props: {
        value: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            entities: [],
        };
    },

    computed: {
        filters() {
            return this.value;
        },

        optionsByEntityId() {
            const entities = this.entities.map((entity) => {
                const data = entity.values
                    .map((r) => ({
                        id: r.company_entity_value_id,
                        name: r.is_heyteam ? this.translate(r.name) : r.name,
                        company_entity_value_id: r.company_entity_value_id,
                        company_entity_id: entity.id,
                    }));

                return {
                    id: entity.id,
                    data,
                };
            });

            return keyBy(entities, 'id');
        },
    },

    created() {
        this.loadEntities();
    },

    methods: {
        classes(index) {
            return index === this.entities.length - 1 ? 'mb-0' : null;
        },

        loadEntities() {
            this.$http('configuration/entities').then(({ data }) => {
                this.entities = data.data;

                this.init();
            });
        },

        init() {
            const entities = this.entities.map((entity) => {
                const data = this.filters
                    .filter((f) => f.company_entity_id === entity.id)
                    .map((f) => this.optionsByEntityId[f.company_entity_id].data.find((d) => d.company_entity_value_id = f.company_entity_value_id));

                return {
                    id: entity.id,
                    data,
                };
            });

            this.valuesByEntityId = keyBy(entities, 'id');
        },

        onChange(option) {
            this.filters.push({
                company_entity_id: option.company_entity_id,
                company_entity_value_id: option.company_entity_value_id,
            });
        },

        onRemove(option) {
            const index = this.filters.findIndex((filter) => filter.company_entity_value_id === option.company_entity_value_id);

            this.filters.splice(index, 1);
        },
    },
};
</script>
