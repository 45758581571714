export const ListQuestionType = {
    QUESTION_TYPE_MULTIPLE_CHOICE: 'multiple_choice',
    QUESTION_TYPE_SATISFACTION_SCALE: 'satisfaction_scale',
    QUESTION_TYPE_SIMPLE_CHOICE: 'simple_choice',
    QUESTION_TYPE_YES_NO: 'yes_no',
    QUESTION_TYPE_TEXT: 'text',
    QUESTION_TYPE_FILE_UPLOAD: 'file_upload',
    QUESTION_TYPE_REQUIREMENT: 'requirement',
};

export const SurveyQuestionType = {
    QUESTION_TYPE_SATISFACTION_SCALE: {
        name: 'satisfaction_scale',
        label: 'Satisfaction scale',
        icon: '/static/icons/survey/type/Smiley.svg',
    },
    QUESTION_TYPE_SIMPLE_CHOICE: {
        name: 'simple_choice',
        label: 'Simple choice',
        icon: '/static/icons/survey/type/Check.svg',
    },
    QUESTION_TYPE_MULTIPLE_CHOICE: {
        name: 'multiple_choice',
        label: 'Multiple choice',
        icon: '/static/icons/survey/type/Choice.svg',
    },
    QUESTION_TYPE_YES_NO: {
        name: 'yes_no',
        label: 'Yes / No',
        icon: '/static/icons/survey/type/YesNo.svg',
    },
    QUESTION_TYPE_TEXT: {
        name: 'text',
        label: 'Text',
        icon: '/static/icons/survey/type/Text.svg',
    },
    QUESTION_TYPE_FILE_UPLOAD: {
        name: 'file_upload',
        label: 'File upload',
        icon: '/static/icons/survey/type/FileUpload.svg',
    },
    QUESTION_TYPE_REQUIREMENT: {
        name: 'requirement',
        label: 'Requirement',
        icon: '/static/icons/survey/type/FileUpload.svg',
    },
};

export const ListQuestionSubtype = {
    QUESTION_SUBTYPE_SMILEY: 'satisfaction_scale_smiley',
    QUESTION_SUBTYPE_NPS: 'satisfaction_scale_nps',
};

export const SurveyQuestionSubtype = [
    {
        name: 'satisfaction_scale_smiley',
        label: 'Smiley',
    },
    {
        name: 'satisfaction_scale_nps',
        label: 'NPS',
    },
];
