import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyChatChannelMessage from './CompanyChatChannelMessage';

export default class CompanyChatChannelMessageCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyChatChannelMessage,
        };
    }
}
