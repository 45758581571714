<template>
    <div class="page-content">
        <h1 class="small">
            {{ getTranslatedMessage(companyFlowCopy['bloc_profile_title']['content']['messages']['message_1']) }}
        </h1>

        <!-- AVATAR -->
        <FlowRow
            v-if="messages['bloc_profile_a'] === true"
            :company-user="bubbleUser"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_profile_a']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_profile_a'] === true && messages['bloc_profile_b'] === true"
            ref="profile_picture"
            :company-user="companyUser"
            :show-buttons="showUserAvatar"
            :reversed="true"
            :width="25"
            @onValidate="onValidePicture"
            @onCancel="increaseStep(1)"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div class="photo-edit">
                    <div class="logo-zone">
                        <Croppable
                            v-show="showCroppable"
                            ref="croppable_profile"
                            style="display: flex;justify-content: center;"
                            :show-width="125"
                            :show-height="125"
                            :dest-width="250"
                            :dest-height="250"
                            :resize-multiple="[0.1]"
                            save-url="company_file"
                            :image="$Utils.resolveS3PublicImage(companyUser.image)"
                            @onChange="onProfileChange"
                            @onUploadEnd="onProfileSave"
                        />
                        <img
                            v-show="!showCroppable"
                            :src="$Utils.resolveS3PublicImage(companyUser.image)"
                            alt=""
                        >
                    </div>
                    <Button
                        class="small"
                        @click=" $refs.croppable_profile.onOpenFileSelector(); showCroppable=true;"
                    >
                        <t>Upload</t>
                    </Button>
                </div>
                <div
                    v-show="avatarChange"
                    class="button-wrapper align-center"
                />
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_profile_c'] === true"
            :company-user="bubbleUser"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_profile_c']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_profile_c'] === true && messages['bloc_profile_d'] === true"
            ref="profile_about"
            :company-user="companyUser"
            :show-buttons="showUserAbout"
            :reversed="true"
            :full="true"
            @onValidate="onValideAbout"
            @onCancel="increaseStep(3)"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div class="form-2">
                    <HtFormTextarea
                        :id="'description'"
                        v-model="companyUser.about"
                        :placeholder="translate('Write here your description...')"
                        :maxlength="255"
                        :name="'description'"
                        class="full-width no-margin"
                    />
                </div>
            </template>
        </FlowRow>

        <!-- HOBBIES -->
        <FlowRow
            v-if="messages['bloc_profile_e'] === true"
            :company-user="bubbleUser"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_profile_e']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_profile_e'] === true && messages['bloc_profile_f'] === true"
            :ref="'userHobbies'"
            :company-user="companyUser"
            :show-buttons="showUserHobbies"
            :reversed="true"
            :full="true"
            @onValidate="onValideHobby"
            @onCancel="increaseStep(5)"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div class="meta-input form-2">
                    <input
                        v-model="newHobby"
                        :placeholder="translate('Write here your answers, separated by comma...')"
                        class="full-width"
                        type="text"
                        @keyup.enter="onValideHobby"
                    >

                    <div class="meta-list">
                        <div
                            v-for="item in getCurrentHobby"
                            :key="item.id"
                            class="meta-item"
                        >
                            <span>{{ item.value }}</span>
                            <div
                                class="meta-delete"
                                @click="deleteHobby(item)"
                            >
                                <svg class="icon icon-cross"><use xlink:href="#icon-cross" /></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </FlowRow>

        <!-- SKILLS -->
        <FlowRow
            v-if="messages['bloc_profile_g'] === true"
            :company-user="bubbleUser"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_profile_g']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_profile_g'] === true && messages['bloc_profile_h'] === true"
            :ref="'userSkills'"
            :company-user="companyUser"
            :show-buttons="showUserSkills"
            :reversed="true"
            :full="true"
            @onValidate="onValideSkill"
            @onCancel="increaseStep(7)"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div class="meta-input form-2">
                    <input
                        v-model="newSkill"
                        :placeholder="translate('Write here your answers, separated by comma...')"
                        class="full-width"
                        type="text"
                        @keyup.enter="onValideSkill"
                    >

                    <div class="meta-list">
                        <div
                            v-for="item in getCurrentSkill"
                            :key="item.id"
                            class="meta-item"
                        >
                            <span>{{ item.value }}</span>
                            <div
                                class="meta-delete"
                                @click="deleteSkill(item)"
                            >
                                <svg class="icon icon-cross"><use xlink:href="#icon-cross" /></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_profile_i'] === true"
            :company-user="bubbleUser"
            @onLoad="$emit('onLoad', true)"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_profile_i']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyUserMetadata from '@/models/CompanyUserMetadata';
import Croppable from '@/components/tools/Croppable.vue';
import FlowRow from '@/components/flow/FlowRow.vue';
import FlowMixin from '@/components/mixins/FlowMixin.vue';

export default {
    name: 'FlowProfile',
    components: {
        FlowRow,
        Croppable,
    },
    mixins: [FlowMixin],

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: {},
            },
        },
    },

    data() {
        return {
            showCroppable: false,
            avatarChange: false,
            newHobby: '',
            newSkill: '',
            showUserAvatar: true,
            showUserAbout: true,
            showUserHobbies: true,
            showUserSkills: true,

            companyFlowCopy: {},
            messages: {},
            messagesEnabled: [],
            rowLabel: [],
            rowLabelInteractive: ['bloc_profile_h', 'bloc_profile_f', 'bloc_profile_d', 'bloc_profile_b'],
            iMessageIndex: 0,
            globalTimeout: null,
        };
    },

    computed: {
        getCurrentHobby() {
            return this.companyUser.company_user_metadata.models.filter((meta) => meta.type === CompanyUserMetadata.TYPE_HOBBY);
        },

        getCurrentSkill() {
            return this.companyUser.company_user_metadata.models.filter((meta) => meta.type === CompanyUserMetadata.TYPE_SKILL);
        },
    },

    async created() {
        this.companyUser.fromSaveState();
        this.contents.forEach((model) => {
            this.companyFlowCopy[model.subtype] = model;

            if (!model.subtype.includes('_title')) {
                this.rowLabel.push(model.subtype);
                this.$set(this.messages, model.subtype, false);
                if (model.is_enabled) {
                    this.messagesEnabled.push(model.subtype);
                }
            }
        });

        if (!this.messagesEnabled.length) {
            if (this.is_forward === true) this.$emit('onNextPage');
            else this.$emit('onPreviousPage');
        }

        this.loadRows();
        this.runLoadRows();
    },

    methods: {
        runLoadRows() {
            this.globalTimeout = setInterval(() => {
                this.loadRows();
            }, 1500);
        },

        loadRows() {
            while (this.iMessageIndex < this.rowLabel.length && this.companyFlowCopy[this.rowLabel[this.iMessageIndex]].is_enabled === false) {
                this.iMessageIndex++;
            }

            if (this.messagesEnabled.indexOf(this.rowLabel[this.iMessageIndex]) === -1) {
                this.$emit('onLoadPage', true);
                clearInterval(this.globalTimeout);
            } else {
                if (this.companyFlowCopy[this.rowLabel[this.iMessageIndex]].is_enabled) {
                    this.messages[this.rowLabel[this.iMessageIndex]] = true;
                }

                if (!this.rowLabelInteractive.includes(this.rowLabel[this.iMessageIndex])) {
                    this.iMessageIndex++;
                }
            }
        },

        onValidePicture() {
            this.showUserAvatar = false;

            if (this.avatarChange === true) this.$refs.croppable_profile.onSave();

            this.increaseStep(1);
        },

        async onValideAbout() {
            this.showUserAbout = false;

            if (!this.companyUser.isSaving()) {
                this.onUpdate().then(() => {
                    this.increaseStep(3);
                });
            }
        },

        onValideHobby() {
            this.showUserHobbies = false;

            if (this.companyUser.isSaving()) return;

            this.addMeta(this.newHobby, 'hobby');
            this.companyUser.save().then(() => {
                this.newHobby = '';
                this.increaseStep(5);
            });
        },

        onValideSkill() {
            this.showUserSkills = false;

            if (this.companyUser.isSaving()) return;

            this.addMeta(this.newSkill, 'skill');
            this.companyUser.save().then(() => {
                this.newSkill = '';
                this.increaseStep(7);
            });
        },

        onValidePhoneNumber() {
            if (!this.companyUser.isSaving()) {
                this.onUpdate().then(() => {
                    this.increaseStep(9);
                });
            }
        },

        increaseStep(index) {
            if (index !== this.iMessageIndex) return;

            this.iMessageIndex++;
            clearInterval(this.globalTimeout);
            this.loadRows();
            this.runLoadRows();
        },

        deleteHobby(hobby) {
            const indexCollection = this.companyUser.company_user_metadata.models.findIndex((metadata) => metadata.id === hobby.id);
            this.companyUser.company_user_metadata.models.splice(indexCollection, 1);
        },

        deleteSkill(skill) {
            const indexCollection = this.companyUser.company_user_metadata.models.findIndex((metadata) => metadata.id === skill.id);
            this.companyUser.company_user_metadata.models.splice(indexCollection, 1);
        },

        onUpdate() {
            return new Promise((resolve, reject) => {
                this.companyUser.save().then(() => {
                    this.shared.session.companyUser.fill(this.companyUser);
                    resolve();
                }).catch(() => {
                    reject();
                });
            });
        },

        addMeta(metadata, key) {
            const tags = metadata.split(',');

            if (tags.length > 0) {
                for (const item of tags) {
                    const companyUserMetaData = this.companyUser.company_user_metadata.new();
                    companyUserMetaData.type = key;
                    companyUserMetaData.value = item.trim();
                    companyUserMetaData.company_user_id = this.companyUser.id;
                    if (companyUserMetaData.value && companyUserMetaData.value.length > 0) {
                        this.companyUser.company_user_metadata.add(companyUserMetaData);
                    }
                }
            }
        },

        // IMAGE LOGIC
        onProfileChange() {
            this.avatarChange = true;
            this.showUserAvatar = true;
        },

        onProfileSave(uploadInfo, data) {
            this.companyUser.company_file = data;
            this.avatarChange = false;
            this.onUpdate();
        },
    },
};
</script>
