import { fetchData as dashboardFetchData, storeData } from '@/store/modules/dashboard.store';
import moment from 'moment';

const SET_DEADLINES = 'set_deadlines';
const SET_NOT_JOINED = 'set_not_joined';
const SET_NEXT_KEY_DATES = 'set_next_key_dates';
const SET_MOST_ACTIVE_MEMBERS = 'set_most_active_members';
const SET_LATE_PROGRAMS_COUNT = 'set_late_programs_count';
const SET_ACTIVE_PROGRAMS_COUNT = 'set_active_programs_count';
const SET_COMPLETION_RATE_PERCENTAGE = 'set_completion_rate_percentage';
const SET_TODO_ACTIONS_COUNT = 'set_todo_actions_count';

const SET_PROGRAMS_FILTER = 'set_programs_filter';
const SET_ENTITIES_FILTER = 'set_entities_filter';

const INVALIDATE_DATA = 'invalidate_data';
const SET_LAST_UPDATE = 'set_last_update';

const CLEAR_ALL_FILTERS = 'clear_all_filters';

function fetchData(context, params) {
    dashboardFetchData(context, params)?.then?.(() => {
        context.commit(SET_LAST_UPDATE);
    });
}

export default {
    namespaced: true,

    state() {
        return {
            filters: {
                programs: [],
                entities: [],
            },

            completionRatePercentage: 0,
            completionRatePercentageLoaded: null,

            activeProgramsCount: 0,
            activeProgramsCountLoaded: null,

            lateProgramsCount: 0,
            lateProgramsCountLoaded: null,

            deadlines: [],
            deadlinesLoaded: null,

            mostActiveMembers: [],
            mostActiveMembersLoaded: null,

            notJoined: [],
            notJoinedLoaded: null,

            nextKeyDates: [],
            nextKeyDatesLoaded: null,

            todoCount: 0,
            todoCountLoaded: null,

            lastUpdate: moment(),
        };
    },

    getters: {
        completionRatePercentage(state) {
            return state.completionRatePercentage;
        },
        activeProgramsCount(state) {
            return state.activeProgramsCount;
        },
        lateProgramsCount(state) {
            return state.lateProgramsCount;
        },
        todoCount(state) {
            return state.todoCount;
        },
        loaded(state) {
            return [
                state.completionRatePercentageLoaded,
                state.activeProgramsCountLoaded,
                state.lateProgramsCountLoaded,
                state.deadlinesLoaded,
                state.mostActiveMembersLoaded,
                state.notJoinedLoaded,
                state.nextKeyDatesLoaded,
            ].reduce((previous, current) => previous && (current !== false), true);
        },
        getFilters(state) {
            return state.filters;
        },
    },

    actions: {
        fetchCompletionRatePercentage(context) {
            fetchData(context, {
                loadedKey: 'completionRatePercentageLoaded',
                apiMethod: 'completionRatePercentage',
                responseKey: 'data.data.completion_rate_percentage',
                mutation: SET_COMPLETION_RATE_PERCENTAGE,
            });
        },
        fetchActiveProgramsCount(context) {
            fetchData(context, {
                loadedKey: 'activeProgramsCountLoaded',
                apiMethod: 'activeProgramsCount',
                responseKey: 'data.data.active_programs_count',
                mutation: SET_ACTIVE_PROGRAMS_COUNT,
            });
        },
        fetchLateProgramsCount(context) {
            fetchData(context, {
                loadedKey: 'lateProgramsCountLoaded',
                apiMethod: 'lateProgramsCount',
                responseKey: 'data.data.late_programs_count',
                mutation: SET_LATE_PROGRAMS_COUNT,
            });
        },
        fetchDeadlines(context) {
            fetchData(context, {
                loadedKey: 'deadlinesLoaded',
                apiMethod: 'getDeadlines',
                responseKey: 'data.data',
                mutation: SET_DEADLINES,
            });
        },
        fetchMostActiveMembers(context) {
            fetchData(context, {
                loadedKey: 'mostActiveMembersLoaded',
                apiMethod: 'getMostActiveMembers',
                responseKey: 'data.data',
                mutation: SET_MOST_ACTIVE_MEMBERS,
            });
        },
        fetchNotJoined(context) {
            fetchData(context, {
                loadedKey: 'notJoinedLoaded',
                apiMethod: 'getNotJoined',
                responseKey: 'data.data',
                mutation: SET_NOT_JOINED,
            });
        },
        fetchNextKeyDates(context) {
            fetchData(context, {
                loadedKey: 'nextKeyDatesLoaded',
                apiMethod: 'getNextKeyDates',
                responseKey: 'data.data',
                mutation: SET_NEXT_KEY_DATES,
            });
        },
        fetchTodoCount(context) {
            fetchData(context, {
                loadedKey: 'todoCountLoaded',
                apiMethod: 'getTodoCount',
                responseKey: 'data.data',
                mutation: SET_TODO_ACTIONS_COUNT,
            });
        },
        refresh({ commit }) {
            commit(INVALIDATE_DATA);
        },
        setProgramsFilter({ commit }, ids) {
            commit(SET_PROGRAMS_FILTER, ids);
            commit(INVALIDATE_DATA);
        },
        setEntitiesFilter({ commit, state }, { company_entity_id, values }) {
            const entities = state.filters.entities.filter((e) => e.company_entity_id !== company_entity_id);
            commit(SET_ENTITIES_FILTER, [...entities, ...values]);
            commit(INVALIDATE_DATA);
        },
        updateTodoCount({ state, commit }, amount) {
            const newCount = state.todoCount + amount;
            commit(SET_TODO_ACTIONS_COUNT, newCount);
        },
        clearAllFilters({ commit }) {
            commit(CLEAR_ALL_FILTERS);
        },
    },

    mutations: {
        [SET_COMPLETION_RATE_PERCENTAGE](state, completionRatePercentage) {
            storeData(state, 'completionRatePercentageLoaded', 'completionRatePercentage', completionRatePercentage);
        },
        [SET_ACTIVE_PROGRAMS_COUNT](state, activeProgramsCount) {
            storeData(state, 'activeProgramsCountLoaded', 'activeProgramsCount', activeProgramsCount);
        },
        [SET_LATE_PROGRAMS_COUNT](state, lateProgramsCount) {
            storeData(state, 'lateProgramsCountLoaded', 'lateProgramsCount', lateProgramsCount);
        },
        [SET_TODO_ACTIONS_COUNT](state, todoCount) {
            state.todoCount = todoCount;
            storeData(state, 'todoCountLoaded', 'todoCount', todoCount.todo_count);
        },
        [SET_DEADLINES](state, deadlines) {
            storeData(state, 'deadlinesLoaded', 'deadlines', deadlines);
        },
        [SET_MOST_ACTIVE_MEMBERS](state, mostActiveMembers) {
            storeData(state, 'mostActiveMembersLoaded', 'mostActiveMembers', mostActiveMembers);
        },
        [SET_NOT_JOINED](state, notJoined) {
            storeData(state, 'notJoinedLoaded', 'notJoined', notJoined);
        },
        [SET_NEXT_KEY_DATES](state, nextKeyDates) {
            storeData(state, 'nextKeyDatesLoaded', 'nextKeyDates', nextKeyDates);
        },
        [INVALIDATE_DATA](state) {
            if (state.completionRatePercentageLoaded) {
                state.completionRatePercentageLoaded = false;
            }
            if (state.activeProgramsCountLoaded) {
                state.activeProgramsCountLoaded = false;
            }
            if (state.lateProgramsCountLoaded) {
                state.lateProgramsCountLoaded = false;
            }
            if (state.deadlinesLoaded) {
                state.deadlinesLoaded = false;
            }
            if (state.mostActiveMembersLoaded) {
                state.mostActiveMembersLoaded = false;
            }
            if (state.notJoinedLoaded) {
                state.notJoinedLoaded = false;
            }
            if (state.nextKeyDatesLoaded) {
                state.nextKeyDatesLoaded = false;
            }
        },
        [SET_PROGRAMS_FILTER](state, ids) {
            state.filters.programs = ids;
        },
        [SET_ENTITIES_FILTER](state, entities) {
            state.filters.entities = entities;
        },
        [SET_LAST_UPDATE](state) {
            state.lastUpdate = moment();
        },
        [CLEAR_ALL_FILTERS](state) {
            state.filters.programs = [];
            state.filters.entities = [];
        },
    },
};
