<template>
    <div class="layout-modal">
        <header>
            <slot name="header"></slot>

            <HtIconClose v-if="canClose" @click="$emit('close')" />
        </header>

        <main>
            <slot name="main"></slot>
        </main>

        <div
            v-if="'information' in $slots"
            class="information"
        >
            <slot name="information" />
        </div>

        <footer>
            <slot name="footer"></slot>
        </footer>
    </div>
</template>

<script>
import HtIconClose from '@/components/icons/HtIconClose.vue';

export default {
    name: 'HtLayoutModal',

    inject: ['$validator'],

    components: {
        HtIconClose,
    },

    props: {
        canClose: {
            type: Boolean,
            default: () => true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.layout-modal {
    width: 600px;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        border-bottom: 1px solid $neutral-300;

        .title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            color: $neutral-black;
        }
    }

    main {
        max-height: 450px;
        overflow: auto;
        margin: 0;
        background-color: $neutral-100;
        padding: 24px 24px 300px;
    }

    footer, .information {
        display: flex;
        justify-content: space-between;
        padding: 16px 24px;
        border-top: 1px solid $neutral-300;
    }
}
</style>
