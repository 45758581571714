<template>
    <Button
        v-if="integration.is_enabled"
        class="red"
        @click="$emit('on-cancel')"
    >
        <t>Disable</t>
    </Button>
    <Button
        v-else
        @click="$emit('on-validate')"
    >
        <t>Enable</t>
    </Button>
</template>

<script>
export default {
    name: 'IntegrationFooterEdit',
    props: {
        integration: {
            type: Object,
            required: true,
        },
    },
};
</script>
