<template>
    <div
        id="login-page"
    >
        <div id="login-content">
            <transition name="fade">
                <div v-show="step == 'default'">
                    <div class="login-component">
                        <img
                            v-if="mail_sent"
                            src="../assets/images/mailbox.svg"
                        >
                        <h1
                            v-if="!mail_sent"
                            class="align-center login-width"
                        >
                            <t>Password reset</t>
                        </h1>
                        <h2
                            v-if="mail_sent"
                            class="align-center login-width pt-5 pb-5"
                        >
                            <t>Email sent</t>
                        </h2>
                        <div
                            v-if="!mail_sent"
                            class="login-width"
                        >
                            <p>
                                <t v-if="hasToken && !token_valid">
                                    The reset link does not exist or is no longer
                                    available.
                                </t>
                                <t>Enter the email address you use to login to your account.</t>
                            </p>
                            <form
                                class="form"
                                @submit.prevent
                            >
                                <fieldset>
                                    <input
                                        id="login-email"
                                        v-model.trim="user.email"
                                        v-validate="'required|email'"
                                        data-vv-scope="email"
                                        type="text"
                                        name="email"
                                        data-cy="login-email"
                                        :placeholder="translate('Email')"
                                    >
                                    <div
                                        v-for="(errorMessage, index) in errors.collect('email')"
                                        :key="index"
                                        class="error-message"
                                    >
                                        {{ errorMessage }}
                                    </div>
                                    <span class="login-input-info">
                                        <t>Password reset can only be done after your first connection</t>
                                    </span>
                                </fieldset>
                                <HtButton
                                    type="primary"
                                    class="button-login"
                                    :loading="loading"
                                    :disabled="disableButton"
                                    @keyup.13="onLogin"
                                    @click.native="onLogin"
                                >
                                    <t>Get reset Link</t>
                                </HtButton>
                            </form>
                        </div>
                        <div
                            v-if="mail_sent"
                            class="login-width"
                        >
                            <p>
                                <t :email="user.email">
                                    You asked to reset your password. Check your {email} inbox
                                    for instructions from
                                    us on how to reset your password.
                                </t>
                            </p>
                        </div>
                    </div>
                </div>
            </transition>

            <!-- STEP RESET -->
            <div v-if="!password_updated && step == 'reset' && company">
                <div class="login-component">
                    <h1 class="align-center login-width">
                        <t>Password reset</t>
                    </h1>
                    <div
                        v-if="token_valid"
                        class="content-over-bg"
                    >
                        <form
                            class="form login-width"
                            @submit.prevent
                        >
                            <input
                                id="create-password"
                                ref="password"
                                v-model="user.password"
                                v-validate="{ required: true, regex: new RegExp(company.company_security.password_regex) }"
                                name="password"
                                type="password"
                                :placeholder="translate('Type your password')"
                                :data-vv-as="translate('new password')"
                            >

                            <div class="error-message">
                                {{ errors.first('password') }}
                            </div>

                            <span style="font-size:1rem">
                                <t>Password must be at least</t>&nbsp;
                                <t :count="company.company_security.password_length">{count} character long | {count} characters long</t>,&nbsp;
                                <t>include</t>&nbsp;
                                <t :count="company.company_security.password_numbers">{count} digit | {count} digits</t>&nbsp;
                                <t>and</t>&nbsp;
                                <t :count="company.company_security.password_symbols">{count} special character | {count} special characters</t>
                            </span>

                            <input
                                id="create-confirm-password"
                                v-model="confirm_password"
                                v-validate="'required|confirmed:password'"
                                name="password_confirmation"
                                type="password"
                                :placeholder="translate('Type your confirmation password')"
                                :data-vv-as="translate('confirm password')"
                            >

                            <div class="error-message">
                                {{ errors.first('password_confirmation') }}
                            </div>

                            <HtButton
                                class="button-login mt-5"
                                :loading="loading"
                                :disabled="disableButton"
                                @click.native="onReset"
                            >
                                <t>Change my password</t>
                            </HtButton>
                        </form>
                    </div>
                </div>
            </div>
            <div v-if="password_updated && step == 'reset' && company">
                <div class="login-component">
                    <img
                        src="../assets/images/happy.svg"
                        alt=""
                    >
                    <h2 class="align-center pt-3">
                        <t>Congratulations</t>
                    </h2>
                    <p>
                        Yous password has been reset
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import HtButton from '@/components/globals/HtButton.vue';
import I18n from '../modules/i18n/I18n';

export default {
    name: 'ForgotPassword',
    components: { HtButton },
    data() {
        return {
            step: 'default',
            error: '',
            user: {
                email: '',
                password: '',
                domain: '',
            },
            mail_sent: false,
            company: null,
            confirm_password: '',
            token_valid: false,
            password_updated: false,
            loading: false,
            disableButton: false,
        };
    },
    computed: {
        hasToken() {
            return this.$route.params.token !== undefined;
        },
    },
    created() {
        let userLanguage = 'en';
        try {
            userLanguage = window.localStorage.getItem('language');
        } catch (e) {
            userLanguage = 'en';
        }
        if (this.$route.params.token) {
            this.step = 'reset';
            this.checkToken();
        }
        I18n.getTranslationPack(userLanguage).then(() => {
            this.$http.get('getMinimumCompanyInfo').then(({ data }) => {
                this.company = data.company;
                this.$store.dispatch('faviconTitle/setFavicon', data.favicon);
            });
        });
    },

    methods: {
        showErrorDoesNotBelongToCompany() {
            this.$Notifier('App').showError(this.translate('It seems you do not belong to this company.'));
        },
        async onLogin() {
            await this.$validator.validate('email.*');

            if (this.loading || this.errors.any('email')) {
                this.$Notifier('App').showError(this.errors.first('email', 'email'));
                return;
            }

            this.error = '';

            this.loading = true;

            const data = {
                email: this.user.email,
                domain: this.$env.getCompanyDomain(document.location.href),
            };
            this.$http.post('auth/forgot_password', data).then(() => {
                this.mail_sent = true;
                this.disableButton = true;
            }).catch(({ response }) => {
                if (response.data?.message) {
                    this.$Notifier('App').showError(this.translate(response.data.message));
                } else {
                    this.showErrorDoesNotBelongToCompany();
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        async onReset() {
            await this.$validator.validateAll();
            if (this.loading || this.errors.any()) {
                return;
            }
            const data = {
                password: this.user.password,
                token: this.$route.params.token,
                domain: this.$env.getCompanyDomain(document.location.href),
            };
            this.loading = true;
            this.$http.post('auth/reset_password', data).then(() => {
                this.password_updated = true;
                this.$Notifier('App').showInfo(this.translate('Update successful! Please log in with your new password'));
                this.$router.push('/Login');
            }).catch(({ response }) => {
                if (response.status !== 409) this.showErrorDoesNotBelongToCompany();
            }).finally(() => {
                this.loading = false;
            });
        },
        checkToken() {
            const data = {
                domain: this.$env.getCompanyDomain(document.location.href),
            };
            this.loading = true;
            this.$http.post(`auth/check_forgot_password_token/${this.$route.params.token}`, data).then(() => {
                this.token_valid = true;
            }).catch(({ response }) => {
                if (response.status === 422) {
                    this.token_valid = false;
                } else {
                    this.showErrorDoesNotBelongToCompany();
                }
            }).finally(() => {
                this.loading = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

@media only screen and (max-width: 375px) {
    #login-page {
        .login-component {
            padding: 25px 30px;
        }

        #login-email, #login-password, #create-password, #create-confirm-password, .button-login, .login-width, .link-sso {
            width: 262px;
        }

        #login-content {
            justify-content: space-between;
            height: 100vh;
            padding: 5em 0 2em;
        }
    }
}

@media screen and (min-width: 376px) and (max-width: 599px) {
    #login-page {
        .login-component {
            padding: 25px 50px;
        }

        #login-email, #login-password, #create-password, #create-confirm-password, .button-login, .login-width, .link-sso {
            width: 262px;
        }

        #login-content {
            justify-content: space-between;
            height: 100vh;
            padding: 5em 0 2em;
        }
    }
}

@media screen and (min-width: 600px) {
    #login-page {
        .login-background {
            // background-position: center;
            // background-repeat: no-repeat;
            // background-size: cover;
            width: 30%;
            height: 100%;
        }

        .login-component {
            padding: 44px 85px;
        }

        .ht-form-input-wrapper, #create-password, #create-confirm-password, .button-login, .login-width, .link-sso {
            width: 500px;
        }
    }
}

#login-page {
    height: 100vh;
    display: flex;
    align-items: center;
    -ms-flex-pack: center;
    -ms-flex-align: center;
    background-color: $background-neutral;

    h1 {
        font-family: Lato;
        font-size: 4rem;
        font-style: normal;
        font-weight: bold;
        line-height: 5rem;
        letter-spacing: 0rem;
        margin-bottom: 30px;
    }

    h2 {
        font-family: Lato;
        font-size: 2rem;
        font-style: normal;
        font-weight: bold;
        line-height: 3rem;
        letter-spacing: 0rem;
        margin-bottom: 0px;
    }

    #login-content {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #login-content img{
        margin: auto;
        display: block;
        max-height: 80px;
    }

    .login-component {
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        position: relative;

        .error-message {
            font-size: 12px;
            color: red;
            height: 24px;
        }
    }

    #login-email, #login-password, #create-password, #create-confirm-password {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 16px;
        background: $neutral-white;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 14px;
        line-height: 17px;
    }

    label[for="login-email"], label[for="login-password"] {
        color: #828283;
    }

    .button-login {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px 24px;
        height: 48px;
        background: #FF5656;
        border-radius: 4px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        cursor: pointer;
        color: #FFFFFF;
        border: none;;
        width: 100%
    }

    .login-input-info {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        /* or 118% */

        color: #979797;

    }

    p {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #342E37;
        padding-bottom: 2rem;
    }
}

form.login-width fieldset {
    margin-bottom: 2rem;
}

</style>
