<template>
    <HtModal
        ref="modalItem"
        @beforeOpen="beforeOpen"
        @beforeClose="beforeClose"
    >
        <template #header>
            <span v-if="isLoaded">{{ companyUserProgramTask.$.title }}</span>
        </template>
        <template #default>
            <div v-if="isLoaded">
                <form
                    class="form-2 spaced label-underline"
                    data-cy="action-form"
                >
                    <fieldset v-if="companyUserProgramTask.company_user_program_task_participant.models.length">
                        <label><t>Done by</t></label>
                        <ParticipantList
                            :users="companyUserProgramTask.company_user_program_task_participant.models"
                            size="8.2rem"
                            :can-add="false"
                        />
                    </fieldset>

                    <fieldset v-if="companyUserProgramTask.description">
                        <label><t>Description</t></label>
                        <div
                            v-dompurify-html="companyUserProgramTask.description"
                            class="item-content no-reset"
                        />
                    </fieldset>

                    <fieldset v-if="shouldShowAvailabilityDate">
                        <label><t>To complete by</t></label>
                        <div class="item-content">
                            {{ $Utils.moment(companyUserProgramTask.datetime_end).format('LL') }}
                        </div>
                    </fieldset>

                    <fieldset>
                        <HtStatusLabel
                            :status="companyUserProgramTask.status"
                        />
                    </fieldset>

                    <fieldset v-if="companyUserProgramTask.recurrence?.id">
                        <label><t>Occures every</t></label>
                        <div>{{ companyUserProgramTask.recurrence.recurrence_frequence_value }} <t>{{ companyUserProgramTask.recurrence.recurrence_frequence_unit }}</t></div>
                    </fieldset>
                </form>

                <Modalable
                    ref="modalEdit"
                    class="modalable-1 medium"
                    :mode="2"
                >
                    <UserActionEdit
                        :id="companyUserProgramTaskId"
                        :company-user-id="companyUserId"
                        :should-show-availability-date="shouldShowAvailabilityDate"
                        @onUpdate="onUpdate"
                        @onDelete="onDelete"
                    />
                </Modalable>
            </div>
        </template>
        <template #footer>
            <div
                v-if="isLoaded"
                class="button-container"
            >
                <Button
                    v-if="companyUserProgramTask.permissions.delete === true && !isCancelled"
                    class="negative"
                    cypress="action-delete-button"
                    @click="onRemove()"
                >
                    <t>Remove</t>
                </Button>
                <Button
                    v-if="companyUserProgramTask.permissions.update === true && !isCancelled"
                    cypress="action-edit-button"
                    @click="onEdit()"
                >
                    <t>Edit</t>
                </Button>
                <Button
                    v-if="companyUserProgramTask.permissions.can_validate && canValidate"
                    :state="buttonState"
                    cypress="action-toggle-status-button"
                    @click="toggleStatus()"
                >
                    {{ translate(isDone ? 'Mark as undone' : 'Mark as done') }}
                </Button>
            </div>
        </template>
    </HtModal>
</template>

<script>
import HtStatusLabel from '@/pages/HtStatusLabel.vue';
import Modalable from '@/Modalable';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import ParticipantList from '@/components/globals/ParticipantList.vue';
import UserActionEdit from './UserActionEdit.vue';

export default {
    name: 'UserActionItem',
    components: {
        ParticipantList,
        HtStatusLabel,
        UserActionEdit,
    },

    props: {
        canValidate: {
            type: Boolean,
            default: true,
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },

    data() {
        return {
            buttonState: 'idle',
            companyUserId: null,
            companyUserProgramTaskId: null,
            companyUserProgramTask: null,
        };
    },

    computed: {
        isLoaded() {
            return this.companyUserProgramTask && this.companyUserProgramTask.isLoaded();
        },

        isDone() {
            return this.companyUserProgramTask.status === CompanyUserProgramTask.STATUS_DONE;
        },

        isCancelled() {
            return this.companyUserProgramTask.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },
    },

    methods: {
        beforeOpen(resolve) {
            this.companyUserProgramTask = this.newCompanyUserProgramTask();
            this.companyUserProgramTask.get();
            resolve();
        },

        beforeClose(resolve) {
            this.item = null;
            resolve();
        },

        open(task_id, companyUserId) {
            this.companyUserProgramTaskId = task_id;
            this.companyUserId = companyUserId;
            this.$refs.modalItem.open();
        },

        onEdit() {
            this.$refs.modalEdit.open();
        },

        toggleStatus() {
            this.companyUserProgramTask.status = this.isDone ? CompanyUserProgramTask.STATUS_PENDING : CompanyUserProgramTask.STATUS_DONE;
            this.$handleValidationTasks(this.companyUserProgramTask).then(() => {
                this.$refs.modalItem.close();
                this.$emit('onUpdate');
            });
        },

        onRemove() {
            Modalable.openRegisteredWindow('Deletewindow', { content: this.translate('Are you sure you want to delete this action ?') }).then((didUserClickDelete) => {
                if (didUserClickDelete) {
                    this.companyUserProgramTask.delete().then(() => this.onDelete());
                }
            });
        },

        onUpdate() {
            this.$refs.modalItem.close();
            this.$emit('onUpdate');
        },

        onDelete() {
            this.$refs.modalItem.close();
            this.$emit('onDelete');
        },

        newCompanyUserProgramTask() {
            return new CompanyUserProgramTask([
                'id',
                'title',
                'resource',
                'company_user_id',
                'status',
                'datetime_end',
                'description',
                'permissions',
                'validator_type',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'firstname']);
                },
                companyUserProgramTaskParticipant: (query) => {
                    query.select(['company_user_id']).with({
                        companyUser: (query) => {
                            query.select(['firstname', 'lastname', 'image', 'id']);
                        },
                    });
                },
                recurrence: (query) => {
                    query.select(['id', 'company_user_program_task_id', 'recurrence_frequence_value', 'recurrence_frequence_unit', 'recurrence_end_date']);
                },
            }).where([
                ['id', '=', this.companyUserProgramTaskId],
            ]);
        },
    },
};
</script>

<style lang="scss" src="./UserActionItem.scss" scoped />
