<template>
    <div>
        <div v-if="companyUserQuiz.isLoaded()">
            <fieldset :class="{ 'error': errors.has('quiz_participants')}">
                <label>
                    <t>Assign to:</t>
                </label>
                <div class="card-6 flex-container">
                    <template>
                        <div class="item">
                            <UserBlock :item="{company_user: companyUserQuiz.company_user_participant}" />
                            <div class="align-center">
                                {{ companyUserQuiz.company_user_quiz_participant.firstname }}
                            </div>
                        </div>
                    </template>
                </div>
            </fieldset>

            <HtUserProgramList
                v-model="companyUserQuiz.company_user_program_id"
                :company-user-id="companyUserId"
                disabled
            />

            <HtKeyDatesInputOrderForDependency
                v-if="hasDependency"
                :user-program-id="companyUserQuiz.company_user_program_id"
                :resource-id="companyUserQuiz.id"
                resource-type="company_quiz"
                :is-business-day="userTask.is_business_day"
                :end-date="userTask.datetime_end"
                :dependents="userTask.dependents.models"
                :dependencies="userTask.dependencies.models"
                :should-show-availability-date="!userTask.dependents.models.length"
            />

            <HtKeyDatesInputOrder
                v-else-if="userTask"
                :user-program-id="companyUserQuiz.company_user_program_id"
                :resource-id="companyUserQuiz.id"
                resource-type="company_quiz"
                :is-business-day.sync="userTask.is_business_day"
                :offset-key-date.sync="userTask.company_user_program_key_date_id"
                :offset-availability-specific-date.sync="userTask.datetime_availability"
                :offset-availability-number.sync="userTask.offset_availability_number"
                :offset-availability-unit.sync="userTask.offset_availability_unit"
                :offset-end-specific-date.sync="userTask.datetime_end"
                :offset-end-number.sync="userTask.offset_end_number"
                :offset-end-unit.sync="userTask.offset_end_unit"
                can-select-specific-date
                :dependents.sync="userTask.dependents.models"
                :dependencies.sync="userTask.dependencies.models"
                :disabled="userTask && userTask.dependencies.models.length > 0"
            />

            <div class="modal-actions right">
                <Button
                    v-if="companyUserQuiz.permissions.delete === true"
                    :state="buttonState"
                    class="negative"
                    cypress="quiz-delete-button"
                    @click="onDelete()"
                >
                    <t>Delete</t>
                </Button>
                <Button
                    v-if="companyUserQuiz.permissions.update === true"
                    :state="buttonState"
                    cypress="quiz-save-button"
                    @click="onUpdate()"
                >
                    <t>Save</t>
                </Button>
            </div>
        </div>
        <template v-else>
            <IconLoading />
        </template>
    </div>
</template>

<script>
import CompanyUserQuiz from '@/models/CompanyUserQuiz';
import HtUserProgramList from '@/components/globals/HtUserProgramList.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import HtKeyDatesInputOrderForDependency
    from '@/components/globals/HtKeyDatesInputOrderForDependency.vue';

export default {
    name: 'UserQuizEdit',
    components: {
        HtKeyDatesInputOrderForDependency,
        HtKeyDatesInputOrder,
        HtUserProgramList,
    },
    inject: ['modal'],

    props: {
        id: { type: Number },
        companyUserId: { type: Number },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },

    data() {
        return {
            hasDependency: false,
            userTask: null,
            buttonState: 'idle',
            item: null,
            intelligentSelection: { type: Array, required: false },
            companyUserQuiz: new CompanyUserQuiz([
                'id',
                'name',
                'company_user_program_id',
                'company_quiz_id',
                'company_user_id',
                'permissions',
            ]).with({
                companyQuiz: (query) => {
                    query.select(['id']).with({
                        resource: (query) => {
                            query.select(['id', 'name', 'translations']);
                        },
                    });
                },
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'resource_id',
                        'resource',
                        'company_user_program_key_date_id',
                        'offset_availability_number',
                        'offset_availability_unit',
                        'offset_end_number',
                        'offset_end_unit',
                        'is_business_day',
                        'datetime_availability',
                        'datetime_end',
                    ]).with({
                        dependencies: (query) => {
                            query.select([
                                'id',
                                'resource',
                            ]);
                        },
                        dependents: (query) => {
                            query.select([
                                'id',
                            ]);
                        },
                    });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'arrival_date'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['key']);
                            },
                        });
                },
                companyUserParticipant: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'arrival_date']);
                },
                companyUserProgram: (query) => query.select(['id', 'company_program_id', 'company_user_id']).with({
                    mainKeyDate: (query) => {
                        query.select(['id', 'starts_at']);
                    },
                    companyProgram: (query) => query.select(['id']).with({
                        keyDate: (query) => query.select(['id', 'company_program_id']).with({
                            locales: (query) => query
                                .select(['id', 'name', 'language_key']),
                        }),
                    }),
                }),
            }),
        };
    },
    created() {
        this.companyUserQuiz.where([['id', '=', this.id]]);
        this.companyUserQuiz.company_user_id = this.companyUserId;
        this.companyUserQuiz.get().then(() => {
            this.userTask = this.companyUserQuiz.company_user_program_task;
            this.modal.setTitle(this.companyUserQuiz.name);
            this.hasDependency = this.userTask.dependents.models.length > 0;
        });
    },

    methods: {
        onDelete() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this quiz? Once you click on delete, you will no longer be able to access this quiz.') }).then((response) => {
                if (response === true) {
                    this.companyUserQuiz.delete().then(() => {
                        this.$emit('onDelete');
                        this.modal.close();
                    });
                }
            });
        },

        onUpdate() {
            this.$validator.validateAll().then(() => {
                if (this.errors.any()) {
                    return;
                }

                this.buttonState = 'loading';

                this.companyUserQuiz.save().then(() => {
                    this.$emit('onUpdate');
                    this.modal.close();
                }).catch(() => {
                    this.buttonState = 'idle';
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped src="./UserQuizEdit.scss" />
