<template>
    <div class="description-text">
        <FontAwesomeIcon
            icon="info-circle"
            class="description-icon"
        />
        <t>{{ sendToEmailTypeString }}</t>
    </div>
</template>

<script>
export default {
    name: 'GuessOnWhichEmailItWillBeSend',
    props: {
        professionalEmailSwitchDayOffset: {
            type: Number,
            required: true,
        },
        companyUser: {
            type: Object,
            default() {
                return null;
            },
        },
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        sendToEmailTypeString() {
            const emailType = this.isPersonalEmail ? 'personal' : 'professional';
            return `The enrollee will receive this invitation on their ${emailType} e-mail`;
        },
        resourceMode() {
            // Guess if no user was provide we are in resource creation not in program
            return this.companyUser === null;
        },
        isPersonalEmail() {
            return (this.resourceMode && this.offsetComputationIsBeforeDayOffsetSetting)
                || (this.userHasPersonalEmail && this.offsetComputationIsBeforeDayOffsetSetting);
        },
        offsetComputationIsBeforeDayOffsetSetting() {
            return (this.orderStart - this.emailOffset) < this.professionalEmailSwitchDayOffset;
        },
        userHasPersonalEmail() {
            return this.companyUser?.main_email === 'personal_email' || false;
        },
        emailOffset() {
            return this.item.email_sending_offset;
        },
        orderStart() {
            return this.item.order_onboarding_end;
        },
    },

};
</script>

<style scoped>

</style>
