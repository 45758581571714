<template>
    <div class="iframe-container">
        <div class="iframe-container-content">
            <HtIconClose
                class="iframe-container-content-close"
                @click="close"
            />
            <iframe
                :src="src"
                class="iframe-container-content-iframe"
                frameborder="0"
                allowfullscreen
            />
        </div>
    </div>
</template>

<script>
import HtIconClose from '@/components/icons/HtIconClose.vue';

export default {
    name: 'IframeContainer',
    components: {
        HtIconClose,
    },
    props: {
        src: {
            type: String,
            required: true,
        },
    },
    methods: {
        close() {
            this.$emit('onClose');
        },
    },
};
</script>

<style lang="scss" scoped>
.iframe-container {
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.2);

	&-content {
		margin: 5em;
		padding: 0;
		width: calc(100% - 10em);
		height: calc(100vh - 10em);
		background: white;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		&-close {
			position: absolute;
			top: 2em;
			right: 2em;
		}

		&-iframe {
			width: 100%;
			height: calc(100vh - 10em);
			border: none;
			margin: 0;
			padding: 5em;
		}
	}
}
</style>
