<template>
    <div>
        <UserNotificationList
            :company_user_id="shared.session.companyUser.id"
            :type="'resource'"
        />
    </div>
</template>
<script>
import UserNotificationList from '@/components/resources/notification/UserNotificationList.vue';

export default {
    name: 'AccountNotificationResource',
    components: {
        UserNotificationList,
    },
};
</script>
