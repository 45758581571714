<template>
    <div class="archive-skeleton">
        <div
            v-for="n in numberOfSkeletons"
            :key="n"
            class="skeleton-section"
        >
            <Skeleton
                width="100%"
                height="30px"
            />
            <Skeleton
                width="80%"
                height="30px"
            />
            <Skeleton
                width="100%"
                height="60px"
            />
            <Skeleton
                width="100%"
                height="20px"
            />
        </div>
    </div>
</template>

<script>
import { Skeleton } from 'vue-loading-skeleton';

export default {
    components: {
        Skeleton,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            numberOfSkeletons: 0,
        };
    },
    mounted() {
        this.fillPageWithSkeletons();
        window.addEventListener('resize', this.fillPageWithSkeletons);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.fillPageWithSkeletons);
    },
    methods: {
        fillPageWithSkeletons() {
            const skeletonHeight = 140;
            const windowHeight = window.innerHeight;
            this.numberOfSkeletons = Math.ceil((windowHeight / skeletonHeight) / 2);
        },
    },
};
</script>

<style scoped>

  .skeleton-section {
    margin-bottom: 20px;
    border-radius: 4px;
    overflow: hidden;
  }

  .skeleton-section > * {
    margin-bottom: 10px;
  }
</style>
