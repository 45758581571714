import Vue from 'vue';
import api from '@/store/api';

/**
 * Represents an office associated with a company.
 *
 * @typedef {Object} Office
 *
 * @property {string} company_user_id - The unique identifier for the office.
 * @property {string} name - The name of the office.
 * @property {string} country - The country where the office is located.
 * @property {string} city - The city where the office is located.
 * @property {string} address - The street address of the office.
 * @property {string} zipcode - The postal code of the office.
 * @property {string} timezone - The timezone of the office.
 */

/**
 * @typedef {{data: Office}} OfficeResourceResponse
 * @typedef {{data: Office[]}} OfficeCollectionResponse
 */
export default {
    /**
     * @param {any} data
     * @returns {Promise<OfficeCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.get(api.endpoints.CONFIGURATION_OFFICES, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<OfficeResourceResponse>}
     */
    get(id, config = {}) {
        return Vue.prototype.$http.get(`${api.endpoints.CONFIGURATION_OFFICES}/${id}`, config);
    },

    /**
     * @param {any} data
     * @returns {Promise<OfficeResourceResponse>}
     */
    create(data = {}) {
        return Vue.prototype.$http.post(api.endpoints.CONFIGURATION_OFFICES, data);
    },

    /**
     * @param {number|string} id
     * @param {any} data
     * @returns {Promise<OfficeResourceResponse>}
     */
    update(id, data = {}) {
        return Vue.prototype.$http.put(`${api.endpoints.CONFIGURATION_OFFICES}/${id}`, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<{}>}
     */
    delete(id, config = {}) {
        return Vue.prototype.$http.delete(`${api.endpoints.CONFIGURATION_OFFICES}/${id}`, config);
    },
};
