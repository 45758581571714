<script>
export default {
    name: 'MiddlewareMail',

    data() {
        return {
            route_url: null,
            route_uuid: '',
            company_user: null,
        };
    },

    mounted() {
        this.handleMailRedirect();
    },

    methods: {
        handleMailRedirect() {
            this.getRouteParams();
            if (this.route_url && this.route_uuid) {
                this.loadMinimumInfoCompanyUser().then(() => {
                    const params = this.$Utils.clone(this.$route.query);
                    delete params.url;

                    if (!this.company_user) {
                        return this.$router.push('/Dashboard');
                    }

                    if (this.company_user.status === 'active') {
                        return this.$router.push({
                            path: this.route_url,
                            query: {
                                ...params,
                                uuid: this.route_uuid,
                            },
                        });
                    }
                    return this.$router.push(`/invite/${this.route_uuid}`);
                });
            } else return this.$router.push('/Dashboard');
        },

        getRouteParams() {
            if (this.$route.path.toLowerCase().includes('/mail/')) {
                if (this.$route.params.uuid) this.route_uuid = this.$route.params.uuid;

                if (this.$route.query.url) this.route_url = this.$route.query.url;
            } else {
                if (this.$router.historyTrack[0].params.uuid) this.route_uuid = this.$router.historyTrack[0].params.uuid;

                if (this.$router.historyTrack[0].query.url) this.route_url = this.$router.historyTrack[0].query.url;
            }
        },

        loadMinimumInfoCompanyUser() {
            return new Promise((resolve, reject) => {
                this.API.get(`getMinimumUserInfo/${this.route_uuid}`, {
                    200: (data) => {
                        this.company_user = data.company_user;
                        resolve();
                    },
                    onError: (data, code) => {
                        reject(data, code);
                    },
                });
            });
        },
    },
};
</script>
