<template>
    <div class="specific-reports">
        <HtTabs>
            <HtTab
                v-for="(dashboard, index) in dashboards"
                :key="index"
                :ref="dashboard.id"
                :title="dashboard.title"
            >
                <iframe
                    :src="dashboard.url"
                    frameborder="0"
                    allowtransparency
                    class="iframe"
                    height="100%"
                    width="100%"
                />
            </HtTab>
        </HtTabs>
    </div>
</template>
<script>
import HtTabs from '@/components/globals/HtTabs.vue';
import HtTab from '@/components/globals/HtTab.vue';

export default {
    name: 'SpecificReports',
    components: { HtTab, HtTabs },

    props: {
        dashboards: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
<style lang="scss" scoped>
.specific-reports{
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;

    .iframe {
        overflow: hidden;
        height: 100%;
        width: 100%;
        position: relative;
        inset: 0px;
    }
}
</style>
