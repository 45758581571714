import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import CompanyFile from './CompanyFile';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import CompanyUserProgram from './CompanyUserProgram';

export default class CompanyUserTraining extends Model {
    modelConfig() {
        return {
            controller: 'CompanyUserDocumentController',
        };
    }

    modelDefaultValues() {
        return {
            type: 'file',
        };
    }

    modelEvents() {
        return {

        };
    }

    modelRelations() {
        return {
            companyUserProgram: () => this.belongsTo(CompanyUserProgram, 'company_user_program_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
            companyUserProgramTask: () => this.belongsTo(CompanyUserProgramTask, 'resource').where(['resource', '=', 'company_document']),
        };
    }
}
