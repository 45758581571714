import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUserRequirement from './CompanyUserRequirement';
import CompanyUserRequirementGroupCollection from './CompanyUserRequirementGroupCollection';

export default class CompanyUserRequirementGroup extends Model {
    modelConfig() {
        return {
            collection: CompanyUserRequirementGroupCollection,
        };
    }

    modelRelations() {
        return {
            companyUserRequirement: () => this.hasMany(CompanyUserRequirement, 'company_user_requirement_group_id', 'id'),
        };
    }
}
