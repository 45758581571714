import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanySurveyExtra from './CompanySurveyExtra';

export default class CompanySurveyExtraCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanySurveyExtra,
        };
    }
}
