/**
 *
 * @typedef {Object} Field
 * @property {String} type - The type of the field. Define several validation rules.
 * @property {Object} rule_params - The object representing the validation rules attached to the field
 * @property {Integer|null} rule_params.max - Value of the rule 'max' for the field
 * @property {Integer|null} rule_params.min - Value of the rule 'min' for the field
 * @property {String|Integer|Boolean|null} value - The value of the field
 *
 */
import Vue from 'vue';

export default class FieldValidator {
    /**
     *
     * @param {Object<string, {name: string, isEnroleeAllowed: boolean, width: Integer, height: Integer, rules?:string}>} fieldTypes
     */
    constructor(fieldTypes) {
        this.fieldTypes = fieldTypes;
    }

    static specificTextRules() {
        return [
            {
                key: 'phone_number',
                label: Vue.prototype.$I18n.translate('Phone number'),
            },
            {
                key: 'zip_code',
                label: Vue.prototype.$I18n.translate('ZIP code'),
            },
            {
                key: 'num_secu',
                label: Vue.prototype.$I18n.translate('Security social number'),
            },
            {
                key: 'email',
                label: Vue.prototype.$I18n.translate('E-mail'),
            },
        ];
    }

    /**
     *
     * @param {String} rulesString - In the shape of Laravel rule string pipe seperated (ex: 'numeric|min:3|max:12')
     * @returns {Array<String>} An array of laravel rule strings
     */
    static parseRulesString(rulesString) {
        return rulesString.split('|');
    }

    /**
     *
     * @param {Field} field
     * @returns {String}
     */
    buildRuleString(field) {
        const rules = FieldValidator.parseRulesString(this.fieldTypes[field.type]?.rules || '');
        Object.keys(field?.rule_params || {}).forEach((key) => {
            // Handle the difference on min and max between veeValidate and Laravel
            let mappedKey = key;
            if ((key === 'min' || key === 'max')) {
                if (field.type === 'numeric') {
                    mappedKey += '_value_or_empty';
                } else {
                    mappedKey += '_or_empty';
                }
            }
            if (['phone_number', 'email'].includes(key)) {
                mappedKey += '_nullable';
            }
            rules.push(`${mappedKey}:${field?.rule_params[key]}`);
        });

        return rules.join('|');
    }
}
