import I18n from '@/modules/i18n/I18n';
import api from '@/store/api';
import cloneDeep from 'lodash.clonedeep';

const SET_ENTITIES = 'set_entities';
const FORCE_ENTITIES_EXPIRATION = 'force_entities_expiration';

export default {
    namespaced: true,

    state() {
        return {
            entities: [],
            entitiesLoaded: false,
            entitiesExpiresAt: null,
        };
    },

    getters: {
        offices(state) {
            let offices = [];

            state.entities
                .filter((e) => e.slug === 'company_office')
                .forEach((e) => {
                    offices = [...offices, ...e.values];
                });

            return offices;
        },
        departments(state) {
            let departments = [];

            state.entities
                .filter((e) => e.slug === 'company_department')
                .forEach((e) => {
                    departments = [...departments, ...e.values];
                });

            return departments;
        },
        officeEntity(state) {
            return state.entities.find((e) => e.slug === 'company_office');
        },
        departmentEntity(state) {
            return state.entities.find((e) => e.slug === 'company_department');
        },
        jobEntity(state) {
            return state.entities.find((e) => e.slug === 'company_job_position');
        },
        contractEntity(state) {
            return state.entities.find((e) => e.slug === 'company_contract');
        },
        officeValuesForFilter(state, getters) {
            return getters.officeEntity ? getters.valuesByEntityId[getters.officeEntity.id] : [];
        },
        sortedOfficeValuesForFilter(state, getters) {
            return [...getters.officeValuesForFilter].sort((office1, office2) => office1.name.localeCompare(office2.name));
        },
        departmentValuesForFilter(state, getters) {
            return getters.departmentEntity ? getters.valuesByEntityId[getters.departmentEntity.id] : [];
        },
        contractValuesForFilter(state, getters) {
            return getters.contractEntity ? getters.valuesByEntityId[getters.contractEntity.id] : [];
        },
        jobValuesForFilter(state, getters) {
            return getters.jobEntity ? getters.valuesByEntityId[getters.jobEntity.id] : [];
        },
        sortedjobValuesForFilter(state, getters) {
            return [...getters.jobValuesForFilter].sort((jobPosition1, jobPosition2) => jobPosition1.name.localeCompare(jobPosition2.name));
        },
        valuesByEntityId(state) {
            const entitiesById = {};

            state.entities.forEach((entity) => {
                const values = entity.values.map((v) => {
                    let value = {
                        value_resourceable_id: v.id,
                        company_entity_id: entity.id,
                        company_entity_value_id: v.company_entity_value_id,
                        name: v.is_heyteam ? I18n.translate(v.name) : v.name,
                    };

                    if (entity.slug === 'company_contract') {
                        value = {
                            ...value,
                            has_end_date: v.has_end_date,
                            has_probation: v.has_probation,
                        };
                    }

                    return value;
                });

                entitiesById[entity.id] = values;
            });

            return entitiesById;
        },
        getEntitiesFromIds(state) {
            return (entitiesLinkedToProgramIds) => {
                if (!entitiesLinkedToProgramIds?.length) return [];
                return state.entities
                    .filter((entity) => entitiesLinkedToProgramIds.includes(entity.id));
            };
        },
    },

    actions: {
        createEntity({ state, commit }, entity) {
            return new Promise((resolve) => {
                api.configuration.entities
                    .create(entity)
                    .then((res) => {
                        const created = res.data.data;
                        const entities = cloneDeep(state.entities);
                        entities.push(created);

                        commit(SET_ENTITIES, entities);
                        resolve(created);
                    });
            });
        },
        updateEntity({ state, commit }, entity) {
            return new Promise((resolve) => {
                api.configuration.entities
                    .update(entity.id, entity)
                    .then((res) => {
                        const entities = cloneDeep(state.entities);
                        const updatedEntityIndex = entities.findIndex((existing) => existing.id === entity.id);
                        entities[updatedEntityIndex] = res.data.data;

                        commit(SET_ENTITIES, entities);
                        resolve(entity);
                    });
            });
        },
        forceFetchEntities({ commit, dispatch }) {
            commit(FORCE_ENTITIES_EXPIRATION);
            return dispatch('fetchEntities');
        },
        forceEntitiesExpiration({ commit }) {
            commit(FORCE_ENTITIES_EXPIRATION);
        },
        fetchEntities({ state, commit }) {
            if (state.entitiesExpiresAt > this._vm.$moment()) {
                return;
            }
            return api.configuration.entities.getAll().then(({ data }) => {
                commit(SET_ENTITIES, data.data);
            });
        },
    },

    mutations: {
        [SET_ENTITIES](state, entities) {
            state.entities = entities;
            state.entitiesLoaded = true;
            state.entitiesExpiresAt = this._vm.$moment().add(5, 'minutes');
        },
        [FORCE_ENTITIES_EXPIRATION](state) {
            state.entitiesLoaded = false;
            state.entitiesExpiresAt = this._vm.$moment();
        },
    },
};
