import Model from '@tony.caron/node-model-proxy/Model';
import CompanyChatbot from '@/models/CompanyChatbot';
import HtProgramTypeCollection from './HtProgramTypeCollection';

export default class HtProgramType extends Model {
    static SLUG_DEFAULT = 'default';

    static SLUG_CROSSBOARDING = 'crossboarding';

    static SLUG_OFFBOARDING = 'offboarding';

    static SLUG_ONBOARDING = 'onboarding';

    static SLUG_PREBOARDING = 'preboarding';

    static SLUG_REBOARDING = 'reboarding';

    static STATUS_ACTIVE = 'active';

    static programSlugToChatbotSlug = {
        onboarding: CompanyChatbot.SLUG_FULL,
        offboarding: CompanyChatbot.SLUG_SIGN_ONLY,
        preboarding: CompanyChatbot.SLUG_LIGHT,
        crossboarding: CompanyChatbot.SLUG_LIGHT,
        reboarding: CompanyChatbot.SLUG_LIGHT,
        campaign: CompanyChatbot.SLUG_SIGN_ONLY,
    };

    modelConfig() {
        return {
            collection: HtProgramTypeCollection,
        };
    }
}
