<template>
    <div class="wrapper">
        <HtFormCard>
            <header>
                <h4>
                    <t :count="contents.length">
                        {count} question | {count} questions
                    </t>
                </h4>

                <FontAwesomeLayers
                    v-if="!isCompleted"
                    :data-cy="'survey-question-add'"
                    class="button-add"
                    @click="$emit('add-question')"
                >
                    <FontAwesomeIcon
                        size="2x"
                        icon="circle"
                        transform="left-9"
                        class="icon-circle"
                    />
                    <FontAwesomeIcon
                        size="2x"
                        icon="plus"
                        transform="left-8 shrink-10"
                        class="plus"
                    />
                </FontAwesomeLayers>
            </header>

            <main>
                <Draggable
                    v-model="contents"
                    :options="{disabled: isCompleted}"
                    class="draggable"
                    tag="section"
                    @update="update"
                >
                    <SurveyFormQuestionDraggableItem
                        v-for="(item, index) in contents"
                        :key="item.id"
                        :content="item"
                        :current-language="currentLanguage"
                        :is-completed="isCompleted"
                        @click="onClickQuestion(index)"
                    />
                </Draggable>
            </main>
        </HtFormCard>
    </div>
</template>

<script>
import Draggable from 'vuedraggable';
import CompanyLanguage from '@/models/CompanyLanguage';
import CompanySurveyContent from '@/models/CompanySurveyContent';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import SurveyFormQuestionDraggableItem from '@/components/pages/resources/surveys/form/SurveyFormQuestionsDraggableItem.vue';

export default {
    name: 'SurveyFormQuestionsDraggable',
    components: {
        SurveyFormQuestionDraggableItem,
        HtFormCard,
        Draggable,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },
        currentLanguage: {
            type: CompanyLanguage,
            required: true,
        },
        isCompleted: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            companySurveyContent: CompanySurveyContent,
            contents: [...this.value].sort((a, b) => a.order - b.order),
        };
    },

    watch: {
        contents: {
            deep: true,
            handler() {
                this.$emit('input', this.contents);
            },
        },
        value: {
            deep: true,
            handler() {
                this.contents = this.value;
            },
        },
    },

    methods: {
        onClickQuestion(index) {
            document.getElementById('app').scrollTop = document.getElementById(`question-${index}`).offsetTop;
        },

        update() {
            const maxOrder = this.contents.length - 1;

            this.contents.map((content, index) => {
                content.order = index;

                if (
                    content.next_content_order <= content.order
                    || content.order === maxOrder
                    || (content.next_content_order > maxOrder
                        && content.next_content_order !== CompanySurveyContent.ORDER_END_SURVEY)
                ) {
                    content.next_content_order = null;
                }

                if (content.resource === CompanySurveyContent.RESOURCE_QUESTION) {
                    content.question.answers.forEach((answer) => {
                        if (answer.next_content_order <= index
                            || content.order === maxOrder
                            || (answer.next_content_order > maxOrder
                                && answer.next_content_order !== CompanySurveyContent.ORDER_END_SURVEY)
                        ) {
                            answer.next_content_order = null;
                        }
                    });
                }

                return content;
            });
        },
    },
};
</script>

<style lang="scss" scoped src="./SurveyFormQuestionsDraggable.scss"></style>
