<template>
    <div v-if="companyUserDocCollection && companyUserDocCollection.isLoaded()">
        <HtAccordion
            :title="translate('Document | Documents', {count: filteredUserDoc.length})"
            :count="filteredUserDoc.length"
            :has-button="filteredUserDoc.length === 0"
            :button-label="addButtonLabel"
            :is-branding="false"
            cypress="document"
            @buttonClick="openSelectModal()"
        >
            <template v-if="filteredUserDoc.length > 0">
                <HtTable
                    :items="filteredUserDoc"
                    :columns="columns"
                    cypress="document-table"
                    @onCellClicked="openEditModal"
                />
                <div class="mt-4 text-center">
                    <Button
                        type="clear"
                        cypress="document-add-button"
                        @click="openSelectModal()"
                    >
                        <slot :name="'buttonText'">
                            {{ translate(addButtonLabel) }}
                        </slot>
                    </Button>
                </div>
            </template>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No document added</t>
                </strong>
            </div>
        </HtAccordion>

        <Modalable
            ref="modalableSelect"
            :mode="2"
            class="modalable-1 medium"
        >
            <UserDocSelect
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                :status-on-create="statusOnCreate"
                @onUpdate="onUpdate()"
            />
        </Modalable>
        <Modalable
            ref="modalableEdit"
            :mode="2"
            class="modalable-1 medium"
        >
            <UserDocEdit
                :id="currentId"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate()"
                @onDelete="onDelete()"
            />
        </Modalable>
    </div>
</template>

<script>
import HtAccordion from '@/components/globals/HtAccordion.vue';
import UserDocSelect from '@/components/pages/dashboard/modals/doc/UserDocSelect.vue';
import UserDocEdit from '@/components/pages/dashboard/modals/doc/UserDocEdit.vue';
import CompanyUserDocCollection from '@/models/CompanyUserDocCollection';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';

export default {
    name: 'UserDocumentFlow',
    components: {
        UserDocSelect,
        UserDocEdit,
        HtAccordion,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        statusOnToggle: {
            type: String,
            default: CompanyUserProgramTask.STATUS_PENDING,
        },
        statusOnCreate: {
            type: String,
            default: CompanyUserProgramTask.STATUS_PENDING,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                },
            ],
            currentId: null,
            companyUserDocCollection: new CompanyUserDocCollection([
                'id', 'company_user_id', 'company_user_program_id', 'company_doc_id', 'name', 'is_heyteam',
            ]).with({
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
                companyUserProgramTask: (query) => {
                    query.select(['id', 'resource_id', 'resource', 'status', 'datetime_availability']);
                },
            }).where([
                ['company_user_program_id', '=', this.companyUserProgramId],
            ]).orderBy([['name', 'asc']]),

            addButtonLabel: 'Add document',
        };
    },

    computed: {
        canModify() {
            return this.$canUpdate('ModelCompanyUserDoc', { company_user_id: this.companyUserId });
        },

        filteredUserDoc() {
            return this.companyUserDocCollection.$.models.filter((item) => ((this.shared.session.companyUser.id === this.companyUserId)
                ? this.$Utils.moment().isSameOrAfter(this.$Utils.moment(item.company_user_program_task.datetime_availability))
                : true));
        },
    },

    created() {
        this.companyUserDocCollection.get().then(() => {
            this.$emit('on-load', this.companyUserDocCollection.$.models.length);
        });
    },

    methods: {
        onDelete() {
            this.companyUserDocCollection.get();
        },

        onUpdate() {
            this.companyUserDocCollection.get();
        },

        openEditModal(row) {
            this.currentId = row.item.id;
            this.$refs.modalableEdit.open();
        },
        openSelectModal() {
            this.$refs.modalableSelect.open();
        },
    },
};
</script>
