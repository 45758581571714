<template>
    <div
        id="main-container"
        class="centered_content align-center"
    >
        <header class="header-light">
            <!-- <div class="logo"><img :src="$Utils.resolveS3PublicImage(company.image)" /></div> -->
            <div class="logo">
                <router-link to="/Dashboard">
                    <img :src="$Utils.resolveS3PublicImage(shared.session.company.image)">
                </router-link>
            </div>
            <div class="user-progression-wrapper">
                <div class="user-progression">
                    <div class="container-number">
                        <div>
                            <span :class="{'red': step >= 1}"><span class="number-step">1</span></span>
                        </div>

                        <div>
                            <span :class="{'red': step == 2}"><span class="number-step">2</span></span>
                        </div>

                        <div>
                            <span :class="{'red': step >= 3}"><span class="number-step">3</span></span>
                        </div>
                    </div>
                    <div class="container-bar">
                        <div
                            class="bar-fill"
                            :style="getProgress"
                        />
                    </div>
                </div>
            </div>
            <HtIconClose
                data-cy="exit"
                @click="$router.goBack()"
            />
        </header>

        <div v-if="is_loading">
            <div class="invite-head">
                <h1><t>Importing contacts</t></h1>
                <p><t>We are fetching contacts from your Gsuite accounts</t></p>
            </div>

            <div class="sk-fading-circle">
                <div class="sk-circle1 sk-circle" />
                <div class="sk-circle2 sk-circle" />
                <div class="sk-circle3 sk-circle" />
                <div class="sk-circle4 sk-circle" />
                <div class="sk-circle5 sk-circle" />
                <div class="sk-circle6 sk-circle" />
                <div class="sk-circle7 sk-circle" />
                <div class="sk-circle8 sk-circle" />
                <div class="sk-circle9 sk-circle" />
                <div class="sk-circle10 sk-circle" />
                <div class="sk-circle11 sk-circle" />
                <div class="sk-circle12 sk-circle" />
            </div>
        </div>

        <div
            v-else
            class="invite-gsuite form-2"
        >
            <div v-if="not_found">
                <div class="invite-head">
                    <h1><svg class="icon icon-alert"><use xlink:href="#icon-alert" /></svg></h1>
                    <h1><t>Oops!</t></h1>
                    <h1><t>Seems like you haven't done the Gsuite integration</t></h1>
                    <p><t>You need to do the Gsuite integration in order to import all your contacts</t></p>
                    <!-- <p><t>Go to Settings > Software > Add Software and choose the Gsuite integration</t></p> -->
                </div>

                <Button @click="$router.push({ name: 'ResourcesSoftware' })">
                    <t>Go to Gsuite integration</t>
                </Button>
            </div>
            <div v-if="!not_found && step == 2">
                <div class="invite-head">
                    <h1><t>Select contacts</t></h1>
                    <p><t>Select the team members you want to invite to HeyTeam</t></p>
                </div>

                <div class="gsuite-users centered">
                    <fieldset>
                        <div class="gsuite-search">
                            <svg class="icon icon-glass"><use xlink:href="#icon-glass" /></svg>
                            <input
                                v-model="search_param"
                                type="text"
                                :placeholder="translate('Search bar')"
                                class="full-width"
                            >
                            <div
                                class="toggle"
                                @click="toggleAll()"
                            >
                                <t>All</t>
                                <svg class="icon icon-checked"><use xlink:href="#icon-checked" /></svg>
                            </div>
                        </div>
                    </fieldset>

                    <div class="user-list">
                        <div
                            v-for="user in filtered"
                            class="reactive-cell large"
                            :class="{'selected': checkIfSelected(user), 'invited': checkIfAlreadyInvited(user)}"
                            @click="!checkIfAlreadyInvited(user) ? toggleSelect(user) : null"
                        >
                            <div
                                class="user-img"
                                :style="getUserImage(user)"
                            />
                            <div class="user-name">
                                {{ user.name.fullName }} <span v-if="checkIfAlreadyInvited(user)">(<t>Already invited</t>)</span>
                            </div>
                            <div class="check">
                                <svg class="icon icon-checked"><use xlink:href="#icon-checked" /></svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row-line invite-button">
                    <Button
                        v-show="selected.length > 0"
                        @click="step = 3"
                    >
                        <t :count="selected.length">
                            Validate list of { count } member | Validate list of { count } members
                        </t>
                    </Button>
                </div>
            </div>

            <div v-if="!not_found && step == 3">
                <div class="invite-head">
                    <h1><t>Customize message and send invitations</t></h1>
                    <p><t>You can personalize the invitation you will send to your teammates</t></p>
                </div>

                <div class="gsuite-message centered">
                    <fieldset class="invite-message">
                        <label class="align-left"><t :count="selected.length">Message we will send to the {count} person you are about to invite to HeyTeam | Message we will send to the {count} people you are about to invite to HeyTeam</t></label>
                        <textarea
                            v-model="message"
                            rows="10"
                        />
                    </fieldset>
                </div>

                <div class="row-line invite-button">
                    <Button
                        v-show="selected.length > 0"
                        @click="invite()"
                    >
                        <t :count="selected.length">
                            Send invitation to {count} person | Send invitations to {count} people
                        </t>
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HtIconClose from '@/components/icons/HtIconClose.vue';
import CompanyUserCollection from '@/models/CompanyUserCollection';

export default {
    name: 'InviteGsuite',
    components: {
        HtIconClose,
    },

    data() {
        return {
            company_user: this.shared.session.companyUser,
            saving: false,
            is_loading: true,
            not_found: false,
            gsuite_users: [],
            selected: [],
            company_users: [],
            toggle_all: true,
            companyUserCollection: new CompanyUserCollection([
                'id',
                'name',
                'firstname',
                'lastname',
                'email',
                'professional_email',
                'company_job_position_id',
            ]).orderBy([['name', 'asc']]),
            filtered: [],
            search_param: '',
            step: 1,
            message: 'Hello there\nWe are using HeyTeam to create an amazing onboarding experience for our new hires.\n\nWe need you to create your profile on HeyTeam so that our new hires would know who you are and what you do in the company!\nThanks for your help :)',
        };
    },

    computed: {
        getProgress() {
            let fill = 0;
            switch (this.step) {
            case 1:
                fill = 0;
                break;

            case 2:
                fill = 50;
                break;

            case 3:
                fill = 100;
                break;

            default:
                fill = 0;
                break;
            }
            return { width: `${fill}%` };
        },
    },

    watch: {
        search_param(val) {
            if (val) this.filtered = this.gsuite_users.filter((user) => user.name.fullName.toLowerCase().includes(val.toLowerCase()));
            else this.filtered = this.gsuite_users;
        },
    },

    created() {
        this.companyUserCollection.get();
        this.getGsuiteUsers();
    },

    methods: {
        getGsuiteUsers() {
            this.API.post('company_software/Gsuite/', {
                method: 'getGsuiteUsers',
                company_user_id: this.company_user.id,
            }, {
                200: (data) => {
                    this.gsuite_users = data.gsuite_users;
                    this.filtered = this.gsuite_users;
                    this.gsuite_users.forEach((user) => {
                        if (!this.checkIfAlreadyInvited(user)) {
                            this.selected.push(user);
                        }
                    });
                    this.is_loading = false;
                    this.step = 2;
                },

                404: () => {
                    this.not_found = true;
                    this.is_loading = false;
                },

                onError: () => {
                    // this.$Notifier('App').showError(`A problem occurred when trying to fetch the configurations for ${this.software.ht_software_type.name}!`);
                },
            });
        },

        toggleSelect(user) {
            if (this.checkIfSelected(user)) this.selected = this.selected.filter((item) => item.id !== user.id);
            else this.selected.push(user);
        },

        checkIfSelected(user) {
            return this.selected.filter((item) => item.id == user.id).length > 0;
        },

        checkIfAlreadyInvited(user) {
            return this.company_users.filter((item) => item.professional_email == user.emails[0].address).length > 0;
        },

        toggleAll() {
            if (this.toggle_all) {
                this.selected = [];
                this.toggle_all = false;
            } else {
                this.gsuite_users.forEach((user) => {
                    if (!this.checkIfSelected(user) && !this.checkIfAlreadyInvited(user)) {
                        this.selected.push(user);
                    }
                });
                this.toggle_all = true;
            }
        },

        formatUserData(user) {
            return {
                firstname: user.name.givenName,
                lastname: user.name.familyName,
                email: user.emails[0].address,
                professional_email: user.emails[0].address,
            };
        },

        invite() {
            if (this.saving) this.saving = true;

            const submittedUserCollection = new CompanyUserCollection(
                [
                    'id',
                    'name',
                    'firstname',
                    'lastname',
                    'email',
                    'professional_email',
                    'company_job_position_id',
                ],
            );
            this.selected.forEach((user) => {
                const companyUser = submittedUserCollection.new();
                companyUser.fill(this.formatUserData(user));
                submittedUserCollection.add(companyUser);
            });

            const userSubmittedCount = submittedUserCollection.models.length;
            submittedUserCollection.save({ method: 'storeAll' }).then(() => {
                this.is_saving = false;
                // If one user or more returned, users were added, and some might be already existing
                if (submittedUserCollection.models.length > 1) {
                    this.$Notifier('App')
                        .showInfo(this.translate('Invitations sent to {count} people!', { count: submittedUserCollection.models.length }));
                }
                if (submittedUserCollection.models.length === 1) {
                    this.$Notifier('App')
                        .showInfo(this.translate('Invitation sent to {email}!', { email: submittedUserCollection.models[0].professional_email }));
                }
                // Print the number of failed creating
                if (submittedUserCollection.models.length !== userSubmittedCount) {
                    this.$Notifier('App').showError(this.translate('{count} users could not be invited ! The emails might be already in use', {
                        count: userSubmittedCount - submittedUserCollection.models.length,
                    }));
                }
            });
        },

        getUserImage(user) {
            return user.thumbnailPhotoUrl || '/static/images/member.png';
        },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	.header-light {
		padding: 15px 35px;
		margin-bottom: 7em;

		&.left {
			justify-content: left;
			border-bottom: 1px solid #f3f3f3;
		}
		.logo {
			text-align: left;
			max-width: 200px;
			max-height: 30px;
			img {
				height: 30px;
			}
		}
	}

	.centered {
		margin: 0 auto;
		max-width: 850px;
	}

	.invite-head {
		h1 {
			font-size: 5rem;
			margin-bottom: 0.5em;
		}
	}

	.align-left {
		text-align: left;
	}

	.form fieldset {
		margin-bottom: 0;
	}

	.invite-gsuite {
		.icon-alert {
			height: 75px;
			width: 75px;
		}

		.button {
			margin-top: 3em;
		}
		.reactive-cell.large {
			display: flex;
			width: calc(50% - 1em);
			border: 1px solid $border-grey-light;
			margin: 0.5em;
			border-radius: 5px;
			padding: 0.5em 1em;
			opacity: 0.5;
			cursor: pointer;

			.user-img {
				border-radius: 30%;
				height: 30px;
				width: 30px;
				margin-right: 1em;
			}

			.user-name {
				margin: auto 0;
			}

			.check {
				--color1: #fff;
				--color2: #e4e4e4;
				border: 1px solid #e4e4e4;
				border-radius: 50%;
				margin: auto 0;
				margin-left: auto;
				height: 20px;
				width: 20px;
			}

			&.selected {
				opacity: 1;
				.check {
					--color1: #f95652;
					--color2: #fff;
					border: none;
				}
			}
			&.invited {
				color: #929292;
				background: #f7f7f7;
				opacity: 1;
				.check {
					display: none;
				}
			}
		}

		.gsuite-search {
			position: relative;
			display: inline-flex;
			width: 100%;
			margin: 1em 0;

			.toggle {
				position: absolute;
				display: inline-flex;
				cursor: pointer;
				top: 15px;
				right: 10px;

				span:first-child {
					margin-right: 0.5em;
				}
			}

			.icon-glass {
				position: absolute;
				top: 15px;
				left: 10px;
			}

			input {
				margin: auto;
				font-family: lato;
				font-size: 1em;
				padding-left: 2.25em;
			}
		}

	}

	.gsuite-message {
		margin-top: 3em;
	}

	.user-list {
	    display: flex;
	    flex-wrap: wrap;
	}
</style>
