export default {
    props: {
        name: {
            type: String,
            required: true,
        },

    },

    data() {
        return {
            notification: [],
            silent: false,
        };
    },

    created() {
        // Register
        this.$Notifier(this.name, this);
    },

    methods: {
        showInfo(message, timer = 5000) {
            this.notification.push({
                type: 'info',
                message,
                visible: true,
            });
            setTimeout(() => {
                this.notification[0].visible = false;
                // Remove old notification
                setTimeout(() => {
                    this.notification.shift();
                }, 1000);
            }, timer);
        },

        showWarning(message, timer = 5000) {
            this.notification.push({
                type: 'warning',
                message,
                visible: true,
                // timeout:5000,
            });
            setTimeout(() => {
                this.notification[0].visible = false;
                // Remove old notification
                setTimeout(() => {
                    this.notification.shift();
                }, 1000);
            }, timer);
        },

        showError(message, timer = 5000) {
            this.notification.push({
                type: 'error',
                message,
                visible: true,
            });
            setTimeout(() => {
                this.notification[0].visible = false;
                // Remove old notification
                setTimeout(() => {
                    this.notification.shift();
                }, 1000);
            }, timer);
        },

        showNotif(message, title = null, timer = 5000) {
            this.notification.push({
                type: 'notification',
                title,
                message,
                visible: true,
            });
            setTimeout(() => {
                this.notification[0].visible = false;
                // Remove old notification
                setTimeout(() => {
                    this.notification.shift();
                }, 1000);
            }, timer);
        },

        silence(timeout = 5000) {
            this.silent = true;
            setTimeout(() => {
                this.unSilence();
            }, timeout);
        },
        unSilence() {
            this.silent = false;
        },
    },
};
