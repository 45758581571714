import api from '@/store/api';

export default {
    props: {
        bulk: {
            type: Object,
            required: true,
        },

        hasPrev: {
            type: Boolean,
            default: () => false,
        },

        hasNext: {
            type: Boolean,
            default: () => false,
        },

        isReadOnly: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            loading: false,
            hasSelectedItems: false,
            searchInputUser: null,
            cellErrors: {},
        };
    },

    methods: {
        deleteSelection() {
            this.genericConfirm(
                this.translate('Confirmation needed'),
                this.translate("You're about to delete theses users permanently."),
            ).then(() => {
                const ids = this.$refs.usersTable.selectedItems.map((i) => i.id);

                api
                    .user
                    .enrolling
                    .bulk
                    .removeUsers(this.bulk.id, {
                        params: {
                            bulk_items_id: ids,
                        },
                    })
                    .then(() => {
                        this.$refs.usersTable.loadItems(1);
                    });
            });
        },
    },

    computed: {
        hasCellErrors() {
            return Object.keys(this.cellErrors).length > 0;
        },

        allItemsAreSelected() {
            return this.$refs.usersTable.selectedItems.length === this.$refs.usersTable.items.length;
        },
    },
};
