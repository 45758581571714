<template>
    <HtLayoutModal @close="$emit('close')">
        <template #header>
            <t>Edit personal information</t>
        </template>

        <template #main>
            <form
                class="form-2 spaced"
                @submit.prevent
            >
                <HtFormInput
                    id="firstname"
                    v-model="selectedUser.firstname"
                    v-validate.disable="'required|max:64'"
                    cypress="firstname"
                    name="firstname"
                    :placeholder="translate('Firstname')"
                    :label="translate('Firstname')"
                    :is-required="true"
                    :show-asterisk="true"
                    :max-length="64"
                    :data-vv-as="translate('firstname')"
                />

                <HtFormInput
                    id="lastname"
                    v-model="selectedUser.lastname"
                    v-validate.disable="'required|max:64'"
                    cypress="lastname"
                    name="lastname"
                    :placeholder="translate('Lastname')"
                    :label="translate('Lastname')"
                    :is-required="true"
                    :show-asterisk="true"
                    :max-length="64"
                    :data-vv-as="translate('lastname')"
                />

                <HtFormSelect
                    id="user-gender"
                    v-model="selectedUser.gender"
                    :label="translate('Gender')"
                    :is-required="true"
                    :show-optional="false"
                    :show-asterisk="true"
                    name="user-gender"
                    :options="genderOptions"
                    cypress="user-gender"
                    :data-vv-as="translate('gender')"
                />

                <HtFormInput
                    id="professional-email"
                    v-model="selectedUser.professional_email"
                    v-validate.disable="`${isProgramWithProfessionalEmailRequired && !isProfessionalEmailDisabled ? 'required' : '' }|email|unique_email:${selectedUser.id}`"
                    cypress="professional-email"
                    name="professional-email"
                    :placeholder="translate('Professional e-mail address')"
                    :label="translate('Professional e-mail address')"
                    :is-required="isProgramWithProfessionalEmailRequired && !isProfessionalEmailDisabled"
                    :show-asterisk="isProgramWithProfessionalEmailRequired && !isProfessionalEmailDisabled"
                    :max-length="255"
                    :tooltip="tooltipProfessionalEmail"
                    :data-vv-as="translate('professional email')"
                />

                <HtFormSwitch
                    v-if="!isProgramWithPersonalEmailRequired && !user.professional_email"
                    :id="'disable-professional-email'"
                    v-model="isProfessionalEmailDisabled"
                    :label="translate('Professional email is not used for this employee')"
                    :description="translate('The email used for communication will be the personal email')"
                    :name="'disable-professional-email'"
                    :show-optional="false"
                />

                <HtFormInput
                    id="personal-email"
                    v-model="selectedUser.personal_email"
                    v-validate.disable="`${isProgramWithPersonalEmailRequired || isProfessionalEmailDisabled ? 'required': ''}|email|unique_email:${selectedUser.id}`"
                    cypress="personal-email"
                    name="personal-email"
                    :placeholder="translate('Personal e-mail address')"
                    :label="translate('Personal e-mail address')"
                    :is-required="isProgramWithPersonalEmailRequired || isProfessionalEmailDisabled"
                    :show-asterisk="isProgramWithPersonalEmailRequired || isProfessionalEmailDisabled"
                    :max-length="255"
                    :tooltip="tooltipPersonalEmail"
                    :data-vv-as="translate('personal email')"
                />

                <PhoneNumberInput
                    :label="translate('Phone number')"
                    :company-user="selectedUser"
                    :error-append="apiErrors?.phone_number ? apiErrors.phone_number[0] : null"
                />

                <HtLanguageSelect
                    id="language"
                    cypress="language"
                    name="language"
                    :value="shared.session.company.company_languages.enabled.find(language => language.id === selectedUser.company_language_id)"
                    :custom-option-label="customLanguageOptionLabel"
                    @input="userLanguageChanged"
                />

                <HtTimezoneSelect
                    v-model="selectedUser.timezone"
                    cypress="timezone"
                    class="timezone"
                />
            </form>
        </template>

        <template #footer>
            <HtButton
                type="secondary"
                @click.native="close"
            >
                <t>Cancel</t>
            </HtButton>

            <HtButton
                :loading="loading"
                @click.native="save"
            >
                <t>Save</t>
            </HtButton>
        </template>
    </HtLayoutModal>
</template>

<script>
import api from '@/store/api';
import I18n from '@/modules/i18n/I18n';
import HtLayoutModal from '@/components/globals/modals/layouts/HtLayoutModal.vue';
import HtButton from '@/components/globals/HtButton.vue';
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import HtProgramType from '@/models/HtProgramType';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtLanguageSelect from '@/components/globals/HtLanguageSelect.vue';
import HtTimezoneSelect from '@/components/globals/HtTimezoneSelect.vue';
import PhoneNumberInput from '@/components/globals/inputs/PhoneNumberInput.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import HtFormFlatPickr from '@/components/globals/HtFormFlatPickr.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'BulkEditPersonalInformationModal',

    components: {
        HtFormSwitch,
        HtFormFlatPickr,
        HtFormSelect,
        PhoneNumberInput,
        HtTimezoneSelect,
        HtLanguageSelect,
        HtFormInput,
        HtFormGroup,
        HtButton,
        HtLayoutModal,
    },

    props: {
        bulkId: {
            type: Number,
            required: true,
        },

        program: {
            type: Object,
            required: true,
        },

        userProgramId: {
            type: Number,
            required: true,
        },

        user: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            loading: false,
            selectedUser: { ...this.user },
            isProfessionalEmailDisabled: false,
            apiErrors: {},
            genderOptions: [
                {
                    id: 'male',
                    name: I18n.translate('Male'),
                },
                {
                    id: 'female',
                    name: I18n.translate('Female'),
                },
                {
                    id: 'neutral',
                    name: I18n.translate('Neutral'),
                },
            ],
        };
    },

    computed: {
        isOnboarding() {
            return HtProgramType.SLUG_ONBOARDING === this.program.type.slug;
        },

        tooltipProfessionalEmail() {
            return this.isOnboarding
                ? this.translate('This e-mail address will be used as login and communication way from the date you have chosen in the program.')
                : null;
        },

        tooltipPersonalEmail() {
            return this.isOnboarding
                ? this.translate('This e-mail address will be used as login and communication way as long as the professional one hasn\'t been set and activated.')
                : null;
        },

        isProgramWithPersonalEmailRequired() {
            return [
                HtProgramType.SLUG_ONBOARDING,
                HtProgramType.SLUG_PREBOARDING,
            ].includes(this.program.type.slug);
        },

        isProgramWithProfessionalEmailRequired() {
            return !this.isProgramWithPersonalEmailRequired;
        },
    },

    watch: {
        'selectedUser.professional_email': function (value) {
            if (value.trim !== '') {
                this.isProfessionalEmailDisabled = false;
            }
        },
    },

    methods: {
        customLanguageOptionLabel(language) {
            return language.name;
        },

        userLanguageChanged(language) {
            this.selectedUser.company_language = {
                id: language.id,
                company_id: language.company_id,
                key: language.key,
                name: language.name,
                code: language.code,
                is_default: language.is_default,
                is_enabled: language.is_enabled,
                created_at: language.created_at,
                updated_at: language.updated_at,
            };

            this.selectedUser.company_language_id = language.id;
        },

        close() {
            this.$emit('close');
        },

        save() {
            this.apiErrors = {};
            this.loading = true;

            this.$validator
                .validateAll()
                .then((result) => {
                    if (!result) {
                        this.loading = false;
                        return;
                    }

                    api
                        .user
                        .enrolling
                        .bulk
                        .updateUserPersonalInformation(this.bulkId, {
                            user_program_id: this.userProgramId,
                            user_data: this.selectedUser,
                        })
                        .then(() => {
                            this.$emit('close', { name: 'on-update', value: this.selectedUser });
                        })
                        .catch(({ response }) => {
                            if (response.status === 422) {
                                this.apiErrors = response.data.errors;
                            }

                            this.loading = false;
                        });
                });
        },
    },
};
</script>
