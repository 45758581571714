import SurveyResults from '@/router/pages/SurveyResults.vue';
import SurveyResultDetail from '@/router/pages/surveyResults/SurveyResultDetail.vue';

export default [
    {
        path: 'SurveyResults',
        name: 'SurveyResults',
        component: SurveyResults,
        meta: {
            permission: true,
        },
    },
    {
        path: 'SurveyResults/:surveyId',
        name: 'SurveyResultDetail',
        component: SurveyResultDetail,
        props: (route) => ({ surveyId: Number(route.params.surveyId) }),
        meta: {
            permission: true,
        },
    },
];
