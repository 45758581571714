<template>
    <ProgramList
        :title="title"
        :subtitle="translate('Enrollees linked to your perimeter.')"
        type="draft"
        :program-type="programType"
        :company-program="companyProgram"
        @pagination-refreshed="count = $event.total"
    />
</template>

<script>
import ProgramList from '@/components/pages/program/lists/ProgramList.vue';

export default {
    name: 'ProgramDetailDraft',
    components: {
        ProgramList,
    },

    props: {
        programType: {
            type: String,
            default: '',
        },
        companyProgram: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data() {
        return {
            count: null,
        };
    },
    computed: {
        title() {
            let programName = '';
            if (this.companyProgram?.name_translated) {
                programName = `${this.companyProgram.name_translated} : `;
            }

            return `${programName}${this.translate('Draft programs | {count} draft program | {count} draft program', { count: this.count })}`;
        },
    },
};
</script>
