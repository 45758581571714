<template>
    <div v-if="loaded">
        <HoldingDataList
            :companies-options="companiesOptions"
            :selected-company-id="selectedCompanyId"
            :count-admin="isCompanyHolding"
            :buttons="buttons"
            @select-company="onSelectCompany"
            @onButtonClick="onButtonClick"
        >
            <UsersList
                :roles-options="rolesOptions"
                :departments-options="departmentsOptions"
                :offices-options="officesOptions"
                :company-id="selectedCompanyId"
                @on-user-clicked="onUserClicked"
            />
        </HoldingDataList>
        <EditUserModal
            ref="modal"
            :company-id="selectedCompanyId"
            @user-deleted="onUserDeleted"
            @user-created="onCreateUser"
            @user-updated="onUpdateUser"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import HoldingDataList from '@/components/globals/HoldingDataList.vue';
import UsersList from '@/usersList/UsersList.vue';
import EditUserModal from '@/components/holding/modals/users/EditUserModal.vue';
import htTablePaginationEventBus from '@/eventBus/htTablePagination';

export default {
    name: 'PageUsersList',
    components: {
        HoldingDataList,
        UsersList,
        EditUserModal,
    },

    data() {
        return {
            selectedCompanyId: Number(this.$route.params.company_id),
            buttons: [
                {
                    id: 'createAdmin',
                    label: this.translate('Add administrator'),
                },
            ],
        };
    },

    computed: {
        ...mapState('companies', [
            'companies',
            'companiesLoaded',
        ]),
        ...mapActions('companies', [
            'clearCompanies',
        ]),
        ...mapState('roles', [
            'roles',
            'rolesLoaded',
        ]),
        ...mapGetters('entities', [
            'offices',
            'departments',
        ]),
        ...mapState('entities', [
            'entitiesLoaded',
        ]),
        companiesOptions() {
            return this.companies.map((company) => ({
                key: company.id,
                label: company.name,
            }));
        },

        rolesOptions() {
            return this.roles
                .filter((role) => role.company_id === this.selectedCompanyId)
                .map((role) => ({
                    id: role.id,
                    name: role.alias,
                }));
        },

        officesOptions() {
            return this.offices
                .filter((office) => office.company_id === this.selectedCompanyId)
                .map((office) => ({
                    id: office.id,
                    name: office.name,
                }));
        },

        departmentsOptions() {
            return this.departments
                .filter((department) => department.company_id === this.selectedCompanyId)
                .map((department) => ({
                    id: department.id,
                    name: department.name,
                }));
        },

        isCompanyHolding() {
            const currentCompany = this.companies
                .find((company) => company.id === this.selectedCompanyId);
            return currentCompany.is_holding;
        },

        loaded() {
            return this.companiesLoaded && this.rolesLoaded && this.entitiesLoaded;
        },
    },

    created() {
        this.$store.dispatch('roles/fetchRoles');
        this.$store.dispatch('entities/fetchEntities');
        this.$store.dispatch('companies/fetchCompanies');
    },

    methods: {
        /**
         * @param {number} companyId
         * @returns {void}
         */
        onSelectCompany(companyId) {
            this.selectedCompanyId = companyId;
            this.$router.push({
                name: 'HoldingSettingsUsersList',
                params: {
                    company_id: companyId,
                },
            });
        },

        onButtonClick(id) {
            if (id === 'createAdmin') {
                this.$refs.modal.open();
            }
        },

        onUserClicked(user) {
            this.$refs.modal.open(user.id);
        },

        onUserDeleted() {
            this.refreshData();
        },

        onCreateUser() {
            this.refreshData();
        },

        onUpdateUser() {
            this.refreshList();
        },

        async refreshData() {
            this.$store.dispatch('companies/clearCompanies');
            await this.$store.dispatch('companies/fetchCompanies');
            this.refreshList();
        },

        refreshList() {
            htTablePaginationEventBus.$emit('refresh');
        },
    },

};
</script>

<style lang="scss" scoped>
.filters-input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 10px;
}
</style>
