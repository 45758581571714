import Model from '@tony.caron/node-model-proxy/Model';
import CompanyProgramNotificationCollection from './CompanyProgramNotificationCollection';
import CompanyProgramNotificationTemplate from './CompanyProgramNotificationTemplate';
import CompanyProgramNotificationRecipient from './CompanyProgramNotificationRecipient';
import CompanyFile from './CompanyFile';
import CompanyUser from './CompanyUser';

export default class CompanyProgramNotification extends Model {
    modelConfig() {
        return {
            collection: CompanyProgramNotificationCollection,
        };
    }

    modelRelations() {
        return {
            templates: () => this.hasMany(CompanyProgramNotificationTemplate),
            recipients: () => this.hasMany(CompanyProgramNotificationRecipient),
            attachment: () => this.belongsTo(CompanyFile),
            senderUser: () => this.belongsTo(CompanyUser),
        };
    }
}
