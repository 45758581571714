import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';

import CompanyUserSurveyQuestionAnswer from './CompanyUserSurveyQuestionAnswer';

export default class CompanyUserSurveyQuestionAnswerCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserSurveyQuestionAnswer,
        };
    }
}
