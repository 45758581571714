import clonedeep from 'lodash.clonedeep';
import Api from './services/Api';

export default class Debuger {
    static API_CALL_STACK = [];

    // Component
    static UUID = {};

    static status = { opened: false, allow_clear: true };

    static openDebuger() {
        Debuger.status.opened = true;
    }

    static closeDebuger() {
        Debuger.status.opened = false;
    }

    static clear() {
        if (!Debuger.status.allow_clear) return;
        Debuger.API_CALL_STACK.splice(0, Debuger.API_CALL_STACK.length);
    }

    static setCurrentApiCall(o) {
        Debuger.API_CALL_STACK.forEach((item) => {
            item.selected = false;
        });

        o.selected = true;

        Debuger.openDebuger();
    }

    static API = {

        addToStack(item) {
            item.selected = false;

            // Blacklist
            if (item.resource == 'company_user/company_user_notification/false') return;

            Debuger.API_CALL_STACK.push(item);

            // From a component
            if (item.caller && item.caller.$options) {
                if (undefined === Debuger.UUID[item.caller._uid]) {
                    Debuger.UUID[item.caller._uid] = [];
                }

                Debuger.UUID[item.caller._uid].push(item);
            }
        },

        select(item) {
            this.unselect();
            item.selected = true;
        },

        unselect(item = null) {
            if (item !== null) item.selected = false;
            else {
                Debuger.API_CALL_STACK.forEach((item) => {
                    item.selected = false;
                });
            }
        },

        searchByApiId(id) {
            for (let j = Debuger.API_CALL_STACK.length - 1; j != 0; j--) {
                if (Debuger.API_CALL_STACK[j].id == id) {
                    return Debuger.API_CALL_STACK[j];
                }
            }
            return false;
        },

        replay(item, auto_select = true) {
            const _ = item.api[item.method](item.resource, item.parameter, item.callBack, item.caller);

            if (auto_select) {
                const itemInStack = this.searchByApiId(_.id);
                if (itemInStack !== false) {
                    this.select(itemInStack);
                }
            }
        },
    };

    static colorString(s, ba = 245, r = 0, g = 0, b = 0) {
        s = s.replace('lookable/', '');
        const tmp = s.split('/');
        const _return = [];

        const getHashCode = (str, ba, r, g, b) => {
            // TODO: adjust base colour values below based on theme

            const base = ba;
		    const baseRed = r + base;
		    const baseGreen = g + base;
		    const baseBlue = b + base;

		    // lazy seeded random hack to get values from 0 - 256
		    // for seed just take bitwise XOR of first two chars
		    // let seed = str.charCodeAt(0) ^ str.charCodeAt(1);
		    let seed = (str.charCodeAt(0) ^ str.charCodeAt(1)) ^ str.charCodeAt(str.length - 1);
		    let hash = 0;
		    for (let i = 0; i < str.length; i++) {
		        hash = str.charCodeAt(i) + ((hash << 5) - hash);
		    }
		    seed = hash;
		    const rand_1 = Math.abs((Math.sin(seed++) * 10000)) % 256;
		    const rand_2 = Math.abs((Math.sin(seed++) * 10000)) % 256;
		    const rand_3 = Math.abs((Math.sin(seed++) * 10000)) % 256;

		    // build colour
		    const red = Math.round((rand_1 + baseRed) / 2);
		    const green = Math.round((rand_2 + baseGreen) / 2);
		    const blue = Math.round((rand_3 + baseBlue) / 2);

		    return `rgb(${red},${green},${blue})`;
        };

        for (let i = 0; i < tmp.length; i++) {
            _return.push({ value: tmp[i], color: getHashCode(tmp[i], ba, r, g, b) });
        }

        return _return;
    }

    static install(Vue) {
        Vue.prototype.$Debuger = Debuger;
        Debuger.Vue = Vue;

        const api = Api.getInstance('default');
        api.interceptCall((data) => {
            // gros hack pour savoir quand la réponse arrive et la cloner à ce moment la
            let x = {};
            Object.defineProperty(data.response, 'response', {
                set(n) {
                    x = clonedeep(n, true);
                },
                get() {
                    return x;
                },
            });

            Debuger.API.addToStack(data);
        });

        Vue.mixin({
            destroyed() {
                // remove UUID
                if (undefined !== Debuger.UUID[this._uid]) delete Debuger.UUID[this._uid];
            },

            mounted() {
                // do not inject himself
                if (this.$options.name == 'debug-in-component') return;
                if (this.$options.name == 'DebugJson') return;
                if (this.$options.name == 'DebugBar') return;
                if (this.$options.name == 'transition') return;
                if (this.$options.name == 'notification') return;
                if (this.$options.name == 'i18n-component') return;
                if (this.$options.name == 'router-link') return;
                if (this.$options._componentTag == 'transition-group') return;

                if (this.$options.name == 'modalable') return;
                // eslint-disable-next-line no-useless-return
                if (this.$options.name == 'notifier') return;
            },
        });
    }
}
