var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-onboarding-probation"},[(_vm.isLoaded)?_c('div',[_c('FilterableSearch',{ref:"filterSearch",attrs:{"prop-keys":[
                'firstname',
                'lastname',
                'end_date',
                'arrival_date',
                'first_probation',
                'second_probation'
            ]},model:{value:(_vm.componentItems),callback:function ($$v) {_vm.componentItems=$$v},expression:"componentItems"}}),_c('HtTable',{staticClass:"table-company-onboarding-probation",attrs:{"columns":_vm.columns,"items":_vm.componentItems,"empty-message":'There is no Users on Probation period found!'},on:{"onCellClicked":_vm.goToProfile},scopedSlots:_vm._u([{key:"cell(fullname)",fn:function(scope){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{directives:[{name:"user-image",rawName:"v-user-image",value:({
                            image: scope.item.image,
                            firstname: scope.item.firstname,
                            lastname: scope.item.lastname
                        }),expression:"{\n                            image: scope.item.image,\n                            firstname: scope.item.firstname,\n                            lastname: scope.item.lastname\n                        }"}],staticClass:"user-image mr-3"}),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(scope.item.firstname)+" "+_vm._s(scope.item.lastname)+" ")]),(scope.item.company_office)?_c('span',[_vm._v(" "+_vm._s(scope.item.company_office.name)+", "+_vm._s(scope.item.company_office.city)+" ")]):_vm._e()])])]}},{key:"cell(probationFinalEndDate)",fn:function(scope){return [_c('div',{staticClass:"table-visible-mobile font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$Utils.mysqlToDate(scope.item.probationFinalEndDate, 'DD MMMM YYYY'))+" ")])]}},{key:"cell(arrival_date)",fn:function(scope){return [_c('div',{staticClass:"table-hide-mobile",style:(scope.size)},[_vm._v(" "+_vm._s(_vm.$Utils.mysqlToDate(scope.item.arrival_date, 'DD MMMM'))+" ")])]}},{key:"cell(duration)",fn:function(scope){return [_c('div',{staticClass:"table-hide-mobile",style:(scope.size)},[_c('t',{attrs:{"count":scope.item.first_probation}},[_vm._v(" {count} "+_vm._s(scope.item.first_probation_unit)+" | {count} "+_vm._s(scope.item.first_probation_unit)+"s ")])],1)]}},{key:"cell(second_probation)",fn:function(scope){return [_c('div',{staticClass:"table-hide-mobile",style:(scope.size)},[(!scope.item.second_probation)?_c('t',[_vm._v(" No ")]):_c('span',[_c('t',[_vm._v("Yes")]),_vm._v(" ("),_c('t',{attrs:{"count":scope.item.second_probation}},[_vm._v("{count} "+_vm._s(scope.item.second_probation_unit)+" | {count} "+_vm._s(scope.item.second_probation_unit)+"s")]),_vm._v(") ")],1)],1)]}}],null,false,2895159133)})],1):_c('IconLoading')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }