<template>
    <HtFormGroup
        :label="label"
        :error="error"
        :is-required="isRequired"
    >
        <!-- On n'affiche le selecteur des variables uniquement si le formulaire n'est pas disabled -->
        <HtFormVariables
            v-if="variables.length > 0 && !disabled && !isEditorDisabled"
            :variables="variables"
            :cypress="cypress + '-variables'"
            @addVariable="addVariable"
        />
        <Editor
            ref="editor"
            class="ht-form-editor"
            :value="value"
            :init="init"
            :disabled="isEditorDisabled || disabled"
            :class="{'no-toolbar': !hasToolbar}"
            :data-cy="cypress"
            @input="$emit('input', $event)"
        />

        <AIForm
            ref="aiForm"
            :settings="{
                hasMaxContentMenu: false,
                hasToggleClearContent: false,
                showFormatOptions: hasContent,
            }"
            :suggestions="suggestions"
            :format-options="formatOptions"
            class="ai-form"
            :is-loading="isAILoading"
            @onAskAI="askAI"
            @onToneChange="onToneChange"
            @onSuggestionClick="onSuggestionClick"
            @onFormatOptionClick="onFormatOptionClick"
        />

        <div
            v-if="hasExtraSlot"
            class="mt-3"
        >
            <slot name="extra" />
        </div>
    </HtFormGroup>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import DefaultEditorMixin from '@/components/mixins/DefaultEditorMixin';
import AIMailService from '@/services/AIMailService';
import AIForm from '@/components/globals/AI/AIForm.vue';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';
import UnauthorizedError from '@/services/Errors/UnauthorizedError';
import HtFormMixin from './HtFormMixin';
import HtFormVariables from './HtFormVariables.vue';

export default {
    components: {
        AIForm,
        Editor,
        HtFormVariables,
    },
    mixins: [
        HtFormMixin,
        DefaultEditorMixin,
    ],

    props: {
        value: {
            type: String,
            default: () => ' ',
        },
        height: {
            type: Number,
            default: () => 300,
        },
        hasToolbar: {
            type: Boolean,
            default: true,
        },
        aiContentType: {
            type: String,
            default: () => 'mail',
        },
        suggestions: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            isAILoading: false,
            aiInputHistory: [],
            formatOptions: [
                { value: this.translate('Extend'), icon: ['fas', 'expand-alt'] },
                { value: this.translate('Shorten'), icon: ['fas', 'compress-alt'] },
                { value: this.translate('Correct Mistakes'), icon: ['fas', 'spell-check'] },
                { value: this.translate('Simplify Phrasing'), icon: ['fas', 'broom'] },
                { value: this.translate('Improve Style'), icon: ['fas', 'magic'] },
            ],
        };
    },

    computed: {
        hasContent() {
            return this.value.trim() !== '';
        },
        hasExtraSlot() {
            return 'extra' in this.$slots;
        },
        isEditorDisabled() {
            return this.isAILoading === true;
        },
    },

    methods: {
        hasContentSelected() {
            const { editor } = this.$refs.editor;

            editor.focus();

            const node = editor.selection.getContent();

            return node.trim() !== '';
        },
        clearContent() {
            this.$emit('input', '');
        },
        addVariable(variable) {
            const { editor } = this.$refs.editor;

            editor.execCommand('mceInsertContent', false, variable);
        },
        replaceSelectedContent(value) {
            const { editor } = this.$refs.editor;

            const bookmark = editor.selection.getBookmark();

            editor.selection.moveToBookmark(bookmark);

            editor.execCommand('mceReplaceContent', false, value);
        },
        processAIResponse(newContent, hasContentSelected) {
            if (hasContentSelected) {
                this.replaceSelectedContent(newContent);
            } else {
                this.$emit('input', this.value + newContent);
            }
        },

        prepareAIInput(currentInput) {
            let input = currentInput;

            if (this.hasContentSelected()) {
                const { editor } = this.$refs.editor;

                input += `\n\n${editor.selection.getContent()}`;
                input = input.replace(/<br\s*\/?>/g, '\n');
                input = input.replace(/<p><\/p>/g, '\n');
            } else if (this.hasContent) {
                input += `\n\n${this.value}`;
                input = input.replace(/<br\s*\/?>/g, '\n');
                input = input.replace(/<p><\/p>/g, '\n');

                this.clearContent();
            }

            return input;
        },

        async onToneChange() {
            if (this.hasContent) {
                await this.$refs.aiForm.askAI();
            }
        },

        async onFormatOptionClick() {
            if (this.hasContent) {
                await this.$refs.aiForm.askAI();
            }
        },

        async onSuggestionClick() {
            if (this.hasContent) {
                await this.clearContent();
            }

            await this.$refs.aiForm.askAI();
        },

        async askAI(params) {
            this.isAILoading = true;

            const resourceType = this.aiContentType;

            const hasContentSelected = this.hasContentSelected();

            try {
                const aiService = new AIMailService(this.$env, RtwSharedSpace.store.session.capiToken);

                await aiService.ask(
                    this.prepareAIInput(params.input),
                    resourceType,
                    params.tone,
                    params.languages,
                    hasContentSelected,
                    this.processAIResponse,
                );
            } catch (error) {
                if (error instanceof UnauthorizedError) {
                    this.$Auth.logOut();

                    this.$router.push('/login');

                    return;
                }

                console.error('Error', error);
            } finally {
                this.isAILoading = false;
                this.$refs.aiForm.aiInput = '';
            }

            this.aiInputHistory.unshift(this.aiInput);
        },
    },
};
</script>

<style lang="scss" src="./HtAIFormEditor.scss" scoped></style>
