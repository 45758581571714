import Vue from 'vue';

export default class ModelUtilsPlugin {
    static install(Vue) {
        Vue.prototype.$modelUtils = ModelUtilsPlugin;
    }

    static createCustomFields(model, tableName) {
        if (model.custom_fields === undefined) {
            return;
        }

        const templates = Vue.prototype.$Utils.shared().session.company.custom_field_templates.models.filter((t) => t.table_name === tableName);

        for (const template of templates) {
            if (model.custom_fields.models.some((f) => f.company_field_template_id === template.id)) {
                continue;
            }

            const field = model.custom_fields.new();

            field.template = template;
            field.text = template.text;
            field.boolean = template.boolean;
            field.company_field_template_id = template.id;

            model.custom_fields.add(field);
        }
    }

    static createLocales(collection, fields = []) {
        if (collection === undefined) {
            return;
        }

        const localesByKey = collection.models.keyBy('language_key');

        const languages = Vue.prototype.$Utils.shared().session.company.company_languages.enabled;

        languages.forEach((language) => {
            if (Object.prototype.hasOwnProperty.call(localesByKey, language.key) === false) {
                const locale = {
                    language_key: language.key,
                };

                fields.forEach((f) => {
                    locale[f] = null;
                });

                collection.models.push(locale);
            }
        });
    }

    static createLocalesByKey(collection) {
        return collection ? collection.models.keyBy('language_key') : {};
    }

    static localize(localesByKey, field, languageKey, defaultLanguageKey) {
        if (Object.keys(localesByKey).length === 0) {
            return null;
        }

        if (languageKey === undefined) {
            if (!Vue.prototype.$Utils.shared().session.companyUser) {
                return null;
            }

            languageKey = Vue.prototype.$Utils.shared().session.companyUser.company_language.key;
        }

        const locale = localesByKey[languageKey];

        if (locale && locale[field] && locale[field].length > 0) {
            return locale[field];
        }

        if (defaultLanguageKey === undefined) {
            defaultLanguageKey = Vue.prototype.$Utils.shared().session.company.company_language.key;
        }

        const defaultLocale = localesByKey[defaultLanguageKey];

        if (defaultLocale && defaultLocale[field] && defaultLocale[field].length > 0) {
            return defaultLocale[field];
        }

        if (defaultLocale === undefined || defaultLocale[field] === null) {
            console.warn(`The field "${field}" is not available in the default language`);
        }

        const fallbackLocale = Object.values(localesByKey).find((locale) => locale[field] && locale[field].length > 0);

        return fallbackLocale ? fallbackLocale[field] : null;
    }
}
