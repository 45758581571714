import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import CompanyUserProgram from './CompanyUserProgram';
import CompanySoftware from './CompanySoftware';
import CompanyUserSoftwareCollection from './CompanyUserSoftwareCollection';

export default class CompanyUserSoftware extends Model {
    modelConfig() {
        return {
            controller: 'CompanyUserSoftwareController',
            collection: CompanyUserSoftwareCollection,
        };
    }

    modelDefaultValues() {
        return {
            specific_information: null,
        };
    }

    modelRelations() {
        return {
            companySoftware: () => this.belongsTo(CompanySoftware, 'company_software_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyUserProgramTask: () => this.belongsTo(CompanyUserProgramTask, 'resource').where(['resource', '=', 'company_software']),
            companyUserProgram: () => this.belongsTo(CompanyUserProgram, 'company_user_program_id', 'id'),
        };
    }
}
