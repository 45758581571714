import Model from '@tony.caron/node-model-proxy/Model';
import CompanyRoleUserPerimeter from './CompanyRoleUserPerimeter';
import CompanyRoleUserEmployee from './CompanyRoleUserEmployee';
import CompanyUser from './CompanyUser';
import CompanyRole from './CompanyRole';
import CompanyRoleUserCollection from './CompanyRoleUserCollection';

export default class CompanyRoleUser extends Model {
    modelConfig() {
        return {
            collection: CompanyRoleUserCollection,
        };
    }

    modelRelations() {
        return {
            role: () => this.belongsTo(CompanyRole),
            user: () => this.belongsTo(CompanyUser),
            employees: () => this.hasMany(CompanyRoleUserEmployee, 'company_role_user_id', 'id'),
            perimeters: () => this.hasMany(CompanyRoleUserPerimeter, 'company_role_user_id', 'id'),
        };
    }
}
