import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from '@/models/CompanyUser';
import CompanyRoleUser from '@/models/CompanyRoleUser';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import CompanyRoleUserEmployeeCollection from '@/models/CompanyRoleUserEmployeeCollection';

export default class CompanyRoleUserEmployee extends Model {
    modelConfig() {
        return {
            collection: CompanyRoleUserEmployeeCollection,
        };
    }

    modelRelations() {
        return {
            user: () => this.belongsTo(CompanyUser),
            companyUser: () => this.belongsTo(CompanyUser),
            companyRoleUser: () => this.belongsTo(CompanyRoleUser),
            roleUser: () => this.belongsTo(CompanyRoleUser),
            programs: () => this.hasMany(CompanyUserProgram, 'company_user_id', 'company_user_id'),
        };
    }
}
