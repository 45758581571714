import I18n from './modules/i18n/I18n';

export default {
    install(Vue) {
        Vue.mixin({
            filters: {
                empty(value) {
                    if (!value) return '-';
                    return value;
                },
                boolean(value) {
                    if (value === null) {
                        return '-';
                    }

                    return value ? I18n.translate('Yes') : I18n.translate('No');
                },
            },
        });
    },
};
