<template>
    <div
        :class="{'ht-link': true, 'ht-link-clickable': isClickable}"
        @click="isClickable ? $emit('click') : null"
    >
        <div class="ht-link-start">
            <div
                :class="{
                    'ht-link-icon': true,
                    'ht-link-icon-excel': type === 'csv'
                }"
            >
                <FontAwesomeIcon
                    v-if="type === 'csv'"
                    :icon="['fal', 'file-excel']"
                />
                <FontAwesomeIcon
                    v-else
                    :icon="['fal', 'file']"
                />
            </div>
            <div class="ht-link-title">
                {{ title }}
            </div>
        </div>
        <div class="ht-link-end">
            <FontAwesomeIcon :icon="icon" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'HtLink',
    props: {
        title: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
        },
        isClickable: {
            type: Boolean,
            default: true,
        },
        icon: {
            type: Array,
            default: () => ['far', 'eye'],
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/styles/var';
.ht-link {
  display: flex;
  justify-content: space-between;
  border: 0.1rem solid $neutral-500;
  padding: 1rem 1.6rem;
  border-radius: 5px;
  color: $neutral-800;
  user-select: none;

  &.ht-link-clickable {
    cursor: pointer;
  }
}

.ht-link-start {
  display: flex;
  gap: 1rem;
}

.ht-link-icon-excel {
  color: #47783f;
}

.ht-link-end {
  color: $neutral-800;
}
</style>
