<template>
    <div>
        <h2 class="foc-title branding-color">
            <t>{{ title }}</t>
        </h2>
        <HtCard class="mb-5">
            <template #default>
                <slot />
            </template>
        </HtCard>
    </div>
</template>
<script>

export default {
    name: 'FocBlock',
    inject: ['$validator'],
    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.foc-title {
    margin-top: 0;
    margin-bottom: 1.5rem;
}
</style>
