<template>
    <InputWrapper
        :can-delete="canDelete"
        :editing="field.editing"
        :title="field.title"
        :role-id="field.completer_id"
        @delete="$emit('delete')"
        @setTitle="setTitle"
        @setRole="setRole"
    >
        <div :title="translate('This field will be automatically filled with the user’s information')">
            <span
                :style="{
                    fontSize: field.font_ratio * 100 + '%',
                    display: 'flex',
                    cursor: 'default'
                }"
            >
                <t v-if="!('parsed_value' in field)">{{ $Utils.capitalize(field.title) }}</t>
                <template v-else>
                    {{ field.parsed_value }}
                </template>
            </span>
        </div>
    </InputWrapper>
</template>

<script>
import HtInputText from '@/components/globals/HtInputText.vue';
import InputActionMixin from '@/components/DocumentEditor/mixins/InputActionMixin';
import InputWrapper from '@/components/DocumentEditor/Inputs/InputWrapper.vue';

export default {
    name: 'VariableField',
    components: {
        HtInputText, InputWrapper,
    },
    mixins: [InputActionMixin],
    props: {
        field: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            value: null,
        };
    },
    methods: {
        setTitle(v) {
            this.$emit('setTitle', v);
        },
        setRole(roleId) {
            this.$emit('setRole', roleId);
        },
    },
};
</script>

<style scoped lang="scss">
</style>
