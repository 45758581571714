<template>
    <div class="wrapper">
        <div
            v-if="questionResults.answers.length === 0"
            class="results"
        >
            -
        </div>
        <component
            :is="component"
            class="results"
            :answers="answers"
            @click-requirement-result="onClickResult"
        />
        <Button
            v-if="showSeeMore"
            :state="buttonState"
            class="see-more"
            @click="onClickButton"
        >
            <t>See More</t>
        </Button>
        <UserRequirementCategoryEditModal
            ref="modal"
            :mode-edit="false"
        />
    </div>
</template>

<script>
import Button from '@/components/globals/Button.vue';
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import UserRequirementCategoryEditModal from '@/components/pages/onboarding/UserRequirementCategoryEditModal.vue';
import ResultsText from './Text/ResultsText.vue';
import ResultsRequirement from './Requirement/ResultsRequirement.vue';
import ResultsFileUpload from './FileUpload/ResultsFileUpload.vue';

export default {
    name: 'FreeAnswerResults',
    components: { Button, UserRequirementCategoryEditModal },
    props: {
        questionResults: {
            type: Object,
            required: true,
        },
        isGlobalView: {
            type: Boolean,
            default: false,
        },
        isGlobalUserView: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isFetching: false,
            answers: this.questionResults.answers,
        };
    },

    computed: {
        showSeeMore() {
            if (this.questionResults.number_of_question_done_unskipped <= 5) {
                return false;
            }
            return this.answers.length < this.questionResults.number_of_question_done_unskipped;
        },

        buttonState() {
            return this.isFetching ? 'loading' : 'idle';
        },

        component() {
            switch (this.questionResults.question.type) {
            case CompanySurveyQuestion.TYPE_TEXT:
                return ResultsText;
            case CompanySurveyQuestion.TYPE_REQUIREMENT:
                return ResultsRequirement;
            case CompanySurveyQuestion.TYPE_FILE_UPLOAD:
                return ResultsFileUpload;
            }
        },
    },

    methods: {
        async onClickButton() {
            this.isFetching = true;
            try {
                const response = await this.fetchSingleQuestionResults();
                this.answers = response.data.question_results.answers;
            } finally {
                this.isFetching = false;
            }
        },

        onClickResult(answer) {
            this.$refs.modal.open(answer.user.id, answer.category.id);
        },

        async fetchSingleQuestionResults() {
            return this.$http.get(
                `results/questions/${this.questionResults.question.id}`,
                { params: { limit: this.answers.length + 5 } },
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.see-more {
    margin-top: 1rem;
}
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.results {
    width: 100%;
}
</style>
