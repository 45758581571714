<template>
    <div>
        <form v-if="value">
            <HtFormInput
                id="name"
                ref="nameInput"
                v-model="value.name"
                v-validate.disable="'required|max:128'"
                class="reset-ht-form-bottom-margin"
                name="name"
                :label="translate('Job name')"
                :data-vv-as="translate('job name')"
                :show-optional="false"
            />
            <HtFormInput
                id="description"
                ref="descriptionInput"
                v-model="value.description"
                v-validate.disable="'max:255'"
                class="reset-ht-form-bottom-margin"
                name="description"
                :label="translate('Description')"
                :data-vv-as="translate('description')"
                :show-optional="false"
            />
        </form>

        <EntityModelCustomFieldGroup :value="value" />
    </div>
</template>

<script>
import ParametersBox from '@/components/globals/ParametersBox.vue';
import EntityModelCustomFieldGroup from '../EntityModelCustomFieldGroup.vue';

export default {
    name: 'EditJobPosition',

    components: {
        ParametersBox,
        EntityModelCustomFieldGroup,
    },
    inject: ['$validator'],
    props: {
        /**
         * @type {import('vue').PropOptions<{
         *     id: number,
         *     name: string,
         *     description: string | null,
         *     is_heyteam: boolean,
         *     custom_fields: {
         *         id: number,
         *         company_field_template_id: number,
         *         name: string,
         *         key: string,
         *         type: string,
         *         value: any,
         *         is_editable: boolean
         *     }[]
         * }>}
         */
        value: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
form {
    display: flex;
    flex-direction: column;
}

.ht-form-error {
    margin-top: 0.8rem;
}

.reset-ht-form-bottom-margin + .reset-ht-form-bottom-margin,
.ht-form-error + .reset-ht-form-bottom-margin {
    margin-top: 3.2rem;
}

.reset-ht-form-bottom-margin {
    margin-bottom: 0;
}

.button-container {
    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 0.8rem;

    margin-bottom: 3.2rem;
}
</style>
