<template>
    <div class="page-content">
        <form
            class="form-2 spaced"
            @submit.prevent=""
        >
            <div class="preboarding-section-title">
                <InputField
                    v-model="collectionCopy['bloc_requirement_title']['content']['messages']['message_1'][`message_${lang}`]"
                    :label="translate('Section title')"
                    :type="'text'"
                    :name="'section_title'"
                    :validate="'required'"
                    :validate-as="translate('section title')"
                />
            </div>

            <div class="preboarding-container-text">
                <HtFormSwitch
                    :id="'first-message'"
                    v-model="collectionCopy['bloc_requirement_a']['is_enabled']"
                    :label="`${translate('Account information')}`"
                    :disabled="!collectionCopy['bloc_requirement_a']['is_enabled_editabled']"
                    :name="'first-message'"
                    :show-optional="false"
                />

                <RichEditable
                    key="0"
                    ref="bloc_requirement_a"
                    v-model="collectionCopy['bloc_requirement_a']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="0"
                    :validate="collectionCopy['bloc_requirement_a']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('Account information')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    :resource-name="'preboarding'"
                    :in-high-light-white-list="flowVariablesFull"
                    class="margin-bottom"
                />
            </div>

            <div
                v-if="isChatbotFull"
                class="preboarding-container-text"
            >
                <HtFormSwitch
                    :id="'second-message'"
                    v-model="collectionCopy['bloc_requirement_c']['is_enabled']"
                    :label="`${translate('Administrative requirements')}`"
                    :disabled="!collectionCopy['bloc_requirement_c']['is_enabled_editabled']"
                    :name="'second-message'"
                    :show-optional="false"
                />

                <HtFormSwitch
                    :id="'mandatory-requirement'"
                    v-model="collectionCopy['bloc_requirement_f']['is_enabled']"
                    :label="`${translate('Block the passage to the next step until the mandatory administrative documents are completed')}`"
                    :disabled="!collectionCopy['bloc_requirement_f']['is_enabled_editabled']"
                    :name="'mandatory-requirement'"
                    :show-optional="false"
                />

                <RichEditable
                    key="1"
                    ref="bloc_requirement_c"
                    v-model="collectionCopy['bloc_requirement_c']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="1"
                    :validate="collectionCopy['bloc_requirement_c']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('Administrative requirements')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    :resource-name="'preboarding'"
                    :in-high-light-white-list="flowVariablesFull"
                    class="margin-bottom"
                />
            </div>

            <div class="preboarding-container-text">
                <HtFormSwitch
                    :id="'third-message'"
                    v-model="collectionCopy['bloc_requirement_e']['is_enabled']"
                    :label="`${translate('Conclusion')}`"
                    :disabled="!collectionCopy['bloc_requirement_e']['is_enabled_editabled']"
                    :name="'third-message'"
                    :show-optional="false"
                />

                <RichEditable
                    key="2"
                    ref="bloc_requirement_e"
                    v-model="collectionCopy['bloc_requirement_e']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="2"
                    :validate="collectionCopy['bloc_requirement_e']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('Conclusion')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    :resource-name="'preboarding'"
                    :in-high-light-white-list="flowVariablesFull"
                    class="margin-bottom"
                />
            </div>
        </form>
    </div>
</template>

<script>
import CompanyFlowCollection from '@/models/CompanyFlowCollection';
import FlowHelper from '@/components/mixins/FlowHelper.vue';
import FlowVariable from '@/components/mixins/FlowVariable.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'CustomRequirement',
    components: {
        HtFormSwitch,
    },

    mixins: [FlowHelper, FlowVariable],
    props: {
        chatbotSlug: {
            type: String,
            required: true,
        },
        lang: {
            type: String,
            default: 'fr',
        },
        maxStep: {
            type: Number,
            default: 6,
        },
        step: {
            type: Number,
            default: 6,
        },
        collection: {
            type: CompanyFlowCollection,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
	@import "~@/styles/var";
	@import "~@/styles/customflow";
</style>
