<template>
    <div>
        <div style="width:20%;">
            {{ getQuestionText }}
        </div>
        <div style="width:70%;">
            <component
                :is="component"
                v-if="value.company_user_survey_question_answer.models.length > 0"
                :survey-question="value.company_survey_question"
                :user-survey-question-answer="value.company_user_survey_question_answer"
                :subtype="value.company_survey_question.subtype"
                :question-index="questionIndex"
            />
        </div>
    </div>
</template>

<script>
import CompanyUserSurveyQuestion from '@/models/CompanyUserSurveyQuestion';
import ResultText from './result/ResultText.vue';
import ResultFileUpload from './result/ResultFileUpload.vue';
import ResultYesNo from './result/ResultYesNo.vue';
import ResultSimpleChoice from './result/ResultSimpleChoice.vue';
import ResultMultipleChoice from './result/ResultMultipleChoice.vue';
import ResultSatisfactionScale from './result/ResultSatisfactionScale.vue';

export default {
    name: 'UserSurveyResultListItem',
    props: {
        value: {
            type: CompanyUserSurveyQuestion,
            required: true,
        },
        questionIndex: {
            type: Number,
            required: true,
        },
    },

    computed: {
        getQuestionText() {
            if (this.value.company_survey_question.locales_by_key[this.shared.session.companyUser.company_language.key].text !== null) {
                return this.value.company_survey_question.locales_by_key[this.shared.session.companyUser.company_language.key].text;
            }
            return this.value.company_survey_question.locales_by_key[this.shared.session.company.company_language.key].text;
        },

        component() {
            switch (this.value.company_survey_question.type) {
            case 'text':
                return ResultText;
            case 'file_upload':
                return ResultFileUpload;
            case 'yes_no':
                return ResultYesNo;
            case 'simple_choice':
                return ResultSimpleChoice;
            case 'multiple_choice':
                return ResultMultipleChoice;
            case 'satisfaction_scale':
                return ResultSatisfactionScale;
            }
        },
    },
};
</script>
