<template>
    <RoundEmptyBackground>
        <FontAwesomeIcon
            size="1x"
            icon="check"
        />
    </RoundEmptyBackground>
</template>

<script>
import RoundEmptyBackground from './RoundEmptyBackground.vue';

export default {
    name: 'CheckGreyIcon',
    components: { RoundEmptyBackground },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.wrapper {
    background-color: $white;
    color: $grey;
}
</style>
