<template>
    <transition name="modal-slide">
        <div
            v-if="isVisible"
            class="modal"
        >
            <slot />
            <div class="modal-close">
                <HtIconClose
                    data-cy="exit"
                    @click="close"
                />
            </div>
        </div>
    </transition>
</template>

<script>
import HtIconClose from '@/components/icons/HtIconClose.vue';

export default {
    name: 'ModalableLarge',
    components: {
        HtIconClose,
    },
    provide() {
        return {
            modal: this,
        };
    },

    data() {
        return {
            isVisible: false,
        };
    },
    methods: {
        open() {
            this.isVisible = true;
            this.$emit('on-open');
        },
        close() {
            this.isVisible = false;
            this.$emit('on-close');
        },
    },
};
</script>

<style lang="scss" scoped src="./ModalableLarge.scss" />
