<template>
    <div
        class="ht-checkbox"
        @click.stop="onChange"
    >
        <div :class="{'checked': value === true}" />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onChange() {
            this.$emit('input', !this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.ht-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    background: $white;
    border: 1px solid $border-grey-light;
    border-radius: 4px;
    cursor: pointer;

    .checked {
        width: 8px;
        height: 8px;
        background-color: $primary;
        border-radius: 2px;
    }

    .half {
        width: 8px;
        height : 2px;
        background-color: $primary;
        border-radius: 2px;
    }
}
</style>
