<template>
    <div>
        <HtFormCard class="mb-4">
            <HtFormSwitch
                id="send-sms"
                v-model="item.notifications.models[indexNotif].templates.models[indexTemplate].is_enabled"
                :label="translate('Send sms')"
                name="send-sms"
                :off-label="translate('No')"
                :on-label="translate('Yes')"
                :show-optional="false"
            />
            <div>
                <t>The message will be sent via SMS to collaborators with a phone number filled in on the platform.</t>
            </div>
        </HtFormCard>

        <HtFormCard class="mb-4">
            <HtFormEditor
                :id="'content'"
                v-model="item.notifications.models[indexNotif].templates.models[indexTemplate].locales_by_key[language.key].content"
                v-validate.disable="'required'"
                :name="'content'"
                :label="translate('Sms content')"
                :variables="getVariablesOfField(item.notifications.models[indexNotif].variables, 'content', 'sms')"
                :has-toolbar="false"
            />
        </HtFormCard>
    </div>
</template>
<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import HtFormEditor from '@/components/globals/HtFormEditor.vue';
import CompanyProgram from '@/models/CompanyProgram';
import NotificationVariableMixin from '@/mixins/NotificationVariableMixin';

export default {
    name: 'SmsForm',
    components: { HtFormCard, HtFormEditor, HtFormSwitch },
    mixins: [
        NotificationVariableMixin,
    ],

    props: {
        item: {
            type: CompanyProgram,
            required: true,
        },
        indexNotif: {
            type: Number,
            required: true,
        },
        indexTemplate: {
            type: Number,
            required: true,
        },
        language: {
            type: Object,
            required: true,
        },

    },
    created() {

    },
};
</script>
