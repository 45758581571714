import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import get from 'lodash.get';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import I18n from '../modules/i18n/I18n';

export default class CompanyUserProgramTaskCollection extends ModelCollection {
    static CUSTOM_HANDLE_VALIDATION_BULK = 'handleValidationBulk';

    static CUSTOM_DELETE_BULK = 'deleteBulk';

    static resourceGrouped = [
        'company_requirement_category',
        'company_software',
        'company_equipment',
    ];

    static resourceSorted = {
        company_requirement_category: {
            relation_name: 'company_user_requirement_category',
            ordering_name: 'order',
        },
        company_equipment: {
            relation_name: 'company_user_equipment',
            ordering_name: 'title',
        },
    };

    modelConfig() {
        return {
            model: CompanyUserProgramTask,
        };
    }

    modelCustomAttributes() {
        return {
            company_user_id: null,
            tasksGrouped: [],
        };
    }

    modelAccessors() {
        return {
            tasksGrouped: () => {
                let wrappedResource = [];

                CompanyUserProgramTaskCollection.resourceGrouped.forEach((resource) => {
                    wrappedResource = wrappedResource.concat(this.groupTasksByResource(this.models, resource));
                });

                return this.models.concat(wrappedResource).map((model) => {
                    const dependentId = get(model, 'dependents.models[0].id', null);
                    return {
                        ...model,
                        dependentId,
                    };
                });
            },
        };
    }

    groupTasksByResource(tasks, resource) {
        // FIRST GROUP TASK PER DATE AND RESOURCE
        const resourceTasks = tasks.filter((item) => item.resource === resource).reduce((acc, task) => {
            if (typeof acc === 'undefined') acc = {};

            if (!acc.hasOwnProperty(task.datetime_end)) acc[task.datetime_end] = this.createWrapTask(task);

            if (task.resource in CompanyUserProgramTaskCollection.resourceSorted) {
                const { relation_name } = CompanyUserProgramTaskCollection.resourceSorted[task.resource];

                if (relation_name in task && task[relation_name]
                    && 'is_heyteam' in task[relation_name]
                    && task[relation_name].is_heyteam === true) {
                    task.title = I18n.translate(task.title);
                }
            }

            acc[task.datetime_end].groupedTasks.push(task);
            return acc;
        }, {});

        // THEN FILTER WRAPTASK THAT HAS ONLY ONE TASK AND ADD MISSING COMPUTED DATA TO WRAPTASK
        const wrappedTasks = Object.values(resourceTasks).filter((item) => (item.groupedTasks.length !== 1)).map((item) => {
            const isPending = item.groupedTasks.some((task) => task.status === 'pending');

            const wrappedTaskParticipants = item.groupedTasks.reduce((acc, task) => {
                if (typeof acc === 'undefined') acc = [];

                const participantIds = acc.map((participant) => participant.company_user_id);
                const participants = task.company_user_program_task_participant ? task.company_user_program_task_participant.models.filter((participant) => !participantIds.includes(participant.company_user_id)) : [];
                acc = acc.concat(participants);

                return acc;
            }, []);

            item.company_user_program_task_participant.models = wrappedTaskParticipants;

            item.groupedTasks.forEach((element) => {
                element.is_grouped = true;
            });

            // Some resource is ordered with a column from the relation of resources
            if (item.resource in CompanyUserProgramTaskCollection.resourceSorted
                && Array.isArray(item.groupedTasks)
                && item.groupedTasks.length > 1) {
                const { relation_name } = CompanyUserProgramTaskCollection.resourceSorted[item.resource];
                const { ordering_name } = CompanyUserProgramTaskCollection.resourceSorted[item.resource];

                item.groupedTasks.sort((a, b) => {
                    if (relation_name in a && a[relation_name] && relation_name in b && b[relation_name]) {
                        return (a[relation_name][ordering_name] < b[relation_name][ordering_name]) ? -1 : 0;
                    }
                    return 0;
                });
            }

            item.status = isPending ? 'pending' : 'done';
            return item;
        });

        return wrappedTasks;
    }

    createWrapTask(task) {
        const collection = new CompanyUserProgramTaskCollection([
            'id', 'company_user_program_id', 'company_user_id', 'title',
            'description', 'resource', 'resource_id', 'status',
            'order', 'order_onboarding_start', 'order_onboarding_end', 'order_availability',
            'datetime_start', 'datetime_end', 'datetime_availability', 'period',
        ]).with({
            companyUserProgramTaskParticipant: (query) => {
                query.select(['company_role_id', 'company_user_id'])
                    .with({
                        companyUser: (query) => {
                            query.select(['id', 'firstname', 'lastname', 'image']);
                        },
                        companyRole: (query) => {
                            query.select(['id', 'name']);
                        },
                    });
            },
        });

        const wrapTask = collection.new();

        wrapTask.resource = task.resource;
        wrapTask.title = wrapTask.getWrapTaskTitle();
        wrapTask.datetime_start = task.datetime_start;
        wrapTask.datetime_end = task.datetime_end;
        wrapTask.datetime_availability = task.datetime_availability;
        wrapTask.order_onboarding_start = task.order_onboarding_start;
        wrapTask.order_onboarding_end = task.order_onboarding_end;
        wrapTask.period = task.period;
        wrapTask.is_grouped = true;
        wrapTask.is_editable = 1;

        return wrapTask;
    }
}
