<template>
    <HtCard>
        <template #title>
            <HtCardTitleBar
                :title="translate('Invite users')"
                show-new-close
                @search="setSearchValue"
                @on-close="$router.goBack()"
            >
                <template #default>
                    <HtButton
                        type="secondary"
                        :loading="isLoading"
                        :disabled="isLoading"
                        :loading-label="translate('Processing')"
                        @click.native="openBulkAddingModal"
                    >
                        <FontAwesomeIcon
                            :icon="['fal', 'arrow-to-bottom']"
                            class="mr-2"
                        />
                        <t>Bulk adding</t>
                    </HtButton>
                </template>
            </HtCardTitleBar>
        </template>

        <template #default>
            <div>
                <div
                    v-show="!isLoading"
                    class="row mb-5"
                >
                    <div class="col-md-auto">
                        <button
                            v-show="hasErrors"
                            class="error-button"
                            @click="doWeDisplayErrors = !doWeDisplayErrors"
                        >
                            <t v-show="doWeDisplayErrors">
                                Hide errors
                            </t>
                            <t v-show="!doWeDisplayErrors">
                                Display errors
                            </t>
                        </button>

                        <HtToast
                            v-show="hasErrors && doWeDisplayErrors"
                            type="danger"
                            title=""
                            message=""
                        >
                            <ErrorListing :form-errors="errorsById" />
                        </HtToast>
                    </div>
                </div>

                <IconLoading v-if="isLoading" />
                <section
                    v-else
                    :class="{'table-wrapper': true, 'table-wrapper-empty': users.length === 0}"
                >
                    <HtTableExtended
                        :items="users"
                        :columns="getColumns"
                        :has-quick-actions="false"
                        :external-search="search"
                        front-filter
                        front-sort
                        :cell-errors="getCellErrors"
                        @on-row-clicked="openEditUserModal"
                    >
                        <template #cell(id)="scope">
                            #{{ scope.item.id }}
                        </template>

                        <template #cell(roles)="scope">
                            <div
                                v-if="scope.item.roles"
                                class="roles-wrapper"
                            >
                                <HtRoleImage
                                    v-for="role in scope.item.roles.split(roleDelimiter)"
                                    :key="role"
                                    :role="role"
                                />
                            </div>
                        </template>

                        <template #cell(actions)="scope">
                            <div class="d-flex align-items-center">
                                <HtButton
                                    type="secondary"
                                    @click.native.stop="deleteRow(scope.item)"
                                >
                                    <FontAwesomeIcon
                                        class="mr-2"
                                        :icon="['far', 'trash-alt']"
                                        :title="translate('Delete row')"
                                    />
                                </HtButton>
                            </div>
                        </template>
                    </HtTableExtended>
                </section>

                <div class="mt-4 row">
                    <div class="col-md-auto">
                        <HtButton
                            :left-icon="['fal', 'plus']"
                            type="secondary"
                            :disabled="isLoading"
                            @click.native="addDummyRow"
                        >
                            <t>Add user</t>
                        </HtButton>
                    </div>

                    <div class="col-md-auto">
                        <HtButton
                            v-show="users.length > 0 && !hasErrors"
                            :disabled="isLoading"
                            @click.native="importUsers"
                        >
                            <t>Submit users</t>
                        </HtButton>
                    </div>
                </div>
            </div>
        </template>
    </HtCard>
</template>

<script>
import HtTableExtended from '@/components/globals/HtTable/HtTableExtended.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import ModalMixin from '@/components/globals/modals/modalMixin';
import BulkAddingModal from '@/components/settings/BulkAddingModal.vue';
import HtButton from '@/components/globals/HtButton.vue';
import EditUserModal from '@/components/settings/EditUserModal.vue';
import {
    addDummyUser, importData, preImportFromCsvFile, preImportFromData,
} from '@/store/api/user/import';
import HtToast from '@/components/globals/HtToast.vue';
import HtRoleImage from '@/components/globals/HtRoleImage.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import ErrorListing from '@/components/settings/ErrorListing.vue';
import CompanyRole from '@/models/CompanyRole';
import HtCard from '@/components/globals/HtCard.vue';
import HtIconClose from '@/components/icons/HtIconClose.vue';
import ExportActionsForm from '@/components/pages/settings/dataAnalysis/ExportActionsForm.vue';
import ExportUsersForm from '@/components/pages/settings/dataAnalysis/ExportUsersForm.vue';
import HtTabs from '@/components/globals/HtTabs.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';

export default {
    name: 'InviteManuallyUser',
    components: {
        HtCardTitleBar,
        HtTabs,
        ExportUsersForm,
        ExportActionsForm,
        HtIconClose,
        HtCard,
        ErrorListing,
        IconLoading,
        HtRoleImage,
        HtToast,
        HtButton,
        HtSearchField,
        HtTableExtended,
    },
    mixins: [ModalMixin],
    data() {
        return {
            isLoading: false,
            users: [],
            errorsById: {},
            search: null,
            doWeDisplayErrors: false,
            shouldAddCodeColumn: false,
        };
    },
    computed: {
        roleDelimiter() {
            return CompanyRole.ROLE_DELILMITER_IN_CSV;
        },
        hasErrors() {
            return Object.keys(this.errorsById).length > 0;
        },
        getColumns() {
            const columns = [
                { label: 'Index', key: 'id', search: true },
                { label: this.translate('Lastname'), key: 'lastname', search: true },
                { label: this.translate('Firstname'), key: 'firstname', search: true },
                { label: this.translate('Email'), key: 'email', search: true },
                { label: this.translate('Office'), key: 'office', search: true },
                { label: this.translate('Job position'), key: 'job_position', search: true },
                { label: this.translate('Roles'), key: 'roles', search: true },
                { label: this.translate('Actions'), key: 'actions', sortable: false },
            ];

            if (this.shouldAddCodeColumn) {
                return [
                    ...columns.slice(0, 7),
                    { label: this.translate('Code'), key: 'code', search: true },
                    ...columns.slice(7),
                ];
            }

            return columns;
        },
        getCellErrors() {
            const cellErrors = {};

            Object.keys(this.errorsById).forEach(
                (id) => { cellErrors[id.substring(1)] = Object.keys(this.errorsById[id]); },
            );

            return cellErrors;
        },
    },
    created() {
        this.$store.dispatch('entities/forceFetchEntities');
        this.addDummyRow();
    },
    methods: {
        setSearchValue(value) {
            this.search = value;
        },
        async addDummyRow() {
            await this.treatPreImportPromise(() => addDummyUser(this.users));
        },
        async deleteRow(userToDelete) {
            this.genericConfirm(
                this.translate('Confirmation needed'),
                this.translate('Are you sure to delete: {user}?', { user: `${userToDelete.firstname} ${userToDelete.lastname}` }),
                null,
                'OK!',
            ).then(async () => {
                const usersToSend = this.users.filter((user) => user.id !== userToDelete.id);
                await this.treatPreImportPromise(() => preImportFromData(usersToSend));
            }).catch(() => {});
        },
        openEditUserModal({ item }) {
            const modalProps = {
                user: item,
                initialFormErrorsFromServer: this.errorsById[`_${item.id}`] || {},
                title: `${this.translate('Edit user')}: #${item.id} ${item.firstname} ${item.lastname}`,
                shouldAddCodeColumn: this.shouldAddCodeColumn,
            };

            this.openCenterModal(EditUserModal, modalProps, {
                'before-close': async (event) => {
                    if (event.params?.name === 'submit') {
                        const userIdToUpdate = event.params.value.id;

                        const userToUpdate = {
                            ...(event.params.value),
                            roles: Array.isArray(event.params.value.roles) ? event.params.value.roles.join(CompanyRole.ROLE_DELILMITER_IN_CSV) : '',
                        };

                        const usersToSend = this.users.map(
                            (user) => (user.id === userIdToUpdate ? userToUpdate : user),
                        );

                        await this.treatPreImportPromise(() => preImportFromData(usersToSend));
                    }
                },
            });
        },
        openBulkAddingModal() {
            this.openCenterModal(BulkAddingModal, {}, {
                'before-close': async (event) => {
                    if (event.params?.name === 'submit') {
                        this.shouldAddCodeColumn = event.params.shouldAddCodeColumn;
                        const csvFileId = event.params.value.id;
                        await this.treatPreImportPromise(() => preImportFromCsvFile(csvFileId));
                    }
                },
            });
        },
        async treatPreImportPromise(callback) {
            this.isLoading = true;

            try {
                const { data: responseData } = await callback();
                this.setDataFromResponse(responseData);
            } catch ({ response }) {
                const responseData = response.data;
                this.setDataFromResponse(responseData);

                if (responseData.exception_message !== null) {
                    this.$Notifier('App').showError(responseData.exception_message);
                }
            }

            this.isLoading = false;
        },
        setDataFromResponse(responseData) {
            this.users = responseData.data.map((userData, index) => ({
                ...userData,
                id: index, // id field is required to properly display errors
            }));

            // this ternary is present because that's difficult to force (easily) object in back sourcecode
            const validationErrors = Array.isArray(responseData.validation_errors) ? {} : responseData.validation_errors;

            if (!this.shouldAddCodeColumn) {
                Object.keys(validationErrors).forEach(
                    (errorKey) => { delete validationErrors[errorKey].code; },
                );
            }

            this.errorsById = validationErrors;
        },
        async importUsers() {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;

            await importData(this.users)
                .then(() => {
                    this.$Notifier('App').showInfo(this.translate('{count} users successfully imported!', { count: this.users.length }));
                    this.users = [];
                    this.errorsById = {};
                    this.$router.push({ name: 'SettingsUsers' });
                })
                .catch(({ response }) => {
                    const responseData = response.data;
                    this.setDataFromResponse(responseData);

                    if (responseData.exception_message !== null) {
                        this.$Notifier('App').showError(responseData.exception_message);
                    }
                });

            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.table-wrapper {
  border-left: 1px solid #EFEFEF; border-right: 1px solid #EFEFEF;
}
.table-wrapper-empty {
  border-width: 0;
}
.roles-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}
.error-button {
  color: $dashboard-blue;
  background-color: transparent;
  padding: 0;
  margin-bottom: 0.75rem;
}
</style>
