<template>
    <div class="box-row">
        <div class="box-column two-thirds">
            <UserProgramItem
                ref="userProgram"
                :from="'cop'"
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                :can-multi-select="true"
                :program="program"
                @on-task-collection-fetched="companyUserProgramTaskCollection = $event"
            />
        </div>

        <div class="box-column one-third onboarding-tasks-selected">
            <div style="position: sticky; top: 20px;">
                <UserProgramAndResourceStatus
                    v-if="companyUserProgramTaskCollection"
                    :company_user_id="companyUserId"
                    :company-user-program-id="companyUserProgramId"
                    :company-user-program-task-collection="companyUserProgramTaskCollection"
                    :program-label="programLabel"
                    :program="program"
                />
                <UserProgramTaskAction />
            </div>
        </div>
    </div>
</template>
<script>
import UserProgramAndResourceStatus from '@/components/profile/UserProgramAndResourceStatus.vue';
import UserProgramTaskAction from '@/components/profile/UserProgramTaskAction.vue';
import UserProgramItem from '@/components/program/UserProgramItem.vue';

export default {
    name: 'ProfileDetailProgram',
    components: {
        UserProgramAndResourceStatus,
        UserProgramTaskAction,
        UserProgramItem,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: true,
        },
        programLabel: {
            type: String,
            default: '',
        },
        program: {
            type: Object,
            default: () => null,
        },
    },

    data() {
        return {
            companyUserProgramTaskCollection: null,
        };
    },
};
</script>
