<template>
    <HtFormSwitch
        :id="id"
        :name="name"
        :value="value"
        :label="label"
        :description="description"
        :disabled="disabled"
        :show-optional="false"
        @input="updateValue"
    />
</template>

<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    components: {
        HtFormSwitch,
    },

    props: {
        value: {
            type: Boolean,
            default: () => false,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },

    computed: {
        id() {
            return `locked-${this._uid}`;
        },

        name() {
            return `locked-${this._uid}`;
        },

        label() {
            return this.translate('Lock the resource');
        },

        description() {
            return this.translate('Locked resources can only be modified or deleted from a program by authorized roles');
        },
    },

    methods: {
        updateValue(value) {
            this.$emit('input', value);
        },
    },
};
</script>
