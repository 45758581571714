/* eslint-disable no-useless-constructor */
/* eslint-disable class-methods-use-this */

import HtProgramType from '@/models/HtProgramType';
import CompanyLanguage from '@/models/CompanyLanguage';
import CompanyRole from '@/models/CompanyRole';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import AbstractTemplateProvider from './AbstractTemplateProvider';

export default class ParentreturnTemplateProvider extends AbstractTemplateProvider {
    static TEMPLATE_SLUG = 'parent-leave-return';

    constructor(programTypes, keyDates, roles, employeeDepartmentGroupId) {
        super(programTypes, keyDates, roles, employeeDepartmentGroupId);
    }

    get baseProgramData() {
        return {
            slug: ParentreturnTemplateProvider.TEMPLATE_SLUG,
            ht_program_type_id: this.programTypes.find(
                (type) => type.slug === HtProgramType.SLUG_REBOARDING,
            ).id,
            locales: [
                {
                    name: 'Congé parental (retour)',
                    description:
                        'À son retour, c’est un vrai programme de reboarding qu’il faut mettre en place pour réintégrer le salarié, humainement et professionnellement.',
                    language_key: CompanyLanguage.KEY_FR,
                },
                {
                    name: 'Parental leave (return)',
                    description:
                        'When the employee returns, a real re-boarding program must be put in place to reintegrate the employee, both humanly and professionally.',
                    language_key: CompanyLanguage.KEY_EN,
                },
            ],
            key_dates: [
                {
                    ht_program_key_date: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Return date')),
                    ht_program_key_date_id: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Return date')).id,
                    is_main: true,
                },
            ],
            actions: [],
            surveys: [],
            emails: [],
            events: [],
        };
    }

    get actions() {
        return [
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -7,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Envoyer une vidéo personnalisée au salarié pour lui souhaiter un bon retour',
                            [CompanyLanguage.KEY_EN]: 'Send a personalized video to the employee to welcome them back',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Préparer un événement d’équipe pour accueillir le salarié à son retour',
                            [CompanyLanguage.KEY_EN]: 'Prepare a team event to welcome the employee back',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 2,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Proposer au salarié une formation de 1 à 2 jours pour mettre à jour ses compétences',
                            [CompanyLanguage.KEY_EN]: 'Offer the employee a 1 to 2 days training to update their skills',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 8,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Organiser des challenges et défis pour recréer une cohésion d’équipe',
                            [CompanyLanguage.KEY_EN]: 'Organize challenges to recreate team cohesion',
                        },
                    },
                },
            },
        ];
    }

    get emails() {
        return [
            {
                template: 'custom',
                translations: [
                    {
                        subject: "J'espère que tout va bien",
                        content: `<p>Bonjour {recipient_firstname}</p>
                            <p>J'espère que tu vas bien. Je voulais simplement te donner des nouvelles et dire qu'on a hâte que tu reviennes parmi nous.</p>
                            <p>Tout avance bien chez nous, et l'équipe pense très fort à toi !</p>`,
                        signature: '{sender_firstname} {sender_lastname}',
                        language_key: CompanyLanguage.KEY_FR,
                    },
                    {
                        subject: 'I hope everything is fine',
                        content: `<p>Hello {recipient_firstname}</p>
                            <p>I hope you're doing well. I just wanted to give you an update and say that we can't wait to have you back with us.</p>
                            <p>Everything is going well here, and the team is thinking about you a lot!</p>`,
                        signature: '{sender_firstname} {sender_lastname}',
                        language_key: CompanyLanguage.KEY_EN,
                    },
                ],
                file_translations: [],
                resource: {
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Prise de contact',
                            [CompanyLanguage.KEY_EN]: 'I hope everything is fine',
                        },
                    },
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -30,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,

                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                },
                sender_role_id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
            },
        ];
    }

    get events() {
        return [
            {
                time_start: '14:00',
                time_end: '15:00',
                is_delegated: false,
                timezone: 'Europe/Paris',
                organiser_role: {
                    id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
                },
                email_sending_offset: 5,
                file_translations: {},
                translations: {
                    content: {
                        [CompanyLanguage.KEY_EN]: `<p>Hello {enrollee_firstname}</p>
                        <p>Let's take a moment together to talk about your return with us.</p>`,
                        [CompanyLanguage.KEY_FR]: `<p>Bonjour {enrollee_firstname}</p>
                        <p>Prenons un moment ensemble pour échanger sur votre retour parmi nous.</p>`,
                    },
                },
                resource: {
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_EN]: 'HR interview',
                            [CompanyLanguage.KEY_FR]: 'Point RH',
                        },
                    },
                    programs: [
                        {
                            is_enabled: true,
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                            filters: [],
                        },
                    ],
                },
            },
        ];
    }
}
