<template>
    <div class="select-wrapper">
        <div
            v-for="(item, index) in selectables"
            :key="index"
            class="flex-1 ml-10"
        >
            <HtFormMultiSelect
                v-if="!item.disabled"
                :id="`${index}-selectFilter`"
                :ref="item.key"
                v-model="selectedOptions[item.key]"
                :placeholder="translate(item.placeholder)"
                :options="item.options"
                :name="`${index}-selectFilter`"
                :label-options="'label'"
                :show-optional="false"
                :is-single="true"
                @input="onFilter(item.key)"
            >
                <template
                    slot="singleLabel"
                    slot-scope="{ option }"
                >
                    <span>{{ option.label }}</span>
                </template>
            </HtFormMultiSelect>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterableSelect',
    props: {
        value: { type: Array, default: () => [] },
        selectables: { type: Array, default: () => [] }, // [[{key, placeholder, options: [{key, label}]}], [...]]
        placeholder: { type: String, default: 'All' },
    },

    data() {
        return {
            selectedOptions: {},
            searchInput: '',
            newArray: [],
            initialArr: [], // gets set from prop during mount
        };
    },

    mounted() {
        this.initialArr = this.value;
    },

    methods: {

        onFilter() {
            this.newArray = this.initialArr.filter((element) => {
                const is_match = [];
                this.selectables.forEach((item, index) => {
                    is_match[index] = !this.selectedOptions[item.key] || this.selectedOptions[item.key].key === element[item.key];
                });

                return is_match.every((item) => item === true);
            });

            this.$emit('input', this.newArray);
        },

        filterData() {
            // const isOptionSelected = this.selectables.filter(item => this.$refs[item.key][0].value !== '').length
            const isOptionSelected = this.selectables.filter((item) => this.selectedOptions[item.key] != null).length;

            const filterArr = (data, count) => data.filter((item) => checkKeys(item, count));
            const checkKeys = (item, count) => this.selectables.filter((selectable) => checkMatch(item, selectable.key)).length === count;

            const checkMatch = (item, key) => {
                const keyGroup = key.split('.');
                const prop = keyGroup.reduce((accum, key) => accum[key], item);
                return prop === this.selectedOptions[key].value;
                // return this.selectedOptions[key] != null ? prop == this.selectedOptions[key].key : false
            };

            this.$emit('input', isOptionSelected ? filterArr(this.initialArr, isOptionSelected) : this.initialArr);
        },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	.select-wrapper {
		display: flex;
		justify-content: flex-start;
	}

	@media (max-width: $phone) {
		.search-wrapper {
			flex-direction: column;
			flex-wrap: wrap;
		}
    }

    .flex-1 {
        flex: 1 1 0%;
    }
</style>
