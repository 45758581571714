<template>
    <div>
        <transition leave-active-class="leave-fade-dashboard">
            <ListActionsItemHtml
                v-show="displayTask"
                :title="title"
                :status="status"
                :is-late="isLate"
                :date="date"
                :is-togglable="isTogglable"
                :participants="participants"
                :show-multi-select="showMultiSelect"
                :is-selectable="isSelectable"
                :can-validate="isSelectable"
                :is-selected="isSelected"
                :validator-type="action.validator_type"
                :is-history="isHistory"
                @onSelectedAction="onSelectedAction"
                @onToggle="onToggle"
                @onClick="onClick"
            >
                <template slot="title">
                    <div class="content-left-title-3">
                        <t :program-name="action.company_user_program.company_program.name_translated">
                            Linked to the program "{ program-name }" of
                        </t><strong>{{ fullname }}</strong>
                    </div>
                </template>
                <template
                    v-if="action.resource === 'company_planning' && action.company_planning_event.is_delegated"
                    #middle-content
                >
                    <HtTag
                        class="middle-content-tag"
                        color="purple"
                    >
                        <FontAwesomeIcon
                            class="middle-content-tag-icon"
                            :icon="['fad', 'calendar-alt']"
                        />
                        <t>To schedule</t>
                    </HtTag>
                </template>
            </ListActionsItemHtml>
        </transition>
        <UserWrapperModal
            ref="userWrapperModal"
            :size="getSize"
            :is-edit="isEdit"
            :is-fill="true"
            @onUpdate="onUpdate"
            @onDelete="onDelete"
        />
    </div>
</template>

<script>
import UserWrapperModal from '@/components/miscellaneous/UserWrapperModal.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import ListActionsItemHtml from '@/components/pages/dashboard/actions/ListActionsItemHtml.vue';

export default {
    name: 'ListActionsByActionWrapperItem',
    components: {
        ListActionsItemHtml, UserWrapperModal,
    },
    props: {
        action: {
            type: Object,
            required: true,
        },
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        selectedActionList: {
            type: Array,
            required: true,
        },
        isHistory: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        isEdit() {
            const { resource } = this.action;
            return resource === 'company_requirement_category';
        },
        isTogglable() {
            const { resource } = this.action;

            return !!(((resource !== 'company_survey' && resource !== 'company_software') || (resource === 'company_software' && this.action.validator_type === 'human')));
        },

        displayTask() {
            return (this.action.status === CompanyUserProgramTask.STATUS_PENDING && !this.isHistory)
                || (this.action.status === CompanyUserProgramTask.STATUS_DONE && this.isHistory);
        },

        status() {
            return this.action.status;
        },

        isLate() {
            return this.$Utils.moment(this.date).isBefore(this.$Utils.moment(), 'day')
                && this.action.resource !== 'company_planning';
        },

        title() {
            return this.action.title;
        },

        participants() {
            if (this.action.resource === 'company_planning') {
                return this.action.company_planning_event.company_planning_event_attendee;
            }

            return this.action.company_user_program_task_participant.models.length > 1
                ? this.action.company_user_program_task_participant.models
                : [];
        },

        date() {
            return this.action.datetime_end;
        },

        getSize() {
            let size = null;

            switch (this.action.resource) {
            case 'company_planning':
            case 'company_document':
            case 'company_doc':
            case 'company_software':
            case 'company_equipment':
            case 'company_requirement_category':
            case 'custom':
                size = 'small';
                break;
            case 'company_user_quiz':
            case 'company_quiz':
                size = 'large';
                break;
            default:
                size = 'medium';
            }

            return size;
        },

        isSelectable() {
            const { resource } = this.action;
            const event = this.action.company_planning_event;

            if (resource === 'company_planning' && event.is_delegated === true) return false;
            return resource !== 'company_software' && resource !== 'company_survey' && resource !== 'company_requirement_category' && resource !== 'company_user_quiz';
        },

        isSelected() {
            return this.selectedActionList.includes(this.action.id);
        },

        fullname() {
            return `${this.action.company_user.firstname} ${this.action.company_user.lastname}`;
        },
    },

    methods: {
        onToggle(status) {
            this.action.status = status ? 'done' : 'pending';

            const currentTask = new CompanyUserProgramTask([
                'id',
                'status',
            ]);
            currentTask.fill(this.action);

            this.$handleValidationTasks(currentTask)
                .then(() => {
                    this.shared.dashboard.statusIncrementTask++;
                });
        },

        onClick() {
            if (!this.showMultiSelect) {
                this.$refs.userWrapperModal.open(this.action);
            }
        },

        onSelectedAction() {
            if (this.selectedActionList.includes(this.action.id)) {
                this.selectedActionList.splice(this.selectedActionList.indexOf(this.action.id), 1);
            } else {
                this.selectedActionList.push(this.action.id);
            }
        },

        onUpdate() {
            this.shared.dashboard.statusIncrementTask++;
        },

        onDelete() {
            this.shared.dashboard.removeIncrementTask++;
        },
    },
};
</script>
<style lang="scss" src="./ListActionsByActionItem.scss" scoped></style>
