<template>
    <div class="user-nudge-item">
        <div v-if="companyUserNudge.isLoaded()">
            <template v-if="undefined === companyUserNudge.id">
                <ErrorDisplay :append-errors="'This resource is not accessible'" />
            </template>
            <template v-else>
                <form
                    class="form-2 spaced label-underline"
                    @submit.prevent
                >
                    <fieldset>
                        <div class="survey-image">
                            <img
                                src="/static/images/modal.svg"
                                alt
                                class="modal-img"
                            >
                        </div>
                    </fieldset>

                    <fieldset>
                        <label><t>Message</t>: </label>
                        <p v-dompurify-html="companyUserNudge.message" />
                    </fieldset>

                    <fieldset v-if="companyUserNudge.company_user_program_task.validator_type === 'human'">
                        <label><t>Challenge deadline</t>: </label>
                        <p>{{ $Utils.moment(companyUserNudge.company_user_program_task.datetime_end).format('L') }}</p>
                    </fieldset>

                    <fieldset>
                        <label><t>Status</t>: </label>
                        <div class="user-nudge-item-status">
                            <template v-if="companyUserNudge.status === 'success'">
                                <i class="fas fa-check-circle status-done" />
                            </template>
                            <template v-else-if="companyUserNudge.status === 'proposed'">
                                <i class="fas fa-hourglass-half status-undone" />
                            </template>
                            <template v-else>
                                <i class="fas fa-times-circle status-undone" />
                            </template>
                            {{ statusText(companyUserNudge) }}
                        </div>
                    </fieldset>
                    <div class="users">
                        <fieldset class="mb-10">
                            <label>
                                <t>Initiator</t>
                            </label>
                            <div class="card-6 flex-container">
                                <div class="item">
                                    <div
                                        v-user-image="$Utils.getCompanyComponentRoleInitials('user',1.25, companyUserNudge.company_user_initiator)"
                                        class="item-image role-only"
                                        :class="'user'"
                                    />
                                    <div class="align-center">
                                        {{ companyUserNudge.company_user_initiator.firstname }}
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset class="mb-10">
                            <label>
                                <t>Recipient</t>
                            </label>
                            <div class="card-6 flex-container">
                                <div class="item">
                                    <div
                                        v-user-image="$Utils.getCompanyComponentRoleInitials('user',1.25, companyUserNudge.company_user_recipient)"
                                        class="item-image role-only"
                                        :class="'user'"
                                    />
                                    <div class="align-center">
                                        {{ companyUserNudge.company_user_recipient.firstname }}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div
                        v-if="!isCancelled"
                        class="modal-actions right"
                    >
                        <Button
                            v-if="$canDelete('ModelCompanyUserNudge', { company_user_id: shared.session.companyUser.id,})"
                            class="negative"
                            :state="buttonState"
                            @click="onDelete()"
                        >
                            <t>Remove</t>
                        </Button>
                        <template v-if="companyUserNudge.company_user_program_task.status === 'pending' && companyUserNudge.company_user_program_task.validator_type === 'human'">
                            <Button
                                v-if="companyUserNudge.company_user_program_task.permissions.can_validate"
                                :state="buttonState"
                                @click="toggleStatus()"
                            >
                                <t>{{ isDone ? 'Mark as undone' : 'Mark as done' }}</t>
                            </Button>
                        </template>
                    </div>
                </form>

                <Modalable
                    ref="userNudgeDelete"
                    class="modalable-1 small"
                    :mode="2"
                    :element-delete-label="translate('this nudge')"
                />
            </template>
        </div>
        <template v-else>
            <IconLoading />
        </template>
    </div>
</template>

<script>
import ParticipantListHelper from '@/components/mixins/ParticipantListHelper.vue';
import CompanyUserProgramTask from '../../../../../models/CompanyUserProgramTask';
import CompanyUserNudge from '../../../../../models/CompanyUserNudge';

export default {
    name: 'UserNudgeItem',
    mixins: [ParticipantListHelper],
    inject: ['modal'],
    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            buttonState: 'idle',
            companyUserNudge: new CompanyUserNudge([
                'id',
                'company_nudge_id',
                'message',
                'status',
                'company_user_id',
                'company_user_recipient_id',
                'company_user_initiator_id',
                'permissions',
            ])
                .with({
                    companyNudge: (query) => {
                        query
                            .select(['id', 'message'])
                            .with({
                                resource: (query) => {
                                    query.select(['name']);
                                },
                            });
                    },
                    companyUserRecipient: (query) => {
                        query.select(['id', 'firstname', 'lastname', 'arrival_date', 'image']);
                    },
                    companyUser: (query) => {
                        query.select(['id', 'firstname', 'lastname', 'arrival_date', 'image'])
                            .with({
                                companyLanguage: (query) => {
                                    query.select(['id', 'key']);
                                },
                            });
                    },
                    companyUserInitiator: (query) => {
                        query.select(['id', 'firstname', 'lastname', 'arrival_date', 'image']);
                    },
                    companyUserProgramTask: (query) => {
                        query.select([
                            'id',
                            'status',
                            'permissions',
                            'resource',
                            'company_user_id',
                            'resource_id',
                            'datetime_end',
                            'validator_type',
                        ]);
                    },
                }),
        };
    },
    computed: {
        /**
             * @returns {boolean}
             */
        isDone() {
            return this.companyUserNudge.status === CompanyUserNudge.STATUS_SUCCESS;
        },

        isCancelled() {
            return this.companyUserNudge.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },
    },
    async created() {
        this.companyUserNudge.where([['id', '=', this.id]]);
        this.companyUserNudge.company_user_id = this.companyUserId;
        this.companyUserNudge.get().then(() => {
            if (undefined !== this.companyUserNudge.id) {
                this.modal.setTitle(this.companyUserNudge.name);
            }
        });
    },
    methods: {
        statusText(companyUserNudge) {
            if (companyUserNudge.company_user_program_task.status === CompanyUserProgramTask.STATUS_PENDING && companyUserNudge.company_user_program_task.validator_type === CompanyUserProgramTask.VALIDATOR_TYPE_SYSTEM) {
                return `${this.translate('Schedule on')} ${this.$Utils.moment(companyUserNudge.company_user_program_task.datetime_end).format('L')}`;
            }

            if (companyUserNudge.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED) {
                return this.translate('Cancelled');
            }

            if (companyUserNudge.company_user_program_task.status === CompanyUserProgramTask.STATUS_DONE) {
                return this.translate('Done');
            }

            switch (companyUserNudge.status) {
            case CompanyUserNudge.STATUS_PROPOSED:
                return this.translate('Waiting for an answer');
            case CompanyUserNudge.STATUS_SUCCESS:
                return this.translate('Challenge completed');
            case CompanyUserNudge.STATUS_REFUSE:
                return this.translate('Challenge not met');
            case CompanyUserNudge.STATUS_FAILURE:
            case CompanyUserNudge.STATUS_ACCEPTED:
                return this.translate('Challenge met but not completed');
            }
        },

        async toggleStatus() {
            await this.$handleValidationTasks({
                id: this.companyUserNudge.company_user_program_task.id,
                status: this.isDone ? CompanyUserProgramTask.STATUS_PENDING : CompanyUserProgramTask.STATUS_DONE,
            });
            this.$emit('onUpdate');
            this.modal.close();
        },

        onUpdate() {
            this.modal.close();
            this.$emit('onUpdate');
        },

        onDelete() {
            this.$refs.userNudgeDelete.deleteWindow().then((result) => {
                if (result) {
                    this.companyUserNudge.delete().then(() => {
                        this.$emit('onDelete');
                        this.modal.close();
                    });
                }
            });
        },

        openAnswerModal() {
            this.$refs.userNudgeFill.open();
        },

        async onReload() {
            this.$emit('onUpdate');
            this.buttonState = 'loading';
            await this.companyUserNudge.get()
                .then(
                    (item) => {
                        this.buttonState = 'idle';
                        this.modal.setTitle(item.name);
                    },
                    () => {
                        this.buttonState = 'idle';
                        this.modal.close();
                    },
                );
        },
    },
};
</script>

<style lang="scss" scoped src="./UserNudgeItem.scss"></style>
