<template>
    <div v-if="companyUserNudgeCollection.isLoaded()">
        <HtAccordion
            :title="translate('Nudges')"
            :count="filteredUserNudges.length"
        >
            <HtSearchField
                v-if="hasNudge"
                :id="'searchNudge'"
                v-model.trim="searchValue"
                :name="'searchNudge'"
            />

            <HtTable
                v-if="filteredUserNudges.length > 0"
                :items="filteredUserNudges"
                :columns="columns"
                cypress="user-activities"
                :external-search="searchValue"
                @onCellClicked="openItemModal"
            >
                <template #cell(display_status)="scope">
                    <HtTag :color="color(scope.item.status)">
                        {{ statusText(scope.item) }}
                    </HtTag>
                </template>
                <template #cell(participant)="scope">
                    <ParticipantList :users="[{company_user: scope.item.company_user_recipient}]" />
                </template>
            </HtTable>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No nudge added</t>
                </strong>
            </div>
        </HtAccordion>

        <modalable
            ref="modalableUserItem"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserNudgeItem
                :id="currentId"
                ref="userItem"
                :from="'profile'"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </modalable>
    </div>
</template>

<script>
import HtAccordion from '@/components/globals/HtAccordion.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import UserNudgeItem from '@/components/pages/dashboard/modals/nudge/UserNudgeItem.vue';
import CompanyUserNudgeCollection from '@/models/CompanyUserNudgeCollection';

export default {
    name: 'UserNudgeProfile',
    components: {
        HtAccordion,
        HtSearchField,
        UserNudgeItem,
    },

    props: {
        companyUserId: {
            type: [String, Number],
            required: false,
        },
    },
    data() {
        return {
            columns: [
                {
                    label: this.translate('status'),
                    key: 'display_status',
                    sortable: false,
                },
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                    search: true,
                },
                {
                    label: this.translate('Participants'),
                    key: 'participant',
                    sortable: false,
                },
                {
                    label: this.translate('Program linked'),
                    key: 'company_user_program.company_program.name_translated',
                    sortable: false,
                },
            ],
            currentId: null,
            companyUserNudgeCollection: new CompanyUserNudgeCollection([
                'id', 'company_user_id', 'status', 'company_user_id', 'datetime_availability', 'time_to_complete',
            ]).with({
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserInitiator: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image']);
                },
                companyUserRecipient: (query) => {
                    query
                        .select(['id', 'firstname', 'lastname', 'gender', 'image', 'company_language_id'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyNudge: (query) => {
                    query.select(['id']);
                    query.with({
                        resource: (query) => {
                            query.select(['name', 'message', 'language_key']);
                        },
                    });
                },
            }),
            searchValue: null,
        };
    },

    computed: {
        /**
         * Nudges filtrés par la barre de recherches
         */
        filteredUserNudges: {
            get() {
                return this.companyUserNudgeCollection.$.models;
            },

            set(val) {
                this.companyUserNudgeCollection.$.models = val;
            },
        },

        hasNudge() {
            return this.companyUserNudgeCollection.$.models.length > 0;
        },
    },

    created() {
        this.companyUserNudgeCollection.where([
            ['company_user_id', '=', this.companyUserId],
        ]);

        if (this.shared.session.companyUser.isAdmin() === false) {
            this.companyUserNudgeCollection.whereIn([
                ['status', ['accepted', 'refuse', 'success']],
            ]);
        }
        this.companyUserNudgeCollection.get().then(() => {
            this.$emit('on-load', this.companyUserNudgeCollection.$.models.length);
        });
    },

    methods: {
        color(status) {
            const colors = {
                draft: 'orange',
                pending: 'purple',
                proposed: 'purple',
                accepted: 'purple',
                failure: 'red',
                refuse: 'red',
                success: 'green',
            };

            return colors[status];
        },
        statusText(oCompanyUserNudge) {
            switch (oCompanyUserNudge.status) {
            case 'pending':
                return `${this.translate('Schedule on')} ${this.$Utils.moment(oCompanyUserNudge.datetime_availability).format('L')}`;
            case 'proposed':
                return this.translate('Waiting for an answer');
            case 'success':
                return this.translate('Challenge completed');
            case 'refuse':
                return this.translate('Challenge not met');
            case 'failure':
            case 'accepted':
                return this.translate('Challenge met but not completed');
            }
        },
        onUpdate() {
            this.companyUserNudgeCollection.get();
        },
        onDelete() {
            this.companyUserNudgeCollection.get();
        },
        openItemModal(row) {
            this.currentId = row.item.id;
            this.$refs.modalableUserItem.open();
        },
    },
};
</script>
