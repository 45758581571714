<template>
    <div v-if="isLoaded">
        <div class="filter-header">
            <slot name="title" />
            <div class="filter-search-and-btn">
                <slot name="topFilter" />
                <HtButton
                    type="secondary"
                    size="big"
                    :class="{active: showFilters}"
                    class="filter-btn hide-mobile"
                    @click.native="showFilters = !showFilters"
                >
                    <FontAwesomeIcon
                        class="filter-icon"
                        :icon="['far', 'filter']"
                    />
                    <span
                        v-if="selectedFiltersLength > 0"
                        class="badge branding-bg"
                    >
                        {{ selectedFiltersLength }}
                    </span>
                </HtButton>
                <HtButton
                    type="secondary"
                    size="big"
                    :class="{active: showFilters}"
                    class="filter-btn hide-desktop"
                    @click.native="$refs.modal.open()"
                >
                    <FontAwesomeIcon
                        class="filter-icon"
                        :icon="['far', 'filter']"
                    />
                    <span
                        v-if="selectedFiltersLength > 0"
                        class="badge branding-bg"
                    >
                        {{ selectedFiltersLength }}
                    </span>
                </HtButton>
                <slot name="button" />
            </div>
        </div>

        <transition
            name="fade-height"
            tag="div"
        >
            <div
                v-if="showFilters"
                class="hide-mobile"
            >
                <HtEntitiesFilters
                    :store="store"
                    :value="value"
                    :entities="filteredEntities"
                    :is-single="isSingle"
                    :should-show-library-mode="shouldShowLibraryMode"
                    :entity-filters-enabled="entityFiltersEnabled"
                    @on-update-filters="$emit('on-update-filters')"
                    @on-clear-filters="clearAllFilters"
                    @input="data => $emit('input', data)"
                >
                    <template
                        v-for="(_, slot) of $scopedSlots"
                        #[slot]="scope"
                    >
                        <slot
                            :name="slot"
                            v-bind="scope"
                        />
                    </template>
                </HtEntitiesFilters>
            </div>
        </transition>

        <HtModal ref="modal">
            <template #header>
                <t>Filters</t>
            </template>
            <HtEntitiesFilters
                :store="store"
                :value="value"
                :entities="filteredEntities"
                :is-single="isSingle"
                :should-show-library-mode="shouldShowLibraryMode"
                layout-modal
                @on-update-filters="$emit('on-update-filters')"
                @on-clear-filters="clearAllFilters"
                @input="data => $emit('input', data)"
            >
                <template
                    v-for="(_, slot) of $scopedSlots"
                    #[slot]="scope"
                >
                    <slot
                        :name="slot"
                        v-bind="scope"
                    />
                </template>
            </HtEntitiesFilters>

            <template #footer-actions>
                <div class="footer-btn">
                    <HtButton
                        type="secondary"
                        size="medium"
                        @click.native="clearAllFilters"
                    >
                        <t>Delete filters</t>
                    </HtButton>
                    <HtButton
                        size="medium"
                        @click.native="$refs.modal.close()"
                    >
                        <t>See results</t>
                    </HtButton>
                </div>
            </template>
        </HtModal>
    </div>
</template>

<script>
import groupBy from 'lodash.groupby';
import HtButton from '../HtButton.vue';
import HtEntitiesFilters from './HtEntitiesFilters.vue';

export default {
    name: 'HtEntitiesFiltersWrapper',
    components: {
        HtButton,
        HtEntitiesFilters,
    },
    props: {
        shouldShowLibraryMode: {
            type: Boolean,
            default: false,
        },
        store: {
            type: String,
            required: false,
            default: '',
        },
        isSingle: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Array,
            default: null,
        },
        programEntitiesIds: {
            type: Array,
            default: () => ([]),
        },
        search: {
            type: String,
            default: undefined,
        },
        additionalFiltersSelectLength: {
            type: Number,
            default: 0,
        },
        entityFiltersEnabled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            showFilters: false,
        };
    },
    computed: {
        filteredEntities() {
            if (!this.programEntitiesIds.length) return this.$store.state.entities.entities;
            return this.$store.state.entities.entities
                .filter((entity) => this.programEntitiesIds.includes(entity.id));
        },
        selectedFilters() {
            return this.$store.getters[`${this.store}/getFilters`]?.entities
                || this.value;
        },
        isLoaded() {
            return this.$store.state.entities.entitiesLoaded;
        },
        valuesByEntityId() {
            const valuesByEntityId = this.$store.getters['entities/valuesByEntityId'];

            for (const [key, values] of Object.entries(valuesByEntityId)) {
                valuesByEntityId[key] = values.sort((a, b) => a.name.localeCompare(b.name));
            }

            return valuesByEntityId;
        },
        selectedValuesByEntityId() {
            const entities = {};

            const valuesByEntityId = groupBy(this.selectedFilters, 'company_entity_id');

            this.filteredEntities.forEach((entity) => {
                entities[entity.id] = valuesByEntityId[entity.id] || [];
            });

            return entities;
        },
        selectedFiltersLength() {
            return this.selectedFilters.length + this.additionalFiltersSelectLength;
        },
    },
    created() {
        this.$store.dispatch('entities/fetchEntities');
    },
    methods: {
        getSelectionLabel(id) {
            return this.isSingle ? null
                : this.translate(
                    '{count} filter | {count} filters',
                    { count: this.selectedValuesByEntityId[id].length },
                );
        },
        clearAllFilters() {
            if (this.store) {
                this.$store.dispatch(`${this.store}/clearAllFilters`);
            }
            this.$emit('input', []);
            this.$emit('on-update-filters');
            this.$emit('on-clear-filters');
            this.$refs.modal.close();
        },
        onClearFilters() {
            this.$emit('input', []);
            this.$emit('on-clear-filters');
        },
        updateEntitiesFilter(entityId, values = []) {
            let valuesToReturn;

            if (!values) {
                valuesToReturn = [];
            } else if (!Array.isArray(values)) {
                valuesToReturn = [values];
            } else {
                valuesToReturn = values;
            }

            if (this.store) {
                this.$store.dispatch(`${this.store}/setEntitiesFilter`, { company_entity_id: entityId, values: valuesToReturn });
            } else {
                const entities = this.value.filter(
                    (entity) => entity.company_entity_id !== entityId,
                );
                this.$emit('input', [...entities, ...valuesToReturn]);
            }

            this.$emit('on-update-filters');
        },
        selectedValuesByEntity(entity) {
            let selectedValues;
            if (this.isSingle) {
                selectedValues = (this.selectedValuesByEntityId[entity.id]
                    ? this.selectedValuesByEntityId[entity.id][0] : null);
            } else {
                selectedValues = this.selectedValuesByEntityId[entity.id];
            }
            return selectedValues;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
::v-deep .ht-form {
    margin-bottom: 0;
}
.filter-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 4px;
        align-self: flex-start;
    }
    .subtitle {
        font-size: 14px;
        color: $neutral-500;
    }

    .filter-search-and-btn {
        display: flex;
        flex: 0 0 auto;
        gap: 8px;
        align-items: flex-end;
        flex-direction: row;
        margin-left: 20px;
        .filter-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 42px;
            width: 42px;
            padding: 0;
            position: relative;
            flex-shrink: 0;
            &.hide-desktop {
                display: none;
            }
            &.active {
                font-weight: bold;
            }
            .filter-icon{
                width: 16px;
                height: 16px;
                padding: 2px;
            }

            .badge {
                position: absolute;
                top: 4px;
                right: 4px;
                border-radius: 100%;
                width: 16px;
                height: 16px;

                display: flex;
                justify-content: center;
                align-items: center;

                font-size: 10px;
                font-weight: 600;

            }
        }
    }
}

.fade-height-enter-active,
.fade-height-leave-active {
    transition: 0.6s ease-in-out;
    max-height: 1000px;
    overflow: hidden;
    opacity: 1;
}
.fade-height-enter,
.fade-height-leave-to {
    max-height: 0;
    opacity: 0;
}

@media (max-width: $tablet) {
    .hide-mobile {
        display: none;
    }
    .filter-header {
        flex-direction: column;
        gap: 24px;

        .filter-search-and-btn{
            flex-direction: row;
            width: 100%;
            margin-left: 0;
            .search{
                width: 100%;
                flex-grow: 1;
                ::v-deep .ht-form {
                    width: 100%;
                }
            }
            .filter-btn {
                &.hide-mobile {
                    display: none;
                }
                &.hide-desktop {
                    display: block;
                }
            }
        }
    }
    .footer-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin-top:24px;
    }
}

</style>
