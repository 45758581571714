<template>
    <HtTabStepper
        :item.sync="item"
        :tabs="tabs"
        @on-close-stepper="$emit('on-close-stepper')"
    />
</template>

<script>
import api from '@/store/api';
import HtTabStepper from '@/layouts/HtTabStepper.vue';
import stepperFormEventBus from '@/eventBus/stepperFormEventBus';
import EnterprisePageFormContent from '@/components/settings/EnterprisePage/Form/EnterprisePageFormContent.vue';
import EnterprisePageFormParameters from '@/components/settings/EnterprisePage/Form/EnterprisePageFormParameters.vue';

export default {
    name: 'EnterprisePageForm',

    components: {
        HtTabStepper,
    },

    props: {
        id: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            item: {
                translations: {
                    name: { [this.shared.session.company.company_language.key]: '' },
                    description: { [this.shared.session.company.company_language.key]: '' },
                },
                order: null,
                filters: [],
            },
            tabs: [
                {
                    ref: 'parameters',
                    title: this.translate('Parameters'),
                    component: EnterprisePageFormParameters,
                    disabled: false,
                    noBody: false,
                },
                {
                    ref: 'content',
                    title: this.translate('Content'),
                    component: EnterprisePageFormContent,
                    disabled: !this.id,
                    noBody: false,
                },
            ],
        };
    },

    created() {
        if (this.id) {
            this.fetchData();
        } else {
            stepperFormEventBus.$emit('fetched-item', this.translate('New enterprise page'));
        }
    },

    methods: {
        fetchData() {
            api
                .settings
                .enterprisePages
                .pages
                .findById(this.id)
                .then((res) => {
                    this.item = res.data.data;
                    stepperFormEventBus.$emit('fetched-item', this.item.name);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.ht-card {
    border: 1px solid #E1E1E1;
    padding: 0;

    ::v-deep .ht-card-title, ::v-deep .ht-card-body {
        padding: 24px;
    }

    ::v-deep .ht-card-body {
        background-color: #FBFBFB;
    }
}
</style>
