<template>
    <div class="generic-dialog-wrapper">
        <div class="generic-dialog-header">
            <div class="top-header">
                <HtResourceTag
                    :resource="resourceType"
                    :label="labelCategory"
                />
                <HtButton
                    type="secondary"
                    size="small"
                    @click.native="closeResourceModal"
                >
                    <FontAwesomeIcon
                        icon="times"
                        class="close-icon"
                    />
                </HtButton>
            </div>
            <div class="title">
                <h1>
                    {{ resource.name }}
                </h1>
                <p
                    v-if="resource.description"
                    v-dompurify-html="resource.description"
                />
            </div>
        </div>
        <div class="generic-dialog-content">
            <HtReadResourcesDocument
                v-if="resourceData"
                :resource-data="resourceData"
            />
            <slot />
            <HtReadResourcesInfo
                v-if="hasAvailabilityDate"
                :label="labelAvailabilityDate"
            >
                <span
                    v-dompurify-html="timeAvailabilityText(resource.programs[0])"
                />
            </HtReadResourcesInfo>
            <HtReadResourcesInfo
                v-if="hasCompletionDate"
                :label="labelCompletionDate"
            >
                <span
                    v-dompurify-html="timeEndText(resource.programs[0])"
                />
            </HtReadResourcesInfo>
            <HtReadResourcesInfo
                v-if="hasSender"
                :label="labelSender"
            >
                <HtParticipantList :users="getSender" />
            </HtReadResourcesInfo>
            <HtReadResourcesInfo
                v-if="hasRecipient"
                :label="labelRecipient"
            >
                <HtParticipantList :users="resource.programs[0].participants" />
            </HtReadResourcesInfo>
            <template v-if="resource.programs[0].filters.length === 0">
                <HtReadResourcesInfo label="Filters">
                    <t>All filters</t>
                </HtReadResourcesInfo>
            </template>
            <template v-else>
                <HtReadResourcesInfo label="Office">
                    {{ getFilterByEntity("company_office") }}
                </HtReadResourcesInfo>
                <HtReadResourcesInfo label="Department">
                    {{ getFilterByEntity("company_department") }}
                </HtReadResourcesInfo>
                <HtReadResourcesInfo label="Job position">
                    {{ getFilterByEntity("company_job_position") }}
                </HtReadResourcesInfo>
                <HtReadResourcesInfo label="Contract">
                    {{ getFilterByEntity("company_contract") }}
                </HtReadResourcesInfo>
            </template>
        </div>
        <div class="generic-dialog-footer">
            <HtButton
                type="secondary"
                size="big"
                @click.native="closeResourceModal"
            >
                <t>Close</t>
            </HtButton>
        </div>
    </div>
</template>

<script>

import TimelineHelper from '@/classes/Timeline/TimelineHelper';
import HtButton from '@/components/globals/HtButton.vue';
import HtParticipantList from '@/components/globals/HtParticipantList.vue';
import HtReadResourcesDocument from '@/components/globals/modals/HtReadResources/HtReadResourcesDocument.vue';
import HtReadResourcesInfo from '@/components/globals/modals/HtReadResources/HtReadResourcesInfo.vue';
import HtResourceTag from '../../Tag/HtResourceTag.vue';

export default {
    name: 'HtReadResources',
    components: {
        HtParticipantList,
        HtButton,
        HtReadResourcesDocument,
        HtReadResourcesInfo,
        HtResourceTag,
    },

    props: {
        resourceType: {
            type: String,
            required: true,
        },
        resource: {
            type: Object,
            required: true,
        },
        resourceData: {
            type: Object,
            default: null,
        },
        labelCategory: {
            type: String,
            required: true,
        },
        hasSender: {
            type: Boolean,
            default: true,
        },
        labelSender: {
            type: String,
            default: 'Sender',
        },
        hasRecipient: {
            type: Boolean,
            default: true,
        },
        labelRecipient: {
            type: String,
            default: 'Recipients',
        },
        hasCompletionDate: {
            type: Boolean,
            default: true,
        },
        labelCompletionDate: {
            type: String,
            default: 'Completion date',
        },
        hasAvailabilityDate: {
            type: Boolean,
            default: true,
        },
        labelAvailabilityDate: {
            type: String,
            default: 'Availability date',
        },
    },
    computed: {
        getSender() {
            if (this.resourceType === 'company_nudge') {
                return [
                    {
                        company_role_id: this.resource.company_nudge_initiator_role.id,
                        company_role: this.resource.company_nudge_initiator_role,
                        role: this.resource.company_nudge_initiator_role,
                    },
                ];
            } if (this.resourceType === 'company_email_custom_template') {
                return [
                    this.resource.sender_user ? {
                        company_user_id: this.resource.sender_user.id,
                        company_user: this.resource.sender_user,
                        user: this.resource.sender_user,
                    } : {
                        company_role_id: this.resource.sender_role.id,
                        company_role: this.resource.sender_role,
                        role: this.resource.sender_role,
                    },
                ];
            }

            return this.resource.programs[0].participants;
        },
    },
    methods: {
        timeAvailabilityText(resourceProgram) {
            return TimelineHelper.getAvailabilityPeriodLabel(
                resourceProgram.offset_availability_number,
                resourceProgram.offset_availability_unit,
                resourceProgram.program.key_date.name,
            );
        },
        timeEndText(resourceProgram) {
            return TimelineHelper.getEndPeriodLabel(
                resourceProgram.offset_availability_unit,
                resourceProgram.offset_end_number,
                resourceProgram.offset_end_unit,
                resourceProgram.program.key_date.name,
            );
        },
        getFilterByEntity(entitySlug) {
            let string = '';
            const filters = this.resource.programs[0].filters.filter(
                (filter) => filter.entity?.slug === entitySlug,
            );

            filters.forEach((f) => string += ` ${f.name_translated},`);

            return filters.length ? string.slice(0, -1) : this.translate('All');
        },
        closeResourceModal() {
            this.$modal.hide('resourceModal');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/ressources';
@import '~@/styles/modal/genericCenterModal';
.close-icon {
    height: 10px;
    width: 10px;
}
.title {
    margin: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;

    h1 {
        font-size: 1.5em;
        font-weight: 700;
        margin: 0;
    }
}
</style>
