<template>
    <div class="wrapper">
        <header>
            <h4>
                <t :count="participants.length">
                    {count} answer | {count} answers
                </t>
            </h4>
        </header>

        <main>
            <AllResultsRow
                :class="['row-item', {'clickable': participants.length > 0}]"
                :no-surveys="participants.length === 0"
                :selected="resultIndex === 'global'"
                @on-click="$emit('on-change-participant', 'global')"
            />

            <IconLoading v-if="loading" />

            <ParticipantItem
                v-for="participant in participants"
                :key="participant.enrollee.id"
                class="row-item clickable"
                :selected="participant.enrollee.id.toString() === resultIndex"
                :is-targeting-program="isTargetingProgram"
                :participant-info="participant.participant"
                :enrollee-info="participant.enrollee"
                @on-click="$emit('on-change-participant', participant.enrollee.id.toString())"
            />
        </main>
    </div>
</template>

<script>
import IconLoading from '@/components/icons/IconLoading.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import ParticipantItem from './ParticipantItem/ParticipantItem.vue';
import AllResultsRow from './AllResultsRow.vue';

export default {
    name: 'SurveyFormResultsParticipants',

    components: {
        IconLoading,
        HtSearchField,
        ParticipantItem,
        AllResultsRow,
    },

    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        resultIndex: {
            type: String,
            required: true,
        },
        participants: {
            type: Array,
            required: true,
        },
        isTargetingProgram: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.wrapper {
    padding: 1.5rem;
    background: $grey-blue;
    border: 1px solid $grey-ultra-light;
    border-radius: $border-radius-5;

}
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.row-item {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}
</style>
