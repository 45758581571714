<template>
    <div class="answer-history">
        <div class="answer-history-title">
            <t>{{ title }}</t>
        </div>

        <div class="answer-history-content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SurveyAnswerHistory',

    props: {
        title: {
            type: String,
            required: false,
            default: () => 'Last answer',
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import '~@/styles/design-system-colors.scss';

.answer-history {
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: $neutral-100;
    border-radius: 8px;
    padding: 12px;
    gap: 8px;

    &-title {
        color: $neutral-black;
    }

    &-content {
        color: $neutral-700;
    }
}
</style>
