<template>
    <HoldingResourcesCount
        v-if="companies.isLoaded()"
        :title="translate('Nudges')"
        :items="items"
        :route-list-name="'HoldingResourcesNudgesList'"
    />
</template>
<script>
import HoldingResourcesCount from '@/components/globals/HoldingResourceCount/HoldingResourcesCount.vue';
import CompanyCollection from '@/models/CompanyCollection';
import HoldingResourceCountItem from '@/components/globals/HoldingResourceCount/HoldingResourceCountItem';

export default {
    name: 'NudgeCount',
    components: { HoldingResourcesCount },

    data() {
        return {
            companies: new CompanyCollection([
                'id',
                'name',
            ]).with({
                nudges: (query) => {
                    query.select(['id']);
                },
            }).where([
                ['is_holding', '=', false],
            ]),
        };
    },

    computed: {
        /**
         * @returns {HoldingResourceCountItem[]}
         */
        items() {
            return this.companies.models.map((company) => new HoldingResourceCountItem(
                company.name,
                company.nudges.models.length,
                company.id,
            ));
        },
    },

    created() {
        this.companies.setEndPointPrefix('holding/lookup/');
        this.companies.get();
    },
};
</script>
