<template>
    <StepperTabLayout>
        <template #main>
            <HtFormCard>
                <div class="mb-4">
                    <HtFormInput
                        :id="'name'"
                        v-model="getName"
                        v-validate.disable="'required|max:255'"
                        :label="translate('Role name')"
                        :name="'name'"
                        :data-vv-as="translate('name')"
                        :show-optional="false"
                        class="mb-4"
                        :disabled="item.is_heyteam"
                    />
                    <HtFormTextarea
                        :id="'description'"
                        v-model="getDescription"
                        v-validate.disable="'max:255'"
                        :name="'description'"
                        :label="translate('Description')"
                        :maxlength="255"
                        :disabled="item.is_heyteam"
                    />
                    <HtFormSwitch
                        id="is_actions_tab_displayed_at_login"
                        v-model="item.is_actions_tab_displayed_at_login"
                        :label="
                            translate(
                                'Display actions tab at login'
                            )
                        "
                        name="is_actions_tab_displayed_at_login"
                        :show-optional="false"
                    />
                    <HtFormSelector
                        id="default_sort_dashboard"
                        v-model="defaultSortDashboard"
                        v-validate.disable="'required'"
                        specific-key="value"
                        track-by="value"
                        name="default_sort_dashboard"
                        :label="translate('Default sort on dashboard')"
                        :show-optional="false"
                        :options="[
                            {
                                name: translate('By date'),
                                value: 'date',
                            },
                            {
                                name: translate('By action'),
                                value: 'action',
                            },
                            {
                                name: translate('By user'),
                                value: 'user',
                            },
                        ]"
                        is-single
                        :is-searchable="false"
                        :allow-empty="false"
                    />
                </div>
            </HtFormCard>
        </template>
        <template #buttonsFooter>
            <Button
                v-if="item.is_removable && !item.isNew()"
                class="negative mr-4"
                :state="buttonState"
                @click="onDelete"
            >
                <t>Delete</t>
            </Button>
            <Button
                :state="buttonState"
                @click="onSave"
            >
                <t v-if="canUpdate">
                    Validate
                </t>
                <t v-else>
                    Next
                </t>
            </Button>
        </template>
        <template #modalables>
            <Modalable
                ref="companyRoleRemoveModal"
                class="modalable-1 small"
                :mode="2"
                :element-delete-label="translate('this role')"
            />
        </template>
    </StepperTabLayout>
</template>

<script>
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import CompanyRole from '@/models/CompanyRole';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'RolesAndPermissionsFormSettings',
    components: { HtFormSwitch, HtFormSelector },
    props: {
        item: {
            type: CompanyRole,
            required: true,
        },
    },
    data() { return { defaultSortDashboard: this.item.default_sort_dashboard }; },
    computed: {
        buttonState() {
            return this.item._state.isSaving ? 'loading' : 'idle';
        },
        isAdminRole() {
            return this.item.name === CompanyRole.NAME_ADMIN;
        },
        getName: {
            get() {
                if (this.item.is_heyteam) {
                    return this.item.alias_translated;
                }
                return this.item.alias;
            },
            set(val) {
                if (!this.item.is_heyteam) {
                    this.item.alias = val;
                }
            },
        },
        getDescription: {
            get() {
                if (this.item.is_heyteam) {
                    return this.item.description_translated;
                }
                return this.item.description;
            },
            set(val) {
                if (!this.item.is_heyteam) {
                    this.item.description = val;
                }
            },
        },
        canUpdate() {
            return ((this.item.is_editable && !this.item.is_heyteam) || typeof this.item.id === 'undefined');
        },
    },
    methods: {
        isDefaultSortDashboardEdited() {
            if (this.item.default_sort_dashboard !== this.defaultSortDashboard) {
                if (!this.canUpdate) {
                    this.item.fromSaveState();
                }
                this.$set(this.item, 'default_sort_dashboard', this.defaultSortDashboard);
                return true;
            }
            return false;
        },
        async onSave() {
            if (!this.isDefaultSortDashboardEdited() && !this.canUpdate) {
                this.$emit('goTo', 'permissions');
                return;
            }
            if (await this.$validator.validateAll()) {
                await this.item.save();
                this.$store.dispatch('roles/fetchRoles', true);
                this.$emit('goTo', 'permissions');
            }
        },
        async onDelete() {
            if (!this.item.is_editable) {
                return;
            }
            if (await this.$refs.companyRoleRemoveModal.deleteWindow({ content: this.translate('Are you sure you want to delete this role? Once you click on delete, you will no longer be able to access this role.') })) {
                await this.item.delete();
                await this.$store.dispatch('roles/fetchRoles', true);
                this.$router.push({ name: 'SettingsRolesAndPermissions' });
            }
        },
    },
};
</script>

<style lang="scss" scoped src="./RolesAndPermissionsFormSettings.scss"></style>
