<template>
    <div class="form-2">
        <div class="mb-10">
            <VueGoogleAutocomplete
                :id="'address-'+requirement.id"
                :ref="'address'+requirement.id"
                :placeholder="translate('Find an address...')"
                :disabled="!isParticipant || isDisabled"
                :data-cy="`address-${requirement.id}`"
                @placechanged="getAddressData(arguments[1], companyUserRequirement)"
                @no-results-found="noResultsFound"
            />
        </div>
        <div>
            <HtFormInput
                :id="requirement.name"
                v-model="companyUserRequirement.value.value"
                name="googleAddress"
                cypress="googleAddress"
                :is-required="requirement.is_mandatory"
                :type="'text'"
                :disabled="true"
                :class="unknowAddress ? 'mb-3' : ''"
            />
            <div
                v-for="(errorMessage, errorIndex) in errors.collect(requirement.name)"
                :key="errorIndex"
                class="error-message-custom"
            >
                <t>{{ errorMessage }}</t>
            </div>
            <div
                v-if="unknowAddress"
                :key="'unknow-address'"
                class="mb-5"
            >
                <div
                    class="error-message-custom"
                >
                    <t>This address is unknown to our services.</t>
                </div>

                <HtButton
                    class="mt-4"
                    @click.native="addAddressManually"
                >
                    <t>Add manually</t>
                </HtButton>
            </div>
        </div>
    </div>
</template>

<script>
import VueGoogleAutocomplete from '@/components/tools/VueGoogleAutocomplete.vue';
import HtButton from '@/components/globals/HtButton.vue';
import UserRequirementEditMixin from './UserRequirementEditMixin';

export default {
    name: 'UserRequirementAddressEdit',

    components: {
        HtButton,
        VueGoogleAutocomplete,
    },

    mixins: [UserRequirementEditMixin],

    inject: ['$validator'],

    props: {
        isParticipant: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            default: 0,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            unknowAddress: null,
        };
    },

    created() {
        if (!this.companyUserRequirement.value) {
            this.companyUserRequirement.value = {
                google: {
                    address_components: [],
                    formatted_address: '',
                    place_id: '',
                },
                value: '',
            };
        } else {
            this.companyUserRequirement.value = JSON.parse(this.companyUserRequirement.value);
        }
    },

    methods: {
        getAddressData(data, companyUserRequirement) {
            this.unknowAddress = null;
            this.$refs[`address${companyUserRequirement.id}`].clear();

            this.companyUserRequirement.value = {
                google: {
                    address_components: data.address_components,
                    formatted_address: data.formatted_address,
                    place_id: data.place_id,
                },
                value: data.formatted_address,
            };

            this.updateRequirement();

            this.$emit('onChangeValue', this.companyUserRequirement.id, true);
        },

        noResultsFound(place) {
            this.unknowAddress = place.name;
        },

        addAddressManually() {
            this.companyUserRequirement.value = {
                google: {
                    address_components: [],
                    formatted_address: '',
                    place_id: '',
                },
                value: this.unknowAddress,
            };

            this.updateRequirement();
            this.$emit('onChangeValue', this.companyUserRequirement.id, true);

            this.unknowAddress = null;
        },
    },
};
</script>

<style lang='scss' src='./UserRequirementAddressEdit.scss' scoped />
