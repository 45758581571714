<template>
    <div class="widget">
        <div class="miniblock">
            <slot name="miniblock" />
        </div>
        <div class="widget-info">
            <span class="title">
                <slot name="title">Title</slot>
            </span>
            <span class="subtitle">
                <slot name="subtitle">Subtitle</slot>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HoldingWidget',
};
</script>

<style lang="scss" scoped>
.widget {
    padding: 3.2rem;
    display: flex;
    justify-content: space-between;
}
.miniblock {
    height: 4.4rem;
    width: 4.4rem;
    font-weight: 900;
    font-size: 4.0rem;
    line-height: 4.8rem;
    color: #5869FF;
}
.widget-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.title {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.4rem;
}
.subtitle {
    font-size: 1.6rem;
}
</style>
