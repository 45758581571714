<template>
    <div class="mb-5">
        <HtFormGroup
            :label="label"
            :is-required="!showOptional"
            :show-asterisk="!showOptional"
        >
            <div class="form-row">
                <HtFormInput
                    id="new-value"
                    v-model.trim="newValue"
                    v-validate="{ required: isRequired, regex: regex }"
                    :data-vv-as="translate('domain')"
                    class="form-input"
                    type="text"
                    name="new-value"
                    :placeholder="placeholder"
                    @onKeyupEnter="addValue(newValue)"
                />
                <FontAwesomeIcon
                    id="icon-new-value"
                    icon="plus-circle"
                    class="add-icon ml-2"
                    @click="addValue(newValue)"
                />
            </div>

            <div
                v-for="(value, index) in list"
                :key="index"
                class="form-row"
            >
                <HtFormInput
                    :id="`value-${index}`"
                    :value="list[index]"
                    :name="`value-${index}`"
                    type="text"
                    class="form-input"
                    :disabled="true"
                />
                <FontAwesomeIcon
                    :id="`icon-remove-value-${index}`"
                    icon="minus-circle"
                    class="remove-icon ml-2"
                    @click="removeValue(index)"
                />
            </div>
        </HtFormGroup>
    </div>
</template>

<script>
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import HtFormMixin from '@/components/globals/HtFormMixin';

export default {
    name: 'HtFormList',

    components: { HtFormGroup, HtFormInput },

    mixins: [
        HtFormMixin,
    ],

    props: {
        value: {
            type: Array,
            default: () => [],
        },
        regex: {
            type: RegExp,
            default: () => /.*/,
        },
    },

    data() {
        return {
            newValue: '',
            list: this.value.slice(),
        };
    },

    computed: {
        isRequired() {
            return !this.showOptional && this.list.length === 0;
        },
    },
    watch: {
        list(newValues) {
            this.$emit('input', newValues.slice());
        },
    },

    methods: {
        async addValue(value) {
            if (value === '') {
                return;
            }

            await this.$validator.validateAll();
            if (this.errors.any()) return;

            this.list.push(value);
            this.newValue = '';
        },
        removeValue(index) {
            this.list.splice(index, 1);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.form-row {
    display: flex;
    align-items: start;
}

.form-input {
    width: 100%;
    margin-bottom: 0.5em;
}

.add-icon{
    color: $semantic-success;
    margin-top: 0.7em;
}
.remove-icon{
    color: $semantic-error;
    margin-top: 0.7em;
}

</style>
