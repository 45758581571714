<template>
    <div>
        <slot />

        <div>
            <div
                v-if="hasRoles"
                class="aop-participant-block"
            >
                <h4>
                    <t>A role</t>
                </h4>
                <div class="search_content">
                    <transition name="fade">
                        <div class="card-7 flex-container no-border">
                            <span>
                                <div
                                    v-for="(item, index) in roles"
                                    :key="`role-${index}`"
                                    class="item"
                                    :class="{selected: item.selected, 'mandatory': item.is_mandatory}"
                                    :data-cy="`role-${item.name}`"
                                    @click="onSelect(item, index)"
                                >
                                    <div
                                        v-user-image="$Utils.getCompanyComponentRoleInitials(item.alias, null, null, item.name)"
                                        class="item-image role-only"
                                        :class="item.name"
                                        :title="item.alias"
                                        :style="{'background-size': '1.75em'}"
                                    />
                                    <div class="item-content">
                                        <span v-if="isRoleEmployee(item)">
                                            <t>Program Recipient</t>
                                            <span v-if="!hasFrenchLanguage"> {{ `(${translate(item.alias)})` }}</span>
                                        </span>
                                        <span v-else>{{ item.alias }}</span>
                                    </div>
                                    <div class="item-legend">
                                        <IconCheck />
                                    </div>
                                </div>
                            </span>
                        </div>
                    </transition>
                </div>
            </div>

            <div
                v-if="hasCompanyGroup && companyGroups.isLoaded()"
                class="aop-participant-block"
            >
                <h4>
                    <t>A group</t>
                </h4>
                <div class="search_content">
                    <transition name="fade">
                        <div class="card-7 flex-container no-border">
                            <span>
                                <div
                                    v-for="(item, index) in companyGroups.models"
                                    :key="`companygroup-${index}`"
                                    class="item"
                                    :class="{selected: item.selected}"
                                    :data-cy="`companygroup-${item.name}`"
                                    @click="onSelect(item, index)"
                                >
                                    <div
                                        v-user-image="$Utils.getCompanyComponentRoleInitials(item.alias, null, null, item.name)"
                                        class="item-image role-only"
                                        :class="item.name"
                                        :title="translate(item.alias)"
                                        :style="{'background-size': '1.75em'}"
                                    />
                                    <div class="item-content"><t>{{ item.alias }}</t></div>
                                    <div class="item-legend">
                                        <IconCheck />
                                    </div>
                                </div>
                            </span>
                        </div>
                    </transition>
                </div>
            </div>

            <div
                v-if="allowUserSearch"
                class="aop-participant-block"
            >
                <h4>
                    <t>A person directly</t>
                </h4>
                <!-- SEARCH USER PANEL-->
                <div
                    class="container-search no-margin"
                    :class="{'input-loading':loading, 'input-no-result':results.length === 0 && !loading}"
                >
                    <div class="form">
                        <div
                            class="wrapper-search"
                            style="border: 1px solid #ccc;padding: 1rem;"
                        >
                            <svg class="icon icon-glass">
                                <use xlink:href="#icon-glass" />
                            </svg>
                            <input
                                ref="search_input"
                                v-model="searchInput"
                                class="s-input"
                                :placeholder="translate('Find an existing user')"
                                type="text"
                                data-cy="user-search"
                            >
                        </div>
                    </div>
                    <div
                        v-show="hasSearched && results.length === 0 && !loading"
                        class="search-no-results"
                    >
                        <t>No results</t>
                    </div>
                </div>

                <div class="search_selected">
                    <div
                        v-show="selected.length"
                        class="card-7 flex-container"
                    >
                        <transition-group name="fade">
                            <div
                                v-for="(item, index) in selected"
                                :key="`selected-user-${index}`"
                                class="item selected"
                                :class="{locked:item.locked}"
                                :data-cy="`selected-user-${index}`"
                                @click="item.selected=!item.selected;onRemoveFromSelected(item, index)"
                            >
                                <div
                                    v-if="isMaleWithoutImage(item)"
                                    class="item-image"
                                    :style="{'background-image': `url('/static/images/Illu_Man.png')`}"
                                />
                                <div
                                    v-else-if="isFemaleWithoutImage(item)"
                                    class="item-image"
                                    :style="{'background-image': `url('/static/images/Illu_Woman.png')`}"
                                />
                                <div
                                    v-else-if="isAnonymousWithoutImage(item)"
                                    class="item-image"
                                    :style="{'background-image': `url('/static/images/Illu_Anonymous.png')`}"
                                />
                                <div
                                    v-else
                                    class="item-image"
                                    :style="{'background-image': `url(${$Utils.resolveS3PublicImage(item.image || item.company_user.image)})`}"
                                />
                                <div class="item-content">
                                    {{ item.firstname || item.company_user.firstname }} {{ item.lastname || item.company_user.lastname }}
                                </div>
                                <div class="item-legend">
                                    <IconCheck />
                                </div>
                            </div>
                        </transition-group>
                    </div>
                </div>

                <div class="search_content">
                    <transition name="fade">
                        <div
                            v-show="results.length"
                            class="card-7 flex-container"
                        >
                            <span>
                                <div
                                    v-for="(item, index) in results"
                                    v-if="!item.selected"
                                    :key="`user-${index}`"
                                    :class="{selected: item.selected}"
                                    class="item"
                                    :data-cy="`user-${index}`"
                                    @click="onSelectUser(item, index)"
                                >
                                    <div
                                        v-if="isMaleWithoutImage(item)"
                                        class="item-image"
                                        :style="{'background-image': `url('/static/images/Illu_Man.png')`}"
                                    />
                                    <div
                                        v-else-if="isFemaleWithoutImage(item)"
                                        class="item-image"
                                        :style="{'background-image': `url('/static/images/Illu_Woman.png')`}"
                                    />
                                    <div
                                        v-else-if="isAnonymousWithoutImage(item)"
                                        class="item-image"
                                        :style="{'background-image': `url('/static/images/Illu_Anonymous.png')`}"
                                    />
                                    <div
                                        v-else
                                        class="item-image"
                                        :style="{'background-image': `url(${$Utils.resolveS3PublicImage(item.image)})`}"
                                    />
                                    <div class="item-content">
                                        {{ item.firstname }} {{ item.lastname }}
                                        <b v-if="item.company_role">
                                            <t v-if="item.company_role.is_heyteam">{{ item.company_role.alias }}</t>
                                            <span v-else>{{ item.company_role.alias }}</span>
                                        </b>
                                    </div>
                                    <div class="item-legend">
                                        <IconCheck />
                                    </div>
                                </div>
                            </span>
                        </div>
                    </transition>
                </div>
            </div>

            <transition name="fade">
                <div
                    v-show="showValidate"
                    class="modal-actions"
                >
                    <Button
                        :data-cy="'search-role-form-submit'"
                        @click="onValidate"
                    >
                        <t>Save</t>
                    </Button>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

import InviteUser from '@/components/InviteUser.vue';
import CompanyGroupCollection from '@/models/CompanyGroupCollection';
import CompanyRoleCollection from '@/models/CompanyRoleCollection';
import get from 'lodash.get';
import CompanyRole from '../../models/CompanyRole';

export default {
    name: 'SearchRolePanel',
    components: {
        InviteUser,
    },

    inject: ['modal'],
    props: {
        multipleMode: {
            type: Boolean,
            default: false,
        },
        is_assignable_onboardee: {
            type: Boolean,
            default: false,
        },
        hasCompanyGroup: {
            type: Boolean,
            default: false,
        },
        hasRoles: {
            type: Boolean,
            default: true,
        },
        allowUserSearch: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            roles: [],
            companyGroups: new CompanyGroupCollection([
                'id',
                'name',
                'alias',
            ]).where([
                ['is_viewable', '=', 1],
            ]),
            companyRoles: new CompanyRoleCollection([
                'id',
                'name',
                'alias',
                'description',
                'is_removable',
                'is_heyteam',
            ])
                .where([['is_assignable_resource', '=', 1]]),
            results: [],
            selected: [],
            intelligentSelection: [],

            min_char: 1,
            min_time: 500,
            interval_id: null,
            searchInput: '',
            loading: false,
            hasSearched: false,

            showValidate: false,

            config: {},
            default_config: {
                onResult: () => {
                },
                onSelect: () => {
                },
                onValidate: () => {
                },
                limitResult: 10,
                selected: [],
                intelligentSelection: [],
            },
        };
    },

    computed: {
        showNoResult() {
            return this.hasSearched && this.results.length === 0 && !this.loading;
        },
        hasFrenchLanguage() {
            return this.shared.session.companyUser.company_language.key === 'fr';
        },
    },

    watch: {
        searchInput(input) {
            const { getArrayDifference } = this.$Utils;

            clearInterval(this.interval_id);
            this.interval_id = setInterval(() => {
                clearInterval(this.interval_id);

                if (input.length >= this.min_char) {
                    this.onSearch(input.trim());
                } else {
                    this.results = getArrayDifference([...this.intelligentSelection], this.selected, 'id');
                }
            }, this.min_time);
        },
    },

    methods: {
        async init(config) {
            await this.companyRoles.get();
            const { getArrayDifference, clone } = this.$Utils;
            this.config = { ...this.default_config, ...config };

            if (this.config.selected.length > 0) {
                this.config.selected = this.config.selected.map((selected) => {
                    if (selected.role) {
                        selected.company_role = { ...selected.role, is_removable: selected.is_removable };
                    }

                    if (selected.user) {
                        selected.company_user = selected.user;
                    }

                    return selected;
                });
            }

            if (this.config.title) { this.modal.setTitle(this.config.title); }

            // Handle selected
            const { selectedRoles, selectedUsers, selectedCompanyGroups } = this.partitionRolesAndUsers(this.config.selected || []);

            // loop over selected
            const selectRoles = (selected, allRolesInDb) => {
                if (!selected.length) return allRolesInDb;

                // Get role name
                const selectedRoles = selected.map((item) => (item.company_role
                    ? item.company_role.is_heyteam ? item.company_role.name : item.company_role.alias
                    : []));

                return allRolesInDb
                    .map((item) => {
                        const selectedRole = selected.find((role) => role?.company_role_id === item.id);
                        return {
                            ...item,
                            selected: selectedRoles.includes(item.is_heyteam ? item.name : item.alias),
                            is_removable: item.is_removable,
                            is_mandatory: get(selectedRole, 'is_mandatory', false),
                        };
                    });
            };
            // Role panel
            if (this.hasRoles) {
                this.roles = selectRoles(
                    selectedRoles,
                    this.companyRoles.$.models
                        .filter((item) => (
                            !this.is_assignable_onboardee
                                ? item.name !== CompanyRole.NAME_EMPLOYEE
                                : true))
                        .map((item) => ({
                            id: item.id,
                            alias: item.alias_translated,
                            selected: false,
                            name: item.name,
                            is_removable: item.is_removable,
                            is_heyteam: item.is_heyteam,
                        })),
                )
                    .map((item) => ({ company_role: item, ...item }));
            }

            if (this.hasCompanyGroup) {
                await this.companyGroups.get();

                this.companyGroups.models.each((item) => {
                    item.selected = selectedCompanyGroups.some((selecteditem) => selecteditem.company_group_id == item.id);
                });
            }

            // User panel
            this.searchInput = '';
            this.selected = selectedUsers;

            this.results = [];

            if (this.config.intelligentSelection) {
                this.intelligentSelection = getArrayDifference(clone(this.config.intelligentSelection, true), this.selected, 'id');
                this.results = getArrayDifference([...this.intelligentSelection], this.selected, 'id');
            }

            this.loading = false;
            this.showValidate = false;
            this.hasSearched = false;
        },

        partitionRolesAndUsers(participants) {
            const flattenUsers = (users) => users.map((user) => ({ ...user, ...user.company_user }));
            const selectedUsers = flattenUsers(participants.filter((item) => item.company_user));
            const selectedRoles = participants.filter((item) => item.company_role_id);

            const selectedCompanyGroups = participants.filter((item) => item.company_group_id);

            return { selectedRoles, selectedUsers, selectedCompanyGroups };
        },

        onRemoveFromSelected(item, index) {
            if (item.locked) return;

            this.selected.splice(index, 1);

            this.showValidate = this.multipleMode;
            if (!this.multipleMode) { this.onUnselect(); }
        },

        onSearch(v) {
            this.loading = true;
            this.hasSearched = true;
            this.API.post('company_user_search', {
                search: v,
                limit: 10,
            }, {
                200: ({ search }) => {
                    this.loading = false;
                    // Remove selected users from results
                    const searchWithUser = search.map((user) => ({ ...user, role: 'user', company_user: { ...user } }));

                    this.results = [...this.$Utils.getArrayDifference([...searchWithUser], this.selected, 'id')];
                },
                onError: () => {
                    this.loading = false;
                },
            });
        },

        onSelectUser(item, index) {
            this.selected.push(item);
            this.onSelect(item, index);
        },

        onSelect(item) {
            if (item.is_mandatory && item.selected) return;
            item.selected = !item.selected;
            this.showValidate = this.multipleMode;
            this.$emit('onSelect', item);
            this.config.onSelect(item);
        },

        filterOutSelected(list) {
            return list.filter((item) => !item.selected);
        },

        onUnselect() {
            this.$emit('onUnselect');
            this.config.onUnselect();
        },

        onValidate() {
            const stitchUsersAndRoles = (selectedRoles, selectedUsers, selectedCompanyGroups) => {
                selectedRoles = selectedRoles.map((item) => ({
                    company_role_id: item.id,
                    company_role: item,
                }));

                selectedCompanyGroups = selectedCompanyGroups.map((item) => ({
                    company_group_id: item.id,
                    company_group: item.toObject(),
                }));

                const mappedSelectedUsers = selectedUsers.map((user) => ({
                    company_user_id: user.id,
                    company_user: user,
                }));

                return [
                    ...selectedRoles.filter((item) => item.company_role.selected),
                    ...mappedSelectedUsers,
                    ...selectedCompanyGroups.filter((item) => item.company_group.selected),
                ];
            };
            const x = stitchUsersAndRoles(this.roles, this.selected, this.companyGroups.models);

            // this.$Notifier('App').showInfo(this.translate('User roles updated'));
            this.$emit('onValidate', x);
            this.config.onValidate(x);
        },

        isMaleWithoutImage(item) {
            return item.gender === 'male' && item.image === this.$Utils.getDefaultUserImage();
        },

        isFemaleWithoutImage(item) {
            return item.gender === 'female' && item.image === this.$Utils.getDefaultUserImage();
        },

        isAnonymousWithoutImage(item) {
            return item.gender === null && item.image === this.$Utils.getDefaultUserImage();
        },

        isRoleEmployee(role) {
            return role.name === CompanyRole.NAME_EMPLOYEE;
        },
    },

};
</script>

<style lang="scss" scoped>

.aop-participant-block {
    margin-bottom: 2em;

    h4 {
        margin-bottom: 1em;
    }
}

.card-7.flex-container .item {
    word-break: break-word;
    font-size: 0.9em;

    .item-image {
        border-radius: 35%;
    }

    &.mandatory {
        cursor: default;
        color: darkgrey;
        &:before {
            box-shadow: none;
        }
    }
}

.role-only.user-initials {
    font-size: 1.5rem !important;
}

.container-search .search-no-results {
    top: 38px;
}

.department-list {
    margin-top: 70px;
}
</style>
