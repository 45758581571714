import Vue from 'vue';
import Model from '@tony.caron/node-model-proxy/Model';
import CompanySurveyQuestion from './CompanySurveyQuestion';
import CompanySurveyQuestionAnswerLocale from './CompanySurveyQuestionAnswerLocale';
import CompanySurveyQuestionAnswerCollection from './CompanySurveyQuestionAnswerCollection';
import CompanyUserSurveyQuestionAnswer from './CompanyUserSurveyQuestionAnswer';
import CompanyEntityValue from './CompanyEntityValue';

export default class CompanySurveyQuestionAnswer extends Model {
    modelConfig() {
        return {
            collection: CompanySurveyQuestionAnswerCollection,
        };
    }

    modelRelations() {
        return {
            companySurveyQuestion: () => this.belongsTo(CompanySurveyQuestion, 'company_survey_question_id', 'id'),
            companyUserSurveyQuestionAnswer: () => this.hasMany(CompanyUserSurveyQuestionAnswer, 'company_survey_question_answer_id', 'id'),
            companySurveyQuestionAnswerLocale: () => this.hasMany(CompanySurveyQuestionAnswerLocale, 'company_survey_question_answer_id', 'id'),
            value: () => this.belongsTo(CompanyEntityValue, 'company_entity_value_id', 'id'),
        };
    }

    modelCustomAttributes() {
        return {
            locales_by_key: {},
            iconPath: null,
            text: null,
        };
    }

    modelDefaultValues() {
        return {
            company_entity_value_id: null,
            next_content_order: null,
        };
    }

    modelEvents() {
        return {
            loaded: () => {
                Vue.prototype.$modelUtils.createLocales(this.company_survey_question_answer_locale, ['text']);
            },
        };
    }

    modelAccessors() {
        return {
            locales_by_key: () => Vue.prototype.$modelUtils.createLocalesByKey(this.company_survey_question_answer_locale),
            text: () => this.localize('text'),
            iconPath: () => {
                switch (this.order) {
                case 1:
                    return '/static/icons/survey/smiley/SmileyNotGood.svg';
                case 2:
                    return '/static/icons/survey/smiley/SmileyMedium.svg';
                case 3:
                    return '/static/icons/survey/smiley/SmileyGood.svg';
                case 4:
                    return '/static/icons/survey/smiley/SmileyVeryGood.svg';
                }
                return null;
            },
        };
    }

    localize(field, languageKey, defaultLanguageKey) {
        return Vue.prototype.$modelUtils.localize(this.locales_by_key, field, languageKey, defaultLanguageKey);
    }
}
