<template>
    <div>
        <UserNotificationList :company_user_id="shared.session.companyUser.id" />
    </div>
</template>

<script>
import UserNotificationList from '@/components/resources/notification/UserNotificationList.vue';

export default {
    name: 'AccountNotification',
    components: {
        UserNotificationList,
    },
};
</script>
