import Model from '@tony.caron/node-model-proxy/Model';
import CompanyRoleProgramCollection from './CompanyRoleProgramCollection';
import CompanyProgram from './CompanyProgram';
import CompanyRole from './CompanyRole';

export default class CompanyRoleProgram extends Model {
    modelRelations() {
        return {
            companyProgram: () => this.belongsTo(CompanyProgram, 'company_program_id', 'id'),
            companyRole: () => this.belongsTo(CompanyRole, 'company_role_id', 'id'),
        };
    }

    modelConfig() {
        return {
            collection: CompanyRoleProgramCollection,
        };
    }
}
