<template>
    <div class="branding">
        <div class="branding-elements">
            <div class="branding-items">
                <IconBranding
                    v-for="icon in icons"
                    :key="icon"
                    :icon="icon"
                    :style="svgCss"
                    class="branding-icon"
                />
                <Button
                    class="branding-button"
                    :style="buttonCss"
                >
                    Test
                </Button>
            </div>
            <div
                class="branding-navbar"
                :style="navbarCss"
            >
                <button class="branding-navbar-button">
                    <svg class="branding-navbar-icon"><use
                        stroke-width="5"
                        xlink:href="#icon-close"
                    /></svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import IconBranding from '@/components/globals/IconBranding.vue';

export default {
    name: 'CompanyBrandingViewer',
    components: {
        IconBranding,
    },

    props: {
        color: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            icons: [
                '#icon-branding-close',
                '#icon-branding-add',
                '#icon-branding-delete',
                '#icon-branding-download',
                '#icon-branding-edit',
                '#icon-branding-go',
            ],
        };
    },

    computed: {
        svgCss() {
            return {
                fill: this.color,
            };
        },
        buttonCss() {
            return {
                backgroundColor: `${this.color}!important`,
            };
        },
        navbarCss() {
            return {
                backgroundColor: this.color,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.branding {
	padding: 20px;
	background-color: $grey-ultra-light;;
	border-radius: 5px;
}

.branding-elements {
	padding: 20px;
	background-color: #FFF;
	border-radius: 5px;
}

.branding-icon {
	width: 42px;
	height: 42px;
	margin-right: 10px;
}

.branding-button {
	margin: 0 !important;
}

.branding-items {
	display: flex;
}

.branding-navbar {
	margin-top: 20px;
	height: 60px;
	color: white;
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
}

.branding-navbar-icon {
	width: 100%;
	height: 100%;
	padding: 8px;
	--color1: white;
}

.branding-navbar-button {
	padding: 0;
    width: 30px;
    height: 30px;
	margin-right: 15px;
    background-color: transparent;

}
</style>
