<template>
    <div>
        <div
            v-if="showBug"
            :class="'bug-wrapper'"
            @mouseover="toggleClose(true)"
            @mouseleave="toggleClose(false)"
        >
            <div class="pt-4 pr-4">
                <FontAwesomeIcon
                    v-show="showClose"
                    class="bug-wrapper-inside-close"
                    :icon="['fal', 'times']"
                    @click.stop="hideBug"
                />
                <div
                    :title="translate('Report a bug')"
                    class="bug-wrapper-inside branding-color-hover"
                    @click="openBugModal"
                >
                    <FontAwesomeIcon
                        class="bug-wrapper-inside-icon"
                        :icon="['fad', 'bug']"
                    />
                </div>
            </div>
        </div>
        <Modalable
            ref="bugReportModalable"
            class="modalable-1 medium"
            :mode="2"
        >
            <BugReport ref="bugReport" />
        </Modalable>
    </div>
</template>
<script>

import BugReport from '@/components/tools/BugReport.vue';

export default {
    name: 'BugIcon',
    components: {
        BugReport,
    },

    data() {
        return {
            showClose: false,
            showBug: true,
            hoverIcon: false,
            isOpenIcon: false,
        };
    },

    computed: {
        getIcon() {
            return (this.isOpenIcon) ? ['fal', 'chevron-left'] : ['fal', 'chevron-right'];
        },
    },

    shared: {
        wrikeApi: {
            authUser: {
                type: Object,
                default: {
                    code: null,
                    expired_at: null,
                },
            },
        },
    },

    methods: {
        openBugModal() {
            this.openBugIcon();
        },

        openBugIcon() {
            this.$refs.bugReportModalable.open();
            this.isOpenIcon = !this.isOpenIcon;
        },

        toggleClose(show) {
            this.showClose = show;
        },

        hideBug() {
            this.showBug = false;
        },
    },
};
</script>
<style lang="scss" scoped>
    .bug-wrapper {
        position: fixed;
        left: 4em;
        bottom: 4em;
        z-index: 1000;

        &-inside {
            border-radius: 50%;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
            background: white;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 16px;

            &-icon {
                width: 16px;
                height: 16px;
            }

            &-close {
                cursor: pointer;
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }
    }
</style>
