<template>
    <div>
        <ResultTextItem
            v-for="answer in answers"
            :key="answer.id"
            class="item"
            :text="answer.text"
        />
    </div>
</template>

<script>
import freeResultsProps from '../freeResultsProps';
import ResultTextItem from './ResultTextItem.vue';

export default {
    name: 'ResultsText',
    components: { ResultTextItem },
    mixins: [
        freeResultsProps,
    ],
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
.item {
    max-width: 100%;
    &:not(:last-child) {
        border-bottom: 1px dashed $grey-ultra-light;
    }
}
</style>
