<template>
    <div class="no">
        <img :src="image">
        <div class="no__title">
            <t>No match</t>
        </div>
        <div class="no__description">
            <t>Edit or remove the selected filters to display other results</t>
        </div>
        <div
            v-if="showRemoveButton"
            class="no_actions"
        >
            <Button @click="removeFilters">
                <t>Remove filters</t>
            </Button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DashboardProgramsEmpty',
    props: {
        showRemoveButton: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        image() {
            return '/static/images/dashboard/widgets/nextKeyDates.svg';
        },
    },
    methods: {
        removeFilters() {
            this.$store.dispatch('dashboard/programs/clearAllFilters');
            this.$store.dispatch('dashboard/programs/fetchPage', 1);
        },
    },
};
</script>

<style lang="scss" scoped>
$margin: 15px;

.no {
    display: flex;
    align-items: center;
    flex-direction: column;

    font-size: 14px;
    line-height: 140%;

    & img {
        width: 14.4rem;
        height: 14.4rem;
    }

    & .no__title {
        font-size: 16px;
        font-weight: bold;
        margin: $margin 0;
    }

    & .no__description {
        margin-bottom: $margin;
    }

    & .no_actions {
        margin-bottom: $margin;
    }
}
</style>
