import Model from '@tony.caron/node-model-proxy/Model';
import Vue from 'vue';
import CompanyProgramNotificationTemplateLocale from '@/models/CompanyProgramNotificationTemplateLocale';
import CompanyProgramNotificationTemplateCollection from './CompanyProgramNotificationTemplateCollection';

export default class CompanyProgramNotificationTemplate extends Model {
    static CANAL_TYPE_EMAIL = 'email';

    static CANAL_TYPE_SMS = 'sms';

    static TEMPLATE_INVITE = 'invite';

    static TEMPLATE_INVITE_ON_PROGRAM = 'inviteOnProgram';

    static TEMPLATE_ENROLLEE_PROGRAM_FINISHED = 'enrolleeProgramFinished';

    static TEMPLATE_RECIPIENTS_PROGRAM_FINISHED = 'recipientsProgramFinished';

    modelConfig() {
        return {
            collection: CompanyProgramNotificationTemplateCollection,
        };
    }

    modelInConvertor() {
        return {
            is_enabled: (n) => Boolean(n),
        };
    }

    modelCustomAttributes() {
        return {
            locales_by_key: {},
            name_translated: null,
        };
    }

    modelEvents() {
        return {
            loaded: () => {
                Vue.prototype.$modelUtils.createLocales(this.locales);
            },
        };
    }

    modelAccessors() {
        return {
            locales_by_key: () => Vue.prototype.$modelUtils.createLocalesByKey(this.locales),
            name_translated: () => this.localize('name'),
        };
    }

    modelRelations() {
        return {
            locales: () => this.hasMany(CompanyProgramNotificationTemplateLocale, 'id', 'company_program_notification_template_id'),
        };
    }

    localize(field, languageKey, defaultLanguageKey) {
        return Vue.prototype.$modelUtils.localize(this.locales_by_key, field, languageKey, defaultLanguageKey);
    }
}
