<template>
    <div class="box-center">
        <div class="main-container">
            <div class="progress-bar">
                <div
                    class="progress"
                    :style="{ width: `${percentage}%` }"
                />
            </div>
            <div class="progress-label">
                {{ percentage }} %
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HtCompletionBar',
    props: {
        percentage: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.box-center {

    display: flex;
    justify-content: center;

    .main-container {
        width: 126px;
        display: flex;
        align-items: center;
        gap: .5rem;
        border: solid .5px $grey-ultra-light;
        border-radius: .5rem;
        padding: .5rem;
        background-color: #FBFBFB;

        & .progress-bar {
            width: 100px;
            height: 1rem;
            background-color: #E1E1E1;
            border-radius: 4px;
            display: flex;
            align-items: center;
            overflow: hidden;

            & .progress {
                height: 100%;
                border-radius: .5rem;
                background: linear-gradient(90deg, #21282A 0%, #7D7D7D 100%);
            }
        }
    }
}
</style>
