<template>
    <HtModal ref="modal">
        <template #header>
            <t>Import answers</t>
        </template>
        <template #default>
            <div class="import-answers">
                <HtFormGroup
                    class="mb-2"
                    :label="translate('Import from a CSV file')"
                    :is-required="true"
                />
                <UploadBar
                    ref="uploadBar"
                    v-model="companyFile"
                    :media="['csv']"
                    @onUploadProgress="toggleButtonState('loading')"
                    @onUploadEnd="onUploadedEnd"
                />
                <div class="mb-5 mt-2 import-answers-format">
                    <p><t>Accepted file format: CSV (UTF-8 format) with columns delimited by ";"</t></p>
                    <p><t>The first row must be "answer" and the next rows must be the answers</t></p>
                    <p><t>You have one way to achieve it:</t></p>
                    <table class="table-example">
                        <tr><td>answer</td></tr>
                        <tr><td>answer #1</td></tr>
                        <tr><td>answer #2</td></tr>
                        <tr><td>etc.</td></tr>
                    </table>
                </div>
                <HtFormGroup
                    class="mb-2"
                    :label="translate('Import from referentials')"
                    :is-required="true"
                />
                <div class="ht-list-simple">
                    <t v-if="entities.models.length === 0">
                        You don't have any referential.
                    </t>
                    <div
                        v-else
                        class="ht-list-simple-container"
                    >
                        <div
                            v-for="entity in entities.models"
                            :key="entity.id"
                            class="d-flex justify-content-between ht-list-simple-container-item branding-bg-hover clickable"
                            @click="openReferential(entity.id)"
                        >
                            <span>{{ entity.name }}</span>
                            <t :count="entity.values.length">
                                {count} entry | {count} entries
                            </t>
                        </div>
                    </div>
                </div>
                <ListingEntries
                    ref="modalListingEntries"
                    :on-save="onImportReferential"
                    @on-close="$refs.uploadBar.destroy()"
                />
            </div>
        </template>
    </HtModal>
</template>
<script>
import CompanyEntityCollection from '@/models/CompanyEntityCollection';
import ListingEntries from '@/components/pages/settings/entities/form/ListingEntries.vue';
import { getExtractedCSVFileFromId } from '@/helpers/file';

export default {
    name: 'ImportAnswersModal',
    components: {
        ListingEntries,
    },

    data() {
        return {
            buttonState: 'idle',
            companyFile: null,
            withKey: false,
            entities: new CompanyEntityCollection([
                'id',
                'name',
                'slug',
            ]).with({
                values: (query) => query.select([
                    'id',
                    'company_entity_id',
                    'resourceable_type',
                    'resourceable_id',
                ]).with({
                    custom: (query) => query.select([
                        'id',
                        'name',
                    ]),
                }),
            }).where([
                ['is_enabled', '=', true],
            ]).whereHas({
                values: (query) => {
                    query.where([
                        ['is_custom', '=', true],
                    ]);
                },
            }),
        };
    },

    methods: {
        toggleButtonState(state) {
            this.buttonState = state;
        },

        open() {
            this.companyFile = null;
            this.entities.get().then(() => {
                this.$refs.modal.open();
            });
        },

        close() {
            this.$refs.modal.close();
        },

        onUploadedEnd() {
            if (this.companyFile?.detected_extension !== 'txt') {
                this.toggleButtonState('idle');
                return;
            }

            getExtractedCSVFileFromId(this.companyFile.id).then((data) => {
                this.toggleButtonState('idle');
                const importContent = data.file_content.map((content) => ({
                    name: content[Object.keys(content)[0]],
                }));

                this.$refs.modalListingEntries.open(importContent);
            }).catch(() => {
                this.$Notifier('App').showError(this.translate('Unable to complete this operation !'));
            });
        },

        openReferential(entityId) {
            const entity = this.entities.models.find((val) => val.id === entityId);

            if (entity) {
                const values = entity.values.models.map((value) => ({
                    id: value.id,
                    name: value.custom.name,
                }));
                this.$refs.modalListingEntries.open(values);
            }
        },

        onImportReferential(listReferential) {
            this.withKey = false;

            return new Promise((resolve) => {
                const formattedList = listReferential.map((value) => {
                    const item = value.name.split(',');

                    if (item.length === 2) {
                        this.withKey = true;

                        return {
                            id: null,
                            key: item[0],
                            value: item[1],
                        };
                    }

                    return {
                        id: null,
                        key: '',
                        value: item[0],
                    };
                });

                this.$emit('on-add-answers', { list: formattedList, withKey: this.withKey });
                this.close();
                resolve();
            });
        },
    },
};
</script>
<style lang="scss" scoped src="./ImportAnswersModal.scss"></style>
