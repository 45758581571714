import Model from '@tony.caron/node-model-proxy/Model';
import I18n from '../modules/i18n/I18n';

export default class CompanyGroup extends Model {
    static NAME_EMPLOYEE_DEPARTMENT = 'enrollee_department';

    modelDefaultValues() {
        return {
            description: '',
        };
    }

    modelCustomAttributes() {
        return {
            selected: false,
        };
    }

    modelAccessors() {
        return {
            alias: (n) => I18n.translate(n),
        };
    }
}
