<template>
    <div class="wrapper">
        {{ text }}
    </div>
</template>
<script>
export default {
    name: 'HtTextDescription',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
.wrapper {
    color: $grey-text;
    font-size: 1.4rem;
}
</style>
