var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLoaded)?_c('div',{staticClass:"full-width"},[_c('HtAccordion',{ref:"accordion",attrs:{"title":_vm.translate('Email | Emails', {count: _vm.companyUserEmailCustomCollection.models.length}),"count":_vm.companyUserEmailCustomCollection.$.models.length,"has-button":_vm.companyUserEmailCustomCollection.$.models.length === 0,"button-label":_vm.addButtonLabel,"is-branding":false,"is-opened":_vm.missing,"cypress":"email"},on:{"buttonClick":function($event){return _vm.openSelectModal()}}},[(_vm.companyUserEmailCustomCollection.$.models.length)?[_c('CardList',{attrs:{"options":{
                    type: _vm.configs.type,
                    perRow: _vm.configs.perRow,
                }}},_vm._l((_vm.companyUserEmailCustomCollection.$.models),function(item,index){return _c('Card',{key:index,class:{'missing': (!item.sender_company_user_id || item.company_user_email_custom_recipient.models.length === 0)},attrs:{"options":{
                        type: _vm.configs.type,
                        perRow: _vm.configs.perRow,
                    },"item":item,"data-cy":`email-card-${index}`},on:{"click":function($event){return _vm.openEditModal(item)}}},[_c('div',{attrs:{"slot":"image"},slot:"image"},[_c('div',{staticClass:"item-image image-small"},[_c('div',{staticClass:"small-image",style:({'background-image': `url(/static/icons/email.svg)`})})])]),_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('t',[_vm._v(_vm._s(item.name))])],1),_c('div',{staticClass:"email-recipients",attrs:{"slot":"extra"},slot:"extra"},[_c('ParticipantList',{attrs:{"users":item.company_user_email_custom_recipient.models,"has-group":"","position":'right'}})],1)])}),1),_c('div',{staticClass:"mt-4 text-center"},[_c('Button',{attrs:{"type":"clear","cypress":"email-add-button"},on:{"click":function($event){return _vm.openSelectModal()}}},[_vm._t('buttonText',function(){return [_vm._v(" "+_vm._s(_vm.translate(_vm.addButtonLabel))+" ")]})],2)],1)]:_c('div',{staticClass:"empty-component"},[_c('strong',[_c('t',[_vm._v("No Emails added")])],1)])],2),_c('modalable',{ref:"modalableSelect",staticClass:"modalable-1 medium",attrs:{"mode":2}},[_c('UserEmailCustomSelect',{attrs:{"company-user-id":_vm.companyUserId,"company-user-program-id":_vm.companyUserProgramId,"selected-ids":_vm.selectedIds},on:{"onUpdate":_vm.onUpdate}})],1),_c('modalable',{ref:"modalableEdit",staticClass:"modalable-1 medium",attrs:{"mode":2}},[_c('UserEmailCustomEdit',{attrs:{"id":_vm.currentId,"from":'bop',"intelligent-selection":_vm.intelligentSelection,"company-user-program-id":_vm.companyUserProgramId,"company-user-id":_vm.companyUserId,"variables":_vm.availableVariables},on:{"onUpdate":_vm.onUpdate,"onDelete":_vm.onDelete}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }