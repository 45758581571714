<template>
    <div class="page-content">
        <form
            class="form-2 spaced"
            @submit.prevent=""
        >
            <div class="preboarding-section-title">
                <InputField
                    v-model="collectionCopy['bloc_company_title']['content']['messages']['message_1'][`message_${lang}`]"
                    :label="translate('Section title')"
                    :type="'text'"
                    :name="'section_title'"
                    :validate="'required'"
                    :validate-as="translate('section title')"
                />
            </div>

            <div class="preboarding-container-text">
                <HtFormSwitch
                    :id="'first-message'"
                    v-model="collectionCopy['bloc_company_a']['is_enabled']"
                    :label="`${translate('Company introduction')}`"
                    :disabled="!collectionCopy['bloc_company_a']['is_enabled_editabled']"
                    :name="'first-message'"
                    :show-optional="false"
                />

                <RichEditable
                    key="0"
                    ref="bloc_company_a"
                    v-model="collectionCopy['bloc_company_a']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="0"
                    name="bloc_company_a"
                    :validate="collectionCopy['bloc_company_a']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('Company introduction')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    :resource-name="'preboarding'"
                    :in-high-light-white-list="flowVariablesFull"
                    class="margin-bottom"
                />
            </div>
            <div class="preboarding-container-text">
                <HtFormSwitch
                    :id="'second-message'"
                    v-model="collectionCopy['bloc_company_b']['is_enabled']"
                    :label="`${translate('Company information')}`"
                    :disabled="!collectionCopy['bloc_company_b']['is_enabled_editabled']"
                    :name="'second-message'"
                    :show-optional="false"
                />

                <RichEditable
                    key="1"
                    ref="bloc_company_b"
                    v-model="collectionCopy['bloc_company_b']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="1"
                    name="bloc_company_b"
                    :validate="collectionCopy['bloc_company_b']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('Company information')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    :resource-name="'preboarding'"
                    :in-high-light-white-list="flowVariablesFull"
                    class="margin-bottom"
                />
            </div>
            <div class="preboarding-container-text">
                <HtFormSwitch
                    :id="'third-message'"
                    v-model="collectionCopy['bloc_company_d']['is_enabled']"
                    :label="`${translate('Company mission')}`"
                    :disabled="!collectionCopy['bloc_company_d']['is_enabled_editabled']"
                    :name="'third-message'"
                    :show-optional="false"
                />

                <RichEditable
                    key="2"
                    ref="bloc_company_d"
                    v-model="collectionCopy['bloc_company_d']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="2"
                    name="bloc_company_d"
                    :validate="collectionCopy['bloc_company_d']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('Company mission')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    :resource-name="'preboarding'"
                    :in-high-light-white-list="flowVariablesFull"
                    class="margin-bottom"
                />
            </div>
            <div class="preboarding-container-text">
                <HtFormSwitch
                    :id="'fourth-message'"
                    v-model="collectionCopy['bloc_company_e']['is_enabled']"
                    :label="`${translate('Company values')}`"
                    :disabled="!collectionCopy['bloc_company_e']['is_enabled_editabled']"
                    :name="'fourth-message'"
                    :show-optional="false"
                />

                <RichEditable
                    key="3"
                    ref="bloc_company_e"
                    v-model="collectionCopy['bloc_company_e']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="3"
                    name="bloc_company_e"
                    :validate="collectionCopy['bloc_company_e']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('Company values')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    :resource-name="'preboarding'"
                    :in-high-light-white-list="flowVariablesFull"
                    class="margin-bottom"
                />
            </div>
            <div class="preboarding-container-text">
                <HtFormSwitch
                    :id="'fifth-message'"
                    v-model="collectionCopy['bloc_company_g']['is_enabled']"
                    :label="`${translate('Company pictures')}`"
                    :disabled="!collectionCopy['bloc_company_g']['is_enabled_editabled']"
                    :name="'fifth-message'"
                    :show-optional="false"
                />

                <RichEditable
                    key="4"
                    ref="bloc_company_g"
                    v-model="collectionCopy['bloc_company_g']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="4"
                    name="bloc_company_g"
                    :validate="collectionCopy['bloc_company_g']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('Company pictures')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    :resource-name="'preboarding'"
                    :in-high-light-white-list="flowVariablesFull"
                    class="margin-bottom"
                />
                <MediaList />
            </div>
        </form>
    </div>
</template>

<script>
import MediaList from '@/components/resources/media/MediaList.vue';
import CompanyFlowCollection from '@/models/CompanyFlowCollection';
import FlowHelper from '@/components/mixins/FlowHelper.vue';
import FlowVariable from '@/components/mixins/FlowVariable.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import RichEditable from '@/components/globals/RichEditable.vue';
import InputField from '@/components/globals/InputField.vue';

export default {
    name: 'CustomCompany',
    components: {
        MediaList,
        HtFormSwitch,
        RichEditable,
        InputField,
    },

    mixins: [FlowHelper, FlowVariable],
    props: {
        chatbotSlug: {
            type: String,
            required: true,
        },
        collection: {
            type: CompanyFlowCollection,
            required: true,
        },
        lang: {
            type: String,
            default: 'fr',
        },
        maxStep: {
            type: Number,
            default: 6,
        },
        step: {
            type: Number,
            default: 3,
        },
    },
};
</script>
<style lang="scss" scoped>
	@import "~@/styles/var";
	@import "~@/styles/customflow";
</style>
