import get from 'lodash.get';

export default {
    props: {
        hidedTabs: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        // ASSUMING THERE IS A DATA TABS ON COMPONENT this.hidedTabs.includes(tab.ref)
        getAvailableTabs() {
            return this.tabs.filter((tab) => {
                const isPropsHiddenTabs = this.hidedTabs.includes(tab.ref);

                const isHidden = get(tab, 'hidden', false);

                return !isPropsHiddenTabs && !isHidden;
            });
        },
    },
};
