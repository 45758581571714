<template>
    <div>
        {{ getLabel | empty }}
    </div>
</template>

<script>

export default {
    name: 'UserRequirementHexapostRead',
    props: {
        UserRequirement: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getLabel() {
            if (this.UserRequirement.value) {
                return JSON.parse(this.UserRequirement.value).label;
            }
            return null;
        },
    },
};
</script>
