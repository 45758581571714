<template>
    <div>
        <ResultMultipleChoiceItem
            v-for="choice in choices"
            :key="choice.id"
            class="item"
            v-bind="propsToPass(choice)"
        />
    </div>
</template>

<script>
import choiceResultsProps from '../choiceResultsProps';
import ResultMultipleChoiceItem from './ResultMultipleChoiceItem.vue';

export default {
    name: 'ResultMultipleChoice',
    components: { ResultMultipleChoiceItem },

    mixins: [
        choiceResultsProps,
    ],

    methods: {
        propsToPass(choice) {
            const data = { answerLabel: choice.label, isGlobalView: this.isGlobalView, isGlobalUserView: this.isGlobalUserView };

            return this.isGlobalView || this.isGlobalUserView
                ? { ...data, percent: choice.percent }
                : { ...data, isSelected: this.answers.some((answer) => answer.choice_id === choice.id) };
        },
    },
};
</script>
<style lang="scss" scoped>
.item:not(:last-child) {
    margin-bottom: 2%;
}

</style>
