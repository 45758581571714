<template>
    <StepperTabLayout>
        <template #main>
            <HtTableModel
                :collection="companyRoleUserCollection"
                :columns="columns"
                :enable-pagination="true"
                :per-page="pageSize"
                :empty-message="'This role has no user assigned'"
                :clickable="hasModal"
                :external-search="search"
                @onCellClicked="onCellClicked"
            >
                <template #cell(user)="scope">
                    <div class="d-flex align-items-center">
                        <div
                            v-user-image="scope.item.user"
                            class="user-image mr-3"
                        />
                        <span>{{ scope.item.user.fullname }}</span>
                    </div>
                </template>
                <template #cell(owner)="scope">
                    <div
                        v-if="isCompanyUserTheOwner(scope.item.user.role)"
                        class="d-flex align-items-center"
                    >
                        <span>{{ translate('Owner') }}</span>
                        <FontAwesomeIcon
                            class="branding-color ml-2"
                            :icon="'star'"
                        />
                    </div>
                </template>
                <template #cell(perimeter)="scope">
                    <ResourceFilter
                        v-if="scope.item.has_perimeter"
                        :resource-filters="scope.item.perimeters.models"
                    />
                    <span v-else>
                        <t>Filters disabled</t>
                    </span>
                </template>
            </HtTableModel>
            <CompanyRoleUserModal
                ref="roleUserModal"
                :company-role="item"
                @on-close="refresh"
            />
        </template>
        <template #buttonsFooter>
            <Button
                v-if="canAddUser()"
                class="negative"
                @click="openModal"
            >
                <t>Promote a user</t>
            </Button>
        </template>
    </StepperTabLayout>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyRole from '@/models/CompanyRole';
import ResourceFilter from '@/components/resources/ResourceFilter.vue';
import CompanyRoleUserCollection from '@/models/CompanyRoleUserCollection';
import debounce from 'lodash.debounce';
import HtDeployableSearchField from '@/components/globals/HtDeployableSearchField.vue';
import HtTableModel from '@/components/globals/HtTableModel.vue';
import CompanyRoleUserModal from './CompanyRoleUserModal.vue';

export default {
    name: 'RolesAndPermissionsFormUsersAndPerimeters',
    components: {
        ResourceFilter,
        CompanyRoleUserModal,
        HtDeployableSearchField,
        HtTableModel,
    },
    props: {
        item: {
            type: CompanyRole,
            required: true,
        },
    },

    data() {
        return {
            companyRoleUserCollection: null,
            search: null,
            filterUser: debounce(
                (filter) => this.companyRoleUserCollection.whereHas({
                    user: (query) => {
                        query.where([['fullname', 'LIKE', `%${filter}%`]]);
                    },
                })
                    .paginate({
                        perPage: this.pageSize,
                    }),
                350,
            ),
            pageSize: 20,
        };
    },

    computed: {
        columns() {
            const columnsProperties = [
                {
                    label: `${this.translate('Role')} ${this.item.alias_translated}`,
                    key: 'user',
                    sortable: false,
                    filterFunction: (filter) => this.filterUser(filter),
                    placeholder: `${this.translate('Search for a')} ${this.item.alias_translated.toLowerCase()}`,
                    class: 'w-50',
                },
            ];

            if (this.item.name === CompanyRole.NAME_ADMIN) {
                columnsProperties.push({
                    label: '',
                    class: 'd-none d-md-table-cell',
                    sortable: false,
                    key: 'owner',
                });
            }

            const rolesWithoutPerimeterColumnFilter = [CompanyRole.NAME_ADMIN, CompanyRole.NAME_EMPLOYEE];
            if (rolesWithoutPerimeterColumnFilter.indexOf(this.item.name) === -1) {
                columnsProperties.push({
                    label: this.translate('Perimeters'),
                    class: 'd-none d-md-table-cell',
                    sortable: false,
                    key: 'perimeter',
                });
            }

            const rolesWithoutEmployeesColumnFilter = ['admin', 'onboardee'];
            if (rolesWithoutEmployeesColumnFilter.indexOf(this.item.name) === -1) {
                columnsProperties.push({
                    label: this.translate('Employees'),
                    class: 'd-none d-md-table-cell',
                    sortable: false,
                    key: 'employees_count',
                });
            }

            return columnsProperties;
        },
        hasModal() {
            return ![CompanyRole.NAME_EMPLOYEE].includes(this.item.name);
        },
    },

    created() {
        this.setRoleUsersCollection();
    },

    methods: {
        refresh() {
            this.setRoleUsersCollection();
        },
        canAddUser() {
            return ![CompanyRole.NAME_EMPLOYEE, CompanyRole.NAME_GUEST].includes(this.item.name);
        },
        isCompanyUserTheOwner(role) {
            return role === CompanyUser.ROLE_SUPERADMIN;
        },
        openModal() {
            this.$refs.roleUserModal.open();
        },
        onCellClicked(row) {
            if (this.hasModal) {
                this.$refs.roleUserModal.open(row.item.id);
            }
        },
        setRoleUsersCollection() {
            this.companyRoleUserCollection = new CompanyRoleUserCollection([
                'id',
                'has_perimeter',
                'company_role_id',
                'company_user_id',
                'employees_count',
            ]).with({
                user: (query) => {
                    query.select([
                        'id',
                        'role',
                        'image',
                        'fullname',
                        'lastname',
                        'firstname',
                    ]);
                },
                perimeters: (query) => {
                    query
                        .select([
                            'id',
                            'company_entity_id',
                            'company_role_user_id',
                            'company_entity_value_id',
                        ])
                        .with({
                            value: (query) => {
                                query.select([
                                    'id',
                                    'company_entity_id',
                                    'resourceable_type',
                                    'resourceable_id',
                                ]).with({
                                    jobPosition: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    contract: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    office: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    department: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    custom: (query) => {
                                        query.select([
                                            'id',
                                            'key',
                                            'name',
                                        ]);
                                    },
                                });
                            },
                            entity: (query) => {
                                query.select([
                                    'id',
                                    'name',
                                    'slug',
                                    'is_enabled',
                                    'is_heyteam',
                                ]);
                            },
                        });
                },
            }).where([
                ['company_role_id', '=', this.item.id],
            ]);
        },
    },
};
</script>
