<template>
    <div class="file-message">
        <div
            v-if="images && images.length"
            class="images"
            :class="`grid-${images.length > 2 ? 3 : images.length}`"
        >
            <div
                v-for="(image, index) in images"
                :key="`image-${index}-${image.id}`"
                class="image"
                @click="togglePreviewer"
            >
                <img :src="image.link">
            </div>
            <FilePreviewer
                ref="previewer"
                :files="images"
            />
        </div>
        <div
            v-if="videos && videos.length"
            ref="videos"
            class="videos"
        >
            <video
                v-for="video in videos"
                :key="`video-player-${video.id}`"
                controls
                controlsList="nodownload"
                width="100%"
                class="video"
                @click.stop
            >
                <source :src="video.link">
            </video>
        </div>
        <div
            v-if="documents && documents.length"
            class="documents"
        >
            <DocumentBlock
                v-for="document in documents"
                :key="`document-${document.id}`"
                :file="document"
            />
        </div>
        <MessageTextTemplate
            v-if="companyChatChannelMessage.text"
            :text="companyChatChannelMessage.text"
            :is-html="companyChatChannelMessage.is_html"
            :is-me="isMe"
        />
    </div>
</template>

<script>
import CompanyChatChannelMessage from '@/models/CompanyChatChannelMessage';
import filePreviewerMixin from '@/components/globals/FilePreviewer/filePreviewerMixin';
import FilePreviewer from '@/components/globals/FilePreviewer/FilePreviewer.vue';
import MessageTextTemplate from './MessageTextTemplate.vue';
import DocumentBlock from '../DocumentBlock.vue';

export default {
    name: 'MessageFile',
    components: { MessageTextTemplate, FilePreviewer, DocumentBlock },
    mixins: [filePreviewerMixin],
    props: {
        companyChatChannelMessage: {
            type: CompanyChatChannelMessage,
            required: true,
        },
    },
    shared: {
        socket: {
            chatOpened: {
                type: Boolean,
                default: false,
            },
        },
    },
    computed: {
        images() {
            return this.companyChatChannelMessage.files.models
                .filter((file) => this.isImage(file.detected_mime_type));
        },
        videos() {
            return this.companyChatChannelMessage.files.models
                .filter((file) => this.isVideo(file.detected_mime_type));
        },
        documents() {
            return this.companyChatChannelMessage.files.models
                .filter((file) => !this.isVideo(file.detected_mime_type)
                    && !this.isImage(file.detected_mime_type));
        },
        isMe() {
            return this.shared.session.companyUser.id === this.companyChatChannelMessage.company_user_id;
        },
    },
    watch: {
        'shared.socket.chatOpened': function (value) {
            if (value === false && this.$refs.videos) {
                for (let i = 0; i < this.$refs.videos.children?.length; i += 1) {
                    const videoPlayer = this.$refs.videos.children[i];
                    videoPlayer?.pause?.();
                }
            }
        },
    },
    methods: {
        togglePreviewer() {
            this.$refs.previewer.togglePreviewer();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
.file-message{
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 2px;
}
.images{
    display: grid;
    gap: 2px;
    max-height: 124px;
    max-width: 124px;
    cursor: pointer;

    .image {
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &.grid-2{
        grid-template-columns: 1fr 1fr;

        .image:first-child{
            border-radius: 8px 0 0 8px;
        }
        .image:last-child{
            border-radius: 0 8px 8px 0;
        }
    }
    &.grid-3{
        grid-template-columns: 1fr 1fr 1fr;

        .image {
            aspect-ratio: 1;
        }
        .image:first-child{
            border-top-left-radius: 8px;
        }
        .image:nth-child(3){
            border-top-right-radius: 8px;
        }
        .image:nth-child(3n+1):last-child {
            border-bottom-left-radius: 8px;
        }
        .image:nth-child(3n):last-child{
            border-bottom-right-radius: 8px;
        }
    }
    .image:only-child {
        border-radius: 8px;
    }
}

.documents {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: fit-content;
}
.videos{
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: fit-content;
    .video {
        max-height: 342px;
        border-radius: $border-radius-30 !important;
        background: black;
        overflow: hidden;
    }
}

</style>
