import Model from '@tony.caron/node-model-proxy/Model';
import CompanyFieldCollection from './CompanyFieldCollection';
import CompanyFieldTemplate from './CompanyFieldTemplate';

export default class CompanyField extends Model {
    modelConfig() {
        return {
            collection: CompanyFieldCollection,
        };
    }

    modelRelations() {
        return {
            template: () => this.belongsTo(CompanyFieldTemplate, 'company_field_template_id', 'id'),
        };
    }
}
