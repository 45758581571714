<template>
    <div class="main-container">
        <div v-if="loading">
            <IconLoading />
        </div>
        <div v-else-if="!fail">
            <p><t>Thank you !</t></p>
            <p><t>Your signature has been registered.</t></p>
            <p><t>You can now close this window.</t></p>
        </div>
        <div v-else>
            <p><t>Error !</t></p>
            <p><t>An error occured during the process.</t></p>
            <p><t>If the issue keep happening, please contact the administrator.</t></p>
        </div>
    </div>
</template>

<script>
import IconLoading from '@/components/icons/IconLoading.vue';

export default {
    name: 'DocusignRedirectedPage',
    components: {
        IconLoading,
    },
    data() {
        return {
            loading: true,
            fail: false,
        };
    },
    async mounted() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const requirementId = urlParams.get('user_requirement_id');
        const userId = urlParams.get('company_user_id');

        try {
            await this.sendSignedRequest(requirementId, userId);
            this.sendFrontMessage(requirementId);
            this.loading = false;
        } catch (err) {
            this.fail = true;
            this.loading = false;
        }
    },
    methods: {
        sendSignedRequest(requirementId, userId) {
            return new Promise((resolve, reject) => {
                this.$http
                    .post('miscellaneous/docusign/signed', {
                        user_requirement_id: requirementId,
                        company_user_id: userId,
                    })
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
        sendFrontMessage(requirementId) {
            const message = JSON.stringify({
                from: 'DocusignRedirectedPage',
                to: 'UserRequirementFileToSignEdit',
                requirementId,
            });
            window.parent.postMessage(message);
        },
    },
};
</script>

<style lang="scss" scoped>
.main-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}
</style>
