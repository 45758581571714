<template>
    <div v-if="companyUserSurveys.isLoaded()">
        <HtAccordion
            :title="translate('Survey | Surveys', {count: companyUserSurveys.models.length})"
            :count="companyUserSurveys.$.models.length"
            :has-button="companyUserSurveys.$.models.length === 0"
            :button-label="addButtonLabel"
            :is-branding="false"
            cypress="survey"
            @buttonClick="openSelectModal()"
        >
            <template v-if="companyUserSurveys.$.models.length > 0">
                <HtFormInput
                    id="surveySearch"
                    v-model.trim="searchValue"
                    name="surveySearch"
                    cypress="surveySearch"
                    :placeholder="translate('Search')"
                >
                    <template #extra>
                        <div class="px-4">
                            <FontAwesomeIcon
                                size="1x"
                                :icon="['far', 'search']"
                            />
                        </div>
                    </template>
                </HtFormInput>

                <HtTable
                    :items="companyUserSurveys.$.models"
                    :columns="columns"
                    cypress="survey-table"
                    :external-search="searchValue"
                    @onCellClicked="openEditModal"
                >
                    <template #cell(name)="scope">
                        {{ scope.item.name }}
                    </template>
                    <template #cell(participant)="scope">
                        <ParticipantList :users="getParticipant(scope.item)" />
                    </template>
                </HtTable>
                <div class="mt-4 text-center">
                    <Button
                        type="clear"
                        cypress="survey-add-button"
                        @click="openSelectModal()"
                    >
                        <slot :name="'buttonText'">
                            {{ translate(addButtonLabel) }}
                        </slot>
                    </Button>
                </div>
            </template>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No Survey added</t>
                </strong>
            </div>
        </HtAccordion>

        <modalable
            ref="modalableEdit"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserSurveyEdit
                :id="currentId"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </modalable>
        <modalable
            ref="modalableSelect"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserSurveySelect
                :company-user-id="companyUserId"
                :intelligent-selection="intelligentSelection"
                :company-user-program-id="companyUserProgramId"
                :is-draft="true"
                @onUpdate="onUpdate(...arguments)"
            />
        </modalable>
    </div>
</template>

<script>
import HtAccordion from '@/components/globals/HtAccordion.vue';
import UserSurveySelect from '@/components/pages/dashboard/modals/survey/UserSurveySelect.vue';
import UserSurveyEdit from '@/components/pages/dashboard/modals/survey/UserSurveyEdit.vue';
import CompanyUserSurveyCollection from '@/models/CompanyUserSurveyCollection';
import resourceEventBus from '@/eventBus/resourceEventBus';

export default {
    name: 'UserSurveyFlow',
    components: {
        HtAccordion,
        UserSurveySelect,
        UserSurveyEdit,
    },
    props: {
        companyUserId: {
            type: Number,
            default: 0,
        },
        companyUserProgramId: {
            type: Number,
            default: 0,
        },
        intelligentSelection: {
            type: Array,
            default: () => ([]),
        },
    },

    data() {
        return {
            currentId: null,
            searchValue: null,
            columns: [
                {
                    label: this.translate('Name'),
                    search: true,
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                },
                {
                    label: this.translate('Participants'),
                    key: 'participant',
                    sortable: false,
                },
            ],
            addButtonLabel: 'Add survey',
            companyUserSurveys: new CompanyUserSurveyCollection([
                'id', 'company_user_program_id', 'company_user_id', 'name', 'company_survey_id', 'permissions',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
                companyUserParticipant: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image', 'company_language_id'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companySurvey: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            resource: (query) => {
                                query.select([
                                    'name',
                                ]);
                            },
                        });
                },
            })
                .where([
                    ['company_user_program_id', '=', this.companyUserProgramId],
                ]),
        };
    },

    created() {
        this.companyUserSurveys.get().then(() => {
            this.$emit('on-load', this.companyUserSurveys.$.models.length);
        });

        resourceEventBus.$on('survey-done', () => {
            this.companyUserSurveys.get();
        });
    },

    methods: {
        color(status) {
            const colors = {
                draft: 'orange',
                in_progress: 'purple',
                pending: 'purple',
                done: 'green',
            };

            return colors[status];
        },

        getSurveyRelatedUser(item) {
            return [{ ...item.company_user, company_user: item.company_user }];
        },

        getParticipant(item) {
            return [{ ...item.company_user_participant, company_user: item.company_user_participant }];
        },

        onUpdate() {
            this.companyUserSurveys.get();
            this.$emit('on-update');
        },

        onDelete() {
            this.companyUserSurveys.get();
            this.$emit('on-delete');
        },

        openEditModal(row) {
            this.currentId = row.item.id;
            this.$refs.modalableEdit.open();
        },
        openSelectModal() {
            this.$refs.modalableSelect.open();
        },
    },
};
</script>

<style lang="scss" scoped>
    .item-image {
        height: 40px;
        width: 40px;
    }

    .items-bar {
        display: flex;
        justify-content: center;
        h2 {
            font-size: 2.8rem;
            text-transform: none;
        }
    }

    .small-image {
        position: relative;
        background: none !important;
    }

    .survey-related-user {
        position: absolute;
        // right: -12px;
        bottom: -25px;
    }

    .title {
        margin-left: -40px;
    }
</style>
