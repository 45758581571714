<template>
    <div>
        <ParametersBox :title="translate('Invitation E-mail')" />
        <HtCard class="mb-5">
            <template v-if="isLoaded">
                <HtFormSwitch
                    :id="'toggle-send-email'"
                    v-model="isEnabledEmail"
                    :label="`${translate('Send an email to notify')} ${user.firstname}`"
                    :name="'toggle-send-email'"
                    :show-optional="false"
                    :disabled="isOnboarding"
                    :on-label="translate('Yes')"
                    :off-label="translate('No')"
                    cypress="switch-email-email"
                />

                <div v-if="isEnabledEmail">
                    <HtFormInput
                        :id="'input-mail-subject'"
                        v-model="userProgram.mail_subject"
                        v-validate.disable="'required'"
                        :name="'input-mail-subject'"
                        :label="translate('Subject')"
                        :placeholder="translate('Subject')"
                        :data-vv-as="translate('E-mail subject')"
                        cypress="input-mail-subject"
                    />

                    <HtFormEditor
                        :id="'mail_content'"
                        v-model="userProgram.mail_content"
                        v-validate.disable="'required'"
                        :name="'mail_content'"
                        :label="translate('Message sent to {firstname}', { firstname: user.firstname })"
                        :data-vv-as="translate('content')"
                        cypress="email-content"
                    />
                    <HtFormSwitch
                        id="file-join"
                        v-model="isFileBoxChecked"
                        :label="translate('Attach a file')"
                        :off-label="translate('No')"
                        :on-label="translate('Yes')"
                        name="file-join"
                        cypress="email-attach-file"
                    />
                    <div
                        v-show="isFileBoxChecked"
                        class="mb-5 item-content"
                    >
                        <UploadBar
                            v-model="userProgram.company_file"
                            :media="['office', 'pdf', 'image']"
                        />
                    </div>
                </div>

                <HtFormSwitch
                    :id="'toggle-send-sms'"
                    v-model="isEnabledSms"
                    :label="`${translate('Send a text message to notify')} ${user.firstname}`"
                    :name="'toggle-send-sms'"
                    :show-optional="false"
                    :on-label="translate('Yes')"
                    :off-label="translate('No')"
                    cypress="switch-email-sms"
                />

                <div v-if="isEnabledSms">
                    <phone-number-input
                        :value="user"
                        :label="translate('Phone number')"
                        :is-required="true"
                        :class="'col-6 pl-0'"
                        @input="updatePhoneNumber"
                    />
                    <div
                        v-if="apiError.includes('phone')"
                        class="error-message"
                    >
                        <span><t>{{ apiError }}</t></span>
                    </div>
                </div>

                <div
                    v-if="isEnabledSms"
                    class="item-content"
                >
                    <HtFormEditor
                        id="sms_textarea"
                        v-model="userProgram.sms_content"
                        v-validate.disable="'required'"
                        :has-toolbar="false"
                        name="sms_textarea"
                        :data-vv-as="translate('sms')"
                        cypress="textarea-sms-content"
                    />
                </div>

                <HtFormFlatPickr
                    v-if="isEnabledSms || isEnabledEmail"
                    :id="'date-sending-email'"
                    v-model="mailSchedule"
                    v-validate.disable="'required'"
                    :label="translate('Sending date')"
                    :inline="true"
                    :min-date="minDate"
                    :max-date="null"
                    :name="'date-sending-email'"
                    :data-vv-as="translate('mail schedule')"
                    :is-working-day="false"
                    cypress="input-sending-date"
                />
            </template>
            <IconLoading v-else />
        </HtCard>
        <EnrollingFooter>
            <Button
                cypress="foc-previous-mail"
                class="inner-box branding-color branding-border-color branding-border-color-darken-hover"
                @click="$emit('on-prev')"
            >
                <t>Previous</t>
            </Button>
            <Button
                cypress="foc-next-mail"
                :state="buttonState"
                @click="onSave"
            >
                <t>Next</t>
            </Button>
        </EnrollingFooter>
    </div>
</template>

<script>
import ParametersBox from '@/components/globals/ParametersBox.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import PhoneNumberInput from '@/components/globals/inputs/PhoneNumberInput.vue';
import EnrollingFooter from './EnrollingFooter.vue';

export default {
    name: 'EnrollingMail',
    components: {
        ParametersBox,
        HtFormSwitch,
        PhoneNumberInput,
        EnrollingFooter,
    },
    props: {
        userId: {
            type: Number,
            required: true,
        },
        userProgramId: {
            type: Number,
            required: true,
        },
        programType: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            apiError: [],
            isFileBoxChecked: false,
            isEnabledEmail: false,
            isEnabledSms: false,
            user: null,
            userProgram: null,
            buttonState: 'idle',
        };
    },

    computed: {
        isOnboarding() {
            return this.programType === 'onboarding';
        },

        isLoaded() {
            return this.userProgram && this.user;
        },

        mailSchedule: {
            get() {
                return (this.userProgram.mail_schedule)
                    ? this.$Utils.moment(this.userProgram.mail_schedule).format('YYYY-MM-DD')
                    : null;
            },
            set(val) {
                const selectedDate = this.$Utils.moment(val);
                const currentDate = new Date();
                const selectedDateWithCurrentTime = new Date(
                    selectedDate.set({
                        hour: currentDate.getHours(),
                        minute: currentDate.getMinutes(),
                        second: currentDate.getSeconds(),
                    }),
                );
                this.userProgram.mail_schedule = this.$Utils.moment(selectedDateWithCurrentTime);
            },
        },

        minDate() {
            const today = this.$Utils.moment();
            const createdAt = this.$Utils.moment(this.userProgram.created_at);
            return today < createdAt ? this.userProgram.created_at : 'today';
        },
    },

    watch: {
        'userProgram.company_file': {
            deep: true,
            handler(file) {
                this.userProgram.company_file_id = file?.id || null;
            },
        },
    },

    created() {
        this.$http.get(`foc/enrolling/mail/${this.userProgramId}`).then(({ data }) => {
            this.userProgram = data.user_program;
            this.user = data.user;

            this.isFileBoxChecked = Boolean(this.userProgram.company_file_id);
            this.isEnabledEmail = (this.isOnboarding) ? true : Boolean(data.is_enabled_email);
            this.isEnabledSms = Boolean(data.is_enabled_sms);
        });
    },

    methods: {
        async onSave() {
            this.$validator.validateAll().then((isValid) => {
                if (isValid) {
                    this.buttonState = 'loading';

                    if (!this.isEnabledSms) {
                        this.userProgram.sms_content = null;
                    }

                    if (!this.isEnabledEmail) {
                        this.userProgram.mail_subject = null;
                        this.userProgram.mail_content = null;
                    }

                    if (!this.isEnabledEmail) {
                        this.userProgram.mail_subject = null;
                        this.userProgram.mail_content = null;
                    }

                    this.$http.put(`foc/enrolling/mail/${this.userProgramId}`, {
                        program: this.userProgram,
                        user: this.user,
                    }).then(() => {
                        this.$emit('on-next');
                    }).finally(() => {
                        this.buttonState = 'idle';
                    });

                    return;
                }

                if (this.errors.any()) {
                    this.$Utils.firstParentScrollableToTop(this.$el);
                }
            });
        },

        updatePhoneNumber(value) {
            this.user.phone_country_code = value.phone_country_code;
            this.user.phone_number = value.phone_number;
        },
    },
};
</script>
