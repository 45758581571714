<template>
    <div class="full-width">
        <template v-if="companyUser.isLoaded()">
            <form
                class="form-2 spaced"
                data-cy="quiz-form"
            >
                <div v-if="quizOptions.length">
                    <fieldset>
                        <HtFormSelect
                            id="quiz"
                            v-validate.disable="'required'"
                            :value="selectedOption"
                            :label="translate('Quiz')"
                            name="quiz"
                            cypress="quiz"
                            :data-vv-as="translate('quiz')"
                            :options="quizOptions"
                            @input="onSelectedQuiz(...arguments)"
                        />
                    </fieldset>

                    <div v-if="selectedOption">
                        <fieldset :class="{ 'error': errors.has('quiz_participants')}">
                            <label>
                                <t>Assign to:</t>
                                *</label>
                            <div class="card-6 flex-container">
                                <template v-if="item.company_user_quiz_participant && item.company_user_quiz_participant.length > 0">
                                    <div
                                        v-for="(oCompanyUserQuizItem, index) in item.company_user_quiz_participant"
                                        :key="index"
                                        class="item"
                                    >
                                        <UserBlock :item="oCompanyUserQuizItem" />

                                        <div
                                            class="trash"
                                            data-cy="participant-delete-button"
                                            @click="onRemoveParticipant(oCompanyUserQuizItem)"
                                        >
                                            <svg class="icon icon-trash">
                                                <use xlink:href="#icon-trash" />
                                            </svg>
                                        </div>
                                        <div class="align-center">
                                            {{ oCompanyUserQuizItem.company_user.firstname }}
                                        </div>
                                    </div>
                                </template>

                                <div class="add_item">
                                    <div class="item">
                                        <svg
                                            class="icon icon-add"
                                            data-cy="participant-add-button"
                                            @click="openAddUsersModal"
                                        >
                                            <use xlink:href="#icon-add" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <input
                                id="quiz_participants"
                                v-validate.disable="'required|array'"
                                v-next-field
                                type="hidden"
                                :value="item.company_user_quiz_participant && item.company_user_quiz_participant.length ? '-' : ''"
                                name="quiz_participants"
                                class="full-width"
                                :data-vv-as="translate('list of participants')"
                                :placeholder="translate('Type here')"
                            >
                            <div
                                v-for="(errorMessage, index) in errors.collect('quiz_participants')"
                                :key="index"
                                class="error-message"
                            >
                                {{ errorMessage }}
                            </div>
                        </fieldset>

                        <HtKeyDatesInputOrder
                            :user-program-id="item.company_user_program_id"
                            :resource-id="item.id"
                            resource-type="company_quiz"
                            :is-business-day.sync="userTask.is_business_day"
                            :offset-key-date.sync="userTask.company_user_program_key_date_id"
                            :offset-availability-specific-date.sync="userTask.datetime_availability"
                            :offset-availability-number.sync="userTask.offset_availability_number"
                            :offset-availability-unit.sync="userTask.offset_availability_unit"
                            :offset-end-specific-date.sync="userTask.datetime_end"
                            :offset-end-number.sync="userTask.offset_end_number"
                            :offset-end-unit.sync="userTask.offset_end_unit"
                            can-select-specific-date
                            :dependents.sync="userTask.dependents.models"
                        />

                        <div class="modal-actions right">
                            <Button
                                :state="buttonState"
                                cypress="quiz-add-button"
                                @click="onValidate()"
                            >
                                {{ translate('Add') }}
                            </Button>
                        </div>
                    </div>
                </div>

                <div
                    v-else
                    class="empty-component"
                >
                    <strong>
                        <t>All available quiz already selected !</t>
                    </strong>
                </div>
            </form>
        </template>

        <template v-else>
            <IconLoading />
        </template>

        <modalable
            ref="modalableSearchRolePanel"
            class="modalable-1 small"
            :options="{ title: translate('Done by')}"
        >
            <SearchRolePanel
                ref="searchRolePanel"
                :multiple-mode="true"
                :has-roles="false"
                :is_assignable_onboardee="true"
                @onValidate="$refs.modalableSearchRolePanel.close()"
            />
        </modalable>
    </div>
</template>

<script>
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import CompanyUser from '@/models/CompanyUser';
import TaskDependencyMixin from '@/mixins/TaskDependencyMixin';
import CompanyUserQuiz from '@/models/CompanyUserQuiz';
import api from '@/store/api';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';

export default {
    name: 'UserQuizSelect',
    components: {
        HtKeyDatesInputOrder,
        HtFormSelect,
    },
    mixins: [
        TaskDependencyMixin,
    ],
    inject: ['modal'],
    props: {
        companyUserId: {
            type: [String, Number],
            default: '',
        },
        companyUserProgramId: {
            type: [String, Number],
            default: '',
        },
        intelligentSelection: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            userTask: null,
            selectedOption: null,
            buttonState: 'idle',
            companyUserProgram: new CompanyUserProgram([
                'id',
            ]).with({
                companyProgram: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            keyDate: (query) => {
                                query.with({
                                    locales: (query) => query.select(['id', 'name', 'language_key']),
                                });
                            },
                        });
                },
            }).where([
                ['id', '=', this.companyUserProgramId],
            ]),

            quizzes: [],

            item: new CompanyUserQuiz([
                'id',
                'company_user_program_id',
                'company_user_id',
                'company_quiz_id',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'resource_id',
                        'resource',
                        'company_user_program_key_date_id',
                        'offset_availability_number',
                        'offset_availability_unit',
                        'offset_end_number',
                        'offset_end_unit',
                        'is_business_day',
                        'datetime_availability',
                        'datetime_end',
                    ]).with({
                        dependents: (query) => {
                            query.select([
                                'id',
                            ]);
                        },
                    });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image', 'company_role_id']);
                },
            }),
            companyUser: new CompanyUser(
                ['id', 'firstname', 'arrival_date'],
            ).with({
                companyLanguage: (query) => {
                    query.select(['id', 'key']);
                },
            }),
        };
    },
    computed: {
        userLanguage() {
            return this.companyUser?.company_language?.key;
        },
        quizOptions() {
            return this.quizzes.map((quiz) => ({
                id: quiz,
                name: quiz.resource.translations.name[this.userLanguage]
                    ?? quiz.resource.name,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
    },
    async created() {
        this.companyUserProgram.get();

        this.quizzes = (await api.configuration.quizzes.getAll({
            is_enabled: true,
        })).data.data;

        this.companyUser.id = this.companyUserId;
        this.companyUser.get();

        this.configs = { ...this.configs, ...this.options };

        this.modal.setTitle(this.translate('Add quiz'));
        this.createTaskWithDependents(this.item);
        this.userTask = this.item.company_user_program_task;
    },
    methods: {
        onSelectedQuiz(value) {
            this.selectedOption = value;

            const firstResourceProgram = this.selectedOption.resource.programs[0] || {
                offset_availability_number: 0,
                offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                offset_end_number: 7,
                offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                is_business_day: true,
            };

            this.item.name = this.selectedOption.name;
            this.item.company_user_id = this.companyUserId;
            this.item.company_quiz_id = this.selectedOption.id;
            this.item.company_user_program_id = this.companyUserProgramId;
            this.item.company_user_program_task.offset_availability_number = firstResourceProgram.offset_availability_number;
            this.item.company_user_program_task.offset_availability_unit = firstResourceProgram.offset_availability_unit;
            this.item.company_user_program_task.offset_end_number = firstResourceProgram.offset_end_number;
            this.item.company_user_program_task.offset_end_unit = firstResourceProgram.offset_end_unit;
            this.item.company_user_program_task.is_business_day = firstResourceProgram.is_business_day;
        },
        openAddUsersModal() {
            this.$refs.searchRolePanel.init({
                onValidate: this.addParticipants,
                selected: this.item.company_user_quiz_participant,
                intelligentSelection: this.intelligentSelection,
            });
            this.$refs.modalableSearchRolePanel.open();
        },
        addParticipants(participantsToAdd) {
            if (Array.isArray(participantsToAdd) && participantsToAdd.length > 0) {
                this.item.company_user_quiz_participant = participantsToAdd.map((participant) => ({
                    company_user_participant_id: participant.company_user_id,
                    company_user: participant.company_user,
                }));
            }
        },
        onRemoveParticipant(participantToRemove) {
            this.item.company_user_quiz_participant = this.item.company_user_quiz_participant.filter((participant) => participant !== participantToRemove);
        },
        onValidate() {
            this.$validator.validateAll().then(() => {
                if (this.errors.any()) {
                    return;
                }

                this.item.save().then(() => {
                    this.$emit('onUpdate');
                    this.modal.close();
                });
            });
        },
    },
};
</script>

<style lang="scss">
    .trash {
        width: 35px;
        height: 35px;
        position: absolute;
        top: -15px;
        right: -15px;
        background: #fff;
        box-sizing: border-box;
        padding: 3px;
        border: 1px solid #ebebeb;
        border-radius: 50%;
        text-align: center;
        padding: 8px;
        color: black;
        svg {
            width: 15px !important;
            height: 15px !important;
        }
    }
</style>
