<template>
    <PageWithSidebar v-if="isLoaded">
        <template #title>
            <HtPageTitle>
                <t>Team</t>
            </HtPageTitle>
        </template>

        <template #sidebar>
            <HtPageSidebar
                :pages="filteredPages"
                @update-page="onUpdatePage"
            />
        </template>

        <template>
            <router-view :active-page="activePage" />
        </template>
    </PageWithSidebar>
</template>

<script>
import HtPageTitle from '@/components/globals/HtPageTitle.vue';
import PageWithSidebar from '@/layouts/PageWithSidebar.vue';
import HtPageSidebar from '@/components/globals/HtPageSidebar.vue';

export default {
    name: 'TeamLayout',
    components: {
        HtPageSidebar,
        PageWithSidebar,
        HtPageTitle,
    },

    data() {
        return {
            activePage: '',
            pages: [
                {
                    name: 'TeamAllEmployees',
                    label: this.translate('All members'),
                },
                {
                    name: 'TeamMyClass',
                    label: this.translate('My class'),
                },
                {
                    name: 'TeamMyTeam',
                    label: this.translate('My team'),
                },
            ],
        };
    },

    computed: {
        isLoaded() {
            return this.$store.getters['permissions/getPermissionsLoaded'](this.permissions);
        },

        permissions() {
            return this.pages.map((page) => `Page${page.name}`);
        },

        filteredPages() {
            return this.pages.filter((page) => this.$can(`Page${page.name}`));
        },
    },

    watch: {
        $route() {
            this.redirect();
        },
    },

    created() {
        this.$loadPermissions(this.permissions).then(() => {
            this.redirect();
        });

        this.setPage({
            title: this.translate('Members'),
            subtitles: [this.translate('Team')],
        });
    },

    methods: {
        onUpdatePage(page) {
            this.activePage = page;
        },

        redirect() {
            if (this.filteredPages.length > 0 && this.$route.name === 'Team') {
                this.$router.push({ name: this.filteredPages[0].name });
            }
        },
    },
};
</script>
