<template>
    <div class="dashboard-block">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'DashboardBlock',
};
</script>

<style lang="scss" scoped>
.dashboard-block {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(52, 46, 55, 0.04), 0px 4px 14px rgba(52, 46, 55, 0.08);
}
</style>
