<template>
    <main>
        <div
            v-if="!loading"
        >
            <div v-if="!is_valid">
                <span
                    class="message"
                ><t>Something has gone wrong.</t></span>
            </div>
            <div
                v-else-if="tasks.length > 0"
                class="container mt-5"
            >
                <div class="row">
                    <h2 class="display-4 text-center">
                        <t>Here's what's left to complete this week:</t>
                    </h2>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div
                            v-if="is_valid"
                            class="content"
                        >
                            <MailTasks
                                :tasks="tasks"
                                :loading="loading"
                                @toggleValidation="toggleValidation"
                            />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 d-flex justify-content-center mb-3">
                        <a
                            href="#"
                            target="_blank"
                            @click.prevent="$router.push({ name: 'Dashboard' })"
                        >
                            <Button
                                class="red"
                            >
                                <t>Login</t>
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <IconLoading v-else />
    </main>
</template>

<script>
import MailTasks from '@/components/pages/dashboard/listTasks/mail/MailTasks.vue';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';
import CompanyUser from '@/models/CompanyUser';

export default {
    name: 'TaskValidator',
    components: {
        MailTasks,
    },
    data() {
        return {
            is_valid: false,
            tasks: [],
            loading: true,
        };
    },
    created() {
        if (!this.$route.query.task_obfuscate_id) {
            this.loading = false;
            return;
        }

        this.validateCurrentTask().then(() => {
            this.fetchTasks();
        });
    },
    methods: {
        validateCurrentTask() {
            return this.$http.post(`mail/tasks/${this.$route.query.task_obfuscate_id}/validate`, {
                user_uuid: this.$route.query.uuid,
                notification_obfuscate_id: this.$route.query.notification_obfuscate_id,
            });
        },
        fetchTasks() {
            this.$http.get('mail/tasks', {
                params: {
                    user_uuid: this.$route.query.uuid,
                    notification_obfuscate_id: this.$route.query.notification_obfuscate_id,
                },
            }).then(({ data }) => {
                const companyUser = new CompanyUser([
                    'id', 'company_id', 'firstname', 'lastname', 'image', 'email', 'company_language_id',
                ]).with({
                    companyLanguage: (query) => {
                        query.select(['id', 'key', 'name', 'code', 'is_default', 'is_enabled']);
                    },
                });
                companyUser.fill(data.data[0].user);

                RtwSharedSpace.share('session', 'companyUser', {
                    type: CompanyUser,
                    default: companyUser,
                    overwrite: true,
                });

                this.tasks = data.data;

                this.is_valid = true;
            }).catch(() => {
                this.is_valid = false;
            }).finally(() => {
                this.loading = false;
            });
        },
        toggleValidation(task) {
            this.$http.post(`mail/tasks/${task.taskObfuscateId}/toggle-validation`, {
                user_uuid: this.$route.query.uuid,
                notification_obfuscate_id: this.$route.query.notification_obfuscate_id,
            }).then(({ data }) => {
                // Pour rafraîchir la liste, on prend en compte les modifications sur la liste
                const index = this.tasks.findIndex((item) => data.data.id === item.id);
                if (index !== -1) {
                    this.tasks[index] = data.data;
                    this.tasks = [...this.tasks];
                }
                this.is_valid = true;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .message {
            margin-bottom: 20px;
        }
    }
}
</style>
