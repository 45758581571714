import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';

import CompanySurveyQuestion from './CompanySurveyQuestion';

export default class CompanySurveyQuestionCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanySurveyQuestion,
        };
    }
}
