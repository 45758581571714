export default {
    name: 'Video header',
    image: 'video-header.png',
    fullPageBorder: true,
    content: `
        <section class="ht-section-video-header">
            <div class="container">
                <h1 class="title">Lorem ipsum dolor sit amet !</h1>
                <h2 class="subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni, provident.</h2>
            </div>

            <video autoplay="autoplay" loop="loop" muted="" width="300" height="150">
                <source src="https://media.istockphoto.com/id/1347162924/fr/vid%C3%A9o/s%C3%A9quence-vid%C3%A9o-4k-dune-jeune-femme-daffaires-tenant-son-ordinateur-portable-tout-en-faisant.mp4?s=mp4-640x640-is&amp;k=20&amp;c=9Gq6qxSnsFMOMM-eVg75_ovuZCow6YHoKPj58sMSFLs=" type="video/mp4" />
            </video>
        </section>
    `,
    css: `
        .ht-section-video-header {
            width: 100%;
            height: 400px; /* set the section height to match the video height */
            display: flex;
            justify-content: center;
            align-items: center; /* vertically center the content */
            background-color: #000;
            position: relative;
        }

        .container {
            text-align: center;
            position: relative; /* add this */
            z-index: 1; /* add this */
        }

        .title {
            color: #fff;
            font-size: 36px;
            margin-bottom: 10px;
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* add a text shadow to improve readability */
        }

        .subtitle {
            color: #fff;
            font-size: 24px;
        }

        .ht-section-video-header video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 400px; /* set max height */
            object-fit: cover;
            object-position: center; /* center the video horizontally and vertically */
        }
    `,
};
