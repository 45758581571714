<template>
    <div
        v-if="companyValues.isLoaded()"
        class="full-width"
    >
        <SectionBar
            :title="configs.title"
            :button-class="'button-plus'"
            :button-text="'+'"
            :has-add-button="true"
            @click="openModal()"
        />

        <CardList
            v-if="companyValues.$.length"
            :options="{
                class:'no-justify'
            }"
        >
            <Card
                v-for="(item, index) in companyValues.$.models"
                :key="index"
                :options="{
                    type: 'small',
                    perRow: 'auto',
                }"
                @click="openModal(item.active)"
            >
                <div slot="title">
                    {{ item.translations.name[editingLang] }}
                </div>
            </Card>
        </CardList>
        <div
            v-else
            :key="0"
            class="empty-component"
        >
            <p> <strong> <t>No Value added</t> </strong> </p>
        </div>

        <Modalable
            ref="modalableValueEdit"
            class="modalable-1 medium"
            :mode="2"
        >
            <ValueEdit
                v-model="currentItem"
                :editing-lang="editingLang"
                :collection="companyValues"
            />
        </Modalable>
    </div>
</template>

<script>
import CompanyValueCollection from '@/models/CompanyValueCollection';
import ValueEdit from '@/components/resources/value/ValueEdit.vue';

export default {
    name: 'ValueList',
    components: {
        ValueEdit,
    },

    props: {
        editingLang: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            configs: {
                title: 'Values',	// Title of SectionBar
                showAddButton: true,			// Show add button of SectionBar,
                perRow: 3,						// How many cards per row
            },
            currentItem: null,
            companyValues: new CompanyValueCollection(
                ['id', 'name', 'description', 'editable', 'is_heyteam', 'translations'],
            ),
        };
    },

    async created() {
        await this.companyValues.get();
    },

    methods: {
        openModal(item) {
            this.currentItem = item;
            this.$refs.modalableValueEdit.open();
        },
    },
};
</script>
