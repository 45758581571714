<template>
    <PageWithSidebar>
        <template #sidebar>
            <HtStepperSidebar
                v-model="stepIndex"
                :steps="steps"
                @back="onBack"
            >
                <HtStepperItem
                    v-for="(step, index) in steps"
                    :key="index"
                    :step="index"
                    :label="step.label"
                    :disabled="step.disabled"
                    :steps="steps"
                />
            </HtStepperSidebar>
        </template>

        <template>
            <IconLoading v-if="!loaded" />
            <template v-else>
                <component
                    :is="currentComponent"
                    :bulk="bulk"
                    :is-read-only="isReadOnly"
                    :has-prev="hasPrev"
                    :has-next="hasNext"
                    @on-prev="onPrev"
                    @on-next="onNext"
                    @go-to="goTo"
                />
            </template>
        </template>
    </PageWithSidebar>
</template>

<script>
import api from '@/store/api';
import IconLoading from '@/components/icons/IconLoading.vue';
import HtStepperSidebar from '@/components/globals/HtStepperSidebar.vue';
import HtPageTitle from '@/components/globals/HtPageTitle.vue';
import PageWithSidebar from '@/layouts/PageWithSidebar.vue';
import HtStepperItem from '@/components/globals/HtStepperItem.vue';
import BulkEnrollingPersonalInformation from '@/router/pages/programs/enrolling/bulk/BulkEnrollingPersonalInformation.vue';
import BulkEnrollingTarget from '@/router/pages/programs/enrolling/bulk/BulkEnrollingTarget.vue';
import BulkEnrollingTeam from '@/router/pages/programs/enrolling/bulk/BulkEnrollingTeam.vue';
import BulkEnrollingProgram from '@/router/pages/programs/enrolling/bulk/BulkEnrollingProgram.vue';
import BulkEnrollingSummary from '@/router/pages/programs/enrolling/bulk/BulkEnrollingSummary.vue';

export default {
    name: 'BulkEnrolling',

    permissions: [
        'PageBulkEnrolling',
    ],

    components: {
        IconLoading,
        PageWithSidebar,
        HtStepperItem,
        HtStepperSidebar,
        HtPageTitle,
    },

    props: {
        bulkId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            loaded: false,
            stepIndex: 0,
            bulk: null,
            steps: [
                {
                    value: BulkEnrollingPersonalInformation,
                    disabled: false,
                    label: this.translate('Information'),
                },
                {
                    value: BulkEnrollingTarget,
                    disabled: true,
                    label: this.translate('Target'),
                },
                {
                    value: BulkEnrollingTeam,
                    disabled: true,
                    label: this.translate('Team'),
                },
                {
                    value: BulkEnrollingProgram,
                    disabled: true,
                    label: this.translate('Program'),
                },
                {
                    value: BulkEnrollingSummary,
                    disabled: true,
                    label: this.translate('Summary'),
                },
            ],
        };
    },

    created() {
        api
            .user
            .enrolling
            .bulk
            .findById(this.bulkId)
            .then((res) => {
                this.bulk = res.data.bulk;

                this.setPage({
                    title: this.oldLocalize(this.bulk.program.locales, 'name'),
                });

                this.loaded = true;
            });
    },

    computed: {
        hasPrev() {
            const newIdx = this.stepIndex - 1;
            return newIdx >= 0;
        },

        hasNext() {
            return Boolean(this.stepIndex < this.steps.length - 1);
        },

        currentComponent() {
            return this.steps[this.stepIndex].value;
        },

        isReadOnly() {
            return this.bulk.status !== 'draft';
        },
    },

    methods: {
        onPrev() {
            if (this.hasPrev) {
                this.stepIndex--;
            }
        },

        onNext() {
            if (this.hasNext) {
                this.stepIndex++;
            }
        },

        onBack() {
            this.$Notifier('App').showInfo(
                this.translate('The draft has been successfully saved'),
            );
        },

        goTo(componentName) {
            const stepIndex = this.steps.findIndex((s) => s.value.name === componentName);
            if (stepIndex !== -1) {
                this.stepIndex = stepIndex;
            }
        },
    },
};
</script>
