import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyRole from './CompanyRole';

export default class CompanyRoleCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyRole,
        };
    }
}
