<template>
    <component
        :is="getListComponent"
        :entity="item"
        @on-back="$emit('goTo', 'settings')"
    />
</template>

<script>
import CompanyEntity from '@/models/CompanyEntity';
import IconLoading from '@/components/icons/IconLoading.vue';
import ListCustom from './list/ListCustom.vue';
import ListOffice from './list/ListOffice.vue';
import ListJobPosition from './list/ListJobPosition.vue';
import ListDepartment from './list/ListDepartment.vue';
import ListContract from './list/ListContract.vue';

export default {
    name: 'EntitiesFormContent',

    components: {
        IconLoading,
        ListCustom,
        ListOffice,
        ListJobPosition,
        ListDepartment,
        ListContract,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getListComponent() {
            const componentMap = {
                [CompanyEntity.SLUG_COMPANY_JOB_POSITION]: 'ListJobPosition',
                [CompanyEntity.SLUG_COMPANY_OFFICE]: 'ListOffice',
                [CompanyEntity.SLUG_COMPANY_DEPARTMENT]: 'ListDepartment',
                [CompanyEntity.SLUG_COMPANY_CONTRACT]: 'ListContract',
            };
            return componentMap[this.item.slug] || 'ListCustom';
        },
    },
};
</script>
