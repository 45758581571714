<template>
    <div v-if="companyUser.isLoaded()">
        <label>
            <t>Action participants</t>
        </label>

        <div class="card-6 flex-container">
            <div
                v-for="(participant, index) in dedupedParticipants"
                :key="index"
                class="item"
            >
                <div
                    v-user-image="$Utils.getCompanyComponentRoleInitials('user', 1.5, participant.company_user)"
                    class="item-image role-only user"
                />
                <div
                    class="trash"
                    data-cy="remove-participant"
                    @click="onRemoveParticipant(participant)"
                >
                    <svg class="icon icon-trash">
                        <use xlink:href="#icon-trash" />
                    </svg>
                </div>
                <div class="align-center">
                    {{ participant.company_user.firstname }}
                </div>
            </div>
            <div class="add_item">
                <div class="item">
                    <svg
                        class="icon icon-add"
                        data-cy="add-participant"
                        @click="openAddUsersModal"
                    >
                        <use xlink:href="#icon-add" />
                    </svg>
                </div>
            </div>
        </div>

        <Modalable
            ref="modalableSearchUser"
            class="modalable-1 small no-padding-bottom"
        >
            <SearchRolePanel
                ref="searchUserPanel"
                :multiple-mode="true"
                :has-roles="false"
                @onValidate="$refs.modalableSearchUser.close()"
            />
        </Modalable>
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyUserProgramTaskParticipantCollection from '@/models/CompanyUserProgramTaskParticipantCollection';

export default {
    name: 'InputParticipants',
    props: {
        value: {
            type: CompanyUserProgramTaskParticipantCollection,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            companyUser: new CompanyUser([
                'id',
                'firstname',
                'lastname',
                'image',
            ]).with({
                companyRoleUserEmployee: (query) => {
                    query.select([
                        'id',
                        'company_role_user_id',
                    ]).with({
                        roleUser: (query) => {
                            query.select(['id', 'company_user_id']).with({
                                user: (query) => {
                                    query.select(['id', 'firstname', 'lastname', 'image']);
                                },
                                role: (query) => {
                                    query.select(['alias', 'name', 'is_heyteam']);
                                },
                            });
                        },
                    });
                },
            }).whereHas({
                companyRoleUserEmployee: (query) => {
                    query.where([
                        ['company_user_id', '=', this.companyUserId],
                    ]);
                },
            })
                .where([
                    ['id', '=', this.companyUserId],
                ]),
        };
    },

    computed: {
        userHasSupervisor() {
            return this.companyUser.id;
        },

        intelligentSelection() {
            // user doesn't have supervisor
            if (!this.userHasSupervisor) {
                return [];
            }

            const companyUser = {
                id: this.companyUser.id,
                firstname: this.companyUser.firstname,
                lastname: this.companyUser.lastname,
                image: this.companyUser.image,
                company_user_id: this.companyUser.id,
            };

            const supervisors = this.companyUser.company_role_user_employee.models.map((companyRoleUserEmployee) => ({
                id: companyRoleUserEmployee.role_user.user.id,
                firstname: companyRoleUserEmployee.role_user.user.firstname,
                lastname: companyRoleUserEmployee.role_user.user.lastname,
                image: companyRoleUserEmployee.role_user.user.image,
                company_user_id: companyRoleUserEmployee.role_user.user.id,
                company_role: companyRoleUserEmployee.role_user.role,
            }));

            return [...this.$Utils.getArrayUniqueByProperty((x) => x.id, supervisors), companyUser];
        },

        dedupedParticipants() {
            return this.value.models.reduce(
                (filteredArray, participant) => (filteredArray.some((filteredParticipant) => filteredParticipant.company_user_id === participant.company_user_id)
                    ? filteredArray
                    : [...filteredArray, participant]),
                [],
            );
        },
    },

    created() {
        this.companyUser.get();
    },

    methods: {
        addParticipants(participants) {
            this.$emit('input', participants);
        },
        onRemoveParticipant(participant) {
            const participants = this.value.models.filter((item) => item.company_user.id !== participant.company_user.id);

            this.$emit('input', participants);
        },
        openAddUsersModal() {
            this.$refs.searchUserPanel.init({
                intelligentSelection: this.intelligentSelection,
                onValidate: this.addParticipants,
                selected: this.value.models,
                title: 'Done by',
            });

            this.$refs.modalableSearchUser.open();
        },
    },
};
</script>
