import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserEmailCustom from './CompanyUserEmailCustom';

export default class CompanyUserEmailCustomCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserEmailCustom,
        };
    }

    modelCustomAttributes() {
        return {
            company_user_id: null,
        };
    }
}
