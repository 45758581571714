import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserRequirementGroup from './CompanyUserRequirementGroup';

export default class CompanyUserRequirementGroupCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserRequirementGroup,
        };
    }
}
