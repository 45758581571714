<template>
    <div>
        <Draggable
            :class="{'dropzone':true, 'disabled':(disableDrag || value.some((role) => role.id === undefined))}"
            :disabled="disableDrag || value.some((role) => role.id === undefined)"
            @end="orderChanged"
        >
            <div
                v-for="(role) in value"
                :key="role.id"
                class="draggable-item"
            >
                <FontAwesomeIcon
                    :icon="['fas', 'bars']"
                    class="handler"
                />
                <HtFormRoleSelector
                    id="main-recipient"
                    :value="role"
                    class="selector"
                    name="main-recipient"
                    :options="getRoleOptions(role)"
                    :allow-empty="false"
                    :is-single="true"
                    :disabled="disabled || role.disabled"
                    @input="(input) => updateParticipant(role, input)"
                />
                <FontAwesomeIcon
                    v-if="!disabled"
                    v-tooltip.right-end="role.disabled ? translate('This contributor role cannot be modified or deleted because it is already a pdf form field completer') : false"
                    :icon="['fas', 'minus-circle']"
                    :class="{'remove-icon':true, 'disabled':(role.disabled)}"
                    :color="role.disabled ? '$neutral-500' : '#DC3D1B'"
                    @click="remove(role)"
                />
            </div>
        </Draggable>
        <HtButton
            v-if="!disabled"
            :disabled="value.some((role) => role.id === undefined)"
            @click.native="addRoleSlot"
        >
            {{ addLabel }}
        </HtButton>
    </div>
</template>

<script>
import Draggable from 'vuedraggable';
import HtFormRoleSelector from '@/components/globals/Selectors/HtFormRoleSelector.vue';
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'OrderableRoleList',
    components: {
        Draggable,
        HtFormRoleSelector,
        HtButton,
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
        unavailables: {
            type: Array,
            default: () => [],
        },
        addLabel: {
            type: String,
            default: '',
        },
        disableDrag: {
            type: Boolean,
            default: false,
        },
        roleOptions: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },

    created() {
        this.$store.dispatch('roles/fetchRoles');
    },
    methods: {
        addRoleSlot() {
            this.$emit('addRoleSlot', { id: 0 });
        },

        getRoleOptions(value) {
            return ((this.roleOptions ?? this.$store.state.roles.roles) ?? [])
                .filter((role) => !this.unavailables
                    .map((u) => u?.id).includes(role.id)
                    || role.id === value?.id);
        },

        remove(role) {
            if (!role.disabled) {
                this.$emit('deleteRole', role);
            }
        },

        updateParticipant(oldRole, newRole) {
            this.$emit('updateRole', { oldRole, newRole });
        },

        orderChanged(event) {
            const { newIndex, oldIndex } = event;
            if (newIndex === oldIndex) return;
            const movedRoles = newIndex > oldIndex
                ? this.handleMoveDown(newIndex, oldIndex)
                : this.handleMoveUp(newIndex, oldIndex);
            this.$emit('orderChanged', movedRoles);
        },

        handleMoveDown(newIndex, oldIndex) {
            const movedRoles = this.value.map((role, index) => {
                if (index === oldIndex) {
                    return {
                        ...role,
                        execution_order: newIndex,
                    };
                }
                if (index > oldIndex && index <= newIndex) {
                    return {
                        ...role,
                        execution_order: index - 1,
                    };
                }
                return {
                    ...role,
                    execution_order: index,
                };
            });
            return movedRoles;
        },

        handleMoveUp(newIndex, oldIndex) {
            const movedRoles = this.value.map((role, index) => {
                if (index === oldIndex) {
                    return {
                        ...role,
                        execution_order: newIndex,
                    };
                }
                if (index < oldIndex && index >= newIndex) {
                    return {
                        ...role,
                        execution_order: index + 1,
                    };
                }
                return {
                    ...role,
                    execution_order: index,
                };
            });
            return movedRoles;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.dropzone {

        .draggable-item {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;

            padding: 5px;
            margin-bottom: .5rem;

            .selector {
                flex: 2;
                margin-bottom: unset;
                margin-left: 1rem;
                margin-right: 1rem;
            }

            .handler {
                cursor: move;
            }
        }
    }

    .dropzone.disabled {
        .draggable-item {
            .handler {
                cursor: default;
            }
        }
    }

    .remove-icon.disabled {
        opacity: 0.3;
    }
</style>
