import CardImgLeftSection from './CardImgLeftSection';

export default {
    name: 'Card image right',
    image: 'card-img-right.png',
    fullPageBorder: false,
    content: CardImgLeftSection.content,
    css: `
        ${CardImgLeftSection.css}

        .card {
            flex-direction: row-reverse;
        }

        @media (max-width: 768px) {
            .card {
                flex-direction: column;
            }
        }
    `,
};
