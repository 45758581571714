<template>
    <div>
        <BulkEnrollingHeader :title="translate('Program')" />

        <IconLoading v-if="loading" />
        <ProgramDetailSimulation
            v-else
            :company-program-id="bulk.company_program_id"
            use-key-date
            :show-filters="false"
            :can-edit="false"
            :default-entities="entities"
        />

        <EnrollingFooter>
            <HtButton
                cypress="foc-previous-program"
                type="secondary"
                :disabled="!hasPrev"
                @click.native="$emit('on-prev')"
            >
                <t>Previous</t>
            </HtButton>

            <HtButton
                cypress="foc-next-program"
                :loading="loading"
                :disabled="!hasNext || loading"
                @click.native="$emit('on-next')"
            >
                <t>Next</t>
            </HtButton>
        </EnrollingFooter>
    </div>
</template>

<script>
import BulkEnrollingHeader from '@/router/pages/programs/enrolling/bulk/BulkEnrollingHeader.vue';
import EnrollingFooter from '@/router/pages/programs/enrolling/EnrollingFooter.vue';
import HtButton from '@/components/globals/HtButton.vue';
import BulkEnrollingMixin from '@/router/pages/programs/enrolling/bulk/BulkEnrollingMixin';
import ProgramDetailSimulation from '@/router/pages/programs/ProgramDetailSimulation.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import api from '@/store/api';

export default {
    name: 'BulkEnrollingProgram',

    components: {
        IconLoading,
        ProgramDetailSimulation,
        HtButton,
        EnrollingFooter,
        BulkEnrollingHeader,
    },

    mixins: [
        BulkEnrollingMixin,
    ],

    data() {
        return {
            entities: [],
        };
    },

    created() {
        this.loading = true;

        api
            .user
            .enrolling
            .bulk
            .getUsersFilters(this.bulk.id)
            .then((res) => {
                this.entities = res.data.data;

                this.loading = false;
            });
    },
};
</script>
