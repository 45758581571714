<template>
    <div>
        <StepperTabLayout v-if="survey">
            <template #main>
                <HtFormCard v-if="selectedProgramType !== 'default'">
                    <HtKeyDatesInputOrder
                        :program-id="survey.resource.programs[0].company_program_id"
                        :resource-id="survey.id"
                        :resource-type="resourceableClass"
                        :filters="survey.resource.programs[0].filters"
                        :is-business-day.sync="survey.resource.programs[0].is_business_day"
                        :offset-key-date.sync="survey.resource.programs[0].company_program_key_date_id"
                        :offset-availability-number.sync="survey.resource.programs[0].offset_availability_number"
                        :offset-availability-unit.sync="survey.resource.programs[0].offset_availability_unit"
                        :offset-end-number.sync="survey.resource.programs[0].offset_end_number"
                        :offset-end-unit.sync="survey.resource.programs[0].offset_end_unit"
                        :dependents.sync="survey.resource.programs[0].dependents"
                        :dependencies.sync="survey.resource.programs[0].dependencies"
                        :disabled="isCompleted"
                    />

                    <RecurrenceConfig
                        :recurrence="recurrence"
                        @update="setRecurrence"
                    />

                    <HtFormSwitch
                        :id="'toggle-close-survey'"
                        v-model="closeSurvey"
                        :label="translate('Close this survey')"
                        :disabled="isCompleted"
                        :on-label="translate('Yes')"
                        :off-label="translate('No')"
                        :name="'toggle-close-survey'"
                        :show-optional="false"
                        class="mb-0 mt-5"
                    />

                    <p class="mb-20 survey-closure-sentence">
                        <t>{{ surveyClosureSentence }}</t>
                    </p>
                </HtFormCard>
                <template v-else>
                    <HtFormCard class="planning-wrapper mb-20">
                        <form class="form-2 spaced form-flex">
                            <fieldset class="half">
                                <div class="item-content">
                                    <div class="ht-form-label mb-3">
                                        <span><t>Release date</t></span>
                                    </div>

                                    <template v-if="isDisabled">
                                        <HtFormInput
                                            :id="'releaseDate'"
                                            v-model="dateRelease"
                                            :name="'release-date'"
                                            :disabled="true"
                                        />
                                    </template>
                                    <template v-else>
                                        <flat-pickr
                                            ref="fp_contractDate_range_end"
                                            v-model="dateRelease"
                                            :config="{
                                                locale: flatPickrLocale,
                                                mode: 'single',
                                                altFormat: flatPickrFormat,
                                                dateFormat: 'Y-m-d H:i:S',
                                                altInput: true,
                                                minDate: minStartDate,
                                                animate: false,
                                                disable: [weDisabled]
                                            }"
                                            class="inline full"
                                        />

                                        <div
                                            v-for="(errorMessage, index) in errors.collect('release_date')"
                                            :key="index"
                                            class="error-message"
                                        >
                                            <span v-if="errorMessage"><t>{{ errorMessage }}</t></span>
                                        </div>

                                        <input
                                            v-model="dateRelease"
                                            v-validate.disable="'required'"
                                            type="hidden"
                                            name="release_date"
                                            :data-vv-as="translate('release date')"
                                        >
                                    </template>
                                </div>
                            </fieldset>

                            <fieldset class="half">
                                <HtFormSelect
                                    id="release-time"
                                    v-model="timeReleased"
                                    v-validate.disable="'required'"
                                    :label="translate('Release time')"
                                    name="release-time"
                                    :data-vv-as="translate('release time')"
                                    :options="releaseTimeOptions"
                                    :disabled="isDisabled"
                                />
                            </fieldset>
                        </form>

                        <HtFormSwitch
                            :id="'toggle-date-close-survey'"
                            v-model="hasEndDate"
                            :label="translate('Schedule a closing date')"
                            :disabled="isDisabled"
                            :on-label="translate('Yes')"
                            :off-label="translate('No')"
                            :name="'toggle-date-close-survey'"
                            :show-optional="false"
                            class="mb-3"
                        />

                        <div v-show="hasEndDate">
                            <form class="form-2 spaced form-flex mb-20">
                                <fieldset :class="'half'">
                                    <div class="item-content">
                                        <div class="ht-form-label mb-3">
                                            <span><t>Closing date</t></span>
                                        </div>

                                        <template v-if="isDisabled">
                                            <InputField
                                                v-model="dateClose"
                                                type="text"
                                                :name="'closing-date'"
                                                :disabled="true"
                                            />
                                        </template>
                                        <template v-else>
                                            <flat-pickr
                                                ref="test_date"
                                                v-model="dateClose"
                                                :config="{
                                                    locale: flatPickrLocale,
                                                    mode: 'single',
                                                    altFormat: flatPickrFormat,
                                                    dateFormat: 'Y-m-d H:i:S',
                                                    altInput: true,
                                                    minDate: minEndDate,
                                                    animate: false,
                                                    disable: [weDisabled]
                                                }"
                                                class="inline full"
                                            />
                                        </template>
                                    </div>
                                </fieldset>

                                <fieldset class="half">
                                    <HtFormSelect
                                        id="closing-time"
                                        v-model="timeClosing"
                                        v-validate.disable="validationClosingTime"
                                        :label="translate('Closing time')"
                                        name="closing-time"
                                        :data-vv-as="translate('closing time')"
                                        :options="closingTimeOptions"
                                    />
                                </fieldset>
                            </form>
                        </div>

                        <HtFormSwitch
                            id="toggle-close-survey"
                            v-model="closeSurvey"
                            :label="translate('Close this survey')"
                            :disabled="isCompleted"
                            :on-label="translate('Yes')"
                            :off-label="translate('No')"
                            name="toggle-close-survey"
                            :show-optional="false"
                            class="mb-0"
                        />

                        <p class="mb-20 survey-closure-sentence">
                            <t>{{ surveyClosureSentence }}</t>
                        </p>
                    </HtFormCard>

                    <HtFormCard
                        v-if="!survey.datetime_start"
                        style="background: rgba(255, 192, 106, 0.2)"
                    >
                        <div class="planning-wrapper">
                            <p>
                                <t>Your survey will not be sent until you have entered a launch date. Take the opportunity to refine your survey!</t>
                            </p>
                        </div>
                    </HtFormCard>
                </template>
            </template>

            <template #buttonsFooter>
                <div class="planning-item-footer buttons">
                    <Button
                        class="negative"
                        @click="$emit('goTo', 'questions')"
                    >
                        <t>Back</t>
                    </Button>

                    <Button
                        v-if="!isAvailable"
                        class="negative"
                        :state="buttonState"
                        @click="onSave(false)"
                    >
                        <t>Save as draft</t>
                    </Button>

                    <Button
                        v-if="!isCompleted"
                        :cypress="'survey-plan-submit'"
                        :state="buttonState"
                        @click="onSave(true)"
                    >
                        {{ labelButton }}
                    </Button>
                </div>
            </template>
        </StepperTabLayout>
        <IconLoading v-else />
    </div>
</template>

<script>
import CompanySurvey from '@/models/CompanySurvey';
import HtProgramType from '@/models/HtProgramType';
import FlatPickrLocale from 'flatpickr/dist/l10n';
import get from 'lodash.get';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import StepperTabLayout from '@/components/globals/StepperTabLayout.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import InputField from '@/components/globals/InputField.vue';
import { mapState } from 'vuex';
import Button from '@/components/globals/Button.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import api from '@/store/api';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import cloneDeep from 'lodash.clonedeep';
import RecurrenceConfig from '@/components/resources/recurrence/RecurrenceConfig.vue';

export default {
    name: 'SurveyFormPlanning',
    components: {
        HtKeyDatesInputOrder,
        IconLoading,
        Button,
        InputField,
        HtFormSelect,
        HtFormInput,
        HtFormCard,
        StepperTabLayout,
        HtFormSwitch,
        RecurrenceConfig,
    },
    props: {
        showListOnEnd: {
            type: Boolean,
            default: true,
        },
    },

    shared: {
        session: {},
    },

    computed: {
        hasProgramIdSetupInResource() {
            return this.hasResourceProgramId;
        },
        hasResourceProgramId() {
            if (this.survey.resource.programs.length === 0) {
                return false;
            }

            const resourceProgram = this.survey.resource.programs[0];
            return resourceProgram && 'company_program_id' in resourceProgram && resourceProgram.company_program_id !== undefined;
        },
        /**
             * @returns {number|undefined}
             */
        selectedProgramId() {
            return this.survey.resource.programs[0].company_program_id;
        },

        ...mapState('stepperform', ['loading']),
        survey: {
            set(survey) {
                this.$store.dispatch('stepperform/setItem', survey);
            },
            get() {
                return this.$store.state.stepperform.item;
            },
        },

        flatPickrLocale() {
            if (this.shared.session.companyUser.company_language.key === 'fr') {
                return FlatPickrLocale.fr;
            }

            return FlatPickrLocale.en;
        },

        flatPickrFormat() {
            if (this.shared.session.companyUser.company_language.key === 'fr') {
                return 'd/m/Y';
            }

            return 'm/d/Y';
        },

        dateRelease: {
            get() {
                return (this.isDisabled) ? this.$Utils.moment(this.dateReleased).format(this.formatInput) : this.dateReleased;
            },
            set(val) {
                this.dateReleased = val;
            },
        },

        dateClose: {
            get() {
                return (this.isDisabled) ? this.$Utils.moment(this.dateClosing).format(this.formatInput) : this.dateClosing;
            },
            set(val) {
                this.dateClosing = val;
            },
        },

        isAvailable() {
            return this.survey.status !== CompanySurvey.STATUS_DRAFT;
        },

        isCompleted() {
            return this.survey.status === CompanySurvey.STATUS_COMPLETED;
        },

        isDisabled() {
            return this.survey.status === 'in_progress' || this.survey.status === 'completed';
        },

        minStartDate() {
            if (this.nowHour < this.defaultTo) {
                return 'today';
            }

            if (this.$Utils.moment().day() === 5 || this.$Utils.moment().day() === 6) {
                return this.$Utils.moment().weekday(8).format('YYYY-MM-DD');
            }

            return this.$Utils.moment().add(1, 'days').format('YYYY-MM-DD');
        },

        minEndDate() {
            if (this.survey.datetime_start != null) {
                return this.survey.datetime_start;
            }

            if (this.nowHour < this.defaultTo) {
                return 'today';
            }

            if (this.$Utils.moment().day() === 5 || this.$Utils.moment().day() === 6) {
                return this.$Utils.moment().weekday(8).format('YYYY-MM-DD');
            }

            return this.$Utils.moment().add(1, 'days').format('YYYY-MM-DD');
        },

        slotsRelease() {
            let from = this.defaultFrom;
            const dateReleased = this.$Utils.moment(this.dateReleased).format('YYYY-MM-DD');
            if (dateReleased === this.nowDate && this.nowHour >= 7 && this.nowHour < this.defaultTo) {
                from = parseInt(this.nowHour, 10) + 1;
            }

            return this.generateTimeSlots(from, this.defaultTo);
        },

        slotsClosing() {
            if (this.timeReleased && this.dateReleased === this.dateClosing) {
                const [hourClosing] = (this.timeClosing) ? this.timeClosing.split(':') : [];
                let [from] = this.timeReleased.split(':');

                if (hourClosing && hourClosing <= from) {
                    this.timeClosing = null;
                }

                from = parseInt(from, 10) + 1;
                return this.generateTimeSlots(from, this.defaultTo);
            }
            return this.generateTimeSlots(this.defaultFrom, this.defaultTo);
        },

        labelButton() {
            return (this.selectedProgramType === HtProgramType.SLUG_DEFAULT && this.isDisabled) ? this.translate('Validate') : this.translate('Schedule');
        },

        buttonState() {
            return this.loading ? 'loading' : 'idle';
        },

        releaseTimeOptions() {
            return this.slotsRelease.map((release) => ({
                id: release,
                name: release,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
        closingTimeOptions() {
            return this.slotsClosing.map((closing) => ({
                id: closing,
                name: closing,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },

        validationClosingTime() {
            return this.hasEndDate ? 'required' : '';
        },

        selectedProgramType() {
            return get(this.survey, 'resource.programs[0].program.type.slug', HtProgramType.SLUG_DEFAULT);
        },
        recurrence() {
            const survey = cloneDeep(this.survey);
            return {
                recurrence_frequence_value: survey?.resource?.programs[0]?.recurrence_frequence_value || null,
                recurrence_frequence_unit: survey?.resource?.programs[0]?.recurrence_frequence_unit || null,
            };
        },
    },

    watch: {
        dateReleased() {
            this.updateReleasedDateTime();
        },
        timeReleased() {
            this.updateReleasedDateTime();
        },
        dateClosing() {
            this.updateClosingDateTime();
        },
        timeClosing() {
            this.updateClosingDateTime();
        },
    },

    mounted() {
        if (this.isDisabled) {
            this.closeSurvey = !this.survey.resource.programs[0].is_enabled;
        }

        if (this.survey.datetime_start) {
            this.dateReleased = this.$Utils.moment(this.survey.datetime_start).format('YYYY-MM-DD');
            this.timeReleased = this.$Utils.moment(this.survey.datetime_start).format('H:mm');
        }

        if (this.survey.datetime_end) {
            this.hasEndDate = true;
            this.dateClosing = this.$Utils.moment(this.survey.datetime_end).format('YYYY-MM-DD');
            this.timeClosing = this.$Utils.moment(this.survey.datetime_end).format('H:mm');
        }
    },

    methods: {
        hasKeyDateTranslated(program) {
            return program?.key_date.name_translated !== null && program?.key_date.name_translated !== undefined;
        },

        weDisabled(date) {
            return (date.getDay() === 0 || date.getDay() === 6);
        },

        generateTimeSlots(from, to, slotSizeMinutes = 60) {
            const fromMinutes = from * this.HOUR_MINUTES;
            const toMinutes = to * this.HOUR_MINUTES;
            const diffMinutes = toMinutes - fromMinutes;
            const numberOfSlots = diffMinutes / slotSizeMinutes;
            const result = [];

            const buildHourMinuteStr = (minutes) => {
                const min = minutes % this.HOUR_MINUTES;
                return `${Math.floor(minutes / this.HOUR_MINUTES)}:${min === 0 ? '00' : min}`;
            };

            for (let i = 0; i <= numberOfSlots; i++) {
                result.push(buildHourMinuteStr(fromMinutes + i * slotSizeMinutes));
            }

            return result;
        },

        onCompanySurveySave() {
            this.$store.dispatch('stepperform/enableLoading');

            api.configuration.surveys
                .update(this.survey.id, this.survey)
                .then(() => {
                    if (this.showListOnEnd) {
                        this.$router.go(-1);
                    }

                    this.$emit('onSave');
                    this.$store.dispatch('stepperform/reset');
                });
        },

        onSave(isSchedule = true) {
            if (isSchedule === true) {
                this.$validator.validateAll().then((isSuccess) => {
                    if (isSuccess === true) {
                        if (this.hasEndDate === false) {
                            this.survey.datetime_end = null;
                        }

                        this.survey.resource.programs[0].is_enabled = !this.closeSurvey;

                        this.onCompanySurveySave();
                    }
                });
            } else {
                this.survey.datetime_start = null;
                this.survey.datetime_end = null;
                this.survey.resource.programs[0].is_enabled = false;

                this.onCompanySurveySave();
            }
        },

        updateReleasedDateTime() {
            if (!this.isDisabled && this.selectedProgramType === HtProgramType.SLUG_DEFAULT) {
                if (this.dateReleased) {
                    let hourReleased = 0;

                    if (this.timeReleased) {
                        hourReleased = this.timeReleased.split(':')[0];
                    }

                    this.survey.datetime_start = this.$Utils.moment(this.dateReleased).set({ hour: hourReleased }).format('YYYY-MM-DD HH:mm:ss');
                }
            }
        },

        updateClosingDateTime() {
            if (!this.isDisabled && this.selectedProgramType === HtProgramType.SLUG_DEFAULT) {
                if (this.dateClosing) {
                    let hourReleased = 0;

                    if (this.timeClosing) {
                        hourReleased = this.timeClosing.split(':')[0];
                    }

                    this.survey.datetime_end = this.$Utils.moment(this.dateClosing).set({ hour: hourReleased }).format('YYYY-MM-DD HH:mm:ss');
                }
            }
        },

        setRecurrence(value) {
            const survey = cloneDeep(this.survey);
            survey.resource.programs[0].recurrence_frequence_value = value?.recurrence_frequence_value || null;
            survey.resource.programs[0].recurrence_frequence_unit = value?.recurrence_frequence_unit || null;
            this.survey = survey;
        },
    },

    data() {
        return {
            resourceableClass: CompanySurvey.RESOURCEABLE_CLASS,
            nowDate: this.$Utils.moment().format('YYYY-MM-DD'),
            nowHour: this.$Utils.moment().format('H'),
            hasEndDate: false,
            formatInput: 'L',
            dateReleased: null,
            timeReleased: null,
            dateClosing: null,
            timeClosing: null,
            HOUR_MINUTES: 60,
            defaultFrom: 8,
            defaultTo: 22,
            closeSurvey: false,
            surveyClosureSentence: 'Survey closure is definitive, the survey will no longer be included in the next generated programs.',
        };
    },
};
</script>

<style lang="scss" scoped src="./SurveyFormPlanning.scss"></style>
