<template>
    <div class="list-tasks">
        <transition-group
            ref="masonryTransition"
            name="list"
            tag="masonry"
        >
            <MailTask
                v-for="(task) in tasks"
                :key="`task-${task.id}`"
                :task="task"
                class="task-item"
                :select-mode="false"
                :hide-program="false"
                @toggle-validation="data => $emit('toggleValidation', data)"
            />
        </transition-group>
    </div>
</template>

<script>
import MailTask from './MailTask.vue';
import listTasksMixin from '../listTasksMixin';

export default {
    name: 'MailTasks',
    components: {
        MailTask,
    },
    mixins: [listTasksMixin],
    props: {
        tasks: {
            type: Array,
            required: true,
            default: () => ([]),
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        // allows to use transition group with masonry
        const masonryComponent = this.$refs.masonryTransition.$children[0];
        // eslint-disable-next-line no-underscore-dangle
        masonryComponent._props = {
            // eslint-disable-next-line no-underscore-dangle
            ...masonryComponent._props,
            cols: { default: 2, 780: 1 },
            gutter: 8,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import "~@/styles/transition-list";
.load-more-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $neutral-white;
    border: 1px solid $neutral-300;
    border-radius: 16px;
    padding: 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    cursor: pointer;

    .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        border-radius: 6px;
        background-color: $primary-background;
        color: $primary;
        height: 24px;
        width: 24px;

        .plus-icon {
            width: 10px;
            height: 10px;
        }

    }
    .button-text {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;

        .count{
            color: $primary
        }

    }
}
.task-item{
    margin-bottom: 8px;
}

</style>
