<template>
    <div
        v-if="permissionsLoaded"
        id="programTask"
        ref="myTaskProgram"
        class="bloc-dashboard my-actions bp"
        data-cy="my-actions"
    >
        <div class="my-actions-header">
            <HtH3>
                <t>My actions</t>
            </HtH3>
            <div class="d-flex align-items-center justify-content-between">
                <div
                    v-if="canMultiSelect"
                    class="mr-10 my-actions-header-multiselect-toggle"
                    @click="toggleMultiSelect"
                >
                    <t v-if="showMultiSelect === false">
                        Select
                    </t>
                    <t v-else>
                        Cancel
                    </t>
                </div>
                <HtSelectSimple
                    :value="sortingType"
                    :label="translate('Sort by')"
                    :options="sortingTypes"
                    @input="onChangeView"
                />
            </div>
        </div>
        <div>
            <component
                :is="`MyActionsBy${$Utils.capitalize(sortingType.value)}`"
                v-if="sortTypeLoaded"
                :show-multi-select="showMultiSelect"
                :selected-tasks="selectedTasks"
                @select="selectTasks"
            />
        </div>
        <HtBannerSelect
            :show="showMultiSelect"
            :count="selectedTasks.length"
            :can-delete="canDeleteAll"
            :button-state-delete="buttonStateDelete"
            :button-state-validate="buttonStateValidate"
            :can-select-all="false"
            @onCancel="cancelSelected"
            @onDelete="deleteTasks"
            @onValidate="validateTasks"
        />
    </div>
</template>

<script>
import HtH3 from '@/components/design-system/typography/header/HtH3.vue';
import HtBannerSelect from '@/components/globals/HtBannerSelect.vue';
import HtSelectSimple from '@/components/globals/HtSelectSimple.vue';
import MyActionsByDate from './sortByDate/MyActionsByDate.vue';
import MyActionsByAction from './sortByAction/MyActionsByAction.vue';
import MyActionsByUser from './sortByUser/MyActionsByUser.vue';
import CompanyUserProgramTask from '../../../../models/CompanyUserProgramTask';
import tasksSortingMixin from '../listTasks/tasksSortingMixin';

export default {
    name: 'MyActionsWidget',
    components: {
        MyActionsByAction,
        MyActionsByDate,
        MyActionsByUser,
        HtBannerSelect,
        HtSelectSimple,
        HtH3,
    },
    mixins: [tasksSortingMixin],
    permissions: [
        'ModelCompanyUserRequirementCategory',
        'ModelCompanyPlanningEvent',
        'ModelCompanyUserDoc',
        'ModelCompanyUserDocument',
        'ModelCompanyUserEquipment',
        'ModelCompanyUserNudge',
        'ModelCompanyUserProgramTask',
        'ModelCompanyUserQuiz',
        'ModelCompanyUserSoftware',
        'ModelCompanyUserSurvey',
    ],

    data() {
        return {
            buttonStateDelete: 'idle',
            buttonStateValidate: 'idle',
            selectedTasks: [],
            showMultiSelect: false,
            sortKeyLocalStorage: 'defaultSortedDashboard',
        };
    },

    shared: {
        dashboard: {
            removeIncrementTask: {
                type: Number,
                default: 0,
            },
            statusIncrementTask: {
                type: Number,
                default: 0,
            },
        },
    },

    computed: {
        /**
         * @returns {boolean}
         */
        canMultiSelect() {
            // Si l'utilisateur peut supprimer toutes les ressources (remplacement de l'ancien is_admin)
            if (this.canDeleteAll) {
                return true;
            }

            if (!this.shared.session.companyUser.is_onboardee) {
                return true;
            }

            return false;
        },

        /**
         * L'utilisateur peut delete toutes les ressources du dashboard
         * @returns {boolean}
         */
        canDeleteAll() {
            return (
                this.$canDelete('ModelCompanyUserRequirementCategory', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserEquipment', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserSoftware', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserQuiz', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserNudge', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyPlanningEvent', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserDoc', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserDocument', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserSurvey', {
                    company_user_id: this.shared.session.companyUser.id,
                })
                && this.$canDelete('ModelCompanyUserProgramTask', {
                    company_user_id: this.shared.session.companyUser.id,
                    resource: CompanyUserProgramTask.TASK_CUSTOM,
                })
            );
        },
    },

    watch: {
        sortingType: {
            immediate: true,
            handler() {
                this.$emit('sortingTypeChange', this.sortingType.value);
            },
        },
    },
    methods: {
        toggleMultiSelect() {
            this.selectedTasks = [];
            this.showMultiSelect = !this.showMultiSelect;
        },

        cancelSelected() {
            this.selectedTasks = [];
            this.showMultiSelect = false;
        },

        deleteTasks() {
            this.buttonStateDelete = 'loading';
            this.$deleteTasks(this.selectedTasks).then(() => {
                this.selectedTasks = [];
                this.shared.dashboard.removeIncrementTask++;
                this.buttonStateDelete = 'idle';
            });
        },

        /**
         * Valide les actions que l'utilisateur a multi-selectionné
         * @returns {void}
         */
        validateTasks() {
            this.buttonStateValidate = 'loading';
            this.$handleValidationTasks(
                this.selectedTasks.map((_id) => ({
                    id: _id,
                    status: CompanyUserProgramTask.STATUS_DONE,
                })),
            ).then(() => {
                this.selectedTasks = [];
                this.shared.dashboard.statusIncrementTask++;
                this.buttonStateValidate = 'idle';
            }).catch(() => {
                this.buttonStateValidate = 'idle';
            });
        },
        selectTasks(taskIds) {
            if (taskIds.length > 1) {
                if (taskIds.some((oneTaskId) => this.selectedTasks.includes(oneTaskId))) {
                    for (let i = 0; i < taskIds.length; i += 1) {
                        const indexTaskId = this.selectedTasks.indexOf(taskIds[0]);
                        this.selectedTasks.splice(indexTaskId, 1);
                    }
                } else {
                    for (let i = 0; i < taskIds.length; i += 1) {
                        if (!this.selectedTasks.includes(taskIds[i])) {
                            this.selectedTasks.push(taskIds[i]);
                        }
                    }
                }
            } else {
                const taskId = taskIds[0];

                if (this.selectedTasks.includes(taskId)) {
                    this.selectedTasks.splice(this.selectedTasks.indexOf(taskId), 1);
                } else {
                    this.selectedTasks.push(taskId);
                }
            }
        },
    },
};
</script>

<style lang="scss" src="./MyActionsWidget.scss" scoped></style>
