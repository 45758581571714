import Model from '@tony.caron/node-model-proxy/Model';
import CompanyGroup from './CompanyGroup';
import CompanyUser from './CompanyUser';
import CompanyEmailCustomTemplate from './CompanyEmailCustomTemplate';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import CompanyUserEmailCustomRecipientCollection from './CompanyUserEmailCustomRecipientCollection';

export default class CompanyUserEmailCustomRecipient extends Model {
    modelConfig() {
        return {
            collection: CompanyUserEmailCustomRecipientCollection,
        };
    }

    modelRelations() {
        return {
            companyGroup: () => this.belongsTo(CompanyGroup, 'company_group_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyEmailCustomTemplate: () => this.belongsTo(CompanyEmailCustomTemplate, 'company_email_custom_template_id', 'id'),
            companyUserProgramTask: () => this.belongsTo(CompanyUserProgramTask, 'company_user_program_task_id', 'id'),
        };
    }
}
