<template>
    <div class="item">
        {{ text }}
    </div>
</template>

<script>
export default {
    name: 'ResultTextItem',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
.item {
    padding: 20px 0;
    word-break: break-word;
    white-space: pre-line;
}
</style>
