<template>
    <div>
        <div v-if="companyUserQuiz.isLoaded()">
            <form
                class="form-2 spaced label-underline"
                @submit.prevent
            >
                <fieldset>
                    <div class="survey-image">
                        <img
                            src="/static/images/modal.svg"
                            alt
                            class="modal-img"
                        >
                    </div>
                </fieldset>

                <fieldset v-if="shouldShowAvailabilityDate">
                    <label>
                        <t>To complete by</t>
                        : </label>
                    <p>
                        {{
                            $Utils
                                .moment(companyUserQuiz.company_user_program_task.datetime_end)
                                .format(
                                    (shared.session.companyUser.company_language.key === 'fr') ?
                                        'DD/MM/YYYY' : 'MM/DD/YYYY'
                                )
                        }}
                    </p>
                </fieldset>

                <fieldset>
                    <HtStatusLabel :status="companyUserQuiz.company_user_program_task.status" />
                </fieldset>

                <fieldset class="mb-100">
                    <label>
                        <t>Participant:</t>
                    </label>
                    <div class="card-6 flex-container">
                        <div class="item">
                            <div
                                v-user-image="$Utils.getCompanyComponentRoleInitials('user',1.25, companyUserQuiz.company_user_participant)"
                                class="item-image role-only"
                                :class="'user'"
                            />
                            <div class="align-center">
                                {{ companyUserQuiz.company_user_participant.firstname }}
                            </div>
                        </div>
                    </div>
                </fieldset>

                <div
                    v-if="!isCancelled"
                    class="modal-actions right"
                >
                    <Button
                        v-if="canDelete"
                        class="negative"
                        :state="buttonState"
                        cypress="quiz-delete-button"
                        @click="onDelete()"
                    >
                        <t>Remove</t>
                    </Button>
                    <Button
                        v-if="canUpdate"
                        :state="buttonState"
                        cypress="quiz-edit-button"
                        @click="openEditModal()"
                    >
                        <t>Edit</t>
                    </Button>
                    <Button
                        v-if="canSeeResult"
                        :state="buttonState"
                        cypress="quiz-see-results-button"
                        @click="openAnswerModal()"
                    >
                        <t>See results</t>
                    </Button>
                    <Button
                        v-if="canAnswer"
                        :state="buttonState"
                        cypress="quiz-open-answer-button"
                        @click="openAnswerModal()"
                    >
                        <t>Answer</t>
                    </Button>
                </div>
            </form>

            <Modalable
                ref="userQuizEdit"
                class="modalable-1 small"
                :mode="2"
                :element-delete-label="translate('this quiz')"
            >
                <UserQuizEdit
                    :id="companyUserQuiz.id"
                    :company-user-id="companyUserId"
                    :should-show-availability-date="shouldShowAvailabilityDate"
                    @onUpdate="onUpdate"
                />
            </Modalable>

            <ModalableLarge
                ref="userQuizFill"
                class="modalable-1 small"
                :mode="2"
            >
                <UserQuizFill
                    :id="id"
                    :company-user-id="companyUserId"
                    @onUpdate="onReload"
                />
            </ModalableLarge>
        </div>
        <template v-else>
            <IconLoading />
        </template>
    </div>
</template>

<script>
import ParticipantListHelper from '@/components/mixins/ParticipantListHelper.vue';
import CompanyUserQuiz from '@/models/CompanyUserQuiz';
import HtStatusLabel from '@/pages/HtStatusLabel.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import UserQuizEdit from './UserQuizEdit.vue';
import UserQuizFill from './UserQuizFill.vue';

export default {
    name: 'UserQuizItem',
    components: { UserQuizFill, UserQuizEdit, HtStatusLabel },
    mixins: [ParticipantListHelper],
    inject: ['modal'],

    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },

    data() {
        return {
            buttonState: 'idle',
            companyUserQuiz: new CompanyUserQuiz([
                'id',
                'name',
                'company_user_program_task_id',
                'company_user_participant_id',
                'permissions',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'arrival_date', 'image'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['key']);
                            },
                        });
                },
                companyUserParticipant: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'arrival_date', 'image']);
                },
                companyUserProgramTask: (query) => {
                    query.select(['id', 'company_user_id', 'status', 'datetime_end']);
                },
                companyUserQuizQuestionAnswer: (query) => {
                    query.select([
                        'is_correct_answer', 'company_quiz_id', 'company_user_quiz_id',
                        'company_quiz_question_id', 'company_quiz_question_answer_id', 'company_user_id',
                    ]);
                },
                companyQuiz: (query) => {
                    query.select([
                        'id', 'image', 'message_welcome', 'message_congratulary',
                    ]).with({
                        resource: (query) => {
                            query.select(['id', 'name', 'translations']);
                        },
                    });
                },
            })
                .where([['id', '=', this.id]]),
        };
    },

    computed: {
        canRead() {
            return this.$canRead('ModelCompanyUserQuiz', { company_user_id: this.companyUserId });
        },

        canDelete() {
            return this.$canDelete('ModelCompanyUserQuiz', { company_user_id: this.companyUserId });
        },

        canSeeResult() {
            if (this.companyUserQuiz.company_user_program_task.status !== 'done') {
                return false;
            }

            return this.canRead || this.isParticipant;
        },

        canUpdate() {
            return this.$canUpdate('ModelCompanyUserQuiz', { company_user_id: this.companyUserId });
        },

        canAnswer() {
            if (this.from === 'bop' || this.companyUserQuiz.company_user_program_task.status !== 'pending') {
                return false;
            }

            return this.isParticipant;
        },

        isParticipant() {
            return this.$UserAcl.isOwner(this.companyUserQuiz.company_user_participant_id);
        },

        isDone() {
            return this.companyUserQuiz.company_user_program_task.status === CompanyUserProgramTask.STATUS_DONE;
        },

        isCancelled() {
            return this.companyUserQuiz.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },

        statusText() {
            return this.isDone ? this.translate('Done') : this.translate('To do');
        },
    },

    async created() {
        this.companyUserQuiz.company_user_id = this.companyUserId;
        await this.companyUserQuiz.get()
            .then(
                (item) => {
                    this.modal.setTitle(item.name);
                },
                () => {
                    this.modal.close();
                },
            );
    },

    methods: {
        onDelete() {
            this.$refs.userQuizEdit.deleteWindow({ content: this.translate('Are you sure you want to delete this quiz? Once you click on delete, you will no longer be able to access this quiz.') }).then((result) => {
                if (result) {
                    this.companyUserQuiz.delete().then(() => {
                        this.modal.close();
                        this.$emit('onDelete');
                    });
                }
            });
        },

        onUpdate() {
            this.modal.close();
            this.$emit('onUpdate');
        },

        openEditModal() {
            this.$refs.userQuizEdit.open();
        },
        openAnswerModal() {
            this.$refs.userQuizFill.open();
        },
        async onReload() {
            this.$emit('onUpdate');
            this.buttonState = 'loading';
            await this.companyUserQuiz.get()
                .then(
                    (item) => {
                        this.buttonState = 'idle';
                        this.modal.setTitle(item.name);
                    },
                    () => {
                        this.buttonState = 'idle';
                        this.modal.close();
                    },
                );
        },
    },
};
</script>
<style lang="scss" scoped>

.mb-100 {
    margin-bottom: 100px !important;
}

</style>
