<template>
    <div>
        <div>
            {{ userRequirement.value.value | empty }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'UserRequirementAddressRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang='scss' src='./UserRequirementAddressEdit.scss' scoped />
