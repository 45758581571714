/* eslint-disable no-useless-constructor */
/* eslint-disable class-methods-use-this */

import HtProgramType from '@/models/HtProgramType';
import CompanyLanguage from '@/models/CompanyLanguage';
import CompanyRole from '@/models/CompanyRole';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import AbstractTemplateProvider from './AbstractTemplateProvider';

export default class CrossboardingTemplateProvider extends AbstractTemplateProvider {
    static TEMPLATE_SLUG = 'crossboarding';

    constructor(programTypes, keyDates, roles, employeeDepartmentGroupId) {
        super(programTypes, keyDates, roles, employeeDepartmentGroupId);
    }

    get baseProgramData() {
        return {
            slug: CrossboardingTemplateProvider.TEMPLATE_SLUG,
            ht_program_type_id: this.programTypes.find(
                (type) => type.slug === HtProgramType.SLUG_CROSSBOARDING,
            ).id,
            locales: [
                {
                    name: 'Changement de poste',
                    description: '',
                    language_key: CompanyLanguage.KEY_FR,
                },
                {
                    name: 'Crossboarding',
                    description: '',
                    language_key: CompanyLanguage.KEY_EN,
                },
            ],
            key_dates: [
                {
                    ht_program_key_date: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Changing job position date')),
                    ht_program_key_date_id: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Changing job position date')).id,
                    is_main: true,
                },
            ],
            actions: [],
            emails: [],
            surveys: [],
            events: [],
        };
    }

    get actions() {
        return [
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -30,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Communiquer en interne sur le changement de poste',
                            [CompanyLanguage.KEY_EN]: 'Communicate internally on the change of position',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -25,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Créer et partager le planning des étapes-clés du changement de poste',
                            [CompanyLanguage.KEY_EN]: 'Create and share the milestones planning of the position change',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -20,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Préparer un livret de passation pour votre successeur',
                            [CompanyLanguage.KEY_EN]: 'Prepare a transition booklet for your successor',
                        },
                        description: {
                            [CompanyLanguage.KEY_FR]: 'Afin de garantir le bon déroulement de la passation, pouvez-vous préparer un livret de passation pour votre successeur ? Indiquez-lui les accès, les process à respecter, les process en cours... Bref, tout ce qui pourra lui être utile',
                            [CompanyLanguage.KEY_EN]: 'In order to ensure a smooth transition, can you prepare a handover booklet for your successor? Show them the accesses, the processes to be respected, the processes in progress... In short, everything that could be useful',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -15,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Mettre à disposition du salarié les documents légaux relatifs à son évolution',
                            [CompanyLanguage.KEY_EN]: 'Provide the employee with the legal documents relating to their career evolution',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -10,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Faire le point avec les différents managers sur la transition du salarié',
                            [CompanyLanguage.KEY_EN]: "Review the employee's transition with the different managers",
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -5,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Proposer au salarié de participer à une formation si besoin',
                            [CompanyLanguage.KEY_EN]: 'Offer the employee the opportunity to participate in training if needed',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Organiser un événement d’équipe pour faciliter l’intégration du salarié',
                            [CompanyLanguage.KEY_EN]: "Organize a team event to ease the employee's integration",
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 10,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Faire le point avec le nouveau manager',
                            [CompanyLanguage.KEY_EN]: 'Meet with the new manager',
                        },
                    },
                },
            },
        ];
    }

    get emails() {
        return [];
    }

    get events() {
        return [
            {
                time_start: '14:00',
                time_end: '15:00',
                is_delegated: false,
                timezone: 'Europe/Paris',
                organiser_role: {
                    id: this.roles[CompanyRole.NAME_MANAGER],
                },
                email_sending_offset: 2,
                file_translations: {},
                translations: {
                    content: {
                        [CompanyLanguage.KEY_EN]: `<p>Hello {enrollee_firstname}</p>
                        <p>Let's take this time to talk about your new position.</p>`,
                        [CompanyLanguage.KEY_FR]: `<p>Bonjour {enrollee_firstname}</p>
                        <p>Prenons ce temps pour parler de votre évolution.</p>`,
                    },
                },
                resource: {
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_EN]: 'Follow-up interview',
                            [CompanyLanguage.KEY_FR]: 'Entretien de suivi',
                        },
                        description: {
                            [CompanyLanguage.KEY_EN]: 'A moment to discuss your evolution.',
                            [CompanyLanguage.KEY_FR]: 'Un moment pour échanger au sujet de votre évolution.',
                        },
                    },
                    programs: [
                        {
                            is_enabled: true,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_availability_number: 0,
                            offset_end_number: 15,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                            filters: [],
                        },
                    ],
                },
            },
        ];
    }
}
