<template>
    <form class="form-2">
        <fieldset>
            <div class="d-flex justify-content-between mb-4">
                <label>
                    <t>{{ label }}</t>
                    *</label>
                <span
                    v-tooltip.left-end="tooltip"
                    class="position-relative cursor-help"
                >
                    <FontAwesomeIcon icon="info-circle" />
                </span>
            </div>

            <div class="input-order">
                <input
                    v-show="showInput"
                    ref="inputDisplayOrder"
                    v-model="setOrderAvailability"
                    v-next-field
                    :disabled="disabled"
                    type="number"
                    min="1"
                    name="order"
                    @keypress="onlyNumber"
                    @default.prevent=""
                >

                <HtFormSelect
                    id="availability-date"
                    class="availability-select"
                    :value="currentOptionId"
                    name="availability-date"
                    :options="availabilityOptions"
                    :disabled="disabled"
                    @input="onChangeTimeline"
                />
            </div>
        </fieldset>

        <HtResourceDependency
            v-if="currentOptionId === 4"
            :dependents="dependents"
            :dependencies="dependencies"
            :filters="filters"
            :company-program-id="companyProgramId"
            :resource-id="resourceId"
            :resource-type="resourceType"
        />
    </form>
</template>

<script>
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import 'moment/locale/fr';
import HtResourceDependency from '@/components/globals/HtResourceDependency.vue';
import CompanyProgramResourceFilterCollection from '@/models/CompanyProgramResourceFilterCollection';
import CompanyRequirementCategory from '@/models/CompanyRequirementCategory';
import get from 'lodash.get';
import CompanyEmailCustomTemplate from '@/models/CompanyEmailCustomTemplate';

export default {
    name: 'InputAvailability',
    components: {
        HtFormSelect,
        HtResourceDependency,
    },
    inject: ['$validator'],

    props: {
        label: {
            type: String,
            default: 'Availability date',
        },
        description: {
            type: String,
            default: '',
        },
        tooltipText: {
            type: String,
            default: null,
        },
        validationLabel: {
            type: String,
            default: 'completion date',
        },
        orderAvailability: {
            type: [String, Number],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        dependents: {
            type: [Object, Array],
            default: () => ([]),
        },
        dependencies: {
            type: [Object, Array],
            default: () => ([]),
        },
        filters: {
            type: [CompanyProgramResourceFilterCollection, Object, Array],
            default: () => ({}),
        },
        companyProgramId: {
            type: Number,
            default: 0,
        },
        resourceId: {
            type: Number,
            default: 0,
        },
        resourceType: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            currentOptionId: 1,
            renderKey: 0,
        };
    },

    computed: {
        setOrderAvailability: {
            set(value) {
                if (value === '' || value <= 0) {
                    value = 1;
                }
                const parsed = parseInt(value, 10);
                this.$emit('update:orderAvailability', isNaN(parsed) ? null : parsed);
                this.renderKey++;
            },
            get() {
                return this.orderAvailability;
            },
        },

        tooltip() {
            return this.tooltipText || this.translate('Date on which the resource will be made available and visible in the program');
        },

        availabilityOptions() {
            const options = [
                {
                    key: 1,
                    label: this.translate('Immediately'),
                },
                {
                    key: 2,
                    label: this.translate(`day before ${this.validationLabel.toLowerCase()} | days before ${this.validationLabel.toLowerCase()}`, { count: this.setOrderAvailability }),
                },
                {
                    key: 3,
                    label: this.translate(`On ${this.validationLabel.toLowerCase()}`),
                },
                {
                    key: 4,
                    label: this.translate('After completing a task'),
                    $isDisabled: !this.companyProgramId,
                },
            ];

            switch (this.resourceType) {
            case CompanyRequirementCategory.RESOURCEABLE_CLASS:
                options.splice(options.findIndex((option) => option.key === 4), 1);
                break;
            case CompanyEmailCustomTemplate.RESOURCEABLE_CLASS:
                options.splice(options.findIndex((option) => option.key === 1), 1);
                options.splice(options.findIndex((option) => option.key === 2), 1);
                break;
            }

            return options.map((option) => ({
                id: option.key,
                name: option.label,
                $isDisabled: get(option, '$isDisabled', false),
            }));
        },

        hasDependents() {
            const dependents = Array.isArray(this.dependents) ? this.dependents : get(this.dependents, 'models', []);

            return dependents.length > 0;
        },

        showInput() {
            return this.orderAvailability > 0;
        },
    },

    created() {
        this.currentOptionId = this.getOptionFromOrderAvailability();
    },

    methods: {
        getOptionFromOrderAvailability() {
            if (this.orderAvailability === null && !this.hasDependents) {
                return 1;
            } if (this.orderAvailability > 0) {
                return 2;
            } if (this.orderAvailability === 0) {
                return 3;
            }
            return 4;
        },

        onChangeTimeline(optionId) {
            this.currentOptionId = optionId;

            if (optionId === 1 || optionId === 4) {
                if (optionId === 1) {
                    if (Array.isArray(this.dependents)) {
                        this.dependents.splice(0, this.dependents.length);
                    } else {
                        this.dependents.clear();
                    }
                }

                this.$emit('update:orderAvailability', null);
            } else if (optionId === 2) {
                this.$emit('update:orderAvailability', 1);
            } else if (optionId === 3) {
                this.$emit('update:orderAvailability', 0);
            }
        },

        onlyNumber(event) {
            const regex = '^[0-9]*$';

            if (this.$refs.inputDisplayOrder.value.length > 2 || !event.key.match(regex)) event.preventDefault();
        },
    },
};
</script>

<style lang="scss" scoped>
    .input-order {
        display: flex;

        input {
            width: 20%;
            margin-right: 1rem;
        }

        .availability-select {
            width: 100%;
            margin-bottom: 0;
        }
    }

    fieldset {
        margin: 1em 0;
    }

    .cursor-help {
        cursor: help !important;
    }

</style>
