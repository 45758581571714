import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyProgram from './CompanyProgram';

export default class CompanyProgramCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyProgram,
        };
    }
}
