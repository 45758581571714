import Model from '@tony.caron/node-model-proxy/Model';
import CompanyResource from '@/models/CompanyResource';
import CompanyEntityValueCollection from './CompanyEntityValueCollection';
import CompanyJobPosition from './CompanyJobPosition';
import CompanyContract from './CompanyContract';
import CompanyOffice from './CompanyOffice';
import CompanyDepartment from './CompanyDepartment';
import CompanyEntityValueCustom from './CompanyEntityValueCustom';

export default class CompanyEntityValue extends Model {
    static RELATION_JOB_POSITION = 'job_position';

    static RELATION_OFFICE = 'office';

    static RELATION_DEPARTMENT = 'department';

    static RELATION_CONTRACT = 'contract';

    modelConfig() {
        return {
            collection: CompanyEntityValueCollection,
        };
    }

    modelRelations() {
        return {
            jobPosition: () => this.belongsTo(CompanyJobPosition, 'resourceable_id', 'id'),
            contract: () => this.belongsTo(CompanyContract, 'resourceable_id', 'id'),
            office: () => this.belongsTo(CompanyOffice, 'resourceable_id', 'id'),
            department: () => this.belongsTo(CompanyDepartment, 'resourceable_id', 'id'),
            custom: () => this.belongsTo(CompanyEntityValueCustom, 'resourceable_id', 'id'),
            item: () => this.belongsTo(CompanyResource),
        };
    }
}
