<template>
    <HoldingResourceList
        :route-list="'HoldingResourcesDocumentsList'"
        :collection="documents"
    >
        <template #table="holdingResourceList">
            <DocumentsList
                :items="holdingResourceList.items"
                :external-search="holdingResourceList.search"
                :columns-to-hide="['filters']"
                @onCellClicked="holdingResourceList.onCellClicked"
            />
        </template>
    </HoldingResourceList>
</template>
<script>
import HoldingResourceList from '@/components/globals/HoldingResourceList.vue';
import DocumentsList, { documentConfig } from '@/resourcesList/DocumentsList.vue';
import CompanySharedDocumentCollection from '@/models/CompanySharedDocumentCollection';

export default {
    name: 'PageDocumentsList',
    components: {
        HoldingResourceList,
        DocumentsList,
    },

    data() {
        return {
            documents: new CompanySharedDocumentCollection(documentConfig.fields).with(documentConfig.relations),
        };
    },
};
</script>
