import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyMedia from './CompanyMedia';

export default class CompanyMediaCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyMedia,
        };
    }
}
