<template>
    <HtReadResources
        v-if="email"
        :resource-type="resourceType"
        :resource="email"
        label-category="Email"
        :has-sender="true"
        :has-recipient="true"
    />
</template>

<script>

import HtReadResources from '@/components/globals/modals/HtReadResources/HtReadResources.vue';

export default {
    name: 'EmailRead',
    components: { HtReadResources },
    props: {
        resourceType: {
            type: String,
            required: true,
        },
        resourceId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            email: null,
        };
    },

    async created() {
        const request = await this.$http.get(`/configuration/emails/${this.resourceId}`);
        this.email = request.data.data.resource;
        this.email.sender_role = request.data.data.sender_role;
        this.email.sender_user = request.data.data.sender_user;
    },
};
</script>
