<template>
    <div>
        <form v-if="value">
            <HtFormInput
                id="name"
                ref="nameInput"
                v-model="value.name"
                v-validate.disable="'required|max:255'"
                class="reset-ht-form-bottom-margin"
                name="name"
                :label="translate('Site name')"
                :data-vv-as="translate('site name')"
                :show-optional="false"
            />

            <!-- Need to create a component for that -->
            <fieldset
                class="form-2 mt-4"
                :class="['ht-form', { error: errors.has('address') }]"
            >
                <label for="office-location"><t>Address Line</t></label>
                <vue-google-autocomplete
                    id="office-location"
                    v-model="value.address"
                    name="address"
                    class="full-width"
                    :enable-geolocation="true"
                    country="fr"
                    :placeholder="translate('Write the address line')"
                    @placechanged="getAddressData"
                />
                <input
                    v-model="value.address"
                    v-validate.disable="'required'"
                    type="hidden"
                    name="address"
                    :data-vv-as="translate('address')"
                >
                <div
                    v-if="errors.collect()['address']"
                    class="ht-form-error"
                >
                    {{ errors.collect()['address'][0] }}
                </div>
            </fieldset>

            <HtFormInput
                id="city"
                ref="cityInput"
                v-model="value.city"
                v-validate.disable="'required|max:32'"
                class="reset-ht-form-bottom-margin"
                name="city"
                :label="translate('City')"
                :data-vv-as="translate('city')"
                :show-optional="false"
            />
            <HtFormInput
                id="zipcode"
                ref="zipcodeInput"
                v-model="value.zipcode"
                v-validate.disable="'required|max:32'"
                class="reset-ht-form-bottom-margin"
                name="zipcode"
                :label="translate('Zipcode')"
                :data-vv-as="translate('zipcode')"
                :show-optional="false"
            />
            <HtFormInput
                id="country"
                ref="countryInput"
                v-model="value.country"
                v-validate.disable="'required|max:32'"
                class="reset-ht-form-bottom-margin"
                name="country"
                :label="translate('Country')"
                :data-vv-as="translate('country')"
                :show-optional="false"
            />
            <fieldset class="timezone reset-ht-form-bottom-margin">
                <label class="ht-form-label">
                    <t>Timezone</t>
                </label>
                <timezone-selector
                    v-validate="'required'"
                    :data-vv-as="translate('Timezone')"
                    :value.sync="value.timezone"
                    :default-value="shared.session.company.timezone"
                    :error-messages="errors.collect('timezone')"
                />
            </fieldset>
            <fieldset class="in-charge">
                <label class="ht-form-label">Responsable</label>
                <ParticipantList
                    class="reset-ht-form-bottom-margin"
                    :users="inChargeAsArray"
                    :single-selection="true"
                    type="stacked"
                    :add-label="translate('Add')"
                    @onAdd="openSearchInCharge"
                    @onRemove="resetInCharge"
                />
            </fieldset>
        </form>

        <EntityModelCustomFieldGroup :value="value" />

        <modalable
            ref="inChargeSelectionModal"
            class="modalable-1 small"
            :options="{ title: translate('Add person in charge') }"
        >
            <SearchRolePanel
                ref="inCharge"
                :has-roles="false"
            />
        </modalable>
    </div>
</template>

<script>
import VueGoogleAutocomplete from '@/components/tools/VueGoogleAutocomplete.vue';
import TimezoneSelector from '@/components/globals/timezone/TimezoneSelector.vue';
import CompanyOffice from '@/models/CompanyOffice';
import EntityModelCustomFieldGroup from '../EntityModelCustomFieldGroup.vue';

export default {
    name: 'EditOffice',

    components: {
        VueGoogleAutocomplete,
        TimezoneSelector,
        EntityModelCustomFieldGroup,
    },
    inject: ['$validator'],

    props: {
        /**
         * @type {import('vue').PropOptions<{
         *     id: number,
         *     name: string,
         *     timezone: string,
         *     editable: boolean,
         *     is_heyteam: boolean,
         *     country: string,
         *     city: string,
         *     address: string,
         *     zipcode: string,
         *     custom_fields: {
         *         id: number,
         *         company_field_template_id: number,
         *         name: string,
         *         key: string,
         *         type: string,
         *         value: any,
         *         is_editable: boolean
         *     }[]
         * }>}
         */
        value: {
            type: Object,
            required: true,
        },
    },

    computed: {
        inChargeAsArray() {
            return this.value && this.value.company_user
                ? [{ company_user: this.value.company_user }]
                : [];
        },
    },

    created() {
        if (!this.value.timezone) {
            this.value.timezone = CompanyOffice.DEFAULT_TIMEZONE;
        }
    },

    methods: {
        openSearchInCharge() {
            this.$refs.inCharge.init({
                onSelect: (user) => {
                    this.$refs.inChargeSelectionModal.close();

                    this.value.company_user_id = user.id;
                    this.value.company_user = user;
                },
            });
            this.$refs.inChargeSelectionModal.open();
        },
        resetInCharge() {
            this.value.company_user_id = null;
            this.value.company_user = null;
        },

        getAddressData(location) {
            this.value.address = location.street_number
                ? `${location.street_number} ${location.route}`
                : location.route;
            this.value.city = location.locality;
            this.value.country = location.country;
            this.value.zipcode = location.postal_code;
        },
    },
};
</script>

<style lang="scss" scoped>
form {
    display: flex;
    flex-direction: column;
}

.ht-form-error {
    margin-top: 0.8rem;
}

.reset-ht-form-bottom-margin + .reset-ht-form-bottom-margin,
.ht-form-error + .reset-ht-form-bottom-margin,
.reset-ht-form-bottom-margin + .in-charge,
.ht-form-error + .in-charge {
    margin-top: 3.2rem;
}

.reset-ht-form-bottom-margin {
    margin-bottom: 0;
}

fieldset {
    display: flex;
    flex-direction: column;
}
.in-charge {
    // Compensating the bottom margin of the ParticipantList component
    margin-bottom: -2rem;
}

.ht-form-label {
    margin-bottom: 1.6rem;
}
</style>
