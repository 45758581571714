<template>
    <div
        class="notification"
        @click.stop=""
    >
        <div
            v-if="shared.config.allowChat"
            class="notif-icon chat active"
            :class="{active:shared.socket.count>0}"
            :title="translate('Open chat')"
            @click="onViewChat"
        >
            <FontAwesomeIcon :icon="['far', 'comment-alt']" />
            <div
                v-if="shared.socket.count"
                class="circle-text branding-bg"
            >
                {{ countLabel }}
            </div>
        </div>
        <div
            class="notif-icon active"
            :class="{active:getNewNotificationCount()>0}"
            @click="onViewNotification"
        >
            <FontAwesomeIcon :icon="['far', 'bell']" />
            <div
                v-if="getNewNotificationCount()"
                class="circle-text branding-bg"
            >
                {{ getNewNotificationCount() }}
            </div>
        </div>
        <Modalable
            ref="modalNotification"
            class="modalable-1 small"
            :mode="2"
            :pre-load="false"
            @onClose="onModalClose"
        >
            <div class="notification_container">
                <div
                    v-for="(item, index) in company_user_notification"
                    :key="index"
                    class="item branding-border-color-hover branding-bg-hover"
                    :class="{'branding-bg':item.status==='to_deliver', redirect:item.data.redirect}"
                    @click="onRedirect(item)"
                >
                    <div class="item-head">
                        <div class="item-date">
                            {{ item.created_at | moment }}
                        </div>
                        <div
                            v-show="item.status==='to_deliver'"
                            class="item-new branding-color"
                        >
                            <t>New</t>
                        </div>
                    </div>
                    &nbsp;
                    <div class="item-content">
                        {{ item.data.message }}
                    </div>
                </div>
                <div
                    v-if="company_user_notification.length === 0"
                    class="empty-component"
                >
                    <strong><t>No notification yet</t></strong>
                </div>
            </div>

            <UserPlanningEventItem
                ref="UserPlanningEventItem"
                style="z-index:1000"
                :from="'dashboard'"
            />
        </Modalable>

        <UserRequirementCategoryEditModal
            ref="userRequirementCategoryEditModal"
            style="z-index:1000"
        />
    </div>
</template>

<script>
import api from '@/store/api';
import moment from 'moment';
import UserPlanningEventItem from '@/components/pages/dashboard/modals/planningEvent/UserPlanningEventItem.vue';
import UserRequirementCategoryEditModal from '@/components/pages/onboarding/UserRequirementCategoryEditModal.vue';
import CompanyUserNotification from '@/models/CompanyUserNotification';

export default {
    name: 'HeadBarNotification',
    components: {
        UserRequirementCategoryEditModal,
        UserPlanningEventItem,
    },

    shared: {
        socket: {
            chatOpened: {
                type: Boolean,
                default: false,
            },
            count: {
                type: Number,
                default: 0,
            },
        },
        config: {
            allowChat: {
                type: Boolean,
                default: false,
            },
        },
    },

    filters: {
        moment(date) {
            return moment(date).format('Do MMMM YYYY');
        },
    },

    data() {
        return {
            refreshTimeInMs: 60 * 1000,
            resourceId: null,
            company_user_notification: [],
            backupedTime: Date.now(),
            visible: false,
        };
    },

    computed: {
        countLabel() {
            return this.shared.socket.count < 10 ? this.shared.socket.count : '+9';
        },
    },

    created() {
        this.load();
        document.addEventListener('mousedown', this.handleMouseDown);
    },

    beforeDestroy() {
        document.removeEventListener('mousedown', this.handleMouseDown);
    },

    methods: {
        load(iAllowedUpdate = false) {
            return new Promise((resolve, reject) => {
                this.API.get(`company_user/company_user_notification/${iAllowedUpdate}`, {
                    200: (data) => {
                        if (this.shouldNotify(data.company_user_notification)) {
                            this.$Notifier('Notifs').showNotif(
                                this.translate('You have a new unseen notification.'),
                                this.translate('New notification !'),
                            );
                        }
                        this.company_user_notification = data.company_user_notification;
                        resolve();
                    },
                    403: () => {
                        this.$router.push({ name: 'Login' });
                        reject();
                    },
                });
            });
        },

        handleMouseDown() {
            if (!this.visible) {
                if (this.backupedTime + this.refreshTimeInMs < Date.now()) {
                    this.backupedTime = Date.now();
                    this.load();
                }
            }
        },

        async onRedirect(item) {
            if (!item.data || !item.data.redirect || item.related_company_user_id == null) return;

            switch (item.data.redirect) {
            case 'Dashboard':
                this.$router.push('/Dashboard');
                break;
            case 'Event':
                this.resourceId = item.user_resourceable_id;
                this.$refs.UserPlanningEventItem.open(
                    item.user_resourceable_id,
                    this.shared.session.companyUser.id,
                );
                break;
            case 'ResourceExport':
                this.downloadResourceExport(item.data.download_resource_type, item.data.download_file_id);
                break;
            case 'ExternalLink':
                window.location = item.data.redirect_url;
                break;
            case 'ProgramDetailInvitations':
                this.$router.push({
                    name: 'ProgramDetailInvitations',
                    params: {
                        program_id: item.data.program_id,
                        company_program_id: item.data.program_id,
                    },
                });
                break;
            case 'ProfileProgramDetail':
                this.$router.push({
                    name: 'ProfileProgramDetail',
                    params: {
                        company_user_id: item.data.user_id,
                        company_user_program_id: item.data.user_program_id,
                    },
                });
                break;
            case 'ProfileAdministrative':
                this.$refs.userRequirementCategoryEditModal.open(
                    item.data.user_id,
                    item.data.requirement_category_id,
                    item.data.task,
                );
                break;
            default:
                this.$router.push({
                    name: item.data.redirect,
                    params: {
                        company_user_id: item.related_company_user_id,
                    },
                });
                break;
            }

            if (item.data.redirect !== 'Event') {
                this.$refs.modalNotification.close();
            }
        },
        downloadResourceExport(resourceType, fileId) {
            api.configuration[resourceType].downloadExport({
                file_id: fileId,
            }).then((data) => {
                window.location = data.data.link;

                this.$Notifier('App').showInfo(this.translate('Resource [{resource_type}] export file has been downloaded! 🎉', {
                    resource_type: resourceType,
                }));
            }).catch(() => {
                this.$Notifier('App').showError(this.translate('Unable to download resource [{resource_type}]!', {
                    resource_type: resourceType,
                }));
            });
        },

        onViewNotification() {
            this.visible = true;
            this.$refs.modalNotification.open();
            this.$refs.modalNotification.setTitle('Notifications');
            this.$emit('on-view');
        },

        onViewChat() {
            this.shared.socket.chatOpened = !this.shared.socket.chatOpened;
            this.$emit('on-view');
        },

        onModalClose() {
            this.visible = false;
            this.load(true);
        },

        getNewNotificationCount() {
            return this.company_user_notification.filter((item) => item.status == 'to_deliver').length;
        },

        getNewMessagesCount() {
            return this.shared.socket.iUnreadMessage;
        },

        shouldNotify(notifCallDataList) {
            const notiflistHasChange = notifCallDataList.some(
                (notifReceived) => this.company_user_notification.map(
                    (notif) => notif.id,
                ).indexOf(notifReceived.id) === -1,
            );

            const oneNotifIsToDeliver = notifCallDataList.some(
                (notif) => notif.status === CompanyUserNotification.STATUS_TO_DELIVER,
            );

            if (notiflistHasChange && oneNotifIsToDeliver) {
                return true;
            }
        },
    },
};

</script>

<style lang="scss" scoped src="./HeadBarNotification.scss"></style>
