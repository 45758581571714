import Model from '@tony.caron/node-model-proxy/Model';

import CompanySurveyExtraLocaleCollection from './CompanySurveyExtraLocaleCollection';

export default class CompanySurveyExtraLocale extends Model {
    modelConfig() {
        return {
            collection: CompanySurveyExtraLocaleCollection,
        };
    }
}
