<template>
    <div
        v-if="internalSettings"
        class="ai-form-container"
    >
        <div
            class="ai-form"
            style="margin-top: -1px;"
        >
            <div class="ai-icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-sparkles branding-color"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ><path
                    stroke="none"
                    d="M0 0h24v24H0z"
                    fill="none"
                /><path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" /></svg>
            </div>
            <div class="grow-wrap">
                <textarea
                    ref="aiInput"
                    v-model="aiInput"
                    class="ai-input"
                    rows="1"
                    :disabled="isLoading"
                    :placeholder="translate('Ask AI to generate the content...')"
                    onInput="this.parentNode.dataset.replicatedValue = this.value"
                    @focusin="isAIInputFocused = true"
                    @focusout="isAIInputFocused = false"
                    @keydown="onKeyDown"
                />
            </div>
            <button
                class="btn ai-send-button"
                :disabled="isLoading"
                @click.prevent="askAI"
            >
                <i
                    v-if="!isLoading"
                    class="fas fa-arrow-circle-right branding-color"
                />
                <ThinkingLoader v-else />
            </button>
        </div>
        <div
            v-show="isAIMenuVisible"
            class="ai-menu"
            @mouseover="isMouseOverMenus = true"
            @mouseout="onMouseOutMenus"
        >
            <div
                v-if="!isMenuTonesVisible"
                class="ai-menu-main"
            >
                <div
                    v-if="suggestions.length > 0"
                    class="ai-menu-content"
                    :class="{ 'mb-0': !hasMenuContent }"
                >
                    <div class="ai-menu-title">
                        <t>Suggestions</t>
                    </div>
                    <template v-if="internalSettings.showFormatOptions === true">
                        <div class="ai-menu-items">
                            <div
                                v-for="(formatOption, index) in formatOptions"
                                :key="index"
                                class="ai-menu-item"
                                @click="onFormatOptionClick(formatOption)"
                            >
                                <FontAwesomeIcon
                                    v-if="formatOption.icon"
                                    class="ai-menu-icon"
                                    :icon="formatOption.icon"
                                />
                                {{ formatOption.value }}
                            </div>
                        </div>
                        <div class="ai-menu-separator" />
                    </template>

                    <div class="ai-menu-items">
                        <div
                            v-for="(suggestion, index) in suggestions"
                            :key="index"
                            class="ai-menu-item"
                            @click="onSuggestionClick(suggestion)"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-sparkles mr-2"
                                width="14"
                                height="14"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ><path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                            /><path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" /></svg>

                            {{ suggestion.value }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="hasMenuContent"
                    class="ai-menu-separator"
                />
                <div
                    v-if="hasMenuContent"
                    class="ai-menu-content"
                >
                    <div class="ai-menu-title">
                        <t>Settings</t>
                    </div>
                    <div class="ai-menu-items">
                        <div
                            v-if="internalSettings.hasToneMenu"
                            class="ai-menu-item"
                            style="gap: 10px;"
                            @click.prevent="toggleMenuTones"
                        >
                            <div class="flex-grow-1 d-flex justify-content-between">
                                <div>
                                    <FontAwesomeIcon
                                        class="ai-menu-icon"
                                        :icon="['fas', 'microphone-alt']"
                                    />
                                    <t>Set the tone</t>
                                </div>
                                <span>({{ currentTone.name }})</span>
                            </div>
                            <div>
                                <i
                                    class="fas fa-chevron-right"
                                    style="font-size: 0.9em;"
                                />
                            </div>
                        </div>
                        <div
                            v-if="internalSettings.hasMaxContentMenu"
                            class="ai-menu-item"
                            style="gap: 10px;"
                        >
                            <div class="flex-grow-1 d-flex justify-content-between">
                                <div class="flex-grow-1">
                                    <FontAwesomeIcon
                                        class="ai-menu-icon"
                                        :icon="['fas', 'question']"
                                    />
                                    <t>Maximum content</t>
                                </div>

                                <input
                                    id="maxAIContent"
                                    v-model="maxAIContent"
                                    style="width: 100px; text-align: right;"
                                    name="maxAIContent"
                                    type="number"
                                    :max="internalSettings.maxAIContentLimit"
                                >
                            </div>
                        </div>
                        <div class="ai-menu-separator" />
                        <div
                            v-if="internalSettings.hasToggleClearContent"
                            class="ai-menu-item"
                            style="gap: 10px;"
                        >
                            <div class="flex-grow-1 d-flex justify-content-between">
                                <div class="flex-grow-1">
                                    <FontAwesomeIcon
                                        class="ai-menu-icon"
                                        :icon="['far', 'trash-alt']"
                                    />
                                    <t>Clear content</t>
                                </div>

                                <HtFormSwitch
                                    :id="'toggle-clear-content'"
                                    v-model="shouldClearContent"
                                    class="mb-0"
                                    :name="'toggle-clear-content'"
                                    :show-optional="false"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="isMenuTonesVisible && internalSettings.hasToneMenu"
                class="ai-menu-tones"
            >
                <div
                    class="ai-menu-content"
                >
                    <div class="ai-menu-title">
                        <button
                            class="btn"
                            @click.prevent="toggleMenuTones"
                        >
                            <i
                                class="fas fa-chevron-left"
                                style="font-size: 0.9em;"
                            />
                        </button>
                        <t>Tones</t>
                    </div>
                    <div class="ai-menu-items">
                        <div
                            v-for="tone in tones"
                            :key="tone.value"
                            class="ai-menu-item"
                            @click="selectCurrentTone(tone)"
                        >
                            <div class="flex-grow-1">
                                {{ tone.name }}
                            </div>

                            <div v-if="tone.value === currentTone.value">
                                <i
                                    class="fas fa-check branding-color"
                                    style="font-size: 0.9em;"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ThinkingLoader from '@/components/chat/message/ThinkingLoader.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'AIForm',
    components: { HtFormSwitch, ThinkingLoader },
    props: {
        settings: {
            type: Object,
            default: () => ({}),
        },
        suggestions: {
            type: Array,
            default: () => ([]),
        },
        formatOptions: {
            type: Array,
            default: () => ([]),
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            defaultSettings: {
                hasMaxContentMenu: true,
                hasToneMenu: true,
                hasClearContentToggle: false,
                defaultClearContentValue: false,
                showFormatOptions: false,
                maxAIContentLimit: 10,
            },
            internalSettings: null,
            token: null,
            mode: null,
            shouldClearContent: false,
            aiInput: '',
            isAIInputFocused: false,
            isMouseOverMenus: false,
            isMenuTonesVisible: false,
            tone: 'professional',
            maxAIContent: 10,
            currentLanguage: this.shared.session.company.company_language,
            companyLanguage: this.shared.session.company.company_languages,
            currentSuggestion: null,
            currentTone: { value: 'professional', name: this.translate('professional') },
            tones: [
                { value: 'professional', name: this.translate('professional') },
                { value: 'useful', name: this.translate('useful') },
                { value: 'spiritual', name: this.translate('spiritual') },
                { value: 'formal', name: this.translate('formal') },
                { value: 'firm', name: this.translate('firm') },
                { value: 'optimistic', name: this.translate('optimistic') },
                { value: 'humorous', name: this.translate('humorous') },
            ],
        };
    },

    computed: {
        languages() {
            return this.companyLanguage.enabled.map((companyLanguage) => companyLanguage.key).join(',');
        },

        hasAIInputFilled() {
            return this.aiInput.trim() !== '';
        },

        hasMenuContent() {
            return this.internalSettings.hasToneMenu || this.internalSettings.hasMaxContentMenu;
        },

        isAIMenuVisible() {
            if (this.isLoading === true) {
                return false;
            }

            if (this.isAIInputFocused === true) {
                return true;
            }

            return this.isMouseOverMenus === true;
        },
    },

    watch: {
        shouldClearContent(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$emit('onClearContentToggle', newValue);
            }
        },
    },

    mounted() {
        this.internalSettings = { ...this.defaultSettings, ...this.settings };

        this.maxAIContent = this.internalSettings.maxAIContentLimit;

        this.shouldClearContent = this.internalSettings.defaultClearContentValue ?? false;
    },

    methods: {
        onMouseOutMenus() {
            this.isMouseOverMenus = false;
            this.$refs.aiInput.focus();
        },

        onKeyDown(event) {
            if (event.code === 'Enter') {
                if (event.shiftKey === true) {
                    return;
                }

                this.askAI();

                event.preventDefault();
                event.stopPropagation();
            }
        },

        selectCurrentTone(value) {
            this.currentTone = value;

            this.aiInput = this.translate('Change the tone.');

            this.toggleMenuTones();

            this.$emit('onToneChange', value);
        },

        onSuggestionClick(suggestion) {
            this.currentSuggestion = suggestion;

            this.aiInput = suggestion.value;

            this.$emit('onSuggestionClick', suggestion);
        },

        onFormatOptionClick(formatOption) {
            this.currentSuggestion = formatOption;

            this.aiInput = formatOption.value;

            this.$emit('onFormatOptionClick', formatOption);
        },

        clearContent() {
            this.aiInput = '';
        },

        toggleMenuTones() {
            this.isMenuTonesVisible = !this.isMenuTonesVisible;
        },

        async askAI() {
            if (this.hasAIInputFilled === false) {
                return;
            }

            this.$nextTick(() => {
                this.$refs.aiInput.blur();
            });

            this.$emit('onAskAI', {
                input: this.aiInput,
                tone: this.currentTone,
                maxContent: this.maxAIContent,
                languages: this.languages,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.ai-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin: 3rem 0;
  padding: 0;
  width: 100%;

  .ai-form {
    display: flex;
    border: 1px solid $grey-ultra-light;
    height: auto;
    min-height: 50px;
    width: 100%;
    align-items: center;

    .ai-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
    }

    .ai-send-button {
      background-color: white;
      width: 45px;
      font-size: 20px;

      &:disabled {
        opacity: 0.5;
      }
    }

    .grow-wrap {
      /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
      display: grid;
      flex-grow: 1;
    }
    .grow-wrap::after {
      /* Note the weird space! Needed to preventy jumpy behavior */
      content: attr(data-replicated-value) " ";

      /* This is how textarea text behaves */
      white-space: pre-wrap;

      /* Hidden from view, clicks, and screen readers */
      visibility: hidden;
    }

    .grow-wrap > .ai-input {
      border-radius: 0!important;
      border: none!important;
      min-height: auto!important;
      font-size: 0.9em;
      margin-left: 0!important;
      padding-left: 0!important;

      &:focus {
        outline: none;
      }

      &::placeholder {
        margin-left: 0!important;
        padding-left: 0!important;
        font-size: 0.8em;
        padding-top: 2px;
      }
    }

    .grow-wrap > .ai-input {
      /* You could leave this, but after a user resizes, then it ruins the auto sizing */
      resize: none;

      /* Firefox shows scrollbar on growth, you can hide like this. */
      overflow: hidden;
    }

    .grow-wrap > .ai-input, .grow-wrap::after {
      /* Identical styling required!! */
      border: 1px solid black;
      padding: 0.5rem;
      font: inherit;

      /* Place on top of each other */
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  .ai-menu {
    border: 1px solid $grey-ultra-light;
    border-top: none;
    padding: 10px;
    font-size: 0.9em;
    width: 100%;

      .ai-menu-icon {
        margin-right: 4px;
        width: 14px;
      }

    .ai-menu-content:not(:last-of-type) {
      margin-bottom: 10px;
    }

    .ai-menu-title {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .ai-menu-separator {
      border-bottom: 1px solid $grey-ultra-light;
      margin: 5px 20px 5px 0;
    }

    .ai-menu-items {
      border-left: 3px solid $color-primary;

      .ai-menu-item {
        padding: 5px 7px;
        display: flex;
        align-items: center;

        &:hover {
          background-color: $grey-ultra-light;
          border-radius: 0 10px 10px 0;
          transition: background-color 0.4s;
          cursor: pointer;
        }
      }
    }
  }
}

</style>
