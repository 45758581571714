import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyTagTemplate from '@/models/CompanyTagTemplate';

export default class CompanyTagTemplateCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyTagTemplate,
        };
    }
}
