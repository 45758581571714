<template>
    <fieldset class="gender-selection-wrapper">
        <div
            v-for="(availableGender, index) in availableGenders"
            :key="index"
            class="gender-selection"
        >
            <div>
                <label
                    class="radio flex-1"
                    :for="availableGender.value"
                    :data-cy="`gender-${availableGender.value}`"
                >
                    <div class="d-flex align-items-center">
                        <input
                            :id="availableGender.value"
                            v-model="companyUser.gender"
                            type="radio"
                            :value="availableGender.value"
                        >
                        <div class="input" />
                        <t>{{ availableGender.name }}</t>
                    </div>
                </label>
            </div>
        </div>
    </fieldset>
</template>

<script>

export default {
    name: 'GenderSelection',
    props: {
        companyUser: {
            type: Object,
            required: true,
        },
        neutralGender: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        availableGenders() {
            const availableGenders = [
                {
                    value: 'male',
                    name: 'Male',
                },
                {
                    value: 'female',
                    name: 'Female',
                },
            ];
            if (this.neutralGender) {
                availableGenders.push({
                    value: 'neutral',
                    name: 'Neutral',
                });
            }
            return availableGenders;
        },
    },
};
</script>

<style lang="scss" scoped>
fieldset {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    .gender-selection {
        flex: 1;
    }
}

.form-2 .radio {
    width: 100%;
    justify-content: start;
    padding-left: 15px;
}
</style>
