import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserQuizQuestionAnswer from './CompanyUserQuizQuestionAnswer';

export default class CompanyUserQuizQuestionAnswerCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserQuizQuestionAnswer,
        };
    }
}
