<template>
    <div class="chat-search">
        <div class="chat-search-top">
            <div class="chat-search-textfield">
                <HtSearchField
                    id="search"
                    v-model.trim="search"
                    type="text"
                    class="chat-search-input"
                    :placeholder="translate('Find a user to talk with !')"
                    name="search"
                />
            </div>
            <div class="search-result-container">
                <slot v-if="search.length === 0 && canCreateGroup" />
                <div v-if="search.length > 0">
                    <ChatListChannelItemHtml
                        v-for="user in users"
                        :key="user.id"
                        :image="user.image"
                        :image-firstname="user.firstname"
                        :image-lastname="user.lastname"
                        :name="user.fullname"
                        :subname="user.professional_email"
                        :is-connected="user.isConnected"
                        @mousedown.native="toggleUser(user)"
                    >
                        <HtCheckbox
                            :id="'checkbox_'+user.id"
                            class="seearch-result-container-checkbox"
                            :name="'checkbox_'+user.id"
                            :value="isSelected(user.id)"
                        />
                    </ChatListChannelItemHtml>
                </div>
            </div>
        </div>
        <div class="chat-search-bottom">
            <div class="selected-user-list">
                <div class="selected-user-list-label">
                    {{ selectedUsers.length }}<t> participants selected</t>
                </div>
                <div
                    v-for="user in selectedUsers"
                    :key="user.id"
                    class="selected-user-container"
                >
                    <div
                        v-user-image="{
                            firstname: user.firstname,
                            lastname: user.lastname,
                            image: user.image
                        }"
                        class="list-channel-item-avatar-img"
                    />
                    <FontAwesomeLayers
                        class="selected-user-remove"
                        @click="removeUser(user)"
                    >
                        <FontAwesomeIcon
                            icon="circle"
                            class="icon-circle"
                        />
                        <FontAwesomeIcon
                            :icon="['fal', 'times']"
                            transform="shrink-9"
                            class="icon-second"
                        />
                    </FontAwesomeLayers>
                </div>
            </div>
            <div class="chat-search-actions">
                <Button
                    class="negative"
                    @click="$emit('cancel')"
                >
                    <t>Cancel</t>
                </Button>
                <Button
                    :state="selectedUsers.length > 0 ? 'idle' : 'disabled'"
                    @click="addChannel"
                >
                    <t v-if="!channel">
                        Create conversation
                    </t>
                    <t v-else>
                        Save
                    </t>
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyChatChannel from '@/models/CompanyChatChannel';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import HtCheckbox from '@/components/globals/HtCheckbox.vue';
import HtButton from '@/components/globals/HtButton.vue';
import ChatSearchMixin from './mixins/ChatSearchMixin';
import NewChannelMixin from './mixins/NewChannelMixin';

export default {
    name: 'ChatConversationManager',
    components: {
        HtButton,
        HtSearchField,
        HtCheckbox,
    },
    mixins: [ChatSearchMixin, NewChannelMixin],
    permissions: [
        'AbstractCreateChatGroup',
    ],
    props: {
        channel: {
            type: CompanyChatChannel,
            default: null,
        },
    },
    data() {
        return {
            selectedUsers: [],
        };
    },
    computed: {
        canCreateGroup() {
            return this.$options.permissions.some((permission) => this.$canRead(permission));
        },
    },
    watch: {
        channel() {
            this.initSelectUsers();
        },
    },
    mounted() {
        this.initSelectUsers();
    },
    methods: {
        initSelectUsers() {
            if (this.channel) {
                this.selectedUsers = this.channel.company_chat_channel_user.models
                    .filter((x) => x.company_user_id !== this.shared.session.companyUser.id)
                    .map((x) => x.company_user);
            }
        },
        toggleUser(user) {
            if (!this.isSelected(user.id)) {
                this.selectedUsers.push(user);
            } else {
                this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1);
            }
        },

        removeUser(user) {
            this.selectedUsers = this.selectedUsers.filter((u) => u.id !== user.id);
        },

        addChannel() {
            const oldChannel = this.getOldChannel();

            if (oldChannel) {
                this.shared.socket.channelCollection.setActive(oldChannel.id);
                this.$emit('close');
                return;
            }
            this.createNewChannel(
                this.selectedUsers.map((user) => user.id),
                this.selectedUsers.length >= 2 ? 'multi' : 'duo',
            )
                .then(() => {
                    this.reset();
                    this.$emit('close');
                });
        },

        getOldChannel() {
            let oldChannel = false;

            this.shared.socket.channelCollection.models.forEach((channel) => {
                if (this.checkChannel(
                    channel.company_chat_channel_user.models
                        .filter((u) => u.company_user_id !== this.shared.session.companyUser.id),
                )) {
                    oldChannel = channel;
                }
            });

            return oldChannel;
        },

        checkChannel(channelUsers) {
            // multi channel match search if all and every selectedUsers are part of it
            const usersMatch = this.selectedUsers.every((selected) => channelUsers.some((u) => u.company_user_id === selected.id));
            const onlyUsers = this.selectedUsers.length === channelUsers.length;

            return usersMatch && onlyUsers;
        },
        reset() {
            this.selectedUsers = [];
            this.clear();
        },
        isSelected(userId) {
            return this.selectedUsers.map((u) => u.id).includes(userId);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

    .chat-search {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        position: relative;
        height: 100%;

        &-top {
            flex: 1;
            max-height: calc(100% - 163px);
            overflow: hidden;
        }

        &-actions {
            z-index: 10;
            height: 60px;
            background-color: $grey-ultra-light;
            border-top: solid 1px $grey-ultra-light;
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            position: sticky;
            bottom: 0;
            width: 100%;
        }

        &-textfield {
            height: 60px;
            z-index: 10;
            padding: 10px;
        }
    }

    .search-result-container {
        max-height: 100%;
        margin-bottom: 70px;
        overflow: auto;
    }

    .selected-user-list {
        z-index: 10;
        position: sticky;
        bottom: 60px;
        left: 0;
        border-top: solid #E8E8E8 .5px;
        padding: 5px;
        padding-bottom: 10px;
        display: inline-block;
        height: 100px;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        &-label {
            color: #A3A3A3;
            padding: 5px;
            position: absolute;
            font-size: 12px;
        }
    }

    .selected-user-container {
        display: inline-block;
        margin-top: 3.5rem;
        margin-right: 1rem;
        margin-bottom: 1em;
    }

    .list-channel-item-avatar-img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        &.bg-red {
            background-color: $primary;
        }
    }
    .selected-user-remove {
        position: relative;
        bottom:40px;
        left: 30px;
        cursor: pointer;

        .icon-circle {
            color: red;
        }

        .icon-second {
            color: white;
        }
    }
    .search-icon {
        width: 1.5rem;
        transform: translateX(2.5rem);
        color: $black-2;

        position: absolute;
        left: -1.5rem;
    }

    @media (max-width: $phone) {
        .chat-search {
            &-actions {
                .button {
                    min-width: 160px;
                }
            }
        }
        .selected-user-list {
            margin-right: 0;
            margin-left: 0;
            padding-left:.5rem;
            padding-right: .5rem;
            width: 100vw;
        }
    }
</style>
