<template>
    <modalable
        ref="modalableConfirmDeletion"
        class="modal"
        :class="size"
    >
        <div class="modal-title">
            <t>{{ header }}</t>
        </div>
        <p><t>{{ body }}</t></p>
        <div class="row-line middle">
            <Button
                class="black"
                @click="$refs.modalableConfirmDeletion.close()"
            >
                <t>{{ cancel }}</t>
            </Button>
            <Button @click="onDestroy">
                <t>{{ confirm }}</t>
            </Button>
        </div>
    </modalable>
</template>

<script>
export default {
    name: 'Deletable',

    props: {
        size: { type: String, default: 'modalable-4' },
        header: { type: String, default: 'Delete this task?' },
        body: { type: String, default: 'Are you sure you want to delete this item? Once you click on delete, you will no longer be able to restore it.' },
        cancel: { type: String, default: 'Cancel' },
        confirm: { type: String, default: 'Delete' },
    },

    data() {
        return {

        };
    },

    methods: {
        open() {
            this.$refs.modalableConfirmDeletion.open();
        },

        onDestroy() {
            this.$refs.modalableConfirmDeletion.close();
            this.$emit('onDestroy');
        },
    },
};
</script>

<style lang="scss">
	@import '~@/styles/var';

	.modal.modalable-4 {
		.overlay {
			background: $white;

			.modal .content {
				margin: auto;
			}
		}
	}
</style>
