<template>
    <div :class="['wrapper', {'selected': isSelected}]">
        <div class="title">
            <slot name="title">
                {{ label }}
            </slot>
        </div>
        <template v-if="typeof percent === 'number'">
            <div
                :class="[
                    'background',
                    {'full-border': percent === 100}
                ]"
                :style="{'width': percent + '%'}"
            />
            <div class="percent">
                {{ percent }}%
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'ChoiceResultItem',
    props: {
        percent: {
            type: Number,
            default: null,
        },
        label: {
            type: String,
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
.wrapper {
    border-radius: $border-radius-8;
    border: 1px solid $grey-ultra-light;
    padding: 20px 30px;
    height: 72px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.selected {
    border-color: $primary;
}
.percent {
    font-weight: bold;
    z-index: 20;
}
.title {
    display: flex;
    align-items: center;
    z-index: 20;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25em;
}
.full-border {
    border-top-right-radius: $border-radius-8;
    border-bottom-right-radius: $border-radius-8;
}
.background {
    position: absolute;
    top: 0;
    left: 0;
    height: 70px;
    background: $grey-light;
    border-top-left-radius: $border-radius-8;
    border-bottom-left-radius: $border-radius-8;
}
</style>
