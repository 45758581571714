<template>
    <div class="dropdown">
        <div
            class="dropdown-header"
            @click="toggleDropdown"
        >
            <div>
                <t>Program</t> {{ item.programName }}
            </div>
            <span class="dropdown-arrow">
                <FontAwesomeIcon
                    :icon="isOpen ? 'chevron-up' : 'chevron-down'"
                    class="chevron"
                />
            </span>
        </div>
        <transition name="dropdown">
            <div
                v-if="isOpen"
                class="dropdown-content"
            >
                <div
                    v-for="(userRequirementsGroup, userRequirementsGroupIndex) in item.userRequirementsGroup"
                    :key="userRequirementsGroupIndex"
                    class="dropdown-item-group"
                >
                    <div
                        v-for="(
                            userRequirement, userRequirementIndex
                        ) in userRequirementsGroup.userRequirements"
                        :key="userRequirementIndex"
                        class="dropdown-item"
                    >
                        <div class="dropdown-label">
                            {{ userRequirement.name }}&nbsp;<span
                                v-if="userRequirement.is_mandatory"
                                class="mandatory-asterisk"
                            >*</span>
                        </div>

                        <div class="dropdown-value">
                            <UserRequirementRead
                                :index="`${userRequirementIndex}-${userRequirementsGroupIndex}`"
                                :is-participant="true"
                                :user-requirement="userRequirement"
                                :user-id="userRequirement.company_user_id"
                            />
                        </div>
                    </div>
                    <hr
                        v-if="userRequirementsGroupIndex <item.userRequirementsGroup.length - 1"
                        class="dropdown-item-group-separator"
                    >
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import UserRequirementRead from '@/components/pages/onboarding/UserRequirementRead.vue';

export default {
    components: {
        UserRequirementRead,
    },
    props: {
        item: {
            type: Object,
            required: true,
            validator(value) {
                const hasProgramName = typeof value.programName === 'string';
                const hasUserRequirementsGroup = Array.isArray(value.userRequirementsGroup)
                    && value.userRequirementsGroup.every(
                        (group) => typeof group.group === 'number'
                            && Array.isArray(group.userRequirements)
                            && group.userRequirements.every(
                                (req) => typeof req.name === 'string'
                                    && typeof req.value !== 'undefined',
                            ),
                    );

                return hasProgramName && hasUserRequirementsGroup;
            },
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>
<style scoped lang="scss">
.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 0.1s ease;
}

.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.dropdown {
  font-size: 1.4rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  background-color: #f5f7f8;

  &-label {
    font-weight: bold;
  }

  &-header {
    padding: 10px 15px;
    background-color: #f3f3f3;
    border-bottom: 0.5px solid #ccc;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    font-weight: bold;
  }

  &-item-group {
    margin-bottom: 10px;

    &-separator {
      border: none;
      border-top: 1px solid #ccc;
      margin: 10px 0;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px;
    margin: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 5px;

    &-row {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &-value {
    /deep/ .file-upload {
      padding: 8px;
    }
  }
}
</style>
