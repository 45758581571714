<template>
    <div
        v-if="!edit_mode"
        class="item-dashed"
    >
        <div class="item-name">
            <label v-if="label">
                <t>{{ label }}</t>
            </label>
            <div
                v-if="show_end && probation_end"
                class="details"
            >
                ( {{ `${translate('until')} ${$Utils.moment(probation_end).format(dateFormat)}` }} )
            </div>
        </div>
        <div class="item-content right">
            <strong>
                <t
                    v-if="probation"
                    :count="probation"
                >{count} {{ probation_unit }} | {count} {{ probation_unit + 's' }}</t>
                <span v-else>-</span>
            </strong>
        </div>
    </div>

    <div
        v-else
        :class="{ error: errors.has('first_probation') }"
    >
        <div class="wrapper-half-form">
            <div class="half item-content">
                <input
                    :id="'first_probation' + _uid"
                    v-model="probationNumber"
                    v-validate="'numeric'"
                    class="full"
                    type="text"
                    name="first_probation"
                    :class="{'input-has-value': probationValue}"
                    :disabled="disabled"
                    :data-cy="'first-probation-value'"
                    @default.prevent=""
                >
                <label
                    v-if="label"
                    :for="'first_probation' + _uid"
                >
                    <t>{{ label }}</t>
                </label>
            </div>

            <div class="half">
                <HtFormSelect
                    id="first-probation-unit"
                    v-model="probationUnit"
                    name="first-probation-unit"
                    :options="probationUnitOptions"
                    :disabled="disabled"
                    :cypress="'first-probation-unit'"
                    @input="$emit('update:probation_unit', probationUnit)"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProbationPeriod',
    props: {
        label: { type: String, required: false },
        probation: { type: [String, Number], default: 0 },
        probation_end: { type: String, required: false },
        probation_unit: { type: String, default: 'day' },
        edit_mode: { type: Boolean, default: true },
        show_end: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        cypress: { type: String, default: 'probation-unit' },
    },
    data() {
        return {
            probationUnit: 'day',
            probationValue: null,
        };
    },
    computed: {
        probationNumber: {
            get() {
                return this.probationValue;
            },
            set(number) {
                const regex = '^[1-9][0-9]*$';
                if (!number || number.match(regex)) this.probationValue = number;
            },
        },

        probationUnitOptions() {
            return [{
                id: 'day',
                name: this.translate('calendar day | calendar days', { count: this.probationValue }),
            }, {
                id: 'week',
                name: this.translate('week | weeks', { count: this.probationValue }),
            }, {
                id: 'month',
                name: this.translate('month | months', { count: this.probationValue }),
            }];
        },
        dateFormat() {
            const language = this.shared.session.companyUser.company_language.key;

            return language === 'fr' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
        },
    },
    watch: {
        probationValue(val) {
            this.$emit('update:probation', (!val) ? null : val);
            this.$emit('update:probation_unit', (!val) ? null : this.probationUnit);
        },
    },
    created() {
        if (this.probation_unit) {
            this.probationUnit = this.probation_unit;
        }

        if (this.probation > 0) {
            this.probationValue = this.probation;
        }
    },
};
</script>
<style lang="scss" scoped>
    .wrapper-half-form {
        gap: 16px;
    }

    .half {
        flex: 1;
    }

    .item-content {
        padding: 0;
    }
</style>
