import Index from '@/router/pages/Index.vue';
import CompanyPage from '@/pages/Company.vue';
import DeepAssign from '@/pages/bugs/DeepAssign.vue';
import Auth from '@/services/Auth';
import MailMiddleware from '@/components/middleware/Mail.vue';
import Locale from '@/pages/admin/Locale.vue';
import Forbidden from '@/pages/Forbidden.vue';
import DocusignRedirectedPage from '@/pages/DocusignRedirectedPage.vue';
import Openid from '@/pages/Openid.vue';
import PagePrivacyPolicy from '@/components/public/PagePrivacyPolicy.vue';
import PageTermsAndConditions from '@/components/public/PageTermsAndConditions.vue';
import Maintenance from '@/pages/Maintenance.vue';
import PocArea from '@/pages/PocArea.vue';
import TaskValidator from '@/pages/TaskValidator.vue';
import resourceRoutes from './root/resource';
import dashboardRoutes from './root/dashboard';
import programRoutes from './root/program';
import teamRoutes from './root/team';
import settingsRoutes from './root/settings';
import surveyResultsRoutes from './root/survey-results';
import profileRoutes from './root/profile';

export default [
    {
        path: '/',
        component: Index,
        children: [
            ...dashboardRoutes,
            ...programRoutes,
            ...surveyResultsRoutes,
            ...resourceRoutes,
            {
                path: '/Company',
                name: 'Company',
                component: CompanyPage,
                meta: {
                    permission: true,
                },
            },
            ...teamRoutes,
            ...settingsRoutes,
            ...profileRoutes,
            {
                path: '/Dev/DeepAssign',
                component: DeepAssign,
                meta: { access: [Auth.ROLE.superadmin] },
            },
            {
                path: '/PocArea',
                name: 'PocArea',
                component: PocArea,
                meta: { access: [Auth.ROLE.superadmin] },
            },
        ],
    },
    {
        path: '/Mail/:uuid',
        component: MailMiddleware,
        meta: {
            public: true,
        },
    },
    {
        path: '/Locale',
        component: Locale,
        name: 'Locale',
        meta: {
            public: true,
        },
    },
    {
        path: '/Forbidden',
        name: 'Forbidden',
        component: Forbidden,
        meta: {
            public: true,
        },
    },
    {
        path: '/DocusignRedirectedPage',
        name: 'DocusignRedirectedPage',
        component: DocusignRedirectedPage,
        meta: {
            public: true,
        },
    },

    {
        path: '/openid',
        component: Openid,
        meta: {
            public: true,
        },
    },
    {
        path: '/Privacy-Policy/:lang',
        name: 'PrivacyPolicy',
        component: PagePrivacyPolicy,
        meta: {
            public: true,
        },
    },
    {
        path: '/Terms-And-Conditions/:lang',
        name: 'TermsAndConditions',
        component: PageTermsAndConditions,
        meta: {
            public: true,
        },
    },
    {
        path: '/Maintenance',
        name: 'Maintenance',
        component: Maintenance,
        meta: {
            public: true,
        },
    },
    {
        path: '/Task/Mail/Validate',
        component: TaskValidator,
        meta: {
            public: true,
        },
    },
];
