<template>
    <div>
        <div class="d-flex justify-content-between status-wrapper">
            <HtFormSelect
                id="creator"
                v-model="currentCreator"
                v-validate.disable="'required'"
                name="creator"
                :options="getContacts"
                :label="'Créateur'"
                class="flex-1"
            />
        </div>

        <div class="d-flex justify-content-between status-wrapper">
            <HtFormSelect
                id="folder"
                v-model="defaultFolder"
                v-validate.disable="'required'"
                name="folder"
                :options="getTaskFolders"
                :label="'Dossier Wrike'"
                class="flex-1"
                :disabled="true"
            />

            <HtFormSelect
                id="status"
                v-model="defaultStatus"
                v-validate.disable="'required'"
                name="status"
                :options="getOptionsStatuses"
                :label="'Status'"
                class="flex-1"
                :disabled="true"
            />
        </div>

        <HtFormInput
            :id="'title'"
            v-model="taskTitle"
            v-validate.disable="'required'"
            :label="'Titre du bug'"
            :name="'title'"
        />

        <HtFormEditor
            :id="'description'"
            v-model="description"
            v-validate.disable="'required'"
            :label="'Description'"
            placeholder="Describe the issue"
            :name="'description'"
        />

        <HtFormGroup
            :label="'Pièces-jointes'"
        />

        <input
            id="file"
            ref="file"
            type="file"
            class="mb-3"
            @change="handleFileUpload"
        >

        <div class="ht-form">
            <a
                :href="permalinkWrike"
                target="_blank"
            >{{ permalinkWrike }}</a>
        </div>

        <div class="modal-actions right mt-5">
            <Button
                :state="buttonState"
                @click="onValidate"
            >
                Créer
            </Button>
        </div>
    </div>
</template>

<script>
import Raven from 'raven-js';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import get from 'lodash.get';
import WrikeApi from '@/classes/ThirdParty/WrikeApi';

export default {
    name: 'BugReportContent',

    components: {
        HtFormSwitch,
        HtFormSelect,
    },

    inject: ['modal'],

    props: {
        title: {
            type: String,
            default: () => 'Modal title',
        },
        defaultFolder: {
            type: String,
            required: true,
        },
        defaultStatus: {
            type: String,
            required: true,
        },
        defaultWorkflow: {
            type: String,
            required: true,
        },
    },

    shared: {
        wrikeApi: {
            workflows: {
                type: Array,
                default: [],
            },
            contacts: {
                type: Array,
                default: [],
            },
            clientFolders: {
                type: Array,
                default: [],
            },
            taskFolders: {
                type: Array,
                default: [],
            },
        },
    },

    data() {
        return {
            buttonState: 'idle',
            permalinkWrike: null,
            wrike: null,
            currentClientFolder: null,
            currentCreator: null,
            clientFolderId: 'IEADI6F4I4P4EHII',
            currentFolders: [
            ],
            folderIds: [
                'IEADI6F4I4Q3ZRGQ', // Bugs
                'IEADI6F4I4WKCNC3', // Sprint lead stab
                'IEADI6F4I4P4DLOA', // Sprint
                'IEADI6F4I4V2OSZY', // Bug prio
                'IEADI6F4I4T34W22', // 1. Bug Critique
                'IEADI6F4I4T34W5B', // 2. Bug Prio
                'IEADI6F4I4T34XCW', // 3. Bug standard
                'IEADI6F4I4T34XES', // 4. Bug basse prio
                'IEADI6F4I4T34XIM', // 5. Bug suspendu
                'IEADI6F4I4Q46UVY', // Priorisation client
                'IEADI6F4I4YJ5YBF', // Quick win
                'IEADI6F4I4TY7WSU', // Amélioration produit
                'IEADI6F4I4TVKT7B', // Préparation du sprint
            ],
            currentResponsibles: [],
            taskTitle: '[THÈME] : rapide description du problème (et non pas du comportement attendu)',
            attachment: {
                fileName: null,
                file: null,
            },
            description:
                '🎬 <strong>Scénario :</strong><br>'
                + 'Connecté en tant que Super Administrateur <br>'
                + 'Ce problème apparaît lorsque je <br><br>'
                + '🧐 <strong>Comportement actuel :</strong><br>'
                + 'J\'observe<br><br>'
                + '😁 <strong>Comportement attendu :</strong><br>'
                + 'Je devrais observer<br><br>'
                + '(optionnel) taux de reproductibilité / commentaire / note / bon à savoir ...',
            sentryLink: '',
            autoconnect: '',
        };
    },

    computed: {
        getOptionsStatuses() {
            const currentWorkflow = this.getWorkflows.find((workflow) => workflow.id === this.defaultWorkflow);

            return get(currentWorkflow, 'customStatuses', []);
        },

        getWorkflows() {
            return get(this.shared, 'wrikeApi.workflows', []);
        },

        getContacts() {
            return get(this.shared, 'wrikeApi.contacts', []);
        },

        getClientFolders() {
            const clientFolders = get(this.shared, 'wrikeApi.clientFolders', []);
            return clientFolders.map((folder) => ({
                id: folder.id,
                name: folder.title,
            }));
        },

        getTaskFolders() {
            const taskFolders = get(this.shared, 'wrikeApi.taskFolders', []);
            return taskFolders.map((folder) => ({
                id: folder.id,
                name: folder.title,
            }));
        },

        getResponsibleIds() {
            return this.currentResponsibles.map((responsible) => responsible.id);
        },

        hasFile() {
            return this.attachment.file && this.attachment.fileName;
        },

        connectedUserId() {
            return get(this.shared, 'session.companyUser.id', null);
        },
    },

    created() {
        this.modal.setTitle(this.title);
        this.wrike = new WrikeApi(this.shared.wrikeApi.authUser.code);

        this.wrike.getToken(true).then(() => {
            this.loadWorkflows();
            this.loadContacts();
            this.loadClientFolders();
            this.loadTaskFolders();
        }).catch((error) => {
            this.$Notifier('App').showError(error);
            this.modal.close();
        });

        if (this.shared.session) {
            const protocol = window.location.href.split('/')[0];
            const webUrl = this.$env.getFromEnv('COMPANY_WEB_URL', {
                company_name: this.shared.session.company.domain,
            });

            this.autoconnect = `${protocol}${webUrl}/autoconnect/${this.connectedUserId}?url=${encodeURIComponent(this.$route.fullPath)}`;
        }

        if (Raven !== undefined && Raven._lastData != undefined) this.sentryLink = `https://sentry.io/heyteam/${Raven._lastData.environment}/issues/?query=${Raven._lastData.event_id}`;
    },

    methods: {
        loadWorkflows() {
            // Load Workflows
            const currentLoadedWorkflows = get(this.shared, 'wrikeApi.workflows', []);

            if (currentLoadedWorkflows.length === 0) {
                this.wrike.getWorkflows().then((workflows) => {
                    this.shared.wrikeApi.workflows = workflows;
                });
            }
        },

        loadContacts() {
            // Load Contacts
            const currentLoadedContacts = get(this.shared, 'wrikeApi.contacts', []);

            if (currentLoadedContacts.length === 0) {
                this.wrike.getUsers().then((contacts) => {
                    this.shared.wrikeApi.contacts = contacts.filter((contact) => contact.deleted === false && contact.type === 'Person' && contact.profiles[0].external === false).map((contact) => ({
                        id: contact.id,
                        name: `${contact.firstName} ${contact.lastName}`,
                    }));

                    this.setLocalStorageCreator();
                });
            } else {
                this.setLocalStorageCreator();
            }
        },

        loadClientFolders() {
            // Load Client Folders
            const currentLoadedClientFolders = get(this.shared, 'wrikeApi.clientFolders', []);

            if (currentLoadedClientFolders.length === 0) {
                this.wrike.getFolders(this.clientFolderId).then((clientFolders) => {
                    this.shared.wrikeApi.clientFolders = clientFolders;
                });
            }
        },

        loadTaskFolders() {
            // Load Task Folders
            const currentLoadedtaskFolders = get(this.shared, 'wrikeApi.taskFolders', []);

            if (currentLoadedtaskFolders.length === 0) {
                this.wrike.getMultipleFolders(this.folderIds).then((taskFolders) => {
                    this.shared.wrikeApi.taskFolders = taskFolders;
                    this.addFolder();
                });
            } else {
                this.addFolder();
            }
        },

        addFolder() {
            const currentFolder = this.shared.wrikeApi.taskFolders.find((folder) => folder.id === this.defaultFolder);

            this.currentFolders.push({
                id: currentFolder.id,
                name: currentFolder.title,
            });
        },

        setLocalStorageCreator() {
            const creator = this.shared.wrikeApi.contacts.find((contact) => contact.id === window.localStorage.getItem('wrikeCardCreator'));

            if (creator) {
                this.currentCreator = creator.id;
            }
        },

        handleFileUpload(event) {
            this.attachment = event.target.files[0];
        },

        createTask(task, folderId) {
            this.buttonState = 'loading';

            this.wrike.createTask(folderId, task).then((taskCreated) => {
                this.permalinkWrike = taskCreated[0].permalink;
                window.localStorage.setItem('wrikeCardCreator', this.currentCreator);

                if (this.hasFile) {
                    const attachmentForm = new FormData();
                    attachmentForm.append('file', this.attachment);

                    this.wrike.addTaskAttachment(taskCreated[0].id, attachmentForm).finally(() => {
                        this.buttonState = 'idle';
                    });
                }
            }).finally(() => {
                if (!this.hasFile) {
                    this.buttonState = 'idle';
                }
            });
        },

        onValidate() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    if (this.errors.any()) {
                        return;
                    }

                    const company_name = this.shared.session.company.name;
                    const fullname = `${this.shared.session.companyUser.firstname} ${this.shared.session.companyUser.lastname}`;
                    const url = document.location.href;

                    let sentrySentence = 'Aucun';
                    if (this.sentryLink) {
                        sentrySentence = `<a href="${this.sentryLink}">Go to sentry</a><br>`;
                    }

                    const description = `<strong>[Environnement]</strong><br>
                        Env: ${this.$env.get('APP_ENV')}<br>
                        Url: <a href="${url}">${url}</a><br>
                        Redirection: <a href="${this.autoconnect}">${this.autoconnect}</a><br><br>

                        ${this.description}<br><br>

                        <strong>[Informations]</strong><br>
                        Company: [${this.shared.session.company.id}] ${company_name}<br>
                        User: [${this.connectedUserId}]  ${fullname}<br>
                        Role : ${get(this.shared, 'session.companyUser.role', 'Aucun')}<br>
                        Last sentry error: ${sentrySentence}<br>
                        Useragent: ${window.navigator.userAgent}`;

                    const folderId = this.currentFolders[0].id;
                    const parents = [...this.currentFolders.slice(1).map((folder) => folder.id), this.currentClientFolder];

                    const task = {
                        title: this.taskTitle,
                        description,
                        responsibles: this.getResponsibleIds,
                        customFields: [{
                            id: 'IEADI6F4JUACRFVQ',
                            value: this.currentCreator,
                        }],
                        parents,
                    };

                    task.customStatus = this.defaultStatus;

                    this.createTask(task, folderId);
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/var";

.status-wrapper {
    gap: 2em;

    .flex-1 {
        flex:1
    }
}
</style>
