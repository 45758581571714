import Vue from 'vue';

export default {

    ROUTES: {
        ADMIN: 'admin',
        ENROLLEE: 'enrollee',
    },

    get(route, id) {
        return Vue.prototype.$http.get(`document-editor/${route}/${id}`);
    },

    addFileTranslation(route, id, body) {
        return Vue.prototype.$http.put(`document-editor/${route}/${id}/add-translated-file`, {
            company_file_id: body.company_file_id,
            lang: body.lang,
        });
    },

    create(route, body) {
        return Vue.prototype.$http.post(`document-editor/${route}`, {
            company_file_id: body.company_file_id,
            lang: body.lang,
        });
    },

    update(route, id, body) {
        return Vue.prototype.$http.put(`document-editor/${route}/${id}`, body);
    },

    loadAvailableVariables() {
        return Vue.prototype.$http.get('document-editor/admin/variables');
    },

    loadAvailableTypes() {
        return Vue.prototype.$http.get('document-editor/admin/types');
    },

};
