<template>
    <div>
        <TranslatedForm
            ref="form"
            class="mb-5"
            v-slot="{editingLang}"
        >
            <HtFormInput
                id="name"
                name="name"
                v-model="section.translations.name[editingLang]"
                v-validate.disable="'required|max:255'"
                :label="translate('Name')"
                :data-vv-as="translate('name')"
                :show-optional="false"
                @input="onChange"
            />

            <HtFormEditor
                ref="editor"
                id="content"
                class="mb-2"
                v-model="section.translations.content[editingLang]"
                :label="translate('Content')"
                name="content"
                cypress="content"
                :custom-style-enabled="section.parameters.active_custom_style"
                :custom-style="section.parameters.active_custom_style ? section.parameters.custom_style : ''"
                @on-update-custom-style="onCustomStyleUpdated"
                @input="onChange"
            />
        </TranslatedForm>

        <HtCollapse blank>
            <HtCollapseItem :show-right-label="false" :default-collapse="false">
                <t class="font-weight-bold">Advanced Parameters</t>

                <template #content>
                    <div class="advanced-parameter">
                        <HtFormSwitch
                            id="active_custom_style"
                            v-model="section.parameters.active_custom_style"
                            name="active_custom_style"
                            :show-optional="false"
                            :label="translate('Use custom styles (instead of default platform styles)')"
                            cypress="active_custom_style"
                            class="mb-2"
                            @input="onChange(); reloadEditor();"
                        />

                        <HtFormSwitch
                            id="is_full_page"
                            v-model="section.parameters.is_full_page"
                            name="is_full_page"
                            :show-optional="false"
                            :label="translate('Full page borders')"
                            cypress="is_full_page"
                            class="mb-2"
                            @input="onChange();"
                        />

                        <HtFormSwitch
                            id="should_show_title"
                            v-model="section.parameters.should_show_title"
                            name="should_show_title"
                            :show-optional="false"
                            :label="translate('Show the section title')"
                            cypress="should_show_title"
                            class="mb-2"
                            @input="onChange();"
                        />
                    </div>
                </template>
            </HtCollapseItem>
        </HtCollapse>
    </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import TranslatedForm from '@/components/form/TranslatedForm.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtFormEditor from '@/components/globals/HtFormEditor.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtCollapse from '@/components/globals/HtCollapse.vue';
import HtCollapseItem from '@/components/globals/HtCollapseItem.vue';
import HtFormTextarea from '@/components/globals/HtFormTextarea.vue';
import ModalMixin from '@/components/globals/modals/modalMixin';

export default {
    name: 'SectionFormContent',

    components: {
        HtFormTextarea,
        HtCollapseItem,
        HtCollapse,
        HtFormSwitch,
        TranslatedForm,
        HtFormInput,
        HtFormEditor,
    },

    mixins: [
        ModalMixin,
    ],

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            section: cloneDeep(this.item),
        };
    },

    methods: {
        onChange() {
            this.$emit('update:item', this.section);
        },

        reloadEditor() {
            this.$refs.editor.reload();
        },

        onCustomStyleUpdated(value) {
            this.section.parameters.custom_style = value;
            this.onChange();
            this.reloadEditor();
        },
    },

    watch: {
        item: {
            deep: true,
            handler() {
                const { active_custom_style: itemCustomStyleEnabled, custom_style: itemCustomStyle } = this.item.parameters;
                const { active_custom_style: sectionCustomStyleEnabled, custom_style: sectionCustomStyle } = this.section.parameters;

                if (itemCustomStyleEnabled !== sectionCustomStyleEnabled || itemCustomStyle !== sectionCustomStyle) {
                    this.reloadEditor();
                }

                this.section = cloneDeep(this.item);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.advanced-parameter {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}
</style>
