<template>
    <PageStepperLayout
        :page-subtitles="pageSubtitles"
    >
        <EnterprisePageForm
            :id="Number($route.params.id) || null"
            @on-close-stepper="$router.go(-1)"
        />
    </PageStepperLayout>
</template>

<script>
import PageStepperLayout from '@/layouts/PageStepperLayout.vue';
import EnterprisePageForm from '@/components/settings/EnterprisePage/EnterprisePageForm.vue';

export default {
    name: 'SettingsEnterprisePageForm',

    components: {
        EnterprisePageForm,
        PageStepperLayout,
    },

    data() {
        return {
            pageSubtitles: [
                this.translate('Settings'),
                this.translate('Enterprise Page'),
            ],
        };
    },
};
</script>
