import Vue from 'vue';
import api from '@/store/api';

/**
 * Represents a contract.
 *
 * @typedef {Object} Contract
 * @property {string} name - The name of the contract.
 * @property {boolean} has_end_date - Indicates if the contract has an end date or not.
 * @property {boolean} has_probation - Indicates if the contract is on probation or not.
 * @property {Object[]} custom_fields - An array of custom field objects associated with the contract.
 */

/**
 * @typedef {{data: Contract}} ContractResourceResponse
 * @typedef {{data: Contract[]}} ContractCollectionResponse
 */
export default {
    /**
     * @param {any} data
     * @returns {Promise<ContractCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.get(api.endpoints.CONFIGURATION_CONTRACTS, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<ContractResourceResponse>}
     */
    get(id, config = {}) {
        return Vue.prototype.$http.get(`${api.endpoints.CONFIGURATION_CONTRACTS}/${id}`, config);
    },

    /**
     * @param {any} data
     * @returns {Promise<ContractResourceResponse>}
     */
    create(data = {}) {
        return Vue.prototype.$http.post(api.endpoints.CONFIGURATION_CONTRACTS, data);
    },

    /**
     * @param {number|string} id
     * @param {any} data
     * @returns {Promise<ContractResourceResponse>}
     */
    update(id, data = {}) {
        return Vue.prototype.$http.put(`${api.endpoints.CONFIGURATION_CONTRACTS}/${id}`, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<{}>}
     */
    delete(id, config = {}) {
        return Vue.prototype.$http.delete(`${api.endpoints.CONFIGURATION_CONTRACTS}/${id}`, config);
    },
};
