function getEntityValue(userEntity) {
    const mapping = {
        company_department: 'department',
        company_office: 'office',
        company_contract: 'contract',
        company_job_position: 'job_position',
    };

    const entityKey = mapping[userEntity.entity.slug] ?? 'custom';

    return userEntity.value[entityKey]?.name;
}

export default {
    mounted() {
        /** @type {{company: Company, companyUser: CompanyUser}} session */
        const { session } = this.shared;
        const { company, companyUser: user } = session;

        if (this.$env.isProd() && user.logins_count >= 5) {
            const entities = {};
            user.entities.models.each((userEntity) => {
                entities[userEntity.entity.slug] = getEntityValue(userEntity);
            });

            const traits = {
                name: `${user.firstname} - ${user.lastname}`,
                company: company.name,
                company_id: company.id,
                email: user.email,
                roles: user.company_role_user.models
                    .map((roleUser) => roleUser.role.name)
                    .join(' ; '),
                language: user.company_language.key,
                programs: user.programs.models
                    .map((userProgram) => userProgram.program.name_translated)
                    .join(' ; '),
                ...entities,
            };

            window.satismeter({
                writeKey: 'aaILVuHcJ6khErSc',
                userId: user.id,
                traits,
            });
        }
    },
};
