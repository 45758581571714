<template>
    <div>
        <template v-if="isCustom">
            <div
                class="videoplayer-container"
                :style="styleVideoPlayerContainer"
            >
                <div
                    class="icon-container"
                    :style="styleVideoPlayerContainer"
                    @click="play"
                >
                    <template v-if="!isVideoPlaying">
                        <FontAwesomeLayers class="layer-play">
                            <FontAwesomeIcon
                                size="4x"
                                icon="circle"
                                class="icon-circle"
                                transform="left-5"
                            />
                            <FontAwesomeIcon
                                size="4x"
                                icon="play"
                                transform="left-4 shrink-12"
                                class="icon-play"
                            />
                        </FontAwesomeLayers>
                    </template>
                </div>
                <video
                    ref="videoPlayer"
                    :style="styleVideo"
                    @click.stop
                    @ended="end"
                >
                    <source :src="url">
                </video>
            </div>
        </template>
        <template v-else>
            <video
                v-if="url"
                controls
                controlsList="nodownload"
                width="100%"
                @click.stop
            >
                <source :src="url">
            </video>
        </template>
    </div>
</template>
<script>
export default {
    name: 'VideoPlayer',
    props: {
        // type: { type: String, default: 'file'}, // ['file', 'opentok']
        hasControls: { type: Boolean, default: true },
        isCustom: { type: Boolean, default: true },
        url: { type: String, default: null },
        scale: { type: Number, default: 2 },
        rotateY: { type: Number, default: 0 },
        width: { type: Number, default: 400 },
        height: { type: Number, default: 470 },
    },

    shared: {
        videoPlayer: {
            current: {
                type: Object,
                default: null,
            },
        },
    },

    data() {
        return {
            styleVideo: {
                transform: `rotateY(${this.rotateY}deg) scale(${this.scale})`,
            },
            styleVideoPlayerContainer: {
                width: `${this.width}px`,
                height: `${this.height}px`,
            },
            isLoading: false,
            isVideoPlaying: false,
        };
    },

    methods: {
        play() {
            if (this.isVideoPlaying === false) {
                if (this.shared.videoPlayer.current != null) {
                    this.shared.videoPlayer.current.pause();
                }
                this.$refs.videoPlayer.play();
                this.isVideoPlaying = true;
                this.shared.videoPlayer.current = this;
            } else {
                this.$refs.videoPlayer.pause();
                this.isVideoPlaying = false;
                this.shared.videoPlayer.current = null;
            }
        },

        pause() {
            this.$refs.videoPlayer.pause();
            this.isVideoPlaying = false;
            this.shared.videoPlayer.current = null;
        },

        end() {
            this.isVideoPlaying = false;
            this.shared.videoPlayer.current = null;
        },
    },
};
</script>
<style lang="scss" src="./VideoPlayer.scss" scoped></style>
