<template>
    <login />
</template>

<script>

import Login from '@/pages/Login.vue';

export default {
    name: 'Forbidden',
    components: {
        login: Login,
    },
};

</script>
