<template>
    <div>
        <HtCard class="p-0">
            <template #title>
                <div
                    v-if="selectedQuestions.length === 0"
                    class="w-100 d-flex"
                >
                    <HtButton
                        type="secondary"
                        style="white-space: nowrap;font-size: 1.5rem; font-family: 'lato', sans-serif"
                        class="mr-5 font-weight-bold"
                        @click.native="exportData"
                    >
                        <i class="fas fa-file-upload mr-2" />&nbsp;<t>Export</t>
                    </HtButton>

                    <div class="d-flex align-items-center mr-5 align-items-stretch">
                        <UploadBar
                            ref="uploadBar"
                            v-model="file"
                            style="font-size: 1.5rem;!important;white-space: nowrap;"
                            :media="['csv']"
                            empty-label="Browse"
                            @file-deleted="serverErrors = null"
                            @on-edit="serverErrors = null"
                        />
                        <HtButton
                            v-if="file"
                            type="primary"
                            style="white-space: nowrap;border-top-left-radius: 0;border-bottom-left-radius: 0;margin-left:-4px;"
                            @click.native="importData"
                        >
                            &nbsp;
                            <span>
                                <t>Import</t>
                            </span>
                        </HtButton>
                    </div>
                </div>

                <div v-if="selectedQuestions.length > 0">
                    <div class="update-state-form">
                        <label
                            for="state"
                            class="mr-3 align-self-center"
                        ><t>Update state to </t></label>
                        <HtFormSelect
                            :id="'state'"
                            v-model="updateStateValue"
                            class="mb-0 update-state-select"
                            :name="'state'"
                            :is-single="true"
                            :placeholder="translate('State')"
                            :options="statesOptions"
                            :show-optional="false"
                            :allow-empty="true"
                        />
                        <HtButton
                            :disabled="isLoading || isUpdating"
                            type="primary"
                            class="left-stick-btn"
                            @click.native="updateStates()"
                        >
                            <t>Apply</t>
                        </HtButton>
                    </div>
                </div>

                <div
                    v-for="(error) in serverErrors"
                    class="font-weight-bold branding-color"
                    style="font-size: 1.4rem;white-space: nowrap"
                >
                    {{ error }}&nbsp;
                </div>

                <HtCardTitleBar
                    title=""
                    :show-button="true"
                    :button-text="translate('Add')"
                    @onAdd="openKnowledgeForm"
                    @search="setSearchValue"
                >
                    <template #card-title-bar-right>
                        <HtFormSelect
                            :id="'state'"
                            v-model="querySearch.state"
                            class="mb-0 update-state-select"
                            :name="'state'"
                            :is-single="true"
                            :placeholder="translate('State')"
                            :options="statesOptions"
                            :allow-empty="true"
                        />
                    </template>
                </HtCardTitleBar>
            </template>

            <div
                class="d-flex align-items-center justify-content-end py-4"
            >
                <HtButton
                    class="mt-3"
                    type="tertiary"
                    :disabled="isLoading || isUpdating"
                    @click.native="refreshList()"
                >
                    <i class="fas fa-sync mr-3" />
                </HtButton>
            </div>

            <IconLoading v-if="isLoading === true" />
            <HtTable
                v-else
                :items="formattedQuestions"
                :columns="questionColumns"
                :empty-message="'No data found'"
                :external-search="search"
                :loading="isLoading"
                :has-multiple-selection="true"
                @onCellClicked="onRowClicked"
                @onRowSelected="onRowSelected"
            >
                <template #cell(trash)="{ item }">
                    <div
                        data-cy="delete-question-value"
                        @click.stop="onDestroyConfirm(item)"
                    >
                        <FontAwesomeIcon :icon="['far', 'trash-alt']" />
                    </div>
                </template>
            </HtTable>
        </HtCard>

        <EditKnowledgeFormModal
            ref="modal"
            @on-close="onClose"
        />

        <modalable
            ref="modalableConfirmDeletion"
            class="modal modalable-4 program"
        >
            <p v-if="currentQuestion">
                <t>
                    Do you really want to permanently delete this content?
                </t>
            </p>

            <div class="row-line middle">
                <Button
                    class="black"
                    cypress="cancel-delete"
                    @click="$refs.modalableConfirmDeletion.close()"
                >
                    <t>Cancel</t>
                </Button>
                <Button
                    class="red"
                    :state="deleteButtonState"
                    cypress="confirm-delete"
                    @click="onDestroy()"
                >
                    <t>Delete</t>
                </Button>
            </div>
        </modalable>
    </div>
</template>
<script>
import HtCard from '@/components/globals/HtCard.vue';
import HtTable from '@/components/globals/HtTable.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import HtButton from '@/components/globals/HtButton.vue';
import EditKnowledgeFormModal from '@/components/pages/settings/ai/EditKnowledgeFormModal.vue';
import Modalable from '@/components/globals/Modalable.vue';
import UploadBar from '@/components/globals/UploadBar/UploadBar.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';

export default {
    components: {
        HtFormSelect,
        IconLoading,
        UploadBar,
        Modalable,
        EditKnowledgeFormModal,
        HtButton,
        HtCardTitleBar,
        HtTable,
        HtCard,
    },
    data() {
        return {
            file: null,
            fileName: '',
            search: null,
            questions: [],
            updateStateValue: null,
            isUpdating: false,
            currentQuestion: null,
            isDeleting: false,
            isLoading: false,
            serverErrors: [],
            querySearch: {
                state: null,
            },
            statesOptions: [
                { id: 'in_review', name: this.translate('In Review') },
                { id: 'published', name: this.translate('Published') },
            ],
            selectedQuestions: [],
            questionColumns: [
                {
                    key: 'question_text',
                    label: this.translate('Question'),
                    search: true,
                    class: 'w-auto',
                },
                {
                    key: 'question_answers',
                    label: this.translate('Answer'),
                    search: true,
                    class: 'w-auto',
                },
                {
                    key: 'question_state',
                    label: this.translate('State'),
                    search: true,
                    class: 'w-10',
                },
                {
                    label: '',
                    key: 'trash',
                    sortable: false,
                    class: 'text-right',
                },
            ],
        };
    },
    computed: {
        deleteButtonState() {
            return this.isDeleting ? 'loading' : 'idle';
        },
        formattedQuestions() {
            const questions = this.questions.map((question) => ({
                question_id: question.id,
                question_state: this.translate(question.state),
                question_text: question.text,
                question_answers: question.answers.map((answer) => answer.text).join(' - '),
            }));

            if (this.querySearch.state && this.querySearch.state.trim() !== '') {
                return questions.filter((question) => question.question_state === this.querySearch.state);
            }

            return questions;
        },
    },
    mounted() {
        this.refreshList();
    },
    methods: {
        onDestroyConfirm(currentQuestion) {
            this.currentQuestion = currentQuestion;

            this.$refs.modalableConfirmDeletion.open();
        },
        onDestroy() {
            if (!this.isDeleting) {
                this.isLoading = true;
                this.isDeleting = true;

                this.$http.delete(`company/knowledge/${this.currentQuestion.question_id}`).then(() => {
                    this.$Notifier('App').showInfo(this.translate('The selected content has been deleted with success!'));

                    this.refreshList();

                    this.$refs.modalableConfirmDeletion.close();
                }).catch(({ response }) => {
                    if (response?.status !== 409) {
                        this.$Notifier('App').showError(this.translate('An error occurred'));
                    }
                }).finally(() => {
                    this.isDeleting = false;
                    this.isLoading = false;
                });
            }
        },
        refreshList() {
            this.serverErrors = [];
            this.isLoading = true;
            this.file = null;
            this.$refs.uploadBar?.resetProgression();

            this.$http.get('company/knowledge').then((response) => {
                this.questions = response.data.data;
            }).catch(({ response }) => {
                this.isLoading = false;
                if (response?.status !== 409) alert('An error occurred.');
            }).finally(() => {
                this.isLoading = false;
            });
        },
        updateStates() {
            if (this.selectedQuestions.length === 0) {
                alert(this.translate('Please select at least one question'));

                return;
            }

            if (!this.updateStateValue) {
                alert(this.translate('Please select a state'));

                return;
            }

            this.isUpdating = true;

            this.$http.put('company/knowledge', {
                question_ids: this.selectedQuestions.map((question) => question.question_id),
                state: this.updateStateValue,
            }).then(() => {
                this.$Notifier('App').showInfo(this.translate('Updated with success!'));

                this.selectedQuestions = [];

                this.refreshList();
            }).catch((error) => {
                const { response } = error;

                if (!response) {
                    console.error(error);

                    return;
                }

                if (response.status === 422) {
                    this.serverErrors = response.data.errors;
                }

                if (response.status !== 409) {
                    this.$Notifier('App').showError(this.translate('An error occurred'));
                }
            }).finally(() => {
                this.isUpdating = false;
            });
        },
        onClose() {
            this.refreshList();
        },
        onRowClicked(row) {
            this.$refs.modal.open(row.item.question_id);
        },
        onRowSelected(data) {
            this.selectedQuestions = data.selectedRows.map((row) => row.item);
        },
        setSearchValue(val) {
            this.search = val;
        },
        async openKnowledgeForm() {
            await this.$refs.modal.open();
        },
        onFileChange(event) {
            const { files } = event.target;

            if (files.length > 0) {
                this.file = files[0];
                this.fileName = files[0].name;
            }
        },
        exportData() {
            this.$http.post('company/knowledge/export').then((response) => {
                const base64Content = response.data.file_content;
                const blob = new Blob([Uint8Array.from(atob(base64Content), (c) => c.charCodeAt(0))], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');

                a.href = url;
                a.download = 'export.csv';
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);

                this.$Notifier('App').showInfo(this.translate('Exported with success!'));
            }).catch(({ response }) => {
                if (response?.status !== 409) {
                    this.$Notifier('App').showError(this.translate('An error occurred'));
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        importData() {
            this.serverErrors = [];

            this.$http.post('company/knowledge/import', this.file).then((response) => {
                this.file = null;
                this.fileName = '';

                if (response?.status >= 300) {
                    this.$Notifier('App').showError(this.translate('An error occurred'));

                    return;
                }

                this.$Notifier('App').showInfo(this.translate('Imported with success!'));

                this.refreshList();
            }).catch(({ response }) => {
                if (response?.status === 422) {
                    this.serverErrors = response.data.errors;
                }

                if (response?.status !== 409) {
                    this.$Notifier('App').showError(this.translate('An error occurred'));
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.update-state-form {
    display: grid;
    grid-template-columns: 1fr 2fr 0.5fr;
    font-size: 1.5rem;

    label {
        white-space: nowrap;
    }

    .update-state-select {
        min-width: 150px;
    }
}

.error-message {
    font-size: 1.4rem;
    white-space: nowrap
}

.left-stick-btn {
    white-space: nowrap;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left:-4px;
    z-index: 10;
}
</style>
