import Model from '@tony.caron/node-model-proxy/Model';

import CompanySurveyQuestion from './CompanySurveyQuestion';
import CompanySurveyQuestionAnswer from './CompanySurveyQuestionAnswer';
import CompanyUserSurvey from './CompanyUserSurvey';
import CompanyUserSurveyQuestionAnswerCollection from './CompanyUserSurveyQuestionAnswerCollection';
import CompanyUser from './CompanyUser';
import CompanyFile from './CompanyFile';

export default class CompanyUserSurveyQuestionAnswer extends Model {
    modelConfig() {
        return {
            collection: CompanyUserSurveyQuestionAnswerCollection,
        };
    }

    modelRelations() {
        return {
            companySurveyQuestion: () => this.belongsTo(CompanySurveyQuestion, 'company_survey_question_id', 'id'),
            companySurveyQuestionAnswer: () => this.belongsTo(CompanySurveyQuestionAnswer, 'company_survey_question_answer_id', 'id'),
            companyUserParticipant: () => this.belongsTo(CompanyUser, 'company_user_participant_id', 'id'),
            companyUserSurvey: () => this.belongsTo(CompanyUserSurvey, 'company_user_survey_id', 'id'),
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
        };
    }
}
