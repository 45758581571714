<template>
    <UploadBar
        :value="requirement.company_file"
        :media="requirement.media"
        :show-media-legend="true"
        :max-size="requirement.file_max_size"
        :show-file-size-legend="requirement.file_max_size !== null"
        :is-requirement="true"
        cypress="requirement-company-file"
        @onRemove="onRemove"
    />
</template>

<script>
import UploadBar from '@/components/globals/UploadBar/UploadBar.vue';

export default {
    name: 'FileIsAlreadySetMode',
    components: { UploadBar },

    props: {
        requirement: {
            type: Object,
            required: true,
        },
    },

    methods: {
        /**
         * @returns {void}
         */
        onRemove() {
            this.requirement.company_file = null;
            this.$emit('on-remove');
        },
    },
};
</script>
