import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUserProgramTask from './CompanyUserProgramTask';

export default class CompanyUserRecurrence extends Model {
    modelRelations() {
        return {
            userProgramTasks: () => this.hasMany(CompanyUserProgramTask, 'company_user_recurrence_id', 'id'),
        };
    }
}
