<template>
    <div>
        <div class="user-survey-result-item-content-end">
            <div class="user-survey-result-item-content-right-text-right">
                {{ getUserAnswerText }}
            </div>
        </div>
    </div>
</template>
<script>
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import CompanyUserSurveyQuestionAnswerCollection from '@/models/CompanyUserSurveyQuestionAnswerCollection';

export default {
    name: 'ResultYesNo',
    props: {
        surveyQuestion: {
            type: CompanySurveyQuestion,
            required: true,
        },
        userSurveyQuestionAnswer: {
            type: CompanyUserSurveyQuestionAnswerCollection,
            required: true,
        },
    },

    shared: {
        session: {
        },
    },

    computed: {
        getUserAnswerText() {
            if (this.userSurveyQuestionAnswer.models[0].company_survey_question_answer.locales_by_key[this.shared.session.companyUser.company_language.key].text !== null) {
                return this.userSurveyQuestionAnswer.models[0].company_survey_question_answer.locales_by_key[this.shared.session.companyUser.company_language.key].text;
            }
            return this.userSurveyQuestionAnswer.models[0].company_survey_question_answer.locales_by_key[this.shared.session.company.company_language.key].text;
        },
    },
};
</script>
