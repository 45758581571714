<template>
    <form class="form">
        <input
            ref="imageInput"
            type="file"
            accept="image/*"
            @change="onChange"
        >
    </form>
</template>

<script>
export default {
    name: 'UploadImage',
    methods: {
        openFileSelector() {
            this.$refs.imageInput.click();
        },
        onChange(e) {
            const image = e.target.files[0];

            const url = URL.createObjectURL(image);

            const formData = new FormData();

            formData.append('file', image);

            this.API.post('company_file', formData, {
                200: (data) => {
                    this.$emit('onUploadEnd', data, url);
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.form {
    display: none;
}
</style>
