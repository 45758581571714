import Vue from 'vue';

/**
 * @typedef {Object} ExportedUser
 * @property {ExportedUserData[]} data
 * @property {Object<int, string[]>} validation_errors
 * @property {String|null} exception_message
 */

/**
 * @typedef {Object} ExportedUserData
 * @property {Number} id
 * @property {String} lastname
 * @property {String} firstname
 * @property {String} email
 * @property {String} site
 * @property {String} job_position
 * @property {String} roles role aliases separed by | (example: "role alias 1|role alias 2")
 * @property {String|null} code
 */

/**
 * @typedef {{data: {data: ExportedUser[]}}} ExportedUserResponse
 */

/**
 * Import users data into UI only (at this moment, it doesn't import data into database)
 *
 * @param {Number} csvFileId
 * @returns {Promise<ExportedUserResponse>}
 */
export function preImportFromCsvFile(csvFileId) {
    return Vue.prototype.$http.post(`users/pre-import/${csvFileId}`);
}

/**
 * Import users data into UI only (at this moment, it doesn't import data into database)
 *
 * @param {any} users
 * @returns {Promise<ExportedUserResponse>}
 */
export function preImportFromData(users) {
    return Vue.prototype.$http.post('users/pre-import', users);
}

/**
 * Import users data into UI only (at this moment, it doesn't import data into database)
 *
 * @returns {Promise<ExportedUserResponse>}
 */
export function addDummyUser(prependedUsers) {
    return Vue.prototype.$http.post('users/add-dummy-row', prependedUsers);
}

/**
 * Import users data into database if all data are good
 *
 * @param {any} usersToImport
 * @returns {Promise<ExportedUserResponse>}
 */
export function importData(usersToImport) {
    return Vue.prototype.$http.post('users/import', usersToImport);
}
