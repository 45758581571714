const SET_PROGRAMS_FILTER = 'set_programs_filter';
const SET_ENTITIES_FILTER = 'set_entities_filter';
const SET_ROLES_FILTER = 'set_roles_filter';
const SET_LIBRARY_MODE_FILTER = 'set_library_mode_filter';
const SET_LOADING = 'set_loading';
const CLEAR_ALL_FILTERS = 'clear_all_filters';

export const LIBRARY_ALL_RESOURCES = 0;
export const LIBRARY_ONLY_MY_RESOURCES = 1;
export const LIBRARY_ONLY_PUBLIC_RESOURCES = 2;

export const LIBRARY_DEFAULT = LIBRARY_ALL_RESOURCES;

export default {
    namespaced: true,

    state() {
        return {
            isLoading: false,
            filters: {
                programs: [],
                entities: [],
                roles: [],
                library_mode: LIBRARY_DEFAULT,
            },
        };
    },

    getters: {
        getFilters(state) {
            return state.filters;
        },

    },

    actions: {
        setProgramsFilter({ commit }, ids) {
            commit(SET_PROGRAMS_FILTER, ids);
        },
        setEntitiesFilter({ commit, state }, { company_entity_id, values }) {
            const entities = state.filters.entities.filter((e) => e.company_entity_id !== company_entity_id);
            commit(SET_ENTITIES_FILTER, [...entities, ...values]);
        },
        setRolesFilter({ commit }, ids) {
            commit(SET_ROLES_FILTER, ids);
        },
        setLibraryModeFilter({ commit }, libraryMode) {
            commit(SET_LIBRARY_MODE_FILTER, libraryMode);
        },
        setLoading({ commit }, loading) {
            commit(SET_LOADING, loading);
        },
        clearAllFilters({ commit }) {
            commit(CLEAR_ALL_FILTERS);
        },
    },

    mutations: {
        [SET_PROGRAMS_FILTER](state, ids) {
            state.filters.programs = ids;
        },
        [SET_ENTITIES_FILTER](state, entities) {
            state.filters.entities = entities;
        },
        [SET_ROLES_FILTER](state, ids) {
            state.filters.roles = ids;
        },
        [SET_LIBRARY_MODE_FILTER](state, libraryMode) {
            state.filters.library_mode = libraryMode;
        },
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [CLEAR_ALL_FILTERS](state) {
            state.filters.programs = [];
            state.filters.entities = [];
            state.filters.roles = [];
            state.filters.library_mode = LIBRARY_DEFAULT;
        },
    },
};
