import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyProgramResourceFilter from './CompanyProgramResourceFilter';

export default class CompanyProgramResourceFilterCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyProgramResourceFilter,
        };
    }
}
