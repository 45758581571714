<template>
    <HtActionMenu
        :actions="actions"
        @action-clicked="$emit('action-clicked', $event)"
    >
        <HtButton type="secondary" size="small">
            <FontAwesomeIcon :icon="['far', 'ellipsis-h']" />
        </HtButton>
    </HtActionMenu>
</template>

<script>
import HtActionMenu from '@/components/globals/HtActionMenu.vue';
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'TableCellQuickActions',

    components: {
        HtActionMenu,
        HtButton,
    },

    props: {
        actions: {
            type: Array,
            required: true,
            validator(actions) {
                return actions.every(
                    (action) => action.label !== undefined && action.id !== undefined,
                );
            },
        },
    },
};
</script>
