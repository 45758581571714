import Model from '@tony.caron/node-model-proxy/Model';
import CompanyProgramLocaleCollection from './CompanyProgramLocaleCollection';

export default class CompanyProgramLocale extends Model {
    modelConfig() {
        return {
            collection: CompanyProgramLocaleCollection,
        };
    }
}
