<template>
    <ResourceListing
        ref="listing"
        api-name="surveys"
        :title="translate('Surveys')"
        :columns="columns"
        :formatter="formatter"
        permission="ModelCompanySurvey"
        empty-message="There are no surveys to display yet..."
        has-quick-actions
        :is-modalable="false"
        @on-create="openForm"
        @on-row-clicked="onRowClicked"
        @on-action-clicked="onActionClicked"
    >
        <template #cell(number_of_responses)="scope">
            <span v-if="scope.item.number_of_target_users > 0">
                {{ scope.item.number_of_responses }} / {{ scope.item.number_of_target_users }}
            </span>
            <span v-if="scope.item.number_of_target_users === 0 || !scope.item.number_of_target_users">
                -
            </span>
        </template>
    </ResourceListing>
</template>

<script>
import api from '@/store/api';
import ResourceListing from '@/components/resources/generic/ResourceListing.vue';

export default {
    permissions: [
        'ModelCompanySurvey',
    ],

    components: {
        ResourceListing,
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('Status'),
                    key: 'status',
                    sortable: true,
                    search: true,
                },
                {
                    label: this.translate('Answers'),
                    key: 'number_of_responses',
                    sortable: false,
                    search: false,
                },
            ],
        };
    },

    methods: {
        fetchData() {
            this.$refs.listing.fetchData();
        },

        formatter(items) {
            const colors = {
                draft: 'orange',
                scheduled: 'purple',
                in_progress: 'green',
                completed: 'grey',
            };

            const statuses = {
                draft: this.translate('Draft'),
                scheduled: this.translate('Scheduled'),
                in_progress: this.translate('In progress'),
                completed: this.translate('Closed'),
            };

            return items.map((items) => {
                const quickActions = [];
                if (this.$canCreate('ModelCompanySurvey', { id: -1 })) {
                    quickActions.push({ id: 'replicate', label: 'Replicate', icon: ['fal', 'copy'] });
                }

                return {
                    ...items,
                    status_color: colors[items.status],
                    status_label: statuses[items.status],
                    quick_actions: quickActions,
                };
            });
        },

        replicateSurvey(survey) {
            api.configuration.surveys.replicate(survey.id)
                .then(() => {
                    this.$Notifier('App').showInfo(this.translate('The resource << {resource_name} >> is replicated', {
                        resource_name: survey.resource.name,
                    }));
                    this.fetchData();
                })
                .catch(() => {
                    this.$Notifier('App').showError(this.translate('The resource << {resource_name} >> is not replicated!', {
                        resource_name: survey.resource.name,
                    }));
                });
        },

        onActionClicked({ item, actionId: action }) {
            switch (action) {
            case 'replicate':
                this.replicateSurvey(item);
                break;
            }
        },

        openForm() {
            return this.$router.push({
                name: 'ResourcesSurveysForm',
            });
        },

        onRowClicked(row) {
            return this.$router.push({
                name: 'ResourcesSurveysForm',
                params: {
                    id: row.item.id,
                },
            });
        },
    },
};
</script>
