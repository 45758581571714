import Vue from 'vue';

export default {
    all(config = {}) {
        return Vue.prototype.$http.get('company/settings/enterprise-pages', config);
    },

    findById(id, data = {}) {
        return Vue.prototype.$http.get(`company/settings/enterprise-pages/${id}`, data);
    },

    store(data = {}) {
        return Vue.prototype.$http.post('company/settings/enterprise-pages', data);
    },

    update(id, data = {}) {
        return Vue.prototype.$http.post(`company/settings/enterprise-pages/${id}`, data);
    },

    destroy(id, config = {}) {
        return Vue.prototype.$http.delete(`company/settings/enterprise-pages/${id}`, config);
    },

    saveOrders(data = {}) {
        return Vue.prototype.$http.post('company/settings/enterprise-pages/save-orders', data);
    },

    toggleFeature() {
        return Vue.prototype.$http.post('company/settings/enterprise-pages/toggle-feature');
    },
};
