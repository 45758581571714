<template>
    <div :class="{'new-style': useNewStyle}">
        <table :class="{'new-style': useNewStyle}">
            <thead v-if="columns">
                <tr>
                    <th
                        v-for="column in formattedColumns"
                        :key="column.key"
                        :style="thStyle(column)"
                        :class="thClass(column)"
                        @click="sortColumn(column)"
                    >
                        <slot
                            :name="headerScope(column.key)"
                            :value="column"
                        >
                            <div class="thead-container">
                                <FontAwesomeIcon
                                    v-if="column.iconClass"
                                    :icon="column.iconClass"
                                />
                                {{ column.label }}
                                <HTableSortButton
                                    v-if="column.sortable"
                                    :sorting="sortKey === column.key"
                                    :direction="sortDirection"
                                />
                            </div>
                        </slot>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(row, rowIndex) in formattedData"
                    :key="rowIndex"
                    :class="trClass(row)"
                    :style="trStyle(row)"
                    @click="onRowClick(row)"
                >
                    <td
                        v-for="(col) in formattedColumns"
                        :key="col.key"
                        :style="tdStyle(col, row)"
                        :class="tdClass(col, row)"
                        @click="onCellClick($event, row, col)"
                    >
                        <Skeleton
                            v-if="loading && !row[col.key]"
                            :width="'50%'"
                            :loading="loading && col.showSkeleton"
                        />
                        <slot
                            v-else
                            :name="cellScope(col.key)"
                            :value="row[col.key]"
                            :row="row"
                            :index="rowIndex"
                            :loading="loading"
                        >
                            <HTableString :string="row[col.key]" />
                        </slot>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import get from 'lodash.get';
import { Skeleton } from 'vue-loading-skeleton';
import HTableString from './TableCellString.vue';
import HTableSortButton from './TableSortButton.vue';

export default {
    components: {
        HTableString,
        HTableSortButton,
        Skeleton,
    },
    props: {
        useNewStyle: {
            type: Boolean,
            default: () => false,
        },
        columns: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        clickable: {
            type: Boolean,
            default: () => true,
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        loadingQuantity: {
            type: Number,
            default: 15,
        },
        initialSortingColumn: {
            type: String,
            required: false,
        },
        initialSortingDirection: {
            type: String,
            default: 'asc',
        },
    },
    data() {
        return {
            sortKey: null,
            sortDirection: 'asc',
        };
    },
    computed: {
        formattedColumns() {
            return this.columns.map((column) => {
                const options = {};

                options.key = get(column, 'key');
                options.label = get(column, 'label');
                options.iconClass = get(column, 'iconClass');
                options.tdClass = get(column, 'tdClass', []);
                options.thStyle = get(column, 'thStyle', {});
                options.tdStyle = get(column, 'tdStyle', {});
                options.sortable = get(column, 'sortable', false);
                options.showSkeleton = get(column, 'showSkeleton', true);
                options.hideOnTablet = get(column, 'hideOnTablet', false);
                options.hideOnMobile = get(column, 'hideOnMobile', false);

                return options;
            });
        },
        sortedData() {
            return this.data.sort((a, b) => {
                if (this.sortKey === null) return 0;
                const aValue = get(a, this.sortKey, '');
                const bValue = get(b, this.sortKey, '');
                const sorter = this.columns.find((col) => col.key == this.sortKey).sort || ({
                    number: (a, b) => (a - b),
                    string: (a, b) => (a.localeCompare(b)),
                    boolean: (a, b) => (a - b),
                })[typeof aValue];
                const reverse = (this.sortDirection === 'desc' ? -1 : 1);
                return reverse * sorter(aValue, bValue);
            });
        },
        formattedData() {
            if (this.loading) {
                return this.loadingQuantity;
            }
            return this.sortedData.map((data) => {
                data._rowVariant = get(data, '_rowVariant', null);
                data._cellVariants = get(data, '_cellVariants', {});
                data._trStyle = get(data, '_trStyle', {});
                return data;
            });
        },
    },
    created() {
        if (this.initialSortingColumn) {
            this.sortKey = this.initialSortingColumn;
            this.sortDirection = this.initialSortingDirection;
        }
    },
    methods: {
        cellScope(key) {
            return `cell(${key})`;
        },
        headerScope(key) {
            return `header(${key})`;
        },
        sortColumn(column) {
            if (!column.sortable) {
                return;
            }

            const columnKey = column.key;

            if (columnKey !== this.sortKey) {
                this.sortKey = columnKey;
                this.sortDirection = 'asc';
            } else {
                this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';
            }

            this.$emit('on-sort', this.sortKey, this.sortDirection);
        },
        sortTextDecoration(column) {
            if (column.key === this.sortKey) {
                return 'underline';
            }
            return 'none';
        },
        thClass(column) {
            const classes = [];
            if (column.sortable) {
                classes.push('sorting');
            }
            classes.push(...this.columnCellClass(column));
            return classes;
        },
        tdClass(column, row) {
            const classes = [];

            classes.push(...this.columnCellClass(column));

            column.tdClass.forEach((cssClass) => {
                if (typeof cssClass === 'string') {
                    classes.push(cssClass);
                    return;
                }
                if (typeof cssClass === 'function') {
                    const result = cssClass(row);
                    if (result) {
                        classes.push(result);
                    }
                }
            });

            if (row._cellVariants && row._cellVariants.hasOwnProperty(column.key) && row._cellVariants[column.key]) {
                classes.push(row._cellVariants[column.key]);
            }

            return classes;
        },
        trClass(row) {
            const classes = [];

            if (this.clickable) {
                classes.push('row-pointer');
            }

            if (row._rowVariant) {
                classes.push(row._rowVariant);
            }

            return classes;
        },
        tdStyle(column) {
            return column.tdStyle;
        },
        trStyle(row) {
            let style = {};
            if (row._trStyle) {
                if (typeof row._trStyle === 'function') {
                    const result = row._trStyle(row);
                    style = {
                        ...style,
                        ...result,
                    };
                } else {
                    style = {
                        ...style,
                        ...row._trStyle,
                    };
                }
            }
            return style;
        },
        columnCellClass(column) {
            const classes = [];
            if (column.hideOnTablet || column.hideOnMobile) {
                classes.push('d-none');
            }
            if (column.hideOnTablet) {
                classes.push('d-md-table-cell');
            } else if (column.hideOnMobile) {
                classes.push('d-sm-table-cell');
            }
            return classes;
        },
        onRowClick(row) {
            if (this.clickable) {
                this.$emit('on-row-click', row);
            }
        },
        onCellClick(event, row, col) {
            this.$emit('on-cell-click', event, row, col);
        },
        thStyle(column) {
            return {
                color: '#1B1B1C',
                'text-decoration': this.sortTextDecoration(column),
                ...column.thStyle,
            };
        },
    },
};

</script>

<style lang="scss" scoped>
@import '~@/styles/var';
table:not(.new-style) {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    & thead {
        & tr {
            font-size: 11px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
            letter-spacing: 0px;
            text-align: left;
            text-transform: uppercase;
        }
        & th {
            position: relative;
            padding: 0.8rem 0;
        }
    }
    & tbody {
        background-color: #FFFFFF;
        & tr:nth-child(even) {
            background-color: #FAFAFA;
        }
        & tr:hover {
            background-color: var(--branding-highlight) !important;
        }
        & td {
            display: table-cell;
            line-height: 2rem;
            padding-top: 1.8rem;
            padding-bottom: 1.8rem;
            font-size: 1.4rem;
            position: relative;
        }
        & tr:first-child td:first-child {
            border-top-left-radius: 5px;
        }

        & tr:first-child td:last-child {
            border-top-right-radius: 5px;
        }

        & tr:last-child td:first-child {
            border-bottom-left-radius: 5px;
        }

        & tr:last-child td:last-child {
            border-bottom-right-radius: 5px;
        }
    }
}

table.new-style {
    table-layout: fixed;
    position: relative;
    white-space: nowrap;
    border: solid $grey-ultra-light 1px;

    & thead {
        & tr {
            font-size: 1.2rem;
            font-style: bold;
            font-weight: 700;
            letter-spacing: 0px;
            text-align: left;
            background-color: $grey-lighter;

            & th {
                position: relative;
                padding: 1rem;
                background-color: $grey-lighter;
                height: 44px;

                &:not(:last-child) {
                    border-right: solid .25px $grey-ultra-light;
                }

                .thead-container {
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                }
            }
        }
    }
    & tbody {
        background-color: #FFFFFF;

        & tr {
            border-bottom: solid .25px $grey-ultra-light;

            &.branding-bg-highlight {
                & td {
                    background-color: var(--branding-highlight);
                }
            }
        }

        & tr:hover {
            background-color: var(--branding-highlight) !important;
        }
        & td {
            display: table-cell;
            padding-left: 1rem;
            padding-right: 1rem;
            font-size: 1.4rem;
            position: relative;
            background-color: #FFFFFF;
            height: 44px;

            &:not(:last-child) {
                border-right: solid .25px $grey-ultra-light;
            }
        }

        & tr:last-child td:first-child {
            border-bottom-left-radius: 5px;
        }

        & tr:last-child td:last-child {
            border-bottom-right-radius: 5px;
        }
    }
}
div.new-style {
    width: fit-content;
    overflow: auto;
    // provide space for tableActions overflow
    padding-bottom: 140px;
}
.sorting {
    cursor: pointer;
}
.row-pointer {
    cursor: pointer;
}
</style>
