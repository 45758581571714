import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserDoc from './CompanyUserDoc';

export default class CompanyUserDocCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserDoc,
        };
    }

    modelCustomAttributes() {
        return {
            company_user_id: null,
        };
    }
}
