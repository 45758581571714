import Vue from 'vue';
import api from '@/store/api';

const SET_RESET = 'set_reset';
const SET_EMAILTEMPLATES = 'set_emailtemplates';

export default {
    namespaced: true,

    state() {
        return {
            data: [],
            dataLoadedAt: null,
        };
    },

    getters: {
        getAll({ data }) {
            return data;
        },
        getBySlug: ({ data }) => (slug) => data.find((template) => template.template_slug === slug),
        getLoadedAt({ dataLoadedAt }) {
            return dataLoadedAt;
        },
        isLoaded({ dataLoadedAt }) {
            return dataLoadedAt !== null;
        },
    },

    actions: {
        fetchEmailTemplates({ getters, commit }) {
            if (getters.isLoaded) {
                return;
            }

            commit(SET_RESET);

            return api.configuration.emailTemplate.fetchAll().then(({ data }) => {
                commit(SET_EMAILTEMPLATES, data.data);
            });
        },
    },

    mutations: {
        [SET_RESET](state) {
            state.dataLoadedAt = null;
        },
        [SET_EMAILTEMPLATES](state, data) {
            state.data = data;
            state.dataLoadedAt = Vue.prototype.$moment();
        },
    },
};
