<template>
    <div class="stepper-tab-wrapper">
        <div>
            <slot name="main" />
        </div>
        <div class="align-self-center mt-4">
            <slot name="buttonsFooter" />
        </div>
        <slot name="modalables" />
    </div>
</template>
<script>
export default {
    name: 'StepperTabLayout',
    inject: ['$validator'],
};
</script>

<style lang="scss" scoped>
.stepper-tab-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 65rem;
    margin: 0 auto;
    height: 100%;
    width: 100%;
}
</style>
