import Vue from 'vue';

/**
 * @typedef {Object} Survey
 * @property {number} id
 * @property {number} number_of_responses
 * @property {number} number_of_target_users
 * @property {string} status
 * @property {string} datetime_start
 * @property {string} datetime_end
 * @property {string} launch_date
 * @property {boolean} is_launched
 * @property {boolean} include_already_registered_users
 * @property {boolean} is_conversational
 * @property {Array} supervisors
 * @property {Array} contents
 * @property {Resource} resource
 * @property {CompanyFileHT} [company_file]
 */

/**
 * @typedef {{data: {data: Survey}}} SurveyResourceResponse
 * @typedef {{data: {data: number}}} SurveyCountResponse
 * @typedef {{data: {data: Survey[]}}} SurveyCollectionResponse
 */

export default {
    /**
     * @param {any} data
     * @returns {Promise<SurveyCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.post('configuration/surveys/list', data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<SurveyResourceResponse>}
     */
    get(id, config = {}) {
        return Vue.prototype.$http.get(`configuration/surveys/${id}`, config);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} data
     * @returns {Promise<SurveyCountResponse>}
     */
    count(id, data = {}) {
        return Vue.prototype.$http.post(`configuration/surveys/${id}/count`, data);
    },

    /**
     * @param {any} data
     * @returns {Promise<SurveyResourceResponse>}
     */
    create(data = {}) {
        return Vue.prototype.$http.post('configuration/surveys', data);
    },

    /**
     * @param {number|string} id
     * @param {any} data
     * @returns {Promise<SurveyResourceResponse>}
     */
    update(id, data = {}) {
        return Vue.prototype.$http.post(`configuration/surveys/${id}`, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<{}>}
     */
    delete(id, config = {}) {
        return Vue.prototype.$http.delete(`configuration/surveys/${id}`, config);
    },

    /**
     * @param {number|string} id
     * @param {any} config
     * @returns {Promise<SurveyResourceResponse>}
     */
    replicate(id, config = {}) {
        return Vue.prototype.$http.post(`configuration/surveys/${id}/replicate`, config);
    },
};
