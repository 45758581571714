<template>
    <div class="d-flex justify-content-end align-items-center flex-wrap">
        <div class="d-flex flex-nowrap color-grey-lighter font-weight-light text-sm-left pr-1 text-nowrap">
            {{ label }} :
        </div>
        <span
            v-for="(item, index) in mainItems"
            :key="index"
            class="px-1 font-weight-light text-nowrap"
        >
            {{ item.name_translated }}
            <span
                v-if="index === 0 && itemsTotal > itemNumber - 1"
                class="px-1"
            >|</span>
        </span>
        <span
            v-if="remaining > 0"
            class="pl-3 text-nowrap"
        >+ {{ remaining }}</span>
    </div>
</template>

<script>
export default {
    name: 'Perimeter',
    props: ['label', 'data', 'hasLabel'],

    data() {
        return {
            itemNumber: 2,
        };
    },

    computed: {
        itemsTotal() {
            return this.data.length;
        },

        remaining() {
            return this.data.length - this.itemNumber;
        },

        mainItems() {
            return this.data.slice(0, this.itemNumber);
        },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	.color-grey-lighter {
        color: $color-grey-lighter;
    }
</style>
