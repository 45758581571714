import Vue from 'vue';
import api from '@/store/api';

/**
 * @typedef {Object} Document
 * @property {number} id
 * @property {CompanyRequirementCategoryHT} category
 * @property {bool} is_locked
 * @property {bool} is_heyteam
 * @property {CompanyFileHT} [company_file]
 * @property {CompanyFileHT[]} [company_files]
 * @property {Object<string, FileTranslation|null>} file_translations
 * @property {Resource} resource
 */

/**
 * @typedef {{data: {data: Document}}} DocumentResourceResponse
 * @typedef {{data: {data: Document[]}}} DocumentCollectionResponse
 */

export default {
    /**
     * @param {number|string} id
     * @param {any} config
     * @returns {Promise<DocumentResourceResponse>}
     */
    replicate(id, config = {}) {
        return Vue.prototype.$http.post(`configuration/documents/${id}/replicate`, config);
    },

    /**
     * @param {any} data
     * @returns {Promise<DocumentCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.post('configuration/documents/list', data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<DocumentResourceResponse>}
     */
    get(id, config = {}) {
        return Vue.prototype.$http.get(`configuration/documents/${id}`, config);
    },

    /**
     * @param {any} data
     * @returns {Promise<DocumentResourceResponse>}
     */
    create(data = {}) {
        return Vue.prototype.$http.post('configuration/documents', data);
    },

    /**
     * @param {number|string} id
     * @param {any} data
     * @returns {Promise<DocumentResourceResponse>}
     */
    update(id, data = {}) {
        return Vue.prototype.$http.post(`configuration/documents/${id}`, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<{}>}
     */
    delete(id, config = {}) {
        return Vue.prototype.$http.delete(`configuration/documents/${id}`, config);
    },

    /**
     * @param {number|string} documentId
     * @param {number|string} fileId
     * @returns {Promise<{CompanyFileHT}>}
     */
    duplicateFile(documentId, fileId) {
        const params = { file: fileId, document: documentId };

        const endpoint = api.utils.replaceVariables(api.endpoints.CONFIGURATION_DOCUMENTS_DUPLICATION_FILE, params);

        return Vue.prototype.$http.post(endpoint);
    },
};
