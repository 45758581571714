<template>
    <div
        class="card-action-container"
    >
        <HtActionCard @click.native="setMode('builder')">
            <template #icon>
                <FontAwesomeIcon
                    :icon="['fas','pencil']"
                    class="icon"
                    :title="translate('Builder')"
                />
            </template>
            <template #title>
                <t>Builder</t>
            </template>
            <template #description>
                <t>Create all yourself, check every detail.</t>
            </template>
            <template #action>
                <t>start</t>
            </template>
        </HtActionCard>
        <HtActionCard
            custom-color="#8337e6"
            @click.native="setMode('ai')"
        >
            <template #icon>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ><path
                    stroke="none"
                    d="M0 0h24v24H0z"
                    fill="none"
                /><path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" /></svg>
            </template>
            <template #title>
                <t>AI</t>
            </template>
            <template #description>
                <t>Let AI formulate innovative things for you.</t>
            </template>
            <template #action>
                <t>start</t>
            </template>
        </HtActionCard>
    </div>
</template>

<script>
import HtActionCard from '@/components/globals/HtActionCard.vue';

export default {
    name: 'AIModeSelector',
    components: { HtActionCard },
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    methods: {
        setMode(mode) {
            this.$emit('input', mode);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.card-action-container {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;

  @media (max-width: $tablet) {
    grid-template-columns: 100% 100%;
  }
}
</style>
