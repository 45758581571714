<template>
    <div
        v-if="filtersLoaded"
        class="dashboard-programs-wrapper"
    >
        <HtTabs>
            <HtTab
                :title="translate('Collaborators view')"
            >
                <HtEntitiesFiltersWrapper
                    store="dashboard/programs"
                >
                    <template #title>
                        <HtFormSelector
                            id="custom-filter"
                            v-model="onlyWhereIamParticipant"
                            name="custom-filter"
                            :options="[
                                {label: translate('All collaborators'), value: false},
                                {label: translate('My team'), value: true}
                            ]"
                            :placeholder="translate('None')"
                            is-single
                            track-by="value"
                            specific-key="value"
                            label-options="label"
                            :allow-empty="false"
                        >
                            <template #prepend>
                                <FontAwesomeIcon
                                    :icon="['far', 'filter']"
                                />
                            </template>
                        </HtFormSelector>
                    </template>
                    <template #topFilter>
                        <HtSearchField
                            id="search"
                            v-model="filterUser"
                            name="search"
                            class="search"
                            :placeholder="translate('Search a user...')"
                        />
                    </template>

                    <template #beforeFilters>
                        <HtFormSelector
                            id="programs"
                            v-model="filterPrograms"
                            name="programs"
                            :options="[...programOptions]"
                            :placeholder="translate('Programs')"
                            :selection-label="translate('{count} program | {count} programs',
                                                        {count: selectedProgramTemplates.length})"
                            open-direction="bottom"
                            class="program-top-filter"
                        />
                    </template>
                    <template #afterFilters="layoutModal">
                        <HtFormSwitch
                            :id="'next-key-dates'"
                            v-model="nextKeyDatesFilter"
                            :label="layoutModal ? translate('Display next key dates only') : undefined"
                            :placeholder="layoutModal ?
                                undefined : translate('Display next key dates only')"
                            :name="'next-key-dates'"
                            :show-optional="false"
                        />
                    </template>
                </HtEntitiesFiltersWrapper>
                <DashboardProgramsEmpty v-if="showEmpty" />
                <div
                    v-else
                    class="table"
                >
                    <div class="table-scrollable-container">
                        <HTable
                            :use-new-style="true"
                            :columns="columns"
                            :data="items"
                            :loading="loading"
                            :initial-sorting-column="initialSorting.column"
                            :initial-sorting-direction="initialSorting.direction"
                            @on-sort="onSort"
                            @on-row-click="onRowClick"
                            @on-cell-click="onCellClick"
                        >
                            <template #header(checkbox)>
                                <HTableCellCheckbox
                                    v-model="checkAll"
                                    :indeterminate="indeterminate"
                                    :is-header="true"
                                />
                            </template>
                            <template #cell(main_key_date_starts_at)="data">
                                {{ data.value.format('L') }}
                            </template>
                            <template #cell(enrollee_completion)="data">
                                <HtCompletionBar
                                    :percentage="data.value"
                                />
                            </template>
                            <template #cell(participants_completion)="data">
                                <HtCompletionBar
                                    :percentage="data.value"
                                />
                            </template>
                            <template #cell(checkbox)="data">
                                <HTableCellCheckbox
                                    :value="rowChecked(data.row.user_program_id)"
                                    @input="toggleCheckbox(data.row.user_program_id)"
                                />
                            </template>
                            <template #cell(user_program_status)="data">
                                <HTableCellTag
                                    :color="statuses[data.value].color"
                                    :label="statuses[data.value].label"
                                    :is-uppercase="false"
                                />
                            </template>
                            <template #cell(user)="data">
                                <HTableCellUser
                                    :user="data.value"
                                />
                            </template>
                            <template #cell(program_template_name)="data">
                                <HTableCellTag
                                    :label="data.value"
                                    :is-uppercase="false"
                                />
                            </template>
                            <template #cell(actions)="data">
                                <HTableActions>
                                    <HTableActionsItem
                                        :disabled="canNotExport"
                                        :label="translate('Export program')"
                                        @click="exportProgram(data.row.user_program_id)"
                                    />
                                    <HTableActionsItem
                                        :disabled="canNotExport"
                                        :label="translate('Export resources')"
                                        @click="exportActions(data.row.user_program_id)"
                                    />
                                    <HTableActionsItem
                                        :disabled="canNotRemind(data.row)"
                                        :label="translate('Remind')"
                                        @click="remindProgram(data.row.user_program_id)"
                                    />
                                    <HTableActionsItem
                                        :disabled="canNotClose(data.row)"
                                        :label="translate('Close')"
                                        @click="closeProgram(data.row.user_program_id)"
                                    />
                                </HTableActions>
                            </template>
                            <template #cell(team)="data">
                                <HTableCellSupervisors
                                    :users="data.value"
                                />
                            </template>
                        </HTable>
                    </div>
                    <div>
                        <HtPagination
                            v-model="page"
                            :last-page="lastPage"
                            class="pagination"
                            @input="changePage"
                        />
                    </div>
                </div>
                <ProgramsFooterBar
                    v-if="showFooter"
                    :page="page"
                />
            </HtTab>
            <HtTab
                :title="translate('Tasks view')"
            >
                <ResourceReminderVue />
            </HtTab>
        </HtTabs>
    </div>
</template>

<script>
import api from '@/store/api';
import keyBy from 'lodash.keyby';
import uniqBy from 'lodash.uniqby';

import ProgramsFooterBar from '@/components/pages/dashboard/components/ProgramsFooterBar.vue';
import DashboardProgramsEmpty from '@/components/pages/dashboard/components/DashboardProgramsEmpty.vue';

import modalMixin from '@/components/globals/modals/modalMixin';

import {
    HTable,
    HTableActions,
    HTableActionsItem,
    HTableCellCheckbox,
    HTableCellSupervisors,
    HTableCellTag,
    HTableCellUser,
} from '@/components/globals/table';
import HtEntitiesFiltersWrapper from '@/components/globals/filters/HtEntitiesFiltersWrapper.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import debounce from 'lodash.debounce';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtCompletionBar from '@/components/globals/HtCompletionBar.vue';
import HtTabs from '@/components/globals/HtTabs.vue';
import HtTab from '@/components/globals/HtTab.vue';
import ResourceReminderVue from '@/components/Dashboard/ResourceReminderVue.vue';

export default {
    name: 'Programs',

    components: {
        ProgramsFooterBar,
        DashboardProgramsEmpty,
        HTable,
        HTableActions,
        HTableActionsItem,
        HTableCellTag,
        HTableCellUser,
        HTableCellCheckbox,
        HTableCellSupervisors,
        HtEntitiesFiltersWrapper,
        HtSearchField,
        HtFormSelector,
        HtFormSwitch,
        HtCompletionBar,
        HtTabs,
        HtTab,
        ResourceReminderVue,
    },

    mixins: [
        modalMixin,
    ],

    data() {
        return {
            page: 1,
        };
    },

    computed: {
        canNotExport() {
            return this.$can('PageSettingsDataAnalysis') === false;
        },
        columns() {
            return [
                {
                    key: 'checkbox',
                    thStyle: {
                        zIndex: 2,
                        width: '44px',
                    },
                    tdStyle: {
                        zIndex: 2,
                    },
                },
                {
                    key: 'user',
                    label: this.translate('User'),
                    iconClass: ['far', 'user'],
                    sortable: true,
                    sort(a, b) {
                        return (`${a.firstname} ${a.lastname}`).localeCompare(`${b.firstname} ${b.lastname}`);
                    },
                },
                {
                    key: 'program_template_name',
                    label: this.translate('Program'),
                    iconClass: ['far', 'chart-network'],
                    sortable: true,
                },
                {
                    key: 'main_key_date_starts_at',
                    label: this.translate('My Key Date'),
                    iconClass: ['fal', 'calendar-alt'],
                    sortable: true,
                    sort(a, b) {
                        return a.diff(b);
                    },
                    hideOnMobile: true,
                    thStyle: {
                        width: '130px',
                    },
                },
                {
                    key: 'enrollee_completion',
                    label: this.translate('Enrollee completion'),
                    iconClass: ['far', 'user'],
                    sortable: false,
                    thStyle: {
                        width: '180px',
                    },
                },
                {
                    key: 'participants_completion',
                    label: this.translate('Participants completion'),
                    iconClass: ['far', 'users'],
                    sortable: false,
                    thStyle: {
                        width: '200px',
                    },
                },
                {
                    key: 'user_program_status',
                    label: this.translate('Status'),
                    iconClass: ['fal', 'calendar-alt'],
                    sortable: true,
                    hideOnTablet: true,
                    thStyle: {
                        width: '130px',
                    },
                },
                {
                    key: 'team',
                    label: this.translate('Team'),
                    iconClass: ['far', 'users'],
                    thStyle: {
                        width: '130px',
                    },
                },
                {
                    key: 'actions',
                    thStyle: {
                        width: '50px',
                    },
                },
            ];
        },
        checkAll: {
            set() {
                this.$store.dispatch('dashboard/programs/toggleCheckAll', this.page);
            },
            get() {
                return this.$store.getters['dashboard/programs/checkboxStatuses'](this.page).some;
            },
        },
        indeterminate() {
            const status = this.$store.getters['dashboard/programs/checkboxStatuses'](this.page);
            return status.some && !status.all;
        },
        statuses() {
            const statuses = keyBy(this.$store.state.config.program_statuses, 'slug');

            return {
                invited: {
                    label: this.translate(statuses.invited.name),
                    color: 'purple',
                },
                active: {
                    label: this.translate(statuses.active.name),
                    color: 'blue',
                },
                late: {
                    label: this.translate(statuses.late.name),
                    color: 'red',
                },
                reminded: {
                    label: this.translate(statuses.reminded.name),
                    color: 'orange',
                },
                completed: {
                    label: this.translate(statuses.completed.name),
                    color: 'green',
                },
                closed: {
                    label: this.translate(statuses.closed.name),
                    color: 'purple',
                },
            };
        },
        items() {
            const { moment } = this.$Utils;

            return this.$store.getters['dashboard/programs/getPage'](this.page).map((row) => ({
                user: row.user,

                user_program_id: row.program.id,
                user_program_status: row.program.status,
                enrollee_completion: row.program.tasks.enrollee_completion,
                participants_completion: row.program.tasks.participants_completion,
                main_key_date_starts_at: moment(row.program.main_key_date.starts_at),

                program_template_name: this.programs.find((p) => p.id == row.program.template.id).name,

                team: this.formatSupervisors(row),

                _rowVariant: this.rowChecked(row.program.id) ? 'branding-bg-highlight' : null,

                _cellVariants: {
                    checkbox: this.rowChecked(row.program.id) ? 'branding-bg' : null,
                },
            }));
        },
        filtersLoaded() {
            return this.$store.state.entities.entitiesLoaded
                && this.$store.state.programs.programsLoaded;
        },
        loading() {
            return this.$store.state.dashboard.programs.loading;
        },
        showEmpty() {
            return this.$store.state.dashboard.programs.totalLines === 0 && !this.loading;
        },
        lastPage() {
            return this.$store.state.dashboard.programs.lastPage;
        },
        showFooter() {
            return this.$store.getters['dashboard/programs/getSelectedProgramsCount'](this.page);
        },
        programs() {
            return this.$store.state.programs.programs.map((program) => ({
                id: program.id,
                name: this.oldLocalize(program.locales, 'name'),
            }));
        },
        selectedProgramTemplates() {
            return this.$store.state.dashboard.programs.filters.programs;
        },
        initialSorting() {
            return this.$store.state.dashboard.programs.sort;
        },
        filterUser: {
            get() {
                return this.$store.state.dashboard.programs.filters.user;
            },
            set: debounce(function (user) {
                this.$store.dispatch('dashboard/programs/setUserFilter', user);
            }, 300),
        },
        filterPrograms: {
            /**
             * @param {array} programs
             * @returns {void}
             */
            set(programs) {
                const ids = programs.map((v) => v.id);

                this.$store.dispatch('dashboard/programs/setProgramsFilter', ids);
            },
            /**
             * @returns {array}
             */
            get() {
                return this.$store.state.dashboard.programs.filters.programs.map((id) => ({ id }));
            },
        },
        programOptions() {
            return this.$store.state.programs.programs.map((program) => ({
                id: program.id,
                name: this.oldLocalize(program.locales, 'name'),
            }));
        },
        nextKeyDatesFilter: {
            get() {
                return this.$store.state.dashboard.programs.filters.next_key_dates;
            },
            set(value) {
                this.$store.dispatch('dashboard/programs/setNextKeyDatesFilter', value);
            },
        },
        onlyWhereIamParticipant: {
            get() {
                return this.$store.state.dashboard.programs.filters.only_where_i_am_participant;
            },
            set(value) {
                this.$store.dispatch('dashboard/programs/setOnlyWhereIamParticipant', value);
                this.$store.dispatch('dashboard/programs/refresh');
                this.$store.dispatch('dashboard/programs/fetchPage', 1);
            },
        },
    },

    watch: {
        lastPage() {
            this.page = 1;
        },
    },

    created() {
        this.setPage({
            title: this.translate('Dashboard'),
            icon: ['far', 'arrow-left'],
            iconAction: () => this.$router.push({ name: 'Dashboard' }),
        });

        if (this.$route.query.status) {
            this.$store.commit('dashboard/programs/set_statuses_filter', this.$route.query.status);
        }

        this.$store.dispatch('entities/fetchEntities');
        this.$store.dispatch('programs/fetchPrograms');
        this.$store.dispatch('dashboard/programs/refresh');
        this.$store.dispatch('dashboard/programs/fetchPage', this.page);
    },

    methods: {
        canNotClose(row) {
            return ['completed', 'closed'].includes(row.user_program_status)
                || this.$can('AbstractClosePrograms', { company_user_id: row.user.id }) === false;
        },
        canNotRemind(row) {
            return ['late', 'invited'].includes(row.user_program_status) === false
                || this.$can('AbstractManageUserPrograms', { company_user_id: row.user.id }) === false;
        },
        remindProgram(programId) {
            this.genericConfirm(
                this.translate('Confirmation needed'),
                this.translate("You're about to send a reminder to this program"),
            ).then(() => this.$http.post('dashboard/statistics/send-reminders', {
                programs: [programId],
            }))
                .then(() => {
                    this.$store.dispatch('dashboard/programs/refresh', this.page);
                    this.$store.dispatch('dashboard/programs/fetchPage', this.page);

                    this.$Notifier('App').showInfo(this.translate('Successful update'));
                }).catch(() => {});
        },
        closeProgram(programId) {
            this.genericConfirm(
                this.translate('Confirmation needed'),
                this.translate("You're about to permanently close this program"),
            ).then(() => this.$http.post('dashboard/statistics/close-programs', {
                programs: [programId],
            }))
                .then(() => {
                    this.$store.dispatch('dashboard/programs/refresh', this.page);
                    this.$store.dispatch('dashboard/programs/fetchPage', this.page);
                    this.$Notifier('App').showInfo(this.translate('Program closed'));
                }).catch(() => {});
        },
        exportProgram(programId) {
            api.miscellaneous.exportUsers({ programs: [programId] }).then((data) => {
                window.location = data.data.link;
            });
        },
        exportActions(programId) {
            api.miscellaneous.exportActions({ programs: [programId] }).then((data) => {
                window.location = data.data.link;
            });
        },
        formatSupervisors(row) {
            return uniqBy(row.supervisors, (s) => s.user.id).map((s) => s.user);
        },
        onSort(column, direction) {
            this.$store.dispatch('dashboard/programs/setSorting', { column, direction });
            this.$store.dispatch('dashboard/programs/fetchPage', 1);
        },
        onRowClick(row) {
            this.$router.push({
                name: 'ProfileProgramDetail',
                params: {
                    company_user_id: row.user.id,
                    company_user_program_id: row.user_program_id,
                },
            });
        },
        onCellClick(event, row, col) {
            if (['actions', 'checkbox'].includes(col.key)) {
                event.stopPropagation();
            }
        },
        changePage() {
            this.$store.dispatch('dashboard/programs/fetchPage', this.page);
        },
        rowChecked(id) {
            return this.$store.getters['dashboard/programs/rowChecked'](id);
        },
        toggleCheckbox(programId) {
            this.$store.dispatch('dashboard/programs/toggleCheckbox', programId);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
.dashboard-programs-wrapper{
    display: flex;
    flex-direction: column;
    gap: 40px;
    .table {
        margin-bottom: 30px;
        margin-top: 30px;

        .table-scrollable-container{
            overflow-y: auto;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            table {
                width: 100%;
                white-space: nowrap;
                table-layout: auto !important;
            }
        }

        .pagination{
            // move navigation up to compensate for new-style table padding-bottom (Table.vue)
            margin-top: -115px;
        }
    }
}
::v-deep .table-scrollable-container table tr td:first-child,
::v-deep .table-scrollable-container table tr th:first-child{
    border-left: 0;
    position: sticky;
    left: 0;
}
::v-deep .table-scrollable-container table tr td:first-child::after,
::v-deep .table-scrollable-container table tr th:first-child::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  background: #E5E6E6;
}
@media (max-width: $tablet) {
    .dashboard-programs-wrapper{
        margin: 16px 8px;
    }
}
</style>
