<template>
    <ResourceListing
        api-name="requirementCategory"
        :title="translate('Requirements')"
        :columns="columns"
        permission="ModelCompanyRequirementCategory"
        empty-message="There are no requirements to display yet..."
        :is-modalable="false"
        @on-create="openForm"
        @on-row-clicked="onRowClicked"
    >
        <template #cell(program_name)="scope">
            <span v-if="'display_survey' in scope.item && scope.item.display_survey">
                <t>Link to a survey</t>
            </span>
            <span v-else>{{ scope.item.resource.programs[0]?.program?.name ?? '-' }}</span>
        </template>

        <template #cell(nb_requirement)="scope">
            {{ scope.item.company_requirement.length }}
        </template>
    </ResourceListing>
</template>

<script>
import ResourceListing from '@/components/resources/generic/ResourceListing.vue';

export default {
    permissions: [
        'ModelCompanyRequirementCategory',
    ],

    components: {
        ResourceListing,
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('Number of requirements'),
                    key: 'nb_requirement',
                    sortable: false,
                },
            ],
        };
    },

    methods: {
        openForm() {
            this.$router.push({
                name: 'ResourcesRequirementsForm',
            });
        },

        onRowClicked(row) {
            this.$router.push({
                name: 'ResourcesRequirementsForm',
                params: {
                    id: row.item.id,
                },
            });
        },
    },
};
</script>
