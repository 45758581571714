import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyRoleProgram from './CompanyRoleProgram';

export default class CompanyRoleProgramCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyRoleProgram,
        };
    }
}
