<template>
    <InputWrapper
        :editing="field.editing"
        :can-delete="canDelete"
        :can-mandatorize="canMandatorize"
        :can-edit="canEdit"
        :mandatory="field.is_mandatory"
        :title="field.title"
        :role-id="field.completer_id"
        :field-type="field.type"
        :field-rules="field.rule_params"
        :field-id="field.id"
        @delete="remove"
        @mandatorize="mandatorize"
        @setTitle="setTitle"
        @setRole="setRole"
        @setRule="setRule"
        @setSpecificRule="setSpecificRule"
    >
        <HtInputText
            v-if="canEdit"
            :id="`ht-input-${field.id}`"
            ref="inputField"
            v-model="field.value"
            :type="fieldType"
            class="placeholder"
            :name="field.uuid"
            contenteditable="true"
            :disabled="disabled"
            :placeholder="placeholder"
            :style="cssVars"
            :rules="getFieldRules"
            @input="onInput"
            @click.native="onClick"
            @dblclick.native="dbClick"
        />
        <div
            v-else
            :style="{
                fontSize: field.font_ratio * 100 + '%'
            }"
        >
            {{ field.value }}
        </div>
    </InputWrapper>
</template>

<script>
import HtInputText from '@/components/globals/HtInputText.vue';
import InputWrapper from '@/components/DocumentEditor/Inputs/InputWrapper.vue';
import FieldValidator from '../FieldValidator';

export default {
    name: 'InputTextField',
    components: {
        HtInputText, InputWrapper,
    },
    props: {
        field: {
            type: Object,
            required: true,
        },
        canDelete: {
            type: Boolean,
            default() {
                return true;
            },
        },
        canMandatorize: {
            type: Boolean,
            default() {
                return false;
            },
        },
        canEdit: {
            type: Boolean,
            default() {
                return false;
            },
        },
        fieldValidator: {
            type: FieldValidator,
            default: () => {},
        },
    },
    data() {
        return {
            hover: false,
            fieldTypes: {
                numeric: {
                    placeholder: this.translate('Enter numbers'),
                    inputType: 'number',
                },
                textonly: {
                    placeholder: this.translate('Enter letters'),
                    inputType: 'text',
                },
                date: {
                    palceholder: this.translate('Enter date'),
                    inputType: 'date',
                },
            },
        };
    },
    computed: {
        getFieldRules() {
            return this.fieldValidator?.buildRuleString(this.field) || '';
        },
        placeholder() {
            return this.fieldTypes[this.field.type]?.placeholder || this.translate('Enter text');
        },
        fieldType() {
            return this.fieldTypes[this.field.type]?.inputType || 'text';
        },
        disabled() {
            if ('disabled' in this.field) {
                return this.field.disabled;
            }
            if (!('editable' in this.field)) {
                return false;
            }
            return !this.field.editable.enrollee;
        },
        cssVars() {
            return {
                'font-size': `${(1.2 * this.field.font_ratio)}rem`,
            };
        },
    },
    mounted() {
        // Add nextTick to ensure that this.$refs exists. Resize doesn't trigger otherwhise
        this.$nextTick(() => {
            this.resize();
        });
    },
    methods: {
        remove() {
            this.$emit('delete');
        },
        mandatorize() {
            this.$emit('mandatorize');
        },
        setSpecificRule(v) {
            this.$emit('setSpecificRule', v);
        },
        setTitle(v) {
            this.$emit('setTitle', v);
        },
        setRole(roleId) {
            this.$emit('setRole', roleId);
        },
        setRule(ruleInfos) {
            this.$emit('setRule', ruleInfos);
        },
        onInput() {
            this.resize();
        },
        resize() {
            if (this.field.value && 'inputField' in this.$refs) {
                this.$refs.inputField.$refs.input.style.width = `${this.field.value.length + 4}ch`;
            }
        },
        increase() {
            this.field.font_ratio += 0.1;
        },
        decrease() {
            this.field.font_ratio -= 0.1;
        },
        onClick(event) {
            event.target.focus();
            if (this.field.type === 'text') {
                event.target.setSelectionRange(event.target.value.length, event.target.value.length);
            }
        },
        dbClick(event) {
            event.stopPropagation();
            event.target.select();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .ht-input input::-moz-focus-inner {
    border: 0;
}

::v-deep .ht-input input {
    padding: 0;
    background: transparent;
    width: 13ch;
    min-width: 13ch;
    border-radius: 5px;
}

.font-adjustement-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 8px;
}

.placeholder {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, var(--branding-color) 0px 0px 0px 2px;
    background: #d8d8d8;
    border-radius: 5px;
}

</style>
