<template>
    <div class="form-2">
        <HtFormSelect
            id="category"
            v-model="clickedUserRequirementCategory"
            name="category"
            :label="translate('Category')"
            :options="options"
            disabled
        />

        <div class="title-section">
            <t>Requirements</t>
        </div>

        <div
            v-for="group in clickedUserRequirementCategory.company_user_requirement_group.$.models"
            :key="group.id"
        >
            <RequirementCard
                v-for="companyRequirement in group.company_user_requirement.models"
                :key="companyRequirement.id"
                :requirement="companyRequirement"
                class="my-3"
            />
        </div>

        <div class="modal-actions">
            <Button
                v-if="canDelete"
                :state="buttonState"
                cypress="training-delete-button"
                @click="onClick()"
            >
                <t>Delete</t>
            </Button>
        </div>
    </div>
</template>

<script>
import CompanyUserRequirementCategory from '@/models/CompanyUserRequirementCategory';
import RequirementCard from './RequirementCard.vue';

export default {
    name: 'UserRequirementCategoryDeleteModal',
    components: {
        RequirementCard,
    },

    inject: ['modal'],

    props: {
        clickedUserRequirementCategory: {
            type: CompanyUserRequirementCategory,
            default: null,
            required: true,
        },
    },

    data() {
        return {
            buttonState: 'idle',
            options: null,
        };
    },

    computed: {
        canDelete() {
            return this.clickedUserRequirementCategory.display_survey === false;
        },
    },

    created() {
        this.options = [{
            id: this.clickedUserRequirementCategory,
            name: this.clickedUserRequirementCategory.name,
        }];

        this.modal.setTitle(this.translate('Delete requirements'));
    },

    methods: {
        /**
         * supprime la companyUserRequirementCategory
         * @returns {void}
         */
        async onClick() {
            if (await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this requirement category ? Once you click on delete, you will no longer be able to access this category.') }) === true) {
                this.buttonState = 'loading';

                try {
                    await this.clickedUserRequirementCategory.delete();
                    this.buttonState = 'idle';
                    this.modal.close();
                    this.$emit('on-delete');
                } catch (error) {
                    this.buttonState = 'idle';
                }
            }
        },
    },
};
</script>

<style lang="scss" src="./UserRequirementCategoryDeleteModal.scss" scoped />
