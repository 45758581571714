<template>
    <HtEntitiesFiltersWrapper
        v-if="isLoaded"
        store="resources"
        should-show-library-mode
        @on-update-filters="onSelectFilter"
    >
        <template
            v-for="(_, slot) of $scopedSlots"
            #[slot]="scope"
        >
            <slot
                :name="slot"
                v-bind="scope"
            />
        </template>
        <template #beforeFilters="{ layoutModal }">
            <HtFormSelector
                id="resourceBarProgramFilters"
                v-model="programs"
                name="resourceBarProgramFilters"
                :options="programOptions"
                :show-optional="false"
                :label="layoutModal ? translate('Programs') : undefined"
                :placeholder="layoutModal ? undefined : translate('Programs')"
                open-direction="bottom"
                @input="onSelectFilter"
            />
            <HtFormSelector
                v-if="hasRoleFilter"
                id="resourceBarRoleFilters"
                v-model="roles"
                name="resourceBarRoleFilters"
                :options="roleOptions"
                :label="layoutModal ? translate('Roles') : undefined"
                :placeholder="layoutModal ? undefined : translate('Roles')"
                open-direction="bottom"
                @input="onSelectFilter"
            />
        </template>
    </HtEntitiesFiltersWrapper>
</template>

<script>
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import HtEntitiesFiltersWrapper from '../globals/filters/HtEntitiesFiltersWrapper.vue';

export default {
    name: 'ResourceBarFilters',
    components: {
        HtFormSelector,
        HtEntitiesFiltersWrapper,
    },
    props: {
        hasRoleFilter: {
            type: Boolean,
            default: () => false,
        },
    },
    computed: {
        isLoaded() {
            return this.$store.state.programs.programsLoaded
                && (!this.hasRoleFilter || (this.hasRoleFilter && this.$store.state.roles.rolesLoaded));
        },
        programOptions() {
            return this.$store.state.programs.programs.map((program) => ({
                id: program.id,
                name: this.oldLocalize(program.locales, 'name'),
            }));
        },
        programs: {
            set(programs) {
                const ids = programs.map((v) => v.id);

                this.$store.dispatch('resources/setProgramsFilter', ids);
            },
            get() {
                return this.$store.state.resources.filters.programs.map((id) => ({ id }));
            },
        },
        roleOptions() {
            return this.$store.state.roles.roles.map((role) => ({
                id: role.id,
                name: this.translate(role.alias),
            }));
        },
        roles: {
            set(roles) {
                const ids = roles.map((v) => v.id);

                this.$store.dispatch('resources/setRolesFilter', ids);
            },
            get() {
                return this.$store.state.resources.filters.roles.map((id) => ({ id }));
            },
        },
    },
    created() {
        this.$store.dispatch('programs/fetchPrograms');

        if (this.hasRoleFilter) {
            this.$store.dispatch('roles/fetchRoles');
        }
    },
    methods: {
        onSelectFilter() {
            this.$emit('on-select-filter');
        },
    },
};
</script>
