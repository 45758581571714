<template>
    <div
        class="list-channel-item"
        :class="{ active }"
    >
        <div class="list-channel-item-avatar">
            <div
                v-if="showIconAsAvatar"
                class="list-channel-item-avatar-img bg-red d-flex align-items-center justify-content-center"
            >
                <FontAwesomeIcon
                    :icon="iconAvatar"
                    style="color:white"
                />
            </div>
            <div
                v-else-if="showGroupImage"
                class="list-channel-item-avatar-img d-flex align-items-center justify-content-center"
            >
                <img :src="image">
            </div>
            <template v-else>
                <div
                    v-user-image="{
                        firstname: imageFirstname,
                        lastname: imageLastname,
                        image: image
                    }"
                    class="list-channel-item-avatar-img"
                />
                <div
                    class="list-channel-item-avatar-status"
                    :class="{ active: isConnected }"
                />
            </template>
        </div>
        <div
            class="list-channel-item-profile"
            :class="{ visible: showProfile }"
            :title="hint"
        >
            <div class="list-channel-item-profile-name">
                {{ name }}
            </div>
            <div
                v-show="subname"
                class="list-channel-item-profile-subname"
            >
                {{ subname }}
            </div>
        </div>
        <div
            v-if="count > 0"
            class="list-channel-item-notification branding-bg"
        >
            {{ countLabel }}
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ChatListChannelItemHtml',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        image: {
            type: String,
            default: '',
        },
        imageFirstname: {
            type: String,
            default: '',
        },
        imageLastname: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        hint: {
            type: String,
            default: '',
        },
        subname: {
            type: String,
            default: null,
        },
        count: {
            type: Number,
            default: 0,
        },
        showProfile: {
            type: Boolean,
            default: false,
        },
        showIconAsAvatar: {
            type: Boolean,
            default: false,
        },
        showGroupImage: {
            type: Boolean,
            default: false,
        },
        iconAvatar: {
            type: [Array, String],
            default: 'times',
        },
        isConnected: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        countLabel() {
            return this.count < 10 ? this.count : '+9';
        },
    },
};
</script>

<style lang="scss" scoped src="./ChatListChannelItemHtml.scss" />
