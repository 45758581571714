import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyEmailTemplate from './CompanyEmailTemplate';

export default class CompanyEmailTemplateCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyEmailTemplate,
        };
    }
}
