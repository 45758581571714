<template>
    <div
        v-tooltip.right="action.tooltip"
        class="quick-actions-item"
        @click="onClick"
    >
        <div class="quick-actions-item-content">
            <FontAwesomeIcon
                :icon="action.icon"
            />

            <span>{{ action.label }}</span>
        </div>

        <HtButton
            class="quick-actions-item-button"
            icon="arrow-right"
            icon-only
            type="secondary"
        />
    </div>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';
import ModalMixin from '@/components/globals/modals/modalMixin';

export default {
    name: 'QuickActionsItem',

    components: {
        HtButton,
    },

    mixins: [
        ModalMixin,
    ],

    props: {
        action: {
            type: Object,
            required: true,
        },
    },

    methods: {
        onClick() {
            this.action.callback();
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.quick-actions-item {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    padding: 8px 24px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #2C2C2C;
    cursor: pointer;
    height: 44px;

    &-content {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &-button {
        display: none;
    }

    &:hover {
        background-color: #F8F8F8;

        .quick-actions-item-button {
            display: inherit;
        }
    }
}
</style>
