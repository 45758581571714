<template>
    <div class="page-content">
        <h1 class="small">
            {{ getTranslatedMessage(companyFlowCopy['bloc_company_title']['content']['messages']['message_1']) }}
        </h1>

        <!-- First Link -->
        <FlowRow
            v-if="messages['bloc_company_a'] === true && company.isLoaded()"
            :company-user="bubbleUser"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div class="text-title">
                    {{ getTranslatedMessage(companyFlowCopy['bloc_company_a']['content']['messages']['message_1']) }}
                </div>
                <div
                    v-dompurify-html="company.about"
                    class="html"
                />
            </template>
        </FlowRow>
        <!-- !First Link -->

        <FlowRow
            v-if="messages['bloc_company_b'] === true"
            :company-user="bubbleUser"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_company_b']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_company_c'] === true && company.isLoaded() && company.company_department.$.models.length"
            :no-bg="true"
            :full="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div class="company-bloc">
                    <div
                        v-if="company.founded_year"
                        class="card"
                    >
                        <span class="bold-padding"><t :count="getCountExistence">{count} year of existence | {count} years of existence</t></span>
                        <span><t :year="company.founded_year">Founded in {year}</t></span>
                    </div>
                    <div
                        v-if="company.employees"
                        class="card"
                    >
                        <span class="bold-padding"><t :count_employees="company.employees">{count_employees} employees</t></span>
                        <span><t>in the company</t></span>
                    </div>
                </div>
            </template>
        </FlowRow>

        <!-- Second Link -->
        <FlowRow
            v-if="messages['bloc_company_d'] === true && company.isLoaded()"
            :company-user="bubbleUser"
            :full="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div class="text-title">
                    {{ getTranslatedMessage(companyFlowCopy['bloc_company_d']['content']['messages']['message_1']) }}
                </div>
                <div
                    v-dompurify-html="company.mission"
                    class="html"
                />
            </template>
        </FlowRow>
        <!-- !Second Link -->

        <FlowRow
            v-if="messages['bloc_company_e'] === true"
            :company-user="bubbleUser"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                {{ getTranslatedMessage(companyFlowCopy['bloc_company_e']['content']['messages']['message_1']) }}
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_company_f'] === true && company.isLoaded() && companyValues.length"
            :no-bg="true"
            :full="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div
                    v-if="companyValues.length"
                    class="company-bloc"
                >
                    <div
                        v-for="(value, index) in companyValues"
                        :key="index"
                        class="card"
                    >
                        <span>{{ value.name }}</span>
                    </div>
                    <div class="card-empty" />
                    <div class="card-empty" />
                    <div class="card-empty" />
                </div>
            </template>
        </FlowRow>

        <template v-if="companyMedias.isLoaded() && companyMedias.$.models.length">
            <FlowRow
                v-if="messages['bloc_company_g'] === true"
                :company-user="bubbleUser"
                :full="true"
                @onLoadRow="$emit('onLoadRow')"
            >
                <template slot="content">
                    <div class="text-title">
                        {{ getTranslatedMessage(companyFlowCopy['bloc_company_g']['content']['messages']['message_1']) }}
                    </div>
                    <div class="block-item no-border no-margin">
                        <div>
                            <carousel
                                :per-page="1"
                                :navigation-enabled="true"
                            >
                                <slide
                                    v-for="media in getPictures"
                                    :key="media.id"
                                >
                                    <img
                                        v-if="media.type === 'file' && !isVideo(media)"
                                        class="image-media"
                                        :data-vv-scope="media.id"
                                        :src="getLink(media)"
                                    >
                                    <div
                                        v-else-if="media.type === 'file' && isVideo(media)"
                                        class="image-video image-media"
                                        :data-vv-scope="media.id"
                                    >
                                        <video
                                            width="100%"
                                            height="300"
                                            controls
                                        >
                                            <source :src="getLink(media)">
                                        </video>
                                    </div>
                                    <div
                                        v-if="media.type === 'link'"
                                        class="image-media video"
                                    >
                                        <iframe
                                            width="100%"
                                            :src="$Utils.embedVideo(media.url)"
                                        />
                                        <div class="overlay" />
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div>
                </template>
            </FlowRow>
        </template>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

import Company from '@/models/Company';
import CompanyMediaCollection from '@/models/CompanyMediaCollection';
import FlowRow from '@/components/flow/FlowRow.vue';
import FlowMixin from '@/components/mixins/FlowMixin.vue';

export default {
    name: 'FlowCompany',
    components: {
        Carousel,
        Slide,
        FlowRow,
    },
    mixins: [FlowMixin],

    shared: {
        session: {
            company: {
                type: Company,
                default: null,
            },
        },
    },

    data() {
        return {
            rowLabel: [],
            globalTimeout: null,
            iMessageIndex: 0,
            companyFlowCopy: {},
            messages: {},
            messagesEnabled: [],
            companyMedias: new CompanyMediaCollection([
                'id',
                'editable',
                'description',
                'image',
                'type',
                'url',
                'company_file_id',
                'is_landing',
            ]).with({
                companyFile: (query) => {
                    query.select(['id', 'original_name', 'uuid', 'path', 'name', 'detected_mime_type']);
                },
            }).where([
                ['is_landing', '=', false],
            ]).orderBy([['order', 'asc']]),
            company: new Company([
                'id',
                'employees',
                'founded_year',
                'mission',
                'about',
                'translations',
            ]).with({
                companyDepartment: (query) => {
                    query.select([
                        'id',
                        'name',
                        'editable',
                        'email',
                        'is_heyteam',
                    ]);
                },
                companyValue: (query) => {
                    query.select([
                        'id',
                        'name',
                        'description',
                        'editable',
                        'is_heyteam',
                        'translations',
                    ]);
                },
            }).where([
                ['id', '=', this.shared.session.company.id],
            ]),
        };
    },

    computed: {
        companyValues() {
            if (!('company_value' in this.company) || this.company.company_value.models.length === 0) {
                return [];
            }
            return this.company.company_value.$.models.filter((i) => i.name);
        },
        getPictures() {
            return this.companyMedias.$.models.filter((item) => ((item.type === 'file' && item.company_file_id !== null) || item.type === 'link'));
        },

        /**
			* Nombre d'années d'existence de l'entreprise
			* @returns {Number}
			*/
        getCountExistence() {
            return this.$Utils.moment().year() - this.company.founded_year;
        },

        getDepartments() {
            const nameDepartments = this.company.company_department.$.models.map(
                (item) => item.name,
            );

            if (nameDepartments.length > 5) {
                const firstsDepartments = nameDepartments.slice(0, 5);

                return `${firstsDepartments.join(', ')}, ...`;
            } return nameDepartments.join(', ');
        },

        isFullyLoaded() {
            return (
                this.company.isLoaded()
                && this.companyMedias.isLoaded()
            );
        },
    },

    async created() {
        this.contents.map((model) => {
            this.companyFlowCopy[model.subtype] = model;

            if (!model.subtype.includes('_title')) {
                this.rowLabel.push(model.subtype);
                this.$set(this.messages, model.subtype, false);
                if (model.is_enabled) this.messagesEnabled.push(model.subtype);
            }
        });

        if (!this.messagesEnabled.length) {
            if (this.is_forward === true) this.$emit('onNextPage');
            else this.$emit('onPreviousPage');
        }

        this.loadRows();
        this.runLoadRows();

        this.company.get();
        this.companyMedias.get();
    },

    methods: {
        isVideo(item) {
            return item && item.company_file && item.company_file.detected_mime_type.startsWith('video');
        },

        getMediaLink(item) {
            let ret = null;
            this.API.get(`company_media/getCompanyMediaFile/${item.id}`, {
                200: (data) => {
                    ret = data.link;
                },
            });

            return ret;
        },

        getLink(item) {
            return this.$Utils.resolveS3PublicImage(item.company_file.path + item.company_file.name);
        },

        runLoadRows() {
            this.globalTimeout = setInterval(() => {
                this.loadRows();
            }, 1500);
        },

        loadRows() {
            while (this.iMessageIndex < this.rowLabel.length && this.companyFlowCopy[this.rowLabel[this.iMessageIndex]].is_enabled === false) {
                this.iMessageIndex++;
            }

            if (this.iMessageIndex < this.rowLabel.length) {
                if (this.companyFlowCopy[this.rowLabel[this.iMessageIndex]].is_enabled) this.messages[this.rowLabel[this.iMessageIndex]] = true;

                if (this.messagesEnabled.indexOf(this.rowLabel[this.iMessageIndex]) === (this.messagesEnabled.length - 1)) this.$emit('onLoadPage', true);

                this.iMessageIndex++;
            } else clearInterval(this.globalTimeout);
        },
    },
};
</script>

<style lang="scss">
@import "~@/styles/var";

.html {
	p {
		margin-bottom: 15px;
	}
}

.no-border  {
	border: none !important
}

.no-margin {
	margin: 0 !important;
}

.VueCarousel {
    .VueCarousel-wrapper {
        width: calc(100% + 12px);
        margin-left: -6px;
    }
}

.VueCarousel-slide {
    text-align: center;
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        border-radius: 9px;
        overflow: hidden;
    }
	div {
		height: 100%;
        border-radius: 9px;
		overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
		svg {
			font-size: 3em;
			color: $grey-light;
		}
    }

    iframe {
        height: 290px;
        border-radius: $border-radius-8;
    }
}

@media (max-width: $tablet) {
    .VueCarousel-navigation-button {
        display: none;
    }
}
</style>
