import Vue from 'vue';
import Model from '@tony.caron/node-model-proxy/Model';
import CompanyProgram from './CompanyProgram';
import CompanyProgramKeyDateCollection from './CompanyProgramKeyDateCollection';
import HtProgramKeyDate from './HtProgramKeyDate';
import HtProgramKeyDateLocale from './HtProgramKeyDateLocale';

export default class CompanyProgramKeyDate extends Model {
    modelConfig() {
        return {
            collection: CompanyProgramKeyDateCollection,
        };
    }

    modelCustomAttributes() {
        return {
            locales_by_key: {},
            name_translated: null,
        };
    }

    modelEvents() {
        return {
            loaded: () => {
                Vue.prototype.$modelUtils.createLocales(this.locales);
            },
        };
    }

    modelRelations() {
        return {
            program: () => this.belongsTo(CompanyProgram, 'company_program_id', 'id'),
            template: () => this.belongsTo(HtProgramKeyDate),
            locales: () => this.hasMany(HtProgramKeyDateLocale, 'ht_program_key_date_id', 'ht_program_key_date_id'),
        };
    }

    modelAccessors() {
        return {
            locales_by_key: () => Vue.prototype.$modelUtils.createLocalesByKey(this.locales),
            name_translated: () => this.localize('name'),
        };
    }

    localize(field, languageKey, defaultLanguageKey) {
        return Vue.prototype.$modelUtils.localize(this.locales_by_key, field, languageKey, defaultLanguageKey);
    }
}
