<template>
    <div>
        <form
            class="form-2 spaced"
            @submit.prevent=""
        >
            <HtFormSwitch
                id="enable-two-factor-auth"
                v-model="dataIsTwoFactorAuthEnabled"
                :label="translate('Enable 2FA')"
                name="enable-two-factor-auth"
                :show-optional="false"
                @input="onSave()"
            />
        </form>
    </div>
</template>

<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import modalMixin from '@/components/globals/modals/modalMixin';

import api from '@/store/api';

export default {
    name: 'Security2FAEdit',

    components: {
        HtFormSwitch,
    },
    mixins: [
        modalMixin,
    ],
    inject: ['modal'],
    props: {
        isTwoFactorAuthEnabled: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            dataIsTwoFactorAuthEnabled: this.isTwoFactorAuthEnabled,
            buttonState: 'idle',
        };
    },

    methods: {
        async onSave() {
            try {
                this.buttonState = 'loading';

                if (!this.dataIsTwoFactorAuthEnabled) {
                    this.genericConfirm(
                        this.translate('Disable dual authentication'),
                        `
                    ${this.translate('Deactivating this feature allows your employees to access the platform via simple authentication (i.e. email/password), which is not at all recommended by the CNIL.')} <br>
                    ${this.translate('Are you sure you want to disable dual authentication?')}
                    `,
                        null,
                        this.translate('Desactivate'),
                        this.translate('Cancel'),
                    ).then(async () => {
                        await api.settings.securityAndPrivacy.twoFactorAuth.toggle().then(() => {
                            this.$emit('updated:isTwoFactorAuthEnabled', this.dataIsTwoFactorAuthEnabled);
                        });
                    }).catch(() => {
                        this.dataIsTwoFactorAuthEnabled = true;
                    });
                } else {
                    this.genericConfirm(
                        this.translate('Enable dual authentication'),
                        `
                    ${this.translate('Activating this feature requires your employees to use dual authentication (i.e. email/password and an additional method), which is highly recommended by the CNIL.')} <br>
                    ${this.translate('Are you sure you want to enable dual authentication?')}
                    `,
                        null,
                        this.translate('Activate'),
                        this.translate('Cancel'),
                    ).then(async () => {
                        await api.settings.securityAndPrivacy.twoFactorAuth.toggle().then(() => {
                            this.$emit('updated:isTwoFactorAuthEnabled', this.dataIsTwoFactorAuthEnabled);
                        });
                    }).catch(() => {
                        this.dataIsTwoFactorAuthEnabled = false;
                    });
                }
            } finally {
                this.buttonState = 'idle';
            }
        },
    },
};
</script>
