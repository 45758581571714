<template>
    <div>
        <template v-if="areSoftwaresLoading">
            <form
                class="form-2 spaced"
                data-cy="software-add-form"
            >
                <div v-if="filteredSoftware.length">
                    <fieldset>
                        <HtFormSelect
                            id="software"
                            v-validate.disable="'required'"
                            :value="selectedOption"
                            :label="translate('Name')"
                            name="software"
                            cypress="software"
                            :data-vv-as="translate('e-mail')"
                            :options="softwareOptions"
                            @input="onSelectedSoftware(...arguments)"
                        />
                    </fieldset>

                    <div v-if="selectedOption">
                        <HtKeyDatesInputOrder
                            :end-label="translate('Installation date')"
                            :user-program-id="companyUserProgramId"
                            :is-business-day.sync="userTask.is_business_day"
                            :offset-key-date.sync="userTask.company_user_program_key_date_id"
                            :offset-availability-specific-date.sync="userTask.datetime_availability"
                            :offset-availability-number.sync="userTask.offset_availability_number"
                            :offset-availability-unit.sync="userTask.offset_availability_unit"
                            :offset-end-specific-date.sync="userTask.datetime_end"
                            :offset-end-number.sync="userTask.offset_end_number"
                            :offset-end-unit.sync="userTask.offset_end_unit"
                            can-select-specific-date
                        />

                        <div class="modal-actions right">
                            <Button
                                :state="buttonState"
                                cypress="software-add-button"
                                @click="onValidate()"
                            >
                                {{ translate('Add') }}
                            </Button>
                        </div>
                    </div>
                </div>

                <div
                    v-else
                    class="empty-component"
                >
                    <strong><t>All available software already selected !</t></strong>
                </div>
            </form>
        </template>

        <template v-else>
            <IconLoading />
        </template>
    </div>
</template>

<script>
import api from '@/store/api';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import CompanyUserSoftwareCollection from '@/models/CompanyUserSoftwareCollection';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import TaskDependencyMixin from '@/mixins/TaskDependencyMixin';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';

export default {
    name: 'UserSoftwareSelect',
    components: {
        HtFormSelect,
        HtKeyDatesInputOrder,
    },

    mixins: [
        TaskDependencyMixin,
    ],

    inject: ['modal'],

    permissions: [
        'ModelCompanyUserSoftware',
    ],

    props: {
        selectedIds: {
            type: Array,
            default: () => [],
        },
        companyUserId: {
            type: [Number, String],
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
    },

    data() {
        return {
            userTask: null,
            companyUserProgram: new CompanyUserProgram([
                'id',
            ]).with({
                mainKeyDate: (query) => {
                    query.select(['id', 'starts_at']);
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname']);
                },
                companyProgram: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            keyDate: (query) => {
                                query.with({
                                    locales: (query) => query
                                        .select(['id', 'name', 'language_key']),
                                });
                            },
                        });
                },
            }).where([
                ['id', '=', this.companyUserProgramId],
            ]),
            softwares: [],
            areSoftwaresLoading: false,
            companyUserSoftware: new CompanyUserSoftwareCollection([
                'id',
                'company_user_id',
                'company_user_program_id',
                'company_software_id',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'resource',
                        'resource_id',
                        'status',
                        'is_business_day',
                        'company_user_program_key_date_id',
                        'offset_availability_number',
                        'offset_availability_unit',
                        'offset_end_number',
                        'offset_end_unit',
                        'datetime_availability',
                        'datetime_end',
                    ]);
                },
            }).where([
                ['company_user_id', '=', this.companyUserId],
            ]),
            selectedOption: null,
            item: null,
            buttonState: 'idle',
            fetchedSelectedIds: [],
        };
    },

    computed: {
        filteredSoftware() {
            return this.softwares.filter((software) => {
                if (this.selectedIds.includes(software.id)) {
                    return false;
                }

                return !this.fetchedSelectedIds.includes(software.id);
            });
        },

        softwareOptions() {
            return this.filteredSoftware.map((software) => ({
                id: software,
                name: software.resource.name,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
    },

    watch: {
        'item._state.isSaving': function (value) {
            this.buttonState = value ? 'loading' : 'idle';
        },
    },

    async created() {
        this.companyUserProgram.get();

        const softwares = (await api.configuration.softwares.getAll()).data.data;
        this.areSoftwaresLoading = true;
        this.softwares = softwares;

        this.item = this.companyUserSoftware.new();
        this.item.company_user_id = this.companyUserId;
        this.item.company_user_program_id = this.companyUserProgramId;
        this.createTaskWithDependents(this.item);
        this.userTask = this.item.company_user_program_task;

        if (!this.selectedIds.length) this.loadSelectedUserResourceIds();

        this.modal.setTitle(this.translate('Add software'));
    },

    methods: {
        onSelectedSoftware(value) {
            this.selectedOption = value;

            const firstResourceProgram = this.selectedOption.resource.programs[0] || {
                offset_availability_number: 0,
                offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                offset_end_number: 0,
                offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                is_business_day: true,
            };

            this.item.company_software_id = this.selectedOption.id;
            this.item.company_user_program_task.is_business_day = firstResourceProgram.is_business_day;
            this.item.company_user_program_task.offset_availability_number = firstResourceProgram.offset_availability_number;
            this.item.company_user_program_task.offset_availability_unit = firstResourceProgram.offset_availability_unit;
            this.item.company_user_program_task.offset_end_number = firstResourceProgram.offset_end_number;
            this.item.company_user_program_task.offset_end_unit = firstResourceProgram.offset_end_unit;
            this.item.type = this.selectedOption.type.resource;
        },

        // THIS IS NEEDED FOR ADD ELEMENT MODAL
        async loadSelectedUserResourceIds() {
            this.companyUserSoftware.company_user_id = this.companyUserId;
            await this.companyUserSoftware.get();

            this.fetchedSelectedIds = this.companyUserSoftware.$.models.map((item) => item.company_software_id);
        },

        async onValidate() {
            await this.$validator.validateAll();

            if (this.errors.any()) return;

            await this.item.save();
            this.modal.close();
            this.$emit('onUpdate');
        },
    },
};
</script>
