<template>
    <div
        v-if="variables.length > 0"
        class="ht-form-variables mt-3"
    >
        <div
            class="ht-form-variables-item"
            :tabindex="0"
            :data-cy="cypress"
            @click="onShowList(!showList)"
            @blur="onShowList(false)"
        >
            <div class="ht-form-variables-item-title">
                <span>{{ getTitleSelect }}</span>
            </div>
            <div>
                <FontAwesomeIcon
                    size="xs"
                    :icon="getIconChevron"
                />
            </div>
        </div>
        <div
            v-show="showList === true"
            class="ht-form-variables-list"
        >
            <div
                v-for="(variable, index) in variables"
                :key="index"
                class="ht-form-variables-list-item"
                :data-cy="cypress + '-' + variable.name"
                @mousedown="addVariable(variable.name)"
            >
                <div>
                    <span>{{ translate(variable.label) }}</span>
                </div>
                <div class="ht-form-variables-list-item-right">
                    <span>{{ variable.name }}</span>
                    <FontAwesomeIcon
                        size="1x"
                        :icon="['far', 'clone']"
                        class="ht-form-variables-list-item-right-icon"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HtFormVariables',
    props: {
        variables: {
            type: Array,
            default: () => [],
        },
        cypress: {
            type: String,
        },
    },

    data() {
        return {
            showList: false,
        };
    },

    computed: {
        getTitleSelect() {
            return `</> ${this.translate('Insert variable')}`;
        },

        getIconChevron() {
            return (this.showList === true) ? 'chevron-up' : 'chevron-down';
        },
    },

    methods: {
        onShowList(isShowList) {
            this.showList = isShowList;
        },

        addVariable(variable) {
            this.showList = false;
            this.$emit('addVariable', `{${variable}}`);
        },
    },
};
</script>

<style lang="scss" src="./HtFormVariables.scss"></style>
