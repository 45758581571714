import { render, staticRenderFns } from "./RequirementCard.vue?vue&type=template&id=a08f77b2&scoped=true&"
import script from "./RequirementCard.vue?vue&type=script&lang=js&"
export * from "./RequirementCard.vue?vue&type=script&lang=js&"
import style0 from "./RequirementCard.scss?vue&type=style&index=0&id=a08f77b2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a08f77b2",
  null
  
)

export default component.exports