import CompanyRole from '@/models/CompanyRole';
import CompanyGroup from '@/models/CompanyGroup';

import DepartureTemplateProvider from '@/router/pages/programs/ProgramTemlates/DepartureTemplateProvider';
import OnboardingTemplateProvider from '@/router/pages/programs/ProgramTemlates/OnboardingTemplateProvider';
import TalentTemplateProvider from '@/router/pages/programs/ProgramTemlates/TalentTemplateProvider';
import CrossboardingTemplateProvider from '@/router/pages/programs/ProgramTemlates/CrossboardingTemplateProvider';
import ParentleaveTemplateProvider from '@/router/pages/programs/ProgramTemlates/ParentleaveTemplateProvider';
import ParentreturnTemplateProvider from '@/router/pages/programs/ProgramTemlates/ParentreturnTemplateProvider';
import NewManagerTemplateProvider from '@/router/pages/programs/ProgramTemlates/NewManagerTemplateProvider';

/**
 * @param {HtProgramType[]} programTypes
 * @param {HtProgramKeyDate[]} keyDates
 * @param {CompanyRole[]} roles
 * @param {CompanyGroup[]} groups
 * @returns
 */
export default (
    programTypes,
    keyDates,
    roles,
    groups,
) => {
    /** @type {number} */
    const managerRoleId = roles.find(
        (role) => role.name === CompanyRole.NAME_MANAGER,
    )?.id ?? null;

    /** @type {number} */
    const employeeRoleId = roles.find(
        (role) => role.name === CompanyRole.NAME_EMPLOYEE,
    )?.id ?? null;

    /** @type {number} */
    const administrativeResponsibleRoleId = roles.find(
        (role) => role.name === CompanyRole.NAME_ADMINISTRATIVE_MANAGER,
    )?.id ?? null;

    /** @type {number} */
    const employeeDepartmentGroupId = groups.find(
        (group) => group.name === CompanyGroup.NAME_EMPLOYEE_DEPARTMENT,
    ).id;

    const roleMap = {
        [CompanyRole.NAME_EMPLOYEE]: employeeRoleId,
        [CompanyRole.NAME_MANAGER]: managerRoleId,
        [CompanyRole.NAME_ADMINISTRATIVE_MANAGER]: administrativeResponsibleRoleId,
    };

    return [
        (new DepartureTemplateProvider(programTypes, keyDates, roleMap, employeeDepartmentGroupId)).build(),
        (new OnboardingTemplateProvider(programTypes, keyDates, roleMap, employeeDepartmentGroupId)).build(),
        (new TalentTemplateProvider(programTypes, keyDates, roleMap, employeeDepartmentGroupId)).build(),
        (new CrossboardingTemplateProvider(programTypes, keyDates, roleMap, employeeDepartmentGroupId)).build(),
        (new ParentleaveTemplateProvider(programTypes, keyDates, roleMap, employeeDepartmentGroupId)).build(),
        (new ParentreturnTemplateProvider(programTypes, keyDates, roleMap, employeeDepartmentGroupId)).build(),
        (new NewManagerTemplateProvider(programTypes, keyDates, roleMap, employeeDepartmentGroupId)).build(),
    ];
};
