<template>
    <div>
        <transition leave-active-class="leave-fade-dashboard">
            <ListActionsItemHtml
                v-show="displayTask"
                :title="action.company_user_program_task.title"
                :status="status"
                :is-late="isLate"
                :date="date"
                :participants="participants"
                :is-togglable="isTogglable"
                :can-validate="canValidate"
                :show-multi-select="showMultiSelect"
                :is-selectable="isSelectable"
                :is-selected="isSelected"
                :has-dependent="hasDependent"
                :is-clickable="isClickable"
                :validator-type="action.company_user_program_task.validator_type"
                :is-history="isHistory"
                @onSelectedAction="onSelectedAction"
                @onToggle="onToggle"
                @onClick="onClick"
            >
                <template #middle-content>
                    <HtTag
                        v-if="action.company_user_program_task.resource === 'company_planning' && action.company_user_program_task.company_planning_event.is_delegated"
                        class="middle-content-tag"
                        color="purple"
                    >
                        <FontAwesomeIcon
                            class="middle-content-tag-icon"
                            :icon="['fad', 'calendar-alt']"
                        />
                        <t>To schedule</t>
                    </HtTag>
                    <HtTag
                        v-else-if="action.company_user_program_task.tag"
                        class="middle-content-tag"
                        color="purple"
                    >
                        <t>{{ action.company_user_program_task.tag }}</t>
                    </HtTag>
                </template>
                <template slot="file">
                    <template v-if="action.company_user_program_task.resource === 'company_document' && !hasDependent">
                        <UserTrainingVideo
                            v-if="isVideo"
                            class="mt-10 mb-10"
                            :company_user_document_id="action.company_user_program_task.resource_id"
                        />
                    </template>
                    <template v-if="action.company_user_program_task.resource === 'company_doc' && !hasDependent">
                        <div class="mb-10 mt-10">
                            <div
                                class="bloc-download"
                                @click.stop="togglePreviewer"
                            >
                                <img
                                    src="/static/images/flow/small/file.svg"
                                    class="mr-10"
                                >
                                <span>{{ action.company_user_program_task.company_user_doc.company_file.original_name }}</span>
                            </div>
                        </div>
                        <FilePreviewer
                            ref="file-previewer"
                            :files="[action.company_user_program_task.company_user_doc.company_file]"
                            @on-close="closePreviewer"
                        />
                    </template>
                </template>
                <template
                    v-if="!isYourself"
                    slot="subtitle"
                >
                    <div class="onboardee-label align-items-center">
                        <t :program-name="action.company_user_program_task.company_user_program.company_program.name_translated">
                            Linked to the program "{ program-name }" of
                        </t><strong>{{ fullname }}</strong>
                    </div>
                </template>
                <template #dependency>
                    <div
                        v-for="(task, index) in action.dependenciesTasks"
                        :key="index"
                    >
                        <ListActionsByActionItem
                            :key="index"
                            :action="task"
                            :selected-action-list="selectedActionList"
                            :show-multi-select="showMultiSelect"
                            :is-history="isHistory"
                        />
                    </div>
                </template>
            </ListActionsItemHtml>
        </transition>
        <UserWrapperModal
            ref="userWrapperModal"
            :size="getSize"
            :is-fill="true"
            :is-edit="isEdit"
            @onUpdate="onUpdate"
            @onDelete="onDelete"
        />
    </div>
</template>

<script>
import UserWrapperModal from '@/components/miscellaneous/UserWrapperModal.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import UserTrainingVideo from '@/components/resources/training/UserTrainingVideo.vue';
import FilePreviewer from '@/components/globals/FilePreviewer/FilePreviewer.vue';
import ListActionsItemHtml from '@/components/pages/dashboard/actions/ListActionsItemHtml.vue';

export default {
    name: 'ListActionsByActionItem',
    components: {
        UserWrapperModal,
        UserTrainingVideo,
        ListActionsItemHtml,
        ListActionsByActionItem: () => import('@/components/pages/dashboard/listTasks/ByAction/ListActionsByActionItem.vue'),
        FilePreviewer,
    },
    props: {
        action: {
            type: Object,
            required: true,
        },
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        selectedActionList: {
            type: Array,
            required: true,
        },
        isHistory: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            openPreview: false,
            resourceId: this.action.company_user_program_task.resource === 'custom'
                ? this.action.company_user_program_task.id
                : this.action.company_user_program_task.resource_id,
        };
    },

    computed: {
        hasDependent() {
            return Boolean(this.action.dependentId);
        },

        isVideo() {
            return this.action.company_user_program_task.company_user_document.company_file
                && this.action.company_user_program_task.company_user_document.company_file.detected_mime_type
                && this.action.company_user_program_task.company_user_document.company_file.detected_mime_type.startsWith('video');
        },

        displayTask() {
            const task = this.action.company_user_program_task;
            return this.isHistory
                ? task.status === CompanyUserProgramTask.STATUS_DONE
                : task.status === CompanyUserProgramTask.STATUS_PENDING;
        },
        status() {
            return this.action.company_user_program_task.status;
        },

        isLate() {
            return this.$Utils.moment(this.date).isBefore(this.$Utils.moment(), 'day')
                && this.action.company_user_program_task.resource !== 'company_planning';
        },

        canValidate() {
            const availableResources = [
                'custom',
                'company_doc',
                'company_document',
                'company_software',
                'company_equipment',
                'company_nudge',
            ];

            return availableResources.includes(this.action.company_user_program_task.resource);
        },

        isTogglable() {
            const { resource } = this.action.company_user_program_task;

            const event = this.action.company_user_program_task.company_planning_event;

            if ((resource === 'company_software' && this.action.validator_type === 'system')
                || (resource === 'company_planning' && event.is_delegated === true)) return false;

            return true;
        },

        participants() {
            if (this.action.company_user_program_task.resource === 'company_planning') {
                return this.action.company_user_program_task.company_planning_event.company_planning_event_attendee.models;
            }

            return this.action.company_user_program_task.company_user_program_task_participant.models.length > 1
                ? this.action.company_user_program_task.company_user_program_task_participant.models
                : [];
        },

        date() {
            return this.action.company_user_program_task.datetime_end;
        },

        getSize() {
            let size = null;

            switch (this.action.company_user_program_task.resource) {
            case 'company_planning':
            case 'company_document':
            case 'company_doc':
            case 'company_software':
            case 'company_equipment':
            case 'company_requirement_category':
            case 'custom':
                size = 'small';
                break;
            case 'company_user_quiz':
                size = 'large';
                break;
            default:
                size = 'medium';
            }

            return size;
        },

        isSelectable() {
            const { resource } = this.action.company_user_program_task;
            const event = this.action.company_user_program_task.company_planning_event;

            if (resource === 'company_planning' && event.is_delegated === true) return false;
            return resource !== 'company_software' && resource !== 'company_survey' && resource !== 'company_requirement_category';
        },

        isSelected() {
            return this.selectedActionList.includes(this.action.company_user_program_task.id);
        },

        fullname() {
            return `${this.action.company_user_program_task.company_user.firstname} ${this.action.company_user_program_task.company_user.lastname}`;
        },

        isYourself() {
            return this.shared.session.companyUser.id === this.action.company_user_program_task.company_user_id;
        },

        isEdit() {
            const { resource } = this.action.company_user_program_task;
            return resource === 'company_requirement_category';
        },

        isClickable() {
            return !this.openPreview && !this.hasDependent;
        },
    },

    methods: {
        togglePreviewer() {
            this.$refs['file-previewer'].togglePreviewer();
            this.openPreview = this.$refs['file-previewer'].openPreview;
        },

        closePreviewer() {
            this.openPreview = false;
        },

        onToggle(status) {
            this.action.company_user_program_task.status = status ? 'done' : 'pending';

            const currentTask = new CompanyUserProgramTask([
                'id',
                'status',
            ]);
            currentTask.fill(this.action.company_user_program_task);

            this.$handleValidationTasks(currentTask)
                .then(() => {
                    this.shared.dashboard.statusIncrementTask++;
                    this.$store.dispatch('dashboard/statistics/updateTodoCount', -1);
                });
        },

        onClick() {
            if (!this.showMultiSelect) {
                this.$refs.userWrapperModal.open(this.action.company_user_program_task);
            }
        },

        onSelectedAction() {
            if (this.selectedActionList.includes(this.action.company_user_program_task.id)) {
                this.selectedActionList.splice(this.selectedActionList.indexOf(this.action.company_user_program_task.id), 1);
            } else {
                this.selectedActionList.push(this.action.company_user_program_task.id);
            }
        },

        onUpdate() {
            this.shared.dashboard.statusIncrementTask++;
        },

        onDelete() {
            this.shared.dashboard.removeIncrementTask++;
        },
    },
};
</script>

<style lang="scss" src="./ListActionsByActionItem.scss" scoped></style>
