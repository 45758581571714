<template>
    <div />
</template>

<script>

export default {
    name: 'PocArea',
};
</script>

<style lang="scss" scoped>
</style>
