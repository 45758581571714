<template>
    <div class="ht-entities-filters">
        <template v-if="hasFilters">
            <HtChip
                v-for="filter in showedFilters"
                :label="filter.name_translated"
            />

            <VPopover
                v-if="hasHiddenFilters"
                trigger="hover"
            >
                <HtChip>
                    <span class="hidden-filters-count">{{ hiddenFilters.length }}</span>

                    <t :count="hiddenFilters.length">
                        filter | filters
                    </t>
                </HtChip>

                <template #popover>
                    <HtChip
                        v-for="filter in hiddenFilters"
                        :label="filter.name_translated"
                    />
                </template>
            </VPopover>
        </template>

        <HtChip
            v-else
            :label="translate('All filters')"
        />
    </div>
</template>

<script>
import { VPopover } from 'v-tooltip';
import HtChip from '@/components/globals/HtChip.vue';

export default {
    name: 'HtEntitiesFiltersChips',
    components: {
        HtChip,
        VPopover,
    },
    props: {
        filters: {
            type: Array,
            required: true,
        },

        maxFiltersCount: {
            type: Number,
            default: 3,
        },
    },

    computed: {
        showedFilters() {
            return this.filters.slice(0, this.maxFiltersCount);
        },

        hasFilters() {
            return this.showedFilters.length > 0;
        },

        hiddenFilters() {
            return this.filters.slice(this.maxFiltersCount);
        },

        hasHiddenFilters() {
            return this.hiddenFilters.length > 0;
        },
    },
};
</script>

<style scoped>
.ht-entities-filters {
    display: flex;
}

.hidden-filters-count {
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    border-radius: 50%;
    min-width: 16px;
    justify-content: center;
    margin-right: 5px;
    font-size: 1rem;
}
</style>
