import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanySoftware from './CompanySoftware';

export default class CompanySoftwareCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanySoftware,
        };
    }

    modelCustomAttributes() {
        return {
            filters: {},
        };
    }
}
