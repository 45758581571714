<template>
    <StepperTabLayout>
        <template #main>
            <HtFormCard>
                <HtFormSelect
                    :id="'program'"
                    v-model="form.program_id"
                    :name="'program'"
                    :show-optional="false"
                    :label="translate('Program concerned')"
                    :options="programs"
                />
                <HtFormSelect
                    :id="'period'"
                    v-model="form.date_period"
                    :name="'period'"
                    :show-optional="false"
                    :label="translate('Select a period')"
                    :options="dates"
                    :description="translate('The period is calculated from the employee\'s key date.')"
                />
                <div v-show="showSpecificPeriod">
                    <div class="row">
                        <div class="col-md-6">
                            <HtFormFlatPickr
                                :id="'date_start'"
                                v-model="form.date_start"
                                :name="'date_start'"
                                :label="translate('Start date')"
                                :show-optional="false"
                                :max-date="form.date_end"
                                :date-format="'Y-m-d'"
                                :is-working-day="false"
                            />
                        </div>
                        <div class="col-md-6">
                            <HtFormFlatPickr
                                :id="'date_end'"
                                v-model="form.date_end"
                                :name="'date_end'"
                                :label="translate('End date')"
                                :show-optional="false"
                                :min-date="form.date_start"
                                :date-format="'Y-m-d'"
                                :is-working-day="false"
                            />
                        </div>
                    </div>
                </div>
                <HtFormSwitch
                    :id="'show_filters'"
                    v-model="form.show_filters"
                    :name="'show_filters'"
                    :show-optional="false"
                    :label="translate('Apply filters')"
                    :class="'mb-0'"
                />
                <div v-show="showFilters">
                    <HtFormFilters
                        v-model="form.filters"
                        :class="'mt-3 mb-0'"
                    />
                </div>
                <HtFormSwitch
                    :id="'show_resources'"
                    v-model="specificForm.show_resources"
                    :name="'show_resources'"
                    :show-optional="false"
                    :label="translate('Choose the type of resources')"
                    :class="'mb-0 mt-5'"
                />
                <div v-show="specificForm.show_resources">
                    <HtFormCheckbox
                        :id="'resources'"
                        v-model="specificForm.resources"
                        :name="'resources'"
                        :options="resources"
                        :show-optional="false"
                        :class="'mt-3 mb-0'"
                    />
                </div>
                <HtFormRadio
                    :id="'format'"
                    v-model="form.format"
                    :name="'format'"
                    :show-optional="false"
                    :label="translate('Export type')"
                    :options="types"
                    :class="'mt-5 mb-0'"
                />
            </HtFormCard>
        </template>
        <template #buttonsFooter>
            <Button
                :class="buttonState"
                @click="download"
            >
                <t>Download</t>
            </Button>
        </template>
    </StepperTabLayout>
</template>

<script>
import HtFormRadio from '@/components/globals/HtFormRadio.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtFormFilters from '@/components/globals/HtFormFilters.vue';
import ExportMixin from '@/components/pages/settings/dataAnalysis/mixins/ExportMixin';
import api from '@/store/api';

export default {
    name: 'ExportActionsForm',
    components: {
        HtFormRadio,
        HtFormSelect,
        HtFormSwitch,
        HtFormFilters,
    },
    mixins: [
        ExportMixin,
    ],

    data() {
        return {
            specificForm: {
                show_resources: false,
                resources: [],
            },
        };
    },

    computed: {
        resources() {
            return this.$store.getters['config/getResources'].map((resource) => ({
                value: resource.key,
                text: this.translate(resource.plurial_name),
            }));
        },
    },

    methods: {
        download() {
            const data = { ...this.form, ...this.specificForm };

            this.send(api.miscellaneous.exportActions, data);
        },
    },
};
</script>
