<template>
    <div>
        <button
            v-if="value === 'builder'"
            class="btn"
            @click.prevent="setMode('ai')"
        >
            <t>Switch to IA Mode</t>
        </button>
        <button
            v-if="value === 'ai'"
            class="btn"
            @click.prevent="setMode('builder')"
        >
            <t>Switch to Builder Mode</t>
        </button>
    </div>
</template>

<script>
export default {
    name: 'AIModeSwitcher',
    props: {
        value: {
            type: String,
            default: null,
        },
    },
    methods: {
        setMode(mode) {
            this.$emit('input', mode);
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
