<template>
    <div class="ht-list">
        <div
            v-show="hasHeaderTitleSlot || hasHeaderRightSlot"
            :class="'ht-list-header'"
        >
            <div
                v-show="hasHeaderTitleSlot"
                class="ht-list-header-title"
            >
                <slot name="header-title" />
            </div>
            <div class="ht-list-header-right">
                <div v-show="hasHeaderRightSlot">
                    <slot name="header-right" />
                </div>
            </div>
        </div>
        <div class="ht-list-subtitle">
            <slot name="header-subtitle" />
        </div>
        <div
            v-show="items.length > 0"
            class="ht-list-wrapper"
        >
            <transition-group name="slide">
                <div
                    v-for="(item, index) in items"
                    :key="index"
                    class="ht-list-wrapper-item"
                >
                    <div>
                        <slot
                            :name="'items-left'"
                            :item="item"
                        />
                    </div>
                    <div>
                        <slot
                            :name="'items-right'"
                            :item="item"
                        />
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>
<script>
export default {
    name: 'HtList',
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        hasHeaderTitleSlot() {
            return 'header-title' in this.$slots;
        },

        hasHeaderRightSlot() {
            return 'header-right' in this.$slots;
        },
    },
};
</script>
<style lang="scss" src="./HtList.scss"></style>
