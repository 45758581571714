<template>
    <div
        v-if="showUpdates"
        class="clickable"
        @click="reload"
    >
        <HtToast
            type="info"
            :title="toastTitle"
            :message="toastMessage"
        />
    </div>
</template>
<script>
import HtToast from '@/components/globals/HtToast.vue';

export default {
    name: 'NewVersionAvailable',
    components: {
        HtToast,
    },

    data() {
        return {
            showUpdates: false,
        };
    },

    computed: {
        toastTitle() {
            return this.translate('A new version is available');
        },
        toastMessage() {
            return this.translate('Reload the page to be up to date.');
        },
    },

    created() {
        if (this.$env.get('APP_ENV') !== 'local') {
            const intervalId = setInterval(() => {
                this.$http.get(`${window.location.origin}/.env.js`).then((res) => {
                    const attribut = 'VERSION';

                    const versionIndex = res.data.indexOf(attribut);

                    if (versionIndex === -1) {
                        clearInterval(intervalId);
                    }

                    const beginIndex = versionIndex + res.data.substring(versionIndex).indexOf(':');
                    const endIndex = versionIndex + res.data.substring(versionIndex).indexOf(',');

                    const extractedVersion = res.data.substring(beginIndex + 1, endIndex).replace(/['"]+/g, '');
                    const newVersion = parseInt(extractedVersion.trim(), 10);
                    const currentVersion = parseInt(this.$env.get('VERSION'), 10);

                    if (!Number.isNaN(newVersion) && newVersion > currentVersion) {
                        this.showUpdates = true;
                        clearInterval(intervalId);
                    }
                });
            }, 60000);
        }
    },

    methods: {
        reload() {
            this.$router.go();
        },
    },
};
</script>
