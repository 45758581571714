<template>
    <div class="page-content">
        <form
            class="form-2 spaced"
            @submit.prevent=""
        >
            <div class="preboarding-section-title">
                <InputField
                    v-model="collectionCopy['bloc_welcome_title']['content']['messages']['message_1'][`message_${lang}`]"
                    :label="translate('Section title')"
                    :type="'text'"
                    :name="'section_title'"
                    :validate="'required'"
                    :validate-as="translate('section title')"
                />
            </div>

            <div class="preboarding-container-text">
                <HtFormSwitch
                    :id="'language'"
                    v-model="collectionCopy['bloc_welcome_language']['is_enabled']"
                    :label="`${translate('Choose language')}`"
                    :disabled="!collectionCopy['bloc_welcome_language']['is_enabled_editabled']"
                    :name="'language'"
                    :show-optional="false"
                />
                <RichEditable
                    key="1"
                    ref="bloc_welcome_language"
                    v-model="collectionCopy['bloc_welcome_language']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="1"
                    :validate="collectionCopy['bloc_welcome_language']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('Choose language message')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    :resource-name="'preboarding'"
                    :in-high-light-white-list="inHighLightWhiteList"
                    class="margin-bottom"
                />
            </div>

            <div class="preboarding-container-text">
                <HtFormSwitch
                    :id="'first-message'"
                    v-model="collectionCopy['bloc_welcome_a']['is_enabled']"
                    :label="translate('First message')"
                    :disabled="!collectionCopy['bloc_welcome_a']['is_enabled_editabled']"
                    :validate="collectionCopy['bloc_welcome_a']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('First message')"
                    :name="'first-message'"
                    :show-optional="false"
                />
                <RichEditable
                    key="0"
                    ref="bloc_welcome_a"
                    v-model="collectionCopy['bloc_welcome_a']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="0"
                    :validate="collectionCopy['bloc_welcome_a']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('First message')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    :resource-name="'preboarding'"
                    :in-high-light-white-list="inHighLightWhiteList"
                    class="margin-bottom"
                />

                <template v-if="isChatbotFull">
                    <template v-if="companyMediaCollection.isLoaded() && companyMediaCollection.$.models.length && currentMedia">
                        <div
                            v-if="currentMedia.type === 'link'"
                            class="video-wrapper"
                        >
                            <iframe :src="$Utils.embedVideo(currentMedia.url)" />
                        </div>
                        <video
                            v-else
                            controls
                            controlsList="nodownload"
                            width="100%"
                            @click.stop
                        >
                            <source :src="getLink()">
                        </video>
                    </template>
                    <div
                        v-else
                        class="first-message-container-img margin-bottom"
                    >
                        <img src="/static/images/good-job2.png">
                    </div>
                </template>
            </div>
            <div class="preboarding-container-text">
                <HtFormSwitch
                    :id="'second-message'"
                    v-model="collectionCopy['bloc_welcome_c']['is_enabled']"
                    :label="`${translate('Second message')}`"
                    :disabled="!collectionCopy['bloc_welcome_c']['is_enabled_editabled']"
                    :name="'second-message'"
                    :show-optional="false"
                />
                <RichEditable
                    key="1"
                    ref="bloc_welcome_b"
                    v-model="collectionCopy['bloc_welcome_c']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="1"
                    :validate="collectionCopy['bloc_welcome_c']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('Second message')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    :resource-name="'preboarding'"
                    :in-high-light-white-list="inHighLightWhiteList"
                    class="margin-bottom"
                />
            </div>
            <div class="preboarding-container-text">
                <HtFormSwitch
                    :id="'third-message'"
                    v-model="collectionCopy['bloc_welcome_d']['is_enabled']"
                    :label="`${translate('Third message')}`"
                    :disabled="!collectionCopy['bloc_welcome_d']['is_enabled_editabled']"
                    :name="'third-message'"
                    :show-optional="false"
                />
                <RichEditable
                    key="2"
                    ref="bloc_welcome_b"
                    v-model="collectionCopy['bloc_welcome_d']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="2"
                    :validate="collectionCopy['bloc_welcome_d']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('Third message')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    resource-name="preboarding"
                    :in-high-light-white-list="inHighLightWhiteList"
                    class="margin-bottom"
                />
                <div
                    v-for="item in blocks.bloc_welcome_d"
                    :key="`bloc_welcome_d_message_${item.key}`"
                    class="preboarding-inline"
                >
                    <div class="square-img">
                        <img :src="item.img">
                    </div>
                    <InputField
                        v-model="collectionCopy['bloc_welcome_d']['content']['messages'][`message_${item.key}`][`message_${lang}`]"
                        type="text"
                        :name="`bloc_welcome_d_message_${item.key}`"
                        :validate="collectionCopy['bloc_welcome_d']['is_enabled'] ? 'required' : ''"
                        :validate-as="translate('message')"
                    />
                </div>
            </div>
            <div class="preboarding-container-text">
                <HtFormSwitch
                    :id="'fourth-message'"
                    v-model="collectionCopy['bloc_welcome_e'][`is_enabled`]"
                    :label="translate('Fourth message')"
                    :disabled="!collectionCopy['bloc_welcome_e']['is_enabled_editabled']"
                    :name="'fourth-message'"
                    :show-optional="false"
                />
                <RichEditable
                    key="3"
                    ref="bloc_welcome_d"
                    v-model="collectionCopy['bloc_welcome_e']['content']['messages']['message_1'][`message_${lang}`]"
                    :p-key="3"
                    :validate="collectionCopy['bloc_welcome_e']['is_enabled'] ? 'required' : ''"
                    :validate-as="translate('Fourth message')"
                    :has-toolbar="false"
                    :has-background="false"
                    :is-auto="true"
                    :resource-name="'preboarding'"
                    :in-high-light-white-list="inHighLightWhiteList"
                    class="margin-bottom"
                />
            </div>
        </form>
    </div>
</template>

<script>
import CompanyMediaCollection from '@/models/CompanyMediaCollection';
import CompanyFlowCollection from '@/models/CompanyFlowCollection';
import FlowHelper from '@/components/mixins/FlowHelper.vue';
import FlowVariable from '@/components/mixins/FlowVariable.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import InputField from '@/components/globals/InputField.vue';
import RichEditable from '@/components/globals/RichEditable.vue';
import CompanyChatbot from '@/models/CompanyChatbot';

export default {
    name: 'CustomHello',
    components: {
        HtFormSwitch, InputField, RichEditable,
    },
    mixins: [FlowHelper, FlowVariable],
    props: {
        chatbotSlug: {
            type: String,
            required: true,
        },
        /**
         * used in flowHelper mixin
         */
        collection: {
            type: CompanyFlowCollection,
            required: true,
        },

        lang: {
            type: String,
            default: 'fr',
        },
        maxStep: {
            type: Number,
            default: 6,
        },
        step: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            blocks: {
                bloc_welcome_d: [
                    {
                        key: 2,
                        img: '/static/images/flow/user.svg',
                    },
                    {
                        key: 3,
                        img: '/static/images/flow/book.svg',
                    },
                    {
                        key: 4,
                        img: '/static/images/flow/landscape.svg',
                    },
                    {
                        key: 5,
                        img: '/static/images/flow/files.svg',
                    },
                ],
            },
            src: null,
            currentMedia: null,
            companyMediaCollection: new CompanyMediaCollection([
                'id',
                'is_landing',
                'url',
                'type',
            ]).with({
                companyFile: (query) => {
                    query.select([
                        'id',
                        'original_name',
                        'path',
                        'name',
                        'uuid',
                        'detected_extension',
                        'detected_mime_type',
                    ]);
                },
            }).where([['is_landing', '=', 1]]),
        };
    },
    computed: {
        inHighLightWhiteList() {
            return (this.chatbotSlug === CompanyChatbot.SLUG_FULL) ? this.flowVariablesFull : this.flowVariablesLight;
        },
    },
    async created() {
        await this.companyMediaCollection.get();

        if (this.companyMediaCollection.$.models.length) this.currentMedia = this.companyMediaCollection.$.models[0];
    },
    methods: {
        getLink() {
            return this.$Utils.resolveS3PublicImage(this.currentMedia.company_file.path + this.currentMedia.company_file.name);
        },
    },
};
</script>
<style lang="scss" scoped>
	@import "~@/styles/var";
	@import "~@/styles/customflow";

	.margin-bottom {
		margin-bottom: 18px;
	}
</style>
