<template>
    <ResourceListing
        ref="listing"
        api-name="events"
        :title="translate('Events')"
        :columns="columns"
        :formatter="formatter"
        permission="ModelCompanyPlanningEventTemplate"
        empty-message="There are no event to display yet..."
        has-quick-actions
        :is-modalable="false"
        @on-create="openForm"
        @on-row-clicked="onRowClicked"
        @on-action-clicked="onActionClicked"
    />
</template>

<script>
import api from '@/store/api';
import ResourceListing from '@/components/resources/generic/ResourceListing.vue';

export default {
    permissions: [
        'ModelCompanyPlanningEventTemplate',
    ],

    components: {
        ResourceListing,
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('Planning'),
                    key: 'status',
                },
            ],
        };
    },

    methods: {
        fetchData() {
            this.$refs.listing.fetchData();
        },

        formatter(items) {
            const colors = {
                draft: 'orange',
                scheduled: 'green',
                delegated: 'purple',
            };

            return items.map((item) => {
                const status = this.getStatus(item);
                const quickActions = [];
                if (this.$canCreate('ModelCompanyPlanningEventTemplate', { id: -1 })) {
                    quickActions.push({ id: 'replicate', label: 'Replicate', icon: ['fal', 'copy'] });
                }

                return {
                    ...item,
                    status_label: this.translate(status),
                    status_color: colors[status],
                    quick_actions: quickActions,
                };
            });
        },

        replicateEvent(event) {
            api.configuration.events.replicate(event.id)
                .then(() => {
                    this.$Notifier('App').showInfo(this.translate('The resource << {resource_name} >> is replicated', {
                        resource_name: event.resource.name,
                    }));
                    this.fetchData();
                })
                .catch(() => {
                    this.$Notifier('App').showError(this.translate('The resource << {resource_name} >> is not replicated!', {
                        resource_name: event.resource.name,
                    }));
                });
        },

        onActionClicked({ item, actionId: action }) {
            switch (action) {
            case 'replicate':
                this.replicateEvent(item);
                break;
            }
        },

        getStatus(event) {
            if (event.resource.programs.length === 0 || !event.resource.programs[0].is_enabled) {
                return 'draft';
            }

            if (event.is_delegated) {
                return 'delegated';
            }

            return 'scheduled';
        },

        openForm() {
            this.$router.push({ name: 'ResourcesEventsForm' });
        },

        onRowClicked(row) {
            this.$router.push({
                name: 'ResourcesEventsForm',
                params: {
                    id: row.item.id,
                },
            });
        },
    },
};
</script>
