<template>
    <HoldingResourceList
        :route-list="'HoldingResourcesActionsList'"
        :collection="actions"
    >
        <template #table="holdingResourceList">
            <ActionsList
                :items="holdingResourceList.items"
                :external-search="holdingResourceList.search"
                @onCellClicked="holdingResourceList.onCellClicked"
            />
        </template>
    </HoldingResourceList>
</template>
<script>
import CompanyProgramTaskCollection from '@/models/CompanyProgramTaskCollection';
import HoldingResourceList from '@/components/globals/HoldingResourceList.vue';
import ActionsList, { actionConfig } from '@/resourcesList/ActionsList.vue';

export default {
    name: 'PageActionsList',
    components: {
        HoldingResourceList,
        ActionsList,
    },

    data() {
        return {
            actions: new CompanyProgramTaskCollection(actionConfig.fields).with(actionConfig.relations),
        };
    },
};
</script>
