<template>
    <div v-if="isLoaded">
        <HtFormSwitch
            id="enable-sso"
            v-model="sso.is_enabled"
            :label="translate('Enable')"
            name="enable-sso"
            :show-optional="false"
        />

        <HtFormSelect
            id="sso-list"
            v-model="sso.name"
            v-validate.disable="'required'"
            name="sso-list"
            :label="translate('Choose your identity management platform')"
            :options="ssoOptions"
            :data-vv-as="translate('SSO')"
            @input="setSso"
        />

        <HtFormInput
            id="sso-auth-uri"
            v-model="sso.auth_uri"
            v-validate.disable="'required'"
            name="auth-uri"
            placeholder="https://your.oauth.service/oauth"
            :label="translate('Auth uri')"
            :is-required="true"
            :show-asterisk="true"
            :data-vv-as="translate('auth uri')"
        />

        <HtFormInput
            id="sso-token-uri"
            v-model="sso.token_uri"
            v-validate.disable="'required'"
            :name="'token-uri'"
            placeholder="https://your.oauth.service/token"
            :label="translate('Token uri')"
            :is-required="true"
            :show-asterisk="true"
            :data-vv-as="translate('token uri')"
        />

        <HtFormInput
            id="sso-user-uri"
            v-model="sso.user_uri"
            v-validate.disable="'required'"
            name="user-uri"
            placeholder="https://your.oauth.service/user_info"
            :label="translate('User uri')"
            :is-required="true"
            :show-asterisk="true"
            :data-vv-as="translate('user uri')"
        />

        <HtFormInput
            id="sso-scope"
            v-model="sso.scope"
            v-validate.disable="'required'"
            name="scope"
            placeholder="scope1 scope2 scope3"
            :label="translate('Scope')"
            :is-required="true"
            :show-asterisk="true"
            :data-vv-as="translate('scope')"
        />

        <HtFormInput
            id="sso-client-id"
            v-model="sso.client_id"
            v-validate.disable="'required'"
            name="client-id"
            placeholder="your-client-id"
            :label="translate('Client id')"
            :is-required="true"
            :show-asterisk="true"
            :data-vv-as="translate('client id')"
        />

        <HtFormInput
            id="sso-client-secret"
            v-model="sso.client_secret"
            v-validate.disable="!hasStoredSso ? 'required' : ''"
            name="client-secret"
            placeholder="****************"
            :label="translate('Client secret')"
            :is-required="!hasStoredSso"
            :show-asterisk="!hasStoredSso"
            :data-vv-as="translate('client secret')"
        />

        <HtFormList
            id="sso-domains"
            name="sso-domains"
            :label="'Domains'"
            :value="sso.domains"
            :regex="ssoDomainValidationRegex"
            :show-optional="true"
            @input="setSsoDomains"
        />

        <div class="modal-actions right">
            <HtButton
                type="secondary"
                :disabled="!canTestSso"
                @click.native="testConfiguration()"
            >
                <t>Test configuration</t>
            </HtButton>

            <Button
                @click="onSave()"
            >
                {{ getButtonLabel }}
            </Button>
        </div>
    </div>
</template>

<script>

import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import api from '@/store/api';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import OAuth from '@/OAuth';
import HtFormList from '@/components/globals/HtFormList.vue';
import HtButton from '@/components/globals/HtButton.vue';
import { isEmpty } from '@/helpers/_';
import { HTTP_RESPONSE_UNPROCESSABLE_ENTITY } from '@/plugins/HttpPlugin';
import CompanySso from '@/models/CompanySso';

export default {
    name: 'SsoEdit',
    components: {
        HtButton,
        HtFormList,
        HtFormSwitch,
        HtFormInput,
        HtFormSelect,
    },
    inject: ['modal', '$validator'],

    data() {
        return {
            ssoDomainValidationRegex: CompanySso.DOMAIN_VALIDATION_REGEX,
            code: null,
            isLoaded: false,
            sso: {
                id: null,
                name: null,
                auth_uri: null,
                token_uri: null,
                user_uri: null,
                client_id: null,
                client_secret: null,
                redirect_uri: `${window.location.origin}/openid`,
                domains: [],
                is_enabled: false,
                response_type: 'code',
                scope: 'openid profile email',
            },
        };
    },

    computed: {
        hasStoredSso() {
            return this.isLoaded && this.sso.id > 0;
        },
        canTestSso() {
            return !isEmpty(this.sso.name)
                && !isEmpty(this.sso.auth_uri)
                && !isEmpty(this.sso.token_uri)
                && !isEmpty(this.sso.user_uri)
                && !isEmpty(this.sso.client_id)
                && (
                    (isEmpty(this.sso.client_secret) && this.hasStoredSso)
                    || (!isEmpty(this.sso.client_secret) && !this.hasStoredSso)
                );
        },
        getButtonLabel() {
            return this.hasStoredSso ? this.translate('Update') : this.translate('Save');
        },
    },

    async created() {
        this.sso = (await api.sso.get()).data.data;
        this.ssoOptions = (await api.sso.getSsoOptions()).data.data;
        this.isLoaded = true;
    },

    methods: {
        setSsoDomains(values) {
            this.sso = {
                ...this.sso,
                domains: values,
            };
        },

        setSso(id) {
            const selectedSso = this.ssoOptions.find((ssoOption) => ssoOption.id === id);

            this.sso = {
                ...this.sso,
                auth_uri: selectedSso.auth_uri,
                token_uri: selectedSso.token_uri,
                user_uri: selectedSso.user_uri,
                scope: selectedSso.scope,
            };
        },

        async onSave() {
            await this.$validator.validateAll();

            if (!this.errors.any()) {
                if (!this.hasStoredSso) {
                    await api.sso.store(this.sso);
                    this.$Notifier('App').showInfo(this.translate('Sso stored'));
                } else {
                    await api.sso.update(this.sso);
                    this.$Notifier('App').showInfo(this.translate('Sso updated'));
                }
                this.modal.close();
            }
        },

        async testConfiguration() {
            const isvalid = await this.$validator.validateAll();
            if (!isvalid) {
                return;
            }

            await this.setCode();

            try {
                await api.sso.testConfiguration({ ...this.sso, code: this.code });
                this.$Notifier('App').showInfo(this.translate('The SSO has been correctly configured'));
            } catch (e) {
                if (e.response.status !== HTTP_RESPONSE_UNPROCESSABLE_ENTITY) {
                    this.$Notifier('App').showError(this.translate(e.message));
                }
            }
        },

        async setCode() {
            const config = {
                url: this.sso.auth_uri,
                client_id: this.sso.client_id,
                redirect_uri: this.sso.redirect_uri,
                response_type: this.sso.response_type,
                scope: this.sso.scope,
            };

            await OAuth.auth(config, {
                200: (data) => {
                    this.code = data.code;
                },
            });
        },
    },
};
</script>
