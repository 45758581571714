<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 14 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14 20H0V18H14V20ZM7 16L1 10L2.41 8.59L6 12.17V0H8V12.17L11.59 8.59L13 10L7 16Z"
            :fill="fillColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'DSIconDownload',
    props: {
        width: {
            type: String,
            default: '14',
        },
        height: {
            type: String,
            default: '20',
        },
        fillColor: {
            type: String,
            default: 'black',
        },
    },
};
</script>

<style scoped>

</style>
