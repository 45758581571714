<template>
    <div class="list-channel-item">
        <div class="list-channel-item-avatar">
            <div class="list-channel-item-avatar-img d-flex align-items-center justify-content-center">
                <FontAwesomeIcon :icon="['fal', 'user-friends']" />
            </div>
        </div>
        <div class="list-channel-item-profile">
            <div class="list-channel-item-profile-name">
                {{ label }}
            </div>
        </div>
        <div>
            <FontAwesomeIcon
                class="icon"
                icon="chevron-right"
            />
        </div>
    </div>
</template>

<script>
import I18n from '@/modules/i18n/I18n';

export default {
    name: 'CreateGroupConversationItem',
    data() {
        return {
            label: I18n.translate('Create group'),
        };
    },
};
</script>

<style lang="scss">
@import "~@/styles/var";

.list-channel-item {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-right: 20px;
    cursor: pointer;

    &.active, &:hover {
        background-color: var(--branding-highlight);

        .icon {
            color: var(--branding-color);
        }
    }

    &-avatar {
        position: relative;
        color: var(--branding-color);
        background-color: var(--branding-highlight);
        border-radius: 50%;

        &-img {
            height: 40px;
            width: 40px;
            border-radius: 50%;
        }
    }

    &-profile {
        margin-left: 10px;
        flex-grow: 1;
        width: 0;
        overflow: hidden;

        &-name {
            font-size: 13px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
