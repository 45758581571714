<template>
    <div
        class="file-upload-zone-wrapper"
        :data-cy="cypress"
    >
        <div :class="['file-upload-zone', { 'border-straight': hasFile && widthProgressBar > 0 }]">
            <EmptyBarZone :label="emptyLabel" />
        </div>
        <ProgressBarZone
            class="progress"
            :width="widthProgressBar"
        />
        <label v-if="showMediaLegend">
            <span class="sub-text">
                <t>File type:</t> {{ mediaLegend }}
            </span>
        </label>
        <label v-if="showFileSizeLegend && !value">
            <span class="sub-text">
                <t>Max size:</t> {{ Math.round((maxSize + Number.EPSILON) * 100) / 100 }} <t>MB</t>
            </span>
        </label>
    </div>
</template>

<script>
import CypressMixin from '@/mixins/CypressMixin';
import EmptyBarZone from './EmptyBarZone.vue';
import ProgressBarZone from './ProgressBarZone.vue';

export default {
    name: 'UploadBarZone',
    components: { EmptyBarZone, ProgressBarZone },
    mixins: [
        CypressMixin,
    ],

    provide() {
        return {
            uploadBarZone: this,
        };
    },

    props: {
        isRequirement: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        media: {
            type: Array,
            required: true,
        },
        extension: {
            type: Array,
            default: null,
        },
        maxSize: {
            type: Number,
            default: 20,
        },
        showMediaLegend: {
            type: Boolean,
            default: false,
        },
        showFileSizeLegend: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
        },
        emptyLabel: {
            type: String,
            default: 'Add a file',
        },
    },

    data() {
        return {
            widthProgressBar: 0,
        };
    },

    computed: {
        hasFile() {
            return Boolean(this.value?.id);
        },

        mediaLegend() {
            const medias = {
                pdf: 'PDF',
                docx: 'docx',
                txt: 'txt',
                office: 'Office (word, excel, txt, csv...)',
                csv: 'CSV',
                image: 'Image (png, jpeg...)',
            };

            const string = this.media.map((m) => medias[m]);

            return string.join(', ');
        },
    },

    methods: {
        resetProgression() {
            this.widthProgressBar = 0;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.file-upload-zone-wrapper {
    cursor: pointer;
}

.file-upload-zone {
    display: flex;
    justify-content: center;
    border: 2px dashed #ccc;
    border-radius: 5px;
    font-weight: bold;
    background-color: #eee;
    cursor: pointer;

    &.border-straight {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    >* {
        flex: 1 1 100%;
        width: 100%;
    }
}

.progress {
    margin-top: -1px; // marge négative pour que la bar de progression soit à l'intérieur du champ d'input
}
</style>
