import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyFlow from './CompanyFlow';

export default class CompanyFlowCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyFlow,
        };
    }
}
