import Model from '@tony.caron/node-model-proxy/Model';
import Api from '@/services/Api';
import CompanyUser from './CompanyUser';

export default class CompanyUserOpentokArchive extends Model {
    modelConfig() {
        return {
            controller: 'CompanyUserOpentokArchiveController',
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
        };
    }

    getArchive() {
        return new Promise((resolve, reject) => {
            Api.getInstance('default').get(`opentok/archive/${this.archive_id}`, {
                200: (data) => {
                    resolve(data);
                },
                onError: (data, code) => {
                    reject(data, code);
                },
            });
        });
    }
}
