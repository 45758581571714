import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import CompanyChatbot from './CompanyChatbot';

export default class CompanyUserFlow extends Model {
    static STATUS_DONE = 'done';

    modelConfig() {
        return {
            controller: 'CompanyUserFlowController',
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            chatbot: () => this.belongsTo(CompanyChatbot, 'company_chatbot_id', 'id'),
        };
    }

    modelEvents() {
        return {
            saved() {},

            updated() {},

            deleted() {},

            failed() {},
        };
    }
}
