<template>
    <HtTabs>
        <HtTab :title="translate('Programs')">
            <ExportUsersForm />
        </HtTab>
        <HtTab :title="translate('Resources')">
            <ExportActionsForm />
        </HtTab>
    </HtTabs>
</template>

<script>
import ExportActionsForm from '@/components/pages/settings/dataAnalysis/ExportActionsForm.vue';
import ExportUsersForm from '@/components/pages/settings/dataAnalysis/ExportUsersForm.vue';

export default {
    name: 'DataAnalysis',
    components: {
        ExportActionsForm,
        ExportUsersForm,
    },
};
</script>
