<template>
    <DSIconError v-if="isError" />
    <DSIconPending v-else-if="isPending" />
    <DSIconSuccess v-else-if="icon === 'success'" />
    <DSIconInformation v-else-if="icon === 'info'" />
    <DSIconWarning v-else-if="icon === 'warning'" />
</template>

<script>
import DSIconError from '@/components/design-system/icons/Alerts/DSIconError.vue';
import DSIconSuccess from '@/components/design-system/icons/Alerts/DSIconSuccess.vue';
import DSIconPending from '@/components/design-system/icons/Alerts/DSIconPending.vue';
import DSIconWarning from '@/components/design-system/icons/Alerts/DSIconWarning.vue';
import DSIconInformation from '@/components/design-system/icons/Alerts/DSIconInformation.vue';

export default {
    name: 'JobLogIcon',
    components: {
        DSIconInformation,
        DSIconWarning,
        DSIconError,
        DSIconSuccess,
        DSIconPending,
    },
    props: {
        icon: {
            type: String,
            required: true,
        },
    },
    computed: {
        isError() {
            return ['error', 'fatal_error'].includes(this.icon);
        },
        isPending() {
            return ['running', 'pending'].includes(this.icon);
        },
    },
};
</script>
