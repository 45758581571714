<template>
    <div
        :class="['ht-stepper-item', {'clickable': isClickable}]"
        @click="changeStep"
    >
        <div
            v-if="hasTopBar"
            class="ht-stepper-bar"
        >
            <div :class="['bar', {'bar-succeed': !disabled}]" />
        </div>
        <div
            class="d-flex align-items-center ht-stepper-item-wrapper"
            :class="[{'current': isCurrent, 'disabled': disabled, 'succeed': isSucceed}]"
        >
            <span class="ht-stepper-item-dot">
                <FontAwesomeIcon
                    v-show="showCheckIcon"
                    size="1x"
                    icon="check"
                    class="icon-check"
                />
            </span>
            <span class="ht-stepper-item-label ml-3">
                {{ label }}
            </span>
        </div>
        <div
            v-if="hasBottomBar"
            class="ht-stepper-bar"
        >
            <div :class="['bar', 'bar-bottom', {'bar-succeed': !disabled && !isLastActive}]" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'HtStepperItem',
    inject: ['stepper'],

    props: {
        step: {
            Number,
            required: true,
        },
        steps: {
            Array,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isCurrent() {
            return this.step === this.stepper.activeIndex;
        },

        showCheckIcon() {
            return this.disabled === false && !this.isCurrent && this.isSucceed;
        },

        isClickable() {
            return !this.disabled && !this.isCurrent;
        },

        isSucceed() {
            const hasNextStepEnabled = this.stepper.steps.slice(this.step + 1).some((step) => step.disabled === false);

            return !this.disabled && !this.isCurrent && hasNextStepEnabled;
        },

        isLastActive() {
            if (this.step === this.steps.length - 1) {
                return !this.disabled;
            }

            const nextStep = this.steps[this.step + 1];
            return !this.disabled && nextStep.disabled;
        },

        hasTopBar() {
            return this.step > 0;
        },

        hasBottomBar() {
            return !(this.step === this.steps.length - 1);
            // return this.steps ? !(this.step === this.steps.length - 1) : true;
        },
    },

    methods: {
        changeStep() {
            if (this.isClickable) {
                this.stepper.setActive(this);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.ht-stepper {
    &-bar {
        width: 2px;
        height: 16px;
        background-color: $blue-light;
        margin: -2px 0;
        position: relative;
        left: (12px * 0.5) - 1px;

        .bar {
            width: 2px;
            max-height: 0;
            transition: max-height .4s ease-out .4s;

            &.bar-succeed {
                max-height: 21px;
                background-color: #25B771;
            }

            &-bottom {
                transition: max-height .4s ease-out 0s;
            }
        }
    }

    &-item {
        &-bar {
            width: 2px;
            height: 21px;
            background-color: $blue-light;
            margin-left: 5px;
            margin-top: -2px;
            margin-bottom: -2px;
        }

        &-dot {
            border-radius: 50%;
            width: 12px;
            height: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $blue-light;
            border: 2px solid $blue-light;
            transition: background 0.4s, border 0.4s;

            .icon-check {
                color: white;
                font-size: 8px;
            }
        }

        &-label {
            font-weight: 700;
            font-size: 14px;
            transition: color 0.4s;
        }
    }
}

.current {
    .ht-stepper-item-dot {
        border: 2px solid #25B771;
        background-color: #25B771;
    }

    .ht-stepper-item-label {
        color: $black-2;
    }
}

.succeed {
    .ht-stepper-item-dot {
        border: 2px solid #25B771;
        background-color: #25B771;
    }

    .ht-stepper-item-label {
        color: $black-2;
    }

    &:hover {
        .ht-stepper-item-dot {
            border: 2px solid #25B771;
            background-color: white;
        }
    }
}

.disabled {
    .ht-stepper-item-label {
        color: $grey-9;
    }
}

.clickable {
    cursor: pointer;
}

@media (max-width: $tablet) {
    .ht-stepper {
        &-bar {
            width: 2px;
            height: 50px;
            background-color: $blue-light;
            margin: 0px 0;
            position: relative;
            left: (12px * 0.5) - 1px;

            .bar {
                width: 2px;
                max-height: 0;
                transition: max-height .4s ease-out .4s;

                &.bar-succeed {
                    max-height: 50px;
                    background-color: #25B771;
                }

                &-bottom {
                    transition: max-height .4s ease-out 0s;
                }
            }
        }

        &-item {
            &-bar {
                width: 2px;
                height: 50px;
                background-color: $blue-light;
                margin-left: 5px;
                margin-top: -2px;
                margin-bottom: -2px;
            }

            &-dot {
                border-radius: 50%;
                width: 12px;
                height: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $blue-light;
                border: 2px solid $blue-light;
                transition: background 0.4s, border 0.4s;
                transform: rotate(90deg);

                .icon-check {
                    color: white;
                    font-size: 8px;
                }
            }

            &-label {
                display: none;
                font-weight: 700;
                font-size: 14px;
                transition: color 0.4s;
            }
        }
    }
}

@media (max-width: $phone) {
    .ht-stepper-bar {
        height: 16px;
        .bar-succeed {
            height: 16px;
        }
    }
}
</style>
