<template>
    <HtTable
        :items="companyUserProgramCollection.models"
        :columns="columns"
        @onCellClicked="goToUserProgram"
    >
        <template #head(progress)="scope">
            {{ scope.column.label }}
            <span
                v-tooltip.right-end="translate('The completion rate represents the number of tasks completed by the employee out of the number of tasks that has to be completed at this given time')"
                class="px-2 position-relative"
            >
                <FontAwesomeIcon icon="info-circle" />
            </span>
        </template>

        <template #cell(display_status)="scope">
            <HtTag :color="getStatusColor(scope.item)">
                {{ getStatusText(scope.item) }}
            </HtTag>
        </template>
        <template #cell(start)="scope">
            {{ $Utils.moment(scope.item.main_key_date.starts_at).format('L') }}
        </template>

        <template #cell(progress)="scope">
            <HtPercentageCompletionProgram
                :enrollee-completion="scope.item.enrollee_completion"
                :participants-completion="scope.item.participants_completion"
            />
        </template>

        <template #cell(health)="scope">
            <div class="d-flex justify-content-start">
                <HtStatusLight
                    :status="getProgramStatus(scope.item.task_count_late)"
                    :label="getProgramStatusLabel(scope.item.task_count_late)"
                />
            </div>
        </template>
    </HtTable>
</template>
<script>
import HtStatusLight from '@/components/globals/HtStatusLight.vue';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import CompanyUserProgramCollection from '@/models/CompanyUserProgramCollection';
import HtPercentageCompletionProgram from '@/components/globals/HtPercentageCompletionProgram.vue';

export default {
    name: 'ProfileAllProgram',
    components: {
        HtPercentageCompletionProgram,
        HtStatusLight,
    },

    props: {
        companyUserProgramCollection: {
            type: CompanyUserProgramCollection,
            required: true,
        },
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    class: 'font-weight-bold',
                    key: 'company_program.name_translated',
                    sortable: false,
                },
                {
                    label: this.translate('status'),
                    class: 'd-none d-md-table-cell',
                    sortable: false,
                    key: 'display_status',
                },
                {
                    label: this.translate('start date'),
                    class: 'd-none d-md-table-cell',
                    sortable: false,
                    key: 'start',
                },
                {
                    label: this.translate('Progress'),
                    class: 'd-none d-md-table-cell',
                    sortable: false,
                    key: 'progress',
                },
                {
                    label: this.translate('Health'),
                    class: 'd-none d-md-table-cell',
                    sortable: false,
                    key: 'health',
                },
            ],
        };
    },

    methods: {
        /**
         * @param {CompanyUserProgram} userProgram
         * @returns {string}
         */
        getStatusText(item) {
            return this.isUserProgramCompleted(item) ? this.translate('Completed') : this.translate('Live');
        },

        /**
         * @param {CompanyUserProgram} userProgram
         * @returns {string}
         */
        getStatusColor(item) {
            return this.isUserProgramCompleted(item) ? 'grey-dark' : 'green';
        },

        /**
         * @param {CompanyUserProgram} item
         * @returns {boolean}
         */
        isUserProgramCompleted(item) {
            return item.status === CompanyUserProgram.STATUS_COMPLETED;
        },

        goToUserProgram(row) {
            this.$emit('on-change-program', { value: row.item.id });
        },

        /**
         * @param {number} lateTasks
         * @returns {string}
         */
        getProgramStatus(lateTasks) {
            if (lateTasks > 15) {
                return 'danger';
            }
            if (lateTasks > 0) {
                return 'warning';
            }

            return 'success';
        },

        /**
         * @param {number} lateTasks
         * @returns {string}
         */
        getProgramStatusLabel(lateTasks) {
            return this.translate('{count} late task | {count} late tasks', { count: lateTasks });
        },
    },
};
</script>
