import resourceEventBus from '@/eventBus/resourceEventBus';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';

export default {
    methods: {
        handleTasks(tasks) {
            if (!tasks?.length) return [];

            const resourcesToGroup = ['company_equipment', 'company_software', 'company_requirement_category'];
            const groupedTasks = [];

            for (let i = 0; i < tasks.length; i += 1) {
                const task = {
                    ...tasks[i],
                    selected: this.selectedTasks?.includes(tasks[i].id),
                };

                // Ignores tasks not meant to be grouped
                if (!resourcesToGroup.includes(task.resource)) {
                    groupedTasks.push(task);
                } else {
                    // We know this is a task that can be grouped
                    const indexParentTask = groupedTasks
                        .findIndex((oneTask) => oneTask.resource === task.resource
                            && oneTask.user.id === task.user.id && oneTask.user_program.program.id === task.user_program.program.id);
                    // first we add the task alone since it can not have sibling tasks
                    if (indexParentTask === -1) {
                        groupedTasks.push(task);
                    } else {
                        const parentTask = groupedTasks[indexParentTask];
                        // Once we are sure there are two task to be grouped
                        // we create the grouped task
                        if (!parentTask.tasks) {
                            groupedTasks.splice(indexParentTask, 1, {
                                id: `group-${parentTask.resource}-${parentTask.id}`,
                                user: parentTask.user,
                                user_program: parentTask.user_program,
                                resource: parentTask.resource,
                                datetime_end: parentTask.datetime_end,
                                tasks: [parentTask, task],
                                selected: parentTask.selected && task.selected,
                                partiallySelected: parentTask.selected || task.selected,
                            });

                        // The tasks were already grouped and we add corresponding tasks
                        } else {
                            parentTask.selected = parentTask.selected && task.selected;
                            parentTask.partiallySelected = parentTask.partiallySelected
                                || task.selected;
                            parentTask.tasks.push(task);
                        }
                    }
                }
            }

            /** Change this when changing tasks relations */
            const relationsWithOrdered = { company_requirement_category: 'requirement_category' };
            groupedTasks.filter((group) => 'tasks' in group).forEach((group) => {
                group.tasks.sort((taskA, taskB) => {
                    const getRelation = (task) => (Boolean(task.resource) && task.resource in relationsWithOrdered ? relationsWithOrdered[task.resource] : null);
                    const hasRelation = (task) => Boolean(task[getRelation(task)]) && Boolean(task[getRelation(task)].order);
                    const hasOrder = hasRelation(taskA) && hasRelation(taskB);

                    if (hasOrder) {
                        const getOrder = (task) => task[relationsWithOrdered[task.resource]].order;
                        if (getOrder(taskA) < getOrder(taskB)) {
                            return -1;
                        }
                        if (getOrder(taskA) > getOrder(taskB)) {
                            return 1;
                        }
                    }

                    return 0;
                });
            });

            return groupedTasks;
        },
        hasParentPendingTask(task) {
            if (!task.dependent_id) {
                return false;
            }
            const parentTask = this.userProgramTasks
                .find((oneTask) => oneTask.id === task.dependent_id);
            return (parentTask?.status === CompanyUserProgramTask.STATUS_PENDING);
        },
        getAllDependentTasks(task) {
            if (!task.dependencies) {
                return task;
            }
            const dependenciesTasks = [];
            for (let i = 0; i < task.dependencies.length; i += 1) {
                const dependentTask = this.userProgramTasks
                    .find((oneTask) => oneTask.id === task.dependencies[i]);
                if (dependentTask) {
                    dependenciesTasks.push(this.getAllDependentTasks(dependentTask));
                }
            }
            return { ...task, dependenciesTasks };
        },
        updateTask(taskId) {
            const tasksStatus = [];
            for (let i; i < this.userProgramTasks.length; i += 1) {
                tasksStatus[this.userProgramTasks[i].id] = this.userProgramTasks[i].status;
            }

            this.$store.dispatch('tasks/fetchTask', { id: taskId }).then((result) => {
                const task = result.data?.data;
                if (task) {
                    const indexTask = this.userProgramTasks
                        .findIndex((oneTask) => oneTask.id === taskId);
                    if (indexTask !== -1) {
                        // only change count if status changed
                        if (tasksStatus[task.id] !== task.status) {
                            this.$store.dispatch(
                                'dashboard/statistics/updateTodoCount',
                                task.status === CompanyUserProgramTask.STATUS_DONE ? -1 : +1,
                            );

                            if (task.resource === 'company_requirement_category'
                                || task.resource === 'company_survey') {
                                resourceEventBus.$emit('requirement-category-done');
                            }
                        }
                        this.userProgramTasks.splice(indexTask, 1, task);
                        // add task to list if it isn't in it (for accepted nudges)
                    } else {
                        if (tasksStatus[task.id] === CompanyUserProgramTask.STATUS_PENDING) {
                            this.$store.dispatch('dashboard/statistics/updateTodoCount', +1);
                        }
                        this.userProgramTasks.push(task);
                    }
                }
            });
        },
        updateTaskStatus({ taskId, status }) {
            const indexTask = this.userProgramTasks.findIndex((task) => task.id === taskId);
            const previousStatus = this.userProgramTasks[indexTask].status;
            if (previousStatus !== status) {
                this.$store.dispatch(
                    'dashboard/statistics/updateTodoCount',
                    status === CompanyUserProgramTask.STATUS_DONE ? -1 : +1,
                );
                this.$set(this.userProgramTasks[indexTask], 'status', status);
            }
        },
        deleteTask(taskId) {
            const indexTask = this.userProgramTasks.findIndex((task) => task.id === taskId);
            if (indexTask !== -1) {
                this.$store.dispatch('dashboard/statistics/updateTodoCount', -1);
                this.userProgramTasks.splice(indexTask, 1);
            }
        },
        getTaskStatus(task) {
            if (!task.tasks) return task.status;

            return task.tasks.reduce((acc, subTask) => {
                if (subTask.status === CompanyUserProgramTask.STATUS_DONE
                    && acc === CompanyUserProgramTask.STATUS_DONE) {
                    return CompanyUserProgramTask.STATUS_DONE;
                }
                return CompanyUserProgramTask.STATUS_PENDING;
            }, CompanyUserProgramTask.STATUS_DONE);
        },
    },
};
