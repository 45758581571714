<template>
    <div class="user-requirement-file-to-fill-edit">
        <div class="item-fill">
            <div class="item-fill-item mb-20">
                <div class="item-fill-item-label">
                    <t>Step 1</t>
                </div>
                <div class="item-fill-item-description">
                    <t>Download the file and fill it</t>
                </div>
                <div class="item-fill-item-input">
                    <Button
                        class="black"
                        cypress="download-button"
                        @click="download(companyUserRequirement.company_file_secondary)"
                    >
                        <t>Download</t>
                    </Button>
                </div>
            </div>
            <div class="item-fill-item">
                <div class="item-fill-item-label">
                    <t>Step 2</t>
                </div>
                <div class="item-fill-item-description">
                    <t>Upload the filled file</t>
                </div>
                <div class="item-fill-item-input">
                    <UploadBar
                        v-model="companyUserRequirement.company_file"
                        :disabled="isDisabled"
                        :media="['office', 'pdf', 'image']"
                        cypress="requirement-file-filled"
                        :is-requirement="true"
                        @onUploadEnd="processFileToFill(false)"
                        @file-deleted="onFileDelete"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="companyUserRequirement.company_file_id"
            class="completion-status"
        >
            <span>&#10003;</span> <t>document completed</t>
        </div>
        <div
            v-else-if="companyUserRequirement.sign_after_complete"
            class="to-complete-status"
        >
            <t>Complete the document before signing it</t>
        </div>
        <div
            v-for="(errorMessage, index) in errors.collect(companyUserRequirement.name)"
            :key="index"
            class="error-message"
        >
            <t>{{ errorMessage }}</t>
        </div>
    </div>
</template>
<script>
import UploadBar from '@/components/globals/UploadBar/UploadBar.vue';
import DownloadUploadFileMixin from '@/components/mixins/DownloadUploadFileMixin';
import UserRequirementEditMixin from '../UserRequirementEditMixin';
import FileToFillMixin from './FileToFillMixin';

export default {
    components: {
        UploadBar,
    },

    mixins: [UserRequirementEditMixin, FileToFillMixin, DownloadUploadFileMixin],

    props: {
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onFileDelete() {
            this.companyUserRequirement.company_file_id = null;

            this.onUpdate();
        },
        download(file) {
            this.downloadCompanyFile(file.id, false);
        },
    },
};
</script>
<style lang="scss" src="./UserRequirementFileToFillEdit.scss" scoped />
