<template>
    <div v-if="permissionsLoaded">
        <div
            v-if="$store.getters['dashboard/actions/canViewProgramSummaries']
                && (hasActiveProgram || $canRead('AbstractWidgetChecklistOnboardee'))"
            class="container-fluid pl-0 pr-0"
        >
            <div class="head-row">
                <HtH3>
                    <t>My program</t>
                </HtH3>
                <HtButton
                    v-if="canSeeQuickActionButton"
                    :icon="['fal', 'plus']"
                    type="secondary"
                    @click.native="openQuickActionModal"
                >
                    <t>Add</t>
                </HtButton>
            </div>
            <div class="wrapper-my-program">
                <div
                    v-if="hasActiveProgram"
                    class="col-my-program"
                >
                    <KeyDateWidget />
                </div>
                <div
                    v-if="hasActiveProgram"
                    class="col-my-program"
                >
                    <CompletionProgramWidget />
                </div>
                <transition
                    name="widget-fade"
                    mode="out-in"
                >
                    <div
                        v-if="$canRead('AbstractWidgetChecklistOnboardee')"
                        class="col-my-program"
                    >
                        <ChecklistWidget />
                    </div>
                    <div
                        v-else
                        class="col-my-program"
                    >
                        <ChatWidget />
                    </div>
                </transition>
            </div>
        </div>
        <div
            class="wrapper-actions-team"
            :class="{
                'has-team': $store.getters['dashboard/actions/canViewMyTeam'],
                'actions-next-team': actionsNextToTeam
            }"
        >
            <div class="wrapper-actions">
                <div
                    v-if="canSeeQuickActionButton && !($store.getters['dashboard/actions/canViewProgramSummaries']
                        && (hasActiveProgram || $canRead('AbstractWidgetChecklistOnboardee')))"
                    style="display: flex; width: 100%; justify-content: end;"
                >
                    <HtButton
                        :icon="['fal', 'plus']"
                        type="secondary"
                        @click.native="openQuickActionModal"
                    >
                        <t>Add</t>
                    </HtButton>
                </div>
                <MyActionsWidget @sortingTypeChange="changeLayout" />
            </div>
            <MyTeamWidget
                v-if="$store.getters['dashboard/actions/canViewMyTeam']"
                :class="{'actions-next-team': actionsNextToTeam}"
            />
        </div>
    </div>
</template>

<script>
import HtH3 from '@/components/design-system/typography/header/HtH3.vue';
import MyActionsWidget from '@/components/pages/dashboard/actions/MyActionsWidget.vue';
import MyTeamWidget from '@/components/pages/dashboard/widgets/MyTeamWidget.vue';
import KeyDateWidget from '@/components/pages/dashboard/widgets/utils/KeyDateWidget.vue';
import CompletionProgramWidget from '@/components/pages/dashboard/widgets/utils/CompletionProgramWidget.vue';
import ChecklistWidget from '@/components/pages/dashboard/widgets/utils/ChecklistWidget.vue';
import CompanyUser from '@/models/CompanyUser';
import ChatWidget from '@/components/pages/dashboard/widgets/utils/ChatWidget.vue';
import ModalMixin from '@/components/globals/modals/modalMixin';
import QuickActionsModal from '@/components/globals/modals/QuickActions/QuickActionsModal.vue';
import HtButton from '@/components/globals/HtButton.vue';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Actions',
    permissions: [
        'AbstractWidgetChecklistOnboardee',
        'AbstractCanSelfEnroll',
        'PageBulkEnrolling',
    ],
    components: {
        HtH3,
        HtButton,
        MyTeamWidget,
        MyActionsWidget,
        KeyDateWidget,
        CompletionProgramWidget,
        ChecklistWidget,
        ChatWidget,
    },

    mixins: [
        ModalMixin,
    ],
    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },
    data() {
        return {
            actionsNextToTeam: true,
        };
    },
    computed: {
        hasActiveProgram() {
            return this.$store.getters['dashboard/actions/activeProgramSummary'];
        },
        canSeeQuickActionButton() {
            return this.$can('PageBulkEnrolling') || this.$canRead('AbstractCanSelfEnroll');
        },
    },
    watch: {
        'shared.session.companyUser.is_administrative_file_validated': function () {
            this.$refreshPermissions(this.$options.permissions, true);
        },
    },
    created() {
        this.$store.dispatch('dashboard/actions/fetchProgramSummaries');
        this.$store.dispatch('dashboard/actions/fetchMyTeam');
        this.$store.dispatch('dashboard/actions/fetchRequirementCategoriesProgress');
    },
    methods: {
        openQuickActionModal() {
            this.openCenterModal(QuickActionsModal);
        },
        changeLayout(sortingType) {
            this.actionsNextToTeam = sortingType !== 'user';
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.widget-fade-enter-active, .widget-fade-leave-active {
  transition: opacity 0.3s ease;
}
.widget-fade-enter, .widget-fade-leave-to {
  opacity: 0;
}
.head-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}
.wrapper-my-program {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.col-my-program {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: 3rem;
}
.wrapper-actions-team {
    &.has-team {
        .wrapper-actions {
            margin-bottom: 3rem;
        }
    }
}

@media (min-width: $tablet-large){
    .wrapper-actions-team {
        &.has-team.actions-next-team {
            display: flex;
            justify-content: space-between;
            width: calc(100% + var(--gutter)*2);
            margin-left: calc(var(--gutter)*-1);
            .wrapper-actions {
                flex: 0 0 auto;
                width: calc(66.6666% - var(--gutter)*2);
                margin: 0 var(--gutter);
            }
            .wrapper-team {
                flex: 0 0 auto;
                width: calc(33.3333% - var(--gutter)*2);
                margin: 0 var(--gutter);
            }
        }
    }
    .wrapper-my-program {
        width: calc(100% + var(--gutter)*2);
        margin-left: calc(var(--gutter)*-1);
    }
    .col-my-program {
        width: calc(33.3333% - var(--gutter)*2);
        margin: 0 var(--gutter) 3rem;
    }
}
</style>
