<template>
    <ListEntityValues
        :entity="entity"
        :columns="columns"
        :relation-name="'contract'"
        :api-resource="'contracts'"
        :filters="filters"
        @on-back="$emit('on-back')"
    />
</template>

<script>
import debounce from 'lodash.debounce';
import ListEntityValues from './ListEntityValues.vue';

export default {
    name: 'ListContract',
    components: {
        ListEntityValues,
    },
    props: {
        entity: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            filters: {},
        };
    },

    computed: {
        columns() {
            return [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    sortable: false,
                    filterFunction: debounce((name) => {
                        this.filters = { name };
                    }, 350),
                    placeholder: `${this.translate('Search for a')} ${this.entity.name.toLowerCase()}`,
                },
                {
                    label: '',
                    key: 'trash',
                    sortable: false,
                    class: 'text-right',
                },
            ];
        },
    },
};
</script>
