<template>
    <form
        v-if="isLoaded"
        class="form-2 spaced"
        data-cy="training-form"
    >
        <InputField
            v-model="companyUserTraining.name"
            :label="translate('Training name')"
            :validate-as="translate('name')"
            type="text"
            name="name"
            cypress="name"
            validate="required"
        />

        <fieldset class="medium">
            <HtFormEditor
                id="description"
                v-model="companyUserTraining.description"
                name="description"
                cypress="description"
                :label="translate('Description')"
                :has-toolbar="false"
            />
        </fieldset>

        <HtFormCard>
            <div class="mb-4 rounded branding-border-color d-flex justify-content-between">
                <div
                    :class="[
                        {'branding-bg font-weight-bold': companyUserTraining.type === 'file'},
                        'w-100 text-center py-2 cursor-pointer',
                    ]"
                    data-cy="display-file-input"
                    @click="companyUserTraining.type = 'file'"
                >
                    <t>Document</t>
                </div>
                <div
                    :class="[
                        {'branding-bg font-weight-bold': companyUserTraining.type === 'link'},
                        'w-100 text-center py-2 cursor-pointer',
                    ]"
                    data-cy="display-link-input"
                    @click="companyUserTraining.type = 'link'"
                >
                    <t>URL</t>
                </div>
            </div>
            <template v-if="companyUserTraining.type === 'file'">
                <label>
                    <t>Attach a document</t> *
                </label>
                <UploadBar
                    ref="original_nameInput"
                    v-model="companyUserTraining.company_file"
                    :media="['office', 'pdf', 'image', 'video']"
                    :max-size="250"
                    cypress="training-field-file"
                    class="mb-2"
                    @onUploadProgress="button_state = 'loading'"
                    @onUploadEnd="button_state = 'idle'"
                />
                <input
                    v-validate.disable="'required'"
                    type="hidden"
                    name="original_name"
                    data-cy="original-name"
                    :value="
                        companyUserTraining.company_file &&
                            companyUserTraining.company_file.original_name
                    "
                    :data-vv-as="translate('file')"
                >
                <div
                    v-for="(errorMessage, index) in errors.collect('original_name')"
                    :key="index"
                    class="error-message"
                >
                    <span v-if="errorMessage"><t>{{ errorMessage }}</t></span>
                </div>
                <HtTextDescription
                    :text="translate('Accepted file formats : HD images (png, jpg & gif), videos (mp4, pgg, webm) and documents (doc, dox, pdf, ...)')"
                />
            </template>
            <template v-else>
                <label>
                    <t>Attach an url</t> *
                </label>
                <HtInputText
                    :id="'url'"
                    ref="urlInput"
                    v-model="companyUserTraining.url"
                    v-validate.disable="'required|valid_link'"
                    name="url"
                    cypress="url"
                    :placeholder="translate('Copy-paste a link')"
                    :data-vv-as="translate('url')"
                />
                <div
                    v-for="(errorMessage, index) in errors.collect('url')"
                    :key="index"
                    class="error-message"
                >
                    <span v-if="errorMessage"><t>{{ errorMessage }}</t></span>
                </div>
            </template>
        </HtFormCard>

        <fieldset class="medium mt-4">
            <HtFormEditor
                id="specific_information"
                v-model="companyUserTraining.specific_information"
                name="specific_information"
                cypress="specific_information"
                :label="translate('Specific information for {firstname}', {'firstname' : companyUserProgram.company_user.firstname})"
                :has-toolbar="false"
            />
        </fieldset>

        <HtUserProgramList
            v-model="companyUserTraining.company_user_program_id"
            :company-user-id="companyUserId"
            :disabled="true"
        />

        <HtKeyDatesInputOrderForDependency
            v-if="hasDependency"
            :user-program-id="companyUserTraining.company_user_program_id"
            :resource-id="companyUserTraining.id"
            resource-type="company_document"
            :is-business-day="userTask.is_business_day"
            :end-date="userTask.datetime_end"
            :dependents="userTask.dependents.models"
            :dependencies="userTask.dependencies.models"
            :should-show-availability-date="!userTask.dependents.models.length"
        />

        <HtKeyDatesInputOrder
            v-else-if="userTask"
            :user-program-id="companyUserTraining.company_user_program_id"
            :resource-id="companyUserTraining.id"
            resource-type="company_document"
            :is-business-day.sync="userTask.is_business_day"
            :offset-key-date.sync="userTask.company_user_program_key_date_id"
            :offset-availability-specific-date.sync="userTask.datetime_availability"
            :offset-availability-number.sync="userTask.offset_availability_number"
            :offset-availability-unit.sync="userTask.offset_availability_unit"
            :offset-end-specific-date.sync="userTask.datetime_end"
            :offset-end-number.sync="userTask.offset_end_number"
            :offset-end-unit.sync="userTask.offset_end_unit"
            can-select-specific-date
            :dependents="userTask.dependents.models"
            :dependencies="userTask.dependencies.models"
            :disabled="userTask && userTask.dependencies.models.length > 0"
        />

        <div class="modal-actions right">
            <Button
                v-if="isNew === false"
                class="negative"
                cypress="training-delete-button"
                @click="onDelete()"
            >
                <t>Remove</t>
            </Button>
            <Button
                :state="buttonState"
                cypress="training-save-button"
                @click="onUpdate()"
            >
                <t>{{ isNew ? 'Add' : 'Save' }}</t>
            </Button>
        </div>
    </form>
</template>

<script>
import CompanyUserProgram from '@/models/CompanyUserProgram';
import CompanyUserTraining from '@/models/CompanyUserTraining';
import HtTextDescription from '@/components/globals/HtTextDescription.vue';
import UploadBar from '@/components/globals/UploadBar/UploadBar.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import HtInputText from '@/components/globals/HtInputText.vue';
import HtUserProgramList from '@/components/globals/HtUserProgramList.vue';
import TaskDependencyMixin from '@/mixins/TaskDependencyMixin';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import HtKeyDatesInputOrderForDependency from '@/components/globals/HtKeyDatesInputOrderForDependency.vue';

export default {
    name: 'UserTrainingEdit',
    components: {
        HtKeyDatesInputOrder,
        HtKeyDatesInputOrderForDependency,
        HtTextDescription,
        HtUserProgramList,
        UploadBar,
        HtFormCard,
        HtInputText,
    },

    mixins: [
        TaskDependencyMixin,
    ],

    inject: ['modal'],

    props: {
        id: {
            type: Number,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
        isNew: {
            type: Boolean,
            default: false,
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },

    data() {
        return {
            hasDependency: false,
            userTask: null,
            buttonState: 'idle',
            companyUserProgram: new CompanyUserProgram([
                'id',
            ]).with({
                mainKeyDate: (query) => {
                    query.select(['id', 'starts_at']);
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname']);
                },
                companyProgram: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            keyDate: (query) => {
                                query.with({
                                    locales: (query) => query
                                        .select(['id', 'name', 'language_key']),
                                });
                            },
                        });
                },
            }).where([
                ['id', '=', this.companyUserProgramId],
            ]),

            companyUserTraining: new CompanyUserTraining([
                'id',
                'company_user_program_id',
                'company_user_id',
                'company_user_program_task_id',
                'name',
                'status',
                'description',
                'type',
                'specific_information',
                'company_file_id',
                'url',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'title',
                        'resource',
                        'resource_id',
                        'company_user_program_key_date_id',
                        'offset_availability_number',
                        'offset_availability_unit',
                        'offset_end_number',
                        'offset_end_unit',
                        'is_business_day',
                        'datetime_availability',
                        'datetime_end',
                    ]).with({
                        dependencies: (query) => {
                            query.select([
                                'id',
                                'resourceable_type',
                            ]);
                        },
                        dependents: (query) => {
                            query.select([
                                'id',
                            ]);
                        },
                    });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'arrival_date']);
                },
                companyFile: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
            }).where([
                ['id', '=', this.id],
            ]),
        };
    },

    computed: {
        isLoaded() {
            return (this.companyUserTraining.isLoaded() || this.isNew) && this.companyUserProgram.isLoaded();
        },
    },

    watch: {
        'companyUserTraining._state.isSaving': function (value) {
            this.buttonState = value ? 'loading' : 'idle';
        },
    },

    created() {
        this.companyUserProgram.get();

        this.companyUserTraining.company_user_id = this.companyUserId;
        this.companyUserTraining.company_user_program_id = this.companyUserProgramId;

        if (this.isNew) {
            this.modal.setTitle(this.translate('Add training'));
            this.createTaskWithDependents(this.companyUserTraining);
            this.userTask = this.companyUserTraining.company_user_program_task;
        } else {
            this.companyUserTraining.get().then((companyUserTraining) => {
                this.userTask = this.companyUserTraining.company_user_program_task;
                this.modal.setTitle(companyUserTraining.$.name);
                this.hasDependency = this.userTask.dependents.models.length > 0;
            });
        }
    },

    methods: {
        onDelete() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this training? Once you click on delete, you will no longer be able to access this training.') }).then((result) => {
                if (result) {
                    this.companyUserTraining.delete().then(() => {
                        this.modal.close();
                        this.$emit('onDelete');
                    });
                }
            });
        },
        onUpdate() {
            this.$validator.validateAll().then((result) => {
                if (result === true) {
                    this.companyUserTraining.save().then(() => {
                        this.modal.close();
                        this.$emit('onUpdate');
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss" src="./UserTrainingEdit.scss" scoped />
