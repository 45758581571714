<template>
    <div
        class="checkbox-label"
        :class="{
            disabled,
        }"
    >
        <input
            :id="id"
            type="checkbox"
            :name="name"
            :disabled="disabled"
            :checked="checked ? 'checked' : ''"
        >
        <span
            class="checkbox-display"
            :class="{
                disabled,
                checked,
                partiallyChecked,
            }"
        >
            <FontAwesomeIcon
                v-if="checked"
                icon="check"
                class="check"
            />
        </span>
        <div v-if="label">
            {{ label }}
        </div>
    </div>
</template>

<script>
import HtFormMixin from '../HtFormMixin';

export default {
    name: 'HtFormCheckbox',
    mixins: [
        HtFormMixin,
    ],

    props: {
        checked: {
            type: Boolean,
            default: false,
        },
        partiallyChecked: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

input[type='checkbox'] {
  display: none;
}
.checkbox-label{
    display: flex;
    align-items: center;
    gap: 8px;

    & > div {
        line-height: 100%;
    }
    &.disabled {
        color: $neutral-500;
    }
    .checkbox-display {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 1px solid $neutral-500;
        display: flex;
        align-items: center;
        justify-content: center;

        .check{
            color: $neutral-white;
            width: 8px;
            height: 8px;
        }

        &.partiallyChecked:not(.checked){
            border: 1px solid $primary;
            background: $primary;
            position: relative;

            &::after{
                content: ' ';
                background: $neutral-white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 50%;
                height: 2px;
            }
        }
        &.checked {
            border: 1px solid $primary;
            background: $primary;
        }
        &.disabled {
            border: 1px solid $neutral-500;
            &.checked {
                background: $neutral-500;
            }
        }
    }
}

</style>
