import PageRoleForm from '@/router/pages/settings/RolesAndPermissions/PageRoleForm.vue';
import PageEntitiesForm from '@/router/pages/settings/Entities/PageEntitiesForm.vue';
import LayoutSettings from '@/layouts/Settings.vue';
import Users from '@/router/pages/settings/Users.vue';
import RolesAndPermissions from '@/router/pages/settings/RolesAndPermissions.vue';
import SettingsCompany from '@/components/settings/PageCompany.vue';
import SettingsNotifications from '@/router/pages/settings/NotificationList.vue';
import Entities from '@/router/pages/settings/Entities.vue';
import SettingsPreboarding from '@/components/settings/PagePreboarding.vue';
import SettingsSecurity from '@/components/settings/PageSecurity.vue';
import CustomFields from '@/router/pages/settings/CustomFields.vue';
import SettingsCommunication from '@/components/settings/PageCommunication.vue';
import Integrations from '@/router/pages/settings/Integrations.vue';
import SettingsDataAnalysis from '@/router/pages/settings/DataAnalysis.vue';
import SettingsLanguages from '@/components/settings/PageLanguage.vue';
import SettingsLogs from '@/router/pages/settings/Logs.vue';
import AISettings from '@/router/pages/settings/AI.vue';
import InviteMembers from '@/pages/InviteMembers.vue';
import InviteGsuite from '@/pages/InviteGsuite.vue';
import InviteManuallyUser from '@/pages/InviteManuallyUser.vue';
import SettingsEnterprisePage from '@/router/pages/settings/SettingsEnterprisePage.vue';
import SettingsEnterprisePageForm from '@/router/pages/settings/SettingsEnterprisePageForm.vue';

export default [
    {
        path: '/Settings/RolesAndPermissions/Form/:id?',
        name: 'SettingsRolesAndPermissionsForm',
        component: PageRoleForm,
        meta: {
            permission: true,
        },
    },
    {
        path: '/Settings/Entities/Form/:id?',
        name: 'SettingsEntitiesForm',
        component: PageEntitiesForm,
        meta: {
            permission: true,
        },
    },
    {
        path: '/Settings/EnterprisePage/Form/:id?',
        name: 'SettingsEnterprisePageForm',
        component: SettingsEnterprisePageForm,
        meta: {
            permission: true,
        },
    },
    {
        name: 'InviteManuallyUser',
        path: '/Settings/Users/Invite/Manual',
        component: InviteManuallyUser,
    },
    {
        path: '/settings',
        name: 'Settings',
        component: LayoutSettings,
        meta: {
            permission: true,
        },
        children: [
            {
                path: 'Users',
                name: 'SettingsUsers',
                component: Users,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'RolesAndPermissions',
                name: 'SettingsRolesAndPermissions',
                component: RolesAndPermissions,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Platform',
                name: 'SettingsCompany',
                component: SettingsCompany,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'EnterprisePage',
                name: 'SettingsEnterprisePage',
                component: SettingsEnterprisePage,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Notifications',
                name: 'SettingsNotifications',
                component: SettingsNotifications,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Entities',
                name: 'SettingsEntities',
                component: Entities,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Preboarding',
                name: 'SettingsPreboarding',
                component: SettingsPreboarding,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'SecurityAndPrivacy',
                name: 'SettingsSecurityAndPrivacy',
                component: SettingsSecurity,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'CustomFields',
                name: 'SettingsCustomFields',
                component: CustomFields,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Communication',
                name: 'SettingsCommunication',
                component: SettingsCommunication,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Integrations',
                name: 'SettingsIntegrations',
                component: Integrations,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'DataAnalysis',
                name: 'SettingsDataAnalysis',
                component: SettingsDataAnalysis,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Languages',
                name: 'SettingsLanguages',
                component: SettingsLanguages,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Logs',
                name: 'SettingsLogs',
                component: SettingsLogs,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'AI',
                name: 'SettingsAi',
                component: AISettings,
                meta: {
                    permission: true,
                },
            },

        ],
    },
    {
        name: 'InviteUser',
        path: '/settings/members/invite',
        component: InviteMembers,
    },
    {
        path: '/settings/members/invite/gsuite',
        component: InviteGsuite,
    },
];
