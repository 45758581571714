<template>
    <div>
        <ParametersBox :title="translate('Program')" />
        <HtCard class="mb-5">
            <UserProgramItem
                ref="programItem"
                :from="'bop'"
                :company-user-program-id="userProgramId"
                :company-user-id="userId"
            />
        </HtCard>
        <EnrollingFooter>
            <Button
                cypress="foc-previous-program"
                class="inner-box branding-color branding-border-color branding-border-color-darken-hover"
                @click="$emit('on-prev')"
            >
                <t>Previous</t>
            </Button>
            <Button
                cypress="foc-next-program"
                :state="buttonState"
                @click="onSave"
            >
                <t>Finish</t>
            </Button>
        </EnrollingFooter>
    </div>
</template>

<script>
import UserProgramItem from '@/components/program/UserProgramItem.vue';
import ParametersBox from '@/components/globals/ParametersBox.vue';
import EnrollingFooter from './EnrollingFooter.vue';

export default {
    name: 'EnrollingProgram',
    components: {
        UserProgramItem,
        ParametersBox,
        EnrollingFooter,
    },
    props: {
        userId: {
            type: Number,
            required: true,
        },
        userProgramId: {
            type: Number,
            required: true,
        },
        programType: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            buttonState: 'idle',
        };
    },

    methods: {
        onSave() {
            this.buttonState = 'loading';
            this.$http.post(`foc/enrolling/finalize/${this.userProgramId}`).then(async () => {
                await this.$refreshPermissions();
                this.$emit('on-next');
            }).finally(() => {
                this.buttonState = 'idle';
            });
        },
    },
};
</script>
