import Dashboard from '@/router/pages/Dashboard.vue';
import DashboardStatistics from '@/router/pages/dashboard/Statistics.vue';
import DashboardExternal from '@/router/pages/dashboard/External.vue';
import DashboardActions from '@/router/pages/dashboard/Actions.vue';
import DashboardHistory from '@/router/pages/dashboard/History.vue';
import DashboardReport from '@/router/pages/dashboard/Report.vue';
import DashboardSpecificReports from '@/router/pages/dashboard/SpecificReports.vue';
import DashboardPrograms from '@/router/pages/dashboard/Programs.vue';

export default [
    {
        path: '/',
        redirect: {
            name: 'Dashboard',
        },
    },
    {
        path: 'Dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            // permission: true,
        },
        children: [
            {
                path: 'External',
                name: 'DashboardExternal',
                component: DashboardExternal,
                meta: {
                    permission: false,
                },
            },
            {
                path: 'Statistics',
                name: 'DashboardStatistics',
                component: DashboardStatistics,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Actions',
                name: 'DashboardActions',
                component: DashboardActions,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'History',
                name: 'DashboardHistory',
                component: DashboardHistory,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Report',
                name: 'DashboardReport',
                component: DashboardReport,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'SpecificReports',
                name: 'DashboardSpecificReports',
                component: DashboardSpecificReports,
                meta: {
                    permission: true,
                },
            },
        ],
    },
    {
        path: 'Dashboard/Programs',
        name: 'DashboardPrograms',
        component: DashboardPrograms,
        meta: {
            permission: true,
        },
    },
];
