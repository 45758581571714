import Vue from 'vue';

export default {
    getSsoOptions() {
        return Vue.prototype.$http.get('sso/options');
    },

    get() {
        return Vue.prototype.$http.get('sso');
    },

    store(sso) {
        return Vue.prototype.$http.post('sso', sso);
    },

    update(sso) {
        return Vue.prototype.$http.put(`sso/${sso.id}`, sso);
    },

    testConfiguration(value) {
        return Vue.prototype.$http.post('sso/test-configuration', value);
    },
};
