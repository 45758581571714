<template>
    <div class="message-item">
        <div class="message-item-question">
            <MessageTextTemplate :text="name" />
        </div>
        <div class="message-item-answers">
            <component
                :is="component"
                :company-chat-channel-message="companyChatChannelMessage"
                @onAnswers="onAnswers"
                @onSkip="skip"
                @focus="$emit('focus')"
                @blur="$emit('blur')"
            />
        </div>
        <div
            v-if="showSkip"
            class="message-item-skip"
        >
            <span @click="skip">
                <t>Skip</t>
            </span>
        </div>
    </div>
</template>

<script>
import CompanyChatChannelMessage from '@/models/CompanyChatChannelMessage';
import CompanyUserSurveyQuestion from '@/models/CompanyUserSurveyQuestion';

import resourceEventBus from '@/eventBus/resourceEventBus';
import MessageTextTemplate from './MessageTextTemplate.vue';
import MessageResourceUserSurveyQuestionText from './MessageResourceUserSurveyQuestionText.vue';
import MessageResourceUserSurveyQuestionYesNo from './MessageResourceUserSurveyQuestionYesNo.vue';
import MessageResourceUserSurveyQuestionSimpleChoice from './MessageResourceUserSurveyQuestionSimpleChoice.vue';
import MessageResourceUserSurveyQuestionMultipleChoice from './MessageResourceUserSurveyQuestionMultipleChoice.vue';
import MessageResourceUserSurveyQuestionSatisfactionScale from './MessageResourceUserSurveyQuestionSatisfactionScale.vue';
import MessageResourceUserSurveyQuestionFileUpload from './MessageResourceUserSurveyQuestionFileUpload.vue';
import MessageResourceUserSurveyQuestionRequirement from './MessageResourceUserSurveyQuestionRequirement.vue';

export default {
    name: 'MessageResourceUserSurveyQuestionTemplate',
    components: {
        MessageTextTemplate,
    },
    props: {
        companyChatChannelMessage: {
            type: CompanyChatChannelMessage,
            required: true,
        },
    },

    computed: {
        showSkip() {
            return this.companySurveyQuestion.is_mandatory === false
                && this.companyUserSurveyQuestion.is_skipped === false
                && this.companyUserSurveyQuestion.status !== 'done'
                && this.companySurveyQuestion.type !== 'multiple_choice'
                && this.companySurveyQuestion.type !== 'simple_choice'
                && this.companySurveyQuestion.type !== 'text';
        },
        name() {
            return this.companySurveyQuestion.text;
        },
        companyUserSurveyQuestion() {
            return this.companyChatChannelMessage.company_user_survey_question;
        },
        companySurveyQuestion() {
            return this.companyUserSurveyQuestion.company_survey_question;
        },
        component() {
            switch (this.companySurveyQuestion.type) {
            case 'text':
                return MessageResourceUserSurveyQuestionText;
            case 'yes_no':
                return MessageResourceUserSurveyQuestionYesNo;
            case 'simple_choice':
                return MessageResourceUserSurveyQuestionSimpleChoice;
            case 'multiple_choice':
                return MessageResourceUserSurveyQuestionMultipleChoice;
            case 'satisfaction_scale':
                return MessageResourceUserSurveyQuestionSatisfactionScale;
            case 'file_upload':
                return MessageResourceUserSurveyQuestionFileUpload;
            case 'requirement':
                return MessageResourceUserSurveyQuestionRequirement;
            }
        },
    },

    methods: {
        /**
         * @param {CompanyUserSurveyQuestionAnswerCollection} answers
         */
        questionActedUpon(answers) {
            this.companyUserSurveyQuestion.status = 'done';

            const companyUserSurveyQuestion = new CompanyUserSurveyQuestion([
                'id', 'company_user_survey_id', 'is_skipped',
            ]).with({
                companyUserSurvey: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            companyUserProgramTask: (query) => {
                                query.select([
                                    'id',
                                    'status',
                                ]);
                            },
                        });
                },
                companyUserSurveyQuestionAnswer: (query) => {
                    query.select([
                        'id',
                        'company_survey_question_answer_id',
                        'text',
                        'company_file_id',
                        'resourceable_id',
                        'resourceable_type',
                    ]);
                },
            });

            companyUserSurveyQuestion.id = this.companyUserSurveyQuestion.id;
            companyUserSurveyQuestion.is_skipped = !answers;

            if (answers) {
                answers.models.forEach((item) => {
                    const answer1 = companyUserSurveyQuestion.company_user_survey_question_answer.new();
                    answer1.company_survey_question_answer_id = item.company_survey_question_answer_id;
                    answer1.text = item.text;
                    answer1.resourceable_id = item.resourceable_id;
                    answer1.resourceable_type = item.resourceable_type;
                    answer1.company_file_id = item.company_file_id;
                    companyUserSurveyQuestion.company_user_survey_question_answer.models.push(answer1);

                    const answer2 = this.companyUserSurveyQuestion.company_user_survey_question_answer.new();
                    answer2.company_survey_question_answer_id = item.company_survey_question_answer_id;
                    answer2.text = item.text;
                    answer2.resourceable_id = item.resourceable_id;
                    answer2.resourceable_type = item.resourceable_type;
                    answer2.company_file_id = item.company_file_id;
                    this.companyUserSurveyQuestion.company_user_survey_question_answer.models.push(answer2);
                });
            }
            companyUserSurveyQuestion.save({ method: 'storeAnswers' }).then((companyUserSurveyQuestion) => {
                if (companyUserSurveyQuestion.company_user_survey.company_user_program_task.status === 'done') {
                    resourceEventBus.$emit(
                        'survey-done',
                        companyUserSurveyQuestion.company_user_survey.id,
                    );
                }
            });
        },
        skip() {
            this.companyChatChannelMessage.company_user_survey_question.is_skipped = true;
            this.questionActedUpon();
        },
        /**
         * @param {CompanyUserSurveyQuestionAnswerCollection} answers
         */
        onAnswers(answers) {
            this.questionActedUpon(answers);
        },
    },
};
</script>

<style lang="scss" scoped src="./MessageResourceUserSurveyQuestionTemplate.scss"></style>
