<template>
    <div>
        <HtReadResourcesInfo
            v-if="resourceData.type === 'other'"
            label="File"
        >
            <UploadBar
                :value="resourceData.data"
                :media="['office', 'pdf', 'image']"
                :with-trash="false"
            />
        </HtReadResourcesInfo>
        <HtReadResourcesInfo
            v-if="resourceData.type === 'video'"
            label="Video"
        >
            <video
                controls
                controlsList="nodownload"
                width="50%"
                @click.stop
            >
                <source :src="resourceData.data.link">
            </video>
        </HtReadResourcesInfo>
        <HtReadResourcesInfo
            v-if="resourceData.type === 'url'"
            label="Url"
        >
            <a
                :href="resourceData.data"
                :target="'_blank'"
            >
                {{ resourceData.data }}
            </a>
        </HtReadResourcesInfo>
    </div>
</template>

<script>
import HtReadResourcesInfo from '@/components/globals/modals/HtReadResources/HtReadResourcesInfo.vue';
import UploadBar from '@/components/globals/UploadBar/UploadBar.vue';

export default {
    components: {
        HtReadResourcesInfo,
        UploadBar,
    },

    props: {
        resourceData: {
            type: Object,
            default: null,
        },
    },
};
</script>
