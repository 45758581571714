<script>
import Collection from '@tony.caron/node-model-proxy/ModelCollection';
import HtTablePagination from './HtTablePagination.vue';

export default {
    name: 'HtTableModel',
    extends: HtTablePagination,
    props: {
        collection: {
            type: Collection,
            required: true,
        },
    },
    computed: {
        lastPage() {
            return this.paginationData._pagination.nbPage;
        },

        /**
         * Items à afficher dans la table
         * @returns {Array}
         */
        items() {
            return this.paginationData.$.models;
        },
    },
    watch: {
        collection() {
            // refresh la collection dès qu'elle est modifiée
            if (!this.collection.isLoaded()) {
                this.refresh();
            }
        },
    },
    methods: {
        async load() {
            this.isLoaded = false;
            this.paginationData = await this.collection.paginate({
                page: this.page,
                perPage: this.perPage,
            });
            this.isLoaded = true;
        },
    },
};
</script>
