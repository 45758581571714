<template>
    <div class="chat-group-manager">
        <div v-show="show.main">
            <div class="chat-group-manager-idle">
                <div
                    v-if="channel.type=== 'multi'"
                    class="chat-group-manager-infos"
                >
                    <div class="chat-group-manager-infos-text">
                        <div class="chat-group-manager-infos-text-name">
                            {{ name }}
                        </div>
                        <div class="chat-group-manager-infos-text-count">
                            {{ participants.length + 1 }} <t> participants</t>
                        </div>
                    </div>
                </div>

                <ChatGroupManagerActionList
                    :actions="actions"
                    @emit="toggleView"
                />
            </div>
        </div>
        <ChatConversationManager
            v-if="show.edit"
            :channel="channel"
            @cancel="toggleView('main')"
            @close="$emit('close')"
        />
        <!-- STATE SEE IMAGES -->
        <ChatGroupManagerSeeImages
            v-if="show.images && channel && channel.id && channel.id !== 'new'"
            :channel-id="channel.id"
            @fileLoaded="(payload) => $emit('fileLoaded', payload)"
        />
        <!-- STATE SEE FILES -->
        <ChatGroupManagerSeeFiles
            v-if="show.files && channel && channel.id && channel.id !== 'new'"
            :channel-id="channel.id"
            @fileLoaded="(payload) => $emit('fileLoaded', payload)"
        />
    </div>
</template>

<script>
import CompanyChatChannel from '@/models/CompanyChatChannel';
import ChatGroupManagerActionList from '@/components/chat/chatGroupManager/ChatGroupManagerActionList.vue';
import ChatGroupManagerSeeFiles from './chatGroupManager/ChatGroupManagerSeeFiles.vue';
import ChatGroupManagerSeeImages from './chatGroupManager/ChatGroupManagerSeeImages.vue';
import ChatConversationManager from './ChatConversationManager.vue';

export default {
    name: 'ChatConversationInfo',
    components: {
        ChatGroupManagerActionList,
        ChatGroupManagerSeeFiles,
        ChatGroupManagerSeeImages,
        ChatConversationManager,
    },
    props: {
        channel: {
            type: CompanyChatChannel,
            default: null,
        },
    },
    data() {
        return {
            show: {
                main: true,
                edit: false,
                images: false,
                files: false,
            },
        };
    },
    computed: {
        actions() {
            const actions = this.channel.id !== 'new' ? [
                {
                    text: this.translate('Shared Images'),
                    subtext: '',
                    icon: ['fal', 'image'],
                    event: 'images',
                },
                {
                    text: this.translate('Shared Files'),
                    subtext: '',
                    icon: ['fal', 'file'],
                    event: 'files',
                },
            ] : [];
            if (this.channel.type === 'multi') {
                actions.unshift({
                    text: this.translate('Members'),
                    subtext: '',
                    icon: ['fal', 'user-friends'],
                    event: 'edit',
                });
            }
            return actions;
        },
        currentView() {
            let current = 'main';
            for (const key in this.show) {
                if (this.show[key]) {
                    current = key;
                }
            }

            return current;
        },
        participants() {
            return this.channel?.company_chat_channel_user.models
                .filter((x) => x.company_user_id !== this.shared.session.companyUser.id)
                .map((x) => x.company_user) || [];
        },
        name() {
            let name = '';
            let count = 0;
            let overCount = 0;

            this.channel.company_chat_channel_user.models.forEach((user) => {
                if (user.company_user_id !== this.shared.session.companyUser.id) {
                    if (count < 3) {
                        name += `${user.company_user.firstname}, `;
                        count += 1;
                    } else {
                        if (overCount === 0) {
                            name = `${name.substr(0, name.length - 2)} ... `;
                        }
                        overCount += 1;
                    }
                }
            });

            if (overCount === 0) {
                return name.substr(0, name.length - 2);
            }

            return `${name}+ ${overCount}`;
        },
    },
    methods: {
        toggleView(view) {
            for (const key in this.show) {
                if (Object.prototype.hasOwnProperty.call(this.show, key)) {
                    this.show[key] = key === view;
                }
            }
            this.$emit('change-view');
        },

    },
};
</script>

<style lang="scss" scoped>
.chat-group-manager {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: hidden;

    &-infos {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin-top: 10px;

        &-text {
            &-name {
                font-size: 1.3em;
                font-weight: bold;
            }

            &-count {
                font-size: 1.2rem;
                color: lightgray;
            }
        }

    }
}

</style>
