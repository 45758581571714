<template>
    <div class="user-requirement-file-to-fill-edit">
        <UploadBar
            v-if="companyUserRequirement.company_file_id"
            v-model="companyUserRequirement.company_file"
            :with-edit="true"
            :with-trash="false"
            :media="['pdf']"
            :is-requirement="true"
            cypress="requirement-file-to-fill"
            @on-edit="onOpenDocumentEditor(true)"
        />
        <Button
            v-else
            @click="onOpenDocumentEditor"
        >
            <t>Complete the pdf</t>
        </Button>
        <div
            v-if="companyUserRequirement.company_file_id"
            class="completion-status"
        >
            <span>&#10003;</span> <t>document completed</t>
        </div>
        <div
            v-else-if="companyUserRequirement.sign_after_complete"
            class="to-complete-status"
        >
            <t>Complete the document before signing it</t>
        </div>
        <ModalableLarge
            ref="documentEditorModal"
            class="modalable-1 small"
            :mode="2"
        >
            <EnrolleeEditor
                :document-id.sync="requirement.company_user_pdf_form_id"
                :company-file="requirement.company_file_secondary"
                @onUpdate="(isComplete) => processFileToFill(true, isComplete)"
            />
        </ModalableLarge>
    </div>
</template>
<script>
import EnrolleeEditor from '@/components/DocumentEditor/EnrolleeEditor.vue';
import UploadBar from '@/components/globals/UploadBar/UploadBar.vue';
import UserRequirementEditMixin from '../UserRequirementEditMixin';
import FileToFillMixin from './FileToFillMixin';

export default {
    components: {
        EnrolleeEditor,
        UploadBar,
    },

    mixins: [UserRequirementEditMixin, FileToFillMixin],

    methods: {
        onOpenDocumentEditor() {
            this.$refs.documentEditorModal.open();
        },
    },
};
</script>
<style lang="scss" src="./UserRequirementFileToFillEdit.scss" scoped />
