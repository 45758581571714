const csrfKey = '_csrf_token_';

/**
 * @return {string|null} The CSRF token or null if not found
 */
export function getCsrfToken() {
    return window.localStorage.getItem(csrfKey);
}

/**
 * @param {string|null} newCsrfToken The new CSRF token to be stored
 * @return {void}
 */
export function updateCsrfToken(newCsrfToken) {
    window.localStorage.setItem(csrfKey, newCsrfToken);
}

/**
 * @return {void}
 */
export function removeCsrfToken() {
    updateCsrfToken(null);
}
