<template>
    <div
        class="item-text position-relative"
        :class="customClass"
    >
        <div
            class="spinner"
        />
    </div>
</template>

<script>
export default {
    name: 'ThinkingLoader',
    props: {
        isMe: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        customClass() {
            return {
                'branding-bg-important': this.isMe,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.item-text {
  display: inline-block;
  border-radius: 5px;
  padding: 15px;
  word-break: break-word;
  background-color: #ECE9E9;
  white-space: pre-line;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 2px solid #0c0c0c;
  border-right: 2px solid transparent;
  animation: spinner .6s linear infinite;
}
</style>
