import moment from 'moment';
import 'moment-timezone';

export default {
    methods: {
        formatTimezoneOffset(offset) {
            const sign = offset >= 0 ? '+' : '-';
            const hours = `${Math.abs(Math.floor(offset / 60))}`.padStart(2, '0');
            const minutes = `${offset - Math.floor(offset / 60) * 60}`.padStart(2, '0');
            return `${sign}${hours}:${minutes}`;
        },
        formatTimezoneName(timezone) {
            const offset = moment.tz.zone(timezone).utcOffset(Date.now());
            // fixme: replaceAll function arrived in ES12+, we need to use replace with "/g" regex to be compatible with chrome browser <=v79 (used by Kiloutou)
            return `${timezone.replace('_/g', ' ')} (UTC ${this.formatTimezoneOffset(
                -offset, // Yes, the offset is actually inverted for some reason https://momentjs.com/timezone/docs/#/zone-object/offset/
            )})`;
        },
        getAvailableTimeZones() {
            return moment.tz.names();
        },
    },
};
