<template>
    <div class="search">
        <svg class="icon icon-glass"><use xlink:href="#icon-glass" /></svg>
        <input
            v-model="searchInput"
            class="s-input"
            :placeholder="translate(placeholder)"
            type="text"
        >
    </div>
</template>

<script>
export default {
    name: 'FilterableSearch',
    props: {
        value: { type: Array, default: () => [] },
        propKeys: { type: Array, required: true },
        placeholder: { type: String, default: 'Search' },
    },

    data() {
        return {
            searchInput: '',
            initialArr: [], // gets set from prop during mount
        };
    },

    watch: {
        searchInput(val) {
            this.$emit('searchInput', val);
            this.$emit('input', this.filterData(val));
        },
    },

    mounted() {
        this.refreshScope(this.value);
    },

    methods: {
        refreshScope(value) {
            this.initialArr = value;
        },

        filterData(input) {
            const checkMatch = (item, input) => this.propKeys.some((key) => {
                const keys = key.includes('.') ? key.split('.') : [key];
                const prop = keys.reduce((accum, key) => accum[key], item);

                return prop && prop.toString().toLowerCase().includes(input.toLowerCase());
            });

            const checkInput = (item) => {
                const split = input.split(' ');
                return split.filter((value) => checkMatch(item, value)).length === split.length;
            };

            return !input ? this.initialArr : this.initialArr.filter((item) => checkInput(item));
        },
        reset() {
            this.searchInput = '';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.search {
	position: relative;
}

.s-input {
	outline: none;
    border: 1px solid $border-grey-light;
    padding: 0.5em 1em 0.5em 2.5em;
	height: 48px;
	border-radius: 5px;
	width: 100%;
	font-size: 0.9em;
}

.icon-glass {
    position: absolute;
    height: 15px;
    width: 15px;
    top: 17px;
    left: 13px;
}

</style>
