<template>
    <div>
        <div class="row justify-content-between">
            <div class="col">
                <b>
                    <t>Requirement name</t>
                </b>
            </div>
            <div class="col text-right">
                {{ requirementName }}

                <span
                    v-if="dynamicDocumentTooltip"
                    v-tooltip.right-end="dynamicDocumentTooltip"
                    class="cursor-help ml-3"
                >
                    <FontAwesomeIcon :icon="['fal', 'file-pdf']" />
                </span>
            </div>
        </div>
        <HtDivider class="my-4" />
        <div class="row justify-content-between">
            <div class="col">
                <b>
                    <t>Requirement type</t>
                </b>
            </div>
            <div class="col text-right">
                {{ getRequirementType }}
            </div>
        </div>
        <HtDivider class="my-4" />
        <div class="row justify-content-between">
            <div class="col">
                <b>
                    <t>Requirement mandatory</t>
                </b>
            </div>
            <div class="col text-right">
                <t>{{ companyRequirement.is_mandatory === true? 'Yes' : 'No' }}</t>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyRequirement from '@/models/CompanyRequirement';
import HtDivider from '@/components/globals/HtDivider.vue';

export default {
    name: 'SingleRequirementRead',
    components: { HtDivider },
    props: {
        companyRequirement: {
            type: CompanyRequirement,
            required: true,
        },
        dynamicDocumentTooltip: {
            type: String,
            default: () => null,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            showEdit: false,
        };
    },

    computed: {
        requirementName() {
            const lang = this.shared.session.company.company_language.key;
            return this.companyRequirement.translations.name[lang];
        },
        getRequirementType() {
            const reqType = CompanyRequirement.getRequirementTypes().find(
                (item) => (item.value === this.companyRequirement.type),
            );
            return this.translate(reqType ? reqType.label : 'Text');
        },
    },

    methods: {
        showEditIcon(is_show) {
            this.showEdit = is_show;
        },
    },
};
</script>
