<template>
    <div class="smartrecruiters">
        <div class="smartrecruiters__image">
            <img :src="smartRecruitersConfig.image">
        </div>
        <div class="smartrecruiters__description">
            <t>
                By activating this connector, you will be able, once you validated a new hire on SmartRecruiters,
                to create automatically an onboarding draft on the HeyTeam platform.
            </t>
        </div>
    </div>
</template>

<script>
import OAuth from '@/OAuth';
import { showNotification } from '@/helpers/ui';

export default {
    name: 'SmartRecruitersEdit',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },

    computed: {
        integration() {
            return this.value;
        },
        smartRecruitersConfig() {
            return this.$store.getters['config/getSmartRecruitersConfig'];
        },
    },

    methods: {
        async onValidate() {
            OAuth.auth({
                scope: this.smartRecruitersConfig.scope,
                url: this.smartRecruitersConfig.auth_url,
                client_id: this.smartRecruitersConfig.client_id,
                return_url: this.smartRecruitersConfig.redirect_uri,
            }, {
                200: ({ code }) => {
                    this.integration.config.code = code;
                    this.integration.is_enabled = true;

                    this.$emit('on-save');
                },
                403: () => {
                    showNotification(this.translate('Sorry we are unable to connect to this api'), 'error');
                },
            });
        },
        async onCancel() {
            this.integration.is_enabled = false;

            this.$emit('on-save');
        },
    },
};
</script>

<style lang="scss" scoped>
.smartrecruiters__image {
    text-align: center;
    margin-bottom: 20px;

    > img {
        width: 100px;
    }
}
</style>
