<template>
    <HtFormMultiSelect
        :id="id"
        :value="selection"
        :options="options"
        :label="label"
        :name="name"
        :is-single="true"
        :allow-empty="allowEmpty"
        :show-optional="showOptional"
        :placeholder="placeholder"
        :disabled="disabled"
        :description="description"
        :max-height="maxHeight"
        :open-direction="openDirection"
        :show-asterisk="showAsterisk"
        :cypress="cypress"
        @input="selectionChanged"
    />
</template>

<script>
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import HtFormMixin from './HtFormMixin';
import HtFormMultiSelect from './HtFormMultiSelect.vue';

export default {
    name: 'HtFormSelect',
    components: { HtFormMultiSelect },

    mixins: [
        HtFormMixin,
    ],

    props: {
        value: {
        },
        options: {
            type: Array,
            default: () => [],
        },
        allowEmpty: {
            type: Boolean,
            default: false,
        },
        openDirection: {
            type: String,
            default: 'bottom',
        },
        maxHeight: {
            type: Number,
        },
    },

    computed: {
        selection() {
            return this.options.find((option) => isEqual(option.id, this.value));
        },
    },

    methods: {
        selectionChanged(newSelection) {
            this.$emit('input', get(newSelection, 'id', null));
        },
    },
};
</script>

<style lang="scss" scope src="./HtFormSelect.scss"></style>
