export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },

        question: {
            type: Object,
            required: true,
        },

        previousOccurrence: {
            type: Object,
            required: false,
            default: null,
        },

        isReadOnly: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        hasPreviousOccurrenceSection() {
            return this.question.template.show_previous_occurrence && this.previousOccurrence;
        },
    },

    methods: {
        choiceIsSelected(choice) {
            if (this.value.length < 1) {
                return false;
            }

            return this.value.find((v) => parseInt(v.company_survey_question_answer_id, 10) === parseInt(choice.id, 10));
        },

        selectChoice(choice) {
            if (this.isReadOnly) {
                return;
            }

            this.question.is_edited = true;

            this.$emit('input', [
                {
                    id: this.value[0]?.id || null,
                    company_survey_question_answer_id: choice.id,
                },
            ]);
        },

        hasValidValue() {
            return this.value.length > 0;
        },
    },
};
