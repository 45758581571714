import Model from '@tony.caron/node-model-proxy/Model';
import CompanyFieldTemplateCollection from './CompanyFieldTemplateCollection';

export default class CompanyFieldTemplate extends Model {
    modelConfig() {
        return {
            collection: CompanyFieldTemplateCollection,
        };
    }

    modelCustomAttributes() {
        return {
            /**
             * @type {{field_is_editable: boolean, is_showable: boolean, is_removable: boolean}}
             */
            options: [],
        };
    }
}
