import DoubleCardImgLeftSection from './DoubleCardImgLeftSection';

export default {
    name: 'Double cards image right',
    image: 'double-card-img-right.png',
    fullPageBorder: true,
    content: DoubleCardImgLeftSection.content,
    css: `
        ${DoubleCardImgLeftSection.css}

        .card {
            flex-direction: row-reverse;
        }

        @media (max-width: 768px) {
            .card {
                flex-direction: row-reverse;
            }
        }

        @media (max-width: 376px) {
            .card {
                flex-direction: column;
            }
        }
    `,
};
