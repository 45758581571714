export default {
    name: 'Text with image',
    image: 'text-img.png',
    fullPageBorder: false,
    content: `
        <section class="ht-section-text-img">
            <div class="text">
                <h2 class="title">Lorem ipsum.</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam cumque dolor dolorem dolores incidunt ipsum mollitia nulla optio, quaerat sequi. Alias aliquid corporis ducimus enim eos et magni nam necessitatibus nobis quaerat quo, quos similique tempore ut vero! Cumque inventore modi placeat! Eligendi molestiae mollitia quia reiciendis veniam vitae voluptatum!</p>
                <p>&nbsp;</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus consectetur culpa dicta eligendi error, et eum ex, harum ipsam itaque laboriosam laudantium libero magni modi molestiae neque nesciunt nisi officiis possimus, quaerat quas qui repudiandae rerum! A amet eaque eum!</p>
                <p>&nbsp;</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium, architecto aut beatae deserunt dolores exercitationem porro praesentium quas quidem quisquam quos ullam velit vero vitae?</p>
                <p>&nbsp;</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam aliquid amet aspernatur beatae commodi consectetur deleniti dignissimos dolore dolores doloribus ea esse eveniet exercitationem fuga fugiat fugit illo in iste laboriosam maxime odio optio perferendis possimus quas quasi qui quibusdam quo rem repellat sequi tempora totam ut veniam vero voluptate, voluptates voluptatibus. Aut blanditiis dicta dolores doloribus, neque nisi nostrum numquam quae velit. Assumenda cumque dignissimos ducimus in quas quisquam?</p>
                <p>&nbsp;</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus ea eos facere maiores minima ullam!</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p><strong>Lorem ipsum.</strong> <br />Lorem ipsum dolor sit amet, consectetur.</p>
            </div>
            <div class="image"><img src="https://via.placeholder.com/350x650" alt="Placeholder" width="350" height="650" /></div>
        </section>
    `,
    css: `
        .ht-section-text-img {
            display: flex;
            gap: 32px;
            padding: 40px 0;
            font-family: "Poppins", sans-serif !important;
            line-height: 24px;
            justify-content: center;
            align-items: center;
        }

        .text {
            flex: 1;
        }

        .text p {
            font-size: 16px;
            line-height: 21px;
            font-weight: regular;
        }

        .title {
            font-size: 32px;
            line-height: 36px;
            font-weight: bold;
            color: var(--primary-base);
            margin-bottom: 32px;
        }

        .image {
            display: flex;
            align-items: center;
        }

        .image img {
            border-radius: 50px;
        }

        @media (max-width: 768px) {
            .ht-section-text-img {
                flex-direction: column-reverse;
                align-items: center;
            }

            .text {
                padding: 0 16px;
            }
        }
    `,
};
