<template>
    <div class="docusign">
        <div class="docusign__image">
            <img
                :src="docuSignConfig.image"
                alt="logo docusign"
            >
        </div>

        <div class="docusign__description">
            <template v-if="!value.is_enabled">
                <t v-if="getApiCallStatus === 'pending'">
                    By activating this connector you can use Docusign to sign your electronic documents
                </t>
                <t v-else-if="getApiCallStatus === 'in_progress'">
                    API calls are in progress. Please come back later to activate the service
                </t>
                <t v-else-if="getApiCallStatus === 'done'">
                    Start the Go-Live review on your Docusign account.
                    When the review will be validated, return here to activate Docusign on Heyteam.
                </t>
            </template>
            <t v-else-if="getApiCallStatus === 'in_progress'">
                API calls are in progress. Please come back later to activate the service
            </t>
            <div v-else>
                <t>DocuSign has been activated</t>
                <t v-if="getApiCallStatus === 'pending'">
                    (for testing)
                </t>
            </div>
        </div>
        <div v-if="!value.is_enabled">
            <HtFormInput
                :id="'user-id'"
                v-model="config.client_id"
                v-validate.disable="'required'"
                :class="'mt-5'"
                :name="'user-id'"
                :placeholder="translate('User ID')"
                :label="translate('User ID')"
                :is-required="true"
                :show-asterisk="true"
                :data-vv-as="translate('User ID')"
            />

            <HtFormInput
                :id="'api-account-id'"
                v-model="config.api_account_id"
                v-validate.disable="'required'"
                :name="'api-account-id'"
                :placeholder="translate('API account ID')"
                :label="translate('API account ID')"
                :is-required="true"
                :show-asterisk="true"
                :data-vv-as="translate('API account ID')"
            />

            <HtFormInput
                :id="'integration-key'"
                v-model="config.integration_key"
                v-validate.disable="'required'"
                :name="'integration-key'"
                :placeholder="translate('Integration key')"
                :label="translate('Integration key')"
                :is-required="true"
                :show-asterisk="true"
                :data-vv-as="translate('Integration key')"
            />

            <HtFormTextarea
                :id="'rsa-private-key'"
                v-model="config.rsa_private_key"
                v-validate.disable="'required'"
                :name="'rsa-private-key'"
                :placeholder="translate('RSA private key')"
                :label="translate('RSA private key')"
                :is-required="true"
                :show-asterisk="true"
                :data-vv-as="translate('RSA private key')"
            />
        </div>
    </div>
</template>

<script>
import OAuth from '@/OAuth';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtFormTextarea from '@/components/globals/HtFormTextarea.vue';
import get from 'lodash.get';
import { showNotification } from '@/helpers/ui';

export default {
    name: 'DocuSignEdit',

    components: {
        HtFormTextarea,
        HtFormInput,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },

    computed: {
        config() {
            return this.value.config;
        },
        docuSignConfig() {
            return this.$store.getters['config/getDocuSignConfig'];
        },
        docuSignConfigDemo() {
            return {
                ...this.$store.getters['config/getDocuSignConfig'],
                auth_url: this.$store.getters['config/getDocuSignConfig'].auth_url_demo,
            };
        },
        getApiCallStatus() {
            return get(this.value.custom_validation, 'rules.api_call_status', null);
        },
    },

    methods: {
        async onValidate() {
            this.$validator.validateAll().then((isValid) => {
                if (isValid) {
                    this.oauthDocusign(this.docuSignConfig).then(() => {
                        this.$emit('on-save');
                    });
                }
            });
        },
        async onMakeApiCall() {
            this.$validator.validateAll().then(async (isValid) => {
                if (isValid) {
                    if (!this.value.is_enabled) {
                        await this.oauthDocusign(this.docuSignConfigDemo);
                    }
                    this.$http
                        .put(`miscellaneous/docusign/update-config/${this.value.id}/make-api-call`, this.config)
                        .then(() => {
                            showNotification(
                                this.translate('API calls are in progress. Please come back later to activate the service'),
                            );
                            this.$emit('on-close-modal');
                        })
                        .catch(() => {
                            showNotification(this.translate('Sorry we are unable to connect to this api'), 'error');
                        });
                }
            });
        },

        async oauthDocusign(docuSignConfig) {
            return new Promise((resolve, reject) => {
                OAuth.auth({
                    url: docuSignConfig.auth_url,
                    response_type: 'code',
                    scope: docuSignConfig.scope,
                    client_id: this.config.integration_key,
                    redirect_uri: docuSignConfig.redirect_uri,
                }, {
                    200: () => {
                        resolve();
                    },
                    403: () => {
                        this.$Notifier('App').showError(this.translate('Sorry we are unable to connect to this api'));
                        reject();
                    },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.docusign__image {
    text-align: center;
    margin-bottom: 20px;

    > img {
        width: 100px;
    }
}
</style>
