<template>
    <div class="user-nudge-edit">
        <div
            v-if="companyUserNudge.isLoaded()"
            class="form-2 spaced large-modal-content"
        >
            <HtFormEditor
                id="message"
                v-model="companyUserNudge.message"
                v-validate.disable="'required|max_without_html:500'"
                type="textarea"
                name="message"
                cypress="message"
                :label="translate('Message')"
                :data-vv-as="translate('message in default language')"
                minimal
                class="mb-4 w-100"
            />

            <HtFormUserOrRoleSelect
                id="nudge_initialisator"
                v-model="companyUserNudge.company_user_initiator"
                v-validate.disable="'required'"
                :data-vv-as="translate('selection of a person initiating the nudge')"
                :label="translate('Person initiating the challenge')"
                name="nudge_initialisator"
                cypress="nudge-initialisator"
                show-asterisk
                class="mb-4 w-100"
                @on-add="openAddInitiatorUsersModal"
                @on-remove="onRemoveInitiator"
            />

            <fieldset :class="[{ error: errors.has('nudge_recipient') }, 'medium']">
                <label>
                    <t>Persons receiving the challenge</t>
                </label>
                <transition-group
                    name="fade"
                    class="card-6 flex-container"
                >
                    <template v-if="companyUserNudge.company_user_recipient">
                        <div
                            :key="1"
                            style="display:flex"
                            class="currentNudge"
                        >
                            <UserBlock2
                                v-model="companyUserNudge.company_user_recipient"
                                type="user"
                                cypress="recipient"
                                @onRemove="onRemoveRecipient"
                            />
                        </div>
                    </template>
                    <UserBlock2
                        v-else
                        :key="-1200"
                        cypress="new-recipient"
                        @onAdd="openAddRecipientModal"
                    />
                </transition-group>
                <input
                    id="nudge_recipient"
                    v-validate.disable="'required|array'"
                    v-next-field
                    type="hidden"
                    name="nudge_recipient"
                    :value="companyUserNudge.company_user_recipient ? '-' : null"
                    class="full-width"
                    :data-vv-as="translate('selection of a person receiving the nudge')"
                >
                <div
                    v-for="(errorMessage, index) in errors.collect('nudge_recipient')"
                    :key="index"
                    class="error-message"
                >
                    {{ errorMessage }}
                </div>
            </fieldset>

            <fieldset>
                <HtUserProgramList
                    v-model="companyUserNudge.company_user_program_id"
                    :company-user-id="companyUserId"
                    disabled
                />
            </fieldset>

            <fieldset>
                <HtKeyDatesInputOrder
                    v-if="userTask"
                    :end-label="translate('Challenge submission date')"
                    :end-has-tooltip="false"
                    :user-program-id="companyUserNudge.company_user_program_id"
                    :is-business-day.sync="userTask.is_business_day"
                    :offset-key-date.sync="userTask.company_user_program_key_date_id"
                    :offset-availability-specific-date.sync="userTask.datetime_availability"
                    :offset-availability-number.sync="userTask.offset_availability_number"
                    :offset-availability-unit.sync="userTask.offset_availability_unit"
                    :offset-end-specific-date.sync="userTask.datetime_end"
                    :offset-end-number.sync="userTask.offset_end_number"
                    :offset-end-unit.sync="userTask.offset_end_unit"
                    can-select-specific-date
                />
            </fieldset>

            <fieldset :class="[{ error: errors.has('order') }, 'medium']">
                <div class="full-width">
                    <label>
                        <t>Time to complete the challenge from its acceptance</t>
                        *
                    </label>
                </div>
                <div class="input-order">
                    <input
                        ref="inputDisplayOrder"
                        v-model="companyUserNudge.time_to_complete"
                        v-validate.disable="'required'"
                        v-validate.min_value="1"
                        v-next-field
                        type="number"
                        min="1"
                        name="order"
                        data-cy="order"
                        :data-vv-as="translate('time to complete the challenge from its acceptance')"
                    >
                    <div class="input-order-text">
                        <t :count="companyUserNudge.time_to_complete">
                            day|days
                        </t>
                    </div>
                </div>
                <div
                    v-for="(errorMessage, index) in errors.collect('order')"
                    :key="index"
                    class="error-message"
                >
                    {{ errorMessage }}
                </div>
            </fieldset>

            <div class="modal-actions right">
                <Button
                    v-if="companyUserNudge.permissions.delete"
                    :state="buttonState"
                    class="negative"
                    cypress="nudge-delete-button"
                    @click="onDestroyModal()"
                >
                    <t>Delete</t>
                </Button>
                <Button
                    v-if="companyUserNudge.permissions.update"
                    :state="buttonState"
                    cypress="nudge-save-button"
                    @click="onValidate()"
                >
                    <t>Save</t>
                </Button>
            </div>

            <modalable
                ref="modalableSearchRolePanel"
                class="modalable-1 small"
                :options="{ title: translate('Done by')}"
            >
                <SearchRolePanel
                    ref="searchRolePanel"
                    :has-roles="false"
                    @onValidate="$refs.modalableSearchRolePanel.close()"
                />
            </modalable>
        </div>
        <template v-else>
            <IconLoading />
        </template>
    </div>
</template>

<script>
import HtFormUserOrRoleSelect from '@/components/globals/HtFormUserOrRoleSelect.vue';
import HtUserProgramList from '@/components/globals/HtUserProgramList.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import CompanyUserNudge from '../../../../../models/CompanyUserNudge';
import SearchRolePanel from '../../../../globals/SearchRolePanel.vue';

export default {
    name: 'UserNudgeEdit',
    permissions: [
        'ModelCompanyUserNudge',
    ],
    components: {
        HtKeyDatesInputOrder,
        HtFormUserOrRoleSelect,
        SearchRolePanel,
        HtUserProgramList,
    },
    inject: ['modal'],
    props: {
        id: {
            type: Number,
            default: 0,
        },
        companyUserId: {
            type: Number,
            default: 0,
        },
        intelligentSelection: {
            type: Array,
            default: () => ([]),
        },
    },

    data() {
        return {
            userTask: null,
            buttonState: 'idle',
            companyUserNudge: new CompanyUserNudge([
                'id',
                'company_nudge_id',
                'message',
                'time_to_complete',
                'company_user_program_id',
                'status',
                'company_user_id',
                'company_user_initiator_id',
                'company_user_recipient_id',
                'permissions',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'company_user_program_id',
                        'company_user_program_key_date_id',
                        'offset_availability_number',
                        'offset_availability_unit',
                        'offset_end_number',
                        'offset_end_unit',
                        'is_business_day',
                        'datetime_availability',
                        'datetime_end',
                    ]);
                },
                companyUser: (query) => {
                    query
                        .select(['id', 'firstname', 'lastname', 'arrival_date', 'image', 'company_language_id'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserInitiator: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'arrival_date', 'image']);
                },
                companyUserRecipient: (query) => {
                    query
                        .select(['id', 'firstname', 'lastname', 'gender', 'image', 'company_language_id'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyNudge: (query) => {
                    query.select(['id']);
                    query.with({
                        resource: (query) => {
                            query.select(['name']);
                        },
                    });
                },
                companyUserProgram: (query) => query.select(['id', 'company_program_id']).with({
                    companyProgram: (query) => query.select(['id']).with({
                        keyDate: (query) => query.select(['id', 'company_program_id']).with({
                            locales: (query) => query
                                .select(['id', 'name', 'language_key']),
                        }),
                    }),
                }),
            }),
        };
    },
    computed: {
        companyUserNudgeInitiator: {
            get() {
                if (this.companyUserNudge) {
                    return {
                        company_user_id: this.companyUserNudge.company_user_initiator ? this.companyUserNudge.company_user_initiator.id : null,
                        company_user: this.companyUserNudge.company_user_initiator,
                    };
                }
            },
            set(selectedInitiator) {
                if (selectedInitiator && selectedInitiator.company_user) {
                    this.companyUserNudge.company_user_initiator_id = selectedInitiator.company_user.id;
                    this.companyUserNudge.company_user_initiator = selectedInitiator.company_user;
                } else {
                    this.companyUserNudge.company_user_initiator_id = null;
                    this.companyUserNudge.company_user_initiator = null;
                }
            },
        },
        companyUserRecipient: {
            get() {
                if (this.companyUserNudge) {
                    return {
                        company_user_id: this.companyUserNudge.company_user_recipient ? this.companyUserNudge.company_user_recipient.id : null,
                        company_user: this.companyUserNudge.company_user_recipient,
                    };
                }
            },
            set(selectedRecipient) {
                if (selectedRecipient && selectedRecipient.company_user) {
                    this.companyUserNudge.company_user_recipient_id = selectedRecipient.company_user.id;
                    this.companyUserNudge.company_user_recipient = selectedRecipient.company_user;
                } else {
                    this.companyUserNudge.company_user_recipient_id = null;
                    this.companyUserNudge.company_user_recipient = null;
                }
            },
        },
    },
    created() {
        this.companyUserNudge.where([['id', '=', this.id]]);
        this.companyUserNudge.company_user_id = this.companyUserId;
        this.companyUserNudge.get().then(() => {
            this.userTask = this.companyUserNudge.company_user_program_task;
            this.modal.setTitle(this.companyUserNudge.name);
        }).catch(() => {
            this.modal.close();
        });
    },
    methods: {
        openAddInitiatorUsersModal() {
            this.$refs.searchRolePanel.init({
                title: this.translate('Nudge initiator'),
                selected: [this.companyUserNudgeInitiator],
                intelligentSelection: this.intelligentSelection,
                onUnselect: this.onRemoveInitiator,
                onSelect: (selectedInitiator) => {
                    this.companyUserNudgeInitiator = selectedInitiator;
                    this.$refs.modalableSearchRolePanel.close();
                },
            });
            this.$refs.modalableSearchRolePanel.open();
        },
        onRemoveInitiator() {
            this.companyUserNudge.company_user_initiator = null;
        },
        openAddRecipientModal() {
            this.isSearchUserMultipleMode = true;
            this.$refs.searchRolePanel.init({
                title: this.translate('Nudge recipient'),
                selected: [this.companyUserRecipient],
                onUnselect: this.onRemoveRecipient,
                intelligentSelection: this.intelligentSelection,
                onSelect: (selectedRecipient) => {
                    this.companyUserRecipient = selectedRecipient;
                    this.$refs.modalableSearchRolePanel.close();
                },
            });
            this.$refs.modalableSearchRolePanel.open();
        },
        onRemoveRecipient() {
            this.companyUserNudge.company_user_recipient = null;
        },
        onValidate() {
            this.$validator.validateAll().then(() => {
                if (this.errors.any()) {
                    return;
                }

                this.buttonState = 'loading';

                this.companyUserNudge.save().then(() => {
                    this.$emit('onUpdate');
                    this.modal.close();
                }).catch(() => {
                    this.buttonState = 'idle';
                });
            });
        },
        async onDestroyModal() {
            if (await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this nudge? Once you click on delete, you will no longer be able to access this nudge.') })) {
                await this.companyUserNudge.delete();
                this.$emit('onUpdate', true);
                this.modal.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped src="./UserNudgeEdit.scss"></style>
