import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyLanguage from './CompanyLanguage';

export default class CompanyLanguageCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyLanguage,
        };
    }

    modelCustomAttributes() {
        return {
            default: null,
            enabled: null,
        };
    }

    modelAccessors() {
        return {

            // Contain the default CompanyLanguage
            default: () => {
                let toReturn = null;

                this.models.each((item) => {
                    if (item.is_default) { return toReturn = item; }
                });

                return toReturn;
            },

            // Contain all CompanyLanguage enabled
            enabled: () => {
                const toReturn = [];
                this.models.each((item) => {
                    if (item.is_enabled) { toReturn.push(item); }
                });

                return toReturn;
            },
        };
    }
}
