<template>
    <div
        class="quiz-image-edit"
        :class="{toggled: isToggled}"
    >
        <div class="quiz-image-edit-label">
            <label :for="'question' + inputModel.id">
                {{ inputLabel }}
            </label>
        </div>
        <div class="quiz-image-edit-input">
            <div class="quiz-image-edit-input-action">
                <div
                    v-if="showRemoveButton"
                    class="bar-item right quiz-image-edit-input-action-remove"
                >
                    <Button
                        class="small black"
                        @click="removePicture"
                    >
                        <t>Remove</t>
                    </Button>
                </div>
                <div
                    v-else
                    class="bar-item right quiz-image-edit-input-add"
                >
                    <Button
                        class="small"
                        @click="onEdit"
                    >
                        <t>Download</t>
                    </Button>
                </div>
            </div>
            <div class="bar-item left quiz-image-edit-input-croppable">
                <Croppable
                    v-show="showCroppableImage"
                    ref="croppable_quiz_image"
                    class="croppable-container"
                    :class="{'toggled': isToggled}"
                    :show-width="312"
                    :show-height="217"
                    :dest-width="312"
                    :dest-height="217"
                    :resize-multiple="[0.1]"
                    :radius="20"
                    :show-label="false"
                    save-url="company_file"
                    :public="true"
                    :image="getImage"
                    @onChange="onPictureChange"
                    @onUploadEnd="onPictureSave"
                />
            </div>
        </div>
    </div>
</template>
<script>
import Croppable from '@/components/tools/Croppable.vue';
import Downloadable from '@/components/globals/Downloadable.vue';

export default {
    components: {
        Croppable,
        Downloadable,
    },

    props: {
        inputModel: { type: Object }, // CompanyQuizQuestion or CompanyQuizQuestionAnswer
        inputLabel: { type: String },
        isToggled: { type: Boolean },
        editingLang: {
            type: String,
            required: true,
            default() {
                return '';
            },
        },
    },

    data() {
        return {
            isUploading: false,
        };
    },

    computed: {
        getImage() {
            if (this.editingLang in this.fileTranslations && this.fileTranslations[this.editingLang]) {
                const file = this.fileTranslations[this.editingLang];
                return this.$Utils.resolveS3PublicImage(file.fullpath);
            }
            return null;
        },
        fileTranslations() {
            return this.inputModel.file_translations;
        },
        showCroppableImage() {
            if (this.isUploading) {
                return true;
            }
            if (this.fileTranslations?.[this.editingLang]) {
                return Object.keys(this.fileTranslations[this.editingLang]).length > 0;
            }
            return false;
        },
        showRemoveButton() {
            return 'file_translations' in this.inputModel
                && this.editingLang in this.fileTranslations
                && this.fileTranslations[this.editingLang]
                && Object.keys(this.fileTranslations[this.editingLang]).length > 0;
        },
    },

    created() {
        if (this.inputModel.file_translations === undefined) {
            throw 'You must load the relation << companyFiles >>';
        }

        if (Array.isArray(this.inputModel.file_translations)) {
            this.$set(this.inputModel, 'file_translations', {});
            this.$emit('update:input-model', this.inputModel);
        }

        if (!(this.editingLang in this.inputModel.file_translations)) {
            return false;
        }
    },

    methods: {
        onEdit() {
            this.$refs.croppable_quiz_image.onOpenFileSelector();
        },
        removePicture() {
            this.$set(this.inputModel.file_translations, this.editingLang, {});
            this.$emit('update:input-model', this.inputModel);
        },
        onPictureChange() {
            this.isUploading = true;
            this.$refs.croppable_quiz_image.onSave();
        },
        onPictureSave(uploadInfo, data) {
            this.isUploading = false;
            this.$set(this.inputModel.file_translations, this.editingLang, data);
            this.$emit('update:input-model', this.inputModel);
        },
    },
};

</script>
<style lang="scss" scoped src="./QuizImageEdit.scss" />
