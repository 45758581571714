import get from 'lodash.get';

export default {
    shared: {
        page: {
            title: {
                type: String,
                default: null,
            },
            subtitles: {
                type: Array,
                default: null,
            },
            avatar: {
                type: Object,
                default: null,
            },
            icon: {
                type: [Array, String],
                default: null,
            },
            iconAction: {
                type: Function,
                default: null,
            },
        },
    },

    methods: {
        setPage(data) {
            this.shared.page.title = get(data, 'title', null);
            this.shared.page.icon = get(data, 'icon', null);
            this.shared.page.iconAction = get(data, 'iconAction', null);
            this.shared.page.avatar = get(data, 'avatar', null);
            this.shared.page.subtitles = get(data, 'subtitles', null);
            this.$store.dispatch('faviconTitle/setTitle', this.shared.page.title);
        },
    },
};
