<template>
    <div v-if="item && item.isLoaded()">
        <form
            class="form-2 spaced"
            data-cy="software-edit-form"
            @submit.prevent=""
        >
            <HtUserProgramList
                v-model="item.company_user_program_id"
                :company-user-id="companyUserId"
                :disabled="true"
            />

            <HtKeyDatesInputOrder
                :end-label="translate('Installation date')"
                :user-program-id="item.company_user_program_id"
                :is-business-day.sync="userTask.is_business_day"
                :offset-key-date.sync="userTask.company_user_program_key_date_id"
                :offset-availability-specific-date.sync="userTask.datetime_availability"
                :offset-availability-number.sync="userTask.offset_availability_number"
                :offset-availability-unit.sync="userTask.offset_availability_unit"
                :offset-end-specific-date.sync="userTask.datetime_end"
                :offset-end-number.sync="userTask.offset_end_number"
                :offset-end-unit.sync="userTask.offset_end_unit"
                can-select-specific-date
            />

            <div class="modal-actions right">
                <Button
                    v-if="$canDelete('ModelCompanyUserSoftware', {company_user_id: companyUserId}) && item.id"
                    class="negative"
                    cypress="software-delete"
                    @click="onDelete()"
                >
                    <t>Remove</t>
                </Button>
                <Button
                    v-if="$canUpdate('ModelCompanyUserSoftware', {company_user_id: companyUserId})"
                    :button_state="buttonState"
                    cypress="software-save"
                    @click="onUpdate()"
                >
                    <t>Save</t>
                </Button>
            </div>
        </form>
    </div>
</template>
<script>
import CompanyUserSoftwareCollection from '@/models/CompanyUserSoftwareCollection';
import CompanyUserSoftware from '@/models/CompanyUserSoftware';
import HtUserProgramList from '@/components/globals/HtUserProgramList.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';

export default {
    name: 'UserSoftwareEdit',
    components: {
        HtUserProgramList,
        HtKeyDatesInputOrder,
    },
    inject: ['modal'],

    permissions: [
        'ModelCompanyUserSoftware',
    ],

    props: {
        companyUserId: { type: [String, Number] },
        id: { type: [String, Number], required: false },
        value: { type: CompanyUserSoftware, required: false },
        collection: { type: CompanyUserSoftwareCollection, required: false },
    },

    data() {
        return {
            userTask: null,
            // FOR LAZY LOADING
            software: new CompanyUserSoftware([
                'id', 'company_program_id', 'company_user_program_id', 'company_user_id', 'company_software_id',
            ]).with({
                companySoftware: (query) => {
                    query.select(['id']).with({
                        resource: (query) => {
                            query.select(['id', 'name', 'description']);
                        },
                    }).withTrashed(true);
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image']);
                },
                companyUserProgram: (query) => query.select(['id', 'company_program_id']).with({
                    companyProgram: (query) => query.select(['id']).with({
                        keyDate: (query) => query.select(['id', 'company_program_id']).with({
                            locales: (query) => query
                                .select(['id', 'name', 'language_key']),
                        }),
                    }),
                }),
                companyUserProgramTask: (query) => {
                    query.select([
                        'status',
                        'company_user_program_key_date_id',
                        'offset_availability_number',
                        'offset_availability_unit',
                        'offset_end_number',
                        'offset_end_unit',
                        'is_business_day',
                        'datetime_availability',
                        'datetime_end',
                    ]);
                },
            }).where([['id', '=', this.id]]),
            item: null,
            buttonState: 'idle',
        };
    },

    computed: {
        labelTitle() {
            return this.translate(this.item.company_software.resource.name);
        },
    },

    watch: {
        'item._state.isSaving': function (val) {
            this.buttonState = val ? 'loading' : 'idle';
        },
    },

    async created() {
        if (this.value) this.item = this.value.fromSaveState();
        else {
            this.software.company_user_id = this.companyUserId;
            await this.software.get();
            this.item = this.software;
            this.userTask = this.item.company_user_program_task;
        }

        this.modal.setTitle(this.labelTitle);
    },

    methods: {
        async onUpdate() {
            if (!await this.$validator.validateAll()) return;

            await this.item.save();

            this.modal.close();
            this.$emit('onUpdate');
        },

        async onDelete() {
            if (await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this software? Once you click on delete, you will no longer be able to access this software.') })) {
                await this.item.delete();

                this.modal.close();
                this.$emit('onDelete');
            }
        },
    },
};
</script>
