import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserNotificationSettings from './CompanyUserNotificationSettings';

export default class CompanyUserNotificationSettingsCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserNotificationSettings,
        };
    }
}
