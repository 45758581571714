<template>
    <div>
        <StepperTabLayout v-if="isLoaded">
            <template #main>
                <SurveyFormTargetsCount
                    ref="countTarget"
                    :company-survey-id="survey.id"
                    :resource-program="resourceProgram"
                    :is-retroactive-on-past-enroll="survey.include_already_registered_users"
                    :is-enroll-program="isEnrollProgram"
                />

                <HtFormCard class="mb-5">
                    <div class="target-wrapper">
                        <HtFormSwitch
                            id="default-program"
                            v-model="isEnrollProgram"
                            :label="translate('Target a program')"
                            name="default-program"
                            :disabled="isDisabled"
                            off-label=""
                            on-label=""
                            :show-optional="false"
                            :tooltip="getTargetedProgramTooltip"
                            cypress="survey-target-program"
                            class="mb-0"
                            @input="onChangeEnrollProgram"
                        />

                        <div class="target-wrapper-description mt-2">
                            <t>By targeting a program, you will be able to add requirements to the survey</t>
                        </div>

                        <template v-if="isEnrollProgram">
                            <HtProgramList
                                v-model="resourceProgram.company_program_id"
                                :disabled="isDisabledProgramList"
                                :with-default="false"
                                :with-label="false"
                                cypress="survey-field-target-program"
                                class="mt-4"
                                @input="selectedProgramChanged"
                            />

                            <div class="supervisor-wrapper">
                                <h1>Roles *</h1>

                                <InputParticipantList
                                    :participants="resourceProgram.participants"
                                    @on-open="openParticipantModal"
                                    @on-remove="removeParticipant"
                                />

                                <input
                                    id="survey_participants"
                                    v-model="resourceProgram.participants"
                                    v-validate.disable="'required|array'"
                                    type="hidden"
                                    name="survey_participants"
                                    class="full-width"
                                    :data-vv-as="translate('field participant')"
                                >

                                <form class="form-2">
                                    <div
                                        v-for="(errorMessage, index) in errors.collect('survey_participants')"
                                        :key="index"
                                        class="error-message"
                                    >
                                        {{ errorMessage }}
                                    </div>
                                </form>
                            </div>

                            <HtFormSwitch
                                id="include-registered-users"
                                v-model="survey.include_already_registered_users"
                                :label="translate('Include programs already started')"
                                name="include-registered-users"
                                :disabled="isDisabled"
                                :on-label="translate('Yes')"
                                :off-label="translate('No')"
                                :show-optional="false"
                                :cypress="'survey-field-include-old-program'"
                                class="mb-0"
                                @input="selectPastEnrollee"
                            />
                        </template>
                    </div>
                </HtFormCard>

                <HtFormCard class="mb-5">
                    <ProgramResourceFilters
                        v-model="resourceProgram.filters"
                        :program-id="resourceProgram.company_program_id"
                        :use-resource-perimeter="useResourcePerimeter"
                        :disabled="hasParent || hasChildren"
                    />
                    <div
                        v-if="hasParent || hasChildren"
                        class="error-message"
                    >
                        <t>You cannot change the filters because this task is nested</t>
                    </div>
                </HtFormCard>

                <HtFormCard v-if="!isEnrollProgram">
                    <div class="target-wrapper">
                        <div class="supervisor-wrapper">
                            <h1>
                                <t>Users</t>
                            </h1>
                            <p class="mb-10">
                                <t>You have the ability to target users directly, regardless of the filters above</t>
                            </p>

                            <InputParticipantList
                                :participants="resourceProgram.participants"
                                @on-open="openParticipantModal"
                                @on-remove="removeParticipant"
                            />
                        </div>
                    </div>
                </HtFormCard>
            </template>

            <template #buttonsFooter>
                <Button
                    class="negative mr-4"
                    @click="$emit('goTo', 'parameters')"
                >
                    <t>Back</t>
                </Button>

                <Button
                    v-if="!isDisabled"
                    :state="buttonState"
                    :cypress="'survey-target-submit'"
                    @click="onSave"
                >
                    <t>Save</t>
                </Button>
                <Button
                    v-else
                    :cypress="'survey-target-submit'"
                    @click="gotoNext"
                >
                    <t>Next</t>
                </Button>
            </template>

            <template #modalables>
                <Modalable
                    ref="modalableAddParticipant"
                    class="modalable-1 small"
                    :options="{ title: translate('Targeted to')}"
                >
                    <SearchRolePanel
                        ref="addParticipant"
                        :multiple-mode="true"
                        :allow-user-search="!isEnrollProgram"
                        :is_assignable_onboardee="isEnrollProgram"
                        :has-roles="isEnrollProgram"
                        @onValidate="$refs.modalableAddParticipant.close()"
                    />
                </Modalable>
            </template>
        </StepperTabLayout>
        <IconLoading v-else />
    </div>
</template>

<script>
import CompanySurvey from '@/models/CompanySurvey';
import HtProgramList from '@/components/globals/HtProgramList.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import StepperTabLayout from '@/components/globals/StepperTabLayout.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import Modalable from '@/components/globals/Modalable.vue';
import SearchRolePanel from '@/components/globals/SearchRolePanel.vue';
import Button from '@/components/globals/Button.vue';
import { mapState } from 'vuex';
import ProgramResourceFilters from '@/components/globals/ProgramResourceFilters.vue';
import api from '@/store/api';
import InputParticipantList from '@/components/globals/participant/InputParticipantList.vue';
import HtProgramType from '@/models/HtProgramType';
import IconLoading from '@/components/icons/IconLoading.vue';
import SurveyFormTargetsCount from './SurveyFormTargetsCount.vue';

export default {
    name: 'SurveyFormTargets',
    permissions: ['ModelCompanySurvey'],

    components: {
        IconLoading,
        InputParticipantList,
        ProgramResourceFilters,
        Button,
        SearchRolePanel,
        Modalable,
        HtFormCard,
        StepperTabLayout,
        SurveyFormTargetsCount,
        HtProgramList,
        HtFormSwitch,
    },

    props: {
        disabledProgramList: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoaded: false,
            isEnrollProgram: false,
            resourceProgram: {
                company_program_id: null,
                is_enabled: false,
                program: null,
                dependencies: [],
                dependents: [],
                participants: [],
                filters: [],
            },
        };
    },

    mounted() {
        this.$store.dispatch('programs/fetchPrograms')
            .then(() => {
                if (this.survey.resource.programs.length === 0) {
                    this.setDefaultProgram();

                    this.survey.resource.programs[0] = this.resourceProgram;
                } else {
                    this.resourceProgram = this.survey.resource.programs[0];
                }

                const typeSlug = this.resourceProgram.program?.type?.slug;
                if (typeSlug) {
                    this.isEnrollProgram = this.resourceProgram.program.type.slug !== HtProgramType.SLUG_DEFAULT;
                }

                this.isLoaded = true;
            });
    },

    computed: {
        ...mapState('stepperform', ['loading']),
        survey: {
            set(survey) {
                this.$store.dispatch('stepperform/setItem', survey);
            },
            get() {
                return this.$store.state.stepperform.item;
            },
        },

        hasChildren() {
            return this.resourceProgram.dependencies && this.resourceProgram.dependencies.length > 0;
        },

        hasParent() {
            return this.resourceProgram.dependents && this.resourceProgram.dependents.length > 0;
        },

        isDisabled() {
            return this.hasDynamicDocumentWithVariable || this.isReleased;
        },

        isReleased() {
            return this.survey.status === 'in_progress' || this.survey.status === 'completed';
        },

        isDisabledProgramList() {
            return this.hasDynamicDocumentWithVariable || (this.disabledProgramList && this.survey.status !== CompanySurvey.STATUS_DRAFT);
        },

        buttonState() {
            return (!this.isLoaded || this.loading) ? 'loading' : 'idle';
        },

        hasDynamicDocumentWithVariable() {
            const questionWithVariable = this.survey.contents.filter((content) => content.question?.has_dynamic_document_with_variables === true);
            return questionWithVariable.length > 0;
        },

        getTargetedProgramTooltip() {
            return this.hasDynamicDocumentWithVariable
                ? this.translate('You cannot change the program of a survey where one of the questions contains a dynamic document populated with variables.')
                : '';
        },

        userPerimeterEntities() {
            return this.$store.state.user.perimeterEntities;
        },

        useResourcePerimeter() {
            return this.$store.getters['permissions/useResourcePerimeter']('ModelCompanySurvey');
        },
    },

    methods: {
        setDefaultProgram() {
            const { defaultProgram } = this.$store.state.programs;

            this.resourceProgram = {
                company_program_id: defaultProgram.id,
                is_enabled: true,
                program: defaultProgram,
                participants: [],
                filters: [],
            };

            this.survey.include_already_registered_users = false;
        },

        openParticipantModal() {
            if (!this.isDisabled) {
                this.$refs.addParticipant.init({
                    onValidate: this.addParticipant,
                    selected: this.resourceProgram.participants,
                });

                this.$refs.modalableAddParticipant.open();
            }
        },

        addParticipant(participants) {
            this.resourceProgram.participants = participants
                .filter((participant) => {
                    if (this.isEnrollProgram) {
                        return !!participant.company_role;
                    }
                    return !!participant.company_user;
                })
                .map((participant) => ({
                    company_role_id: participant.company_role_id || null,
                    role: participant.company_role || null,
                    company_user_id: participant.company_user_id || null,
                    user: participant.company_user || null,
                }));

            this.$refs.countTarget.loadTargetLater();
        },

        removeParticipant(index) {
            if (!this.isDisabled) {
                this.resourceProgram.participants.splice(index, 1);

                this.$refs.countTarget.loadTargetLater();
            }
        },

        selectedProgramChanged(id) {
            this.resourceProgram.program = this.$store.state.programs.programs.find((program) => program.id === id);
            this.resourceProgram.company_program_key_date_id = this.resourceProgram.program.key_date.id;
        },

        selectPastEnrollee() {
            this.$refs.countTarget.loadTargetLater();
        },

        onSave() {
            this.$store.dispatch('stepperform/enableLoading');

            this.$validator.validateAll().then((result) => {
                if (result === true) {
                    this.survey.resource.programs[0] = this.resourceProgram;

                    api.configuration.surveys
                        .update(this.survey.id, this.survey)
                        .then((res) => {
                            this.$store.dispatch('stepperform/setItem', res.data.data);
                            this.$store.dispatch('stepperform/disableLoading');

                            this.$emit('goTo', 'questions');
                        });
                } else {
                    this.$store.dispatch('stepperform/disableLoading');
                }
            });
        },

        onChangeEnrollProgram(value) {
            this.resourceProgram.participants = [];
            if (value) {
                this.resourceProgram.company_program_id = null;
                this.resourceProgram.program = null;
                this.resourceProgram.is_enabled = false;
            } else {
                this.setDefaultProgram();

                this.$refs.countTarget.loadTargetLater();
            }
        },

        gotoNext() {
            this.$emit('goTo', 'questions');
        },
    },

    watch: {
        'resourceProgram.filters': {
            deep: true,
            handler(val) {
                if (val.length === 0 && this.useResourcePerimeter) {
                    this.$nextTick(() => this.resourceProgram.filters = this.userPerimeterEntities);
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped src="./SurveyFormTargets.scss"></style>
