<template>
    <div>
        <HtModal
            ref="modal"
            @onClose="$emit('on-close')"
        >
            <template #header>
                {{ title }}
            </template>
            <template #default>
                <ParametersBox>
                    <HtFormSelect
                        :id="'questionEditState'"
                        v-model="question.state"
                        v-validate.disable="'required'"
                        :data-vv-as="translate('state')"
                        :placeholder="translate('All states...')"
                        :options="stateOptions"
                        :name="'questionEditState'"
                        :label="translate('State')"
                        :show-optional="false"
                        class="mb-10"
                    />
                    <br>
                    <HtFormTextarea
                        :id="'name'"
                        v-model="question.text"
                        v-validate.disable="'required'"
                        :name="'text'"
                        :label="translate('Question')"
                        :data-vv-as="translate('question')"
                        :show-optional="false"
                        class="mb-10"
                    />
                    <br>
                    <span
                        class="mb-3"
                        style="font-size: 1.4rem; font-family: 'latobold', 'lato', sans-serif; color: #4A4A4A;"
                    >
                        <t>Answer</t>
                    </span>
                    <div
                        v-for="(answer, index) in question.answers"
                        :key="index"
                    >
                        <div
                            style="display: flex; flex-direction: row; align-items: center; gap: 1rem;"
                            class="mb-2"
                        >
                            <HtFormTextarea
                                :id="'answer' + index"
                                v-model="answer.text"
                                v-validate.disable="'required'"
                                :index="index"
                                style="flex-grow: 1"
                                :data-vv-as="translate('answer')"
                                :show-optional="false"
                                name="answer"
                                class="form-control mb-10"
                                required
                            />

                            <!--div
                                v-if="index > 0"
                                class="input-group-append"
                            >
                                <button
                                    class="p-3"
                                    @click="removeAnswer(index)"
                                >
                                    -
                                </button>
                            </div-->
                        </div>
                    </div>

                    <!--button
                        class="p-3"
                        @click="addAnswer"
                    >
                        +
                    </button-->
                </ParametersBox>
            </template>
            <template #footer-actions>
                <div
                    class="d-flex w-100"
                    style="justify-content: space-between; padding: 20px 25px 0 25px;"
                >
                    <Button
                        v-if="canRemove"
                        :state="buttonState"
                        class="negative"
                        @click.stop="onRemoveConfirm()"
                    >
                        <t>Delete</t>
                    </Button>
                    <Button
                        :state="buttonState"
                        @click="save()"
                    >
                        <t>Save</t>
                    </Button>
                </div>
            </template>
        </HtModal>

        <modalable
            v-if="canRemove"
            ref="modalableConfirmDeletion"
            class="modal modalable-4 program"
        >
            <p>
                <t>
                    Do you really want to permanently delete this content?
                </t>
            </p>

            <div class="row-line middle">
                <Button
                    class="black"
                    cypress="cancel-delete"
                    @click="$refs.modalableConfirmDeletion.close()"
                >
                    <t>Cancel</t>
                </Button>
                <Button
                    class="red"
                    :state="buttonState"
                    cypress="confirm-delete"
                    @click="remove()"
                >
                    <t>Delete</t>
                </Button>
            </div>
        </modalable>
    </div>
</template>

<script>
import ParametersBox from '@/components/globals/ParametersBox.vue';
import HtModal from '@/components/globals/HtModal.vue';
import HtFormTextarea from '@/components/globals/HtFormTextarea.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import Modalable from '@/components/globals/Modalable.vue';

export default {
    name: 'EditKnowledgeFormModal',
    components: {
        Modalable,
        HtFormSelect,
        HtFormTextarea,
        HtModal,
        ParametersBox,
    },

    data() {
        return {
            emptyQuestion: {
                text: '',
                state: '',
                answers: [{
                    text: '',
                }],
            },
            question: {
                text: '',
                state: '',
                answers: [{
                    text: '',
                }],
            },
            isContentHasBeenSaved: false,
            isLoading: false,
            stateOptions: [
                {
                    id: 'in_review',
                    name: this.translate('In Review'),
                },
                {
                    id: 'published',
                    name: this.translate('Published'),
                },
            ],
        };
    },

    computed: {
        title() {
            return this.question.id ? this.translate('Edit Knowledge') : this.translate('Add Knowledge');
        },
        canRemove() {
            return Number.isInteger(this.question.id);
        },
        buttonState() {
            return this.isLoading ? 'loading' : 'idle';
        },
    },

    methods: {
        removeAnswer(index) {
            this.question.answers.splice(index, 1);
        },
        addAnswer() {
            this.question.answers.push({ text: '' });
        },
        async open(id = null) {
            await this.getQuestion(id);
        },
        resetQuestion() {
            this.question = JSON.parse(JSON.stringify(this.emptyQuestion));
        },
        async getQuestion(id = null) {
            this.resetQuestion();

            if (id) {
                this.isLoading = true;

                this.$http.get(`company/knowledge/${id}`).then((data) => {
                    this.question = data.data.data;

                    this.$refs.modal.open();
                }).catch(({ response }) => {
                    if (response?.status !== 409) {
                        this.$Notifier('App').showError(this.translate('An error occurred'));
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            } else {
                this.$refs.modal.open();
            }
        },
        async save() {
            const ok = await this.$validator.validateAll();

            if (ok) {
                this.isLoading = true;

                this.$http.post('company/knowledge', { question: this.question }).then(() => {
                    this.question = JSON.parse(JSON.stringify(this.emptyQuestion));

                    this.$Notifier('App').showInfo(this.translate('The content has been saved with success!'));

                    this.$refs.modal.close();
                }).catch(({ response }) => {
                    if (response?.status !== 409) {
                        this.$Notifier('App').showError(this.translate('An error occurred'));
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        onRemoveConfirm() {
            this.$refs.modalableConfirmDeletion.open();
        },
        remove() {
            this.isLoading = true;

            this.$http.delete(`company/knowledge/${this.question.id}`).then(() => {
                this.$refs.modalableConfirmDeletion.close();

                this.question = this.emptyQuestion;

                this.$Notifier('App').showInfo(this.translate('The selected content has been deleted with success!'));

                this.$refs.modal.close();
            }).catch(({ response }) => {
                if (response?.status !== 409) {
                    this.$Notifier('App').showError(this.translate('An error occurred'));
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
    },
};
</script>
