<template>
    <StepperTabLayout v-if="requirementCategory">
        <template #main>
            <ParametersBox :title="translate('General')">
                <TranslatedForm
                    ref="translatedForm"
                    v-slot="{editingLang}"
                    :disabled="!requirementCategory.resource.is_editable"
                >
                    <HtFormInput
                        :id="'name'"
                        v-model="requirementCategory.resource.translations.name[editingLang]"
                        v-validate.disable="{
                            translation_default:[requirementCategory.resource.translations.name, shared.session.company.company_languages.default.key],
                            max: 255,
                        }"
                        :label="translate('Category name')"
                        :name="'name'"
                        class="mb-4"
                        :data-vv-as="translate('name')"
                        :disabled="requirementCategory.is_heyteam === true || !requirementCategory.resource.is_editable"
                        :show-optional="false"
                    />
                    <HtFormTextarea
                        :id="'description'"
                        v-model="requirementCategory.resource.translations.description[editingLang]"
                        v-validate.disable="'max:255'"
                        :name="'description'"
                        :label="translate('Description')"
                        class="mb-4"
                        :maxlength="255"
                        :disabled="!requirementCategory.resource.is_editable"
                    />
                    <HtFormSelect
                        v-if="options.length > 0"
                        v-show="editingLang === shared.session.company.company_languages.default.key"
                        :id="'askAfter'"
                        v-model="requirementCategory.order"
                        :name="'askAfter'"
                        :label="translate('Ask after')"
                        :options="options"
                        :disabled="!requirementCategory.resource.is_editable"
                    />
                    <HtFormSwitch
                        v-show="editingLang === shared.session.company.company_languages.default.key"
                        :id="'toogle-is-duplicable'"
                        :value="requirementCategory.is_duplicable"
                        :label="translate('User can duplicate all requirements from this category')"
                        :description="translate('By checking this option, users will be able to duplicate the requirements if they need to be completed several times')"
                        :on-label="translate('Yes')"
                        :off-label="translate('No')"
                        :show-optional="false"
                        :name="'toogle-is-duplicable'"
                        :disabled="!requirementCategory.resource.is_editable || hasDynamicDocument"
                        @input="(value) => updateRequirementCategoryBoolean('is_duplicable', value)"
                    />
                    <HtFormSwitch
                        v-if="showToggleSurvey"
                        :id="'toogle-display_survey'"
                        :value="requirementCategory.display_survey"
                        :label="translate('Ask the requirement in a survey')"
                        :description="getDescriptionSurveyToggle"
                        :on-label="translate('Yes')"
                        :off-label="translate('No')"
                        :disabled="requirementCategory.survey_question || !requirementCategory.resource.is_editable"
                        :show-optional="false"
                        :name="'toogle-display_survey'"
                        @input="toggleDisplaySurvey"
                    />

                    <HtFormSwitch
                        :id="'toogle-is_archivable'"
                        :value="requirementCategory.is_archivable"
                        :label="translate('Allow to archive all requirements from this category')"
                        :description="translate('The requirements will be created once a new program is launched')"
                        :show-optional="false"
                        :name="'toogle-is_archivable'"
                        :disabled="!requirementCategory.resource.is_editable || requirementCategory.display_survey === true"
                        @input="(value) => updateRequirementCategoryBoolean('is_archivable', value)"
                    />

                    <LockedToggle
                        v-if="$can('AbstractManageLockedResources')"
                        :value="requirementCategory.is_locked"
                        class="mb-0"
                        :disabled="!requirementCategory.resource.is_editable"
                        @input="(value) => updateRequirementCategoryBoolean('is_locked', value)"
                    />
                </TranslatedForm>
            </ParametersBox>
        </template>
        <template #buttonsFooter>
            <div class="parameter-footer buttons">
                <Button
                    v-if="canDelete"
                    class="negative"
                    :state="deleteButtonState"
                    cypress="requirement-delete"
                    @click="onDelete"
                >
                    <t>Delete</t>
                    <span
                        v-if="isUseInDynamicDocument"
                        v-tooltip.right-end="'This requirement category cannot be deleted because it contains one or more requirements used in dynamic document'"
                        class="cursor-help ml-3"
                    >
                        <FontAwesomeIcon :icon="['fas', 'info-circle']" />
                    </span>
                </Button>
                <Button
                    v-if="requirementCategory.resource.is_editable"
                    :state="buttonState"
                    @click="onSave"
                >
                    <t>Validate</t>
                </Button>
            </div>
        </template>
        <template #modalables>
            <Modalable
                ref="companyRequirementCategoryRemoveModal"
                class="modalable-1 small"
                :mode="2"
                :element-delete-label="translate('this requirement category')"
            />
        </template>
    </StepperTabLayout>
</template>

<script>
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import showButtonActionMixin from '@/components/mixins/showButtonActionMixin';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import StepperTabLayout from '@/components/globals/StepperTabLayout.vue';
import ParametersBox from '@/components/globals/ParametersBox.vue';
import stepperFormEventBus from '@/eventBus/stepperFormEventBus';
import TranslatedForm from '@/components/form/TranslatedForm.vue';
import LockedToggle from '@/components/globals/LockedToggle.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtFormTextarea from '@/components/globals/HtFormTextarea.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import Modalable from '@/components/globals/Modalable.vue';
import { mapState } from 'vuex';
import api from '@/store/api';
import cloneDeep from 'lodash.clonedeep';

export default {
    name: 'RequirementFormCategorySettings',
    components: {
        Modalable,
        HtFormSelect,
        HtFormTextarea,
        HtFormInput,
        StepperTabLayout,
        HtFormSwitch,
        TranslatedForm,
        ParametersBox,
        LockedToggle,
    },

    mixins: [
        DefaultOrderCompletionMixin,
        showButtonActionMixin,
    ],

    props: {
        showListOnEnd: {
            type: Boolean,
            default: true,
        },
        showToggleSurvey: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            companyRequirementCategoryCollection: [],
            hasDynamicDocument: false,
        };
    },

    computed: {
        ...mapState('stepperform', ['loading']),

        requirementCategory: {
            set(requirementCategory) {
                this.$store.dispatch('stepperform/setItem', requirementCategory);
            },
            get() {
                if (this.$store.state.stepperform.item.company_requirement?.[0]?.type === 'dynamic_document') {
                    this.hasDynamicDocument = true;
                }
                return cloneDeep(this.$store.state.stepperform.item);
            },
        },

        requirementCategoryIsNew() {
            return this.requirementCategory.id === undefined;
        },

        canDelete() {
            return this.requirementCategory.resource.is_editable && this.showButtonDelete === true && this.requirementCategory.id && this.requirementCategory.number_of_active_user_survey === 0;
        },

        getDescriptionSurveyToggle() {
            if (this.requirementCategory.survey_question) {
                return this.translate('Please remove the requirement from the survey before you can deactivate it.');
            }
            return null;
        },

        options() {
            return this.companyRequirementCategoryCollection
                .filter((category) => category.order !== this.requirementCategory.order)
                .sort((categoryA, categoryB) => categoryA.order - categoryB.order)
                .map((category) => ({
                    id: category.order + 1,
                    name: category.name,
                }));
        },
        isUseInDynamicDocument() {
            return this.requirementCategory.company_requirement.find((requirement) => requirement.is_use_in_dynamic_document);
        },
        buttonState() {
            return this.loading ? 'loading' : 'idle';
        },
        deleteButtonState() {
            if (this.isUseInDynamicDocument) {
                return 'disabled';
            }

            return this.buttonState;
        },
    },
    async created() {
        await api.configuration.requirementCategory
            .getAll(this.id)
            .then((res) => {
                this.companyRequirementCategoryCollection = res.data.data;
                if (this.requirementCategoryIsNew) {
                    this.requirementCategory.order = this.getMaxOrder(res.data.data) + 1;
                }
            });

        const event = this.requirementCategory.display_survey ? 'hide-tab' : 'show-tab';
        stepperFormEventBus.$emit(event, 'planning');
    },

    methods: {
        async onSave() {
            if (!this.requirementCategory.resource.is_editable) {
                return;
            }

            this.$store.dispatch('stepperform/enableLoading');

            this.$validator.validateAll().then((isSuccess) => {
                if (isSuccess) {
                    if (this.requirementCategory.display_survey) {
                        this.activateDisplaySurvey();
                    }

                    if (this.requirementCategoryIsNew) {
                        api.configuration.requirementCategory
                            .create(this.requirementCategory)
                            .then(this.afterSave);
                    } else if (!this.requirementCategory.display_survey && this.requirementCategory.resource.programs.length === 0) {
                        this.$emit('goTo', 'targets');
                        this.$store.dispatch('stepperform/disableLoading');
                        this.$Notifier('App').showError(this.translate('You need to select a program'));
                    } else {
                        api.configuration.requirementCategory
                            .update(this.requirementCategory.id, this.requirementCategory)
                            .then(this.afterSave);
                    }
                } else {
                    this.$store.dispatch('stepperform/disableLoading');
                }
            });
        },

        afterSave(res) {
            this.$store.dispatch('stepperform/setItem', res.data.data);
            this.$store.dispatch('stepperform/disableLoading');

            this.$emit('goTo', 'targets');

            if (this.companyProgramId) {
                this.$emit('onSave', { close: false });
            }

            if (this.shared.session.company.is_tracking_enabled && this.requirementCategoryIsNew) {
                Segment.track(Segment.EVENT_RESOURCE_CREATED, {
                    type: 'requirement',
                });
            }
        },

        async onDelete() {
            if (!this.requirementCategory.resource.is_editable) {
                return;
            }

            this.$refs.companyRequirementCategoryRemoveModal.deleteWindow({ content: this.translate('Are you sure you want to delete this requirement category ? Once you click on delete, you will no longer be able to access this requirement category.') })
                .then((result) => {
                    if (result) {
                        api.configuration.requirementCategory
                            .delete(this.requirementCategory.id)
                            .then(() => {
                                if (this.showListOnEnd) {
                                    this.$router.push({ name: 'ResourcesRequirements' });
                                } else {
                                    this.$emit('onDelete');
                                }

                                if (this.shared.session.company.is_tracking_enabled) {
                                    Segment.track(Segment.EVENT_RESOURCE_DELETED, {
                                        type: 'requirement',
                                    });
                                }
                            });
                    }
                });
        },

        updateRequirementCategoryBoolean(key, value) {
            this.requirementCategory[key] = value;
            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        toggleDisplaySurvey(value) {
            this.updateRequirementCategoryBoolean('display_survey', value);
            const event = this.requirementCategory.display_survey ? 'hide-tab' : 'show-tab';
            this.requirementCategory.is_archivable = false;
            stepperFormEventBus.$emit(event, 'planning');
        },

        /**
         * retourne l'order maximal d'une liste de company_requirement_category
         * @param {CompanyRequirementCategoryCollection} categories
         * @returns {number}
         */
        getMaxOrder(categories) {
            const orders = categories.map(
                (category) => category.order,
            );

            return Math.max(0, ...orders);
        },

        activateDisplaySurvey() {
            this.requirementCategory.resource.programs = [];
            this.requirementCategory.display_chatbot = false;
            this.requirementCategory.company_requirement_category_recipient = [];
            this.requirementCategory.company_program_resource_filter = [];
        },
    },

};
</script>

<style lang="scss" scoped src="./RequirementFormCategorySettings.scss"></style>
