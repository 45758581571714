<template>
    <HtEntitiesFiltersChips
        :filters="filters"
    />
</template>

<script>
import HtEntitiesFiltersChips from '@/components/globals/filters/HtEntitiesFiltersChips.vue';

export default {
    name: 'TableCellFilters',

    components: {
        HtEntitiesFiltersChips,
    },

    props: {
        filters: {
            type: Array,
            required: true,
        },
    },
};
</script>
