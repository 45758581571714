<template>
    <div v-if="tasks.length">
        <div
            class="mb-3"
            @click="isTimelineToggled = !isTimelineToggled"
        >
            <UserProgramTimelineTitle
                :title="periodLabel"
                :count-task="countPendingTasks"
                :color="color"
                :show-multi-select="showMultiSelect"
                :is-selected="isSelected"
                :program-id="programId"
                data-cy="period-element-title"
                @onSelectAll="onSelectAll"
            />
        </div>

        <div
            v-if="isTimelineToggled"
            :key="periodLabel"
        >
            <div
                v-for="(task, index) in tasks"
                :key="index"
            >
                <UserProgramTaskItem
                    v-if="!task.is_grouped"
                    :key="index"
                    :from="from"
                    :item="task"
                    :selected-action-list="selectedActionList"
                    :show-multi-select="showMultiSelect"
                    cypress="period-element-task-item"
                    @onToggleStatus="onToggleStatus"
                />
                <UserProgramTaskWrapper
                    v-else-if="task.groupedTasks.length"
                    :key="index"
                    :from="from"
                    :item="task"
                    :selected-action-list="selectedActionList"
                    :show-multi-select="showMultiSelect"
                    cypress="period-element-task-wrapper"
                />
            </div>

            <div
                v-if="canAdd"
                class="add-task mb-3 d-flex align-items-center"
                data-cy="period-element-add-button"
                @click="openAddElement"
            >
                <div class="add-task-button d-flex align-items-center justify-content-center mr-4">
                    <FontAwesomeIcon :icon="['fal', 'plus']" />
                </div>
                <div class="add-task-title">
                    <t>Add an action</t>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserProgramTaskItem from './UserProgramTaskItem.vue';
import UserProgramTimelineTitle from './UserProgramTimelineTitle.vue';
import UserProgramTaskWrapper from './UserProgramTaskWrapper.vue';

export default {
    name: 'UserProgramTimelineItem',
    components: {
        UserProgramTimelineTitle,
        UserProgramTaskItem,
        UserProgramTaskWrapper,
    },
    props: {
        from: {
            type: String,
            required: true,
        },
        periodLabel: {
            type: String,
            required: true,
        },
        tasks: {
            type: Array,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        /**
         * Can the user add a resource
         */
        canAdd: {
            type: Boolean,
            required: true,
        },
        selectedActionList: {
            type: Array,
            required: true,
        },
        selectedTimelineList: {
            type: Array,
            required: true,
        },
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        programId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            isTimelineToggled: true,
        };
    },

    computed: {
        countPendingTasks() {
            return this.tasks.filter((task) => task.groupedTasks.length === 0 && ['pending', 'draft'].includes(task.status)).length;
        },

        isSelected() {
            return this.selectedTimelineList.includes(this.periodLabel);
        },
    },

    methods: {
        onToggleStatus(item) {
            this.$emit('onToggleStatus', item);
        },

        openAddElement() {
            this.$emit('onAddAction');
        },

        onSelectAll(isChecked) {
            // Add or remove timeline
            if (isChecked && !this.selectedTimelineList.includes(this.periodLabel)) {
                this.selectedTimelineList.push(this.periodLabel);
            } else if (!isChecked && this.selectedTimelineList.includes(this.periodLabel)) {
                this.selectedTimelineList.splice(this.selectedTimelineList.indexOf(this.periodLabel), 1);
            }

            // Add or remove each task of timeline
            this.tasks.forEach((task) => {
                if (task.is_grouped) {
                    task.groupedTasks.forEach((taskGrouped) => {
                        if (this.canSelect(taskGrouped)) {
                            this.checkIfAddOrRemove(isChecked, taskGrouped.id);
                        }
                    });
                } else if (this.canSelect(task)) {
                    this.checkIfAddOrRemove(isChecked, task.id);
                }
            });
        },

        checkIfAddOrRemove(isChecked, taskId) {
            if (isChecked && !this.selectedActionList.includes(taskId)) {
                this.selectedActionList.push(taskId);
            } else if (!isChecked && this.selectedActionList.includes(taskId)) {
                this.selectedActionList.splice(this.selectedActionList.indexOf(taskId), 1);
            }
        },

        canSelect(task) {
            const { resource } = task;

            if (task.validator_type === 'system') {
                return false;
            }

            return resource !== 'company_survey'
                && resource !== 'company_requirement_category'
                && resource !== 'company_user_quiz'
                && resource !== 'company_email_custom';
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.add-task {
    border-radius: $border-radius-8;
    border: 1px solid $blue-light;
    min-height: 4.5em;
    cursor: pointer;
    padding: 0 20px;

    &:hover {
        border: 1px solid $grey-1;
        background: $white;
    }

    &-button {
        background-color: $blue-light;
        width: 34px;
        height: 34px;
        border-radius: 50%;
    }

    &-title {
        font-size: 16px;
        color: $grey-3;
        font-weight: 700;
    }
}
</style>
