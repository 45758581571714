<template>
    <div>
        <HtTimezoneSelect
            v-if="item.timezone !== null && !item.is_delegated"
            v-model="item.timezone"
            class="tz-select"
            cypress="tz-select"
        />
        <HtFormSwitch
            v-if="!item.is_delegated"
            id="adapt-tz"
            :value="item.timezone === null"
            :label="translate('Adapt the time of the event to the user\'s time zone')"
            :off-label="translate('No')"
            :on-label="translate('Yes')"
            name="file-join"
            cypress="file-join"
            @input="setDefaultTimezoneIfNeeded"
        />
    </div>
</template>

<script>
import HtTimezoneSelect from '@/components/globals/HtTimezoneSelect.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'EventTimezoneManager',
    components: {
        HtTimezoneSelect, HtFormSwitch,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        company() {
            return this.shared.session.company;
        },
    },
    methods: {
        getCompanyTimezone() {
            if (!this.company || !('timezone' in this.company)) {
                return 'Europe/Paris';
            }

            return this.company.timezone;
        },
        setDefaultTimezoneIfNeeded(value) {
            this.item.timezone = !value ? this.getCompanyTimezone() : null;
        },
    },
};
</script>

<style scoped>

>>>.tz-select .ht-form {
    margin-bottom: 5px;
}
</style>
