import Model from '@tony.caron/node-model-proxy/Model';

import Company from './Company';
import CompanySurveyQuestionAnswerLocaleCollection from './CompanySurveyQuestionAnswerLocaleCollection';

export default class CompanySurveyQuestionAnswerLocale extends Model {
    modelConfig() {
        return {
            collection: CompanySurveyQuestionAnswerLocaleCollection,
        };
    }

    modelRelations() {
        return {
            company: () => this.belongsTo(Company, 'company_id', 'id'),
        };
    }
}
