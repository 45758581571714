/* eslint-disable no-useless-constructor */
/* eslint-disable class-methods-use-this */

import HtProgramType from '@/models/HtProgramType';
import CompanyLanguage from '@/models/CompanyLanguage';
import CompanyRole from '@/models/CompanyRole';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import AbstractTemplateProvider from './AbstractTemplateProvider';

export default class ParentleaveTemplateProvider extends AbstractTemplateProvider {
    static TEMPLATE_SLUG = 'parent-leave-leaving';

    constructor(programTypes, keyDates, roles, employeeDepartmentGroupId) {
        super(programTypes, keyDates, roles, employeeDepartmentGroupId);
    }

    get baseProgramData() {
        return {
            slug: ParentleaveTemplateProvider.TEMPLATE_SLUG,
            ht_program_type_id: this.programTypes.find(
                (type) => type.slug === HtProgramType.SLUG_REBOARDING,
            ).id,
            locales: [
                {
                    name: 'Congé parental (départ)',
                    description:
                        'Le départ en congé parental est un événement qui doit être accompagné par l’entreprise, avant mais aussi après l’absence du salarié.',
                    language_key: CompanyLanguage.KEY_FR,
                },
                {
                    name: 'Parental leave (departure)',
                    description:
                        "The departure on parental leave is an event that must be supported by the company, both before and after the employee's absence",
                    language_key: CompanyLanguage.KEY_EN,
                },
            ],
            key_dates: [
                {
                    ht_program_key_date: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Departure date')),
                    ht_program_key_date_id: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Departure date')).id,
                    is_main: true,
                },
            ],
            actions: [],
            surveys: [],
            emails: [],
            events: [],
        };
    }

    get actions() {
        return [
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -20,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Accompagner le salarié vers l’obtention d’aides financières',
                            [CompanyLanguage.KEY_EN]: 'Assist the employee in obtaining financial aid',
                        },
                        description: {
                            [CompanyLanguage.KEY_FR]: `- Allocation de base
                            - Prime à la naissance ou à l’adoption
                            - PREPARE (Prestation partagée d’éducation de l’enfant)
                            - CMG (Complément de libre choix du mode de garde)`,
                            [CompanyLanguage.KEY_EN]: `- Basic allowance
                            - Birth or adoption grant`,
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -10,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Programmer un point avec l’équipe pour le départ du collaborateur',
                            [CompanyLanguage.KEY_EN]: 'Schedule an exit briefing with the team',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: -4,
                            offset_availability_unit: CompanyResourceProgram.UNIT_DAYS,
                            offset_end_number: -5,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Participer à la formation du remplaçant',
                            [CompanyLanguage.KEY_EN]: "Participate in the replacement's training",
                        },
                    },
                },
            },
        ];
    }

    get emails() {
        return [
            {
                template: 'custom',
                translations: [
                    {
                        subject: "J'espère que tout va bien",
                        content: `<p>Bonjour {recipient_firstname}</p>
                            <p>J'espère que tu vas bien. Je voulais simplement te donner des nouvelles et dire qu'on a hâte que tu reviennes parmi nous.</p>
                            <p>Tout avance bien chez {company_name}, et l'équipe pense très fort à toi !</p>`,
                        signature: '{sender_firstname} {sender_lastname}',
                        language_key: CompanyLanguage.KEY_FR,
                    },
                    {
                        subject: 'I hope everything is fine',
                        content: `<p>Hello {recipient_firstname}</p>
                            <p>I hope you're doing well. I just wanted to give you an update and say that we can't wait to have you back with us.</p>
                            <p>Everything is going well here, and the team is thinking about you a lot!</p>`,
                        signature: '{sender_firstname} {sender_lastname}',
                        language_key: CompanyLanguage.KEY_EN,
                    },
                ],
                file_translations: [],
                resource: {
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Mail de reprise de contact',
                            [CompanyLanguage.KEY_EN]: 'I hope everything is fine',
                        },
                    },
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 45,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                },
                sender_role_id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
            },
        ];
    }

    get events() {
        return [
            {
                time_start: '14:00',
                time_end: '15:00',
                is_delegated: false,
                timezone: 'Europe/Paris',
                organiser_role: {
                    id: this.roles[CompanyRole.NAME_MANAGER],
                },
                email_sending_offset: 2,
                file_translations: {},
                translations: {
                    content: {
                        [CompanyLanguage.KEY_FR]: `<p>Bonjour {recipient_firstname}</p>
                        <p>Je vous propose d'échanger afin d'organiser votre départ en congé parental !</p>`,
                        [CompanyLanguage.KEY_EN]: `<p>Hello {recipient_firstname}</p>
                        <p>I'm looking forward to talking with you to arrange your departure on parental leave!</p>`,
                    },
                },
                resource: {
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Entretien avant le départ en congé',
                            [CompanyLanguage.KEY_EN]: 'Pre-Leave Interview',
                        },
                        description: {
                            [CompanyLanguage.KEY_FR]: 'Prenons un moment ensemble pour échanger avant votre départ en congé parental.',
                            [CompanyLanguage.KEY_EN]: "Let's take a moment together to talk before your departure on parental leave.",
                        },
                    },
                    programs: [
                        {
                            is_enabled: true,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_availability_number: 0,
                            offset_end_number: -30,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                            filters: [],
                        },
                    ],
                },
            },
        ];
    }
}
