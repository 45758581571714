var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center align-items-center flex-column"},[_c('img',{attrs:{"src":"/static/illustrations/success1.svg"}}),_c('div',{staticClass:"enrolling-finalize-title"},[_c('t',[_vm._v("Congrats !")])],1),_c('div',{staticClass:"enrolling-finalize-description"},[_c('t',{attrs:{"enrollee_fullname":_vm.user.fullname}},[_vm._v(" You've just finalized {enrollee_fullname}'s program ")])],1),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"1em"}},[_c('router-link',{attrs:{"to":"/Dashboard"}},[_c('Button',{staticClass:"inner-box branding-color branding-border-color branding-border-color-darken-hover"},[_c('t',[_vm._v("Back to Dashboard")])],1)],1),_c('router-link',{attrs:{"to":{
                name: 'ProfileProgram',
                params: {
                    company_user_id: _vm.user.id,
                    company_user_program_id: _vm.userProgram.id,
                },
            }}},[_c('Button',[_c('t',[_vm._v("Consult the program")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }