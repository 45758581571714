<template>
    <HtFormMultiSelect
        :id="'htResourceDependent'"
        v-validate.disable="'required'"
        :value="getDependent"
        :label="translate('Resource to complete to unlock')"
        :placeholder="translate('No action selected')"
        :options="getAvailableResource"
        :name="'htResourceDependent'"
        :is-single="true"
        :show-optional="false"
        :data-vv-as="translate('action selection')"
        :max-height="230"
        :open-direction="'above'"
        cypress="dependent-select"
        @input="onSelectDependent"
    />
</template>

<script>
import get from 'lodash.get';
import invert from 'lodash.invert';
import keyBy from 'lodash.keyby';
import debounce from 'lodash.debounce';
import CompanyProgramResourceFilterCollection from '@/models/CompanyProgramResourceFilterCollection';
import CompanySharedDocument from '@/models/CompanySharedDocument';
import CompanyTraining from '@/models/CompanyTraining';
import CompanySurvey from '@/models/CompanySurvey';
import CompanyEmailCustomTemplate from '@/models/CompanyEmailCustomTemplate';
import CompanyProgramTask from '@/models/CompanyProgramTask';
import CompanyQuiz from '@/models/CompanyQuiz';
import Company from '@/models/Company';

export default {
    name: 'HtResourceDependency',
    inject: ['$validator'],

    props: {
        dependents: {
            type: [Object, Array],
            required: true,
        },
        dependencies: {
            type: [Object, Array],
            required: true,
        },
        filters: {
            type: [CompanyProgramResourceFilterCollection, Object, Array],
            default: () => ({}),
        },
        resourceId: {
            type: Number,
            default: 0,
        },
        resourceType: {
            type: String,
            required: true,
        },
        companyProgramId: {
            type: Number,
            required: true,
        },
    },

    shared: {
        session: {
            company: {
                type: Company,
                default: null,
            },
        },
    },

    data() {
        return {
            availableResource: [],
            resourceWithLocales: [
                CompanyTraining.RESOURCEABLE_CLASS,
                CompanySurvey.RESOURCEABLE_CLASS,
                CompanyEmailCustomTemplate.RESOURCEABLE_CLASS,
                CompanyProgramTask.RESOURCEABLE_CLASS,
            ],
            mappingClassAndResource: {
                document: CompanySharedDocument.RESOURCEABLE_CLASS,
                training: CompanyTraining.RESOURCEABLE_CLASS,
                survey: CompanySurvey.RESOURCEABLE_CLASS,
                quiz: CompanyQuiz.RESOURCEABLE_CLASS,
                action: CompanyProgramTask.RESOURCEABLE_CLASS,
                email: CompanyEmailCustomTemplate.RESOURCEABLE_CLASS,
            },
        };
    },

    computed: {
        getDependent() {
            const dependents = Array.isArray(this.dependents) ? this.dependents : get(this.dependents, 'models', []);

            if (dependents.length === 0) {
                return null;
            }

            return this.getAvailableResource.find((resource) => resource.id === dependents[0].id);
        },

        getAvailableResource() {
            const mapping = invert(this.mappingClassAndResource);
            const dependencies = Array.isArray(this.dependencies) ? this.dependencies : get(this.dependencies, 'models', []);
            const userLang = this.shared.session.companyUser.company_language.key;

            return this.availableResource.filter((resource) => {
                const hasChild = dependencies.some((dependency) => dependency.id === resource.id);
                const isCurrent = resource.resourceable_id === this.resourceId && resource.resourceable_type === this.resourceType;

                return !isCurrent && !hasChild;
            }).map((resource) => {
                const relation = mapping[resource.resourceable_type];

                let name = null;
                let field = 'name';
                let languageKey = this.shared.session.company.company_language.key;

                if (resource.resource) {
                    name = resource.resource.name;
                } else if (this.resourceWithLocales.includes(resource.resourceable_type)) {
                    const localeKeyByLanguageKey = keyBy(resource[relation].locales, 'language_key');
                    field = ('title' in resource[relation].locales[0]) ? 'title' : 'name';

                    if (localeKeyByLanguageKey[userLang] !== undefined
                        && localeKeyByLanguageKey[userLang][field]
                        && localeKeyByLanguageKey[userLang][field].length > 0) {
                        languageKey = this.shared.session.companyUser.company_language.key;
                    }

                    name = localeKeyByLanguageKey[languageKey][field];
                } else {
                    name = resource[relation][field];
                }

                return {
                    id: resource.id,
                    name,
                };
            });
        },

        hasDependent() {
            const dependents = Array.isArray(this.dependents) ? this.dependents : get(this.dependents, 'models', []);

            return dependents.length > 0;
        },
    },

    created() {
        this.loadAvailableTasks();
        this.$eventHub.$on('filter-change', this.debouncedCall);
        this.$eventHub.$on('program-change', this.debouncedCall);
    },

    beforeDestroy() {
        this.onSelectDependent(null); // Reset dependents when destroyed

        this.$eventHub.$off('filter-change');
        this.$eventHub.$off('program-change');
    },

    methods: {
        // eslint-disable-next-line func-names
        debouncedCall: debounce(function () {
            this.loadAvailableTasks();
        }, 1000),
        loadAvailableTasks() {
            const filters = this.getFilters();
            const { companyProgramId } = this;

            if (companyProgramId > 0) {
                this.$http.post('resourceProgram/available', {
                    filters,
                    company_program_id: companyProgramId,
                }).then(({ data }) => {
                    this.availableResource = get(data, 'resources', []);
                });
            }
        },
        onSelectDependent(dependent) {
            if (this.hasDependent) {
                if (Array.isArray(this.dependents)) {
                    this.dependents.splice(0, this.dependents.length);
                } else {
                    this.dependents.clear();
                }
            }

            if (dependent) {
                if (Array.isArray(this.dependents)) {
                    this.dependents.push({
                        id: dependent.id,
                    });
                } else {
                    const newDependent = this.dependents.new();

                    newDependent.id = dependent.id;

                    this.dependents.add(newDependent);
                }
            }
        },

        getFilters() {
            if (Array.isArray(this.filters)) {
                return this.filters.map((filter) => ({
                    company_entity_id: filter.company_entity_id,
                    company_entity_value_id: filter.company_entity_value_id,
                }));
            }

            return get(this.filters, 'models', []).map((filter) => ({
                company_entity_id: filter.company_entity_id,
                company_entity_value_id: filter.company_entity_value_id,
            }));
        },
    },
};
</script>
