<template>
    <div class="question-completion">
        <FilesUpload
            v-model="files"
            :media="question.template.params"
            :preview-only="isReadOnly"
            @on-uploaded="emitInput"
            @on-remove="emitInput"
        />

        <SurveyAnswerHistory
            v-if="hasPreviousOccurrenceSection"
        >
            <SurveyFileUploadHistory
                :answers="previousOccurrence.question.answers"
            />
        </SurveyAnswerHistory>
    </div>
</template>

<script>
import SurveyQuestionMixin from '@/components/resources/user/surveys/questions/SurveyQuestionMixin';
import SurveyAnswerHistory from '@/components/resources/user/surveys/questions/SurveyAnswerHistory.vue';
import SurveyFileUploadHistory from '@/components/resources/user/surveys/questions/FileUpload/SurveyFileUploadHistory.vue';
import FilesUpload from '@/components/resources/user/surveys/questions/FilesUpload.vue';

export default {
    name: 'SurveyFileUploadCompletion',

    mixins: [
        SurveyQuestionMixin,
    ],

    components: {
        FilesUpload,
        SurveyAnswerHistory,
        SurveyFileUploadHistory,
    },

    data() {
        return {
            files: [...this.value.map((v) => v.company_file)],
        };
    },

    watch: {
        value: {
            handler() {
                this.files = [...this.value.map((v) => v.company_file)];
            },
            deep: true,
        },
    },

    methods: {
        emitInput(file) {
            if (this.isReadOnly) {
                return;
            }

            this.question.is_edited = true;

            let values = [...this.value];

            if (!this.value.find((v) => v.company_file_id === file.id)) {
                values.push({
                    id: null,
                    company_file_id: file.id,
                    company_file: file,
                });
            } else {
                values = values.filter((v) => v.company_file_id !== file.id);
            }

            this.$emit('input', values);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../SurveyQuestion';

.answer-history {
    flex-direction: column;
    align-items: start;
}
</style>
