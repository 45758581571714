<template>
    <HtTable
        v-if="companyUserAuditCollection.models.length > 0"
        :items="companyUserAuditCollection.models"
        :columns="columns"
        cypress="user-activities"
        :empty-message="'You have no activities yet...'"
    >
        <template #cell(member)="scope">
            <div
                v-user-image="{
                    image: scope.item.from.image,
                    firstname: scope.item.from.firstname,
                    lastname: scope.item.from.lastname
                }"
                class="user-image"
            />
        </template>

        <template #cell(activity)="scope">
            <span v-text="formatMessage(scope.item)" />
        </template>

        <template #cell(created_at)="scope">
            <span v-text="formatDate(scope.item.created_at)" />
        </template>
    </HtTable>
</template>

<script>

import CompanyUserAuditCollection from '@/models/CompanyUserAuditCollection';

export default {
    name: 'NotificationsActivities',
    components: {},
    props: {
        company_user_id: { type: Number, required: true },
    },

    data() {
        return {
            is_loaded: false,
            columns: [
                {
                    label: this.translate('Member'),
                    key: 'member',
                    sortable: false,
                },
                {
                    label: this.translate('Activity'),
                    key: 'activity',
                    sortable: false,
                },
                {
                    label: this.translate('When'),
                    key: 'created_at',
                },
            ],
            companyUserAuditCollection: new CompanyUserAuditCollection([
                'id',
                'message',
                'param',
                'created_at',
                'permissions',
            ]).where([
                ['to_company_user_id', '=', this.company_user_id],
            ]).with({
                to: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']).withTrashed(true);
                },
                from: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']).withTrashed(true);
                },
            }).orderBy([
                ['id', 'desc'],
            ])
                .limit(20),
        };
    },

    mounted() {
        window.scrollTo(0, 0);
    },

    created() {
        this.companyUserAuditCollection.get();

        this.isLoaded = true;
    },

    methods: {
        // TODO : refactor this if perf issues
        formatMessage(item) {
            return this
                .translate(item.message)
                .replace('{from->fullName}', `${item.from.firstname} ${item.from.lastname}`)
                .replace('{from->fullname}', `${item.from.firstname} ${item.from.lastname}`)
                .replace('{related->fullName}', `${item.from.firstname} ${item.from.lastname}`)
                .replace('{related->fullname}', `${item.from.firstname} ${item.from.lastname}`)
                .replace('{to->fullName}', `${item.to.firstname} ${item.to.lastname}`)
                .replace('{to->fullname}', `${item.to.firstname} ${item.to.lastname}`)
                .replace('{param->name}', Object.prototype.hasOwnProperty.call(item.param, 'name') ? item.param.name : '');
        },

        formatDate(date) {
            return this.$Utils.moment(date).format('LLL');
        },
    },

};
</script>
