import Sign from '@/pages/Sign.vue';
import Account from '@/router/pages/Account.vue';
import OAuth2 from '@/pages/OAuth2.vue';
import Autoconnect from '@/pages/admin/Autoconnect.vue';
import AutoLogin from '@/router/pages/AutoLogin.vue';
import LoginMaster from '@/pages/LoginMaster.vue';
import Login from '@/pages/Login.vue';
import ForgotPassword from '@/pages/ForgotPassword.vue';
import InviteMail from '@/pages/InviteMail.vue';

export default [
    {
        path: '/Autoconnect/:id?',
        component: Autoconnect,
        name: 'Autoconnect',
        meta: {
            public: true,
        },
    },
    {
        path: '/AutoLogin/:token',
        component: AutoLogin,
        name: 'AutoLogin',
        meta: {
            public: true,
        },
    },
    {
        path: '/LoginMaster',
        component: LoginMaster,
        name: 'LoginMaster',
        meta: {
            public: true,
        },
    },
    {
        name: 'Login',
        path: '/Login',
        component: Login,
        meta: {
            public: true,
        },
    },
    {
        path: '/ForgotPassword/:token?',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            public: true,
        },
    },
    {
        path: '/invite/:id',
        name: 'InviteMail',
        component: InviteMail,
        meta: {
            public: true,
        },
    },
    {
        path: '/Sign',
        component: Sign,
        meta: {
            public: true,
        },
    },
    {
        path: '/Account',
        name: 'Account',
        component: Account,
    },
    {
        path: '/oauth2',
        component: OAuth2,
        meta: {
            public: true,
        },
    },
];
