<template>
    <div>
        <IconLoading v-if="loading" />
        <div v-else>
            <div class="form-2">
                <div
                    v-if="!selectedCategory"
                    class="empty-component"
                >
                    <strong>
                        <t>All available requirements already selected !</t>
                    </strong>
                </div>

                <template v-else>
                    <div>
                        <HtFormSelect
                            id="category"
                            v-model="selectedCategory"
                            name="category"
                            cypress="category"
                            :label="translate('Category')"
                            :options="categoryOptions"
                            :show-optional="false"
                        />
                    </div>

                    <div class="title-section">
                        Pièces
                    </div>

                    <RequirementCard
                        v-for="companyRequirement in selectedCategory.requirements"
                        :key="companyRequirement.id"
                        :requirement="companyRequirement"
                        class="my-3"
                    />

                    <div class="modal-actions right">
                        <Button
                            :state="buttonState"
                            cypress="training-add-button"
                            @click="onClick()"
                        >
                            <t>Add</t>
                        </Button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import api from '@/store/api';
import IconLoading from '@/components/icons/IconLoading.vue';
import RequirementCard from './RequirementCard.vue';

export default {
    name: 'UserRequirementCategoryCreateModal',

    components: {
        RequirementCard,
        IconLoading,
    },

    inject: ['modal'],

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            default: null,
        },
    },

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },

    data() {
        return {
            loading: true,
            buttonState: 'idle',
            selectedCategory: null,
            availableRequirementCategories: [],
        };
    },

    computed: {
        categoryOptions() {
            // si il y a des categories à selectionner dans le HtFormSelect
            return this.availableRequirementCategories.map((category) => ({
                id: category,
                name: category.is_heyteam ? this.translate(category.resource.name) : category.resource.name,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
    },

    created() {
        this.modal.setTitle(this.translate('Add requirements'));

        api.user.requirementCategories
            .available(this.companyUserId, {
                user_program_id: this.companyUserProgramId,
            })
            .then(({ data }) => {
                this.loading = false;
                this.availableRequirementCategories = data.data;

                if (this.availableRequirementCategories.length > 0) {
                    this.selectedCategory = this.availableRequirementCategories[0];
                }
            });
    },

    methods: {
        /**
         * Crée un nouveau companyUserRequirementCategory
         * @returns {void}
         */
        onClick() {
            this.buttonState = 'loading';

            api.user.requirementCategories.create(this.companyUserId, {
                user_program_id: this.companyUserProgramId,
                requirement_category_id: this.selectedCategory.id,
            }).then(() => {
                this.modal.close();
                this.$emit('on-create');
            }).finally(() => {
                this.buttonState = 'idle';
            });
        },
    },

};
</script>

<style lang="scss" src="./UserRequirementCategoryCreateModal.scss" scoped />
