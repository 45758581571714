<template>
    <fieldset :class="{'has-error': errors.has(name) || errorAppend, 'half': isHalf}">
        <!-- TEXT -->
        <div
            v-if="isText"
            :class="{'item-content': interactive}"
        >
            <label
                v-if="label && !interactive"
                :for="id"
            >{{ label }} <span v-if="checkMandatory">*</span></label>
            <input
                v-if="trim"
                :id="id"
                v-model.trim="input"
                v-validate.disable="validate"
                :name="name"
                :disabled="disabled"
                :class="{'input-has-value': input}"
                :type="type"
                :data-vv-as="validateAs"
                :placeholder="placeholder"
                :style="{'height': `${height}px`}"
                :data-cy="cypress"
                @keyup.13="$emit('onKeyupEnter')"
            >
            <input
                v-else
                :id="id"
                v-model="input"
                v-validate.disable="validate"
                :name="name"
                :disabled="disabled"
                :class="{'input-has-value': input}"
                :type="type"
                :data-vv-as="validateAs"
                :placeholder="placeholder"
                :style="{'height': `${height}px`}"
                :data-cy="cypress"
                @click="$emit('onClick')"
                @keyup.13="$emit('onKeyupEnter')"
            >
            <label
                v-if="label && interactive"
                :for="id"
            >{{ label }} <span v-if="validate">*</span></label>

            <div
                v-for="(errorMessage, index) in errors.collect(name)"
                :key="index"
                class="error-message"
            >
                {{ errorMessage }}
            </div>
            <div
                v-if="errorAppend"
                class="error-message"
            >
                <t>{{ errorAppend }}</t>
            </div>
        </div>

        <!-- TEXTEXTRA -->
        <div
            v-else-if="isTextExtra"
            :class="{'item-content': interactive}"
        >
            <label
                v-if="label && !interactive"
                :for="id"
            >{{ label }} <span v-if="checkMandatory">*</span></label>
            <div
                class="wrapper-text-extra"
                :style="{'height': `${height}px`}"
            >
                <img
                    v-if="configs && configs.iconPath"
                    :src="configs.iconPath"
                    width="32"
                    height="32"
                >
                <input
                    :id="id"
                    v-model="input"
                    v-validate.disable="validate"
                    :name="name"
                    :disabled="disabled"
                    :type="'text'"
                    :data-vv-as="validateAs"
                    :placeholder="placeholder"
                    :style="{'height': `${height}px`}"
                    @keyup.13="$emit('onKeyupEnter')"
                >
                <template v-if="isDeletable">
                    <div class="wrapper-text-extra-right">
                        <FontAwesomeLayers
                            :class="['wrapper-text-extra-right-button', {'disabled': disabled}]"
                            @click="inputDelete"
                        >
                            <FontAwesomeIcon
                                size="2x"
                                icon="circle"
                                class="circle"
                            />
                            <FontAwesomeIcon
                                size="2x"
                                :icon="['far', 'trash-alt']"
                                transform="right-1 shrink-8"
                                class="trash-icon"
                            />
                        </FontAwesomeLayers>
                    </div>
                </template>
            </div>
            <div
                v-for="(errorMessage, index) in errors.collect(name)"
                :key="index"
                class="error-message"
            >
                {{ errorMessage }}
            </div>
            <div
                v-if="errorAppend"
                class="error-message"
            >
                <t>{{ errorAppend }}</t>
            </div>
        </div>

        <!-- TEXTFLAG -->
        <div
            v-else-if="isTextFlag"
            :class="{'item-content': interactive}"
        >
            <label
                v-if="label && !interactive"
                :for="id"
            >{{ label }} <span v-if="checkMandatory">*</span></label>
            <div :class="['wrapper-text-flag', {'disabled': disabled}]">
                <input
                    :id="id"
                    v-model="input"
                    v-validate.disable="validate"
                    :name="name"
                    :disabled="disabled"
                    :type="'text'"
                    :data-vv-as="validateAs"
                    :placeholder="placeholder"
                    :data-cy="cypress"
                    @keyup.13="$emit('onKeyupEnter')"
                >
                <HtFormMultiSelect
                    :id="`${name}-textFlag`"
                    v-model="selectedLanguage"
                    :name="`${name}-textFlag`"
                    :is-single="true"
                    :is-searchable="false"
                    :allow-empty="false"
                    :options="companyLanguage.enabled"
                    @input="languageChange"
                >
                    <template
                        slot="singleLabel"
                        slot-scope="{ option }"
                    >
                        <div class="rounded-flag">
                            <country-flag
                                :country="option.codeFlag"
                                size="big"
                            />
                        </div>
                    </template>
                    <template
                        slot="option"
                        slot-scope="{ option }"
                    >
                        <div class="rounded-flag">
                            <country-flag
                                :country="option.codeFlag"
                                size="big"
                            />
                        </div>
                    </template>
                </HtFormMultiSelect>
            </div>
            <div
                v-for="(errorMessage, index) in errors.collect(name)"
                :key="index"
                class="error-message"
            >
                {{ errorMessage }}
            </div>
            <div
                v-if="errorAppend"
                class="error-message"
            >
                <t>{{ errorAppend }}</t>
            </div>
        </div>

        <!-- TEXTAREA -->
        <div
            v-else-if="isTextarea"
            :class="{'item-content': interactive}"
        >
            <label
                v-if="label && !interactive"
                :for="id"
            >{{ label }} <span v-if="validate">*</span></label>
            <textarea
                :id="id"
                v-model="input"
                v-validate.disable="validate"
                :name="name"
                :class="{'input-has-value': input}"
                :data-vv-as="validateAs"
                :placeholder="placeholder"
                :style="{'height': `${height}px`}"
                :disabled="disabled"
            />
            <label
                v-if="label && interactive"
                :for="id"
            >{{ label }} <span v-if="validate">*</span></label>

            <div
                v-for="(errorMessage, index) in errors.collect(name)"
                :key="index"
                class="error-message"
            >
                {{ errorMessage }}
            </div>
            <div
                v-if="errorAppend"
                class="error-message"
            >
                <t>{{ errorAppend }}</t>
            </div>
        </div>

        <!-- FILE -->
        <div
            v-else-if="isFile"
            :class="{'item-content': interactive}"
        >
            <label
                v-if="label && !interactive"
                :for="id"
            >{{ label }} <span v-if="checkMandatory">*</span></label>
            <input
                v-if="trim"
                :id="id"
                v-model.trim="input"
                v-validate.disable="validate"
                :name="name"
                :disabled="disabled"
                :class="{'input-has-value': input}"
                :type="type"
                :data-vv-as="validateAs"
                :placeholder="placeholder"
                :style="{'height': `${height}px`}"
                :data-cy="cypress"
                @keyup.13="$emit('onKeyupEnter')"
            >
            <input
                v-else
                :id="id"
                v-model="input"
                v-validate.disable="validate"
                :name="name"
                :disabled="disabled"
                :class="{'input-has-value': input}"
                :type="type"
                :data-vv-as="validateAs"
                :placeholder="placeholder"
                :style="{'height': `${height}px`}"
                :data-cy="cypress"
                @click="$emit('onClick')"
                @keyup.13="$emit('onKeyupEnter')"
            >
            <label
                v-if="label && interactive"
                :for="id"
            >{{ label }} <span v-if="validate">*</span></label>

            <div
                v-for="(errorMessage, index) in errors.collect(name)"
                :key="index"
                class="error-message"
            >
                {{ errorMessage }}
            </div>
            <div
                v-if="errorAppend"
                class="error-message"
            >
                <t>{{ errorAppend }}</t>
            </div>
        </div>
    </fieldset>
</template>

<script>
import Company from '@/models/Company';

import CypressMixin from '@/mixins/CypressMixin';

export default {
    mixins: [
        CypressMixin,
    ],

    inject: ['$validator'],

    props: {
        id: { type: String, required: false }, // Same as <input> attribute
        name: { type: String, required: false }, // Same as <input> attribute
        type: { type: String, default: 'text' }, // Same as <input> attribute

        label: { type: String, required: false },
        placeholder: { type: String, required: false, default: '' },
        validate: { type: [String, Object], required: false },
        validateAs: { type: String, required: false },
        errorAppend: { type: String, required: false },
        disabled: { type: Boolean, default: false },

        isHalf: { type: Boolean, default: false },
        isDeletable: { type: Boolean, default: false },

        height: { type: [Number], default: 48 },

        value: { type: [String, Number, Object, Boolean] },
        options: { type: Array, default: () => [] },

        configs: { type: Object, default: () => {} },

        trim: { type: Boolean, default: false }, // For trim whitespace because of email
        interactive: { type: Boolean, default: false }, // Determines if the label transitions or not

        // Multiselect
        searchValue: { type: [String, null], required: false }, // Used for emit searching (Needs to be sync)
    },

    shared: {
        session: {
            company: {
                type: Company,
                default: {},
            },
        },
    },

    data() {
        return {
            companyLanguage: (this.shared.session && this.shared.session.company) ? this.shared.session.company.company_languages : [],
            selectedLanguage: (this.shared.session && this.shared.session.company && this.shared.session.company.company_languages) ? this.shared.session.company.company_languages.default : {},
            input: this.value,
        };
    },

    computed: {
        checkMandatory() {
            return this.validate && this.validate.includes('required');
        },
        isText() {
            return this.type === 'text' || this.type === 'number' || this.type === 'password';
        },
        isTextExtra() {
            return this.type === 'textextra';
        },
        isTextFlag() {
            return this.type === 'textflag';
        },
        isTextarea() {
            return this.type === 'textarea';
        },
        isImageUpload() {
            return this.type === 'image';
        },
        isFile() {
            return this.type === 'file';
        },
    },

    watch: {
        value() {
            this.input = this.value;
        },

        input(val) {
            this.$emit('input', val);
        },
    },

    methods: {
        inputDelete() {
            if (this.disabled === false) {
                this.$emit('inputDelete');
            }
        },

        languageChange(val) {
            this.$emit('languageChange', val);
        },
    },
};
</script>

<style lang="scss">
    .wrapper-text-flag {
        .multiselect {
            width: calc(64px + 40px);
            margin-right: 1px; // to see input's border on right
            max-height: 48px;

            .multiselect__tags {
                padding: 0 0 0 15px;
                border: none;
            }

            .multiselect__single {
                height: 48px;
                display: flex;
                align-items: center;
            }

            .multiselect__option {
                height: 48px;
                display: flex;
                align-items: center;
            }
        }
    }
</style>

<style lang="scss" scoped>
	@import '~@/styles/var';

    .ht-form {
        margin-bottom: 0 !important;
    }

	input, textarea, select {
		&:focus {
			border-color: $primary-light;

			+ label {
				color: $black !important;
				font-weight: bold;
			}
		}
	}

	.has-error {
		input, textarea, select {
			border-color: $primary;

			+ label {
				color: $primary !important;
			}
		}
		label {
			color: $primary-darker !important;
		}
    }

    .wrapper-text-extra {
        height: 48px;

        img {
            margin-left: 15px;
        }

        &-right {
            &-button {
                margin-right: 30px;
                cursor: pointer;

                .circle {
                    color: $white;
                    border: 1px solid $grey-ultra-light;
                    border-radius: 50%;
                }

                .trash-icon {
                    color: $grey;

                    &:hover {
                        color: $black;
                    }
                }

                &.disabled {
                    cursor: default;

                    .trash-icon:hover {
                        color: $grey;
                    }

                }
            }
        }
    }

    .error-message {
        display: block;
        color: $error;
        margin-top: 10px;
        padding-left: 4px;
        font-style: italic;
    }

    .wrapper-text-flag, .wrapper-text-extra {
        border: 1px solid $grey-ultra-light;
        background: $white;
        border-radius: 5px;
        display:flex;
        align-items:center;
        justify-content: center;

        &.disabled {
            background: $grey-lighter;
        }

        &:focus-within {
            border-color: $primary;
            outline: 0;
        }

        input {
            margin: 0 15px;
            padding: 0;
            border: none;
            background: none;
        }
    }
</style>
