<template>
    <HtCard>
        <PreboardingList />
    </HtCard>
</template>

<script>

import PreboardingList from '@/components/resources/customize/preboarding/PreboardingList.vue';

export default {
    name: 'PagePreboarding',
    components: {
        PreboardingList,
    },

};

</script>
