<template>
    <div
        v-if="value && value.custom_fields && value.custom_fields.length > 0"
        class="mt-4"
    >
        <div
            v-for="(customField, index) in value.custom_fields"
            :key="customField.id"
        >
            <HtFormCustomField
                v-model="value.custom_fields[index]"
                :label="customField.name"
                :id="`custom-field-${customField.id}`"
                :name="`custom-field-${customField.id}`"
                :type="customField.type"
                :disabled="customField.is_editable === false"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'EntityModelCustomFieldGroup',
    props: {
        value: Object,
    },
};
</script>
