<template>
    <HtFormGroup
        :label="label"
        :show-asterisk="false"
        :is-required="true"
    >
        <div v-if="type === 'text'">
            <HtFormInput
                :id="id"
                v-model="value.value"
                :disabled="disabled"
                :name="name"
                :max-length="256"
            />
        </div>
        <div v-if="type === 'boolean'">
            <HtFormSelect
                :id="id"
                v-model="value.value"
                :disabled="disabled"
                :options="options"
                :name="name"
            />
        </div>
    </HtFormGroup>
</template>

<script>
export default {
    name: 'HtFormCustomField',
    props: {
        label: {
            type: String,
            default: () => null,
        },
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        options() {
            return [
                {
                    name: this.translate('Yes'),
                    id: true,
                },
                {
                    name: this.translate('No'),
                    id: false,
                },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
