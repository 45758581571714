<template>
    <HtTable
        :items="items"
        :columns="columns"
        cypress="action-resource-table"
        :empty-message="'There are no requirement to display yet...'"
        :external-search="externalSearch"
        @onCellClicked="$emit('onCellClicked', $event)"
    />
</template>
<script>
import HtTable from '@/components/globals/HtTable.vue';
import ParticipantList from '@/components/globals/ParticipantList.vue';
import ResourceListMixin from '@/mixins/ResourceListMixin';

export default {
    name: 'RequirementsList',
    components: { HtTable, ParticipantList },
    mixins: [
        ResourceListMixin,
    ],
    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'requirement_category.name',
                    class: 'font-weight-bold',
                    search: true,
                },
            ],
        };
    },
};

export const requirementConfig = {
    fields: [
        'id',
        'resourceable_id',
        'resourceable_type',
        'company_program_id',
    ],
    relations: {
        requirementCategory: (query) => query.select([
            'id',
            'name',
            'order',
            'is_heyteam',
        ])
            .with({
                resource: (query) => {
                    query.select([
                        'name',
                    ]);
                },
                companyRequirement: (query) => {
                    query.select([
                        'id',
                    ]);
                },
            })
            .orderBy(['order', 'asc']),
        program: (query) => {
            query.select(['id']).with({
                locales: (query) => {
                    query.select(['id', 'language_key', 'name']);
                },
            });
        },
    },
};

</script>
