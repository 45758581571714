<template>
    <HtGenericModal
        ref="modal"
        size="large"
        @onClose="onClose"
    >
        <template #media>
            <img
                v-if="survey && survey.company_file"
                :src="survey.company_file.link_preview"
                style="height: 100%; width: 100%; object-fit: cover;"
            >
        </template>

        <template #header>
            <span v-if="survey">{{ survey.task.title }}</span>
        </template>

        <template #default>
            <template v-if="survey">
                <SurveyQuestionCompletion
                    v-if="currentContentIsQuestion"
                    v-model="currentContent.question.comment"
                    :question-number="currentContentIndex + 1"
                    :question="currentContent.question"
                    :previous-occurrence="previousOccurrenceContent"
                    :is-read-only="isReadOnly"
                >
                    <template #question>
                        <component
                            :is="currentContentComponent.name"
                            v-if="currentContentComponent"
                            ref="questionComponent"
                            v-bind="currentContentComponent.props"
                            v-model="currentContent.question.answers"
                            :question="currentContent.question"
                            :previous-occurrence="previousOccurrenceContent"
                            :is-read-only="isReadOnly"
                        />
                    </template>
                </SurveyQuestionCompletion>
                <SurveyExtraDisplay
                    v-else
                    :extra="currentContent.extra"
                />

                <HtGenericModal
                    ref="navigationModal"
                    class="survey-navigation-modal"
                    size="small"
                    position="left"
                    :show-footer="false"
                    is-sub-modal
                    @onClose="onCloseNavigationModal"
                >
                    <template #header>
                        <t>Survey navigation</t>
                    </template>

                    <template #default>
                        <UserSurveyNavigation
                            :contents="filteredContents"
                            :mandatory-question-without-answers="mandatoryQuestionWithoutAnswers"
                            :next-local-jump-content-index="nextLocalJumpContentIndex"
                            :is-read-only="isReadOnly"
                            @goto="handleGoToContent"
                        />
                    </template>
                </HtGenericModal>
            </template>
        </template>

        <template #extra-footer>
            <div
                v-if="survey"
                class="progress-bar-container"
            >
                <div
                    class="progress-bar green"
                    :style="{'width': completionPercentage + '%'}"
                />
            </div>
        </template>

        <template #footer>
            <div
                v-if="survey"
                class="survey-footer"
            >
                <HtButton
                    class="survey-actions"
                    :disabled="isSaving || !hasPreviousContent"
                    @click.native="previousContent"
                >
                    <FontAwesomeIcon icon="chevron-left" />

                    <span><t>Previous</t></span>
                </HtButton>

                <HtButton
                    class="survey-navigations-button"
                    @click.native="openNavigationModal"
                >
                    <FontAwesomeIcon :icon="['fas', 'list']" />

                    <span class="percentage"><t>Question</t> {{ (currentContentIndex + 1) }}</span>
                </HtButton>

                <HtButton
                    :class="{'survey-actions': !isLastContent, 'terminate-btn': isLastContent}"
                    :loading="isSaving"
                    :disabled="isSaving || (isLastContent && isReadOnly)"
                    @click.native="nextContent"
                >
                    <t v-if="isLastContent">
                        Finish
                    </t>
                    <template v-else>
                        <span><t>Next</t></span>

                        <FontAwesomeIcon icon="chevron-right" />
                    </template>
                </HtButton>
            </div>
        </template>
    </HtGenericModal>
</template>

<script>
import api from '@/store/api';
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import HtButton from '@/components/globals/HtButton.vue';
import HtGenericModal from '@/components/globals/modals/HtGenericModal.vue';
import SurveyQuestionCompletion from '@/components/resources/user/surveys/questions/SurveyQuestionCompletion.vue';
import SurveyChoiceCompletion from '@/components/resources/user/surveys/questions/Choice/SurveyChoiceCompletion.vue';
import SurveyTextCompletion from '@/components/resources/user/surveys/questions/Text/SurveyTextCompletion.vue';
import SurveyYesNoCompletion from '@/components/resources/user/surveys/questions/YesNo/SurveyYesNoCompletion.vue';
import SurveyFileUploadCompletion from '@/components/resources/user/surveys/questions/FileUpload/SurveyFileUploadCompletion.vue';
import UserSurveyNavigation from '@/components/resources/user/surveys/navigation/UserSurveyNavigation.vue';
import SurveySatisfactionScaleCompletion from '@/components/resources/user/surveys/questions/SatisfactionScale/SurveySatisfactionScaleCompletion.vue';
import CompanySurveyContent from '@/models/CompanySurveyContent';
import SurveyExtraDisplay from '@/components/resources/user/surveys/questions/SurveyExtraDisplay.vue';

export default {
    name: 'UserSurveyCompletion',

    components: {
        UserSurveyNavigation,
        SurveyExtraDisplay,
        HtButton,
        HtGenericModal,
        SurveyQuestionCompletion,
        SurveyChoiceCompletion,
        SurveyTextCompletion,
    },

    data() {
        return {
            survey: null,
            previousOccurrence: null,
            currentContentIndex: 0,
            currentGlobalIndex: 0,
            isTerminated: false,
            isSaving: false,
            mandatoryQuestionWithoutAnswers: [],
        };
    },

    computed: {
        currentContent() {
            return this.filteredContents[this.currentContentIndex];
        },

        previousOccurrenceContent() {
            if (!this.previousOccurrence) {
                return null;
            }

            return this.previousOccurrence.contents.find((content) => content.template.id === this.currentContent.template.id);
        },

        currentContentIsQuestion() {
            return this.currentContent.type === CompanySurveyContent.TYPE_QUESTION;
        },

        currentContentComponent() {
            switch (this.currentContent.type) {
            case CompanySurveyContent.TYPE_QUESTION:
                switch (this.currentContent.question.template.type) {
                case CompanySurveyQuestion.TYPE_SIMPLE_CHOICE:
                case CompanySurveyQuestion.TYPE_MULTIPLE_CHOICE:
                    return {
                        name: SurveyChoiceCompletion,
                        props: {
                            isMultiple: this.currentContent.question.template.type === CompanySurveyQuestion.TYPE_MULTIPLE_CHOICE,
                            previousOccurrence: this.previousOccurrenceContent,
                        },
                    };
                case CompanySurveyQuestion.TYPE_TEXT:
                    return {
                        name: SurveyTextCompletion,
                        props: {
                            previousOccurrence: this.previousOccurrenceContent,
                        },
                    };
                case CompanySurveyQuestion.TYPE_YES_NO:
                    return {
                        name: SurveyYesNoCompletion,
                        props: {
                            previousOccurrence: this.previousOccurrenceContent,
                        },
                    };
                case CompanySurveyQuestion.TYPE_FILE_UPLOAD:
                    return {
                        name: SurveyFileUploadCompletion,
                        props: {
                            previousOccurrence: this.previousOccurrenceContent,
                        },
                    };
                case CompanySurveyQuestion.TYPE_SATISFACTION_SCALE:
                    return {
                        name: SurveySatisfactionScaleCompletion,
                        props: {
                            previousOccurrence: this.previousOccurrenceContent,
                        },
                    };
                }
                break;
            }
        },

        hasPreviousContent() {
            return !!this.filteredContents[this.currentContentIndex - 1];
        },

        hasNextContent() {
            return !!this.filteredContents[this.currentContentIndex + 1];
        },

        nextLocalJumpContentIndex() {
            return this.survey.contents.findIndex((content) => content.type === CompanySurveyContent.TYPE_QUESTION && content.question.template.has_logical_jump && content.question.answers.length === 0 && content.template.order >= this.nextShouldReachGlobalIndex);
        },

        nextShouldReachGlobalIndex() {
            const content = this.survey.contents[this.currentGlobalIndex];

            if (content.type === CompanySurveyContent.TYPE_QUESTION && content.question.template.has_logical_jump && content.question.answers.length > 0) {
                return content.question.template.answers.find((answer) => answer.id === content.question.answers[0].company_survey_question_answer_id).next_content_order;
            }

            if (content.type === CompanySurveyContent.TYPE_QUESTION && content.template.next_content_order) {
                return content.template.next_content_order;
            }

            return (this.currentGlobalIndex + 1);
        },

        nextContentIsLocked() {
            return (this.nextLocalJumpContentIndex + 1) === (this.currentGlobalIndex + 1);
        },

        showedContents() {
            if (!this.survey || this.survey.contents.length < 1) {
                return [];
            }

            if (this.nextLocalJumpContentIndex === -1) {
                return this.survey.contents;
            }

            return this.survey.contents.slice(0, (this.nextLocalJumpContentIndex + 1));
        },

        filteredContents() {
            let shouldReachIndex = 0;

            return this.showedContents.filter((content, index) => {
                if (index < shouldReachIndex || shouldReachIndex === CompanySurveyContent.ORDER_END_SURVEY) {
                    return false;
                }

                if (content.type === CompanySurveyContent.TYPE_QUESTION && content.question.template.has_logical_jump && content.question.answers.length > 0) {
                    shouldReachIndex = content.question.template.answers.find((answer) => answer.id === content.question.answers[0].company_survey_question_answer_id).next_content_order;
                } else if (content.type === CompanySurveyContent.TYPE_QUESTION && content.template.next_content_order) {
                    shouldReachIndex = content.template.next_content_order;
                } else {
                    shouldReachIndex = (index + 1);
                }

                return true;
            });
        },

        isLastContent() {
            return !this.nextContentIsLocked && !this.hasNextContent;
        },

        isReadOnly() {
            return this.survey.task.status === 'done';
        },

        completionPercentage() {
            if (this.isLastContent) {
                return 100;
            }

            return (this.currentGlobalIndex / this.survey.contents.length) * 100;
        },
    },

    methods: {
        previousContent() {
            if (this.hasPreviousContent) {
                this.switchToContentIndex(this.currentContentIndex - 1);
            }
        },

        nextContent() {
            if (this.isLastContent) {
                this.submit();

                return;
            }

            if (this.nextContentIsLocked) {
                this.openNavigationModal();

                return;
            }

            if (this.hasNextContent) {
                this.switchToContentIndex(this.currentContentIndex + 1);
            }
        },

        handleGoToContent(content, shouldCloseNavigationModal = true) {
            const contentIndex = this.filteredContents.findIndex((c) => c.template.id === content.template.id);
            if (contentIndex !== -1) {
                this.switchToContentIndex(contentIndex);

                if (shouldCloseNavigationModal) {
                    this.$refs.navigationModal.close();
                }
            }
        },

        switchToContentIndex(contentIndex) {
            if (this.currentContentIsQuestion && this.currentContent.question.is_edited) {
                this.isSaving = true;

                this.saveDraftQuestion()
                    .then(() => {
                        this.isSaving = false;
                        this.currentContentIndex = contentIndex;
                        this.setCurrentGlobalIndex();
                    });
            } else {
                this.currentContentIndex = contentIndex;
                this.setCurrentGlobalIndex();
            }
        },

        setCurrentGlobalIndex() {
            this.currentGlobalIndex = this.survey.contents.findIndex((content) => content.template.id === this.currentContent.template.id);
        },

        closeModal() {
            this.$refs.modal.close();
        },

        open(survey) {
            this.survey = survey;
            this.survey.contents.map((content) => {
                if (content.type === CompanySurveyContent.TYPE_QUESTION) {
                    content.question.is_edited = false;
                }
            });

            this.previousOccurrence = this.survey.previous_occurrence;

            if (this.isReadOnly) {
                this.currentContentIndex = 0;
                this.currentGlobalIndex = 0;
            } else {
                let resumeIndex = 0;
                const maxIndex = this.filteredContents.length - 1;

                const lastAnsweredQuestionIndex = this.filteredContents
                    .findLastIndex((content) => content.type === CompanySurveyContent.TYPE_QUESTION && content.question && content.question.answers.length > 0);

                if (lastAnsweredQuestionIndex !== -1) {
                    resumeIndex = lastAnsweredQuestionIndex + 1;
                }

                if (resumeIndex > maxIndex) {
                    resumeIndex = maxIndex;
                }

                this.currentContentIndex = resumeIndex;
                this.setCurrentGlobalIndex();
            }

            this.$refs.modal.open();
        },

        openNavigationModal() {
            this.$refs.navigationModal.open();
        },

        onCloseNavigationModal() {
            this.mandatoryQuestionWithoutAnswers = [];
        },

        submit() {
            if (this.isReadOnly) {
                return;
            }

            this.mandatoryQuestionWithoutAnswers = this.filteredContents
                .filter((content) => content.type === CompanySurveyContent.TYPE_QUESTION && content.question.template.is_mandatory && content.question.answers.length === 0);

            if (this.mandatoryQuestionWithoutAnswers.length > 0) {
                this.openNavigationModal();

                return;
            }

            const surveyData = this.filteredContents
                .filter((content) => content.type === CompanySurveyContent.TYPE_QUESTION)
                .map((content) => ({
                    company_survey_question_id: content.question.template.id,
                    is_skipped: content.question.answers.length === 0,
                    answers: content.question.answers,
                    comment: content.question.comment,
                }));

            this.isSaving = true;

            api.user
                .surveys
                .save(this.survey.id, {
                    contents: surveyData,
                })
                .then((data) => {
                    this.isSaving = false;
                    this.isTerminated = true;
                    this.$emit('on-save', data.data.nextOccurrence);
                });
        },

        saveDraftQuestion() {
            if (this.isReadOnly && !this.currentContentIsQuestion) {
                return;
            }

            const questionData = {
                company_survey_question_id: this.currentContent.question.template.id,
                is_skipped: false,
                answers: this.currentContent.question.answers,
                comment: this.currentContent.question.comment,
            };

            return api.user
                .surveys
                .saveDraftQuestion(this.survey.id, this.currentContent.question.id, {
                    data: questionData,
                })
                .then((res) => {
                    this.currentContent.question = res.data.question;
                    this.currentContent.question.is_edited = false;
                });
        },

        onDelete() {
            this.$emit('on-delete');
        },

        onClose() {
            if (!this.isReadOnly && !this.isTerminated && this.currentContentIsQuestion && this.currentContent.question.is_edited) {
                this.saveDraftQuestion();
            }

            this.survey = null;
            this.currentContentIndex = 0;
            this.currentGlobalIndex = 0;
            this.$emit('on-close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import '~@/styles/design-system-colors.scss';

.survey-navigation-modal {
    ::v-deep {
        .modal-wrapper.small {
            min-width: 350px;
            width: 32% !important;

            @media (max-width: $phone) {
                min-width: 100%;
            }
        }
    }
}

.progress-bar-container {
    background-color: #F0FFED;
    border-radius: 0;

    .progress-bar {
        background-color: #00A744;
        transition: all .5s;
        border-radius: 0;
    }
}

.survey-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    .ht-button {
        align-items: center;
        gap: 30px;
        color: white;
        background-color: var(--branding-color);
        border: none;
        font-size: 16px;
        font-family: $lato;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        height: 42px;

        &.survey-navigations-button {
            gap: 0;
            flex: 2;
            background-color: $neutral-100;
            border: 1px solid $neutral-300;
            color: $neutral-900;

            .percentage {
                flex: 2;
            }
        }

        &.terminate-btn {
            background-color: var(--branding-color);
            color: $neutral-white;
        }

        &:disabled {
            background-color: $neutral-100;
            color: $neutral-300;
        }
    }

    @media (max-width: $phone) {
        gap: 8px;

        .ht-button {
            width: auto;

            &.terminate-btn {
                padding: 14px 24px;
            }

            &.survey-actions {
                transform: rotate(90deg);
                padding: 16px;

                & span {
                    display: none;
                }
            }
        }
    }
}
</style>
