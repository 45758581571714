<template>
    <div class="generic-dialog-wrapper">
        <div class="generic-dialog-header">
            <h1 class="title">
                {{ modalTitle }}
            </h1>
        </div>
        <div class="generic-dialog-content">
            <component
                :is="getComponentEdit"
                ref="componentEdit"
                v-model="currentValue"
                :entity-id="entity.id"
            />
        </div>
        <div class="generic-dialog-footer">
            <HtButton
                class="button"
                type="secondary"
                @click.native="$emit('close')"
            >
                {{ translate('Cancel') }}
            </HtButton>
            <HtButton
                class="button"
                type="primary"
                :loading="loading"
                @click.native="onSave"
            >
                {{ translate('Assign') }}
            </HtButton>
        </div>
    </div>
</template>

<script>
import api from '@/store/api';
import HtButton from '@/components/globals/HtButton.vue';
import EditOffice from '@/components/pages/settings/entities/form/edit/EditOffice.vue';
import EditDepartment from '@/components/pages/settings/entities/form/edit/EditDepartment.vue';
import EditJobPosition from '@/components/pages/settings/entities/form/edit/EditJobPosition.vue';
import EditContract from '@/components/pages/settings/entities/form/edit/EditContract.vue';
import EditCustom from '@/components/pages/settings/entities/form/edit/EditCustom.vue';

export default {
    name: 'AddEntityModal',
    components: {
        HtButton,
        EditOffice,
        EditDepartment,
        EditJobPosition,
        EditContract,
        EditCustom,
    },
    inject: ['$validator'],
    props: {
        entityValueName: {
            type: String,
            required: true,
        },
        entity: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            currentValue: {},
            loading: false,
            entityModels: {
                company_office: {
                    apiResource: 'offices',
                    relationName: 'office',
                    componentEdit: 'EditOffice',
                    defaultValues: {
                        is_heyteam: false,
                    },
                },
                company_contract: {
                    apiResource: 'contracts',
                    relationName: 'contract',
                    componentEdit: 'EditContract',
                    defaultValues: {
                        is_heyteam: false,
                        has_probation: false,
                    },
                },
                company_department: {
                    apiResource: 'departments',
                    relationName: 'department',
                    componentEdit: 'EditDepartment',
                },
                company_job_position: {
                    apiResource: 'jobPositions',
                    relationName: 'job_position',
                    componentEdit: 'EditJobPosition',
                    defaultValues: {
                        is_heyteam: false,
                    },
                },
                custom: {
                    apiResource: 'customs',
                    relationName: 'custom',
                    componentEdit: 'EditCustom',
                },
            },
        };
    },
    computed: {
        getComponentEdit() {
            return (
                this.entityModels[this.entity.slug]?.componentEdit
                ?? this.entityModels.custom.componentEdit
            );
        },
        modalTitle() {
            return this.translate(
                `Create new ${(
                    this.entityModels[this.entity.slug]?.relationName ?? 'entry'
                ).replace(/_/g, ' ')}`,
            );
        },
    },
    created() {
        this.currentValue = {
            name: this.entityValueName,
            ...this.entityModels[this.entity.slug]?.defaultValues,
        };
    },
    methods: {
        onSave() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.loading = true;

                    if (typeof this.$refs.componentEdit.beforeSave === 'function') {
                        this.$refs.componentEdit.beforeSave();
                    }

                    api.configuration[this.entityModels[this.entity.slug]?.apiResource ?? this.entityModels.custom.apiResource]
                        .create(this.currentValue)
                        .then((response) => {
                            this.$emit('close', {
                                entity_id: this.entity.id,
                                id: response.data.data.id,
                                name: response.data.data.name,
                            });
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import '~@/styles/modal/genericCenterModal';
.title {
    margin: 0;
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 700;
    line-height: 130%;
}
</style>
