<template>
    <div>
        <div
            v-if="companyUserQuiz.isLoaded()"
            class="quiz-fill"
        >
            <div class="quiz-fill-sidebar" />

            <div
                v-if="isStartStep"
                class="quiz-fill-body quiz-fill-start"
            >
                <div class="quiz-fill-body-main quiz-fill-start-main">
                    <h1 class="quiz-fill-body-title">
                        {{ companyUserQuiz.name }}
                    </h1>
                    <p
                        class="quiz-fill-body-description"
                        v-html="companyUserQuiz.company_quiz.message_welcome"
                    />
                </div>
                <div class="quiz-fill-body-footer">
                    <Button
                        cypress="quiz-set-step-button"
                        @click="setStep('question')"
                    >
                        <template v-if="companyUserQuiz.company_user_program_task.status === 'pending'">
                            <t>Start</t>
                        </template>
                        <template v-else>
                            <t>Continue</t>
                        </template>
                    </Button>
                </div>
            </div>

            <div
                v-if="isQuestionStep"
                class="quiz-fill-body quiz-fill-question"
            >
                <div
                    v-if="currentQuestion"
                    class="quiz-fill-body-main quiz-fill-question-main"
                >
                    <div class="quiz-fill-question-block-left">
                        <div class="quiz-fill-question-tag">
                            <t>Question</t>
                            {{ questionIndex + 1 }}
                        </div>
                        <h1 class="quiz-fill-body-title">
                            {{ currentQuestion.text }}
                        </h1>
                        <div
                            v-if="currentQuestion.company_files_from_locale && currentQuestion.company_files_from_locale.models.length > 0"
                            class="quiz-fill-question-image"
                        >
                            <img :src="$Utils.resolveS3PublicImage(currentQuestion.company_files_from_locale.models[0].fullpath)">
                        </div>
                        <div
                            v-if="currentAnswer && currentAnswer.is_correct_answer === true"
                            class="quiz-fill-question-result success"
                        >
                            <t>Good answer</t>
                        </div>
                        <div
                            v-if="currentAnswer && currentAnswer.is_correct_answer === false"
                            class="quiz-fill-question-result danger"
                        >
                            <t>Bad answer</t>
                        </div>
                    </div>
                    <div
                        class="quiz-fill-question-block-right"
                        :class="{'with-image' : questionAnswerHasImage(currentQuestion.company_quiz_question_answer.models)}"
                    >
                        <div
                            v-for="(questionAnswer, index) in currentQuestion.company_quiz_question_answer.models"
                            :key="index"
                            class="quiz-fill-question-answer"
                            :class="answerClassResult(questionAnswer)"
                            cypress="quiz-add-answer-button"
                            @click="addAnswer(questionAnswer)"
                        >
                            <div
                                v-if="questionAnswer.company_files_from_locale && questionAnswer.company_files_from_locale.length > 0"
                                class="quiz-fill-question-answer-image"
                            >
                                <img :src="$Utils.resolveS3PublicImage(questionAnswer.company_files_from_locale.models[0].fullpath)">
                            </div>
                            <div
                                v-if="questionAnswer.text && questionAnswer.text.length > 0"
                                class="quiz-fill-question-answer-title"
                            >
                                {{ questionAnswer.text }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="displayResultQuestion"
                    class="quiz-fill-body-footer"
                >
                    <Button
                        :state="buttonState"
                        cypress="quiz-next-step-button"
                        @click="nextStep()"
                    >
                        <t>Next</t>
                    </Button>
                </div>
            </div>

            <div
                v-if="isEndStep"
                class="quiz-fill-body quiz-fill-end"
            >
                <div class="quiz-fill-body-main quiz-fill-end-main">
                    <h1 class="quiz-fill-body-title">
                        <t>You have completed this quiz!</t>
                    </h1>
                    <p
                        v-dompurify-html="companyUserQuiz.company_quiz.message_congratulary"
                        class="quiz-fill-body-description"
                    />
                    <div
                        v-if="successRate !== false"
                        class="quiz-fill-end-main-graph"
                    >
                        <UserQuizGraph
                            :pourcentage="successRate"
                            :average-quiz="companyUserQuiz.company_quiz.average_grade"
                        />
                    </div>
                    <template v-else>
                        <IconLoading />
                    </template>
                </div>
                <div class="quiz-fill-body-footer">
                    <!-- <Button class="negative" @click="onExport">
                        <t>Export this form</t>
                    </Button> -->
                    <Button
                        :state="buttonState"
                        cypress="quiz-finish-button"
                        @click="finish()"
                    >
                        <t>Finish</t>
                    </Button>
                </div>
            </div>
        </div>
        <template v-else>
            <IconLoading />
        </template>
    </div>
</template>

<script>
import CompanyUserQuiz from '@/models/CompanyUserQuiz';
import UserQuizGraph from './UserQuizGraph.vue';

export default {
    name: 'UserQuizFill',
    components: {
        UserQuizGraph,
    },
    inject: ['modal'],
    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            buttonState: 'idle',
            displayResultQuestion: false,
            questionIndex: 0,
            currentAnswer: null,
            step: 'start',
            companyUserQuiz: null,
        };
    },
    computed: {
        isStartStep() {
            return this.step === 'start';
        },
        isQuestionStep() {
            return this.step === 'question';
        },
        isEndStep() {
            return this.step === 'end';
        },
        currentQuestion() {
            if (this.companyUserQuiz.company_quiz.company_quiz_question.models[this.questionIndex]) {
                if (this.companyUserQuiz.company_quiz.company_quiz_question.models[this.questionIndex].status === 'pending') {
                    return this.companyUserQuiz.company_quiz.company_quiz_question.models[this.questionIndex];
                }
                this.questionIndex++;
                return this.currentQuestion;
            }
            this.step = 'end';
        },
        numberOfQuestions() {
            if (this.companyUserQuiz.isLoaded()) {
                return this.companyUserQuiz.company_quiz.company_quiz_question.models.length;
            }
            return 0;
        },
        successRate() {
            if (this.step === 'end' && this.companyUserQuiz.isLoaded()) {
                let correctResponseNumber = 0;
                this.companyUserQuiz.company_user_quiz_question_answer.models.map((answer) => correctResponseNumber += answer.is_correct_answer ? 1 : 0);
                if (correctResponseNumber > 0) {
                    return correctResponseNumber / this.numberOfQuestions;
                }
                return 0;
            }
        },
    },
    async created() {
        this.load();
    },

    methods: {
        load() {
            this.companyUserQuiz = new CompanyUserQuiz([
                'id',
                'name',
                'company_quiz_id',
                'company_user_participant_id',
            ])
                .with({
                    companyUser: (query) => {
                        query.select(['id', 'firstname', 'lastname', 'arrival_date'])
                            .with({
                                companyLanguage: (query) => {
                                    query.select(['key']);
                                },
                            });
                    },
                    companyUserProgramTask: (query) => {
                        query.select(['id', 'company_user_id', 'status']);
                    },
                    companyUserQuizQuestionAnswer: (query) => {
                        query.select([
                            'is_correct_answer', 'company_quiz_id', 'company_user_quiz_id',
                            'company_quiz_question_id', 'company_quiz_question_answer_id', 'company_user_id',
                        ]);
                    },
                    companyQuiz: (query) => {
                        query.select([
                            'id', 'name', 'image', 'message_welcome', 'message_congratulary', 'average_grade',
                        ]);
                        query.with({
                            resource: (query) => {
                                query.select(['id', 'name', 'translations']);
                            },
                            companyQuizQuestion: (query) => {
                                query.select(['id', 'text', 'image', 'company_file_id', 'company_quiz_id']);
                                query.with({
                                    companyFilesFromLocale: (query) => {
                                        query.select([
                                            'id',
                                            'path',
                                            'name',
                                            'fullpath',
                                        ]);
                                    },
                                    companyQuizQuestionAnswer: (query) => {
                                        query.select(['id', 'text', 'image', 'company_file_id', 'is_correct_answer', 'company_quiz_question_id']);
                                        query.with({
                                            companyFilesFromLocale: (query) => {
                                                query.select([
                                                    'id',
                                                    'path',
                                                    'name',
                                                    'fullpath',
                                                ]);
                                            },
                                        });
                                    },
                                });
                            },
                        });
                    },
                })
                .where([['id', '=', this.id]]);
            this.companyUserQuiz.company_user_id = this.companyUserId;
            this.companyUserQuiz.get().then(
                () => {
                    if (this.companyUserQuiz.company_user_program_task.status === 'done') {
                        this.setStep('end');
                    } else {
                        let aUserQuizQuestionAnswerId = [];
                        if (this.companyUserQuiz.company_user_quiz_question_answer.models.length > 0) {
                            aUserQuizQuestionAnswerId = this.companyUserQuiz.company_user_quiz_question_answer.models.map((userAnswer) => userAnswer.company_quiz_question_id);
                        }
                        this.companyUserQuiz.company_quiz.company_quiz_question.models.map((element) => {
                            element.status = 'pending';
                            if (aUserQuizQuestionAnswerId.includes(element.id)) {
                                element.status = 'done';
                            }
                            return this.getAnswersList(element.company_quiz_question_answer.models);
                        });
                    }
                },
                () => {
                    this.modal.close();
                },
            );
        },
        questionAnswerHasImage(aQuestionAnswer) {
            for (let i = 0; i < aQuestionAnswer.length; i++) {
                if (aQuestionAnswer[i].image && aQuestionAnswer[i].image.length > 0) {
                    return true;
                }
            }
        },
        setStep(step) {
            this.step = step;
        },
        nextStep() {
            if (this.numberOfQuestions > (this.questionIndex + 1)) {
                if (this.displayResultQuestion) {
                    this.questionIndex++;
                    this.displayResultQuestion = false;
                    this.currentAnswer = null;
                }
            } else {
                this.load();
                this.setStep('end');
            }
        },

        getAnswersList(answers) {
            const shuffle = (array) => array.sort(() => Math.random() - 0.5);
            return shuffle(answers);
        },

        answerClassResult(questionAnswerItem) {
            if (this.displayResultQuestion) {
                if (this.currentAnswer === questionAnswerItem) {
                    return questionAnswerItem.is_correct_answer ? 'success' : 'danger';
                }
                return questionAnswerItem.is_correct_answer ? 'success' : 'disabled';
            }
        },
        addAnswer(questionAnswerItem) {
            if (this.displayResultQuestion === false) {
                this.oCompanyUserQuizAnswers = this.companyUserQuiz.company_user_quiz_question_answer.new();
                this.oCompanyUserQuizAnswers.is_correct_answer = questionAnswerItem.is_correct_answer;
                this.oCompanyUserQuizAnswers.company_user_quiz_id = this.companyUserQuiz.id;
                this.oCompanyUserQuizAnswers.company_quiz_id = this.companyUserQuiz.company_quiz_id;
                this.oCompanyUserQuizAnswers.company_quiz_question_id = questionAnswerItem.company_quiz_question_id;
                this.oCompanyUserQuizAnswers.company_quiz_question_answer_id = questionAnswerItem.id;
                this.oCompanyUserQuizAnswers.company_user_id = this.companyUserQuiz.company_user_id;
                this.oCompanyUserQuizAnswers.company_user_participant_id = this.companyUserQuiz.company_user_participant_id;
                this.oCompanyUserQuizAnswers.status = 'done';

                this.companyUserQuiz.company_user_quiz_question_answer.add(this.oCompanyUserQuizAnswers);

                this.displayResultQuestion = true;
                this.currentAnswer = questionAnswerItem;
                this.onValidate();
            }
        },
        onValidate() {
            this.buttonState = 'loading';
            this.companyUserQuiz.save({ method: 'storeAnswers' })
                .finally(() => {
                    this.buttonState = 'idle';
                });
        },

        onExport() {
            this.companyUserQuiz.onExport();
        },

        finish() {
            this.$emit('onUpdate');
            this.modal.close();
        },
    },
};
</script>

<style lang="scss" scoped src="./UserQuizFill.scss" />
