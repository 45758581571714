import Vue from 'vue';
import { VTooltip } from 'v-tooltip';
import NextFieldDirective from '@/directives/NextField';
import UserImageDirective from '@/directives/UserImage';
import PrivateImageDirective from '@/directives/PrivateImage';

Vue.directive('tooltip', VTooltip);

Vue.directive('next-field', NextFieldDirective);

// User Profile Picture (Avatar)
Vue.directive('user-image', UserImageDirective);

Vue.directive('PrivateImage', PrivateImageDirective);
