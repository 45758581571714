import Vue from 'vue';

export default {
    /**
     * Fetches a list of custom field templates.
     *
     * @param {Object} [config={}] - Optional configuration for the HTTP request, such as query parameters.
     * @param {string} [config.type] - Optional type filter for custom field templates (e.g., 'boolean' or 'text').
     * @returns {Promise} - A promise that resolves with the API response.
     *
     * @example
     * // Fetch all custom field templates
     * api.settings.customFields.all().then(response => {
     *   console.log(response.data);
     * });
     *
     * @example
     * // Fetch custom field templates of type 'boolean'
     * api.settings.customFields.all({ type: 'boolean' }).then(response => {
     *   console.log(response.data);
     * });
     *
     * @example
     * // Handle invalid type
     * api.settings.customFields.all({ type: 'invalid_type' })
     *   .catch(error => {
     *     if (error.response.status === 422) {
     *       console.error('Validation error:', error.response.data);
     *     }
     *   });
     */
    all(params = {}) {
        return Vue.prototype.$http.get('company/settings/custom-field-templates', { params });
    },
    /**
     * Finds a custom field template by its ID.
     *
     * @param {string} id - The ID of the custom field template.
     * @return {Promise} A promise that resolves to the response data.
     */
    findById(id) {
        return Vue.prototype.$http.get(`company/settings/custom-field-templates/${id}`);
    },
    /**
     * Creates a new custom field template.
     *
     * @param {Object} data - The data to send to the API to create the custom field template.
     * @param {string} data.key - The unique key for the custom field.
     * @param {string} data.name - The name of the custom field.
     * @param {string} data.type - The type of the custom field (e.g., 'boolean' or 'text').
     * @param {string} data.table_name - The name of the table associated with the custom field.
     * @param {Object} data.options - Additional options for the custom field.
     * @param {boolean} data.options.field_is_editable - Indicates if the field is editable.
     * @param {boolean} data.options.is_showable - Indicates if the field is showable.
     * @param {boolean} data.options.is_removable - Indicates if the field can be removed.
     * @returns {Promise} - A promise that resolves with the API response.
     */
    store(data = {}) {
        return Vue.prototype.$http.post('company/settings/custom-field-templates', data);
    },
    /**
     * Deletes a custom field template by its ID.
     *
     * @param {string} id - The ID of the custom field template to delete.
     * @return {Promise} A promise that resolves to the response data.
     */
    delete(id) {
        return Vue.prototype.$http.delete(`company/settings/custom-field-templates/${id}`);
    },
};
