<template>
    <div class="item-opentok">
        <div
            class="icon-container"
            @click="videoPlay"
        >
            <div v-show="isVideoPlaying === false && isLoading === false">
                <FontAwesomeLayers class="layer-play">
                    <FontAwesomeIcon
                        size="4x"
                        icon="circle"
                        class="icon-circle"
                        transform="left-5"
                    />
                    <FontAwesomeIcon
                        size="4x"
                        icon="play"
                        transform="left-4 shrink-12"
                        class="icon-play"
                    />
                </FontAwesomeLayers>
            </div>
            <div v-show="isVideoPlaying === false && isLoading === true">
                <IconLoading />
            </div>
        </div>
        <video
            :id="videoPlayerId"
            ref="videoPlayer"
            class="video-scale"
            @click.stop
            @ended="videoEnd"
        >
            <source :src="videoLink">
        </video>
    </div>
</template>

<script>
import CompanyChatChannelMessage from '@/models/CompanyChatChannelMessage';

export default {
    name: 'MessageOpentok',
    props: {
        companyChatChannelMessage: {
            type: CompanyChatChannelMessage,
            required: true,
        },
    },

    shared: {
        videoPlayer: {
            current: {
                type: Object,
                default: null,
            },
        },
        socket: {
            chatOpened: {
                type: Boolean,
                default: false,
            },
        },
    },

    data() {
        return {
            videoPlayerId: `videoPlayer${this.$Utils.uniqId()}`,
            isVideoPlaying: false,
            isLoading: false,
        };
    },

    computed: {
        videoLink() {
            return this.companyChatChannelMessage.company_user_opentok_archive.url;
        },
    },

    watch: {
        'shared.socket.chatOpened': function (value) {
            if (value === false) {
                this.$refs.videoPlayer.pause();
            }
        },
    },

    methods: {
        videoPlay() {
            if (this.isVideoPlaying === false && this.isLoading === false) {
                this.isLoading = true;
                this.companyChatChannelMessage.company_user_opentok_archive.getArchive().then((data) => {
                    this.companyChatChannelMessage.company_user_opentok_archive.url = data.archive.url;

                    // Set previous videoPlayer that is currently playing to pause
                    if (this.shared.videoPlayer.current !== null) {
                        const videoPlayer = document.getElementById(this.shared.videoPlayer.current);
                        videoPlayer.pause();
                    }

                    const playPromise = this.$refs.videoPlayer.play();

                    if (playPromise !== undefined) {
                        playPromise.then(() => {
                            this.isLoading = false;
                            this.isVideoPlaying = true;
                            this.shared.videoPlayer.current = this.videoPlayerId;
                        });
                    }
                }).catch(() => {
                    this.isLoading = false;
                    this.$Notifier('App').showError(
                        this.translate('An error occured when trying to play this video!'),
                    );
                });
            } else {
                this.isVideoPlaying = false;
                this.$refs.videoPlayer.pause();
                this.shared.videoPlayer.current = null;
            }
        },

        videoEnd() {
            this.isVideoPlaying = false;
            this.shared.videoPlayer.current = null;
        },
    },
};
</script>

<style lang="scss" src="./MessageOpentok.scss" scoped></style>
