export default {
    created() {
        if (window.localStorage && window.localStorage.getItem(this.sortKeyLocalStorage)) {
            const localStorageSortingType = this.sortingTypes
                .find((type) => type.value === window.localStorage
                    .getItem(this.sortKeyLocalStorage));

            if (localStorageSortingType) {
                this.sortingType = localStorageSortingType;
            } else {
                window.localStorage.removeItem(this.sortKeyLocalStorage);
            }
        } else {
            const userDefaultDashboardSorts = this.$Auth.getLoggedUser().company_role_user
                .filter((roleUser) => roleUser.role.default_sort_dashboard
                    && roleUser.role.default_sort_dashboard !== this.sortingType.value)
                .map((roleUser) => roleUser.role.default_sort_dashboard)
                .filter((value, index, self) => self
                    .indexOf(value) === index);
            if (userDefaultDashboardSorts.length === 1) {
                const roleDefaultSort = this.sortingTypes
                    .find((type) => type.value === userDefaultDashboardSorts[0]);
                this.onChangeView(roleDefaultSort);
            }
        }
        this.sortTypeLoaded = true;
    },
    data() {
        return {
            sortTypeLoaded: false,
            sortingType: {
                label: this.translate('date'),
                value: 'date',
            },
            sortingTypes: [
                {
                    label: this.translate('date'),
                    value: 'date',
                },
                {
                    label: this.translate('action'),
                    value: 'action',
                },
                {
                    label: this.translate('user'),
                    value: 'user',
                },
            ],

            // Define the sortKeyLocalStorage used in local storage
            // in the data of the component using this mixin
        };
    },
    methods: {
        onChangeView(type) {
            if (type.value !== this.sortingType.value) {
                window.localStorage.setItem(this.sortKeyLocalStorage, type.value);
                if (typeof this.showMultiSelect !== 'undefined') {
                    this.showMultiSelect = false;
                }
            }

            this.sortingType = type;
        },

    },
};
