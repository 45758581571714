<template>
    <HtReadResources
        v-if="requirementCategory"
        :resource-type="resourceType"
        :resource="requirementCategory"
        label-category="Requirement"
        :has-sender="false"
    />
</template>

<script>

import HtReadResources from '@/components/globals/modals/HtReadResources/HtReadResources.vue';

export default {
    name: 'RequirementCategoryRead',
    components: { HtReadResources },
    props: {
        resourceType: {
            type: String,
            required: true,
        },
        resourceId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            requirementCategory: null,
        };
    },

    async created() {
        const request = await this.$http.get(`/configuration/requirement-categories/${this.resourceId}`);
        this.requirementCategory = request.data.data.resource;
    },
};
</script>
