<template>
    <div>
        <div class="input-label">
            {{ label }}
        </div>
        <ResultItem
            v-if="currentUser"
            class="input-user"
            :item="currentUser"
        >
            <template #right-content>
                <div class="cross-icon">
                    <FontAwesomeIcon
                        v-if="!disabled"
                        :icon="['fal', 'times']"
                        @click="unselectUser"
                    />
                </div>
            </template>
        </ResultItem>

        <SearchInput
            v-else
            :results="users"
            :absolute-results="false"
            :loading="loading"
            @on-search="onSearch"
            @item-selection="selectUser"
        />
    </div>
</template>

<script>
import ResultItem from '@/components/miscellaneous/SearchInput/ResultItem.vue';
import SearchInput from '@/components/miscellaneous/SearchInput/SearchInput.vue';
import debounce from 'lodash.debounce';
import api from '@/store/api';

export default {
    name: 'FormInputUser',
    components: { ResultItem, SearchInput },
    props: {
        label: {
            type: String,
            default: () => this.translate('Search an user'),
        },
        roleId: {
            type: Number,
            default: () => null,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            loading: false,
            users: [],
            query: null,
            currentUser: null,
            onSearch: debounce((search) => this.onSearchUser(search), 300),
        };
    },
    methods: {
        onSearchUser(search) {
            if (search.length > 2) {
                this.loading = true;
                api.configuration.roles.getUsersSearch(this.roleId, search).then(({ data }) => {
                    this.users = data.search;
                }).finally(() => {
                    this.loading = false;
                });
            } else {
                this.users = [];
            }
        },

        selectUser(user) {
            this.users = [];
            this.currentUser = user.item;

            this.$emit('input', user.item.id);
        },

        unselectUser() {
            this.currentUser = null;

            this.$emit('input', null);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.input-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}
.input-user {
    border: 1px solid $grey-ultra-light;
}
.cross-icon {
    cursor: pointer;
    color: $color-text-grey;
}
</style>
