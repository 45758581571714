<template>
    <div v-if="companyPlanningEvents">
        <HtAccordion
            ref="accordion"
            :title="translate('Event | Events', {count: companyPlanningEvents.length})"
            :count="companyPlanningEvents.length"
            :has-button="companyPlanningEvents.length === 0"
            :button-label="addButtonLabel"
            :is-branding="false"
            :is-opened="missing"
            cypress="planning-event"
            @buttonClick="openEditModal()"
        >
            <template v-if="companyPlanningEvents.length > 0">
                <HtTable
                    :items="companyPlanningEvents"
                    :columns="columns"
                    cypress="planning-event-table"
                    @onCellClicked="openEditModal"
                >
                    <template #cell(scheduled_date)="scope">
                        {{ getDateTime(scope.item) }}
                    </template>
                    <template #cell(participant)="scope">
                        <ParticipantList :users="scope.item.attendees" />
                    </template>
                    <template #cell(has_missing_organizers)="scope">
                        <HtTag
                            v-if="!scope.item.owner_id"
                            color="primary"
                        >
                            <t>Action required</t>
                        </HtTag>
                    </template>
                </HtTable>
                <div class="mt-4 text-center">
                    <Button
                        type="clear"
                        cypress="planning-event-add-button"
                        @click="openEditModal()"
                    >
                        <slot name="buttonText">
                            {{ translate(addButtonLabel) }}
                        </slot>
                    </Button>
                </div>
            </template>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No Events</t>
                </strong>
            </div>
        </HtAccordion>

        <Modalable
            ref="modalableEdit"
            class="modalable-1 medium"
            :mode="2"
            :options="{title: currentPlanningEvent ? translate('Edit an event') : translate('Add an event')}"
            @onOpen="bottomModalOpened"
            @onClose="bottomModalClosed"
        >
            <UserPlanningEventEdit
                :id="currentPlanningEvent ? currentPlanningEvent.id : ''"
                ref="editComponent"
                :value="currentPlanningEvent"
                :from="'bop'"
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                :intelligent-selection="intelligentSelection"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </Modalable>
    </div>
</template>

<script>
import HtAccordion from '@/components/globals/HtAccordion.vue';
import UserPlanningEventEdit from '@/components/pages/dashboard/modals/planningEvent/UserPlanningEventEdit.vue';
import api from '@/store/api';

export default {
    name: 'UserPlanningEventFlow',
    components: {
        HtAccordion,
        UserPlanningEventEdit,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: true,
        },
        intelligentSelection: {
            type: Array,
            required: false,
        },
        missing: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                    search: true,
                },
                {
                    label: this.translate('Date'),
                    key: 'scheduled_date',
                    sortable: false,
                },
                {
                    label: this.translate('Participants'),
                    key: 'participant',
                    sortable: false,
                },
                {
                    key: 'has_missing_organizers',
                    search: false,
                    sortable: false,
                    class: this.checkMissing ? '' : 'd-none',
                },
            ],
            addButtonLabel: 'Add event',
            currentPlanningEvent: null,
            companyPlanningEvents: null,
        };
    },

    created() {
        this.loadEvents();
    },

    methods: {
        loadEvents() {
            api.user.planningEvents.getAllByUserProgram(this.companyUserProgramId).then((response) => {
                this.companyPlanningEvents = response.data.data;
                this.checkMissing();
            });
        },

        onUpdate() {
            this.loadEvents();
        },

        onDelete() {
            this.loadEvents();
        },

        openEditModal(row) {
            if (row) {
                this.currentPlanningEvent = row.item;
            } else {
                this.currentPlanningEvent = null;
            }
            this.$refs.modalableEdit.open();
        },

        checkMissing() {
            const hasMissingOrganisers = this.companyPlanningEvents.some((event) => !event.owner_id);

            this.$emit('update:missing', hasMissingOrganisers);

            return hasMissingOrganisers;
        },

        getDateTime(item) {
            if (!item.task.datetime_end) {
                return '';
            }

            const language = this.shared.session.companyUser.company_language.key;

            if (!item.time_start) {
                return `${this.$Utils.moment(item.task.datetime_end).format(language === 'fr' ? 'Do MMM' : 'MMM Do')}`;
            }

            return `${this.$Utils.moment(item.task.datetime_start).format(language === 'fr' ? 'Do MMM -- HH:mm' : 'MMM Do -- HH:mm')}-${this.$Utils.moment(item.task.datetime_end).format('HH:mm')}`;
        },

        openAccordion() {
            this.$refs.accordion.open();
        },

        bottomModalOpened() {
            this.$emit('bottomModalOpened');
        },

        bottomModalClosed() {
            this.$emit('bottomModalClosed');
        },
    },
};
</script>
