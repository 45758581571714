import axios from 'axios';
import router from '@/bootstrap/declare-router';
import Auth from '@/services/Auth';
import { getCsrfToken, updateCsrfToken } from '@/helpers/csrf';
import { config } from '@/helpers/env';

// HTTP response codes
export const HTTP_RESPONSE_CONFLICT = 409;
export const HTTP_RESPONSE_UNAUTHORIZED = 401;
export const HTTP_RESPONSE_UNPROCESSABLE_ENTITY = 422;
export const HTTP_RESPONSE_SERVICE_UNAVAILABLE = 503;
export const HTTP_PAGE_EXPIRED = 419;

function handleErrorResponse(Vue, error) {
    const messageKey = error.response.data?.message || 'The given data was invalid.';
    const message = Vue.prototype.$I18n.translate(messageKey);
    Vue.prototype.$Notifier('App').showError(message);
    if (error.response.status === HTTP_RESPONSE_UNAUTHORIZED || error.response.status === HTTP_PAGE_EXPIRED) {
        Auth.logOut();
        router.push('/login');
    } else if (error.response.status === HTTP_RESPONSE_SERVICE_UNAVAILABLE) {
        router.push('Maintenance');
    }
}

// Generic function to create and install HTTP plugin
function createHttpPlugin(pluginName, baseURLFunction) {
    return {
        install(Vue) {
            Vue.prototype[pluginName] = axios.create({
                baseURL: baseURLFunction(),
                withCredentials: true,
                headers: {
                    'x-front-version': config('VERSION'),
                },
            });

            // Error callback
            const errorCallback = (error) => {
                if ([HTTP_RESPONSE_CONFLICT, HTTP_RESPONSE_UNAUTHORIZED, HTTP_RESPONSE_UNPROCESSABLE_ENTITY,
                    HTTP_RESPONSE_SERVICE_UNAVAILABLE, HTTP_PAGE_EXPIRED].includes(error.response.status)) {
                    handleErrorResponse(Vue, error);
                }
                return Promise.reject(error);
            };

            // Success callback
            const successCallback = (response) => {
                if ('x-xsrf-token' in response.headers) {
                    updateCsrfToken(response.headers['x-xsrf-token']);
                }
                return response;
            };

            Vue.prototype[pluginName].interceptors.request.use((config) => {
                config.headers['X-XSRF-TOKEN'] = getCsrfToken();
                return config;
            }, (error) => Promise.reject(error));

            Vue.prototype[pluginName].interceptors.response.use(successCallback, errorCallback);

            Vue.mixin({
                computed: {
                    getBackVersion() {
                        return null; // Placeholder for actual version logic
                    },
                },
            });
        },
    };
}

export { createHttpPlugin };
