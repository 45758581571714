import Model from '@tony.caron/node-model-proxy/Model';
import CompanyEntity from '@/models/CompanyEntity';
import CompanyEntityValue from '@/models/CompanyEntityValue';
import CompanyResourceProgramFilterCollection from '@/models/CompanyResourceProgramFilterCollection';

export default class CompanyResourceProgramFilter extends Model {
    modelConfig() {
        return {
            collection: CompanyResourceProgramFilterCollection,
        };
    }

    modelDefaultValues() {
        return {
            company_entity_id: null,
            company_entity_value_id: null,
        };
    }

    modelRelations() {
        return {
            entity: () => this.belongsTo(CompanyEntity),
            value: () => this.belongsTo(CompanyEntityValue),
        };
    }
}
