import Model from '@tony.caron/node-model-proxy/Model';
import CompanyEquipmentCollection from './CompanyEquipmentCollection';
import CompanyResource from './CompanyResource';
import CompanyResourceProgram from './CompanyResourceProgram';

export default class CompanyEquipment extends Model {
    modelConfig() {
        return {
            primaryKey: 'id',
            controller: 'CompanyEquipmentController',
            collection: CompanyEquipmentCollection,
        };
    }

    modelRelations() {
        return {
            resource: () => this.hasOne(CompanyResource),
            resourceProgram: () => this.hasMany(CompanyResourceProgram),
        };
    }
}
