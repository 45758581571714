<template>
    <div>
        <div class="list-actions-event-calendar">
            <div class="list-actions-event-calendar-header">
                <div
                    v-if="displayedPart === 2"
                    class="mobile-button mobile-button-previous branding-bg"
                    :style="{
                        gridColumnStart: 1,
                        gridColumnEnd: 2,
                        gridRowStart: 1,
                        gridRowEnd: 2
                    }"
                    @click="displayFirstPart"
                >
                    <FontAwesomeIcon icon="chevron-left" />
                </div>
                <div
                    v-for="(day, index) in eventByDay"
                    v-if="index + 2 + calendarOffset > 1"
                    :key="index"
                    class="day-label cellHeader"
                    :style="{
                        gridColumnStart: index + 2 + calendarOffset,
                        gridColumnEnd: index + 3 + calendarOffset,
                        gridRowStart: 1,
                        gridRowEnd: 2
                    }"
                >
                    <span class="true-label">{{ day.label }}</span>
                    <span
                        v-if="displayedPart === 1"
                        class="mobile-button mobile-button-next branding-bg"
                        @click="displaySecondPart"
                    >
                        <FontAwesomeIcon icon="chevron-right" />
                    </span>
                </div>
            </div>
            <div
                ref="calendar"
                class="list-actions-event-calendar-days"
            >
                <div
                    ref="timeNeedleDot"
                    class="time-needle-dot"
                />
                <div
                    ref="timeNeedleLine"
                    class="time-needle-line"
                />
                <template
                    v-for="(day, index) in eventByDay"
                    ref="calendarContent"
                >
                    <div
                        v-for="hour in 24"
                        :key="'hour-' + index + '-' + hour"
                        class="cell"
                        :style="{
                            gridColumnStart: index + 2,
                            gridColumnEnd: index + 3,
                            gridRowStart: (hour - 1) * 4 + 1,
                            gridRowEnd: hour * 4 + 1
                        }"
                    />
                </template>
                <div
                    v-for="hour in 24"
                    :ref="'hour-' + (hour - 1)"
                    :key="'hour-' + hour"
                    class="hour-label"
                    :style="{
                        gridColumnStart: 1,
                        gridColumnEnd: 2,
                        gridRowStart: (hour - 1) * 4 + 1,
                        gridRowEnd: hour * 4 + 1
                    }"
                >
                    {{ hour - 1 }}:00
                </div>
                <template v-for="(day, dayIndex) in eventByDay">
                    <div
                        v-for="(event, eventIndex) in day.events"
                        v-if="dayIndex + 2 + calendarOffset > 1"
                        :key="dayIndex + '-' + eventIndex"
                        class="event branding-bg"
                        :class="getEventSizeClass(event)"
                        :style="{
                            gridColumnStart: dayIndex + 2 + calendarOffset,
                            gridColumnEnd: dayIndex + 3 + calendarOffset,
                            gridRowStart: getRowFromTime(getTimeStart(event)),
                            gridRowEnd: getRowFromTime(getTimeEnd(event))
                        }"
                        :title="event.name"
                        @click="openItemModal(event)"
                    >
                        <div />
                        <div class="overflow-hidden">
                            <div class="event-info">
                                <div class="event-time">
                                    {{ getDate(event) }}
                                </div>
                                <div class="event-title">
                                    {{ event.name }}
                                </div>
                            </div>
                            <ParticipantList
                                v-if="
                                    event.attendees
                                        .length && getEventDuration(event) >= 60
                                "
                                :users="
                                    event.attendees
                                "
                                :limit="2"
                                class="event-participants"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <UserPlanningEventItem
            ref="userItem"
            from="dashboard"
            @onDelete="onDelete()"
            @onUpdate="onUpdate()"
        />
    </div>
</template>
<script>
import moment from 'moment';
import UserPlanningEventItem from '../modals/planningEvent/UserPlanningEventItem.vue';

export default {
    name: 'ListActionsEvent',
    components: {
        UserPlanningEventItem,
    },
    props: {
        events: { type: Array, default: () => [] },
    },

    shared: {
        dashboard: {
            removeIncrementEvent: {
                type: Number,
                default: 0,
            },
        },
    },

    data() {
        return {
            displayedPart: 1,
            planningWeekDayCoordinates: [
                {
                    name: 'Monday',
                    offsetMDPI: {
                        offsetLeft: '0%',
                        offsetRight: '77%',
                    },
                    offsetHDPI: {
                        offsetLeft: '0%',
                        offsetRight: '78%',
                    },
                },
                {
                    name: 'Tuesday',
                    offsetMDPI: {
                        offsetLeft: '19.4%',
                        offsetRight: '57.8%',
                    },
                    offsetHDPI: {
                        offsetLeft: '19.5%',
                        offsetRight: '58.4%',
                    },
                },
                {
                    name: 'Wednesday',
                    offsetMDPI: {
                        offsetLeft: '38.7%',
                        offsetRight: '38.6%',
                    },
                    offsetHDPI: {
                        offsetLeft: '39%',
                        offsetRight: '39%',
                    },
                },
                {
                    name: 'Thursday',
                    offsetMDPI: {
                        offsetLeft: '57.9%',
                        offsetRight: '19.3%',
                    },
                    offsetHDPI: {
                        offsetLeft: '58.4%',
                        offsetRight: '19.5%',
                    },
                },
                {
                    name: 'Friday',
                    offsetMDPI: {
                        offsetLeft: '77.3%',
                        offsetRight: '0%',
                    },
                    offsetHDPI: {
                        offsetLeft: '78%',
                        offsetRight: '0%',
                    },
                },
            ],
        };
    },

    computed: {
        eventByDay() {
            const weekEvent = [];

            if (this.events.length > 0) {
                const firstEventDate = this.$Utils.moment(
                    this.events[0].task.datetime_start,
                    'YYYY-MM-DD HH:mm:ss',
                );
                const begin = this.$Utils.moment(
                    firstEventDate.startOf('isoWeek').format('YYYY-MM-DD'),
                );

                for (let i = 0; i < 5; i++) {
                    const beginLabel = begin.format('dddd Do/MM');
                    weekEvent.push({
                        label:
                            beginLabel.charAt(0).toUpperCase()
                            + beginLabel.slice(1),
                        events: this.events.filter(
                            (e) => begin.format('YYYY-MM-DD')
                                === this.$Utils
                                    .moment(e.task.datetime_start)
                                    .format('YYYY-MM-DD'),
                        ),
                    });
                    begin.add(1, 'days');
                }
            }
            return weekEvent;
        },

        calendarOffset() {
            return -(this.displayedPart - 1) * 2;
        },
    },

    mounted() {
        this.scrollCalendarToNow();

        this.setTimeNeedleToNow();

        window.addEventListener('resize', this.setTimeNeedleToNow);
    },

    methods: {
        onUpdate() {
            this.shared.dashboard.removeIncrementEvent++;
        },

        onDelete() {
            this.shared.dashboard.removeIncrementEvent++;
        },

        openItemModal(item) {
            this.$refs.userItem.open(item.id, item.company_user_id);
        },

        getDate(event) {
            return `${this.getTimeStart(event)} - ${this.getTimeEnd(event)}`;
        },
        getTimezone(event) {
            if (event.timezone) {
                return event.timezone;
            }
            return event.user.timezone;
        },
        getCurrentUserTz() {
            return this.shared.session.companyUser.timezone;
        },
        getTimeStart(event) {
            const date = moment(event.datetime_end).format('YYYY-MM-DD');
            const [hour, minute] = event.time_start.split(':');
            const to = moment.tz(`${date} ${hour}:${minute}:00`, 'YYYY-MM-DD HH:mm:ss', this.getTimezone(event));
            return to.tz(this.getCurrentUserTz()).format('HH:mm');
        },
        getTimeEnd(event) {
            const date = moment(event.datetime_start).format('YYYY-MM-DD');
            const [hour, minute] = event.time_end.split(':');
            const to = moment.tz(`${date} ${hour}:${minute}:00`, 'YYYY-MM-DD HH:mm:ss', this.getTimezone(event));
            return to.tz(this.getCurrentUserTz()).format('HH:mm');
        },
        getRowFromTime(time) {
            const [hour, minutes] = time.split(':');
            return parseInt(hour, 10) * 4 + Math.floor(parseInt(minutes, 10) / 15) + 1;
        },

        getEventDuration(event) {
            const [startHour, startMinutes] = event.time_start.split(':');
            const [endHour, endMinutes] = event.time_end.split(':');

            return (
                parseInt(endHour, 10) * 60
                - parseInt(startHour, 10) * 60
                + parseInt(endMinutes, 10)
                - parseInt(startMinutes, 10)
            );
        },

        getEventSizeClass(event) {
            const duration = this.getEventDuration(event);
            if (duration >= 45) {
                return 'large';
            }
            if (duration >= 30) {
                return 'medium';
            }
            return 'small';
        },

        displayFirstPart() {
            this.displayedPart = 1;
        },

        displaySecondPart() {
            this.displayedPart = 2;
        },

        scrollCalendarToNow() {
            const currentHour = (new Date()).getHours();
            this.$refs.calendar.scroll(0, this.$refs[`hour-${currentHour}`][0].offsetTop);
        },

        setTimeNeedleToNow() {
            const currentHour = (new Date()).getHours();
            const currentMinute = (new Date()).getMinutes();
            const currentWeekDay = (new Date()).getDay();

            const currentDPIOffset = window.innerWidth >= 1920 ? 'offsetHDPI' : 'offsetMDPI';

            if (currentWeekDay === 0 || currentWeekDay === 6) {
                this.$refs.timeNeedleDot.style.display = 'hidden';
                this.$refs.timeNeedleLine.style.display = 'hidden';
            } else {
                this.$refs.timeNeedleDot.style.marginLeft = this.planningWeekDayCoordinates[currentWeekDay - 1][currentDPIOffset].offsetLeft;
                this.$refs.timeNeedleLine.style.marginLeft = this.planningWeekDayCoordinates[currentWeekDay - 1][currentDPIOffset].offsetLeft;
                this.$refs.timeNeedleDot.style.marginRight = this.planningWeekDayCoordinates[currentWeekDay - 1][currentDPIOffset].offsetRight;
                this.$refs.timeNeedleLine.style.marginRight = this.planningWeekDayCoordinates[currentWeekDay - 1][currentDPIOffset].offsetRight;

                this.$refs.timeNeedleDot.style.marginTop = `${currentHour * 9.4 + currentMinute * 0.156}rem`;
                this.$refs.timeNeedleLine.style.marginTop = `${currentHour * 9.4 + currentMinute * 0.156}rem`;
            }
        },
    },
};
</script>

<style lang="scss" src="../listTasks/ByAction/ListActionsByAction.scss" scoped></style>
<style lang="scss" scoped>
@import '~@/styles/var';

.list-actions-event-calendar {
    border: 1px solid $grey-light;
    border-radius: 0.8rem;
    background-color: white;
}

.list-actions-event-calendar-header,
.list-actions-event-calendar-days {
    display: grid;
    grid-template-columns: 3.3rem repeat(5, minmax(15.3rem, 1fr));

    overflow-x: hidden;
}

.list-actions-event-calendar-days {
    // Necessary for the auto-scroll to work correctly
    position: relative;
    // 1 row = one fifteen minutes period => there are 96 fifteen minutes periods in 24 hours
    grid-template-rows: repeat(96, 2.35rem);

    height: 23.4rem;
    overflow-y: scroll;
}

.mobile-button {
    display: none;
}

.day-label {
    color: $grey;
    font-family: $lato-bold;
    font-size: 1.4rem;

    text-align: center;
    height: 3.8rem;
    line-height: 3.8rem;
}

.hour-label {
    color: $grey;
    font-family: $lato-bold;
    font-size: 1rem;
    text-align: right;
    margin-right: 0.4rem;
}
.cellHeader {
    border-bottom: 1px dashed $grey-ultra-light;
    &:nth-child(1) {
        border-left: 1px dashed $grey-ultra-light;
    }
}

.cell {
    border-left: 1px dashed $grey-ultra-light;
    border-bottom: 1px dashed $grey-ultra-light;
}

.event {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin: 0.4rem;
    border-radius: 0.4rem;

    padding: 0.8rem;

    cursor: pointer;

    &.small {
        margin: 0.2rem 0.4rem;
        padding: 0.4rem 0.8rem;
    }

    &.medium {
        padding: 0.6rem 0.8rem;
    }

    .event-info {
        display: flex;
    }

    .event-time {
        flex: 2;
        font-family: $lato;
        line-height: 1.68rem;
        font-size: 1rem;
        color: white;
    }

    .event-title {
        flex: 3;
        float: right;
        line-height: 1.68rem;
        font-family: $lato-bold;
        font-size: 1.4rem;
        word-break: break-all;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &.small .event-title,
    &.medium .event-title {
        font-size: 1.3rem;
    }

    .event-participants {
        align-self: flex-end;
        // Compensating the ParticipantList overflow
        margin-right: 1rem;
    }
}

@media (max-width: $phone) {
    // Hiding the scrollbar
    .list-actions-event-calendar-days {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
        }
    }

    .list-actions-event-calendar-header,
    .list-actions-event-calendar-days {
        display: grid;
        grid-template-columns: 3rem repeat(5, minmax(9rem, 1fr));
    }

    .day-label {
        font-size: 1.2rem;
    }

    .mobile-button {
        color: white;
        border-radius: 50%;

        height: 1.6rem;
        width: 1.6rem;
        font-size: 1rem;

        justify-content: center;
        align-items: center;
        padding-left: 0.2rem;

        cursor: pointer;
    }

    .mobile-button-previous {
        display: flex;
        margin: auto;
    }

    .day-label:nth-child(4) {
        display: flex;
        align-items: center;

        .true-label {
            display: none;
        }

        .mobile-button-next {
            display: flex;
            margin-left: 0.2rem;
        }
    }

    .mobile-button-previous ~ .day-label:nth-child(4) .true-label {
        display: block;
    }

    .hour-label {
        font-size: 0.8rem;
    }
}
</style>
