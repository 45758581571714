export default {
    inserted(el, binding) {
        el.addEventListener('keydown', (e) => {
            if (e.keyCode !== 13) {
                return true;
            }

            if (binding.value && binding.value.linkedToId) {
                el = $(`#${binding.value.linkedToId}`).get(0);
            }

            // if we haven't stored the tabbing order
            if (el.form && !el.form.tabOrder) {
                const els = el.form.elements;
                const ti = [];
                const rest = [];

                // store all focusable form elements with tabIndex > 0
                for (let i = 0, il = els.length; i < il; i++) {
                    if (els[i].tabIndex > 0
                        && !els[i].disabled
                        && !els[i].hidden
                        && !els[i].readOnly
                        && els[i].type !== 'hidden') {
                        ti.push(els[i]);
                    }
                }

                // sort them by tabIndex order
                ti.sort((a, b) => a.tabIndex - b.tabIndex);

                // store the rest of the elements in order
                for (let i = 0, il = els.length; i < il; i++) {
                    if (els[i].tabIndex === 0
                        && !els[i].disabled
                        && !els[i].hidden
                        && !els[i].readOnly
                        && els[i].type !== 'hidden') {
                        rest.push(els[i]);
                    }
                }

                // store the full tabbing order
                el.form.tabOrder = ti.concat(rest);
            }

            // find the next element in the tabbing order and focus it
            // if the last element of the form then blur
            // (this can be changed to focus the next <form> if any)
            // let form = el.form ||  $(el).closest("form");
            if (el.form) {
                for (let j = 0, jl = el.form.tabOrder.length; j < jl; j++) {
                    if (el === el.form.tabOrder[j]) {
                        if (j + 1 < jl) {
                            $(el.form.tabOrder[j + 1]).focus();
                            console.warn('NEXT IS');
                        } else {
                            $(el).blur();
                        }
                    }
                }
            }

            e.preventDefault();
            e.cancelBubble = true;

            return false;
        });

        /* let form = $(el).closest("form");

        if(!form) return;

        let tabOrder = [];

        var els = form.elements,
            ti = [],
            rest = [];

        // store all focusable form elements with tabIndex > 0
        for (var i = 0, il = els.length; i < il; i++) {
            if (els[i].tabIndex > 0 &&
                !els[i].disabled &&
                !els[i].hidden &&
                !els[i].readOnly &&
                els[i].type !== 'hidden') {
                ti.push(els[i]);
            }
        }

        // sort them by tabIndex order
        ti.sort(function(a,b){ return a.tabIndex - b.tabIndex; });

        // store the rest of the elements in order
        for (i = 0, il = els.length; i < il; i++) {
            if (els[i].tabIndex == 0 &&
                !els[i].disabled &&
                !els[i].hidden &&
                !els[i].readOnly &&
                els[i].type !== 'hidden') {
                rest.push(els[i]);
            }
        }

        // store the full tabbing order
        tabOrder = ti.concat(rest);

        // find the next element in the tabbing order and focus it
        // if the last element of the form then blur
        // (this can be changed to focus the next <form> if any)
        for (var j = 0, jl = tabOrder.length; j < jl; j++) {
            if (el === tabOrder[j]) {
                if (j+1 < jl) {
                    //$(this.form.tabOrder[j+1]).focus();
                } else {
                    $(this).blur();
                }
            }
        } */
    },
};
