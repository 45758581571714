import CompanyFile from '@/models/CompanyFile';

/**
 * Trigger the downloading of data
 * @param {string} filename
 * @param {string} urlData
 */
export function downloadFile(filename, urlData) {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', urlData);
    downloadLink.setAttribute('download', filename);
    downloadLink.click();
}

/**
 * Get CSV content
 * @param {Number} fileId
 * @return {Promise<{file_content: Array}>}
 */
export function getExtractedCSVFileFromId(fileId) {
    const companyFile = new CompanyFile(['id', 'link']);
    companyFile.id = fileId;
    companyFile.setLookupMethod('extractCsv');
    return companyFile.get();
}
