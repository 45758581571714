import IconBack from './IconBack.vue';
import IconChange from './IconChange.vue';
import IconCheck from './IconCheck.vue';
import IconChecked from './IconChecked.vue';
import IconCog from './IconCog.vue';
import IconError from './IconError.vue';
import IconLoading from './IconLoading.vue';
import IconNotChecked from './IconNotChecked.vue';
import IconTrash from './IconTrash.vue';

export default Object.entries({
    IconBack,
    IconChange,
    IconCheck,
    IconChecked,
    IconCog,
    IconError,
    IconLoading,
    IconNotChecked,
    IconTrash,
});
