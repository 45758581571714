<template>
    <RoundEmptyBackground class="cursor-not-allowed" />
</template>

<script>
import RoundEmptyBackground from './RoundEmptyBackground.vue';

export default {
    name: 'RoundEmptyBackgroundWithNoCursorNotAllowed',
    components: { RoundEmptyBackground },

};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.cursor-not-allowed {
    cursor: not-allowed;
}

</style>
