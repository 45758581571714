<template>
    <div class="ht-bubbles">
        <HtBubble
            v-for="(item, index) in limitedItems"
            :key="index"
            class="ht-bubbles-item"
        >
            {{ item }}
        </HtBubble>
        <HtBubble v-if="remainingItems.length > 0">
            {{ getNbRemaining }}
        </HtBubble>
    </div>
</template>

<script>
export default {
    name: 'HtBubbles',
    props: {
        items: {
            type: Array,
            default: () => [],
        },

        limit: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        limitedItems() {
            return this.limit > 0 ? this.items.slice(0, this.limit) : this.items;
        },

        remainingItems() {
            return this.items.slice(this.limit);
        },

        getNbRemaining() {
            return `+${this.remainingItems.length}`;
        },
    },

    methods: {
        customClass(tab) {
            return {
                disabled: tab.disabled,
                'branding-color': tab.active,
                'branding-border-bottom': tab.active,
            };
        },
    },
};
</script>

<style lang="scss" scoped src="./HtBubbles.scss"></style>
