import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyProgramNotificationRecipient from './CompanyProgramNotificationRecipient';

export default class CompanyProgramNotificationRecipientCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyProgramNotificationRecipient,
        };
    }
}
