<template>
    <modalable
        ref="Deletewindow"
        class="modal modalable-4"
        :mode="2"
        style="z-index:999999999"
    >
        <p>{{ content }}</p>
        <div class="row-line middle">
            <Button
                class="black"
                @click="reject"
            >
                <t>Cancel</t>
            </Button>
            <Button
                class="red"
                cypress="confirm-deletion"
                @click="resolve"
            >
                <t>Delete</t>
            </Button>
        </div>
    </modalable>
</template>

<script>

import Modalable from '@/Modalable';

export default {
    data() {
        return {
            resolve: () => {},
            reject: () => {},

            title: '',
            content: '',
            element: 'this element',
        };
    },
    computed: {
        defaultTitle() {
            return this.translate('Delete this element?');
        },
        defaultContent() {
            return this.translate('Are you sure you want to delete {element}? Once you click on delete, you will no longer be able to access {element}.', {
                element: this.element,
            });
        },
    },

    created() {
        Modalable.registerWindow(this, 'Deletewindow');
    },
    methods: {
        setElement(element) {
            this.element = element;
        },

        async open({ title = this.defaultTitle, content = this.defaultContent } = { title: this.defaultTitle, content: this.defaultContent }) {
            this.title = title;
            this.content = content;

            return new Promise((resolve, reject) => {
                this.reject = () => { this.$refs.Deletewindow.close(); };
                this.resolve = () => { resolve(); this.$refs.Deletewindow.close(); };

                this.$refs.Deletewindow.open();
                this.$refs.Deletewindow.onClose = () => {
                    reject();
                };
            });
        },
    },
};

</script>
