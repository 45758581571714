export default class ValidatorPlugin {
    static install(Vue) {
        Vue.prototype.$validate = function () {
            return this.$validator.validate().then((result) => new Promise((resolve, reject) => {
                if (result) {
                    resolve();
                    return;
                }

                reject();
            }));
        };
    }
}
