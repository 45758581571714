<template>
    <div class="form-video">
        <div v-show="isLoadedPublisher">
            <div class="wrapper">
                <div class="camera-container">
                    <template v-if="archive && archive.url">
                        <VideoPlayer
                            :url="archive.url"
                            :scale="2.2"
                            :rotate-y="180"
                        />
                    </template>
                    <div
                        v-else
                        id="opentok-publisher"
                        class="opentok-container"
                    />
                </div>
            </div>
            <div class="mt-20">
                <Chronometer ref="chronometer" />
            </div>
            <div class="row-line middle">
                <template v-if="isFinished">
                    <Button
                        class="negative"
                        @click="restart"
                    >
                        <t>Start again</t>
                    </Button>
                    <Button
                        :state="buttonState"
                        @click="send"
                    >
                        <t>Send</t>
                    </Button>
                </template>
                <template v-else>
                    <Button
                        v-if="isStarted"
                        :state="buttonState"
                        @click="stop"
                    >
                        <t>Stop</t>
                    </Button>
                    <Button
                        v-else
                        :state="buttonState"
                        @click="start"
                    >
                        <t>Record</t>
                    </Button>
                </template>
            </div>
        </div>
        <IconLoading v-show="!isLoadedPublisher" />
    </div>
</template>
<script>
import OT from '@opentok/client';
import CompanyChatChannelCollection from '@/models/CompanyChatChannelCollection';
import NewChannelMixin from './mixins/NewChannelMixin';

export default {
    name: 'ChatFormVideo',
    mixins: [NewChannelMixin],
    inject: ['modal'],

    shared: {
        socket: {
            channelCollection: {
                type: CompanyChatChannelCollection,
                default: null,
            },
        },
    },
    data() {
        return {
            isLoadedPublisher: false,
            isStarted: false,
            isFinished: false,
            session: null,
            publisher: null,
            sessionId: null,
            token: null,
            archive: null,
            archiveId: null,
            isVideoPlaying: false,
            buttonState: 'idle',
        };
    },

    computed: {
        channelActive() {
            if (!this.shared.socket.channelCollection) return null;
            return this.shared.socket.channelCollection.channelActive;
        },
        channelActiveId() {
            if (!this.channelActive) return null;
            return this.channelActive.id;
        },
    },

    mounted() {
        this.getSession().then((data) => {
            this.sessionId = data.sessionId;
            this.token = data.token;
            this.initSession();
        }).catch(() => {
            this.handleError({ message: this.translate('A problem occured when trying to get a new session!') });
        });
    },

    beforeDestroy() {
        if (this.publisher) {
            if (this.session) {
                this.session.unpublish(this.publisher);
            }

            this.publisher.destroy();
        }

        if (this.session) {
            this.session.disconnect();
        }
    },

    methods: {
        // ENDPOINTS
        getSession() {
            return new Promise((resolve, reject) => {
                this.API.get('opentok/session', {
                    200: (data) => {
                        resolve(data);
                    },
                    onError: (data, code) => {
                        reject(data, code);
                    },
                });
            });
        },

        getArchive() {
            return new Promise((resolve, reject) => {
                this.API.get(`opentok/archive/${this.archiveId}`, {
                    200: (data) => {
                        resolve(data);
                    },
                    onError: (data, code) => {
                        reject(data, code);
                    },
                });
            });
        },

        startArchive() {
            return new Promise((resolve, reject) => {
                this.API.post('opentok/archive', {
                    sessionId: this.sessionId,
                }, {
                    200: (data) => {
                        resolve(data);
                    },
                    onError: (data, code) => {
                        reject(data, code);
                    },
                });
            });
        },

        stopArchive() {
            return new Promise((resolve, reject) => {
                this.API.put(`opentok/archive/${this.archiveId}`, {
                    200: (data) => {
                        resolve(data);
                    },
                    onError: (data, code) => {
                        reject(data, code);
                    },
                });
            });
        },

        deleteArchive() {
            return new Promise((resolve, reject) => {
                this.API.delete(`opentok/archive/${this.archiveId}`, {
                    200: (data) => {
                        resolve(data);
                    },
                    onError: (data, code) => {
                        reject(data, code);
                    },
                });
            });
        },

        async sendArchiveMessage() {
            if (this.channelActiveId === 'new') {
                await this.saveNewChannel();
            }
            return new Promise((resolve, reject) => {
                this.API.post(`opentok/archive/message/${this.archiveId}`, {
                    channelId: this.channelActiveId,
                    component: 'chat',
                }, {
                    200: (data) => {
                        resolve(data);
                    },
                    onError: (data, code) => {
                        reject(data, code);
                    },
                });
            });
        },
        // ! ENDPOINTS !

        handleError(error) {
            if (error) {
                switch (error.name) {
                case 'OT_USER_MEDIA_ACCESS_DENIED':
                    this.$Notifier('App').showError(this.translate('You need to enable camera and mic to send videos!'));
                    break;
                default:
                    this.$Notifier('App').showError(error.message);
                }

                if (this.publisher) {
                    this.publisher.destroy();
                    this.publisher = null;
                }

                if (this.session) {
                    this.session.disconnect();
                }

                this.modal.close();
            }
        },

        initSession() {
            this.session = OT.initSession(this.$env.getFromEnv('OPENTOK_API_KEY'), this.sessionId);

            this.session.connect(this.token, (error) => {
                if (error) {
                    this.handleError(error);
                } else {
                    this.isLoadedPublisher = true;
                    this.openStream();
                }
            });
        },

        openStream() {
            this.publisher = OT.initPublisher('opentok-publisher', {
                insertMode: 'append',
                width: '100%',
                height: '100%',
                // mirror: false,
            }, this.handleError);

            this.publisher.setStyle({ buttonDisplayMode: 'off' });

            if (this.publisher.accessAllowed) {
                this.session.publish(this.publisher, this.handleError);
            } else {
                this.publisher.on({
                    accessAllowed: () => {
                        this.session.publish(this.publisher, this.handleError);
                    },
                });
            }
        },

        videoPlay() {
            if (this.isVideoPlaying === false) {
                this.$refs.videoPlayer.play();
                this.isVideoPlaying = true;
            } else {
                this.$refs.videoPlayer.pause();
                this.isVideoPlaying = false;
            }
        },

        videoEnd() {
            this.isVideoPlaying = false;
        },

        // BUTTON ACTION
        start() {
            this.buttonState = 'loading';
            this.startArchive().then((data) => {
                this.buttonState = 'idle';
                this.archiveId = data.archiveId;
                this.isStarted = true;
                this.$refs.chronometer.start();
            });
        },

        stop() {
            this.buttonState = 'loading';
            this.$refs.chronometer.stop();
            this.stopArchive().then(() => {
                this.getArchive().then((data) => {
                    this.buttonState = 'idle';
                    this.publisher.destroy();
                    this.archive = data.archive;
                    this.isStarted = false;
                    this.isFinished = true;
                });
            });
        },

        restart() {
            this.buttonState = 'loading';
            this.deleteArchive().then(() => {
                this.buttonState = 'idle';
            });
            this.archive = null;
            this.archiveId = null;
            this.isFinished = false;

            this.$nextTick(() => {
                this.openStream();
            });
            this.$refs.chronometer.reset();
        },

        send() {
            this.buttonState = 'loading';
            this.sendArchiveMessage().then(() => {
                this.modal.close();
            }).catch(() => {
                this.handleError({ message: this.translate('A problem occured when trying to send video message!') });
            });
        },
        // ! BUTTON ACTION !
    },
};
</script>
<style lang="scss" src="./ChatFormVideo.scss" scoped></style>
<style lang="scss">
@import "~@/styles/var";

.OT_publisher {
    border-radius: $border-radius-30;
}
</style>
