import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyEmailTemplateRecipient from './CompanyEmailTemplateRecipient';

export default class CompanyEmailTemplateRecipientCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyEmailTemplateRecipient,
        };
    }
}
