<template>
    <div
        class="main"
        style="background:#0e0e0e"
        :style="'min-height:'+setDebugerHeight+ ' ;max-height:'+setDebugerHeight"
        :class="debugclass"
    >
        <div class="debug-bar">
            <div
                class="top-bar"
                @mousedown="onDocumentMouseDown"
                @mouseup="onOpenToggle"
            >
                <div class="title">
                    The Debugger
                </div>
                <div class="close">
                    <span v-if="debugerStatus.opened">&#x25BC;</span>
                    <span v-else>&#x25B2;</span>
                </div>
            </div>
            <div class="bottom-bar">
                <div class="icon-menu">
                    <router-link
                        title="Autoconnect"
                        class="icon"
                        tag="div"
                        to="/Autoconnect"
                        exact
                    >
                        <FontAwesomeIcon icon="user" />
                    </router-link>
                </div>
                <div class="left-menu">
                    <div class="panel">
                        <transition-group
                            name="slide"
                            tag="div"
                        >
                            <div
                                v-for="item in API_CALL_STACK_reversed"
                                :key="item.id"
                            >
                                <div
                                    class="title"
                                    :title="item.resource"
                                    :class="{selected:item.selected, underline:item.response.response.status !=200}"
                                    @click="onClick(item)"
                                >
                                    <!--<span v-if="item.response.response.config">{{item.response.response.config.duration}}</span>-->
                                    <span
                                        class="method"
                                        :class="'method_'+getMethod(item)"
                                    >{{ getMethod(item) }}</span>
                                    <template v-for="(text, index) in $Debuger.colorString(item.resource)">
                                        <span
                                            :key="index"
                                            :style="'color:'+text.color"
                                        >{{ text.value }}/</span>
                                    </template>
                                </div>
                            </div>
                        </transition-group>
                    </div>
                    <div class="panel-bar">
                        <div
                            class="button"
                            @click="onReplay"
                        >
                            &#9654;Replay()
                        </div>
                        <div
                            class="button"
                            @click="onClear"
                        >
                            &#9654;Clear()
                        </div>
                        <div class="search-bar">
                            <input
                                v-model="search"
                                placeholder="Filter:"
                                type="text"
                            >
                        </div>
                    </div>
                </div>

                <div class="left">
                    <div class="panel">
                        <DebugJson
                            v-if="getSelected"
                            :visible="true"
                            :data="getSelected.parameter"
                        />
                    </div>
                    <div class="panel-bar" />
                </div>
                <div class="right">
                    <div class="panel">
                        <DebugJson
                            v-if="getSelected"
                            :visible="true"
                            :data="getSelectedResponse"
                        />
                    </div>
                    <div
                        class="panel-bar"
                        style="color: wheat;padding: 5px;"
                    >
                        <template v-if="getSelected && getSelectedResponse.stats">
                            Total: {{ getSelectedResponse.stats['total_duration'] }}ms - Php: {{ getSelectedResponse.stats['php_duration'] }}ms - Db: {{ getSelectedResponse.stats['db_duration'] }}ms [{{ getSelectedResponse.stats['db_queries'] }}]
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DebugJson from '@/components/debug/DebugJson.vue';
import Modalable from '@/Modalable';

export default {
    name: 'DebugBar',

    components: {
        DebugJson,
    },

    data() {
        return {

            API_CALL_STACK: [],
            debugerStatus: {},
            request: [],
            search: '',
            debugerClosedHeight: '25px',
            debugerHeigth: '25px',
            test: {},
            toto: 0,
            is_bar_dragged: false,

            // Query
            totalTime: 0,
            queryNb: 0,

            debugclass: '',

        };
    },

    computed: {

        getSelected() {
            let _return = false;

            this.API_CALL_STACK.forEach((item) => {
                if (item.selected) {
                    _return = item;
                    return false;
                }
            });

            return _return;
        },

        getSelectedResponse() {
            const item = this.getSelected;

            item.response.response = { ...item.response.response };

            this.totalTime = 0;
            this.queryNb = 0;

            if (!item.response.response.data) return false;

            // Patch to be sure we work on a copy
            // Sinon un changement dans les vues sur l'objet affecte aussi l'object dans le display du debugger
            // item.response.response = this.$Utils.clone(item.response.response, true)
            // item.response.response.data = this.$Utils.clone(item.response.response.data, true)

            // Try to format sql query
            if (item.response.response.data.query_log) {
                const { query_log } = item.response.response.data;
                for (let i = 0; i < query_log.length; i++) {
                    query_log[i].query = query_log[i].query.replace(/`/g, '');

                    if (undefined !== query_log[i].bindings) {
                        for (let j = 0; j < query_log[i].bindings.length; j++) {
                            query_log[i].query = query_log[i].query.replace('?', `'${query_log[i].bindings[j]}'`);
                        }
                    }

                    this.totalTime += query_log[i].time;
                    this.queryNb++;
                }
            }

            this.totalTime = Math.round(this.totalTime, 2);

            return item.response.response.data;
        },

        API_CALL_STACK_reversed() {
            let result = this.API_CALL_STACK;

            if (this.search != '') {
                result = this.API_CALL_STACK.filter((item) => {
                    let label = item.resource;
                    let index = -1;
                    for (let i = 0; i < this.search.length; i++) {
                        index = label.indexOf(this.search[i]);
                        if (index == -1) return false;
                        label = label.substr(index);
                    }
                    return true;
                });
            }

            return result.slice().reverse();
        },

        setDebugerHeight() {
            const _d = parseFloat(this.debugerHeigth);

            for (const n in Modalable.modalables) {
                if (this.debugerStatus.opened) Modalable.modalables[n].$el.style.height = `${100 - _d}%`;
                else Modalable.modalables[n].$el.style.height = '100%';
            }

            if (this.debugerStatus.opened) return this.debugerHeigth;

            this.debugerHeigth = '60%'; // TODO LOCALSTORAGE
            return this.debugerClosedHeight;
        },
    },
    mounted() {
        this.addEventListener();

        document.body.style.overflow = 'hidden';
        document.body.style.display = 'flex';
        document.body.style.flexDirection = 'column';

        document.body.style.overflow = 'hidden';
        document.getElementById('app').style.overflow = 'auto';
        document.getElementById('app').style.position = 'relative';
        document.body.appendChild(this.$el);

        this.$el.style.zIndex = '10001';
    },

    created() {
        this.API_CALL_STACK = this.$Debuger.API_CALL_STACK;
        this.debugerStatus = this.$Debuger.status;
    },

    beforeDestroy() {
        this.removeEventListener();
    },

    methods: {

        getMethod(item) {
            const method = (item.parameter && item.parameter && item.parameter.method) ? item.parameter.method : item.method;
            if (item.resource.indexOf('lookup') !== -1) return 'LOOKUP*';
            return method.toUpperCase();
        },
        onComponent() {
            let j = 0;
            this.test = {};
            for (const i in this.getSelected.caller._data) {
                if (i == 'debugInComponent') continue;
                this.test[i] = this.getSelected.caller._data[i];
                j++;

                if (j == 2000) break;
            }
            this.toto = 1;
        },

        addEventListener() {
            this.$el.addEventListener('mousedown', this.onMouseDown);
            document.addEventListener('contextmenu', this.contextmenu);
        },

        removeEventListener() {
            this.$el.removeEventListener('mousedown', this.onMouseDown);
            document.removeEventListener('contextmenu', this.contextmenu);
        },

        onDocumentMouseDown() {
            document.addEventListener('mousemove', this.onMouseMove);
            document.addEventListener('mouseup', this.onDocumentMouseUp);
        },

        contextmenu(e) {
            if (e.button == 2 && this.debugerStatus.opened) {
                this.debugclass = '';

                e.preventDefault();
                e.stopPropagation();
                e.cancelBubble = true;
                return false;
            }
        },

        onMouseDown(e) {
            if (e.button == 2 && this.debugerStatus.opened) {
                this.debugclass = 'opacity';
            }
        },

        onDocumentMouseUp() {
            this.is_bar_dragged = false;
            document.removeEventListener('mouseup', this.onDocumentMouseUp);
            document.removeEventListener('mousemove', this.onMouseMove);
        },

        onDestroy() {
            document.removeEventListener('mousemove', this.onMouseMove);
        },

        onMouseMove(e) {
            const documentHeight = $(document).height();

            const percent = 100 - (e.pageY * 100 / documentHeight);

            this.is_bar_dragged = true;

            if (percent < 8) {
                this.$Debuger.closeDebuger();
            } else {
                this.$Debuger.openDebuger();
                this.debugerHeigth = `${percent}%`;
            }
        },

        onClick(item) {
            this.$Debuger.setCurrentApiCall(item);
            if (!item.caller || !item.caller.debugInComponent) return;
            const debugInComponent = item.caller.debugInComponent.$el;
            const app = $('#app');

            if (!debugInComponent) return;

            const { scrollTop } = document.getElementById('app');
            const scrollTopAddHeight = scrollTop + app.height();

            const debugInComponentAbsoluteTop = $(debugInComponent).offset().top + scrollTop;

            app.animate({ scrollTop: (scrollTop - (scrollTopAddHeight - debugInComponentAbsoluteTop)) + 25 }, 500);
        },

        onOpenToggle() {
            if (this.is_bar_dragged) return;
            if (this.debugerStatus.opened) this.$Debuger.closeDebuger();
            else this.$Debuger.openDebuger();
        },

        onClear() {
            this.$Debuger.clear();
        },

        onReplay() {
            const selected = this.getSelected;
            if (selected !== false) {
                this.$Debuger.API.replay(selected);
            }
        },

        toggle(o) {
            this.$refs[o][0].toggle();
        },

    },
};
</script>

<style>
.modalable-1 .modalable-onboarding {
	position:absolute !important;
	height: 100% !important;
}
</style>
<style lang="scss" scoped>
.main{
	flex-shrink:1;
	transition: .1s all;
}

.lag{
	color:red;
}

.opacity {
	opacity: 0.2;
	pointer-events: none;
}

.debug-bar{
	width:100%;
	height:100%;
	bottom:0;
	left:0;

	z-index: 10000;
	display:flex;
	flex-direction: column;

	transition: 0.4s cubic-bezier(0, 0, 0, 1.62);

	.button{
		background: #0e0e0e;
        padding: 4px 5px 5px 4px;
        cursor: pointer;
        border-bottom: 0;
        transition: 0.3s ease-in-out;
        display: inline-block;
        box-sizing: border-box;
        color: wheat;
        border: 1px solid;
        border-top-right-radius: 10px;

		&:hover{
			color:#d69bd6;
		}
	}

	.top-bar{
		display: flex;
		height: 26px;
		background: #1d1d1d;
		box-shadow: -3px 13px 5px 12px #1d1d1d;
		user-select: none;
		cursor: pointer;
		border-bottom: 1px solid #615f5f;
		color: wheat;
		justify-content: center;
        align-items:center;

		.close{
            display: flex;
            align-items:center;
            justify-content: center;
			width: 25px;
			height: 25px;
			font-family: monospace;
			font-size: 2.3rem;
			text-align: center;
		}
	}

	.bottom-bar{
		display: flex;
		flex-basis: 100%;
		height:100%;

		.title{
			 transition: 0.3s ease-in-out;
			cursor: pointer;
			display:flex;
			font-size: 0.9em;
			&:hover, &.selected{
				background-color:#285f61;
			}
			&.underline{
				border-left: 11px solid red;
				border-radius: 2rem;
			}

			.method{
				border: 1px solid;
				background: orange;
				border-radius: 4px;
				padding: 1px;
				margin-right: 1px;
				font-size: 0.8em;
				min-width: 62px;
				text-align: center;
			}

			.method_POST, .method_STORE{
				background:#00dcff;
			}

			.method_UPDATE{
				background:#6eff00;
			}
		}

		.icon-menu{
			width: 32px;
			background: rgb(14, 14, 14);
			border-right: 1px solid #615f5f;

			.icon{
				width: 32px;
				height: 32px;
				cursor: pointer;
				color: white;
				text-align: center;
				padding-top: 5px;

				&:hover{
					border:1px solid #ccc;
				}
			}
		}

		.left-menu{
			width: 25%;
			height: 100%;
			overflow: hidden;
			background: rgb(14, 14, 14);
			font-family: monospace;
			font-size: 1.2em;
			border-right: 1px solid #615f5f;
			display: flex;
			flex-direction: column;
            position: relative;

			.search-bar{

				width: 100%;
				display: flex;
				height: 100%;
				input{
					background: #1d1d1d;
					border: 0;
					width: 100%;
					color:rgb(136, 226, 130);
					padding: 0px 5px;
					font-size: 1.3rem;
				}
			}
		}

		.panel{

			height: 100%;
			padding: 5px;
			overflow-y: auto;
			overflow-x: hidden;

			&::-webkit-scrollbar {
				width: 10px;
			}

			&::-webkit-scrollbar-thumb {
				background: #353535;
			}

			&::-webkit-scrollbar-track {
				-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
				border-radius: 0px;
				margin-top: 2px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 0px;
				-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
			}

		}

		.panel-bar{
            position: absolute;
            bottom: 0;
            width: 100%;
            background: #1d1d1d;
            height: 30px;
            border-top: 1px solid #615f5f;
            display: flex;
        }

		.left, .right{
			overflow: hidden;
			background: rgba(14, 14, 14, 0.9);
			font-family: monospace;
			font-size: 1.2em;
			border-right: 1px solid #615f5f;
			display: flex;
			flex-direction: column;
            position: relative;
			height: 100%;
		}

		.left{
			width: 27%;
		}
		.right{
			width: 48%;
		}
	}

}

.slide-enter-active, .slide-leave-active  {
	transition: all .5s ease-in-out;
	overflow-y: hidden;
	overflow-x: hidden;
}

.slide-enter{
    max-height: 0;
}

.slide-enter-to {
    max-height: 200px;
}

.slide-leave{
    max-height: 200px;
}

.slide-leave-to{
    max-height: 0;
}
</style>
