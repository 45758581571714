<template>
    <div
        class="wrapper"
        @click="onClick"
    >
        <FontAwesomeIcon
            :icon="['fal', 'plus-circle']"
            class="mr-3 branding-color"
            size="lg"
        />
        <div>
            <t>{{ label }}</t>
        </div>
        <Uploadable
            ref="uploadable"
            :media="uploadBar.media"
            :extension="uploadBar.extension"
            ressource="company_file"
            :max-size="uploadBar.maxSize"
            :is-requirement="uploadBar.isRequirement"
            @onUploadStart="onUploadStart"
            @onUploadProgress="onUploadProgress"
            @onUploadEnd="onUploadEnd"
            @onUploadError="onUploadError"
            @onUploadCancel="onUploadCancel"
        />
    </div>
</template>
<script>
import uploadBarEventBus from '@/eventBus/uploadBar';
import Uploadable from '../Uploadable.vue';

export default {
    components: { Uploadable },
    inject: ['uploadBar'],
    props: {
        label: {
            type: String,
            required: true,
        },
    },

    methods: {
        onClick() {
            this.$refs.uploadable.open();
        },

        onUploadStart() {
            uploadBarEventBus.$emit('upload-start');
        },

        onUploadProgress(uploadInfo) {
            this.uploadBar.widthProgressBar = 0.9 * uploadInfo.percent;
            this.uploadBar.$emit('onUploadProgress');
        },

        /**
         * @param {Object} uploadInfo données du navigateur sur le fichier
         * @param {Object} data données du back-end sur le fichier
         * @returns {void}
         */
        onUploadEnd(uploadInfo, data) {
            this.uploadBar.$emit('update:fileSize', uploadInfo.size);
            this.uploadBar.widthProgressBar = 100;
            const file = {
                id: data.id,
                size: uploadInfo.size,
                uuid: data.uuid,
                original_name: data.original_name,
                detected_extension: data.detected_extension,
                detected_mime_type: data.detected_mime_type,
                link: data.link,
                link_preview: data.link_preview,
                content: data.content,
            };

            this.uploadBar.$emit('input', file);
            this.uploadBar.$emit('onUploadEnd');
            uploadBarEventBus.$emit('upload-end');
        },

        onUploadError(code) {
            if (code === 415) {
                this.$Notifier('App').showError(this.translate('This media is not currently supported.'));
            }
            this.uploadBar.$emit('onUploadEnd');
        },

        onUploadCancel() {
            this.uploadBar.$emit('onUploadEnd');
        },
    },
};
</script>
<style lang="scss" scoped>
    .wrapper {
        display: flex;
        cursor: pointer;
    }
</style>
