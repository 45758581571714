import Vue from 'vue';

/**
 * Display a notification
 *
 * @param {string} message
 * @param {string} type
 * @param {number} timer
 */
export function showNotification(message, type = 'info', timer = 5000) {
    const methodNamesKeyedByType = {
        info: 'showInfo',
        warning: 'showWarning',
        error: 'showError',
    };

    const app = Vue.prototype.$Notifier('App');
    const methodName = methodNamesKeyedByType[type] || 'showNotif';

    app[methodName](message, timer);
}
