<!-- eslint-disable vue/no-unused-vars -->
<template>
    <div
        class="card-list"
        :class="options.class"
    >
        <slot />
        <Card
            v-for="(item, index) in countEmpty"
            :options="{
                type: options.type,
                perRow: options.perRow,
            }"
            class="empty"
        />
    </div>
</template>

<script>
export default {
    name: 'CardList',

    props: {
        countEmpty: {
            type: Number,
            default: 0,
        },

        options: {
            type: Object,
            default: () => ({
                type: 'square',
                perRow: 3,
            }),
        },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	.card-list {
	    display: flex;
	    -ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	    -ms-flex-pack: start;
	    justify-content: space-between;
	    width: 100%;
		margin-top: 1em;
		&.no-justify{
			width: calc(100% + 8px);
			justify-content: flex-start;
			.card{
				margin-right: 8px;
			}
		}
		&.card-centered{
			.card{
				justify-content: center;
			}
		}
	}

	@media (max-width: $phone) {
		.card-list {
			justify-content: center;
		}
	}
</style>
