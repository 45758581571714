<template>
    <div
        class="input-wrapper-config"
        :style="zIndex"
    >
        <div
            class="input-wrapper-config-icon"
            @click="toggleConfig"
        >
            <FontAwesomeIcon
                :icon="['far', 'ellipsis-h']"
                size="2x"
            />
        </div>
        <transition name="fade">
            <div
                v-show="showConfig"
                class="input-wrapper-config-panel"
            >
                <div
                    v-if="isAdminMode"
                    class="input-wrapper-config-panel-row"
                >
                    <t>Label</t>
                    <HtInputText
                        id="label"
                        class="input-wrapper-config-panel-row-textfield"
                        name="label"
                        :value="title"
                        @input="setTitle"
                        @click.native="onClick"
                        @dblclick.native="dbClick"
                    />
                </div>
                <div v-if="canMandatorize && isAdminMode">
                    <div class="input-wrapper-config-panel-row">
                        <t>Mandatory</t>
                        <HtFormSwitch
                            id="mandatory"
                            class="input-wrapper-config-panel-row-switch"
                            name="mandatory"
                            :show-optional="false"
                            :value="mandatory"
                            @input="mandatorize"
                        />
                    </div>

                    <div
                        v-if="fieldType === 'text'"
                        class="input-wrapper-config-panel-row"
                    >
                        <t>Specific rules</t>
                        <div class="input-wrapper-config-panel-row-selector">
                            <HtFormSelector
                                id="specific_rule"
                                name="specific_rule"
                                :placeholder="translate('None')"
                                :options="getRuleOptions"
                                is-single
                                track-by="key"
                                specific-key="key"
                                label-options="label"
                                :value="getSpecificRule"
                                @input="setSpecificRule"
                            />
                        </div>
                    </div>

                    <div
                        v-if="fieldType != 'date' && !getSpecificRule"
                        class="input-wrapper-config-panel-row"
                    >
                        <t v-if="fieldType === 'numeric'">
                            Minimum value
                        </t>
                        <t v-else>
                            Minimum length
                        </t>
                        <HtInputText
                            id="min"
                            class="input-wrapper-config-panel-row-textfield"
                            name="min"
                            type="number"
                            :rules="minFieldValidationRule"
                            :value="fieldMinValue"
                            :disabled="!!getSpecificRule"
                            @input="(value) => setRule('min', value)"
                            @click.native="onClick"
                            @dblclick.native="dbClick"
                        />
                    </div>

                    <div
                        v-if="fieldType != 'date' && !getSpecificRule"
                        class="input-wrapper-config-panel-row"
                    >
                        <t v-if="fieldType === 'numeric'">
                            Maximum value
                        </t>
                        <t v-else>
                            Maximum length
                        </t>
                        <HtInputText
                            id="max"
                            class="input-wrapper-config-panel-row-textfield"
                            name="max"
                            type="number"
                            :rules="maxFieldValidationRule"
                            :value="fieldMaxValue"
                            :disabled="!!getSpecificRule"
                            @input="(value) => setRule('max', value)"
                            @click.native="onClick"
                            @dblclick.native="dbClick"
                        />
                    </div>
                </div>
                <div
                    v-if="canSetRole && getRoleOptions.length"
                    class="input-wrapper-config-panel-row"
                >
                    <t>Target Role</t>
                    <div class="input-wrapper-config-panel-row-selector">
                        <HtFormRoleSelector
                            id="role"
                            :placeholder="translate('All participants')"
                            :value="role"
                            name="role"
                            :options="getRoleOptions"
                            :is-single="true"
                            @input="setRole"
                        />
                    </div>
                </div>
                <div class="input-wrapper-config-panel-row">
                    <div @mousedown="$emit('delete')">
                        <t class="input-wrapper-config-panel-row-button">
                            Delete
                        </t>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtInputText from '@/components/globals/HtInputText.vue';
import HtFormRoleSelector from '@/components/globals/Selectors/HtFormRoleSelector.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import FieldValidator from '../FieldValidator';

export default {
    name: 'InputConfig',
    components: {
        HtFormSwitch,
        HtInputText,
        HtFormRoleSelector,
        HtFormSelector,
    },
    props: {
        canMandatorize: {
            type: Boolean,
            required: true,
        },
        mandatory: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        roleId: {
            type: Number,
            default: null,
        },
        fieldType: {
            type: String,
            default: 'text',
        },
        fieldRules: {
            type: Object,
            default: () => null,
        },
        fieldId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            showConfig: false,
            zIndex: 'z-index:100;',
        };
    },
    computed: {
        canSetRole() {
            return this.getRoleOptions.length > 0;
        },
        getRoleOptions() {
            return this.$store.state.stepperform.item?.resource?.programs[0]?.participants?.filter(
                (p) => (['executor', 'signer']).includes(p.type),
            ).map((p) => p.role) || [];
        },
        getRuleOptions() {
            return FieldValidator.specificTextRules();
        },
        getSpecificRule() {
            const allowedKeys = FieldValidator.specificTextRules().map((rule) => rule.key);
            return Object.keys(this.fieldRules || {}).find((key) => allowedKeys.includes(key));
        },
        role() {
            return this.$store.state.roles.roles.find((role) => role.id === this.roleId);
        },
        isAdminMode() {
            return !!this.$store.state.stepperform.item?.resource;
        },
        fieldMinValue() {
            return this.fieldRules?.min || '';
        },
        fieldMaxValue() {
            return this.fieldRules?.max || '';
        },
        minFieldValidationRule() {
            let rule = 'min_value_or_empty:0';
            if (this.fieldRules?.max) {
                rule += `|max_value_or_empty:${this.fieldRules.max}`;
            }
            return rule;
        },
        maxFieldValidationRule() {
            const min = this.fieldRules?.min ?? 0;
            return `min_value_or_empty:${min}`;
        },
    },
    created() {
        this.$store.dispatch('roles/fetchRoles');
        if (!this.roleId && !this.fieldId) {
            const defaultRoleId = this.getRoleOptions[0]?.id;
            this.$emit('setRole', defaultRoleId);
        }
    },
    methods: {
        mandatorize() {
            this.$emit('mandatorize');
        },
        setSpecificRule(v) {
            this.setRule('min', '');
            this.setRule('max', '');
            this.$emit('setSpecificRule', v);
        },
        setTitle(v) {
            this.$emit('setTitle', v);
        },
        setRole(role) {
            this.$emit('setRole', role?.id);
        },
        setRule(key, value) {
            this.$emit('setRule', { key, value });
        },
        dbClick(event) {
            event.stopPropagation();
            event.target.select();
        },
        onClick(event) {
            event.target.focus();
            if (event.target.type !== 'number') {
                event.target.setSelectionRange(event.target.value.length, event.target.value.length);
            }
        },
        toggleConfig() {
            this.showConfig = !this.showConfig;
            if (this.showConfig) {
                this.zIndex = 'z-index:101;';
            } else {
                this.zIndex = 'z-index:100;';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
$panel-width: 400px;

.input-wrapper-config {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 20px;
    height: 20px;
    right: -25px;

    &-icon {
        cursor: pointer;
    }

    &-panel {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: $panel-width;
        top: 25px;
        right: -5px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 8px 8px -4px grey;

        &-row {
            width: $panel-width;
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 30px;
            max-height: 50px;
            font-size: 1.6em;
            padding: 10px;

            &-switch {
                position: relative;
                top: 1.2rem;
            }

            &-textfield {
                width: 28ch;
                height: 30px;
            }

            &-selector {
                width: 25ch;
                height: 50px;
            }

            &-button {
                cursor: pointer;
                color: var(--branding-color);

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media (max-width: $tablet) {
    .input-wrapper-config{
        right: -15px;
        height: 0.7em;
    }
}
@media (max-width: $phone) {
    .input-wrapper-config{
        right: -10px;
        height: 0.5em;
    }
}
</style>
