<template>
    <div class="holding-headbar">
        <div
            v-for="(link, index) in links"
            :key="index"
            class="holding-headbar-link"
        >
            <router-link :to="link">
                <t>{{ link.label }}</t>
            </router-link>
        </div>
        <div class="holding-headbar-profile">
            <div
                class="holding-headbar-profile-user"
                :style="{cursor:'pointer'}"
                @click.stop="toggleUserMenu()"
            >
                <FontAwesomeIcon
                    :icon="['far', 'user']"
                    :style="{ color: 'white', height:'3.2rem', width:'3.2rem'}"
                />
            </div>
            <HeadBarDropdown
                :show-user-menu="showUserMenu"
                @showUserMenu="showUserMenu = $event"
            />
        </div>
    </div>
</template>

<script>
import HeadBarDropdown from '@/components/miscellaneous/HeadBarDropdown.vue';

export default {
    name: 'HeadBar',
    components: {
        HeadBarDropdown,
    },
    data() {
        return {
            links: [
                {
                    name: 'HoldingDashboard',
                    label: 'Holding',
                },
                {
                    name: 'HoldingResources',
                    label: 'Resources',
                },
                {
                    name: 'HoldingSettings',
                    label: 'Settings',
                },
            ],
            showUserMenu: false,
        };
    },
    methods: {
        toggleUserMenu() {
            this.showUserMenu = !this.showUserMenu;
        },
    },
};
</script>

<style lang="scss" scoped>
.holding-headbar {
    padding-top:2.2rem;
    padding-bottom: 2.2rem;
    background-color: #2E2F39;
    display: flex;
    justify-content: center;
    position:relative;
    height: 7.8rem;
    &-link{
        padding: 0 1.2rem;
        height: 3.5rem;
        a {
            color: #FFFFFF;
            text-decoration: none;
            padding: 0.8rem;
            border-radius: 0.4rem;
            display:inline-block;
            &.active {
                background: rgba(255, 255, 255, 0.12);
            }
        }
    }
    &-profile {
        position:absolute;
        right: 4.7rem;
        top: 2.3rem;
    }
}
</style>
