<template>
    <div>
        <div
            class="chat-list-message-header"
        >
            <div class="chat-list-message-header-prev">
                <div
                    class="indicator__header-icon icon-prev"
                    @click="$emit('onSwitchPanel')"
                >
                    <FontAwesomeIcon icon="chevron-left" />
                </div>
            </div>
            <div />
            <div class="chat-list-message-header-left">
                <div
                    class="chat-list-message-header-avatar"
                    @click="onShowConfig"
                >
                    <div
                        v-if="showIconAsAvatar"
                        class="avatar-img bg-red"
                    >
                        <FontAwesomeIcon
                            icon="users"
                            style="color:white"
                        />
                    </div>
                    <div
                        v-else-if="showGroupImage"
                        class="avatar-img"
                    >
                        <img :src="channel.avatar_image.link">
                    </div>
                    <div v-else>
                        <template>
                            <div
                                v-user-image="userImage"
                                class="avatar-img user-image"
                            />
                            <div
                                class="avatar-status"
                                :class="{ active: isConnected }"
                            />
                        </template>
                    </div>
                </div>
                <div
                    class="chat-list-message-header-users"
                    @click="onShowConfig"
                >
                    <div>{{ userNameMain }}</div>
                    <div>
                        {{ userNameSecond }}
                    </div>
                </div>
            </div>
            <div class="chat-list-message-header-right">
                <div class="chat-list-message-header-close">
                    <div
                        class="indicator__header-icon icon-close"
                        @click="onClose"
                    >
                        <FontAwesomeIcon :icon="['fal', 'times']" />
                    </div>
                </div>

                <div
                    v-if="isAiBotChannel === false"
                    class="chat-list-message-header-config"
                    @click="onShowConfig"
                >
                    <div
                        class="indicator__header-icon icon-config"
                        :class="{'config-open': configOpen}"
                    >
                        <FontAwesomeIcon :icon="['fa', 'ellipsis-h']" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyChatChannel from '@/models/CompanyChatChannel';
import I18n from '@/modules/i18n/I18n';

export default {
    name: 'ChatListMessageHeader',
    props: {
        channel: {
            type: CompanyChatChannel,
            required: true,
        },
        configOpen: {
            type: Boolean,
            required: true,
        },
        isAiBotChannel: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showIconAsAvatar() {
            return this.channel.type === 'multi' || (this.channel.type === 'group' && !this.channel.avatar_image?.id);
        },

        showGroupImage() {
            return this.channel.type === 'group' && this.channel.avatar_image?.id;
        },

        userImage() {
            return {
                firstname: this.channel.company_chat_channel_user.channelUserInterlocutor.company_user.firstname,
                lastname: this.channel.company_chat_channel_user.channelUserInterlocutor.company_user.lastname,
                image: this.channel.company_chat_channel_user.channelUserInterlocutor.company_user.image,
            };
        },

        userNameMain() {
            if (this.channel.type === 'duo') {
                return this.channel.company_chat_channel_user.channelUserInterlocutor.company_user.firstname;
            }
            if (this.channel.name) {
                return this.channel.name;
            }
            return this.getUserConcat();
        },

        userNameSecond() {
            if (this.channel.type === 'duo') {
                return '';
            }
            if (this.channel.subname) {
                return this.channel.subname;
            }
            return `${this.channel.company_chat_channel_user.models.length} ${I18n.translate('participants')}`;
        },

        isConnected() {
            return this.channel.company_chat_channel_user.channelUserInterlocutor.company_user.isConnected;
        },

    },
    methods: {
        getUserConcat() {
            let name = '';

            this.channel.company_chat_channel_user.models.forEach((user) => {
                if (user.company_user_id !== this.shared.session.companyUser.id) {
                    name += `${user.company_user.firstname}, `;
                }
            });

            return name.substr(0, name.length - 2);
        },

        onClose() {
            this.$emit('onClose');
        },

        onShowConfig() {
            this.$emit('onShowConfig');
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.chat-list-message-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    margin-right: 2rem;

    @media (max-width: $phone){
        .chat-list-message-header-prev {
            margin-right: 1rem;
            margin-left: 1rem;
        }
    }

    &-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        width:100%;
    }

    &-right {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        flex: 0;
        width:100%;
    }

    &-avatar {
        position: relative;

        .avatar-img {
            display:flex;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            margin-left: 1rem;

            &.bg-red {
                background-color: $primary;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .avatar-status {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $grey;

            &.active {
                background-color: $green;
            }
        }
    }

    &-users {
        flex: 4;
        margin-left: 1rem;
        overflow: hidden;

        :nth-child(1) {
            font-weight: bold;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        :nth-child(2) {
            color: lightgray;
            font-size: .90em;
            margin-top: .5rem;

            &.clickable {
                cursor: pointer;
            }
        }
    }
    &-config {
        margin-left: auto;
        align-items: top;
    }
    &-avatar, &-users {
        cursor: pointer;
    }
    @media (min-width: $phone){
        &-avatar, &-users {
            pointer-events: none;
            cursor: auto;
        }
    }
    @media (max-width: $phone){
        &-config {
            display: none;
        }
    }

    &-close {
        margin-left: auto;
    }

    .indicator__header-icon {
        position: relative;
        right: unset;
        bottom: unset;
        width: 24px;
        height: 24px;
        padding-top: 5px;
        border-radius: 4px;
        font-size: 13px;
        text-align: center;
        cursor: pointer;
        border: solid lightgray .5px;
        display:inline-block;
        font-weight: bold;

        &.icon-prev {
            display: none;
        }
    }

}

@media (max-width: $phone) {
    .chat-list-message-header {
        padding-left: .5rem;

        &-avatar {
            .avatar-img {
                display:flex;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                margin-left: 0;

                &.bg-red {
                    background-color: $primary;
                }
            }
        }

        .indicator__header-icon {
            position: relative;
            right: unset;
            bottom: unset;
            width: 24px;
            height: 24px;
            padding-top: 5px;
            border-radius: 4px;
            font-size: 13px;
            text-align: center;
            cursor: pointer;
            border: solid lightgray .5px;
            display:inline-block;

            &.icon-prev {
                display: block;
            }
        }
    }
}

</style>
