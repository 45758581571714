import Vue from 'vue';
import Model from '@tony.caron/node-model-proxy/Model';
import HtProgramKeyDateLocale from './HtProgramKeyDateLocale';
import HtProgramKeyDateCollection from './HtProgramKeyDateCollection';

export default class HtProgramKeyDate extends Model {
    modelConfig() {
        return {
            collection: HtProgramKeyDateCollection,
        };
    }

    modelRelations() {
        return {
            locales: () => this.hasMany(HtProgramKeyDateLocale),
        };
    }

    modelCustomAttributes() {
        return {
            locales_by_key: {},
        };
    }

    modelAccessors() {
        return {
            locales_by_key: () => Vue.prototype.$modelUtils.createLocalesByKey(this.locales),
        };
    }

    modelEvents() {
        return {
            loaded: () => {
                Vue.prototype.$modelUtils.createLocales(this.locales);
            },
        };
    }

    localize(field, languageKey, defaultLanguageKey) {
        return Vue.prototype.$modelUtils.localize(this.locales_by_key, field, languageKey, defaultLanguageKey);
    }
}
