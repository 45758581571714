<template>
    <div :class="['role-wrapper', isRoleExisting ? '' : 'unexisting-role']">
        <div
            v-user-image="getUserImage(role, size)"
            class="role-image"
            :class="getSlugifiedRole(role)"
            :title="role"
            :style="{ 'background-size': '1.75em' }"
        />
        <span>{{ role }}</span>
    </div>
</template>

<script>
import { slugify } from '@/helpers/string';

export default {
    name: 'HtRoleImage',
    props: {
        role: {
            type: String,
            required: true,
        },
        size: {
            type: Number,
            default: 1.25,
        },
    },
    computed: {
        isRoleExisting() {
            return this.$store.getters['roles/isExistingRole'](this.role);
        },
    },
    methods: {
        getUserImage(role, fontSize) {
            return this.$Utils.getCompanyComponentRoleInitials(role, fontSize);
        },
        getSlugifiedRole(role) {
            return slugify(role);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.role-wrapper {
  display: flex;
  border: 1px solid $neutral-500;
  border-radius: $border-radius-5;
  padding: 0.75rem 1rem;
}

.role-image {
  padding: 0.25rem 1rem;
    border-radius: 1rem;
    margin-right: 1rem;
    background-size: cover;
    height: 100%;

  &.onboardee {
    background: #000;
  }

  &.manager {
    background: #5addb3;
  }

  &.buddy {
    background: #feb856;
  }

  &.administrative-responsible {
    background: #2b81f0;
  }

  &.software-responsible {
    background: #6e58ff;
  }

  &.equipment-responsible {
    background: #b9e986;
  }

  &.employee {
    background: #FF6058;
  }
}

.unexisting-role {
  border-color: $error-1;
  background-color: rgba($error-1, 0.2);

  span {
    text-decoration: line-through;
  }
}
</style>
