<template>
    <HtTag
        v-if="tag"
        :color="color"
    >
        {{ statusText }}
    </HtTag>
    <div v-else>
        <label>
            <t>Status</t>
        </label>
        <div class="status-icon">
            <div class="icon">
                <FontAwesomeIcon
                    v-if="status === 'done'"
                    :class="'color-green'"
                    :icon="['fa', 'check-circle']"
                />
                <FontAwesomeIcon
                    v-else-if="status === 'cancelled'"
                    :class="'color-primary'"
                    :icon="['fa', 'exclamation-triangle']"
                />
                <FontAwesomeIcon
                    v-else
                    :class="'color-grey-lighter'"
                    :icon="['far', 'check-circle']"
                />
            </div>
            {{ statusText }}
        </div>
    </div>
</template>
<script>
import HtTag from '@/components/globals/HtTag.vue';

export default {
    name: 'HtStatusLabel',
    components: { HtTag },
    props: {
        status: {
            type: String,
            required: true,
        },
        validatorType: {
            type: String,
            default: 'human',
        },
        datetime: {
            required: false,
            type: [Object, String],
        },
        tag: {
            default: () => false,
        },
    },
    computed: {
        getTime() {
            const { moment } = this.$Utils;
            return moment(this.datetime);
        },
        statusText() {
            const { moment } = this.$Utils;
            if (this.tag) {
                switch (this.status) {
                case 'done':
                    return this.translate('Done');
                case 'draft':
                    return this.translate('Draft');
                case 'cancelled':
                    return this.translate('Cancelled');
                case 'pending':
                default:
                    return this.translate('Pending');
                }
            } else {
                switch (this.status) {
                case 'done':
                    return this.translate('Done');
                case 'cancelled':
                    return this.translate('Cancelled');
                case 'draft':
                    return this.translate('Draft');
                case 'pending':
                    if (this.validatorType == 'system') {
                        if (this.getTime <= moment()) {
                            return this.translate('Will be automaticaly executed in a few minutes');
                        }

                        return this.translate('Will be automaticaly executed on {date}', { date: this.getTime.format('LL') });
                    }
                default:
                    return this.translate('To do');
                }
            }
        },
        color() {
            const colors = {
                draft: 'orange',
                pending: 'purple',
                done: 'green',
                cancelled: 'red',
            };
            return colors[this.status];
        },
    },
};
</script>
<style lang="scss" scoped>
.status-icon {
        display: flex;

        .icon {
            margin-right: 1em;
        }
    }
</style>
