<template>
    <div
        class="indicator"
        @click="$emit('click')"
    >
        <div class="indicator__header">
            <div class="indicator__header-icon">
                <font-awesome-icon :icon="icon" />
            </div>
            <div class="indicator__header-label">
                {{ label }}
            </div>
        </div>
        <div class="indicator__description">
            <Skeleton
                :width="'30%'"
                :loading="loading"
            >
                {{ description }}
            </Skeleton>
        </div>
    </div>
</template>

<script>
import { Skeleton } from 'vue-loading-skeleton';

export default {
    name: 'IndicatorTemplate',
    components: {
        Skeleton,
    },

    props: {
        icon: {
            type: [String, Array],
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
    },
};
</script>

<style lang="scss" scoped>
.indicator {
    padding: 16px;
    border-radius: 4px;
    background-color: #FFFFFF;
    cursor: pointer;

    &:hover {
        box-shadow: 0 6px 8px 0 rgba(153, 153, 153, 0.12);
    }
}

.indicator__description {
    font-size: 16px;
    font-weight: 700;
}

.indicator__header {
    display: flex;
    align-items: center;

    margin-bottom: 20px;
}

.indicator__header-label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
}

.indicator__header-icon {
    width: 24px;
    height: 24px;
    padding-top: 5px;
    margin-right: 8px;
    border-radius: 4px;
    font-size: 13px;
    color: #FFFFFF;
    text-align: center;
    background-color: var(--branding-color);
}
</style>
