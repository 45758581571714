<template>
    <ChatListChannelItemHtml
        :count="count"
        :active="active"
        :image="userImage.image"
        :image-lastname="userImage.lastname"
        :image-firstname="userImage.firstname"
        :name="name"
        :hint="hint"
        :subname="subname"
        :is-connected="isConnected"
        :show-icon-as-avatar="showIconAsAvatar"
        :show-group-image="showGroupImage"
        :icon-avatar="iconAvatar"
    />
</template>

<script>
import HtProgramType from '@/models/HtProgramType';
import CompanyChatChannel from '@/models/CompanyChatChannel';
import ChatListChannelItemHtml from './ChatListChannelItemHtml.vue';

export default {
    name: 'ChatListChannelItem',
    components: {
        ChatListChannelItemHtml,
    },
    props: {
        companyChatChannel: {
            type: CompanyChatChannel,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        channelUserInterlocutor() {
            return this.companyChatChannel.company_chat_channel_user.channelUserInterlocutor;
        },
        count() {
            return this.companyChatChannel.company_chat_channel_user.channelUserMe.count;
        },
        name() {
            if (this.companyChatChannel.type === 'channel') {
                if (this.companyChatChannel.resource === 'company_user_survey') {
                    return this.companyChatChannel.company_user_survey ? this.companyChatChannel.company_user_survey.company_survey.resource.name : '';
                }
                return this.companyChatChannel.name;
            }
            if (this.companyChatChannel.type === 'multi') {
                return this.getMultiChannelName();
            }
            if (this.companyChatChannel.type === 'group') {
                return this.companyChatChannel.name;
            }
            return this.channelUserInterlocutor.company_user.fullname;
        },
        subname() {
            if (this.companyChatChannel.type === 'channel') {
                if (this.companyChatChannel.resource === 'company_user_survey') {
                    if (
                        this.companyChatChannel.company_user_survey.company_survey.company_program
                        && this.companyChatChannel.company_user_survey.company_survey.company_program.ht_program_type.slug !== HtProgramType.SLUG_DEFAULT
                        && this.companyChatChannel.company_user_survey.company_user_id !== this.companyChatChannel.company_user_survey.company_user_participant_id
                    ) {
                        return this.translate('{user_fullname}', {
                            user_fullname: this.companyChatChannel.company_user_survey.company_user.fullname,
                        });
                    }
                    return null;
                }
                return this.companyChatChannel.subname;
            }
            if (this.companyChatChannel.type === 'multi' || this.companyChatChannel.type === 'group') {
                return null;
            }
            if (this.channelUserInterlocutor.company_user?.company_job_position) {
                return this.channelUserInterlocutor.company_user.company_job_position.name;
            }
            return null;
        },
        hint() {
            if (this.companyChatChannel.type === 'multi') {
                return this.getMultiChannelHint();
            }

            return null;
        },
        showIconAsAvatar() {
            if (this.companyChatChannel.type === 'multi' || (this.companyChatChannel.type === 'group' && !this.companyChatChannel.avatar_image?.id)) {
                return true;
            }
            return this.companyChatChannel.resource === 'company_user_survey';
        },
        showGroupImage() {
            return this.companyChatChannel.type === 'group' && Boolean(this.companyChatChannel.avatar_image?.id);
        },
        iconAvatar() {
            if (this.companyChatChannel.type === 'multi' || this.companyChatChannel.type === 'group') {
                return 'users';
            }
            return ['fal', 'poll'];
        },
        isConnected() {
            let connected = false;
            for (const user of this.companyChatChannel.company_chat_channel_user.models) {
                if (user?.company_user?.isConnected) {
                    connected = true;
                    break;
                }
            }
            return connected;
        },
        userImage() {
            if (this.companyChatChannel.type !== 'group') {
                return {
                    image: this.channelUserInterlocutor.company_user.image,
                    firstname: this.channelUserInterlocutor.company_user.firstname,
                    lastname: this.channelUserInterlocutor.company_user.lastname,
                };
            }
            return {
                image: this.companyChatChannel?.avatar_image?.link || '',
                firstname: this.companyChatChannel.name,
                lastname: this.companyChatChannel.name.substr(1),
            };
        },
    },
    methods: {
        getMultiChannelName() {
            let name = '';
            let count = 0;
            let overCount = 0;

            this.companyChatChannel.company_chat_channel_user.models.forEach((user) => {
                if (user.company_user_id !== this.shared.session.companyUser.id) {
                    if (count < 3) {
                        name += `${user.company_user.firstname}, `;
                        count++;
                    } else {
                        if (overCount === 0) {
                            name = `${name.substr(0, name.length - 2)} ... `;
                        }
                        overCount++;
                    }
                }
            });

            if (overCount === 0) {
                return name.substr(0, name.length - 2);
            }

            return `${name}+ ${overCount}`;
        },
        getMultiChannelHint() {
            let hint = '';
            this.companyChatChannel.company_chat_channel_user.models.forEach((user) => {
                if (user.company_user_id !== this.shared.session.companyUser.id) {
                    hint += `${user.company_user.firstname}, `;
                }
            });
            return hint.substr(0, hint.length - 2);
        },
    },
};
</script>

<style lang="scss" scoped src="./ChatListChannelItem.scss" />
