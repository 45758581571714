<template>
    <div class="action-list">
        <div
            v-for="action in actions"
            :key="action.event"
            class="action-list-line"
            @mousedown="$emit('emit', action.event)"
        >
            <div class="action-list-line-left">
                <FontAwesomeIcon :icon="action.icon" />
            </div>
            <div class="action-list-line-center">
                <p><strong><t>{{ action.text }}</t></strong></p>
                <p class="sub">
                    {{ action.subtext }}
                </p>
            </div>
            <div class="action-list-line-right">
                <FontAwesomeIcon :icon="['fa', 'chevron-right']" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ChatGroupManagerActionList',
    props: {
        actions: {
            type: Array,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.action-list {
    margin-top: 20px;

    &-line {
        cursor: pointer;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 10px;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        background-color: white;
        border: solid lightgray .5px;
        border-top: none;

        &:first-child {
            border-top: solid lightgray .5px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            margin-bottom: 20px;
        }

        &-left {
            width: 30px;
            height: 30px;
            background-color: #F5F8F9;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }

        &-center {
            .sub {
                font-size: 12px;
                color: lightgray;
            }
        }
    }
}
</style>
