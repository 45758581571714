<template>
    <ModalablePersist
        ref="mainChatModal"
        size="large"
        @onClose="onClose()"
    >
        <template #main>
            <div
                v-show="shared.socket.channelCollection !== null"
                class="chat-modal"
                :class="{'desktop-three-panel-view': threePanelView}"
            >
                <!-- LEFT PANEL -->
                <div
                    class="chat-modal-sidebar"
                    :class="{'phone-hidden':sideModalHidden}"
                >
                    <!-- LEFT PANEL IDLE -->
                    <transition name="modal-slide">
                        <div
                            v-show="!showConversationView && !showGroupCreate"
                            class="side-panel"
                        >
                            <div class="chat-modal-sidebar-header">
                                <t>Messaging</t>
                                <div class="chat-modal-sidebar-header-actions">
                                    <div
                                        class="indicator__header-icon icon-create-conversation"
                                        @click="showConversationView = true"
                                    >
                                        <FontAwesomeIcon icon="edit" />
                                    </div>
                                    <div
                                        class="indicator__header-icon icon-close"
                                        @click="onClose"
                                    >
                                        <FontAwesomeIcon :icon="['fal', 'times']" />
                                    </div>
                                </div>
                            </div>

                            <div class="chat-modal-sidebar-top">
                                <ChatSearch
                                    ref="chatSearch"
                                    :search-in-groups="true"
                                    @onSwitchPanel="switchPanel"
                                />
                            </div>

                            <div
                                v-show="channelActiveId && hasSearch"
                                style="flex:1;"
                                class="chat-modal-sidebar-bottom"
                            >
                                <ChatListChannel @onSwitchPanel="switchPanel" />
                            </div>
                        </div>
                    </transition>

                    <!-- LEFT PANEL CONVERSATION MANAGER -->
                    <transition name="modal-slide">
                        <div
                            v-show="showConversationView"
                            class="side-panel"
                        >
                            <div
                                v-show="channelActiveId"
                                class="chat-modal-sidebar-top"
                            >
                                <div
                                    class="indicator__header-icon icon-prev"
                                    @click="sideModalConversationPrevAction"
                                >
                                    <FontAwesomeIcon icon="chevron-left" />
                                </div>
                                <t>New conversation</t>
                            </div>
                            <div class="chat-modal-sidebar-bottom">
                                <ChatConversationManager
                                    ref="chatGroupCreate"
                                    @close="showConversationView = false; sideModalHidden = true; mainModalHidden = false;"
                                    @cancel="sideModalConversationPrevAction"
                                >
                                    <CreateGroupConversationItem @mousedown.native="showConversationView = false; showGroupCreate = true;" />
                                </ChatConversationManager>
                            </div>
                        </div>
                    </transition>

                    <!-- LEFT PANEL GROUP CREATION -->
                    <transition name="modal-slide">
                        <div
                            v-show="showGroupCreate"
                            class="side-panel"
                        >
                            <div
                                v-show="channelActiveId"
                                class="chat-modal-sidebar-top"
                            >
                                <div
                                    class="indicator__header-icon icon-prev"
                                    @click="leftModalGroupPrevAction"
                                >
                                    <FontAwesomeIcon icon="chevron-left" />
                                </div>
                                <p>{{ sideModalGroupTitle }}</p>
                            </div>
                            <div class="chat-modal-sidebar-bottom">
                                <ChatGroupManager
                                    ref="leftChatGroupManager"
                                    @change-group-view="changeSideModalGroupTitle('leftChatGroupManager')"
                                    @close="showGroupCreate = false; sideModalHidden = true; mainModalHidden = false;"
                                />
                            </div>
                        </div>
                    </transition>
                </div>

                <!-- MAIN PANEL -->
                <div
                    class="chat-modal-main"
                    :class="{'phone-hidden':mainModalHidden}"
                >
                    <ChatListMessageHeader
                        v-if="channelActiveId"
                        :channel="channelActive"
                        :config-open="!rightModalHidden"
                        :is-ai-bot-channel="isAiBotChannel"
                        @onShowConfig="onShowConfig()"
                        @onClose="onClose()"
                        @onSwitchPanel="switchPanel"
                    />
                    <ChatListMessage
                        v-if="channelActiveId"
                        ref="chatListMessage"
                        :is-ai-bot-channel="isAiBotChannel"
                        @on-suggestion-click="onSuggestionClick"
                    />
                    <ChatFormMessage
                        v-if="displayFormMessage"
                        ref="chatFormMessage"
                        :is-ai-bot-channel="isAiBotChannel"
                        @onMessageSending="scrollToBottom"
                        @onMessageSent="(message) => sendMessage(message)"
                    />
                </div>

                <!-- RIGHT PANEL -->
                <div
                    v-if="isAiBotChannel === false"
                    class="chat-modal-right"
                    :class="{'phone-hidden':rightModalHidden}"
                >
                    <div class="side-panel">
                        <div
                            v-if="channelActive"
                            class="chat-modal-sidebar-top"
                        >
                            <div
                                class="indicator__header-icon icon-prev"
                                :class="{'desktop-hidden': !showDesktopRightPrevButton}"
                                @click="rightModalGroupPrevAction"
                            >
                                <FontAwesomeIcon icon="chevron-left" />
                            </div>
                            <p>{{ rightModalGroupTitle }}</p>
                        </div>
                        <div class="chat-modal-sidebar-bottom">
                            <ChatConversationInfo
                                v-if="channelActive && channelActive.type !== 'group'"
                                ref="rightModal"
                                :channel="channelActive"
                                @change-view="onRightModalViewChange"
                                @fileLoaded="onRightModalViewChange"
                                @onSwitchPanel="switchPanel"
                                @close="onRightModalCancel"
                            />
                            <ChatGroupManager
                                v-if="channelActive && channelActive.type === 'group'"
                                ref="rightModal"
                                :channel="channelActive"
                                @change-view="onRightModalViewChange"
                                @onSwitchPanel="switchPanel"
                                @fileLoaded="onRightModalViewChange"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="shared.socket.channelCollection === null">
                <IconLoading />
            </div>
        </template>
    </ModalablePersist>
</template>

<script>
import CompanyChatChannelCollection from '@/models/CompanyChatChannelCollection';
import I18n from '@/modules/i18n/I18n';
import IconLoading from '@/components/icons/IconLoading.vue';
import AIChatModalMixin from '@/components/mixins/AIChatModalMixin';
import ChatSearch from './ChatSearch.vue';
import ChatConversationManager from './ChatConversationManager.vue';
import ChatGroupManager from './ChatGroupManager.vue';
import ChatListChannel from './ChatListChannel.vue';
import ChatListMessage from './ChatListMessage.vue';
import ChatListMessageHeader from './ChatListMessageHeader.vue';
import ChatFormMessage from './ChatFormMessage.vue';
import CreateGroupConversationItem from './CreateGroupConversationItem.vue';
import ChatConversationInfo from './ChatConversationInfo.vue';

export default {
    name: 'ChatModal',
    components: {
        IconLoading,
        ChatListChannel,
        ChatListMessage,
        ChatFormMessage,
        ChatSearch,
        ChatConversationManager,
        ChatListMessageHeader,
        CreateGroupConversationItem,
        ChatGroupManager,
        ChatConversationInfo,
    },
    mixins: [AIChatModalMixin],
    data() {
        return {
            showConversationView: false,
            showGroupCreate: false,
            sideModalHidden: false,
            rightModalHidden: true,
            mainModalHidden: true,
            showDesktopRightPrevButton: false,
            sideModalGroupTitle: I18n.translate('New group'),
            rightModalGroupTitle: I18n.translate('Group informations'),
            company: this.shared.session.company,
        };
    },
    shared: {
        socket: {
            chatOpened: {
                type: Boolean,
                default: false,
            },
            channelCollection: {
                type: CompanyChatChannelCollection,
                default: null,
            },
            channelMessageCollection: {
                type: Object,
                default: {},
            },
        },
    },
    computed: {
        channelActive() {
            if (!this.shared.socket.channelCollection) return null;

            return this.shared.socket.channelCollection.channelActive;
        },
        channelUserInterlocutor() {
            const { channelActive } = this;

            if (!channelActive) return null;

            return channelActive.company_chat_channel_user.channelUserInterlocutor;
        },
        isAiBotChannel() {
            return this.company.ai_bot_company_user_id === this.channelUserInterlocutor?.company_user_id;
        },
        channelActiveId() {
            if (!this.channelActive) return null;

            return this.channelActive.id;
        },
        displayFormMessage() {
            if (!this.channelActiveId) {
                return false;
            }

            if (this.channelActive.resource !== null && this.channelActive.resource !== undefined) {
                return false;
            }

            return !!this.channelActive.created_by_company_user_id;
        },
        threePanelView() {
            return !this.rightModalHidden;
        },
        hasSearch() {
            return this.$refs.chatSearch?.search?.length === 0;
        },
    },
    watch: {
        'shared.socket.chatOpened': function (value) {
            if (value) {
                this.$refs.mainChatModal.open();
            }
        },
        channelActive() {
            this.onRightModalCancel();
        },
    },
    methods: {
        onClose() {
            this.$refs.mainChatModal.isVisible = false;
            this.shared.socket.chatOpened = false;

            // remove new unsaved channel if there is one
            const indexNewChannel = this.shared.socket.channelCollection.models
                .findIndex((oneChannel) => oneChannel.id === 'new');
            if (indexNewChannel !== -1) {
                this.shared.socket.channelCollection.models.splice(indexNewChannel, 1);
                if (!this.channelActive) {
                    this.shared.socket.channelCollection.setActive(
                        this.shared.socket.channelCollection.models[0].id,
                    );
                }
            }
        },
        onShowConfig() {
            this.mainModalHidden = !this.mainModalHidden;
            this.rightModalHidden = !this.rightModalHidden;
            this.$refs.rightModal.toggleView('main');
            this.onRightModalViewChange();
        },
        async sendMessage(message) {
            this.scrollToBottom();

            if (this.isAiBotChannel) {
                await this.sendToCapi(message);
            }
        },
        scrollToBottom() {
            this.$nextTick(() => {
                this.$refs.chatListMessage.scrollToBottom();
            });
        },
        switchPanel() {
            this.sideModalHidden = !this.sideModalHidden;
            this.mainModalHidden = !this.mainModalHidden;
            this.rightModalHidden = true;
        },
        sideModalConversationPrevAction() {
            this.showConversationView = false;
            this.showGroupCreate = false;
            this.$refs.chatGroupCreate.reset();
        },
        leftModalGroupPrevAction() {
            if (this.$refs.leftChatGroupManager.show.main) {
                this.showConversationView = true;
                this.showGroupCreate = false;
                this.$refs.leftChatGroupManager.reset();
            } else {
                this.$refs.leftChatGroupManager.previous();
            }
        },
        changeSideModalGroupTitle() {
            if (this.$refs.leftChatGroupManager.show.main) {
                this.sideModalGroupTitle = I18n.translate('New group');
            } else {
                this.sideModalGroupTitle = I18n.translate('Add participants');
            }
        },
        rightModalGroupPrevAction() {
            if (this.$refs.rightModal.show.main) {
                this.mainModalHidden = !this.mainModalHidden;
                this.rightModalHidden = !this.rightModalHidden;
            } else {
                this.$refs.rightModal.toggleView('main');
            }
        },
        onRightModalViewChange(count) {
            if (!this.$refs.rightModal || this.$refs.rightModal.show.main) {
                this.showDesktopRightPrevButton = false;
                this.rightModalGroupTitle = I18n.translate(this.channelActive.type === 'group' ? 'Group informations' : 'Conversation informations');
            } else {
                this.showDesktopRightPrevButton = true;
                if (this.$refs.rightModal.show.byName
                    || this.$refs.rightModal.show.edit) {
                    this.rightModalGroupTitle = I18n.translate('Modify participants');
                }
                if (this.$refs.rightModal.show.infos) {
                    this.rightModalGroupTitle = I18n.translate('Modify informations');
                }
                if (this.$refs.rightModal.show.images) {
                    if (count === undefined) this.rightModalGroupTitle = I18n.translate('Loading images ...');
                    else this.rightModalGroupTitle = I18n.translate('No image shared | {count} image | {count} images', { count });
                }
                if (this.$refs.rightModal.show.files) {
                    if (count === undefined) this.rightModalGroupTitle = I18n.translate('Loading files ...');
                    else this.rightModalGroupTitle = I18n.translate('No file shared | {count} file | {count} files', { count });
                }
            }
        },
        onRightModalCancel() {
            this.mainModalHidden = !this.sideModalHidden;
            this.rightModalHidden = true;
        },
    },
};
</script>

<style lang="scss" scoped>

@import "./ChatModal.scss";

.side-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    height: 100%;
}

@media (min-width: $phone){
    .desktop-hidden{
        display: none !important;
    }
}
.chat-search {
    width: 100%;
}

.chat-modal-sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    font-weight: bold;

    &-actions {
       .indicator__header-icon {
            margin-left: 5px;
       }
    }
}

.chat-modal-sidebar-top {
    font-weight: bold;
    display: flex;
    align-items: center;
    p {
        display: inline-block;
    }
}

.icon-create-conversation {
    border: none!important;

    .fa-edit {
        position: relative;
    }
}

@media (max-width: $phone){
    .icon-create-conversation {
        margin-right: 1rem;
    }
    .icon-close {
        margin-right: 1rem;
    }
}
</style>
