<template>
    <div>
        <form
            class="form-2 spaced"
            @submit.prevent=""
        >
            <fieldset>
                <HtFormSelect
                    id="before-arrival"
                    v-model="item.content['before_arrival']['selected']"
                    v-validate.disable="'required'"
                    :label="translate('Show members before arrival')"
                    name="before-arrival"
                    :data-vv-as="translate('before arrival')"
                    :options="showMembersBeforeArrivalOptions"
                />
            </fieldset>

            <fieldset>
                <HtFormSelect
                    id="after-arrival"
                    v-model="item.content['after_arrival']['selected']"
                    v-validate.disable="'required'"
                    :label="translate('Show members after arrival')"
                    name="after-arrival"
                    :data-vv-as="translate('after arrival')"
                    :options="showMembersAfterArrivalOptions"
                />
            </fieldset>
        </form>
        <div class="modal-actions right">
            <Button
                :state="buttonState"
                @click="onSave()"
            >
                <t>Save</t>
            </Button>
        </div>
    </div>
</template>

<script>
import CompanyCustomize from '@/models/CompanyCustomize';

export default {
    name: 'TeamMembersEdit',
    inject: ['modal'],

    props: {
        item: { type: CompanyCustomize, required: true },
    },

    computed: {
        buttonState() {
            return this.item._state.isSaving ? 'loading' : 'idle';
        },

        showMembersBeforeArrivalOptions() {
            return this.item.content.before_arrival.options.map((option) => ({
                id: option.key,
                name: this.translate(option.label),
            })).sort((a, b) => a.name.localeCompare(b.name));
        },

        showMembersAfterArrivalOptions() {
            return this.item.content.after_arrival.options.map((option) => ({
                id: option.key,
                name: this.translate(option.label),
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
    },

    created() {
        this.item.fromSaveState();
    },

    methods: {
        async onSave() {
            await this.item.save();
            this.modal.close();
        },
    },
};
</script>
