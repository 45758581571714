import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyPlanningEventTemplate from './CompanyPlanningEventTemplate';

export default class CompanyPlanningEventTemplateCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyPlanningEventTemplate,
        };
    }

    modelCustomAttributes() {
        return {
            filters: {},
            sortedByNameTranslated: [],
        };
    }

    modelAccessors() {
        return {
            sortedByNameTranslated: () => {
                if (this.$.models) {
                    return [...this.$.models].sort((a, b) => a.resource.name.localeCompare(b.resource.name));
                }
                return [];
            },
        };
    }
}
