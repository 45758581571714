<template>
    <HtEntitiesFilters
        v-model="entities"
        is-single
        :entities-slug="[
            'company_office',
            'company_contract',
            'company_department',
        ].concat([programEntitiesIds.length <= 4 ? 'company_job_position' : undefined])"
        :program-entities-ids="programEntitiesIds"
        @input="$emit('input', entities)"
    >
        <template
            v-for="(_, slot) of $scopedSlots"
            #[slot]="scope"
        >
            <slot
                :name="slot"
                v-bind="scope"
            />
        </template>
    </HtEntitiesFilters>
</template>

<script>
import HtEntitiesFilters from '@/components/globals/filters/HtEntitiesFilters.vue';

export default {
    name: 'ProgramFilters',
    components: {
        HtEntitiesFilters,
    },
    props: {
        value: {
            type: Array,
            default: () => ([]),
        },
        programEntitiesIds: {
            type: Array,
            default: () => ([]),
        },
    },

    data() {
        return {
            entities: this.value ?? [],
        };
    },

    async created() {
        await this.$store.dispatch('entities/fetchEntities');
    },
};
</script>
