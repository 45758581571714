<template>
    <PageStepperLayout>
        <SurveyForm
            :id="surveyId"
            :disabled-program-list="Boolean($route.params.id)"
            @on-close-stepper="onCloseStepper"
            @on-load="setPageTitle"
        />
    </PageStepperLayout>
</template>

<script>
import PageStepperLayout from '@/layouts/PageStepperLayout.vue';
import SurveyForm from '@/components/pages/resources/surveys/form/SurveyForm.vue';

export default {
    components: {
        PageStepperLayout,
        SurveyForm,
    },

    computed: {
        surveyId() {
            return Number(this.$route.params.id) || null;
        },
    },

    methods: {
        onCloseStepper() {
            this.$router.go(-1);
        },

        setPageTitle(title) {
            this.setPage({
                title,
                subtitles: [this.translate('Resources'), this.translate('Surveys')],
            });
        },
    },
};
</script>
