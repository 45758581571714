<template>
    <fieldset>
        <HtFormMultiSelect
            id="timezone"
            v-validate.disable="'required'"
            :allow-empty="false"
            :is-single="true"
            :value="selectedTimezone"
            :options="formattedTimezones"
            name="timezone"
            :label="translate('Timezone selection')"
            :data-vv-as="translate('Timezone')"
            :cypress="cypress"
            @input="selectedTimezoneChanged"
        />
    </fieldset>
</template>

<script>
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import moment from 'moment';
import 'moment-timezone';
import CypressMixin from '@/mixins/CypressMixin';
import timezoneMixin from '@/components/globals/timezone/timezoneMixin';

export default {
    name: 'HtTimezoneSelect',
    components: {
        HtFormMultiSelect,
    },
    mixins: [
        CypressMixin,
        timezoneMixin,
    ],
    props: {
        value: { type: String, required: true },
    },
    computed: {
        selectedTimezone() {
            return this.formattedTimezones.find(
                (timezone) => timezone.id === this.value,
            );
        },
        formattedTimezones() {
            return moment.tz.names().map((timezone) => ({
                id: timezone,
                name: this.formatTimezoneName(timezone),
            }));
        },
    },
    methods: {
        selectedTimezoneChanged(newTimezone) {
            if (newTimezone) {
                this.$emit('input', newTimezone.id);
            } else {
                this.$emit('input', null);
            }
        },
    },
};
</script>
