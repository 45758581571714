<template>
    <HtFormSelector
        :id="id"
        :value="currentValue"
        :label="label"
        :description="description"
        :placeholder="placeholder"
        :name="name"
        :show-asterisk="showAsterisk"
        :options="processedOptions"
        :is-single="isSingle"
        :allow-empty="allowEmpty"
        label-options="label"
        :track-by="trackBy"
        :max-height="maxHeight"
        :open-direction="openDirection"
        :disabled="disabled"
        :cypress="cypress"
        @input="onUpdateValue"
    >
        <template #image="{ option }">
            <div
                v-user-image="$Utils.getCompanyComponentRoleInitials(option.alias, null, null, option.name)"
                class="image role-font-size role-only"
                :class="option.name"
                :title="option.alias"
                :data-cy="'role-'+option.name"
            />
        </template>
    </HtFormSelector>
</template>

<script>
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import HtFormMixin from '@/components/globals/HtFormMixin';
import CompanyRole from '@/models/CompanyRole';
import HtFormSelectorMixin from '@/components/globals/Selectors/HtFormSelectorMixin';

export default {
    components: {
        HtFormSelector,
    },
    mixins: [
        HtFormMixin,
        HtFormSelectorMixin,
    ],

    data() {
        return {
            currentValue: this.isSingle ? null : [],
        };
    },

    computed: {
        processedOptions() {
            return this.options.map((role) => {
                let label;
                if (role.name === CompanyRole.NAME_EMPLOYEE) {
                    label = `${this.translate('Program Recipient')} (${this.translate(role.alias)})`;
                } else {
                    label = (role.is_heyteam) ? this.translate(role.alias) : role.alias;
                }
                return {
                    ...role,
                    label,
                    is_employee: role.name === CompanyRole.NAME_EMPLOYEE,
                };
            });
        },
    },

    watch: {
        value(val) {
            this.setCurrentValue(val);
        },
    },

    created() {
        this.setCurrentValue(this.value);
    },

};
</script>
