import Model from '@tony.caron/node-model-proxy/Model';
import CompanyKnowledgeSettings from './CompanyKnowledgeSettings';
import CompanyResourceProgram from './CompanyResourceProgram';

export default class CompanyResource extends Model {
    modelCustomAttributes() {
        return {
            translations: {
                name: '',
                description: '',
            },
            is_private: false,
        };
    }

    modelRelations() {
        return {
            companyKnowledgeSettings: () => this.hasOne(CompanyKnowledgeSettings),
            programs: () => this.hasMany(CompanyResourceProgram, 'company_resource_id'),
            resourcePrograms: () => this.hasMany(CompanyResourceProgram, 'company_resource_id'),
        };
    }
}
