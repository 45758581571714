<template>
    <HtStepperLayout
        v-if="survey"
        :id="id"
        ref="htStepperLayout"
        :tabs="tabs"
        :default-filters="defaultFilters"
        :default-order-onboarding-start="defaultOrderOnboardingStart"
        :default-order-onboarding-end="defaultOrderOnboardingEnd"
        :show-button-delete="showButtonDelete"
        :add-default-order-completion="true"
        :company-program-id="companyProgramId"
        :is-duplicable-model="isDuplicableModel"
        :show-list-on-end="showListOnEnd"
        :hided-tabs="hidedTabs"
        :disabled-program-list="disabledProgramList"
        @onSave="$emit('onSave')"
        @onDelete="onDelete"
        @on-close-stepper="onCloseStepper"
        @on-load="onLoad"
    />
</template>

<script>
import HtStepperLayout from '@/layouts/HtStepperLayout.vue';
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import HidedTabsMixin from '@/components/mixins/HidedTabsMixin';
import showButtonActionMixin from '@/components/mixins/showButtonActionMixin';
import CompanySurveyContent from '@/models/CompanySurveyContent';
import api from '@/store/api';
import SurveyFormResults from './SurveyFormResults.vue';
import SurveyFormPlanning from './SurveyFormPlanning.vue';
import SurveyFormQuestions from './SurveyFormQuestions.vue';
import SurveyFormTargets from './SurveyFormTargets.vue';
import SurveyFormParameters from './SurveyFormParameters.vue';

export default {
    name: 'SurveyForm',
    components: {
        HtStepperLayout,
    },

    mixins: [
        DefaultFiltersMixin,
        DefaultOrderCompletionMixin,
        HidedTabsMixin,
        showButtonActionMixin,
    ],

    permissions: [
        'ModelCompanySurvey',
    ],

    shared: {
        session: {},
    },

    props: {
        id: {
            type: Number,
            default: null,
        },
        showListOnEnd: {
            type: Boolean,
            default: true,
        },
        companyProgramId: {
            type: Number,
            required: false,
        },
        isDuplicableModel: {
            type: Boolean,
            default: false,
        },
        disabledProgramList: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            tabs: [],
        };
    },

    computed: {
        survey: {
            set(survey) {
                this.$store.dispatch('stepperform/setItem', survey);
            },
            get() {
                return this.$store.state.stepperform.item;
            },
        },
    },

    watch: {
        permissionsLoaded: {
            immediate: true,
            handler() {
                this.tabs = [
                    {
                        ref: 'parameters',
                        title: this.translate('Parameters'),
                        component: SurveyFormParameters,
                        disabled: false,
                        noBody: false,
                    },
                    {
                        ref: 'targets',
                        title: this.translate('Targeting'),
                        component: SurveyFormTargets,
                        disabled: (!this.id || this.isDuplicableModel),
                        noBody: false,
                    },
                    {
                        ref: 'questions',
                        title: this.translate('Questions'),
                        component: SurveyFormQuestions,
                        disabled: (!this.id || this.isDuplicableModel),
                        noBody: false,
                    },
                    {
                        ref: 'planning',
                        title: this.translate('Scheduling'),
                        component: SurveyFormPlanning,
                        disabled: true,
                        noBody: false,
                    },
                    {
                        ref: 'results',
                        title: this.translate('Results'),
                        component: SurveyFormResults,
                        disabled: (this.id && !this.isDuplicableModel) ? !this.$canRead('ModelCompanySurvey', { id: this.$route.params.id }) : true,
                        noBody: true,
                    },
                ];
            },
        },
    },

    created() {
        this.$store.dispatch('stepperform/reset');
    },

    mounted() {
        this.fetchData();
    },

    methods: {
        onLoad() {
            if (this.id && !this.isDuplicableModel) {
                const hasQuestion = this.survey.contents.some((content) => content.resource === CompanySurveyContent.RESOURCE_QUESTION);
                if (hasQuestion) {
                    this.$refs.htStepperLayout.updateTab('planning', false);
                }
            }
        },

        onDelete() {
            this.init();

            this.$emit('onDelete');

            this.onCloseStepper();
        },

        fetchData() {
            if (this.id) {
                api.configuration.surveys
                    .get(this.id)
                    .then((res) => {
                        const survey = res.data.data;

                        if (this.isDuplicableModel) {
                            survey.id = undefined;
                            survey.status = 'draft';
                            survey.resource.programs[0].id = null;
                            survey.resource.programs[0].company_program_id = this.companyProgramId;
                            survey.resource.programs[0].company_program_key_date_id = null;
                            survey.resource.programs[0].filters = [];
                            survey.resource.programs[0].dependents = [];
                            survey.resource.programs[0].dependencies = [];
                            survey.resource.programs[0].participants = [];
                            survey.contents = survey.contents.map((content) => {
                                let { question, extra } = content;

                                if (content.resource === 'company_survey_question') {
                                    question = {
                                        ...question,
                                        id: undefined,
                                        answers: question.answers.map(
                                            (answer) => ({ ...answer, id: undefined }),
                                        ),
                                    };
                                } else {
                                    extra = { ...extra, id: undefined };
                                }

                                return {
                                    ...content,
                                    id: undefined,
                                    question,
                                    extra,
                                };
                            });
                        }

                        this.$store.dispatch('stepperform/setItem', res.data.data);

                        this.$nextTick(() => {
                            this.$emit('on-load', this.survey.resource.translations.name[this.shared.session.companyUser.company_language.key]);
                        });
                    });
            } else {
                this.init();
            }
        },

        init() {
            this.$store.dispatch('stepperform/setItem', {
                is_conversational: true,
                supervisors: [],

                resource: {
                    translations: {
                        name: {},
                    },

                    programs: [],
                },
            });

            this.$emit('on-load', this.translate('New survey'));
        },

        onCloseStepper() {
            this.$store.dispatch('stepperform/reset');

            this.$emit('on-close-stepper');
        },
    },
};
</script>
