<template>
    <Downloadable
        v-if="canDownload"
        :company-file="UserRequirement.company_file"
        :is-requirement="true"
    />
    <p v-else>
        -
    </p>
</template>

<script>

export default {
    name: 'UserRequirementFileRead',
    props: {
        UserRequirement: {
            type: Object,
            required: true,
        },
    },

    computed: {
        canDownload() {
            return this.UserRequirement.company_file
                && this.UserRequirement.company_file.id;
        },
    },
};
</script>
