<template>
    <CentralConfirmation
        :title="title"
        :description="description"
        :cancel-label="cancelLabel"
        :accept-label="acceptLabel"
        @accept="accept"
        @cancel="cancel"
    />
</template>
<script>
import CentralConfirmation from '@/components/globals/modals/layouts/CentralConfirmation.vue';

export default {
    name: 'GenericConfirmation',
    components: {
        CentralConfirmation,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        cancelLabel: {
            type: String,
            required: true,
        },
        acceptLabel: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            radio: null,
        };
    },
    methods: {
        cancel() {
            this.$emit('close', 'decline');
        },
        accept() {
            this.$emit('close', 'accept');
        },
    },
};
</script>
<style lang="scss" scoped>

</style>
