<template>
    <HtLayoutModal @close="$emit('close')">
        <template #header>
            {{ title }}
        </template>

        <template #main>
            <ProgramLaunchConditionToast />

            <HtToast
                v-if="hasUsersCountLimitExceeded"
                :title="translate('Limit of {maxCount} users reached', { maxCount: maxLoadUsers })"
                :message="translate('Please add more filters for a more accurate selection')"
                class="mb-5"
                type="info"
            />

            <HtToast
                v-if="userNameError"
                :title="translate('Validation error')"
                :message="translate('The user ({fullname}) has an invalid phone number format, please fix it before continuing', { fullname: userNameError })"
                class="mb-5"
                type="danger"
            />

            <component
                :is="additionComponent"
                :program-id="programId"
                :excluded-users-id="excludedUsersId"
                @update-users="users = $event"
                @enable-loading-users="loading = true"
                @disable-loading-users="loading = false"
                @change="canAddUsers = $event"
            />
        </template>

        <template #information>
            <div class="users-selected">
                <div class="users-selected-title">
                    <div class="users-selected-title-count">
                        <t
                            :count="users.length"
                            :maxCount="maxLoadUsers"
                        >
                            {count}/{maxCount} users selected
                        </t>
                    </div>

                    <div
                        class="users-selected-title-details"
                        @click="showDetailsSelectedUsers = !showDetailsSelectedUsers"
                    >
                        <t v-if="showDetailsSelectedUsers">
                            Hide details
                        </t>
                        <t v-else>
                            Show details
                        </t>
                    </div>
                </div>

                <div
                    v-if="showDetailsSelectedUsers"
                    class="users-selected-details"
                >
                    <div class="users-list">
                        <div
                            v-for="user in users"
                            :key="user.id"
                            class="user-card"
                        >
                            <div
                                class="user-close"
                                @click="removeUserFromSelected(user)"
                            >
                                <FontAwesomeIcon
                                    :icon="['fas', 'times']"
                                    transform="shrink-9"
                                />
                            </div>

                            <div
                                v-user-image="{
                                    image: user.image,
                                    firstname: user.firstname,
                                    lastname: user.lastname
                                }"
                                class="user-image"
                            />

                            <span class="user-name">{{ user.firstname }} {{ user.lastname }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <HtButton
                type="secondary"
                @click.native="$emit('previous')"
            >
                <t>Back</t>
            </HtButton>

            <HtButton
                :loading="loading"
                :disabled="loading || !canAddUsers || users.length === 0"
                @click.native="addUsers"
            >
                <t :count="users.length">
                    Add {count} users
                </t>
            </HtButton>
        </template>
    </HtLayoutModal>
</template>

<script>
import api from '@/store/api';
import I18n from '@/modules/i18n/I18n';
import HtFormRadio from '@/components/globals/HtFormRadio.vue';
import HtLayoutModal from '@/components/globals/modals/layouts/HtLayoutModal.vue';
import HtButton from '@/components/globals/HtButton.vue';
import HtToast from '@/components/globals/HtToast.vue';
import AdditionByFilters from '@/components/pages/foc/multiple/addition/AdditionByFilters.vue';
import AdditionByNames from '@/components/pages/foc/multiple/addition/AdditionByNames.vue';
import ProgramLaunchConditionToast from './ProgramLaunchConditionToast.vue';

const MAX_LOAD_USERS = 100;

export default {
    name: 'ProgramAdditionConfiguration',

    components: {
        HtToast,
        HtButton,
        HtLayoutModal,
        HtFormRadio,
        ProgramLaunchConditionToast,
    },

    props: {
        programId: {
            type: Number,
            required: false,
            default: () => null,
        },

        configuration: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            loading: false,
            canAddUsers: false,
            maxLoadUsers: MAX_LOAD_USERS,
            users: [],
            excludedUsersId: [],
            showDetailsSelectedUsers: false,
            userNameError: null,
        };
    },

    computed: {
        title() {
            switch (this.configuration.additionType) {
            case 'by_filter':
                return I18n.translate('Add users by filters');
            case 'by_name':
                return I18n.translate('Add users by names');
            default:
                return I18n.translate('Add users');
            }
        },

        additionComponent() {
            switch (this.configuration.additionType) {
            case 'by_filter':
                return AdditionByFilters;
            case 'by_name':
            default:
                return AdditionByNames;
            }
        },

        hasUsersCountLimitExceeded() {
            return this.users.length > MAX_LOAD_USERS;
        },
    },

    created() {
        if (this.configuration.additionType === 'new_user') {
            this.goToEnrollingPage(this.programId);
        }
    },

    methods: {
        goToEnrollingPage(programId, userId = null) {
            const query = {
                program_id: programId,
            };

            if (userId) {
                query.user_id = userId;
            }

            this.$router.push({
                name: 'Enrolling',
                query,
            });

            this.$emit('close');
        },

        addUsers() {
            this.loading = true;
            this.userNameError = null;

            if (this.users.length === 1) {
                this.goToEnrollingPage(this.programId, this.users[0].id);
            } else {
                api.user
                    .enrolling
                    .multipleEnrolling
                    .inviteUsers(this.programId, {
                        users_id: this.users.map((u) => u.id),
                        key_dates: this.configuration.keyDates,
                    })
                    .then((res) => {
                        const bulkId = res.data.bulk.id;

                        this.$router.push({
                            name: 'BulkEnrolling',
                            params: {
                                id: bulkId,
                            },
                        });

                        this.$emit('close');
                    })
                    .catch(({ response }) => {
                        this.loading = false;

                        if (response.status === 422 && !!response.data.errors.fullname) {
                            this.userNameError = response.data.errors.fullname[0];
                        }
                    });
            }
        },

        removeUserFromSelected(user) {
            this.excludedUsersId.push(user.id);
            this.users.splice(this.users.indexOf(user), 1);
        },
    },
};
</script>

<style lang="scss" scoped>
.users-selected {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    &-title {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &-count {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: #DADADA;
        }

        &-details {
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            color: #2C2C2C;
            cursor: pointer;
        }
    }

    &-details {
        .users-list {
            display: flex;
            gap: 8px;
            overflow: auto;

            .user-card {
                display: flex;
                flex-direction: column;
                gap: 5px;
                position: relative;
                background-color: #FBFBFB;
                border-radius: 8px;
                padding: 8px;

                .user-close {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    height: 16px;
                    width: 16px;
                    top: 0;
                    right: 0;
                    color: #DADADA;
                    cursor: pointer;
                    font-size: 20px;
                }

                .user-image {
                    border-radius: 50%;
                }

                .user-name {
                    color: #2C2C2C;
                    font-size: 10px;
                    font-weight: 400;
                    overflow: hidden;
                    max-width: 35px;
                    max-height: 23px;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
</style>
