<template>
    <HtCard>
        <template #title>
            <HtCardTitleBar
                :title="translate('All templates')"
                :show-close="true"
                :show-search="false"
                :show-back="true"
                @on-close="$router.push({ name: 'Programs' })"
                @on-back="$router.push({ name: 'ProgramTemplateSelection' })"
            />
        </template>
        <template
            v-if="allLoaded"
            #default
        >
            <HtFormCard class="page">
                <div class="template">
                    <div class="template-header">
                        <div class="template-title">
                            {{ name }}
                        </div>
                        <div class="template-description">
                            {{ description }}
                        </div>
                    </div>

                    <section v-if="tasksBeforeKeyDate.length > 0">
                        <div class="template-tasks-section-header">
                            <div class="tasks-section-title">
                                <t :key-date="keyDateLabel">
                                    Before {key-date}
                                </t>
                            </div>
                            <div class="tasks-count">
                                <t :count="tasksBeforeKeyDate.length">
                                    {count} task | {count} tasks
                                </t>
                            </div>
                        </div>
                        <ul class="template-tasks-list">
                            <li
                                v-for="task in tasksBeforeKeyDate"
                                :key="task.title"
                                class="task"
                            >
                                <div class="task-date">
                                    <t :order="getTaskOrderEnd(task)">
                                        D{order}
                                    </t>
                                </div>
                                <div clas="task-title">
                                    {{ task.title }}
                                </div>
                            </li>
                        </ul>
                    </section>
                    <section v-if="tasksAfterKeyDate.length > 0">
                        <div class="template-tasks-section-header">
                            <div class="tasks-section-title">
                                <t :key-date="keyDateLabel">
                                    After {key-date}
                                </t>
                            </div>
                            <div class="tasks-count">
                                <t :count="tasksAfterKeyDate.length">
                                    {count} task | {count} tasks
                                </t>
                            </div>
                        </div>
                        <ul class="template-tasks-list">
                            <li
                                v-for="task in tasksAfterKeyDate"
                                :key="task.title"
                                class="task"
                            >
                                <div class="task-date">
                                    <t v-if="getTaskOrderEnd(task) === 0">
                                        D-day
                                    </t>
                                    <t
                                        v-if="getTaskOrderEnd(task) > 0"
                                        :order="getTaskOrderEnd(task)"
                                    >
                                        D+{order}
                                    </t>
                                </div>
                                <div clas="task-title">
                                    {{ task.title }}
                                </div>
                            </li>
                        </ul>
                    </section>
                </div>
                <Button
                    :state="isSaving? 'loading' : 'idle'"
                    @click="generateProgram"
                >
                    <t>Pick this template</t>
                </Button>
            </HtFormCard>
        </template>
    </HtCard>
</template>

<script>
import HtCard from '@/components/globals/HtCard.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import Button from '@/components/globals/Button.vue';
import HtProgramTypeCollection from '@/models/HtProgramTypeCollection';
import HtProgramKeyDateCollection from '@/models/HtProgramKeyDateCollection';
import CompanyRoleCollection from '@/models/CompanyRoleCollection';
import CompanyGroupCollection from '@/models/CompanyGroupCollection';
import CompanyLanguageCollection from '@/models/CompanyLanguageCollection';
import CompanyProgramCollection from '@/models/CompanyProgramCollection';
import getProgramsModelsData from '@/router/pages/programs/TemplatesData';
import CompanyEntityCollection from '@/models/CompanyEntityCollection';
import get from 'lodash.get';
import api from '@/store/api';
import CompanyChatbot from '@/models/CompanyChatbot';

export default {
    name: 'TemplatePreview',
    components: {
        HtCard,
        HtCardTitleBar,
        HtFormCard,
        Button,
    },
    props: {
        slug: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            /**
             * Est-on en train de créer le programme ?
             */
            isSaving: false,
            allLoaded: false,
            template: null,
            programEntityList: [],
            chatbotList: [],
            templateSlugToChatbotSlug: {
                departure: CompanyChatbot.SLUG_SIGN_ONLY,
                onboarding: CompanyChatbot.SLUG_FULL,
                'talent-acquisition': CompanyChatbot.SLUG_LIGHT,
                crossboarding: CompanyChatbot.SLUG_LIGHT,
                'parent-leave-leaving': CompanyChatbot.SLUG_LIGHT,
                'parent-leave-return': CompanyChatbot.SLUG_LIGHT,
                'new-manager': CompanyChatbot.SLUG_FULL,
            },
        };
    },
    computed: {
        name() {
            return this.template
                ? this.getLocale(this.template.locales).name
                : '';
        },
        description() {
            return this.template
                ? this.getLocale(this.template.locales).description
                : '';
        },
        formattedActions() {
            if (!this.template) {
                return [];
            }

            return this.template.actions.map((action) => ({
                ...action,
                title: action.resource.translations.name[
                    this.shared.session.companyUser.company_language.key
                ],
            }));
        },
        formattedSurveys() {
            return [];
        },
        formattedEmails() {
            if (!this.template) {
                return [];
            }

            return this.template.emails.map((email) => ({
                ...email,
                title: email.resource.translations.name[
                    this.shared.session.companyUser.company_language.key
                ],
            }));
        },
        formattedEvents() {
            if (!this.template) {
                return [];
            }

            return this.template.events.map((event) => ({
                ...event,
                title: event.resource.translations.name[
                    this.shared.session.companyUser.company_language.key
                ],
            }));
        },
        tasks() {
            return [
                ...this.formattedActions,
                ...this.formattedSurveys,
                ...this.formattedEmails,
                ...this.formattedEvents,
            ].sort((taskA, taskB) => this.getTaskOrderEnd(taskA) - this.getTaskOrderEnd(taskB));
        },
        tasksBeforeKeyDate() {
            return this.tasks.filter((task) => this.getTaskOrderEnd(task) < 0);
        },
        tasksAfterKeyDate() {
            return this.tasks.filter((task) => this.getTaskOrderEnd(task) >= 0);
        },
        keyDateLabel() {
            if (!this.template) {
                return '';
            }

            return this.template.key_dates[0].ht_program_key_date.locales.models.find(
                (locale) => locale.language_key
                    === this.shared.session.companyUser.company_language.key,
            ).name;
        },
    },
    created() {
        Promise.all([
            new HtProgramTypeCollection(['id', 'slug']).get(),
            new HtProgramKeyDateCollection(['id'])
                .with({
                    locales: (query) => {
                        query.select(['id', 'name', 'language_key']);
                    },
                })
                .get(),
            new CompanyRoleCollection(['id', 'name']).get(),
            new CompanyGroupCollection(['id', 'name']).get(),
            new CompanyLanguageCollection(['id', 'key']).get(),
            new CompanyEntityCollection(['id', 'is_heyteam', 'slug']).get(),
            api.chatbot.all(),
        ]).then(([programTypes, keyDates, roles, groups, languages, entities, chatbot]) => {
            const templates = getProgramsModelsData(
                programTypes.models,
                keyDates.models,
                roles.models,
                groups.models,
                languages.models,
            );

            this.template = templates.find(
                (template) => template.slug === this.slug,
            );

            this.programEntityList = entities.models.filter((entity) => entity.is_heyteam).map((entity) => ({
                company_entity_id: entity.id,
                is_deletable: false,
            }));

            this.chatbotList = chatbot.data.data;
        }).finally(() => {
            this.allLoaded = true;
        });
    },
    methods: {
        /**
         * @returns {number}
         */
        getTaskOrderEnd(task) {
            const programs = get(task, 'resource.programs', null);

            if (programs) {
                return programs[0].offset_end_number;
            }

            if (Number.isInteger(task.order_onboarding_end)) {
                return task.order_onboarding_end;
            }

            return task.resource_program[0].offset_end_number;
        },

        getLocale(locales) {
            return locales.find(
                (locale) => locale.language_key === this.shared.session.companyUser.company_language.key,
            );
        },
        recomputeTranslations(emailTranslations) {
            const newTranslations = {};

            // Parcourir chaque objet de traduction dans emailTranslations
            for (const translation of emailTranslations) {
                const languageKey = translation.language_key;

                // Parcourir chaque propriété de l'objet de traduction
                for (const [attribute, value] of Object.entries(translation)) {
                    // Ignorer la propriété 'language_key'
                    if (attribute === 'language_key') {
                        continue;
                    }

                    // Ajouter la traduction pour la propriété dans newTranslations
                    if (!newTranslations[attribute]) {
                        newTranslations[attribute] = {};
                    }
                    newTranslations[attribute][languageKey] = value;
                }
            }

            return newTranslations;
        },
        generateProgram() {
            this.isSaving = true;
            const programs = new CompanyProgramCollection([
                'id',
                'ht_program_type_id',
                'company_chatbot_id',
            ]).with({
                locales: (query) => query.select([
                    'id',
                    'company_program_id',
                    'language_key',
                    'name',
                    'description',
                ]),
                entities: (query) => {
                    query.select([
                        'id',
                        'company_program_id',
                        'company_entity_id',
                        'is_deletable',
                    ]);
                },
                keyDates: (query) => query.select(['ht_program_key_date_id', 'is_main']),
                actions: (query) => query
                    .select([
                        'id',
                    ])
                    .with({
                        resource: (query) => {
                            query.select([
                                'id',
                                'name',
                            ]).with({
                                programs: (query) => {
                                    query.select([
                                        'offset_availability_number',
                                        'offset_availability_unit',
                                        'offset_end_number',
                                        'offset_end_unit',
                                        'is_business_day',
                                        'is_enabled',
                                    ]).with({
                                        filters: (query) => {
                                            query.select(['id']);
                                        },
                                        participants: (query) => {
                                            query.select(['id', 'company_role_id', 'company_user_id']);
                                        },
                                    });
                                },
                            });
                        },
                    }),
                surveys: (query) => query.select([
                    'id',
                    'order_onboarding_end',
                    'order_availability',
                ])
                    .with({
                        companySurveySupervisor: (query) => query.select(['company_role_id']),
                        companySurveyContent: (query) => query.select(['order', 'resource']).with({
                            companySurveyExtra: (query) => query.with({
                                companySurveyExtraLocale: (query) => query.select([
                                    'text',
                                    'language_key',
                                ]),
                            }),
                            companySurveyQuestion: (query) => query
                                .select([
                                    'type',
                                    'subtype',
                                    'is_mandatory',
                                ])
                                .with({
                                    companySurveyQuestionLocale: (query) => query.select([
                                        'text',
                                        'language_key',
                                    ]),
                                    companySurveyQuestionAnswer: (query) => query.select(['order']).with({
                                        companySurveyQuestionAnswerLocale: (query) => query.select([
                                            'text',
                                            'language_key',
                                        ]),
                                    }),
                                }),
                        }),
                    }),
                emails: (query) => query
                    .select([
                        'template',
                        'sender_role_id',
                        'is_enabled',
                        'is_locked',
                    ])
                    .with({
                        locales: (query) => query.select([
                            'subject',
                            'content',
                            'signature',
                            'language_key',
                        ]),
                        resource: (query) => {
                            query.select([
                                'id',
                                'name',
                            ]).with({
                                programs: (query) => {
                                    query.select([
                                        'offset_availability_number',
                                        'offset_availability_unit',
                                        'offset_end_number',
                                        'offset_end_unit',
                                        'is_business_day',
                                        'is_enabled',
                                    ]).with({
                                        filters: (query) => {
                                            query.select(['id']);
                                        },
                                        participants: (query) => {
                                            query.select(['id', 'company_role_id', 'company_group_id']);
                                        },
                                    });
                                },
                            });
                        },
                    }),
                events: (query) => query
                    .select([
                        'time_start',
                        'time_end',
                        'is_delegated',
                        'timezone',
                        'organiser_role_id',
                        'email_sending_offset',
                        'content',
                        'translations',
                        'file_translations',
                    ])
                    .with({
                        organiserRole: (query) => {
                            query.select(['id']);
                        },
                        resource: (query) => {
                            query
                                .select([
                                    'name',
                                    'description',
                                    'language_key',
                                ])
                                .with({
                                    programs: (query) => {
                                        query
                                            .select(['is_enabled'])
                                            .with({
                                                filters: (query) => {
                                                    query.select(['id']);
                                                },
                                                participants: (query) => {
                                                    query.select(['id', 'company_role_id']);
                                                },
                                            });
                                    },
                                });
                        },
                    }),
            });

            const program = programs.new().fill(this.template);
            program.entities = this.programEntityList;
            program.emails.models.forEach((email) => {
                email.translations = this.recomputeTranslations(email.translations);
            });

            program.company_chatbot_id = (
                this.chatbotList.find((c) => c.slug === this.templateSlugToChatbotSlug[this.slug])
            ).id;

            program.save().then((program) => {
                this.$router.push({
                    name: 'ProgramsForm',
                    params: { id: program.id },
                });
                this.shared.queries.companyProgramCollection.get().then((data) => {
                    this.shared.data.companyProgramCollection = data;
                });
            }).finally(() => {
                this.isSaving = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.page {
    max-width: 86.6rem;
    margin: auto;

    gap: 3.2rem;
    align-items: center;
}

.template {
    max-width: 72rem;
    margin: auto;
    padding: 4.8rem 6.4rem;
    background: white;

    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.template-header {
    background-color: $color-lightblue-grey;
    border-radius: 0.4rem;
    padding: 2.4rem 4.8rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
}

.template-title {
    font-family: $lato-bold;
    font-size: 2.4rem;
}

.template-description {
    text-align: center;
    font-size: 1.3rem;
    line-height: 1.6rem;
}

section {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.template-tasks-section-header {
    background-color: $color-lightblue-grey;
    border-radius: 0.4rem;
    padding: 2rem 2.4rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tasks-section-title {
    font-family: $lato-bold;
}

.tasks-count {
    background-color: darken($color-lightblue-grey, 5%);
    font-size: 1.4rem;

    border-radius: 0.2rem;
    padding: 0.8rem;
}

.template-tasks-list {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.task {
    border: 1px solid $grey-2;
    border-radius: 0.4rem;
    padding: 2.4rem;

    display: flex;
    gap: 1.2rem;
    align-items: center;

    font-size: 1.4rem;
}

.task-date {
    border-radius: 0.4rem;
    background: $grey-1;
    padding: 0.4rem 0.8rem;
}
</style>
