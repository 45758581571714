<template>
    <div class="user-quiz">
        <svg
            class="graph"
            viewbox="0 0 1 1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                class="graph-circle circle-1"
                cx="50%"
                cy="50%"
                r="40%"
            />
            <circle
                v-if="pourcentage > 0"
                class="graph-circle circle-2"
                cx="50%"
                cy="50%"
                r="40%"
                :stroke-dasharray="setCircle()"
            />
            <g>
                <text
                    class="graph-text"
                    x="50%"
                    y="50%"
                    alignment-baseline="central"
                    text-anchor="middle"
                >{{ Math.ceil(pourcentage * 100) }}%</text>
            </g>
        </svg>
        <div
            v-if="averageQuiz"
            class="user-quiz-average"
        >
            (<t>Average quiz : </t> {{ averageQuiz }} %)
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserQuizGraph',
    props: {
        pourcentage: {
            type: Number,
            required: true,
        },
        averageQuiz: {
            type: Number,
        },
    },

    methods: {
        setCircle() {
            return `calc(6.14 * 40% / 100 * ${this.pourcentage * 100}), calc(6.14 * 40%)`;
        },
    },
};
</script>

<style lang="scss" scoped src="./UserQuizGraph.scss" />
