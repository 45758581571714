import Model from '@tony.caron/node-model-proxy/Model';

import Company from './Company';
import CompanyUser from './CompanyUser';
import CompanyRole from './CompanyRole';
import CompanyRequirementCategorySupervisorCollection from './CompanyRequirementCategorySupervisorCollection';
import CompanyRequirementCategory from './CompanyRequirementCategory';

export default class CompanyRequirementCategorySupervisor extends Model {
    modelConfig() {
        return {
            collection: CompanyRequirementCategorySupervisorCollection,
        };
    }

    modelRelations() {
        return {
            company: () => this.belongsTo(Company, 'company_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyRole: () => this.belongsTo(CompanyRole, 'company_role_id', 'id'),
            companyRequirementCategory: () => this.belongsTo(CompanyRequirementCategory, 'company_requirement_category_id', 'id'),
        };
    }
}
