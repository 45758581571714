const SET_PERIMETER_ENTITIES = 'set_perimeter_entities';

export default {
    namespaced: true,

    state() {
        return {
            perimeterEntities: [],
        };
    },

    actions: {
        setPerimeterEntities({ commit }, entities) {
            commit(SET_PERIMETER_ENTITIES, entities);
        },
    },

    mutations: {
        [SET_PERIMETER_ENTITIES](state, value) {
            state.perimeterEntities = value;
        },
    },
};
