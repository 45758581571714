<template>
    <div class="full-width">
        <template v-if="companySurveys.isLoaded() && companyUserProgram.isLoaded()">
            <form
                class="form-2 spaced"
                data-cy="survey-edit-form"
            >
                <div v-if="surveyOptions.length">
                    <fieldset>
                        <HtFormSelect
                            id="survey"
                            v-validate.disable="'required'"
                            :label="translate('Name')"
                            name="survey"
                            cypress="survey"
                            :data-vv-as="translate('survey')"
                            :options="surveyOptions"
                            :value="selectedOption"
                            @input="onSelectSurvey(...arguments)"
                        />
                    </fieldset>

                    <div v-if="selectedOption">
                        <fieldset :class="{ 'error': errors.has('survey_participants')}">
                            <label><t>Assign To:</t> *</label>
                            <div class="card-6 flex-container">
                                <template>
                                    <div
                                        v-for="(participant,index) in item.company_user_survey_participant"
                                        :key="index"
                                        class="item"
                                    >
                                        <UserBlock :item="participant" />

                                        <div
                                            class="trash"
                                            data-cy="survey-participant-delete"
                                            @click="onRemoveParticipant(participant)"
                                        >
                                            <svg class="icon icon-trash">
                                                <use xlink:href="#icon-trash" />
                                            </svg>
                                        </div>
                                        <div class="align-center">
                                            {{ participant.firstname }}
                                        </div>
                                    </div>
                                    <div class="add_item">
                                        <div class="item">
                                            <svg
                                                class="icon icon-add"
                                                data-cy="survey-participant-add"
                                                @click="openAddUsersModal"
                                            >
                                                <use xlink:href="#icon-add" />
                                            </svg>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <input
                                id="survey_participants"
                                v-model="item.company_user_survey_participant"
                                v-validate.disable="'required|array'"
                                type="hidden"
                                name="survey_participants"
                                class="full-width"
                                :data-vv-as="translate('list of participants')"
                            >
                            <div
                                v-for="(errorMessage, index) in errors.collect('survey_participants')"
                                :key="index"
                                class="error-message"
                            >
                                {{ errorMessage }}
                            </div>
                        </fieldset>

                        <fieldset>
                            <label><t>Choose the supervisor of this survey:</t></label>
                            <div class="card-6 flex-container">
                                <div
                                    v-for="(supervisor,index) in item.company_user_survey_supervisor.models"
                                    :key="index"
                                    class="item"
                                >
                                    <UserBlock :item="supervisor" />

                                    <div
                                        class="trash"
                                        data-cy="survey-supervisor-delete"
                                        @click="onRemoveSupervisor(supervisor)"
                                    >
                                        <svg class="icon icon-trash">
                                            <use xlink:href="#icon-trash" />
                                        </svg>
                                    </div>

                                    <div class="align-center">
                                        {{ supervisor.company_user.firstname }}
                                    </div>
                                </div>
                                <div class="add_item">
                                    <div class="item">
                                        <svg
                                            class="icon icon-add"
                                            data-cy="survey-supervisor-add"
                                            @click="openAddSupervisorsModal"
                                        >
                                            <use xlink:href="#icon-add" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <HtKeyDatesInputOrder
                            :user-program-id="companyUserProgramId"
                            :is-business-day.sync="userTask.is_business_day"
                            :offset-key-date.sync="userTask.company_user_program_key_date_id"
                            :offset-availability-specific-date.sync="userTask.datetime_availability"
                            :offset-availability-number.sync="userTask.offset_availability_number"
                            :offset-availability-unit.sync="userTask.offset_availability_unit"
                            :offset-end-specific-date.sync="userTask.datetime_end"
                            :offset-end-number.sync="userTask.offset_end_number"
                            :offset-end-unit.sync="userTask.offset_end_unit"
                            can-select-specific-date
                        />

                        <RecurrenceUserConfig
                            :recurrence="recurrence"
                            @update="setRecurrence"
                        />

                        <div class="modal-actions right">
                            <Button
                                :state="buttonState"
                                cypress="survey-add-button"
                                @click="onValidate()"
                            >
                                {{ translate('Add') }}
                            </Button>
                        </div>
                    </div>
                </div>

                <div
                    v-else
                    class="empty-component"
                >
                    <strong><t>All available surveys already selected !</t></strong>
                </div>
            </form>
        </template>

        <template v-else>
            <IconLoading />
        </template>

        <modalable
            ref="modalableSearchRolePanel"
            class="modalable-1 small"
            :options="{ title: translate('Done by')}"
        >
            <SearchRolePanel
                ref="searchRolePanel"
                :multiple-mode="true"
                :has-roles="false"
                :is_assignable_onboardee="true"
                @onValidate="$refs.modalableSearchRolePanel.close()"
            />
        </modalable>
    </div>
</template>

<script>
import HtProgramType from '@/models/HtProgramType';
import CompanySurvey from '@/models/CompanySurvey';
import CompanyUserSurvey from '@/models/CompanyUserSurvey';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import CompanySurveyCollection from '@/models/CompanySurveyCollection';
import CompanyRoleUserCollection from '@/models/CompanyRoleUserCollection';
import TaskDependencyMixin from '@/mixins/TaskDependencyMixin';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import RecurrenceUserConfig from '@/components/resources/recurrence/RecurrenceUserConfig.vue';
import CompanyUserRecurrence from '@/models/CompanyUserRecurrence';
import CompanyUser from '@/models/CompanyUser';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';

export default {
    name: 'UserSurveySelect',
    components: {
        HtKeyDatesInputOrder,
        RecurrenceUserConfig,
    },

    mixins: [
        TaskDependencyMixin,
    ],

    inject: ['modal'],

    props: {
        companyUserId: {
            type: [String, Number],
            default: '',
        },
        companyUserProgramId: {
            type: [String, Number],
            default: '',
        },
        intelligentSelection: {
            type: Array,
            default: () => ([]),
        },
        isDraft: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            userTask: null,
            selectedOption: null,
            buttonState: 'idle',

            companyUser: new CompanyUser([
                'id',
            ]).with({
                companyLanguage: (query) => {
                    query.select(['id', 'key']);
                },
            }).where([
                ['id', '=', this.companyUserId],
            ]),
            companyUserProgram: new CompanyUserProgram([
                'id',
            ]).with({
                mainKeyDate: (query) => {
                    query.select(['id', 'starts_at']);
                },
                companyProgram: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            keyDate: (query) => {
                                query.with({
                                    locales: (query) => query
                                        .select(['id', 'name', 'language_key']),
                                });
                            },
                        });
                },
            }).where([
                ['id', '=', this.companyUserProgramId],
            ]),

            companyRoleUserCollection: new CompanyRoleUserCollection([
                'id',
                'company_user_id',
                'company_role_id',
            ]).with({
                user: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']);
                },
            }).whereHas({
                employees: (query) => {
                    query.where([
                        ['company_user_id', '=', this.companyUserId],
                    ]);
                },
            }),
            companySurveys: new CompanySurveyCollection([
                'id',
            ]).with({
                companySurveySupervisor: (query) => {
                    query.select(['id', 'company_survey_id', 'company_user_id', 'company_role_id']).with({
                        companyUser: (query) => {
                            query.select(['id', 'firstname', 'lastname', 'image']);
                        },
                    });
                },

                resource: (query) => {
                    query
                        .select([
                            'id',
                            'name',
                        ])
                        .with({
                            resourcePrograms: (query) => {
                                query.select([
                                    'id',
                                    'company_program_id',
                                    'company_resource_id',
                                    'is_business_day',
                                    'offset_availability_number',
                                    'offset_availability_unit',
                                    'offset_end_number',
                                    'offset_end_unit',
                                    'recurrence_frequence_unit',
                                    'recurrence_frequence_value',
                                ]);
                            },
                        });
                },
            }).where([
                ['status', '=', CompanySurvey.STATUS_IN_PROGRESS],
            ]).whereHas({
                resource: (query) => {
                    query.whereHas({
                        resourcePrograms: (query) => {
                            query.where([
                                ['is_enabled', '=', true],
                            ]).whereHas({
                                program: (query) => query.whereHas({
                                    htProgramType: (query) => query.where([['slug', '!=', HtProgramType.SLUG_DEFAULT]]),
                                }),
                            });
                        },
                    });
                },
            }),
            item: new CompanyUserSurvey([
                'id',
                'company_user_program_id',
                'name',
                'company_user_id',
                'company_survey_id',
                'company_user_participant_id',
            ]).with({
                companyUserProgramTask: (query) => {
                    query
                        .select([
                            'id',
                            'resource_id',
                            'resource',
                            'is_business_day',
                            'company_user_program_key_date_id',
                            'offset_availability_number',
                            'offset_availability_unit',
                            'offset_end_number',
                            'offset_end_unit',
                            'datetime_availability',
                            'datetime_end',
                            'company_user_recurrence_id',
                        ])
                        .with({
                            dependents: (query) => {
                                query.select([
                                    'id',
                                ]);
                            },
                            recurrence: (query) => {
                                query.select([
                                    'id', 'recurrence_frequence_unit', 'recurrence_frequence_value',
                                ]);
                            },
                        });
                },
                companyUserSurveySupervisor: (query) => {
                    query.select(['id', 'company_survey_id', 'company_user_id']).with({
                        companyUser: (query) => {
                            query.select(['id', 'firstname', 'lastname', 'image']);
                        },
                    });
                },
            }),
        };
    },

    computed: {
        userLanguage() {
            return this.companyUser?.company_language?.key;
        },
        surveyOptions() {
            return this.companySurveys.$.models.map((survey) => ({
                id: survey,
                name: survey.resource.translations.name[this.userLanguage]
                    ?? survey.resource.name,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
        recurrence() {
            return this.userTask.recurrence;
        },
    },

    created() {
        this.companyUser.get();
        this.companyUserProgram.get();

        this.companyRoleUserCollection.get();
        this.companySurveys.get().then(() => {
            this.item.company_user_id = this.companyUserId;
            this.createTaskWithDependents(this.item);
        });

        this.configs = { ...this.configs, ...this.options };
        this.modal.setTitle(this.translate('Add survey'));
    },

    methods: {
        onSelectSurvey(value) {
            this.selectedOption = value;

            const firstResourceProgram = value.resource.resource_programs.models[0] || {
                offset_availability_number: 0,
                offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                offset_end_number: 0,
                offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                is_business_day: true,
                recurrence_frequence_value: null,
            };

            const task = new CompanyUserProgramTask();
            task.is_business_day = firstResourceProgram.is_business_day;
            task.offset_availability_number = firstResourceProgram.offset_availability_number;
            task.offset_availability_unit = firstResourceProgram.offset_availability_unit;
            task.offset_end_number = firstResourceProgram.offset_end_number;
            task.offset_end_unit = firstResourceProgram.offset_end_unit;
            task.recurrence = null;

            if (firstResourceProgram.recurrence_frequence_value) {
                task.recurrence = new CompanyUserRecurrence();
                task.recurrence.recurrence_frequence_unit = firstResourceProgram.recurrence_frequence_unit;
                task.recurrence.recurrence_frequence_value = firstResourceProgram.recurrence_frequence_value;
            }

            this.item.company_survey_id = value.id;

            this.item.company_user_program_task = task;
            this.userTask = this.item.company_user_program_task;

            this.item.company_user_program_id = this.companyUserProgramId;

            this.item.company_user_survey_supervisor = value.company_survey_supervisor.models.reduce((acc, current) => {
                if (current.company_user_id) {
                    const oCompanyUserSurveySupervisor = this.item.company_user_survey_supervisor.new();
                    oCompanyUserSurveySupervisor.company_user_id = current.company_user_id;
                    oCompanyUserSurveySupervisor.company_user = current.company_user;
                    oCompanyUserSurveySupervisor.company_role_id = null;
                    acc.push(oCompanyUserSurveySupervisor);
                } else {
                    const currentProgramSupervisor = this.companyRoleUserCollection.models.find((roleUser) => roleUser.company_role_id === current.company_role_id);

                    if (currentProgramSupervisor) {
                        const oCompanyUserSurveySupervisor = this.item.company_user_survey_supervisor.new();
                        oCompanyUserSurveySupervisor.company_user_id = currentProgramSupervisor.company_user_id;
                        oCompanyUserSurveySupervisor.company_user = currentProgramSupervisor.user;
                        oCompanyUserSurveySupervisor.company_role_id = currentProgramSupervisor.company_role_id;
                        acc.push(oCompanyUserSurveySupervisor);
                    }
                }

                return acc;
            }, []);
        },
        openAddSupervisorsModal() {
            this.$refs.searchRolePanel.init({
                onValidate: this.addSupervisors,
                selected: this.item.company_user_survey_supervisor.models,
                intelligentSelection: this.intelligentSelection,
            });
            this.$refs.modalableSearchRolePanel.open();
        },

        addSupervisors(supervisors) {
            this.item.company_user_survey_supervisor = supervisors.map(
                (supervisor) => ({
                    company_user: null,
                    company_user_id: null,
                    ...supervisor,
                }),
            );
        },

        onRemoveSupervisor(supervisor) {
            this.item.company_user_survey_supervisor = this.item.company_user_survey_supervisor.models.filter(
                (item) => (
                    item.company_role_id !== supervisor.company_role_id
                    || item.company_user_id !== supervisor.company_user_id
                ),
            );
        },

        openAddUsersModal() {
            this.$refs.searchRolePanel.init({
                onValidate: this.addParticipants,
                selected: this.item.company_user_survey_participant,
                intelligentSelection: this.intelligentSelection,
            });
            this.$refs.modalableSearchRolePanel.open();
        },

        addParticipants(participants) {
            this.item.company_user_survey_participant = participants.map(
                (participant) => ({
                    company_role: null,
                    company_role_id: null,
                    company_user: null,
                    company_user_id: null,
                    ...participant,
                }),
            );
        },

        onRemoveParticipant(participant) {
            this.item.company_user_survey_participant = this.item.company_user_survey_participant.filter(
                (item) => (
                    item.company_role_id !== participant.company_role_id
                    || item.company_user_id !== participant.company_user_id
                ),
            );
        },

        onValidate() {
            this.$validator.validateAll().then(() => {
                if (this.errors.any()) return;

                this.item.save().then(() => {
                    this.$emit('onUpdate');
                    this.modal.close();
                });
            });
        },

        setRecurrence(value) {
            this.item.company_user_program_task.recurrence = value;
        },
    },
};
</script>

<style lang="scss">
	.trash {
		width: 35px;
		height: 35px;
		position: absolute;
		top: -15px;
		right: -15px;
		background: #fff;
		box-sizing: border-box;
		padding: 3px;
		border: 1px solid #ebebeb;
		border-radius: 50%;
		text-align: center;
		padding: 8px;
		color: black;

		svg {
			width: 15px !important;
			height: 15px !important;
		}
	}
</style>
