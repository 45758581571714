<template>
    <div
        class="quiz-question-answer"
        :class="{toggled: isToggled}"
    >
        <div class="quiz-question-answer-header">
            <div v-if="answer.is_correct_answer">
                <span class="icon-answer-right"><i class="fas fa-check" /></span>
                <span class="answer-title"><t>Right answer</t>*</span>
            </div>
            <div v-else>
                <span class="icon-answer-bad"><i class="fas fa-times" /></span>
                <span class="answer-title"><t>Bad answer</t></span>
            </div>

            <div v-if="canDelete">
                <button
                    class="button button-icon"
                    @click="emitDeleteAnswer(answer)"
                >
                    <svg class="icon icon-trash">
                        <use xlink:href="#icon-trash" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="quiz-question-answer-input-text">
            <InputField
                :id="(answer.id) ? `text ${answer.id}` : `text ${answer.tempId}`"
                v-model="answer.translations.text[editingLang]"
                v-next-field
                type="text"
                :name="(answer.id) ? `text ${answer.id}` : `text ${answer.tempId}`"
                :placeholder="translate('Type here')"
                :validate="!answer.company_file_id ||answer.company_file_id.length === 0 ? 'required':''"
                :validate-as="translate('answer text')"
                :cypress="`quizQuestionAnswer-form-text-${indexAnswer}`"
            />
        </div>
        <div
            v-for="lang in shared.session.company.company_languages.enabled"
            :key="lang.key"
            :class="['quiz-question-answer-input-image', { toggled: isToggled }]"
        >
            <QuizImageEdit
                v-show="lang.key === editingLang"
                :key="lang.key"
                :editing-lang="lang.key"
                :input-model.sync="answer"
                :is-toggled="isToggled"
                :input-label="translate('Attach picture to the answer')"
            />
        </div>
    </div>
</template>

<script>
import Croppable from '@/components/tools/Croppable.vue';
import QuizImageEdit from './QuizImageEdit.vue';

export default {
    components: { QuizImageEdit, Croppable },
    inject: ['$validator'],
    props: {
        isToggled: { type: Boolean, default: false },
        answer: { type: Object }, // CompanyQuizQuestionAnswer
        indexAnswer: { type: Number, required: true },
        canDelete: { type: Boolean, default: false },
        editingLang: {
            type: String,
            required: true,
        },
    },
    computed: {
        showCroppableImage() {
            return this.answer.image && this.answer.image.length > 0 ? 'displayPicture' : 'hidePicture';
        },
    },
    watch: {
        'answer.text': function () {
            this.$emit('updateAnswer', this.answer);
        },
    },
    methods: {
        emitDeleteAnswer(answer) {
            this.$emit('deleteAnswer', answer);
        },
    },
};
</script>

<style lang="scss" scoped src="./QuizQuestionAnswerEdit.scss" />
