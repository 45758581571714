<template>
    <div
        id="app"
        :class="[
            appClass,
            { 'background-grey': checkGrey, 'background-lightblue-grey': checkLightBlueGrey }
        ]"
        tabindex="0"
    >
        <CollectionSvg />

        <DebugBar v-if="$env.get('SHOW_DEBUGGER')" />

        <BugIcon v-if="showBugIcon" />

        <transition name="fade">
            <router-view />
        </transition>

        <DeleteWindow />

        <Notifier name="App" />
        <HtNotifier name="Notifs" />
    </div>
</template>

<script>
import CollectionSvg from '@/components/CollectionSvg.vue';
import Notifier from '@/components/tools/Notifications/Notifier.vue';
import HtNotifier from '@/components/tools/Notifications/HtNotifier.vue';
import DebugBar from '@/components/debug/DebugBar.vue';
import DeleteWindow from '@/components/tools/DeleteWindow.vue';
// import common from '@/store/modules/common.store';
import modalMixin from '@/components/globals/modals/modalMixin';
import BugIcon from './components/tools/BugIcon.vue';
import TwoFactorAuthInformationModal from './pages/admin/TwoFactorAuthInformationModal.vue';

export default {
    name: 'App',
    components: {
        Notifier,
        HtNotifier,
        DebugBar,
        DeleteWindow,
        CollectionSvg,
        BugIcon,
    },
    mixins: [
        modalMixin,
    ],
    data() {
        return {
            appClass: '',
            forceShowBugIcon: false,
        };
    },

    computed: {
        showBugIcon() {
            const blacklistRoutes = [
                'DocusignRedirectedPage',
            ];

            return !blacklistRoutes.includes(this.$route.name) && (this.$env.get('SHOW_BUG_ICON') || this.forceShowBugIcon);
        },

        checkGrey() {
            const allowedRoutes = [
                'SettingsRolesAndPermissionsForm',
                'SettingsEntitiesForm',
                'ResourcesSurveysForm',
                'ResourcesRequirementsForm',
                'ResourcesEventsForm',
                'InviteManuallyUser',
            ];

            return allowedRoutes.includes(this.$route.name);
        },

        checkLightBlueGrey() {
            const allowedRoutes = [
                'PocArea',
                'Locale',
                'Autoconnect',
                'Dashboard',
                'DashboardActions',
                'DashboardHistory',
                'DashboardExternal',
                'DashboardPrograms',
                'DashboardStatistics',
                'DashboardReport',
                'DashboardSpecificReports',
                'Team',
                'TeamAllEmployees',
                'TeamMyClass',
                'Company',
                'ProfileResources',
                'ProfileProgram',
                'Profile',
                'ProfileAdministrative',
                'ProfileProgramDetail',
                'ProfileActivities',
                'ProfileDelegations',
                'Programs',
                'ProgramsForm',
                'ProgramDetail',
                'ProgramDetailLive',
                'ProgramDetailDraft',
                'ProgramDetailDone',
                'ProgramDetailProbation',
                'ProgramDetailSimulation',
                'ProgramDetailInvitations',
                'SurveyResults',
                'SurveyResultDetail',
                'Enrolling',
                'EnrollingNew',
                'EnrollingTeam',
                'EnrollingSynthese',
                'EnrollingMail',
                'EnrollingProgram',
                'BulkEnrolling',
                'BulkEnrollingPersonalInformation',
                'BulkEnrollingTarget',
                'BulkEnrollingTeam',
                'BulkEnrollingProgram',
                'BulkEnrollingSummary',
                'Resources',
                'ResourcesDocuments',
                'ResourcesTrainings',
                'ResourcesSurveys',
                'ResourcesQuizzes',
                'ResourcesEvents',
                'ResourcesSoftware',
                'ResourcesEquipments',
                'ResourcesRequirements',
                'ResourcesEmails',
                'ResourcesActions',
                'ResourcesNudges',
                'Settings',
                'SettingsUsers',
                'SettingsRolesAndPermissions',
                'SettingsEntities',
                'SettingsCompany',
                'SettingsEnterprisePage',
                'SettingsEnterprisePageForm',
                'SettingsNotifications',
                'SettingsPreboarding',
                'SettingsCommunication',
                'SettingsLanguages',
                'SettingsDataAnalysis',
                'SettingsIntegrations',
                'SettingsSecurityAndPrivacy',
                'SettingsCustomFields',
                'SettingsEntitiesCustom',
                'SettingsEntitiesOffices',
                'SettingsEntitiesContracts',
                'SettingsEntitiesDepartments',
                'SettingsEntitiesJobPositions',
                'SettingsLogs',
                'HoldingDashboard',
                'HoldingResources',
                'HoldingResourcesDocumentsList',
                'HoldingResourcesDocumentsCount',
                'HoldingResourcesTrainingsList',
                'HoldingResourcesTrainingsCount',
                'HoldingResourcesSurveysList',
                'HoldingResourcesSurveysCount',
                'HoldingResourcesQuizzesList',
                'HoldingResourcesQuizzesCount',
                'HoldingResourcesEventsList',
                'HoldingResourcesEventsCount',
                'HoldingResourcesSoftwareCount',
                'HoldingResourcesSoftwareList',
                'HoldingResourcesEquipmentsCount',
                'HoldingResourcesEquipmentsList',
                'HoldingResourcesRequirementsCount',
                'HoldingResourcesRequirementsList',
                'HoldingResourcesEmailsCount',
                'HoldingResourcesEmailsList',
                'HoldingResourcesActionsCount',
                'HoldingResourcesActionsList',
                'HoldingResourcesNudgesCount',
                'HoldingResourcesNudgesList',
                'HoldingSettings',
                'HoldingSettingsUsersCount',
                'HoldingSettingsUsersList',
                'NewProgram',
                'ProgramTemplateSelection',
                'ProgramTemplatePreview',
            ];
            return allowedRoutes.includes(this.$route.name);
        },

        favicon() {
            return this.$store.state.faviconTitle.favicon;
        },
        title() {
            return this.$store.state.faviconTitle.title;
        },
    },

    methods: {
        addShiftBUGListeners() {
            const keysPressed = {};
            document.addEventListener('keydown', (event) => {
                keysPressed[event.key] = true;

                if (keysPressed.Shift && keysPressed.B && keysPressed.U && event.key === 'G') {
                    this.forceShowBugIcon = !this.forceShowBugIcon;
                }
            });
            document.addEventListener('keyup', (event) => {
                delete keysPressed[event.key];
            });
        },
        setIcon: (function () {
            const link = document.querySelector("link[rel='icon']");
            return function (href, type) {
                link.href = href;
                link.type = type;
            };
        }()),
        setTitle: (function () {
            const title = document.querySelector('title');
            return function (text) {
                title.innerText = text;
            };
        }()),
    },

    watch: {
        favicon(icon) {
            this.setIcon(icon.href, icon.type);
        },
        title(text) {
            this.setTitle(text);

            if (this.favicon) {
                this.setIcon(this.favicon.href, this.favicon.type);
            }
        },
    },
    mounted() {
        const isIEMore11 = document.documentMode || /Edge/.test(navigator.userAgent);
        // IE10 + IE11
        const ie11ie10 = (navigator.userAgent.indexOf('Trident') !== -1
            && navigator.userAgent.indexOf('rv:11') !== -1)
            || navigator.appVersion.indexOf('MSIE 10') !== -1;
        if (ie11ie10) this.appClass = 'ie';
        if (!ie11ie10 && isIEMore11) this.appClass = 'edge';

        this.addShiftBUGListeners();
    },
    updated() {
        if (this.$store.state.common.showTwoFactorAuthInformationModal) {
            this.$store.dispatch('common/updateShowTwoFactorAuthInformationModal', false);

            this.openCenterModal(
                TwoFactorAuthInformationModal,
                {
                    companyName: this.shared.session.company.name,
                },
                {},
                {
                    reset: true, adaptive: true, styles: 'border-radius: 16px; box-shadow:none;',
                },
            );
        }
    },
};
</script>

<style lang="scss">
// Import de Bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import '~@/styles/bootstrap-custom';

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";

@import "~@/styles/var";
@import "~@/styles/reset";
@import "~@/styles/message";
@import "~@/styles/button";
@import "~@/styles/photo-edit";
@import "~@/styles/card";
@import "~@/styles/flags";
@import "~@/styles/form";
@import "~@/styles/animated-loader";
@import "~@/styles/header";
@import "~@/styles/invite";
@import "~@/styles/planning";
@import "~@/styles/profile";
@import "~@/styles/installation";
@import "~@/styles/items-bar";
@import "~@/styles/company";
@import "~@/styles/table";
@import "~@/styles/layouts";
@import "~@/styles/preboarding";
@import "~@/styles/icon-elements";
@import "~@/styles/survey";
@import "~@/styles/onboarding";
@import "~@/styles/utils";
@import "~@/styles/tooltip";

@import url("https://fonts.googleapis.com/css?family=Lato:400,700,400italic,700italic&subset=latin");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700,400italic,700italic&subset=latin");

@font-face {
    font-family: "lato";
    src: url("~@/assets/fonts/lato-regular.woff2") format("woff2"),
        url("~@/assets/fonts/lato-regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "latoitalic";
    src: url("~@/assets/fonts/lato-italic.woff2") format("woff2"),
        url("~@/assets/fonts/lato-italic.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "latobold";
    src: url("~@/assets/fonts/lato-bold.woff2") format("woff2"),
        url("~@/assets/fonts/lato-bold.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "latoboldit";
    src: url("~@/assets/fonts/lato-bolditalic.woff2") format("woff2"),
        url("~@/assets/fonts/lato-bolditalic.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "latoblack";
    src: url("~@/assets/fonts/lato-black.woff2") format("woff2"),
        url("~@/assets/fonts/lato-black.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

.progress-bar-container {
    display: flex;
    align-items: center;
    height: 2px;
    width: 100%;
    border-radius: 3px;
    background: $border-grey-light;

    .progress-bar {
        display: block;
        height: 4px;
        border-radius: 3px;

        &.green {
            background-color: $color-green;
        }

        &.yellow {
            background-color: $color-yellow;
        }

        &.red {
            height: 4px;
            background-color: $color-primary;
        }
    }
}

html {
    font-size: 62.5%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    width: 100%;
    font-family: $lato;
    font-size: 1.6em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

html,
body {
    height: 100%;
}

.white {
    color: #fff !important;
}

.bg-grey-2 {
    background: $bg-grey;
}

.bg-grey {
    background: $white;
}

#app {
    height: 100%;
    overflow-y: auto;
    color: $black;
    background: $white;

    &.background-white {
        background: $white;
    }

    &.background-grey {
        background: #f9f9f8;
    }

    &.background-lightblue-grey {
        background: $color-lightblue-grey;
    }

}

#main-page {
    display: block;
    width: 100%;
    max-width: 1200px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 50px 20px;

    &.large {
        max-width: 1230px;
    }

    &.with-header {
        min-height: calc(100vh - 100px);
    }

    &.small {
        max-width: 760px;
    }

    &.padding-top {
        padding-top: 90px;
    }

    &.has-nav-bottom {
        padding-bottom: 150px;
    }
}

@media (max-width: $desktop-large) {
    #main-page {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.icon {
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;
    fill: currentColor;
}

/* TOP PAGE */
.title-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 4.5em;

    &.title-center {
        .title {
            text-align: center;
            padding: 0 60px;
        }

        .button {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -17px;
        }
    }

    &.no-margin-bottom {
        margin-bottom: 0;
    }

    .title {
        margin-bottom: 0;
        flex-grow: 1;
    }

    .wrapper-button {
        display: inline-block;
        position: relative;
        margin-left: 2em;
        flex-shrink: 0;
    }
}

.none {
    display: none;
}

/* TRANSITION */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s;
}

.list-enter,
.list-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: all 0.5s;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.zoom-enter-active,
.zoom-leave-active {
    transition: all 0.9s;
    transition-timing-function: cubic-bezier(0, 1.03, 0.57, 1.1);
}

.zoom-enter,
.zoom-leave-to {
    transform: scale(0.5);
    opacity: 0;
}

.slide-enter-active {
    transition: all 0.5s ease-in-out;
    overflow-y: hidden;
}

.slide-leave-active {
    transition: all 0.2s ease-in-out;
    overflow-y: hidden;
}

.slide-enter {
    max-height: 0;
}

.slide-enter-to {
    max-height: 100vh;
}

.slide-leave {
    max-height: 100vh;
}

.slide-leave-to {
    max-height: 0;
}

@keyframes fade_anim {
    from {
        opacity: 0;
        background: red;
    }

    to {
        opacity: 1;
    }
}

.fade_anim {
    animation-name: fade_anim;
    animation-duration: 0.8s;
}

.admin_bg {
    background: #fafafa;
}

.admin_bg .item-legend {
    color: #7d7d7d;
}

/* GLOBAL */
.page {
    padding: 0 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $lato-bold;
}

h1 {
    font-size: 5rem;
    line-height: 1;
    margin: 0 0 2em;

    &.low {
        margin: 0 0 36px;
    }

    &.medium {
        font-size: 3.2rem;
        line-height: 1.3;
        margin: 0 0 0.3em;
    }

    &.small {
        font-size: 2.8rem;
        margin: 0 0 1em;
    }

    .dashboard & {
        margin-bottom: 0;
    }
}

h2 {
    font-size: 2.2rem;
    margin: 0 0 2em;

    &.margin-top {
        margin: 2em 0 2em;
    }

    &.big-margin-bottom {
        margin-bottom: 3em;
    }
}

h3 {
    font-size: 3rem;
    margin: 0 0 1.3em;

    .top_attached {
        margin-top: 0;
    }

    &.small {
        font-size: 2.2rem;
    }
}

h4 {
    &.big {
        font-size: 1.8rem;
    }
}

p {
    color: $text;
    line-height: 1.3;

    .big {
        display: block;
        text-transform: uppercase;
        margin-top: 8px;
    }
}

b,
strong {
    font-family: $lato-bold;
}

/* DISABLE / RESET */
.ui.form .field {
    margin: 0 !important;
}

.ui.input,
.ui input[type="text"] {
    display: flex;
    flex-grow: 1;
}

.ui.form .field.error input {
    width: 100% !important;
}

.ui.selection.dropdown {
    width: 100%;
}

.ui.selection.dropdown {
    max-height: 2rem !important;
    min-width: auto !important;
    line-height: 0.5em !important;
    min-height: 0 !important;
}

.ui.selection.dropdown>.dropdown.icon {
    top: 0.58571429em !important;
}

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"] {
    font-family: "Avenir", Helvetica, Arial, sans-serif !important;
    margin: 0;
    outline: 0;
    -webkit-appearance: none !important;
    background: #ffffff !important;
    border: 1px solid rgba(34, 36, 38, 0) !important;
    transition: color 0.1s ease !important;
    border-bottom: 1px solid #e0bdbd !important;
    color: #965b5b !important;
    height: 27px;
}

.ui.form .field.error input:not([type]),
.ui.form .field.error input[type="date"],
.ui.form .field.error input[type="datetime-local"],
.ui.form .field.error input[type="email"],
.ui.form .field.error input[type="file"],
.ui.form .field.error input[type="number"],
.ui.form .field.error input[type="password"],
.ui.form .field.error input[type="search"],
.ui.form .field.error input[type="tel"],
.ui.form .field.error input[type="text"],
.ui.form .field.error input[type="time"],
.ui.form .field.error input[type="url"],
.ui.form .field.error select,
.ui.form .field.error textarea,
.ui.form .fields.error .field input:not([type]),
.ui.form .fields.error .field input[type="date"],
.ui.form .fields.error .field input[type="datetime-local"],
.ui.form .fields.error .field input[type="email"],
.ui.form .fields.error .field input[type="file"],
.ui.form .fields.error .field input[type="number"],
.ui.form .fields.error .field input[type="password"],
.ui.form .fields.error .field input[type="search"],
.ui.form .fields.error .field input[type="tel"],
.ui.form .fields.error .field input[type="text"],
.ui.form .fields.error .field input[type="time"],
.ui.form .fields.error .field input[type="url"],
.ui.form .fields.error .field select,
.ui.form .fields.error .field textarea {
    background: #fff6f6 !important;
}

.editable {

    .ui.form input:not([type]),
    .ui.form input[type="date"],
    .ui.form input[type="datetime-local"],
    .ui.form input[type="email"],
    .ui.form input[type="file"],
    .ui.form input[type="number"],
    .ui.form input[type="password"],
    .ui.form input[type="search"],
    .ui.form input[type="tel"],
    .ui.form input[type="text"],
    .ui.form input[type="time"],
    .ui.form input[type="url"] {
        height: auto;
    }
}

.ui.primary.button,
.ui.primary.buttons .button {
    background-color: #33859a !important;
}

.row-line {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
    flex-flow: row wrap;
    flex-wrap: wrap;

    &.middle {
        justify-content: center;
        align-items: center;
    }

    &.space-between {
        justify-content: space-between;
    }

    &.nowrap {
        flex-wrap: nowrap;
    }

    .button {
        margin: 5px 25px;
    }
}

.row-line:first-child {
    margin-top: 0rem;
}

/******************LAYOUT********************/
.user-img {
    background: #d8d8d8 no-repeat 50% 50%;
    background-size: cover;
    border-radius: 50%;
    position: relative;
}

.user-initials {
    align-items: center;
    background: #e0e0e3;
    color: #505050;
    display: flex;
    font-size: 2.5rem;
    font-family: $lato-bold;
    justify-content: center;
    display: flex;
}

.info-message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    padding: 17px 20px;
    background: $info;
    border-radius: 5px;

    .icon-alert-triangle {
        width: 23px;
        height: 19px;
        margin: 0 15px 0 0;
        flex-shrink: 0;
    }

    p {
        margin: 0;
    }
}

/******************TOOL********************/
.centered_content {
    justify-content: center;
}

.align-center {
    text-align: center;
    justify-content: center;
}

.relative {
    position: relative;
}

.sub-text {
    font-size: 1rem;
    color: grey;
}

.description-text {
    color: $grey-text;
}

.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@media (max-width: $tablet) {
    h1 {
        font-size: 4rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 2rem;
    }

    .title-button-container {
        display: block;

        h1 {
            margin-bottom: 0.3em;
        }

        .wrapper-button {
            margin-top: 1em;
            margin-left: 0;
        }
    }
}

@media (max-width: $phone) {
    h1 {
        font-size: 3.5rem;
    }

    .title-pic {
        .user-img {
            width: 80px;
            height: 80px;
            margin-right: 20px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .ui.container {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

@media only screen and (min-width: 1200px) {
    .ui.container {
        width: 900px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s;
}

.fade-enter,
.fade-leave-to,
.fade-leave-active

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

.fade-leave-to,
.fade-leave-active {
    display: none;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-to {
    opacity: 1;
}

._editable {
    cursor: pointer;
    border-bottom: 1px solid transparent !important;
    transition: all 0.5s;
}

._editable:hover {
    border-bottom: 1px solid #e0bdbd !important;
}

html,
#app,
.full.height,
._404 {
    height: 100%;
}

.empty-component {
    transition: all 0.5s;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: $color-text-grey;

    p {
        margin: 15px 0 0;
        text-align: center;
        max-width: 50%;

        &:last-child {
            line-height: 2;
        }
    }

    .icon-sad {
        cursor: default;
        width: 119px;
        height: 119px;
    }

    &.small-modal {
        margin-top: 4em;

        p {
            max-width: 70%;
        }
    }
}

/* QUICK MODIFIER */
.full-width {
    width: 100%;
}

.center {
    text-align: center;
}

.scale {
    transition: all 0.3s;

    &:hover {
        transform: scale(1.05);
    }
}

.scale-small {
    transition: all 0.3s;

    &:hover {
        transform: scale(1.03);
    }
}

.hover-shadow {
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        box-shadow: 0px 3px 5px $border-grey-light;
    }
}

.clickable {
    cursor: pointer;
}

.w-35 {
    width: 35%;
}

/********* SNCF R2H2 chatbot (company_id = 213) ********/

//Obligé de coller la bulle qui actionne le chatbot à gauche de l'écran, sinon il prend la place de certains boutons de nos modales
.l-clevy-chatbox {
    left: 20px !important;
    right: unset !important;

    &__launcher-btn {

        background-size: cover !important;
        background-image: url('/static/images/logo_rhd2.png') !important;

        svg {
            display: none;
        }
    }
}

/**
    set la taille maximal que prend la popup du chatbot, sinon elle dépasse la taille de l'écran
    100vh : taille de la page
    25px : marge entre la bulle et le bas de la page
    80px : marge entre la popup du chat et la bulle
*/
.l-clevy-chatbox--visible .l-clevy-chatbox__popup {
    max-height: calc(100vh - 80px - 25px);
}

.l-clevy-chatbox__launcher-btn {
    right: unset !important;
}

// quand l'écran est petit, on laisse le css de sncf gérer
@media (max-width: $sncf-small-screen) {
    .l-clevy-chatbox {
        left: 0 !important;
        right: 0 !important;
    }

    .l-clevy-chatbox--visible .l-clevy-chatbox__popup {
        max-height: unset;
    }
}

.w-3rem {
    width: 3rem;
}
</style>
