import sectionTemplates from './Sections/templates';

const templates = {
    empty: {
        name: 'Empty',
        image: 'empty.png',
        sections: [],
    },

    welcome: {
        name: 'Welcome',
        image: 'welcome.png',
        sections: [
            sectionTemplates.VideoHeaderSection,
            sectionTemplates.TextImgRightSection,
            sectionTemplates.CarouselSection,
            sectionTemplates.CardVideoRightSection,
            sectionTemplates.CardImgLeftSection,
            sectionTemplates.StatisticsSection,
        ],
    },

    documents: {
        name: 'Documents & useful links',
        image: 'documents.png',
        sections: [
            sectionTemplates.DoubleCardImgLeftSection,
            sectionTemplates.DoubleCardImgLeftSection,
            sectionTemplates.QuoteSection,
            sectionTemplates.TripleCardImgTopSection,
            sectionTemplates.SocialNetworksSection,
        ],
    },
};

export default templates;
