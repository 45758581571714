<template>
    <div v-if="companyUser && companyUser.isLoaded() && permissionsLoaded">
        <!-- PERSONAL INFO / CONFIDENTIAL INFO -->
        <ProfileAdministrativePersonal
            v-if="canShowPersonalInformation"
            :company-user="companyUser"
        />

        <!-- REQUIREMENTS -->
        <UserRequirementProfile
            v-if="$canRead('AbstractProfileRequirements', {company_user_id: companyUser.id})"
            :company-user="companyUser"
            :company-user-program-id="companyUserProgramId"
        />

        <!-- CONTRACT / CONFIDENTIAL INFO -->
        <ProfileAdministrativeContract
            v-if="$canRead('AbstractProfileContract', {company_user_id: companyUser.id}) || $canRead('AbstractProfileProbation', {company_user_id: companyUser.id})"
            :company-user="companyUser"
        />

        <!-- CONFIDENTIAL INFO / COMPENSATION-->
        <ProfileAdministrativeConfidential
            v-if="$canRead('ModelCompanyUserConfidential', {company_user_id: companyUser.id})"
            :company-user-id="companyUser.id"
        />
    </div>
</template>

<script>
import UserRequirementProfile from '@/components/pages/profile/administrative/UserRequirementProfile.vue';
import ProfileAdministrativeContract from '@/components/pages/profile/administrative/ProfileAdministrativeContract.vue';
import ProfileAdministrativeConfidential from '@/components/pages/profile/administrative/ProfileAdministrativeConfidential.vue';
import ProfileAdministrativePersonal from '@/components/pages/profile/administrative/ProfileAdministrativePersonal.vue';
import CompanyUser from '@/models/CompanyUser';
import ProfileBlocMixin from '@/components/mixins/ProfileBlocMixin';

export default {
    name: 'ProfileAdministrative',
    permissions: [
        'ModelCompanyUserConfidential',
        'AbstractProfilePersonal',
        'AbstractProfileContract',
        'AbstractProfileRequirements',
        'AbstractProfileProbation',
        'AbstractProfileUserPersonalInformation',
        'ModelCompanyUser',
    ],

    components: {
        ProfileAdministrativePersonal,
        ProfileAdministrativeContract,
        ProfileAdministrativeConfidential,
        UserRequirementProfile,
    },
    mixins: [ProfileBlocMixin],

    props: {
        companyUser: {
            type: CompanyUser,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
    },

};
</script>
