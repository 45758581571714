<template>
    <header
        class="headbar"
        :style="headbarStyle"
    >
        <div class="headbar-shadow" />
        <div class="position-relative">
            <HeadBarMenu
                @on-change-page="onChangePage"
            />
        </div>
        <div class="d-none d-md-block position-relative">
            <HeadBarBody
                :key="reloadHeadBarBody"
            />
        </div>
    </header>
</template>

<script>
import HeadBarMenu from './HeadBarMenu.vue';
import HeadBarBody from './HeadBarBody.vue';

export default {
    name: 'HeadBar',
    shared: {
        session: {},
    },
    components: {
        HeadBarMenu, HeadBarBody,
    },
    data() {
        return {
            reloadHeadBarBody: 0,
        };
    },
    computed: {
        headbarStyle() {
            const image = this.$Utils.resolveS3PublicImage(this.shared.session.company.cover);

            return {
                backgroundImage: `url(${image})`,
            };
        },
        image() {
            return {
                firstname: this.shared.session.companyUser.firstname,
                lastname: this.shared.session.companyUser.lastname,
                image: this.shared.session.companyUser.image,
            };
        },
    },
    methods: {
        onChangePage() {
            // forces the reload of the HeadBarBody to avoid residual div (e.g. the initials of a user's program)
            this.reloadHeadBarBody += 1;
        },
    },
};
</script>

<style lang="scss" scoped src="./HeadBar.scss"></style>
