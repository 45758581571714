<template>
    <div class="question-simple">
        <form class="form-2">
            <div
                v-if="!isCompleted && controlAnswer"
                class="question-simple-add"
                @click="addAnswer"
            >
                <div class="question-simple-add-button">
                    <FontAwesomeIcon
                        icon="plus"
                        class="icon-plus"
                    />
                </div>

                <div class="question-simple-add-text">
                    <t>Add an answer</t>
                </div>
            </div>

            <div
                v-for="(answer, index) in value"
                :key="index"
                class="question-simple-item"
            >
                <div v-show="isCollapsed || (!isCollapsed && index < limitAnswers)">
                    <HtFormInput
                        :id="`${fieldNameChoice}_${questionIndex}_${index}`"
                        v-model="answer.translations.text[currentLanguage.key]"
                        v-validate.disable="isDefaultLangSelected ? 'required' : ''"
                        :name="`${fieldNameChoice}-${questionIndex}-${index}`"
                        :placeholder="getPlaceholderAnswers[index]"
                        :type="'text'"
                        :cypress="`survey-answer-simple-choice-${index}`"
                        :disabled="isCompleted || Boolean(answer.company_entity_value_id)"
                        :data-vv-as="translate(`answer {number}`, {number: index + 1})"
                    >
                        <template
                            v-if="!isCompleted && controlAnswer"
                            #extra
                        >
                            <div
                                class="trash-wrapper"
                                @click="deleteAnswer(index)"
                            >
                                <FontAwesomeIcon
                                    size="1x"
                                    :icon="['far', 'trash-alt']"
                                    class="trash-wrapper-icon"
                                />
                            </div>
                        </template>
                    </HtFormInput>

                    <LogicalJump
                        v-if="showNextQuestionSelect"
                        v-model="answer.next_content_order"
                        :contents="contents"
                        :language="currentLanguage.key"
                        :question-index="questionIndex"
                    />
                </div>
            </div>

            <HtLessMore
                v-show="value.length > limitAnswers"
                v-model="isCollapsed"
                :label-less="translate('Hide answers')"
                :label-more="translate('Show all answers')"
            />
        </form>
    </div>
</template>

<script>
import CompanyLanguage from '@/models/CompanyLanguage';
import LogicalJump from '@/components/pages/resources/surveys/form/question/list/LogicalJump.vue';
import HtLessMore from '@/components/globals/HtLessMore.vue';
import QuestionChoiceMixin from '@/mixins/QuestionChoiceMixin';
import HtFormInput from '@/components/globals/HtFormInput.vue';

export default {
    name: 'QuestionSimpleChoice',
    components: {
        HtFormInput,
        LogicalJump,
        HtLessMore,
    },

    mixins: [
        QuestionChoiceMixin,
    ],
    inject: ['$validator'],

    props: {
        value: {
            type: Array,
            required: true,
        },
        question: {
            type: Object,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
        contents: {
            type: Array,
            required: true,
        },
        currentLanguage: {
            type: CompanyLanguage,
            required: true,
        },
        questionIndex: {
            type: Number,
            required: true,
        },
        isCompleted: {
            type: Boolean,
            required: false,
            default: false,
        },
        controlAnswer: {
            type: Boolean,
            default: true,
        },
        showNextQuestionSelect: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            fieldNameChoice: 'simple-answer',
        };
    },

    computed: {
        getPlaceholderAnswers() {
            const aPlaceholders = [];

            this.value.forEach((element, index) => {
                if (this.isDefaultLangSelected === false && element.translations.text[this.shared.session.company.company_language.key]) {
                    aPlaceholders.push(element.translations.text[this.shared.session.company.company_language.key]);
                } else {
                    aPlaceholders.push(`${this.translate('Answer title')} ${index + 1}`);
                }
            });

            return aPlaceholders;
        },
    },
};
</script>

<style lang="scss" scoped src="./QuestionSimpleChoice.scss"></style>
