<template>
    <RoundEmptyBackground>
        <FontAwesomeIcon
            size="1x"
            icon="check"
        />
    </RoundEmptyBackground>
</template>

<script>
import RoundEmptyBackground from './RoundEmptyBackground.vue';

export default {
    name: 'CheckGreenIcon',
    components: { RoundEmptyBackground },

};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.wrapper {
    background-color: $green;
    color: white;
    border: none;
}
</style>
