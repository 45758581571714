<template>
    <HoldingResourceList
        :route-list="'HoldingResourcesRequirementsList'"
        :collection="requirements"
        :use-resource-program="false"
    >
        <template #table="holdingResourceList">
            <RequirementsList
                :items="holdingResourceList.items"
                :external-search="holdingResourceList.search"
                @onCellClicked="holdingResourceList.onCellClicked"
            />
        </template>
    </HoldingResourceList>
</template>
<script>
import HoldingResourceList from '@/components/globals/HoldingResourceList.vue';
import CompanyResourceProgramCollection from '@/models/CompanyResourceProgramCollection';
import RequirementsList, { requirementConfig } from '@/resourcesList/RequirementsList.vue';

export default {
    name: 'PageRequirementsList',
    components: {
        HoldingResourceList,
        RequirementsList,
    },

    data() {
        return {
            requirements: new CompanyResourceProgramCollection(requirementConfig.fields)
                .with(requirementConfig.relations)
                .whereHas({
                    requirementCategory: (query) => query.where(['deleted_at', '=', null]),
                })
                .where(['is_enabled', '=', true]),
        };
    },
};
</script>
