<script>
import CompanyUserFlow from '@/models/CompanyUserFlow';
import CompanyUser from '@/models/CompanyUser';

export default {
    props: {
        is_forward: {
            type: Boolean,
            default: true,
        },
        value: {
            type: CompanyUserFlow,
            required: true,
        },
        bubbleUser: {
            type: Object,
            required: true,
        },
        companyUser: {
            type: CompanyUser,
            required: true,
        },
        contents: {
            type: Array,
            required: true,
        },
    },

    computed: {
        /**
             * @returns {string}
             */
        getUserLanguage() {
            const aFallback = this.shared.session.company.company_languages.enabled.map((item) => item.key);// list of language handle by the system of ht_locale
            if (!aFallback.includes(this.companyUser.company_language.key)) {
                return 'en';
            }

            return this.companyUser.company_language.key;
        },
    },

    methods: {
        /**
             * @return {void}
             */
        onValidate() {
            this.$emit('onNextPage');
        },
        getTranslatedMessage(message) {
            const currentLang = this.getUserLanguage;
            if (!(`message_${currentLang}` in message)) {
                return message.message_en;
            }

            return message[`message_${currentLang}`];
        },
    },
};
</script>
