import Model from '@tony.caron/node-model-proxy/Model';
import CompanyFile from './CompanyFile';
import CompanyProgramNotificationTemplateLocaleCollection from './CompanyProgramNotificationTemplateLocaleCollection';
import CompanyLanguage from './CompanyLanguage';

export default class CompanyProgramNotificationTemplateLocale extends Model {
    modelConfig() {
        return {
            collection: CompanyProgramNotificationTemplateLocaleCollection,
        };
    }

    modelRelations() {
        return {
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
            companyLanguage: () => this.belongsTo(CompanyLanguage, 'company_file_id', 'id'),
        };
    }
}
