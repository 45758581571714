<template>
    <HtTable
        :items="items"
        :columns="columns"
        cypress="action-resource-table"
        :empty-message="'There are no training to display yet...'"
        :external-search="externalSearch"
        @onCellClicked="$emit('onCellClicked', $event)"
    >
        <template #cell(type)="scope">
            <div class="column-title">
                {{ translate($Utils.capitalize(scope.item.type)) }}
            </div>
        </template>
    </HtTable>
</template>
<script>
import HtTable from '@/components/globals/HtTable.vue';
import ParticipantList from '@/components/globals/ParticipantList.vue';
import ResourceListMixin from '@/mixins/ResourceListMixin';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';

export default {
    name: 'TrainingsLIst',
    components: { HtTable, ParticipantList },
    mixins: [
        ResourceListMixin,
    ],
    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'resource.name',
                    class: 'font-weight-bold',
                    search: true,
                },
                {
                    label: this.translate('Type'),
                    key: 'type',
                    search: false,
                },
                {
                    label: this.translate('Program'),
                    key: 'resource.programs.models[0].program.name_translated',
                    sortable: false,
                    class: 'hide-mobile',
                    search: false,
                },
            ],
        };
    },
};

export const trainingConfig = {
    fields: [
        'id',
        'type',
        'url',
        'order',
    ],
    relations: {
        resource: (query) => {
            query
                .select([
                    'id',
                    'name',
                    'description',
                ])
                .with({
                    programs: (query) => {
                        query.select([
                            'id',
                            'company_program_id',
                            'resourceable_id',
                            'resourceable_type',
                            'is_enabled',
                        ]).with({
                            dependencies: (query) => {
                                query.select([
                                    'id',
                                    'resourceable_type',
                                ]);
                            },
                            dependents: (query) => {
                                query.select([
                                    'id',
                                ]);
                            },
                            program: (query) => {
                                query.select(['id']).with({
                                    locales: (query) => {
                                        query.select(['id', 'language_key', 'name']);
                                    },
                                    keyDate: (query) => query.with({
                                        locales: (query) => query
                                            .select(['id', 'name', 'language_key']),
                                    }),
                                });
                            },
                        });
                    },
                });
        },
        companyFiles: (query) => {
            query.select(UtilsCompanyFile.allowedFullFields());
        },

        companyProgramResourceFilter: (query) => {
            query.select([
                'id',
                'company_entity_id',
                'company_entity_value_id',
                'component_name',
                'resource_id',
                'group',
                'name',
            ]);
            query.with({
                value: (query) => {
                    query.select([
                        'id',
                        'company_entity_id',
                        'resourceable_type',
                        'resourceable_id',
                    ]).with({
                        jobPosition: (query) => {
                            query.select([
                                'id',
                                'name',
                                'is_heyteam',
                            ]);
                        },
                        contract: (query) => {
                            query.select([
                                'id',
                                'name',
                                'is_heyteam',
                            ]);
                        },
                        office: (query) => {
                            query.select([
                                'id',
                                'name',
                                'is_heyteam',
                            ]);
                        },
                        department: (query) => {
                            query.select([
                                'id',
                                'name',
                                'is_heyteam',
                            ]);
                        },
                        custom: (query) => {
                            query.select([
                                'id',
                                'key',
                                'name',
                            ]);
                        },
                    });
                },
                entity: (query) => {
                    query.select([
                        'id',
                        'name',
                        'slug',
                        'is_enabled',
                        'is_heyteam',
                    ]);
                },
            });
        },
    },
};

</script>
