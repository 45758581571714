import api from '@/store/api';
import get from 'lodash.get';

import programs from '@/store/modules/dashboard/programs.store';
import statistics from '@/store/modules/dashboard/statistics.store';
import actions from '@/store/modules/dashboard/actions.store';
import cloneDeep from 'lodash.clonedeep';

const EXPIRATION_IN_SECONDS = 5 * 60;

let timeout = null;

export function fetchData({ commit, state }, {
    loadedKey, apiMethod, responseKey, mutation,
}) {
    if (state[loadedKey]) return;
    if (typeof timeout === 'number') {
        clearTimeout(timeout);
        timeout = null;
    }
    return api.dashboard[apiMethod]({
        filters: state.filters,
    }).then((response) => {
        commit(mutation, get(response, responseKey));
        if (typeof timeout === 'number') {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(commit, EXPIRATION_IN_SECONDS * 1000, mutation);
    });
}

export function storeData(state, loadedKey, dataKey, data) {
    state[loadedKey] = (data !== undefined);
    if (data === undefined) return;
    state[dataKey] = data;
}

export default {
    modules: {
        programs,
        statistics,
        actions,
    },
    namespaced: true,

    actions: {
        transferFiltersToPrograms({ state, dispatch }) {
            dispatch('programs/setFilters', cloneDeep(state.statistics.filters));
        },
    },
};
