<template>
    <div>
        <HtFormSwitch
            id="isRecurrent"
            v-model="isRecurrent"
            :label="translate('Enable resource recurrence')"
            :description="translate('A new occurrence of the resource will be added after the defined delay')"
            name="isRecurrent"
            :show-optional="false"
            @input="toggleRecurrent"
        />

        <div v-show="isRecurrent" class="container-row">
            <HtInputText
                id="recurrence_frequence_value"
                :disabled="!isRecurrent"
                class="container-row-input"
                name="recurrence_frequence_value"
                :value="innerRecurrence.recurrence_frequence_value"
                type="number"
                @input="(value) => updateRecurrence('recurrence_frequence_value', value)"
            />

            <HtFormSelector
                id="recurrence_frequence_unit"
                :disabled="!isRecurrent"
                class="container-row-input"
                name="recurrence_frequence_unit"
                :is-single="true"
                :options="frequence_unit_options"
                track-by="value"
                specific-key="value"
                label-options="label"
                :value="innerRecurrence.recurrence_frequence_unit"
                @input="(value) => updateRecurrence('recurrence_frequence_unit', value)"
            />
        </div>

        <!-- TODO : Implement end of recurrence selector based on keyDate selector -->
        <!-- <HtFormSwitch
            id="haveRecurrenceEnd"
            v-model="haveRecurrenceEnd"
            :label="translate('End of recurrence')"
            name="haveRecurrenceEnd"
            :show-optional="false"
        />
        <div class="container-row">
            <HtKeyDatesInputOrder
                :program-id="item.resource.programs[0].company_program_id"
                :resource-id="item.id"
                :resource-type="resourceableClass"
                :filters="item.resource.programs[0].filters"
                :is-business-day.sync="item.resource.programs[0].is_business_day"
                :offset-key-date.sync="item.resource.programs[0].company_program_key_date_id"
                :offset-availability-number.sync="item.resource.programs[0].offset_availability_number"
                :offset-availability-unit.sync="item.resource.programs[0].offset_availability_unit"
                :offset-end-number.sync="item.resource.programs[0].offset_end_number"
                :offset-end-unit.sync="item.resource.programs[0].offset_end_unit"
                :dependents.sync="item.resource.programs[0].dependents"
                :dependencies.sync="item.resource.programs[0].dependencies"
            />
        </div> -->
    </div>
</template>

<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtInputText from '@/components/globals/HtInputText.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';

export default {
    name: 'RecurrenceConfig',
    components: {
        HtFormSwitch,
        HtInputText,
        HtFormSelector,
    },
    props: {
        recurrence: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            innerRecurrence: this.recurrence || {
                recurrence_frequence_value: null,
                recurrence_frequence_unit: null,
            },
            isRecurrent: !!this.recurrence?.recurrence_frequence_value || false,
            haveRecurrenceEnd: false,
            frequence_unit_options: [
                { label: this.translate('days'), value: 'days' },
                { label: this.translate('weeks'), value: 'weeks' },
                { label: this.translate('months'), value: 'months' },
                { label: this.translate('years'), value: 'years' },
            ],
        };
    },

    watch: {
        recurrence: {
            handler(newRecurrence) {
                this.innerRecurrence = newRecurrence || {
                    recurrence_frequence_value: null,
                    recurrence_frequence_unit: null,
                };
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        updateRecurrence(key, value) {
            this.innerRecurrence[key] = value;
            this.$emit('update', this.innerRecurrence);
        },

        toggleRecurrent() {
            if (!this.isRecurrent) {
                this.$emit('update', null);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.container-row {

    display: flex;
    gap: 5px;

    &-input {
        flex: 3 1 0;
        height: 100%;
    }

    &-input:first-child {
        flex: 1 1 0;
    }

}

</style>
