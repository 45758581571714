<template>
    <div class="list-answer">
        <div
            v-if="companySurveyQuestionAnswerCollection.models.length > 5"
            class="form-2"
        >
            <HtFormMultiSelect
                :id="`${companyChatChannelMessage.id}-multiple-choice`"
                v-model="selected"
                class="list-answer-select"
                :disabled="readOnly"
                :placeholder="translate('Select answers')"
                :options="getOptionsAnswers"
                :name="`${companyChatChannelMessage.id}-multiple-choice`"
                @open="$emit('focus')"
                @close="$emit('blur')"
            />
        </div>
        <div
            v-for="item in companySurveyQuestionAnswerCollection.models"
            v-else
            :key="item.id"
            :class="customClass(item)"
            class="list-answer-item"
            @click="toggleItem(item.id)"
        >
            <div class="list-answer-item-checkbox">
                <input
                    type="checkbox"
                    name="answer-item"
                    :checked="isActive(item.id) || isChecked(item.id)"
                    :disabled="readOnly"
                >
            </div>
            <div class="list-answer-item-name">
                {{ getAnswerChoice(item) }}
            </div>
        </div>
        <div
            v-if="readOnly === false"
            class="list-answer-actions"
        >
            <div
                v-if="isNotMandatory"
                class="list-answer-actions-skip"
                @click="skip()"
            >
                <t>Skip</t>
            </div>
            <Button
                :state="buttonState"
                @click="validateAnswers"
            >
                <t>Validate</t>
            </Button>
        </div>
    </div>
</template>

<script>
import CompanyUserSurveyQuestionAnswerCollection from '@/models/CompanyUserSurveyQuestionAnswerCollection';
import MessageResourceUserSurveyQuestionMixin from './mixin/MessageResourceUserSurveyQuestionMixin';

export default {
    name: 'MessageResourceUserSurveyQuestionMultipleChoice',
    mixins: [
        MessageResourceUserSurveyQuestionMixin,
    ],
    data() {
        return {
            ids: [],
            selected: [],
        };
    },
    computed: {
        buttonState() {
            return this.ids.length > 0 ? 'idle' : 'disabled';
        },

        getOptionsAnswers() {
            return this.companySurveyQuestionAnswerCollection.models.map((answer) => {
                const name = (answer.company_entity_value_id) ? answer.value.custom.name : answer.text;
                return {
                    id: answer.id,
                    name,
                };
            });
        },
    },
    watch: {
        selected() {
            this.ids = this.selected.map((i) => i.id);
        },
    },
    created() {
        const ids = this.companyUserSurveyQuestionAnswerCollection.models.map((i) => i.company_survey_question_answer_id);

        this.selected = this.companySurveyQuestionAnswerCollection.models.filter((answer) => ids.includes(answer.id));
    },
    methods: {
        isChecked(companySurveyQuestionAnswerId) {
            const index = this.ids.indexOf(companySurveyQuestionAnswerId);
            return index !== -1;
        },
        toggleItem(companySurveyQuestionAnswerId) {
            if (this.readOnly) return;

            const index = this.ids.indexOf(companySurveyQuestionAnswerId);

            if (index > -1) {
                this.ids.splice(index, 1);
                return;
            }

            this.ids.push(companySurveyQuestionAnswerId);
        },
        validateAnswers() {
            if (this.readOnly) return;

            const companyUserSurveyQuestionAnswerCollection = new CompanyUserSurveyQuestionAnswerCollection([
                'company_survey_question_answer_id',
            ]);

            this.ids.forEach((id) => {
                const answer = companyUserSurveyQuestionAnswerCollection.new();
                answer.company_survey_question_answer_id = id;
                companyUserSurveyQuestionAnswerCollection.models.push(answer);
            });

            this.$emit('onAnswers', companyUserSurveyQuestionAnswerCollection);

            this.ids = [];
        },
        customClass(companySurveyQuestionAnswer) {
            return {
                disabled: this.isActive(companySurveyQuestionAnswer.id) && this.readOnly,
                'cursor-pointer': this.readOnly === false,
            };
        },

        getAnswerChoice(answer) {
            if (answer.company_entity_value_id) {
                return answer.value.custom.name;
            }

            return answer.text;
        },
    },
};
</script>

<style lang="scss" scoped src="./MessageResourceUserSurveyQuestionMultipleChoice.scss"></style>
