<template>
    <div>
        <div id="login-page">
            <div
                class="login-background"
                style="background: url('/static/images/maintenance/bg-maintenance-side.png') right center no-repeat, rgb(255, 96, 88);"
            />
            <div id="login-content">
                <div>
                    <img
                        src="/static/images/maintenance-boy.svg"
                        alt="maintenance logo"
                    >
                    <div class="login-component">
                        <h1
                            class="align-center mb-0"
                            style="font-size: 24px;"
                        >
                            <t>Ongoing maintenance</t>
                        </h1>

                        <h1
                            class="align-center mb-0"
                            style="font-size: 16px;"
                        >
                            <t>Please try again later</t>
                        </h1>
                    </div>
                </div>
                <div>
                    <button
                        type="button"
                        size="small"
                        class="button-login mt-5"
                        style="background-color: #FCC200"
                        data-cy="maintenance-next"
                        :disabled="isRefreshing"
                        @click="refresh"
                    >
                        <t v-if="isRefreshing">
                            Refreshing...
                        </t>
                        <t v-else>
                            Refresh
                        </t>
                    </button>
                </div>
            </div>
            <div class="by-heyteam">
                <img src="/static/images/by-heyteam.svg">
            </div>
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'Maintenance',
    components: {
        HtButton,
    },
    data() {
        return {
            isRefreshing: false,
        };
    },
    methods: {
        async refresh() {
            this.isRefreshing = true;

            try {
                await this.$http.get('/health');
                this.$router.push({ name: 'Login' });
            } catch (error) {
                this.$Notifier('App').showError(this.translate('The service is unavailable.'));
            } finally {
                this.isRefreshing = false;
            }
        },
    },
};
</script>

<style lang="scss" src="../styles/login.scss" scoped></style>
<style lang="scss" scoped>
#login-page .login-component {
    padding-top: 20px;
}

#login-page #login-content img {
    max-height: none;
}
</style>
