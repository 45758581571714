<script>
export default {
    name: 'Openid',

    mounted() {
        opener.postMessage(this.$route.query, '*');

        window.close();
    },
};
</script>
