<template>
    <PageStepperLayout
        :page-subtitles="pageSubtitles"
    >
        <ProgramForm
            :id="Number($route.params.id) || null"
            @on-close-stepper="$router.push({ name: 'Programs' })"
        />
    </PageStepperLayout>
</template>

<script>
import PageStepperLayout from '@/layouts/PageStepperLayout.vue';
import ProgramForm from '@/components/pages/program/form/ProgramForm.vue';

export default {
    name: 'PageProgramForm',
    components: { PageStepperLayout, ProgramForm },
    data() {
        return {
            pageSubtitles: [this.translate('Program')],
        };
    },
};
</script>
