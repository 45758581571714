<template>
    <div>
        {{ userSurveyQuestionAnswer.models[0].text }}
    </div>
</template>
<script>
import CompanyUserSurveyQuestionAnswerCollection from '@/models/CompanyUserSurveyQuestionAnswerCollection';

export default {
    name: 'ResultText',
    props: {
        userSurveyQuestionAnswer: {
            type: CompanyUserSurveyQuestionAnswerCollection,
            required: true,
        },
    },
};
</script>
