<template>
    <div v-if="is_loaded">
        <ErrorDisplay />

        <fieldset class="switch-label">
            <HtFormSwitch
                :id="'is-enabled-sms'"
                v-model="item.is_enabled_sms"
                :label="translate('Enable this text message')"
                :disabled="!item.is_editable_enabled_sms"
                :on-label="translate('On')"
                :off-label="translate('Off')"
                :name="'is-enabled-sms'"
                :show-optional="false"
                class="mt-5"
            />
        </fieldset>

        <template v-if="item.is_enabled_sms">
            <HtLanguageWithOptionsSelect
                id="language"
                v-model="language"
                :name="'language'"
                :options="availableLanguages"
            />

            <fieldset :class="{ error: errors.has('content_sms') }">
                <HtFormEditor
                    :id="'content_sms'"
                    v-model="item[`content_sms_${language}`]"
                    :name="'content_sms'"
                    :label="translate('Sms content')"
                    :variables="getVariablesOfField(item.variables, 'content', 'sms')"
                    :has-toolbar="false"
                >
                    <template #extra>
                        <div class="counter-container">
                            <div>
                                <t :count="countCharacter">
                                    {count}/160 character | {count}/160 characters
                                </t>
                            </div>
                            <div>
                                <t :count="countSms">
                                    {count} text message | {count} text messages
                                </t>
                            </div>
                        </div>
                    </template>
                </HtFormEditor>
            </fieldset>

            <div class="tooltip-container">
                <div class="icon-question">
                    <FontAwesomeIcon icon="info-circle" />
                </div>
                <div class="text">
                    <t>The text message will be sent to users having activated their notifications by text message in their parameters of personal notification</t>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import HtLanguageWithOptionsSelect from '@/components/globals/HtLanguageWithOptionsSelect.vue';
import NotificationVariableMixin from '@/mixins/NotificationVariableMixin';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'SmsEdit',
    components: {
        HtLanguageWithOptionsSelect,
        HtFormSwitch,
    },
    mixins: [
        NotificationVariableMixin,
    ],

    props: {
        value: { type: Object },
    },

    data() {
        return {
            is_loaded: false,
            item: null,
            language: 'en',
            availableLanguages: [
                {
                    value: 'fr',
                    text: this.translate('The content will be in {language}', { language: 'french' }),
                    codeFlag: 'fr',
                },
                {
                    value: 'en',
                    text: this.translate('The content will be in {language}', { language: 'english' }),
                    codeFlag: 'gb',
                },
            ],
        };
    },

    computed: {
        countCharacter() {
            if (this.item[`content_sms_${this.language}`]) {
                const stripedHtml = this.item[`content_sms_${this.language}`].replace(/<[^>]*>?/gm, '');
                return stripedHtml.length;
            }
            return 0;
        },

        countSms() {
            if (this.item[`content_sms_${this.language}`]) {
                const stripedHtml = this.item[`content_sms_${this.language}`].replace(/<[^>]*>?/gm, '');
                return Math.floor(stripedHtml.length / 161) + 1;
            }
            return 1;
        },
    },

    created() {
        this.item = this.value;
        this.errors.clear();

        if (this.shared.session.company.company_language.key === 'fr') {
            this.language = 'fr';
        }

        if (this.item && this.item.order != null) {
            this.item.order = Math.abs(this.item.order);
        }

        this.is_loaded = true;
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	.tooltip-container {
		display: flex;
		justify-content: space-between;

		svg {
			color: $grey-dark;
			cursor: default;
		}

		.text {
			margin-left: 10px;
			text-align: justify;
			font-size: 1em;
			color: $grey-dark
		}
    }

    .counter-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 5px;
        font-size: .9em;
        color: $grey-dark
    }

</style>
