$.extend(
    $.easing,
    {
        def: 'easeOutQuad',

        easeOutExpo(x, t, b, c, d) {
            return (t == d) ? b + c : c * (-(2 ** (-10 * t / d)) + 1) + b;
        },
    },
);
