<template>
    <div class="perimeters-container">
        <div class="mb-5">
            <b><t>Roles and Perimeters</t></b>
        </div>
        <RoleWithPerimeter
            v-for="role in mainRoles"
            :key="role.id"
            class="mb-3"
            :data="role"
        />
        <div
            v-if="rolesCountRemaining > 0 && !showAdditionalRoles"
            class="d-flex justify-content-center cursor-pointer branding-color"
            :class="showAdditionalRoles ? 'mb-3' : ''"
            @click="toggleAdditionalRoles"
        >
            <t :count="rolesCountRemaining">
                +{count} role | +{count} roles
            </t>
            <FontAwesomeIcon
                class="ml-2 mt-1"
                :icon="['fa', 'chevron-down']"
            />
        </div>
        <template v-if="showAdditionalRoles">
            <RoleWithPerimeter
                v-for="role in additionalRoles"
                :key="role.id"
                class="mb-3"
                :data="role"
            />

            <div
                class="d-flex justify-content-center cursor-pointer branding-color"
                @click="toggleAdditionalRoles"
            >
                <t>Hide additional roles</t>
                <FontAwesomeIcon
                    class="ml-2 mt-1"
                    :icon="['fa', 'chevron-up']"
                />
            </div>
        </template>
    </div>
</template>

<script>
import RoleWithPerimeter from './RoleWithPerimeter.vue';

export default {
    name: 'ShowRolesWithPerimeters',
    components: { RoleWithPerimeter },

    mixins: [],
    props: ['data'],

    data() {
        return {
            showAdditionalRoles: false,
        };
    },

    computed: {
        // Take from 0 to 2 roles
        mainRoles() {
            return this.data.slice(0, 2);
        },
        // Take from 2 to the end of all roles
        additionalRoles() {
            return this.data.slice(2);
        },
        rolesCountTotal() {
            return this.data.length;
        },
        rolesCountRemaining() {
            return this.rolesCountTotal > 2 ? this.rolesCountTotal - 2 : 0;
        },
    },

    methods: {
        toggleAdditionalRoles() {
            this.showAdditionalRoles = !this.showAdditionalRoles;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "~@/styles/var";

    .perimeters-container {
        background-color: $blue-light;
        padding: 20px;
        border-radius: $border-radius-5;
    }
</style>
