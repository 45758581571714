<template>
    <HtCard>
        <LanguageList />
    </HtCard>
</template>

<script>
import LanguageList from '@/components/resources/language/LanguageList.vue';

export default {
    name: 'PageLanguage',
    components: {
        LanguageList,
    },
};
</script>
