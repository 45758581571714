<template>
    <div>
        <SectionBar
            :title="translate('Language')"
            :has-add-button="false"
        />
        <p class="title-text">
            <t>Choose the languages in which you want to be able to create resources. Onboardees will be able to choose their preferred language when they accept their invitation to the platform.</t>
        </p>
        <HtFormLanguageSelector
            v-if="companyLanguage.$.length"
            id="select-languages"
            :value="selectedLanguages"
            label=""
            description=""
            placeholder=""
            name="selectedLanguages"
            :allow-empty="false"
            :options="languageOptions"
            @input="toggleLanguage"
        />
    </div>
</template>

<script>
import HtFormLanguageSelector from '@/components/globals/Selectors/HtFormLanguageSelector.vue';

export default {
    name: 'LanguageList',
    components: { HtFormLanguageSelector },
    data() {
        return {
            hasClicked: false,
            companyLanguage: this.$ModelStore.get('Company').company_languages,
        };
    },
    computed: {
        languageOptions() {
            return this.companyLanguage?.$?.models?.map?.((language) => ({
                id: language.id,
                name: language.name,
                codeFlag: language.codeFlag,
                is_enabled: language.is_enabled,
                is_deletable: !language.is_default,
            }));
        },
        selectedLanguages() {
            return this.languageOptions?.filter?.((language) => language.is_enabled);
        },
    },
    methods: {
        toggleLanguage(selectedLanguages) {
            for (let i = 0; i < this.companyLanguage.$.models.length; i += 1) {
                const currentItem = this.companyLanguage.$.models[i].active.fromSaveState();
                const itemSelectedOption = selectedLanguages.find(
                    (language) => language.id === currentItem.id,
                );
                if (itemSelectedOption && !currentItem.is_enabled) {
                    currentItem.is_enabled = true;
                    currentItem.save();
                } else if (!itemSelectedOption && currentItem.is_enabled) {
                    currentItem.is_enabled = false;
                    currentItem.save();
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
    .title-text {
        margin: 2em 0;
    }

    .wrapper-title {
        display:flex;
        align-items:center;
    }

    .margin-l-1 {
        margin-left: 1em;
    }
</style>
