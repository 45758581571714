import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyRequirement from './CompanyRequirement';

export default class CompanyRequirementCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyRequirement,
        };
    }
}
