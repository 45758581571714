<template>
    <div>
        <form
            class="form-2 spaced"
            @submit.prevent=""
        >
            <HtFormRange
                id="length"
                v-model="item.password_length"
                name="length"
                :label="translate('Length')"
                :min="getMinPasswordLength"
                :step="1"
                :max="15"
            />
            <HtFormRange
                id="digits"
                v-model="item.password_numbers"
                name="digits"
                :label="translate('Digits')"
                :min="1"
                :step="1"
                :max="item.password_length - item.password_symbols"
            />
            <HtFormRange
                id="symbols"
                v-model="item.password_symbols"
                name="symbols"
                :label="translate('Symbols')"
                :min="1"
                :max="item.password_length - item.password_numbers"
                :step="1"
            />
            <HtFormRange
                id="attempts"
                v-model="item.password_attempts"
                name="attempts"
                :label="translate('Attempts')"
                :min="3"
                :step="1"
                :max="10"
            />

            <HtFormRange
                id="attempts"
                v-model="item.password_reset_attempts"
                name="password_reset_attempts"
                :label="translate('Password reset attempts')"
                :min="1"
                :step="1"
                :max="10"
            />
        </form>
        <div class="modal-actions right">
            <Button @click="onSave()">
                <t>Save</t>
            </Button>
        </div>
    </div>
</template>

<script>
import CompanySecurity from '@/models/CompanySecurity';

export default {
    name: 'SecurityPasswordEdit',
    inject: ['modal'],

    props: {
        item: {
            type: CompanySecurity,
            required: true,
        },
    },

    computed: {
        getMinPasswordLength() {
            return this.item.password_symbols + this.item.password_numbers > 8
                ? this.item.password_symbols + this.item.password_numbers : 8;
        },
    },
    created() {
        this.item.fromSaveState();
    },

    methods: {
        async onSave() {
            await this.item.save();
            this.modal.close();
        },
    },
};
</script>
