<template>
    <HtTable
        :items="items"
        :columns="columns"
        cypress="action-resource-table"
        :empty-message="'There are no event to display yet...'"
        :external-search="externalSearch"
        @onCellClicked="$emit('onCellClicked', $event)"
    >
        <template #cell(status)="scope">
            <HtTag
                class="status"
                :color="statusColor(scope.item.status())"
            >
                <t>
                    {{ scope.item.status() }}
                </t>
            </HtTag>
        </template>
    </HtTable>
</template>
<script>
import HtTable from '@/components/globals/HtTable.vue';
import ResourceListMixin from '@/mixins/ResourceListMixin';
import HtTag from '@/components/globals/HtTag.vue';

export default {
    name: 'EventsListModels',
    components: { HtTable, HtTag },

    mixins: [
        ResourceListMixin,
    ],
    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    class: 'font-weight-bold',
                    key: 'resource.name',
                    sortDirection: 'asc',
                    search: true,
                },
                {
                    label: this.translate('Status'),
                    key: 'status',
                },
                {
                    label: this.translate('Program'),
                    key: 'resource.programs.models[0].program.name_translated',
                    sortable: false,
                    class: 'hide-mobile',
                    search: false,
                },
            ],
        };
    },

    methods: {
        /**
         * @param {string} status
         * @returns {string} color
         */
        statusColor(status) {
            switch (status) {
            case 'draft':
                return 'orange';
            case 'scheduled':
                return 'green';
            case 'delegated':
                return 'purple';
            default:
                throw new Error('wrong status given');
            }
        },
    },
};

export const eventConfig = {
    fields: [
        'id',
    ],
    relations: {
        resource: (query) => query
            .select([
                'id',
                'name',
            ])
            .with({
                programs: (query) => query
                    .select(['id', 'company_program_id', 'company_resource_id', 'is_enabled'])
                    .with({
                        program: (query) => {
                            query
                                .select([
                                    'id',
                                    'name_translated',
                                ])
                                .with({
                                    locales: (query) => {
                                        query.select(['id', 'language_key', 'name']);
                                    },
                                });
                        },
                    }),
            }),
    },
};

</script>
<style lang="scss" scoped>
.status::first-letter {
  text-transform: uppercase;
}
</style>
