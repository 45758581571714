<template>
    <div
        class="item"
        @click="onClick"
    >
        <div class="d-flex align-items-center">
            <div
                v-user-image="userImage"
                class="user-image mr-2"
            />
            <span> {{ firstname }} {{ lastname }} </span>
        </div>
        <div>
            <FontAwesomeIcon :icon="['fal', 'chevron-right']" />
        </div>
    </div>
</template>

<script>
import Utils from '@/Utils';

export default {
    name: 'ResultRequirementItem',
    props: {
        firstname: {
            type: String,
            required: true,
        },
        lastname: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
    },
    computed: {
        userImage() {
            return Utils.getCompanyComponentRoleInitials(
                'user',
                1.5,
                {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    image: this.image,
                },
            );
        },
    },

    methods: {
        onClick() {
            this.$emit('click');
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $blue-light;
    padding: 20px;
    border-radius: $border-radius-8;
}
.user-image {
    border-radius: 50%;
}
</style>
