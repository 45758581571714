<template>
    <div v-if="companyUserProbation.isLoaded()">
        <ProfileBloc
            :is-edit.sync="isEdit"
            :show-bloc="showProfileBloc"
            :can-edit="canUpdate"
            :button-state="buttonState"
            @on-save="onUpdate"
        >
            <div class="profile-row form-2 last">
                <div class="profile-item full-width">
                    <div class="profile-item-title">
                        <t>Initial probationary period</t>
                    </div>
                    <div class="profile-item-content">
                        <ProbationPeriod
                            id="first-probation"
                            :edit_mode="isEdit"
                            :probation.sync="companyUserProbation.first_probation"
                            :probation_end.sync="companyUserProbation.first_probation_end_date"
                            :probation_unit.sync="companyUserProbation.first_probation_unit"
                        />
                    </div>
                </div>
                <div
                    v-if="(!isEdit && companyUserProbation.second_probation) || isEdit"
                    class="profile-item full-width"
                >
                    <div class="profile-item-title">
                        <t>Renewed probationary period</t>
                    </div>
                    <div class="profile-item-content">
                        <ProbationPeriod
                            id="second-probation"
                            :edit_mode="isEdit"
                            :probation.sync="companyUserProbation.second_probation"
                            :probation_end.sync="companyUserProbation.second_probation_end_date"
                            :probation_unit.sync="companyUserProbation.second_probation_unit"
                        />
                    </div>
                </div>
            </div>
        </ProfileBloc>
    </div>
</template>
<script>
import ProbationPeriod from '@/components/ProbationPeriod.vue';
import ProfileBlocMixin from '@/components/mixins/ProfileBlocMixin';
import CompanyUser from '@/models/CompanyUser';
import ProfileBloc from '../ProfileBloc.vue';

export default {
    name: 'ProfileInfoProbationary',
    components: {
        ProfileBloc,
        ProbationPeriod,
    },

    mixins: [ProfileBlocMixin],
    data() {
        return {
            /**
             * Utilisateur avec ses données de période d'essai
             */
            companyUserProbation: new CompanyUser([
                'id',
                'first_probation',
                'first_probation_end_date',
                'first_probation_unit',
                'second_probation',
                'second_probation_end_date',
                'second_probation_unit',
            ])
                .with({
                    companyContract: (query) => {
                        query.select([
                            'id',
                            'name',
                            'is_heyteam',
                            'has_end_date',
                            'has_probation',
                        ]).withTrashed();
                    },
                })
                .where([
                    ['id', '=', this.companyUser.id],
                ]),
        };
    },

    computed: {
        showProfileBloc() {
            return this.companyUserProbation.company_contract !== null && this.companyUserProbation.company_contract.has_probation;
        },

        first_probation_period() {
            return this.companyUserProbation.first_probation_status == null
                ? this.probation_period(this.companyUserProbation.first_probation_end_date, this.companyUserProbation.first_probation_unit)
                : false;
        },

        second_probation_period() {
            return this.companyUserProbation.second_probation_status == null
                ? this.probation_period(this.companyUserProbation.second_probation_end_date, this.companyUserProbation.second_probation_unit)
                : null;
        },

        days_left_in_first_probation_period() {
            const { moment } = this.$Utils;
            const today = moment();
            const endFirstProbationaryDate = moment(this.companyUserProbation.first_probation_end_date);

            return endFirstProbationaryDate.diff(today, 'days');
        },

        days_left_in_second_probation_period() {
            const { moment } = this.$Utils;
            const today = moment();
            const endSecondProbationaryDate = moment(this.companyUserProbation.second_probation_end_date);

            return endSecondProbationaryDate.diff(today, 'days');
        },
    },
    created() {
        this.companyUserProbation.setLookupMethod('lookupProbationPeriod');
        this.companyUserProbation.get();
    },

    methods: {
        probation_period(value, interval) {
            if (!this.companyUserProbation.arrival_date || !value) return false;

            const { moment } = this.$Utils;
            const today = moment();
            const date = moment(value, 'YYYY-MM-DD HH:mm:ss');
            const reminder = moment(value, 'YYYY-MM-DD HH:mm:ss').subtract(interval == 'day' ? 7 : 1, interval);

            return today.isBetween(reminder, date, 'day') ? date.format('DD/MM/YYYY') : false;
        },

        async onUpdate() {
            await this.$validator.validateAll();

            if (this.companyUserProbation.isSaving() || this.errors.any()) {
                return Promise.resolve();
            }

            if (this.companyUserProbation.$.active.first_probation_unit !== null && this.companyUserProbation.$.active.first_probation == null) {
                this.companyUserProbation.$.active.first_probation_unit = null;
                this.companyUserProbation.$.active.first_probation_end_date = null;
            }

            if (this.companyUserProbation.$.active.second_probation_unit !== null && this.companyUserProbation.$.active.second_probation == null) {
                this.companyUserProbation.$.active.second_probation_unit = null;
            }

            this.companyUserProbation.save()
                .then(() => {
                    this.isEdit = false;
                    this.companyUserProbation.store('currentUserContext');
                    this.companyUserProbation.get();
                });
        },
    },

};
</script>
