import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUserEntity from '@/models/CompanyUserEntity';
import CompanyPlanningEvent from '@/models/CompanyPlanningEvent';
import CompanyUserEmailCustom from '@/models/CompanyUserEmailCustom';
import Company from './Company';
import CompanyFile from './CompanyFile';
import CompanyUser from './CompanyUser';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import CompanyUserProgramCollection from './CompanyUserProgramCollection';
import CompanyProgram from './CompanyProgram';
import CompanyUserProgramKeyDate from './CompanyUserProgramKeyDate';

export default class CompanyUserProgram extends Model {
    static STATUS_DRAFT = 'draft';

    static TYPE_DEFAULT = 'default';

    static STATUS_ACTIVE = 'active';

    static STATUS_COMPLETED = 'completed';

    static TYPE_ONBOARDING = 'onboarding';

    modelRelations() {
        return {
            company: () => this.belongsTo(Company, 'company_id', 'id'),
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyUserProgramTask: () => this.hasMany(CompanyUserProgramTask, 'company_user_program_id', 'id'),
            companyUserOwner: () => this.belongsTo(CompanyUser, 'owner_id', 'id'),
            companyProgram: () => this.belongsTo(CompanyProgram),
            program: () => this.belongsTo(CompanyProgram),
            entities: () => this.hasMany(CompanyUserEntity),
            events: () => this.hasMany(CompanyPlanningEvent, 'company_user_program_id', 'id'),
            emailsCustom: () => this.hasMany(CompanyUserEmailCustom, 'company_user_program_id', 'id'),
            mainKeyDate: () => this.hasOne(CompanyUserProgramKeyDate),
            keyDates: () => this.hasMany(CompanyUserProgramKeyDate),
        };
    }

    modelConfig() {
        return {
            collection: CompanyUserProgramCollection,
        };
    }
}
