import actions from '@/store/api/configuration/actions';
import emails from '@/store/api/configuration/emails';
import events from '@/store/api/configuration/events';
import trainings from '@/store/api/configuration/trainings';
import documents from '@/store/api/configuration/documents';
import roles from '@/store/api/configuration/roles';
import programs from '@/store/api/configuration/programs';
import surveys from '@/store/api/configuration/surveys';
import surveyResults from '@/store/api/configuration/surveyResults';
import configurations from '@/store/api/configuration/configurations';
import equipments from '@/store/api/configuration/equipments';
import softwares from '@/store/api/configuration/softwares';
import nudges from '@/store/api/configuration/nudges';
import quizzes from '@/store/api/configuration/quizzes';
import requirementCategory from '@/store/api/configuration/requirementCategory';
import entities from '@/store/api/configuration/entities';
import jobPositions from '@/store/api/configuration/job-positions';
import departments from '@/store/api/configuration/departments';
import offices from '@/store/api/configuration/offices';
import contracts from '@/store/api/configuration/contracts';
import customs from '@/store/api/configuration/customs';
import job from '@/store/api/configuration/job';
import emailTemplate from './emailTemplate';

export default {
    actions,
    emails,
    events,
    trainings,
    documents,
    roles,
    entities,
    jobPositions,
    departments,
    offices,
    contracts,
    customs,
    programs,
    surveys,
    surveyResults,
    nudges,
    softwares,
    configurations,
    equipments,
    quizzes,
    requirementCategory,
    job,
    emailTemplate,
};
