<template>
    <HtCard>
        <SectionBar
            :title="translate('Main speaker')"
            :has-add-button="false"
        />
        <div class="description">
            <t>This person will be the person who signs system emails and talk in the different chats available on the platform.</t>
        </div>
        <form
            v-if="currentItem && currentItem.isLoaded()"
            class="content-user form-2"
        >
            <div class="container-speaker">
                <div class="photo-edit">
                    <div class="logo-zone">
                        <Croppable
                            v-show="showCroppable"
                            ref="croppable_profile"
                            style="display: flex;justify-content: center;"
                            :show-width="125"
                            :show-height="125"
                            :dest-width="250"
                            :dest-height="250"
                            :resize-multiple="[0.1]"
                            save-url="company_file"
                            :image="getUserImage"
                            @onChange="onPictureChange"
                            @onUploadEnd="onPictureSave"
                        />
                        <img
                            v-show="!showCroppable"
                            :src="getUserImage"
                            alt=""
                        >
                    </div>
                    <Button
                        class="small"
                        @click=" $refs.croppable_profile.onOpenFileSelector(); showCroppable=true;"
                    >
                        <t>Upload</t>
                    </Button>
                </div>
                <div class="container-speaker-info">
                    <InputField
                        v-model="currentItem['content'].fullname"
                        type="text"
                        :name="'fullname'"
                        :placeholder="translate('First & lastname')"
                        :validate="'required'"
                        :validate-as="translate('fullname')"
                    />

                    <InputField
                        v-model="currentItem['content'].job_position"
                        type="text"
                        :name="'job_position'"
                        :placeholder="translate('Job position')"
                        :validate="'required'"
                        :validate-as="translate('job_position')"
                    />

                    <InputField
                        v-model="currentItem['content'].email"
                        type="text"
                        :name="'email'"
                        :validate="'email'"
                        :placeholder="translate('Email')"
                    />
                </div>
            </div>
            <div class="row-line">
                <Button
                    :state="button_state"
                    @click="onValidate()"
                >
                    <t>Save</t>
                </Button>
            </div>
        </form>
    </HtCard>
</template>

<script>
import Croppable from '@/components/tools/Croppable.vue';
import CompanyCustomizeCollection from '@/models/CompanyCustomizeCollection';

export default {
    name: 'PageCommunication',
    components: {
        Croppable,
    },

    data() {
        return {
            button_state: 'idle',
            showCroppable: false,
            hasUpload: false,
            currentItem: null,
            companyCustomize: new CompanyCustomizeCollection([
                'id', 'type', 'subtype', 'content', 'company_file_id',
            ]).with({
                companyFile: (query) => {
                    query.select(['id', 'path', 'name', 'original_name', 'uuid']);
                },
            }).where([
                ['type', '=', 'speaker'],
            ]),
        };
    },

    computed: {
        getUserImage() {
            if (this.currentItem
                && this.currentItem.isLoaded()
                && this.currentItem.company_file) return this.$Utils.resolveS3PublicImage(this.currentItem.company_file.path + this.currentItem.company_file.name);
            return '/static/images/Illu_Man.png';
        },
    },

    async created() {
        await this.companyCustomize.get();

        if (this.companyCustomize.$.models[0]) {
            this.currentItem = this.companyCustomize.$.models[0].active.fromSaveState();
        } else {
            this.currentItem = this.companyCustomize.new();
            this.currentItem.content = { fullname: null, job_position: null, email: null };
        }

        this.currentItem.type = 'speaker';
        this.currentItem.subtype = 'primary';
    },

    methods: {
        onPictureChange() {
            this.hasUpload = true;
        },

        onPictureSave(uploadInfo, data) {
            if (this.showCroppable && this.hasUpload) this.currentItem.company_file = data;
        },

        async onValidate() {
            if (!await this.$validator.validateAll()) return;

            this.button_state = 'loading';
            if (this.hasUpload) {
                await this.$refs.croppable_profile.onSave();
            }
            await this.currentItem.save();
            this.hasUpload = false;
            this.showCroppable = false;
            this.button_state = 'idle';
        },
    },
};
</script>
<style lang="scss" scoped>
	@import "~@/styles/var";

	.description {
		margin-bottom: .5em;
	    color: #a2a0a0;
	}

	.container-speaker {
		display:flex;

		.container-speaker-info {
			margin-left: 18px;
			margin-top: 18px;
			align-self: center;
			width: 100%;
		}
	}

	.logo-zone {
		text-align: center;
		height: 125px;
		// width: 100%;
		img {
			max-height: 100%;
			width: auto;
		}
	}

	.photo-edit {
		width: 18em;
		border-color: #B2B2B2;
		border-radius: 8px;
		border: 8px solid #E5E6E6;
		// background: #B2B2B2;
		.button{
			flex: 0 0 auto;
			margin: 12px 0 0;
		}
	}
</style>
