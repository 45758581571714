export default class AIRequirementService {
    constructor(env, token) {
        this.env = env;
        this.token = token;
        this.order = 0;
    }

    // eslint-disable-next-line class-methods-use-this
    createRequirement(newContent) {
        // eslint-disable-next-line prefer-const
        let { options, type } = newContent;
        let fileSize = newContent.file_size_in_mb;
        let isDirty = false;
        let canFillDocument = false;

        switch (newContent.type) {
        case 'short_text':
            type = 'string';
            break;
        case 'long_text':
            type = 'text';
            break;
        case 'social_security_number':
            type = 'social security number';
            break;
        case 'file_to_sign':
        case 'file_to_fill':
            isDirty = true;
            canFillDocument = true;
            break;
        }

        if (fileSize === 'any') {
            fileSize = null;
        }

        return {
            isDirty,
            tags: [],
            question_multiple_value: [],
            type,
            is_heyteam: 0,
            is_mandatory: newContent.is_mandatory,
            media: newContent.params ?? null,
            file_max_size: fileSize ?? null,
            order: 0,
            can_fill_document: canFillDocument,
            dynamic_documents: [],
            translations: {
                name: newContent.translations,
                question_multiple_value: options?.translations ?? {},
                image: {},
            },
            file_translations: {},
            config_value: null,
        };
    }

    async ask(input, currentTone, maxAIContent, languages, callback) {
        const url = this.env.getCapiUrl('requirement/ask');
        const data = {
            input,
            type: 'requirement',
            tone: currentTone.value,
            max_requirements: maxAIContent,
            languages,
        };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, options);
        return this.readStream(response.body, callback);
    }

    async readStream(stream, callback) {
        const reader = stream.getReader();
        let buffer = '';
        let inBrackets = 0;
        let lastProcessedIndex = 0;
        let isCompleteObject = false;
        const OPEN_BRACKET = '{';
        const CLOSE_BRACKET = '}';

        // eslint-disable-next-line no-constant-condition
        while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { done, value } = await reader.read();
            if (done) break;

            buffer += new TextDecoder().decode(value, { stream: true });

            for (let position = lastProcessedIndex; position < buffer.length; position++) {
                const char = buffer[position];

                if (char === OPEN_BRACKET) {
                    inBrackets += 1;
                } else if (char === CLOSE_BRACKET) {
                    inBrackets -= 1;

                    // si les accolades sont équilibrées, on a un objet JSON complet
                    if (inBrackets === 0) {
                        let jsonStr = '';
                        const firstBrace = buffer.indexOf(OPEN_BRACKET);
                        const lastBrace = position;

                        if (firstBrace !== -1 && lastBrace !== -1) {
                            jsonStr = buffer.substring(firstBrace, lastBrace + 1);
                        }

                        buffer = buffer.substring(position + 1);

                        let requirement = null;

                        try {
                            requirement = JSON.parse(jsonStr);
                        } catch (e) {
                            console.warn('Partial or malformed JSON, waiting for more data...', jsonStr);
                        }

                        if (requirement) {
                            const newContent = this.createRequirement(requirement);

                            this.order += 1;

                            isCompleteObject = true;

                            callback(newContent);
                        }
                    }
                }
            }

            if (isCompleteObject) {
                lastProcessedIndex = 0;
                isCompleteObject = false;
            } else {
                lastProcessedIndex = buffer.length;
            }
        }
        return buffer;
    }
}
