<template>
    <div class="index">
        <header class="index-header">
            <slot name="headbar" />
        </header>
        <main class="index-content">
            <router-view />
        </main>
        <footer class="index-footer">
            <PortalTarget name="footer" />
        </footer>
        <div class="index-extra">
            <slot name="extra" />
        </div>
    </div>
</template>

<script>

export default {
    name: 'IndexLayout',
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.index {
	height: 100%;
	display: flex;
	flex-direction: column;

	&-content {
		flex-grow: 1;
        width: 100%;
        padding: 45px 15px;
	}

    &-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: white;
    }
}

@media (max-width: $tablet) {
    .index-content {
        padding: 0;
    }
}
</style>
