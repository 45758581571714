import Model from '@tony.caron/node-model-proxy/Model';
import HtPermission from './HtPermission';
import HtPermissionGroupCollection from './HtPermissionGroupCollection';

export default class HtPermissionGroup extends Model {
    modelConfig() {
        return {
            controller: 'HtPermissionGroupController',
            collection: HtPermissionGroupCollection,
        };
    }

    modelRelations() {
        return {
            htPermission: () => this.hasMany(HtPermission, 'ht_permission_group_id', 'id'),
        };
    }
}
