import Model from '@tony.caron/node-model-proxy/Model';
import CompanyProgramKeyDate from './CompanyProgramKeyDate';
import CompanyUserProgramKeyDateCollection from './CompanyUserProgramKeyDateCollection';

export default class CompanyUserProgramKeyDate extends Model {
    modelConfig() {
        return {
            collection: CompanyUserProgramKeyDateCollection,
        };
    }

    modelRelations() {
        return {
            keyDate: () => this.belongsTo(CompanyProgramKeyDate, 'company_program_key_date_id', 'id'),
        };
    }
}
