<template>
    <div
        class="ht-notifier"
        :class="[{'silent': silent}]"
    >
        <div
            v-for="(notif, index) in notification"
            :key="index"
            class="ht-notifier-container"
        >
            <HtToast
                :message="notif.message"
                :type="notif.type"
            />
        </div>
    </div>
</template>

<script>
import NotifierMixin from '@/components/tools/Notifications/NotifierMixin';
import HtToast from '@/components/globals/HtToast.vue';

export default {
    name: 'HtNotifier',
    components: {
        HtToast,
    },
    mixins: [NotifierMixin],
};
</script>

<style lang="scss" scoped>
@keyframes popright {
    0% {
        right: -100em;
    }
    10%,90%
    {
        right: 1em;
    }
    100% {
        right: -100em;
    }
}
.silent {
    display: none !important;
}
.ht-notifier{
    position: fixed;
    top: 6em;
    right: 1em;
    z-index: 99999;
    display: flex;
    flex-direction: column;

    &-container {
        position: relative;
        animation: popright 3s forwards;
        height: 6rem;
    }
}
</style>
