<template>
    <DashboardFooterBar>
        <template #title>
            <span v-if="remindableProgramsCount === programsSelectedCount || remindableProgramsCount === 0">
                <t :count="programsSelectedCount">{count} program selected | {count} programs selected</t>
            </span>
            <span v-if="remindableProgramsCount < programsSelectedCount && remindableProgramsCount > 0">
                <t :count="programsSelectedCount">{count} program selected, this program cannot be reminded | {count} programs selected, some programs cannot be reminded</t>
            </span>
            <span>
            &nbsp;
            </span>
            <span
                v-if="programsSelectedCount < programsCount"
                class="link"
                @click="selectAll"
            >
                <t>Select all</t>
            </span>
            <span
                v-else
                class="link"
                @click="cancelSelection"
            >
                <t>Cancel selection</t>
            </span>
        </template>
        <template #actions>
            <Button
                class="negative"
                :state="closeButtonState"
                @click="closePrograms"
            >
                <t :count="closeProgramsCount">
                    Close
                </t>
            </Button>
            <Button
                class="negative"
                :state="exportButtonState"
                @click="downloadExport"
            >
                <t :count="programsSelectedCount">
                    Export
                </t>
            </Button>
            <Button
                :state="reminderButtonState"
                @click="sendReminders"
            >
                <t :count="remindableProgramsCount">
                    Remind
                </t>
            </Button>
        </template>
    </DashboardFooterBar>
</template>

<script>
import api from '@/store/api';

import DashboardFooterBar from '@/components/pages/dashboard/components/DashboardFooterBar.vue';

import modalMixin from '@/components/globals/modals/modalMixin';

export default {
    name: 'ProgramsFooterBar',
    components: {
        DashboardFooterBar,
    },
    mixins: [
        modalMixin,
    ],
    props: {
        page: {
            type: Number,
            required: true,
        },
    },
    computed: {
        remindableProgramsCount() {
            return this.$store.getters['dashboard/programs/getRemindableProgramsCount'](this.page);
        },
        closeProgramsCount() {
            return this.$store.getters['dashboard/programs/getCloseProgramsCount'](this.page);
        },
        programsSelectedCount() {
            return this.$store.getters['dashboard/programs/getSelectedProgramsCount'](this.page);
        },
        programsCount() {
            return this.programs.length;
        },
        programs() {
            return this.$store.getters['dashboard/programs/getPage'](this.page);
        },
        canExport() {
            return this.$can('PageSettingsDataAnalysis');
        },
        canSendReminders() {
            return this.$store.getters['dashboard/programs/canSendReminders'](this.page);
        },
        canClosePrograms() {
            return this.$store.getters['dashboard/programs/canCloseReminders'](this.page);
        },
        closeButtonState() {
            return this.canClosePrograms
                ? 'idle'
                : 'disabled';
        },
        exportButtonState() {
            return this.canExport
                ? 'idle'
                : 'disabled';
        },
        reminderButtonState() {
            return this.canSendReminders
                ? 'idle'
                : 'disabled';
        },
    },
    methods: {
        selectAll() {
            this.programs.forEach((data) => {
                const isChecked = this.$store.getters['dashboard/programs/rowChecked'](data.program.id);

                if (isChecked === false) {
                    this.$store.dispatch('dashboard/programs/toggleCheckbox', data.program.id);
                }
            });
        },
        sendReminders() {
            const programs = this.$store.getters['dashboard/programs/getSelectedPrograms'](this.page);

            this.genericConfirm(
                this.translate('Confirmation needed'),
                this.translate(
                    "You're about to send a reminder to {count} program | You're about to send reminders to {count} programs",
                    { count: programs.length },
                ),
            ).then(() => this.$http.post('dashboard/statistics/send-reminders', {
                programs,
            })).then(() => {
                this.$store.dispatch('dashboard/programs/refresh');
                this.$store.dispatch('dashboard/programs/fetchPage', 1);

                this.$Notifier('App').showInfo(this.translate('Successful update'));
                this.cancelSelection();
            }).catch(() => {});
        },
        closePrograms() {
            const programs = this.$store.getters['dashboard/programs/getSelectedPrograms'](this.page);

            this.genericConfirm(
                this.translate('Confirmation needed'),
                this.translate(
                    "You're about to permanently close {count} program | You're about to permanently close {count} programs",
                    { count: programs.length },
                ),
            ).then(() => this.$http.post('dashboard/statistics/close-programs', {
                programs,
            })).then(() => {
                this.$store.dispatch('dashboard/programs/refresh');
                this.$store.dispatch('dashboard/programs/fetchPage', 1);

                const message = this.translate('{count} program closed | {count} programs closed', {
                    count: programs.length,
                });

                this.$Notifier('App').showInfo(message);
                this.cancelSelection();
            }).catch(() => {});
        },
        downloadExport() {
            const programs = this.$store.getters['dashboard/programs/getSelectedPrograms'](this.page);

            api.miscellaneous.exportUsers({ programs }).then((data) => {
                window.location = data.data.link;
            });

            this.cancelSelection();
        },
        cancelSelection() {
            this.$store.dispatch('dashboard/programs/cancelSelection', this.page);
        },
    },
};
</script>

<style lang="scss" scoped>
.link {
    color: var(--branding-color);

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
</style>
