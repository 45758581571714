<template>
    <div
        class="quiz-question-item"
        :class="{toggled: isToggled}"
    >
        <fieldset :class="{ error: errors.has('question') }">
            <div class="quiz-question-item-header">
                <div class="quiz-question-item-header-label">
                    <label>
                        <t>Nom de la question</t>
                        <span>*</span>
                    </label>
                </div>
                <div class="quiz-question-item-header-button">
                    <button
                        class="mr-10 button button-icon toggle-question"
                        @click="isToggled = !isToggled"
                    >
                        <svg
                            class="icon icon-arrow-item"
                            :class="{toggled: isToggled}"
                        >
                            <use xlink:href="#icon-arrow-item" />
                        </svg>
                    </button>
                    <button
                        class="button button-icon"
                        @click="emitDeleteQuestion"
                    >
                        <svg class="icon icon-trash">
                            <use xlink:href="#icon-trash" />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="quiz-question-item-box">
                <div class="quiz-question-item-text">
                    <div class="quiz-question-item-text no-mt no-middle">
                        <div class="quiz-question-item-text-label">
                            <label :for="'question' + question.id">
                                <t>Question</t>
                            </label>
                        </div>
                        <div class="quiz-question-item-text-input">
                            <InputField
                                :id="(question.id) ? `question-${question.id}` : `question-${question.tempId}`"
                                v-model="question.translations.text[editingLang]"
                                v-next-field
                                type="text"
                                :name="(question.id) ? `question-${question.id}` : `question-${question.tempId}`"
                                :placeholder="translate('Question title')"
                                :validate="{
                                    required: !question.company_file_id || question.company_file_id.length === 0,
                                    translation_default:[question.translations.text, shared.session.company.company_languages.default.key],
                                }"
                                :validate-as="translate('question text')"
                                :cypress="`quizQuestion-form-text-title-${indexQuestion}`"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-for="lang in shared.session.company.company_languages.enabled"
                    :key="lang.key"
                    :class="['quiz-question-item-image', { toggled: isToggled }]"
                >
                    <QuizImageEdit
                        v-show="lang.key === editingLang"
                        :key="lang.key"
                        :editing-lang="lang.key"
                        :input-model.sync="question"
                        :is-toggled="isToggled"
                        :input-label="translate('Attach picture to the question')"
                    />
                </div>
            </div>
        </fieldset>
        <QuizQuestionAnswerEdit
            v-for="(correctAnswer, indexAnswer) in correctAnswers"
            :key="correctAnswer.id"
            :editing-lang="editingLang"
            :index-answer="indexAnswer"
            :answer.sync="correctAnswer"
            :is-toggled="isToggled"
            :can-delete="indexAnswer > 0"
            @updateAnswer="updateAnswer"
            @deleteAnswer="deleteWrongAnswer"
        />

        <QuizQuestionAnswerEdit
            v-for="(wrongAnswer, indexAnswer) in wrongAnswers"
            :key="wrongAnswer.id"
            :editing-lang="editingLang"
            :index-answer="indexAnswer + 1"
            :answer.sync="wrongAnswer"
            :is-toggled="isToggled"
            :can-delete="indexAnswer > 0"
            @updateAnswer="updateAnswer"
            @deleteAnswer="deleteWrongAnswer"
        />
        <div
            class="quiz-question-item-note items-bar"
            :class="{toggled: isToggled}"
        >
            <t>The answers will appear in a random order</t>
        </div>
        <div
            v-if="answersCount < 4"
            class="quiz-question-item-add items-bar center"
            :class="{toggled: isToggled}"
            data-cy="quiz-add-wrong-answer"
        >
            <a
                href="#"
                @click="addAnswer"
            >
                <t>Add a wrong answer</t>
            </a>
        </div>
    </div>
</template>

<script>
import Croppable from '@/components/tools/Croppable.vue';
import QuizImageEdit from './QuizImageEdit.vue';
import QuizQuestionAnswerEdit from './QuizQuestionAnswerEdit.vue';

export default {
    components: { QuizImageEdit, Croppable, QuizQuestionAnswerEdit },
    inject: ['modal', '$validator'],
    props: {
        question: { type: Object }, // CompanyQuizQuestion
        indexQuestion: { type: Number },
        editingLang: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isToggled: false,
        };
    },
    computed: {
        answersCount() {
            return this.question.answers.length;
        },
        showCroppableImage() {
            return this.question.image && this.question.image.length > 0 ? 'displayPicture' : 'hidePicture';
        },
        correctAnswers() {
            return this.question.answers.filter((answer) => answer.is_correct_answer);
        },
        wrongAnswers() {
            return this.question.answers.filter((answer) => !answer.is_correct_answer);
        },
    },
    created() {
        this.modal.setTitle('Quiz Questions');
    },
    methods: {
        addAnswer() {
            const newAnswer = {
                is_correct_answer: false,
                tempId: Math.floor(Math.random() * Math.floor(1000)) + this._uid,
                translations: {
                    text: {},
                },
                text: '',
                file_translations: {},
            };

            this.question.answers.push(newAnswer);
        },
        updateAnswer(answerToUpdate) {
            let findeIndex = -1;
            if (undefined === answerToUpdate.id && 'tempId' in answerToUpdate) {
                findeIndex = this.question.answers.findIndex((answer) => answer.tempId === answerToUpdate.tempId);
            } else if (undefined !== answerToUpdate.id) {
                findeIndex = this.question.answers.findIndex((answer) => answer.id === answerToUpdate.id);
            }
            if (findeIndex > -1) {
                this.question.answers[findeIndex] = answerToUpdate;
            }
        },
        deleteWrongAnswer(answerToDelete) {
            let findeIndex = -1;
            if (undefined === answerToDelete.id && 'tempId' in answerToDelete) {
                findeIndex = this.question.answers.findIndex((answer) => answer.tempId === answerToDelete.tempId);
            } else if (undefined !== answerToDelete.id) {
                findeIndex = this.question.answers.findIndex((answer) => answer.id === answerToDelete.id);
            }
            if (findeIndex > -1) {
                this.question.answers.splice(findeIndex, 1);
            }
        },
        emitDeleteQuestion() {
            this.$emit('deleteQuestion', this.question);
        },
    },
};

</script>
<style lang="scss" scoped src="./QuizQuestionItemEdit.scss" />
