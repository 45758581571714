<template>
    <div class="profil-metadata">
        <div class="profile-item-title">
            {{ sectionTitle }}
        </div>
        <div class="profile-item-content form-2">
            <div
                v-if="!isEdit"
                class="d-flex flex-wrap"
            >
                <template v-if="companyUserMetadataCollection.isLoaded() && getSavedMetadata.length > 0">
                    <div
                        v-for="(item, index) in getSavedMetadata"
                        :key="index"
                        class="meta-item d-flex align-items-center"
                    >
                        {{ item.value }}
                    </div>
                </template>
            </div>

            <div v-else>
                <input
                    id="hobbies"
                    v-model="outputValue"
                    type="text"
                    class="full"
                    name="hobbies"
                    :placeholder="translate('Separated by commas')"
                    @input="$emit('input', outputValue)"
                >

                <div class="d-flex flex-wrap mt-10">
                    <div
                        v-for="(item, index) in getCurrentMetadata"
                        :key="index"
                        class="meta-item d-flex align-items-center"
                    >
                        <span>{{ item.value }}</span>
                        <FontAwesomeIcon
                            :icon="['fal', 'times']"
                            class="icon"
                            @click="deleteMeta(item)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyUserMetadataCollection from '@/models/CompanyUserMetadataCollection';

export default {
    name: 'ProfileMetadata',
    props: {
        companyUserMetadataCollection: {
            type: CompanyUserMetadataCollection,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        sectionTitle: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            validator: (val) => ['hobby', 'skill'].indexOf(val) !== -1,
            required: true,
        },
    },

    data() {
        return {
            outputValue: this.value,
        };
    },

    computed: {
        getSavedMetadata() {
            return this.companyUserMetadataCollection.$.models.filter((meta) => meta.type === this.type);
        },

        getCurrentMetadata() {
            return this.companyUserMetadataCollection.models.filter((meta) => meta.type === this.type);
        },
    },

    watch: {
        isEdit() {
            if (this.isEdit === false) {
                this.outputValue = '';
            } else {
                this.companyUserMetadataCollection.fromSaveState();
            }
        },
    },

    methods: {
        deleteMeta(metadataToDelete) {
            const indexCollection = this.companyUserMetadataCollection.models.findIndex((metadata) => metadata.id === metadataToDelete.id);
            this.companyUserMetadataCollection.models.splice(indexCollection, 1);
        },
    },
};
</script>

<style lang="scss" src="./_ProfilMetadata.scss" scoped />
