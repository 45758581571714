<template>
    <ChoiceResultItem
        :label="answerLabel"
        :percent="percent"
        :is-selected="isSelected"
    >
        <template #title>
            <img
                :src="iconPath"
                class="image"
            >
            {{ answerLabel }}
        </template>
    </ChoiceResultItem>
</template>

<script>
import ChoiceResultItem from '../ChoiceResultItem.vue';
import choiceResultItemProps from '../choiceResultItemProps';

export default {
    name: 'ResultSatisfactionScaleItem',
    components: { ChoiceResultItem },

    mixins: [
        choiceResultItemProps,
    ],
    props: {
        iconPath: {
            type: String,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
.image {
    z-index: 1;
    margin-right: 20px;
}
</style>
