<template>
    <div
        v-if="readOnly === false"
        class="answer"
    >
        <MultipleFilesUpload
            :item="item"
            :media="item.media"
            :show-media-legend="true"
            :max-size="item.file_max_size"
            :show-file-size-legend="item.file_max_size !== null"
            :disabled="readOnly"
            @update:company-files="files = $event"
        />
        <div
            v-if="readOnly === false"
            class="answer-actions"
        >
            <Button
                :state="buttonState"
                @click="validateAnswers"
            >
                <t>Validate</t>
            </Button>
        </div>
    </div>
</template>

<script>
import CompanyUserSurveyQuestionAnswerCollection from '@/models/CompanyUserSurveyQuestionAnswerCollection';
import MessageResourceUserSurveyQuestionMixin from './mixin/MessageResourceUserSurveyQuestionMixin';

export default {
    name: 'MessageResourceUserSurveyQuestionFileUpload',
    mixins: [
        MessageResourceUserSurveyQuestionMixin,
    ],
    data() {
        return {
            files: [],
        };
    },
    computed: {
        item() {
            return {
                media: this.companySurveyQuestion.params,
                file_max_size: null,
                company_files: this.files,
            };
        },
        buttonState() {
            return this.files.length > 0 ? 'idle' : 'disabled';
        },
    },
    watch: {

    },
    created() {

    },
    methods: {
        validateAnswers() {
            if (this.readOnly) return;

            const companyUserSurveyQuestionAnswerCollection = new CompanyUserSurveyQuestionAnswerCollection([
                'company_file_id',
            ]);

            this.files.forEach((file) => {
                const answer = companyUserSurveyQuestionAnswerCollection.new();
                answer.company_file_id = file.id;
                companyUserSurveyQuestionAnswerCollection.models.push(answer);
            });

            this.$emit('onAnswers', companyUserSurveyQuestionAnswerCollection);

            this.files = [];
        },
    },
};
</script>

<style lang="scss" scoped src="./MessageResourceUserSurveyQuestionFileUpload.scss"></style>
