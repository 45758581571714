<template>
    <ListTasksByAction v-bind="$props" />
</template>

<script>
import ListTasksByAction from '../../listTasks/ByAction/ListTasksByAction.vue';

export default {
    name: 'MyActionsByAction',
    components: { ListTasksByAction },
    props: {
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        selectedTasks: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
};
</script>
