export default class UtilsCompanyFile {
    static allowedFullFields() {
        return [
            'id',
            'original_name',
            'path',
            'name',
            'uuid',
            'link',
            'link_preview',
            'detected_mime_type',
            'detected_extension',
        ];
    }

    static allowedMinimumFields() {
        return [
            'id',
            'original_name',
            'path',
            'name',
            'link',
            'detected_extension',
        ];
    }
}
