<template>
    <div class="wrapper">
        <div class="confirm">
            <img src="@/assets/images/happy.svg">
            <div class="congrat">
                <b>
                    <t>Congratulations</t>
                </b>
            </div>
            <div class="create">
                <t>You have just created your password</t>
            </div>
            <Button @click="$router.push({ name: 'HoldingDashboard' })">
                <t>Access to your holding account</t>
            </Button>
        </div>
    </div>
</template>
<script>
import Button from '@/components/globals/Button.vue';
import CompanyUser from '@/models/CompanyUser';

export default {
    name: 'WelcomChatbotConfirm',
    components: { Button },

    created() {
        if (this.shared.session.companyUser.state === CompanyUser.STATE_PREBOARDING) {
            this.$router.replace({ name: 'HoldingWelcomeChatbotCreatePassword' });
        }
    },
};
</script>
<style lang="scss" scoped>
.wrapper {
    background-color: #333333;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.congrat {
    margin-top: 3.2rem;
    margin-bottom: 1.6rem;
}
.confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 5.2rem 6.7rem;
}
.create {
    font-size: 1.4rem;
    margin-bottom: 3.2rem;
}
</style>
