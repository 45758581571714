<template>
    <HtFormGroup
        :label="label"
        :error="error"
        :is-required="isRequired"
        :show-asterisk="showAsterisk"
    >
        <fieldset>
            <UserBlock2
                :id="id"
                :data-cy="cypress"
                :name="name"
                @onAdd="$emit('on-add')"
            >
                <UserBlock2
                    v-if="value"
                    v-model="value"
                    :type="type"
                    @onRemove="$emit('on-remove')"
                />
            </UserBlock2>
        </fieldset>
    </HtFormGroup>
</template>

<script>
import HtFormMixin from './HtFormMixin';

export default {
    name: 'HtFormUserOrRoleSelect',
    mixins: [
        HtFormMixin,
    ],

    props: {
        value: {
            type: Object,
            default: () => ({}),
        },

        type: {
            type: String,
            default: () => 'user',
        },
    },
};
</script>
