<template>
    <div class="company-onboarding-probation">
        <div v-if="isLoaded">
            <FilterableSearch
                ref="filterSearch"
                v-model="componentItems"
                :prop-keys="[
                    'firstname',
                    'lastname',
                    'end_date',
                    'arrival_date',
                    'first_probation',
                    'second_probation'
                ]"
            />

            <HtTable
                :columns="columns"
                :items="componentItems"
                class="table-company-onboarding-probation"
                :empty-message="'There is no Users on Probation period found!'"
                @onCellClicked="goToProfile"
            >
                <template #cell(fullname)="scope">
                    <div class="d-flex align-items-center">
                        <div
                            v-user-image="{
                                image: scope.item.image,
                                firstname: scope.item.firstname,
                                lastname: scope.item.lastname
                            }"
                            class="user-image mr-3"
                        />
                        <div>
                            <span class="font-weight-bold">
                                {{ scope.item.firstname }}
                                {{ scope.item.lastname }}
                            </span>
                            <span v-if="scope.item.company_office">
                                {{ scope.item.company_office.name }},
                                {{ scope.item.company_office.city }}
                            </span>
                        </div>
                    </div>
                </template>

                <template #cell(probationFinalEndDate)="scope">
                    <div
                        class="table-visible-mobile font-weight-bold"
                    >
                        {{ $Utils.mysqlToDate(scope.item.probationFinalEndDate, 'DD MMMM YYYY') }}
                    </div>
                </template>
                <template #cell(arrival_date)="scope">
                    <div
                        class="table-hide-mobile"
                        :style="scope.size"
                    >
                        {{ $Utils.mysqlToDate(scope.item.arrival_date, 'DD MMMM') }}
                    </div>
                </template>

                <template #cell(duration)="scope">
                    <div
                        class="table-hide-mobile"
                        :style="scope.size"
                    >
                        <t :count="scope.item.first_probation">
                            {count} {{ scope.item.first_probation_unit }} | {count} {{ scope.item.first_probation_unit }}s
                        </t>
                    </div>
                </template>

                <template #cell(second_probation)="scope">
                    <div
                        class="table-hide-mobile"
                        :style="scope.size"
                    >
                        <t v-if="!scope.item.second_probation">
                            No
                        </t>
                        <span v-else>
                            <t>Yes</t>
                            (<t :count="scope.item.second_probation">{count} {{ scope.item.second_probation_unit }} | {count} {{ scope.item.second_probation_unit }}s</t>)
                        </span>
                    </div>
                </template>
            </HtTable>
        </div>
        <IconLoading v-else />
    </div>
</template>

<script>
import CompanyUserProgramCollection from '@/models/CompanyUserProgramCollection';
import moment from 'moment';
import CompanyUserProgram from '@/models/CompanyUserProgram';

export default {
    name: 'CompanyOnboardingProbationsList',
    filters: {
        moment(date) {
            return moment(date).format('MMMM Do YYYY');
        },
    },
    data() {
        return {
            columns: [
                {
                    label: this.translate('Member'),
                    key: 'fullname',
                },
                {
                    label: this.translate('End Date'),
                    key: 'probationFinalEndDate',
                    sortDirection: 'asc',
                },
                {
                    label: this.translate('Arrival Date'),
                    key: 'arrival_date',
                },
                {
                    label: this.translate('Duration'),
                    key: 'duration',
                },
                {
                    label: this.translate('Renewed?'),
                    key: 'second_probation',
                },
            ],
            companyUserProgramCollection: new CompanyUserProgramCollection([
                'id', 'company_user_id',
            ]).with({
                companyUser: (query) => {
                    query.select([
                        'id',
                        'firstname',
                        'lastname',
                        'fullname',
                        'image',
                        'end_date',
                        'arrival_date',
                        'first_probation',
                        'first_probation_unit',
                        'first_probation_end_date',
                        'first_probation_status',
                        'second_probation',
                        'second_probation_unit',
                        'second_probation_end_date',
                        'second_probation_status',
                        'status',
                    ]).with({
                        companyOffice: (query) => {
                            query.select(['id', 'name', 'city']);
                        },
                        companyDepartment: (query) => {
                            query.select(['id', 'name']);
                        },
                    });
                },
            }).where([
                ['company_program_id', '=', this.$router.currentRoute.params.program_id],
                ['status', '!=', CompanyUserProgram.STATUS_DRAFT],
            ]).whereHas({
                companyUser: (query) => {
                    query.where([
                        ['first_probation', '>', '0'],
                        [
                            ['first_probation_status', '!=', 'valid'],
                            ['first_probation_status', '=', null, 'or'],
                        ],
                        [
                            ['second_probation_status', '!=', 'valid'],
                            ['second_probation_status', '=', null, 'or'],
                        ],
                    ]);
                },
            }),
        };
    },

    computed: {
        componentItems() {
            return this.isLoaded ? this.companyUserProgramCollection.$.models.map((userProgram) => userProgram.company_user) : [];
        },

        isLoaded() {
            return this.companyUserProgramCollection.isLoaded();
        },
    },

    created() {
        this.companyUserProgramCollection.get();
    },

    methods: {
        goToProfile(row) {
            this.$router.push({
                name: 'Profile',
                params: {
                    company_user_id: row.item.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.button-1 {
    font-size: 1.3rem; // because of long month
    padding: 3px 5px;
    pointer-events: none;
    display: grid;
    margin: auto auto auto 0;
    align-items: end;
    border-radius: 4px;

    &.red {
        color: white;
        background: #ff6058;
    }
    &.black {
        color: white;
        background: $black;
    }
}
</style>
