import Programs from '@/router/pages/Programs.vue';
import ProgramDetail from '@/router/pages/programs/ProgramDetail.vue';
import ProgramDetailLive from '@/router/pages/programs/ProgramDetailLive.vue';
import ProgramDetailDraft from '@/router/pages/programs/ProgramDetailDraft.vue';
import ProgramDetailDone from '@/router/pages/programs/ProgramDetailDone.vue';
import ProgramDetailProbation from '@/router/pages/programs/ProgramDetailProbation.vue';
import ProgramDetailSimulation from '@/router/pages/programs/ProgramDetailSimulation.vue';

import PageNewProgram from '@/router/pages/programs/New.vue';
import PageProgramTemplateSelection from '@/router/pages/programs/TemplateSelection.vue';
import PageProgramTemplatePreview from '@/router/pages/programs/TemplatePreview.vue';
import PageProgramForm from '@/router/pages/programs/PageProgramForm.vue';

import Enrolling from '@/router/pages/programs/enrolling/Enrolling.vue';
import BulkEnrolling from '@/router/pages/programs/enrolling/bulk/BulkEnrolling.vue';
import ProgramDetailInvitations from '@/router/pages/programs/ProgramDetailInvitations.vue';

export default [
    {
        path: '/Programs',
        name: 'Programs',
        component: Programs,
    },
    {
        path: '/Programs/:program_id',
        name: 'ProgramDetail',
        component: ProgramDetail,
        props: (route) => ({ companyProgramId: Number(route.params.program_id) }),
        meta: {
            permission: true,
        },
        children: [
            {
                path: 'Live',
                name: 'ProgramDetailLive',
                component: ProgramDetailLive,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Draft',
                name: 'ProgramDetailDraft',
                component: ProgramDetailDraft,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Done',
                name: 'ProgramDetailDone',
                component: ProgramDetailDone,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Probation',
                name: 'ProgramDetailProbation',
                component: ProgramDetailProbation,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'Simulation',
                name: 'ProgramDetailSimulation',
                component: ProgramDetailSimulation,
                meta: {
                    permission: true,
                },
                props: (route) => ({ companyProgramId: Number(route.params.program_id) }),
            },
            {
                path: 'Invitations',
                name: 'ProgramDetailInvitations',
                component: ProgramDetailInvitations,
                meta: {
                    permission: true,
                },
                props: (route) => ({ companyProgramId: Number(route.params.program_id) }),
            },
        ],
    },

    {
        path: '/Program/New',
        name: 'NewProgram',
        component: PageNewProgram,
        meta: {
            permission: true,
        },
    },
    {
        path: '/Program/Templates',
        name: 'ProgramTemplateSelection',
        component: PageProgramTemplateSelection,
        meta: {
            permission: true,
        },
    },
    {
        path: '/Program/Templates/:slug',
        name: 'ProgramTemplatePreview',
        component: PageProgramTemplatePreview,
        props: (route) => ({ slug: route.params.slug }),
        meta: {
            permission: true,
        },
    },
    {
        path: '/Program/Form/:id?',
        name: 'ProgramsForm',
        component: PageProgramForm,
        meta: {
            permission: true,
        },
    },
    {
        path: 'Enrolling',
        name: 'Enrolling',
        component: Enrolling,
        meta: {
            permission: true,
        },
    },
    {
        path: 'Enrolling/Bulk/:id',
        name: 'BulkEnrolling',
        component: BulkEnrolling,
        props: (route) => ({ bulkId: parseInt(route.params.id, 10) }),
        meta: {
            permission: true,
        },
    },
];
