<template>
    <div class="errors-listing">
        <div
            v-for="(error, key) in formErrors"
            :key="key"
            class="error-element"
        >
            <p>
                <t>Row</t> {{ getFormattedKey(key) }}

                <button @click="toggleErrors(key)">
                    <t v-show="doWeShowErrorsByKey[key]">
                        Hide errors
                    </t>
                    <t v-show="!doWeShowErrorsByKey[key]">
                        Show errors
                    </t>
                </button>
            </p>
            <ul
                :class="{'invisible': !doWeShowErrorsByKey[key]}"
            >
                <li
                    v-for="(errorMessage, field) in error"
                    :key="field"
                >
                    <strong>{{ field }}</strong>: {{ errorMessage }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErrorListing',
    props: {
        formErrors: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            doWeShowErrorsByKey: {},
        };
    },
    methods: {
        toggleErrors(key) {
            this.doWeShowErrorsByKey = {
                ...this.doWeShowErrorsByKey,
                [key]: !this.doWeShowErrorsByKey[key],
            };
        },
        getFormattedKey(key) {
            return `#${key.substring(1)}`;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.error-element {
  margin-bottom: 1.5rem;

  button {
    border: none;
    color: $dashboard-blue;
    background: transparent;
  }

  li {
    list-style-type: initial;
    margin-left: 2rem;
  }
}

.invisible {
  visibility: hidden;
  height: 0;
}
</style>
