<template>
    <div v-if="companyUserEquipment.isLoaded()">
        <form
            class="form-2 spaced label-underline"
            data-cy="equipment-form"
        >
            <fieldset>
                <div class="icon-wrapper">
                    <div
                        class="image"
                        :style="{ 'background-image': 'url('+companyUserEquipment.company_equipment.image+')' }"
                    />
                </div>
            </fieldset>

            <fieldset v-if="companyUserEquipment.specific_information">
                <label>
                    <t :firstname="companyUserEquipment.company_user.firstname"> Specific information for {firstname}</t> :
                </label>
                <p>{{ companyUserEquipment.specific_information }}</p>
            </fieldset>

            <fieldset>
                <label><t>Setup date</t> :</label>
                <div class="item-content">
                    {{ $Utils.moment(companyUserEquipment.company_user_program_task.datetime_end).format('LL') }}
                </div>
            </fieldset>

            <fieldset>
                <HtStatusLabel
                    :status="companyUserEquipment.company_user_program_task.status"
                />
            </fieldset>

            <div class="modal-actions right">
                <Button
                    v-if="companyUserEquipment.permissions.delete && !isCancelled"
                    class="negative"
                    cypress="equipment-remove-button"
                    @click="remove()"
                >
                    <t>Remove</t>
                </Button>
                <Button
                    v-if="companyUserEquipment.permissions.update && !isCancelled"
                    cypress="equipment-edit-button"
                    @click="edit()"
                >
                    <t>Edit</t>
                </Button>
                <Button
                    v-if="companyUserEquipment.company_user_program_task.permissions.can_validate && canValidate"
                    :state="buttonState"
                    cypress="equipment-toggle-status-button"
                    @click="toggleStatus()"
                >
                    <t>{{ isDone ? 'Mark as undone' : 'Mark as done' }}</t>
                </Button>
            </div>
        </form>

        <Modalable
            ref="modal"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserEquipmentEdit
                :id="id"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate"
                @onDelete="onDelete"
            />
        </Modalable>
    </div>
</template>

<script>
import HtStatusLabel from '@/pages/HtStatusLabel.vue';
import CompanyUserEquipment from '@/models/CompanyUserEquipment';
import UserEquipmentEdit from './UserEquipmentEdit.vue';
import CompanyUserProgramTask from '../../../../../models/CompanyUserProgramTask';

export default {
    name: 'UserEquipmentItem',
    components: {
        UserEquipmentEdit,
        HtStatusLabel,
    },
    inject: ['modal'],
    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        canValidate: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            buttonState: 'idle',

            companyUserEquipment: new CompanyUserEquipment([
                'id',
                'company_user_id',
                'company_equipment_id',
                'specific_information',
                'permissions',
            ]).with({
                companyEquipment: (query) => {
                    query
                        .select(['id', 'image'])
                        .withTrashed()
                        .with({
                            resource: (query) => {
                                query.select(['name']);
                            },
                        });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'resource',
                        'company_user_id',
                        'status',
                        'datetime_start',
                        'datetime_end',
                        'permissions',
                    ]);
                },
            }).where([
                ['id', '=', this.id],
            ]),
        };
    },

    computed: {
        isDone() {
            return this.companyUserEquipment.company_user_program_task.status === CompanyUserProgramTask.STATUS_DONE;
        },

        labelTitle() {
            return this.companyUserEquipment.name;
        },

        isCancelled() {
            return this.companyUserEquipment.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },
    },

    watch: {
        'companyUserEquipment._state.isSaving': function (value) {
            this.buttonState = value ? 'loading' : 'idle';
        },
    },

    created() {
        this.companyUserEquipment.company_user_id = this.companyUserId;
        this.companyUserEquipment.get()
            .then(() => {
                this.modal.setTitle(this.labelTitle);
            });
    },

    methods: {
        edit() {
            this.$refs.modal.open();
        },

        /**
             * validate or invalidate the associated task
             */
        async toggleStatus() {
            await this.$handleValidationTasks({
                id: this.companyUserEquipment.company_user_program_task.id,
                status: this.isDone ? CompanyUserProgramTask.STATUS_PENDING : CompanyUserProgramTask.STATUS_DONE,
            });
            this.$emit('onUpdate');
            this.modal.close();
        },

        remove() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this equipment? Once you click on delete, you will no longer be able to access this equipment.') }).then((result) => {
                if (result) {
                    this.companyUserEquipment.delete().then(() => this.onDelete());
                }
            });
        },

        onUpdate() {
            this.modal.close();
            this.$emit('onUpdate');
        },

        onDelete() {
            this.modal.close();
            this.$emit('onDelete');
        },
    },
};
</script>

<style lang="scss" src="./UserEquipmentItem.scss" scoped />
