import Vue from 'vue';
import Model from '@tony.caron/node-model-proxy/Model';
import CompanySurveyExtraLocale from './CompanySurveyExtraLocale';
import CompanySurveyExtraCollection from './CompanySurveyExtraCollection';

export default class CompanySurveyExtra extends Model {
    modelConfig() {
        return {
            collection: CompanySurveyExtraCollection,
        };
    }

    modelCustomAttributes() {
        return {
            text: null,
            locales_by_key: {},
            extra: null,
        };
    }

    modelEvents() {
        return {
            loaded: () => {
                Vue.prototype.$modelUtils.createLocales(this.company_survey_extra_locale, ['name']);
            },
        };
    }

    modelAccessors() {
        return {
            extra: () => ({
                name: 'text',
                label: 'Text',
                icon: '/static/icons/survey/type/AdditionalInfo.svg',
            }),
            locales_by_key: () => Vue.prototype.$modelUtils.createLocalesByKey(this.company_survey_extra_locale),
            text: () => this.localize('text'),
        };
    }

    modelRelations() {
        return {
            companySurveyExtraLocale: () => this.hasMany(CompanySurveyExtraLocale, 'company_survey_extra_id', 'id'),
        };
    }

    localize(field, languageKey, defaultLanguageKey) {
        return Vue.prototype.$modelUtils.localize(this.locales_by_key, field, languageKey, defaultLanguageKey);
    }
}
