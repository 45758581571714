<template>
    <div>
        <form class="form-2">
            <InputField
                :value="null"
                :type="'text'"
                :name="'response-text'"
                :placeholder="translate('User answer')"
                :disabled="true"
                :height="72"
            />
        </form>
    </div>
</template>

<script>
export default {
    name: 'QuestionText',
    props: {
        value: {
            type: Array,
            required: true,
        },
        questionIndex: {
            type: Number,
            required: false,
        },
        isCompleted: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
