import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserEntity from './CompanyUserEntity';

export default class CompanyUserEntityCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserEntity,
        };
    }
}
