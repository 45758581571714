export default class Localize {
    static install(Vue) {
        Vue.mixin({
            methods: {
                oldLocalize(locales, field) {
                    let locale = locales.find((locale) => locale.language_key === this.shared.session.companyUser.company_language.key);

                    if (locale && locale[field] && locale[field].length > 0) {
                        return locale[field];
                    }

                    locale = locales.find((locale) => locale.language_key === this.shared.session.company.company_language.key);

                    if (locale && locale[field] && locale[field].length > 0) {
                        return locale[field];
                    }

                    return null;
                },

                localize(locales) {
                    const value = locales[this.shared.session.companyUser.company_language.key] || locales[this.shared.session.company.company_language.key];

                    if (value) {
                        return value;
                    }

                    return null;
                },
            },
        });
    }
}
