<template>
    <div>
        <template v-if="companyUserDoc.isLoaded() && !hasError">
            <form
                class="form-2 spaced label-underline"
                data-cy="document-form"
                @submit.prevent=""
            >
                <fieldset v-if="companyUserDoc.description">
                    <label>
                        <t>Description</t>
                        : </label>
                    <p v-dompurify-html="companyUserDoc.description" />
                </fieldset>

                <fieldset v-if="companyUserDoc.company_file">
                    <label>
                        <t>File</t>
                    </label>
                    <div class="item-content">
                        <Downloadable :company-file="companyUserDoc.company_file" />
                    </div>
                </fieldset>

                <fieldset v-if="companyUserDoc.specific_information">
                    <label>
                        <t :firstname="companyUserDoc.company_user.firstname"> Specific information for {firstname}</t>
                        : </label>
                    <p> {{ companyUserDoc.specific_information }} </p>
                </fieldset>

                <fieldset v-if="shouldShowAvailabilityDate && companyUserDoc.company_user_program_task">
                    <label>
                        <t>To complete before</t>
                        : </label>
                    <div class="item-content">
                        {{ $Utils.moment(companyUserDoc.company_user_program_task.datetime_end).format('Do MMM') }}
                    </div>
                </fieldset>

                <fieldset>
                    <HtStatusLabel
                        :status="companyUserDoc.company_user_program_task.status"
                    />
                </fieldset>
                <div
                    v-if="!isCancelled"
                    class="modal-actions right"
                >
                    <Button
                        v-if="companyUserDoc.permissions.delete === true"
                        class="negative"
                        cypress="document-delete-button"
                        @click="onDelete()"
                    >
                        <t>Remove</t>
                    </Button>
                    <Button
                        v-if="companyUserDoc.permissions.update === true"
                        cypress="document-edit-button"
                        @click="openEditModal()"
                    >
                        <t>Edit</t>
                    </Button>
                    <Button
                        v-if="companyUserDoc.company_user_program_task.permissions.can_validate && canValidate"
                        cypress="document-toggle-status-button"
                        @click="toggleStatus()"
                    >
                        {{ toggleButtonLabel }}
                    </Button>
                </div>
            </form>
            <modalable
                ref="modalableUserEdit"
                class="modalable-1 medium"
                :mode="2"
            >
                <UserDocEdit
                    :id="id"
                    ref="userEdit"
                    :company-user-id="companyUserId"
                    :should-show-availability-date="shouldShowAvailabilityDate"
                    @onUpdate="onUpdate()"
                    @onDelete="onDelete(true)"
                />
            </modalable>
        </template>
        <template v-if="hasError">
            <ErrorDisplay :append-errors="'This resource is not accessible'" />
        </template>
    </div>
</template>
<script>
import HtStatusLabel from '@/pages/HtStatusLabel.vue';
import CompanyUserDoc from '@/models/CompanyUserDoc';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import UserDocEdit from './UserDocEdit.vue';
import CompanyUserProgramTask from '../../../../../models/CompanyUserProgramTask';

export default {
    name: 'UserDocItem',
    components: {
        HtStatusLabel,
        UserDocEdit,
    },
    inject: ['modal'],

    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        statusOnToggle: {
            type: String,
            default: CompanyUserProgramTask.STATUS_PENDING,
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        canValidate: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            hasError: false,

            companyUserDoc: new CompanyUserDoc([
                'id',
                'company_user_id',
                'name',
                'description',
                'specific_information',
                'permissions',
            ])
                .with({
                    companyUser: (query) => {
                        query.select(['id', 'firstname']);
                    },
                    companyUserProgramTask: (query) => {
                        query.select([
                            'id',
                            'resource',
                            'status',
                            'company_user_id',
                            'datetime_start',
                            'datetime_end',
                            'permissions',
                            'validator_type',
                        ]);
                    },
                    companyFile: (query) => {
                        query.select(UtilsCompanyFile.allowedFullFields());
                    },
                })
                .where([
                    ['id', '=', this.id],
                ]),
        };
    },

    computed: {
        /**
         * @returns {boolean}
         */
        isDone() {
            return this.companyUserDoc.company_user_program_task.status === CompanyUserProgramTask.STATUS_DONE;
        },

        labelTitle() {
            return this.translate(this.companyUserDoc.name);
        },

        toggleButtonLabel() {
            return this.translate(this.isDone ? 'Mark as unread' : 'Mark as read');
        },

        isCancelled() {
            return this.companyUserDoc.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },

    },

    watch: {
        'item._state.isSaving': function (val) {
            this.button_state = val ? 'loading' : 'idle';
        },
    },

    created() {
        this.companyUserDoc.get()
            .then(
                () => this.modal.setTitle(this.labelTitle),
                () => this.hasError = true,
            );
    },

    methods: {
        onUpdate() {
            this.modal.close();
            this.$emit('onUpdate');
        },

        onDelete(fromEdit = false) {
            if (fromEdit === true) {
                this.modal.close();
                this.$emit('onDelete');
            } else {
                this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this document? Once you click on delete, you will no longer be able to access this document.') }).then(
                    (response) => {
                        if (response === true) {
                            this.companyUserDoc.delete().then(
                                () => {
                                    this.modal.close();
                                    this.$emit('onDelete');
                                },
                            );
                        }
                    },
                );
            }
        },

        openEditModal() {
            this.$refs.modalableUserEdit.open();
        },

        async toggleStatus() {
            await this.$handleValidationTasks({
                id: this.companyUserDoc.company_user_program_task.id,
                status: this.isDone ? CompanyUserProgramTask.STATUS_PENDING : CompanyUserProgramTask.STATUS_DONE,
            });
            this.modal.close();
            this.$emit('onUpdate');
        },

    },
};
</script>
