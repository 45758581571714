<template>
    <MessageTextTemplate
        :text="companyChatChannelMessage.text"
        :is-html="companyChatChannelMessage.is_html"
        :is-me="isMe"
    />
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyChatChannelMessage from '@/models/CompanyChatChannelMessage';
import MessageTextTemplate from './MessageTextTemplate.vue';

export default {
    name: 'MessageText',
    components: {
        MessageTextTemplate,
    },
    props: {
        companyChatChannelMessage: {
            type: CompanyChatChannelMessage,
            required: true,
        },
    },
    computed: {
        isMe() {
            return this.shared.session.companyUser.id === this.companyChatChannelMessage.company_user_id;
        },
    },
    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },
};
</script>
