<template>
    <HtLayoutModal :can-close="false">
        <template #header>
            <p class="font-weight-bold">
                {{ title }}
            </p>
        </template>
        <template #main>
            <form @submit.prevent="editUser">
                <div class="form-group">
                    <HtFormInput
                        id="lastname"
                        v-model.trim="userForm.lastname"
                        v-validate.immediate="'required|min:2'"
                        :error-append="formErrors.lastname"
                        name="lastname"
                        :label="translate('Lastname')"
                        :data-vv-as="translate('lastname')"
                        :show-optional="false"
                        @input="formErrors.lastname = null"
                    />
                </div>

                <div class="form-group">
                    <HtFormInput
                        id="firstname"
                        v-model.trim="userForm.firstname"
                        v-validate.immediate="'required|min:2'"
                        :error-append="formErrors.firstname"
                        name="firstname"
                        :label="translate('Firstname')"
                        :data-vv-as="translate('firstname')"
                        :show-optional="false"
                        @input="formErrors.firstname = null"
                    />
                </div>

                <div class="form-group">
                    <HtFormInput
                        id="email"
                        v-model.trim="userForm.email"
                        v-validate.immediate="'required|email'"
                        :error-append="formErrors.email"
                        name="email"
                        :label="translate('Email')"
                        :data-vv-as="translate('email')"
                        :show-optional="false"
                        @input="formErrors.email = null"
                    />
                </div>

                <div class="form-group">
                    <HtFormSelector
                        id="offices"
                        v-validate.immediate="'required'"
                        name="offices"
                        specific-key="id"
                        :value="userForm.office"
                        :label="translate('Office')"
                        :options="getOfficeOptions"
                        :is-single="true"
                        :data-vv-as="translate('office')"
                        :allow-empty="false"
                        cypress="offices-list"
                        @input="setOffice"
                    />
                </div>

                <div class="form-group">
                    <HtFormSelector
                        id="job_positions"
                        v-validate.immediate="'required'"
                        name="job_positions"
                        specific-key="id"
                        :value="userForm.job_position"
                        :label="translate('Job position')"
                        :options="getJobPositionOptions"
                        :is-single="true"
                        :data-vv-as="translate('job position')"
                        :allow-empty="false"
                        cypress="job-positions-list"
                        @input="setJobPosition"
                    />
                </div>

                <div class="form-group">
                    <HtFormSelector
                        id="roles"
                        name="roles"
                        specific-key="id"
                        :value="userForm.roles"
                        :label="translate('Roles')"
                        :options="getRoleOptions"
                        :is-single="false"
                        :allow-empty="true"
                        cypress="roles-list"
                        @input="setRoles"
                    />
                </div>

                <div
                    v-if="shouldAddCodeColumn"
                    class="form-group"
                >
                    <HtFormInput
                        id="code"
                        v-model.trim="userForm.code"
                        v-validate.immediate="'min:5'"
                        :error-append="formErrors.code"
                        name="code"
                        :label="translate('Code')"
                        :data-vv-as="translate('code')"
                        @input="formErrors.code = null"
                    />
                </div>
            </form>
        </template>
        <template #footer>
            <HtButton
                type="secondary"
                @click.native="$emit('close')"
            >
                <t>Close</t>
            </HtButton>

            <HtButton @click.native="editUser">
                OK!
            </HtButton>
        </template>
    </HtLayoutModal>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';
import HtLayoutModal from '@/components/globals/modals/layouts/HtLayoutModal.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import CompanyRole from '@/models/CompanyRole';
import HtFormInput from '@/components/globals/HtFormInput.vue';

export default {
    name: 'EditUserModal',
    components: {
        HtFormInput,
        HtFormSelector,
        HtLayoutModal,
        HtButton,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            default: () => this.translate('Edit user: {user}', { user: `${this.user.firstname} ${this.user.lastname}` }),
        },
        initialFormErrorsFromServer: {
            type: Object,
            required: true,
        },
        shouldAddCodeColumn: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            userForm: { ...this.user, roles: this.user.roles.split(CompanyRole.ROLE_DELILMITER_IN_CSV) },
            formErrors: { ...this.initialFormErrorsFromServer },
        };
    },
    computed: {
        getRoleOptions() {
            return this.$store.getters['roles/sortedRoles']
                .filter((role) => role.name !== CompanyRole.NAME_EMPLOYEE)
                .map((role) => ({
                    id: role.alias,
                    name: role.alias,
                }));
        },
        getJobPositionOptions() {
            return this.$store.getters['entities/sortedjobValuesForFilter'].map((jobPosition) => ({
                id: jobPosition.name,
                name: jobPosition.name,
            }));
        },
        getOfficeOptions() {
            return this.$store.getters['entities/sortedOfficeValuesForFilter'].map((office) => ({
                id: office.name,
                name: office.name,
            }));
        },
    },
    mounted() {
        const selectedOffice = this.getOfficeOptions.find((office) => office.id === this.user.office);
        if (selectedOffice === undefined) {
            this.userForm.office = '';
        }

        const selectedJobPosition = this.getJobPositionOptions.find((jobPosition) => jobPosition.id === this.user.job_position);
        if (selectedJobPosition === undefined) {
            this.userForm.job_position = '';
        }

        this.userForm.roles = this.userForm.roles.filter(
            (role) => Boolean(this.getRoleOptions.find((roleOption) => role === roleOption.id)),
        );
    },
    methods: {
        editUser() {
            const hasError = this.$el.querySelector('.ht-form-error') !== null;

            if (hasError) {
                this.$Notifier('App').showError(this.translate('Fix errors and resubmit'));
                return;
            }

            this.$emit('close', { name: 'submit', value: this.userForm });
        },
        setRoles(newRoles) {
            this.userForm.roles = [...newRoles];
        },
        setJobPosition(newJobPosition) {
            this.userForm.job_position = newJobPosition;

            if (newJobPosition) {
                this.formErrors.job_position = null;
            }
        },
        setOffice(newOffice) {
            this.userForm.office = newOffice;

            if (newOffice) {
                this.formErrors.office = null;
            }
        },
    },
};
</script>
