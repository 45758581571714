<template>
    <ResourceListing
        ref="listing"
        api-name="softwares"
        :title="translate('Softwares')"
        :formatter="formatter"
        permission="ModelCompanySoftware"
        empty-message="There are no software to display yet..."
        has-quick-actions
        @on-action-clicked="onActionClicked"
    >
        <template #modalable="{ selectedResourceId }">
            <SoftwareEdit
                :id="selectedResourceId"
                :disabled-program-list="false"
                @onDelete="fetchData"
                @onSave="fetchData"
            />
        </template>
    </ResourceListing>
</template>

<script>
import api from '@/store/api';
import ResourceListing from '@/components/resources/generic/ResourceListing.vue';
import SoftwareEdit from '@/components/resources/software/SoftwareEdit.vue';

export default {
    permissions: [
        'ModelCompanySoftware',
        'PageSettingsIntegrations',
    ],

    components: {
        ResourceListing,
        SoftwareEdit,
    },

    methods: {
        fetchData() {
            this.$refs.listing.fetchData();
        },

        formatter(items) {
            return items.map((item) => {
                const quickActions = [];
                if (this.$canCreate('ModelCompanySoftware', { id: -1 })) {
                    quickActions.push({ id: 'replicate', label: 'Replicate', icon: ['fal', 'copy'] });
                }

                return {
                    ...item,
                    quick_actions: quickActions,
                };
            });
        },

        replicateSoftware(software) {
            api.configuration.softwares.replicate(software.id)
                .then(() => {
                    this.$Notifier('App').showInfo(this.translate('The resource << {resource_name} >> is replicated', {
                        resource_name: software.resource.name,
                    }));
                    this.fetchData();
                })
                .catch(() => {
                    this.$Notifier('App').showError(this.translate('The resource << {resource_name} >> is not replicated!', {
                        resource_name: software.resource.name,
                    }));
                });
        },

        onActionClicked({ item, actionId: action }) {
            switch (action) {
            case 'replicate':
                this.replicateSoftware(item);
                break;
            }
        },
    },
};
</script>
