import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAddressCard as falAddressCard,
    faArrowToBottom as falArrowToBottom,
    faBolt as falBolt,
    faBrowser as falBrowser,
    faCalendarAlt as falCalendarAlt,
    faCalendarPlus as falCalendarPlus,
    faCamera as falCamera,
    faChartBar as falChartBar,
    faChevronCircleDown as falChevronCircleDown,
    faChevronCircleRight as falChevronCircleRight,
    faChevronDown as falChevronDown,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faChevronUp as falChevronUp,
    faEdit as falEdit,
    faEnvelope as falEnvelope,
    faExclamationCircle as falExclamationCircle,
    faFile as falFile,
    faFire as falFire, faImage,
    faLevelUp as falLevelUp,
    faLightbulb as falLightbulb,
    faLongArrowLeft as falLongArrowLeft, faPaperclip,
    faPaperPlane,
    faPlus as falPlus,
    faPlusCircle as falPlusCircle,
    faPoll as falPoll,
    faQuestionCircle as falQuestionCircle,
    faQuestionSquare as falQuestionSquare,
    faSpinnerThird as falSpinnerThird,
    faSuitcase as falSuitcase,
    faTimes as falTimes, faUser as falLightUser,
    faUserFriends as falUserGroup, faUsers as falLightUsers,
    faUserMinus as falUserMinus,
    faVideo, faWrench, faCopy,
    faDatabase,
    faBuilding as falBuilding,
    faTag as falTag,
    faClone as falClone,
    faFilePdf,
    faFileExcel,
} from '@fortawesome/pro-light-svg-icons';
import {
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowRight, faBars, faBuilding,
    faCheck,
    faCheckCircle, faChevronCircleRight,
    faChevronDown, faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle, faCircleNotch, faCogs,
    faCommentAlt,
    faDesktop,
    faEdit, faEllipsisH, faEuroSign,
    faExclamationTriangle,
    faInfoCircle, faLock,
    faLongArrowAltRight,
    faMinus, faMinusCircle, faPen,
    faPencilAlt,
    faPlay, faPlayCircle,
    faPlus,
    faPlusCircle,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner, faStar, faSync,
    faTimes,
    faTimesCircle, faTrash,
    faUser,
    faUsers,
    faExpandAlt,
    faCompressAlt,
    faSpellCheck,
    faMagic,
    faMicrophoneAlt,
    faBroom,
    faQuestion,
    faList as fasList,
} from '@fortawesome/free-solid-svg-icons';
import {
    faArrowAltCircleDown as farArrowAltCircleDown,
    faArrowAltCircleRight as farArrowAltCircleRight, faBell as farBell, faCheckCircle as farCheckCircle,
    faCircle as farCircle,
    faClock, faClone as farClone,
    faCommentAlt as farCommentAlt, faEye as farEye, faEyeSlash as farEyeSlash,
    faFileAlt as farFileAlt, faStar as farStar,
    faTimesCircle as farTimesCircle, faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import {
    faArrowLeft as farArrowLeft,
    faBuilding as farBuilding,
    faCog as farCog,
    faEllipsisH as farEllipsisH,
    faEllipsisV as farEllipsisV, faExclamationCircle as farExclamationCircle,
    faExclamationTriangle as farExclamationTriangle,
    faFilter as farFilter,
    faInfoCircle as farInfoCircle,
    faLink as farLink, faLongArrowDown as farLongArrowDown, faLongArrowUp as farLongArrowUp,
    faPowerOff as farPowerOff,
    faSearch as farSearch, faSortAlt as farSortAlt,
    faStopwatch as farStopwatch,
    faUser as farUser,
    faUsers as farUsers,
    faChartNetwork,
    faPalette as farPalette,
    faAlarmClock,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faBrowser as fadBrowser,
    faBug as fadBug,
    faCalendarAlt as fadCalendarAlt,
    faEnvelope as fadEnvelope,
    faFileAlt as fadFileAlt,
    faGraduationCap as fadGraduationCap, faPaperclip as fadPaperclip,
    faPhoneLaptop as fadPhoneLaptop,
    faPoll as fadPoll,
    faQuestionSquare as fadQuestionSquare,
    faScrewdriver as fadScrewdriver,
    faSirenOn as fadSirenOn,
    faSmileWink as fadSmileWink,
    faTasks as fadTasks,
    faTrash as fadTrash,
    faUserCircle as fadUserCircle,
} from '@fortawesome/pro-duotone-svg-icons';
import { faDoNotEnter, faPencil as fasPencil } from '@fortawesome/pro-solid-svg-icons';

library.add(
    faPaperPlane,
    faVideo,
    faPencilAlt,
    farTimesCircle,
    faChevronUp,
    faChevronDown,
    faChevronRight,
    faCommentAlt,
    farCommentAlt,
    farFileAlt,
    faExpandAlt,
    faCompressAlt,
    faSpellCheck,
    faMagic,
    faMicrophoneAlt,
    faBroom,
    faTimes,
    faCheck,
    faTimesCircle,
    faArrowRight,
    faClock,
    faInfoCircle,
    farInfoCircle,
    faPlusCircle,
    farArrowAltCircleDown,
    farArrowAltCircleRight,
    farCircle,
    farCheckCircle,
    faCheckCircle,
    faExclamationTriangle,
    farExclamationTriangle,
    faArrowCircleLeft,
    faArrowCircleRight,
    faEdit,
    falEdit,
    faTrashAlt,
    faCircle,
    faLongArrowAltRight,
    faSpinner,
    faPlus,
    faMinus,
    faUser,
    farUser,
    farPalette,
    faUsers,
    faDesktop,
    farEye,
    faPlay,
    faSort,
    faSortUp,
    faSortDown,
    faBars,
    farBell,
    farClone,
    faChevronCircleRight,
    falChevronCircleDown,
    falChevronCircleRight,
    faChevronLeft,
    fadCalendarAlt,
    fadQuestionSquare,
    fadTasks,
    fadBrowser,
    fadGraduationCap,
    fadPhoneLaptop,
    fadPoll,
    fadSmileWink,
    fadEnvelope,
    fadFileAlt,
    fadFileAlt,
    fadUserCircle,
    falTimes,
    falPlus,
    falChevronUp,
    falChevronDown,
    falChevronRight,
    falChevronLeft,
    falPoll,
    falQuestionCircle,
    falSpinnerThird,
    falLongArrowLeft,
    faStar,
    faTrash,
    faCogs,
    falPlusCircle,
    falArrowToBottom,
    fadTrash,
    farArrowLeft,
    farEllipsisV,
    farEllipsisH,
    farFilter,
    faPen,
    faBuilding,
    farBuilding,
    farEye,
    farEyeSlash,
    farCog,
    farLink,
    farStar,
    farPowerOff,
    falLevelUp,
    farUser,
    fadBug,
    falFile,
    fasPencil,
    farSearch,
    faSync,
    farStopwatch,
    faLock,
    faEuroSign,
    fadSirenOn,
    fadScrewdriver,
    fadPaperclip,
    faEllipsisH,
    falUserGroup,
    falCamera,
    falExclamationCircle,
    farExclamationCircle,
    falLightbulb,
    faDoNotEnter,
    faCircleNotch,
    faMinusCircle,
    falFire,
    falBolt,
    falAddressCard,
    falSuitcase,
    falChartBar,
    falQuestionSquare,
    falBrowser,
    falEnvelope,
    falCalendarAlt,
    falCalendarPlus,
    faImage,
    faPlayCircle,
    faWrench,
    falLightUser,
    falLightUsers,
    falUserMinus,
    faPaperclip,
    farLongArrowUp,
    farLongArrowDown,
    farSortAlt,
    faCopy,
    fasList,
    faDatabase,
    falBuilding,
    falTag,
    falClone,
    faFilePdf,
    faFileExcel,
    faQuestion,
    farUsers,
    faChartNetwork,
    faAlarmClock,
    farCheckCircle,
);
