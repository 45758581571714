<template>
    <HtCard>
        <SecurityList />
    </HtCard>
</template>

<script>
import SecurityList from '@/components/resources/security/SecurityList.vue';

export default {
    name: 'PageSecurity',
    components: {
        SecurityList,
    },
};
</script>
