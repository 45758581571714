<template>
    <div class="h-100">
        <HtCard
            v-if="isLoaded"
        >
            <template #title>
                <HtCardTitleBar
                    :title="title"
                    :show-button="true"
                    :button-text="translate('Create role')"
                    @onAdd="openRoleForm"
                    @search="setSearchValue"
                />
            </template>
            <template #default>
                <HtTable
                    :items="roles"
                    :columns="roleColumns"
                    :empty-message="'No roles...'"
                    :external-search="search"
                    @onCellClicked="onRowClicked"
                >
                    <template #head(alias)="scope">
                        {{ scope.column.label }}
                        <span
                            v-tooltip.right-end="translate('A user can have multiple roles. He will have the highest permission level allowed.')"
                            class="px-2 position-relative cursor-pointer"
                        >
                            <FontAwesomeIcon icon="info-circle" />
                        </span>
                    </template>
                    <template #cell(alias)="scope">
                        {{ scope.item.alias_translated }}
                    </template>
                </HtTable>
                <HtTable
                    :items="statuses"
                    :columns="statusesColumns"
                    :show-header="false"
                    class="mt-5"
                    :empty-message="'No statuses...'"
                    @onCellClicked="onRowClicked"
                >
                    <template #cell(alias)="scope">
                        <div class="mb-2">
                            {{ scope.item.alias_translated }}
                        </div>
                        <div :style="{ color: 'rgba(74, 74, 74, 0.5)' }">
                            <t>{{ scope.item.description }}</t>
                        </div>
                    </template>
                    <template #cell(company_role_user_count)="scope">
                        {{ presentCount(scope.item.company_role_user_count) }}
                    </template>
                </HtTable>
            </template>
        </HtCard>
    </div>
</template>

<script>
import HtCardTitleBar from '../../../components/globals/HtCardTitleBar.vue';
import HtTable from '../../../components/globals/HtTable.vue';
import CompanyRoleCollection from '../../../models/CompanyRoleCollection';

export default {
    components: { HtCardTitleBar, HtTable },
    data() {
        return {
            show: null,
            search: null,
            roleColumns: [
                {
                    key: 'alias',
                    search: true,
                    label: this.translate('Role'),
                },
                {
                    label: this.translate('Members'),
                    key: 'company_role_user_count',
                    class: 'w-25',
                },
            ],

            statusesColumns: [
                {
                    key: 'alias',
                },
                {
                    key: 'company_role_user_count',
                    class: 'w-25',
                },
            ],

            companyRoleCollection: new CompanyRoleCollection([
                'id',
                'alias',
                'name',
                'description',
                'is_heyteam',
                'company_role_user_count',
                'is_assignable_program',
            ]),
        };
    },

    computed: {
        title() {
            return this.translate('{count} role | {count} roles', {
                count: this.companyRoleCollection.models.length,
            });
        },
        isLoaded() {
            return this.companyRoleCollection.isLoaded();
        },
        roles() {
            return this.companyRoleCollection.models.filter((role) => role.is_assignable_program);
        },
        statuses() {
            let roles = this.companyRoleCollection.models.filter((role) => role.is_assignable_program === false);

            if (this.$env.get('APP_ENV') === 'production') {
                roles = roles.filter((role) => role.name !== 'guest');
            }

            return roles;
        },
    },

    created() {
        this.companyRoleCollection.get();
    },

    methods: {
        onRowClicked(row) {
            return this.$router.push({
                name: 'SettingsRolesAndPermissionsForm',
                params: { id: row.item.id },
            });
        },

        openRoleForm() {
            return this.$router.push({
                name: 'SettingsRolesAndPermissionsForm',
            });
        },

        toggleShow(classPermission) {
            this.show = classPermission;
        },

        setSearchValue(val) {
            this.search = val;
        },

        presentCount(count) {
            if (count === 0) {
                return this.translate('No member');
            }

            if (count === 1) {
                return `${count} ${this.translate('member')}`;
            }

            return `${count} ${this.translate('members')}`;
        },
    },
};
</script>
