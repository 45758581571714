<template>
    <div class="image-picker">
        <div
            class="image-picker-image"
            :style="customStyle"
            :class="{'filled':imageSrc}"
        >
            <div
                v-if="!imageSrc"
                class="image-picker-image-placeholder"
                @mousedown="$refs.fileSelector.openFileSelector()"
            >
                <div class="image-picker-image-placeholder-icon">
                    <FontAwesomeIcon :icon="['fal','camera']" />
                </div>
                <div class="image-picker-image-placeholder-text">
                    <t>Add an image</t>
                </div>
            </div>
        </div>
        <UploadImage
            ref="fileSelector"
            @onUploadEnd="imageChange"
        />
    </div>
</template>

<script>
import UploadImage from '@/components/tools/UploadImage.vue';

export default {
    name: 'RoundImagePicker',
    components: {
        UploadImage,
    },
    props: {
        imageSrc: String,
    },
    computed: {
        customStyle() {
            if (this.imageSrc) {
                return `background-image: url(${this.imageSrc});`;
            }
            return '';
        },
    },
    methods: {
        openFileSelector() {
            this.$refs.fileSelector.openFileSelector();
        },
        async imageChange(file) {
            this.$emit('image-change', file);
        },
        clear() {
            this.$refs.fileSelector.value = null;
        },
    },
};
</script>

<style lang="scss" scoped>

.image-picker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15rem;
        height: 15rem;
        border: solid var(--branding-color) .5px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        background-color: white;

        &.filled {
            border: none;
        }

        &-placeholder {
            cursor: pointer;
            color: var(--branding-color);
            text-align: center;

            &-icon {
                margin-bottom: 10px;
            }
        }
    }

    &-actions {
        cursor: pointer;
        margin-top: 10px;
    }
}

</style>
