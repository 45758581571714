<template>
    <HtLayoutModal @close="$emit('close')">
        <template #header>
            <t>Add users</t>
        </template>

        <template #main>
            <div class="type-addition-selector">
                <HtFormRadio
                    id="type"
                    v-model="selectedType"
                    name="type"
                    :show-optional="false"
                    :label="translate('Type of addition')"
                    :options="types"
                    class="mb-0"
                />
            </div>
        </template>

        <template #footer>
            <HtButton
                type="secondary"
                @click.native="$emit('previous')"
            >
                <t>Back</t>
            </HtButton>

            <HtButton
                @click.native="$emit('next')"
            >
                <t>Next</t>
            </HtButton>
        </template>
    </HtLayoutModal>
</template>

<script>
import I18n from '@/modules/i18n/I18n';
import HtFormRadio from '@/components/globals/HtFormRadio.vue';
import HtButton from '@/components/globals/HtButton.vue';
import HtLayoutModal from '@/components/globals/modals/layouts/HtLayoutModal.vue';

export default {
    name: 'ProgramAdditionTypeConfiguration',

    components: {
        HtLayoutModal,
        HtButton,
        HtFormRadio,
    },

    props: {
        programId: {
            type: Number,
            required: false,
            default: () => null,
        },
    },

    data() {
        return {
            selectedType: 'by_filter',
            types: [
                {
                    value: 'by_name',
                    text: I18n.translate('Add users by names'),
                    description: I18n.translate('Invite users using their names'),
                },
                {
                    value: 'by_filter',
                    text: I18n.translate('Add users by filters'),
                    description: I18n.translate('Use application filters to select users'),
                },
                {
                    value: 'new_user',
                    text: I18n.translate('Invite new user'),
                    description: I18n.translate('Invite a new user with their email address'),
                },
            ],
        };
    },

    watch: {
        selectedType() {
            this.$emit('on-update', 'addition_type', this.selectedType);
        },
    },
};
</script>

<style lang="scss">
@import '~@/styles/var';

.type-addition-selector {
    .ht-checkbox {
        border: 1px solid $grey-1;
        background-color: $neutral-white;
        border-radius: 8px;

        &-item {
            padding: 12px;
            margin: 0;

            &:not(:last-child) {
                border-bottom: 1px solid $grey-1;
            }

            &-name {
                display: flex;
                flex-direction: column;
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                color: $neutral-800;

                &-description {
                    font-size: 12px;
                    line-height: 20px;
                    color: $neutral-600;
                }
            }
        }
    }
}
</style>
