<template>
    <div>
        <HtFormSelector
            v-for="(entity, index) in entitiesLinkedToProgram"
            :id="entity.slug"
            :key="index"
            v-model="choosenEntities[entity.slug]"
            v-validate.disable="getValidationRule(choosenEntities[entity.slug])"
            :name="entity.slug"
            :label="translate(entity.name)"
            :placeholder="translate(entity.name)"
            :options="getSortedValues(valuesByEntityId[entity.id])"
            :label-options="'name'"
            :is-single="true"
            :allow-empty="false"
            track-by="company_entity_value_id"
            :data-vv-as="translate(entity.name)"
            :disabled="disabled"
            :search-value.sync="searchFilter"
            :cypress="`entity-select-${entity.slug}`"
            :taggable="canCreateEntityValue"
            :show-optional="showOptional"
            :show-asterisk="showAsterisk"
            @change="$emit('input', choosenEntities)"
            @createOption="(value) => {createOption(entity, value)}"
        />
    </div>
</template>

<script>
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import modalMixin from '@/components/globals/modals/modalMixin';

export default {
    name: 'HtFormMultiselectFilter',
    components: {
        HtFormSelector,
    },

    mixins: [modalMixin],

    inject: ['$validator'],

    props: {
        value: {
            type: Object,
            required: true,
        },
        entitiesLinkedToProgramIds: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showOptional: {
            type: Boolean,
            default: false,
        },
        showAsterisk: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            searchFilter: null,
            choosenEntities: this.value,
        };
    },

    computed: {
        canCreateEntityValue() {
            return this.$can('PageSettingsEntities');
        },
        entitiesLinkedToProgram() {
            return this.$store.getters['entities/getEntitiesFromIds'](this.entitiesLinkedToProgramIds);
        },
        valuesByEntityId() {
            return this.$store.getters['entities/valuesByEntityId'];
        },
    },

    methods: {
        getValidationRule(userEntityValue) {
            return !this.disabled || Boolean(userEntityValue) ? 'required' : '';
        },
        createOption(entity, value) {
            this.openEntityModal('addEntityModal', entity, value).then((params) => {
                this.$store.dispatch('entities/forceFetchEntities').then(() => {
                    const chosenValue = this.valuesByEntityId[entity.id]
                        .find((entityValue) => entityValue.value_resourceable_id === params.id);

                    if (chosenValue) {
                        this.$set(this.choosenEntities, entity.slug, chosenValue);
                        this.$emit('input', this.choosenEntities);
                    }
                });
            });
        },
        getSortedValues(values) {
            return values.map((x) => x).sort((a, b) => a.name.localeCompare(b.name));
        },
    },
};
</script>
