import Model from '@tony.caron/node-model-proxy/Model';
import HtProgramKeyDateLocaleCollection from './HtProgramKeyDateLocaleCollection';
import HtProgramKeyDate from './HtProgramKeyDate';

export default class HtProgramKeyDateLocale extends Model {
    modelConfig() {
        return {
            collection: HtProgramKeyDateLocaleCollection,
        };
    }

    modelRelations() {
        return {
            keyDate: () => this.belongsTo(HtProgramKeyDate, 'company_program_id', 'id'),
        };
    }
}
