<template>
    <div class="task-timeline-simulation">
        <div
            class="task-timeline-simulation-head"
            :style="{ background: period.color }"
            @click="isToggled = !isToggled"
        >
            <div class="task-timeline-simulation-head-title">
                {{ getPeriodLabel }}
            </div>
            <div class="d-flex align-items-center">
                <div class="task-timeline-simulation-head-subtitle">
                    <t :count="tasks.length">
                        {count} action | {count} actions
                    </t>
                </div>
                <div class="ml-20 task-timeline-simulation-head-icon">
                    <FontAwesomeIcon :icon="getToggleIcon" />
                </div>
            </div>
        </div>
        <transition
            name="slide"
            tag="div"
            class="task-timeline-simulation-body"
        >
            <div v-if="isToggled">
                <div
                    v-for="(taskList, indexList) in getGroupedTasks"
                    :key="indexList"
                >
                    <TaskSimulation
                        v-for="(task, index) in taskList"
                        :key="index"
                        :timeline-color="period.color"
                        :task="task"
                        :key-date="keyDate"
                        :use-key-date="useKeyDate"
                        :can-edit="canEdit"
                        @onSave="$emit('onSave')"
                        @onDelete="$emit('onDelete')"
                    />

                    <template v-if="taskList.groupedRequirements">
                        <TaskSimulation
                            is-wrapper
                            :timeline-color="period.color"
                            :task="companyRequirementTask(taskList.groupedRequirements[0])"
                            :key-date="keyDate"
                            :use-key-date="useKeyDate"
                            :can-edit="canEdit"
                            @onToggle="e => companyRequirementTaskToggle = e"
                            @onSave="$emit('onSave')"
                            @onDelete="$emit('onDelete')"
                        />
                        <transition-group name="slide">
                            <template v-if="companyRequirementTaskToggle">
                                <TaskSimulation
                                    v-for="(task, index) in taskList.groupedRequirements"
                                    :key="task.resource+'-'+index"
                                    is-wrap
                                    :timeline-color="period.color"
                                    :task="task"
                                    :use-key-date="useKeyDate"
                                    :key-date="keyDate"
                                    :can-edit="canEdit"
                                    @onSave="$emit('onSave')"
                                    @onDelete="$emit('onDelete')"
                                />
                            </template>
                        </transition-group>
                    </template>

                    <template v-if="taskList.groupedEquipments">
                        <TaskSimulation
                            is-wrapper
                            :timeline-color="period.color"
                            :task="companyEquipmentTask(taskList.groupedEquipments[0])"
                            :key-date="keyDate"
                            :use-key-date="useKeyDate"
                            :can-edit="canEdit"
                            @onToggle="e => companyEquipmentTaskToggle = e"
                            @onSave="$emit('onSave')"
                            @onDelete="$emit('onDelete')"
                        />
                        <transition-group name="slide">
                            <template v-if="companyEquipmentTaskToggle">
                                <TaskSimulation
                                    v-for="(task, index) in taskList.groupedEquipments"
                                    :key="task.resource+'-'+index"
                                    is-wrap
                                    :timeline-color="period.color"
                                    :task="task"
                                    :use-key-date="useKeyDate"
                                    :key-date="keyDate"
                                    :can-edit="canEdit"
                                    @onSave="$emit('onSave')"
                                    @onDelete="$emit('onDelete')"
                                />
                            </template>
                        </transition-group>
                    </template>
                </div>
                <div
                    v-if="canAddResource"
                    class="d-flex align-items-center task-timeline-simulation-add"
                    @click="openAddResource"
                >
                    <div
                        :class="[
                            'd-flex',
                            'align-items-center',
                            'justify-content-center',
                            'task-timeline-simulation-add-button',
                            'branding-bg'
                        ]"
                    >
                        <FontAwesomeIcon :icon="['fal', 'plus']" />
                    </div>
                    <div class="ml-4 branding-color task-timeline-simulation-add-text">
                        <t>Add element</t>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import TaskSimulation from './TaskSimulation.vue';

export default {
    name: 'TaskTimelineSimulation',
    components: {
        TaskSimulation,
    },

    props: {
        period: {
            type: Object,
            required: true,
        },
        tasks: {
            type: Array,
            required: true,
        },
        keyDate: {
            type: String,
            default: null,
        },
        keyDateName: {
            type: String,
            required: true,
        },
        useKeyDate: {
            type: Boolean,
            required: true,
        },
        canAddResource: {
            type: Boolean,
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: () => true,
        },
    },

    data() {
        return {
            isToggled: true,
            companyEquipmentTaskToggle: false,
            companyRequirementTaskToggle: false,
        };
    },

    computed: {
        getGroupedTasks() {
            const sortArray = {};

            this.tasks.forEach((task) => {
                const date = task.order_date;
                const type = task.resource;

                if (!sortArray[date]) {
                    sortArray[date] = [];
                }

                if (type === 'company_requirement_category') {
                    if (!sortArray[date].groupedRequirements) {
                        sortArray[date].groupedRequirements = [];
                    }
                    sortArray[date].groupedRequirements.push(task);
                } else if (type === 'company_equipment') {
                    if (!sortArray[date].groupedEquipments) {
                        sortArray[date].groupedEquipments = [];
                    }
                    sortArray[date].groupedEquipments.push(task);
                } else {
                    sortArray[date].push(task);
                }
            });

            Object.keys(sortArray).forEach((date) => {
                if (sortArray[date].groupedRequirements && sortArray[date].groupedRequirements.length === 1) {
                    sortArray[date].push(sortArray[date].groupedRequirements[0]);
                    delete sortArray[date].groupedRequirements;
                }
                if (sortArray[date].groupedEquipments && sortArray[date].groupedEquipments.length === 1) {
                    sortArray[date].push(sortArray[date].groupedEquipments[0]);
                    delete sortArray[date].groupedEquipments;
                }
            });

            return sortArray;
        },

        getToggleIcon() {
            return this.isToggled ? ['fal', 'chevron-up'] : ['fal', 'chevron-down'];
        },
        getPeriodLabel() {
            return this.translate(this.period.label, {
                key_date_name: this.keyDateName,
            });
        },
    },

    methods: {
        companyRequirementTask(task) {
            return {
                resource: 'company_requirement_category',
                name: this.translate('Administrative file to be completed'),
                filters: [],
                participants: task.participants,
                order_availability: null,
                order_onboarding_start: task.resourceProgram.order_start,
                order_onboarding_end: task.resourceProgram.order_end,
                prefix: task.prefix,
            };
        },
        companyEquipmentTask(task) {
            return {
                resource: 'company_equipment',
                name: this.translate('Install all of the following equipment'),
                filters: [],
                participants: task.participants,
                order_availability: null,
                order_onboarding_start: task.resourceProgram.order_start,
                order_onboarding_end: task.resourceProgram.order_end,
                prefix: task.prefix,
            };
        },
        openAddResource() {
            this.$emit('onOpenAddResource', this.period.orderStart, this.period.orderEnd);
        },
    },
};
</script>

<style lang="scss" scoped src="./TaskTimelineSimulation.scss"></style>
