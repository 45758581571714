<template>
    <div
        v-if="active"
        class="h-100"
    >
        <slot name="default" />
    </div>
</template>

<script>
export default {
    name: 'HtTab',
    inject: ['tabs'],
    props: {
        title: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        noBody: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            active: false,
        };
    },
    created() {
        this.tabs.addTab(this);
    },
    beforeDestroy() {
        this.tabs.removeTab(this);
    },
    methods: {
        setActive() {
            this.tabs.setActive(this);
        },
    },
};
</script>
