<template>
    <div>
        <CompanyEnterprisePage v-if="newEnterprisePageEnabled" />
        <template v-else-if="companyGeneral.isLoaded()">
            <HtPageTitle>
                <t>Company</t>
            </HtPageTitle>
            <div class="bottom-header" />

            <div class="company-container">
                <div
                    v-if="!modePicture"
                    class="wrapper-half"
                >
                    <div class="block-item">
                        <h4 class="big">
                            <t>About us</t>
                        </h4>
                        <p v-dompurify-html="company.about" />
                    </div>
                    <div
                        v-if="!modePicture"
                        class="block-item"
                    >
                        <h4 class="big">
                            <t>Our mission</t>
                        </h4>
                        <p v-dompurify-html="company.mission" />
                    </div>
                </div>

                <!-- SHOW COMPANY CAROUSSEL -->
                <template v-if="carousels.length">
                    <div
                        v-for="(carousel, index) in carousels"
                        :key="index"
                        class="block-item"
                    >
                        <h4 class="big">
                            {{ getCarouselTitle(carousel) }}
                        </h4>
                        <div class="photos">
                            <carousel
                                :per-page="1"
                                :navigation-enabled="true"
                            >
                                <slide
                                    v-for="item in carousel.fullItems"
                                    :key="item.id"
                                    class="slide"
                                >
                                    <img
                                        v-if="item.type === 'file' && getFile(item)"
                                        :src="getLink(item)"
                                        @click="onItemClick(item)"
                                    >
                                    <div
                                        v-if="item.type === 'link'"
                                        class="link-video video"
                                        :style="'background-size: cover;background-repeat: no-repeat;background-position:center;background-image: url(' + item.previewUrl + ')'"
                                        @click="onItemClick(item)"
                                    >
                                        <svg class="icon icon-play-invert">
                                            <use xlink:href="#icon-play-invert" />
                                        </svg>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div>
                </template>

                <div
                    v-if="!modePicture"
                    class="wrapper-half"
                >
                    <div
                        v-if="companyValues.length > 0"
                        class="block-item purple"
                    >
                        <h3 class="small">
                            <t>Company values</t>
                        </h3>
                        <div class="photos no-margin">
                            <carousel
                                :per-page="1"
                                :navigation-enabled="companyValues.length > max_values"
                                class="wrapper-carousel"
                            >
                                <slide
                                    v-for="(value, index) in companyValues"
                                    :key="index"
                                >
                                    <div class="container">
                                        <template v-if="value.is_heyteam">
                                            <t class="valeur-title fix-bottom">
                                                {{ value.name }}
                                            </t>
                                            <t>{{ value.description }}</t>
                                        </template>
                                        <template v-else>
                                            <span class="valeur-title fix-bottom">{{ value.name }}</span>
                                            {{ value.description }}
                                        </template>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div>
                    <div
                        v-if="companyBenefits.length > 0"
                        class="block-item green"
                    >
                        <h3 class="small">
                            <t>Benefits</t>
                        </h3>
                        <div class="photos no-margin">
                            <carousel
                                :per-page="1"
                                :navigation-enabled="companyBenefits.length > max_benefits"
                            >
                                <slide
                                    v-for="(value, benefitIndex) in benefit"
                                    :key="benefitIndex"
                                >
                                    <div class="container">
                                        <div :class="{'multiple-inside': benefit[benefitIndex].length !== 1}">
                                            <div
                                                v-for="(item, index) in benefit[benefitIndex]"
                                                :key="index"
                                                class="inside-card line text-center"
                                            >
                                                <template v-if="item.is_heyteam">
                                                    <t>{{ item.name }}</t>
                                                </template>
                                                <template v-else>
                                                    {{ item.name }}
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="!modePicture && companyDocumentPublics.isLoaded() && companyDocumentPublics.$.models.length"
                class="block-item"
            >
                <h4 class="big">
                    <t>Company documents</t>
                </h4>
                <div class="card-1 flex-container">
                    <div
                        v-for="(item, index) in companyDocumentPublics.$.models"
                        :key="index"
                        class="item selectable"
                    >
                        <div class="item-content">
                            <div
                                class="content"
                                @click="openDocument(item.active)"
                            >
                                <div class="wrapper-icon">
                                    <div
                                        class="image"
                                        :style="`background-image:url(${item.image})`"
                                    />
                                </div>
                                <div class="item-text">
                                    <div>{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <modalable
                ref="modalableMedia"
                class="modalable-1 big"
            >
                <CarouselItem ref="modal" />
            </modalable>

            <modalable
                ref="modalableDocumentPublic"
                class="modalable-1 small"
            >
                <DocumentItem
                    ref="documentItem"
                />
            </modalable>
        </template>
    </div>
</template>

<script>

import { Carousel, Slide } from 'vue-carousel';
import HtPageTitle from '@/components/globals/HtPageTitle.vue';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import api from '@/store/api';
import CompanyEnterprisePage from '@/pages/CompanyEnterprisePage.vue';
import Company from '../models/Company';
import CompanyCustomizeCollection from '../models/CompanyCustomizeCollection';
import CarouselItem from '../components/resources/carousel/CarouselItem.vue';
import DocumentItem from '../components/documents/DocumentItem.vue';
import CompanyDocumentPublicCollection from '../models/CompanyDocumentPublicCollection';

export default {
    name: 'Company',
    components: {
        CompanyEnterprisePage,
        Carousel,
        Slide,
        CarouselItem,
        DocumentItem,
        HtPageTitle,
    },
    data() {
        return {
            max_values: 1,
            max_benefits: 6,
            carousels: [],

            companyGeneral: new CompanyCustomizeCollection([
                'id', 'company_id', 'type', 'content', 'subtype', 'is_enabled',
            ])
                .where([
                    ['type', '=', 'page'], ['subtype', '=', 'company'],
                ]),

            modePicture: false,

            companyDocumentPublics: new CompanyDocumentPublicCollection([
                'id',
                'name',
                'editable',
                'type',
                'url',
                'description',
                'image',
                'url',
                'translations',
                'file_translations',
            ]).with({
                companyFilesFromLocale: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
                companyFiles: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
            }),

            company: new Company(['id', 'name', 'image', 'cover', 'about', 'mission', 'founded_year', 'employees', 'translations'])
                .with({
                    companyDepartment: (query) => {
                        query.select(['id', 'name']);
                    },
                    companyOffice: (query) => {
                        query.select(['id', 'name']);
                    },
                    companyBenefit: (query) => {
                        query.select(['id', 'name', 'editable', 'is_heyteam', 'translations']);
                    },
                    companyValue: (query) => {
                        query.select(['id', 'name', 'description', 'editable', 'is_heyteam', 'translations']);
                    },
                })
                .orderBy([['name', 'asc']]),

            benefit: [],
        };
    },
    computed: {
        companyBenefits() {
            if (!('company_benefit' in this.company) || this.company.company_benefit.models.length === 0) {
                return [];
            }

            return this.company.company_benefit.$.models.filter((i) => i.name !== '');
        },
        companyValues() {
            if (!('company_value' in this.company) || this.company.company_value.models.length === 0) {
                return [];
            }

            return this.company.company_value.$.models.filter((i) => i.name);
        },

        userLanguageKey() {
            return this.shared.session.companyUser.company_language.key;
        },
        defaultLanguageKey() {
            return this.shared.session.company.company_languages.models
                .find((language) => language.is_default).key;
        },
        newEnterprisePageEnabled() {
            return this.$store.state.config.features.find((feature) => feature.key === 'new_enterprise_page')?.is_enabled || false;
        },
    },
    async created() {
        this.setPage({
            title: this.shared.session.company.name,
            subtitles: [this.translate('General Information')],
        });

        if (!this.newEnterprisePageEnabled) {
            await this.companyDocumentPublics.get();

            await this.company.get().then(() => {
                let listBenefit = [];
                const benefits = this.company.company_benefit.models.filter((i) => i.name !== '');
                benefits.filter((i) => i.name !== '').forEach((item, index) => {
                    listBenefit.push(item);
                    if ((index + 1) % 6 === 0 || (index + 1) === benefits.length) {
                        this.benefit.push(listBenefit);
                        listBenefit = [];
                    }
                });
            });

            await this.companyGeneral.get();
            this.modePicture = this.companyGeneral.models[0].content.mode_picture;

            this.loadCarousel();
        }
    },
    methods: {
        getLink(item) {
            const file = this.getFile(item);
            return this.$Utils.resolveS3PublicImage(file.path + file.name);
        },

        async loadCarousel() {
            const response = await this.$http.get(api.endpoints.CAROUSELS_BY_COMPANY);
            this.carousels = response.data.carousels;
            this.carousels = await Promise.all(this.carousels.map(async (carousel) => {
                carousel.fullItems = await Promise.all(carousel.fullItems.map(async (item) => {
                    if (item.type === 'link') {
                        item.previewUrl = await this.$Utils.getVideoThumbnail(this.getUrl(item));
                    }

                    return item;
                }));
                return carousel;
            }));
        },

        onItemClick(item) {
            const tmpItem = {
                type: item.type,
                file: this.getFile(item),
                url: this.getUrl(item),
            };
            this.$refs.modal.init(tmpItem);
            this.$refs.modalableMedia.open();
        },

        openDocument(item) {
            this.$refs.documentItem.init(item);
            this.$refs.modalableDocumentPublic.open();
        },

        getCarouselTitle(carousel) {
            return carousel.translations.title[this.userLanguageKey]
                || carousel.translations.title[this.defaultLanguageKey];
        },

        getFile(item) {
            let file = item.fileTranslations[this.userLanguageKey];
            if (!file) {
                file = item.fileTranslations[this.defaultLanguageKey];
            }
            return file;
        },
        getUrl(item) {
            let url = item.translations.url[this.userLanguageKey];
            if (!url) {
                url = item.translations.url[this.defaultLanguageKey];
            }
            return url;
        },
    },

};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.wrapper-carousel {
    border-radius: 8px;
    padding: 25px;
    background: rgba(0, 0, 0, 0.1);

    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

.modal-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
}

.button-modify {
    margin-bottom: 0;
    position: relative;
}

.button-x {
    margin: 0 !important;
}

.display-block {
    display: block !important;
}

.photos {
    margin-top: 15px;
}

.image.logo {
    img {
        background: white;
        padding: 10px;
    }
}

.block-item {
    .link-video {
        min-height: 500px;
        width: 100%;
    }

    p {
        word-break: break-word;
    }
}

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo-left {
    div {
        border-radius: 5px;
    }
}

.photos-right {
    div {
        border-radius: 5px;
    }
}

.bottom-header {
    width: 100vw;
    margin-left: -1.5rem;
    background: white;
    height: 2rem;
    margin-bottom: 2rem;
    border-bottom: #E2E2E2 1px solid;
}

@media (min-width: $tablet) {
    .bottom-header {
        display: none;
    }
}

@media (max-width: $tablet) {
    .company-container {
        margin: 0 16px 20px;
    }
}
</style>
