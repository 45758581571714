<template>
    <div>
        <HtToast
            :title="getToastTitle"
            :message="getToastMessage"
            :closable="true"
            class="mb-5"
            :type="programId ? 'info' : 'danger'"
        />

        <div
            v-for="(category, index) in categories"
            :key="index"
        >
            <div class="category-label">
                {{ categoriesLabel[index] }}
            </div>

            <div
                v-for="(variable, subCategory) in category"
                :key="subCategory"
                class="variable"
            >
                <div
                    v-if="!variable.length"
                    class="variable-line"
                >
                    <div>
                        <span
                            class="variable-icon"
                            :class="getClassVariableIcon(variable.key)"
                        >
                            <FontAwesomeIcon
                                size="1x"
                                :icon="getVariableIcon(variable.key)"
                            />
                        </span>

                        <t>{{ variable.label }}</t>
                    </div>

                    <div
                        class="variable-plus"
                        @click="addVariable(variable)"
                    >
                        <FontAwesomeIcon
                            size="1x"
                            :icon="['fal', 'plus']"
                        />
                    </div>
                </div>

                <div
                    v-else
                >
                    <div
                        class="sub-category clickable"
                        @click="onShowList(subCategory)"
                    >
                        <div>
                            <t>{{ subCategory }}</t>
                        </div>

                        <div class="sub-category-info">
                            <div class="sub-category-info-count">
                                <t :count="category[subCategory].length">
                                    {count} variable | {count} variables
                                </t>
                            </div>
                            <FontAwesomeIcon
                                class="sub-category-info-chevron"
                                size="xs"
                                :icon="getIconChevron(subCategory)"
                            />
                        </div>
                    </div>

                    <div class="sub-category-variable-list">
                        <div
                            v-for="(subVariable) in variable"
                            v-show="showList[subCategory] === true"
                            :key="subVariable.key"
                            class="variable-sub-line"
                        >
                            <div>
                                <span
                                    class="variable-icon"
                                    :class="getClassVariableIcon(subVariable.key)"
                                >
                                    <FontAwesomeIcon
                                        size="1x"
                                        :icon="getVariableIcon(subVariable.key)"
                                    />
                                </span>
                                <t>{{ subVariable.label }}</t>
                            </div>

                            <div class="d-flex">
                                <div
                                    v-if="canDuplicateVariable(subVariable)"
                                    class="variable-plus mr-2"
                                    @click="duplicateRequirementVariable(subVariable)"
                                >
                                    <FontAwesomeIcon
                                        size="1x"
                                        :icon="['fal', 'clone']"
                                    />
                                </div>

                                <div class="variable-plus">
                                    <FontAwesomeIcon
                                        size="1x"
                                        :icon="['fal', 'plus']"
                                        @click="addVariable(subVariable)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HtToast from '@/components/globals/HtToast.vue';

export default {
    name: 'DynamicDocumentSettingVariables',
    components: { HtToast },

    props: {
        categories: {
            type: Object,
            required: true,
        },
        showList: {
            type: Object,
            required: true,
        },
        programId: {
            type: Number,
            default: null,
        },
        displaySurvey: {
            type: Boolean,
            default: () => false,
        },
        usedVariables: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            categoriesLabel: {
                user: this.translate('Enrollee'),
                company: this.translate('Company'),
                program: this.translate('Program'),
                keydate: this.translate('Key date'),
                supervisor: this.translate('Supervisor'),
                entity: this.translate('Entity'),
                requirement: this.translate('Requirement Category'),
            },
            categoriesColor: {
                user: 'green',
                supervisor: 'green',
                program: 'grey',
                date: 'pink',
                keydate: 'pink',
                company: 'blue',
                entity: 'yellow',
                requirement: 'red',
            },
            categoriesIcon: {
                user: ['fal', 'user'],
                supervisor: ['fal', 'user-friends'],
                program: ['fal', 'database'],
                date: ['fal', 'calendar-alt'],
                keydate: ['fal', 'calendar-alt'],
                company: ['fal', 'building'],
                entity: ['fal', 'tag'],
                requirement: ['fal', 'address-card'],
            },
        };
    },

    computed: {
        getToastTitle() {
            if (this.programId) {
                return this.translate('Avoid double entry');
            }

            return this.displaySurvey
                ? this.translate('Missing link to survey')
                : this.translate('Missing link to program');
        },

        getToastMessage() {
            if (this.programId) {
                return this.translate('Click on a variable to add it to the document and use the information already entered.');
            }

            return this.displaySurvey
                ? this.translate('To access variables, you must first add this requirement category to a survey linked to a program.')
                : this.translate('To access variables, you must first link a program to this requirement category.');
        },
    },

    async created() {
        this.usedVariables.filter((v) => v.key.includes('_duplication:'))
            .forEach((duplicateVariable) => {
                const metaData = JSON.parse(duplicateVariable.meta_data);
                const lastUnderscoreIndex = duplicateVariable.key.lastIndexOf('_');
                const cleanVariableKey = duplicateVariable.key.substring(0, lastUnderscoreIndex);

                const lastNumberIndex = duplicateVariable.key.lastIndexOf(':');
                const number = duplicateVariable.key.substring(lastNumberIndex + 1, duplicateVariable.key.length);

                const originalVariable = this.categories.requirement[metaData.company_requirement_category_label]
                    .find((v) => v.key === cleanVariableKey);

                if (originalVariable) {
                    this.categories.requirement[metaData.company_requirement_category_label]
                        .push({ label: `${originalVariable.label}(${number})`, ...duplicateVariable });
                }
            });

        for (const index in this.categories.requirement) {
            if (this.categories.requirement.hasOwnProperty(index)) {
                this.categories.requirement[index].sort((a, b) => a.key.localeCompare(b.key));
            }
        }

        this.categories.entity.sort((a, b) => a.key.localeCompare(b.key));
    },

    methods: {
        canDuplicateVariable(variable) {
            const prefix = variable.key.split('_');
            if (prefix[0] !== 'requirement') {
                return false;
            }

            if (variable.key.includes('_duplication:')) {
                return false;
            }

            return variable.meta_data.is_duplicable;
        },

        duplicateRequirementVariable(variable) {
            const nbrOfDuplicate = this.categories.requirement[variable.meta_data.company_requirement_category_label]
                .filter((obj) => obj.key.includes(`${variable.key}_duplication:`)).length + 1;

            const duplicateVariable = {
                key: `${variable.key}_duplication:${nbrOfDuplicate}`,
                label: `${variable.label} (${nbrOfDuplicate})`,
                meta_data: variable.meta_data,
                value: variable.value,
            };

            this.categories.requirement[variable.meta_data.company_requirement_category_label]
                .push(duplicateVariable);

            this.categories.requirement[variable.meta_data.company_requirement_category_label]
                .sort((a, b) => a.key.localeCompare(b.key));
        },

        addVariable(variable) {
            this.$emit('addVariable', variable, this.getClassVariableIcon(variable.key));
        },

        getVariableIcon(variableKey) {
            const prefix = variableKey.split('_');
            return this.categoriesIcon[prefix[0]];
        },

        getClassVariableIcon(variableKey) {
            const prefix = variableKey.split('_');
            return `variable-${this.categoriesColor[prefix[0]]}`;
        },

        getIconChevron(categoryName) {
            return (this.showList[categoryName] === false) ? 'chevron-down' : 'chevron-up';
        },

        onShowList(categoryName) {
            this.showList[categoryName] = !this.showList[categoryName];
        },
    },
};
</script>

<style lang="scss" scoped>

.category-label {
  margin: 3rem 0 1rem 0;
  color: #DADADA;
}

.sub-category {
  &-info {
    display: flex;

    &-count {
      margin-right: 1rem;
      color: #DADADA;
      font-size: 1.2rem;
    }

    &-chevron {
      align-self: center;
    }
  }

  &-variable-list {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
}

.variable {
  &-line, &-sub-line,
  .sub-category {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #F0F0F0;
    gap: 8px;
    background: white;
    margin-bottom: 1rem;

    &.clickable {
        cursor: pointer;
    }
  }

  &-sub-line {
    width: 95%;
  }

  &-plus {
    justify-content: end;
    align-self: center;
    color: #2C2C2C;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    padding: 0.4rem 0.6rem;
    font-size: 1.2rem;
    cursor: pointer;
  }

  &-icon {
    margin-right: 0.5rem;
    padding: 0.3rem 0.5rem;
    border-radius: 4px;
  }

  &-grey {
    background-color: #E9E9E9;
    color: #575757;
  }

  &-red {
    background-color: #FCDADA;
    color: #8F2929;
  }

  &-pink {
    background-color: #FCDAE4;
    color: #8F2950;
  }

  &-green {
    background-color: #DAFCE2;
    color: #298F4D;
  }

  &-blue {
    background-color: #DAE4FC;
    color: #29418F;
  }

  &-yellow {
    background-color: #FBFCDA;
    color: #8f7E29;
  }
}

</style>
