<template>
    <div
        class="ht-select-simple"
        :data-cy="cypress"
    >
        <div
            :tabindex="0"
            class="ht-select-simple-select"
            @click="onOpen"
            @blur="onBlur"
        >
            <span v-if="label">{{ label }}</span>
            <p
                :class="[
                    {'ht-select-simple-select-chevron': getOptions.length > 0}
                    , 'branding-color']"
            >
                {{ value.label }}
            </p>
        </div>
        <template v-if="getOptions.length > 0">
            <div
                v-show="show"
                class="ht-select-simple-popin"
            >
                <p
                    v-for="option in getOptions"
                    :key="option.value"
                    class="ht-select-simple-popin-item branding-color"
                    @mousedown="onSelect(option)"
                >
                    {{ option.label }}
                </p>
            </div>
        </template>
    </div>
</template>

<script>
import CypressMixin from '@/mixins/CypressMixin';

export default {
    name: 'HtSelectSimple',
    mixins: [CypressMixin],
    props: {
        value: {
            type: [Object, String, Number, Array, Boolean],
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        options: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            show: false,
        };
    },

    computed: {
        getOptions() {
            return this.value
                ? this.options.filter((type) => type.value !== this.value.value)
                : this.options;
        },
    },

    methods: {
        onBlur() {
            if (this.show) {
                this.show = false;
            }
        },

        onOpen() {
            if (this.getOptions.length > 0) {
                this.show = !this.show;
            }
        },

        onSelect(option) {
            this.$emit('input', option || null);
            this.show = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.ht-select-simple {
    position: relative;

    &-wrapper {
        margin-right: 10px;
    }
    &-select {
        display: flex;
        color: $color-text-grey;
        font-size: 14px;
        line-height: 18px;

        &:focus {
            outline: 0;
        }

        p {
            margin-left: 5px;
            font-weight: 700;
        }

        &-chevron {
            cursor: pointer;
            margin-right: 20px;

            &::after {
                border-style: solid;
                border-width: 2px 2px 0 0;
                content: '';
                display: inline-block;
                height: 0.45em;
                left: 5px;
                position: relative;
                top: 5px;
                vertical-align: top;
                width: 0.45em;
                transform: rotate(135deg);
            }
        }
    }
    &-popin {
        position: absolute;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        right: 0;
        padding: 10px 15px;
        text-align: right;
        margin-top: 5px;
        z-index: 1;

        &-item {
            font-size: 14px;
            font-weight: 700;
            cursor: pointer;
            white-space: nowrap;

            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }
}
</style>
