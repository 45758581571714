<template>
    <div
        class="ht-choice-next-button"
        :data-cy="cypress"
        @click="() => $emit('click')"
    >
        <div class="ht-choice-next-button-content">
            <img
                v-if="icon"
                :src="icon"
                width="32"
                height="32"
            >
            <div class="ht-choice-next-button-content-title">
                <t>{{ label }}</t>
            </div>
        </div>
        <FontAwesomeLayers class="ht-choice-next-button-icon">
            <FontAwesomeIcon
                size="2x"
                icon="circle"
                class="circle"
            />
            <FontAwesomeIcon
                size="2x"
                icon="chevron-right"
                transform="right-4 shrink-8"
                class="chevron"
            />
        </FontAwesomeLayers>
    </div>
</template>

<script>
import CypressMixin from '@/mixins/CypressMixin';

export default {
    mixins: [
        CypressMixin,
    ],
    props: {
        label: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.ht-choice-next-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e5e6e6;
    padding: 3rem 2rem;
    border-radius: 5px;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &-content {
        display: flex;
        align-items: center;

        img {
            margin-right: 2rem;
        }

        &-title {
            font-size: 1em;
        }
    }

    &-icon {
        margin-right: 20px;

        .circle {
            border: 1px solid $grey-ultra-light;
            border-radius: 50%;
            color: $white;
        }

        .chevron {
            color: $grey;
        }
    }
}
</style>
