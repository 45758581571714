<template>
    <div>
        <form
            v-if="item.isLoaded()"
            class="form-2 spaced"
            @submit.prevent=""
        >
            <div class="modal-box">
                <InputField
                    v-model="name"
                    :label="translate('Name')"
                    :type="'text'"
                    :name="'name'"
                    :validate="'required'"
                    :validate-as="translate('Document name')"
                    :placeholder="translate('Write the document name')"
                    @input="onUpdateName"
                />

                <fieldset>
                    <CardList :options="cardListOptions">
                        <Card
                            v-for="(type, index) in typeOptions"
                            :key="index"
                            :options="cardOptions"
                            :class="{ selected: item.type === type.type }"
                            @click="updateType(type.type)"
                        >
                            <div slot="image">
                                <div class="item-image image-small">
                                    <div
                                        class="small-image"
                                        :style="{'background-image': `url(${type.img})`}"
                                    />
                                </div>
                            </div>
                            <div slot="title">
                                <t>{{ type.label }}</t>
                            </div>
                        </Card>
                    </CardList>
                </fieldset>

                <!-- FILE -->
                <transition name="fade">
                    <div v-if="item.type === 'file'">
                        <HtFormSwitch
                            :id="'toggle-is-tos'"
                            v-model="item.is_tos"
                            :label="translate('Terms & Conditions')"
                            :name="'toggle-is-tos'"
                            :show-optional="false"
                        />

                        <fieldset :class="{ error: errors.has('original_name') }">
                            <TranslatableFiles
                                :files="item.file_translations"
                                :item="item"
                                :errors-messages="errors"
                                :editing-lang="editingLang"
                                :button-state.sync="buttonState"
                                :validation-rules="{
                                    translation_default:[item.file_translations, shared.session.company.company_languages.default.key]
                                }"
                                @input="v => item.file_translations = v"
                            />
                        </fieldset>
                    </div>
                </transition>

                <!-- LINK -->
                <transition name="fade">
                    <div v-if="item.type === 'link'">
                        <InputField
                            v-model="url"
                            :label="translate('Url of your document')"
                            :validate-as="translate('url')"
                            :type="'text'"
                            :name="'url'"
                            :validate="'required|url'"
                            :placeholder="translate('Write the document url')"
                            @input="onUpdateUrl"
                        />
                    </div>
                </transition>

                <InputField
                    v-model="description"
                    :label="translate('Description (optional)')"
                    :type="'textarea'"
                    :name="'type'"
                    :placeholder="translate('Please specify')"
                    @input="onUpdateDescription"
                />
            </div>
        </form>

        <div class="modal-actions right">
            <Button
                v-show="!item.isNew()"
                class="negative"
                @click="onDelete()"
            >
                <t>Delete</t>
            </Button>
            <Button
                :state="buttonState"
                @click="onSave()"
            >
                {{ labelButton }}
            </Button>
        </div>
    </div>
</template>

<script>
import CompanyDocumentPublicCollection from '@/models/CompanyDocumentPublicCollection';
import CompanyDocumentPublic from '@/models/CompanyDocumentPublic';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import TranslatableFiles from '@/components/form/TranslatableFiles.vue';

export default {
    name: 'DocumentPublicEdit',
    components: { TranslatableFiles, HtFormSwitch },

    inject: ['modal'],

    props: {
        value: {
            type: CompanyDocumentPublic,
            default: null,
        },
        collection: {
            type: CompanyDocumentPublicCollection,
            required: true,
        },
        editingLang: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            item: null,

            buttonState: 'idle',

            cardListOptions: {
                perRow: 2,
            },

            cardOptions: {
                type: 'long',
                perRow: 2,
            },
        };
    },

    computed: {
        defaultLanguageKey() {
            return this.shared.session.company.company_languages.models
                .find((language) => language.is_default).key;
        },
        typeOptions() {
            const types = [
                {
                    img: '/static/icons/doc.svg',
                    type: 'file',
                    label: 'File',
                },
                {
                    img: '/static/icons/link.svg',
                    type: 'link',
                    label: 'Link',
                },
            ];
            if (this.editingLang === this.defaultLanguageKey) return types;
            return types.filter((type) => type.type === this.item.type);
        },
        name() {
            if (!(this.editingLang in this.item.translations.name)) {
                this.$set(this.item.translations.name, this.editingLang, '');
            }
            return this.item.translations.name[this.editingLang];
        },
        url() {
            if (!(this.editingLang in this.item.translations.url)) {
                this.$set(this.item.translations.url, this.editingLang, '');
            }
            return this.item.translations.url[this.editingLang];
        },
        description() {
            if (!(this.editingLang in this.item.translations.description)) {
                this.$set(this.item.translations.description, this.editingLang, '');
            }
            return this.item.translations.description[this.editingLang];
        },
        labelTitle() {
            return this.translate(this.item.isNew() ? 'Add company document' : 'Edit company document');
        },
        labelButton() {
            return this.translate(this.item.isNew() ? 'Add' : 'Save');
        },
    },

    watch: {
        'item._state.isSaving': function (val) {
            this.buttonState = val ? 'loading' : 'idle';
        },
    },

    created() {
        if (this.value) {
            this.item = this.value.fromSaveState();
        } else {
            this.item = this.collection.new();
            this.$set(this.item, 'translations', {});
            this.$set(this.item.translations, 'name', {});
            this.$set(this.item.translations, 'url', {});
            this.$set(this.item.translations, 'description', {});
        }

        this.modal.setTitle(this.labelTitle);
    },

    methods: {
        updateType(type) {
            this.item.type = type;

            this.item.translations.url[this.item.editingLang] = '';
        },

        onUpdateName(value) {
            this.item.translations.name[this.editingLang] = value;
        },

        onUpdateUrl(value) {
            this.item.translations.url[this.editingLang] = value;
        },

        onUpdateDescription(value) {
            this.item.translations.description[this.editingLang] = value;
        },

        onSave() {
            this.$validator.validateAll().then((result) => {
                if (result === true) {
                    this.item.save().then(() => {
                        this.collection.get();
                        this.modal.close();
                    });
                }
            });
        },

        onDelete() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this document? Once you click on delete, you will no longer be able to access this document.') }).then((response) => {
                if (response === true) {
                    this.item.delete().then(() => {
                        this.modal.close();
                    });
                }
            });
        },
    },
};
</script>
