import { render, staticRenderFns } from "./SurveyChoiceHistory.vue?vue&type=template&id=67589b86&scoped=true&"
import script from "./SurveyChoiceHistory.vue?vue&type=script&lang=js&"
export * from "./SurveyChoiceHistory.vue?vue&type=script&lang=js&"
import style0 from "./SurveyChoiceHistory.vue?vue&type=style&index=0&id=67589b86&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67589b86",
  null
  
)

export default component.exports