<template>
    <HtCard>
        <template #title>
            <HtCardTitleBar
                :title="translate('Pages list')"
                :show-button="!toggleFeatureLoading"
                :show-search="false"
                :button-text="translate('Add a new page')"
                @onAdd="openTemplateModal"
            />
        </template>
        <template #default>
            <HtFormSwitch
                id="feature_enabled"
                :value="isFeatureEnabled"
                name="feature_enabled"
                :show-optional="false"
                :label="translate('Use the new enterprise page for your users !')"
                cypress="feature_enabled"
                :disabled="toggleFeatureLoading"
                @input="onToggleFeature();"
            />

            <EnterprisePageList
                ref="enterprise-page-list"
            />
        </template>
    </HtCard>
</template>

<script>
import api from '@/store/api';
import HtCard from '@/components/globals/HtCard.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import EnterprisePageList from '@/components/settings/EnterprisePage/EnterprisePageList.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import ModalMixin from '@/components/globals/modals/modalMixin';
import EnterprisePageTemplateModal from '@/components/settings/EnterprisePage/Modal/EnterprisePageTemplateModal.vue';

export default {
    name: 'SettingsEnterprisePage',

    components: {
        HtFormSwitch,
        EnterprisePageList,
        HtCard,
        HtCardTitleBar,
    },

    mixins: [
        ModalMixin,
    ],

    data() {
        return {
            isFeatureEnabled: this.$store.state.config.features.find((feature) => feature.key === 'new_enterprise_page')?.is_enabled || false,
            toggleFeatureLoading: false,
        };
    },

    methods: {
        openTemplateModal() {
            this.openCenterModal(
                EnterprisePageTemplateModal,
                {},
                {
                    'before-close': (event) => {
                        if (!event.params) {
                            return;
                        }

                        const template = event.params;

                        const location = {
                            name: 'SettingsEnterprisePageForm',
                            query: {},
                        };

                        if (template !== 'empty') {
                            location.query.template = template;
                        }

                        this.$router.push(location);
                    },
                },
            );
        },

        onToggleFeature() {
            this.toggleFeatureLoading = true;

            api
                .settings
                .enterprisePages
                .pages
                .toggleFeature()
                .then((res) => {
                    this.$store.dispatch('config/registerFeature', res.data.data);
                    this.isFeatureEnabled = res.data.data.is_enabled;
                })
                .finally(() => {
                    this.toggleFeatureLoading = false;
                });
        },
    },
};
</script>
