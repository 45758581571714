<template>
    <div v-if="companyDocumentPublic">
        <h2 class="align-center">
            {{ companyDocumentPublic.name }}
        </h2>
        <form
            class="form"
            @submit.prevent=""
        >
            <fieldset v-if="companyDocumentPublic.description">
                <label for="last-name"><t>Description</t>:</label>
                <div class="item-content">
                    {{ companyDocumentPublic.description }}
                </div>
            </fieldset>

            <fieldset v-if="companyDocumentPublic.url">
                <label for="last-name"><t>Url</t>:</label>
                <div class="item-content">
                    <a
                        :href="companyDocumentPublic.url"
                        target="_blank"
                    >{{ companyDocumentPublic.url }}</a>
                </div>
            </fieldset>
        </form>

        <Downloadable
            v-if="companyDocumentPublic.type === 'file'"
            :company-file="file"
        />
        <div
            v-else
            class="row-line item"
        >
            <div
                class="button button-1 black"
                @click="openUrl(companyDocumentPublic.url)"
            >
                <t>Go to URL</t>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DocumentItem',
    data() {
        return {
            saving: false,
            companyDocumentPublic: null,
        };
    },
    computed: {
        defaultLanguageKey() {
            return this.shared.session.company.company_languages.models
                .find((language) => language.is_default).key;
        },
        file() {
            if (this.companyDocumentPublic.company_files_from_locale.models?.length) {
                return this.companyDocumentPublic.company_files_from_locale.models?.[0];
            }
            return this.companyDocumentPublic.file_translations?.[this.defaultLanguageKey];
        },
    },

    methods: {
        init(item) {
            this.companyDocumentPublic = item;
        },

        openUrl(sUrl) {
            window.open(sUrl, 'training');
        },
    },
};
</script>

<style lang="scss" scoped>

	.row-line.item {
		display: grid;

		.button {
			margin: 1em 0 0;
		}
	}
</style>
