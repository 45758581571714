<template>
    <div class="generic-dialog-modal">
        <div class="content">
            <div class="title">
                <div v-if="title">
                    {{ title }}
                </div>
                <HtButton
                    type="secondary"
                    size="small"
                    @click.native="$emit('close')"
                >
                    <FontAwesomeIcon
                        icon="times"
                        class="close-icon"
                    />
                </HtButton>
            </div>
            <div
                v-if="description"
                class="description"
            >
                {{ description }}
            </div>
        </div>
        <div
            v-if="buttons && buttons.length"
            class="buttons"
        >
            <HtButton
                v-for="(button, index) in buttons"
                :key="`button-${index}`"
                class="button"
                :type="button.type ? button.type : (index === 0 ? 'secondary' : 'primary')"
                @click.native="button.handler"
            >
                {{ button.title }}
            </HtButton>
        </div>
    </div>
</template>
<script>
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'GenericDialog',
    components: { HtButton },
    props: {
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        buttons: {
            type: Array,
            default: () => ([]),
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.generic-dialog-modal {
    padding: 25px;
    display: flex;
    flex-direction: column;

    & .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 8px;

        & .title {
            display: flex;
            align-content: center;
            justify-content: space-between;
            gap: 8px;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;

            color: $neutral-black;

            .close-icon{
                width: 10px;
                height: 10px;
            }
        }
        & .description {
            font-size: 14px;
            line-height: 140%;

            color: $neutral-700;
        }
    }
    & .buttons {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        margin-top: 40px;

        .button {
            width: 100%;
        }
    }
}
</style>
