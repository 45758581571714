<template>
    <div
        class="members"
        :class="[position]"
    >
        <template v-if="type === 'horizontal'">
            <template v-for="(item, index) in showedUsers">
                <div
                    :key="index"
                    class="wrapper-member"
                    :class="{ shrink: shrink }"
                    :style="`width: ${size}; height: ${size};`"
                >
                    <div
                        v-user-image="getUserImage(item, initialFont, true)"
                        class="image-person tinyMaskWrapper"
                        :class="getClass(item, true)"
                        :title="getTitle(item, true)"
                        :style="`width: ${size}; height: ${size};`"
                    />
                </div>
            </template>

            <VPopover
                v-if="hiddenUsers.length > 0"
                trigger="hover"
            >
                <div
                    class="wrapper-member"
                    :class="{ shrink: shrink }"
                    :style="`width: ${size}; height: ${size};`"
                >
                    <div
                        class="image-person tinyMaskWrapper limit-class"
                        :style="`width: ${size}; height: ${size};`"
                    >
                        <span>+{{ hiddenUsers.length }}</span>
                    </div>
                </div>

                <template #popover>
                    <HtChip
                        v-for="(item, index) in hiddenUsers"
                        :label="getTitle(item, true)"
                    >
                        <template #image>
                            <div
                                :key="index"
                                class="wrapper-member"
                                :class="{ shrink: shrink }"
                                :style="`width: ${size}; height: ${size};`"
                            >
                                <div
                                    v-user-image="getUserImage(item, initialFont, true)"
                                    class="image-person tinyMaskWrapper"
                                    :class="getClass(item, true)"
                                    :title="getTitle(item, true)"
                                    :style="`width: ${size}; height: ${size};`"
                                />
                            </div>
                        </template>
                    </HtChip>
                </template>
            </VPopover>
        </template>
        <template v-else-if="type === 'stacked'">
            <transition name="fade">
                <div class="full-width">
                    <div
                        class="card-7 flex-container no-border flex-container-full"
                    >
                        <span>
                            <div
                                v-for="(item, index) in showedUsers"
                                :key="item.id"
                                class="item"
                            >
                                <div
                                    v-user-image="getUserImage(item, initialFont, true)"
                                    class="item-image role-only"
                                    :class="getClass(item, true)"
                                    :title="getTitle(item, true)"
                                    :style="{ 'background-size': '1.75em' }"
                                />

                                <div class="item-content">
                                    <div>
                                        <template v-if="item.company_user && item.company_user.id">
                                            {{ item.company_user.firstname }}
                                            {{ item.company_user.lastname }}
                                        </template>
                                        <template v-else-if="item.company_role && item.company_role.alias">
                                            <template v-if="item.company_role.alias_translated">
                                                {{ item.company_role.alias_translated }}
                                            </template>
                                            <template v-else>
                                                <template v-if="item.company_role.is_heyteam">
                                                    <t>{{ item.company_role.alias }}</t>
                                                </template>
                                                <template v-else>
                                                    {{ item.company_role.alias }}
                                                </template>
                                            </template>
                                        </template>
                                        <template v-else-if="hasGroup && item.company_group && item.company_group.name">
                                            <t>{{ item.company_group.alias }}</t>
                                        </template>
                                    </div>
                                    <IconTrash
                                        v-if="!disabled && singleSelection"
                                        @click="$emit('onRemove', index)"
                                    />
                                </div>
                            </div>
                            <Button
                                v-if="!disabled && canAdd && (!singleSelection || showedUsers.length === 0)"
                                :data-cy="cypress"
                                type="rounded"
                                class="participant-add-button negative"
                                @click="$emit('onAdd')"
                            >
                                {{ actionLabel }}
                            </Button>
                        </span>
                    </div>
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
import { VPopover } from 'v-tooltip';
import ParticipantListHelper from '@/components/mixins/ParticipantListHelper.vue';
import Button from '@/components/globals/Button.vue';
import HtChip from '@/components/globals/HtChip.vue';
import I18n from '../../modules/i18n/I18n';

export default {
    mixins: [
        ParticipantListHelper,
        Button,
    ],

    components: {
        HtChip,
        VPopover,
    },

    props: {
        users: { type: Array },
        hasGroup: { type: Boolean, default: false },
        shrink: { type: Boolean, default: true },
        size: { type: String, default: '25px' },
        initialFont: { type: Number, default: 1 },
        position: { type: String, default: 'left' },
        limit: { type: Number, default: null },
        type: { type: String, default: 'horizontal' },
        canAdd: { type: Boolean, default: true },
        singleSelection: { type: Boolean, default: false },
        addLabel: { type: [Function, String], default: (count) => I18n.translate(count > 0 ? 'Edit' : 'Add a participant') },
        cypress: { type: String },
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },

    computed: {
        getUsers() {
            return this.users
                .reduce((filteredArray, participant) => {
                    if (filteredArray.some((filteredParticipant) => participant.company_user_id && filteredParticipant.company_user_id === participant.company_user_id)) {
                        return filteredArray;
                    }

                    return [...filteredArray, participant];
                }, [])
                .map((item) => {
                    if (!item.company_user && item.user && typeof item.user === 'object') {
                        item.company_user = item.user;
                    }

                    if (!item.company_role && item.role && typeof item.role === 'object') {
                        item.company_role = item.role;
                    }

                    if (!item.company_group && item.group && typeof item.group === 'object') {
                        item.company_group = item.group;
                    }

                    return item;
                });
        },

        showedUsers() {
            if (!this.limit) {
                return this.getUsers;
            }

            return this.getUsers.slice(0, this.limit);
        },

        hiddenUsers() {
            if (!this.limit) {
                return [];
            }

            return this.getUsers.slice(this.limit);
        },

        actionLabel() {
            if (typeof this.addLabel === 'string') {
                return this.addLabel;
            }

            return this.addLabel(this.users.length);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.add-options {
    text-align: right;

    & > span {
        text-decoration: underline;
        font-family: $lato-bold;
        cursor: pointer;
    }
}

.full-width {
    width: 100%;
}

.card-7.flex-container .item {
    word-break: break-word;
    font-size: 0.9em;

    padding: 0.6rem 2.5rem 0.6rem 0;

    .item-image {
        border-radius: 35%;

		height: 2.4rem;
		width: 2.4rem;
        margin-right: 0;
    }

    .item-content {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 0 0 0.8rem;
    }
}

.participant-add-button {
    padding: 1.5rem 4rem;
}

.item ~ .participant-add-button {
    margin-top: 2.4rem;
}

.flex-container {
    margin: 0;

    .item {
        cursor: default;

        &:hover:before {
            opacity: 0;
        }
    }
}

.flex-container-full {
    margin-top: 0;
    margin-bottom: 20px;
}

.members {
    display: flex;

    &.left {
        justify-content: flex-start;
    }
    &.right {
        justify-content: flex-end;
    }

    .wrapper-member {
        position: relative;
        z-index: 1;
        &.shrink {
            margin-right: -10px;
        }
        .image-person {
            justify-content: center;
            justify-items: center;
            display: flex;
        }
        .limit-class {
            display: flex;
            background: $white;
            border: 1px solid $grey-dark;

            span {
                align-self: center;
                color: $black;
                font-size: 0.6em;
                font-weight: bold;
            }
        }
    }
}

.tinyMaskWrapper {
    border-radius: 50%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translate3d(0, 0, 0);
}

.cursor-pointer {
    cursor: pointer !important;
}

</style>
