import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyQuiz from './CompanyQuiz';

export default class CompanyQuizCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyQuiz,
        };
    }

    modelCustomAttributes() {
        return {
            filters: {},
        };
    }
}
