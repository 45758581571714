import I18n from '@/modules/i18n/I18n';

export default class ResourcesInfo {
    static icons = {
        company_program_task: ['fal', 'bolt'],
        company_document: ['fal', 'lightbulb'],
        company_nudge: ['fal', 'fire'],
        company_shared_document: ['fal', 'file'],
        company_survey: ['fal', 'chart-bar'],
        company_quiz: ['fal', 'question-square'],
        company_equipment: ['fal', 'suitcase'],
        company_software: ['fal', 'browser'],
        company_requirement_category: ['fal', 'address-card'],
        company_planning: ['fal', 'calendar-alt'],
        company_planning_event_template: ['fal', 'calendar-alt'],
        company_email_custom_template: ['fal', 'envelope'],
    };

    static labels = {
        company_program_task: I18n.translate('Action'),
        company_document: I18n.translate('Training'),
        company_nudge: I18n.translate('Nudge'),
        company_shared_document: I18n.translate('Document'),
        company_survey: I18n.translate('Survey'),
        company_quiz: I18n.translate('Quiz'),
        company_equipment: I18n.translate('Equipment'),
        company_software: I18n.translate('Software'),
        company_requirement_category: I18n.translate('Requirement'),
        company_planning: I18n.translate('Event'),
        company_planning_event_template: I18n.translate('Event'),
        company_email_custom_template: I18n.translate('Email'),
    };

    static getResourceIcon(resourceName) {
        const icon = this.icons[resourceName];

        if (!icon) {
            throw new Error(`No icon is configured for the "${resourceName}" resource`);
        }

        return icon;
    }
}
