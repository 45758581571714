<template>
    <div>
        <FilterableSearch
            ref="filterSearch"
            v-model="rows"
            :prop-keys="['label']"
        />

        <HtTable
            :items="rows"
            :columns="columns"
            class="mt-5"
            @onCellClicked="onEdit"
        >
            <template #cell(actions)="">
                <IconCog />
            </template>
        </HtTable>

        <Modalable
            ref="passwordModalable"
            class="modalable-1 medium"
            :mode="2"
            :options="{ title: translate('Password') }"
        >
            <SecurityPasswordEdit :item="item" />
        </Modalable>

        <Modalable
            ref="ssoModalable"
            class="modalable-1 medium"
            :mode="1"
            :options="{ title: translate('SSO') }"
        >
            <SsoEdit />
        </Modalable>

        <Modalable
            ref="twoFactorAuthModalable"
            class="modalable-1 medium"
            :mode="2"
            :options="{ title: translate('Two-Factor Authentication (2FA)') }"
        >
            <SecurityTwoFactorAuthEdit
                v-if="item"
                :is-two-factor-auth-enabled="item.is_two_factor_auth_enabled"
                @updated:isTwoFactorAuthEnabled="update2faEnabled"
            />
        </Modalable>

        <Modalable
            ref="sessionModalable"
            class="modalable-1 medium"
            :mode="2"
            :options="{ title: translate('Session time') }"
        >
            <SecuritySessionEdit :item="item" />
        </Modalable>
        <Modalable
            ref="teamMembersModalable"
            class="modalable-1 medium"
            :mode="2"
            :options="{ title: translate('Team members') }"
        >
            <TeamMembersEdit :item="itemCustomize" />
        </Modalable>
        <Modalable
            ref="dataSuppresionModalable"
            class="modalable-1 medium"
            :mode="2"
            :options="{ title: translate('Data suppression') }"
        >
            <PurgeEdit />
        </Modalable>
    </div>
</template>

<script>
import CompanySecurityCollection from '@/models/CompanySecurityCollection';
import CompanyCustomizeCollection from '@/models/CompanyCustomizeCollection';
import SecurityPasswordEdit from './SecurityPasswordEdit.vue';
import SsoEdit from './SsoEdit.vue';
import SecuritySessionEdit from './SecuritySessionEdit.vue';
import SecurityTwoFactorAuthEdit from './SecurityTwoFactorAuthEdit.vue';
import TeamMembersEdit from './TeamMembersEdit.vue';
import PurgeEdit from './PurgeEdit.vue';

export default {
    name: 'SecurityList',
    components: {
        SecurityPasswordEdit,
        SsoEdit,
        SecuritySessionEdit,
        SecurityTwoFactorAuthEdit,
        TeamMembersEdit,
        PurgeEdit,
    },

    data() {
        return {
            columns: [
                {
                    label: 'Name',
                    key: 'label',
                },
                {
                    label: '',
                    key: 'actions',
                    sortable: false,
                },
            ],
            rows: [
                {
                    label: this.translate('Password'),
                    key: 'password',
                },
                {
                    label: this.translate('SSO'),
                    key: 'sso',
                },
                {
                    label: this.translate('Session time'),
                    key: 'session',
                },
                {
                    label: this.translate('Team members'),
                    key: 'teamMembers',
                },
                {
                    label: this.translate('Data suppression'),
                    key: 'dataSuppresion',
                },
                {
                    label: this.translate('Double authentication 2FA'),
                    key: 'twoFactorAuth',
                },
            ],
            companySecurity: new CompanySecurityCollection([
                'id',
                'session_time',
                'password_length',
                'password_numbers',
                'password_symbols',
                'password_attempts',
                'password_reset_attempts',
                'is_two_factor_auth_enabled',
            ]),

            companyCustomize: new CompanyCustomizeCollection([
                'id',
                'content',
                'is_enabled',
                'type',
                'subtype',
            ]).where([
                ['type', '=', 'page'],
                ['subtype', '=', 'team'],
            ]),

            item: null,
            itemCustomize: null,
        };
    },

    async created() {
        await this.companySecurity.get();
        this.item = this.companySecurity.$.models[0].active;

        await this.companyCustomize.get();
        this.itemCustomize = this.companyCustomize.$.models[0].active;
    },

    methods: {
        onEdit(row) {
            this.$refs[`${row.item.key}Modalable`].open();
        },
        update2faEnabled(value) {
            this.shared.session.company.security.is_two_factor_auth_enabled = value;
            this.$set(this.item, 'is_two_factor_auth_enabled', value);
        },
    },
};
</script>
