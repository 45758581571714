import Model from '@tony.caron/node-model-proxy/Model';
import CompanySecurityCollection from './CompanySecurityCollection';
import Notifier from '../Notifier';
import I18n from '../modules/i18n/I18n';

export default class CompanySecurity extends Model {
    modelConfig() {
        return {
            collection: CompanySecurityCollection,
        };
    }

    modelInConvertor() {
        return {
            is_two_factor_auth_enabled: (n) => Boolean(n),
        };
    }

    modelEvents() {
        return {
            updated() {
                Notifier.getInstance('App').showInfo(
                    I18n.translate('You have successfully updated the security settings of your company'),
                );
            },
        };
    }
}
