<template>
    <HtTabStepper
        :item.sync="item"
        :tabs="tabs"
        @on-close-stepper="$emit('on-close-stepper')"
    />
</template>

<script>
import api from '@/store/api';
import stepperFormEventBus from '@/eventBus/stepperFormEventBus';
import HtTabStepper from '@/layouts/HtTabStepper.vue';
import EntitiesFormSettings from './EntitiesFormSettings.vue';
import EntitiesFormContent from './EntitiesFormContent.vue';

export default {
    name: 'EntityForm',
    components: {
        HtTabStepper,
    },

    props: {
        id: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            item: {
                name: null,
                is_editable: true,
                is_heyteam: false,
                is_private: false,
            },
            tabs: [
                {
                    ref: 'settings',
                    title: this.translate('Settings'),
                    component: EntitiesFormSettings,
                    disabled: false,
                    noBody: false,
                },
                {
                    ref: 'content',
                    title: this.translate('Content'),
                    component: EntitiesFormContent,
                    disabled: !this.id,
                    noBody: false,
                },
            ],
        };
    },
    created() {
        if (this.id) {
            this.fetchData();
        } else {
            stepperFormEventBus.$emit('fetched-item', this.translate('New entity'));
        }
    },
    methods: {
        fetchData() {
            api.configuration.entities.get(this.id).then((res) => {
                this.item = res.data.data;
                const title = this.item.is_heyteam ? this.translate(this.item.name) : this.item.name;
                stepperFormEventBus.$emit('fetched-item', title);
            });
        },
    },
};
</script>
