<template>
    <IndexLayout>
        <template #headbar>
            <HeadBar />
        </template>
    </IndexLayout>
</template>

<script>
import IndexLayout from '@/components/globals/IndexLayout.vue';
import HeadBar from '@/components/holding/HeadBar.vue';

export default {
    name: 'HoldingIndex',
    components: {
        IndexLayout,
        HeadBar,
    },
};
</script>
