import api from '@/store/api';

export default {
    namespaced: true,
    actions: {
        fetchCurrentTasks(context, body) {
            return api.tasks.fetchCurrentTasks(body);
        },
        fetchCurrentUsersAndEmployees(context, body) {
            return api.tasks.fetchCurrentUsersAndEmployees(body);
        },
        fetchCurrentUserTasks(context, { id, body }) {
            return api.tasks.fetchCurrentUserTasks(id, body);
        },
        fetchHistoryTasks(context, body) {
            return api.tasks.fetchHistoryTasks(body);
        },
        fetchHistoryUsersAndEmployees(context, body) {
            return api.tasks.fetchHistoryUsersAndEmployees(body);
        },
        fetchHistoryUserTasks(context, { id, body }) {
            return api.tasks.fetchHistoryUserTasks(id, body);
        },
        fetchTask(context, { id, body }) {
            return api.tasks.fetchTask(id, body);
        },
    },
};
