import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyFieldTemplate from './CompanyFieldTemplate';

export default class CompanyFieldTemplateCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyFieldTemplate,
        };
    }
}
