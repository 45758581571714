import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import ViewCompanyUserTaskCollection from './ViewCompanyUserTaskCollection';

export default class ViewCompanyUserTask extends Model {
    modelCustomAttributes() {
        return {
            dependenciesTasks: [],
            dependentId: null,
        };
    }

    modelConfig() {
        return {
            collection: ViewCompanyUserTaskCollection,
        };
    }

    modelRelations() {
        return {
            companyOnboardee: () => this.belongsTo(CompanyUser, 'company_onboardee_id', 'id'),
            companyUserProgramTask: () => this.belongsTo(CompanyUserProgramTask, 'company_user_program_task_id', 'id'),
        };
    }
}
