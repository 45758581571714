import Validator from './validator';

export default (Vue) => ({
    computed: {
        errorBag: {

            get() {
                return this.$fvalidate.errorBag;
            },
        },
    },

    beforeCreate() {
        this.$fvalidate = new Validator(null, { init: false });
        Vue.util.defineReactive(this.$fvalidate, 'errorBag', this.$fvalidate.errorBag);
    },
    mounted() {
        this.$fvalidate.init();
    },
});
