<template>
    <div>
        <BulkEnrollingHeader
            :has-errors="hasCellErrors"
            :title="translate('Target')"
        >
            <template v-if="hasSelectedItems">
                <HtButton
                    :left-icon="['fal', 'user-minus']"
                    type="secondary"
                    :disabled="allItemsAreSelected"
                    @click.native="deleteSelection"
                >
                    <t>Remove from list</t>
                </HtButton>
            </template>
            <template v-else>
                <HtSearchField
                    id="search_user"
                    v-model.trim="searchInputUser"
                    name="search-user"
                    :placeholder="translate('Search')"
                />
            </template>
        </BulkEnrollingHeader>

        <HtTablePaginated
            ref="usersTable"
            :url="`foc/enrolling/bulk/${bulk.id}/target/paginate`"
            :columns="columns"
            :external-search="searchInputUser"
            :cell-errors="cellErrors"
            has-checkboxes
            @has-selected-items="hasSelectedItems = $event"
            @on-row-clicked="onRowClicked"
        >
            <template #cell(user.fullname)="scope">
                <TableCellUser
                    :user="scope.item.user"
                />
            </template>

            <template v-for="keyDate in bulk.program.key_dates" v-slot:[`cell(key_dates.${keyDate.id})`]="scope">
                {{ getDateFormat(scope.item.user_program.key_dates.find((kd) => kd.company_program_key_date_id === keyDate.id)?.starts_at) }}
            </template>

            <template #cell(user.end_date)="scope">
                {{ getDateFormat(scope.item.user.end_date) }}
            </template>

            <template v-for="entity in entities" v-slot:[`cell(entities.${entity.id})`]="scope">
                {{ scope.item.user_program.entities.find((e) => e.company_entity_id === entity.id)?.value?.item?.name || '-' }}
            </template>
        </HtTablePaginated>

        <EnrollingFooter>
            <HtButton
                cypress="foc-previous-program"
                type="secondary"
                :disabled="!hasPrev"
                @click.native="$emit('on-prev')"
            >
                <t>Previous</t>
            </HtButton>

            <HtButton
                cypress="foc-next-program"
                :loading="loading"
                :disabled="!hasNext || loading"
                @click.native="validate"
            >
                <t>Next</t>
            </HtButton>
        </EnrollingFooter>
    </div>
</template>

<script>
import api from '@/store/api';
import EnrollingFooter from '@/router/pages/programs/enrolling/EnrollingFooter.vue';
import HtTablePaginated from '@/components/globals/HtTable/HtTablePaginated.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import BulkEnrollingHeader from '@/router/pages/programs/enrolling/bulk/BulkEnrollingHeader.vue';
import ModalMixin from '@/components/globals/modals/modalMixin';
import HtButton from '@/components/globals/HtButton.vue';
import BulkEditTargetModal from '@/router/pages/programs/enrolling/bulk/Target/BulkEditTargetModal.vue';
import TableCellUser from '@/components/globals/table/TableCellUser.vue';
import BulkEnrollingMixin from '@/router/pages/programs/enrolling/bulk/BulkEnrollingMixin';

export default {
    name: 'BulkEnrollingTarget',

    mixins: [
        ModalMixin,
        BulkEnrollingMixin,
    ],

    components: {
        EnrollingFooter,
        TableCellUser,
        HtButton,
        BulkEnrollingHeader,
        HtSearchField,
        HtTablePaginated,
    },

    data() {
        return {
            entities: [],
        };
    },

    created() {
        this.$store
            .dispatch('entities/fetchEntities')
            .then(() => {
                this.entities = this.$store
                    .state
                    .entities
                    .entities
                    .filter((entity) => entity.programs.map((p) => p.id).includes(this.bulk.program.id));
            });
    },

    methods: {
        validate() {
            if (this.isReadOnly) {
                this.$emit('on-next');

                return;
            }

            this.loading = true;

            api
                .user
                .enrolling
                .bulk
                .validateUserTarget(this.bulk.id)
                .then(() => {
                    this.$emit('on-next');
                })
                .catch(({ response }) => {
                    this.cellErrors = {};

                    Object.keys(response.data.errors)
                        .forEach((key) => {
                            const id = key.substring(0, key.indexOf('.'));
                            const rest = key.substring((key.indexOf('.') + 1));
                            const values = this.cellErrors[id] || [];

                            values.push(rest);

                            this.$set(this.cellErrors, id, values);
                        });

                    if (this.hasCellErrors) {
                        this.$nextTick(() => {
                            this.$refs.usersTable.loadItems(1);
                        });
                    }

                    this.loading = false;
                });
        },

        onRowClicked({ item }) {
            if (this.isReadOnly) {
                return;
            }

            this.openCenterModal(
                BulkEditTargetModal,
                {
                    bulk: this.bulk,
                    user: item.user,
                    userProgram: item.user_program,
                    requiredEntities: this.entities,
                },
                {
                    'before-close': (event) => {
                        if (event.params && event.params.name && event.params.name === 'on-update') {
                            const user = event.params.value.user;
                            const userProgram = event.params.value.user_program;
                            this.$refs.usersTable.replaceItemById(item, 'user', user);
                            this.$refs.usersTable.replaceItemById(item, 'user_program', userProgram);
                            this.cellErrors = {};
                        }
                    },
                },
            );
        },

        getDateFormat(date) {
            if (!date) {
                return '-';
            }

            return this.$Utils.moment(date).format('L');
        },
    },

    computed: {
        columns() {
            const columns = [
                {
                    label: this.translate('Name'),
                    key: 'user.fullname',
                },
            ];

            this.bulk
                .program
                .key_dates
                .forEach((keyDate) => {
                    columns.push({
                        label: this.oldLocalize(keyDate.locales, 'name'),
                        key: `key_dates.${keyDate.id}`,
                    });
                });

            columns.push({
                label: this.translate('Program end date'),
                key: 'user.end_date',
            });

            this.entities
                .forEach((entity) => {
                    columns.push({
                        label: entity.name,
                        key: `entities.${entity.id}`,
                    });
                });

            return columns;
        },
    },
};
</script>
