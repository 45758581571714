var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.companySecurity.isLoaded())?_c('div',[_c('form',{staticClass:"form-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('HtFormInput',{directives:[{name:"validate",rawName:"v-validate.disable",value:({ required: true }),expression:"{ required: true }",modifiers:{"disable":true}}],attrs:{"id":"oldpassword","name":"oldpassword","type":"password","show-asterisk":true,"error-append":_vm.errorPassword,"label":_vm.translate('Current Password'),"placeholder":_vm.translate('Enter current password'),"data-vv-as":_vm.translate('current password')},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('label',[_c('span',[_c('t',[_vm._v("New Password")]),_vm._v(" *")],1),_c('span',{staticStyle:{"font-size":".75em","font-weight":"normal"}},[_vm._v(" ( "),_c('t',[_vm._v("Password must be at least")]),_vm._v("  "),_c('t',{attrs:{"count":_vm.companySecurity.password_length}},[_vm._v("{count} character long | {count} characters long")]),_vm._v(",  "),_c('t',{attrs:{"count":_vm.companySecurity.password_numbers}},[_vm._v("{count} digit | {count} digits")]),_vm._v("  "),_c('t',[_vm._v("and")]),_vm._v("  "),_c('t',{attrs:{"count":_vm.companySecurity.password_symbols}},[_vm._v("{count} special character | {count} special characters")]),_vm._v("  ) ")],1)]),_c('HtFormInput',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
                required: true,
                regex: new RegExp(_vm.companySecurity.password_regex),
                confirmed: 'password'
            }),expression:"{\n                required: true,\n                regex: new RegExp(companySecurity.password_regex),\n                confirmed: 'password'\n            }",modifiers:{"disable":true}}],ref:"password",attrs:{"id":'password',"name":"password","type":"password","show-asterisk":true,"placeholder":_vm.translate('Enter new password'),"data-vv-as":_vm.translate('new password'),"show-optional":false},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('HtFormInput',{directives:[{name:"validate",rawName:"v-validate.disable",value:({
                required: true,
                regex: new RegExp(_vm.companySecurity.password_regex),
                confirmed: 'password'
            }),expression:"{\n                required: true,\n                regex: new RegExp(companySecurity.password_regex),\n                confirmed: 'password'\n            }",modifiers:{"disable":true}}],attrs:{"id":'confirmPassword',"name":'confirmPassword',"type":'password',"show-asterisk":true,"label":_vm.translate('Confirm New Password'),"placeholder":_vm.translate('Confirm new password'),"data-vv-validate-on":"none","data-vv-as":_vm.translate('password confirmation'),"show-optional":false},model:{value:(_vm.confirmedPassword),callback:function ($$v) {_vm.confirmedPassword=$$v},expression:"confirmedPassword"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmedPassword),expression:"confirmedPassword"},{name:"validate",rawName:"v-validate.disable",value:('required'),expression:"'required'",modifiers:{"disable":true}}],attrs:{"type":"hidden","name":"confirmPasswordRequired","data-vv-as":_vm.translate('password confirmation')},domProps:{"value":(_vm.confirmedPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.confirmedPassword=$event.target.value}}}),_c('div',{staticClass:"row-line"},[_c('HtButton',{attrs:{"loading":_vm.loading,"disabled":_vm.loading},nativeOn:{"click":function($event){return _vm.onUpdatePassword.apply(null, arguments)}}},[_c('t',[_vm._v("Save")])],1)],1)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }