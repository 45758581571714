export default {

    bind(el, binding, vnode) {
        if (!binding.value || !binding.value.url) return;

        const apiUrl = binding.value.url;
        const fileId = binding.value.file_id;

        el.setAttribute('data-pimage', fileId);
        vnode.context.API.get(apiUrl, {
            200: (data) => {
                if (vnode.tag === 'img') { el.src = data.link; } else { el.style.backgroundImage = `url(${data.link})`; }
            },
            403: () => {
                vnode.context.$Notifier('App').showError(vnode.context.translate("You don't have sufficient Permission to show this image"));
            },
            onError: (data, code) => {
                vnode.context.$Notifier('App').showError(`${vnode.context.translate('Unable to complete this operation !')} (${code}) `);
            },
        });
    },

    // Care with that, it can spam AWS !
    update(el, binding, vnode) {
        // let apiUrl = binding.value.url;
        const fileId = binding.value.file_id;

        // Check if we need to ask AWS again
        if (fileId && el.getAttribute('data-pimage') != fileId) {
            el.style.backgroundImage = '';
            binding.def.bind(el, binding, vnode);
        }
    },
};
