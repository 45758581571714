<template>
    <HoldingResourceList
        :route-list="'HoldingResourcesSurveysList'"
        :collection="surveys"
    >
        <template #table="holdingResourceList">
            <SurveysList
                :items="holdingResourceList.items"
                :external-search="holdingResourceList.search"
                @onCellClicked="holdingResourceList.onCellClicked"
            />
        </template>
    </HoldingResourceList>
</template>
<script>
import HoldingResourceList from '@/components/globals/HoldingResourceList.vue';
import SurveysList, { surveyConfig } from '@/resourcesList/SurveysList.vue';
import CompanySurveyCollection from '@/models/CompanySurveyCollection';

export default {
    name: 'PageSurveysList',
    components: {
        HoldingResourceList,
        SurveysList,
    },

    data() {
        return {
            surveys: new CompanySurveyCollection(surveyConfig.fields).with(surveyConfig.relations),
        };
    },
};
</script>
