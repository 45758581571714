<template>
    <div class="user-requirement-iban-edit">
        <div class="mb-10">
            <HtFormInput
                :id="`iban-owner-${index}`"
                v-model="companyUserRequirement.value.owner"
                v-validate.disable="{
                    required: companyUserRequirement.value.iban.length > 0 || companyUserRequirement.value.bic.length > 0,
                    alpha_spaces_hyphen: true,
                }"
                :name="`iban-owner-${index}`"
                :cypress="`iban-owner-${index}`"
                type="text"
                class="no-margin"
                :data-vv-as="translate('owner')"
                :placeholder="translate('Owner')"
                :disabled="!isParticipant || isDisabled"
                @input="onChanged"
            />
        </div>
        <div class="mb-10">
            <HtFormInput
                :id="`iban-number-${index}`"
                v-model="companyUserRequirement.value.iban"
                v-validate.disable="{
                    required: companyUserRequirement.value.owner.length > 0 || companyUserRequirement.value.bic.length > 0,
                    iban: true,
                }"
                :name="`iban-number-${index}`"
                :cypress="`iban-number-${index}`"
                type="text"
                class="no-margin"
                :data-vv-as="translate('IBAN')"
                :placeholder="translate('IBAN')"
                :disabled="!isParticipant || isDisabled"
                :error-append="backendIbanError"
                @input="onChanged"
            />
        </div>
        <div>
            <HtFormInput
                :id="`iban-bic-${index}`"
                v-model="companyUserRequirement.value.bic"
                v-validate.disable="{
                    required: companyUserRequirement.value.owner.length > 0 || companyUserRequirement.value.iban.length > 0,
                    bic: true
                }"
                :name="`iban-bic-${index}`"
                :cypress="`iban-bic-${index}`"
                type="text"
                class="no-margin"
                :data-vv-as="translate('BIC')"
                :placeholder="translate('BIC')"
                :disabled="!isParticipant || isDisabled"
                :error-append="backendBicError"
                @input="onChanged"
            />
        </div>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import UserRequirementEditMixin from './UserRequirementEditMixin';

export default {
    name: 'UserRequirementIbanEdit',

    components: {
        HtFormInput,
    },

    mixins: [UserRequirementEditMixin],

    inject: ['$validator'],

    props: {
        isParticipant: {
            type: Boolean,
            default: false,
        },
        index: {
            type: Number,
            required: true,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            backendIbanError: '',
            backendBicError: '',
        };
    },

    computed: {
        showButton() {
            const hasFill = this.companyUserRequirement.value.owner.trim() !== ''
                && this.companyUserRequirement.value.bic.trim() !== ''
                && this.companyUserRequirement.value.iban.trim() !== '';

            return hasFill && this.companyUserRequirement.value !== this.requirement.value;
        },
    },

    created() {
        if (!this.companyUserRequirement.value) {
            this.companyUserRequirement.value = {
                owner: '',
                iban: '',
                bic: '',
            };
        } else {
            this.companyUserRequirement.value = JSON.parse(this.companyUserRequirement.value);
        }
    },

    methods: {
        validateAndSave: debounce(function () {
            Promise.all([
                this.$validator.validate(`iban-owner-${this.index}`, this.companyUserRequirement.value.owner),
                this.$validator.validate(`iban-number-${this.index}`, this.companyUserRequirement.value.iban),
                this.$validator.validate(`iban-bic-${this.index}`, this.companyUserRequirement.value.bic),
            ]).then((values) => {
                if (values.every((value) => value)) {
                    this.updateRequirement()
                        .catch((err) => {
                            this.handleBackendValidation(err.response);
                        });
                }
            });
        }, 700),

        onChanged() {
            let checkChanged = false;

            if (this.companyUserRequirement.value.owner.trim() !== ''
                && this.companyUserRequirement.value.bic.trim() !== ''
                && this.companyUserRequirement.value.iban.trim() !== '') {
                checkChanged = true;

                this.clearBackendErrors();
                this.validateAndSave();
            }

            this.$emit('onChangeValue', this.companyUserRequirement.id, checkChanged);
        },

        handleBackendValidation(response) {
            if (response.status === 422) {
                const validationErrors = response.data.errors;

                if (validationErrors['value.iban']) {
                    this.backendIbanError = this.translate('The IBAN format is invalid');
                }

                if (validationErrors['value.bic']) {
                    this.backendBicError = this.translate('The BIC format is invalid or mismatch the IBAN');
                }
            }
        },

        clearBackendErrors() {
            this.backendIbanError = '';
            this.backendBicError = '';
        },
    },
};
</script>

<style lang="scss" src="./UserRequirementIbanEdit.scss" />
