import Model from '@tony.caron/node-model-proxy/Model';
import CompanyResource from '@/models/CompanyResource';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import CompanyQuizQuestion from './CompanyQuizQuestion';
import CompanyUserQuiz from './CompanyUserQuiz';
import CompanyUserQuizQuestionAnswer from './CompanyUserQuizQuestionAnswer';
import CompanyQuizCollection from './CompanyQuizCollection';

export default class CompanyQuiz extends Model {
    static RESOURCEABLE_CLASS = 'App\\CompanyQuiz';

    status() {
        return this.resource.programs?.models[0].is_enabled ? 'Active' : 'Inactive';
    }

    modelConfig() {
        return {
            controller: 'CompanyQuizController',
            collection: CompanyQuizCollection,
        };
    }

    modelDefaultValues() {
        return {
            translations: {
                name: {},
                message_welcome: {},
                message_congratulary: {},
            },
        };
    }

    modelRelations() {
        return {
            companyUserQuiz: () => this.hasMany(CompanyUserQuiz, 'company_quiz_id', 'id'),
            companyUserQuizQuestionAnswer: () => this.hasMany(CompanyUserQuizQuestionAnswer, 'company_quiz_id', 'id'),
            companyQuizQuestion: () => this.hasMany(CompanyQuizQuestion, 'company_quiz_id', 'id'),
            resource: () => this.hasOne(CompanyResource),
            resourceProgram: () => this.hasMany(CompanyResourceProgram),
        };
    }
}
