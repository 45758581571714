<template>
    <div class="full-width user-nudge-select">
        <template v-if="cCompanyNudge && cCompanyNudge.isLoaded() && companyUser.isLoaded()">
            <form
                class="form-2 spaced"
                data-cy="nudge-select-form"
            >
                <div v-if="nudgeOptions.length">
                    <fieldset>
                        <HtFormSelect
                            id="nudge"
                            v-model="selectedOption"
                            v-validate.disable="'required'"
                            :label="translate('Select an existing nudge')"
                            name="nudge"
                            cypress="nudge"
                            :data-vv-as="translate('Nudge')"
                            :options="nudgeOptions"
                        />
                    </fieldset>

                    <div
                        v-if="selectedOption"
                        class="form-2 spaced"
                    >
                        <InputField
                            id="name"
                            v-model="selectedOption.resource.name"
                            disabled
                            name="name"
                            type="text"
                            :label="translate('Name of the nudge')"
                            validate="required"
                            :validate-as="translate('name of the nudge in default language')"
                        />

                        <HtFormEditor
                            id="message"
                            v-model="item.message"
                            v-validate.disable="'required|max_without_html:500'"
                            type="textarea"
                            name="message"
                            cypress="message"
                            :label="translate('Message')"
                            :data-vv-as="translate('message in default language')"
                            disabled
                            minimal
                            class="mb-4 w-100"
                        />

                        <HtFormUserOrRoleSelect
                            id="nudge_initialisator"
                            v-model="item.company_user_initiator"
                            v-validate.disable="'required'"
                            :data-vv-as="translate('selection of a person initiating the nudge')"
                            :label="translate('Person initiating the challenge')"
                            name="nudge_initialisator"
                            cypress="nudge_initialisator"
                            show-asterisk
                            @on-add="openAddInitiatorUsersModal"
                            @on-remove="onRemoveInitiator"
                        />

                        <fieldset :class="[{ error: errors.has('nudge_recipient') }, 'medium']">
                            <label>
                                <t>Persons receiving the challenge</t>
                            </label>
                            <transition-group
                                name="fade"
                                class="card-6 flex-container"
                            >
                                <template v-if="item.custom_company_user_recipients.length > 0">
                                    <div
                                        v-for="(userRecipient, index) in item.custom_company_user_recipients"
                                        :key="index"
                                        style="display:flex"
                                        class="currentNudge"
                                    >
                                        <UserBlock2
                                            :type="'user'"
                                            :value="userRecipient"
                                            :cypress="`recipient-${index}`"
                                            @onRemove="onRemoveRecipient(userRecipient)"
                                        />
                                    </div>
                                </template>
                                <UserBlock2
                                    :key="-1200"
                                    :cypress="`new-recipient`"
                                    @onAdd="openAddRecipientModal"
                                />
                            </transition-group>
                            <input
                                id="nudge_recipient"
                                v-validate.disable="'required|array'"
                                v-next-field
                                type="hidden"
                                name="nudge_recipient"
                                :value="item.custom_company_user_recipients.length > 0 ? '-' : null"
                                class="full-width"
                                :data-vv-as="translate('selection of a person receiving the nudge')"
                            >
                            <div
                                v-for="(errorMessage, index) in errors.collect('nudge_recipient')"
                                :key="index"
                                class="error-message"
                            >
                                {{ errorMessage }}
                            </div>
                        </fieldset>

                        <HtKeyDatesInputOrder
                            :end-label="translate('Challenge submission date')"
                            :end-has-tooltip="false"
                            :user-program-id="companyUserProgramId"
                            :is-business-day.sync="userTask.is_business_day"
                            :offset-key-date.sync="userTask.company_user_program_key_date_id"
                            :offset-availability-specific-date.sync="userTask.datetime_availability"
                            :offset-availability-number.sync="userTask.offset_availability_number"
                            :offset-availability-unit.sync="userTask.offset_availability_unit"
                            :offset-end-specific-date.sync="userTask.datetime_end"
                            :offset-end-number.sync="userTask.offset_end_number"
                            :offset-end-unit.sync="userTask.offset_end_unit"
                            can-select-specific-date
                        />

                        <fieldset :class="[{ error: errors.has('order') }, 'medium']">
                            <div class="full-width">
                                <label>
                                    <t>Time to complete the challenge from its acceptance</t>
                                    *
                                </label>
                            </div>
                            <div class="input-order">
                                <input
                                    ref="inputDisplayOrder"
                                    v-model="item.time_to_complete"
                                    v-validate.disable="'required'"
                                    v-validate.min_value="1"
                                    v-next-field
                                    type="number"
                                    min="1"
                                    name="order"
                                    data-cy="time_to_complete"
                                    :data-vv-as="translate('time to complete the challenge from its acceptance')"
                                >
                                <div class="input-order-text">
                                    <t :count="item.time_to_complete">
                                        day|days
                                    </t>
                                </div>
                            </div>
                            <div
                                v-for="(errorMessage, index) in errors.collect('order')"
                                :key="index"
                                class="error-message"
                            >
                                {{ errorMessage }}
                            </div>
                        </fieldset>
                    </div>

                    <div class="modal-actions right">
                        <Button
                            :state="buttonState"
                            cypress="nudge-add-button"
                            @click="onValidate()"
                        >
                            {{ translate("Add") }}
                        </Button>
                    </div>
                </div>

                <div
                    v-else
                    class="empty-component"
                >
                    <strong>
                        <t>No nudge is available !</t>
                    </strong>
                </div>
            </form>
        </template>

        <template v-else>
            <IconLoading />
        </template>

        <modalable
            ref="modalableSearchRolePanel"
            class="modalable-1 small"
            :options="{ title: translate('Done by')}"
        >
            <SearchRolePanel
                ref="searchRolePanel"
                :has-roles="false"
                :is_assignable_onboardee="true"
                :multiple-mode="isSearchUserMultipleMode"
                @onValidate="$refs.modalableSearchRolePanel.close()"
            />
        </modalable>
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import CompanyNudgeCollection from '@/models/CompanyNudgeCollection';
import CompanyUserNudgeCollection from '@/models/CompanyUserNudgeCollection';
import HtFormUserOrRoleSelect from '@/components/globals/HtFormUserOrRoleSelect.vue';
import RichEditable from '@/components/globals/RichEditable.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';

export default {
    name: 'UserNudgeSelect',
    components: {
        HtKeyDatesInputOrder,
        RichEditable,
        HtFormUserOrRoleSelect,
        HtFormSelect,
    },
    inject: ['modal'],
    props: {
        companyUserId: {
            type: [String, Number],
            default: '',
        },
        companyUserProgramId: {
            type: Number,
            default: 0,
        },
        intelligentSelection: {
            type: Array,
            default: () => ([]),
        },
    },
    data() {
        return {
            userTask: null,
            isSearchUserMultipleMode: false,
            item: null,
            selectedOption: null,
            buttonState: 'idle',
            cCompanyNudge: null,

            companyUserProgram: new CompanyUserProgram([
                'id',
            ]).with({
                companyProgram: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            keyDate: (query) => {
                                query.with({
                                    locales: (query) => query
                                        .select(['id', 'name', 'language_key']),
                                });
                            },
                        });
                },
            }).where([
                ['id', '=', this.companyUserProgramId],
            ]),

            companyUserNudgeCollection: new CompanyUserNudgeCollection([
                'id',
                'company_user_program_id',
                'company_nudge_id',
                'name',
                'message',
                'company_user_id',
                'company_user_initiator_id',
                'company_user_recipient_id',
                'time_to_complete',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'company_user_program_id',
                        'company_user_program_key_date_id',
                        'offset_availability_number',
                        'offset_availability_unit',
                        'offset_end_number',
                        'offset_end_unit',
                        'is_business_day',
                        'datetime_availability',
                        'datetime_end',
                    ]);
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']);
                },
                companyNudge: (query) => {
                    query.select(['id']);
                },
                companyUserInitiator: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']);
                },
                companyUserRecipient: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']);
                },
            }),
            companyUser: new CompanyUser([
                'id',
                'firstname',
                'lastname',
                'image',
                'arrival_date',
                'company_language_id',
            ])
                .with({
                    companyLanguage: (query) => {
                        query.select(['id', 'key']);
                    },
                }),
        };
    },

    computed: {
        companyUserNudgeInitiator: {
            get() {
                if (this.item) {
                    return {
                        company_user_id: this.item.company_user_initiator ? this.item.company_user_initiator.id : null,
                        company_user: this.item.company_user_initiator,
                    };
                }
            },
            set(selectedInitiator) {
                if (selectedInitiator && selectedInitiator.company_user) {
                    this.item.company_user_initiator_id = selectedInitiator.company_user.id;
                    this.item.company_user_initiator = selectedInitiator.company_user;
                } else {
                    this.item.company_user_initiator_id = null;
                    this.item.company_user_initiator = null;
                }
            },
        },
        userLanguage() {
            return this.companyUser?.company_language?.key;
        },

        nudgeOptions() {
            return this.cCompanyNudge.$.models.map((nudge) => ({
                id: nudge,
                name: nudge.resource.translations.name[this.userLanguage]
                    ?? nudge.resource.name,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
    },

    watch: {
        selectedOption() {
            if (this.selectedOption) {
                this.item.company_nudge_id = this.selectedOption.id;
                this.item.message = this.selectedOption.message;
                this.item.time_to_complete = this.selectedOption.time_to_complete;
                this.item.company_user_program_task.offset_availability_number = this.selectedOption.resource.programs.models[0].offset_availability_number;
                this.item.company_user_program_task.offset_availability_unit = this.selectedOption.resource.programs.models[0].offset_availability_unit;
                this.item.company_user_program_task.offset_end_number = this.selectedOption.resource.programs.models[0].offset_end_number;
                this.item.company_user_program_task.offset_end_unit = this.selectedOption.resource.programs.models[0].offset_end_unit;
                this.item.company_user_program_task.is_business_day = this.selectedOption.resource.programs.models[0].is_business_day;
            } else {
                this.resetItem();
            }
        },
    },

    async created() {
        this.companyUserProgram.get();

        this.companyUser.id = this.companyUserId;
        await this.companyUser.get();

        this.resetItem();

        await this.loadCompanyNudge();

        this.configs = { ...this.configs, ...this.options };

        this.modal.setTitle(this.translate('Add nudge'));
    },
    methods: {
        resetItem() {
            this.item = this.companyUserNudgeCollection.new();
            this.item.company_user_id = this.companyUser.id;
            this.item.company_user_program_task = new CompanyUserProgramTask();
            this.item.time_to_complete = 1;

            this.userTask = this.item.company_user_program_task;

            // fixme: added company_user field, required in SearchRolePanel TOREFACTO
            const { companyUser } = this;
            companyUser.company_user = this.companyUser.toObject();
            this.item.custom_company_user_recipients = [companyUser.company_user];

            this.item.company_user_program_id = this.companyUserProgramId;
        },
        openAddInitiatorUsersModal() {
            this.isSearchUserMultipleMode = false;
            this.$refs.searchRolePanel.init({
                title: this.translate('Nudge initiator'),
                selected: [this.companyUserNudgeInitiator],
                onUnselect: this.onRemoveInitiator,
                intelligentSelection: this.intelligentSelection,
                onSelect: (selectedInitiator) => {
                    this.companyUserNudgeInitiator = selectedInitiator;
                    this.$refs.modalableSearchRolePanel.close();
                },
            });
            this.$refs.modalableSearchRolePanel.open();
        },
        onRemoveInitiator() {
            this.item.company_user_initiator = null;
        },
        openAddRecipientModal() {
            this.isSearchUserMultipleMode = true;
            this.$refs.searchRolePanel.init({
                title: this.translate('Nudge recipient'),
                onValidate: this.addRecipients,
                intelligentSelection: this.intelligentSelection,
                selected: this.item.custom_company_user_recipients,
            });
            this.$refs.modalableSearchRolePanel.open();
        },
        addRecipients(recipients) {
            this.item.custom_company_user_recipients = recipients;
        },
        onRemoveRecipient(recipient) {
            this.item.custom_company_user_recipients = this.item.custom_company_user_recipients.filter(
                (companyUserRecipientItem) => companyUserRecipientItem.company_user_id !== recipient.company_user_id,
            );
        },
        onValidate() {
            this.$validator.validateAll().then(() => {
                if (this.errors.any()) {
                    return;
                }

                this.item.save(this.companyUserId).then(() => {
                    this.$emit('onUpdate');
                    this.modal.close();
                }).catch(() => {
                    this.button_state = 'idle';
                });
            });
        },
        async loadCompanyNudge() {
            this.cCompanyNudge = new CompanyNudgeCollection([
                'id',
                'time_to_complete',
                'company_nudge_initiator_user_id',
                'company_nudge_initiator_role_id',
                'company_nudge_creator_user_id',
                'message',
            ])
                .with({
                    companyNudgeInitiatorRole: (query) => {
                        query.select(['id', 'name', 'alias']);
                    },
                    companyNudgeInitiatorUser: (query) => {
                        query.select(['id', 'firstname', 'lastname', 'image']);
                    },
                    companyNudgeCreatorUser: (query) => {
                        query.select(['id', 'firstname', 'lastname', 'image']);
                    },
                    resource: (query) => {
                        query
                            .select(['id', 'name'])
                            .with({
                                programs: (query) => {
                                    query.select([
                                        'id',
                                        'offset_availability_number',
                                        'offset_availability_unit',
                                        'offset_end_number',
                                        'offset_end_unit',
                                        'is_business_day',
                                    ]);
                                },
                            });
                    },
                })
                .whereHas({
                    resource: (query) => {
                        query.whereHas({
                            programs: (query) => {
                                query.where([
                                    ['is_enabled', '=', true],
                                ]);
                            },
                        });
                    },
                });

            await this.cCompanyNudge.get();
        },
    },
};
</script>

<style lang="scss" scoped src="./UserNudgeSelect.scss"></style>
