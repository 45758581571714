import Vue from 'vue';

export default {
    findById(id) {
        return Vue.prototype.$http.get(`foc/enrolling/bulk/${id}`);
    },

    getSummaryInformation(id) {
        return Vue.prototype.$http.get(`foc/enrolling/bulk/${id}/summary-information`);
    },

    getAssignableRoles(id) {
        return Vue.prototype.$http.get(`foc/enrolling/bulk/${id}/assignable-roles`);
    },

    removeUsers(id, config = {}) {
        return Vue.prototype.$http.delete(`foc/enrolling/bulk/${id}/remove-users`, config);
    },

    finalize(id) {
        return Vue.prototype.$http.post(`foc/enrolling/bulk/${id}/finalize`);
    },

    updateUserPersonalInformation(id, data = {}) {
        return Vue.prototype.$http.post(`foc/enrolling/bulk/${id}/information/users`, data);
    },

    validateUserPersonalInformation(id, data = {}) {
        return Vue.prototype.$http.post(`foc/enrolling/bulk/${id}/information/validate-step`, data);
    },

    updateUserTarget(id, data = {}) {
        return Vue.prototype.$http.post(`foc/enrolling/bulk/${id}/target/users`, data);
    },

    validateUserTarget(id) {
        return Vue.prototype.$http.post(`foc/enrolling/bulk/${id}/target/validate-step`);
    },

    updateUserTeam(id, data = {}) {
        return Vue.prototype.$http.post(`foc/enrolling/bulk/${id}/team/users`, data);
    },

    validateUserTeam(id) {
        return Vue.prototype.$http.post(`foc/enrolling/bulk/${id}/team/validate-step`);
    },

    getUsersFilters(id) {
        return Vue.prototype.$http.get(`foc/enrolling/bulk/${id}/program/filters`);
    },
};
