import Vue from 'vue';
import api from '@/store/api';

/**
 * @typedef {Object} Entity
 * @property {number} id - Identifiant unique de l'entité.
 * @property {string} name - Nom de l'entité.
 * @property {string} slug - Slug de l'entité.
 * @property {boolean} is_private - Indique si l'entité est privée ou publique.
 * @property {boolean} is_enabled - Indique si l'entité est activée.
 * @property {boolean} is_editable - Indique si l'entité peut être modifiée.
 */

/**
 * @typedef {{data: Entity}} EntityResourceResponse
 * @typedef {{data: Entity[]}} EntityCollectionResponse
 */
export default {
    /**
     * @param {any} data
     * @returns {Promise<EntityCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.get(api.endpoints.CONFIGURATION_ENTITIES, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<EntityResourceResponse>}
     */
    get(id, config = {}) {
        return Vue.prototype.$http.get(`${api.endpoints.CONFIGURATION_ENTITIES}/${id}`, config);
    },

    /**
     * @param {number|string} id
     * @param {Object} filters
     * @param {import("axios").AxiosRequestConfig} config
     * @returns
     */
    getValues(id, filters = {}, config = {}) {
        const params = new URLSearchParams();
        Object.entries(filters).forEach(([key, value]) => params.append(`filter[${key}]`, value));

        return Vue.prototype.$http.get(`${api.endpoints.CONFIGURATION_ENTITIES}/${id}/values?${params.toString()}`, config);
    },

    /**
     * @param {any} data
     * @returns {Promise<EntityResourceResponse>}
     */
    create(data = {}) {
        return Vue.prototype.$http.post(api.endpoints.CONFIGURATION_ENTITIES, data);
    },

    /**
     * @param {number|string} id
     * @param {any} data
     * @returns {Promise<EntityResourceResponse>}
     */
    update(id, data = {}) {
        return Vue.prototype.$http.put(`${api.endpoints.CONFIGURATION_ENTITIES}/${id}`, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<{}>}
     */
    delete(id, config = {}) {
        return Vue.prototype.$http.delete(`${api.endpoints.CONFIGURATION_ENTITIES}/${id}`, config);
    },

    /**
     * @param {number|string} entityId
     * @param {any} data
     * @returns {{}}
     */
    importBasicValues(entityId, data) {
        return Vue.prototype.$http.post(`${api.endpoints.CONFIGURATION_ENTITIES}/${entityId}/import-basic-values`, data);
    },

    /**
     * @param {number|string} entityId
     * @param {any} data
     * @returns {{}}
     */
    importCustomValues(entityId, data) {
        return Vue.prototype.$http.post(`${api.endpoints.CONFIGURATION_ENTITIES}/${entityId}/import-custom-values`, data);
    },
};
