import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyProgramNotificationTemplateLocale from './CompanyProgramNotificationTemplateLocale';

export default class CompanyProgramNotificationTemplateLocaleCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyProgramNotificationTemplateLocale,
        };
    }
}
