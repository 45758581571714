<template>
    <UtilsTemplate
        :title="
            translate('{count} unread message | {count} unread messages',
                      {count: countUnreadMessage})"
        :subtitle="subtitle"
        @click.native="openChat"
    >
        <template #header>
            <div
                class="message-icon-wrapper branding-bg"
                :class="{'no-new-message': countUnreadMessage === 0}"
            >
                <FontAwesomeIcon
                    :icon="['far', 'comment-alt']"
                    class="message-icon"
                />
            </div>
        </template>
        <template #footer>
            <HtButton
                type="tertiary"
                size="small"
                icon="arrow-right"
            >
                <t>Open chat</t>
            </HtButton>
        </template>
    </UtilsTemplate>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';
import CompanyChatChannelCollection from '@/models/CompanyChatChannelCollection';
import UtilsTemplate from '../templates/UtilsTemplate.vue';

export default {
    name: 'ChatWidget',
    components: {
        UtilsTemplate,
        HtButton,
    },
    shared: {
        socket: {
            chatOpened: {
                type: Boolean,
                default: false,
            },
            count: {
                type: Number,
                default: 0,
            },
            channelCollection: {
                type: CompanyChatChannelCollection,
                default: null,
            },
            chatListChannelComponent: {
                type: Object,
                Object: null,
            },
        },
    },
    computed: {
        countChannels() {
            return this.shared.socket.channelCollection?.models?.length;
        },
        countUnreadMessage() {
            return this.shared.socket.count;
        },
        lastUnreadChannel() {
            if (!this.shared.socket.channelCollection) return false;
            return this.shared.socket.channelCollection.models
                .filter((channel) => channel.company_chat_channel_user.channelUserMe.count > 0)
                .sort((a, b) => new Date(b.last_message_at || b.created_at)
                    - new Date(a.last_message_at || a.created_at))
                .pop();
        },
        subtitle() {
            if (!this.lastUnreadChannel) {
                return this.translate('{count} conversation | {count} conversations', { count: this.countChannels });
            }
            const lastMessageTime = this.$Utils.moment(
                this.lastUnreadChannel.last_message_at,
            );
            const now = this.$Utils.moment();
            if (now.diff(lastMessageTime, 'hours') === 0) {
                return this.translate('Last message received {min} minutes ago', { min: now.diff(lastMessageTime, 'minutes') });
            }
            if (now.diff(lastMessageTime, 'days') === 0) {
                return this.translate('Last message received {h} hours ago', { h: now.diff(lastMessageTime, 'hours') });
            }
            return this.translate('Last message received the {date}', { date: lastMessageTime.format('HH:mm DD-MM-YY') });
        },
    },
    methods: {
        async openChat() {
            if (this.lastUnreadChannel) {
                await this.shared.socket.chatListChannelComponent
                    .setActive(this.lastUnreadChannel.id);
            }
            this.shared.socket.chatOpened = true;
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
.message-icon-wrapper{
    border-radius: 8px;
    height: 56px;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.no-new-message{
        background: $neutral-300;
        color: $neutral-white;
    }

    &.has-new-message{
        background: $primary;
    }
    .message-icon{
        height: 24px;
        width: 24px;
    }
}
</style>
