<template>
    <div>
        <div v-for="(logs, type) in report">
            <ul class="ml-10">
                <li
                    v-for="(count, code) in logs"
                    class="ml-20"
                >
                    <DSIconWarning
                        v-if="type === 'warning'"
                        width="15"
                        height="12"
                    />
                    <DSIconError
                        v-else-if="type === 'error'"
                        width="12"
                        height="12"
                    />
                    <DSIconSuccess
                        v-else-if="type === 'info'"
                        width="12"
                        height="12"
                    />
                    {{ code }} : <b>{{ count }}</b>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import DSIconError from '@/components/design-system/icons/Alerts/DSIconError.vue';
import DSIconSuccess from '@/components/design-system/icons/Alerts/DSIconSuccess.vue';
import DSIconWarning from '@/components/design-system/icons/Alerts/DSIconWarning.vue';

export default {
    name: 'IntegrationReport',
    components: { DSIconWarning, DSIconSuccess, DSIconError },
    mixins: [],
    props: {
        report: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
