<template>
    <div>
        <HtLanguageSelect
            id="company-language"
            :value="shared.session.company.company_languages.enabled.find(language => language.key === editingLang)"
            name="company-language"
            :custom-option-label="customLanguageOptionLabel"
            :disabled="disabled"
            @input="onInput"
        />
        <slot :editing-lang="editingLang" />
    </div>
</template>

<script>
import HtLanguageSelect from '@/components/globals/HtLanguageSelect.vue';

export default {
    name: 'TranslatedForm',
    components: {
        HtLanguageSelect,
    },
    inject: ['$validator'],
    props: {
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            editingLang: this.shared.session.company.company_language.key,
        };
    },
    methods: {
        onInput(v) {
            this.$set(this, 'editingLang', v.key);
            this.$emit('input', v);
        },
        customLanguageOptionLabel(language) {
            return language.name;
        },
    },

};
</script>

<style scoped>

</style>
