<template>
    <HtTable
        :items="items"
        :columns="columns"
        cypress="quiz-resource-table"
        :empty-message="'There are no quiz to display yet...'"
        :external-search="externalSearch"
        @onCellClicked="$emit('onCellClicked', $event)"
    >
        <template #cell(filters)="scope">
            <ResourceFilter :resource-filters="getFilters(scope.item)" />
        </template>

        <template #cell(participants)="scope">
            <ParticipantList
                :users="getParticipants(scope.item)"
                has-roles
                :limit="3"
            />
        </template>

        <template #cell(status)="scope">
            <HtTag :color="getStatus(scope.item)">
                <t>{{ getStatus(scope.item) }}</t>
            </HtTag>
        </template>

        <template #cell(program_name)="scope">
            {{ getProgramName(scope.item) }}
        </template>
    </HtTable>
</template>
<script>
import HtTable from '@/components/globals/HtTable.vue';
import ParticipantList from '@/components/globals/ParticipantList.vue';
import ResourceListMixin from '@/mixins/ResourceListMixin';
import ResourceFilter from '@/components/resources/ResourceFilter.vue';
import CompanyQuiz from '@/models/CompanyQuiz';

export default {
    name: 'QuizzesList',
    components: { HtTable, ParticipantList, ResourceFilter },
    mixins: [
        ResourceListMixin,
    ],
    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'resource.name',
                    class: 'font-weight-bold',
                    sortDirection: 'asc',
                    search: true,
                },
                {
                    label: this.translate('Filters'),
                    key: 'filters',
                    sortable: false,
                },
                {
                    label: this.translate('Participants'),
                    key: 'participants',
                    class: 'd-none d-md-table-cell',
                    sortable: false,
                },
                {
                    label: this.translate('Status'),
                    key: 'status',
                    class: 'd-none d-md-table-cell',
                    sortable: false,
                },
                {
                    label: this.translate('Program'),
                    key: 'program_name',
                    sortable: false,
                    class: 'hide-mobile',
                    search: false,
                },
            ],
        };
    },
    methods: {
        getFilters(item) {
            return item instanceof CompanyQuiz
                ? item.resource.programs.models[0].filters.models
                : item.resource.programs[0].filters;
        },

        getParticipants(item) {
            return item instanceof CompanyQuiz
                ? item.resource.programs.models[0].participants.models
                : item.resource.programs[0].participants;
        },

        getProgramName(item) {
            return item instanceof CompanyQuiz
                ? item.resource.programs.models[0].program.name_translated
                : item.resource.programs[0].program.name;
        },

        getStatus(item) {
            if (item instanceof CompanyQuiz) {
                return item.status();
            }

            if (item.resource.programs[0].is_enabled) {
                return 'Active';
            }

            return 'Inactive';
        },
    },
};

export const quizConfig = {
    fields: [
        'id',
        'translations',
        'message_welcome',
        'message_congratulary',
    ],
    relations: {
        companyUserQuizQuestionAnswer: (query) => {
            query.select(['id']);
        },
        companyQuizQuestion: (query) => {
            query.select(['id', 'text', 'image', 'company_quiz_id', 'translations', 'file_translations']);
            query.with({
                companyQuizQuestionAnswer: (query) => {
                    query.select(['id', 'text', 'image', 'is_correct_answer', 'company_quiz_question_id', 'translations', 'file_translations'])
                        .with({
                            companyFiles: (query) => {
                                query.select(['id', 'name', 'path', 'fullpath']);
                            },
                        });
                },
                companyFiles: (query) => {
                    query.select(['id', 'name', 'path', 'fullpath']);
                },
            });
        },
        resource: (query) => {
            query.select(['id', 'name', 'translations']).with({
                programs: (query) => {
                    query.select(['id', 'resourceable_id', 'resourceable_type', 'company_program_id', 'is_enabled'])
                        .with({
                            program: (query) => {
                                query.select(['id']).with({
                                    keyDate: (query) => query.with({
                                        locales: (query) => query.select(['id', 'language_key', 'name']),
                                    }),
                                    locales: (query) => query.select(['id', 'name', 'description', 'language_key']),
                                });
                            },
                            participants: (query) => {
                                query.select(['id', 'company_user_id', 'company_role_id'])
                                    .with({
                                        user: (query) => {
                                            query.select(['id', 'firstname', 'lastname', 'image']);
                                        },
                                        role: (query) => {
                                            query.select(['id', 'name', 'alias', 'is_removable', 'is_heyteam']);
                                        },
                                    });
                            },
                            filters: (query) => {
                                query
                                    .with({
                                        entity: (query) => query.select(['id', 'name', 'slug', 'is_heyteam']),
                                        value: (query) => {
                                            query.select([
                                                'id',
                                                'company_entity_id',
                                                'resourceable_type',
                                                'resourceable_id',
                                            ])
                                                .with({
                                                    jobPosition: (query) => {
                                                        query.select([
                                                            'id',
                                                            'name',
                                                            'is_heyteam',
                                                        ]);
                                                    },
                                                    contract: (query) => {
                                                        query.select([
                                                            'id',
                                                            'name',
                                                            'is_heyteam',
                                                        ]);
                                                    },
                                                    office: (query) => {
                                                        query.select([
                                                            'id',
                                                            'name',
                                                            'is_heyteam',
                                                        ]);
                                                    },
                                                    department: (query) => {
                                                        query.select([
                                                            'id',
                                                            'name',
                                                            'is_heyteam',
                                                        ]);
                                                    },
                                                    custom: (query) => {
                                                        query.select([
                                                            'id',
                                                            'key',
                                                            'name',
                                                        ]);
                                                    },
                                                });
                                        },
                                    });
                            },
                            dependencies: (query) => query.select([
                                'id',
                                'resourceable_id',
                                'resourceable_type',
                                'company_program_id',
                                'is_enabled',
                            ]),
                            dependents: (query) => query.select(['id', 'resourceable_type']),
                        });
                },
            });
        },
    },
};

</script>
