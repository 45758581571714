<template>
    <div>
        <AdditionByNamesUserSelector
            id="users-selection"
            v-model="users"
            v-validate.disable="'required'"
            name="users-selection"
            :label="translate('Users selection')"
            :data-vv-as="translate('Users selection')"
            :allow-empty="false"
            :show-asterisk="true"
            :show-optional="false"
            :options="users"
            :program-id="programId"
            from-api
            @input="onSelectUser"
        />
    </div>
</template>

<script>
import AdditionByNamesUserSelector from '@/components/pages/foc/multiple/addition/AdditionByNamesUserSelector.vue';

export default {
    name: 'AdditionByNames',

    components: {
        AdditionByNamesUserSelector,
    },

    props: {
        programId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            users: [],
        };
    },

    methods: {
        onSelectUser() {
            this.$emit('update-users', this.users);
            this.$emit('change', this.users.length > 0);
        },
    },
};
</script>
