import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyJobPosition from './CompanyJobPosition';

export default class CompanyJobPositionCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyJobPosition,
        };
    }
}
