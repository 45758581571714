<template>
    <div>
        <FilterableSearch
            ref="filterSearch"
            v-model="chatbotList"
            :prop-keys="['label']"
        />

        <HtTable
            :items="chatbotList"
            :columns="columns"
            class="mt-5"
            @onCellClicked="onEdit"
        >
            <template #cell(actions)="">
                <IconCog />
            </template>
        </HtTable>

        <Modalable
            ref="modalablePreboardingEdit"
            class="modalable-1 large"
            :mode="2"
        >
            <PreboardingEdit
                ref="preboardingEdit"
                :chatbot-slug="chatbotSlug"
            />
        </Modalable>
    </div>
</template>
<script>
import PreboardingEdit from '@/components/resources/customize/preboarding/PreboardingEdit.vue';
import CompanyChatbot from '@/models/CompanyChatbot';

export default {
    name: 'PreboardingList',
    components: {
        PreboardingEdit,
    },

    data() {
        return {
            chatbotSlug: null,
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'label',
                    size: 10.5,
                    class: 'full-mobile',
                },
                {
                    label: '',
                    key: 'actions',
                    size: 1.5,
                    class: 'full-mobile',
                    sortable: false,
                },
            ],
            aPreboarding: [
                {
                    label: this.translate(CompanyChatbot.slugToName[CompanyChatbot.SLUG_FULL]),
                    key: CompanyChatbot.SLUG_FULL,
                },
                {
                    label: this.translate(CompanyChatbot.slugToName[CompanyChatbot.SLUG_LIGHT]),
                    key: CompanyChatbot.SLUG_LIGHT,
                },
                {
                    label: this.translate(CompanyChatbot.slugToName[CompanyChatbot.SLUG_SIGN_ONLY]),
                    key: CompanyChatbot.SLUG_SIGN_ONLY,
                },
            ],
        };
    },

    computed: {
        chatbotList() {
            return this.aPreboarding.filter((chatbot) => {
                if (chatbot.key === CompanyChatbot.SLUG_SIGN_ONLY) {
                    return this.$env.get('APP_ENV') !== 'production';
                }
                return true;
            });
        },
    },

    methods: {
        onEdit(row) {
            this.chatbotSlug = row.item.key;
            this.$refs.modalablePreboardingEdit.open();
        },
    },
};
</script>
