import Model from '@tony.caron/node-model-proxy/Model';
import Utils from '../Utils';
import I18n from '../modules/i18n/I18n';
import Company from './Company';
import CompanyResource from './CompanyResource';
import CompanyUserSurvey from './CompanyUserSurvey';
import CompanySurveyQuestion from './CompanySurveyQuestion';
import CompanySurveyQuestionLocale from './CompanySurveyQuestionLocale';
import CompanySurveySupervisor from './CompanySurveySupervisor';
import CompanySurveyCollection from './CompanySurveyCollection';
import CompanyProgramResourceFilter from './CompanyProgramResourceFilter';
import CompanySurveyContent from './CompanySurveyContent';
import CompanyResourceProgram from './CompanyResourceProgram';

export default class CompanySurvey extends Model {
    static COMPONENT_NAME = 'company_survey';

    static STATUS_COMPLETED = 'completed';

    static STATUS_DRAFT = 'draft';

    static STATUS_IN_PROGRESS = 'in_progress';

    static STATUS_SCHEDULED = 'scheduled';

    static RESOURCEABLE_CLASS = 'App\\CompanySurvey';

    modelConfig() {
        return {
            collection: CompanySurveyCollection,
        };
    }

    modelRelations() {
        return {
            resourceProgram: () => this.hasMany(CompanyResourceProgram, 'resourceable_id', 'id'),
            dependencies: () => this.belongsToMany(CompanyResourceProgram, 'company_resource_program_dependencies', 'company_resource_program_id', 'dependency_id'),
            company: () => this.belongsTo(Company, 'company_id', 'id'),
            resource: () => this.belongsTo(CompanyResource, 'resourceable_id', 'id'),
            companyUserSurvey: () => this.hasMany(CompanyUserSurvey, 'company_survey_id', 'id'),
            companySurveySupervisor: () => this.hasMany(CompanySurveySupervisor, 'company_survey_id', 'id'),
            companySurveyContent: () => this.hasMany(CompanySurveyContent, 'company_survey_id', 'id'),
            companySurveyQuestion: () => this.hasMany(CompanySurveyQuestion, 'company_survey_id', 'id'),
            companySurveyQuestionLocale: () => this.hasMany(CompanySurveyQuestionLocale, 'company_survey_question_id', 'id'),
            companyProgramResourceFilter: () => this.hasMany(CompanyProgramResourceFilter, 'resource_id', 'id').where(['component_name', '=', 'company_survey']),
        };
    }

    modelCustomAttributes() {
        return {
            name: null,
            display_status: null,
            display_launch_date: null,
            iOffsetTextAnswers: 0,
            iLimitTextAnswers: 3,
        };
    }

    modelAccessors() {
        return {
            display_status: () => {
                const status = {
                    draft: I18n.translate('Draft'),
                    scheduled: I18n.translate('Scheduled'),
                    in_progress: I18n.translate('In progress'),
                    completed: I18n.translate('Closed'),
                };
                return status[this.status] ? status[this.status] : null;
            },
            display_launch_date: () => {
                if (this.launch_date) {
                    return Utils.moment(this.launch_date).format('LLL');
                }
                return null;
            },
        };
    }
}
