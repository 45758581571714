<template>
    <HtFormGroup
        :label="label"
        :error="error"
        :is-required="isRequired"
        :show-asterisk="showAsterisk"
        :tooltip="tooltip"
        :class="{'ht-form-loading': !!loading}"
    >
        <HtFormVariables
            v-if="variables.length > 0"
            :variables="variables"
            :cypress="cypress + '-variables'"
            @addVariable="addVariable"
        />
        <fieldset :class="['ht-form-input-wrapper', {'no-border': type === 'hidden'}]">
            <input
                :id="id"
                ref="input"
                :class="['ht-form-input-wrapper-field', {'no-right-radius': $slots.extra && disabled}]"
                :name="name"
                :value="value"
                :type="computedType"
                :placeholder="placeholder"
                :disabled="disabled"
                :data-cy="cypress"
                :maxlength="maxLength"
                :min="min"
                :max="max"
                :size="size"
                @input="$emit('input', $event.target.value)"
                @focus="$emit('focus')"
                @blur="$emit('blur')"
                @keyup.13="$emit('onKeyupEnter')"
            >
            <div v-if="$slots.extra">
                <slot name="extra" />
            </div>
            <div
                v-if="type==='password'"
                @click="toggleShowPassword"
            >
                <FontAwesomeIcon
                    v-if="!showPassword"
                    :icon="['far', 'eye']"
                />
                <FontAwesomeIcon
                    v-else
                    :icon="['far', 'eye-slash']"
                />
            </div>
        </fieldset>
        <slot name="append" />
    </HtFormGroup>
</template>

<script>
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import HtFormMixin from './HtFormMixin';
import HtFormVariables from './HtFormVariables.vue';

export default {
    name: 'HtFormInput',
    components: {
        HtFormVariables,
        HtFormGroup,
    },
    mixins: [
        HtFormMixin,
    ],

    props: {
        value: {
            type: [Number, String],
            default: () => '',
        },

        type: {
            type: String,
            default: () => 'text',
        },

        // if we are making api call while user is inputting
        loading: {
            type: Boolean,
            required: false,
            default: () => false,
        },

        maxLength: {
            type: Number,
            required: false,
            default: () => null,
        },
        min: {
            type: Number,
            required: false,
            default: () => null,
        },
        max: {
            type: Number,
            required: false,
            default: () => null,
        },
        size: {
            type: String,
            required: false,
            default: '1',
        },
    },
    data() {
        return { showPassword: false };
    },
    computed: {
        computedType() {
            return this.type === 'password' && this.showPassword ? 'text' : this.type;
        },
    },

    methods: {
        addVariable(variable) {
            const position = this.$refs.input.selectionStart;

            const text = (this.value) ? this.value.substring(0, position) + variable + this.value.substring(position) : variable;

            this.$emit('input', text);

            this.$nextTick(() => {
                this.$refs.input.setSelectionRange(position + variable.length, position + variable.length);
                this.$refs.input.focus();
            });
        },
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
        },
    },
};
</script>

<style lang="scss" src="./HtFormInput.scss" scoped></style>
<style lang="scss" scoped>
.fa-eye, .fa-eye-slash {
    width: 20px;
    margin: 0 8px;
}
</style>
