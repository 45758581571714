<template>
    <ul>
        <div v-if="type == 'array_object'">
            [
        </div>
        <transition-group name="slide">
            <li
                v-for="(item, k) in data"
                :key="k"
                :class="'li_'+getChildType(item)+' parent_'+type"
            >
                <span :ref="'x_'+k">

                    <!-- SHOW KEY -->
                    <span v-if="type != 'array_object'">
                        <span
                            title="Copy To Clipboard"
                            class="key"
                            :class="'key_'+getChildType(item)"
                            @click.stop.prevent="onToggle2('x_'+k)"
                            @click="onCopy(item, k)"
                        >{{ k }}</span>
                    </span>

                    <template v-if="getChildType(item) == &quot;object&quot; && k.length">:</template>

                    <DebugJson
                        v-if="getChildType(item) === 'object' || getChildType(item) === 'array_object' "
                        :type="getChildType(item)"
                        :data="item"
                        :visible="true"
                    />
                    <span v-else>: <span
                        :ref="'_'+k"
                        v-dompurify-html="format(item)"
                        class="value"
                    /></span>
                </span>
            </li>
        </transition-group>
        <div v-if="type == 'array_object'">
            ]
        </div>
    </ul>
</template>

<script>

import DebugJson from '@/components/debug/DebugJson.vue';

export default {
    name: 'DebugJson',

    components: {
        DebugJson,
    },

    props: {

        data: {
            required: true,
        },

        type: {
            type: String,
            default: 'object',
        },

        visible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            p_visible: true,
            toto: true,
            titi: {},
        };
    },

    methods: {

        onToggle2(index) {
            if (this.$refs[index][0].className.length == 0) { this.$refs[index][0].className = 'hide'; } else { this.$refs[index][0].className = ''; }
        },

        onCopy(item, key) {
            let text = JSON.stringify(item);

            // Patch for sql query
            if (key == 'query') { text = text.substr(1, text.length - 2); }

            this.copyToClipboard(text);
        },

        copyToClipboard(text) {
            const $temp = $('<input>');
            $('body').append($temp);
            $temp.val(text).select();
            document.execCommand('copy');
            $temp.remove();
        },

        _copyToClipboard(element) {
            const $temp = $('<input>');
            $('body').append($temp);
            $temp.val(JSON.stringify($(element).text())).select();
            document.execCommand('copy');
            $temp.remove();
        },

        getChildType(mixed) {
            if (mixed === null) return null;
            let type = typeof mixed;

            const isObject = type == 'object';
            let isArray = false;

            if (isObject) {
                isArray = Array.isArray(mixed);

                if (isArray) {
                    if (mixed.length && typeof mixed[0] === 'object') {
                        type = 'array_object';
                    } else {
                        type = 'array';
                    }
                }
            }

            return type;
        },

        format(s) {
            s = JSON.stringify(s);
            if (s) {
                s = s.replace(/,/g, ', ');
                s = s.replace(/["]([^"]+)"/g, '"<span class="yellow">$1</span>"');
            }

            return s;
        },

    },

};

</script>

<style lang="scss">
span.yellow{
	color:#bfbf82;
}
</style>
<style lang="scss" scoped>

.hide > ul{
	display:none;
}

.key{
	    color: #d69bd6;
	    cursor: pointer;

	    &:hover{
	    	text-decoration: underline;
	    }
}

.value{
	    color: white;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

}

li {
      padding-left: 2rem;
    text-indent: -.7em;
    margin: 2px 0px 0px 0px;
    color: #848181;
}

li.li_object::before {
  content: "[-] ";
  color: grey;
}

li.li_array_object::before {
  content: "[-] ";
  color: grey;
}

li.parent_array_object{

	&.li_object::before {
	  content: "{ ";
	  color: grey;
	  display: block;
	}

	&.li_object::after {
	  content: "}, ";
	  color: grey;
	  display: block;
	}

}
/*
li::before {
  content: "• ";
  color: red; /
}*/

 .slide-enter-active, .slide-leave-active  {
    transition: all .2s ease-in-out;
    overflow-y: hidden;
  }
  .slide-enter{
    max-height: 0;
  }
  .slide-enter-to {
    max-height: 300px;
  }
  .slide-leave{
    max-height: 300px;
  }
  .slide-leave-to{
    max-height: 0;
  }

</style>
