import Vue from 'vue';
import filters from '@/filters';
import VModal from 'vue-js-modal';
import VueMasonry from 'vue-masonry-css';
import vClickOutside from 'v-click-outside';
import PortalVue from 'portal-vue';
import UserAcl from '@/plugins/UserAccessControl/UserAccessControlPlugin';
import Auth from '@/services/Auth';
import PermissionPlugin from '@/plugins/PermissionPlugin';
import TaskPlugin from '@/plugins/TaskPlugin';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import Env from '@/Env';
import ModelValidate from '@/modules/form-validate';
import Debugger from '@/Debugger';
import Branding from '@/Branding';
import Utils from '@/Utils';
import ModelStore from '@tony.caron/node-model-proxy/ModelStore';
import I18n from '@/modules/i18n/I18n';
import Localize from '@/modules/localize';
import BeautifyPlugin from '@/plugins/BeautifyPlugin';
import ValidatorPlugin from '@/plugins/ValidatorPlugin';
import ModelUtilsPlugin from '@/plugins/ModelUtilsPlugin';
import RtwWhiteSocket from '@tony.caron/rtw-white-socket';
import Notifier from '@/Notifier';
import Modalable from '@/Modalable';
import VueFlatpickr from 'vue-flatpickr-component';
import VeeValidate from 'vee-validate';
import VeePlugin from '@/plugins/VeeValidate';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';
import { createHttpPlugin } from '@/plugins/HttpPlugin';
import { getBaseUrl, getNumspotBaseUrl } from '@/helpers/url';

Vue.use(filters);
Vue.use(VModal);
Vue.use(vClickOutside);
Vue.use(PortalVue);
Vue.use(UserAcl, { targetUserId: Auth.getLoggedUser().id });
Vue.use(PermissionPlugin);
Vue.use(TaskPlugin);
Vue.use(VueDOMPurifyHTML, {
    default: {
        ADD_ATTR: ['target'],
    },
    hooks: {
        afterSanitizeAttributes: (currentNode) => {
            if ('target' in currentNode) {
                currentNode.setAttribute('rel', 'noopener');
            }
        },
    },
});
Vue.use(Env);
Vue.use(vClickOutside);
Vue.use(ModelValidate);
Vue.use(Debugger);
Vue.use(Auth);
Vue.use(Branding);
Vue.use(Utils);
Vue.use(ModelStore);
Vue.use(I18n);
Vue.use(Localize);
Vue.use(createHttpPlugin('$http', getBaseUrl));
Vue.use(createHttpPlugin('$numspotHttp', getNumspotBaseUrl));
Vue.use(BeautifyPlugin);
Vue.use(ValidatorPlugin);
Vue.use(ModelUtilsPlugin);
Vue.use(RtwWhiteSocket, {
    name: 'default',
    host: Env.getFromEnv('WEB_SOCKET_BACK_URL'),
    ioOptions: {
        path: Env.getFromEnv('WEB_SOCKET_BACK_PATH'),
        forceNew: true,
    },
    debug: Env.get('WEB_SOCKET_BACK_DEBUG'),
});

Vue.use(Notifier);

Vue.use(Modalable);

Vue.use(VueFlatpickr);
Vue.use(VeeValidate);
Vue.use(VeePlugin);

Vue.use(RtwSharedSpace);
Vue.use(VueMasonry);
