<template>
    <span class="tag">
        <FontAwesomeIcon
            :icon="['fal', 'calendar-plus']"
            class="icon"
        />
        <t v-if="label"> To Schedule </t>
    </span>
</template>

<script>

export default {
    name: 'HtEventToPlanTag',
    props: {
        label: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
.tag {
    color: $semantic-info;
    background-color: $semantic-info-light;
    font-size: 12px;
    line-height: 100%;
    padding: 6px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .icon{
        height: 12px;
        width: 12px;
    }

}

</style>
