import Auth from '@/services/Auth';

export default class UserAccessControlPlugin {
    isOwner(companyUserId) {
        return companyUserId == this.loggedUser.id;
    }

    get loggedUser() {
        return Auth.getLoggedUser();
    }

    static install(Vue) {
        const userAcl = new UserAccessControlPlugin();
        Vue.prototype.$UserAcl = userAcl;
    }
}
