<template>
    <div v-if="companyUserRequirementCategoryCollection.isLoaded()">
        <HtAccordion
            :title="translate('Requirement | Requirements', {count: companyUserRequirementCategoryCollection.models.length})"
            :count="companyUserRequirementCategoryCollection.models.length"
            :has-button="companyUserRequirementCategoryCollection.models.length === 0"
            :button-label="addButtonLabel"
            :is-branding="false"
            cypress="requirement"
            @buttonClick="openCreateModal()"
        >
            <template v-if="companyUserRequirementCategoryCollection.models.length">
                <RequirementCategoryCard
                    v-for="companyUserRequirementCategory in companyUserRequirementCategoryCollection.models"
                    :key="companyUserRequirementCategory.id"
                    :company-user-requirement-category="companyUserRequirementCategory"
                    class="my-3"
                    @on-click-requirement-card="(userRequirementCategory) => openDeleteModal(userRequirementCategory)"
                />
                <div class="mt-4 text-center">
                    <Button
                        type="clear"
                        cypress="requirement-add-button"
                        @click="openCreateModal()"
                    >
                        <slot :name="'buttonText'">
                            {{ translate(addButtonLabel) }}
                        </slot>
                    </Button>
                </div>
            </template>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <strong>
                        <t>No Requirements added</t>
                    </strong>
                </strong>
            </div>
        </HtAccordion>

        <Modalable
            ref="modalableDelete"
            :mode="2"
            class="modalable-1 medium"
        >
            <UserRequirementCategoryDeleteModal
                :clicked-user-requirement-category="clickedUserRequirementCategory"
                @on-delete="() => companyUserRequirementCategoryCollection.get()"
            />
        </Modalable>
        <Modalable
            ref="modalableCreate"
            :mode="2"
            class="modalable-1 medium"
        >
            <UserRequirementCategoryCreateModal
                :company-user-requirement-category-collection="companyUserRequirementCategoryCollection"
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                @on-create="() => companyUserRequirementCategoryCollection.get()"
            />
        </Modalable>
    </div>
</template>

<script>
import HtAccordion from '@/components/globals/HtAccordion.vue';
import UserRequirementCategoryCreateModal from '@/components/pages/onboarding/UserRequirementCategoryCreateModal.vue';
import UserRequirementCategoryDeleteModal from '@/components/pages/onboarding/UserRequirementCategoryDeleteModal.vue';
import CompanyUserRequirementCategoryCollection from '@/models/CompanyUserRequirementCategoryCollection';
import RequirementCategoryCard from '@/components/pages/onboarding/RequirementCategoryCard.vue';

export default {
    name: 'UserRequirementFlow',
    components: {
        HtAccordion,
        UserRequirementCategoryCreateModal,
        UserRequirementCategoryDeleteModal,
        RequirementCategoryCard,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
        forceUpdateRequirementFlow: {
            type: Number,
            required: false,
        },
    },

    data() {
        return {
            id: null,

            /**
             * userRequirementCategory sur lequel l'utilisateur a cliqué
             */
            clickedUserRequirementCategory: null,

            addButtonLabel: 'Add requirement',

            companyUserRequirementCategoryCollection: new CompanyUserRequirementCategoryCollection([
                'id',
                'name',
                'company_user_id',
                'company_user_program_id',
                'order',
                'company_requirement_category_id',
                'display_survey',
            ])
                .with({
                    companyUserRequirementGroup: (query) => {
                        query.select(['id', 'company_user_requirement_category_id']).with({
                            companyUserRequirement: (query) => {
                                query.select([
                                    'id', 'name', 'type', 'is_heyteam',
                                ]);
                            },
                        });
                    },
                })
                .where([
                    ['company_user_program_id', '=', this.companyUserProgramId],
                ]).orderBy([
                    ['order', 'asc'],
                ]),

        };
    },

    watch: {
        forceUpdateRequirementFlow() {
            this.companyUserRequirementCategoryCollection.get();
        },
    },

    created() {
        this.companyUserRequirementCategoryCollection.get();
    },

    methods: {
        onUpdate() {
            this.companyUserRequirementCollection.get();
        },

        openCreateModal() {
            this.$refs.modalableCreate.open();
        },

        openDeleteModal(clickedCompanyUserRequirementCategory) {
            this.clickedUserRequirementCategory = clickedCompanyUserRequirementCategory;
            this.$refs.modalableDelete.open();
        },
    },
};
</script>
