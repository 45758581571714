<template>
    <HtModal
        ref="modal"
        @onClose="resetForm"
    >
        <template #header>
            {{ title }}
        </template>
        <HtFormInput
            :id="'firstname'"
            v-model="form.firstname"
            v-validate.disable="'required'"
            :label="translate('Firstname')"
            :type="'text'"
            :name="'firstname'"
        />
        <HtFormInput
            :id="'lastname'"
            v-model="form.lastname"
            v-validate.disable="'required'"
            :label="translate('Lastname')"
            :type="'text'"
            :name="'lastname'"
        />
        <HtFormInput
            :id="'professional_email'"
            v-model="form.professional_email"
            v-validate.disable="'required|email'"
            :label="translate('Professional email')"
            :type="'text'"
            :name="'professional_email'"
            :data-vv-as="translate('professional email')"
            :disabled="isUpdateMode"
        />
        <Button
            v-if="isUpdateMode && !company.is_holding"
            @click="redirectToUser"
        >
            <t :company="company.name">
                See user on {company}
            </t>
        </Button>
        <template #footer-actions>
            <div class="footer-actions">
                <Button
                    v-if="isUpdateMode"
                    v-tooltip="tooltip"
                    :class="[
                        'negative',
                        {'disabled' : cannotDeleteUser}
                    ]"
                    :state="buttonState"
                    @click="onClickDelete"
                >
                    <t> Delete this account </t>
                </Button>
                <Button
                    :state="buttonState"
                    @click="handleUser"
                >
                    <t>Continue</t>
                </Button>
            </div>
        </template>
    </HtModal>
</template>

<script>
import { mapState } from 'vuex';
import HtModal from '@/components/globals/HtModal.vue';
import CompanyUser from '@/models/CompanyUser';
import api from '@/store/api';
import Utils from '@/Utils';
import Modalable from '@/Modalable';
import I18n from '@/modules/i18n/I18n';

const form = {
    firstname: null,
    lastname: null,
    professional_email: null,
};

export default {
    name: 'EditUserModal',
    components: {
        HtModal,
    },
    props: {
        companyId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            form: null,
            id: null,
        };
    },
    computed: {
        cannotDeleteUser() {
            return !this.company.is_holding || this.company.administrator.id === this.id;
        },
        tooltip() {
            if (!this.company.is_holding) {
                return I18n.translate('You can only delete an holding admin. Login to the corresponding platform to delete this user.');
            }
            if (this.company.administrator.id === this.id) {
                return I18n.translate('You can not delete the holding owner');
            }
            return false;
        },
        buttonState() {
            return this.loading ? 'loading' : 'idle';
        },
        isUpdateMode() {
            return Number.isInteger(this.id);
        },
        title() {
            const updatedTranslation = this.company.is_holding ? 'Update administrator' : 'Update collaborator';

            return this.translate(
                this.isUpdateMode
                    ? updatedTranslation
                    : 'Add administrator'
                ,
            );
        },
        ...mapState('companies', [
            'companies',
        ]),
        company() {
            return this.companies.find((company) => company.id === this.companyId);
        },
    },
    created() {
        this.resetForm();
    },
    methods: {
        async onClickDelete() {
            if (this.cannotDeleteUser) {
                return;
            }

            const clickedDelete = await Modalable.openRegisteredWindow('Deletewindow', {
                content: this.translate(
                    'Confirm deleting the account of {fullname} ?',
                    { fullname: `${this.form.firstname} ${this.form.lastname}` },
                ),
            });

            if (!clickedDelete) {
                return;
            }

            this.loading = true;
            api.holding
                .deleteUser(this.id)
                .then(() => {
                    this.$emit('user-deleted');
                    this.$Notifier('App').showInfo(this.translate('The account of {fullname} has been deleted', { fullname: `${this.form.firstname} ${this.form.lastname}` }));
                    this.$refs.modal.close();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        open(id = null) {
            this.id = id;

            let promise = Promise.resolve();

            if (id) {
                promise = this.loadUser(id);
            }

            promise.then(() => {
                this.$refs.modal.open();
            });
        },
        loadUser(id) {
            const user = new CompanyUser([
                'firstname',
                'lastname',
                'professional_email',
            ]);

            user.where(['id', '=', id]);

            return user.get().then((data) => {
                this.form.lastname = data.lastname;
                this.form.firstname = data.firstname;
                this.form.professional_email = data.professional_email;
            });
        },
        handleUser() {
            this.$validate()
                .then(() => {
                    this.loading = true;
                })
                .then(this.isUpdateMode ? this.updateUser : this.createUser)
                .then(() => {
                    this.$emit(this.isUpdateMode ? 'user-updated' : 'user-created');
                    this.$refs.modal.close();
                })
                .catch(() => {
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        createUser() {
            return api.holding.createUser(this.form);
        },
        deleteUser() {
            return api.holding.deleteUser(this.id);
        },

        updateUser() {
            return api.holding.updateUser(this.id, {
                firstname: this.form.firstname,
                lastname: this.form.lastname,
            });
        },
        resetForm() {
            this.form = { ...form };
        },
        redirectToUser() {
            Utils.autologin(this.companyId)
                .then((url) => {
                    url.searchParams.append('redirect', `/Profile/${this.id}/Info`);
                    window.open(url, '_blank');
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.footer-actions {
    display: flex;
    gap: 0.4rem;
}
</style>
