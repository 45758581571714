<template>
    <HtCard class="mb-5">
        <CompanyOnboardingProbationsList
            ref="probations"
            from="'onboarding'"
            @isLoaded="is_loaded = true"
            @onReload="$emit('onReload')"
        />
    </HtCard>
</template>

<script>
import CompanyOnboardingProbationsList from '@/components/onboarding/CompanyOnboardingProbationsList.vue';

export default {
    name: 'ProgramDetailProbation',
    components: {
        CompanyOnboardingProbationsList,
    },

    data() {
        return {
            is_loaded: false,
        };
    },

    mounted() {
        window.scrollTo(0, 0);
    },
};
</script>
