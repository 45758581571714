<template>
    <div>
        <div
            id="main-container"
            class="centered_content"
        >
            <header class="header logo-only">
                <div class="wrapper-user-progression">
                    <div
                        class="user-progression"
                        style="width: 50%;"
                    />
                </div>
                <div class="logo">
                    <img src="../assets/images/logo.svg">
                </div>
            </header>
            <div
                id="main-page"
                class="with-header"
            >
                <div
                    v-if="step == 'default'"
                    class="page-bubble"
                >
                    <div class="content-left">
                        <div class="txt-bubble title-bubble">
                            <h3>
                                <t>Hello, welcome to the HeyTeam onboarding platform</t>
                            </h3>
                            <img
                                class="image-bot-avatar"
                                src="../assets/images/avatar.png"
                                alt=""
                            >
                        </div>

                        <div class="txt-bubble">
                            <p>
                                <strong><t>Create your company account now !</t></strong>
                            </p>
                        </div>
                        <div class="wrapper-button-form">
                            <div
                                v-if="!sign.beta_code"
                                class="button button-1 disabled"
                            >
                                <t>Sign up</t>
                            </div>
                            <div
                                v-else
                                class="button button-1"
                                @click="step = 'sign'"
                            >
                                <t>Sign up</t>
                            </div>
                        </div>
                    </div>
                    <div class="invite-gj">
                        <!-- <img src="../assets/images/good-job.png" alt=""> -->
                        <img
                            src="../assets/images/welcome.png"
                            alt=""
                        >
                    </div>
                </div>
                <transition name="fade">
                    <div
                        v-show="step == 'sign'"
                    >
                        <h1 class="align-center">
                            <t>Create your account on HeyTeam</t>
                        </h1>
                        <form class="form-2">
                            <HtFormInput
                                id="email"
                                v-model="sign.professional_email"
                                v-validate.disable="'required|email'"
                                cypress="professional-email"
                                :label="translate('Email')"
                                type="email"
                                name="email"
                                :placeholder="translate('Email')"
                                :show-asterisk="true"
                                :data-vv-as="translate('email')"
                            />

                            <fieldset :class="{ error: errors.has('password') }">
                                <label for="password"><t>Password</t> * <span style="font-size:1rem">(<t>Password must be at least 12 characters long, 1 digit and 1 special character</t>)</span></label>
                                <input
                                    id="password"
                                    ref="password"
                                    v-model="sign.password"
                                    v-validate.disable="{ required: true, regex: new RegExp('(?=^.{12,}$)(?=(.*\\d){1,})(?=(.*\\W){1,})^.*$') }"
                                    v-next-field
                                    name="password"
                                    class="full"
                                    type="password"
                                    :data-vv-as="translate('password')"
                                >
                            </fieldset>

                            <fieldset :class="{ error: errors.has('confirmPassword') || errors.has('confirmPasswordRequired') }">
                                <label for="confirmPassword"><t>Confirm Password</t> *</label>
                                <input
                                    id="confirmPassword"
                                    v-model="confirm_password"
                                    v-validate.disable="{ required: true, regex: new RegExp('(?=^.{12,}$)(?=(.*\\d){1,})(?=(.*\\W){1,})^.*$'), confirmed: 'password'}"
                                    v-next-field
                                    name="confirmPassword"
                                    class="full"
                                    type="password"
                                    data-vv-validate-on="none"
                                    :data-vv-as="translate('password confirmation')"
                                >
                            </fieldset>

                            <div class="d-flex justify-content-between">
                                <!-- FIRSTNAME -->
                                <HtFormInput
                                    id="firstname"
                                    v-model="sign.firstname"
                                    v-validate.disable="'required|max:64'"
                                    cypress="firstname"
                                    class="col-6 pl-0"
                                    name="firstname"
                                    :placeholder="translate('Firstname')"
                                    :label="translate('Firstname')"
                                    :is-required="true"
                                    :show-optional="false"
                                    :show-asterisk="true"
                                    :max-length="64"
                                    :data-vv-as="translate('firstname')"
                                />

                                <!-- LASTNAME -->
                                <HtFormInput
                                    :id="'lastname'"
                                    v-model="sign.lastname"
                                    v-validate.disable="'required|max:64'"
                                    cypress="lastname"
                                    class="col-6 pr-0"
                                    name="lastname"
                                    :placeholder="translate('Lastname')"
                                    :label="translate('Lastname')"
                                    :is-required="true"
                                    :show-optional="false"
                                    :show-asterisk="true"
                                    :max-length="64"
                                    :data-vv-as="translate('lastname')"
                                />
                            </div>

                            <HtFormSelect
                                id="language"
                                v-model="sign.language"
                                v-validate.disable="'required'"
                                class="col-6 pl-0"
                                :label="translate('Company default language')"
                                name="language"
                                :options="languageOptions"
                                :data-vv-as="translate('language')"
                            />

                            <div class="d-flex justify-content-between">
                                <HtFormInput
                                    id="sign-company-name"
                                    v-model="companyName"
                                    v-validate.disable="'required'"
                                    :label="translate('Company Name')"
                                    type="text"
                                    name="company_name"
                                    cypress="company-name"
                                    :show-asterisk="true"
                                    :data-vv-as="translate('company name')"
                                    :placeholder="translate('Type your company name here')"
                                    class="col-6 pl-0"
                                />

                                <HtFormInput
                                    id="domain"
                                    v-model="sign.domain"
                                    v-validate.disable="'required|domain_check'"
                                    :label="translate('Your team URL')"
                                    type="text"
                                    name="domain"
                                    cypress="domain"
                                    placeholder="mycompany"
                                    :data-vv-as="translate('domain')"
                                    :show-asterisk="true"
                                    class="col-6 pr-0"
                                >
                                    <template #extra>
                                        <span class="text-fixed mr-3">.heyteam.com</span>
                                    </template>
                                </HtFormInput>
                            </div>

                            <HtFormSwitch
                                id="add-default-resource"
                                v-model="sign.has_default_resource"
                                label="Utiliser les ressources template par défaut"
                                :show-optional="false"
                                name="add-default-resource"
                                cypress="add-default-resource"
                            />

                            <fieldset :class="{'error': errors.has('sign_checked')}">
                                <label class="label-checkbox">
                                    <input
                                        v-next-field
                                        v-validate.disable="'required'"
                                        type="checkbox"
                                        name="sign_checked"
                                        data-vv-as="terms and conditions"
                                    >
                                    <div>
                                        <t>By clicking on this box, you accept the HeyTeam</t><span>&nbsp;</span>
                                        <router-link
                                            target="_blank"
                                            :to="{name: 'TermsAndConditions', params: { lang: sign.language }}"
                                            tag="a"
                                        >
                                            <t>Terms & Conditions</t>
                                        </router-link>
                                        <span>&nbsp;</span><t>and</t><span>&nbsp;</span>
                                        <router-link
                                            target="_blank"
                                            :to="{name: 'PrivacyPolicy', params: { lang: sign.language }}"
                                            tag="a"
                                        >
                                            <t>privacy policy</t>
                                        </router-link>
                                    </div>
                                </label>
                            </fieldset>
                            <transition name="fade">
                                <div
                                    v-show="error"
                                    class="message message-error"
                                >
                                    <div class="content">
                                        {{ error }}
                                    </div>
                                </div>
                            </transition>
                            <div class="row-line">
                                <Button
                                    class="red"
                                    :state="button_state"
                                    @keyup.13="onSign"
                                    @click="onSign"
                                >
                                    <t>Confirm my account</t>
                                </Button>
                            </div>
                        </form>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/services/Api';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'SignItem',

    components: {
        HtFormSwitch,
    },

    data() {
        return {
            step: 'default',
            error: '',
            confirm_password: '',
            button_state: 'idle',
            sign: {
                professional_email: '',
                firstname: '',
                lastname: '',
                password: '',
                name: '',
                domain: '',
                beta_code: '',
                language: 'en',
                has_default_resource: true,
            },
            languageOptions: [],
        };
    },

    computed: {
        companyName: {
            get() {
                return this.sign.name;
            },

            set(val) {
                this.sign.domain = (val) ? val.replace(/[^a-zA-Z0-9-_]+/g, '').toLowerCase() : null;
                this.sign.name = val;
            },
        },
    },

    created() {
        this.checkBetaCode();
        this.getLanguages();
    },

    methods: {
        getLanguages() {
            this.API.get('auth/languages', {
                200: ({ languages }) => {
                    const keys = Object.keys(languages);

                    keys.forEach((key) => {
                        this.languageOptions.push({
                            id: languages[key],
                            name: this.translate(key),
                        });
                    });
                },
            });
        },

        checkBetaCode() {
            this.API.post('auth/beta', { beta_code: this.$route.query.beta_code }, {

                200: (data) => {
                    this.sign.beta_code = data.code.code;
                },
            });
        },

        async onSign() {
            await this.$validator.validateAll().then((isValid) => {
                if (isValid === true) {
                    this.error = '';
                    this.button_state = 'loading';

                    this.sign.domain = this.sign.domain.toLowerCase();

                    const COMPANY_API = new Api({ name: 'COMPANY_API', base_url: this.$env.getFromEnv('COMPANY_API_URL', { company_name: this.sign.domain }) });

                    COMPANY_API.post('auth/signup', { signup: this.sign }, {
                        201: (data) => {
                            this.button_state = 'idle';
                            document.location.href = this.$env.getFromEnv('COMPANY_WEB_URL', { company_name: data.domain });
                        },
                        404: () => {
                            this.error = 'It seems you do not belong to this company.';
                            this.button_state = 'idle';
                        },
                        422: (data) => {
                            Object.values(data.errors).forEach((typeError) => {
                                typeError.forEach((message) => {
                                    this.error += message;
                                });
                            });
                            this.button_state = 'idle';
                        },
                        onError: () => {
                            this.button_state = 'idle';
                        },
                    });
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.label-checkbox input {
    flex:30px;
}

.image-bot-avatar {
    position: absolute;
    left: -110px;
    top: -5px;
}

.title-bubble {
    h3 {
        font-size: 2.5rem;
    }
}
</style>
