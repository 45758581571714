<template>
    <div>
        <p class="mb-5"><t>Choose the format of your choice. Each block is configurable and already optimized for mobile!</t></p>

        <div class="enterprise-section-templates-list">
            <div
                v-for="value in templates"
                @click="$emit('on-select', value)"
                class="enterprise-section-templates-item"
            >
                <img
                    :src="`/static/images/settings/enterprisepages/sections/templates/${value.image}`"
                >
                <t>{{ value.name }}</t>
            </div>
        </div>
    </div>
</template>

<script>
import sectionTemplates from '@/components/settings/EnterprisePage/Templates/Sections/templates';

export default {
    name: 'SectionTemplateModal',

    inject: ['modal'],

    data() {
        return {
            templates: sectionTemplates,
        };
    },

    created() {
        this.modal.setTitle(this.translate('Select a template'));
    },
};
</script>

<style lang="scss" scoped>
.enterprise-section-templates-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;

    .enterprise-section-templates-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        max-width: 260px;
        cursor: pointer;

        & img {
            transition: transform .2s;
        }

        &:hover {
            img {
                transform: scale(0.97);
            }
        }
    }
}
</style>
