<template>
    <div class="block">
        <div
            v-user-image="userImage(user)"
            class="block__image"
        />
        <div class="block__description" v-if="shouldLinkToProfile">
            <router-link :to="`/Profile/${user.id}`" target="_blank">{{ user.firstname }} {{ user.lastname }}</router-link>
        </div>
        <div class="block__description" v-else>
            {{ user.firstname }} {{ user.lastname }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
        shouldLinkToProfile: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        userImage(user) {
            return {
                id: user.id,
                image: user.image,
                lastname: user.lastname,
                firstname: user.firstname,
                size: 1,
            };
        },
    },
};
</script>
<style lang="scss" scoped>
.block {
    display: flex;
    align-items: center;
    width: inherit;

    & .block__image {
        margin-right: 4px;
        height: 2.4rem;
        min-height: 2.4rem;
        width: 2.4rem;
        min-width: 2.4rem;
        border-radius: 1000px;
    }

    & .block__description {
        flex-grow: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
</style>
