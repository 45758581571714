<template>
    <div class="selected-user-list">
        <div class="selected-user-list-label">
            {{ users.length }}<t> participants selected</t>
        </div>
        <div
            v-for="user in users"
            :key="user.id"
            class="selected-user-container"
        >
            <div
                v-user-image="{
                    firstname: user.firstname,
                    lastname: user.lastname,
                    image: user.image
                }"
                class="list-channel-item-avatar-img"
            />
            <FontAwesomeLayers
                class="selected-user-remove"
                @click="$emit('remove', user)"
            >
                <FontAwesomeIcon
                    icon="circle"
                    class="icon-circle"
                />
                <FontAwesomeIcon
                    :icon="['fal', 'times']"
                    transform="shrink-9"
                    class="icon-second"
                />
            </FontAwesomeLayers>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ChatGroupManagerHUserList',
    props: {
        users: {
            type: Array,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.selected-user-list {
    border-top: solid #E8E8E8 .5px;
    padding: 5px;
    padding-bottom: 10px;
    display: inline-block;
    height: 100px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    z-index: 10;
    background-color: #F9F9F9;

    &-label {
        color: #A3A3A3;
        padding: 5px;
        position: absolute;
        font-size: 12px;
    }
}

.selected-user-container {
    display: inline-block;
    margin-top: 3.5rem;
    margin-right: 1rem;
    margin-bottom: 1em;
}

.list-channel-item-avatar-img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    &.bg-red {
        background-color: var(--brading-color);
    }
}

.selected-user-remove {
    position: relative;
    bottom:40px;
    left: 30px;
    cursor: pointer;

    .icon-circle {
        color: red;
    }

    .icon-second {
        color: white;
    }
}
</style>
