<template>
    <div class="page-content">
        <h1 class="small">
            {{ getTranslatedMessage(companyFlowCopy['bloc_signup_title']['content']['messages']['message_1']) }}
        </h1>

        <FlowRow
            v-if="messages['bloc_signup_a'] === true"
            :company-user="bubbleUser"
            :full="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <div class="text-form">
                    {{ getTranslatedMessage(companyFlowCopy['bloc_signup_a']['content']['messages']['message_1']) }}
                </div>
                <HtFormInput
                    :id="'email'"
                    v-model="companyUser.email"
                    :type="'email'"
                    :name="'email'"
                    :disabled="true"
                />
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_signup_b'] === true"
            :company-user="bubbleUser"
            :full="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <template v-if="hasSso === true || hasSso === 1">
                    <t>Your connection is made in SSO thanks to your company identifier. You do not need to register a password.</t>
                </template>
                <template v-else>
                    <form class="form-2 spaced">
                        <div class="text-form">
                            {{ getTranslatedMessage(companyFlowCopy['bloc_signup_b']['content']['messages']['message_1']) }}
                        </div>
                        <fieldset :class="{ error: errors.has('password') }">
                            <label for="password">
                                <t>Password</t> *
                                <span style="font-size:1rem">{{ descriptionPassword }}</span>
                            </label>
                            <HtFormInput
                                :id="'password'"
                                ref="password"
                                v-model="companyUser.password"
                                v-validate.disable="{
                                    required: true,
                                    regex: passwordRegex,
                                    confirmed: 'password'
                                }"
                                :name="'password'"
                                :type="'password'"
                                :show-optional="false"
                            />
                        </fieldset>

                        <fieldset :class="{ error: errors.has('confirmPassword') || errors.has('confirmPasswordRequired') }">
                            <label for="confirmPassword"><t>Confirm Password</t>*</label>
                            <HtFormInput
                                :id="'confirmPassword'"
                                v-model="confirmedPassword"
                                v-validate.disable="{
                                    required: true,
                                    regex: passwordRegex,
                                    confirmed: 'password'
                                }"
                                :name="'confirmPassword'"
                                :type="'password'"
                                :data-vv-as="translate('password confirmation')"
                                :show-optional="false"
                            />
                            <input
                                v-model="confirmedPassword"
                                v-validate.disable="'required'"
                                type="hidden"
                                name="confirmPasswordRequired"
                                :data-vv-as="translate('password confirmation')"
                            >
                        </fieldset>

                        <fieldset :class="{'error': errors.has('sign_checked')}">
                            <label class="label-checkbox">
                                <input
                                    v-next-field
                                    v-validate.disable="'required'"
                                    type="checkbox"
                                    name="sign_checked"
                                    :data-vv-as="translate('terms and conditions')"
                                >
                                <div>
                                    <t>I accept the</t>
                                    <span>&nbsp;</span>
                                    <router-link
                                        target="_blank"
                                        :to="{name: 'TermsAndConditions', params: { lang: getUserLanguage }}"
                                        tag="a"
                                    >
                                        <t>terms & conditions</t>
                                    </router-link>
                                    <span
                                        v-for="companyDocumentPublic in companyDocumentPublicCollection.models"
                                        :key="companyDocumentPublic.id"
                                    >
                                        ,&nbsp;
                                        <a
                                            href="#"
                                            @click.stop="companyDocumentPublic.company_files_from_locale.models[0].download()"
                                        >
                                            {{ companyDocumentPublic.name }}
                                        </a>
                                    </span>
                                    <span>&nbsp;</span>
                                    <t>and acknowledge that I've read and accept the</t>
                                    <span>&nbsp;</span>
                                    <router-link
                                        target="_blank"
                                        :to="{name: 'PrivacyPolicy', params: { lang: getUserLanguage }}"
                                        tag="a"
                                    >
                                        <t>confidentiality policy</t>
                                    </router-link>
                                    <span>&nbsp;</span>
                                    <t>of Heyteam</t>
                                </div>
                            </label>
                        </fieldset>
                    </form>
                </template>
            </template>
        </FlowRow>
    </div>
</template>

<script>
import FlowRow from '@/components/flow/FlowRow.vue';
import FlowMixin from '@/components/mixins/FlowMixin.vue';
import CompanySecurityCollection from '@/models/CompanySecurityCollection';
import CompanyUser from '@/models/CompanyUser';
import CompanyDocumentPublicCollection from '@/models/CompanyDocumentPublicCollection';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';

const companySecurity = new CompanySecurityCollection([
    'password_regex', 'password_numbers', 'password_length', 'password_symbols',
]);

export default {
    name: 'FlowSign',
    components: {
        FlowRow,
    },
    mixins: [FlowMixin],

    data() {
        return {
            itemFlow: null,
            confirmedPassword: null,
            block: true,
            companyFlowCopy: {},
            messages: {},
            messagesEnabled: [],
            rowLabel: [],
            globalTimeout: null,
            iMessageIndex: 0,
            companySecurity,
            companyDocumentPublicCollection: new CompanyDocumentPublicCollection([
                'id',
                'name',
                'is_tos',
                'translations',
                'file_translations',
            ]).where([
                ['is_tos', '=', true],
            ]).with({
                companyFilesFromLocale: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
                companyFiles: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
            }).orderBy([
                ['order', 'asc'],
            ]),
        };
    },

    computed: {
        hasSso() {
            return this.$ModelStore.get('Company').hasSso === true
                && this.$ModelStore.get('CompanyUser').main_email === 'professional_email'
                && this.$ModelStore.get('CompanyUser').has_sso === true;
        },
        passwordRegex() {
            return this.companySecurity.models[0].password_regex;
        },
        passwordLength() {
            return this.companySecurity.models[0].password_length;
        },
        passwordSymbols() {
            return this.companySecurity.models[0].password_symbols;
        },
        passwordNumbers() {
            return this.companySecurity.models[0].password_numbers;
        },

        descriptionPassword() {
            const passwordMustBe = this.translate('Password must be at least');
            const characterLong = this.translate('{count} character long | {count} characters long', {
                count: this.passwordLength,
            });
            const digit = this.translate('{count} digit | {count} digits', {
                count: this.passwordNumbers,
            });
            const and = this.translate('and');
            const special = this.translate('{count} special character | {count} special characters', {
                count: this.passwordSymbols,
            });

            return `(${passwordMustBe} ${characterLong} ${digit} ${and} ${special})`;
        },
    },

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },

    async created() {
        setTimeout(() => {
            this.block = false;
        }, 2000);

        this.itemFlow = this.value.fromSaveState();

        if (this.itemFlow.has_sign === 1) this.$emit((this.is_forward) ? 'onNextPage' : 'onPreviousPage');

        this.contents.map((model) => {
            this.companyFlowCopy[model.subtype] = model;

            if (!model.subtype.includes('_title')) {
                this.$set(this.messages, model.subtype, false);
                if (model.is_enabled) this.messagesEnabled.push(model.subtype);
            }
        });
        await this.companyDocumentPublicCollection.get();
        await this.companySecurity.get();

        this.rowLabel = Object.keys(this.messages);
        this.loadRows();
        this.runLoadRows();
    },

    methods: {
        runLoadRows() {
            this.globalTimeout = setInterval(() => {
                this.loadRows();
            }, 1500);
        },

        loadRows() {
            while (this.iMessageIndex <= this.rowLabel.length && this.companyFlowCopy[this.rowLabel[this.iMessageIndex]].is_enabled === false) {
                this.iMessageIndex++;
            }

            if (this.companyFlowCopy[this.rowLabel[this.iMessageIndex]].is_enabled) this.messages[this.rowLabel[this.iMessageIndex]] = true;

            if (this.messagesEnabled.indexOf(this.rowLabel[this.iMessageIndex]) === (this.messagesEnabled.length - 1)) this.$emit('onLoadPage', true);

            this.iMessageIndex++;

            if (this.iMessageIndex === this.rowLabel.length) clearInterval(this.globalTimeout);
        },

        updatePassword() {
            return new Promise((resolve, reject) => {
                this.companyUser.save().then(() => {
                    resolve();
                }).catch(() => {
                    this.$emit('onFail');
                    reject();
                });
            });
        },

        async onValidate() {
            await this.$validator.validateAll();

            if (this.block || this.companyUser.isSaving() || this.errors.any()) {
                this.$emit('onFail');
                return;
            }

            this.updatePassword().then(() => {
                this.itemFlow.has_sign = 1;
                this.itemFlow.save();
                this.$emit('onNextPage');
            });
        },
    },
};
</script>
<style lang="scss" scoped>
	.text-form {
		margin-bottom:17px;
	}

	.label-checkbox {
		display:flex;
		justify-content: flex-start;

		input {
			width: 50px
		}
	}
</style>
