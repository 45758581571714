<template>
    <div class="chat-group-manager">
        <!-- STATE IDLE  -->
        <div class="main-panel" v-show="show.main">
            <div class="chat-group-manager-idle main-panel-top">
                <RoundImagePicker
                    ref="imagePicker"
                    :image-src="newImage.link_preview"
                    @image-change="updateImage"
                />
                <div class="chat-group-manager-infos">
                    <div
                        v-show="mode === 'edit'"
                        class="chat-group-manager-infos-text"
                    >
                        <div class="chat-group-manager-infos-text-name">
                            {{ name }}
                        </div>
                        <div class="chat-group-manager-infos-text-count">
                            {{ participants.length + 1 }} <t> participants</t>
                        </div>
                    </div>
                    <div
                        v-if="newImage.link_preview || channel"
                        class="chat-group-manager-infos-modify"
                        @mousedown="clickModifyButton"
                    >
                        <t>Modify</t>
                    </div>
                </div>

                <div
                    v-if="mode === 'create'"
                    class="chat-group-manager-groupname"
                >
                    <t>Group name</t>
                    <HtInputText
                        id="name"
                        v-model="name"
                        name="name"
                        :class="{'errors':nameError}"
                        @mousedown.native="clearErrors"
                    />
                    <t
                        v-show="nameError"
                        class="sub-text"
                    >
                        Enter group name
                    </t>
                </div>

                <ChatGroupManagerActionList
                    v-if="mode === 'create'"
                    :actions="createActions"
                    @emit="onActionList"
                />
                <ChatGroupManagerActionList
                    v-if="mode === 'edit'"
                    :actions="editActions"
                    @emit="onActionList"
                />
            </div>
            <div
                v-show="mode === 'create'"
                class="chat-search-actions main-panel-bottom"
            >
                <div class="chat-search-actions-title">
                    <div class="chat-search-actions-title-count">
                        <p>{{ participants.length +" / "+maxParticipants }} <t>PARTICIPANTS SELECTED</t></p>
                    </div>
                    <div
                        class="chat-search-actions-title-details"
                        @mousedown="toggleView('details_main')"
                    >
                        <t>See details</t>
                    </div>
                </div>
                <div class="chat-search-actions-buttons">
                    <Button
                        v-if="mode === 'create'"
                        class="red full-width"
                        @click="saveGroup"
                    >
                        <t>Create group</t>
                    </Button>
                </div>
            </div>
        </div>

        <!-- STATE DETAILS MAIN -->
        <div class="main-panel" v-show="show.details_main">
            <ChatGroupManagerVUserList
                class="main-panel-top"
                ref="mainVlist"
                :selected-users="tmpSelectedUsers.participants"
                @add="user => addUser('participants', user)"
                @remove="user => removeUser('participants', user)"
            />
            <div class="main-panel-bottom">
                <ChatGroupManagerHUserList
                    class="huser-list"
                    :users="tmpSelectedUsers.participants"
                    @remove="user => removeUser('participants', user)"
                />
                <div class="chat-search-actions">
                    <div class="chat-search-actions-buttons">
                        <Button
                            class="red"
                            @click="setParticipants"
                        >
                            <t>Save</t>
                        </Button>
                    </div>
                </div>
            </div>
        </div>

        <!-- STATE SEARCH BY NAMES -->
        <div class="main-panel" v-show="show.byName">
            <ChatGroupManagerVUserList
                class="main-panel-top"
                ref="byNameVlist"
                :selected-users="tmpSelectedUsers.byName"
                @add="user => addUser('byName', user)"
                @remove="user => removeUser('byName', user)"
            />

            <div class="main-panel-bottom">
                <ChatGroupManagerHUserList
                    class="huser-list"
                    :users="tmpSelectedUsers.byName"
                    @remove="user => removeUser('byName', user)"
                />
                <div class="chat-search-actions">
                    <div class="chat-search-actions-buttons">
                        <Button
                            class="negative"
                            @click="toggleView('main')"
                        >
                            <t>Cancel</t>
                        </Button>
                        <Button
                            v-if="mode === 'create'"
                            class="red"
                            @click="addParticipants('byName')"
                        >
                            <t>Save</t>
                        </Button>
                        <Button
                            v-else
                            class="red"
                            :state="tmpSelectedUsers.byName.length > 0 ? 'idle' : 'disabled'"
                            @click="addParticipants('byName'); saveGroup();"
                        >
                            <t>Save</t>
                        </Button>
                    </div>
                </div>
            </div>
        </div>

        <!-- STATE SEARCH BY FILTER -->
        <div class="main-panel" v-show="show.byFilter">
            <ChatGroupManagerFilters
                class="main-panel-top"
                ref="groupFilter"
                :max-users="maxParticipants"
                @filtered="onFiltered"
            />
            <div class="chat-search-actions main-panel-bottom">
                <div class="chat-search-actions-title">
                    <div class="chat-search-actions-title-count">
                        <p>{{ virtualFilteredCount +" / "+maxParticipants }} <t>PARTICIPANTS SELECTED</t></p>
                    </div>
                    <div
                        class="chat-search-actions-title-details"
                        @mousedown="toggleView('details_byFilter')"
                    >
                        <t>See details</t>
                    </div>
                </div>
                <div class="chat-search-actions-buttons">
                    <Button
                        class="negative"
                        @click="toggleView('main')"
                    >
                        <t>Cancel</t>
                    </Button>
                    <Button
                        class="red"
                        @click="addParticipants('byFilter')"
                    >
                        <t>Save</t>
                    </Button>
                </div>
            </div>
        </div>

        <!-- STATE DETAILS BY FILTER -->
        <div class="main-panel" v-show="show.details_byFilter">
            <ChatGroupManagerVUserList
                class="main-panel-top"
                ref="byFilterVlist"
                :selected-users="tmpSelectedUsers.byFilter"
                @add="user => addUser('byFilter', user)"
                @remove="user => removeUser('byFilter', user)"
            />
            <div class="main-panel-bottom">
                <ChatGroupManagerHUserList
                    class="huser-list"
                    :users="tmpSelectedUsers.byFilter"
                    @remove="user => removeUser('byFilter', user)"
                />
                <div class="chat-search-actions">
                    <div class="chat-search-actions-buttons">
                        <Button
                            class="red"
                            @click="previous()"
                        >
                            <t>Save</t>
                        </Button>
                    </div>
                </div>
            </div>
        </div>

        <!-- STATE MODIFY INFOS -->
        <div class="main-panel" v-show="show.infos">
            <div class="main-panel-top">
                <RoundImagePicker
                    ref="imagePickerEdit"
                    :image-src="newImage.link_preview"
                    @image-change="updateImage"
                />
                <div class="chat-group-manager-infos">
                    <div
                        v-if="newImage.link_preview"
                        class="chat-group-manager-infos-modify"
                        @mousedown="$refs.imagePickerEdit.openFileSelector()"
                    >
                        <t>Modify image</t>
                    </div>
                </div>

                <div class="chat-group-manager-groupname">
                    <t>Group name</t>
                    <HtInputText
                        id="name"
                        v-model="name"
                        name="name"
                        :class="{'errors':nameError}"
                        @mousedown.native="clearErrors"
                    />
                    <t
                        v-show="nameError"
                        class="sub-text"
                    >
                        Enter group name
                    </t>
                </div>
            </div>

            <div class="chat-search-actions main-panel-bottom">
                <div class="chat-search-actions-buttons">
                    <Button
                        class="red full-width"
                        @click="saveGroup"
                    >
                        <t>Save</t>
                    </Button>
                </div>
            </div>
        </div>

        <!-- STATE SEE IMAGES -->
        <ChatGroupManagerSeeImages
            v-if="show.images && channel && channel.id && channel.id !== 'new'"
            :channel-id="channel.id"
            @fileLoaded="(payload) => $emit('fileLoaded', payload)"
        />
        <!-- STATE SEE FILES -->
        <ChatGroupManagerSeeFiles
            v-if="show.files && channel && channel.id && channel.id !== 'new'"
            :channel-id="channel.id"
            @fileLoaded="(payload) => $emit('fileLoaded', payload)"
        />
    </div>
</template>

<script>
import RoundImagePicker from '@/components/globals/RoundImagePicker.vue';
import CompanyChatChannel from '@/models/CompanyChatChannel';
import HtInputText from '@/components/globals/HtInputText.vue';
import ChatGroupManagerActionList from '@/components/chat/chatGroupManager/ChatGroupManagerActionList.vue';
import ChatGroupManagerHUserList from '@/components/chat/chatGroupManager/ChatGroupManagerHUserList.vue';
import ChatGroupManagerVUserList from '@/components/chat/chatGroupManager/ChatGroupManagerVUserList.vue';
import ChatGroupManagerFilters from '@/components/chat/chatGroupManager/ChatGroupManagerFilters.vue';
import ChatSearchMixin from './mixins/ChatSearchMixin';
import ChatGroupManagerSeeFiles from './chatGroupManager/ChatGroupManagerSeeFiles.vue';
import ChatGroupManagerSeeImages from './chatGroupManager/ChatGroupManagerSeeImages.vue';
import NewChannelMixin from './mixins/NewChannelMixin';

export default {
    name: 'ChatGroupManager',
    components: {
        RoundImagePicker,
        HtInputText,
        ChatGroupManagerActionList,
        ChatGroupManagerHUserList,
        ChatGroupManagerVUserList,
        ChatGroupManagerFilters,
        ChatGroupManagerSeeFiles,
        ChatGroupManagerSeeImages,
    },
    mixins: [ChatSearchMixin, NewChannelMixin],
    props: {
        channel: {
            type: CompanyChatChannel,
            default: null,
        },
    },
    data() {
        return {
            maxParticipants: 1000,
            virtualFilteredCount: 0,
            show: {
                main: true,
                byName: false,
                byFilter: false,
                infos: false,
                details_byFilter: false,
                details_main: false,
                images: false,
                files: false,
            },
            imageSrc: this.channel?.avatar_image || {
                link_preview: '',
            },
            newImage: this.channel?.avatar_image || {
                link_preview: '',
            },
            name: this.channel?.name || '',
            selectedUsers: {
                byName: this.channel?.company_chat_channel_user.models
                    .filter((x) => x.company_user_id !== this.shared.session.companyUser.id)
                    .map((x) => x.company_user) || [],
                byFilter: [],
            },
            tmpSelectedUsers: {
                byName: [],
                byFilter: [],
                participants: [],
            },
            participants: this.channel?.company_chat_channel_user.models
                .filter((x) => x.company_user_id !== this.shared.session.companyUser.id)
                .map((x) => x.company_user) || [],
            formErrors: [],
        };
    },
    computed: {
        createActions() {
            return [
                {
                    text: this.translate('Add participants by name'),
                    subtext: `${this.selectedUsers.byName.length} ${this.translate('participants added')}`,
                    icon: ['far', 'search'],
                    event: 'byName',
                },
                {
                    text: this.translate('Add participants by filter'),
                    subtext: `${this.selectedUsers.byFilter.length} ${this.translate('participants added')}`,
                    icon: ['far', 'filter'],
                    event: 'byFilter',
                },
            ];
        },
        editActions() {
            const actions = this.channel.id !== 'new' ? [
                {
                    text: this.translate('Shared Images'),
                    subtext: '',
                    icon: ['fal', 'image'],
                    event: 'images',
                },
                {
                    text: this.translate('Shared Files'),
                    subtext: '',
                    icon: ['fal', 'file'],
                    event: 'files',
                },
            ] : [];
            actions.push({
                text: this.translate('Members'),
                subtext: '',
                icon: ['fal', 'user-friends'],
                event: 'byName',
            });
            return actions;
        },
        nameError() {
            return this.formErrors.some((e) => e.field === 'name');
        },
        mode() {
            return this.channel ? 'edit' : 'create';
        },
        currentView() {
            let current = 'main';
            for (const key in this.show) {
                if (this.show[key]) {
                    current = key;
                }
            }

            return current;
        },
    },
    watch: {
        channel(channel) {
            this.participants = channel.company_chat_channel_user.models
                .filter((x) => x.company_user_id !== this.shared.session.companyUser.id)
                .map((x) => x.company_user);
            this.selectedUsers.byName = this.participants.map((p) => p);
            this.name = channel.name;
            if (channel.avatar_image) {
                this.imageSrc = channel.avatar_image;
                this.newImage = channel.avatar_image;
            }
        },
    },
    methods: {
        addUser(method, user) {
            if (
                !this.tmpSelectedUsers[method].map((p) => p.id).includes(user.id)
                && !this.shared.session.companyUser.id !== user.id
            ) {
                this.tmpSelectedUsers[method].push(user);
            }
        },
        removeUser(method, user) {
            this.tmpSelectedUsers[method].splice(this.tmpSelectedUsers[method].indexOf(user), 1);
        },
        onActionList(event) {
            if (event === 'byName') {
                this.tmpSelectedUsers.byName = this.selectedUsers.byName.map((u) => u);
            }
            this.toggleView(event);
        },
        updateImage(image) {
            this.newImage = image;
        },
        toggleView(view) {
            for (const key in this.show) {
                if (Object.prototype.hasOwnProperty.call(this.show, key)) {
                    this.show[key] = key === view;
                }
            }
            this.users = [];
            this.search = '';
            this.$refs.byNameVlist?.resetSearch();
            this.$emit('change-view');
        },
        previous() {
            if (this.currentView.includes('details_')) {
                this.toggleView(this.currentView.substr(8));
            } else {
                this.toggleView('main');
            }
        },
        addParticipants(method) {
            this.selectedUsers[method] = this.tmpSelectedUsers[method].map((u) => u);
            this.participants = this.selectedUsers.byName.map((u) => u);
            this.selectedUsers.byFilter.forEach((u) => {
                if (!this.participants.map((p) => p.id).includes(u.id)) {
                    this.participants.push(u);
                }
            });
            this.tmpSelectedUsers.participants = this.participants.map((p) => p);
            this.toggleView('main');
        },
        setParticipants() {
            this.tmpSelectedUsers.byName = this.tmpSelectedUsers.participants.map((p) => p);
            this.selectedUsers.byName = this.tmpSelectedUsers.byName.map((u) => u);
            this.tmpSelectedUsers.byFilter = [];
            this.selectedUsers.byFilter = [];
            this.participants = this.tmpSelectedUsers.participants.map((p) => p);
            this.previous();
        },
        reset() {
            this.selectedUsers.byName = [];
            this.selectedUsers.byFilter = [];
            this.participants = [];
            this.name = this.channel ? this.channel.name : '';
            this.newImage = '';
            this.$refs.imagePicker.clear();
            this.toggleView('main');
            this.clearErrors();
        },
        saveGroup() {
            if (this.checkForm()) {
                this.saveChannel();
            }
        },
        checkForm() {
            this.clearErrors();
            if (this.name === '') {
                this.formErrors.push({ field: 'name', message: this.translate('You must choose a name.') });
            }
            if (this.participants.length <= 0) {
                this.formErrors.push({ field: 'participants', message: this.translate('You must add at least 1 participant.') });
            }
            if (this.participants.length > this.maxParticipants) {
                this.formErrors.push({ field: 'participants', message: this.translate(`A group can't include more than ${this.maxParticipants} users`) });
            }

            if (this.formErrors.length > 0) {
                this.$Notifier('App').showError(this.formErrors[0].message);
                return false;
            }

            return true;
        },
        clearErrors() {
            this.formErrors = [];
        },
        async saveChannel() {
            let companyChatChannel = this.channel;

            if (!companyChatChannel || companyChatChannel.id === 'new') {
                companyChatChannel = await this.createNewChannel(
                    this.participants.map((participant) => participant.id),
                    'group',
                    this.name.trim(),
                    this.newImage,
                );
            } else {
                companyChatChannel.company_chat_channel_user.clear();
                this.participants.forEach((selected) => {
                    this.addChannelUser(selected.id, companyChatChannel);
                });
                this.addChannelUser(this.shared.session.companyUser.id, companyChatChannel);
                companyChatChannel.name = this.name.trim();
                companyChatChannel.avatar_image_id = this.newImage.id;

                await companyChatChannel.save();
                await this.shared.socket.channelCollection.get();
            }
            this.shared.socket.channelCollection.setActive(companyChatChannel.id);
            this.toggleView('main');
            if (this.mode === 'create') {
                this.reset();
                this.$refs.groupFilter.deleteFilters();
                this.$emit('close');
            }
        },
        addChannelUser(userId, channel) {
            const companyChatChannelUser = channel.company_chat_channel_user.new();
            companyChatChannelUser.company_user_id = userId;
            channel.company_chat_channel_user.models.push(companyChatChannelUser);
        },
        clickModifyButton() {
            if (!this.channel) {
                this.$refs.imagePicker.openFileSelector();
            } else {
                this.toggleView('infos');
            }
        },
        onFiltered(data) {
            this.tmpSelectedUsers.byFilter = data.data;
            this.virtualFilteredCount = data.virtualCount;
        },

    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.chat-group-manager {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: hidden;

    ::v-deep .ht-checkbox {
        border-color: #BFC1C2;
    }

    &-idle {
        padding-top: 10px;
        min-height: calc(100vh - 185px);
    }

    &-infos {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        margin-top: 10px;

        &-text {
            &-name {
                font-size: 1.3em;
                font-weight: bold;
            }

            &-count {
                font-size: 1.2rem;
                color: lightgray;
            }
        }

        &-modify {
            color: var(--branding-color);
            font-weight: bold;
            margin-top: 10px;
            cursor: pointer;
        }
    }

    &-groupname {
        font-weight: bold;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-top: 2rem;

        ::v-deep .ht-input {
            margin-top: 10px;
            margin-bottom: 5px;

            &.errors {
                border-color: $error;
            }

            input {
                border-radius: 5px;
            }
        }
    }

    ::v-deep .row .col-md-3 {
        flex: unset;
        max-width: 100%;
    }

    .huser-list {
        position: sticky;
        bottom: 80px;
    }
}

.chat-search {
    &-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 5%;
        position: sticky;
        bottom: 10px;
        width: 100%;
        margin: 0;
        border-top: solid $grey-ultra-light .5px;
        height: 80px;
        z-index: 10;

        &-buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            .button.full-width {
                width: 100%;
            }
        }

        &-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: .7em;
            margin-bottom: 20px;

            &-count {
                color: $grey-ultra-light;
            }
            &-details {
                cursor: pointer;
                font-weight: bold;
            }
        }
    }
}

.sub-text {
    font-size: 1.2rem;
    font-weight: normal;
}

.error-text {
    color: $error;
}

.main-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;

    &-top {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-height: calc(100% - 150px);
        overflow: auto;
    }
}

@media (max-width: $phone) {
    .chat-search {
        &-actions {
            .button {
                min-width: 160px;
            }
        }
    }
    .selected-user-list {
        margin-right: 0;
        margin-left: 0;
        padding-left:.5rem;
        padding-right: .5rem;
        width: 100vw;
    }
}
</style>
