import { fetchData, storeData } from '@/store/modules/dashboard.store';
import Vue from 'vue';

const SET_MY_TEAM = 'set_my_team';
const SET_PROGRAM_SUMMARIES = 'set_program_summary';
const SET_REQUIREMENT_CATEGORIES = 'set_requirement_categories';
const SET_REQUIREMENT_CATEGORIES_PROGRESS = 'set_requirement_categories_progress';
const RESET_REQUIREMENT_CATEGORIES_LOADING_STATUS = 'reset_requirement_categories_loading_status';

export default {

    namespaced: true,

    state() {
        return {
            programSummaries: [],
            programSummariesLoaded: null,

            myTeam: [],
            myTeamLoaded: null,

            requirementCategories: [],
            requirementCategoriesLoaded: null,
            countDoneRequirementCategories: 0,
            countTotalRequirementCategories: 0,
            canValidateAdministrativeFile: false,
            requirementCategoriesProgressLoaded: null,

        };
    },

    getters: {
        filteredProgramSummaries(state) {
            return state.programSummaries.filter((summary) => (summary.program_type_slug !== 'default'));
        },
        activeProgramSummary(state, getters) {
            return getters.filteredProgramSummaries.find((summary) => (summary.status === 'active'));
        },
        activeProgramsSummary(state, getters) {
            return getters.filteredProgramSummaries.filter((summary) => (summary.status === 'active'));
        },
        canViewProgramSummaries(state, getters) {
            return getters.filteredProgramSummaries.length > 0;
        },
        canViewMyTeam(state) {
            return state.myTeam.length > 0;
        },
        getMyTeam({ myTeam }) {
            return myTeam;
        },
        percentRequirementCategoriesDone({
            countDoneRequirementCategories,
            countTotalRequirementCategories,
        }) {
            return Vue.prototype.$Utils
                .getPercentage(countDoneRequirementCategories, countTotalRequirementCategories);
        },
    },

    actions: {
        fetchProgramSummaries(context) {
            fetchData(context, {
                loadedKey: 'programSummariesLoaded',
                apiMethod: 'getProgramSummaries',
                responseKey: 'data.data',
                mutation: SET_PROGRAM_SUMMARIES,
            });
        },
        fetchMyTeam(context) {
            fetchData(context, {
                loadedKey: 'myTeamLoaded',
                apiMethod: 'getMyTeam',
                responseKey: 'data.data',
                mutation: SET_MY_TEAM,
            });
        },
        fetchRequirementCategoriesProgress(context) {
            return fetchData(context, {
                loadedKey: 'requirementCategoriesProgressLoaded',
                apiMethod: 'getRequirementCategoriesProgress',
                responseKey: 'data.data',
                mutation: SET_REQUIREMENT_CATEGORIES_PROGRESS,
            });
        },
        fetchRequirementCategories(context) {
            return fetchData(context, {
                loadedKey: 'requirementCategoriesLoaded',
                apiMethod: 'getRequirementCategories',
                responseKey: 'data.data',
                mutation: SET_REQUIREMENT_CATEGORIES,
            });
        },
        forceFetchRequirementCategories(context) {
            context.commit(RESET_REQUIREMENT_CATEGORIES_LOADING_STATUS);
            return Promise.all([
                context.dispatch('fetchRequirementCategoriesProgress'),
                context.dispatch('fetchRequirementCategories'),
            ]);
        },
    },

    mutations: {
        [SET_PROGRAM_SUMMARIES](state, summaries) {
            storeData(state, 'programSummariesLoaded', 'programSummaries', summaries);
        },
        [SET_MY_TEAM](state, myTeam) {
            storeData(state, 'myTeamLoaded', 'myTeam', myTeam);
        },
        [SET_REQUIREMENT_CATEGORIES_PROGRESS](state, requirementCategoriesProgress) {
            storeData(state, 'requirementCategoriesProgressLoaded', 'countDoneRequirementCategories', requirementCategoriesProgress.countDone);
            storeData(state, 'requirementCategoriesProgressLoaded', 'countTotalRequirementCategories', requirementCategoriesProgress.total);
            storeData(state, 'requirementCategoriesProgressLoaded', 'canValidateAdministrativeFile', requirementCategoriesProgress.canValidateAdministrativeFile);
        },
        [SET_REQUIREMENT_CATEGORIES](state, requirementCategories) {
            storeData(state, 'requirementCategoriesLoaded', 'requirementCategories', requirementCategories);
        },
        [RESET_REQUIREMENT_CATEGORIES_LOADING_STATUS](state) {
            state.requirementCategoriesLoaded = null;
            state.requirementCategoriesProgressLoaded = null;
        },
    },
};
