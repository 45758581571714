<template>
    <div v-if="companyUserProbation.isLoaded()">
        <ProfileBloc
            :is-edit.sync="isEdit"
            :can-edit="canEdit"
            :button-state="buttonState"
            @on-save="onUpdate"
        >
            <div class="profile-section-title">
                <t>Contract</t>
            </div>
            <div class="profile-row form-2">
                <div class="profile-column-half">
                    <div class="profile-item">
                        <div class="profile-item-title">
                            <t>Contract Type</t>
                        </div>

                        <div class="profile-item-content">
                            <span v-if="!isEdit">
                                <template v-if="companyUserProbation.$.company_contract">{{ companyUserProbation.$.company_contract.name_translated | empty }}</template>
                                <template v-else>-</template>
                            </span>

                            <div v-else>
                                <div v-if="companyContractCollection.isLoaded()">
                                    <HtFormMultiSelect
                                        :id="'company_contract'"
                                        v-model="contractUser"
                                        :options="companyContractCollection.$.models"
                                        :name="'company_contract'"
                                        :label-options="'name_translated'"
                                        :is-single="true"
                                        :specific-key="'id'"
                                        :disabled="canNotUpdateContractInformations"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-show="companyUserProbation.arrival_date && !companyUserProbation.end_date && !isEdit"
                    class="profile-column-half"
                >
                    <div class="profile-item">
                        <div class="profile-item-title">
                            <t>Start Date</t>
                        </div>

                        <div class="profile-item-content">
                            <span>{{ $Utils.moment(companyUserProbation.arrival_date).format(currentDateFormat) | empty }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-show="companyUserProbation.end_date || isEdit"
                class="profile-row form-2"
            >
                <div class="profile-column-half">
                    <div class="profile-item">
                        <div class="profile-item-title">
                            <t>Start Date</t>
                        </div>

                        <div class="profile-item-content">
                            <span v-if="!isEdit">{{ companyUserProbation.arrival_date ? $Utils.moment(companyUserProbation.arrival_date).format(currentDateFormat) : '' | empty }}</span>

                            <InputField
                                v-else
                                :value="$Utils.moment(companyUserProbation.arrival_date).format(currentDateFormat)"
                                :type="'text'"
                                :name="'arrival_date'"
                                :disabled="true"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="companyUserProbation.company_contract && companyUserProbation.company_contract.has_end_date"
                    class="profile-column-half"
                >
                    <div class="profile-item">
                        <div class="profile-item-title">
                            <t>End Date</t>
                        </div>

                        <div class="profile-item-content">
                            <span v-if="!isEdit">{{ $Utils.moment(companyUserProbation.end_date).format(currentDateFormat) | empty }}</span>

                            <HtFormFlatPickr
                                v-else
                                :id="'end_date'"
                                v-model="companyUserProbation.end_date"
                                v-validate.disable="'required'"
                                :placeholder="translate(currentDateFormat)"
                                :name="'end_date'"
                                :min-date="minEndDate"
                                :is-working-day="false"
                                :data-vv-as="translate('contract end date')"
                                :disabled="canNotUpdateContractInformations"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!-- INITIAL PROBATION -->
            <div class="profile-row form-2">
                <div class="profile-column-half">
                    <div
                        v-if="companyUserProbation.company_contract"
                        class="profile-item"
                    >
                        <div class="profile-item-title">
                            <t>Initial probationary period</t>
                        </div>

                        <div class="profile-item-content">
                            <ProbationPeriod
                                :edit_mode="isEdit"
                                :probation.sync="companyUserProbation.first_probation"
                                :probation_end.sync="companyUserProbation.first_probation_end_date"
                                :probation_unit.sync="companyUserProbation.first_probation_unit"
                                :show_end="false"
                                :disabled="canNotUpdateProbationInformations"
                            />
                        </div>
                    </div>
                </div>
                <div class="profile-column-half">
                    <div
                        v-if="companyUserProbation.company_contract"
                        class="profile-item"
                    >
                        <div class="profile-item-title">
                            <t>End of initial probationary period</t>
                        </div>

                        <div class="profile-item-content">
                            <span v-if="companyUserProbation.first_probation_end_date">
                                {{ $Utils.moment(companyUserProbation.first_probation_end_date).format('L') }}
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- RENEWED PROBATION -->
            <div class="profile-row form-2">
                <div class="profile-column-half">
                    <div
                        v-if="companyUserProbation.company_contract"
                        class="profile-item"
                    >
                        <div class="profile-item-title">
                            <t>Renewed probationary period</t>
                        </div>

                        <div class="profile-item-content">
                            <ProbationPeriod
                                v-if="companyUserProbation.company_contract"
                                :edit_mode="isEdit"
                                :probation.sync="companyUserProbation.second_probation"
                                :probation_end.sync="companyUserProbation.second_probation_end_date"
                                :probation_unit.sync="companyUserProbation.second_probation_unit"
                                :show_end="false"
                                :disabled="canNotUpdateProbationInformations"
                            />
                        </div>
                    </div>
                </div>
                <div class="profile-column-half">
                    <div
                        v-if="companyUserProbation.company_contract"
                        class="profile-item"
                    >
                        <div class="profile-item-title">
                            <t>End of renewed probationary period</t>
                        </div>

                        <div class="profile-item-content">
                            <span v-if="companyUserProbation.second_probation_end_date">
                                {{ $Utils.moment(companyUserProbation.second_probation_end_date).format('L') }}
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                </div>
            </div>
        </ProfileBloc>
    </div>
</template>

<script>
import ProfileBlocMixin from '@/components/mixins/ProfileBlocMixin';
import ProbationPeriod from '@/components/ProbationPeriod.vue';
import CompanyContractCollection from '@/models/CompanyContractCollection';
import CompanyUser from '@/models/CompanyUser';
import ProfileBloc from '../ProfileBloc.vue';

export default {
    name: 'ProfileAdministrativeContract',
    components: {
        ProfileBloc,
        ProbationPeriod,
    },
    mixins: [
        ProfileBlocMixin,
    ],

    data() {
        return {
            company_contract: [],
            companyUserProbation: new CompanyUser([
                'id',
                'arrival_date',
                'company_contract_id',
                'end_date',
                'first_probation_end_date',
                'first_probation_status',
                'first_probation_unit',
                'first_probation',
                'second_probation_end_date',
                'second_probation_status',
                'second_probation_unit',
                'second_probation',
            ]).with({
                companyContract: (query) => {
                    query.select([
                        'id',
                        'name',
                        'has_probation',
                        'has_end_date',
                        'is_heyteam',
                    ]);
                },
            }).where([
                ['id', '=', this.companyUser.id],
            ]),
            companyContractCollection: new CompanyContractCollection([
                'id', 'name', 'is_heyteam', 'has_end_date', 'has_probation',
            ]),
        };
    },

    computed: {
        canNotUpdateContractInformations() {
            return !this.$canRead('AbstractProfileContract', { company_user_id: this.companyUser.id });
        },

        canNotUpdateProbationInformations() {
            return !this.$canRead('AbstractProfileProbation', { company_user_id: this.companyUser.id });
        },

        canEdit() {
            return this.$can('ModelCompanyUser', { id: this.companyUser.id }, 'lookupProbationPeriod');
        },

        currentDateFormat() {
            return (this.currentUserLang === 'fr') ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
        },

        currentUserLang() {
            return this.shared.session.companyUser.isLoaded()
                ? this.shared.session.companyUser.company_language.key
                : 'en';
        },

        getContractOptions() {
            return this.companyContractCollection.models.map((contract) => ({
                text: contract.name_translated,
                value: contract.id,
            }));
        },

        contractUser: {
            get() {
                return this.companyUserProbation.company_contract_id;
            },
            set(companyContractId) {
                this.companyUserProbation.company_contract = this.companyContractCollection.models.find((contract) => contract.id === companyContractId);
                this.companyUserProbation.company_contract_id = companyContractId;
            },
        },

        minEndDate() {
            return this.$Utils.moment(this.companyUserProbation.arrival_date).add(1, 'days').format('YYYY-MM-DD');
        },
    },

    async created() {
        this.companyUserProbation.setLookupMethod('lookupProbationPeriod');
        this.companyContractCollection.get();
        this.companyUserProbation.get();
    },

    methods: {
        onUpdate() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.buttonState = 'loading';

                    if (this.companyUserProbation.first_probation_unit !== null && this.companyUserProbation.first_probation == null) this.companyUserProbation.first_probation_unit = null;

                    if (this.companyUserProbation.second_probation_unit !== null && this.companyUserProbation.second_probation == null) this.companyUserProbation.second_probation_unit = null;

                    if (this.companyUserProbation.company_contract.has_probation == 0) {
                        this.companyUserProbation.first_probation = null;
                        this.companyUserProbation.first_probation_unit = null;
                        this.companyUserProbation.first_probation_end_date = null;
                        this.companyUserProbation.first_probation_status = null;
                        this.companyUserProbation.second_probation = null;
                        this.companyUserProbation.second_probation_unit = null;
                        this.companyUserProbation.second_probation_end_date = null;
                        this.companyUserProbation.second_probation_status = null;
                    }

                    if (!this.companyUserProbation.company_contract.has_end_date) {
                        this.companyUserProbation.end_date = null;
                    }

                    this.companyUserProbation.save().then(() => {
                        this.buttonState = 'idle';
                        this.isEdit = false;
                    }, () => {
                        this.companyUserProbation.get();

                        this.buttonState = 'idle';
                        this.$Notifier('App').showError(this.translate('Unable to complete this operation!'));
                    });
                }
            });
        },
    },
};
</script>
