<template>
    <InputWrapper
        :editing="field.editing"
        :can-delete="canDelete"
        :can-edit="canEdit"
        :custom-delete="true"
        :title="field.title"
        :role-id="field.completer_id"
        placeholder-item="checkmark"
        :style="cssVars"
        @delete="remove"
        @setTitle="setTitle"
        @setRole="setRole"
    >
        <label
            class="checkbox-wrapper"
            :class="{
                checked: field.value,
                edit: canEdit
            }"
            :style="cssVars"
            @touchstart.stop
        >
            <input
                :value="field.value"
                type="checkbox"
                name="checkbox"
                :disabled="disabled"
                @click="toggle"
                @touchstart.stop
            >
            <span class="checkmark" />
        </label>
    </InputWrapper>
</template>

<script>
import InputWrapper from '@/components/DocumentEditor/Inputs/InputWrapper.vue';

export default {
    name: 'InputCheckboxField',
    components: {
        InputWrapper,
    },
    props: {
        field: {
            type: Object,
            require: true,
            default() {
                return {};
            },
        },
        canDelete: {
            type: Boolean,
            default() {
                return true;
            },
        },
        canEdit: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    data() {
        return {
            value: null,
            hover: false,
        };
    },
    computed: {
        cssVars() {
            return {
                '--checkmark-height': `${(8 * this.field.font_ratio)}px`,
                '--checkmark-width': `${(4 * this.field.font_ratio)}px`,
                '--checkbox-height': `${(10 * this.field.font_ratio)}px`,
            };
        },
        ratio() {
            return 0.7 * this.field.font_ratio;
        },
        disabled() {
            if ('disabled' in this.field) {
                return this.field.disabled;
            }
            if (!('editable' in this.field)) {
                return false;
            }
            return !this.field.editable;
        },
    },
    methods: {
        remove() {
            this.$emit('delete');
        },
        toggle(event) {
            if (!this.canEdit) {
                event.preventDefault();
                event.stopImmediatePropagation();
                return;
            }
            this.$set(this.field, 'value', !this.field.value);
        },
        setTitle(v) {
            this.$emit('setTitle', v);
        },
        setRole(roleId) {
            this.$emit('setRole', roleId);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/styles/var';
.input-wrapper {
    border: 1px solid transparent;
    height: var(--checkbox-height);
    width: var(--checkbox-height);
    .checkbox-wrapper {
        display: block;
        position: relative;
        cursor: pointer;
        height: var(--checkbox-height);
        width: var(--checkbox-height);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        ::v-deep + .input-wrapper-config {
            top: 50%;
            right: -10px;
            transform: translateX(100%) translateY(-50%);
        }

        /* Hide the browser's default checkbox */
        input {
            opacity: 0;
            height: 0;
            width: 0;
        }
        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: var(--checkbox-height);
            width: var(--checkbox-height);
            background-color: $neutral-white;
        }

        /* On mouse-over, add a grey background color */
        &:hover input ~ .checkmark {
            background-color: $neutral-300;
        }

        /* When the checkbox is checked, add a blue background */
        &.checked input ~ .checkmark {
            background-color: $semantic-info;
        }

        /* Style the checkmark/indicator */
        .checkmark:after {
            content: " ";
            position: absolute;
            top: 0;
            left: -10%;
            width: var(--checkmark-width);
            height: var(--checkmark-height);
            border: solid $neutral-white;
            border-width: 0 3px 3px 0;
            -webkit-transform: translateX(100%) rotate(45deg);
            -ms-transform: translateX(100%) rotate(45deg);
            transform: translateX(100%) rotate(45deg);
            display: none;
        }

        /* Show the checkmark when checked */
        &.checked input ~ .checkmark:after {
            display: block;
        }
    }
}

</style>
