import Model from '@tony.caron/node-model-proxy/Model';

import Company from './Company';
import CompanyUser from './CompanyUser';
import CompanyRole from './CompanyRole';
import CompanySurveySupervisorCollection from './CompanySurveySupervisorCollection';

export default class CompanySurveySupervisor extends Model {
    modelConfig() {
        return {
            collection: CompanySurveySupervisorCollection,
        };
    }

    modelRelations() {
        return {
            company: () => this.belongsTo(Company, 'company_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyRole: () => this.belongsTo(CompanyRole, 'company_role_id', 'id'),
        };
    }
}
