import Vue from 'vue';

export default {
    fetchAll() {
        return Vue.prototype.$http.get('configuration/configurations');
    },

    update(configurationId, data) {
        return Vue.prototype.$http.put(`configuration/configurations/${configurationId}`, data);
    },
};
