<template>
    <div v-if="isLoaded">
        <Button
            v-if="trainings.length > 0 && isFromLibrary === false"
            class="mb-20"
            cypress="training-import-button"
            @click="isFromLibrary = true"
        >
            <t>Import from library</t>
        </Button>
        <form
            v-if="isFromLibrary"
            class="form-2 spaced"
            data-cy="training-add-form"
        >
            <fieldset class="modal-training-import-fieldset">
                <HtFormSelect
                    id="name"
                    class="mb-3"
                    name="name"
                    cypress="name"
                    :label="translate('Select training')"
                    :validate="'required'"
                    :validate-as="translate('name')"
                    :options="trainingOptions"
                    :show-optional="false"
                    @input="onSelectedTraining(...arguments)"
                />

                <HtFormSwitch
                    :id="'are-displayed-personal-library'"
                    v-model="areDisplayedPersonalLibrary"
                    :name="'are-displayed-personal-library'"
                    :show-optional="false"
                    :label="translate('Display only my personal library')"
                />
            </fieldset>

            <template v-if="selectedTraining !== null">
                <HtFormEditor
                    :id="'info'"
                    :value="selectedTraining.resource.description"
                    :label="translate('Description')"
                    type="textarea"
                    name="info"
                    cypress="info"
                    :disabled="true"
                />

                <InputField
                    v-model="companyUserTraining.specific_information"
                    :label="translate('Specific information for {firstname}', { firstname: companyUser.firstname })"
                    type="textarea"
                    name="info"
                    cypress="info"
                />

                <HtKeyDatesInputOrder
                    :user-program-id="companyUserProgramId"
                    :resource-id="companyUserTraining.id"
                    resource-type="company_document"
                    :is-business-day.sync="userTask.is_business_day"
                    :offset-key-date.sync="userTask.company_user_program_key_date_id"
                    :offset-availability-specific-date.sync="userTask.datetime_availability"
                    :offset-availability-number.sync="userTask.offset_availability_number"
                    :offset-availability-unit.sync="userTask.offset_availability_unit"
                    :offset-end-specific-date.sync="userTask.datetime_end"
                    :offset-end-number.sync="userTask.offset_end_number"
                    :offset-end-unit.sync="userTask.offset_end_unit"
                    can-select-specific-date
                    :dependents="userTask.dependents.models"
                />

                <div class="modal-actions right">
                    <Button
                        :state="buttonState"
                        cypress="training-add-button"
                        @click="onNew()"
                    >
                        <t>Add</t>
                    </Button>
                </div>
            </template>
        </form>
        <UserTrainingEdit
            v-if="isFromLibrary === false"
            :is-new="true"
            :company-user-id="companyUserId"
            :company-user-program-id="companyUserProgramId"
            @onUpdate="onNew"
        />
    </div>
</template>

<script>
import CompanyUserTraining from '@/models/CompanyUserTraining';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import CompanyUser from '@/models/CompanyUser';
import TaskDependencyMixin from '@/mixins/TaskDependencyMixin';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import api from '@/store/api';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import UserTrainingEdit from './UserTrainingEdit.vue';

export default {
    name: 'UserTrainingSelect',
    components: {
        HtFormSwitch,
        HtKeyDatesInputOrder,
        HtFormSelect,
        UserTrainingEdit,
    },

    mixins: [
        TaskDependencyMixin,
    ],
    inject: ['modal'],

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
    },

    data() {
        return {
            userTask: null,
            buttonState: 'idle',
            isFromLibrary: false,
            selectedTraining: null,
            areDisplayedPersonalLibrary: false,

            companyUser: new CompanyUser([
                'arrival_date', 'firstname', 'company_language_id',
            ]).with({
                companyLanguage: (query) => {
                    query.select(['id', 'key']);
                },
            }).where([
                ['id', '=', this.companyUserId],
            ]),

            companyUserProgram: new CompanyUserProgram([
                'id',
            ]).with({
                mainKeyDate: (query) => {
                    query.select(['id', 'starts_at']);
                },
                companyProgram: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            keyDate: (query) => {
                                query.with({
                                    locales: (query) => query
                                        .select(['id', 'name', 'language_key']),
                                });
                            },
                        });
                },
            }).where([
                ['id', '=', this.companyUserProgramId],
            ]),
            areTrainingsLoaded: false,
            trainings: [],

            companyUserTraining: new CompanyUserTraining([
                'id', 'company_user_id', 'company_user_program_id',
                'specific_information', 'company_document_id',
            ]).with({
                companyUserProgramTask: (query) => {
                    query
                        .select([
                            'id',
                            'resource',
                            'resource_id',
                            'company_user_program_key_date_id',
                            'offset_availability_number',
                            'offset_availability_unit',
                            'offset_end_number',
                            'offset_end_unit',
                            'is_business_day',
                            'datetime_availability',
                            'datetime_end',
                        ])
                        .with({
                            dependents: (query) => {
                                query.select(['id']);
                            },
                        });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'arrival_date']);
                },
                companyFile: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
                companyUserProgram: (query) => query.with({
                    companyProgram: (query) => query.with({
                        keyDate: (query) => query.with({
                            locales: (query) => query
                                .select(['id', 'name', 'language_key']),
                        }),
                    }),
                }),
            }),
        };
    },

    computed: {
        isLoaded() {
            return this.areTrainingsLoaded && this.companyUser.isLoaded();
        },
        allTrainingOptions() {
            return this.trainings.map((training) => ({
                id: training,
                name: training.resource.name,
            })).sort((t1, t2) => t1.name.localeCompare(t2.name));
        },
        trainingOptions() {
            return this.allTrainingOptions.filter(
                (trainingOption) => trainingOption.id.resource.is_private === this.areDisplayedPersonalLibrary,
            );
        },
    },

    watch: {
        areDisplayedPersonalLibrary() {
            this.selectedTraining = null;
        },
    },

    created() {
        this.companyUserProgram.get();

        this.modal.setTitle(this.translate('Add training'));

        this.companyUserTraining.company_user_id = this.companyUserId;
        this.createTaskWithDependents(this.companyUserTraining);
        this.userTask = this.companyUserTraining.company_user_program_task;

        this.companyUser.get();

        api.configuration.trainings
            .getAll()
            .then((res) => {
                this.trainings = res.data.data;
                this.areTrainingsLoaded = true;
            });
    },

    methods: {
        onSelectedTraining(selectedTraining) {
            this.selectedTraining = selectedTraining;

            const firstResourceProgram = selectedTraining.resource.programs[0] || {
                offset_availability_number: 0,
                offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                offset_end_number: 0,
                offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                is_business_day: true,
            };

            this.companyUserTraining.company_user_program_task.offset_availability_number = firstResourceProgram.offset_availability_number;
            this.companyUserTraining.company_user_program_task.offset_availability_unit = firstResourceProgram.offset_availability_unit;
            this.companyUserTraining.company_user_program_task.offset_end_number = firstResourceProgram.offset_end_number;
            this.companyUserTraining.company_user_program_task.offset_end_unit = firstResourceProgram.offset_end_unit;
            this.companyUserTraining.company_user_program_task.is_business_day = firstResourceProgram.is_business_day;
            this.companyUserTraining.company_document_id = selectedTraining.id;
            this.companyUserTraining.company_user_program_id = this.companyUserProgramId;
            this.companyUserTraining.description = this.selectedTraining.resource.description;
        },

        onNew() {
            if (this.isFromLibrary) {
                this.$validator.validateAll().then((result) => {
                    if (result === true) {
                        this.companyUserTraining.save().then(() => {
                            this.modal.close();
                            this.$emit('onNew');
                            this.$emit('onUpdate');
                        });
                    }
                });
            } else {
                this.modal.close();
                this.$emit('onNew');
                this.$emit('onUpdate');
            }
        },
    },
};
</script>
