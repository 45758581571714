<template>
    <HoldingResourceList
        :route-list="'HoldingResourcesEmailsList'"
        :collection="emails"
    >
        <template #table="holdingResourceList">
            <EmailsList
                :items="holdingResourceList.items"
                :external-search="holdingResourceList.search"
                @onCellClicked="holdingResourceList.onCellClicked"
            />
        </template>
    </HoldingResourceList>
</template>
<script>
import HoldingResourceList from '@/components/globals/HoldingResourceList.vue';
import CompanyEmailCustomTemplateCollection from '@/models/CompanyEmailCustomTemplateCollection';
import EmailsList, { emailConfig } from '@/resourcesList/EmailsList.vue';

export default {
    name: 'PageEmailsList',
    components: {
        HoldingResourceList,
        EmailsList,
    },

    data() {
        return {
            emails: new CompanyEmailCustomTemplateCollection(emailConfig.fields).with(emailConfig.relations),
        };
    },
};
</script>
