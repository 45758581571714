<template>
    <div>
        <ResultSatisfactionScaleSmileyItem
            v-for="(choice, index) in choices"
            :key="choice.id"
            class="item"
            v-bind="propsToPass(choice)"
            :icon-path="getIconPath(index)"
        />
    </div>
</template>

<script>
import choiceResultsProps from '../choiceResultsProps';
import ResultSatisfactionScaleSmileyItem from './ResultSatisfactionScaleSmileyItem.vue';

export default {
    name: 'ResultsSatisfactionScaleSmiley',
    components: { ResultSatisfactionScaleSmileyItem },

    mixins: [
        choiceResultsProps,
    ],

    methods: {
        /**
         * @returns {string}
         */
        getIconPath(index) {
            const path = '/static/icons/survey/smiley/';

            switch (index) {
            case 0:
                return `${path}SmileyVeryGood.svg`;
            case 1:
                return `${path}SmileyGood.svg`;
            case 2:
                return `${path}SmileyMedium.svg`;
            case 3:
                return `${path}SmileyNotGood.svg`;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.item:not(:last-child) {
    margin-bottom: 2%;
}
</style>
