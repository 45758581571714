<template>
    <div
        class="link"
        :class="classes"
        @click.stop="onClick"
    >
        {{ label }}
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },
    computed: {
        classes() {
            const classes = [];

            if (this.disabled) {
                classes.push('disabled');
            }

            return classes;
        },
    },
    methods: {
        onClick() {
            if (this.disabled === false) {
                this.$parent.hideMenu();
                this.$emit('click');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.link {
    white-space: nowrap;
    cursor: pointer;
    padding: 0.8rem;
    border-bottom: 1px solid #E0E2E2;

    &:last-child {
        border: 0;
    }

    &:hover {
        background-color: #F2F2F2;
    }

    &.disabled {
        cursor: not-allowed;
        background-color: #F2F2F2;
    }
}
</style>
