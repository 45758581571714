<template>
    <div class="widget">
        <div class="widget__header">
            <div class="widget__header-title">
                {{ title }}
            </div>
            <div
                v-if="viewAllLink"
                class="widget__header-link"
                @click="onLinkClick"
            >
                <t>View all</t>
            </div>
            <div
                v-if="help"
                v-tooltip="help"
                class="widget__header-tooltip"
            >
                <FontAwesomeIcon :icon="['far', 'info-circle']" />
            </div>
        </div>
        <div class="inner">
            <div
                v-if="showList"
                class="table"
            >
                <div
                    v-for="(line, index) in getItems"
                    :key="index"
                    class="list"
                >
                    <div
                        class="user"
                        @click="goToProfile(line.id)"
                    >
                        <Skeleton
                            circle
                            height="2.4rem"
                            width="2.4rem"
                            :loading="loading"
                        >
                            <div class="image">
                                <div :style="avatar(line.image)" />
                            </div>
                        </Skeleton>
                        <div class="username">
                            <Skeleton
                                :loading="loading"
                                :width="width(30, 80)"
                            >
                                {{ line.name }}
                            </Skeleton>
                        </div>
                    </div>
                    <div class="details">
                        <Skeleton
                            :loading="loading"
                            width="10rem"
                        >
                            {{ line.detail }}
                        </Skeleton>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="no-data"
            >
                <img :src="placeholderImage">
                <div class="placeholderMessage">
                    <div class="inner">
                        <div class="placeholderTitle">
                            {{ placeholderTitle }}
                        </div>
                        <div class="placeholderSubtitle">
                            {{ placeholderText }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Skeleton } from 'vue-loading-skeleton';

export default {
    name: 'TableTemplate',
    components: {
        Skeleton,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        placeholderImage: {
            type: String,
            required: true,
        },
        placeholderTitle: {
            type: String,
            required: true,
        },
        placeholderText: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            default: () => false,
        },
        help: {
            type: String,
            default: () => '',
        },
        viewAllLink: {
            type: Boolean,
            default: () => false,
        },
    },
    computed: {
        showList() {
            return this.items.length > 0 || this.loading;
        },
        getItems() {
            if (this.loading) return 3;
            return this.items;
        },
    },
    methods: {
        onLinkClick() {
            this.$emit('on-link-click');
        },
        avatar(url) {
            return {
                'background-image': `url(${this.$Utils.resolveS3PublicImage(url)})`,
            };
        },
        width(min, max) {
            return `${Math.floor(Math.random() * (max - min) + min)}%`;
        },
        goToProfile(company_user_id) {
            this.$router.push({
                name: 'Profile',
                params: { company_user_id },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.widget {
    background-color: #ffffff;
    border-radius: 8px;
    padding:1.6rem;
    height: 21.4rem;
    & .widget__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Lato;
        font-style: normal;
        line-height: 140%;
        color: #1B1B1C;
        margin-bottom: 1.6rem;

        & .widget__header-title {
            font-weight: bold;
            font-size: 1.6rem;
        }

        & .widget__header-link {
            font-size: 1.4rem;
            font-weight: 700;
            color: var(--branding-color);

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
    & .inner {
        height: 14.4rem;
        overflow: hidden auto;

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        & {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }

        & .table {
            display: flex;
            flex-direction: column;
            & .list {
                &:nth-child(odd) {
                    background-color: #FAFAFA;
                }
                &:hover {
                    background-color: #FFF0EF;
                    cursor: pointer;
                }
                height: 4.0rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0.8rem;
                & .image {
                    &, & > div {
                        height: 2.4rem;
                        min-height: 2.4rem;
                        width: 2.4rem;
                        min-width: 2.4rem;
                        background-size:24px 24px;
                    }
                    & > div {
                        border-radius: 1000px;
                    }
                }
                & .user {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 140%;
                    display: flex;
                    flex:1;
                    align-items: center;
                    min-width: 0;
                    gap: 10px;
                    & .username {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width:100%;
                    }
                }
                & .details {
                    font-weight: bold;
                    font-size: 10px;
                    line-height: 140%;
                    text-transform: uppercase;
                    white-space: nowrap;
                }
            }
        }

        & .no-data {
            display: flex;
            gap:10px;
            & .placeholderMessage {
                width: 100%;
                height: 100%;
                & .inner {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    color: #ABADAE;
                    font-weight: bold;
                    font-size: 1.4rem;
                    line-height: 140%;
                    & .placeholderSubtitle {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
}
</style>
