import Vue from 'vue';
import api from '@/store/api';

/**
 * @typedef {Object} Custom
 * @property {string} name - Le nom de l'objet
 * @property {string} description - La description de l'objet
 */

/**
 * @typedef {{data: Custom}} CustomResourceResponse
 * @typedef {{data: Custom[]}} CustomCollectionResponse
 */
export default {
    /**
     * @param {any} data
     * @returns {Promise<CustomCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.get(api.endpoints.CONFIGURATION_CUSTOMS, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<CustomResourceResponse>}
     */
    get(id, config = {}) {
        return Vue.prototype.$http.get(`${api.endpoints.CONFIGURATION_CUSTOMS}/${id}`, config);
    },

    /**
     * @param {any} data
     * @returns {Promise<CustomResourceResponse>}
     */
    create(data = {}) {
        return Vue.prototype.$http.post(api.endpoints.CONFIGURATION_CUSTOMS, data);
    },

    /**
     * @param {number|string} id
     * @param {any} data
     * @returns {Promise<CustomResourceResponse>}
     */
    update(id, data = {}) {
        return Vue.prototype.$http.put(`${api.endpoints.CONFIGURATION_CUSTOMS}/${id}`, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<{}>}
     */
    delete(id, config = {}) {
        return Vue.prototype.$http.delete(`${api.endpoints.CONFIGURATION_CUSTOMS}/${id}`, config);
    },
};
