<template>
    <div
        v-if="item.isLoaded()"
        class="print"
    >
        <div class="head-container">
            <div class="logo-corner">
                <img
                    width="25"
                    src="/static/images/logo.png"
                >
            </div>
        </div>
        <h3 class="align-center">
            <div class="title-big padding-b-big">
                {{ translate('Survey') }} :
            </div>
            {{ item.company_survey.resource.name }}
        </h3>
        <div
            v-if="hasAnswer"
            class="bloc-detail"
        >
            <div class="group-detail">
                <div v-if="hasRelatedUser">
                    <div class="title-medium padding-b-big">
                        {{ translate('Program') }} :
                    </div>
                    <div>
                        <span class="padding-r-small">- {{ item.company_user.firstname }}</span>
                        <span>{{ item.company_user.lastname }}</span>
                    </div>
                </div>
                <div v-if="hasParticipant">
                    <div class="title-medium padding-b-big">
                        {{ translate('Assigned to') }} :
                    </div>
                    <div>
                        <span class="padding-r-small">- {{ item.company_user_participant.firstname }}</span>
                        <span>{{ item.company_user_participant.lastname }}</span>
                    </div>
                </div>
                <div v-if="hasWatchers">
                    <div class="title-medium padding-b-big">
                        {{ translate('Results visible by') }} :
                    </div>
                    <div
                        v-for="(watcher, index) in filterWatchers"
                        :key="index"
                        class="padding-b-big"
                    >
                        <span class="padding-r-small">- {{ watcher.company_user.firstname }}</span>
                        <span>{{ watcher.company_user.lastname }}</span>
                    </div>
                </div>
            </div>
            <div class="title-big">
                {{ translate('Answers to question') }} :
            </div>
            <div
                v-for="(question, index) in item.company_user_survey_question.models"
                :key="index"
            >
                <div class="title-detail">
                    <div class="title-medium padding-r-medium">
                        Q#{{ index + 1 }} :
                    </div>
                    <div class="title-medium">
                        {{ question.company_survey_question.company_survey_question_locale.models[0].text }}
                    </div>
                </div>
                <div>
                    <template v-if="question.company_survey_question.type === 'text' && question.company_user_survey_question_answer.models.length > 0">
                        {{ question.company_user_survey_question_answer.models[0].text }}
                    </template>
                    <template
                        v-else-if="question.company_survey_question.type ==='yes_no' || question.company_survey_question.type === 'multiple_choice' || question.company_survey_question.type === 'simple_choice' || question.company_survey_question.type === 'satisfaction_scale'"
                    >
                        <div
                            v-for="(answer, iSurveyQuestionAnswerIndex) in question.company_survey_question.company_survey_question_answer.models"
                            :key="iSurveyQuestionAnswerIndex"
                            :class="['user-survey-result-item-content-right', {
                                'pb-3': (iSurveyQuestionAnswerIndex < question.company_survey_question.company_survey_question_answer.models.length - 1)
                            }]"
                        >
                            <div class="user-survey-result-item-content-right-text-right">
                                {{ getAnswerText(answer) }}
                            </div>
                            <div
                                v-if="question.company_survey_question.type !== 'text'"
                                :class="['icon-check', {'ml-20': question.company_survey_question.type === 'satisfaction_scale'}]"
                            >
                                <template v-if="hasAnsweredThisOne(answer.id, question.company_user_survey_question_answer.models)">
                                    <FontAwesomeIcon icon="check" />
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div
            v-else
            style="background: white; border: 1px solid lightgrey;padding: 20px"
        >
            <t>No result yet</t>
            ...
        </div>
    </div>
</template>

<script>
import CompanyUserSurvey from '@/models/CompanyUserSurvey';

import Printd from 'printd';

export default {
    name: 'UserSurveyResultPdf',
    props: {
        item: { type: CompanyUserSurvey, required: false },
    },

    computed: {
        hasAnswer() {
            return this.item.company_user_program_task.status === 'done';
        },

        hasRelatedUser() {
            return this.item.company_user;
        },

        hasParticipant() {
            return this.item.company_user_participant;
        },

        hasWatchers() {
            return this.item.company_user_survey_supervisor.models.length;
        },

        filterWatchers() {
            return this.item.company_user_survey_supervisor.models.filter((item) => item.company_user);
        },
    },

    mounted() {
        this.onPrint();
    },

    methods: {
        onPrint() {
            const cssText = `

                * {
                    font-family: 'latobold', 'lato', sans-serif;
                    font-weight: normal;
                    font-size: 16px;
                }
                .print {
                    display:block;
                }
                .no-print{
                    display:none;
                }
                .user-survey-result-item-content-right {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .icon-check {
                    width: 16px;
                    margin-left: 20px;
                }
                .title-big {
                    font-size: 22px;
                    font-weight: bold;
                }
                .title-medium {
                    font-size: 18px;
                    font-weight: bold;
                }
                .padding-r-small {
                    padding-right: 0.1em;
                }
                .padding-r-medium {
                    padding-right: 0.2em;
                }
                .padding-b-big {
                    padding-bottom: 1em;
                }
                .padding-t-big {
                    padding-top: 1em;
                }
                .group-detail {
                    padding: 2em 0;
                    wdith: 100%;
                    display:flex;
                    justify-content: space-between;
                    flex:direction: column;
                }
                .title-detail{
                    padding: 1em 0;
                    width:100%;
                    display:flex;
                    align-items: center;
                    flex-direction: row;
                }
                .head-container {
                    width: 100%;
                    padding: 10px;
                    background-color: #ff6058 !important;
                    display:flex;
                    align-items: center;
                    justify-content: center;
                    -webkit-print-color-adjust: exact;
                }
                .logo-corner {
                    background-color: #fff;
                    padding: 10px 10px 5px 10px;
                    border-radius: 10px;
                }
                `;

            const d = new Printd();
            d.print(this.$el, [cssText]);
        },

        hasAnsweredThisOne(iCompanySurveyQuestionAnswerId, aCompanyUserSurveyQuestionAnswer) {
            return aCompanyUserSurveyQuestionAnswer.some((oCompanyUserSurveyQuestionAnswer) => oCompanyUserSurveyQuestionAnswer.company_survey_question_answer_id === iCompanySurveyQuestionAnswerId);
        },

        getAnswerText(answer) {
            if (answer.company_entity_value_id) {
                return answer.value.custom.name;
            }

            return answer.company_survey_question_answer_locale.models[0].text;
        },
    },
};
</script>
<style lang="scss" scoped>
.print {
    display: none;
}
</style>
