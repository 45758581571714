<template>
    <div>
        <template v-if="isLoaded">
            <form class="form-2 spaced">
                <template v-if="filteredEmails.length">
                    <h4><t>Select an existing e-mail</t> :</h4>
                    <fieldset>
                        <HtFormSelect
                            id="email"
                            v-model="selectedOption"
                            v-validate.disable="'required'"
                            name="email"
                            cypress="email"
                            :data-vv-as="translate('e-mail')"
                            :options="emailOptions"
                        />
                    </fieldset>
                </template>

                <div
                    v-else
                    class="empty-component"
                >
                    <strong><t>All available e-mails already added !</t></strong>
                </div>

                <div
                    v-if="selectedOption"
                    class="modal-actions right"
                >
                    <Button
                        cypress="email-save-button"
                        @click="onValidate()"
                    >
                        <t>Save</t>
                    </Button>
                </div>
            </form>
        </template>

        <template v-else>
            <IconLoading />
        </template>
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyUserEmailCustomCollection from '@/models/CompanyUserEmailCustomCollection';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import api from '@/store/api';

export default {
    name: 'UserEmailCustomSelect',
    inject: ['modal'],
    props: {
        selectedIds: { type: Array, default: () => [] },
        companyUserId: { type: [Number, String], required: true },
        companyUserProgramId: { type: [Number, String], required: true },
    },

    data() {
        return {
            companyUserEmailCustom: new CompanyUserEmailCustomCollection([
                'id',
                'company_email_custom_template_id',
                'company_user_id',
                'company_user_program_id',
                'name',
            ]),
            companyUser: new CompanyUser([
                'arrival_date', 'firstname', 'company_language_id',
            ]).with({
                companyLanguage: (query) => {
                    query.select(['id', 'key']);
                },
            }).where([
                ['id', '=', this.companyUserId],
            ]),
            companyUserProgram: new CompanyUserProgram([
                'id',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'firstname']);
                },
                companyProgram: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            keyDate: (query) => {
                                query.with({
                                    locales: (query) => query
                                        .select(['id', 'name', 'language_key']),
                                });
                            },
                        });
                },
            }).where([
                ['id', '=', this.companyUserProgramId],
            ]),
            selectedOption: null,
            item: null,
            emails: [],
            isLoaded: false,
            buttonState: 'idle',
            fetchedSelectedIds: [],
        };
    },

    computed: {
        filteredEmails() {
            if (this.selectedIds.length) {
                return this.emails.filter((email) => !this.selectedIds.includes(email.id));
            }
            if (this.fetchedSelectedIds.length) {
                return this.emails.filter((email) => !this.fetchedSelectedIds.includes(email.id));
            }
            return this.emails;
        },

        emailOptions() {
            return this.filteredEmails.map((email) => ({
                id: email,
                name: email.resource.name,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
    },

    watch: {
        selectedOption() {
            this.item.company_email_custom_template_id = this.selectedOption.id;
        },
    },

    async created() {
        this.companyUserProgram.get();

        this.emails = (await api.configuration.emails.getAll({
            filters: this.$store.state.resources.filters,
        })).data.data;

        if (!this.selectedIds.length) {
            this.loadSelectedUserResourceIds();
        }

        this.modal.setTitle(this.translate('Add email'));
        this.companyUser.get().then(() => {
            this.item = this.companyUserEmailCustom.new();
            this.item.company_user_id = this.companyUserId;
            this.item.company_user_program_id = this.companyUserProgramId;
        });
        this.isLoaded = true;
    },

    methods: {
        // THIS IS NEEDED FOR ADD ELEMENT MODAL
        async loadSelectedUserResourceIds() {
            this.companyUserEmailCustom.company_user_id = this.companyUserId;
            await this.companyUserEmailCustom.get();

            this.fetchedSelectedIds = this.companyUserEmailCustom.$.models.map((item) => item.company_email_custom_template_id);
        },
        onValidate() {
            this.$validator.validateAll().then(() => {
                if (this.errors.any()) return;

                this.item.save().then(() => {
                    this.$emit('onUpdate');
                    this.modal.close();
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	h4 {
		margin-bottom: 0.5em;
	}
</style>
