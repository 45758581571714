export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        externalSearch: {
            type: String,
            required: false,
        },
        /**
         * Columns we should not display.
         */
        columnsToHide: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        statusColor(status) {
            switch (status) {
            case 'Active':
                return 'green';
            case 'Inactive':
                return 'grey';
            }
        },
    },

    created() {
        this.columns = this.columns.filter((column) => !this.columnsToHide.includes(column.key));
    },
};
