import Vue from 'vue';
import Model from '@tony.caron/node-model-proxy/Model';
import CompanyEmailCustomTemplateCollection from '@/models/CompanyEmailCustomTemplateCollection';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import CompanyResource from '@/models/CompanyResource';
import CompanyProgramResourceFilter from './CompanyProgramResourceFilter';
import CompanyEmailCustomTemplateLocale from './CompanyEmailCustomTemplateLocale';
import CompanyEmailCustomTemplateRecipient from './CompanyEmailCustomTemplateRecipient';
import CompanyFile from './CompanyFile';
import CompanyUser from './CompanyUser';
import CompanyRole from './CompanyRole';

export default class CompanyEmailCustomTemplate extends Model {
    static RESOURCEABLE_CLASS = 'App\\CompanyEmailCustomTemplate';

    static DEFAULT_SIGNATURE_FR = '<p>Bien cordialement,</p><p>{sender_firstname} {sender_lastname}</p>';

    static DEFAULT_SIGNATURE_EN = '<p>Best regards,</p><p>{sender_firstname} {sender_lastname}</p>';

    static DEFAULT_SIGNATURE_DE = '<p>Mit freundlichen Grüßen,</p><p>{sender_firstname} {sender_lastname}</p>';

    static DEFAULT_SIGNATURE_IT = '<p>Cordialmente,</p><p>{sender_firstname} {sender_lastname}</p>';

    static DEFAULT_SIGNATURE_ES = '<p>Cordialemente,</p><p>{sender_firstname} {sender_lastname}</p>';

    modelConfig() {
        return {
            collection: CompanyEmailCustomTemplateCollection,
        };
    }

    modelDefaultValues() {
        return {
            is_enabled: true,
            is_locked: false,
        };
    }

    modelCustomAttributes() {
        return {
            locales_by_key: {},
            name_translated: null,
            is_retroactive: false,
            send_retroactive_notif: false,
            file_translations: [],
            translations: [],
        };
    }

    localize(field, languageKey, defaultLanguageKey) {
        return Vue.prototype.$modelUtils.localize(this.locales_by_key, field, languageKey, defaultLanguageKey);
    }

    modelEvents() {
        return {
            loaded: () => Vue.prototype.$modelUtils.createLocales(this.locales),
        };
    }

    modelAccessors() {
        return {
            locales_by_key: () => Vue.prototype.$modelUtils.createLocalesByKey(this.locales),
            name_translated: () => this.localize('name'),
        };
    }

    modelRelations() {
        return {
            resource: () => this.hasOne(CompanyResource),
            dependencies: () => this.belongsToMany(CompanyResourceProgram, 'company_resource_program_dependencies', 'company_resource_program_id', 'dependency_id'),
            senderUser: () => this.belongsTo(CompanyUser, 'sender_user_id', 'id'),
            senderRole: () => this.belongsTo(CompanyRole, 'sender_role_id', 'id'),
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
            companyEmailCustomTemplateRecipient: () => this.hasMany(CompanyEmailCustomTemplateRecipient, 'company_email_custom_template_id', 'id'), // TODO: à supprimer ?
            companyProgramResourceFilter: () => this.hasMany(CompanyProgramResourceFilter, 'resource_id', 'id').where(['component_name', '=', 'company_email_custom_template']), // TODO: à supprimer ?
            locales: () => this.hasMany(CompanyEmailCustomTemplateLocale, 'id', 'company_email_custom_template_id'),
            resourceProgram: () => this.hasMany(CompanyResourceProgram),
        };
    }
}
