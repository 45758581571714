<template>
    <!-- Custom entities -->
    <div
        v-if="customEntities.isLoaded()"
        class="profile-row grid-2-col mt-0"
    >
        <div
            v-for="customEntity in orderedCustomEntities"
            :key="customEntity.id"
            class="profile-item"
            :class="!isEdit ? 'no-margin-compensation' : ''"
        >
            <div class="profile-item-title">
                {{ customEntity.name }}
            </div>

            <HtFormSelect
                v-if="isEdit"
                :id="customEntity.slug"
                :value="companyUserEntityValueId(customEntity)"
                :name="customEntity.slug"
                :options="entityOptions(customEntity)"
                @input="
                    value =>
                        selectedCompanyUserEntityValue(
                            customEntity,
                            value
                        )
                "
            />
            <div
                v-else
                class="profile-item-content"
            >
                {{
                    companyUserEntityValueName(customEntity) | empty
                }}
            </div>
        </div>
    </div>
</template>
<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyEntityCollection from '@/models/CompanyEntityCollection';
import get from 'lodash.get';

export default {
    name: 'ProfileCustomEntities',
    props: {
        companyUser: {
            type: CompanyUser,
            required: false,
        },
        isEdit: {
            type: Boolean,
            required: true,
        },
        entityIds: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            customEntities: new CompanyEntityCollection(['id', 'name', 'slug'])
                .with({
                    values: (query) => query.select([
                        'id',
                        'company_entity_id',
                        'resourceable_type',
                        'resourceable_id',
                    ]),
                    customData: (query) => query.select(['id', 'key', 'name']),
                })
                .whereHas({
                    values: (query) => query.where([['is_custom', '=', true]]),
                })
                .whereIn([
                    ['id', this.entityIds],
                ]),

        };
    },

    computed: {
        orderedCustomEntities() {
            return this.customEntities.models.sort((a, b) => a.name > b.name);
        },
    },

    created() {
        this.customEntities.get();
    },

    methods: {
        entityOptions(customEntity) {
            return customEntity.custom_data.models
                .map(({ id, name }) => ({
                    id,
                    name,
                }))
                .sort((a, b) => a.name > b.name);
        },

        companyUserEntity(customEntity) {
            return this.companyUser.default_entities.models.find(
                (entity) => entity.company_entity_id === customEntity.id,
            );
        },

        companyUserEntityValue(customEntity) {
            const entity = this.companyUserEntity(customEntity);

            return get(entity, 'value', null);
        },

        companyUserEntityValueId(customEntity) {
            const value = this.companyUserEntityValue(customEntity);

            return get(value, 'resourceable_id', null);
        },

        companyUserEntityValueName(customEntity) {
            const value = this.companyUserEntityValue(customEntity);
            const custom = customEntity.custom_data.models.find(
                (custom) => value && custom.id === value.resourceable_id,
            );

            return get(custom, 'name', null);
        },

        selectedCompanyUserEntityValue(customEntity, customValueId) {
            const userEntity = this.companyUserEntity(customEntity);

            userEntity.value = customEntity.values.models.find(
                (value) => value.resourceable_id === customValueId,
            );
            userEntity.company_entity_value_id = userEntity.value.id;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.profile-item:not(.no-margin-compensation) {
    // Compensating ht-form bottom margin
    margin-bottom: -2.5rem;
}
</style>
