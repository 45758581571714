import Model from '@tony.caron/node-model-proxy/Model';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import CompanyResource from '@/models/CompanyResource';
import CompanyFile from './CompanyFile';
import CompanyProgramResourceFilter from './CompanyProgramResourceFilter';
import CompanyProgram from './CompanyProgram';
import CompanySharedDocumentCollection from './CompanySharedDocumentCollection';

export default class CompanySharedDocument extends Model {
    static RESOURCEABLE_CLASS = 'App\\CompanySharedDocument';

    modelConfig() {
        return {
            collection: CompanySharedDocumentCollection,
        };
    }

    modelDefaultValues() {
        return {
            type: 'file',
            category: 'general',
            translations: {
                name: {},
                description: {},
            },
            file_translations: {},
        };
    }

    modelCustomAttributes() {
        return {
            is_retroactive: false,
            send_retroactive_notif: false,
        };
    }

    modelRelations() {
        return {
            resource: () => this.belongsTo(CompanyResource, 'resourceable_id', 'id'),
            dependencies: () => this.belongsToMany(CompanyResourceProgram, 'company_resource_program_dependencies', 'company_resource_program_id', 'dependency_id'),
            companyFiles: () => this.hasMany(CompanyFile),
            companyProgram: () => this.belongsTo(CompanyProgram, 'company_program_id', 'id'),
            companyProgramResourceFilter: () => this.hasMany(CompanyProgramResourceFilter, 'resource_id', 'id').where(['component_name', '=', 'company_shared_document']),
        };
    }
}
