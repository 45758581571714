<template>
    <div class="program-simulation-wrapper">
        <IconLoading v-if="!isLoaded" />
        <template v-else>
            <HtEntitiesFiltersWrapper
                v-if="showFilters"
                store="programs"
                is-single
                @on-update-filters="onSelectFilter"
                @on-clear-filters="onClearFilters"
            >
                <template #title>
                    <h1 class="title">
                        {{ title }}
                    </h1>
                </template>
                <template #afterFilters="layoutModal">
                    <HtFormSelector
                        id="selectedRoleSimulateur"
                        v-model="selectedCompanyRole"
                        name="selectedRoleSimulateur"
                        :label="layoutModal ? translate('Role') : undefined"
                        :placeholder="layoutModal ? undefined : translate('Role')"
                        :options="roles"
                        :show-optional="false"
                        open-direction="bottom"
                        is-single
                        @input="onSelectFilter"
                    />
                </template>
            </HtEntitiesFiltersWrapper>
            <div
                v-if="showFilters && useKeyDate && (hasTasks || isFiltering)"
                class="mt-4"
            >
                <fieldset>
                    <HtFormFlatPickr
                        :id="'key_date'"
                        v-model.trim="mainKeyDateValue"
                        :name="'key-date'"
                        :label="translate(
                            'Select the {keyDate}',
                            { keyDate: mainKeyDateModel.name.toLowerCase() }
                        )"
                        :show-optional="false"
                        :cypress="'key_date'"
                        :is-with-calendar-icon="true"
                        date-format="Y-m-d"
                    />

                    <div
                        v-for="(additionalKeyDateModel, index) in additionalKeyDateModels"
                        :key="index"
                    >
                        <HtFormFlatPickr
                            :id="'additional_key_date' + additionalKeyDateModel.id"
                            v-model.trim="additionalKeyDateValues[additionalKeyDateModel.id]"
                            :name="'additional_key_date' + additionalKeyDateModel.id"
                            :label="translate(
                                'Select the {keyDate}',
                                { keyDate: additionalKeyDateModel.name.toLowerCase() }
                            )"
                            :show-optional="false"
                            :cypress="'additional_key_date' + additionalKeyDateModel.id"
                            :is-with-calendar-icon="true"
                            date-format="Y-m-d"
                        />
                    </div>
                </fieldset>
            </div>

            <div
                v-if="canEdit && !hasTasks && !isFiltering"
                class="d-flex flex-column align-items-center justify-center py-5"
            >
                <img
                    src="/static/icons/survey/tabs/survey_no_result.svg"
                    class="mb-5 mx-auto d-block"
                >
                <t>Nothing to display right now...</t>
                <div>
                    <t>Add first element to create your program</t>
                    😀
                </div>
                <Button
                    v-if="availableResourceList.length > 0"
                    class="mt-5 negative"
                    @click="openAddRessource(0, 0)"
                >
                    <t>Add a resource</t>
                </Button>
            </div>
            <div
                v-else
                :class="[{'grid-wrapper': !useKeyDate}]"
                class="simulation-wrapper"
            >
                <template v-if="hasTasks">
                    <div v-if="!useKeyDate">
                        <div
                            v-for="(items, index) in tasksGroupedByPeriod"
                            :key="index"
                            class="stats-wrapper"
                        >
                            <div>
                                {{ getPeriodLabel(items[0].period.label) }}
                            </div>
                            <div class="nb-tasks-wrapper">
                                {{ items.length }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <TaskTimelineSimulation
                            v-for="(items, index) in tasksGroupedByPeriod"
                            :key="index"
                            :period="items[0].period"
                            :tasks="items"
                            :key-date="mainKeyDateValue"
                            :key-date-name="mainKeyDateModel.name"
                            :use-key-date="useKeyDate"
                            :can-add-resource="canEdit && availableResourceList.length > 0"
                            :can-edit="canEdit"
                            @onAdd="onSelectFilter"
                            @onSave="onSelectFilter"
                            @onDelete="onSelectFilter"
                            @onOpenAddResource="openAddRessource"
                        />
                    </div>
                </template>
                <div
                    v-else
                    class="empty"
                >
                    <t>Your selected filter does not match with any resources.</t>
                </div>
            </div>
            <AddElementModal
                v-if="canEdit"
                ref="modalAddResource"
                :default-filters="defaultFilters"
                :resources="availableResourceList"
                :company-program-id="companyProgramId"
                :default-order-onboarding-start="periodStart"
                :default-order-onboarding-end="periodEnd"
                @onAdd="refreshTasks"
            />
        </template>
    </div>
</template>

<script>
import groupBy from 'lodash.groupby';
import orderBy from 'lodash.orderby';
import AddElementModal from '@/components/pages/program/builder/AddElementModal.vue';
import Company from '@/models/Company';
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import TaskTimelineSimulation from '@/components/resources/task/TaskTimelineSimulation.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import programs from '@/store/api/configuration/programs';
import HtEntitiesFiltersWrapper from '@/components/globals/filters/HtEntitiesFiltersWrapper.vue';

import { arrayToTree } from 'performant-array-to-tree';
import TimelineHelper from '@/classes/Timeline/TimelineHelper';
import { mapGetters } from 'vuex';

const htModalTypes = {
    BOTTOM: 'HtModalBottom',
    RIGHT: 'HtModal',
};

export default {
    name: 'ProgramDetailSimulation',
    permissions: [
        'ModelCompanySharedDocument',
        'ModelCompanyDocument',
        'ModelCompanySurvey',
        'ModelCompanyQuiz',
        'ModelCompanyPlanningEventTemplate',
        'ModelCompanySoftware',
        'ModelCompanyEquipment',
        'ModelCompanyRequirementCategory',
        'ModelCompanyProgramTask',
        'ModelCompanyNudge',
        'ModelCompanyEmailCustomTemplate',
    ],

    components: {
        HtFormSelector,
        TaskTimelineSimulation,
        AddElementModal,
        HtEntitiesFiltersWrapper,
    },

    mixins: [
        DefaultFiltersMixin,
    ],

    props: {
        /**
         * id du companyProgram simulé
         */
        companyProgramId: {
            type: Number,
            required: true,
        },
        useKeyDate: {
            type: Boolean,
            default: true,
        },
        showFilters: {
            type: Boolean,
            default: () => true,
        },
        canEdit: {
            type: Boolean,
            default: () => true,
        },
        defaultEntities: {
            type: Array,
            default: () => ([]),
        },
        companyProgram: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },

    data() {
        return {
            tasks: [],
            mainKeyDateValue: null,
            additionalKeyDateValues: {},
            periodStart: 0,
            periodEnd: 0,
            selectedCompanyRole: null,
            resourceList: [
                {
                    key: 'document',
                    label: 'Documents',
                    image: 'temp-doc.svg',
                    componentList: 'Document',
                    componentAdd: 'SharedDocumentEdit',
                    ht_modal_type: htModalTypes.RIGHT,
                    modelPermission: 'ModelCompanySharedDocument',
                },
                {
                    key: 'training',
                    label: 'Trainings',
                    image: 'training.svg',
                    componentList: 'Training',
                    componentAdd: 'TrainingEdit',
                    ht_modal_type: htModalTypes.RIGHT,
                    modelPermission: 'ModelCompanyDocument',
                },
                {
                    key: 'survey',
                    label: 'Surveys',
                    image: 'survey.svg',
                    componentList: 'Survey',
                    componentAdd: 'SurveyEdit',
                    hidedTabs: ['results'],
                    ht_modal_type: htModalTypes.BOTTOM,
                    modelPermission: 'ModelCompanySurvey',
                },
                {
                    key: 'quiz',
                    label: 'Quiz',
                    image: 'survey.svg',
                    componentList: 'Quiz',
                    componentAdd: 'QuizEdit',
                    ht_modal_type: htModalTypes.RIGHT,
                    modelPermission: 'ModelCompanyQuiz',
                },
                {
                    key: 'event',
                    label: 'Event',
                    image: 'planning.svg',
                    componentList: 'PlanningEvent',
                    componentAdd: 'PlanningEventTemplateEdit',
                    ht_modal_type: htModalTypes.RIGHT,
                    modelPermission: 'ModelCompanyPlanningEventTemplate',
                    title: this.translate('Add an event'),
                },
                {
                    key: 'software',
                    label: 'Software',
                    image: 'software.svg',
                    componentList: 'Software',
                    componentAdd: 'SoftwareEdit',
                    ht_modal_type: htModalTypes.RIGHT,
                    modelPermission: 'ModelCompanySoftware',
                },
                {
                    key: 'equipment',
                    label: 'Equipment',
                    image: 'temp-equipment.svg',
                    componentList: 'Equipment',
                    componentAdd: 'EquipmentEdit',
                    ht_modal_type: htModalTypes.RIGHT,
                    modelPermission: 'ModelCompanyEquipment',
                },
                {
                    key: 'requirement',
                    label: 'Requirement',
                    image: 'requirement.svg',
                    componentList: 'RequirementCategory',
                    componentAdd: 'RequirementCategoryEdit',
                    hidedTabs: [],
                    ht_modal_type: htModalTypes.BOTTOM,
                    modelPermission: 'ModelCompanyRequirementCategory',
                },
                {
                    key: 'email',
                    label: 'Emails',
                    image: 'email.svg',
                    componentList: 'EmailCustom',
                    componentAdd: 'ResourceEmailEdit',
                    ht_modal_type: htModalTypes.RIGHT,
                    modelPermission: 'ModelCompanyEmailCustomTemplate',
                },
                {
                    key: 'action',
                    label: 'Actions',
                    image: 'temp-tasks.svg',
                    componentList: 'Action',
                    componentAdd: 'TaskEdit',
                    ht_modal_type: htModalTypes.RIGHT,
                    modelPermission: 'ModelCompanyProgramTask',
                },
                {
                    key: 'nudge',
                    label: 'Nudges',
                    image: 'temp-tasks.svg',
                    componentList: 'Nudge',
                    componentAdd: 'NudgeEdit',
                    ht_modal_type: htModalTypes.RIGHT,
                    modelPermission: 'ModelCompanyNudge',
                },
            ],
            simulationData: {
                tasks: [],
                key_dates: [],
            },
            areSimulationDataLoaded: false,
            company: new Company(['id']).with({
                entityValues: (query) => {
                    query.select([
                        'id',
                        'company_entity_id',
                        'resourceable_type',
                        'resourceable_id',
                    ]).with({
                        jobPosition: (query) => {
                            query.select([
                                'id',
                                'name',
                                'is_heyteam',
                            ]);
                        },
                        contract: (query) => {
                            query.select([
                                'id',
                                'name',
                                'is_heyteam',
                            ]);
                        },
                        office: (query) => {
                            query.select([
                                'id',
                                'name',
                                'is_heyteam',
                            ]);
                        },
                        department: (query) => {
                            query.select([
                                'id',
                                'name',
                                'is_heyteam',
                            ]);
                        },
                    });
                },
                companyRole: (query) => {
                    query.select(['id', 'name', 'alias', 'is_heyteam'])
                        .where([['is_assignable_resource', '=', 1]])
                        .orderBy('alias', 'asc');
                },
            }),
        };
    },

    computed: {
        ...mapGetters('programs', [
            'findProgramById',
        ]),

        ...mapGetters('config', [
            'getKeyDateBySlug',
        ]),

        mainKeyDateModel() {
            return this.simulationData.key_dates.find((keyDate) => keyDate.is_main);
        },
        additionalKeyDateModels() {
            return this.simulationData.key_dates.filter((keyDate) => !keyDate.is_main);
        },
        isLoaded() {
            return this.areSimulationDataLoaded
                && this.company.isLoaded()
                && this.$store.state.roles.rolesLoaded;
        },

        filtersCount() {
            let count = 0;

            if (this.selectedCompanyRole) {
                count++;
            }

            return count;
        },

        roles() {
            return this.$store.state.roles.roles.map((role) => ({
                id: role.id,
                name: this.translate(role.alias),
            }));
        },

        tasksGroupedByPeriod() {
            return orderBy(
                groupBy(this.filterByRole(this.simulationData.tasks), 'period.label'),
                (group) => group[0].period.order,
            );
        },

        hasTasks() {
            return Object.keys(this.tasksGroupedByPeriod).length > 0;
        },

        isFiltering() {
            return this.$store.state.programs.filters.entities.length > 0 || !!this.selectedCompanyRole;
        },

        availableResourceList() {
            return this.resourceList.filter((resource) => this.$canCreate(resource.modelPermission));
        },
        title() {
            let programName = '';
            if (this.companyProgram?.name_translated) {
                programName = `${this.companyProgram.name_translated} : `;
            }

            return `${programName}${this.translate('Program simulation', { count: this.count })}`;
        },
    },

    watch: {
        mainKeyDateValue() {
            this.refreshTasks();
        },
        additionalKeyDateValues() {
            this.refreshTasks();
        },
    },

    async created() {
        await this.company.get();
        await this.refreshTasks();

        this.$store.dispatch('roles/fetchRoles');
        this.$store.dispatch('programs/fetchPrograms');
    },

    methods: {
        getPeriod(task) {
            let keyDate;

            switch (true) {
            case task.resourceProgram.key_date.is_main:
                // If :
                // - the resource is an event OR
                // - the resource is related to a main key date (simulated or not)
                // then this last one must be placed in "classic blocks" of timeline

                keyDate = this.mainKeyDateValue;
                return TimelineHelper.getTaskPeriod(
                    this.$Utils.moment(keyDate),
                    this.$Utils.moment(task.order_date),
                );

            case Boolean(this.mainKeyDateValue)
                && Boolean(this.additionalKeyDateValues[task.resourceProgram.key_date.id]):
                // If :
                // - the main key date is simulated AND
                // - the resource is related to an additional key date,
                // then this last one must be placed in "classic blocks" of timeline

                keyDate = this.additionalKeyDateValues[task.resourceProgram.key_date.id];
                return TimelineHelper.getTaskPeriod(
                    this.$Utils.moment(keyDate),
                    this.$Utils.moment(task.order_date),
                );

            default:
                // Otherwise, the resource must be placed in "other blocks"

                return TimelineHelper.getOtherPeriod(
                    `Resources attached to ${this.getKeyDateBySlug(task.resourceProgram.key_date.template.slug).name}`,
                );
            }
        },
        getKeyDatesForm() {
            const keyDates = {};

            if (this.mainKeyDateValue) {
                keyDates[this.mainKeyDateModel.id] = { starts_at: this.mainKeyDateValue };
            }

            for (const [additionalKeyDateId, value] of Object.entries(this.additionalKeyDateValues)) {
                if (value) {
                    keyDates[additionalKeyDateId] = { starts_at: value };
                }
            }

            return keyDates;
        },
        async refreshTasks() {
            this.areSimulationDataLoaded = false;

            try {
                let { filters } = this.$store.state.programs;

                if (!this.showFilters && !!this.defaultEntities) {
                    filters = { entities: this.defaultEntities };
                }

                const simulateResponse = await programs.simulate(this.companyProgramId, {
                    resource: {
                        key_dates: this.getKeyDatesForm(),
                        filters,
                    },
                });

                this.simulationData = {
                    tasks: orderBy(
                        arrayToTree(
                            simulateResponse.data.tasks.map((task) => ({
                                ...task,
                                period: this.getPeriod(task),
                            })),
                            {
                                id: 'resource_program_id',
                                parentId: 'dependent',
                                dataField: null,
                                childrenField: 'dependencies',
                            },
                        ),
                        (task) => task.order_date,
                    ),
                    key_dates: simulateResponse.data.key_dates,
                };

                this.areSimulationDataLoaded = true;
            } catch (error) {
                console.error({ error });
                this.$Notifier('App').showError(this.translate('An error occured. Please reload the page to retry'));
                this.simulationData = {
                    tasks: [],
                    key_dates: [],
                };
                this.areSimulationDataLoaded = false;
            }

            if (!this.isFiltering) {
                this.$emit('updateValidateButton', this.simulationData.tasks.length > 0);
            }
        },

        onSelectFilter() {
            this.refreshTasks();
        },

        onClearFilters() {
            this.selectedCompanyRole = null;
        },

        /**
         * @param {Array} tasksFiltered
         * @returns {Array}
         */
        filterByRole(tasksFiltered) {
            if (!this.selectedCompanyRole) {
                return tasksFiltered;
            }
            return tasksFiltered
                // eslint-disable-next-line arrow-body-style
                .filter((task) => {
                    // eslint-disable-next-line arrow-body-style
                    return task.participants.some((participant) => {
                        return participant.company_role_id === this.selectedCompanyRole.id;
                    });
                });
        },

        openAddRessource(start, end) {
            if (!this.canEdit) {
                return;
            }

            this.periodStart = start;
            this.periodStart = end;
            this.$refs.modalAddResource.open();
        },

        getPeriodLabel(label) {
            return this.translate(label, {
                key_date_name: this.mainKeyDateModel.name,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/type";
@import "~@/styles/var";

.program-simulation-wrapper{
    padding: 2.4rem 0;
}

@media (max-width: $tablet) {
    .program-simulation-wrapper{
        margin: 0 16px;
    }
}
.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-1 {
    flex: 1 1 0%;
}

.grid-wrapper {
    display: grid;
    grid-template-columns: auto 75%;

    &-left {
        position: sticky;
        top: 0;
        height: 100vh;
    }
}
.simulation-wrapper{
    padding: 16px;
    background: white;
    border: 1px solid $neutral-300;
    border-radius: 8px;
}

.stats-wrapper {
    display: flex;
    align-items: center;
    padding: 1.6rem 0;
    color: $color-text-grey;
    font-size: 1.4rem;
}

.nb-tasks-wrapper {
    margin: 0 1rem 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 35px;
    min-height: 35px;
    border-radius: 50%;
    background: $blue-light;
    color: $color-text;
    font-weight: bold;
}
</style>
