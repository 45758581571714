<script>
import CompanyUser from '@/models/CompanyUser';
import I18n from '../modules/i18n/I18n';

export default {
    name: 'InviteMail',
    data() {
        return {
            is_saving: false,
            company_user: null,
        };
    },
    created() {
        this.getInvited().then(() => {
            this.updateInvited().then(async () => {
                this.pushToFlow();
            });
        });
    },

    methods: {
        getInvited() {
            return new Promise((resolve) => {
                this.is_saving = true;

                this.API.get(`company_user/getInvited/${this.$route.params.id}`, {
                    200: ({ company_user }) => {
                        this.company_user = company_user;

                        I18n.getTranslationPack(company_user.language).then(() => {
                            resolve();
                        });

                        this.is_saving = false;

                        if (company_user.state === CompanyUser.STATE_PREBOARDING) {
                            this.pushToFlow();
                        }
                    },
                    onError: () => {
                        this.$router.push('/Login');
                    },
                });
            });
        },

        async pushToFlow() {
            if (this.company_user.company.is_holding) {
                this.$router.push({ name: 'HoldingWelcomeChatbotCreatePassword' });
            } else {
                this.$router.push({ name: 'Flow' });
            }
        },

        updateInvited() {
            return new Promise((resolve, reject) => {
                this.is_saving = true;

                this.API.post('company_user/updateInvited', {
                    company_user: this.company_user,
                }, {
                    200: () => {
                        resolve();
                    },

                    onError: () => {
                        this.is_saving = false;
                        reject();
                    },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
	.full-page, .link-expired {
		height: 100%;
	}

	.link-expired {
		align-items: center;
		display: flex;
		font-size: 5vw;
		justify-content: center;
	}

	.centered {
		margin: 0 auto;

		&.large {
			max-width: 850px;
		}

		&.small {
			max-width: 700px;
		}
	}

	.bg-home {
		background: #f7f7f7;
		align-items: initial;

		&:before {
			background: none;
		}

		p {
			color: inherit;
		}

		.wrapper-over-bg {
			margin: 0 8rem;
			background: #fff;
			border: 1px solid #e5e5e5;
			border-radius: 5px;
			color: #332d37;
			padding: 5rem 50px;

			.wrapper-custom-message {
				background: #f7f7f7;
				margin: 2.5em 0;
				padding: 2.5rem 2rem 5rem;
				font-style: italic;
			}

			.reactive-cell {
				align-items: center;
				margin-bottom: 1em;
			}

			.user-img {
				height: 30px;
				width: 30px;
				border-radius: 7px;
				margin-right: 2rem;
			}
		}
	}

	.flex {
		width: 100%;
		display: inline-flex;
	}

	.image-bot-avatar {
		position: absolute;
		left: -110px;
		top: -5px;
	}
	.title-bubble {
		h3 {
			font-size: 2.5rem;
		}
	}

	.label-checkbox input {
		flex:30px;
	}

	.documents-sign {
		h5 {
			margin-bottom: 1.5em;
		}
		iframe {
			border-bottom: 1px solid #f3f3f3;
			width: 100%;
			height: 450px;
		}
	}

</style>
