<template>
    <HtToast
        type="info"
        class="mb-5 mt-2"
        :title="translate('Program Launch Condition Alert')"
        :message="translate(message)"
    />
</template>

<script>
import HtToast from '@/components/globals/HtToast.vue';

export default {
    name: 'ProgramLaunchConditionToast',
    components: {
        HtToast,
    },
    data() {
        return {
            message: 'Users with a similar program "in progress"/"in draft" and/or not responding to the conditioning associated with this program, were not selected.',
        };
    },
};
</script>
