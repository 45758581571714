<template>
    <div
        class="input-wrapper"
        :class="cssClasses"
    >
        <slot />
        <InputConfig
            v-if="canDelete"
            :can-mandatorize="canMandatorize"
            :mandatory="mandatory"
            :title="title"
            :role-id="roleId"
            :field-type="fieldType"
            :field-rules="fieldRules"
            :field-id="fieldId"
            @mandatorize="$emit('mandatorize')"
            @setTitle="setTitle"
            @delete="$emit('delete')"
            @setRole="setRole"
            @setRule="setRule"
            @setSpecificRule="setSpecificRule"
        />
        <mandatory-icon
            v-else-if="mandatory"
            :mandatory="mandatory"
        />
    </div>
</template>

<script>
import MandatoryIcon from '@/components/DocumentEditor/Inputs/MandatoryIcon.vue';
import InputConfig from './InputConfig.vue';

export default {
    name: 'InputWrapper',
    components: {
        MandatoryIcon,
        InputConfig,
    },
    props: {
        canDelete: {
            type: Boolean,
            default: false,
        },
        canMandatorize: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
        customDelete: {
            type: Boolean,
            default: false,
        },
        editing: {
            type: Boolean,
            default: false,
        },
        placeholderItem: {
            type: String,
            default: '',
        },
        mandatory: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        roleId: {
            type: Number,
            default: null,
        },
        fieldType: {
            type: String,
            default: 'text',
        },
        fieldRules: {
            type: Object,
            default: () => null,
        },
        fieldId: {
            type: Number,
            default: null,
        },
    },
    computed: {
        cssClasses() {
            const baseclass = {
                editing: this.editing,
            };
            const placeHolderClass = this.placeholderItem ? `can-edit-${this.placeholderItem}` : 'can-edit';
            baseclass[placeHolderClass] = this.canEdit;
            return baseclass;
        },
    },
    methods: {
        setSpecificRule(v) {
            this.$emit('setSpecificRule', v);
        },
        setTitle(v) {
            this.$emit('setTitle', v);
        },
        setRole(roleId) {
            this.$emit('setRole', roleId);
        },
        setRule(ruleInfos) {
            this.$emit('setRule', ruleInfos);
        },
    },
};
</script>
<style scoped lang="scss">
@import '~@/styles/var';
.input-wrapper{
    position: relative;
    display: flex;
    align-items: center;
}

.input-wrapper.can-edit-checkmark ::v-deep .checkmark {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, var(--branding-color) 0px 0px 0px 2px;
    background: #d8d8d8;
    border-radius: 5px;
}

.input-wrapper.editing {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 1px, var(--branding-color) 0px 0px 0px 1px;
}

.input-wrapper.can-edit {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, var(--branding-color) 0px 0px 0px 2px;
    background: #d8d8d8;
    border-radius: 5px;
}

.input-wrapper:not(.can-edit):not(.can-edit-checkmark) {
    background: transparent;
    border: 1px solid  #D8D8D8;
    border-radius: 5px;
    padding: 1px;
}

</style>
