<template>
    <div ref="outer_popin">
        <div
            ref="inner_popin"
            :key="ids"
        >
            <transition name="fade">
                <!--  IE BUG -->
                <div v-if="visible">
                    <slot />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Popin',

    shared: {
        popin: {
        },
    },

    props: {
        uniqClass: {
            type: String,
            default: 'default',
        },
    },

    data() {
        return {
            visible: false,

            ids: 'id',

        };
    },

    created() {
        this.ids = this.$Utils.uniqId();
    },

    beforeDestroy() {
        this.close();
    },

    methods: {

        open() {
            if (this.visible) return;
            this.visible = true;

            this.$nextTick(() => {
                document.getElementById('app').appendChild(this.$refs.inner_popin);

                if (this.shared.popin[this.uniqClass]) {
                    this.shared.popin[this.uniqClass].close();
                }

                this.shared.popin[this.uniqClass] = this;

                this.addEventListener();

                this.refreshPosition();
            });
        },

        close() {
            if (!this.visible) return;
            this.$refs.outer_popin.appendChild(this.$refs.inner_popin);

            delete this.shared.popin[this.uniqClass];
            this.removeEventListener();
            this.visible = false;
        },

        onScroll() {
            if (!this.visible) return;

            this.refreshPosition();
        },

        addEventListener() {
            document.getElementById('app').addEventListener('scroll', this.onScroll);
            document.body.addEventListener('scroll', this.onScroll);
            window.addEventListener('resize', this.onResize);
            document.addEventListener('click', this.onClick);
        },

        removeEventListener() {
            document.getElementById('app').removeEventListener('scroll', this.onScroll);
            document.body.removeEventListener('scroll', this.onScroll);
            window.removeEventListener('resize', this.onResize);
            document.removeEventListener('click', this.onClick);
        },

        onClick() {
            if (!this.visible) return;

            this.close();
        },

        onResize() {
            if (!this.visible) return;

            this.refreshPosition();
        },

        refreshPosition() {
            const position = $(this.$refs.outer_popin).offset();
            this.$refs.inner_popin.style.position = 'absolute';
            this.$refs.inner_popin.style.top = `${position.top}px`;
            this.$refs.inner_popin.style.left = `${position.left}px`;
        },

    },

};

</script>

<style style="scss" scoped>
.fade-enter-active, .fade-leave-active {
		transition: opacity 0.3s;
		z-index: 5;
		position: absolute;
	}

	.fade-leave-to, .fade-leave-active{
		display: none;
		z-index: 5;
		position: absolute;

	}
	.fade-enter{
		opacity: 0;
		z-index: 5;
		position: absolute;

	}
	.fade-enter-to{
		opacity: 1;
		z-index: 5;
		position: absolute;
	}
</style>
