import Vue from 'vue';
import Model from '@tony.caron/node-model-proxy/Model';
import { SurveyQuestionType, ListQuestionType } from '@/models/common/SurveyQuestionType';
import Company from './Company';
import CompanySurvey from './CompanySurvey';
import CompanySurveyQuestionAnswer from './CompanySurveyQuestionAnswer';
import CompanySurveyQuestionLocale from './CompanySurveyQuestionLocale';
import CompanySurveyQuestionCollection from './CompanySurveyQuestionCollection';
import CompanyUserSurveyQuestion from './CompanyUserSurveyQuestion';
import CompanyUserSurveyQuestionAnswer from './CompanyUserSurveyQuestionAnswer';

export default class CompanySurveyQuestion extends Model {
    static TYPE_MULTIPLE_CHOICE = 'multiple_choice';

    static TYPE_SATISFACTION_SCALE = 'satisfaction_scale';

    static TYPE_SIMPLE_CHOICE = 'simple_choice';

    static TYPE_YES_NO = 'yes_no';

    static TYPE_TEXT = 'text';

    static TYPE_REQUIREMENT = 'requirement';

    static TYPE_FILE_UPLOAD = 'file_upload';

    static SUBTYPE_SATISFACTION_SCALE_SMILEY = 'satisfaction_scale_smiley';

    static SUBTYPE_SATISFACTION_SCALE_NPS = 'satisfaction_scale_nps';

    static FREE_ANSWERS = [
        CompanySurveyQuestion.TYPE_TEXT,
        CompanySurveyQuestion.TYPE_REQUIREMENT,
        CompanySurveyQuestion.TYPE_FILE_UPLOAD,
    ];

    static CHOICES_ANSWERS = [
        CompanySurveyQuestion.TYPE_MULTIPLE_CHOICE,
        CompanySurveyQuestion.TYPE_SIMPLE_CHOICE,
        CompanySurveyQuestion.TYPE_SATISFACTION_SCALE,
        CompanySurveyQuestion.SUBTYPE_SATISFACTION_SCALE_NPS,
        CompanySurveyQuestion.SUBTYPE_SATISFACTION_SCALE_SMILEY,
    ];

    static NON_CONVERSATIONAL_TYPES = [
        CompanySurveyQuestion.TYPE_TEXT,
        CompanySurveyQuestion.TYPE_SIMPLE_CHOICE,
        CompanySurveyQuestion.TYPE_MULTIPLE_CHOICE,
        CompanySurveyQuestion.TYPE_YES_NO,
        CompanySurveyQuestion.TYPE_FILE_UPLOAD,
        CompanySurveyQuestion.TYPE_SATISFACTION_SCALE,
    ];

    modelConfig() {
        return {
            collection: CompanySurveyQuestionCollection,
        };
    }

    modelCustomAttributes() {
        return {
            locales_by_key: {},
            extra: null,
            text: null,
        };
    }

    modelEvents() {
        return {
            loaded: () => {
                Vue.prototype.$modelUtils.createLocales(this.company_survey_question_locale, ['text']);
            },
        };
    }

    modelRelations() {
        return {
            company: () => this.belongsTo(Company, 'company_id', 'id'),
            companySurvey: () => this.belongsTo(CompanySurvey, 'company_survey_id', 'id'),
            companySurveyQuestionAnswer: () => this.hasMany(CompanySurveyQuestionAnswer, 'company_survey_question_id', 'id'),
            companySurveyQuestionLocale: () => this.hasMany(CompanySurveyQuestionLocale, 'company_survey_question_id', 'id'),
            companyUserSurveyQuestion: () => this.hasMany(CompanyUserSurveyQuestion, 'company_survey_question_id', 'id'),
            companyUserSurveyQuestionAnswer: () => this.hasMany(CompanyUserSurveyQuestionAnswer, 'company_survey_question_id', 'id'),

        };
    }

    modelAccessors() {
        return {
            extra: () => {
                if (this.type === undefined) { return null; }
                let item = null;
                switch (this.type) {
                case ListQuestionType.QUESTION_TYPE_MULTIPLE_CHOICE:
                    item = SurveyQuestionType.QUESTION_TYPE_MULTIPLE_CHOICE;
                    break;
                case ListQuestionType.QUESTION_TYPE_SIMPLE_CHOICE:
                    item = SurveyQuestionType.QUESTION_TYPE_SIMPLE_CHOICE;
                    break;
                case ListQuestionType.QUESTION_TYPE_YES_NO:
                    item = SurveyQuestionType.QUESTION_TYPE_YES_NO;
                    break;
                case ListQuestionType.QUESTION_TYPE_TEXT:
                    item = SurveyQuestionType.QUESTION_TYPE_TEXT;
                    break;
                case ListQuestionType.QUESTION_TYPE_SATISFACTION_SCALE:
                    item = SurveyQuestionType.QUESTION_TYPE_SATISFACTION_SCALE;
                    break;
                case ListQuestionType.QUESTION_TYPE_FILE_UPLOAD:
                    item = SurveyQuestionType.QUESTION_TYPE_FILE_UPLOAD;
                case ListQuestionType.QUESTION_TYPE_REQUIREMENT:
                    item = SurveyQuestionType.QUESTION_TYPE_REQUIREMENT;
                }
                return item;
            },
            locales_by_key: () => Vue.prototype.$modelUtils.createLocalesByKey(this.company_survey_question_locale),
            text: () => this.localize('text'),
        };
    }

    localize(field, languageKey, defaultLanguageKey) {
        return Vue.prototype.$modelUtils.localize(this.locales_by_key, field, languageKey, defaultLanguageKey);
    }
}
