<template>
    <div class="requirement-card-wrapper">
        <div class="requirement-card-wrapper-title">
            <strong>
                <t v-if="requirement.is_heyteam">{{ requirement.name }}</t>
                <span v-else>{{ requirement.name }}</span>
            </strong>
        </div>
        <div class="requirement-card-wrapper-description">
            {{ getRequirementType }}
        </div>
    </div>
</template>

<script>
import CompanyRequirement from '@/models/CompanyRequirement';
import CompanyUserRequirement from '@/models/CompanyUserRequirement';

export default {
    name: 'RequirementCard',
    props: {
        requirement: {
            type: [CompanyRequirement, CompanyUserRequirement],
        },
    },

    computed: {

        getRequirementType() {
            const reqType = CompanyRequirement.getRequirementTypes().find(
                (item) => (item.value === this.requirement.type),
            );
            return this.translate(reqType ? reqType.label : 'Text');
        },

    },

};
</script>

<style lang="scss" src="./RequirementCard.scss" scoped />
