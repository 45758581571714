<template>
    <div>
        <div
            v-for="(item, index) in mainItems"
            :key="`main-${index}`"
            class="d-flex justify-content-end align-items-center mb-3"
        >
            <span class="px-1 font-weight-light text-right">
                {{ item.user.fullname ? item.user.fullname : getFullname(item.user) }}
            </span>
        </div>
        <div
            v-if="remaining > 0"
            class="d-flex justify-content-end cursor-pointer branding-color"
            @click="toggleAdditionalEmployees(mainItems.length + 5)"
        >
            <span v-text="translate('+{employeeCount} people', { employeeCount: remaining})" />
            <FontAwesomeIcon
                class="ml-2 mt-1"
                :icon="['fa', 'chevron-down']"
            />
        </div>
        <div
            v-else-if="remaining <= 0 && data.length > 5"
            class="d-flex justify-content-end cursor-pointer branding-color"
            @click="toggleAdditionalEmployees(5)"
        >
            <t>Hide additional users</t>
            <FontAwesomeIcon
                class="ml-2 mt-1"
                :icon="['fa', 'chevron-up']"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserPerimeter',
    props: ['label', 'data', 'hasLabel'],

    data() {
        return {
            itemNumber: 5,
        };
    },
    computed: {
        remaining() {
            return this.data.length - this.itemNumber;
        },

        mainItems() {
            return this.data.slice(0, this.itemNumber);
        },
    },

    methods: {
        toggleAdditionalEmployees(nbEmployee) {
            this.itemNumber = nbEmployee;
        },

        getFullname(user) {
            return `${user.firstname} ${user.lastname}`;
        },
    },
};
</script>

<style lang="scss" scoped>
    .cursor-pointer {
        cursor: pointer !important;
    }
</style>
