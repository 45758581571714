export default {
    computed: {
        keyDateLabel() {
            if (!this.hasProgramIdSetupInResource) {
                return this.translate('arrival date');
            }
            const program = this.shared.queries.companyProgramCollection.models.find(
                (program) => program.id === this.selectedProgramId,
            );

            if (!this.hasKeyDateTranslated(program)) {
                return this.translate('arrival date');
            }
            return program.key_date.name_translated.toLowerCase();
        },
        hasProgramIdSetupInResource() {
            return this.hasCompanyProgramId || this.hasResourceProgramId;
        },
        hasCompanyProgramId() {
            return 'company_program_id' in this.item && this.item.company_program_id !== undefined;
        },
        hasResourceProgramId() {
            if (!this.item.resource_program) {
                return false;
            }
            const resourceProgram = this.item.resource_program.models.find((i, k) => k === 0);
            return resourceProgram && 'company_program_id' in resourceProgram && resourceProgram.company_program_id !== undefined;
        },
        /**
         * @returns {number|undefined}
         */
        selectedProgramId() {
            return this.item.company_program_id || this.item.resource_program.models[0].company_program_id;
        },
    },
    methods: {
        hasKeyDateTranslated(program) {
            return program?.key_date.name_translated !== null && program?.key_date.name_translated !== undefined;
        },
    },
};
