<template>
    <div>
        <ProgramList
            :title="title"
            :subtitle="translate('Enrollees linked to your perimeter.')"
            :button-text="translate('Add a user')"
            :show-button="canCreateProgram"
            :type="'active'"
            :program-type="programType"
            :company-program="companyProgram"
            @on-button-clicked="createOnboardingClicked"
            @pagination-refreshed="liveProgramCount = $event.total"
        />

        <UserSelectionModal
            ref="selectUserModal"
            :company-program-id="parseInt($route.params.program_id)"
            @addExistingUser="openUsersModal"
            @create-new-invitation-session="openNewInvitationSessionModal"
        />

        <AddExistingUserModal
            ref="addExistingUserModal"
            :company-program-id="parseInt($route.params.program_id)"
            @userSelected="userSelected"
        />
    </div>
</template>

<script>
import ProgramList from '@/components/pages/program/lists/ProgramList.vue';
import ModalMixin from '@/components/globals/modals/modalMixin';
import NewInvitationSessionModal from '@/components/pages/foc/multiple/NewInvitationSessionModal.vue';
import api from '@/store/api';
import UserSelectionModal from './modals/UserSelectionModal.vue';
import AddExistingUserModal from './modals/AddExistingUserModal.vue';
import GoToDraftUserModalMixin from './modals/GoToDraftUserModalMixin';

export default {
    name: 'ProgramDetailLive',
    permissions: [
        'ModelCompanyUserProgram',
    ],

    components: {
        ProgramList,
        UserSelectionModal,
        AddExistingUserModal,
    },

    mixins: [
        ModalMixin,
        GoToDraftUserModalMixin,
    ],

    props: {
        programType: {
            type: String,
            default: '',
        },
        companyProgram: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data() {
        return {
            liveProgramCount: 0,
        };
    },

    computed: {
        canCreateProgram() {
            return this.$canCreate('ModelCompanyUserProgram');
        },
        title() {
            let programName = '';
            if (this.companyProgram?.name_translated) {
                programName = `${this.companyProgram.name_translated} : `;
            }

            return `${programName}${this.translate('Live programs | {count} live program | {count} live programs', { count: this.liveProgramCount })}`;
        },
    },

    methods: {
        searchChanged(search) {
            this.search = search;
        },
        createOnboardingClicked() {
            this.$refs.selectUserModal.open();
        },
        openUsersModal() {
            this.$refs.selectUserModal.close();
            this.$refs.addExistingUserModal.open();
        },
        openNewInvitationSessionModal() {
            this.$refs.selectUserModal.close();

            this.openCenterModal(
                NewInvitationSessionModal,
                {
                    programId: parseInt(this.$route.params.program_id, 10),
                },
                {},
                { clickToClose: false },
            );
        },
        userSelected(user) {
            // Appel à l'API pour vérifier les conditions de lancement du programme pour l'utilisateur sélectionné
            api
                .user
                .enrolling
                .checker
                .check(user.id, parseInt(this.$route.params.program_id, 10)).then((res) => {
                    const { reason, program } = res.data.data;

                    switch (reason) {
                    case 'USER_HAS_ACTIVE_PROGRAM':
                        this.showActiveProgramModal(program);
                        break;
                    case 'USER_HAS_DRAFT_PROGRAM':
                        this.showDraftProgramModal(program);
                        break;
                    case 'USER_MISMATCH_PROGRAM_LAUNCH_CONDITION':
                        this.showMismatchProgramModal();
                        break;
                    case 'USER_CAN_LAUNCH_PROGRAM':
                        this.$router.push({
                            name: 'Enrolling',
                            query: {
                                program_id: this.$route.params.program_id,
                                user_id: user.id,
                            },
                        });
                        break;
                    default:
                        break;
                    }
                }).catch(() => {
                    this.$Notifier('App').showError(this.translate('Error checking user program launch conditions'));
                });
        },
        showActiveProgramModal(activeProgram) {
            this.genericConfirm(
                this.translate('This user already have an active program'),
                this.translate('Do you want to close the active program ?'),
                null,
                this.translate('Close active program'),
                this.translate('See active program'),
            ).then(() => {
                this.$http.post('dashboard/statistics/close-programs', {
                    programs: [activeProgram.id],
                }).then(() => {
                    this.$Notifier('App').showInfo(this.translate('Active program closed'));
                    this.$router.push({
                        name: 'Enrolling',
                        query: {
                            program_id: this.$route.params.program_id,
                            user_id: activeProgram.company_user_id,
                        },
                    });
                }).catch(() => {
                    this.$Notifier('App').showError(this.translate('An error occured while closing the active program'));
                });
            }).catch((error) => {
                if (error === 'decline') {
                    this.$router.push({ path: `/Profile/${activeProgram.company_user_id}/Program/${activeProgram.id}` });
                }
            });
        },
        showDraftProgramModal(draftProgram) {
            this.goToDraftUserModal(draftProgram).then((choice) => {
                if (choice === 'create') {
                    this.$router.push({
                        name: 'Enrolling',
                        query: {
                            program_id: this.$route.params.program_id,
                            user_id: draftProgram.company_user_id,
                        },
                    });
                } else if (choice === 'existing') {
                    this.$router.push({
                        name: 'Enrolling',
                        query: {
                            user_program_id: draftProgram.id,
                        },
                    });
                }
            });
        },
        showMismatchProgramModal() {
            this.openDialogModal(
                'goToMismatchProgramModal',
                this.translate('Alert'),
                this.translate('Please check your entries, as this user cannot be invited to the programme: they do not meet the programme invitation criteria.'),
                [
                    {
                        title: this.translate('Ok'),
                        type: 'primary',
                        handler: () => {
                            this.$modal.hide('goToMismatchProgramModal');
                        },
                    },
                ],
            );
        },
    },
};
</script>
