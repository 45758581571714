<template>
    <div class="ht-table">
        <table
            v-if="rows.length > 0 || Object.keys(filters).length > 0"
            :class="{'hide-phone': !!$scopedSlots.responsive}"
            :data-cy="cypress"
        >
            <thead v-if="showHeader">
                <tr>
                    <th v-if="hasMultipleSelection">
                        <Checkable
                            v-model="isAllSelected"
                            :disabled="loading"
                            simple
                            :show-label="false"
                            @onChange="selectAllRows"
                        />
                    </th>
                    <th
                        v-for="(column, columnIndex) in columns"
                        :key="columnIndex"
                        :class="thClass(column)"
                        @click="sortColumn(column)"
                    >
                        <HtDeployableSearchField
                            v-if="isFilterable(column)"
                            v-model.trim="filters[column.key]"
                            :placeholder="column.placeholder"
                        >
                            {{ column.label }}
                        </HtDeployableSearchField>

                        <template v-else>
                            <FontAwesomeIcon
                                v-if="isSortable(column) && sortKey !== column.key"
                                class="ht-table-icon-sort"
                                icon="sort"
                            />
                            <FontAwesomeIcon
                                v-if="isSortable(column) && sortKey === column.key"
                                :icon="orderClass"
                            />
                            <slot
                                :name="headScope(column.key)"
                                :value="column.label"
                                :column="column"
                            >
                                {{ column.label }}
                            </slot>
                        </template>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(row, rowIndex) in rows"
                    :key="rowIndex"
                    :class="{clickable: clickable}"
                    @click="$emit('onRowClicked', row)"
                >
                    <td
                        v-for="(cell, cellIndex) in row.cells"
                        :key="cellIndex"
                        :class="tdClass(cell)"
                        @click="$emit('onCellClicked', cell)"
                    >
                        <Checkable
                            v-if="hasMultipleSelection && cell.column.key === 'tbl_checkbox'"
                            v-model="cell.item.selected"
                            :disabled="loading"
                            simple
                            :show-label="false"
                            @onChange="selectRow(row)"
                        />
                        <Skeleton
                            v-else
                            :loading="loading"
                        >
                            <slot
                                :name="cellScope(cell.key)"
                                :value="cell.value"
                                :item="cell.item"
                            >
                                {{ cell.value }}
                            </slot>
                        </Skeleton>
                    </td>
                </tr>
            </tbody>
        </table>
        <div
            v-else
            class="h-100 d-flex flex-column justify-content-center align-items-center"
        >
            <div class="mb-5">
                <img
                    src="/static/icons/survey/tabs/survey_no_result.svg"
                    class="d-block mx-auto"
                >
            </div>
            <div class="text-center">
                <t>{{ emptyMessage }}</t>
            </div>
        </div>
        <div
            class="responsive-table"
            :class="{'show-phone': !!$scopedSlots.responsive}"
        >
            <div
                v-for="(row, index) in rows"
                :key="index"
                class="responsive-element"
                @click="$emit('onRowClicked', row)"
            >
                <slot
                    name="responsive"
                    :item="row.item"
                />
            </div>
        </div>
    </div>
</template>

<script>
import get from 'lodash.get';
import Vue from 'vue';
import CypressMixin from '@/mixins/CypressMixin';
import HtDeployableSearchField from '@/components/globals/HtDeployableSearchField.vue';
import { Skeleton } from 'vue-loading-skeleton';
import Checkable from '@/components/globals/Checkable.vue';

export default {
    components: {
        Checkable,
        Skeleton,
        HtDeployableSearchField,
    },
    mixins: [
        CypressMixin,
    ],
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        hasMultipleSelection: {
            type: Boolean,
            default: false,
        },
        columns: {
            type: Array,
            default: () => [],
        },
        showHeader: {
            type: Boolean,
            default: () => true,
        },
        emptyMessage: {
            type: String,
            default: () => 'There is nothing to display here for now...',
        },
        clickable: {
            type: Boolean,
            default: true,
        },
        externalSearch: {
            type: String,
            default: null,
        },
        externalSortKey: {
            type: String,
            default: null,
        },
        externalSortDirection: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            search: null,
            sortKey: null,
            isAllSelected: null,
            sortDirection: null,
            filters: {},
            selectedRows: [],
        };
    },
    computed: {
        searchColumns() {
            return this.columns.filter((column) => column.search === true);
        },
        showSearch() {
            return this.searchColumns.length > 0;
        },
        fakeItems() {
            const items = [];

            for (let index = 0; index < 2; index++) {
                const item = {};
                this.columns.forEach((element) => {
                    item[element.key] = null;
                });
                items.push(item);
            }
            return items;
        },
        rows() {
            let currentRows = this.fakeItems;

            if (!this.loading) {
                currentRows = this.items;
            }

            return currentRows
                .sort((a, b) => {
                    const aString = get(a, this.sortKey) !== null && get(a, this.sortKey) !== undefined ? get(a, this.sortKey) : '';
                    const bString = get(b, this.sortKey) !== null && get(b, this.sortKey) !== undefined ? get(b, this.sortKey) : '';
                    if (this.sortKey === null) {
                        return 0;
                    }
                    if (typeof aString === 'number' && typeof bString === 'number') {
                        return this.sortDirection === 'asc'
                            ? aString - bString
                            : bString - aString;
                    }
                    return this.sortDirection === 'asc'
                        ? aString.toString().localeCompare(bString)
                        : bString.toString().localeCompare(aString);
                })
                .filter((item) => {
                    if (typeof this.externalSearch === 'string' && this.searchColumns.length > 0) {
                        const search = this.externalSearch.toString()
                            .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                            .toUpperCase();

                        for (const column of this.searchColumns) {
                            const text = ['string', 'number'].includes(typeof get(item, column.key))
                                ? get(item, column.key).toString()
                                    .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                    .toUpperCase()
                                : '';

                            if (text.indexOf(search) >= 0) {
                                return true;
                            }
                        }

                        return false;
                    }

                    return true;
                })
                .map((item) => {
                    const cells = this.columns.map((c) => ({
                        key: c.key,
                        item,
                        value: get(item, c.key),
                        column: c,
                    }));

                    if (this.hasMultipleSelection) {
                        // add an empty cell for the checkbox at the beginning of the row
                        cells.unshift({
                            key: 'tbl_checkbox',
                            item,
                            value: null,
                            column: {
                                key: 'tbl_checkbox',
                                class: 'ht-table-checkbox',
                            },
                        });
                    }

                    return { cells, item };
                });
        },
        orderClass() {
            return this.sortDirection === 'asc' ? 'sort-up' : 'sort-down';
        },
    },
    watch: {
        columns: {
            immediate: true,
            handler() {
                this.columns
                    .filter((column) => this.isFilterable(column))
                    .forEach((column) => {
                        Vue.set(this.filters, column.key, this.filters[column.key] || '');
                    });
            },
        },
        filters: {
            deep: true,
            handler() {
                this.columns
                    .filter((column) => column.filterFunction)
                    .forEach((column) => column.filterFunction(this.filters[column.key]));
            },
        },
    },
    mounted() {
        if (this.$route.query.id) {
            const cell = this.rows.find((row) => row.item.id == this.$route.query.id).cells[0];
            this.$emit('onCellClicked', cell);
        }
    },
    methods: {
        selectAllRows() {
            this.isAllSelected = !this.isAllSelected;

            this.rows.forEach((row) => {
                row.item.selected = this.isAllSelected;
            });

            this.selectedRows = this.isAllSelected ? this.rows : [];

            this.$emit('onRowSelected', {
                selectedRow: null,
                selectedRows: this.selectedRows,
            });
        },
        selectRow(row) {
            row.item.selected = !row.item.selected;

            if (row.item.selected) {
                this.selectedRows.push(row);
            } else {
                this.selectedRows = this.selectedRows.filter((selectedRow) => selectedRow.item !== row.item);
            }

            this.$emit('onRowSelected', {
                selectedRow: row,
                selectedRows: this.selectedRows,
            });
        },
        tdClass(cell) {
            const classes = [];

            if (cell.column.class) {
                classes.push(...cell.column.class.split(' '));
            }

            return classes;
        },
        thClass(column) {
            const classes = [];

            if (column.class) {
                classes.push(...column.class.split(' '));
            }

            if (this.isSortable(column) || this.isFilterable(column)) {
                classes.push('cursor-pointer');
            }

            return classes;
        },
        cellScope(key) {
            return `cell(${key})`;
        },
        headScope(key) {
            return `head(${key})`;
        },
        sortColumn(column) {
            if (this.isSortable(column) === false) {
                return;
            }

            const columnKey = column.sortKey || column.key;

            const sortKey = this.externalSortKey ?? this.sortKey;
            const sortDirection = this.externalSortDirection ?? this.sortDirection;

            if (columnKey !== sortKey) {
                this.sortKey = columnKey;
                this.sortDirection = 'asc';
            } else {
                this.sortKey = sortKey;
                this.sortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
            }
        },
        isSortable(column) {
            return column.sortable !== false;
        },
        isFilterable(column) {
            return column.filterFunction;
        },
    },
};
</script>

<style lang="scss" scoped src="./HtTable.scss"></style>
