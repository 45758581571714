import Model from '@tony.caron/node-model-proxy/Model';
import CompanyQuizQuestionAnswer from './CompanyQuizQuestionAnswer';
import CompanyQuiz from './CompanyQuiz';
import CompanyQuizQuestionCollection from './CompanyQuizQuestionCollection';
import CompanyFile from './CompanyFile';

export default class CompanyQuizQuestion extends Model {
    modelConfig() {
        return {
            collection: CompanyQuizQuestionCollection,
        };
    }

    modelDefaultValues() {
        return {
            translations: {
                text: {},
            },
            file_translations: {},
        };
    }

    modelRelations() {
        return {
            companyQuiz: () => this.belongsTo(CompanyQuiz, 'company_quiz_id', 'id'),
            companyQuizQuestionAnswer: () => this.hasMany(CompanyQuizQuestionAnswer),
            companyFiles: () => this.hasMany(CompanyFile),
            companyFilesFromLocale: () => this.hasMany(CompanyFile),
        };
    }
}
