<template>
    <HoldingResourcesCount
        v-if="companiesLoaded"
        :title="translate('Users')"
        :items="items"
        :route-list-name="'HoldingSettingsUsersList'"
    />
</template>

<script>
import { mapState } from 'vuex';
import HoldingResourcesCount from '@/components/globals/HoldingResourceCount/HoldingResourcesCount.vue';
import HoldingResourceCountItem from '@/components/globals/HoldingResourceCount/HoldingResourceCountItem';

export default {
    name: 'UsersCount',
    components: {
        HoldingResourcesCount,
    },

    created() {
        this.$store.dispatch('companies/fetchCompanies');
    },

    computed: {
        ...mapState('companies', [
            'companies',
            'companiesLoaded',
        ]),

        /**
         * @returns {HoldingResourceCountItem[]}
         */
        items() {
            return this.companies.map((company) => new HoldingResourceCountItem(
                company.name,
                company.users_count,
                company.id,
            ));
        },
    },
};
</script>
