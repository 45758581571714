<template>
    <div :data-cy="`${cypress}-item`">
        <div class="d-flex mb-3">
            <div
                v-if="hasDependent"
                class="d-flex justify-content-center align-items-center depend-icon"
            >
                <FontAwesomeIcon
                    class="branding-color"
                    size="lg"
                    :icon="['fal', 'level-up']"
                />
            </div>
            <div
                :class="['list-actions-item', {'bg-white': showWrapper }]"
                @mouseenter="isHoveringTask = true"
                @mouseleave="isHoveringTask = false"
            >
                <div
                    :class="['list-actions-item-wrapper d-flex justify-content-between', {'cursor-pointer': isClickable, 'list-actions-item-hover': isClickable && !showWrapper}]"
                    :data-cy="`${cypress}-click`"
                    @click.stop="onClick()"
                >
                    <div
                        v-if="showMultiSelect && isSelectable"
                        class="multiselect-checkbox"
                        :data-cy="`${cypress}-select`"
                        @click.stop="onSelectedAction"
                    >
                        <div class="multiselect-checkbox-wrapper">
                            <div :class="{'checked': isSelected == true, 'half': isSelected == 2}" />
                        </div>
                    </div>
                    <div class="content-left">
                        <div>
                            <div
                                v-if="!showWrapper"
                                class="d-flex align-items-center show-mobile mobile-action-date"
                            >
                                <div :class="[{'list-actions-item-late': isLate && isPending, 'list-actions-item-clock': isPending || isDone, 'list-actions-item-done': isDone}, 'mr-2']">
                                    <FontAwesomeIcon
                                        :icon="['far', 'clock']"
                                        size="1x"
                                    />
                                </div>
                                <div
                                    :class="[
                                        'list-actions-item-date',
                                        {
                                            'color-error': isLate && isPending,
                                            'list-actions-item-done': isDone
                                        }
                                    ]"
                                >
                                    {{ $Utils.moment(date).format("Do MMM") }}
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <slot name="title">
                                    <div
                                        v-if="showIconResource"
                                        class="mr-10"
                                    >
                                        <FontAwesomeIcon
                                            :icon="getIconResource"
                                            class="branding-color"
                                        />
                                    </div>
                                    <div class="list-actions-item-title-1">
                                        <div
                                            v-if="tagLabel"
                                            class="tag-label"
                                        >
                                            {{ tagLabel }}
                                        </div>

                                        {{ title }}
                                    </div>
                                </slot>
                            </div>
                            <div
                                v-if="$slots.file"
                                class="mt-2"
                            >
                                <slot name="file" />
                            </div>
                            <div
                                v-if="$slots.subtitle"
                                class="mt-2"
                            >
                                <slot name="subtitle" />
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="hasMiddleContent"
                        class="middle-content"
                    >
                        <div
                            v-if="participants.length > 0"
                            class="d-flex align-items-center mr-20"
                        >
                            <div class="participant-list">
                                <ParticipantList
                                    class="mr-10"
                                    :users="participants"
                                    :cypress="`${cypress}-participants`"
                                    :limit="3"
                                />
                            </div>
                        </div>
                        <div
                            v-if="availableDate !== '' && !hasDependent"
                            class="available-container mr-4"
                        >
                            <div class="available-container-icon">
                                <FontAwesomeIcon :icon="['far', 'eye']" />
                            </div>
                            <div class="available-container-label">
                                {{ `${translate('Available at: {date}', { date: localizedDate })}` }}
                            </div>
                        </div>
                        <div
                            v-if="$slots['middle-content']"
                            class="mr-4"
                        >
                            <slot name="middle-content" />
                        </div>
                    </div>
                    <div
                        v-if="shouldShowAvailabilityDate"
                        class="content-right d-flex align-items-center justify-content-flex-end mr-20"
                    >
                        <FontAwesomeIcon
                            v-if="hasDependent && shared.program.isLoading"
                            :icon="['fal', 'spinner-third']"
                            class="mr-5"
                            size="2x"
                            spin
                        />
                        <div
                            v-else
                            class="d-flex align-items-center mr-20"
                            :class="{'hide-mobile': !showWrapper}"
                        >
                            <template v-if="showWrapper">
                                <slot name="extra-right" />
                            </template>
                            <template v-else>
                                <div
                                    :class="[
                                        {
                                            'list-actions-item-late': isLate && isPending,
                                            'list-actions-item-clock': !isLate || isDone,
                                            'list-actions-item-done': isDone
                                        },
                                        'mr-2'
                                    ]"
                                >
                                    <FontAwesomeIcon
                                        :icon="['far', 'clock']"
                                        size="1x"
                                    />
                                </div>
                                <div
                                    :class="[
                                        'list-actions-item-date',
                                        {
                                            'color-error': isLate && isPending,
                                            'list-actions-item-done': isDone
                                        }
                                    ]"
                                >
                                    {{ $Utils.moment(date).format("Do MMM") }}
                                </div>
                            </template>
                        </div>
                        <div
                            v-if="showButtonRight"
                            :data-cy="`${cypress}-buttons`"
                            class="interaction"
                        >
                            <div
                                v-if="canValidate || isDone || isCancelled"
                                @click.stop="onToggle(!isDone)"
                            >
                                <CheckCancelledIcon
                                    v-if="isCancelled"
                                />
                                <CheckGreenIcon
                                    v-else-if="isDone"
                                />
                                <RoundEmptyBackgroundWithCursorNotAllowed
                                    v-else-if="!isTogglable"
                                />
                                <CheckGreyIcon
                                    v-else-if="isHoveringTask && isTogglable"
                                />
                                <RoundEmptyBackground v-else />
                            </div>
                            <div
                                v-else-if="!showWrapper"
                                class="icon-validate"
                            >
                                <FontAwesomeIcon
                                    size="1x"
                                    icon="chevron-right"
                                    class="branding-color"
                                />
                            </div>
                            <div
                                v-else-if="showWrapper"
                                class="icon-validate grey"
                            >
                                <FontAwesomeIcon
                                    size="1x"
                                    class="icon"
                                    :icon="getChevronDirection"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="$slots.subItems"
                    class="ml-20 mr-20 subitems"
                >
                    <slot name="subItems" />
                </div>
            </div>
        </div>
        <div
            v-if="$slots['dependency']"
            class="children-wrapper"
        >
            <slot :name="`dependency`" />
        </div>
    </div>
</template>
<script>
import CheckCancelledIcon from '@/components/globals/CheckCancelledIcon.vue';
import CheckGreenIcon from '@/components/globals/CheckGreenIcon.vue';
import CheckGreyIcon from '@/components/globals/CheckGreyIcon.vue';
import RoundEmptyBackgroundWithCursorNotAllowed from '@/components/globals/RoundEmptyBackgroundWithCursorNotAllowed.vue';
import RoundEmptyBackground from '@/components/globals/RoundEmptyBackground.vue';
import CypressMixin from '@/mixins/CypressMixin';
import moment from 'moment';
import IconLoading from '@/components/icons/IconLoading.vue';

export default {
    name: 'ListActionsItemHtml',
    components: {
        IconLoading,
        CheckCancelledIcon,
        CheckGreenIcon,
        CheckGreyIcon,
        RoundEmptyBackgroundWithCursorNotAllowed,
        RoundEmptyBackground,
    },
    mixins: [CypressMixin],
    props: {
        title: {
            type: String,
            default: '',
        },
        tagLabel: {
            type: String,
            default: null,
        },
        isLate: {
            type: Boolean,
            default: false,
        },
        date: {
            type: String,
            default: '',
        },
        availableDate: {
            type: String,
            default: '',
        },
        showWrapper: {
            type: Boolean,
            default: false,
        },
        isTogglable: {
            type: Boolean,
            default: true,
        },
        isToggled: {
            type: Boolean,
            default: false,
        },
        canValidate: {
            type: Boolean,
            default: true,
        },
        participants: {
            type: Array,
            default: () => [],
        },
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        isSelectable: {
            type: Boolean,
            default: true,
        },
        isSelected: {
            type: [Boolean, Number],
            default: false,
        },
        showIconResource: {
            type: Boolean,
            default: false,
        },
        resource: {
            type: String,
            default: 'custom',
        },
        hasDependent: {
            type: Boolean,
            default: false,
        },
        isClickable: {
            type: Boolean,
            default: true,
        },
        status: {
            type: String,
            default: 'pending',
        },
        validatorType: {
            type: String,
            default: 'human',
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            default: true,
        },
    },

    shared: {
        program: {
            isLoading: {
                type: Boolean,
                default: false,
            },
        },
    },

    data() {
        return {
            /**
             * L'utilisateur est-il en train de survoler la tâche
             */
            isHoveringTask: false,
        };
    },

    computed: {
        isHuman() {
            return this.validatorType === 'human';
        },

        isDone() {
            return this.status === 'done';
        },

        isPending() {
            return this.status === 'pending';
        },

        isCancelled() {
            return this.status === 'cancelled';
        },

        getChevronDirection() {
            return this.isToggled === true ? 'chevron-down' : 'chevron-right';
        },

        showButtonRight() {
            if (this.showMultiSelect) {
                return this.showWrapper;
            }
            return this.isClickable;
        },

        getIconResource() {
            switch (this.resource) {
            case 'company_planning':
                return ['fad', 'calendar-alt'];
            case 'company_software':
                return ['fad', 'browser'];
            case 'company_equipment':
                return ['fad', 'phone-laptop'];
            case 'company_training':
            case 'company_document':
                return ['fad', 'graduation-cap'];
            case 'company_doc':
                return ['fad', 'file-alt'];
            case 'company_survey':
                return ['fad', 'poll'];
            case 'company_user_quiz':
                return ['fad', 'question-square'];
            case 'company_nudge':
                return ['fad', 'smile-wink'];
            case 'company_email_custom':
                return ['fad', 'envelope'];
            default:
                return ['fad', 'tasks'];
            }
        },

        hasMiddleContent() {
            return this.participants.length > 0 || this.availableDate !== '' || this.$slots['middle-content'];
        },

        localizedDate() {
            return moment(this.availableDate).format('L');
        },
    },

    methods: {
        onSelectedAction() {
            this.$emit('onSelectedAction');
        },

        onToggle(status) {
            if (this.isTogglable && (this.isDone || this.isPending)) {
                this.$emit('onToggle', status);
            }
        },

        onClick() {
            if (this.isClickable) {
                this.$emit('onClick');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.subitems {
    .list-actions-item {
        background-color: #F5F8F9;
    }
}

.list-actions-item {
    background-color: $white;
    border-radius: $border-radius-8;
    min-height: 4.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .list-actions-item-wrapper {
        align-items: center;
    }

    &-hover {
        &:hover {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), $blue-light;
            border-radius: $border-radius-8;
        }
    }

    .tag-label {
        padding: 5px;
        max-width: fit-content;
        background-color: $grey-light;
        color: $black;
        border-radius: 3px;
        font-size: 14px;
        text-transform: uppercase;
    }

    &.bg-white {
        background-color: $white;
    }

    .multiselect-checkbox {
        display: flex;
        padding: 20px 0 20px 20px;
        cursor: pointer;

        &-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            background: $white;
            border: 1px solid $border-grey-light;
            border-radius: 4px;

            .checked {
                width: 8px;
                height: 8px;
                background-color: $primary;
                border-radius: 2px;
            }

            .half {
                width: 8px;
                height : 2px;
                background-color: $primary;
                border-radius: 2px;
            }
        }
    }

    .middle-content {
        display: flex;
        flex: 4;
        justify-content: flex-end;

        .available-container {
            position: relative;

            &-icon {
                display: flex;
                align-items: center;
                color: $blue-darker;
                --color6: $blue-darker;
            }

            &-label {
                box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.05);
                display: none;
                position: absolute;
                transform:
                    translateX(0.8rem) /* the status icon width */
                    translateX(0.8rem) /* the required horizontal gap */
                    translateY(0.4rem); /* the required vertical gap */

                padding: 0.6rem 1rem;
                font-size: 1.2rem;
                font-weight: bold;
                border-radius: 4px;
                background: $blue;
                color: $blue-darker;
                white-space: nowrap;
            }

            .available-container-icon:hover ~ .available-container-label, .available-container-label:hover {
                display: block;
            }
        }
    }

    .content-left {
        display: flex;
        align-items: center;
        padding: 15px 20px;
        min-height: 4.5em;
        flex: 7;

        &-title-2 {
            font-family: $lato;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-weight: bold;
            font-size: 16px;
        }

        &-title-3 {
            font-family: $lato;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 13px;
            color: $black-1;
        }
    }

    .content-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 4;

        .interaction {
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: stretch;

        }
    }

    &-title-1 {
        font-family: $lato;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        flex: 1 1 0;
        font-weight: bold;
        word-break: break-word;
    }

    &-title-2 {
        font-family: $lato;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 13px;
        flex: 1 1 0;
        font-weight: bold;
    }

    &-title-3 {
        font-family: $lato;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        font-weight: bold;
        flex: 1 1 0;
    }

    &-late {
        font-family: $lato;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
        color: $primary;
    }

    &-clock {
        font-size: 14px;
        color: $black;
    }

    &-date {
        font-size: 14px;
        color: $black;

        &.color-error {
            color: $primary;
        }
    }

    &-done {
        color: $grey-3;
    }
}

.depend-icon {
    padding: 0 15px;
    margin-bottom: 8px;
    transform: rotate(90deg);
}

.children-wrapper .children-wrapper {
    margin-left: 50px;
}

.cursor-pointer {
    cursor: pointer !important;
}

@media (max-width: $tablet) {
    .list-actions-item {
        &.action-members {
            display: none;
        }
    }
}

.mobile-action-date {
    margin-bottom: 1.2rem;
}

.show-mobile {
    display: none !important;
}

@media (max-width: $phone) {
    .list-actions-item .list-actions-item-wrapper {
        padding: 1.2rem;
        align-items: flex-start;
    }

    .list-actions-item .content-right .interaction {
        justify-content: flex-end;
        flex: none;
    }

    .list-actions-item .content-left {
        flex: auto;
        padding: 0;
    }

    .list-actions-item .content-right {
        flex: auto;
        margin-right: 0;
    }

    .list-actions-item {
        .interaction {
            flex: 0 0 50px;
        }
    }

    .show-mobile {
        display: block !important;
    }

    .show-mobile.d-flex {
        display: flex !important;
    }

    .hide-mobile {
        display: none !important;
    }
}

</style>
