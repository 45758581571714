import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyBenefit from './CompanyBenefit';

export default class CompanyBenefitCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyBenefit,
        };
    }
}
