<template>
    <Downloadable
        v-if="canDownload"
        :company-file="UserRequirement.company_file"
        :is-requirement="true"
    />
    <div v-else-if="isHellosignProcessing">
        {{ getPendingText }}
    </div>
    <div v-else>
        -
    </div>
</template>

<script>

export default {
    name: 'UserRequirementFileToSignRead',
    props: {
        UserRequirement: {
            type: Object,
            required: true,
        },
    },

    computed: {
        canDownload() {
            return this.UserRequirement.company_file
                && this.UserRequirement.company_file.id;
        },
        isHellosignProcessing() {
            return this.isSignaturesComplete && !this.canDownload;
        },
        isSignaturesComplete() {
            if (!this.UserRequirement.config_value) {
                return false;
            }

            return this.UserRequirement.config_value.signature_request.signers.every((signer) => signer.status_code === 'signed');
        },
        getPendingText() {
            return this.translate('It may take few minutes to visualize the document');
        },
    },
};
</script>

<style lang='scss' src='./UserRequirementFileToSignEdit.scss' scoped />
