import { env, isLocal } from '@/helpers/env';

/**
 * Check if the current URL contains the domain searched
 * @param {string} searchedDomain
 * @returns {boolean}
 */
export function doesDomainContain(searchedDomain) {
    const { href } = document.location;

    return href.indexOf(searchedDomain) !== -1;
}

/**
 * Check if the current URL is related to heyteam
 * @returns {boolean}
 */
export function isInternalDomain() {
    return doesDomainContain('heyteam.com') || doesDomainContain('heyteam.loc') || doesDomainContain('heyteam.local');
}

/**
 * Get the base URL for our XHR requests
 * @returns {string}
 */
export function getBaseUrl() {
    const isUrlLocal = isLocal();

    if (!isUrlLocal) {
        return env('COMPANY_API_URL');
    }

    return doesDomainContain('ngrok-free.app') ? `//${(new URL(window.location.href)).host}/api` : `${env('COMPANY_WEB_URL')}/api`;
}

/**
 * Get the base URL for our XHR requests
 * @returns {string}
 */
export function getNumspotBaseUrl() {
    const isUrlLocal = isLocal();

    if (!isUrlLocal) {
        return env('NUMSPOT_COMPANY_API_URL');
    }

    return doesDomainContain('ngrok-free.app') ? `//${(new URL(window.location.href)).host}/api` : `${env('NUMSPOT_COMPANY_API_URL')}`;
}
