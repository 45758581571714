<script>
export default {
    methods: {
        determineType(item) {
            // DEPRECATED
            if (item.company_user) { return 'user'; }
            if (item.company_role) { return 'role'; }
            if (item.company_group || item.group) { return 'company_group'; }
        },

        determineTypeFromModel(item) {
            if (item.company_user && item.company_user.firstname) { return 'user'; }

            if (item.company_role && item.company_role.alias) { return 'role'; }

            if ((item.group && item.group.alias)
                || (item.company_group && item.company_group.alias)) { return 'company_group'; }
        },

        getClass(item, fromModel = false) {
            const type = (fromModel) ? this.determineTypeFromModel(item) : this.determineType(item);

            switch (type) {
            case 'role':
                return `role-only ${item.company_role.name}`;

            case 'company_group':
                return `role-only ${item.group ? item.group.name : item.company_group.name}`;

            default:
                return '';
            }
        },

        getTitle(item, fromModel = false) {
            const type = (fromModel) ? this.determineTypeFromModel(item) : this.determineType(item);

            switch (type) {
            case 'user':
                return `${item.company_user.firstname} ${item.company_user.lastname}`;

            case 'role':
                return item.company_role.alias;

            case 'company_group':
                return item.group ? item.group.alias : item.company_group.alias;
            default:
                return '';
            }
        },

        getUserImage(item, fontSize = 1.25, fromModel = false) {
            const { getCompanyComponentRoleInitials } = this.$Utils;
            const type = (fromModel) ? this.determineTypeFromModel(item) : this.determineType(item);

            switch (type) {
            case 'user':
                return getCompanyComponentRoleInitials(
                    'user',
                    fontSize,
                    {
                        firstname: item.company_user.firstname,
                        lastname: item.company_user.lastname,
                        image: item.company_user.image,
                    },
                );

            case 'role':
                return getCompanyComponentRoleInitials(
                    item.company_role.alias,
                    fontSize,
                    null,
                );

            case 'company_group':
                return getCompanyComponentRoleInitials(
                    item.group ? item.group.alias : item.company_group.alias,
                    fontSize,
                    null,
                );
            default:
                return null;
            }
        },
    },
};
</script>
