<template>
    <div>
        <HtCard
            v-if="isLoaded"
            class="mb-5"
        >
            <template #title>
                <HtCardTitleBar
                    :title="title"
                    :show-close="true"
                    :show-button="true"
                    :button-text="translate('Create')"
                    @onAdd="onCreate"
                    @search="setSearchValue"
                    @on-close="$emit('on-change-step', 'SelectResourceTypeModal')"
                />
            </template>
            <template #default>
                <EquipmentsList
                    :items="resourceCollection.$.models"
                    :external-search="search"
                    :columns-to-hide="['filters']"
                    @onCellClicked="onCreate"
                />
            </template>
        </HtCard>
    </div>
</template>

<script>
import ResourceFilter from '@/components/resources/ResourceFilter.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import ProgramBuilderListResourceMixin from '@/mixins/ProgramBuilderListResourceMixin';
import EquipmentsList, { equipmentConfig } from '@/resourcesList/EquipmentsListModels.vue';
import HtProgramType from '@/models/HtProgramType';

export default {
    name: 'Equipment',
    components: {
        ResourceFilter,
        HtCardTitleBar,
        EquipmentsList,
    },

    mixins: [
        ProgramBuilderListResourceMixin,
    ],

    data() {
        return {
            resourceTitleSingular: 'equipment',
            resourceTitlePlural: 'equipments',
            collectionName: 'CompanyEquipmentCollection',
            itemFields: equipmentConfig.fields,
            itemRelations: equipmentConfig.relations,
        };
    },

    methods: {
        AddCollectionWhereHas() {
            this.resourceCollection.whereHas({
                resource: (query) => {
                    query.whereHas({
                        programs: (query) => {
                            query.where([
                                ['company_program_id', '!=', this.companyProgramId],
                            ]).whereHas({
                                program: (query) => {
                                    query.whereHas({
                                        htProgramType: (query) => {
                                            query.where([
                                                ['slug', '!=', HtProgramType.SLUG_DEFAULT],
                                            ]);
                                        },
                                    });
                                },
                            });
                        },
                    });
                },
            });
        },

    },
};
</script>
