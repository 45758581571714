import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyEquipment from './CompanyEquipment';

export default class CompanyEquipmentCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyEquipment,
        };
    }

    modelCustomAttributes() {
        return {
            filters: {},
        };
    }
}
