<template>
    <div>
        <transition-group
            :name="transition"
            tag="div"
        >
            <div
                v-for="(item, index) in notifications"
                :key="index"
                :class="item.typeObject"
                role="alert"
            >
                <span
                    v-if="item.important"
                    class="alert-close"
                    @click="hide(item)"
                >x</span>
                <span
                    v-if="displayIcons"
                    class="alert-icon"
                >
                    <img
                        v-if="item.type === 'error'"
                        class="pr-3"
                        src="../assets/images/notifications/error.svg"
                        alt=""
                    >
                    <img
                        v-if="item.type === 'success'"
                        class="pr-3"
                        src="../assets/images/notifications/success.svg"
                        alt=""
                    >
                    <img
                        v-if="item.type === 'info'|| item.type === null"
                        class="pr-3"
                        src="../assets/images/notifications/info.svg"
                        alt=""
                    >
                </span>
                <span v-dompurify-html="item.message" />
            </div>
        </transition-group>
    </div>
</template>
<script>
export default {
    name: 'Flash',
    props: {
        messages: {},
        timeout: {
            type: Number,
            default: 5000,
        },
        transition: {
            type: String,
            default: 'slide-fade',
        },
        types: {
            type: Object,
            default: () => ({
                base: 'alert',
                success: 'alert-success',
                error: 'alert-danger',
                info: 'alert-info',
            }),
        },
        displayIcons: {
            type: Boolean,
            default: true,
        },
        icons: {
            type: Object,
            default: () => ({
                base: '',
                error: 'error',
                success: 'success',
                info: 'info',
            }),
        },
    },

    data: () => ({
        notifications: [],
    }),

    /**
     * On creation Flash a message if a message exists otherwise listen for
     * flash event from global event bus
     */
    created() {
        window.events.$on('flash', (message, type, important) => this.flash(message, type, important));
    },

    methods: {
        /**
         * Flash our alert to the screen for the user to see
         * and begin the process to hide it
         *
         * @param message
         * @param type
         * @param important
         */
        flash(message, type, important) {
            this.notifications.push({
                message,
                type: type || 'info',
                important: important || false,
                typeObject: this.classes(this.types, type),
                iconObject: this.classes(this.icons, type),
            });
            if (!important) {
                setTimeout(this.hide, this.timeout);
            }
        },

        /**
         * Sets and returns the values needed
         *
         * @param type
         */
        classes(propObject, type) {
            const classes = {};

            if (Object.prototype.hasOwnProperty.call(propObject, 'base')) {
                classes[propObject.base] = true;
            }
            if (Object.prototype.hasOwnProperty.call(propObject, type)) {
                classes[propObject[type]] = true;
            }

            return classes;
        },

        /**
         * Hide Our Alert
         *
         * @param item
         */
        hide(item = this.notifications[0]) {
            const key = this.notifications.indexOf(item);
            this.notifications.splice(key, 1);
        },
    },
};
</script>
<style scoped>

/**
 * Fade transition styles
 */
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
    opacity: 0
}

/**
 * Bounce transition styles
 */
.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.alert {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    position: relative;
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 9999;
    margin-bottom: 5px;
}

.alert {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    position: relative;
    box-sizing: border-box;
    border-radius: 4px;
    z-index: 9999;
    margin-bottom: 5px;
}

.alert .alert-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    flex-shrink: 0;
}

.alert.alert-danger {
    background: #FFEFEF;
    border: 1px solid #FF5656;
}

.alert.alert-success {
    background: #E6F7F4;
    border: 1px solid #58E2C2;
}

.alert.alert-info {
    background: #EFF0F9;
    border: 1px solid #9FA2B5;
}

.alert .alert-close {
    position: absolute;
    top: -2px;
    right: 3px;
    cursor: pointer;
    font-size: 1.25rem;
}

/**
 * Slide transition styles
 */
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for <2.1.8 */
{
    transform: translateX(10px);
    opacity: 0;
}

</style>
