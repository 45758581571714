<template>
    <HtModal ref="modal">
        <template #header>
            {{ roleName }}
        </template>
        <template #default>
            <SearchInputAjax
                :endpoint="'foc/search-users'"
                :params="{ role_id: roleId, is_guest: isGuestRole, with_delegations: true}"
                :initial-results="suggestion"
                :absolute-results="false"
                @item-selection="onSelectUser"
            />
        </template>
        <template #footer-actions>
            <div v-if="showInvite">
                <Button
                    class="modal-actions right"
                    cypress="button-invite-user"
                    @click="openInviteUser"
                >
                    <t>Invite a new user</t>
                </Button>
                <Modalable
                    ref="modalableInviteUser"
                    class="modalable-1 medium"
                    :mode="2"
                >
                    <InviteUser
                        :is-guest-role="isGuestRole"
                        @onCreate="onUserCreate"
                    />
                </Modalable>
            </div>
        </template>
    </HtModal>
</template>
<script>
import SearchInputAjax from '@/components/miscellaneous/SearchInput/SearchInputAjax.vue';
import InviteUser from '@/components/InviteUser.vue';
import HtModal from '../HtModal.vue';

export default {
    components: { HtModal, SearchInputAjax, InviteUser },

    props: {
        roleName: {
            type: String,
            required: true,
        },
        roleId: {
            type: Number,
            required: true,
        },
        isGuestRole: {
            type: Boolean,
            required: true,
        },
        suggestion: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        showInvite() {
            return this.$canRead('AbstractManageFocRoles');
        },
    },

    methods: {
        open() {
            this.$refs.modal.open();
        },

        close() {
            this.$refs.modal.close();
        },

        /**
         * @param {{item: any}} user
         */
        onSelectUser(user) {
            this.$emit('user-selected', user.item);
        },

        openInviteUser() {
            this.$refs.modalableInviteUser.open();
        },

        onUserCreate(user) {
            this.$refs.modalableInviteUser.close();
            this.$emit('user-invited', user);
        },
    },
};
</script>
