<template>
    <div class="wrapper">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'RoundEmptyBackground',
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.wrapper {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: $grey-2 solid 1px;
    background-color: $white;
    width: 34px;
    height: 34px;
}
</style>
