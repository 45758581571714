<template>
    <HtTabs
        @onChangeTab="onChangeTab"
    >
        <template #headerRightContent>
            <slot name="headerRightContent">
                <HtIconClose @click="$emit('on-close-stepper')" />
            </slot>
        </template>

        <HtTab
            v-for="(tab, index) in getAvailableTabs"
            :key="index"
            :ref="tab.ref"
            :title="tab.title"
            :disabled="tab.disabled"
            :no-body="tab.noBody"
        >
            <component
                :is="tab.component"
                ref="currentPage"
                :item="item"
                @update:item="$emit('update:item', $event)"
                @goTo="goTo"
                @onSave="onSave"
                @onDelete="$emit('onDelete')"
            />
        </HtTab>

        <template v-if="hasFooterSlot" #footer>
            <slot name="footer" />
        </template>
    </HtTabs>
</template>

<script>
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import HidedTabsMixin from '@/components/mixins/HidedTabsMixin';
import showButtonActionMixin from '@/components/mixins/showButtonActionMixin';
import HtTabs from '@/components/globals/HtTabs.vue';
import HtTab from '@/components/globals/HtTab.vue';
import HtIconClose from '@/components/icons/HtIconClose.vue';
import stepperFormEventBus from '@/eventBus/stepperFormEventBus';

export default {
    name: 'HtTabStepper',
    components: { HtTabs, HtTab, HtIconClose },
    mixins: [
        HidedTabsMixin,
        DefaultFiltersMixin,
        DefaultOrderCompletionMixin,
        showButtonActionMixin,
    ],

    props: {
        item: {
            type: Object,
            required: true,
        },
        tabs: {
            type: Array,
            required: true,
        },
        showListOnEnd: {
            type: Boolean,
            default: true,
        },
    },

    created() {
        stepperFormEventBus.$on('toggle-tab', this.toggleTab);
        stepperFormEventBus.$on('show-tab', this.showTab);
        stepperFormEventBus.$on('hide-tab', this.hideTab);
    },

    destroyed() {
        stepperFormEventBus.$off('toggle-tab', this.toggleTab);
        stepperFormEventBus.$off('show-tab', this.showTab);
        stepperFormEventBus.$off('hide-tab', this.hideTab);
    },

    computed: {
        hasFooterSlot() {
            return 'footer' in this.$slots;
        },
    },

    methods: {
        /**
         * @returns {void}
         */
        onChangeTab(resolve) {
            resolve();
        },

        /**
         * @returns {void}
         */
        updateTab(ref, setActive) {
            const tab = this.tabs.find((t) => t.ref === ref);

            if (tab) {
                tab.disabled = false;

                if (setActive) {
                    this.$nextTick(() => {
                        this.$refs[ref][0].setActive();
                    });
                }
            }
        },

        toggleTab(ref) {
            const tab = this.tabs.find((t) => t.ref === ref);

            if (tab) {
                if ('hidden' in tab === false) {
                    this.$set(tab, 'hidden', true);
                } else {
                    tab.hidden = !tab.hidden;
                }
            }
        },
        showTab(ref) {
            const tab = this.tabs.find((t) => t.ref === ref);

            if (tab) {
                this.$set(tab, 'hidden', false);
            }
        },
        hideTab(ref) {
            const tab = this.tabs.find((t) => t.ref === ref);

            if (tab) {
                this.$set(tab, 'hidden', true);
            }
        },

        /**
         * @returns {void}
         */
        goTo(ref) {
            if (typeof this.$refs.currentPage[0].onCheck === 'function') {
                this.$refs.currentPage[0].onCheck().then(() => {
                    this.updateTab(ref, true);
                }).catch(() => {});
            } else {
                this.updateTab(ref, true);
            }
        },

        onSave(...args) {
            this.$emit('onSave', ...args);
        },
    },
};
</script>
