<template>
    <HtFormSelector
        :id="id"
        :value="value"
        :label="label"
        :description="description"
        :placeholder="placeholder"
        :name="name"
        :options="options"
        :is-single="isSingle"
        :specific-key="specificKey"
        :allow-empty="allowEmpty"
        :label-options="labelOptions"
        :track-by="trackBy"
        :max-height="maxHeight"
        :open-direction="openDirection"
        :disabled="disabled"
        :cypress="cypress"
        @input="onUpdateValue"
    >
        <template #image="{ option }">
            <span class="country-wrapper">
                <span class="country">
                    <country-flag
                        :country="option.codeFlag"
                    />
                </span>
            </span>
        </template>
    </HtFormSelector>
</template>

<script>
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import HtFormMixin from '@/components/globals/HtFormMixin';
import HtFormSelectorMixin from '@/components/globals/Selectors/HtFormSelectorMixin';

export default {
    components: {
        HtFormSelector,
    },
    mixins: [
        HtFormMixin,
        HtFormSelectorMixin,
    ],
    props: {
        // Used for showing specific label in object of options
        labelOptions: {
            type: String,
            default: 'name',
        },
    },
};
</script>
<style lang="scss" scoped>
.ht-form-selector{

    ::v-deep .multiselect__option, .multiselect__single {
        .country-wrapper {
            display: flex;
            align-items: center;
            margin-right: 8px;
            width: 16px;

                .country {
                transform: scale(0.5);
                -ms-transform: scale(0.5);
                -webkit-transform: scale(0.5);
                -moz-transform: scale(0.5);
                height: 12px;
                width: 16px;
                margin-top: -10px;
                margin-left: -4px;
            }
        }
    }
    ::v-deep .multiselect__tags-wrap{
        .country-wrapper {
            display: flex;
            align-items: center;
            border-radius: 100%;
            width: 16px;
            height: 16px;
            overflow: hidden;

            .country {
                transform: scale(0.6, 0.8);
                -ms-transform: scale(0.6, 0.8);
                -webkit-transform: scale(0.6, 0.8);
                -moz-transform: scale(0.6, 0.8);
                margin-left: -8px;
            }
        }
    }
}
</style>
