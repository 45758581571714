import Model from '@tony.caron/node-model-proxy/Model';
import CompanySurveyContentCollection from './CompanySurveyContentCollection';
import CompanySurveyQuestion from './CompanySurveyQuestion';
import CompanySurveyExtra from './CompanySurveyExtra';
import CompanySurvey from './CompanySurvey';
import Company from './Company';

export default class CompanySurveyContent extends Model {
    static RESOURCE_EXTRA = 'company_survey_extra';

    static TYPE_EXTRA = 'extra';

    static RESOURCE_QUESTION = 'company_survey_question';

    static TYPE_QUESTION = 'question';

    static ORDER_END_SURVEY = 10000;

    modelConfig() {
        return {
            collection: CompanySurveyContentCollection,
        };
    }

    modelDefaultValues() {
        return {
            next_content_order: null,
        };
    }

    modelCustomAttributes() {
        return {
            uniqueID: null,
            uniqueIDCache: null,
        };
    }

    modelAccessors() {
        return {
            uniqueID: () => {
                if (this.uniqueIDCache) {
                    return this.uniqueIDCache;
                }
                if (this.id) {
                    this.uniqueIDCache = this.id;
                    return this.id;
                }
                this.uniqueIDCache = Symbol('CompanySurveyContentUniqueId');
                return this.uniqueIDCache;
            },
        };
    }

    modelRelations() {
        return {
            company: () => this.belongsTo(Company, 'company_id', 'id'),
            companySurvey: () => this.belongsTo(CompanySurvey, 'company_survey_id', 'id'),
            companySurveyQuestion: () => this.belongsTo(CompanySurveyQuestion, 'resource_id', 'id'),
            companySurveyExtra: () => this.belongsTo(CompanySurveyExtra, 'resource_id', 'id'),

        };
    }
}
