<template>
    <HtReadResources
        v-if="survey"
        :resource="survey"
        :resource-type="resourceType"
        label-category="Survey"
        :has-sender="false"
        :has-recipient="true"
    >
        <HtReadResourcesInfo
            label="Participants"
        >
            {{ nbOfTargetUsers }}
        </HtReadResourcesInfo>
    </HtReadResources>
</template>

<script>

import HtReadResources from '@/components/globals/modals/HtReadResources/HtReadResources.vue';
import HtReadResourcesInfo from '@/components/globals/modals/HtReadResources/HtReadResourcesInfo.vue';

export default {
    name: 'SurveyRead',
    components: { HtReadResources, HtReadResourcesInfo },
    props: {
        resourceType: {
            type: String,
            required: true,
        },
        resourceId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            survey: null,
            nbOfTargetUsers: '',
        };
    },

    async created() {
        const requestSurvey = await this.$http.get(`/configuration/surveys/${this.resourceId}`);
        this.survey = requestSurvey.data.data.resource;

        const requestSurveyCount = await this.$http.post(`/configuration/surveys/${this.resourceId}/count`, {
            filters: this.survey.programs[0].filters,
            participants: this.survey.programs[0].participants,
            include_already_registered_users: true,
            company_program_id: this.survey.programs[0].program.id,
        });
        this.nbOfTargetUsers = requestSurveyCount.data.data;
    },
};
</script>
