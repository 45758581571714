<template>
    <div
        class="task-wrapper"
        :class="{minimalLayout}"
    >
        <div
            class="task-tile"
            :class="{'is-dependent': isDependent}"
            @click="onClickTask"
        >
            <div
                v-if="file && !minimalLayout"
                @click.stop
            >
                <div
                    v-if="isVideo(file.detected_mime_type)"
                    class="task-video-wrapper"
                >
                    <video
                        :key="`video-${task.id}`"
                        controls
                        controlsList="nodownload"
                        width="100%"
                        :src="file.link"
                        @click.stop
                    />
                </div>
                <div
                    v-else-if="canPreview(file.detected_mime_type)"
                    class="task-file-preview-wrapper"
                    @click.stop="togglePreviewer"
                >
                    <img
                        v-if="isImage(file.detected_mime_type)"
                        :src="file.link_preview"
                    >
                    <iframe
                        v-else
                        :src="file.link_preview"
                        frameborder="0"
                    />
                </div>
                <FilePreviewer
                    ref="file-previewer"
                    :files="[file]"
                />
            </div>
            <div
                v-else-if="isYoutube && !minimalLayout"
                class="task-video-wrapper"
            >
                <iframe
                    :src="$Utils.embedVideo(url)"
                    frameborder="0"
                    allow="fullscreen"
                />
            </div>
            <div class="task-header">
                <div class="task-header-left">
                    <HtFormCheckbox
                        v-if="selectMode && isSelectable(task)"
                        :id="`checkbox-${task.id}`"
                        :name="`checkbox-${task.id}`"
                        :checked="task.selected"
                        :partially-checked="task.partiallySelected"
                    />
                    <HtResourceTag
                        :resource="resourcesInfo[task.resource].name"
                        :label="minimalLayout ? '' : resourcesInfo[task.resource].label"
                    />
                    <HtEventToPlanTag
                        v-if="isEventToPlan(task)"
                        :label="!minimalLayout"
                    />
                </div>
                <div
                    v-if="!isGroupedTask"
                    class="task-header-right"
                    :class="{'late': isLate}"
                    @click="toggleValidation(task, $event)"
                >
                    <div
                        v-if="isEventNotToPlan(task)"
                        class="event-time"
                    >
                        {{ task.planning_event.time_start }} - {{ task.planning_event.time_end }}
                    </div>
                    <div>
                        {{ getFormattedDate(task.datetime_end) }}
                    </div>
                    <HtTaskStatus
                        v-if="isDependent"
                        status="unavailable"
                    />
                    <HtTaskStatus
                        v-else-if="isEventNotToPlan(task)"
                        status="disabled"
                    />
                    <HtTaskStatus
                        v-else-if="(!selectMode || !isSelectable(task)) && statusTasks[task.id]"
                        :status="statusTasks[task.id]"
                        :is-late="isLate"
                        with-animation
                    />
                </div>
                <div
                    v-else
                    class="task-header-right"
                >
                    {{ countTasksDone }} / {{ task.tasks.length }}
                </div>
            </div>
            <div class="task-content">
                <div class="task-title">
                    {{ title }}
                </div>
                <a
                    v-if="!minimalLayout
                        &&file
                        && !canPreview(file.detected_mime_type)
                        && !isVideo(file.detected_mime_type)"
                    class="task-file"
                    :href="file.link"
                    @click.stop
                >
                    <FontAwesomeIcon
                        :icon="['fal', 'file']"
                        class="file-icon"
                    />
                    <span>{{ file.original_name }}</span>
                </a>
            </div>
            <div
                v-if="task.participants || !hideProgram || isGroupedTask"
                class="task-footer"
            >
                <div class="task-program">
                    <div
                        v-if="task.participants
                            && task.participants.length > 1"
                        class="task-participants"
                    >
                        <ParticipantList :users="task.participants" />
                    </div>
                    <template v-if="!hideProgram">
                        {{ task.user_program.program.name }}
                        <template v-if="!isYourself && !minimalLayout">
                            {{ task.user.firstname }} {{ task.user.lastname }}
                        </template>
                    </template>
                </div>
                <div
                    v-if="isGroupedTask"
                    class="task-show-sub-tasks"
                >
                    <HtButton
                        type="tertiary"
                        size="small"
                        @click.native.stop="toggleShowSubTasks"
                    >
                        <t v-if="!showSubTasks">
                            Show List
                        </t>
                        <t v-else>
                            Hide List
                        </t>
                        <FontAwesomeIcon
                            icon="chevron-down"
                            class="chevron"
                            :class="{ up: showSubTasks }"
                        />
                    </HtButton>
                </div>
            </div>

            <transition name="fade-height">
                <div
                    v-if="isGroupedTask && showSubTasks"
                    class="task-grouped-tasks"
                    @click.stop
                >
                    <div
                        v-for="subTask in task.tasks"
                        :key="`subtask-${subTask.id}`"
                        class="task-sub-task"
                        @click="onClickSubTask(subTask)"
                    >
                        <div class="task-header-left">
                            <HtFormCheckbox
                                v-if="selectMode && isSelectable(task)"
                                :id="`checkbox-${task.id}-${subTask.id}`"
                                :name="`checkbox-${task.id}-${subTask.id}`"
                                :checked="subTask.selected"
                            />
                            <div v-if="subTask.resource === 'company_requirement_category'">
                                {{ subTask.full_title }}
                            </div>
                            <div v-else>
                                {{ subTask.title }}
                            </div>
                        </div>
                        <div
                            class="task-header-right"
                            @click="toggleValidation(subTask, $event)"
                        >
                            <div>
                                {{ getFormattedDate(subTask.datetime_end) }}
                            </div>
                            <HtTaskStatus
                                v-if="(!selectMode || !isSelectable(subTask))
                                    && statusTasks[subTask.id]"
                                :status="statusTasks[subTask.id]"
                                :is-late="isLate"
                                with-animation
                            />
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div
            v-if="task.dependenciesTasks && task.dependenciesTasks.length"
            class="task-dependent-tasks"
        >
            <div
                v-for="dependentTask in task.dependenciesTasks"
                :key="`dependent-task-${dependentTask.id}`"
                class="task-dependent-task"
            >
                <FontAwesomeIcon
                    :icon="['fal', 'level-up']"
                    class="dependent-icon"
                />
                <div class="task-dependent-task-wrapper">
                    <div class="task-dependent-task-title">
                        <HtResourceTag
                            :resource="resourcesInfo[dependentTask.resource].name"
                        />
                        <HtEventToPlanTag
                            v-if="isEventToPlan(dependentTask)"
                            :label="!minimalLayout"
                        />
                        {{ dependentTask.title }}
                    </div>
                    <div class="task-header-right">
                        <HtTaskStatus status="unavailable" />
                    </div>
                </div>
            </div>
        </div>
        <UserWrapperModal
            ref="userWrapperModal"
            :size="resourcesInfo[task.resource].modalSize"
            is-fill
            :is-edit="isEditModal"
            @onUpdate="onUpdate"
            @onDelete="onDelete"
        />
    </div>
</template>

<script>
import HtFormCheckbox from '@/components/globals/Checkbox/HtFormCheckbox.vue';
import FilePreviewer from '@/components/globals/FilePreviewer/FilePreviewer.vue';
import HtButton from '@/components/globals/HtButton.vue';
import ParticipantList from '@/components/globals/ParticipantList.vue';
import HtResourceTag from '@/components/globals/Tag/HtResourceTag.vue';
import UserWrapperModal from '@/components/miscellaneous/UserWrapperModal.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import filePreviewerMixin from '@/components/globals/FilePreviewer/filePreviewerMixin';
import HtEventToPlanTag from '@/components/globals/Tag/HtEventToPlanTag.vue';
import CompanyUser from '@/models/CompanyUser';
import HtTaskStatus from './HtTaskStatus.vue';

export default {
    name: 'ListTasksItem',
    components: {
        HtResourceTag,
        HtButton,
        HtTaskStatus,
        UserWrapperModal,
        ParticipantList,
        FilePreviewer,
        HtFormCheckbox,
        HtEventToPlanTag,
    },
    mixins: [filePreviewerMixin],
    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },
    props: {
        task: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        selectMode: {
            type: Boolean,
            required: false,
            default: false,
        },
        minimalLayout: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideProgram: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            statusTasks: {},
            openModalTaskId: null,
            resourcesInfo: {
                custom: {
                    label: 'Action',
                    name: 'company_program_task',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: false,
                    isSelectable: () => true,
                },
                company_survey: {
                    label: 'Survey',
                    name: 'company_survey',
                    modalSize: 'medium',
                    validateOnClick: false,
                    useFullTitle: false,
                    isSelectable: () => false,
                },
                company_user_quiz: {
                    label: 'Quiz',
                    name: 'company_quiz',
                    modalSize: 'large',
                    validateOnClick: false,
                    useFullTitle: false,
                    isSelectable: () => true,
                },
                company_nudge: {
                    label: 'Nudge',
                    name: 'company_nudge',
                    modalSize: 'medium',
                    validateOnClick: true,
                    useFullTitle: false,
                    isSelectable: () => true,
                },
                company_doc: {
                    label: 'Document',
                    name: 'company_shared_document',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: true,
                    isSelectable: () => true,
                },
                company_document: {
                    label: 'Training',
                    name: 'company_document',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: false,
                    isSelectable: () => true,
                },
                company_planning: {
                    label: 'Event',
                    name: 'company_planning',
                    modalSize: 'small',
                    validateOnClick: false,
                    useFullTitle: false,
                    isSelectable: () => true,
                },
                company_email_custom: {
                    label: 'Email',
                    name: 'company_email_custom',
                    modalSize: 'medium',
                    validateOnClick: false,
                    useFullTitle: false,
                    isSelectable: () => true,
                },
                company_equipment: {
                    title: this.translate('Install the following equipment | Install the following equipments', { count: this.task.tasks?.length }),
                    label: 'Equipment',
                    name: 'company_equipment',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: true,
                    isSelectable: () => true,
                },
                company_software: {
                    title: this.translate('Install the following software | Install the following softwares', { count: this.task.tasks?.length }),
                    label: 'Software',
                    name: 'company_software',
                    modalSize: 'small',
                    validateOnClick: true,
                    useFullTitle: true,
                    isSelectable: (task) => task.software?.type === 'company_software_custom_http',
                },
                company_requirement_category: {
                    title: this.translate('Administrative file to be completed | Administrative files to be completed', { count: this.task.tasks?.length }),
                    label: 'Requirement',
                    name: 'company_requirement_category',
                    modalSize: 'small',
                    validateOnClick: false,
                    useFullTitle: true,
                    isSelectable: () => false,
                },
            },
            showSubTasks: false,
        };
    },
    computed: {
        title() {
            if (this.isGroupedTask) {
                return this.resourcesInfo[this.task.resource].title;
            }
            return this.resourcesInfo[this.task.resource].useFullTitle
                ? this.task.full_title : this.task.title;
        },
        isGroupedTask() {
            return !!this.task.tasks?.length;
        },
        isYourself() {
            return this.shared.session.companyUser.id === this.task.user.id;
        },
        isYoutube() {
            if (this.task.training?.url) {
                return this.$Utils.isYoutubeUrl(this.task.training.url);
            }
            return false;
        },
        isLate() {
            return this.task.status === CompanyUserProgramTask.STATUS_PENDING
                && this.$Utils.moment(this.task.datetime_end) <= this.$Utils.moment();
        },
        file() {
            if (this.task.training) {
                return this.task.training.file;
            }
            if (this.task.document) {
                return this.task.document.file;
            }
            return undefined;
        },
        url() {
            if (this.task.training) {
                return this.task.training.url;
            }
            return undefined;
        },
        countTasksDone() {
            if (this.isGroupedTask) {
                return this.task.tasks
                    .filter((oneTask) => oneTask.status === CompanyUserProgramTask.STATUS_DONE)
                    .length;
            }
            return 0;
        },
        isDependent() {
            return this.task.dependent_status
                && this.task.dependent_status !== CompanyUserProgramTask.STATUS_DONE;
        },
        isEditModal() {
            return this.task.resource === 'company_requirement_category';
        },
    },
    watch: {
        task() {
            this.setStatusTask();
        },
    },
    mounted() {
        this.setStatusTask();
    },
    methods: {
        setStatusTask() {
            if (this.isGroupedTask) {
                for (let i = 0; i < this.task.tasks.length; i += 1) {
                    this.$set(this.statusTasks, this.task.tasks[i].id, this.task.tasks[i].status);
                }
            } else {
                this.$set(this.statusTasks, this.task.id, this.task.status);
            }
        },
        onClickTask() {
            if (this.selectMode && this.isSelectable(this.task)) {
                const selectedTasks = this.isGroupedTask
                    ? this.task.tasks.filter(
                        (oneTask) => this.resourcesInfo[oneTask.resource].isSelectable(oneTask),
                    )
                        .map((oneTask) => oneTask.id)
                    : [this.task.id];
                this.$emit('select', selectedTasks);
            } else if (this.isGroupedTask) {
                this.toggleShowSubTasks();
            } else if (!this.isDependent) {
                this.openTaskModal(this.task);
            }
        },
        onClickSubTask(subTask) {
            if (this.selectMode) {
                if (this.isSelectable(subTask)) {
                    this.$emit('select', [subTask.id]);
                }
            } else {
                this.openTaskModal(subTask);
            }
        },
        openTaskModal(task) {
            if (task.resource === 'company_requirement_category' && task.requirement_category.display_survey) {
                return;
            }

            this.openModalTaskId = task.id;
            this.$refs.userWrapperModal.open({
                id: task.id,
                company_user_id: task.user.id,
                company_user_program_id: task.user_program.id,
                resource: task.resource,
                resource_id: task.resource_id,
            });
        },
        togglePreviewer() {
            this.$refs['file-previewer'].togglePreviewer();
        },
        toggleShowSubTasks() {
            this.showSubTasks = !this.showSubTasks;
        },
        isSelectable(task) {
            if (task.tasks) {
                return task.tasks.some((subTask) => this.resourcesInfo[subTask.resource]
                    .isSelectable(subTask));
            }
            return this.resourcesInfo[task.resource].isSelectable(task);
        },
        getFormattedDate(date) {
            return this.$Utils.momentLocalized(date, this.shared.session.companyUser.company_language.key).format('DD MMM');
        },
        toggleValidation(task, $event) {
            if (this.resourcesInfo[task.resource].validateOnClick
                && task.validator_type === 'human'
                && !this.isDependent
            ) {
                const newStatus = task.status === CompanyUserProgramTask.STATUS_DONE
                    ? CompanyUserProgramTask.STATUS_PENDING
                    : CompanyUserProgramTask.STATUS_DONE;
                if (newStatus !== this.statusTasks[task.id]) {
                    this.$set(this.statusTasks, task.id, newStatus);
                    this.$handleValidationTasks({ id: task.id, status: newStatus })
                        .then(() => {
                            this.$emit('updateStatus', { taskId: task.id, status: newStatus });
                        })
                        .catch(() => {
                            this.$set(this.statusTasks, task.id, task.status);
                        });
                }
                $event.stopPropagation();
            }
        },
        onUpdate() {
            this.$emit('update', this.openModalTaskId);
        },

        onDelete() {
            this.$emit('delete', this.openModalTaskId);
        },
        isEventNotToPlan(task) {
            return task.planning_event && !task.planning_event?.is_delegated;
        },
        isEventToPlan(task) {
            return task.planning_event?.is_delegated;
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
.task-tile{
    padding: 16px;
    border-radius: 16px;
    border: 1px solid $neutral-300;
    background: $neutral-white;
    font-size: 14px;
    line-height: 140%;
    cursor: pointer;

    &.is-dependent {
        cursor: auto;
    }

    &:hover {
        box-shadow: 0px 4px 6px rgba(153, 153, 153, 0.14);
    }

    .task-file-preview-wrapper, .task-video-wrapper{
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
    }

    .task-file-preview-wrapper {
        max-height: 380px;
    }
    .task-video-wrapper{
        > video {
            width: 100%;
            max-height: 380px;
            object-fit: cover;
        }
    }

    .task-header {
        display: flex;
        justify-content: space-between;

        .event-time{
            font-size: 13px;
            padding: 4px;
            color: $neutral-500;
            background-color: $neutral-200;
            border-radius: 4px;
            line-height: 90%;
            margin-right: 8px;
        }
    }
    .task-content {
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .task-file{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: fit-content;
            background-color: $neutral-200;
            border-radius: 6px;
            gap: 8px;
            padding: 8px;
            line-height: 100%;
            text-decoration: underline;
            color: $neutral-black;
        }
    }
    .task-footer{
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        font-size: 12px;
        margin-top: 16px;

        .task-program{
            color: $neutral-500;
            display: flex;
            align-items: center;
            gap: 4px;

            .task-participants {
                padding-right: 10px;
            }
        }

        .task-show-sub-tasks {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            font-weight: 700;
            flex-grow: 1;
            .chevron {
                width: 8px;
                margin-left: 8px;
                transition: 0.3s ease-in-out;

                &.up {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .task-grouped-tasks {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 16px;
        .task-sub-task{
            background: $neutral-100;
            border: 1px solid $neutral-300;
            border-radius: 8px;
            padding: 8px;
            display: flex;
            justify-content: space-between;
            align-content: center;
            .task-sub-task-header-right{
                display: flex;
                align-content: center;
                gap: 4px;
                font-size: 12px;
                color: $neutral-500;
            }
        }
    }
}

.task-dependent-tasks {
    margin-top: 8px;
    display:flex;
    flex-direction: column;
    gap: 4px;

    .task-dependent-task{
        display: flex;
        justify-content: center;
        align-items: center;
        .dependent-icon {
            color: $neutral-300;
            transform: rotate(90deg);
            height: 16px;
            width: 16px;
            margin: 4px;
        }
        .task-dependent-task-wrapper {
            background-color: $neutral-100;
            border-radius: 8px;
            border: 1px solid $neutral-300;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            flex-grow: 1;

            .task-dependent-task-title{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                font-size: 12px;
            }
            .task-dependent-task-header-right {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 4px;
                font-size: 12px;
                color: $neutral-500;
            }
        }
    }
}

.task-header-left {
    display: flex;
    align-items: center;
    gap: 8px;
}
.task-header-right {
    display: flex;
    align-items: center;
    color: $neutral-500;
    font-size: 12px;
    gap: 4px;

    &.late {
        color: $semantic-error;
    }
}
.task-wrapper {
    .fade-height-enter-active,
    .fade-height-leave-active {
        transition: 0.3s ease-in-out;
        max-height: 1500px;
        overflow: hidden;
        opacity: 1;
    }
    .fade-height-enter,
    .fade-height-leave-to {
        max-height: 0;
        opacity: 0;
    }
}
.minimalLayout{
    .task-tile{
        padding: 8px;
        border-radius: 8px;
    }
    .task-footer {
        margin-top: 4px;
    }
}

</style>
