<template>
    <div class="planning-event-template-list">
        <div
            v-if="!filterFromModel.length > 0"
            class="filter-tag filter-tag-all-filters"
        >
            {{ translate("All filters") }}
        </div>
        <div
            v-for="(filter, index) in filterFromModel.slice(0, 2)"
            v-else
            :key="index"
            class="filter-tag"
        >
            {{ filter.name_translated || filter.name }}
        </div>
        <div
            v-if="filterFromModel.length > 2"
            class="planning-event-template-list-other-filter"
        >
            <div
                class="filter-tag filter-tag-plus"
                @mouseenter="trigger(true)"
                @mouseleave="trigger(false)"
            >
                +{{ filterFromModel.length - 2 }}
            </div>
            <div
                v-if="filterFromModel"
                v-show="showFilter"
                class="planning-event-template-list-other-filter-tooltip"
                @mouseover="trigger(true)"
                @mouseleave="trigger(false)"
            >
                <div class="planning-event-template-list-other-filter-tooltip-content">
                    <span
                        v-for="(filter, index) in filterFromModel"
                        :key="index"
                        class="filter-tag mr-3"
                    >
                        {{ filter.name_translated || filter.name }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ResourceFilter',
    props: {
        resourceFilters: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            showFilter: false,
            timeoutForHideFilterPopin: null,
        };
    },

    computed: {
        filterFromModel() {
            return this.resourceFilters.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                } if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        },
    },

    methods: {
        trigger(showTooltip = true) {
            if (showTooltip) {
                clearTimeout(this.timeoutForHideFilterPopin);
                this.showFilter = true;
            } else {
                clearTimeout(this.timeoutForHideFilterPopin);
                this.timeoutForHideFilterPopin = setTimeout(() => { this.showFilter = false; }, 500);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.planning-event-template-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -0.7rem;
    position: relative;
    .filter-tag:not(.filter-tag-plus):not(.filter-tag-all-filters):not(:last-child) {
        &::after {
            content: '|';
            opacity: 0.4;
            margin: 0 0.25rem;
        }
    }
    &-other-filter {
        &-tooltip {
            background: #FFFFFF;
            border: 0.1rem solid #E5E6E6;
            border-radius: 0.5rem;
            box-shadow: 0rem 0.4rem 0.4rem rgba(74, 74, 74, 0.05), 0rem 0.4rem 2.4rem rgba(74, 74, 74, 0.08);
            padding: 1.6rem 2rem 2.3rem 2.3rem;
            text-align: center;
            z-index: 10;
            max-width: 40rem;
            max-height: 20rem;
            margin-left: -15rem;
            margin-top: 0.7rem;
            overflow-x: hidden;
            overflow-y: hidden;
            position: absolute;

            &-content {
                display: flex;
                flex-wrap: wrap;
            }

            .filter-tag {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

</style>
