<template>
    <div>
        <HtToast
            :title="translate('Advanced formats')"
            :message="translate('These parameters will be applied when the final document is generated (e.g. for signature availability, PDF creation...)')"
            :closable="true"
            class="mb-5"
            type="info"
        />
        <HtFormCard>
            <section>
                <h5 class="mb-5">
                    <t>Printing margins</t>
                </h5>

                <HtFormInput
                    id="margin-top"
                    v-model="formData.margin_top"
                    :label="translate('Margin top')"
                    name="marginTop"
                    :show-optional="false"
                    type="number"
                    :min="0"
                    class="input-number"
                    @input="handleInputChange('margin_top', $event)"
                >
                    <template
                        #append
                    >
                        <span class="unit">{{ defaultUnit }}</span>
                    </template>
                </HtFormInput>
                <HtFormInput
                    id="margin-bottom"
                    v-model="formData.margin_bottom"
                    :label="translate('Margin bottom')"
                    name="marginBottom"
                    :show-optional="false"
                    type="number"
                    :min="0"
                    class="input-number"
                    @input="handleInputChange('margin_bottom', $event)"
                >
                    <template
                        #append
                    >
                        <span class="unit">{{ defaultUnit }}</span>
                    </template>
                </HtFormInput>
                <HtFormInput
                    id="margin-left"
                    v-model="formData.margin_left"
                    :label="translate('Margin left')"
                    name="marginLeft"
                    :show-optional="false"
                    type="number"
                    :min="0"
                    class="input-number"
                    @input="handleInputChange('margin_left', $event)"
                >
                    <template
                        #append
                    >
                        <span class="unit">{{ defaultUnit }}</span>
                    </template>
                </HtFormInput>
                <HtFormInput
                    id="margin-right"
                    v-model="formData.margin_right"
                    :label="translate('Margin right')"
                    name="marginRight"
                    :show-optional="false"
                    type="number"
                    :min="0"
                    class="input-number"
                    @input="handleInputChange('margin_right', $event)"
                >
                    <template
                        #append
                    >
                        <span class="unit">{{ defaultUnit }}</span>
                    </template>
                </HtFormInput>
            </section>
        </HtFormCard>
    </div>
</template>

<script>
import HtToast from '@/components/globals/HtToast.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';

export default {
    name: 'DynamicDocumentSettingMargins',
    components: { HtToast, HtFormInput, HtFormSelect },
    props: {
        usedAdvancedSetting: {
            type: Object,
            default: () => ({
                margin_top: '1cm',
                margin_bottom: '1cm',
                margin_left: '1cm',
                margin_right: '1cm',
            }),
        },
    },
    data() {
        return {
            formData: {},
            inputTimers: {},
            defaultUnit: 'cm',
        };
    },
    async created() {
        const sanitizedAdvancedsettings = Object.keys(this.usedAdvancedSetting).reduce((acc, key) => {
            acc[key] = parseFloat(this.usedAdvancedSetting[key]);
            return acc;
        }, {});
        this.formData = {
            ...sanitizedAdvancedsettings,
        };
    },
    methods: {
        handleInputChange(field, value) {
            if (this.inputTimers[field]) {
                clearTimeout(this.inputTimers[field]);
            }

            this.inputTimers[field] = setTimeout(() => {
                this.formData[field] = value;

                this.$validator.validateAll().then((isValidMargins) => {
                    this.$emit('changeSettings', { settings: { [field]: `${this.formData[field]}${this.defaultUnit}` }, isValidMargins });
                });
            }, 500);
        },
    },
};
</script>

<style lang="scss" scoped>
.input-number {
    ::v-deep .ht-form-body {
        display: flex;
        align-items: baseline;
        .ht-form-input-wrapper {
            flex: 0 0 auto;
            width: 65px;
        }
        .unit {
            flex: 0 0 auto;
            margin-left: 8px;
        }
    }
}
</style>
