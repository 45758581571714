<template>
    <span>
        {{ formattedTimezoneName }}
    </span>
</template>

<script>
import timezoneMixin from '@/components/globals/timezone/timezoneMixin';

export default {
    name: 'TimezoneDisplay',
    mixins: [timezoneMixin],
    props: {
        timezone: {
            type: String,
            default() {
                return '';
            },
        },
    },
    computed: {
        formattedTimezoneName() {
            return this.formatTimezoneName(this.timezone);
        },
    },
};
</script>

<style scoped>

</style>
