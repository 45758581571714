import HtProgramType from '@/models/HtProgramType';

export default {
    data() {
        return {
            loading: false,

            form: {
                show_filters: false,
                filters: [],

                date_period: 'first-user',
                date_end: null,
                date_start: null,

                program_id: null,

                format: 'csv',
            },
        };
    },

    methods: {
        send(endpoint, form) {
            if (this.loading) return;
            this.loading = true;
            endpoint(form)
                .then((response) => {
                    window.location = response.data.link;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },

    computed: {
        buttonState() {
            return this.loading ? 'loading' : 'idle';
        },
        showFilters() {
            return this.form.show_filters;
        },

        showSpecificPeriod() {
            return this.form.date_period === 'specific-period';
        },

        types() {
            return [
                {
                    value: 'csv',
                    text: '.csv',
                },
                {
                    value: 'xlsx',
                    text: '.xlsx',
                },
            ];
        },

        dates() {
            return [
                {
                    id: 'first-user',
                    name: this.translate('From the first user'),
                    date_start: null,
                    date_end: null,
                },
                {
                    id: 'seven-days',
                    name: this.translate('The last 7 days'),
                    date_start: this.$Utils.moment().subtract(7, 'days').format('YYYY-MM-DD'),
                    date_end: null,
                },
                {
                    id: 'last-month',
                    name: this.translate('The last month'),
                    date_start: this.$Utils.moment().subtract(1, 'month').format('YYYY-MM-DD'),
                    date_end: null,
                },
                {
                    id: 'tree-months',
                    name: this.translate('The last 3 months'),
                    date_start: this.$Utils.moment().subtract(3, 'months').format('YYYY-MM-DD'),
                    date_end: null,
                },
                {
                    id: 'last-year',
                    name: this.translate('The last 12 months'),
                    date_start: this.$Utils.moment().subtract(1, 'year').format('YYYY-MM-DD'),
                    date_end: null,
                },
                {
                    id: 'specific-period',
                    name: this.translate('Select a specific period'),
                    date_start: this.$Utils.moment().format('YYYY-MM-DD'),
                    date_end: this.$Utils.moment().format('YYYY-MM-DD'),
                },
            ];
        },

        programs() {
            return this.shared.queries.companyProgramCollection.models
                .filter((program) => program.ht_program_type.slug !== HtProgramType.SLUG_DEFAULT)
                .map((program) => ({
                    id: program.id,
                    name: program.localize('name'),
                }));
        },
    },

    watch: {
        'form.date_period': function () {
            const period = this.dates.find((d) => d.id === this.form.date_period);

            this.form.date_end = period.date_end;
            this.form.date_start = period.date_start;
        },
        'form.show_filters': function () {
            if (this.form.show_filters === false) {
                this.form.filters = [];
            }
        },
        programs() {
            if (this.form.program_id === null) {
                this.form.program_id = this.programs[0].id;
            }
        },
    },

    created() {
        if (this.shared.queries.companyProgramCollection.isLoaded() === false) {
            this.shared.queries.companyProgramCollection.get();
        } else {
            this.form.program_id = this.programs[0].id;
        }
    },
};
