<template>
    <div class="question-history">
        <div
            v-for="choice in templateAnswers"
            v-if="answers.find((v) => parseInt(v.company_survey_question_answer_id, 10) === parseInt(choice.id, 10))"
            class="question-history-item"
            :class="{'smiley': isSmileySubType}"
        >
            <img
                v-if="isSmileySubType"
                :src="smileys[choice.order]"
            >
            <span v-else>{{ localize(choice.translations.text) }}</span>
        </div>
    </div>
</template>

<script>
import SurveyQuestionHistoryMixin from '@/components/resources/user/surveys/questions/SurveyQuestionHistoryMixin';

export default {
    name: 'SurveySatisfactionScaleHistory',

    mixins: [
        SurveyQuestionHistoryMixin,
    ],

    props: {
        isSmileySubType: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        smileys() {
            return {
                1: '/static/icons/survey/smiley/SmileyNotGood.svg',
                2: '/static/icons/survey/smiley/SmileyMedium.svg',
                3: '/static/icons/survey/smiley/SmileyGood.svg',
                4: '/static/icons/survey/smiley/SmileyVeryGood.svg',
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../SurveyQuestion';

.question-history {
    &-item.smiley {
        font-size: initial;
    }
}
</style>
