<template>
    <div>
        <component
            :is="componentResource"
            :company-program-id="companyProgramId"
            @on-open-add="onOpenAdd"
            @on-change-step="onChangeStep"
        />
    </div>
</template>
<script>
import Document from './list/Document.vue';
import Training from './list/Training.vue';
import Software from './list/Software.vue';
import Equipment from './list/Equipment.vue';
import Survey from './list/Survey.vue';
import Quiz from './list/Quiz.vue';
import Action from './list/Action.vue';
import EmailCustom from './list/EmailCustom.vue';
import Nudge from './list/Nudge.vue';
import PlanningEvent from './list/PlanningEvent.vue';
import RequirementCategory from './list/RequirementCategory.vue';

export default {
    name: 'ListResourceModal',
    components: {
        Action,
        Document,
        EmailCustom,
        Equipment,
        Nudge,
        PlanningEvent,
        Quiz,
        RequirementCategory,
        Software,
        Survey,
        Training,
    },
    props: {
        componentResource: {
            type: String,
            required: true,
        },
        companyProgramId: {
            type: Number,
            required: true,
        },
    },

    methods: {
        onOpenAdd(resourceModel, resourceCollection) {
            this.$emit('on-open-add', resourceModel, resourceCollection);
        },

        onChangeStep(step) {
            this.$emit('on-change-step', step);
        },
    },
};
</script>
