export default {
    data() {
        return {
            subject_variables: [
                { name: 'enrollee_firstname', label: 'Enrollee firstname' },
                { name: 'enrollee_lastname', label: 'Enrollee lastname' },
                { name: 'recipient_firstname', label: 'Recipient firstname' },
                { name: 'recipient_lastname', label: 'Recipient lastname' },
                { name: 'key_date', label: 'Key date' },
                { name: 'program_name', label: 'Program name' },
                { name: 'key_date_type', label: 'Key date type' },
                { name: 'site_address', label: 'Site address' },
                { name: 'site_name', label: 'Site name' },
                { name: 'department', label: 'Department' },
                { name: 'buddy_name', label: 'Buddy name' },
                { name: 'manager_name', label: 'Manager name' },
                { name: 'company_name', label: 'Company name' },
                { name: 'admin_responsible_name', label: 'Admin responsible name' },
                { name: 'job_position_name', label: 'Job position' },
                { name: 'contract_name', label: 'Contract' },
                { name: 'enrollee_birthday', label: 'Birthday' },
            ],
            content_variables: [
                { name: 'enrollee_firstname', label: 'Enrollee firstname' },
                { name: 'enrollee_lastname', label: 'Enrollee lastname' },
                { name: 'recipient_firstname', label: 'Recipient firstname' },
                { name: 'recipient_lastname', label: 'Recipient lastname' },
                { name: 'key_date', label: 'Key date' },
                { name: 'program_name', label: 'Program name' },
                { name: 'key_date_type', label: 'Key date type' },
                { name: 'site_address', label: 'Site address' },
                { name: 'site_name', label: 'Site name' },
                { name: 'department', label: 'Department' },
                { name: 'buddy_name', label: 'Buddy name' },
                { name: 'manager_name', label: 'Manager name' },
                { name: 'company_name', label: 'Company name' },
                { name: 'admin_responsible_name', label: 'Admin responsible name' },
                { name: 'job_position_name', label: 'Job position' },
                { name: 'contract_name', label: 'Contract' },
                { name: 'enrollee_birthday', label: 'Birthday' },
            ],
            signature_variables: [
                { name: 'sender_firstname', label: 'Sender firstname' },
                { name: 'sender_lastname', label: 'Sender lastname' },
                { name: 'sender_department', label: 'Sender department' },
                { name: 'sender_job_position', label: 'Sender job position' },
                { name: 'company_name', label: 'Company name' },
                { name: 'recipient_firstname', label: 'Recipient firstname' },
                { name: 'recipient_lastname', label: 'Recipient lastname' },
                { name: 'recipient_department', label: 'Recipient department' },
                { name: 'recipient_job_position', label: 'Recipient job position' },
            ],
        };
    },
};
