import TeamLayout from '@/router/pages/team/TeamLayout.vue';
import TeamListPage from '@/router/pages/team/TeamListPage.vue';
import MyTeam from '@/components/pages/team/MyTeam.vue';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';

export default [
    {
        path: '/Team',
        name: 'Team',
        component: TeamLayout,
        children: [
            {
                path: 'AllEmployees',
                name: 'TeamAllEmployees',
                component: TeamListPage,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'MyClass',
                name: 'TeamMyClass',
                component: TeamListPage,
                meta: {
                    permission: true,
                },
            },
            {
                path: 'MyTeam',
                name: 'TeamMyTeam',
                component: MyTeam,
                props: () => ({ companyUserId: Number(RtwSharedSpace.store.session.companyUser.id) }),
            },
        ],
    },
];
