import api from '@/store/api';
import Vue from 'vue';
import I18n from '@/modules/i18n/I18n';

const SET_PROGRAMS = 'set_programs';
const INVALIDATE_DATA = 'invalidate_data';

const SET_USER_FILTER = 'set_user_filter';
const SET_PROGRAMS_FILTER = 'set_programs_filter';
const SET_ENTITIES_FILTER = 'set_entities_filter';
const SET_STATUSES_FILTER = 'set_statuses_filter';
const SET_ONLY_WHERE_I_AM_PARTICIPANT = 'set_only_where_i_am_participant';
const SET_NEXT_KEY_DATES_FILTER = 'set_next_key_dates_filter';

const SET_SORTING = 'set_sorting';

const SET_CHECKBOX = 'set_checkbox';
const UNSET_ALL_CHECKBOXES = 'unset_all_checkboxes';

const SET_FILTERS = 'set_filters';
const REMOVE_FILTERS = 'remove_filters';

const SET_LOADING = 'set_loading';

const PROGRAM_STATUS = [
    { name: 'Active', slug: 'active' },
    { name: 'Completed', slug: 'completed' },
    { name: 'Late', slug: 'late' },
    { name: 'Invited', slug: 'invited' },
    { name: 'Reminded', slug: 'reminded' },
    { name: 'Closed', slug: 'closed' },
];

function defaultState() {
    return {
        sort: {
            column: 'main_key_date_starts_at',
            direction: 'desc',
        },
        filters: {
            user: '',
            programs: [],
            entities: [],
            userStatuses: [],
            program_statuses: [],
            next_key_dates: false,
            only_where_i_am_participant: false,
        },
    };
}

export default {
    namespaced: true,

    state() {
        return {
            ...defaultState(),

            pages: {},
            lastPage: 1,
            totalLines: 0,

            checkboxes: {},

            loading: false,
        };
    },
    getters: {
        getPage(state, getters) {
            return function (page) {
                return getters.pageLoaded(page) ? state.pages[`page_${page}`] : [];
            };
        },
        pageLoaded(state) {
            return function (page) {
                return Object.prototype.hasOwnProperty.call(state.pages, `page_${page}`);
            };
        },
        rowChecked(state) {
            return function (id) {
                return state.checkboxes[`program_${id}`] === true;
            };
        },
        getPageProgramIdList(state, getters) {
            return function (page) {
                return getters.getPage(page).map((row) => row.program.id);
            };
        },
        checkboxStatuses(state, getters) {
            return function (page) {
                const list = getters.getPageProgramIdList(page);
                const some = list.some((id) => getters.rowChecked(id));
                const all = list.every((id) => getters.rowChecked(id));
                return { some, all };
            };
        },
        getSelectedProgramsCount(state, getters) {
            return (page) => getters.getSelectedPrograms(page).length;
        },
        getSelectedPrograms(state, getters) {
            return (page) => getters.getPageProgramIdList(page).filter((id) => getters.rowChecked(id));
        },
        canSendReminders(state, getters) {
            return (page) => getters.getRemindableProgramsCount(page) > 0;
        },
        canCloseReminders(state, getters) {
            return (page) => getters.getCloseProgramsCount(page) > 0;
        },
        getRemindableProgramsCount(state, getters, rootState, rootGetters) {
            return function (page) {
                return getters.getPage(page)
                    .filter((row) => getters.rowChecked(row.program.id))
                    .filter((row) => ['late', 'invited'].includes(row.program.status))
                    .filter((row) => rootGetters['permissions/can']('AbstractManageUserPrograms', { company_user_id: row.user.id }))
                    .length;
            };
        },
        getCloseProgramsCount(state, getters, rootState, rootGetters) {
            return function (page) {
                return getters.getPage(page)
                    .filter((row) => getters.rowChecked(row.program.id))
                    .filter((row) => !['completed', 'closed'].includes(row.program.status))
                    .filter((row) => rootGetters['permissions/can']('AbstractClosePrograms', { company_user_id: row.user.id }))
                    .length;
            };
        },
        getFilters(state) {
            return state.filters;
        },
        getProgramStatusesList() {
            return PROGRAM_STATUS.map((status) => ({ ...status, name: I18n.translate(status.name) }));
        },
        getProgramStatuses(state) {
            return state.filters.program_statuses;
        },
    },

    actions: {
        fetchPage({ commit, state, getters }, page) {
            this.dispatch('permissions/load', { names: ['AbstractManageUserPrograms'] });

            if (getters.pageLoaded(page)) {
                return;
            }

            commit(SET_LOADING, true);

            api.dashboard.programs({
                filters: state.filters,
                sort: state.sort,
            }, {
                params: {
                    page,
                },
            }).then(({ data }) => {
                commit(SET_PROGRAMS, data);
            }).finally(() => {
                commit(SET_LOADING, false);
            });
        },
        refresh({ commit }, page = 0) {
            commit(INVALIDATE_DATA, page);
        },
        setProgramsFilter({ commit, dispatch }, ids) {
            commit(SET_PROGRAMS_FILTER, ids);
            commit(INVALIDATE_DATA);
            dispatch('fetchPage', 1);
        },
        setEntitiesFilter({ commit, state, dispatch }, { company_entity_id, values }) {
            const entities = state.filters.entities.filter((e) => e.company_entity_id !== company_entity_id);
            commit(SET_ENTITIES_FILTER, [...entities, ...values]);
            commit(INVALIDATE_DATA);
            dispatch('fetchPage', 1);
        },
        setStatusesFilter({ commit, dispatch }, statuses) {
            commit(SET_STATUSES_FILTER, statuses);
            commit(INVALIDATE_DATA);
            dispatch('fetchPage', 1);
        },
        setUserFilter({ commit, dispatch }, user) {
            commit(SET_USER_FILTER, user);
            commit(INVALIDATE_DATA);
            dispatch('fetchPage', 1);
        },
        setNextKeyDatesFilter({ commit, dispatch }, nextKeyDates) {
            commit(SET_NEXT_KEY_DATES_FILTER, nextKeyDates);
            commit(INVALIDATE_DATA);
            dispatch('fetchPage', 1);
        },
        /**
         *
         * @param {Boolean} value
         */
        setOnlyWhereIamParticipant({ commit }, value) {
            commit(SET_ONLY_WHERE_I_AM_PARTICIPANT, value);
        },
        setSorting({ commit }, sorting) {
            commit(SET_SORTING, sorting);
            commit(INVALIDATE_DATA);
        },
        toggleCheckbox({ commit, getters }, program_id) {
            const checked = !getters.rowChecked(program_id);
            commit(SET_CHECKBOX, { checked, program_id });
        },
        toggleCheckAll({ commit, getters }, page) {
            const masterCheckboxStatus = getters.checkboxStatuses(page).some;
            getters.getPageProgramIdList(page).forEach((id) => {
                commit(SET_CHECKBOX, {
                    checked: !masterCheckboxStatus,
                    program_id: id,
                });
            });
            return !masterCheckboxStatus;
        },
        cancelSelection({ commit, getters }, page = 0) {
            if (page == 0) {
                // Uncheck ALL boxes
                commit(UNSET_ALL_CHECKBOXES);
                return;
            }
            getters.getPageProgramIdList(page).forEach((id) => {
                commit(SET_CHECKBOX, {
                    checked: false,
                    program_id: id,
                });
            });
        },
        clearAllFilters({ commit }) {
            commit(REMOVE_FILTERS);
            commit(INVALIDATE_DATA);
        },
        setFilters({ commit }, filters) {
            commit(SET_FILTERS, filters);
        },
    },

    mutations: {
        [SET_PROGRAMS](state, pagination) {
            state.lastPage = pagination.last_page;
            state.totalLines = pagination.total;
            Vue.set(state.pages, `page_${pagination.current_page}`, pagination.data);
            state.loading = false;
        },
        [SET_PROGRAMS_FILTER](state, ids) {
            state.filters.programs = ids;
        },
        [SET_ENTITIES_FILTER](state, entities) {
            state.filters.entities = entities;
        },
        [SET_USER_FILTER](state, user) {
            state.filters.user = user;
        },
        [SET_STATUSES_FILTER](state, statuses) {
            state.filters.program_statuses = statuses;
        },
        [SET_NEXT_KEY_DATES_FILTER](state, nextKeyDates) {
            state.filters.next_key_dates = nextKeyDates;
        },
        [SET_ONLY_WHERE_I_AM_PARTICIPANT](state, value) {
            state.filters.only_where_i_am_participant = value;
        },
        [SET_SORTING](state, sorting) {
            state.sort = sorting;
        },
        [INVALIDATE_DATA](state, page = 0) {
            state.checkboxes = {};
            if (page == 0) {
                state.pages = {};
                state.lastPage = 1;
                state.totalLines = 0;
            } else {
                Vue.delete(state.pages, `page_${page}`);
            }
        },
        [SET_CHECKBOX](state, { checked, program_id }) {
            Vue.set(state.checkboxes, `program_${program_id}`, checked);
        },
        [UNSET_ALL_CHECKBOXES](state) {
            state.checkboxes = {};
        },
        [REMOVE_FILTERS](state) {
            Object.assign(state, defaultState());
        },
        [SET_FILTERS](state, filters) {
            Object.assign(state.filters, filters);
        },
        [SET_LOADING](state, loading) {
            state.loading = loading;
        },
    },
};
