<template>
    <StepperTabLayout>
        <template #main>
            <HtFormCard>
                <div class="mb-4">
                    <HtFormInput
                        :id="'name'"
                        v-model="item.name"
                        v-validate.disable="'required|max:255'"
                        :label="translate('Entity name')"
                        :name="'name'"
                        :data-vv-as="translate('name')"
                        :show-optional="false"
                        class="mb-4"
                        :disabled="!item.is_editable || Boolean(item.is_heyteam)"
                    />
                </div>
                <HtFormSwitch
                    :id="'private'"
                    v-model="item.is_private"
                    :name="'private'"
                    :label="translate('Private data')"
                    :disabled="!item.is_editable || item.is_heyteam"
                    :description="
                        translate(
                            'If the entity is used as a filter it will only be visible to users with the permissions.',
                        )
                    "
                    :show-optional="false"
                />
            </HtFormCard>
        </template>
        <template #buttonsFooter>
            <Button
                class="mr-4 negative"
                :state="buttonState"
                @click="$router.push({ name: 'SettingsEntities' })"
            >
                <t>Cancel</t>
            </Button>
            <Button
                v-if="item.is_editable"
                :state="buttonState"
                @click="onSave"
            >
                <t>Save</t>
            </Button>
        </template>
        <template #modalables>
            <Modalable
                ref="entiteDelete"
                class="modalable-1 small"
                :mode="2"
                :element-delete-label="translate('this entity')"
            />
        </template>
    </StepperTabLayout>
</template>

<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'EntitiesFormSettings',

    components: {
        HtFormSwitch,
    },
    props: {
        item: {
            type: [Object, null],
        },
    },

    data() {
        return {
            isSaving: false,
        };
    },
    computed: {
        buttonState() {
            return this.isSaving ? 'loading' : 'idle';
        },
    },

    methods: {
        async onSave() {
            if (await this.$validator.validateAll()) {
                if (this.item.id) {
                    this.updateEntity();
                } else {
                    this.createEntity();
                }
            }
        },
        createEntity() {
            this.isSaving = true;
            this.$store.dispatch('entities/createEntity', this.item)
                .then((entity) => {
                    this.$emit('update:item', entity);
                    this.$nextTick(() => {
                        this.$emit('goTo', 'content');
                    });
                })
                .finally(() => {
                    this.isSaving = false;
                });
        },
        updateEntity() {
            this.isSaving = true;
            this.$store.dispatch('entities/updateEntity', this.item)
                .finally(() => {
                    this.isSaving = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped src="./EntitiesFormSettings.scss"></style>
