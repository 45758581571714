<template>
    <component
        :is="ressourcesComponents[resourceType]"
        :resource-type="resourceType"
        :resource-id="resourceId"
    />
</template>

<script>

import ActionRead from '@/components/resources/action/ActionRead.vue';
import TrainingRead from '@/components/resources/training/TrainingRead.vue';
import NudgeRead from '@/components/resources/nudge/NudgeRead.vue';
import SharedDocumentRead from '@/components/resources/sharedDocument/SharedDocumentRead.vue';
import SurveyRead from '@/components/resources/survey/SurveyRead.vue';
import QuizRead from '@/components/resources/quiz/QuizRead.vue';
import EquipmentRead from '@/components/resources/equipment/EquipmentRead.vue';
import SoftwareRead from '@/components/resources/software/SoftwareRead.vue';
import RequirementCategoryRead from '@/components/resources/requirementCategory/RequirementCategoryRead.vue';
import EventRead from '@/components/resources/event/EventRead.vue';
import EmailRead from '@/components/resources/emails/EmailRead.vue';

export default {
    name: 'LoadResourceComponentRead',
    components: {
        ActionRead,
        TrainingRead,
        NudgeRead,
        SharedDocumentRead,
        SurveyRead,
        QuizRead,
        EquipmentRead,
        SoftwareRead,
        RequirementCategoryRead,
        EventRead,
        EmailRead,
    },
    props: {
        resourceType: {
            type: String,
            required: true,
        },
        resourceId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            ressourcesComponents: {
                company_program_task: ActionRead,
                company_document: TrainingRead,
                company_nudge: NudgeRead,
                company_shared_document: SharedDocumentRead,
                company_survey: SurveyRead,
                company_quiz: QuizRead,
                company_equipment: EquipmentRead,
                company_software: SoftwareRead,
                company_requirement_category: RequirementCategoryRead,
                company_planning: EventRead,
                company_email_custom_template: EmailRead,
            },
        };
    },
};
</script>
