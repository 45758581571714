<template>
    <div
        class="wrapper"
        :class="isTransitionOnEnter ? 'branding-border-color pb-4' : 'wrapper-inactive'"
    >
        <div
            class="wrapper-header clickable"
            :class="isTransitionOnEnter ? 'branding-bg' : 'wrapper-header-inactive'"
            @click="toggleActive()"
        >
            <div>
                <div class="title">
                    <t>{{ title }}</t>
                </div>
                <div :class="isTransitionOnEnter ? 'description' : 'description-inactive'">
                    <t>{{ description }}</t>
                </div>
            </div>
            <div :class="isTransitionOnEnter ? 'icon-wrapper' : 'icon-wrapper inactive'">
                <FontAwesomeIcon
                    class="align-self-center"
                    size="1x"
                    :icon="getIcon"
                />
            </div>
        </div>
        <transition
            name="dropdown-fade"
            @after-leave="toggleTransition"
            @before-enter="toggleTransition"
        >
            <div
                v-show="isActive"
                :class="'content'"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>
<script>

export default {

    props: {
        title: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            isTransitionOnEnter: false,
            isActive: false,
        };
    },

    computed: {
        getIcon() {
            return this.isActive ? 'chevron-up' : 'chevron-down';
        },
    },

    methods: {
        toggleTransition() {
            this.isTransitionOnEnter = !this.isTransitionOnEnter;
        },

        toggleActive() {
            this.isActive = !this.isActive;
        },
    },
};
</script>

<style lang="scss" scoped src="./Dropdown.scss"></style>
<style lang="scss" scoped>
.dropdown-fade-enter-active {
    transition: all 0.3s ease-in-out;
}
.dropdown-fade-leave-active {
    transition: all 0.2s ease-in-out;
}
.dropdown-fade-enter, .dropdown-fade-leave-to {
    opacity: 0;
}
</style>
