<template>
    <div class="holding">
        <div class="holding-name">
            {{ holding.name }}
        </div>
        <div class="companies">
            <div
                v-for="company in childCompanies"
                :key="company.id"
                class="company"
            >
                <div class="building-icon">
                    <FontAwesomeIcon
                        :icon="['far', 'building']"
                        :style="{ color: '#929292' }"
                    />
                </div>
                <div class="company-info">
                    <span class="name company-name">{{ company.name }}</span>
                    <span class="name manager-name">{{ company.administrator.fullname }}</span>
                </div>
            </div>
        </div>
        <div class="holding-owner">
            <t class="label">
                Owner
            </t>
            <div class="info">
                <div
                    v-user-image="{
                        image: holding.administrator.image,
                        firstname: holding.administrator.firstname,
                        lastname: holding.administrator.lastname
                    }"
                    class="user-image small"
                /><span>{{ holding.administrator.fullname }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'HoldingInfo',
    computed: {
        ...mapGetters('companies', [
            'holding',
            'childCompanies',
        ]),
    },
};
</script>

<style lang="scss" scoped>
.holding {
    padding: 4rem;
}

.holding-name {
    font-size: 2.4rem;
    line-height: 2.9rem;
    display: flex;
    justify-content: space-between;
}

.holding-name > .edit {
    background-color: #434452;
    border-radius: 12px;
    font-size: 10px;
    line-height: 10px;
    display: inline-block;
    height:2.4rem;
    width: 2.4rem;
    display: flex;
    justify-content: center;
}
.holding-name > .edit > svg {
    align-self: center;
}

.companies {
    padding-top: 2.4rem;
}
.company {
    height: 7.2rem;
    border: 1px solid #E5E6E6;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 1.2rem;
    display: flex;
}
.company-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.building-icon {
    line-height: 2.0rem;
    margin-top: 2.6rem;
    margin-bottom: 2.6rem;
    margin-left: 1.525rem;
    margin-right: 1.125rem;
}
.company .name {
    line-height: 1.9rem;
    font-size: 1.6rem;
}
.company .name.company-name {
    font-weight: bold;
}
.company .name.manager-name {
    color: #929292;
}

.holding-owner {
    margin-top: 2.4rem;
}
.holding-owner .label {
    color: #342E37;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.9rem;
}
.holding-owner .info {
    margin-top: 0.8rem;
}
.holding-owner .info span {
    line-height: 2.4rem;
    font-size: 1.4rem;
}

.user-image.small {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.8rem;
}
</style>
