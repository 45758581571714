<template>
    <component
        :is="noDownload ? 'div' : 'a'"
        :href="file.link"
        class="file-wrapper"
    >
        <div class="icon-wrapper">
            <FontAwesomeIcon
                v-if="isVideo(file.detected_mime_type)"
                icon="play-circle"
                class="file-icon"
            />
            <FontAwesomeIcon
                v-else
                :icon="['fal', 'file']"
                class="file-icon"
            />
        </div>
        <div class="file-info">
            <div class="name">
                {{ file.original_name }}
            </div>
            <div
                v-if="file.size"
                class="size"
            >
                {{ $Utils.convertByteToKilobyte(file.size) }} Ko
            </div>
        </div>
    </component>
</template>
<script>
import filePreviewerMixin from '../globals/FilePreviewer/filePreviewerMixin';

export default {
    name: 'DocumentBlock',
    mixins: [filePreviewerMixin],
    props: {
        file: {
            type: Object,
            required: true,
        },
        noDownload: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
.file-wrapper{
    position: relative;
    display: flex;
    gap: 8px;
    padding: 8px 16px 8px 8px;
    border: 1px solid $neutral-300;
    height: 48px;
    border-radius: 8px;
    text-decoration: none;

    .icon-wrapper {
        color: $primary;
        background-color: $primary-background;
        border-radius: 8px;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
    .file-info {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .name{
            color: $neutral-black;
            font-weight: 700;

            &:hover {
                text-decoration: underline;
            }
        }
        .size{
            color: $neutral-500;
            margin-top: 4px;
        }
    }

}
</style>
