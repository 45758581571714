<template>
    <div>
        {{ getValue | empty }}
    </div>
</template>

<script>
import get from 'lodash.get';

export default {
    name: 'UserRequirementSingleSelectRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getValue() {
            return get(this.userRequirement.value, 'value', null);
        },
    },
};
</script>
<style lang='scss' src='./UserRequirementMultipleSelectEdit.scss' scoped />
