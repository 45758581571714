const list = {
    ALL: { label: 'Albania Lek', symbol: 'Lek' },
    AFN: { label: 'Afghanistan Afghani', symbol: '؋' },
    ARS: { label: 'Argentina Peso', symbol: '$' },
    AWG: { label: 'Aruba Guilder', symbol: 'ƒ' },
    AUD: { label: 'Australia Dollar', symbol: '$' },
    AZN: { label: 'Azerbaijan Manat', symbol: '₼' },
    BSD: { label: 'Bahamas Dollar', symbol: '$' },
    BBD: { label: 'Barbados Dollar', symbol: '$' },
    BYN: { label: 'Belarus Ruble', symbol: 'Br' },
    BZD: { label: 'Belize Dollar', symbol: 'BZ$' },
    BMD: { label: 'Bermuda Dollar', symbol: '$' },
    BOB: { label: 'Bolivia Bolíviano', symbol: '$b' },
    BAM: { label: 'Bosnia and Herzegovina Convertible Marka', symbol: 'KM' },
    BWP: { label: 'Botswana Pula', symbol: 'P' },
    BGN: { label: 'Bulgaria Lev', symbol: 'лв' },
    BRL: { label: 'Brazil Real', symbol: 'R$' },
    BND: { label: 'Brunei Darussalam Dollar', symbol: '$' },
    KHR: { label: 'Cambodia Riel', symbol: '៛' },
    CAD: { label: 'Canada Dollar', symbol: '$' },
    KYD: { label: 'Cayman Islands Dollar', symbol: '$' },
    CLP: { label: 'Chile Peso', symbol: '$' },
    CNY: { label: 'China Yuan Renminbi', symbol: '¥' },
    COP: { label: 'Colombia Peso', symbol: '$' },
    CRC: { label: 'Costa Rica Colon', symbol: '₡' },
    HRK: { label: 'Croatia Kuna', symbol: 'kn' },
    CUP: { label: 'Cuba Peso', symbol: '₱' },
    CZK: { label: 'Czech Republic Koruna', symbol: 'Kč' },
    DKK: { label: 'Denmark Krone', symbol: 'kr' },
    DOP: { label: 'Dominican Republic Peso', symbol: 'RD$' },
    XCD: { label: 'East Caribbean Dollar', symbol: '$' },
    EGP: { label: 'Egypt Pound', symbol: '£' },
    SVC: { label: 'El Salvador Colon', symbol: '$' },
    EUR: { label: 'Euros', symbol: '€' },
    FKP: { label: 'Falkland Islands (Malvinas) Pound', symbol: '£' },
    FJD: { label: 'Fiji Dollar', symbol: '$' },
    GHS: { label: 'Ghana Cedi', symbol: '¢' },
    GIP: { label: 'Gibraltar Pound', symbol: '£' },
    GTQ: { label: 'Guatemala Quetzal', symbol: 'Q' },
    GGP: { label: 'Guernsey Pound', symbol: '£' },
    GYD: { label: 'Guyana Dollar', symbol: '$' },
    HNL: { label: 'Honduras Lempira', symbol: 'L' },
    HKD: { label: 'Hong Kong Dollar', symbol: '$' },
    HUF: { label: 'Hungary Forint', symbol: 'Ft' },
    ISK: { label: 'Iceland Krona', symbol: 'kr' },
    INR: { label: 'India Rupee', symbol: '' },
    IDR: { label: 'Indonesia Rupiah', symbol: 'Rp' },
    IRR: { label: 'Iran Rial', symbol: 'IRR' },
    IMP: { label: 'Isle of Man Pound', symbol: '£' },
    ILS: { label: 'Israel Shekel', symbol: '₪' },
    JMD: { label: 'Jamaica Dollar', symbol: 'J$' },
    JPY: { label: 'Japan Yen', symbol: '¥' },
    JEP: { label: 'Jersey Pound', symbol: '£' },
    KZT: { label: 'Kazakhstan Tenge', symbol: 'лв' },
    KPW: { label: 'Korea (North) Won', symbol: '₩' },
    KRW: { label: 'Korea (South) Won', symbol: '₩' },
    KGS: { label: 'Kyrgyzstan Som', symbol: 'лв' },
    LAK: { label: 'Laos Kip', symbol: '₭' },
    LBP: { label: 'Lebanon Pound', symbol: '£' },
    LRD: { label: 'Liberia Dollar', symbol: '$' },
    MKD: { label: 'Macedonia Denar', symbol: 'ден' },
    MYR: { label: 'Malaysia Ringgit', symbol: 'RM' },
    MUR: { label: 'Mauritius Rupee', symbol: '₨' },
    MXN: { label: 'Mexico Peso', symbol: '$' },
    MNT: { label: 'Mongolia Tughrik', symbol: '₮' },
    MZN: { label: 'Mozambique Metical', symbol: 'MT' },
    NAD: { label: 'Namibia Dollar', symbol: '$' },
    NPR: { label: 'Nepal Rupee', symbol: '₨' },
    ANG: { label: 'Netherlands Antilles Guilder', symbol: 'ƒ' },
    NZD: { label: 'New Zealand Dollar', symbol: '$' },
    NIO: { label: 'Nicaragua Cordoba', symbol: 'C$' },
    NGN: { label: 'Nigeria Naira', symbol: '₦' },
    NOK: { label: 'Norway Krone', symbol: 'kr' },
    OMR: { label: 'Oman Rial', symbol: 'OMR' },
    PKR: { label: 'Pakistan Rupee', symbol: '₨' },
    PAB: { label: 'Panama Balboa', symbol: 'B/.' },
    PYG: { label: 'Paraguay Guarani', symbol: 'Gs' },
    PEN: { label: 'Peru Sol', symbol: 'S/.' },
    PHP: { label: 'Philippines Piso', symbol: '₱' },
    PLN: { label: 'Poland Zloty', symbol: 'zł' },
    QAR: { label: 'Qatar Riyal', symbol: 'QAR' },
    RON: { label: 'Romania Leu', symbol: 'lei' },
    RUB: { label: 'Russia Ruble', symbol: '₽' },
    SHP: { label: 'Saint Helena Pound', symbol: '£' },
    SAR: { label: 'Saudi Arabia Riyal', symbol: 'SAR' },
    RSD: { label: 'Serbia Dinar', symbol: 'Дин.' },
    SCR: { label: 'Seychelles Rupee', symbol: '₨' },
    SGD: { label: 'Singapore Dollar', symbol: '$' },
    SBD: { label: 'Solomon Islands Dollar', symbol: '$' },
    SOS: { label: 'Somalia Shilling', symbol: 'S' },
    ZAR: { label: 'South Africa Rand', symbol: 'R' },
    LKR: { label: 'Sri Lanka Rupee', symbol: '₨' },
    SEK: { label: 'Sweden Krona', symbol: 'kr' },
    CHF: { label: 'Switzerland Franc', symbol: 'CHF' },
    SRD: { label: 'Suriname Dollar', symbol: '$' },
    SYP: { label: 'Syria Pound', symbol: '£' },
    TWD: { label: 'Taiwan New Dollar', symbol: 'NT$' },
    THB: { label: 'Thailand Baht', symbol: '฿' },
    TTD: { label: 'Trinidad and Tobago Dollar', symbol: 'TT$' },
    TRY: { label: 'Turkey Lira', symbol: '' },
    TVD: { label: 'Tuvalu Dollar', symbol: '$' },
    UAH: { label: 'Ukraine Hryvnia', symbol: '₴' },
    GBP: { label: 'United Kingdom Pound', symbol: '£' },
    USD: { label: 'United States Dollar', symbol: '$' },
    UYU: { label: 'Uruguay Peso', symbol: '$U' },
    UZS: { label: 'Uzbekistan Som', symbol: 'лв' },
    VEF: { label: 'Venezuela Bolívar', symbol: 'Bs' },
    VNF: { label: 'Vietnam Dong', symbol: '₫' },
    YER: { label: 'Yemen Rial', symbol: 'YER' },
    ZWD: { label: 'Zimbabwe Dollar', symbol: 'Z$' },
};

export default { list };
