import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import CompanyUserNudgeCollection from './CompanyUserNudgeCollection';
import CompanyNudge from './CompanyNudge';
import CompanyUserProgram from './CompanyUserProgram';

export default class CompanyUserNudge extends Model {
    /**
     * custom methods
     */
    static STORE_ANSWER = 'storeAnswer';

    // status
    static STATUS_ACCEPTED = 'accepted';

    static STATUS_DRAFT = 'draft';

    static STATUS_FAILURE = 'failure';

    static STATUS_PENDING = 'pending';

    static STATUS_PROPOSED = 'proposed';

    static STATUS_REFUSE = 'refuse';

    static STATUS_SUCCESS = 'success';

    modelConfig() {
        return {
            collection: CompanyUserNudgeCollection,
        };
    }

    modelCustomAttributes() {
        return {
            statusText: null,
            custom_company_user_recipients: [],
            name: '',
        };
    }

    modelRelations() {
        return {
            companyUserProgram: () => this.belongsTo(CompanyUserProgram, 'company_user_program_id', 'id'),
            // Onboardee
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id'),
            companyNudge: () => this.belongsTo(CompanyNudge, 'company_nudge_id'),
            companyUserInitiator: () => this.belongsTo(CompanyUser, 'company_user_initiator_id'),
            // recipient
            companyUserRecipient: () => this.belongsTo(CompanyUser, 'company_user_recipient_id'),
            companyUserProgramTask: () => this.belongsTo(CompanyUserProgramTask, 'company_user_program_task_id'),
        };
    }

    modelAccessors() {
        return {
            name: () => {
                if (!this.company_nudge || !this.company_user?.company_language) return '';
                return this
                    .company_nudge.resource.translations
                    .name[this.company_user.company_language.key]
                    ?? this.company_nudge.resource.name;
            },
        };
    }
}
