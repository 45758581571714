<template>
    <header class="headbar-light">
        <div class="headbar-light-logo">
            <router-link to="/Dashboard">
                <img :src="$Utils.resolveS3PublicImage(shared.session.company.image_square)">
            </router-link>
        </div>
        <div
            class="headbar-light-close"
            @click="onBack()"
        >
            <FontAwesomeIcon
                size="1x"
                class="icon"
                :icon="['fal', 'times']"
            />
        </div>
    </header>
</template>

<script>
import Company from '@/models/Company';

export default {
    name: 'HeadBarLight',
    props: {
        canBack: {
            type: Boolean,
            default: true,
        },
    },

    shared: {
        session: {
            company: {
                type: Company,
                default: {},
            },
        },
    },

    methods: {
        onBack() {
            if (this.canBack) {
                this.$router.goBack();
            }
        },
    },
};
</script>

<style lang="scss" scoped src="./HeadBarLight.scss"></style>
