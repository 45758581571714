import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import Notifier from '../Notifier';
import I18n from '../modules/i18n/I18n';
import CompanyUserMetadataCollection from './CompanyUserMetadataCollection';

export default class CompanyUserMetadata extends Model {
    static TYPE_HOBBY = 'hobby';

    static TYPE_SKILL = 'skill';

    modelConfig() {
        return {
            collection: CompanyUserMetadataCollection,
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
        };
    }

    modelEvents() {
        return {
            saved() {
            },

            updated() {
            },

            deleted() {
                Notifier.getInstance('App').showInfo(
                    I18n.translate('You have successfully deleted this item'),
                );
            },

            failed() {
                Notifier.getInstance('App').showError(
                    I18n.translate('A problem occurred while trying to update this resource!'),
                );
            },
        };
    }
}
