<!-- eslint-disable no-debugger -->
<template>
    <div v-if="companySecurity.isLoaded()">
        <form
            class="form-2"
            @submit.prevent=""
        >
            <HtFormInput
                id="oldpassword"
                v-model="oldPassword"
                v-validate.disable="{ required: true }"
                name="oldpassword"
                type="password"
                :show-asterisk="true"
                :error-append="errorPassword"
                :label="translate('Current Password')"
                :placeholder="translate('Enter current password')"
                :data-vv-as="translate('current password')"
            />

            <label>
                <span><t>New Password</t> *</span>
                <span style="font-size: .75em;font-weight: normal">
                    (
                    <t>Password must be at least</t>&nbsp;
                    <t :count="companySecurity.password_length">{count} character long | {count} characters long</t>,&nbsp;
                    <t :count="companySecurity.password_numbers">{count} digit | {count} digits</t>&nbsp;
                    <t>and</t>&nbsp;
                    <t :count="companySecurity.password_symbols">{count} special character | {count} special characters</t>&nbsp;
                    )
                </span>
            </label>
            <HtFormInput
                :id="'password'"
                ref="password"
                v-model="newPassword"
                v-validate.disable="{
                    required: true,
                    regex: new RegExp(companySecurity.password_regex),
                    confirmed: 'password'
                }"
                name="password"
                type="password"
                :show-asterisk="true"
                :placeholder="translate('Enter new password')"
                :data-vv-as="translate('new password')"
                :show-optional="false"
            />

            <HtFormInput
                :id="'confirmPassword'"
                v-model="confirmedPassword"
                v-validate.disable="{
                    required: true,
                    regex: new RegExp(companySecurity.password_regex),
                    confirmed: 'password'
                }"
                :name="'confirmPassword'"
                :type="'password'"
                :show-asterisk="true"
                :label="translate('Confirm New Password')"
                :placeholder="translate('Confirm new password')"
                data-vv-validate-on="none"
                :data-vv-as="translate('password confirmation')"
                :show-optional="false"
            />
            <input
                v-model="confirmedPassword"
                v-validate.disable="'required'"
                type="hidden"
                name="confirmPasswordRequired"
                :data-vv-as="translate('password confirmation')"
            >

            <div class="row-line">
                <HtButton
                    :loading="loading"
                    :disabled="loading"
                    @click.native="onUpdatePassword"
                >
                    <t>Save</t>
                </HtButton>
            </div>
        </form>
    </div>
</template>
<script>
import CompanySecurity from '@/models/CompanySecurity';
import Company from '@/models/Company';
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'AccountPassword',

    components: {
        HtButton,
    },

    shared: {
        session: {
            company: {
                type: Company,
                default: null,
            },
        },
    },

    data() {
        return {
            errorPassword: null,
            oldPassword: null,
            newPassword: null,
            confirmedPassword: null,
            loading: false,
            companySecurity: new CompanySecurity([
                'password_length',
                'password_symbols',
                'password_numbers',
                'password_regex',
            ]),
        };
    },

    created() {
        this.companySecurity.get();
    },

    methods: {
        onUpdatePassword() {
            if (this.loading === true) {
                return;
            }

            this.$validator.validateAll().then((result) => {
                if (result === true) {
                    this.errorPassword = null;

                    const params = {
                        newPassword: this.newPassword,
                        password: this.oldPassword,
                    };

                    this.loading = true;
                    this.$http.put('me/password', params).then(() => {
                        this.$Notifier('App').showInfo(this.translate('Password successfully updated! You will be redirect to login.'));
                        this.$Auth.logOut();

                        setTimeout(() => {
                            this.$router.push({
                                name: 'Login',
                            });
                        }, 5000);
                    }).catch(({ response }) => {
                        this.errorPassword = response.data.message;
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

form {
    max-width: 600px;
    margin: auto;
}
</style>
