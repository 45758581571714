import Model from '@tony.caron/node-model-proxy/Model';
import CompanyProgramResourceFilterCollection from '@/models/CompanyProgramResourceFilterCollection';
import CompanyEntity from '@/models/CompanyEntity';
import CompanyEntityValue from '@/models/CompanyEntityValue';
import Notifier from '@/Notifier';
import I18n from '@/modules/i18n/I18n';

export default class CompanyProgramResourceFilter extends Model {
    modelConfig() {
        return {
            primaryKey: 'id',
            controller: 'CompanyProgramResourceFilterController',
            collection: CompanyProgramResourceFilterCollection,
        };
    }

    modelCustomAttributes() {
        return {
		    name: null,
		    name_translated: null,
		    order: 0,
        };
    }

    modelAccessors() {
        return {
            name: () => {
                if (this.entity && this.value) {
                    const relationName = !this.entity.is_custom ? this.entity.slug.replace('company_', '') : 'custom';
                    return this.value[relationName].name;
                }

                return '';
            },
            name_translated: () => {
                if (this.entity && this.value) {
                    const relationName = !this.entity.is_custom ? this.entity.slug.replace('company_', '') : 'custom';
                    return this.value[relationName].is_heyteam ? I18n.translate(this.value[relationName].name) : this.value[relationName].name;
                }

                return '';
            },
            order: () => {
                if (this.entity) {
                    switch (this.entity.slug) {
                    case 'company_office':
                        return 1;
                    case 'company_contract':
                        return 2;
                    case 'company_department':
                        return 3;
                    case 'company_job_position':
                        return 4;
                    }
                }
            },
        };
    }

    modelRelations() {
        return {
            entity: () => this.belongsTo(CompanyEntity, 'company_entity_id', 'id'),
            value: () => this.belongsTo(CompanyEntityValue, 'company_entity_value_id', 'id'),
        };
    }

    onSaved() {
        Notifier.getInstance('App').showInfo('ok');
    }
}
