<template>
    <div class="button">
        <FontAwesomeLayers v-if="sorting === false">
            <FontAwesomeIcon
                icon="chevron-up"
                transform="up-5 shrink-6"
            />
            <FontAwesomeIcon
                icon="chevron-down"
                transform="down-5 shrink-6"
            />
        </FontAwesomeLayers>
        <FontAwesomeLayers v-else-if="direction === 'asc'">
            <FontAwesomeIcon
                icon="chevron-down"
                transform="down-5 shrink-6"
            />
        </FontAwesomeLayers>
        <FontAwesomeLayers v-else>
            <FontAwesomeIcon
                icon="chevron-up"
                transform="up-5 shrink-6"
            />
        </FontAwesomeLayers>
    </div>
</template>
<script>
export default {
    props: {
        sorting: {
            type: Boolean,
            required: true,
        },
        direction: {
            type: String,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.button {
    display: inline-block;
}
</style>
