import Vue from 'vue';

export default {
    /**
     * Makes an API call to check the user program launch conditions.
     *
     * @function
     * @param {number} userId - The ID of the user.
     * @param {number} programId - The ID of the program.
     * @param {number} [excludeDraftId] - The ID of the draft to exclude.
     * @returns {Promise<Object>} - A promise that resolves with the API response.
     * @property {Object} data - The response data.
     * @property {string} data.reason - The reason why the program can or cannot be launched. Possible values are:
     * - `USER_HAS_ACTIVE_PROGRAM`
     * - `USER_HAS_DRAFT_PROGRAM`
     * - `USER_MISMATCH_PROGRAM_LAUNCH_CONDITION`
     * - `USER_CAN_LAUNCH_PROGRAM`
     * @property {Object|null} data.program - The program information if there is a conflict, otherwise null.
     * @property {number} [data.program.id] - The ID of the conflicting program.
     * @property {number} [data.program.company_id] - The ID of the company associated with the program.
     * @property {number} [data.program.company_user_id] - The ID of the company user associated with the program.
     * @property {number} [data.program.owner_id] - The ID of the owner associated with the program.
     * @property {number} [data.program.company_program_id] - The ID of the company program associated with the program.
     * @property {boolean} [data.program.has_default_resources] - Indicates if the program has default resources.
     * @property {string} [data.program.status] - The status of the program.
     * @property {Date|null} [data.program.start] - The start date of the program.
     * @property {Date|null} [data.program.end] - The end date of the program.
     * @property {number} [data.program.duration_day] - The duration of the program in days.
     * @property {string} [data.program.mail_subject] - The mail subject of the program.
     * @property {string|null} [data.program.mail_content] - The mail content of the program.
     * @property {number|null} [data.program.company_file_id] - The ID of the company file associated with the program.
     * @property {string|null} [data.program.sms_content] - The SMS content of the program.
     * @property {string|null} [data.program.mail_schedule] - The mail schedule of the program.
     * @property {Date|null} [data.program.activated_at] - The activation date of the program.
     * @property {Date|null} [data.program.last_reminder_at] - The last reminder date of the program.
     * @property {Date|null} [data.program.closed_at] - The closure date of the program.
     * @property {number} [data.program.is_late] - Indicates if the program is late.
     * @property {number} [data.program.enrollee_completion] - The completion percentage of the enrollees in the program.
     * @property {number} [data.program.participants_completion] - The completion percentage of the participants in the program.
     * @property {Date} [data.program.created_at] - The creation date of the program.
     * @property {Date} [data.program.updated_at] - The last update date of the program.
     * @property {Date|null} [data.program.deleted_at] - The deletion date of the program.
     *
     * @example
     * check(1, 101)
     *   .then(response => {
     *     console.log(response.data);
     *     // Handle the response data
     *   })
     *   .catch(error => {
     *     console.error(error);
     *     // Handle the error
     *   });
     */
    check(userId, programId, excludeDraftId = null) {
        return Vue.prototype.$http.post('foc/enrolling/check', {
            user_id: userId,
            program_id: programId,
            exclude_draft_id: excludeDraftId,
        });
    },
};
