<template>
    <HtModal ref="modal">
        <template #header>
            <t>Add existing collaborator</t>
        </template>
        <template #default>
            <label>
                <div class="mb-4"><t>Search a user to add</t></div>
                <HtSearchField
                    id="search"
                    name="search"
                    placeholder=""
                    @input="value => (searchTerm = value)"
                />
            </label>

            <div class="users">
                <div
                    v-for="user in userList"
                    :key="user.id"
                    class="user clickable"
                    @click="onSelect(user)"
                >
                    <div
                        v-user-image="{
                            image: user.image,
                            firstname: user.firstname,
                            lastname: user.lastname
                        }"
                        class="user-image"
                    />
                    <div class="user-info">
                        <div class="name">
                            {{ user.firstname }} {{ user.lastname }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </HtModal>
</template>

<script>
import HtSearchField from '@/components/globals/HtSearchField.vue';
import debounce from 'lodash.debounce';

export default {
    name: 'AddExistingUserModal',
    components: {
        HtSearchField,
    },
    props: {
        companyProgramId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            searchTerm: '',
            searching: false,
            userList: [],
            searchUsers: debounce((filter) => {
                this.$http.post(
                    'company_user_search',
                    {
                        search: filter,
                        limit: 10,
                        withPrograms: true,
                    },
                ).then((data) => {
                    this.userList = data.data?.search;
                    this.searching = false;
                }).catch(() => {
                    this.searching = false;
                });
            }, 350),
        };
    },
    watch: {
        searchTerm() {
            this.searching = true;
            this.searchUsers(this.searchTerm);
        },
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        onSelect(user) {
            this.$emit('userSelected', user);
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/styles/var';

.users {
    display: flex;
    flex-direction: column;
    margin-top: 3.2rem;

    .user {
        display: flex;
        flex-direction: row;
        font-size: 1.4rem;
        gap: 1.2rem;
        padding: 1.7rem;
        align-items: center;
        border-radius: 0.8rem;

        &.disabled {
            color: $grey-dark;
        }

        &:nth-child(2n + 1) {
            background-color: $color-lightblue-grey;
        }

        .user-image {
            width: 3rem;
            height: 3rem;
        }
        .user-info {
            display: flex;
            flex-direction: column;
            gap: 6px;
            font-size: 14px;

            .name {
                font-weight: 700;
                font-size: 16px;
            }
        }
    }
}
</style>
