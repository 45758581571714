import Model from '@tony.caron/node-model-proxy/Model';
import I18n from '../modules/i18n/I18n';
import CompanyUser from './CompanyUser';
import Company from './Company';

export default class CompanyUserNotification extends Model {
    static STATUS_TO_DELIVER = 'to_deliver';

    static STATUS_DELIVERED = 'delivered';

    modelConfig() {
        return {
            controller: 'CompanyUserNotificationController',
        };
    }

    modelDefaultValues() {
        return {
        };
    }

    modelCustomAttributes() {
        return {
            title_translated: null,
            resource_translated: null,
        };
    }

    modelAccessors() {
        return {
            title_translated: () => ((this.title) ? I18n.translate(this.title) : null),
            resource_translated: () => {
                let resource_label = null;
                switch (this.resource) {
                case 'company_planning':
                    resource_label = 'Event';
                    break;
                case 'company_software':
                    resource_label = 'Software';
                    break;
                case 'company_equipment':
                    resource_label = 'Equipment';
                    break;
                case 'company_training':
                    resource_label = 'Training';
                    break;
                case 'company_requirement_category':
                    resource_label = 'Requirement';
                    break;
                case 'company_document':
                    resource_label = 'Document';
                    break;
                default:
                }
                return (resource_label) ? I18n.translate(resource_label) : null;
            },
        };
    }

    modelInConvertor() {
        return {
            is_enabled_email: (n) => Boolean(n),
            is_enabled_sms: (n) => Boolean(n),
            data: (n) => ((n) ? JSON.parse(n) : n),
        };
    }

    modelRelations() {
        return {
            toCompanyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            fromCompanyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            relatedCompanyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            company: () => this.belongsTo(Company, 'company_id', 'id'),
        };
    }
}
