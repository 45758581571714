<template>
    <HoldingResourceList
        :route-list="'HoldingResourcesEventsList'"
        :collection="events"
        :use-resource-program="false"
    >
        <template #table="holdingResourceList">
            <EventsListModels
                :items="holdingResourceList.items"
                :external-search="holdingResourceList.search"
                @onCellClicked="holdingResourceList.onCellClicked"
            />
        </template>
    </HoldingResourceList>
</template>
<script>
import HoldingResourceList from '@/components/globals/HoldingResourceList.vue';
import EventsListModels, { eventConfig } from '@/resourcesList/EventsListModels.vue';
import CompanyPlanningEventTemplateCollection from '@/models/CompanyPlanningEventTemplateCollection';

export default {
    name: 'PageEventsList',
    components: {
        HoldingResourceList,
        EventsListModels,
    },

    data() {
        return {
            events: new CompanyPlanningEventTemplateCollection(eventConfig.fields)
                .with(eventConfig.relations),
        };
    },
};
</script>
