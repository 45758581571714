<template>
    <div
        v-if="previewableLinks && previewableLinks.length && openPreview"
        class="preview-container"
        @mousedown.self.stop="togglePreviewer"
    >
        <div class="preview-container-content">
            <HtIconClose
                class="preview-container-content-close"
                data-cy="close-preview"
                @click="togglePreviewer"
            />
            <carousel
                v-if="previewableLinks.length > 1"
                :per-page="1"
                navigation-enabled
            >
                <slide
                    v-for="(link, index) in previewableLinks"
                    :key="`slide-${index}`"
                >
                    <img
                        v-if="isImage(link.detected_mime_type)"
                        class="preview-container-content-image"
                        :src="link.link"
                    >
                    <iframe
                        v-else
                        :src="link.link"
                        class="preview-container-content-iframe"
                        frameborder="0"
                        allowfullscreen
                    />
                </slide>
            </carousel>
            <template v-else>
                <img
                    v-if="isImage(previewableLinks[0].detected_mime_type)"
                    class="preview-container-content-image"
                    :src="previewableLinks[0].link"
                >
                <iframe
                    v-else
                    :src="previewableLinks[0].link"
                    class="preview-container-content-iframe"
                    frameborder="0"
                    allowfullscreen
                />
            </template>
        </div>
    </div>
</template>
<script>
import HtIconClose from '@/components/icons/HtIconClose.vue';
import { Carousel, Slide } from 'vue-carousel';
import filePreviewerMixin from './filePreviewerMixin';

export default {
    name: 'FilePreviewer',
    components: {
        HtIconClose, Carousel, Slide,
    },

    mixins: [filePreviewerMixin],
    props: {
        files: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            openPreview: false,
        };
    },

    computed: {
        previewableLinks() {
            return this.files.filter((file) => this.canPreview(file.detected_mime_type))
                .map((file) => ({ ...file, link: file.link_preview ?? file.link }));
        },
    },

    methods: {
        togglePreviewer() {
            if (this.previewableLinks?.length) {
                this.openPreview = !this.openPreview;

                if (this.openPreview === false) {
                    this.$emit('on-close');
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.preview-container {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.2);

    &-content {
        margin: 5em;
        padding: 0;
        width: calc(100% - 10em);
        height: calc(100vh - 10em);
        background: white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &-close {
            position: absolute;
            top: 2em;
            right: 2em;
            z-index: 99;
        }

        &-image {
            padding: 5em;
            max-height: calc(100vh - 10em);
        }

        &-iframe {
            width: 100%;
            height: calc(100vh - 10em);
            border: none;
            margin: 0;
            padding: 5em;
        }
    }
}

@media (max-width: $phone) {
    .preview-container-content {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        margin: 0;

        &-close {
            top: 1em;
            right: 1em;
        }

        &-iframe, &-image {
            padding: 2em;
        }
    }
}
@media (min-width: $phone) {
    ::v-deep .VueCarousel {
        height: 100%;
        width: 100%;
        .VueCarousel-wrapper {
            display: block;
        }
    }

    ::v-deep .VueCarousel-navigation-prev {
        transform: translateX(100%) !important;
    }
    ::v-deep .VueCarousel-navigation-next {
        transform: translateX(-100%) !important;
    }
    ::v-deep .VueCarousel-pagination{
        transform: translateY(-100%) !important;
    }
}

</style>
