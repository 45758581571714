<template>
    <div class="question-completion">
        <div class="list-answer">
            <div
                v-for="choice in question.template.answers"
                class="list-answer-item"
                :class="{'selected': choiceIsSelected(choice), 'read-only': isReadOnly}"
                @click="selectChoice(choice)"
            >
                {{ localize(choice.translations.text) }}
            </div>
        </div>

        <SurveyAnswerHistory
            v-if="hasPreviousOccurrenceSection"
        >
            <SurveyChoiceHistory
                :answers="previousOccurrence.question.answers"
                :templateAnswers="question.template.answers"
            />
        </SurveyAnswerHistory>
    </div>
</template>

<script>
import SurveyQuestionMixin from '@/components/resources/user/surveys/questions/SurveyQuestionMixin';
import SurveyAnswerHistory from '@/components/resources/user/surveys/questions/SurveyAnswerHistory.vue';
import SurveyChoiceHistory from '@/components/resources/user/surveys/questions/Choice/SurveyChoiceHistory.vue';

export default {
    name: 'SurveyYesNoCompletion',

    mixins: [
        SurveyQuestionMixin,
    ],

    components: {
        SurveyAnswerHistory,
        SurveyChoiceHistory,
    },
};
</script>

<style lang="scss" scoped>
@import '../SurveyQuestion';

.question-completion {
    .list-answer {
        flex-direction: row;

        &-item {
            justify-content: center;
            width: 164px;
        }
    }
}
</style>
