import HoldingIndex from '@/router/pages/holding/Index.vue';
import HoldingDashboard from '@/router/pages/holding/Dashboard.vue';
import HoldingSettings from '@/router/pages/holding/Settings.vue';
import HoldingSettingsUsersCount from '@/router/pages/holding/settings/UsersCount.vue';
import HoldingSettingsUsersList from '@/router/pages/holding/settings/PageUsersList.vue';
import HoldingResources from '@/router/pages/holding/Resources.vue';
import HoldingResourcesActionsCount from '@/router/pages/holding/resources/Actions/ActionsCount.vue';
import HoldingResourcesActionsList from '@/router/pages/holding/resources/Actions/PageActionsList.vue';
import HoldingResourcesTrainingsCount from '@/router/pages/holding/resources/Trainings/TrainingsCount.vue';
import HoldingResourcesTrainingsList from '@/router/pages/holding/resources/Trainings/PageTrainingsList.vue';
import HoldingResourcesDocumentsCount from '@/router/pages/holding/resources/Documents/DocumentsCount.vue';
import HoldingResourcesDocumentsList from '@/router/pages/holding/resources/Documents/PageDocumentsList.vue';
import HoldingResourcesSoftwareCount from '@/router/pages/holding/resources/Software/SoftwareCount.vue';
import HoldingResourcesSoftwareList from '@/router/pages/holding/resources/Software/PageSoftwareList.vue';
import HoldingResourcesQuizzesCount from '@/router/pages/holding/resources/Quizzes/QuizzesCount.vue';
import HoldingResourcesQuizzesList from '@/router/pages/holding/resources/Quizzes/PageQuizzesList.vue';
import HoldingResourcesNudgesCount from '@/router/pages/holding/resources/Nudges/NudgesCount.vue';
import HoldingResourcesNudgesList from '@/router/pages/holding/resources/Nudges/PageNudgesList.vue';
import HoldingResourcesEventsCount from '@/router/pages/holding/resources/Events/EventsCount.vue';
import HoldingResourcesEventsList from '@/router/pages/holding/resources/Events/PageEventsList.vue';
import HoldingResourcesEmailsCount from '@/router/pages/holding/resources/Emails/EmailsCount.vue';
import HoldingResourcesEmailsList from '@/router/pages/holding/resources/Emails/PageEmailsList.vue';
import HoldingResourcesSurveysCount from '@/router/pages/holding/resources/Surveys/SurveysCount.vue';
import HoldingResourcesSurveysList from '@/router/pages/holding/resources/Surveys/PageSurveysList.vue';
import HoldingResourcesEquipmentsCount from '@/router/pages/holding/resources/Equipments/EquipmentsCount.vue';
import HoldingResourcesEquipmentsList from '@/router/pages/holding/resources/Equipments/PageEquipmentsList.vue';
import HoldingResourcesRequirementsCount from '@/router/pages/holding/resources/Requirements/RequirementsCount.vue';
import HoldingResourcesRequirementsList from '@/router/pages/holding/resources/Requirements/PageRequirementsList.vue';
import HoldingWelcomeChatbotCreatePassword from '@/router/pages/holding/WelcomeChatbot/CreatePassword.vue';
import HoldingWelcomeChatbotConfirm from '@/router/pages/holding/WelcomeChatbot/Confirm.vue';

export default [
    {
        path: '/Holding',
        component: HoldingIndex,
        redirect: {
            name: 'HoldingDashboard',
        },
        meta: {
            holding: true,
        },
        children: [
            {
                path: 'Dashboard',
                name: 'HoldingDashboard',
                component: HoldingDashboard,
            },
            {
                path: 'Settings',
                name: 'HoldingSettings',
                component: HoldingSettings,
                redirect: {
                    name: 'HoldingSettingsUsersCount',
                },
                children: [
                    {
                        path: 'Users',
                        name: 'HoldingSettingsUsersCount',
                        component: HoldingSettingsUsersCount,
                    },
                    {
                        path: 'Users/:company_id',
                        name: 'HoldingSettingsUsersList',
                        component: HoldingSettingsUsersList,
                    },
                ],
            },
            {
                path: 'Resources',
                name: 'HoldingResources',
                redirect: {
                    name: 'HoldingResourcesDocumentsCount',
                },
                component: HoldingResources,
                children: [
                    {
                        path: 'Actions',
                        name: 'HoldingResourcesActionsCount',
                        component: HoldingResourcesActionsCount,
                    },
                    {
                        path: 'Actions/:company_id',
                        name: 'HoldingResourcesActionsList',
                        component: HoldingResourcesActionsList,
                    },
                    {
                        path: 'Trainings',
                        name: 'HoldingResourcesTrainingsCount',
                        component: HoldingResourcesTrainingsCount,
                    },
                    {
                        path: 'Trainings/:company_id',
                        name: 'HoldingResourcesTrainingsList',
                        component: HoldingResourcesTrainingsList,
                    },
                    {
                        path: 'Documents',
                        name: 'HoldingResourcesDocumentsCount',
                        component: HoldingResourcesDocumentsCount,
                    },
                    {
                        path: 'Documents/:company_id',
                        name: 'HoldingResourcesDocumentsList',
                        component: HoldingResourcesDocumentsList,
                    },
                    {
                        path: 'Software',
                        name: 'HoldingResourcesSoftwareCount',
                        component: HoldingResourcesSoftwareCount,
                    },
                    {
                        path: 'Software/:company_id',
                        name: 'HoldingResourcesSoftwareList',
                        component: HoldingResourcesSoftwareList,
                    },
                    {
                        path: 'Quizzes',
                        name: 'HoldingResourcesQuizzesCount',
                        component: HoldingResourcesQuizzesCount,
                    },
                    {
                        path: 'Quizzes/:company_id',
                        name: 'HoldingResourcesQuizzesList',
                        component: HoldingResourcesQuizzesList,
                    },
                    {
                        path: 'Nudges',
                        name: 'HoldingResourcesNudgesCount',
                        component: HoldingResourcesNudgesCount,
                    },
                    {
                        path: 'Nudges/:company_id',
                        name: 'HoldingResourcesNudgesList',
                        component: HoldingResourcesNudgesList,
                    },
                    {
                        path: 'Events',
                        name: 'HoldingResourcesEventsCount',
                        component: HoldingResourcesEventsCount,
                    },
                    {
                        path: 'Events/:company_id',
                        name: 'HoldingResourcesEventsList',
                        component: HoldingResourcesEventsList,
                    },
                    {
                        path: 'Emails',
                        name: 'HoldingResourcesEmailsCount',
                        component: HoldingResourcesEmailsCount,
                    },
                    {
                        path: 'Emails/:company_id',
                        name: 'HoldingResourcesEmailsList',
                        component: HoldingResourcesEmailsList,
                    },
                    {
                        path: 'Surveys',
                        name: 'HoldingResourcesSurveysCount',
                        component: HoldingResourcesSurveysCount,
                    },
                    {
                        path: 'Surveys/:company_id',
                        name: 'HoldingResourcesSurveysList',
                        component: HoldingResourcesSurveysList,
                    },
                    {
                        path: 'Equipments',
                        name: 'HoldingResourcesEquipmentsCount',
                        component: HoldingResourcesEquipmentsCount,
                    },
                    {
                        path: 'Equipments/:company_id',
                        name: 'HoldingResourcesEquipmentsList',
                        component: HoldingResourcesEquipmentsList,
                    },
                    {
                        path: 'Requirements',
                        name: 'HoldingResourcesRequirementsCount',
                        component: HoldingResourcesRequirementsCount,
                    },
                    {
                        path: 'Requirements/:company_id',
                        name: 'HoldingResourcesRequirementsList',
                        component: HoldingResourcesRequirementsList,
                    },
                ],
            },
        ],
    },
    {
        path: '/Holding/WelcomeChatbot/CreatePassword',
        component: HoldingWelcomeChatbotCreatePassword,
        name: 'HoldingWelcomeChatbotCreatePassword',
        meta: {
            holding: true,
        },
    },
    {
        path: '/Holding/WelcomeChatbot/Confirm',
        component: HoldingWelcomeChatbotConfirm,
        name: 'HoldingWelcomeChatbotConfirm',
        meta: {
            holding: true,
        },
    },
];
