<template>
    <div>
        <transition leave-active-class="leave-fade-dashboard">
            <ListActionsItemHtml
                class="mb-10"
                :title="item.title"
                :is-late="isLate"
                :action="{}"
                :date="date"
                :show-wrapper="showWrapper"
                :is-toggled="groupedToggled"
                :can-validate="false"
                :is-selectable="isSelectable"
                :is-selected="isWrapperSelected"
                :show-multi-select="showMultiSelect"
                :cypress="cypress"
                @onSelectedAction="onSelectedAction"
                @onClick="onItemClick"
            >
                <template slot="extra-right">
                    <CheckGreenIcon v-if="allTasksDone" />
                    <div
                        v-else
                        class="extra-right branding-bg"
                    >
                        {{ item.groupedTasks.length }}
                    </div>
                </template>
                <template
                    v-if="groupedToggled"
                    slot="subItems"
                >
                    <div class="mb-20">
                        <transition-group name="slide">
                            <UserProgramTaskWrapperItem
                                v-for="(groupedTask, index) in item.groupedTasks"
                                :key="index"
                                :item="groupedTask"
                                :from="from"
                                :show-multi-select="showMultiSelect"
                                :selected-action-list="selectedActionList"
                                class="mb-10"
                            />
                        </transition-group>
                    </div>
                </template>
            </ListActionsItemHtml>
        </transition>
    </div>
</template>

<script>
import ListActionsItemHtml from '@/components/pages/dashboard/actions/ListActionsItemHtml.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import cypressMixin from '@/mixins/CypressMixin';
import UserProgramTaskWrapperItem from './UserProgramTaskWrapperItem.vue';
import CheckGreenIcon from '../globals/CheckGreenIcon.vue';

export default {
    name: 'UserProgramTaskWrapper',
    components: {
        ListActionsItemHtml,
        UserProgramTaskWrapperItem,
        CheckGreenIcon,
    },
    mixins: [cypressMixin],
    props: {
        from: {
            type: String,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
        showWrapper: {
            type: Boolean,
            default: true,
        },
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        selectedActionList: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            groupedToggled: false,
        };
    },

    computed: {
        date() {
            const {
                datetime_start,
                datetime_end,
            } = this.item;

            if (!datetime_end) return datetime_start;

            return datetime_end;
        },

        /**
         * @returns {number[]}
         */
        actionsIds() {
            return this.item.groupedTasks.map((action) => action.id);
        },

        /**
         * @returns {boolean}
         */
        allTasksDone() {
            return this.item.groupedTasks.every((task) => task.status === CompanyUserProgramTask.STATUS_DONE);
        },

        participants() {
            return this.item.company_user_program_task_participant.models;
        },

        isSelectable() {
            return this.item.resource !== 'company_survey'
                && this.item.resource !== 'company_requirement_category'
                && this.item.resource !== 'company_user_quiz'
                && this.item.resource !== 'company_email_custom';
        },

        isTaskParticipant() {
            return this.item.company_user_program_task_participant.models.some(
                (participant) => participant.company_user_id == this.shared.session.companyUser.id,
            );
        },

        isLate() {
            return this.$Utils.moment(this.date).isBefore(this.$Utils.moment(), 'day')
                && this.item.resource !== 'company_planning';
        },

        isWrapperSelected() {
            let ret = 0;
            if (this.actionsIds.every((actionId) => this.selectedActionList.includes(actionId))) {
                ret = 1;
            } else if (this.actionsIds.some((actionId) => this.selectedActionList.includes(actionId))) {
                ret = 2;
            }
            return ret;
        },
    },

    methods: {
        onItemClick() {
            this.groupedToggled = !this.groupedToggled;
        },

        isDateInPast(date) {
            if (!date) return;
            const { moment } = this.$Utils;
            return moment(date, this.shared.session.companyUser.company_language.key == 'fr' ? 'DD/MM/YY' : 'MM/DD/YY').isBefore(moment().startOf('day'));
        },

        onSelectedAction() {
            this.item.groupedTasks.forEach((task) => {
                if (!this.selectedActionList.includes(task.id) && this.canSelect(task)) {
                    this.selectedActionList.push(task.id);
                } else if (this.selectedActionList.includes(task.id) && this.canSelect(task)) {
                    this.selectedActionList.splice(this.selectedActionList.indexOf(task.id), 1);
                }
            });
        },

        canSelect(task) {
            const { resource } = task;

            return resource !== 'company_software'
                && resource !== 'company_survey'
                && resource !== 'company_requirement_category'
                && resource !== 'company_user_quiz'
                && resource !== 'company_email_custom';
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.extra-right {
    width: 23px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: $white;
}
</style>
