<template>
    <div clas="result-satisfaction">
        <template v-if="subtype === oCompanySurveyQuestion.SUBTYPE_SATISFACTION_SCALE_SMILEY">
            <div
                v-for="(answer, iSurveyQuestionAnswerIndex) in surveyQuestion.company_survey_question_answer.models"
                :key="iSurveyQuestionAnswerIndex"
                :class="['d-flex', {
                    'pb-10': (iSurveyQuestionAnswerIndex < surveyQuestion.company_survey_question_answer.models.length - 1)
                }]"
            >
                <img
                    :src="answer.iconPath"
                    class="mr-20"
                >
                <div class="result-satisfaction-smiley-align-center">
                    {{ answer.company_survey_question_answer_locale.models[0].text }}
                </div>
                <div
                    v-if="surveyQuestion.type !== 'text'"
                    :class="['result-satisfaction-smiley-align-center', 'icon-check', {'ml-20': surveyQuestion.type === 'satisfaction_scale'}]"
                >
                    <template v-if="hasAnsweredThisOne(answer.id, userSurveyQuestionAnswer.models)">
                        <FontAwesomeIcon icon="check" />
                    </template>
                </div>
            </div>
        </template>
        <template v-else-if="subtype === oCompanySurveyQuestion.SUBTYPE_SATISFACTION_SCALE_NPS">
            {{ userSurveyQuestionAnswer.models[0].company_survey_question_answer.company_survey_question_answer_locale.models[0].text }} / 10
        </template>
    </div>
</template>
<script>
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import CompanyUserSurveyQuestionAnswerCollection from '@/models/CompanyUserSurveyQuestionAnswerCollection';

export default {
    name: 'ResultSatisfactionScale',
    props: {
        surveyQuestion: {
            type: CompanySurveyQuestion,
            required: true,
        },
        userSurveyQuestionAnswer: {
            type: CompanyUserSurveyQuestionAnswerCollection,
            required: true,
        },
        subtype: {
            type: String,
            default: CompanySurveyQuestion.SUBTYPE_SATISFACTION_SCALE_SMILEY,
        },
    },

    data() {
        return {
            oCompanySurveyQuestion: CompanySurveyQuestion,
        };
    },

    methods: {
        hasAnsweredThisOne(iCompanySurveyQuestionAnswerId, aCompanyUserSurveyQuestionAnswer) {
            return aCompanyUserSurveyQuestionAnswer.some((oCompanyUserSurveyQuestionAnswer) => oCompanyUserSurveyQuestionAnswer.company_survey_question_answer_id === iCompanySurveyQuestionAnswerId);
        },
    },
};
</script>
<style lang="scss" scoped src="./ResultSatisfactionScale.scss">
</style>
