import EmptySection from './EmptySection';
import VideoHeaderSection from './VideoHeaderSection';
import CardImgLeftSection from './CardImgLeftSection';
import CardImgRightSection from './CardImgRightSection';
import CardVideoLeftSection from './CardVideoLeftSection';
import CardVideoRightSection from './CardVideoRightSection';
import DoubleCardImgLeftSection from './DoubleCardImgLeftSection';
import DoubleCardImgRightSection from './DoubleCardImgRightSection';
import TextImgRightSection from './TextImgRightSection';
import StatisticsSection from './StatisticsSection';
import CarouselSection from './CarouselSection';
import QuoteSection from './QuoteSection';
import TripleCardImgTopSection from './TripleCardImgTopSection';
import SocialNetworksSection from './SocialNetworksSection';

const templates = {
    EmptySection,
    VideoHeaderSection,
    CardImgLeftSection,
    CardImgRightSection,
    CardVideoLeftSection,
    CardVideoRightSection,
    DoubleCardImgLeftSection,
    DoubleCardImgRightSection,
    TextImgRightSection,
    StatisticsSection,
    CarouselSection,
    QuoteSection,
    TripleCardImgTopSection,
    SocialNetworksSection,
};

export default templates;
