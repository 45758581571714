<template>
    <StepperTabLayout
        v-if="loaded"
    >
        <template #main>
            <HtFormCard>
                <div class="mb-4">
                    <HtLanguageSelect
                        :id="'language'"
                        v-model="currentLanguage"
                        :name="'language'"
                        :cypress="'language'"
                    />

                    <HtFormInput
                        :id="'name'"
                        ref="nameInput"
                        v-model="item.locales_by_key[currentLanguage.key].name"
                        v-validate.disable="getValidationInputName"
                        :label="translate('Program name')"
                        :data-vv-as="translate('name')"
                        :name="'name'"
                        :type="'text'"
                        :show-asterisk="true"
                        :show-optionnal="false"
                        :cypress="'name'"
                    />

                    <HtFormTextarea
                        :id="'description'"
                        v-model="
                            item.locales_by_key[currentLanguage.key].description
                        "
                        v-validate.disable="getValidationInputDescription"
                        :name="'description'"
                        :label="translate('Description')"
                        :maxlength="255"
                        :cypress="'description'"
                    />

                    <HtFormSelect
                        :id="'type'"
                        v-model="item.ht_program_type_id"
                        v-validate.disable="'required'"
                        :name="'type'"
                        :label="translate('Program Type')"
                        :disabled="!item.isNew()"
                        :options="programTypesOptions"
                        :show-asterisk="true"
                        :cypress="'programType'"
                        @input="onProgramTypeChange"
                    />

                    <HtFormSelect
                        :id="'chatbot'"
                        v-model="item.company_chatbot_id"
                        v-validate.disable="'required'"
                        :name="'chatbot'"
                        :label="translate('Chatbot')"
                        :disabled="!item.isNew()"
                        :options="programChatbotOptions"
                        :show-asterisk="true"
                        :cypress="'chatbot'"
                    />

                    <HtToast
                        :title="translate('About the chatbot')"
                        :message="translate('The chatbot must be used to create at least the following identifiers')"
                        class="mb-5"
                        type="info"
                    />

                    <HtFormSelect
                        :id="'key_date'"
                        v-validate.disable="'required'"
                        :value="mainKeyDate"
                        :name="'key_date'"
                        :label="translate('Key date')"
                        :options="keyDateOptions"
                        :maxlength="255"
                        :max-height="230"
                        :open-direction="'auto'"
                        :show-asterisk="true"
                        :cypress="'mainDate'"
                        @input="updateMainKeyDate"
                    />

                    <HtFormGroup
                        :label="translate('Complementary dates')"
                        :is-required="false"
                        :show-asterisk="false"
                    >
                        <div
                            v-for="date, key in optionalKeyDates"
                            :key="date.id + '-' + date.company_keydate_id"
                            class="complementaryDate"
                        >
                            <HtFormSelect
                                :id="'extra_date' + key"
                                v-validate.disable="'required'"
                                class="mb-0"
                                style="flex-grow:1"
                                :name="'extra_date' + key"
                                :options="optionalKeyDateOptions(date.id)"
                                :maxlength="255"
                                :max-height="230"
                                :open-direction="'auto'"
                                :show-asterisk="false"
                                :is-required="true"
                                :value="date.id"
                                :cypress="'optional-'+key"
                                @input="event => updateOptionalKeyDate(date.id, event)"
                            />
                            <FontAwesomeIcon
                                v-if="!date.company_keydate_id"
                                icon="do-not-enter"
                                class="clickable"
                                @click="removeDate(date.id)"
                            />
                        </div>
                        <span
                            v-if="showAddDateButton"
                            class="branding-color clickable"
                            :data-cy="'addDateButton'"
                            @click="addOptionalDate"
                        >+ <t>Add a complementary date</t></span>
                    </HtFormGroup>

                    <HtFormSwitch
                        id="switch_enabled"
                        v-model="item.switch_enabled"
                        :label="
                            translate(
                                'Switch between professional email and personal email'
                            )
                        "
                        name="switch_enabled"
                        :style="{ 'margin-top': '20px' }"
                        :show-optional="false"
                        :tooltip="translate(
                            'No notifications will be sent if the toggle is not enabled'
                        )"
                    />
                    <HtFormRange
                        v-if="item.switch_enabled"
                        id="switch_offset"
                        v-model="item.switch_offset"
                        :label="
                            translate(
                                'Effective mail switch date (working days relative to key date)'
                            )
                        "
                        :is-required="true"
                        :min="-30"
                        :max="30"
                        :step="1"
                        name="switch_offset"
                    />
                    <HtFormSelect
                        v-if="item.switch_enabled"
                        id="emailSwitchType"
                        v-model="item.switch_type"
                        :placeholder="translate('Switch type')"
                        :options="emailSwitchType"
                        name="emailSwitchType"
                        :allow-empty="false"
                        :label="translate('Switch email from')"
                        :show-optional="false"
                        @open="$emit('focus')"
                        @close="$emit('blur')"
                    />
                    <HtFormSwitch
                        id="toggle-start-another-program"
                        v-model="isStartAnotherProgramToggled"
                        name="toggle-start-another-program"
                        cypress="toggle-start-another-program"
                        :label="translate('Start another program after completion')"
                        :on-label="translate('Yes')"
                        :off-label="translate('No')"
                        :show-optional="false"
                        :description="translate('Once program is completed for all the participants, another one will be created in draft for the specified type')"
                    />
                    <HtFormSelect
                        v-if="isStartAnotherProgramToggled"
                        id="followingProgramId"
                        v-model="item.following_program_id"
                        :placeholder="translate('Following program')"
                        :options="programOptions"
                        name="followingProgramId"
                        :show-optional="false"
                        @open="$emit('focus')"
                        @close="$emit('blur')"
                    />
                    <HtFormSwitch
                        id="toggle-allow-self-enroll"
                        v-model="item.is_self_enrollable"
                        name="toggle-allow-self-enroll"
                        cypress="toggle-allow-self-enroll"
                        :label="translate('Allow self enroll')"
                        :on-label="translate('Yes')"
                        :off-label="translate('No')"
                        :show-optional="false"
                        :description="translate('Allow users that have the permission to self enroll on this program')"
                    />
                </div>
            </HtFormCard>
        </template>
        <template #buttonsFooter>
            <Button
                :state="buttonState"
                @click="onSave"
            >
                <t>Continue</t>
            </Button>
        </template>
    </StepperTabLayout>
</template>

<script>
import StepperTabLayout from '@/components/globals/StepperTabLayout.vue';
import CompanyProgramCollection from '@/models/CompanyProgramCollection';
import CompanyEntityCollection from '@/models/CompanyEntityCollection';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import CompanyProgramKeyDateCollection from '@/models/CompanyProgramKeyDateCollection';
import api from '@/store/api';
import CompanyChatbot from '@/models/CompanyChatbot';
import HtToast from '@/components/globals/HtToast.vue';
import Button from '../../../globals/Button.vue';
import CompanyProgram from '../../../../models/CompanyProgram';
import HtFormCard from '../../../globals/HtFormCard.vue';
import HtFormInput from '../../../globals/HtFormInput.vue';
import HtFormSelect from '../../../globals/HtFormSelect.vue';
import HtFormMultiSelect from '../../../globals/HtFormMultiSelect.vue';
import HtFormTextarea from '../../../globals/HtFormTextarea.vue';
import HtLanguageSelect from '../../../globals/HtLanguageSelect.vue';
import HtProgramKeyDateCollection from '../../../../models/HtProgramKeyDateCollection';
import HtProgramType from '../../../../models/HtProgramType';
import HtProgramTypeCollection from '../../../../models/HtProgramTypeCollection';

export default {
    name: 'ProgramFormSettings',
    components: {
        HtToast,
        Button,
        HtFormCard,
        HtFormInput,
        HtFormSelect,
        HtFormMultiSelect,
        HtFormTextarea,
        HtLanguageSelect,
        StepperTabLayout,
        HtFormSwitch,
    },
    props: {
        item: {
            type: CompanyProgram,
            required: true,
        },
    },

    shared: {
        data: {
            companyProgramCollection: {
                type: CompanyProgramCollection,
                default: null,
            },
        },
        queries: {
            companyProgramCollection: {
                type: CompanyProgramCollection,
                default: null,
            },
        },
    },

    data() {
        return {
            currentLanguage: this.shared.session.company.company_language,
            htProgramTypeCollection: new HtProgramTypeCollection([
                'id',
                'slug',
            ]).where([
                ['status', '=', HtProgramType.STATUS_ACTIVE],
                ['slug', '!=', HtProgramType.SLUG_DEFAULT],
            ]),

            htProgramKeyDateCollection: new HtProgramKeyDateCollection([
                'id',
            ]).with({
                locales: (query) => {
                    query.select(['id', 'name', 'language_key']);
                },
            }),

            chatbotCollection: [],

            entitiesCollection: new CompanyEntityCollection([
                'id',
                'name',
                'slug',
                'is_heyteam',
            ]).where([['is_enabled', '=', true]]),
            keyDates: [],
            loaded: false,
            isStartAnotherProgramToggled: false,
        };
    },

    computed: {
        buttonState() {
            return this.item._state.isSaving ? 'loading' : 'idle';
        },

        emailSwitchType() {
            return [
                {
                    id: 'to-pro',
                    name: this.translate('Personal to professional'),
                },
                {
                    id: 'to-perso',
                    name: this.translate('Professional to personal'),
                },
            ];
        },

        /**
         * options pour le multiselect des des program_types
         * @returns {{id: number, name: string}[]}
         */
        programTypesOptions() {
            return this.htProgramTypeCollection.models.map((type) => ({
                id: type.id,
                name: type.slug,
            }));
        },

        programChatbotOptions() {
            return this.chatbotCollection.map((chatbot) => ({
                id: chatbot.id,
                name: this.translate(CompanyChatbot.slugToName[chatbot.slug]),
            }));
        },

        programOptions() {
            return this.$store.state.programs.programs
                .filter((program) => program.id !== this.item.id)
                .map((program) => ({
                    id: program.id,
                    name: program.name,
                }));
        },

        /**
         * options pour le multiselect des program_locales
         * @returns {{id: number, name: string}[]}
         */
        keyDateOptions() {
            return this.htProgramKeyDateCollection.models.map((key_date) => ({
                id: key_date.id,
                name: key_date.localize('name'),
            }));
        },

        getValidationInputName() {
            return this.shared.session.company.company_language.key
                === this.currentLanguage.key
                ? 'required'
                : '';
        },

        getValidationInputDescription() {
            return 'max:255';
        },

        mainKeyDate() {
            return this.keyDates
                .find((date) => date.is_main)?.id || null;
        },

        optionalKeyDates() {
            return this.keyDates
                .filter((date) => !date.is_main);
        },

        showAddDateButton() {
            return this.optionalKeyDateOptions(0).length > 0;
        },
    },

    watch: {
        isStartAnotherProgramToggled() {
            if (!this.isStartAnotherProgramToggled) {
                this.item.following_program_id = null;
            }
        },
    },

    async created() {
        await this.htProgramTypeCollection.get();
        await this.htProgramKeyDateCollection.get();
        await this.entitiesCollection.get();
        this.chatbotCollection = (await api.chatbot.all()).data.data;

        this.item.key_dates.models.forEach((keyDate) => {
            this.keyDates = [
                ...this.keyDates,
                {
                    id: keyDate.ht_program_key_date_id,
                    company_keydate_id: keyDate.id,
                    is_main: keyDate.is_main,
                },
            ];
        });
        if (!this.keyDates.some((date) => date.is_main === true)) {
            this.keyDates.push({
                id: this.keyDateOptions[0].id,
                company_keydate_id: 0,
                is_main: true,
            });
        }
        this.loaded = true;
        this.isStartAnotherProgramToggled = !!this.item.following_program_id;
    },

    methods: {
        removeDate(id) {
            const index = this.keyDates.indexOf(this.keyDates.find((date) => date.id === id));
            if (this.keyDates[index] && this.keyDates[index].company_keydate_id === 0) {
                this.keyDates.splice(index, 1);
            }
        },
        async onSave() {
            // Create / Update the collection of CompanyProgramKeyDates
            this.item.key_dates = new CompanyProgramKeyDateCollection([
                'id',
                'ht_program_key_date_id',
                'is_main',
            ]);
            for (let i = 0; i < this.keyDates.length; i++) {
                const date = this.keyDates[i];
                const keyDate = this.item.key_dates.new();
                keyDate.ht_program_key_date_id = date.id;
                keyDate.is_main = date.is_main;
                keyDate.id = date.company_keydate_id || null;
                this.item.key_dates.add(keyDate);
            }

            // Submit the form
            this.$validator.validateAll().then((isValid) => {
                if (isValid) {
                    this.item.save().then(() => {
                        this.shared.queries.companyProgramCollection
                            .get()
                            .then((data) => {
                                this.shared.data.companyProgramCollection = data;
                            });

                        this.$store.dispatch('programs/fetchPrograms', { forceRefresh: true });

                        this.$emit('goTo', 'target');
                    });
                }
            });
        },
        updateMainKeyDate(value) {
            this.keyDates.find((date) => date.is_main === true).id = value;
        },

        addOptionalDate() {
            if (!this.showAddDateButton) return;
            this.keyDates.push({
                id: this.optionalKeyDateOptions(0)[0].id,
                company_keydate_id: 0,
                is_main: false,
            });
        },

        updateOptionalKeyDate(oldValue, value) {
            for (let i = 0; i < this.keyDates.length; i++) {
                if (this.keyDates[i].id === oldValue) {
                    this.keyDates[i].id = value;
                    break;
                }
            }
        },

        optionalKeyDateOptions(currentID) {
            return this.keyDateOptions.filter((option) => (option.id !== this.mainKeyDate
                && (!this.keyDates.some((opt) => opt.id === option.id)
                    || option.id === currentID)
            ));
        },

        onProgramTypeChange(programTypeId) {
            const programType = this.htProgramTypeCollection.models.find((type) => type.id === programTypeId);
            this.item.company_chatbot_id = (
                this.chatbotCollection.find((chatbot) => chatbot.slug === HtProgramType.programSlugToChatbotSlug[programType.slug])
            ).id;
        },
    },
};
</script>
<style lang="scss" scoped>
.clickable{
    cursor: pointer;
}
.complementaryDate {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    &:nth-child(1) {
        margin-top: 0
    }
}
</style>
