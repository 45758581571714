import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserNote from './CompanyUserNote';

export default class CompanyUserNoteCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserNote,
        };
    }
}
