<template>
    <HtModal ref="modal">
        <template #header>
            <t>Add a user</t>
        </template>
        <template #default>
            <HtChoiceNextButton
                :label="translate('Add existing collaborator')"
                cypress="add-existing-user"
                @click="existingEmployee"
            />
            <HtChoiceNextButton
                :label="translate('Invite new collaborator')"
                cypress="add-new-user"
                @click="newEmployee"
            />
            <HtChoiceNextButton
                :label="translate('New invitation session')"
                cypress="add-new-invitation-session"
                @click="newInvitationSession"
            />
        </template>
    </HtModal>
</template>

<script>
import HtChoiceNextButton from '@/components/globals/HtChoiceNextButton.vue';

export default {
    name: 'UserSelectionModal',
    components: {
        HtChoiceNextButton,
    },
    props: {
        companyProgramId: {
            type: Number,
            required: true,
        },
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        newEmployee() {
            this.$router.push({
                name: 'Enrolling',
                query: {
                    program_id: this.companyProgramId,
                },
            });
        },
        existingEmployee() {
            this.$emit('addExistingUser');
        },
        newInvitationSession() {
            this.$emit('create-new-invitation-session');
        },
    },
};
</script>
