import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserQuiz from './CompanyUserQuiz';

export default class CompanyUserQuizCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserQuiz,
        };
    }

    modelCustomAttributes() {
        return {
            company_user_id: null,
            company_user_participant_id: null,
        };
    }
}
