import Model from '@tony.caron/node-model-proxy/Model';
import Notifier from '../Notifier';
import CompanyCustomizeCollection from './CompanyCustomizeCollection';
import CompanyFile from './CompanyFile';
import I18n from '../modules/i18n/I18n';

export default class CompanyCustomize extends Model {
    modelConfig() {
        return {
            collection: CompanyCustomizeCollection,
        };
    }

    modelInConvertor() {
        return {
            is_enabled: (n) => Boolean(n),
        };
    }

    modelRelations() {
        return {
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
        };
    }

    modelEvents() {
        return {
            saved() {
                Notifier.getInstance('App').showInfo(
                    I18n.translate('You have successfully saved it'),
                );
            },

            updated: () => {
                if (this.type === 'page' && this.subtype === 'team') {
                    if (this.is_enabled === true) {
                        if (this.content.before_arrival.selected === 'allMembers' && this.content.after_arrival.selected === 'allMembers') {
                            Notifier.getInstance('App').showInfo(
                                I18n.translate('The users will now see all the members of the company before and after their arrival date'),
                            );
                        } else if (this.content.before_arrival.selected === 'allMembers' && this.content.after_arrival.selected === 'yourDepartment') {
                            Notifier.getInstance('App').showInfo(
                                I18n.translate('The users will now see all the members of the company before their arrival date, and only the members of their department after their arrival date'),
                            );
                        } else if (this.content.before_arrival.selected === 'allMembers' && this.content.after_arrival.selected === 'yourOffice') {
                            Notifier.getInstance('App').showInfo(
                                I18n.translate('The users will now see all the members of the company before their arrival date, and only the members of their site after their arrival date'),
                            );
                        } else if (this.content.before_arrival.selected === 'yourDepartment' && this.content.after_arrival.selected === 'allMembers') {
                            Notifier.getInstance('App').showInfo(
                                I18n.translate('The users will now see the members of their department before their arrival date, and all the members of the company after their arrival date'),
                            );
                        } else if (this.content.before_arrival.selected === 'yourDepartment' && this.content.after_arrival.selected === 'yourOffice') {
                            Notifier.getInstance('App').showInfo(
                                I18n.translate('The users will now see the members of their department before their arrival date, and only the members of their site after their arrival date'),
                            );
                        } else if (this.content.before_arrival.selected === 'yourDepartment' && this.content.after_arrival.selected === 'yourDepartment') {
                            Notifier.getInstance('App').showInfo(
                                I18n.translate('The users will now see the members of their department before and after their arrival date'),
                            );
                        } else if (this.content.before_arrival.selected === 'yourOffice' && this.content.after_arrival.selected === 'yourOffice') {
                            Notifier.getInstance('App').showInfo(
                                I18n.translate('The users will now see the members of their site before and after their arrival date'),
                            );
                        } else if (this.content.before_arrival.selected === 'yourOffice' && this.content.after_arrival.selected === 'allMembers') {
                            Notifier.getInstance('App').showInfo(
                                I18n.translate('The users will now see the members of their site before their arrival date, and all the members of the company after their arrival date'),
                            );
                        } else if (this.content.before_arrival.selected === 'yourOffice' && this.content.after_arrival.selected === 'yourDepartment') {
                            Notifier.getInstance('App').showInfo(
                                I18n.translate('The users will now see the members of their site before their arrival date, and only the members of their department after their arrival date'),
                            );
                        }
                    } else {
                        Notifier.getInstance('App').showInfo(
                            I18n.translate('The users will not see the members of the company'),
                        );
                    }
                } else {
                    Notifier.getInstance('App').showInfo(
                        I18n.translate('You have successfully updated it'),
                    );
                }
            },

            deleted: () => {
                if (this.type === 'carousel') {
                    Notifier.getInstance('App').showInfo(
                        I18n.translate('You have successfully deleted the carousel'),
                    );
                } else {
                    Notifier.getInstance('App').showInfo(
                        I18n.translate('You have successfully deleted it'),
                    );
                }
            },
        };
    }
}
