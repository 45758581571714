<template>
    <VueDragResize
        :x="leftPosition"
        :y="topPosition"
        :parent-h="parentHeight"
        w="auto"
        h="auto"
        :is-draggable="canMove"
        :is-resizable="false"
        :parent-limitation="true"
        @dragging="(newRect) => onDragging(newRect)"
    >
        <div
            class="d-flex align-items-center draggable-items"
            @mousedown="mousedown"
            @mouseup="mouseup"
        >
            <InputTextField
                v-if="displayText(field.type)"
                :field="field"
                :can-delete="canDelete"
                :can-mandatorize="canMandatorize"
                :can-edit="canEdit"
                :field-validator="fieldValidator"
                @delete="remove"
                @mandatorize="mandatorize"
                @setTitle="setTitle"
                @setRole="setRole"
                @setRule="setRule"
                @setSpecificRule="setSpecificRule"
            />
            <InputCheckboxField
                v-else-if="field.type === 'checkbox'"
                :field="field"
                :can-delete="canDelete"
                :can-edit="canEdit"
                :field-validator="fieldValidator"
                @delete="remove"
                @setTitle="setTitle"
                @setRole="setRole"
            />
            <component
                :is="'VariableField'"
                v-else
                :field="field"
                :is-admin-component="isAdminComponent"
                @delete="remove"
                @setTitle="setTitle"
                @setRole="setRole"
            />
        </div>
    </VueDragResize>
</template>

<script>

import VueDragResize from 'vue-drag-resize';
import InputTextField from '@/components/DocumentEditor/Inputs/InputTextField.vue';
import InputCheckboxField from '@/components/DocumentEditor/Inputs/InputCheckboxField.vue';
import VariableField from '@/components/DocumentEditor/Inputs/VariableField.vue';
import InputActionMixin from '@/components/DocumentEditor/mixins/InputActionMixin';
import FieldValidator from './FieldValidator';

export default {
    name: 'DraggableItem',
    components: {
        VueDragResize, InputTextField, InputCheckboxField, VariableField,
    },
    mixins: [InputActionMixin],
    props: {
        parentHeight: {
            type: Number,
            required: true,
        },
        startResize: {
            type: Boolean,
            required: false,
        },
        isAdminComponent: {
            type: Boolean,
            default() {
                return false;
            },
        },
        field: {
            type: Object,
            required: true,
        },
        fieldValidator: {
            type: FieldValidator,
            default: () => {},
        },
    },
    data() {
        return {
            dragOn: true,
            mouseIsDown: false,
            isDragged: false,
            focus: false,
            timeoutID: null,
            fieldErrors: [],
        };
    },
    computed: {
        leftPosition() {
            return Math.trunc(this.field.left_position);
        },
        topPosition() {
            return Math.trunc(this.field.top_position);
        },
    },
    methods: {
        remove() {
            this.$emit('delete');
        },
        mandatorize() {
            this.$emit('mandatorize');
        },
        setSpecificRule(v) {
            this.$emit('setSpecificRule', v);
        },
        setTitle(v) {
            this.$emit('setTitle', v);
        },
        setRole(roleId) {
            this.$emit('setRole', roleId);
        },
        setRule(ruleInfos) {
            this.$emit('setRule', ruleInfos);
        },
        endEditing() {
            Object.assign(this.field, {
                editing: false,
            });
        },
        mousedown() {
            this.mouseIsDown = true;
        },
        mouseup() {
            this.mouseIsDown = false;
        },
        editing(event) {
            Object.assign(this.field, {
                editing: true,
            });

            if (event.target !== document.activeElement) {
                event.target.focus();
            }
        },
        onDragging(newRect) {
            const old = { ...this.field };
            Object.assign(this.field, {
                ...newRect,
                left_position: newRect.left,
                top_position: newRect.top,
            });

            const isDragged = this.isInDrag(old);
            if (!this.isDragged && isDragged) {
                this.isDragged = true;
            }
            clearTimeout(this.timeoutID);
            this.timeoutID = setTimeout(() => {
                if (!this.mouseIsDown) {
                    this.isDragged = false;
                }
            }, 100);
        },
        isInDrag(old) {
            const leftIsSame = Math.trunc(old.top_position) !== Math.trunc(this.topPosition);
            const topIsSame = Math.trunc(old.left_position) !== Math.trunc(this.leftPosition);
            return leftIsSame || topIsSame;
        },
        click(event) {
            if (this.isDragged) {
                event.stopPropagation();
                event.preventDefault();
            }
        },
        displayText(type) {
            return type !== 'checkbox' && type !== 'variable';
        },
    },
};
</script>

<style lang="scss" scoped>
.vdr.active:before {
    outline: none;
}
.draggable-items {
    background: white;
    position: relative;
}
</style>
