<template>
    <div style="display: flex; flex-direction: column; justify-content: center; width: 100%">
        <AIForm
            ref="aiForm"
            :settings="{maxAIContentLimit: 5}"
            :suggestions="suggestions"
            class="ai-form"
            :is-loading="isAILoading || isSaving"
            @onAskAI="askAI"
        />
        <div
            v-if="events.length > 0"
            class="row"
        >
            <div class="col-md-4">
                <div
                    class="sticky"
                >
                    <div
                        class="mb-10 wrapper-language-selector"
                        :class="{'opacity-50': isAILoading || isSaving}"
                    >
                        <HtFormMultiSelect
                            id="questionLanguageSelector"
                            v-model="currentLanguage"
                            :options="companyLanguage.enabled"
                            name="questionLanguageSelector"
                            is-single
                            :is-searchable="false"
                            :allow-empty="false"
                            class="mb-20"
                            :disabled="isAILoading || isSaving"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                            >
                                <div class="d-flex align-items-center">
                                    <div class="rounded-flag">
                                        <country-flag
                                            :country="option.codeFlag"
                                            size="big"
                                        />
                                    </div>

                                    <span class="ml-10">{{ option.name }}</span>
                                </div>
                            </template>

                            <template
                                slot="option"
                                slot-scope="{ option }"
                            >
                                <div class="d-flex align-items-center">
                                    <div class="rounded-flag">
                                        <country-flag
                                            :country="option.codeFlag"
                                            size="big"
                                        />
                                    </div>

                                    <span class="ml-10">{{ option.name }}</span>
                                </div>
                            </template>
                        </HtFormMultiSelect>
                    </div>

                    <div class="events-menu">
                        <h4>{{ events.length }} <t>event</t> | {{ events.length }} <t>events</t> </h4>
                        <ul>
                            <li
                                v-for="(eventResource, index) in events"
                                :key="index"
                                @click="setCurrentEvent(eventResource)"
                            >
                                <span>{{ eventResource.resource.translations.name[currentLanguage.key] }}</span>
                                <button
                                    v-if="!eventResource.is_saved"
                                    :disabled="isAILoading || isSaving"
                                    @click.prevent="(event) => removeEvent(event, eventResource, index)"
                                >
                                    <FontAwesomeIcon
                                        class="button-delete-icon"
                                        :icon="['far', 'trash-alt']"
                                    />
                                </button>
                                <FontAwesomeIcon
                                    v-else
                                    class="color-green"
                                    :icon="['fa', 'check-circle']"
                                />
                            </li>
                        </ul>

                        <div style="display: flex; justify-content: right; margin-top: 20px;">
                            <Button
                                :disabled="isAILoading || isSaving"
                                :state="buttonState"
                                cypress="event-add"
                                @click="saveEvents"
                            >
                                <t>Save</t>
                            </Button>
                        </div>
                    </div>

                    <div
                        v-if="isAILoading"
                        class="text-center"
                    >
                        <IconLoading
                            class="mb-4"
                            style="max-height: 30px;"
                        />
                        <t>Please wait for the events to be generated. This may take a few minutes.</t>
                    </div>
                </div>
            </div>

            <div class="col-md-8">
                <PlanningEventTemplateEdit
                    v-if="events.length > 0"
                    :id="null"
                    ref="planningEventTemplateEdit"
                    class="planning-event-template-visualizer"
                    style="flex: 1;"
                    :event="currentEvent"
                    :language="currentLanguage.key"
                    :show-save-btn="false"
                    @onSave="markEventAsSaved(currentEvent)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AIForm from '@/components/globals/AI/AIForm.vue';
import AIEventService from '@/services/AIEventService';
import PlanningEventTemplateEdit from '@/components/resources/planningEvent/PlanningEventTemplateEdit.vue';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import CompanyRoleCollection from '@/models/CompanyRoleCollection';
import IconLoading from '@/components/icons/IconLoading.vue';
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';
import UnauthorizedError from '@/services/Errors/UnauthorizedError';

export default {
    name: 'PlanningEventTemplateAIGenerator',
    components: {
        HtFormMultiSelect,
        IconLoading,
        PlanningEventTemplateEdit,
        AIForm,
    },
    data() {
        return {
            roles: [],
            currentLanguage: this.shared.session.company.company_language,
            companyLanguage: this.shared.session.company.company_languages,
            companyRoles: new CompanyRoleCollection([
                'id',
                'name',
                'alias',
                'description',
                'is_removable',
                'is_heyteam',
            ]).where([['is_assignable_resource', '=', 1]]),
            isAILoading: false,
            isSaving: false,
            currentEvent: null,
            suggestions: [
                { value: this.translate('Welcome to an employee') },
                { value: this.translate('Retirement') },
                { value: this.translate('Return from maternity leave') },
                { value: this.translate('Skill transfer') },
                { value: this.translate('Farewell party') },
            ],
            events: [],
        };
    },

    computed: {
        buttonState() {
            if (this.isAILoading || this.isSaving) {
                return 'loading';
            }

            return 'idle';
        },
        unsavedEvents() {
            return this.events.filter((event) => !event.is_saved);
        },
    },

    methods: {
        markEventAsSaved(eventResource) {
            eventResource.is_saved = true;
        },

        async askAI(params) {
            this.isAILoading = true;

            try {
                const aiService = new AIEventService(this.$env, RtwSharedSpace.store.session.capiToken);

                if (this.roles.length === 0) {
                    await this.companyRoles.get();

                    this.roles = this.companyRoles.$.models
                        .map((item) => ({
                            id: item.id,
                            alias: item.alias_translated,
                            selected: false,
                            name: item.name,
                            is_removable: item.is_removable,
                            is_heyteam: item.is_heyteam,
                        }));
                }

                await aiService.ask(params.input, params.tone, params.maxContent, params.languages, this.processResponse);
            } catch (error) {
                if (error instanceof UnauthorizedError) {
                    this.$Auth.logOut();

                    this.$router.push('/login');

                    return;
                }

                console.error('Error', error);
            } finally {
                this.isAILoading = false;
            }
        },
        processResponse(content) {
            this.replaceNewLinesWithBR(content);
            this.replaceSquareBracketsWithBraces(content);

            const event = this.transformToEventResource(content);

            this.events.push(event);

            if (!this.currentEvent) {
                this.$nextTick(() => {
                    this.currentEvent = event;
                });
            }
        },

        removeEvent(event, eventResource, index) {
            event.preventDefault();

            this.events.splice(index, 1);

            event.stopPropagation();
        },

        async setCurrentEvent(eventResource) {
            this.currentEvent = eventResource;

            await this.$refs.$validator.reset();
        },

        async saveEvents() {
            this.isSaving = true;

            for (let i = 0; i < this.events.length; i++) {
                this.currentEvent = null;
                this.currentEvent = this.events[i];

                if (this.currentEvent.is_saved === true) {
                    continue;
                }

                // eslint-disable-next-line no-await-in-loop
                const result = await this.$refs.planningEventTemplateEdit.onValidate();

                if (!result) {
                    break;
                }
            }

            if (this.unsavedEvents.length === 0) {
                setTimeout(() => {
                    this.routeToEventList();
                }, 500);

                return;
            }

            this.isSaving = false;
        },

        routeToEventList() {
            this.$router.push({ name: 'ResourcesEvents' });
        },

        replaceNewLinesWithBR(obj) {
            Object.entries(obj).forEach(([key, value]) => {
                if (typeof value === 'object') {
                    this.replaceNewLinesWithBR(value);
                } else if (typeof value === 'string') {
                    obj[key] = value.replace(/\n/g, '<br>');
                }
            });
        },

        replaceSquareBracketsWithBraces(obj) {
            Object.entries(obj).forEach(([key, value]) => {
                if (typeof value === 'object') {
                    this.replaceSquareBracketsWithBraces(value);
                } else if (typeof value === 'string') {
                    obj[key] = value.replace(/\[/g, '{').replace(/]/g, '}');
                }
            });
        },

        findOrganizerRole(organizerRoleName) {
            if (!organizerRoleName) {
                return null;
            }

            return this.roles.find((role) => role.name.toLowerCase() === organizerRoleName.toLowerCase());
        },

        transformToEventResource(content) {
            const organizerRole = this.findOrganizerRole(content.email.organizer_role) ?? null;

            if (organizerRole) {
                organizerRole.company_role = JSON.parse(JSON.stringify(organizerRole));
                organizerRole.selected = true;
            }

            return {
                resource: {
                    programs: [
                        {
                            company_program_id: this.companyProgramId,
                            company_program_key_date_id: null,
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            is_enabled: true,
                            recurrence_frequence_value: content.programming?.frequence_value ?? null,
                            recurrence_frequence_unit: content.programming?.frequence_unit ?? null,
                            participants: [],
                            filters: [],
                        },
                    ],
                    translations: {
                        name: content.general.name,
                        description: content.general.description,
                    },
                },
                organiser: {},
                organiser_user: null,
                organiser_role: organizerRole,
                timezone: content.programming.timezone_selection.id,
                translations: {
                    content: content.email.content,
                },
                file_translations: {},
                organiser_role_id: organizerRole?.id,
                time_start: content.programming.starting_time,
                time_end: content.programming.ending_time,
                email_sending_offset: content.programming.invitation_sending_date,
                is_delegated: content.email.delegate_scheduling_to_the_organizer,
            };
        },
    },
};
</script>
<style lang="scss">
.planning-event-template-visualizer form {
  max-width: none !important;
}
</style>
<style lang="scss" scoped>
@import '~@/styles/var';

.ai-form {
  width: 50%;
  margin: 20px auto 30px;

  @media (max-width: $tablet) {
    width: 100%;
  }
}

.events-menu {
  background-color: $color-lightblue-grey;
  padding: 3.2rem;
  border-radius: 8px;
  width: auto; flex: 0;

  > ul > li {
    padding: 1.5rem;
    background-color: white;
    border: 1px solid #E5E6E6;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    cursor: pointer;

    button {
      background-color: transparent;
      color: $primary;

      &:hover {
        color: $primary;
        transform: scale(1.1);
        transition: 0.2s ease transform;
      }
    }

    &:hover {
      border: 1px solid #FF6058;
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    }
  }
}

.wrapper-language-selector {
  .multiselect {
    width: 100%;
    max-height: 48px;

    .multiselect__tags {
      padding: 0 40px 0 15px;
    }

    .multiselect__select {
      top: 0;
      padding: 0 8px;
      height: 48px;

      &::before {
        top: 33%;
      }
    }

    .multiselect__option {
      padding-top: calc((48px - 32px) / 2);
      padding-bottom: calc((48px - 32px) / 2);
    }
  }
}
</style>
