<template>
    <div
        class="chip-wrapper"
        :class="{clickable}"
    >
        <slot
            name="image"
            :option="option"
        />
        <span class="label">
            <slot>
                <span v-html="label" />
            </slot>
        </span>
        <button
            v-if="deletable"
            class="fa-xs close"
            type="button"
            @click="$emit('delete')"
        >
            <FontAwesomeIcon icon="times-circle" />
        </button>
    </div>
</template>
<script>
export default {
    name: 'HtChip',
    props: {
        label: {
            type: String,
            default: '',
        },
        deletable: {
            type: Boolean,
            default: false,
        },
        option: {
            type: Object,
            default: undefined,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
.chip-wrapper {
    border: 1px solid $neutral-300;
    background: $neutral-200;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 5px;
    gap: 4px;
    border-radius: 52px;
    margin-bottom: 4px;
    margin-right: 4px;
    cursor: default;

    &.selected {
        background: $neutral-300;
    }

    &.clickable {
        cursor: pointer;

        &:hover {
            background: $neutral-300;
        }
    }

    .image {
        height: 16px;
        width: 16px;
        border-radius: 100%;
        overflow: hidden;

        ::v-deep span {
            font-size: 10px;
        }
    }
    .label {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 16px;

        .role-font-size {
            font-size: 10px !important;
        }
    }

    button {
        background: transparent;
        margin: 0;
        padding: 0;
    }
    .close {
        color: $neutral-500;
        &:hover {
            color: $primary;
        }
    }
}
</style>
