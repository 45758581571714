<template>
    <div class="full-width">
        <HtCard
            v-if="isLoaded"
            class="mb-5"
        >
            <template #title>
                <HtCardTitleBar
                    :title="title"
                    :show-close="true"
                    :show-button="true"
                    :button-text="translate('Create')"
                    @onAdd="onCreate"
                    @search="setSearchValue"
                    @on-close="$emit('on-change-step', 'SelectResourceTypeModal')"
                />
            </template>
            <template #default>
                <EventsListModels
                    :items="resourceCollection.$.models"
                    :external-search="search"
                    @onCellClicked="onCreate"
                />
            </template>
        </HtCard>
    </div>
</template>

<script>
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import ProgramBuilderListResourceMixin from '@/mixins/ProgramBuilderListResourceMixin';
import EventsListModels, { eventConfig } from '@/resourcesList/EventsListModels.vue';

export default {
    name: 'PlanningEvent',
    components: {
        HtCardTitleBar,
        EventsListModels,
    },

    mixins: [
        ProgramBuilderListResourceMixin,
    ],

    data() {
        return {
            collectionName: 'CompanyPlanningEventTemplateCollection',
            itemFields: eventConfig.fields,
            itemRelations: eventConfig.relations,
        };
    },

    computed: {
        title() {
            return this.translate('{count} event | {count} events', {
                count: this.resourceCollection.sortedByNameTranslated.length,
            });
        },
    },

    methods: {
        AddCollectionWhereHas() {
            this.resourceCollection.whereHas({
                resource: (query) => {
                    query.whereHas({
                        programs: (query) => {
                            query.where([
                                ['company_program_id', '!=', this.companyProgramId],
                            ]);
                        },
                    });
                },
            });
        },
    },
};
</script>
