import Model from '@tony.caron/node-model-proxy/Model';
import CompanyFileCollection from './CompanyFileCollection';

export default class CompanyFile extends Model {
    modelConfig() {
        return {
            collection: CompanyFileCollection,
        };
    }

    modelCustomAttributes() {
        return {
            /**
             * used for survey, in SurveyFormQuestionsImportAnswersModal, step
             */
            file_content: false,
        };
    }

    download() {
        if (this.link) {
            document.location.href = this.link;
        }
    }
}
