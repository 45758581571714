<template>
    <HtCard>
        <template #title>
            <div> {{ title }} </div>
            <HtSearchField
                :id="'search'"
                v-model.trim="search"
                :name="'search'"
            />
        </template>
        <template #default>
            <HtTable
                :items="items"
                :columns="columns"
                :external-search="search"
                @onCellClicked="onCellClicked"
            />
        </template>
    </HtCard>
</template>
<script>
import I18n from '@/modules/i18n/I18n';
import HtCard from '../HtCard.vue';
import HtSearchField from '../HtSearchField.vue';
import HtTable from '../HtTable.vue';
import HoldingResourceCountItem from './HoldingResourceCountItem';

export default {
    components: {
        HtSearchField,
        HtTable,
        HtCard,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        routeListName: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
            validator: (items) => {
                for (const item of items) {
                    if (!(item instanceof HoldingResourceCountItem)) {
                        return false;
                    }
                }
                return true;
            },
        },
    },

    data() {
        return {
            search: null,
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'companyName',
                    class: 'w-75',
                    search: true,
                },
                {
                    label: `${I18n.translate('Amount of')} ${this.title}`,
                    key: 'countResources',
                    class: 'd-md-table-cell',
                    search: false,
                },
            ],
        };
    },

    methods: {
        onCellClicked(cell) {
            this.$router.push({
                name: this.routeListName,
                params: { company_id: cell.item.companyId },
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.nb-collaborators {
    font-size: 1.4rem;
    font-weight: 400;
}
.filters {
    display: flex;
}
.filter {
    width: 30%;
}
.temp {
    margin-bottom: 0rem;
}
</style>
