import NumspotApi from '@/services/NumspotApi';
import router from '@/bootstrap/declare-router';
import Vue from 'vue';
import Auth from '@/services/Auth';
import { getBaseUrl, getNumspotBaseUrl } from '@/helpers/url';
import API from '@/services/Api';

const clients = [
    { Service: API, baseUrl: getBaseUrl() },
    { Service: NumspotApi, baseUrl: getNumspotBaseUrl() },
];

/**
 * Add timezone header to all requests
 */
const sTimezone = (() => {
    if (Intl !== undefined && Intl.DateTimeFormat() !== undefined && Intl.DateTimeFormat().resolvedOptions() !== undefined && Intl.DateTimeFormat().resolvedOptions().timeZone !== undefined) return Intl.DateTimeFormat().resolvedOptions().timeZone;
    return 'Europe/Paris';
})();

/**
 * Setup API clients
 */
for (const client of clients) {
    Vue.use(client.Service, { name: 'default', base_url: client.baseUrl });

    new client.Service({ name: 'qa', base_url: client.baseUrl }); // TODO a supprimer et utiliser la connection du dessus

    client.Service.getInstance('default')
        .interceptResponse(() => {
            router.push('/login');
        }, '401')
        .interceptResponse(() => {
            const message = Vue.prototype.$I18n.translate('Your session has expired. Please log in again.');
            Vue.prototype.$Notifier('App').showError(message);
            Auth.logOut();
            router.push('/login');
        }, '419')
        .interceptResponse(() => {
            router.push('/LoginMaster');
        }, '423');

    client.Service.getInstance('default').addHeader({ 'X-TIMEZONE': sTimezone });
    client.Service.getInstance('qa').addHeader({ 'X-TIMEZONE': sTimezone });
}
