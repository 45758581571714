<template>
    <div
        class="user-initials"
        :style="cssStyle"
    >
        {{ displayInitials ? initials : '' }}
    </div>
</template>

<script>
import Utils from '@/Utils';

export default {
    name: 'UserInitialsOrImage',
    props: {
        initials: {
            type: String,
            required: false,
        },
        image: {
            type: [String, Boolean],
            required: false,
        },
    },
    computed: {
        cssStyle() {
            if (!this.hasImage) {
                return {};
            }

            return {
                backgroundImage: `url(${Utils.resolveS3PublicImage(this.image)})`,
                backgroundSize: 'contain',
            };
        },
        displayInitials() {
            return !this.hasImage;
        },
        hasImage() {
            return this.image !== null && typeof (this.image) === 'string' && this.image !== '';
        },
    },
};
</script>

<style scoped>

.user-initials {
    font-size: 1.2rem;
    color: #717171;
    padding: 5px;
    border-radius: 35%;
    margin-right: 20px;
    width: 30px;
    height: 30px;
}
</style>
