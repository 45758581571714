<template>
    <div v-if="resourceCollection.isLoaded()">
        <HtCard class="mb-5">
            <template #title>
                <HtCardTitleBar
                    :title="title"
                    :show-close="true"
                    :show-button="true"
                    :button-text="translate('Create')"
                    @onAdd="onCreate"
                    @search="setSearchValue"
                    @on-close="$emit('on-change-step', 'SelectResourceTypeModal')"
                />
            </template>
            <template #default>
                <NudgesList
                    :items="resourceCollection.$.models"
                    :external-search="search"
                    @onCellClicked="onCreate"
                />
            </template>
        </HtCard>
    </div>
</template>

<script>
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import ProgramBuilderListResourceMixin from '@/mixins/ProgramBuilderListResourceMixin';
import NudgesList, { nudgeConfig } from '@/resourcesList/NudgesList.vue';
import HtProgramType from '@/models/HtProgramType';

export default {
    name: 'Nudge',
    components: {
        HtCardTitleBar,
        NudgesList,
    },

    mixins: [
        ProgramBuilderListResourceMixin,
    ],

    data() {
        return {
            resourceTitleSingular: 'nudge',
            resourceTitlePlural: 'nudges',
            collectionName: 'CompanyNudgeCollection',
            itemFields: nudgeConfig.fields,
            itemRelations: nudgeConfig.relations,
        };
    },

    methods: {
        statusColor(status) {
            switch (status) {
            case 'Active':
                return 'green';
            case 'Inactive':
                return 'grey';
            }
        },

        AddCollectionWhereHas() {
            this.resourceCollection.whereHas({
                resource: (query) => {
                    query.whereHas({
                        programs: (query) => {
                            query.where([
                                ['company_program_id', '!=', this.companyProgramId],
                            ]).whereHas({
                                program: (query) => {
                                    query.whereHas({
                                        htProgramType: (query) => {
                                            query.where([
                                                ['slug', '!=', HtProgramType.SLUG_DEFAULT],
                                            ]);
                                        },
                                    });
                                },
                            });
                        },
                    });
                },
            });
        },

        AddCollectionOrderBy() {
            this.resourceCollection.orderBy([
                ['is_enable', 'desc'],
                ['order', 'desc'],
            ]);
        },
    },
};
</script>
