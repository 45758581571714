import UnauthorizedError from '@/services/Errors/UnauthorizedError';

export default class AIMailService {
    constructor(env, token) {
        this.env = env;
        this.token = token;
        this.order = 0;
    }

    async ask(input, resourceType, currentTone, languages, hasContentSelected, callback) {
        let type = resourceType;

        if (hasContentSelected) {
            type = `${resourceType}-part`;
        }

        const url = this.env.getCapiUrl('assistant/ask');
        const data = {
            input,
            type,
            tone: currentTone.value,
        };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, options);

        if ([401, 403].includes(response.status)) {
            throw new UnauthorizedError();
        }

        return this.readStream(response.body, hasContentSelected, callback);
    }

    // eslint-disable-next-line class-methods-use-this
    async readStream(stream, hasContentSelected, callback) {
        const reader = stream.getReader();

        // eslint-disable-next-line no-constant-condition
        while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { done, value } = await reader.read();
            if (done) break;

            const decodedText = new TextDecoder()
                .decode(value, { stream: true });

            const text = decodedText
                .replace(/\n\n/g, '<br><br>')
                .replace(/\n/g, '<br>');

            callback(text, hasContentSelected);
        }
    }
}
