<template>
    <div v-if="companyUserNote">
        <form
            class="form-2 spaced has-padding"
            @submit.prevent=""
        >
            <span>{{ companyUserNote.name }}</span>

            <HtDivider class="my-4" />

            <fieldset>
                <label>
                    <t>Upload a note</t>
                    <span class="sub-text">(<t>Up to 20 MB</t>)</span>
                </label>

                <UploadBar
                    v-model="companyUserNote.companyFile"
                    :media="['office', 'pdf', 'image']"
                    :disabled="isDisabled"
                    cypress="company-file"
                    @onUploadProgress="buttonState = 'loading'"
                    @onUploadEnd="buttonState = 'idle'"
                />
            </fieldset>

            <fieldset class="medium">
                <HtFormEditor
                    id="content"
                    v-model="companyUserNote.content"
                    v-validate.disable="getValidateContent"
                    :data-vv-as="translate('content or file')"
                    name="content"
                    cypress="content"
                    :disabled="isDisabled"
                />
            </fieldset>

            <div class="modal-actions right">
                <Button
                    v-if="showDeleteButton"
                    class="negative"
                    cypress="note-delete-button"
                    @click="onDelete()"
                >
                    <t>Delete</t>
                </Button>
                <Button
                    v-if="isNew"
                    :state="buttonState"
                    cypress="note-add-button"
                    @click="onUpdate()"
                >
                    <t>Add</t>
                </Button>
                <Button
                    v-if="showSaveButton"
                    :state="buttonState"
                    cypress="note-save-button"
                    @click="onUpdate()"
                >
                    <t>Save</t>
                </Button>
            </div>
        </form>
    </div>
</template>

<script>
import CompanyUserNoteCollection from '@/models/CompanyUserNoteCollection';
import HtDivider from '@/components/globals/HtDivider.vue';
import api from '@/store/api';

export default {
    name: 'UserNoteModal',
    components: { HtDivider },
    permissions: [
        'ModelCompanyUserNote',
    ],
    inject: ['modal'],
    props: {
        value: { type: Object, default: () => {} },
        collection: { type: CompanyUserNoteCollection, required: true },
        resourceName: { type: String, required: true },
        resourceId: { type: Number, required: true },
    },

    data() {
        return {
            companyUserNote: null,
            buttonState: 'idle',
            isNew: true,
        };
    },

    computed: {
        getValidateContent() {
            return this.companyUserNote.company_file ? '' : 'required';
        },

        labelButton() {
            return this.translate(this.isNew ? 'Add' : 'Save');
        },

        showSaveButton() {
            return !this.isNew && this.companyUserNote.permissions.update === true;
        },

        showDeleteButton() {
            return !this.isNew && this.companyUserNote.permissions.delete === true;
        },

        isDisabled() {
            // Si on est en mode création, l'utilisateur peut forcément créer
            if (this.isNew) {
                return false;
            }

            return !this.companyUserNote.permissions.update;
        },
    },

    created() {
        if (this.value) {
            this.isNew = false;
            this.companyUserNote = this.value;
        } else {
            const today = this.$Utils.moment().format('YYYY-MM-DD');

            const filteredNoteByToday = this.collection.filter((note) => {
                const noteDateCreated = this.$Utils.moment(note.created_at).format('YYYY-MM-DD');
                return today === noteDateCreated;
            });

            const nbNotes = filteredNoteByToday.length + 1;
            this.companyUserNote = {};
            this.companyUserNote.resource_name = this.resourceName;
            this.companyUserNote.resource_id = this.resourceId;
            this.companyUserNote.name = this.$Utils.moment().format('YYYY-MM-DD-') + nbNotes.toString().padStart(3, '0');
            this.companyUserNote.company_user_owner = this.shared.session.companyUser.id;
        }

        this.modal.setTitle(this.translate('Meeting notes'));
    },

    methods: {
        onDelete() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this note? Once you click on delete, you will no longer be able to access this note.') }).then((response) => {
                if (response === true) {
                    api.user.planningEventNotes.delete(this.companyUserNote.id).then(() => {
                        this.$emit('onDeleteNote', this.companyUserNote.id);
                        this.modal.close();
                    });
                }
            });
        },

        onUpdate() {
            this.$validator.validateAll().then((result) => {
                if (result === true) {
                    if (this.isNew) {
                        api.user.planningEventNotes.store(this.companyUserNote).then((response) => {
                            this.$emit('onCreateNote', response.data.data);
                            this.modal.close();
                        });
                    } else {
                        api.user.planningEventNotes.update(this.companyUserNote).then(() => {
                            this.modal.close();
                        });
                    }
                }
            });
        },

    },
};
</script>
