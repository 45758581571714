var render = function render(){var _vm=this,_c=_vm._self._c;return _c('StepperTabLayout',{scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('HtFormCard',[_c('div',{staticClass:"mb-4"},[_c('HtFormInput',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required|max:255'),expression:"'required|max:255'",modifiers:{"disable":true}}],staticClass:"mb-4",attrs:{"id":'name',"label":_vm.translate('Role name'),"name":'name',"data-vv-as":_vm.translate('name'),"show-optional":false,"disabled":_vm.item.is_heyteam},model:{value:(_vm.getName),callback:function ($$v) {_vm.getName=$$v},expression:"getName"}}),_c('HtFormTextarea',{directives:[{name:"validate",rawName:"v-validate.disable",value:('max:255'),expression:"'max:255'",modifiers:{"disable":true}}],attrs:{"id":'description',"name":'description',"label":_vm.translate('Description'),"maxlength":255,"disabled":_vm.item.is_heyteam},model:{value:(_vm.getDescription),callback:function ($$v) {_vm.getDescription=$$v},expression:"getDescription"}}),_c('HtFormSwitch',{attrs:{"id":"is_actions_tab_displayed_at_login","label":_vm.translate(
                            'Display actions tab at login'
                        ),"name":"is_actions_tab_displayed_at_login","show-optional":false},model:{value:(_vm.item.is_actions_tab_displayed_at_login),callback:function ($$v) {_vm.$set(_vm.item, "is_actions_tab_displayed_at_login", $$v)},expression:"item.is_actions_tab_displayed_at_login"}}),_c('HtFormSelector',{directives:[{name:"validate",rawName:"v-validate.disable",value:('required'),expression:"'required'",modifiers:{"disable":true}}],attrs:{"id":"default_sort_dashboard","specific-key":"value","track-by":"value","name":"default_sort_dashboard","label":_vm.translate('Default sort on dashboard'),"show-optional":false,"options":[
                        {
                            name: _vm.translate('By date'),
                            value: 'date',
                        },
                        {
                            name: _vm.translate('By action'),
                            value: 'action',
                        },
                        {
                            name: _vm.translate('By user'),
                            value: 'user',
                        },
                    ],"is-single":"","is-searchable":false,"allow-empty":false},model:{value:(_vm.defaultSortDashboard),callback:function ($$v) {_vm.defaultSortDashboard=$$v},expression:"defaultSortDashboard"}})],1)])]},proxy:true},{key:"buttonsFooter",fn:function(){return [(_vm.item.is_removable && !_vm.item.isNew())?_c('Button',{staticClass:"negative mr-4",attrs:{"state":_vm.buttonState},on:{"click":_vm.onDelete}},[_c('t',[_vm._v("Delete")])],1):_vm._e(),_c('Button',{attrs:{"state":_vm.buttonState},on:{"click":_vm.onSave}},[(_vm.canUpdate)?_c('t',[_vm._v(" Validate ")]):_c('t',[_vm._v(" Next ")])],1)]},proxy:true},{key:"modalables",fn:function(){return [_c('Modalable',{ref:"companyRoleRemoveModal",staticClass:"modalable-1 small",attrs:{"mode":2,"element-delete-label":_vm.translate('this role')}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }