<template>
    <div>
        <template v-if="isParticipant === true && isDisabled === false">
            <form class="form-2">
                <flat-pickr
                    v-model="companyUserRequirement.value"
                    :name="companyUserRequirement.name"
                    :config="{
                        mode: 'single',
                        locale: flatPickrLocale,
                        altInput: true,
                        altFormat: flatPickrFormat,
                        dateFormat: 'Y-m-d H:i:S'
                    }"
                    :data-cy="companyUserRequirement.name"
                    @input="onChanged"
                />
            </form>
        </template>
        <HtFormInput
            v-else
            :id="companyUserRequirement.name"
            :value="getFormatedValue"
            :name="companyUserRequirement.name"
            :cypress="companyUserRequirement.name"
            disabled
            type="text"
        />
    </div>
</template>

<script>
import FlatPickrLocale from 'flatpickr/dist/l10n';
import UserRequirementEditMixin from './UserRequirementEditMixin';

export default {
    name: 'UserRequirementDateEdit',
    filters: {
        iban(value) {
            if (value && value.owner.length) {
                return `${value.owner} - ${value.iban} (${value.bic})`;
            }
            return '-';
        },
    },

    mixins: [UserRequirementEditMixin],

    inject: ['$validator'],

    props: {
        isParticipant: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        flatPickrLocale() {
            if (this.shared.session.companyUser.company_language.key === 'fr') {
                return FlatPickrLocale.fr;
            }
            return FlatPickrLocale.en;
        },

        flatPickrFormat() {
            if (this.shared.session.companyUser.company_language.key === 'fr') {
                return 'd/m/Y';
            }
            return 'm/d/Y';
        },

        getFormatedValue() {
            return (this.companyUserRequirement.value) ? this.$Utils.moment(this.companyUserRequirement.value).format('L') : null;
        },
    },

    methods: {
        onChanged(value) {
            if (this.companyUserRequirement.value !== this.requirement.value
                && value !== this.requirement.value) {
                const checkChanged = this.companyUserRequirement.value !== null && this.companyUserRequirement.value.trim() !== '';
                this.updateRequirementWithDebounce(checkChanged);
            }
        },
    },
};
</script>

<style lang='scss' src='./UserRequirementDateEdit.scss' scoped />
