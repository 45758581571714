<template>
    <div class="chat-search">
        <div class="chat-search-button">
            <FontAwesomeIcon
                size="2x"
                :icon="['far', 'search']"
                @click="$refs.searchModal.open()"
            />
        </div>
        <HtSearchField
            id="search"
            v-model.trim="search"
            name="search"
            class="chat-search-input"
            :placeholder="translate('Find a user to talk with !')"
            @blur="onClear"
        />
        <slot />
        <div
            v-if="search.length > 0"
            class="h-100 overflow-auto"
        >
            <ChatListChannelItemHtml
                v-for="user in users"
                :key="user.id"
                :image="user.image"
                :image-firstname="user.firstname"
                :image-lastname="user.lastname"
                :name="user.fullname"
                :subname="user.professional_email"
                :is-connected="user.isConnected"
                @mousedown.native="addChannel(user.id)"
            />
            <ChatListChannelItem
                v-for="channel in matchingChannels"
                :key="channel.id"
                :active="channel.active"
                :company-chat-channel="channel"
                @click.native="shared.socket.channelCollection.setActive(channel.id)"
            />
        </div>

        <ModalablePersist
            ref="searchModal"
            size="small"
        >
            <template #header>
                <t>Search</t>
            </template>
            <template #main>
                <div class="modal">
                    <HtSearchField
                        id="searchModal"
                        v-model.trim="searchModal"
                        name="searchModal"
                        class="modal-input"
                        :placeholder="translate('Find a user to talk with !')"
                        @blur="onClear"
                    />
                    <div v-if="searchModal.length > 0">
                        <ChatListChannelItemHtml
                            v-for="user in users"
                            :key="user.id"
                            :image="user.image"
                            :image-firstname="user.firstname"
                            :image-lastname="user.lastname"
                            :name="user.fullname"
                            :show-profile="true"
                            :is-connected="user.isConnected"
                            @mousedown.native="addChannel(user.id)"
                        />
                    </div>
                </div>
            </template>
        </ModalablePersist>
    </div>
</template>

<script>
import HtSearchField from '@/components/globals/HtSearchField.vue';
import debounce from 'lodash.debounce';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ChatSearchMixin from './mixins/ChatSearchMixin';
import NewChannelMixin from './mixins/NewChannelMixin';
import ChatListChannelItemHtml from './ChatListChannelItemHtml.vue';
import ModalablePersist from '../globals/ModalablePersist.vue';
import ChatListChannelItem from './ChatListChannelItem.vue';

export default {
    name: 'ChatSearch',
    components: {
        HtSearchField,
        ChatListChannelItemHtml,
        ModalablePersist,
        ChatListChannelItem,
        FontAwesomeIcon,
    },
    mixins: [ChatSearchMixin, NewChannelMixin],
    props: {
        searchInGroups: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            matchingChannels: [],
        };
    },
    watch: {
        users: debounce(function () { this.onSearch(); }, 150),
    },
    methods: {
        addChannel(companyUserId) {
            this.clear();

            this.$refs.searchModal.close();

            const oldChannel = this.shared.socket.channelCollection.models.find((channel) => channel.type === 'duo' && channel.company_chat_channel_user.models.some((channelUser) => channelUser.company_user_id === companyUserId));

            if (oldChannel) {
                this.shared.socket.channelCollection.setActive(oldChannel.id);
                return;
            }
            this.createNewChannel([companyUserId]);
        },
        onSearch() {
            this.matchingChannels = [];
            if (this.searchInGroups) {
                this.searchChannels();
            }
        },
        searchChannels() {
            this.shared.socket.channelCollection.models.filter((c) => c.type !== 'duo').forEach((channel) => {
                for (const user of channel.company_chat_channel_user.models.map((c) => c.company_user.fullname.toLowerCase())) {
                    if (user.indexOf(this.search.toLowerCase()) === 0) {
                        if (!this.matchingChannels.includes(channel)) {
                            this.matchingChannels.push(channel);
                            break;
                        }
                    }
                }
            });
        },
        async onClear() {
            setTimeout(() => {
                this.clear();
            }, 100);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.chat-search {
    display: flex;
    flex-direction: column;
    height: 100%;

    &-input {
        width: 100%;
    }

    &-button {
        display: none;
    }
}

.modal {
    padding: 20px;

    &-input {
        width: 100%;
    }
}
</style>
