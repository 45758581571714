<template>
    <StepperTabLayout>
        <template
            v-if="programsLoaded"
            #main
        >
            <HtFormCard>
                <div class="programs">
                    <div
                        v-for="(program, index) in requirementCategory.resource.programs"
                        :key="program.id"
                        class="program"
                    >
                        <div class="program-name">
                            {{ getProgramName(program.company_program_id) }}
                        </div>

                        <HtKeyDatesInputOrder
                            :program-id="program.company_program_id"
                            :filters="program.filters"
                            :is-business-day="program.is_business_day"
                            :offset-key-date.sync="program.company_program_key_date_id"
                            :offset-availability-number.sync="program.offset_availability_number"
                            :offset-availability-unit.sync="program.offset_availability_unit"
                            :offset-end-number.sync="program.offset_end_number"
                            :offset-end-unit.sync="program.offset_end_unit"
                            :disabled="!requirementCategory.resource.is_editable"
                            @update:is-business-day="(value) => updateBusinessDay(index, value)"
                        />
                    </div>
                </div>
            </HtFormCard>
        </template>
        <template #buttonsFooter>
            <div class="mt-4">
                <Button
                    class="negative mr-4"
                    :state="buttonState"
                    @click="$emit('goTo', 'content')"
                >
                    <t>Back</t>
                </Button>
                <Button
                    v-if="requirementCategory.resource.is_editable"
                    :state="buttonState"
                    @click="onSave"
                >
                    <t>Validate</t>
                </Button>
            </div>
        </template>
    </StepperTabLayout>
</template>

<script>
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import StepperTabLayout from '@/components/globals/StepperTabLayout.vue';
import CompanyRequirementCategory from '@/models/CompanyRequirementCategory';
import { mapGetters, mapState } from 'vuex';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import cloneDeep from 'lodash.clonedeep';
import api from '@/store/api';

export default {
    name: 'RequirementFormPlanning',

    components: {
        StepperTabLayout,
        HtKeyDatesInputOrder,
    },

    mixins: [DefaultFiltersMixin],

    props: {
        showListOnEnd: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            resourceableClass: CompanyRequirementCategory.RESOURCEABLE_CLASS,
            requirementCategory: cloneDeep(this.$store.state.stepperform.item),
        };
    },

    watch: {
        requirementCategory: {
            handler(newValue) {
                this.$store.dispatch('stepperform/setItem', newValue);
            },
            deep: true,
        },
    },

    computed: {
        ...mapState('programs', [
            'programs',
            'programsLoaded',
        ]),
        ...mapState('stepperform', [
            'loading',
        ]),
        ...mapGetters('programs', [
            'getProgramsByIds',
            'findProgramById',
        ]),

        /**
         * @returns {Array}
         */
        selectedPrograms() {
            const selectedIds = this.requirementCategory.resource.programs.map((program) => program.company_program_id);
            return this.programsOptions.filter((option) => selectedIds.includes(option.id));
        },
        programsOptions() {
            return this.programs.map((program) => ({
                id: program.id,
                name: this.oldLocalize(program.locales, 'name'),
            }));
        },
        buttonState() {
            return this.loading ? 'loading' : 'idle';
        },
    },

    created() {
        this.$store.dispatch('programs/fetchPrograms');
    },

    methods: {
        updateBusinessDay(index, value) {
            this.requirementCategory.resource.programs[index].is_business_day = value;
            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        getProgramName(id) {
            const program = this.findProgramById(id);

            return this.oldLocalize(program.locales, 'name');
        },

        async onSave() {
            this.$store.dispatch('stepperform/enableLoading');

            this.$validator.validateAll().then((result) => {
                if (result === true) {
                    api.configuration.requirementCategory
                        .update(this.requirementCategory.id, this.requirementCategory)
                        .then((res) => {
                            this.$store.dispatch('stepperform/setItem', res.data.data);
                            this.$store.dispatch('stepperform/disableLoading');

                            if (this.showListOnEnd) {
                                this.$router.push({ name: 'ResourcesRequirements' });
                            } else {
                                this.$emit('onSave');
                            }
                        });
                } else {
                    this.$store.dispatch('stepperform/disableLoading');
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.programs {
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.program-name {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 1.9rem;
}
</style>
