import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';

import CompanySurveyQuestionAnswer from './CompanySurveyQuestionAnswer';

export default class CompanySurveyQuestionAnswerCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanySurveyQuestionAnswer,
        };
    }
}
