<template>
    <transition name="fade">
        <TranslatedForm
            v-if="item"
            v-slot="{ editingLang }"
        >
            <form
                class="form-2 spaced"
                @submit.prevent=""
            >
                <div class="modal-content-item-wrapper">
                    <fieldset
                        class="medium"
                        :class="{ error: errors.has('title') }"
                    >
                        <HtFormInput
                            :id="'name'"
                            ref="titleInput"
                            v-model="item.resource.translations.name[editingLang]"
                            v-validate.disable="isDefaultLangSelected ? 'required|max:255' : 'max:255'"
                            data-cy="action-field-name"
                            :name="'name'"
                            :label="translate('Action name')"
                            :data-vv-as="translate('action name')"
                        />
                    </fieldset>

                    <fieldset class="m-0">
                        <HtFormSwitch
                            v-if="canCreatePublicResource"
                            :id="'is_private'"
                            v-model="item.resource.is_private"
                            :description="translate('Mark this resource as private')"
                            :name="'is_private'"
                            :show-optional="false"
                            :label="translate('In my personal library')"
                            :cypress="'action-field-is-private'"
                        />
                    </fieldset>

                    <fieldset class="medium">
                        <HtFormEditor
                            :id="'description'"
                            ref="descriptionInput"
                            v-model="item.resource.translations.description[editingLang]"
                            cypress="action-field-description"
                            :name="'description'"
                            :label="translate('Action description')"
                            :placeholder="translate('Please specify')"
                            resource-openai="action"
                        />
                    </fieldset>

                    <HtProgramList
                        ref="htProgramListInput"
                        v-model="item.resource.programs[0].company_program_id"
                        :with-default="false"
                        :disabled="disabledProgramList"
                    />

                    <section
                        v-if="isResourceTargeted"
                        v-show="isDefaultLangSelected"
                    >
                        <fieldset :class="{ error: errors.has('participant') }">
                            <label>
                                <t>Assign this action to</t> *
                            </label>
                            <ParticipantList
                                ref="participantInput"
                                cypress="action-field-role-participant"
                                :can-add="true"
                                :type="'stacked'"
                                has-role
                                :users="participants"
                                @onAdd="openAddUsersModal"
                            />
                            <input
                                v-validate.disable="'required'"
                                :value="participants.length ? '-' : ''"
                                type="hidden"
                                name="participant"
                            >
                        </fieldset>

                        <HtFormGroup
                            v-show="!item.resource.is_private"
                            :label="translate('Filters')"
                            :description="translate('Add filters to decide who can access the resource')"
                        >
                            <HtFormCard>
                                <ProgramResourceFilters
                                    v-if="item.resource.programs[0].company_program_id"
                                    v-model="item.resource.programs[0].filters"
                                    :program-id="item.resource.programs[0].company_program_id"
                                    :use-resource-perimeter="useResourcePerimeter"
                                />
                                <div
                                    v-else
                                    class="sentence-filter"
                                >
                                    <t>Select a program to display associated filters</t>
                                </div>
                                <div
                                    v-if="hasParent || hasChildren"
                                    class="error-message"
                                >
                                    <t>You cannot change the filters because this task is nested</t>
                                </div>
                            </HtFormCard>
                        </HtFormGroup>

                        <HtFormCard
                            v-show="!item.resource.is_private"
                            class="mb-5"
                        >
                            <RetroactivityToggle
                                :is-retroactive.sync="isRetroactive"
                                :send-notif.sync="sendRetroactiveNotification"
                                :resource="'custom'"
                                :affected-programs="affectedPrograms"
                                :loading="affectedProgramsLoading"
                            />
                        </HtFormCard>

                        <HtKeyDatesInputOrder
                            :program-id="item.resource.programs[0].company_program_id"
                            :resource-id="item.id"
                            :resource-type="resourceableClass"
                            :filters="item.resource.programs[0].filters"
                            :is-business-day.sync="item.resource.programs[0].is_business_day"
                            :offset-key-date.sync="item.resource.programs[0].company_program_key_date_id"
                            :offset-availability-number.sync="item.resource.programs[0].offset_availability_number"
                            :offset-availability-unit.sync="item.resource.programs[0].offset_availability_unit"
                            :offset-end-number.sync="item.resource.programs[0].offset_end_number"
                            :offset-end-unit.sync="item.resource.programs[0].offset_end_unit"
                            :dependents.sync="item.resource.programs[0].dependents"
                            :dependencies.sync="item.resource.programs[0].dependencies"
                        />
                    </section>
                </div>
            </form>

            <div class="modal-actions right">
                <Button
                    v-show="canDelete"
                    class="negative"
                    cypress="action-delete"
                    :state="buttonState"
                    @click="onDelete()"
                >
                    <t>Delete</t>
                </Button>
                <Button
                    :state="buttonState"
                    data-cy="add-action"
                    @click="onValidate()"
                >
                    {{ labelButton }}
                </Button>
            </div>

            <modalable
                ref="modalableSearchUser"
                class="modalable-1 small no-padding-bottom"
            >
                <SearchRolePanel
                    ref="searchRolePanel"
                    :multiple-mode="true"
                    :is_assignable_onboardee="true"
                    @onValidate="$refs.modalableSearchUser.close()"
                >
                    <h3>
                        <t>Done by</t>
                    </h3>
                </SearchRolePanel>
            </modalable>
        </TranslatedForm>
    </transition>
</template>

<script>
import CompanyProgramTask from '@/models/CompanyProgramTask';
import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';
import HtProgramList from '@/components/globals/HtProgramList.vue';
import TranslatedForm from '@/components/form/TranslatedForm.vue';
import ResourceModalEditMixin from '@/mixins/ResourceModalEditMixin';
import ResourceKeyDateLabelMixin from '@/mixins/ResourceKeyDateLabelMixin';
import RetroactivityToggle from '@/components/globals/RetroactivityToggle.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import ProgramResourceFilters from '@/components/globals/ProgramResourceFilters.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import cloneDeep from 'lodash.clonedeep';
import api from '@/store/api';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';

export default {
    name: 'TaskEdit',
    components: {
        HtFormSwitch,
        HtKeyDatesInputOrder,
        TranslatedForm,
        HtProgramList,
        ProgramResourceFilters,
        RetroactivityToggle,
    },

    mixins: [
        DefaultFiltersMixin,
        DefaultOrderCompletionMixin,
        ResourceModalEditMixin,
        ResourceKeyDateLabelMixin,
    ],

    inject: ['modal'],

    props: {
        id: {
            type: Number,
            required: false,
        },
    },

    data() {
        return {
            currentLanguage: this.shared.session.company.company_language,
            item: null,
            isRetroactive: false,
            sendRetroactiveNotification: false,
            affectedProgramsLoading: false,
            affectedPrograms: 0,
        };
    },

    computed: {
        userPerimeterEntities() {
            return this.$store.state.user.perimeterEntities;
        },

        useResourcePerimeter() {
            return this.$store.getters['permissions/useResourcePerimeter']('ModelCompanyProgramTask');
        },

        modelName() {
            return 'CompanyProgramTask';
        },
        modalResourceName() {
            return 'action';
        },
        taskType() {
            return CompanyUserProgramTask.TASK_CUSTOM;
        },
        resourceableClass() {
            return CompanyProgramTask.RESOURCEABLE_CLASS;
        },
        isResourceTargeted() {
            return this.item && this.item.resource.programs.length > 0 && !!this.item.resource.programs[0].company_program_id;
        },
        canCreatePublicResource() {
            const canCreatePublicResource = this.$canCreate('ModelCompanyProgramTask');
            const isCreationMode = !this.id;
            return isCreationMode && canCreatePublicResource;
        },
        isDefaultLangSelected() {
            return this.shared.session.company.company_language.key === this.currentLanguage.key;
        },
        hasChildren() {
            return !!this.item.resource.programs[0].dependencies?.length;
        },
        hasParent() {
            return !!this.item.resource.programs[0].dependents?.length;
        },
        participants() {
            return this.item.resource.programs[0].participants;
        },
    },
    watch: {
        isRetroactive() {
            this.getUserProgramsAffected();
        },
    },

    beforeDestroy() {
        this.$eventHub.$off('filter-change', this.getUserProgramsAffected);
        this.$eventHub.$off('program-change', this.getUserProgramsAffected);
    },

    methods: {
        async fetchModelFromId() {
            const action = (await api.configuration.actions.get(this.id)).data.data;

            const isResourceTargeted = action.resource.programs.length > 0;
            if (!isResourceTargeted) {
                // default values for the targeting
                action.resource.programs[0] = {
                    company_program_id: null,
                    company_program_key_date_id: null,
                    offset_availability_number: 0,
                    offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                    offset_end_number: 0,
                    offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                    is_business_day: true,
                    is_enabled: true,
                    dependencies: [],
                    dependents: [],
                    participants: [],
                    filters: [],
                };
            }

            return {
                ...action,
                isNew() {
                    return !this.id;
                },
            };
        },

        async duplicateModel() {
            this.item = await this.fetchModelFromId();
            this.item.id = null;
            this.item.resource.id = null;
            this.item.resource.programs[0].id = null;
            this.item.resource.programs[0].company_program_id = this.companyProgramId;
            this.item.resource.programs[0].company_program_key_date_id = null;
            this.item.resource.programs[0].filters = [];
            this.item.resource.programs[0].dependents = [];
            this.item.resource.programs[0].dependencies = [];
            this.item.resource.programs[0].participants = [];
        },
        setDefaultOrderCompletion() { },

        createModelFromCollection() {
            this.item = {
                isNew() {
                    return !this.id;
                },
                resource: {
                    programs: [
                        {
                            company_program_id: this.companyProgramId,
                            company_program_key_date_id: null,
                            offset_availability_number: 0,
                            offset_availability_unit: 'immediately',
                            offset_end_number: 0,
                            offset_end_unit: 'days',
                            is_business_day: true,
                            is_enabled: true,
                            dependencies: [],
                            dependents: [],
                            participants: [],
                            filters: [],
                        },
                    ],
                    translations: {
                        name: {},
                        description: {},
                    },
                    // if I can't see this field, then the resource is private
                    is_private: !this.canCreatePublicResource,
                },
            };

            if (this.useResourcePerimeter) {
                this.item.resource.programs[0].filters = this.userPerimeterEntities;
            }

            this.affectedPrograms = 0;
            this.listenFiltersOrProgramChange();
        },

        async createModelFromId() {
            this.item = await this.fetchModelFromId();
            this.listenFiltersOrProgramChange();
        },

        getUserProgramsAffected() {
            if (this.isRetroactive === false) return;
            this.affectedProgramsLoading = true;
            const resourceProgram = this.isResourceTargeted ? this.item.resource.programs[0] : {};
            const programIds = this.isResourceTargeted ? [resourceProgram.company_program_id] : [];
            const entities = this.isResourceTargeted ? resourceProgram.filters.map((filter) => ({
                company_entity_id: filter.company_entity_id,
                company_entity_value_id: filter.company_entity_value_id,
            })) : [];

            this.$http.post('affected-programs-retroactivity', {
                task_type: this.taskType,
                resource_id: this.id,
                entities,
                program_ids: programIds,
            }).then((response) => {
                this.affectedPrograms = response.data.count;
            }).finally(() => {
                this.affectedProgramsLoading = false;
            });
        },

        listenFiltersOrProgramChange() {
            this.$eventHub.$on('filter-change', this.getUserProgramsAffected);
            this.$eventHub.$on('program-change', this.getUserProgramsAffected);
        },

        openAddUsersModal() {
            this.$refs.searchRolePanel.init({
                onValidate: this.addParticipants,
                selected: this.item.resource.programs[0].participants,
            });
            this.$refs.modalableSearchUser.open();
        },

        addParticipants(participants) {
            this.item.resource.programs[0].participants = participants.map((p) => {
                if (p.company_user) {
                    // Format user like role, otherwise the user.id will be used as participant.id
                    return {
                        company_user: p.company_user,
                        company_user_id: p.company_user_id,
                    };
                }
                return p;
            });
        },

        onValidate() {
            this.buttonState = 'loading';

            // to be sure we can validate the default lang
            this.currentLanguage = this.shared.session.company.company_language;

            this.$nextTick(async () => {
                const result = await this.$validator.validateAll();

                if (result !== true) {
                    this.buttonState = 'idle';
                    return this.$Utils.scrollToFirstError(this);
                }

                const resourceToSave = cloneDeep({
                    ...this.item,
                    is_retroactive: this.isRetroactive,
                    send_retroactive_notif: this.sendRetroactiveNotification,
                });
                if (!resourceToSave.resource.programs[0].company_program_id) {
                    resourceToSave.resource.programs = [];
                }

                if (this.item.id) {
                    await api.configuration.actions.update(this.item.id, resourceToSave);
                } else {
                    await api.configuration.actions.create(resourceToSave);
                }

                this.$emit('onSave');
                this.modal.close();
                this.buttonState = 'idle';
            });
        },

        async onDelete() {
            if (await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this task? Once you click on delete, you will no longer be able to access this task.') })) {
                this.buttonState = 'loading';
                await this.$http.delete(`configuration/actions/${this.id}`);
                this.modal.close();
                this.$emit('onDelete');
                this.buttonState = 'idle';
            }
        },
    },
};
</script>
