import CardImgLeftSection from './CardImgLeftSection';

export default {
    name: 'Triple cards',
    image: 'triple-card.png',
    fullPageBorder: true,
    content: `
        <section class="ht-section-triple-card-img">
            <div class="card">
                <div class="card-image">
                    <img src="https://via.placeholder.com/200x200" alt="Placeholder Image" />
                </div>
                <div class="card-content">
                    <h2 class="title"><a href="#">Lorem ipsum dolor....</a></h2>
                    <p>... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias corporis deserunt ducimus labore non omnis quibusdam sapiente suscipit voluptate?.</p>
                </div>
            </div>

            <div class="card">
                <div class="card-image">
                    <img src="https://via.placeholder.com/200x200" alt="Placeholder Image" />
                </div>
                <div class="card-content">
                    <h2 class="title"><a href="#">Lorem ipsum dolor....</a></h2>
                    <p>... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias corporis deserunt ducimus labore non omnis quibusdam sapiente suscipit voluptate?.</p>
                </div>
            </div>

            <div class="card">
                <div class="card-image">
                    <img src="https://via.placeholder.com/200x200" alt="Placeholder Image" />
                </div>
                <div class="card-content">
                    <h2 class="title"><a href="#">Lorem ipsum dolor....</a></h2>
                    <p>... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad alias corporis deserunt ducimus labore non omnis quibusdam sapiente suscipit voluptate?.</p>
                </div>
            </div>
        </section>
    `,
    css: `
        ${CardImgLeftSection.css}

        .ht-section-triple-card-img {
            display: flex;
            flex-direction: row;
            gap: 16px;
            padding: 40px 0;
        }

        .card {
            flex-direction: column;
            padding: 0;
        }

        .card-image {
            max-width: 100%;
            max-height: 100%;
            justify-content: center;
            align-items: center;
            max-width: 100%;
            border-radius: 0;
        }

        .card-image img {
            border-radius: 0;
        }

        .card-content {
            flex: 0;
            padding: 10px;
            justify-content: start;
            align-items: center;
        }

        .title {
            font-size: 32px;
            line-height: 36px;
            font-weight: bold;
            color: var(--primary-base);
            margin-bottom: 32px;
        }

        .title a {
            color: var(--primary-base);
        }

        .card-content p {
            font-size: 16px;
            line-height: 21px;
            font-weight: regular;
        }

        @media (max-width: 768px) {
            .ht-section-triple-card-img {
                flex-direction: column;
            }
        }
    `,
};
