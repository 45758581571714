import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import HtPermissionGroup from './HtPermissionGroup';

export default class HtPermissionGroupCollection extends ModelCollection {
    modelConfig() {
        return {
            model: HtPermissionGroup,
        };
    }
}
