<template>
    <div>
        <form
            v-if="item"
            class="form-2 spaced"
            @submit.prevent=""
        >
            <div class="modal-box">
                <CardList
                    :options="{
                        perRow: 2,
                    }"
                >
                    <Card
                        v-for="(fileType, index) in availableFileTypes"
                        :key="index"
                        :options="{
                            type: 'long',
                            perRow: 2,
                        }"
                        :class="{ selected: item.type === fileType.type }"
                        @click="changeTo(fileType.type)"
                    >
                        <div slot="image">
                            <div class="item-image image-small">
                                <div
                                    class="small-image"
                                    :style="{'background-image': `url(${fileType.img})`}"
                                />
                            </div>
                        </div>
                        <div slot="title">
                            <t>{{ fileType.label }}</t>
                        </div>
                    </Card>
                </CardList>

                <!-- FILE -->
                <TranslatableFiles
                    v-if="item.type === 'file'"
                    :files="item.fileTranslations"
                    :editing-lang="editingLang"
                    :validation-rules="{
                        translation_default: [
                            item.fileTranslations,
                            shared.session.company.company_languages.default.key
                        ]
                    }"
                />

                <!-- LINK -->
                <div v-if="item.type === 'link'">
                    <transition name="fade">
                        <div>
                            <InputField
                                v-model="item.translations.url[editingLang]"
                                type="text"
                                :label="translate('Url Youtube or Vimeo')"
                                :name="'url'"
                                :placeholder="translate('Write the document url')"
                                :validate="'required|url'"
                                :validate-as="translate('link')"
                            />
                        </div>
                    </transition>
                </div>
            </div>
        </form>

        <div class="modal-actions right">
            <Button
                v-show="canDelete"
                class="negative"
                @click="onDelete()"
            >
                <t>Delete</t>
            </Button>
            <Button
                v-if="item.type"
                :state="button_state"
                @click="onValidate()"
            >
                {{ labelButton }}
            </Button>
        </div>
    </div>
</template>

<script>

import TranslatableFiles from '@/components/form/TranslatableFiles.vue';
import api from '@/store/api';

export default {
    name: 'CarouselMediaEdit',
    components: {
        TranslatableFiles,
    },
    inject: ['modal'],

    props: {
        value: { type: Object, default: () => {} },
        collection: { type: Array, required: true },
        editingLang: { type: String, required: true },
    },

    data() {
        return {
            item: null,
            type: 'file',
            button_state: 'idle',
            typeFiles: [
                {
                    img: '/static/icons/photo.svg',
                    type: 'file',
                    label: 'Photo',
                },
                {
                    img: '/static/icons/video.svg',
                    type: 'link',
                    label: 'Video',
                },
            ],
        };
    },

    computed: {
        labelTitle() {
            return this.translate(this.item.id ? 'Add Photo or Video' : 'Edit Photo or Video');
        },

        labelButton() {
            return this.translate(this.item.id ? 'Add' : 'Save');
        },

        defaultLanguageKey() {
            return this.shared.session.company.company_languages.models
                .find((language) => language.is_default).key;
        },

        // If editingLang is not the companyDefault one, user can't change item.type
        availableFileTypes() {
            if (this.item.id) {
                return this.typeFiles.filter((fileType) => fileType.type === this.item.type);
            }
            return this.typeFiles;
        },

        canDelete() {
            return this.editingLang === this.defaultLanguageKey && this.item.id;
        },
    },

    watch: {
        'item._state.isSaving': function (val) {
            this.button_state = val ? 'loading' : 'idle';
        },
    },

    created() {
        if (this.value) this.item = this.value;
        else {
            this.item = {};
            this.item.type = 'file';
            this.item.is_enabled = true;
        }

        this.modal.setTitle(this.labelTitle);
    },

    methods: {
        changeTo(type) {
            this.item.type = type;
            if (type === 'file') this.item.translations.url = {};
            else this.item.fileTranslations = {};
        },

        async onValidate() {
            if (!await this.$validator.validateAll()) return;
            if (this.button_state === 'loading') return;

            this.button_state = 'loading';
            await this.save();
            this.button_state = 'idle';

            this.$emit('onReload');
            this.modal.close();
        },

        async onDelete() {
            if (await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this media? Once you click on delete, you will no longer be able to access this media.') })) {
                await this.delete();
                this.modal.close();
                this.$emit('onReload');
            }
        },

        save() {
            return this.$http.post(
                api.endpoints.CAROUSEL_ITEMS,
                { carouselItem: this.item },
            );
        },

        delete() {
            return this.$http.delete(
                `${api.endpoints.CAROUSEL_ITEMS}/${this.item.id}`,
            );
        },
    },
};
</script>

<style lang="scss" scoped>
	@import "~@/styles/var";

	.input-removable {
		display: flex;
		justify-content: space-between;
		align-items: center;

		input {
			width: calc(100% - 35px);
		}
	}

	.add-options {
		text-align: right;
		text-decoration: underline;
		font-family: $lato-bold;
	}

</style>
