import Vue from 'vue';

export default {
    globalResults(id, data = {}) {
        return Vue.prototype.$http.post(`results/surveys/${id}`, data);
    },

    globalUserResults(id, userId, data = {}) {
        return Vue.prototype.$http.post(`results/surveys/${id}/users/${userId}`, data);
    },

    userSurveyResults(id, data = {}) {
        return Vue.prototype.$http.get(`results/user-surveys/${id}`, data);
    },
};
