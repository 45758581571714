<template>
    <div class="d-flex justify-content-center align-items-center flex-column">
        <img src="/static/illustrations/success1.svg">
        <div class="enrolling-finalize-title">
            <t>Congrats !</t>
        </div>
        <div class="enrolling-finalize-description">
            <t :enrollee_fullname="user.fullname">
                You've just finalized {enrollee_fullname}'s program
            </t>
        </div>
        <div
            class="d-flex align-items-center"
            style="gap: 1em"
        >
            <router-link to="/Dashboard">
                <Button
                    class="inner-box branding-color branding-border-color branding-border-color-darken-hover"
                >
                    <t>Back to Dashboard</t>
                </Button>
            </router-link>
            <router-link
                :to="{
                    name: 'ProfileProgram',
                    params: {
                        company_user_id: user.id,
                        company_user_program_id: userProgram.id,
                    },
                }"
            >
                <Button>
                    <t>Consult the program</t>
                </Button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EnrollingFinalized',
    props: {
        userProgram: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.enrolling-finalize {
    &-title {
        font-size: 32px;
        font-weight: 700;
        color: $black;
        margin-bottom: 8px
    }

    &-description {
        font-size: 18px;
        font-weight: 700;
        color: $black;
        margin-bottom: 48px;
    }
}
</style>
