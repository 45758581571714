export class MenuListItem {
    /**
     * @param {string} label
     * @param {any} value
     * @returns {void}
     */
    constructor(label, value) {
        this.label = label;
        this.value = value;
    }
}
