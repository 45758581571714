import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';

export default class CompanyUserConfidential extends Model {
    modelConfig() {
        return {
            controller: 'CompanyUserConfidentialController',
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
        };
    }
}
