<template>
    <div class="quick-actions-modal">
        <QuickActionsList
            v-if="$can('PageBulkEnrolling')"
            :title="translate('Quick actions')"
            :actions="quickActions"
            @close="$emit('close')"
        />

        <QuickActionsList
            v-if="$canRead('AbstractCanSelfEnroll')"
            :loading="loading"
            :title="translate('Start my new program')"
            :actions="selfEnrollableProgramActions"
            @close="$emit('close')"
        />
    </div>
</template>

<script>
import ModalMixin from '@/components/globals/modals/modalMixin';
import QuickActionsList from '@/components/globals/modals/QuickActions/QuickActionsList.vue';
import NewInvitationSessionModal from '@/components/pages/foc/multiple/NewInvitationSessionModal.vue';
import api from '@/store/api';
import GeneralLoadingModal from '../GeneralLoadingModal.vue';

export default {
    name: 'QuickActionsModal',
    permissions: [
        'AbstractCanSelfEnroll',
        'PageBulkEnrolling',
    ],

    components: {
        QuickActionsList,
    },

    mixins: [
        ModalMixin,
    ],

    data() {
        return {
            quickActions: [
                {
                    label: this.translate('Add user in program'),
                    icon: ['fal', 'database'],
                    tooltip: null,
                    callback: () => {
                        this.openCenterModal(NewInvitationSessionModal, {}, {}, { clickToClose: false });
                    },
                },
            ],
            selfEnrollableProgramActions: [],
            loading: false,
        };
    },

    async created() {
        if (this.$canRead('AbstractCanSelfEnroll')) {
            this.loading = true;
            const response = await api.configuration.programs.getSelfEnrollablePrograms();
            const programs = response.data.data;
            this.loading = false;

            this.selfEnrollableProgramActions = programs.sort(
                (a, b) => this.oldLocalize(a.locales, 'name').localeCompare(this.oldLocalize(b.locales, 'name')),
            ).map((program) => ({
                label: this.oldLocalize(program.locales, 'name'),
                icon: ['fal', 'database'],
                tooltip: this.oldLocalize(program.locales, 'description'),
                callback: () => {
                    this.genericConfirm(
                        this.translate('Do you want to start this program ?'),
                        this.translate('You\'re about to start a new program. By clicking yes, new resources will be created and assigned to you.'),
                    ).then(() => {
                        api.configuration.programs.selfEnrollOnProgram(program.id).then(() => {
                            this.$deletePermissions(['ModelCompanyUserRequirementCategory']);
                        });
                        this.openCenterModal(GeneralLoadingModal, { message: 'We are preparing your new resources.' });
                    });
                },
            }));
        }
    },
};
</script>

<style lang="scss" scoped>
.quick-actions-modal {
    border: 1px solid #E1E1E1;
    border-radius: 8px;
}
</style>
