<template>
    <div>
        <HtCard class="p-0">
            <template #title>
                <div
                    v-show="selectedDocuments.length === 0"
                    class="row w-100"
                >
                    <div class="col-md-6">
                        <div class="d-flex">
                            <UploadBar
                                ref="uploadBar"
                                v-model="file"
                                class="document-upload-bar"
                                :media="['docx', 'pdf']"
                                @onUploadEnd="documentUploaded = true"
                                @file-deleted="serverErrors = null"
                                @on-edit="serverErrors = null"
                            />

                            <HtButton
                                v-show="file && documentUploaded"
                                type="primary"
                                class="left-stick-btn"
                                @click.native="importDocument"
                            >
                                <t>Import</t>
                            </HtButton>
                        </div>
                    </div>
                </div>

                <div v-show="selectedDocuments.length > 0">
                    <div class="update-state-form">
                        <label
                            for="state"
                            class="mr-3 align-self-center"
                        ><t>Update state to </t></label>
                        <HtFormSelect
                            :id="'state'"
                            v-model="updateStateValue"
                            class="mb-0 update-state-select"
                            :name="'state'"
                            :is-single="true"
                            :placeholder="translate('State')"
                            :options="statesOptions"
                            :show-optional="false"
                            :allow-empty="true"
                        />
                        <HtButton
                            :disabled="isLoading || isUpdating"
                            type="primary"
                            class="left-stick-btn"
                            @click.native="updateStates()"
                        >
                            <t>Apply</t>
                        </HtButton>
                    </div>
                </div>

                <div
                    v-if="serverErrors"
                    class="ml-3"
                >
                    <span
                        v-for="(error, index) in serverErrors"
                        :key="index"
                        class="error-message font-weight-bold branding-color"
                    >
                        {{ error[0] }}&nbsp;
                    </span>
                </div>

                <HtCardTitleBar
                    v-show="selectedDocuments.length === 0"
                    title=""
                    :show-button="false"
                    @search="setSearchValue"
                >
                    <template #card-title-bar-right>
                        <HtFormSelect
                            :id="'state'"
                            v-model="querySearch.state"
                            class="mb-0 update-state-select"
                            :name="'state'"
                            :is-single="true"
                            :placeholder="translate('State')"
                            :options="statesOptions"
                            :allow-empty="true"
                        />
                    </template>
                </HtCardTitleBar>
            </template>

            <div
                class="d-flex align-items-center justify-content-end py-4"
            >
                <HtButton
                    type="tertiary"
                    :disabled="isLoading || isUpdating"
                    @click.native="refreshDocumentList()"
                >
                    <i class="fas fa-sync mr-3" />
                </HtButton>
            </div>

            <IconLoading v-if="isLoading === true" />
            <HtTable
                v-else
                :items="formattedDocuments"
                :columns="documentColumns"
                :empty-message="'No data found'"
                :external-search="search"
                :loading="isLoading"
                :has-multiple-selection="true"
                @onCellClicked="onDocumentRowClicked"
                @onRowSelected="onRowSelected"
            />
        </HtCard>

        <EditKnowledgeDocumentFormModal
            ref="modal"
            @on-close="onClose"
        />
    </div>
</template>

<script>
import HtCard from '@/components/globals/HtCard.vue';
import HtTable from '@/components/globals/HtTable.vue';
import HtButton from '@/components/globals/HtButton.vue';
import UploadBar from '@/components/globals/UploadBar/UploadBar.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import EditKnowledgeDocumentFormModal from '@/components/pages/settings/ai/EditKnowledgeDocumentFormModal.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';

export default {
    components: {
        HtFormSelect,
        IconLoading,
        EditKnowledgeDocumentFormModal,
        HtCardTitleBar,
        UploadBar,
        HtButton,
        HtTable,
        HtCard,
    },
    data() {
        return {
            file: null,
            updateStateValue: null,
            search: '',
            isLoading: false,
            isUpdating: false,
            documentUploaded: false,
            serverErrors: null,
            documents: [],
            querySearch: {
                state: null,
            },
            statesOptions: [
                { id: 'in_review', name: this.translate('In Review') },
                { id: 'published', name: this.translate('Published') },
            ],
            selectedDocuments: [],
            documentColumns: [
                {
                    label: '',
                    key: 'is_selected',
                    sortable: false,
                    class: 'w-auto hide-mobile p-0',
                },
                {
                    key: 'document_name',
                    label: this.translate('Name'),
                    search: true,
                    class: 'w-auto',
                },
                {
                    key: 'document_state',
                    label: this.translate('State'),
                    search: true,
                    class: 'w-10',
                },
            ],
        };
    },
    computed: {
        formattedDocuments() {
            const documents = this.documents.map((document) => ({
                document_id: document.id,
                document_state: document.state,
                document_name: document.original_name,
                company_file_id: document.company_file_id,
            }));

            if (this.querySearch.state && this.querySearch.state.trim() !== '') {
                return documents.filter((document) => document.document_state === this.querySearch.state);
            }

            return documents;
        },
    },
    mounted() {
        this.refreshDocumentList();
    },
    methods: {
        onClose() {
            this.refreshDocumentList();
        },
        onRowSelected(data) {
            this.selectedDocuments = data.selectedRows.map((row) => row.item);
        },
        onDocumentRowClicked(row) {
            this.$refs.modal.open(row.item.document_id);
        },
        setSearchValue(val) {
            this.search = val;
        },
        refreshDocumentList() {
            this.serverErrors = null;
            this.file = null;
            this.$refs.uploadBar.resetProgression();
            this.documentUploaded = false;
            this.isLoading = true;

            this.$http.get('company/knowledge/document').then((response) => {
                this.documents = response.data.data;
            }).catch(({ response }) => {
                if (response.status !== 409) {
                    alert('An error occurred.');

                    this.$Notifier('App').showError(this.translate('An error occurred'));
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        updateStates() {
            if (this.selectedDocuments.length === 0) {
                alert(this.translate('Please select at least one document'));

                return;
            }

            if (!this.updateStateValue) {
                alert(this.translate('Please select a state'));

                return;
            }

            this.isUpdating = true;
            this.$http.put('company/knowledge/document', {
                company_file_ids: this.selectedDocuments.map((document) => document.company_file_id),
                state: this.updateStateValue,
            }).then(() => {
                this.$Notifier('App').showInfo(this.translate('Updated with success!'));

                this.selectedDocuments = [];
                this.refreshDocumentList();
            }).catch(({ response }) => {
                if (response.status === 422) {
                    this.serverErrors = response.data.errors;
                }

                if (response.status !== 409) {
                    this.$Notifier('App').showError(this.translate('An error occurred'));
                }
            }).finally(() => {
                this.isUpdating = false;
            });
        },
        importDocument() {
            this.serverErrors = null;
            this.isLoading = true;

            this.file.company_file_id = this.file.id;

            this.$http.post('company/knowledge/document', this.file).then((response) => {
                if (response.status >= 300) {
                    this.$Notifier('App').showError(this.translate('An error occurred'));

                    return;
                }

                this.$Notifier('App').showInfo(this.translate('Imported with success!'));

                this.refreshDocumentList();
            }).catch(({ response }) => {
                if (response?.status === 422) {
                    this.serverErrors = response.data.errors;
                }

                if (response.status !== 409) {
                    this.$Notifier('App').showError(this.translate('An error occurred'));
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.document-upload-bar {
    font-size: 1.5rem;
    white-space: nowrap;
}

.update-state-form {
    display: grid;
    grid-template-columns: 1fr 2fr 0.5fr;
    font-size: 1.5rem;

    label {
        white-space: nowrap;
    }

    .update-state-select {
        min-width: 150px;
    }
}

.error-message {
    font-size: 1.4rem;
    white-space: nowrap
}

.left-stick-btn {
    white-space: nowrap;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left:-4px;
    z-index: 10;
}
</style>
