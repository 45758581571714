<template>
    <div class="wrapper">
        <aside>
            <slot name="aside" />
        </aside>
        <main
            id="flow-container"
            class="content-info"
        >
            <slot name="main" />
        </main>
        <footer
            v-if="'footer' in $slots"
            class="footer"
        >
            <slot name="footer" />
        </footer>
        <div>
            <slot name="intro" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'WelcomChatbotLayout',
    inject: ['$validator'],
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";
$width-aside-bar: 20%;

aside {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: $width-aside-bar;
    z-index: 3;
}
main {
    padding-top: 13.3rem;
    padding-bottom: 16.3rem;
    margin-left: 8%;
    width: 43.7rem;
}

footer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    height: 8%;
	bottom: 0;
	right: 0;
    left: $width-aside-bar;
    background-color: white;
	z-index: 2;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.wrapper {
    background: $bg-grey;
    margin-left: $width-aside-bar;
}
</style>
