import Vue from 'vue';

/**
 * @typedef {Object} Equipment
 * @property {number} id
 * @property {Resource} resource
 */

/**
 * @typedef {{data: {data: Equipment}}} EquipmentResourceResponse
 * @typedef {{data: {data: Equipment[]}}} EquipmentCollectionResponse
 */

export default {
    /**
     * @param {any} data
     * @returns {Promise<EquipmentCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.post('configuration/equipments/list', data);
    },

    /**
     * @param {number|string} id
     * @param {any} config
     * @returns {Promise<EquipmentResourceResponse>}
     */
    replicate(id, config = {}) {
        return Vue.prototype.$http.post(`configuration/equipments/${id}/replicate`, config);
    },
};
