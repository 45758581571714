<template>
    <div class="main">
        <slot name="main" />
        <slot />
    </div>
</template>

<script>
export default {
    name: 'HtFormCard',
    inject: ['$validator'],
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.main {
    display: flex;
    flex-direction: column;
    padding: 3.2rem;
    background: $color-lightblue-grey;
    border-radius: $border-radius-8;
}
</style>
