<template>
    <HtSidebar class="ht-stepper-sticky">
        <HtStepperVertical
            :value="value"
            :steps="steps"
            @input="$emit('input', $event)"
        >
            <slot />
        </HtStepperVertical>
        <template #footer>
            <div
                class="ht-stepper-sidebar-footer d-flex align-items-center"
                @click="goBack"
            >
                <div class="ht-stepper-sidebar-footer-icon">
                    <FontAwesomeIcon
                        size="1x"
                        :icon="['fal', 'times']"
                        class="icon-second"
                    />
                </div>
                <div class="ht-stepper-sidebar-footer-label">
                    <t>Leave</t>
                </div>
            </div>
        </template>
    </HtSidebar>
</template>
<script>
import HtSidebar from './HtSidebar.vue';
import HtStepperVertical from './HtStepperVertical.vue';

export default {
    name: 'HtStepperSidebar',
    components: {
        HtSidebar,
        HtStepperVertical,
    },

    props: {
        value: {
            type: Number,
            default: undefined,
        },
        steps: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        goBack() {
            this.$emit('back');
            this.$router.goBack();
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.ht-stepper-sticky {
    position: sticky;
    top: 20px;
}

.ht-stepper-sidebar-footer {
    cursor: pointer;
    transition: color .2s ease;

    &:hover {
        color: darken($error-1, 10%);

        .ht-stepper-sidebar-footer-icon {
            background: darken($error-1, 10%);
        }
    }

    &-icon {
        display: flex;
        justify-content: center;
        align-items:center;
        border-radius: 50%;
        background: $error-1;
        width: 14px;
        height: 14px;
        margin-right: 10px;
        transition: background-color .2s ease;

        .icon-second {
            color: white;
            font-size: 10px;
        }
    }

    &-label {
        color: $error-1;
        font-size: 14px;
        font-weight: 700;
    }
}

@media (max-width: $tablet) {

    .ht-stepper-sticky {
        position: sticky;
        flex-direction: column;
    }

    .ht-sidebar-default {
        display: flex;
        justify-content: center;
        transform: rotate(-90deg);
    }
}
</style>
