<template>
    <HtFormGroup
        :label="label"
        :error="error"
        :is-required="isRequired"
        :show-asterisk="showAsterisk"
    >
        <HtFormVariables
            v-if="variables.length > 0"
            :variables="variables"
            @addVariable="addVariable"
        />
        <textarea
            :id="id"
            ref="input"
            class="ht-form-textarea"
            :name="name"
            :rows="rowsCount"
            :placeholder="placeholder"
            :maxlength="maxlength"
            :disabled="disabled"
            :value="inputValue"
            @input="$emit('input', $event.target.value)"
            @click.stop
            @change="$emit('input', inputValue)"
            @keydown="onKeyUp($event)"
        />
    </HtFormGroup>
</template>

<script>
import HtFormMixin from './HtFormMixin';
import HtFormVariables from './HtFormVariables.vue';

export default {
    name: 'HtFormExtensibleTextarea',
    components: {
        HtFormVariables,
    },
    mixins: [
        HtFormMixin,
    ],

    props: {
        value: {
            type: String,
            default: () => '',
        },
        rows: {
            type: Number,
            default: () => 1,
        },
        maxlength: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            rowsCount: 1,
            inputValue: '',
        };
    },

    watch: {
        value(newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }

            this.inputValue = newValue;
        },
    },

    mounted() {
        this.rowsCount = this.rows;
    },

    methods: {
        addVariable(variable) {
            const position = this.$refs.input.selectionStart;

            const text = (this.value) ? this.value.substring(0, position) + variable + this.value.substring(position) : variable;

            this.$emit('input', text);

            this.$nextTick(() => {
                this.$refs.input.setSelectionRange(position + variable.length, position + variable.length);
                this.$refs.input.focus();
            });
        },

        getCaret(el) {
            if (el.selectionStart) {
                return el.selectionStart;
            }

            if (document.selection) {
                el.focus();
                const r = document.selection.createRange();
                if (!r) {
                    return 0;
                }

                const re = el.createTextRange(); const
                    rc = re.duplicate();
                re.moveToBookmark(r.getBookmark());
                rc.setEndPoint('EndToStart', re);
                return rc.text.length;
            }
            return 0;
        },

        onKeyUp(event) {
            if (event.key === 'Enter' && event.shiftKey) {
                event.preventDefault();

                const caret = this.getCaret(event.target);

                this.inputValue = `${this.value.substring(0, caret)}\n${this.value.substring(caret)}`;

                this.rowsCount += 1;
            } else if (event.key === 'Backspace' || event.key === 'Delete') {
                const lines = this.inputValue.trim().split('\n');

                this.rowsCount = lines.length;
            } else if (event.key === 'Enter') {
                event.preventDefault();

                this.$emit('onKeyupEnter');
            }
        },
    },
};
</script>

<style lang="scss" src="./HtFormExtensibleTextarea.scss"></style>
