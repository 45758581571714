import Model from '@tony.caron/node-model-proxy/Model';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';
import CompanyBenefitCollection from './CompanyBenefitCollection';

export default class CompanyBenefit extends Model {
    modelConfig() {
        return {
            collection: CompanyBenefitCollection,
        };
    }

    modelDefaultValues() {
        return {
            translations: {
                name: {},
            },
            is_heyteam: 0,
        };
    }

    modelAccessors() {
        return {
            name: () => this.translations.name[RtwSharedSpace.store.session.companyUser.company_language.key],
        };
    }
}
