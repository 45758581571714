import Vue from 'vue';
import VueDragResize from 'vue-drag-resize';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import globals from '@/components/globals';
import icons from '@/components/icons';
import CountryFlag from 'vue-country-flag';
import MaskedInput from 'vue-text-mask';
import Multiselect from 'vue-multiselect';
import Flash from '@/components/Flash.vue';

Vue.component('VueDragResize', VueDragResize);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText);
// global components
globals.map(([tag, component]) => Vue.component(tag, component));
icons.map(([tag, component]) => Vue.component(tag, component));

Vue.component('CountryFlag', CountryFlag);
Vue.component('MaskedInput', MaskedInput);
Vue.component('Multiselect', Multiselect);
Vue.component('Flash', Flash);
