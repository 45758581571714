import clonedeep from 'lodash.clonedeep';
import moment from 'moment';
import CompanyRole from '@/models/CompanyRole';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';
import Vue from 'vue';
import isMobile from 'ismobilejs';
import uniq from 'lodash.uniq';
import api from './store/api';
import I18n from './modules/i18n/I18n';
import Env from './Env';

export default class Utils {
    static Vue = null;

    static uniqId() {
        return (new Date().getTime() + Math.floor((Math.random() * 10000) + 1)).toString(16);
    }

    static capitalize(str) {
        if (!str) {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    // Concate S3 path to a relative image path
    static resolveS3PublicImage(imagePath, isGuest) {
        if (!imagePath || !imagePath.length) return '';

        const container = isGuest ? 'guest' : 'public';

        if (imagePath.indexOf(container) !== -1) {
            const splitPublic = imagePath.split(container).reverse();
            return Env.getFromEnv('S3_URL', { company_name: window.location.host.split('.')[0] }) + container + splitPublic[0];
        }

        return imagePath;
    }

    static clone(_object, deep = false) {
        if (deep) { return clonedeep(_object); }

        return { ..._object };
    }

    static moment(value = false, input_format = false) {
        return !value ? moment() : (input_format ? moment(value, input_format) : moment(value));
    }

    /**
     * @description Return a localized date string
     * @param {mixed} value
     * @param {string} languageKey
     * @param {string} inputFormat
     * @returns
     */
    static momentLocalized(value, languageKey, inputFormat = false) {
        moment.locale(languageKey);
        return !inputFormat ? moment(value) : moment(value, inputFormat);
    }

    static mysqlToDate(value, format = false) {
        if (!value) return '';

        const _format = format === false ? 'DD/MM/YYYY' : format;

        return moment.utc(String(value), 'YYYY-MM-DD HH:mm:ss').local().format(_format);
    }

    static dateToMysql(value, format = false, input_format = false) {
        const _format = format === false ? 'YYYY-MM-DD HH:mm:ss' : format;

        if (input_format) { return String(value).length ? moment(value, input_format).format(_format) : null; }
        return String(value).length ? moment(value).format(_format) : null;
    }

    static company_user = {};

    // https://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
    static dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) { byteString = atob(dataURI.split(',')[1]); } else { byteString = unescape(dataURI.split(',')[1]); }
        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        return new Blob([ab], { type: mimeString });
    }

    static install(Vue) {
        Utils.Vue = Vue;
        Vue.prototype.$Utils = Utils;

        Vue.prototype.$moment = Utils.moment;
    }

    // https://stackoverflow.com/questions/4814398/how-can-i-check-if-a-scrollbar-is-visible
    // https://stackoverflow.com/questions/5207301/jquery-easing-functions-without-using-a-plugin
    static firstParentScrollableToTop(el, speed = 500) {
        // eslint-disable-next-line no-cond-assign
        while (el = el.parentNode) {
            if (el.scrollTop != 0 && el.nodeType != 9) {
                $(el).animate({ scrollTop: 0 }, speed, 'easeOutExpo');
                return;
            }
        }
    }

    static scrollToTop(el, speed = 500, paddingTop = 0, elem = Env.isDev() ? '#app' : 'body,html') {
        if (el) {
            $(elem).animate({ scrollTop: $(el)[0].offsetTop - paddingTop }, speed, 'easeOutExpo');
        } else {
            $('html, body').animate({
                scrollTop: $(elem).offset().top,
            }, 'slow');
        }
    }

    static embedVideo(url) {
        if (Utils.isYoutubeUrl(url)) {
            return `https://www.youtube.com/embed/${this.getYoutubeVideoId(url)}`;
        }
        if (url.includes('vimeo')) {
            return `https://player.vimeo.com/video/${this.getVimeoVideoId(url)}`;
        }
    }

    static async getVideoThumbnail(url) {
        // This method tends to be more complicated than it should because of youtube being
        // Unable to return "the best quality thumbnail"
        // The problem is that maxresdefault is not always available
        // and if it's not we get an uggly 120px not found thumbnail.
        // So we have to test if it exists
        // Otherwise we try the next one in the quality order.
        if (Utils.isYoutubeUrl(url)) {
            const youtubeThumbBaseUrl = `https://img.youtube.com/vi/${this.getYoutubeVideoId(url)}`;

            const request = `${youtubeThumbBaseUrl}/maxresdefault.jpg`;
            // Externalizing the resolve method of a promise so we can wait
            // For resolution before running the rest of the code.
            let res;
            const prom = new Promise((resolve) => {
                res = resolve;
            });

            // Using an Image.src because img are not submitted to the same
            // CORS rules. You would get CORS error trying the same with fetch or axios.
            const img = new Image();
            img.src = request;
            img.onload = function () {
                res(this.width);
            };

            const width = await prom;
            // The only way to get a 120 width is by getting the not found image from youtube.
            // Based on a trick seen here : https://gist.github.com/tonY1883/a3b85925081688de569b779b4657439b
            // So if it's the case, we just continue looping 'till we get anything else.
            // hqdefault (second quality in the array) should always be available.
            if (width > 120) {
                return request;
            }

            // If something goes wrong, return the one that should always be available
            return `${youtubeThumbBaseUrl}/hqdefault.jpg`;
        }
    }

    static getYoutubeVideoId(url) {
        if (url.includes('youtube')) {
            return url.split('v=')[url.split('v=').length - 1].split('&')[0];
        }

        if (url.includes('youtu.be')) {
            return url.split('?')[0].split('/').pop();
        }
    }

    static getVimeoVideoId(url) {
        return url.split('/')[url.split('/').length - 1];
    }

    static getArrayDifference(array1, array2, key = null) {
        const checkIfExists = (item1, item2) => (key && item1[key] === item2[key]) || (!key && Utils.isEqualObj(item1, item2));
        const inArray = (item1, array) => array.filter((item2) => checkIfExists(item1, item2)).length;

        return array1.filter((item1) => !inArray(item1, array2));
    }

    static getArrayUniqueByProperty(keyFn, array) {
        const mySet = new Set();
        return array.filter((x) => {
            const key = keyFn(x); const
                isNew = !mySet.has(key);
            if (isNew) mySet.add(key);
            return isNew;
        });
    }

    static isEqualObj(obj1, obj2) {
        let equal = true;

        if (Object.keys(obj1).length !== Object.keys(obj2).length) { return false; }

        Object.keys(obj1).forEach((key) => {
            if (obj1[key] !== obj2[key]) { equal = false; }
        });

        return equal;
    }

    static getDefaultUserImage() {
        return '/static/images/member.png';
    }

    static getCompanyComponentRoleInitials(role, size = 1, company_user = null, name = null) {
        let firstname = ' ';
        let lastname = ' ';
        let image = Utils.getDefaultUserImage();

        switch (role) {
        case CompanyRole.ALIAS_ADMIN:
            firstname = 'Admin';
            break;

        case CompanyRole.ALIAS_BUDDY:
            firstname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'Parrain' : 'Buddy';
            break;

        case CompanyRole.ALIAS_EMPLOYEE:
            firstname = I18n.translate('Employee');
            break;

        case 'heyteam':
            image = '/static/images/logo.png';
            break;

        case CompanyRole.ALIAS_SOFTWARE_MANAGER:
            firstname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'Responsable' : 'I';
            lastname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'Informatique' : 'T';
            break;

        case CompanyRole.ALIAS_EQUIPMENT_MANAGER:
            firstname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'Responsable' : 'E';
            lastname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'Matériel' : 'R';
            break;

        case CompanyRole.ALIAS_ADMINISTRATIVE_MANAGER:
            firstname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'R' : 'H';
            lastname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'H' : 'R';
            break;

        case 'company_planning':
            image = '/static/images/logo.png';
            break;

        case 'user':
            firstname = company_user.firstname;
            lastname = company_user.lastname;
            image = company_user.image ? company_user.image : image;
            break;

            // GROUPS
        case 'owner':
            firstname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'Createur de l\'' : 'Onboarding';
            lastname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'onboarding' : 'Creator';
            break;

        case 'company':
            firstname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'Tous les' : 'All';
            lastname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'employees' : 'Employees';
            break;

        case 'program_participants':
            firstname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'Participants du' : 'Program';
            lastname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'programme' : 'Participants';
            break;

        case 'company_department':
            if (name == null) {
                firstname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'Departement de l\'' : 'Company';
                lastname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'entreprise' : 'Department';
            } else {
                switch (name) {
                case 'Enrollee department':
                    firstname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'Departement du' : 'Enrollee';
                    lastname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'destinataire de programme' : 'Department';
                    break;
                default:
                    [firstname, lastname] = name.split(' ');
                }
            }
            break;

        case 'enrollee_department':
            firstname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'Departement du' : 'Enrollee';
            lastname = RtwSharedSpace.store.session.companyUser.company_language.codeFlag == 'fr' ? 'destinataire de programme' : 'Department';
            break;

        default:
            let a = null;
            if (role.indexOf('_') != -1) a = role.split('_');
            else if (role) a = role.split(' ');

            [firstname = ' ', lastname = ' '] = a;

            break;
        }
        return {
            image, firstname, lastname, size,
        };
    }

    static findIndexInArrayWithAttr(array, attr, value) {
        for (let i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    /**
     * Helper to scroll to first error when validateAll()
     * @param {Vue} nodeInstance
     */
    static scrollToFirstError(nodeInstance) {
        const errors = Object.keys(nodeInstance.errors.collect());

        if (errors.length === 0) return;

        let elementToPoint;
        for (const error of errors) {
            elementToPoint = nodeInstance.$el.querySelector(`[name="${error}"]`);
            if (elementToPoint) {
                break;
            }
        }

        if (elementToPoint) {
            if (elementToPoint.getAttribute('type') === 'hidden') {
                // if the error is on hidden input, we point the parent element
                elementToPoint = elementToPoint.parentElement;
            }

            elementToPoint.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }

    // @param list of data|string
    // @param separator to join with
    // @param int to display
    // @param string label to use when the list is too long
    // @return exemple Data1 | Data2 (+7)
    static listToTruncatedString(list, separator, lengthToDisplay, label) {
        // Take out all input from 0 to lengToDisplay and join it with the separator
        const expandedList = list.slice(0, lengthToDisplay).join(separator);
        // slice the rest from lengthToDisplay and concat it as (+X)
        const overflowingCount = list.slice(lengthToDisplay).length;

        return expandedList + (overflowingCount > 0 ? ` +${overflowingCount} ${I18n.translate(label, { count: overflowingCount })}` : '');
    }

    static shared() {
        return Vue.prototype.$RtwSharedSpace.store;
    }

    /**
	 * @param {number} companyId
	 */
    static autologin(companyId) {
        return api.holding.getLoginToken(companyId).then(({ data: { data: { token, url: host } } }) => new URL(`${host}/AutoLogin/${token}`));
    }

    static getUniqName(name) {
        return `${Utils.uniqId()}-${name}`;
    }

    static isMobileDevice() {
        return isMobile(navigator).any;
    }

    static getBackroute(router) {
        const origin = router.historyTrack[router.historyTrack.length - 1];

        if (['Team', 'TeamAllEmployees', 'Dashboard', 'ProgramDetailLive', 'Profile', 'DashboardPrograms'].includes(origin.name)) {
            return { ...origin };
        } if (origin.name === 'FocFinalise') {
            return router.resolve({ name: 'Programs' }).location;
        }

        return router.resolve({ name: 'Dashboard' }).location;
    }

    static hasDuplicate(array) {
        return uniq(array).length !== array.length;
    }

    static mimeTypeImgAvailable(mimeType) {
        const mimeTypeAvailable = [
            'image/gif',
            'image/x-icon',
            'image/jpeg',
            'image/bmp',
            'image/png',
            'image/svg+xml',
            'image/webp',
        ];

        const setObj = new Set(mimeTypeAvailable);

        return setObj.has(mimeType);
    }

    static convertByteToKilobyte(sizeInByte) {
        return sizeInByte / 1000;
    }

    static convertByteToMegabyte(sizeInByte) {
        return sizeInByte / 1000 / 1000;
    }

    /**
     * @param {number} dividend
     * @param {number} divisor
     * @returns {number}
     */
    static getPercentage(dividend, divisor) {
        if (divisor === 0) {
            return 100;
        }

        return Math.round((dividend / divisor) * 100);
    }

    /**
     * @param {string} url
     * @returns {boolean}
     */
    static isYoutubeUrl(url) {
        return url.includes('youtube') || url.includes('youtu.be');
    }

    static isSubstringWithoutDiacritics(needle, haystack) {
        const regExp = new RegExp(Utils.removeDiacritics(needle), 'gi');
        return regExp.test(Utils.removeDiacritics(haystack));
    }

    static removeDiacritics(text) {
        if (typeof text === 'undefined' || text === null || typeof text.toString !== 'function') {
            return '';
        }

        return text.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    static getCsrfTokenFromHeaders(headers) {
        const normalizedHeaders = Object.keys(headers).reduce((acc, key) => {
            acc[key.toLowerCase()] = headers[key];
            return acc;
        }, {});

        if ('x-xsrf-token' in normalizedHeaders) {
            return normalizedHeaders['x-xsrf-token'];
        }
        return null;
    }
}
