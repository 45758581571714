<template>
    <HtModal ref="modal">
        <template #header>
            <t>Add filter</t>
        </template>
        <template #default>
            <div class="mb-4 modal-form-label">
                <t>Select filters to add</t>
            </div>
            <div class="ht-list-simple">
                <div class="mb-3 ht-list-simple-info">
                    <t
                        :count="getCountSelectedEntities"
                        :total="customEntityList.length"
                    >
                        {count}/{total} selected entity | {count}/{total} selected entities
                    </t>
                </div>
                <div
                    v-if="customEntityList.length > 0"
                    class="ht-list-simple-container"
                >
                    <div
                        v-for="(entity, index) in customEntityList"
                        :key="index"
                        class="d-flex align-items-center ht-list-simple-container-item clickable"
                        @click.stop="onSelectFilter(entity)"
                    >
                        <div class="mr-3">
                            <HtCheckbox v-model="entity.selected" />
                        </div>
                        <div class="ht-list-simple-container-item-label">
                            {{ entity.name }}
                        </div>
                        <div class="filler" />
                        <div class="ht-list-simple-container-item-label">
                            <t :count="entity.values.length">
                                {count} entry | {count} entries
                            </t>
                        </div>
                    </div>
                </div>
                <div
                    v-show="customEntityList.length === 0"
                    class="ht-list-simple-no-result"
                >
                    <t>No custom entities found for this company</t>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="text-right">
                <Button
                    class="mr-3 negative"
                    :state="buttonState"
                    @click="close()"
                >
                    <t>Back</t>
                </Button>
                <Button
                    :state="buttonState"
                    @click="onImport"
                >
                    <t>Import</t>
                </Button>
            </div>
        </template>
    </HtModal>
</template>
<script>
import HtCheckbox from '@/components/globals/HtCheckbox.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';

export default {
    name: 'CustomEntitiesList',
    components: {
        HtCheckbox,
        HtSearchField,
    },
    props: {
        onSave: {
            type: Function,
            default: null,
        },
    },

    data() {
        return {
            customEntityList: [],
            buttonState: 'idle',
        };
    },

    computed: {
        getCountSelectedEntities() {
            return this.customEntityList.reduce((accum, current) => {
                if ('selected' in current && current.selected === true) {
                    accum += 1;
                }

                return accum;
            }, 0);
        },
    },

    methods: {
        open(selectedEntities) {
            this.$http.get('configuration/entities').then((response) => {
                this.customEntityList = response.data.data.filter((entity) => !entity.is_heyteam).map((entity) => ({
                    ...entity,
                    selected: !!selectedEntities.find((selectedEntity) => selectedEntity.id === entity.id),
                }));

                this.$refs.modal.open();
            });
        },

        close() {
            this.$refs.modal.close();
        },

        onImport() {
            if (this.onSave) {
                this.buttonState = 'loading';
                const selected = this.customEntityList.filter((filter) => filter.selected === true);

                this.onSave(selected);

                this.buttonState = 'idle';
                this.$refs.modal.close();
            } else {
                this.$refs.modal.close();
            }
        },

        onSelectFilter(filter) {
            filter.selected = !filter.selected;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.modal-form-label {
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-align: justify;
}

.ht-list-simple {
    background: $blue-light;
    border-radius: 4px;
    padding: 16px;

    &-info {
        font-size: 10px;
        font-weight: bold;
        color: $grey-5;
    }

    &-container {
        border: 1px solid $grey-ultra-light;
        border-radius: 4px;

        &-item {
            background: white;
            padding: 16px;

            &:not(:last-child) {
                border-bottom: 1px solid $grey-ultra-light;
            }

            &:first-child {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }

            &:last-child {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            &-label {
                font-size: 14px;
                color: $black-2;
            }
        }
    }

    .filler{
        flex-grow:1;
        text-align:center
    }

    &-no-result {
        font-size: 14px;
        color: $black-2;
    }
}
</style>
