<template>
    <div class="bulk-enrolling-summary">
        <div class="bulk-enrolling-summary-header">
            <h2><t>Campaign summary</t></h2>
            <h3><t>Make sure your campaign is set up correctly</t></h3>
        </div>

        <div class="bulk-enrolling-summary-content">
            <HtCollapse>
                <HtCollapseItem>
                    <Skeleton
                        width="150px"
                        height="20px"
                        :loading="loading"
                    >
                        <t :count="users.length">{count} targeted users</t>
                    </Skeleton>

                    <template #content>
                        <HtGenericTag
                            v-for="user in users"
                            :key="user.id"
                            :label="user.fullname"
                        >
                            <template #image>
                                <div
                                    v-user-image="user"
                                    class="image"
                                />
                            </template>
                        </HtGenericTag>
                    </template>

                    <template #header-right>
                        <HtButton
                            @click.native="$emit('go-to', 'BulkEnrollingPersonalInformation')"
                            type="secondary"
                            :icon="['fas','pencil']"
                            icon-only
                        />
                    </template>
                </HtCollapseItem>

                <HtCollapseItem>
                    <Skeleton
                        width="150px"
                        height="20px"
                        :loading="loading"
                    >
                        <t :count="entities.length">{count} entities in targeting</t>
                    </Skeleton>

                    <template #content>
                        <HtGenericTag
                            v-for="entity in entities"
                            :key="entity.value.id"
                            :label="entity.value.name"
                            :count="entity.count"
                        />
                    </template>

                    <template #header-right>
                        <HtButton
                            @click.native="$emit('go-to', 'BulkEnrollingTarget')"
                            type="secondary"
                            :icon="['fas','pencil']"
                            icon-only
                        />
                    </template>
                </HtCollapseItem>

                <HtCollapseItem>
                    <Skeleton
                        width="150px"
                        height="20px"
                        :loading="loading"
                    >
                        <t :count="resources.length">{count} resources are allocated</t>
                    </Skeleton>

                    <template #content>
                        <HtGenericTag
                            v-for="resource in resources"
                            :key="resource.value"
                            :label="resourceLabels[resource.value]"
                            :type="resource.value"
                            :count="resource.count"
                        >
                            <template #image>
                                <FontAwesomeIcon
                                    :icon="resourceIcons[resource.value]"
                                />
                            </template>
                        </HtGenericTag>
                    </template>

                    <template #header-right>
                        <HtButton
                            @click.native="$emit('go-to', 'BulkEnrollingProgram')"
                            type="secondary"
                            :icon="['fas','pencil']"
                            icon-only
                        />
                    </template>
                </HtCollapseItem>

                <HtCollapseItem :collapsable="false">
                    <t>Campaign start</t>

                    <template #header-right>
                        <t>Immediately</t>
                    </template>
                </HtCollapseItem>
            </HtCollapse>
        </div>

        <div class="bulk-enrolling-summary-footer">
            <HtButton
                cypress="foc-previous-program"
                type="secondary"
                :disabled="!hasPrev"
                @click.native="$emit('on-prev')"
            >
                <t>Previous</t>
            </HtButton>

            <HtButton
                cypress="foc-next-program"
                :loading="loading"
                :disabled="loading || isReadOnly"
                @click.native="terminate"
            >
                <t>Start campaign</t>
            </HtButton>
        </div>
    </div>
</template>

<script>
import { Skeleton } from 'vue-loading-skeleton';
import api from '@/store/api';
import HtButton from '@/components/globals/HtButton.vue';
import HtCollapse from '@/components/globals/HtCollapse.vue';
import HtCollapseItem from '@/components/globals/HtCollapseItem.vue';
import BulkEnrollingMixin from '@/router/pages/programs/enrolling/bulk/BulkEnrollingMixin';
import HtGenericTag from '@/components/globals/Tag/HtGenericTag.vue';
import ResourcesInfo from '@/ResourcesInfo';

export default {
    name: 'BulkEnrollingSummary',

    components: {
        HtGenericTag,
        HtCollapseItem,
        HtCollapse,
        HtButton,
        Skeleton,
    },

    mixins: [
        BulkEnrollingMixin,
    ],

    data() {
        return {
            loading: true,
            users: [],
            entities: [],
            resources: [],
            resourceLabels: ResourcesInfo.labels,
            resourceIcons: ResourcesInfo.icons,
        };
    },

    created() {
        api
            .user
            .enrolling
            .bulk
            .getSummaryInformation(this.bulk.id)
            .then((res) => {
                const summaryInformation = res.data.data;

                this.users = summaryInformation.users;
                this.entities = summaryInformation.entities;
                this.resources = summaryInformation.resources;

                this.loading = false;
            });
    },

    methods: {
        terminate() {
            if (this.isReadOnly) {
                return;
            }

            this.loading = true;

            api
                .user
                .enrolling
                .bulk
                .finalize(this.bulk.id)
                .then(() => {
                    this.loading = false;

                    this.$Notifier('App').showInfo(
                        this.translate('Generation in progress, you will receive a notification when it is finished'),
                    );

                    this.$router.push('/Dashboard');
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.bulk-enrolling-summary {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 640px;
    margin: 0 auto;
    gap: 16px;

    &-header {
        h2 {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            text-align: center;
            margin: 0;
        }

        h3 {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            text-align: center;
            margin: 0;
        }
    }

    &-footer {
        display: flex;
        justify-content: space-between;
    }
}
</style>
