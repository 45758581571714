import Model from '@tony.caron/node-model-proxy/Model';

import CompanySurveyExtra from './CompanySurveyExtra';
import CompanyUserSurveyExtraCollection from './CompanyUserSurveyExtraCollection';

export default class CompanyUserSurveyExtra extends Model {
    modelConfig() {
        return {
            collection: CompanyUserSurveyExtraCollection,
        };
    }

    modelRelations() {
        return {
            companySurveyExtra: () => this.belongsTo(CompanySurveyExtra, 'company_survey_extra_id', 'id'),
        };
    }
}
