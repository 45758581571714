<template>
    <div class=" bg-white px-4 py-3 border rounded">
        <div
            class="d-flex justify-content-between"
            :class="hasPerimeters || hasEmployees ? 'border-bottom  pb-15' : ''"
        >
            <b><t>Role</t></b>
            <span v-text="name" />
        </div>
        <div
            v-if="hasPerimeters"
            class="d-flex justify-content-between pt-15"
            :class="hasEmployees ? 'border-bottom  pb-15' : ''"
        >
            <span><b><t>Perimeter by filter</t></b></span>
            <div>
                <Perimeter
                    v-for="(perimeter, key, index) in perimeters"
                    :key="key"
                    :label="perimeter.name"
                    :data="perimeter.data"
                    :class="index !== Object.keys(perimeters).length - 1 ? 'mb-3' : ''"
                />
            </div>
        </div>
        <div
            v-if="hasEmployees"
            class="d-flex justify-content-between pt-15"
        >
            <span><b><t>Perimeter by user</t></b></span>
            <UserPerimeter :data="employees" />
        </div>
    </div>
</template>

<script>
import groupBy from 'lodash.groupby';
import Perimeter from './Perimeter.vue';
import UserPerimeter from './UserPerimeter.vue';

export default {
    name: 'RoleWithPerimeter',
    components: {
        Perimeter,
        UserPerimeter,
    },
    props: {
        data: {
            required: true,
        },
    },
    computed: {
        name() {
            return this.translate(this.data.role.alias);
        },
        perimeters() {
            const perimetersByEntityId = groupBy(this.data.perimeters.models, 'company_entity_id');

            const perimeters = Object.values(perimetersByEntityId).map((data) => ({
                name: data[0].entity.name_translated,
                data,
            }));

            return perimeters;
        },
        employees() {
            return this.data.employees.models;
        },
        hasPerimeters() {
            return this.data.perimeters.models.length > 0;
        },
        hasEmployees() {
            return this.employees.length > 0;
        },
    },
};
</script>

<style lang="scss" scoped>
	.pb-15 {
        padding-bottom: 15px;
    }
    .pt-15 {
        padding-top: 15px;
    }
</style>
