<template>
    <div class="content-container">
        <SectionList
            class="content-navigator"
            :sections="sections"
            :selected-index="currentSectionIndex"
            :loading="loading"
            @on-select="currentSectionIndex = $event"
            @on-add-new-section="addNewSection"
            @on-update-orders="onUpdateOrders"
        />

        <SectionItem
            v-if="selectedSection"
            class="content-main"
            :page-id="item.id"
            :item="selectedSection"
            :loading="loading"
            @update:item="onUpdateSection"
            @on-delete="onDeleteSection"
        />
    </div>
</template>

<script>
import api from '@/store/api';
import SectionList from '@/components/settings/EnterprisePage/Form/Section/SectionList.vue';
import SectionItem from '@/components/settings/EnterprisePage/Form/Section/SectionItem.vue';

export default {
    name: 'EnterprisePageFormContent',

    components: {
        SectionItem,
        SectionList,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            loading: true,
            isSaving: false,
            sections: [],
            currentSectionIndex: 0,
            defaultLanguageKey: this.shared.session.company.company_language.key,
        };
    },

    created() {
        this.loadData();
    },

    computed: {
        selectedSection() {
            if (this.sections.length === 0) {
                return null;
            }

            return this.sections[this.currentSectionIndex];
        },
    },

    methods: {
        loadData() {
            this.loading = true;
            this.sections = [];

            api
                .settings
                .enterprisePages
                .sections
                .all(this.item.id)
                .then((res) => {
                    res.data.data.forEach((section, index) => {
                        this.$set(this.sections, index, section);
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        addNewSection(defaultConfig) {
            this.sections.push({
                translations: {
                    name: { [this.defaultLanguageKey]: '' },
                    content: { [this.defaultLanguageKey]: defaultConfig.content },
                },
                order: this.sections.length,
                type: 'text',
                parameters: {
                    active_custom_style: defaultConfig.active_custom_style,
                    custom_style: defaultConfig.custom_style,
                    is_full_page: defaultConfig.is_full_page,
                    should_show_title: false,
                },
            });

            this.currentSectionIndex = this.sections.length - 1;
        },

        onUpdateSection(newData) {
            this.$set(this.sections, this.currentSectionIndex, newData);
        },

        onUpdateOrders(sections) {
            this.loading = true;
            this.sections = [];

            api
                .settings
                .enterprisePages
                .sections
                .saveOrders(this.item.id, {
                    orders: sections.map(({ id, order }) => ({ id, order })),
                })
                .then(() => {
                    sections.forEach((section, index) => {
                        this.$set(this.sections, index, section);
                    });

                    this.loading = false;
                });
        },

        onDeleteSection() {
            this.sections.splice(this.currentSectionIndex, 1);
            this.currentSectionIndex = 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.content-container {
    display: flex;
    width: 100%;
    gap: 32px;

    .content-navigator {
        width: 20%;
        min-width: 200px;
    }

    .content-main {
        flex: 1;
    }
}
</style>
