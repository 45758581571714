<template>
    <div>
        <HtModal
            ref="modal"
            class="user-requirement-category-list-modal"
            @beforeOpen="beforeOpen"
        >
            <template #header>
                <div class="header-modal">
                    <t class="title">
                        Administrative file
                    </t>
                </div>
            </template>
            <template #default>
                <div>
                    <span class="title">
                        <t v-if="doneCategories === 0">
                            Complete my administrative file
                        </t>
                        <t
                            v-else
                            :count="doneCategories"
                            :total="totalCategories"
                        >
                            {count}/{total} file completed | {count}/{total} files completed
                        </t>
                    </span>
                    <div class="progress-bar">
                        <div
                            class="progress"
                            :style="{'width': completionPercentage + '%'}"
                        />
                    </div>
                    <div
                        v-for="category in categoryList"
                        :key="category.id"
                        :class="[{'clickable': !cannotOpen(category)}, 'col-md-12', 'list-row']"
                        @click="openEdit(category)"
                    >
                        <t
                            v-if="category.is_heyteam"
                            class="category-name"
                        >
                            {{ category.name }}
                        </t>
                        <span
                            v-else
                            class="category-name"
                        >{{ category.name }}</span>
                        <StatusTag
                            :status-slug="category.progress_status"
                            class="details"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <Button
                    v-tooltip="translate('You must complete all the documents to finalize the form. Some parts may not yet be available, preventing validation at this time')"
                    :state="buttonState"
                    class="d-flex justify-content-center"
                    :class="{'disabled': !canValidateAdministrativeFile}"
                    @click="finalizeFile()"
                >
                    <t>Send my finalized administrative file</t>
                </Button>
            </template>
        </HtModal>
        <UserRequirementCategoryEditModal
            ref="modalEdit"
            @on-save="onSave()"
        />
        <UserWrapperModal
            ref="userWrapperModal"
            size="medium"
            is-fill
        />
    </div>
</template>
<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyUserRequirementCategory from '@/models/CompanyUserRequirementCategory';
import UserWrapperModal from '@/components/miscellaneous/UserWrapperModal.vue';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import UserRequirementCategoryEditModal from '../UserRequirementCategoryEditModal.vue';
import StatusTag from './StatusTag.vue';

export default {
    name: 'UserRequirementCategoryListModal',
    components: { UserRequirementCategoryEditModal, StatusTag, UserWrapperModal },
    props: {
        ids: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            buttonState: 'idle',
        };
    },

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },

    computed: {
        categoryList() {
            return this.$store.state.dashboard.actions.requirementCategories;
        },
        /**
         * @returns {number}
         */
        totalCategories() {
            return this.categoryList.length;
        },
        /**
         * @returns {number}
         */
        doneCategories() {
            const filtered = this.categoryList.filter((category) => [
                CompanyUserRequirementCategory.STATUS_DONE,
                CompanyUserRequirementCategory.STATUS_NOT_CONCERNED,
            ].includes(category.progress_status));
            return filtered.length;
        },
        /**
         * @returns {number}
         */
        completionPercentage() {
            return ((this.doneCategories / this.totalCategories) * 100);
        },
        canValidateAdministrativeFile() {
            return this.$store.state.dashboard.actions.canValidateAdministrativeFile;
        },
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        async beforeOpen(resolve) {
            await this.$store.dispatch('dashboard/actions/fetchRequirementCategories');
            resolve();
        },
        async onSave() {
            await this.$store.dispatch('dashboard/actions/forceFetchRequirementCategories');
            this.$emit('on-save');
        },
        openEdit(category) {
            if (category.display_survey && category.userSurvey.task.status !== 'done') {
                this.$refs.userWrapperModal.open({
                    id: category.userSurvey.task.id,
                    company_user_id: category.userSurvey.task.user.id,
                    company_user_program_id: category.userSurvey.task.user_program.id,
                    resource: 'company_survey',
                    resource_id: category.userSurvey.id,
                });
                return;
            }
            if (this.cannotOpen(category)) {
                return;
            }
            this.$nextTick(() => this.$refs.modalEdit.open(
                this.$Auth.getLoggedUser().id,
                category.id,
                this.getUserTask(category),
            ));
        },
        cannotOpen(category) {
            return (category.display_survey && category.userSurvey.task.status === 'pending') || (!category.task_id && category.progress_status === 'available_soon');
        },
        async finalizeFile() {
            if (!this.canValidateAdministrativeFile) {
                return;
            }
            this.buttonState = 'loading';
            const companyUser = new CompanyUser();

            try {
                await companyUser.save({ method: 'validateAdministrativeFile' });
                this.$set(this.shared.session.companyUser, 'is_administrative_file_validated', true);
                this.$refs.modal.close();
            } finally {
                this.buttonState = 'idle';
            }
        },
        getUserTask(category) {
            if (category.progress_status === CompanyUserRequirementCategory.STATUS_NOT_CONCERNED) {
                return category.tasks
                    .find((task) => task.participants[0].company_user_id === this.shared.session.companyUser.id);
            }

            return category.tasks
                .filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING)
                .find((task) => task.participants[0].company_user_id === this.shared.session.companyUser.id);
        },
    },
};
</script>

<style lang="scss" src="./UserRequirementCategoryListModal.scss" scoped />
