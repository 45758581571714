<template>
    <div class="item">
        <div
            v-user-image="{
                firstname: user.firstname,
                lastname: user.lastname,
                image: user.image,
                size: 2
            }"
            class="item-avatar"
        />
        <div
            v-if="count > 0"
            class="item-notification branding-bg"
        >
            {{ countLabel }}
        </div>
    </div>
</template>

<script>
import CompanyChatChannel from '@/models/CompanyChatChannel';

export default {
    name: 'ChatNotificationItem',
    props: {
        companyChatChannel: {
            type: CompanyChatChannel,
            required: true,
        },
    },
    computed: {
        user() {
            return this.companyChatChannel.company_chat_channel_user.channelUserInterlocutor.company_user;
        },
        count() {
            return this.companyChatChannel.company_chat_channel_user.channelUserMe.count;
        },
        countLabel() {
            return this.count < 10 ? this.count : '+9';
        },
    },
};
</script>

<style lang="scss" scoped src="./ChatNotificationItem.scss" />
