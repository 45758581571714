<template>
    <div>
        <div
            v-if="!isGlobalView && occurrences.length > 1"
            class="occurrences-selection"
        >
            <HtChip
                :class="{selected: resultIndex === 'global'}"
                :label="translate('Global')"
                clickable
                @click.native="resultIndex = 'global'"
            />

            <HtChip
                v-for="(occurrence, index) in occurrences"
                :key="occurrence"
                :class="{selected: resultIndex.toString() === occurrence.toString()}"
                :label="`Session ${index + 1}`"
                clickable
                @click.native="onChangeOccurrence(occurrence)"
            />
        </div>

        <IconLoading v-if="loading" />
        <SurveyListResultItem
            v-else
            v-for="result in allResults"
            :key="`${resultIndex}_${result.question.id}`"
            :question-results="result"
            :is-global-view="isGlobalView"
            :is-global-user-view="isGlobalUserView"
            :occurrences="occurrences"
        />
    </div>
</template>

<script>
import api from '@/store/api';
import IconLoading from '@/components/icons/IconLoading.vue';
import HtChip from '@/components/globals/HtChip.vue';
import SurveyListResultItem from './SurveyListResultItem.vue';

export default {
    name: 'SurveyListResult',

    components: {
        IconLoading,
        HtChip,
        SurveyListResultItem,
    },

    props: {
        isUserView: {
            type: Boolean,
            default: false,
        },
        results: {
            type: Array,
            required: true,
        },
        occurrences: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            loading: false,
            resultIndex: 'global',
            occurrencesResults: {},
        };
    },

    methods: {
        onChangeOccurrence(occurrenceId) {
            if (!this.occurrencesResults[occurrenceId]) {
                this.loading = true;

                api
                    .surveyResults
                    .userSurveyResults(occurrenceId)
                    .then((res) => {
                        this.occurrencesResults[occurrenceId] = res.data.questions_results;
                        this.resultIndex = occurrenceId;
                    })
                    .finally(() => this.loading = false);
            } else {
                this.resultIndex = occurrenceId;
            }
        },
    },

    computed: {
        isGlobalView() {
            return !this.isUserView;
        },
        isGlobalUserView() {
            return this.resultIndex === 'global' && this.isUserView;
        },
        allResults() {
            if (this.isGlobalView || this.isGlobalUserView) {
                return this.results;
            }

            return this.occurrencesResults[this.resultIndex];
        },
    },
};
</script>

<style lang="scss" scoped>
.occurrences-selection {
    display: flex;
    flex-wrap: wrap;
}
</style>
