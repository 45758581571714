<template>
    <PageStepperLayout>
        <RequirementForm
            :id="id"
            :program-id="programId"
            @on-close-stepper="$router.go(-1)"
            @on-load="setPageTitle"
        />
    </PageStepperLayout>
</template>

<script>
import PageStepperLayout from '@/layouts/PageStepperLayout.vue';
import RequirementForm from '@/components/pages/resources/requirements/form/RequirementForm.vue';

export default {
    components: {
        PageStepperLayout,
        RequirementForm,
    },

    props: {
        id: {
            type: Number,
            default: null,
        },
        programId: {
            type: Number,
            default: null,
        },
    },

    methods: {
        setPageTitle(title) {
            this.setPage({
                title,
                subtitles: [this.translate('Resources'), this.translate('Requirements')],
            });
        },
    },
};
</script>
