<template>
    <div class="enterprise-pages">
        <div class="enterprise-pages-header">
            <div
                v-for="(page, index) in pages"
                :key="index"
                class="enterprise-pages-item"
                :class="{'active': index === selectedPageIndex }"
                @click="selectedPageIndex = index"
            >
                {{ page.translations.name[defaultLanguageKey] }}
            </div>
        </div>

        <div
            v-if="selectedPage"
            class="enterprise-pages-content"
        >
            <EnterprisePageSection
                v-for="section in selectedPage.sections"
                :key="`section-${selectedPage.id}-${section.id}`"
                :page-id="selectedPage.id"
                :section="section"
            />
        </div>
    </div>
</template>

<script>
import api from '@/store/api';
import EnterprisePageSection from '@/pages/EnterprisePage/EnterprisePageSection.vue';

export default {
    name: 'CompanyEnterprisePage',

    components: {
        EnterprisePageSection,
    },

    data() {
        return {
            pages: [],
            selectedPageIndex: 0,
            defaultLanguageKey: this.shared.session.companyUser.company_language.key,
        };
    },

    computed: {
        selectedPage() {
            if (this.pages.length === 0) {
                return null;
            }

            return this.pages[this.selectedPageIndex];
        },
    },

    created() {
        api
            .enterprisePages
            .all()
            .then((res) => {
                this.pages = res.data.data;
            });
    },
};
</script>

<style lang="scss" scoped>
.enterprise-pages {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-header {
        display: flex;
        gap: 8px;
        overflow-x: auto;

        .enterprise-pages-item {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #E1E1E1;
            border-radius: 8px;
            padding: 8px;
            cursor: pointer;
            min-width: fit-content;

            &.active, &:hover {
                border-color: var(--branding-color);
            }
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
</style>
