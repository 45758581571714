<template>
    <div
        class="task-item"
        :class="{ dependent: task.dependent !== null }"
    >
        <div class="item-wrapper">
            <div
                v-if="task.dependent"
                class="d-flex justify-content-center align-items-center depend-icon"
            >
                <FontAwesomeIcon
                    class="branding-color"
                    size="lg"
                    :icon="['fal', 'level-up']"
                />
            </div>
            <div
                style="flex: 1;"
                class="task-list d-flex justify-content-between align-items-center flex-grow-1"
                :class="{'task-list-little-padding': isWithLittlePadding}"
                @click="openModal"
            >
                <div class="task-list-item-left d-flex align-items-center justify-content-start">
                    <div
                        v-if="isWrapper"
                        class="mr-20"
                    >
                        <FontAwesomeIcon
                            size="1x"
                            :icon="getChevronDirection"
                        />
                    </div>
                    <div class="d-flex flex-column">
                        <div class="d-flex justify-content-start align-items-center">
                            <div v-if="isWithIcon">
                                <FontAwesomeIcon
                                    :icon="icons[task.resource]"
                                    :style="{color: timelineColor}"
                                    q
                                />
                            </div>
                            <div
                                class="task-list-title"
                                :class="{'ml-10': isWithIcon}"
                            >
                                <div class="name">
                                    {{ task.name }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="useKeyDate"
                            class="task-list-subtitle mt-2"
                        >
                            <div v-if="hasAvailabilityDate">
                                <t>Availability:</t>
                                <span class="ml-2">{{ availabilityDate }}</span>
                            </div>
                            <div v-if="hasCompletionDate">
                                <t>Completion:</t>
                                <span class="ml-2">{{ completionDate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="getFilters.length > 0"
                    class="task-list-item-center"
                >
                    <HtBubbles
                        :items="getFilters"
                        :limit="3"
                    />
                </div>
                <div
                    class="task-list-item-right row align-items-center justify-content-end mr-10"
                >
                    <div class="col-md-auto">
                        <ParticipantList
                            :users="task.participants"
                            :limit="3"
                        />
                    </div>
                    <div class="col-md-auto">
                        <FontAwesomeIcon :icon="['fas', 'chevron-right']" />
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="task.dependencies && task.dependencies.length > 0"
            class="children-wrapper"
        >
            <TaskSimulation
                v-for="(child, index) in task.dependencies"
                :key="index"
                :timeline-color="timelineColor"
                :task="child"
                :key-date="keyDate"
                :use-key-date="useKeyDate"
                :can-edit="canEdit"
                @onSave="$emit('onSave')"
                @onDelete="$emit('onDelete')"
            />
        </div>
        <WrapperModal
            ref="wrapperModal"
            @onSave="$emit('onSave')"
            @onDelete="$emit('onDelete')"
        />
    </div>
</template>

<script>
import WrapperModal from '@/components/miscellaneous/WrapperModal.vue';
import HtBubbles from '@/components/globals/HtBubbles.vue';
import ParticipantList from '@/components/globals/ParticipantList.vue';
import modalMixin from '@/components/globals/modals/modalMixin';
import get from 'lodash.get';

export default {
    name: 'TaskItem',

    components: {
        ParticipantList,
        HtBubbles,
        WrapperModal,
        TaskSimulation: () => import('@/components/resources/task/TaskSimulation.vue'),
    },

    mixins: [
        modalMixin,
    ],

    props: {
        task: {
            type: Object,
            required: true,
        },
        timelineColor: {
            type: String,
            required: true,
        },
        isWrap: {
            type: Boolean,
            default: false,
        },
        isWrapper: {
            type: Boolean,
            default: false,
        },
        keyDate: {
            type: String,
            default: null,
        },
        useKeyDate: {
            type: Boolean,
            required: true,
        },
        isWithIcon: {
            type: Boolean,
            default: true,
        },
        isWithLittlePadding: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: () => true,
        },
    },

    data() {
        return {
            isToggled: false,
            icons: {
                company_planning: ['fad', 'calendar-alt'],
                company_software: ['fad', 'browser'],
                company_equipment: ['fad', 'phone-laptop'],
                company_shared_document: ['fad', 'file-alt'],
                company_document: ['fad', 'graduation-cap'],
                company_requirement_category: ['fad', 'user-circle'],
                company_email_custom_template: ['fad', 'envelope'],
                company_survey: ['fad', 'poll'],
                company_nudge: ['fad', 'smile-wink'],
                company_quiz: ['fad', 'question-square'],
                company_program_task: ['fad', 'tasks'],
            },
        };
    },

    computed: {
        hasDepedent() {
            return this.task.dependent !== null && this.task.dependent !== undefined;
        },
        getFilters() {
            return this.task.filters.map((filter) => {
                if (filter.entity && filter.value) {
                    const relationName = filter.entity.is_heyteam ? filter.entity.slug.replace('company_', '') : 'custom';
                    if (filter.value[relationName]) {
                        const isHeyteam = get(filter.value[relationName], 'is_heyteam', false);
                        return (isHeyteam === true)
                            ? this.translate(filter.value[relationName].name)
                            : filter.value[relationName].name;
                    }
                }
                if (filter.value) {
                    return (filter.value.item.is_heyteam === true)
                        ? this.translate(filter.value.item.name)
                        : filter.value.item.name;
                }
            });
        },

        getChevronDirection() {
            return this.isToggled === true ? ['fal', 'chevron-down'] : ['fal', 'chevron-right'];
        },

        hasCompletionDate() {
            return Boolean(this.task.completion_date);
        },

        completionDate() {
            return (new Date(this.task.completion_date)).toLocaleDateString(undefined, {
                month: 'short', day: '2-digit',
            });
        },

        hasAvailabilityDate() {
            return Boolean(this.task.availability_date);
        },

        availabilityDate() {
            return (new Date(this.task.availability_date)).toLocaleDateString(undefined, {
                month: 'short', day: '2-digit',
            });
        },
    },

    methods: {
        openModal() {
            const permissions = {
                company_shared_document: 'ModelCompanySharedDocument',
                company_software: 'ModelCompanySoftware',
                company_equipment: 'ModelCompanyEquipment',
                company_document: 'ModelCompanyDocument',
                company_planning: 'ModelCompanyPlanningEventTemplate',
                company_email_custom_template: 'ModelCompanyEmailCustomTemplate',
                company_quiz: 'ModelCompanyQuiz',
                company_nudge: 'ModelCompanyNudge',
                company_survey: 'ModelCompanySurvey',
                company_requirement_category: 'ModelCompanyRequirementCategory',
                company_program_task: 'ModelCompanyProgramTask',
            };

            if (this.isWrapper) {
                this.isToggled = !this.isToggled;
                this.$emit('onToggle', this.isToggled);
            } else if (this.$canUpdate(
                permissions[this.task.resource],
                { is_locked: this.task.is_locked },
            )) {
                if (this.canEdit) {
                    this.$refs.wrapperModal.open(this.task.resource, this.task.resource_id);
                }
            } else if ([
                'company_program_task',
                'company_document',
                'company_nudge',
                'company_shared_document',
                'company_survey',
                'company_quiz',
                'company_equipment',
                'company_software',
                'company_requirement_category',
                'company_planning',
                'company_email_custom_template'].includes(this.task.resource)
            ) {
                if (this.canEdit) {
                    this.genericResources(this.task.resource, this.task.resource_id);
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped src="./TaskSimulation.scss"></style>

<style>
.item-wrapper {
    display: flex;
}

.item-wrapper {
    margin-bottom: 8px;
}
</style>
