<template>
    <form
        v-if="companyUserEquipment.isLoaded()"
        class="form-2 spaced"
        data-cy="equipement-edit-form"
    >
        <InputField
            v-model="companyUserEquipment.specific_information"
            :label="translate('Specific information for {firstname}', { firstname: companyUserEquipment.company_user.firstname })"
            type="textarea"
            name="info"
            cypress="info"
        />

        <HtUserProgramList
            v-model="companyUserEquipment.company_user_program_id"
            :company-user-id="companyUserId"
            :disabled="true"
        />

        <HtKeyDatesInputOrder
            v-if="userTask"
            :end-label="translate('To deliver')"
            :user-program-id="companyUserEquipment.company_user_program_id"
            :is-business-day.sync="userTask.is_business_day"
            :offset-key-date.sync="userTask.company_user_program_key_date_id"
            :offset-availability-specific-date.sync="userTask.datetime_availability"
            :offset-availability-number.sync="userTask.offset_availability_number"
            :offset-availability-unit.sync="userTask.offset_availability_unit"
            :offset-end-specific-date.sync="userTask.datetime_end"
            :offset-end-number.sync="userTask.offset_end_number"
            :offset-end-unit.sync="userTask.offset_end_unit"
            can-select-specific-date
        />

        <div class="modal-actions right">
            <Button
                class="negative"
                cypress="equipment-delete-button"
                @click="onRemove()"
            >
                <t>Remove</t>
            </Button>
            <Button
                :state="buttonState"
                cypress="equipment-edit-button"
                @click="onUpdate()"
            >
                <t>Save</t>
            </Button>
        </div>
    </form>
</template>

<script>
import CompanyUserEquipment from '@/models/CompanyUserEquipment';
import HtUserProgramList from '@/components/globals/HtUserProgramList.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';

export default {
    name: 'UserEquipmentEdit',
    components: {
        HtKeyDatesInputOrder,
        HtUserProgramList,
    },
    inject: ['modal'],

    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            userTask: null,
            buttonState: 'idle',

            companyUserEquipment: new CompanyUserEquipment([
                'id', 'company_user_program_id', 'company_user_id', 'specific_information',
            ]).with({
                companyEquipment: (query) => {
                    query
                        .select(['id'])
                        .withTrashed()
                        .with({
                            resource: (query) => {
                                query.select(['id', 'name']);
                            },
                        });
                },
                companyUser: (query) => {
                    query.select(['id', 'arrival_date', 'firstname', 'lastname'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['key']);
                            },
                        });
                },
                companyUserProgramTask: (query) => query.select([
                    'id',
                    'company_user_program_id',
                    'company_user_program_key_date_id',
                    'offset_availability_number',
                    'offset_availability_unit',
                    'offset_end_number',
                    'offset_end_unit',
                    'is_business_day',
                    'datetime_availability',
                    'datetime_end',
                ]).with({
                    companyUserProgram: (query) => query.select(['id', 'company_program_id']).with({
                        mainKeyDate: (query) => {
                            query.select(['id', 'starts_at']);
                        },
                        companyProgram: (query) => query.select(['id']).with({
                            keyDate: (query) => query.select(['id', 'company_program_id']).with({
                                locales: (query) => query
                                    .select(['id', 'name', 'language_key']),
                            }),
                        }),
                    }),
                }),
            }).where([
                ['id', '=', this.id],
            ]),
        };
    },

    watch: {
        'companyUserEquipment._state.isSaving': function (value) {
            this.buttonState = value ? 'loading' : 'idle';
        },
    },

    created() {
        this.companyUserEquipment.company_user_id = this.companyUserId;
        this.companyUserEquipment.get().then(() => {
            this.userTask = this.companyUserEquipment.company_user_program_task;
            this.modal.setTitle(this.companyUserEquipment.name);
        });
    },

    methods: {
        onRemove() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this equipment? Once you click on delete, you will no longer be able to access this equipment.') }).then((result) => {
                if (result) {
                    this.companyUserEquipment.delete().then(() => {
                        this.modal.close();
                        this.$emit('onDelete');
                    });
                }
            });
        },
        onUpdate() {
            this.companyUserEquipment.save().then(() => {
                this.modal.close();
                this.$emit('onUpdate');
            });
        },
    },
};
</script>
