<template>
    <div
        :data-cy="`${cypress}-accordion`"
        :class="['ht-accordion', {'ht-accordion-branding': isBranding, 'branding-border-color': isBranding && isToggled}]"
    >
        <div
            :class="['ht-accordion-header', {'active': hasCount, 'branding-bg-important': isBranding && isToggled}]"
            @click="toggle"
        >
            <div class="d-flex align-items-center">
                <div
                    v-if="count !== null"
                    :class="['ht-accordion-header-count', 'mr-4']"
                >
                    {{ count }}
                </div>
                <div>
                    <div class="ht-accordion-header-title">
                        {{ title }}
                    </div>
                    <div
                        v-if="Boolean(description)"
                        :class="['ht-accordion-header-description', {'branding-bg-important': isBranding && isToggled}]"
                    >
                        {{ description }}
                    </div>
                </div>
            </div>
            <div class="ht-accordion-header-right">
                <div v-if="hasButton">
                    <Button
                        type="clear"
                        :cypress="`${cypress}-add-button`"
                        @click.stop="$emit('buttonClick')"
                    >
                        <slot :name="'buttonText'">
                            {{ translate(buttonLabel) }}
                        </slot>
                    </Button>
                </div>
                <div
                    v-if="!hasButton"
                    class="ht-accordion-header-icon"
                >
                    <FontAwesomeIcon
                        v-show="isToggled === true"
                        size="2x"
                        :icon="['fal', 'chevron-up']"
                        transform="shrink-6"
                        :class="['icon-chevron', 'up', {'active': isToggled}]"
                    />
                    <FontAwesomeIcon
                        v-show="isToggled === false"
                        size="2x"
                        :icon="['fal', 'chevron-down']"
                        transform="shrink-6"
                        class="icon-chevron down"
                    />
                </div>
            </div>
        </div>
        <div
            v-show="isToggled"
            class="ht-accordion-wrapper"
        >
            <slot />
        </div>
    </div>
</template>
<script>

import CypressMixin from '@/mixins/CypressMixin';

export default {
    mixins: [CypressMixin],
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            default: '',
        },
        count: {
            type: Number,
            default: null,
        },
        isBranding: {
            type: Boolean,
            default: true,
        },
        hasButton: {
            type: Boolean,
            default: false,
        },
        buttonLabel: {
            type: String,
            default: 'Add',
        },
        isOpened: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isToggled: false,
        };
    },

    computed: {
        hasCount() {
            return this.count && this.count > 0;
        },
    },

    watch: {
        isOpened() {
            this.isToggled = this.isOpened;
        },
    },

    methods: {
        open() {
            this.isToggled = true;
        },

        toggle() {
            this.isToggled = !this.hasButton ? !this.isToggled : this.isToggled;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.ht-accordion {
    background: white;
    border-radius: 5px;
    position: relative;
    background: $white;
    border: 1px solid $grey-ultra-light;
    border-radius: 5px;

    &-branding {
        border: 1px solid $color-lightblue-grey;

        .ht-accordion-header {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background: $color-lightblue-grey;

            &-count {
                background: $white;
                color: $color-text;
            }
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        height: 81px;

        &.active {
            &:hover,
            &:focus {
                cursor: pointer;
            }
        }

        &-title {
            font-size: 2rem;
            font-weight: bold;
            max-width: 450px;
        }

        &-description {
            color: $color-grey;
            font-size: 1.4rem;
        }

        &-right {
            display: flex;
            align-items: center;
        }

        &-count {
            display:flex;
            justify-content:center;
            align-items:center;
            background: $color-lightblue-grey;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            font-weight: bold;
        }

        &-icon {
            cursor: pointer;
            display:flex;
            justify-content:center;
            align-items:center;
            background: $white;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: 1px solid $grey-ultra-light;

            .icon-chevron {
                font-size: 32px;
                color: $black;
                opacity: 0.8;
                transition: $transition;

                &.active {
                    opacity: 0.8;
                }

                &.down {
                    transform: translate(-2%, 5%);
                }

                &.up {
                    transform: translate(-2%, 2%);
                }
            }
        }
    }

    &-wrapper {
        padding: 20px;
    }
}
</style>
