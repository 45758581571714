<template>
    <div
        class="wrapper mt-5 p-5"
        @mouseover="showEditIcon(true)"
        @mouseleave="showEditIcon(false)"
    >
        <SingleRequirementEdit
            v-if="editMode"
            :requirement="companyRequirement"
            :current-item="companyRequirement"
            :company-requirement-category="companyRequirementCategory"
            :company-requirement-collection="companyRequirementCategory.company_requirement"
            :current-item-index="index"
            :preparer-label="preparerLabel"
            :display-survey="companyRequirementCategory.display_survey"
            :program-id="getProgramId"
            @on-cancel="cancel"
            @on-save-requirement="onSaveRequirement"
            @on-delete-requirement="onDeleteRequirement"
        />

        <SingleRequirementRead
            v-else
            class="single-requirement-detail"
            :company-requirement="companyRequirement"
            :dynamic-document-tooltip="dynamicDocumentTooltip"
            :disabled="disabled"
        />

        <div
            v-show="!disabled && showEdit"
            class="icon-container"
            @click="toggleEditMode"
        >
            <FontAwesomeIcon
                :icon="['fal', 'edit']"
                transform="shrink-9"
                class="icon-edit"
            />
        </div>
    </div>
</template>

<script>
import CompanyRequirement from '@/models/CompanyRequirement';
import CompanyRequirementCategory from '@/models/CompanyRequirementCategory';
import CompanyResourceProgramParticipant from '@/models/CompanyResourceProgramParticipant';
import CompanyRole from '@/models/CompanyRole';
import SingleRequirementRead from './singleRequirementRead/SingleRequirementRead.vue';
import SingleRequirementEdit from './singleRequirementEdit/SingleRequirementEdit.vue';

export default {
    name: 'SingleRequirement',
    components: {
        SingleRequirementRead,
        SingleRequirementEdit,
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false,
        },
        companyRequirement: {
            type: CompanyRequirement,
            required: true,
        },
        companyRequirementCategory: {
            type: CompanyRequirementCategory,
            required: true,
        },
        dynamicDocumentTooltip: {
            type: String,
            default: () => null,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        index: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            editMode: '',
            showEdit: false,
        };
    },

    computed: {
        preparerLabel() {
            return this.getParticipantRole(CompanyResourceProgramParticipant.TYPE_PREPARER)[0]?.role.alias_translated
                || this.translate(CompanyRole.ALIAS_ADMINISTRATIVE_MANAGER);
        },

        getProgramId() {
            return this.companyRequirementCategory.display_survey
                ? this.companyRequirementCategory.survey_program_id
                : this.companyRequirementCategory.resource.programs[0]?.program.id;
        },
    },

    created() {
        this.editMode = this.isEdit || this.companyRequirement.isDirty;
    },

    methods: {
        toggleEditMode() {
            this.editMode = !this.editMode;
        },
        showEditIcon(is_show) {
            if (!this.editMode) {
                this.showEdit = is_show;
            } else {
                this.showEdit = false;
            }
        },
        getParticipantRole(role) {
            if (this.companyRequirementCategory.resource.programs.length === 0) {
                return [];
            }

            return this.companyRequirementCategory.resource.programs[0].participants.filter(
                (participant) => role === participant.type,
            );
        },
        cancel(index) {
            if (index !== null) {
                this.$emit('on-cancel', index);
            }
            this.toggleEditMode();
        },
        onSaveRequirement(item, itemIndex) {
            this.$emit('on-save-requirement', item, itemIndex);
            this.toggleEditMode();
        },
        onDeleteRequirement(item, index, hardDeletedRequirementId) {
            this.$emit('on-delete-requirement', item, index, hardDeletedRequirementId);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.wrapper{
    background: $grey-blue;
    border: 1px solid $grey-ultra-light;
    border-radius: $border-radius-8;
    position: relative;
    width: 100%;
}
.collapse-button{
    color: $color-text-grey;
    cursor: pointer;
}

.icon-container {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 3em;
    height: 3em;
    background: $white;
    cursor: pointer;

    .icon-edit {
        width: 3em;
        height: 3em;
        color: $black;
        transition: $transition;
    }

}
</style>
