import Model from '@tony.caron/node-model-proxy/Model';

export default class CompanyChatbot extends Model {
    static SLUG_FULL = 'full';

    static SLUG_LIGHT = 'light';

    static SLUG_SIGN_ONLY = 'sign_only';

    static slugToName = {
        full: 'Full',
        light: 'Light',
        sign_only: 'Sign only',
    };

    modelConfig() {
        return {
            controller: 'CompanyChatbotController',
        };
    }
}
