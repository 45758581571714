<template>
    <div
        :id="idTag"
        class="enterprise-pages-section"
        :class="{'full-width': isFullPage}"
    >
        <h2 v-if="shouldShowTitle">
            {{ section.translations.name[defaultLanguageKey] }}
        </h2>
        <span v-html="section.translations.content[defaultLanguageKey]" />
    </div>
</template>

<script>
export default {
    name: 'EnterprisePageSection',

    props: {
        pageId: {
            type: Number,
            required: true,
        },

        section: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            defaultLanguageKey: this.shared.session.companyUser.company_language.key,
        };
    },

    computed: {
        idTag() {
            return `enterprise-pages-section-${this.pageId}-${this.section.id}`;
        },

        idTagStyle() {
            return `${this.idTag}-style`;
        },

        hasCustomStyleActivated() {
            return this.section?.parameters?.active_custom_style || false;
        },

        isFullPage() {
            return this.section?.parameters?.is_full_page || false;
        },

        shouldShowTitle() {
            return this.section?.parameters?.should_show_title || false;
        },

        customStyle() {
            if (!this.hasCustomStyleActivated) {
                return null;
            }

            return this.section?.parameters?.custom_style;
        },
    },

    created() {
        const { customStyle } = this;
        if (customStyle && document.getElementById(this.idTagStyle) === null) {
            const style = document.createElement('style');
            style.id = this.idTagStyle;
            style.innerHTML = this.createStyleTagContent(customStyle);
            document.head.appendChild(style);
        }
    },

    destroyed() {
        const styleElement = document.getElementById(this.idTagStyle);
        if (styleElement) {
            styleElement.remove();
        }
    },

    methods: {
        createStyleTagContent(style) {
            const importStatements = style.match(/@import[^;]+;/g) || [];
            const cssWithoutImports = style.replace(/@import[^;]+;/g, '');

            return `
                ${importStatements.join('\n')}
                #${this.idTag} {
                    ${cssWithoutImports}
                }
            `;
        },
    },
};
</script>

<style lang="scss" scoped>
.enterprise-pages-section {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    &.full-width {
        max-width: 100%;
    }
}
</style>
