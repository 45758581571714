<template>
    <WelcomeChatbotLayout v-if="allLoaded">
        <template #aside>
            <div class="aside">
                <img src="@/assets/images/logo.svg">
            </div>
        </template>
        <template #main>
            <h1>
                <t>Choose your password</t>
            </h1>
            <FlowRow
                v-if="shouldDisplayed('hello')"
                :use-heyteam-bubble="true"
            >
                <t>Hello, in order to secure your access to the holding account, create a new password.</t>
            </FlowRow>
            <FlowRow
                v-if="shouldDisplayed('login')"
                use-heyteam-bubble
            >
                <t>Your login is :</t>
                <br>
                <b>{{ shared.session.companyUser.email }}</b>
            </FlowRow>
            <FlowRow
                v-if="shouldDisplayed('inputPassword')"
                :use-heyteam-bubble="true"
            >
                <div class="mb-3">
                    <t>Choose your password</t>
                </div>
                <HtFormInput
                    :id="'password'"
                    ref="password"
                    v-model="password"
                    v-validate.disable="{
                        required: true,
                        regex: new RegExp(companySecurity.password_regex),
                    }"
                    :name="'password'"
                    :placeholder="translate('Password')"
                    :type="'password'"
                    :show-optional="false"
                />
                <HtFormInput
                    :id="'confirmedPassword'"
                    v-model="confirmedPassword"
                    v-validate.disable="'required|confirmed:password'"
                    :name="'confirmedPassword'"
                    :placeholder="translate('Confirm password')"
                    :type="'password'"
                    :data-vv-as="translate('password confirmation')"
                />
                <HtTextDescription :text="textPassword" />
            </FlowRow>
            <FlowRow
                v-if="shouldDisplayed('legalClauses')"
                :use-heyteam-bubble="true"
            >
                <div>
                    <t>I accept the</t>
                    <span>&nbsp;</span>
                    <router-link
                        target="_blank"
                        :to="{name: 'TermsAndConditions'}"
                        tag="a"
                    >
                        <t>terms & conditions</t>
                    </router-link>
                    <span>&nbsp;</span>
                    <t>and acknowledge that I've read and accept the</t>
                    <span>&nbsp;</span>
                    <router-link
                        target="_blank"
                        :to="{name: 'PrivacyPolicy', params: { lang: 'en' }}"
                        tag="a"
                    >
                        <t>confidentiality policy</t>
                    </router-link>
                    <span>&nbsp;</span>
                    <t>of Heyteam</t>
                </div>
                <div class="legal-clauses">
                    <input
                        id="legalClauses"
                        v-validate.disable="'required'"
                        name="legalClauses"
                        type="checkbox"
                        :data-vv-as="translate('legal clauses')"
                    >
                    <label for="legalClauses">
                        <t>I accept all legal clauses</t>
                    </label>
                </div>
                <div
                    v-if="errors.has('legalClauses')"
                    class="ht-form-error"
                >
                    {{ errors.first('legalClauses') }}
                </div>
            </FlowRow>
        </template>
        <template
            v-if="showFooter"
            #footer
        >
            <Button
                :state="isSaving? 'loading' : 'idle'"
                @click="onClick"
            >
                <t>Confirm</t>
            </Button>
        </template>
    </WelcomeChatbotLayout>
</template>
<script>
import FlowRow from '@/components/flow/FlowRow.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtTextDescription from '@/components/globals/HtTextDescription.vue';
import I18n from '@/modules/i18n/I18n';
import { mapGetters, mapState } from 'vuex';
import CompanySecurity from '@/models/CompanySecurity';
import WelcomeChatbotLayout from '@/components/globals/WelcomeChatbotLayout.vue';
import HtCheckbox from '@/components/globals/HtCheckbox.vue';
import CompanyUser from '@/models/CompanyUser';
import api from '@/store/api';

export default {
    name: 'CreatePassword',
    components: {
        FlowRow,
        HtFormInput,
        HtTextDescription,
        WelcomeChatbotLayout,
        HtCheckbox,
    },

    computed: {
        ...mapGetters('companies', [
            'holding',
        ]),
        ...mapState('companies', [
            'companiesLoaded',
        ]),

        /**
         * @returns {boolean}
         */
        allLoaded() {
            return this.companiesLoaded && this.companySecurity.isLoaded();
        },

        /**
         * @returns {string}
         */
        textPassword() {
            return `${I18n.translate('Password must be at least')}
                    ${I18n.translate('{count} character long | {count} characters long', { count: this.companySecurity.password_length })},
                    ${I18n.translate('{count} digit | {count} digits', { count: this.companySecurity.password_numbers })}
                    ${I18n.translate('and')}
                    ${I18n.translate('{count} special character | {count} special characters', { count: this.companySecurity.password_symbols })}`;
        },
        /**
         * Show footer when all flow rows are displayed
         * @returns {boolean}
         */
        showFooter() {
            return this.flowRows.every((flowRow) => flowRow.isDisplayed);
        },
    },

    data() {
        return {
            isSaving: false,
            password: '',
            confirmedPassword: '',
            legalClausesAccepted: false,
            companySecurity: new CompanySecurity([
                'password_regex',
                'password_numbers',
                'password_length',
                'password_symbols',
            ]),
            flowRows: [
                new FlowRowClass('hello', false),
                new FlowRowClass('login', false),
                new FlowRowClass('inputPassword', false),
                new FlowRowClass('legalClauses', false),
            ],
        };
    },

    async created() {
        if (this.shared.session.companyUser.state !== CompanyUser.STATE_PREBOARDING) {
            this.$router.replace({ name: 'HoldingDashboard' });
        }
        await this.$store.dispatch('companies/fetchCompanies');
        this.companySecurity.setEndPointPrefix('holding/lookup/');
        this.companySecurity.where([['company_id', '=', this.holding.id]]).get();

        // affichage petit à petit des lignes
        this.flowRows.forEach((row, index) => {
            setTimeout(() => {
                this.displayRow(row.name);
            }, index * 1500);
        });
    },

    methods: {
        /**
         * @param {string}
         * @returns {boolean} should display the flow row
         */
        shouldDisplayed(flowRowName) {
            const flowRow = this.flowRows.find((row) => row.name === flowRowName);
            return flowRow.isDisplayed;
        },
        /**
         * @param {string} flowRowName
         * @returns {void}
         */
        displayRow(flowRowName) {
            const flowRow = this.flowRows.find((row) => row.name === flowRowName);
            flowRow.isDisplayed = true;
        },
        /**
         * @returns {void}
         */
        async onClick() {
            this.isSaving = true;

            try {
                // validation des données
                const isValid = await this.$validator.validateAll();
                if (!isValid) {
                    return;
                }
                // send password to back
                await api.holding.createPassword(this.shared.session.companyUser.id, this.password);

                // refresh companyUser
                const refreshedUser = await (new CompanyUser(['id', 'email', 'state'])).get();
                this.shared.session.companyUser = refreshedUser;

                // redirect to "password created"
                this.$router.push({ name: 'HoldingWelcomeChatbotConfirm' });
            } finally {
                this.isSaving = false;
            }
        },
    },
};
class FlowRowClass {
    /**
     * @param {string} name
     * @param {boolean} isDisplayed
     */
    constructor(name, isDisplayed) {
        this.name = name;
        this.isDisplayed = isDisplayed;
    }
}

</script>
<style lang="scss" scoped>
.aside{
    height: 100%;
    display: flex;
    background-color: #333333;
    align-items: center;
    justify-content: center;
}
img {
    width: 5.2rem;
    height: 5.2rem;
}
h1 {
    font-size: 3.2rem;
}
.legal-clauses{
    margin-top: 1rem;
    display: flex;
    gap: 1.2rem;
}
</style>
