<template>
    <section class="mt-5">
        <HtFormGroup
            class="mb-2"
            :label="translate('Multiple adding')"
            :is-required="true"
        />
        <HtFormSwitch
            id="should_add_code_custom_field_key"
            :value="Boolean(customFieldKey)"
            :description="translate('It allows to associate a code for each value. That\'s useful to synchronize your HeyTeam data with the data located in your information system')"
            name="should_add_code_custom_field_key"
            :label="translate('Add codes')"
            cypress="should_add_code_custom_field_key"
            :show-optional="false"
            @input="(checked) => customFieldKey = checked ? 'code' : undefined"
        />
        <div class="mb-2">
            <HtLink
                :title="getFileTitle"
                type="csv"
                @click="generateExampleCsv"
            />
        </div>
        <UploadBar
            ref="uploadBar"
            v-model="companyFile"
            :media="['csv']"
            :empty-label="translate('Import a file')"
            @onUploadProgress="$emit('update-button-state', 'loading')"
            @onUploadEnd="openListingEntriesModal"
        />
        <div class="mb-5 mt-2 import-values-format">
            <p><t>Accepted file format: CSV (UTF-8 format) with columns delimited by ";"</t></p>
            <p><t>You must fill the first row by setting the title of columns and from 2nd row you must set the values</t></p>
        </div>

        <ListingEntries
            ref="listingEntriesModal"
            :should-display-key="Boolean(customFieldKey)"
            :must-keys-be-filled="Boolean(customFieldKey)"
            :on-save="importValues"
            @on-close="$refs.uploadBar.destroy()"
            @update-button-state="(buttonState) => $emit('update-button-state', buttonState)"
        />
    </section>
</template>

<script>
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import ListingEntries from '@/components/pages/settings/entities/form/ListingEntries.vue';
import api from '@/store/api';
import htTablePaginationEventBus from '@/eventBus/htTablePagination';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import { downloadFile, getExtractedCSVFileFromId } from '@/helpers/file';
import CompanyEntityValue from '@/models/CompanyEntityValue';

export default {
    components: {
        HtFormSwitch,
        ListingEntries,
        HtFormGroup,
    },
    props: {
        entityId: {
            type: Number,
            required: true,
        },
        entitiesValueEditModal: {
            type: Object,
            required: true,
        },
        relationName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            companyFile: null,
            customFieldKey: undefined,
        };
    },
    computed: {
        getFileTitle() {
            return this.customFieldKey ? `template_${this.relationName}_import_cf.csv` : `template_${this.relationName}_import.csv`;
        },

        exampleValues() {
            const departmentsWithoutCustomFieldValue = [
                ['name', 'description'],
                ['department name 1', 'department description 1'],
            ];
            const departmentsWithCustomFieldValue = [
                ['name', 'description', 'custom_field_value'],
                ['department name 1', 'department description 1', 'department-key-1'],
            ];

            const jobPositionsWithoutCustomFieldValue = [
                ['name', 'description'],
                ['job position name 1', 'job position description 1'],
            ];
            const jobPositionsWithCustomFieldValue = [
                ['name', 'description', 'custom_field_value'],
                ['job position name 1', 'job position description 1', 'job-position-key-1'],
            ];

            const officesWithoutCustomFieldValue = [
                ['name', 'address', 'zipcode', 'city', 'country', 'timezone'],
                ['office name 1', 'office address 1', 'office postal code 1', 'office city 1', 'office country 1', 'Europe/Paris'],
            ];
            const officesWithCustomFieldValue = [
                ['name', 'address', 'zipcode', 'city', 'country', 'timezone', 'custom_field_value'],
                ['office name 1', 'office address 1', 'office postal code 1', 'office city 1', 'office country 1', 'Europe/Paris', 'office-key-1'],
            ];

            const exampleValuesMapped = {
                [CompanyEntityValue.RELATION_DEPARTMENT]: {
                    with_custom_fields: departmentsWithCustomFieldValue,
                    without_custom_fields: departmentsWithoutCustomFieldValue,
                },
                [CompanyEntityValue.RELATION_JOB_POSITION]: {
                    with_custom_fields: jobPositionsWithCustomFieldValue,
                    without_custom_fields: jobPositionsWithoutCustomFieldValue,
                },
                [CompanyEntityValue.RELATION_OFFICE]: {
                    with_custom_fields: officesWithCustomFieldValue,
                    without_custom_fields: officesWithoutCustomFieldValue,
                },
            };

            return exampleValuesMapped[this.relationName];
        },
    },
    methods: {
        generateExampleCsv() {
            const rows = this.customFieldKey
                ? this.exampleValues.with_custom_fields
                : this.exampleValues.without_custom_fields;

            downloadFile(this.getFileTitle, `data:text/csv;charset=utf-8,${rows.map((row) => row.join(';')).join('\n')}`);
        },

        importValues(selectedEntries) {
            let values = [];

            for (const entry of selectedEntries) {
                const isTooLongValue = entry.length > 255;
                if (isTooLongValue) {
                    this.$Notifier('App').showError(
                        this.translate('There is a data too long on your values.'),
                    );
                    return;
                }

                values = [...values, {
                    ...entry,
                    custom_field_value: this.customFieldKey ? entry.key : undefined,
                }];
            }

            return api.configuration.entities.importBasicValues(this.entityId, {
                values,
                custom_field_key: this.customFieldKey,
            })
                .then(() => {
                    this.entitiesValueEditModal.close();
                    this.$emit('on-close');

                    htTablePaginationEventBus.$emit('refresh');
                }).catch(() => {
                    this.$Notifier('App').showError(
                        this.translate('An error occured !'),
                    );
                });
        },

        openListingEntriesModal() {
            if (this.companyFile?.detected_extension !== 'txt') {
                this.$emit('update-button-state', 'idle');
                return;
            }

            getExtractedCSVFileFromId(this.companyFile.id).then(({ file_content }) => {
                this.$emit('update-button-state', 'idle');

                let formattedEntries = [];

                for (const entry of file_content) {
                    if (this.customFieldKey && !entry.custom_field_value) {
                        this.$Notifier('App').showError(
                            this.translate('You chose addind codes but at least one row does not contain a code'),
                        );

                        return;
                    }

                    formattedEntries = [...formattedEntries, {
                        ...entry,
                        key: this.customFieldKey ? entry.custom_field_value : undefined,
                    }];
                }

                this.$refs.listingEntriesModal.open(formattedEntries);
            }).catch(() => {
                this.$Notifier('App').showError(
                    this.translate('Unable to complete this operation ! Probably, the file is badly formatted'),
                );
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.import-values-format {
  font-size: 1.3rem;
  color: $neutral-600;
}

.ht-switch {
  margin-bottom: 1.5rem;
}
</style>
