<template>
    <div>
        <HtCard
            v-if="isLoaded"
            class="mb-5"
        >
            <template #title>
                <HtCardTitleBar
                    :title="title"
                    :show-close="true"
                    :show-button="$canCreate('ModelCompanySurvey')"
                    :button-text="translate('Create')"
                    @onAdd="onCreate"
                    @search="setSearchValue"
                    @on-close="$emit('on-change-step', 'SelectResourceTypeModal')"
                />
            </template>
            <template #default>
                <SurveysList
                    :items="resourceCollection.$.models"
                    :external-search="search"
                    @onCellClicked="onCreate"
                />
            </template>
        </HtCard>
    </div>
</template>

<script>
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import HtProgramType from '@/models/HtProgramType';
import ProgramBuilderListResourceMixin from '@/mixins/ProgramBuilderListResourceMixin';
import SurveysList, { surveyConfig } from '@/resourcesList/SurveysList.vue';

export default {
    name: 'Survey',
    components: {
        HtCardTitleBar,
        SurveysList,
    },

    mixins: [
        ProgramBuilderListResourceMixin,
    ],

    permissions: ['ModelCompanySurvey'],

    data() {
        return {
            collectionName: 'CompanySurveyCollection',
            itemFields: surveyConfig.fields,
            itemRelations: surveyConfig.relations,
            resourceTitleSingular: 'survey',
            resourceTitlePlural: 'surveys',
        };
    },

    methods: {
        AddCollectionWhereHas() {
            this.resourceCollection.whereHas({
                resource: (query) => {
                    query.whereHas({
                        programs: (query) => {
                            query.where([
                                ['company_program_id', '!=', this.companyProgramId],
                            ]).whereHas({
                                program: (query) => {
                                    query.whereHas({
                                        htProgramType: (query) => {
                                            query.where([
                                                ['slug', '!=', HtProgramType.SLUG_DEFAULT],
                                            ]);
                                        },
                                    });
                                },
                            });
                        },
                    });
                },
            });
        },

        AddCollectionWhere() {},
    },
};
</script>
