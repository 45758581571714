import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserRequirement from './CompanyUserRequirement';

export default class CompanyUserRequirementCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserRequirement,
            controller: 'CompanyUserRequirementController',
        };
    }

    modelCustomAttributes() {
        return {
            company_user_id: null,
        };
    }
}
