import CompanyUserProgramTaskCollection from '../models/CompanyUserProgramTaskCollection';

export default class TaskPlugin {
    /**
     * Valide ou invalide des tâches en masse
     *
     * @param {{id: number, status: string}[] | {id: number, status: string}} tasks liste des tâches à valider
     */
    handleValidation(tasks) {
        // encapsule dans un array si ce qui est envoyé est un objet
        if (!Array.isArray(tasks)) {
            tasks = [tasks];
        }

        const collection = new CompanyUserProgramTaskCollection(['id', 'status']);

        tasks.forEach((task) => {
            const item = collection.new();
            item.id = task.id;
            item.status = task.status;
            collection.add(item);
        });

        return collection.save({ method: CompanyUserProgramTaskCollection.CUSTOM_HANDLE_VALIDATION_BULK });
    }

    /**
     * Supprime des tâches en masse
     * @param {number[]} ids Listes des ids des tâches à supprimer
     */
    deleteBulk(ids) {
        const collection = new CompanyUserProgramTaskCollection(['id', 'status']);

        ids.forEach((id) => {
            const item = collection.new();
            item.id = id;
            collection.add(item);
        });
        return collection.save({ method: CompanyUserProgramTaskCollection.CUSTOM_DELETE_BULK });
    }

    /**
     * Installation du plugin Vue
     */
    static install(Vue) {
        const taskPlugin = new TaskPlugin();
        Vue.prototype.$handleValidationTasks = function (tasks) {
            return taskPlugin.handleValidation(tasks);
        };
        Vue.prototype.$deleteTasks = function (tasks) {
            return taskPlugin.deleteBulk(tasks);
        };
    }
}
