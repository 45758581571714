<template>
    <HtLayoutModal @close="$emit('close')">
        <template #header>
            <t>Edit target</t>
        </template>

        <template #main>
            <form
                class="form-2 spaced"
                @submit.prevent
            >
                <HtFormGroup
                    :label="translate('Selected user')"
                    is-required
                >
                    <TableCellUser :user="selectedUser" />
                </HtFormGroup>

                <HtFormFlatPickr
                    v-for="(keyDate, index) in selectedKeyDates"
                    :id="`${keyDate.id}`"
                    :key="keyDate.id"
                    v-model="keyDate.starts_at"
                    v-validate.disable="'required'"
                    :name="`key-date${index}`"
                    :label="keyDate.name"
                    :placeholder="keyDate.name"
                    :show-optional="false"
                    :show-asterisk="true"
                    :data-vv-as="keyDate.name"
                    :is-working-day="false"
                    :static="false"
                    :cypress="`${'keyDate'-index}`"
                    is-with-calendar-icon
                />

                <HtFormFlatPickr
                    v-if="hasEndDate"
                    id="program-end-date"
                    v-model="selectedUser.end_date"
                    v-validate.disable="'required'"
                    name="program-end-date"
                    :label="translate('Program end date')"
                    :placeholder="translate('Program end date')"
                    :value="selectedUser.end_date"
                    :show-optional="false"
                    :show-asterisk="true"
                    :data-vv-as="translate('program end date')"
                    :is-working-day="false"
                    :static="false"
                    cypress="program-end-date"
                    is-with-calendar-icon
                />

                <div v-if="hasProbation">
                    <ProbationPeriod
                        :label="'Initial probationary period'"
                        :probation.sync="selectedUser.first_probation"
                        :probation_unit.sync="selectedUser.first_probation_unit"
                        :cypress="'first-probation-unit'"
                    />

                    <Checkable
                        v-model="hasRenewedProbation"
                        :label="translate('Renewed probationary period')"
                        :cypress="'renew-probation-period-check'"
                    />

                    <ProbationPeriod
                        v-if="hasRenewedProbation"
                        :label="'Renewed probationary period'"
                        :probation.sync="selectedUser.second_probation"
                        :probation_unit.sync="selectedUser.second_probation_unit"
                        :cypress="'second-probation-unit'"
                    />
                </div>

                <HtFormSelector
                    v-for="(entity, index) in requiredEntities"
                    :id="entity.slug"
                    :key="index"
                    v-model="selectedEntities[entity.slug]"
                    v-validate.disable="'required'"
                    :name="entity.slug"
                    :label="translate(entity.name)"
                    :placeholder="translate(entity.name)"
                    :options="valuesByEntityId[entity.id]"
                    :label-options="'name'"
                    :is-single="true"
                    :allow-empty="false"
                    track-by="company_entity_value_id"
                    :data-vv-as="translate(entity.name)"
                    :cypress="`entity-select-${entity.slug}`"
                />
            </form>
        </template>

        <template #footer>
            <HtButton
                type="secondary"
                @click.native="close"
            >
                <t>Cancel</t>
            </HtButton>

            <HtButton
                :loading="loading"
                @click.native="save"
            >
                <t>Save</t>
            </HtButton>
        </template>
    </HtLayoutModal>
</template>

<script>
import api from '@/store/api';
import HtLayoutModal from '@/components/globals/modals/layouts/HtLayoutModal.vue';
import HtButton from '@/components/globals/HtButton.vue';
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import HtFormFlatPickr from '@/components/globals/HtFormFlatPickr.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import TableCellUser from '@/components/globals/table/TableCellUser.vue';
import ProbationPeriod from '@/components/ProbationPeriod.vue';
import Checkable from '@/components/globals/Checkable.vue';

export default {
    name: 'BulkEditTargetModal',

    components: {
        Checkable,
        ProbationPeriod,
        TableCellUser,
        HtFormSelector,
        HtFormFlatPickr,
        HtFormGroup,
        HtButton,
        HtLayoutModal,
    },

    props: {
        bulk: {
            type: Object,
            required: true,
        },

        user: {
            type: Object,
            required: true,
        },

        userProgram: {
            type: Object,
            required: true,
        },

        requiredEntities: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            loading: false,
            selectedUser: { ...this.user },
            selectedUserProgram: { ...this.userProgram },
            selectedKeyDates: [],
            selectedEntities: {},
            hasRenewedProbation: !!this.user.second_probation,
        };
    },

    created() {
        this.$store.dispatch('entities/fetchEntities');

        this.selectedUserProgram
            .entities
            .forEach((userEntity) => {
                if (userEntity.value) {
                    this.$set(this.selectedEntities, userEntity.entity.slug, {
                        company_entity_id: userEntity.value.company_entity_id,
                        company_entity_value_id: userEntity.value.id,
                        name: userEntity.value.item.name,
                        value_resourceable_id: userEntity.value.resourceable_id,
                        has_end_date: userEntity.value.item.has_end_date,
                        has_probation: userEntity.value.item.has_probation,
                    });
                } else {
                    this.$set(this.selectedEntities, userEntity.entity.slug, null);
                }
            });

        this.selectedUserProgram
            .key_dates
            .forEach((keyDate, index) => {
                this.$set(this.selectedKeyDates, index, {
                    id: keyDate.id,
                    name: this.oldLocalize(keyDate.parent.template.locales, 'name'),
                    slug: keyDate.parent.template.slug,
                    starts_at: keyDate.starts_at,
                });
            });
    },

    methods: {
        close() {
            this.$emit('close');
        },

        save() {
            this.$validator
                .validateAll()
                .then((result) => {
                    if (!result) {
                        return;
                    }

                    this.loading = true;

                    api
                        .user
                        .enrolling
                        .bulk
                        .updateUserTarget(this.bulk.id, {
                            user_program_id: this.userProgram.id,
                            key_dates: this.selectedKeyDates.filter((k) => !!k.starts_at),
                            entities: this.selectedEntities,
                            user_data: this.selectedUser,
                        })
                        .then((res) => {
                            this.$emit('close', { name: 'on-update', value: res.data.data });
                        });
                });
        },
    },

    computed: {
        valuesByEntityId() {
            return this.$store.getters['entities/valuesByEntityId'];
        },

        hasEndDate() {
            return this.selectedEntities?.company_contract?.has_end_date || false;
        },

        hasProbation() {
            return this.selectedEntities?.company_contract?.has_probation || false;
        },
    },

    watch: {
        hasEndDate() {
            if (!this.hasEndDate) {
                this.selectedUser.end_date = null;
            }
        },
    },
};
</script>
