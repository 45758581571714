<template>
    <div>
        <HtFormMultiSelect
            :id="`${companyUserRequirement.id}-multiple`"
            :value="getSelectedResponse"
            class="list-answer-select"
            :placeholder="translate('Select answers')"
            :options="getOptionsResponse"
            :name="`${companyUserRequirement.id}-multiple`"
            :cypress="`${companyUserRequirement.id}-multiple`"
            @input="onSelectResponse"
        >
            <template slot="append">
                <HtButton
                    v-show="showButton"
                    class="mt-3"
                    :cypress="`save-requirement-${companyUserRequirement.id}`"
                    :disabled="loading"
                    :loading="loading"
                    @click.native="updateRequirement"
                >
                    <t>Save</t>
                </HtButton>
            </template>
        </HtFormMultiSelect>

        <div>
            <!-- Nest the error msg in a div, otherwise there might be a "duplicate kley detected" error -->
            <div
                v-for="(errorMessage, index) in errors.collect(companyUserRequirement.name)"
                :key="index"
                class="error-message"
            >
                <t>{{ errorMessage }}</t>
            </div>
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';
import UserRequirementEditMixin from './UserRequirementEditMixin';

export default {
    name: 'UserRequirementMultipleSelectEdit',

    components: {
        HtButton,
    },

    filters: {
        responses(value) {
            const items = value.filter((v) => v.selected);
            return items.length ? items.map((v) => v.value).join(', ') : '-';
        },
    },

    mixins: [UserRequirementEditMixin],

    inject: ['$validator'],

    props: {
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            answers: [],
        };
    },

    computed: {
        showButton() {
            if (!this.requirement.value) {
                return this.getSelectedResponse.length > 0;
            }
            const currentAnswers = this.getSelectedResponse;
            const previousAnswers = (typeof this.requirement.value === 'string' ? JSON.parse(this.requirement.value) : this.requirement.value).filter((value) => Boolean(value.selected)).map((value) => ({
                id: value.value,
                name: value.value,
            }));

            const sameLength = previousAnswers.length === currentAnswers.length;

            if (sameLength) {
                const mappedPrevious = previousAnswers.map((val) => val.id);
                const mappedCurrent = currentAnswers.map((val) => val.id);

                return !mappedPrevious.every((val) => mappedCurrent.includes(val));
            }

            if (this.companyUserRequirement.is_mandatory) {
                return currentAnswers.length > 0;
            }

            return true;
        },

        getSelectedResponse() {
            return this.companyUserRequirement.value.filter((value) => Boolean(value.selected)).map((value) => ({
                id: value.value,
                name: value.value,
            }));
        },

        getOptionsResponse() {
            return this.companyUserRequirement.value.map((value) => ({
                id: value.key,
                name: value.value,
            }));
        },
    },

    created() {
        if (!this.companyUserRequirement.value) {
            const questions = JSON.parse(this.companyUserRequirement.question_multiple_value);
            this.companyUserRequirement.value = questions.map((item) => ({
                key: item.key,
                value: item.value,
                selected: false,
            }));
        } else {
            this.companyUserRequirement.value = JSON.parse(this.companyUserRequirement.value);
        }
        this.answers = this.companyUserRequirement.value.filter((answer) => answer.selected === true).map((answer, index) => index);
    },

    methods: {
        onSelectResponse(newAnswers) {
            if (this.isDisabled) {
                return;
            }

            const hasChanged = newAnswers.length !== this.answers.length;

            this.companyUserRequirement.value.forEach((value) => {
                const findValue = newAnswers.find((answer) => value.value === answer.name);

                value.selected = Boolean(findValue);
            });

            this.$emit('onChangeValue', this.companyUserRequirement.id, hasChanged);
        },
    },
};
</script>

<style lang='scss' src='./UserRequirementMultipleSelectEdit.scss' scoped />
