import { api_create } from '@/services/Api';

export default class NumspotAPI {
    static instance = {};

    constructor(_options = {}) {
        const options = {
            name: '',
            base_url: '',
            ..._options,
        };

        if (!options.name.length) throw "name can't be empty";

        if (undefined === NumspotAPI.instance[options.name]) {
            if (!options.base_url.length) throw "base_url can't be empty";

            // eslint-disable-next-line new-cap
            NumspotAPI.instance[options.name] = new api_create(options);
        }

        return NumspotAPI.instance[options.name];
    }

    static getInstance(name) {
        if (undefined === NumspotAPI.instance[name]) throw `Instance ${name} doesn't exists`;

        return NumspotAPI.instance[name];
    }

    static install(Vue, options) {
        NumspotAPI.Vue = Vue;

        // Set default connection from main.js
        const _api = new NumspotAPI(options);

        Vue.mixin({

            data() {
                return {

                    NumspotAPI: {
                        get: (...arg) => this.apiCallNumspot('get', arg),
                        post: (...arg) => this.apiCallNumspot('post', arg),
                        put: (...arg) => this.apiCallNumspot('put', arg),
                        delete: (...arg) => this.apiCallNumspot('delete', arg),
                        getInstance: (...arg) => NumspotAPI.getInstance(arg),
                    },
                };
            },

            methods: {
                apiCallNumspot(fname, arg) {
                    _api[fname](arg[0], arg[1] || undefined, arg[2] || undefined, this);
                },
            },
        });
    }
}
