import GenericConfirmation from '@/components/globals/modals/GenericConfirmation.vue';
import LoadResourceComponentRead from '@/components/resources/LoadResourceComponentRead.vue';
import GenericDialog from '@/components/globals/modals/GenericDialog.vue';
import AddEntityModal from '@/components/pages/settings/entities/form/modals/AddEntityModal.vue';
import NextOccurrencePreviewModal from '@/components/globals/modals/Recurrence/NextOccurrencePreviewModal.vue';

export default {
    methods: {
        openCenterModal(Component, props = {}, events = {}, options = {}) {
            const defaultOptions = {
                adaptive: true,
                width: '90%',
                maxWidth: 600,
                height: 'auto',
            };

            const finalOptions = {
                ...defaultOptions,
                ...options,
                styles: `border-radius: 8px; box-shadow:none; ${options.styles ?? ''}`,
            };

            this.$modal.show(Component, props, finalOptions, events);
        },
        genericConfirm(
            title,
            description,
            onAccept = null,
            acceptLabel = 'Accept',
            cancelLabel = 'Cancel',
            options = {},
        ) {
            const defaultOptions = {
                reset: true,
                adaptive: true,
            };

            const finalOptions = {
                ...defaultOptions,
                ...options,
                styles: `border-radius: 8px; box-shadow: none; ${options.styles ?? ''}`,
            };

            return new Promise((resolve, reject) => {
                this.openCenterModal(
                    GenericConfirmation,
                    {
                        title, description, acceptLabel, cancelLabel,
                    },
                    {
                        'before-close': (event) => {
                            if (event.params === 'accept') {
                                onAccept?.();
                                resolve();
                            } else {
                                reject(event.params);
                            }
                        },
                    },
                    finalOptions,
                );
            });
        },
        genericResources(resourceType, resourceId) {
            this.openCenterModal(
                LoadResourceComponentRead,
                { resourceType, resourceId },
                {},
                {
                    name: 'resourceModal',
                    width: '800px',
                    styles: 'border-radius: 16px; box-shadow:none;',
                },
            );
        },
        openDialogModal(name, title, description, buttons, events = null) {
            this.openCenterModal(
                GenericDialog,
                {
                    title,
                    description,
                    buttons,
                },
                events,
                { name, width: '400px' },
            );
        },
        openEntityModal(name, entity, entityValueName, options = {}) {
            let _resolve;
            const promise = new Promise((resolve) => {
                _resolve = resolve;
            });
            this.openCenterModal(
                AddEntityModal,
                {
                    entity,
                    entityValueName,
                },
                {
                    'before-close': (event) => {
                        _resolve(event.params);
                    },
                },
                { ...options, name },
            );

            return promise;
        },
        openNextOccurrencePreviewModal(userProgramTask, title, subTitle) {
            let _resolve;
            const promise = new Promise((resolve) => {
                _resolve = resolve;
            });
            this.openCenterModal(
                NextOccurrencePreviewModal,
                {
                    userProgramTask,
                    title,
                    subTitle,
                },
                {
                    'before-close': () => {
                        _resolve();
                    },
                },
                {
                    name: 'nextOccurrencePreviewModal',
                    width: '90%',
                    maxWidth: 400,
                    adaptive: true,
                    styles: 'border-radius: 20px; box-shadow:none;',
                },
            );

            return promise;
        },
    },
};
