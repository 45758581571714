<template>
    <div class="question-history" :class="{'is-navigation': isNavigation}">
        <span v-if="answers.length > 0">{{ answers[0].text }}</span>
    </div>
</template>

<script>
import SurveyQuestionHistoryMixin from '@/components/resources/user/surveys/questions/SurveyQuestionHistoryMixin';

export default {
    name: 'SurveyTextHistory',

    mixins: [
        SurveyQuestionHistoryMixin,
    ],
};
</script>

<style lang="scss" scoped>
@import '../SurveyQuestion';

.question-history {
    display: block;

    &.is-navigation {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
