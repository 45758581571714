import { removeCsrfToken } from '@/helpers/csrf';
import Utils from '../Utils';

export default class Auth {
    static ROLE = {
        anonymous: 'anonymous',
        basic: 'basic',
        admin: 'admin',
        superadmin: 'superadmin',
    };

    static CONTEXT = {
        owner: 'owner',
        manager: 'manager',
        buddy: 'buddy',
        onboarding_participant: 'onboarding_participant',
        program_team: 'program_team',
        company_software: 'company_software',
        company_user_software: 'company_software',
        company_user_equipment: 'company_equipment',
        company_planning: 'company_planning',
        company_user_planning: 'company_planning',
        company_email_custom: 'company_email_custom',
        company_email_template: 'company_email_template',
        company_doc: 'company_doc',
        company_user_doc: 'company_user_doc',
        company_document: 'company_document',
        company_user_document: 'company_document',
        company_user_requirement: 'company_requirement_category',
        company_user_survey_watcher: 'company_survey_watcher',
        company_survey: 'company_survey',
        company_user_survey: 'company_user_survey',
        company_nudge: 'company_nudge',
        company_user_nudge: 'company_user_nudge',
        company_quiz: 'company_quiz',
        company_user_quiz: 'company_user_quiz',
    };

    // Cached context permissions
    static context_permission = {};

    static company_user = { role: Auth.ROLE.anonymous, logged: false }; /* static company_user = {role:Auth.ROLE.basic, logged:false}; */

    static company = {};

    static logUser(company_user) {
        Auth.company_user = company_user;
        Auth.company_user.logged = true;
    }

    static setAutoConnectAdmin() {
        window.localStorage.setItem('isAutoConnectAdmin', 'true');
    }

    static isAutoConnectAdmin() {
        return window.localStorage.getItem('isAutoConnectAdmin') === 'true';
    }

    static logOut() {
        Auth.company_user = { role: Auth.ROLE.anonymous, logged: false };

        if (window.localStorage.getItem('isAutoConnectAdmin') !== null) {
            window.localStorage.removeItem('isAutoConnectAdmin');
        }

        removeCsrfToken();
    }

    static getLoggedUser() {
        return Auth.company_user;
    }

    static getAuthImage() {
        return Utils.resolveS3PublicImage(Auth.company_user.image);
    }

    static isLogged() {
        return Auth.company_user.logged === true;
    }

    static getRole() {
        return Auth.company_user.role;
    }

    static install(Vue) {
        Vue.prototype.$Auth = Auth;
    }
}
