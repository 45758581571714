<template v-if="preLoad || innerPreLoad">
    <transition name="modal-fade">
        <div
            v-show="visible"
            ref="modal"
            @mousedown.self.stop="close({reason:'manual_out'}, $event)"
        >
            <transition name="modal-slide">
                <div
                    v-show="visible"
                    ref="overlay"
                    class="overlay"
                >
                    <div class="modal">
                        <div class="modal-container">
                            <header
                                class="header-modal"
                                :class="{custom: $slots.header}"
                            >
                                <div
                                    v-if="$slots.header"
                                    class="custom-header"
                                >
                                    <slot name="header" />
                                </div>
                                <div
                                    v-if="!$slots.header"
                                    v-show="default_config.title"
                                    class="title"
                                    :title="default_config.title"
                                >
                                    {{ default_config.title }}
                                </div>
                                <HtIconClose
                                    v-if="!$slots.header"
                                    class="close-modal"
                                    data-cy="close-modal"
                                    @click="close({reason:'manual'})"
                                />
                            </header>
                            <div class="modal-content">
                                <slot v-if="visible || mode == 1" />
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>
<script>

import Modalable from '@/Modalable';
import HtIconClose from '@/components/icons/HtIconClose.vue';

export default {
    name: 'Modalable',
    shared: {
        modalable: {
        },
    },

    components: {
        HtIconClose,
    },

    provide() {
        return {
            modal: this,
        };
    },

    props: {

        // Let vue load the dom content of the modal & executed eventualy the mount in the component attached
        preLoad: { default: true, type: Boolean },
        mode: { default: 1, type: Number },
        options: { type: Object, default: () => {} },
        elementDeleteLabel: { type: String, default: 'element' },
    },

    data() {
        return {
            id: 0,
            visible: false,
            innerPreLoad: false,
            config: {},
            default_config: {
                title: null,
            },
            clickProtectEl: null,
            mouseX: 0,
            mouseY: 0,
            onClose: () => {},
        };
    },

    watch: {
        options() {
            this.default_config = { ...this.default_config, ...this.options };
        },
    },

    created() {
        this.innerPreLoad = this.preLoad;
        this.default_config = { ...this.default_config, ...this.options };
    },

    beforeDestroy() {
        this.close();
    },

    methods: {

        async deleteWindow(data) {
            return Modalable.openRegisteredWindow('Deletewindow', data, this.elementDeleteLabel);
        },

        open(config) {
            return new Promise((resolve) => {
                this.innerPreLoad = true;

                if (this.visible) this.close();

                document.getElementById('app').appendChild(this.$el);

                this.config = { ...this.default_config, ...config };
                this.addEventListener();

                this.visible = true;

                this.id = this.$Utils.uniqId();
                this.shared.modalable[this.id] = this;

                Modalable.register(this, this.id);

                this.$refs.overlay.scrollTop = 0;

                this.$nextTick(() => {
                    this.$refs.overlay.scrollTop = 0;
                    resolve();
                });
            });
        },
        close(data) {
            data = undefined === data ? {} : data;
            data.reason = undefined === data.reason ? 'unknow' : data.reason;

            if (this.visible) {
                if (data.reason == 'manual_out' && this.$slots.default[0] && this.$slots.default[0].componentInstance && this.$slots.default[0].componentInstance.modalable_var && this.$slots.default[0].componentInstance.modalable_var.contentChanged) {
                    // alert(this.id+' ('+data.reason+') Stop closing + open prompt')
                    this.$refs.modalableClose.open();
                    return;
                }

                this.attachClickProtectionOnClose();

                // alert('close ('+data.reason+') : '+this.id)
                delete this.shared.modalable[this.id];
                Modalable.unRegister(this.id);
                this.removeEventListener();
                this.visible = false;
                this.onClose();
                this.$emit('onClose', data);

                // element.parentNode.removeChild(element);

                setTimeout(() => {
                    if (this.clickProtectEl) this.$el.removeChild(this.clickProtectEl);
                    document.getElementById('app').removeChild(this.$el);
                }, 150);
            }
        },

        setTitle(title) {
            this.default_config.title = title;
        },

        scrollToEnd() {
            const { scrollHeight } = this.$refs.overlay;

            setTimeout(() => {
                this.$refs.overlay.scrollTop = scrollHeight;
            }, 100);
        },

        attachClickProtectionOnClose() {
            this.clickProtectEl = document.createElement('DIV');
            this.clickProtectEl.style.position = 'absolute';
            this.clickProtectEl.style.width = '20px';
            this.clickProtectEl.style.height = '20px';
            this.clickProtectEl.style.top = `${this.mouseY - 10}px`;
            this.clickProtectEl.style.left = `${this.mouseX - 10}px`;
            this.$el.appendChild(this.clickProtectEl);
        },

        addEventListener() {
            document.addEventListener('keydown', this.handleKeyPress);
            document.addEventListener('mousemove', this.handleMouseMove);
        },

        removeEventListener() {
            document.removeEventListener('keydown', this.handleKeyPress);
            document.removeEventListener('mousemove', this.handleMouseMove);
        },

        handleMouseMove(e) {
            this.mouseX = e.clientX;
            this.mouseY = e.clientY;
            if (!this.clickProtectEl) return;
            this.clickProtectEl.style.top = `${e.clientY - 10}px`;
            this.clickProtectEl.style.left = `${e.clientX - 10}px`;

            return false;
        },

        handleKeyPress(e) {
            // Code that allow to only close the last window opened
            if (e.keyCode == 27) {
                let i = 0;
                let iFindedIndex = -1;

                for (const n in this.shared.modalable) {
                    if (Object.prototype.hasOwnProperty.call(this.shared.modalable, n)) {
                        i++;
                        if (n == this.id) {
                            iFindedIndex = i;
                        }
                    }
                }

                if (iFindedIndex == i) {
                    this.close({ reason: 'manual_out' });
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	.modal {
		position: relative;
		min-height: 100%;
		.button{
			width: auto;
			float: right;
		}
	}

	.modalable-1 {
		display: flex;
		justify-content: flex-end;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 999;
		background: rgba(0, 0, 0, 0.3);
		.overlay{
			position: relative;
			background: white;
			top: 0;
			width: 90%;
			max-width: 1550px;
			justify-content: center;
			box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.1);
			//transition: $transition;
			overflow-y: auto;
			.content-info{
				.bottom-navbar{
					left: 340px;
				}
			}
			.bottom-navbar{
				// left: 10%;
				right: 0;
				max-width: 1550px;
				left: auto;
				width: 90%;
			}
		}
		&.bg-grey{
			.modal{
				background: $bg-grey!important;
				min-height: 100%;
			}
			&.full{
				.overlay{
					background: none;
				}
				.modal{
					background: none!important;
				}
			}
		}
		&.no-padding{
			.modal{
				padding: 0!important;
			}
			.modal:not(.modalable-1):not(.modalable-4){
				.header-modal{
					margin-bottom: 0;
				}
			}
		}
        &.full-height-content  .modal .modal-container {
            display: flex;
            flex-direction: column;

            .modal-content {
                flex-grow: 1;
                margin-bottom: 0;
            }
        }
		.modal:not(.modalable-1,.modalable-4, .modalable-3){
			display: flex;
			flex-direction: column;
			position: relative;
			width: 100%;
			padding: 30px 15%;
			border-radius: 0.3rem;
			background-color: white;
			h3{
				font-size: 6rem;
				margin-bottom: 70px;
				&.small{
					font-size: 2.2rem;
					margin-bottom: 10px;
				}
			}
			.header-modal{
				display: flex;

				.close-modal{
					position: absolute;
					right: 30px;
					padding: 0;
					z-index: 2;
				}
			}
			.modal-content{
				display: flex;
				flex-direction: column;
			}
		}
		// SMALL VERSION
		&.small {
			overflow-y: auto;
			overflow-x: hidden;
			.overlay {
				width: 450px;
				max-width: 75%;
				.modal-content {
					 h3{
						font-size: 3rem;
						margin-top: -52px;
						color:blue;
					}
				}
			}
		}
		&.medium > .overlay{
			width: 600px;
			max-width: 75%;
			.modal{
				h3{
					font-size: 3rem;
					color:green;
				}
			}
		}
		&.big > .overlay{
			width: 1000px;
			max-width: 75%;
			.modal{
				h3{
					font-size: 3rem;
				}
			}
		}
		&.large {
			>.overlay {
				width: 100%;
				max-width: 100%;
				max-width: -webkit-fill-available;
			}
		}
	}

	.responsible-info{
		text-align: center;
		h2 {
			margin: 0 0 1em;
		}
		p{
			margin: 15px 0;
		}
	}

	/////// CENTERED
	.modal.modalable-3{
		display: flex;
		justify-content: center;
		align-items: flex-end;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1000;
        background:rgba(216, 214, 214, 0.54);

		.modal{
			align-items: center;
			display: flex;
			flex-direction: column;
			position: relative;
			width: 100%;
			background-color: white;
			border-radius: 0.9rem;
        }

		.overlay{
			position: relative;
			background: $white;
			box-shadow: none;
			border-radius: 0.9rem;
			width: 550px;
			max-width: 60rem;
			justify-content: center;
			box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.1);
			transition: $transition;
			align-self: center;
			display:flex;
			overflow-y: auto;
			top: 0;
        }

		.modal-content{
			display: flex;
			flex-direction: column;
			max-width: 715px;
            min-height: unset !important;
            max-height: 630px;
            padding: 0;
            margin-top: 80px;
			text-align: center;
			position: relative;
        }

		.header-modal{
			position: absolute;
		    top: 0px;
			width: 100%;
			background: $white;
			display: flex;
            height: 80px;
			color: white;
			align-items: center;

			.title{
                color: $color-text;
				font-size: 2.3rem;
				font-weight: bolder;
                text-align: center;

				// To ellipse the header title
				text-overflow: ellipsis;
				white-space: nowrap;
                overflow: hidden;
                width:100%;
			}

			.close-modal{
				right: -52px;
				position: absolute;
				padding: 0;
				z-index: 2;
			}
		}
	}

	/////// STICK TO BOTTOM
	.modal.modalable-4{
		display: flex;
		justify-content: center;
		align-items: flex-end;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1000;
		//color: #fff;
		background:rgba(216, 214, 214, 0.54);
		.modal{
			padding: 50px 40px 50px;
			display: flex;
			flex-direction: column;
			position: relative;
			width: 100%;
			border-radius: 0.3rem;
			background-color: white;
		}
		.overlay{
			position: relative;
			background: $primary;
			box-shadow: none;
			border-radius: 0.9rem;
			width: 100%;
			max-width: 60rem;
			justify-content: center;
			box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.1);
			transition: $transition;
			align-self: center;
			display:flex;
			overflow-y: auto;
			top: 0;

		}
		.modal-content{
			display: flex;
			flex-direction: column;
			max-width: 715px;
			padding: 0;
			text-align: center;
			position: relative;

			.modal-title{
				font-family: $lato-bold;
				font-size: 2.2rem;
				text-transform: uppercase;
				color: $text;
				margin: 0 0 38px;
				color: black;
				margin-bottom: 1rem;
				font-weight: bold;
			}
		}
		.header-modal{
			position: absolute;
		    top: -70px;
			width: 100%;
			background: white;
			display: flex;
			height: 80px;
			margin-bottom: 2em;
			color: white;
			align-items: center;

			.title{
				font-size: 2.3rem;
				margin-left: 1em;
				margin-right: 3em;
				font-weight: bolder;

				// To ellipse the header title
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				width: calc(100% - 30px);
			}

			.close-modal{
                right: -20px;
                margin-top: 20px;
				position: absolute;
				z-index: 2;
			}
		}

		.row-line{
			margin-top: 50px;

		}
	}

	.modal-fade-enter-active, .modal-fade-leave-active {
		transition: all .3s;
		transition-timing-function: cubic-bezier(0.04, 1.15, 0.98, 0.98);
	}
	.modal-fade-enter, .modal-fade-leave-to {
		opacity: 0;
	}

	.modal-slide-enter-active, .modal-slide-leave-active {
		transition: all 0.3s;
		transition-timing-function: cubic-bezier(0.04, 1.15, 0.98, 0.98);
		transform: translate3d(0, 0, 0);
	}
	.modal-slide-enter, .modal-slide-leave-to {
		opacity: 1;
		transform: translate3d(100%, 0, 0);
	}

	.invite-new{
		margin: 50px 0 0 0;
	}

	@media (max-width: $desktop-small){
		.modalable-1{
			.overlay{
				.content-info{
					.bottom-navbar{
						left: 250px;
					}
				}
			}
		}
	}
	@media (max-width: $tablet){
		.modalable-1 {
			height: 100%;

			.modal-container {
				width: 100%;
			}
			.overlay{
				max-width: 100% !important;
				width: 100% !important;
				.bottom-navbar{
					width: 100%;
				}
			}
			.modal {
				// padding: 0 25px !important;
				&.modalable-4{
					padding: 0;
				}
			}
		}
		.modalable-1{
			&.small > .overlay{
				width: 100%;
				max-width: 100%;
				.modal{
					padding: 0 25px;
				}
			}
			.overlay{
				.content-info{
					.bottom-navbar{
						left: 0;
					}
				}
			}
		}
	}

	.modal-container {
		position: relative;
	}
</style>

<style lang="scss">
	@import '~@/styles/var';

	.modalable-4 {
		.modal {
			.header-modal {
				border-bottom: none !important;
			}
			.modal-content {
				margin-bottom: 0 !important;
			}
		}
	}

    .modalable-4 .modal .modal-container {
		min-height: unset !important;
		max-height: 600px;
    }

	.modalable-1 {
		h3 {
			margin-top: -82px;
			margin-bottom: 2em;
			font-size: 2.3rem;
			font-weight: bolder;
			color:white;
			font-family: $lato;
		}

		.modal {
			padding: 0 !important;
			.modal-container {
				min-height: 100vh;

				.modal-content {
					padding: 0 3.2rem;
                    margin-bottom: 75px;
				}
			}

			.header-modal {
				border-bottom: 1px solid $border-grey-light;
				margin-bottom: 2em;
				background: $primary-greyter;
				color:white;

				align-items: center;

                &:not(.custom) {
                    height: 80px;
                }

				.title{
					font-size: 2.3rem;
					margin-left: 1em;
					margin-right: 3em;
					font-weight: bolder;

					// To ellipse the header title
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					width: calc(100% - 30px);
				}
			}
		}

		.modal-box {

			margin-bottom: 70px;

			h2 {
				margin-top: 0;
			}
		}

		.modal-actions {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			display: flex;
			padding: 0.5em 0;
			justify-content: center;
			align-items: center;
			background: white;

			padding-right: 3rem;
    		margin-bottom: 3rem;

			&.right{
				justify-content: flex-end;
			}

			.button {
				margin-left: 0.5em;
			}
		}

    }

	.modalable-1.small {
		.modal-actions:first-of-type {
			flex-direction: column-reverse;

			.button {
				margin-top: 0.5em;
				width: 75%;
			}
		}
	}

	// FULL SCREEN MODAL STYLING
	.modalable-1.medium, .modalable-1.big, .modalable-1.small {
		.modal {
			.header-modal {
				background-color: $white !important;

				.title {
					font-size: 1.5em;
					color: $black;
					// text-align: center;
				}
			}
			.modal-content {
				overflow:visible; // Changed from hidden to visible because it cropped tooltips.

				.large-modal-content {
					display: flex;
					flex-wrap: wrap;
					// justify-content: space-between;
					justify-content: center;

					h2 {
						font-size: 2rem;
						margin-bottom: 0.75em;
					}
				}

				.bg-only {
					background-color: $bg-grey;
					padding: 1em 1.5em 0.5em;
					border-radius: 5px;
				}
			}
		}
	}
	.modalable-1.large {
		.modal {
			.header-modal {
				background-color: $white;
				border-bottom: none;

				.title {
					font-size: 2em;
					color: $black;
					text-align: center;
				}
			}

			.modal-content {

				.large-modal-content {
					display: flex;
					flex-wrap: wrap;
					// justify-content: space-between;
					justify-content: center;

					h2 {
						font-size: 2rem;
						margin-bottom: 0.75em;
					}
				}

				.modal-content-item-wrapper {
					width: calc(50% - 0.5em);
					margin-bottom: 1em;
				}
				.modal-content-item {
					background-color: $bg-grey;
					padding: 1em 1.5em 0.5em;
					border-radius: 5px;

					&.no-bg {
						background-color: transparent;
					}
				}

				.bg-only {
					background-color: $bg-grey;
					padding: 1em 1.5em 0.5em;
					border-radius: 5px;
				}
			}
		}
	}

    .custom-header {
        height: 100%;
        width: 100%;
    }

	@media (max-width: $desktop-small) {
		.modalable-1.large .modal .modal-content {
			.modal-content-item-wrapper {
				width: calc(100% - 1em);
			}
			.large-modal-content {
				flex-direction: column;
			}

			.modal-content-item {
				width: calc(100% - 1em);
			}
		}
	}

	@media (max-width: $phone) {
		.modalable-1{
			.modal {
				.modal-container {
					.modal-content {
						margin-bottom: 63px;
					}
				}
			}
		}

	}
</style>
