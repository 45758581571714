<template>
    <PageStepperLayout :page-subtitles="pageSubtitles">
        <EntityForm
            :id="Number($route.params.id) || null"
            @on-close-stepper="$router.go(-1)"
        />
    </PageStepperLayout>
</template>

<script>
import PageStepperLayout from '@/layouts/PageStepperLayout.vue';
import EntityForm from '@/components/pages/settings/entities/form/EntityForm.vue';

export default {
    components: { PageStepperLayout, EntityForm },
    data() {
        return {
            pageSubtitles: [this.translate('Settings'), this.translate('Entities')],
        };
    },
};
</script>
