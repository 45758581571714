import UnauthorizedError from '@/services/Errors/UnauthorizedError';

export default class AIEventService {
    constructor(env, token) {
        this.env = env;
        this.token = token;
        this.order = 0;
    }

    async ask(input, currentTone, maxAIContent, languages, callback) {
        const url = this.env.getCapiUrl('event/ask');
        const data = {
            input,
            type: 'event',
            tone: currentTone.value,
            max_events: maxAIContent,
            languages,
        };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.token}`,
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(url, options);

        if ([401, 403].includes(response.status)) {
            throw new UnauthorizedError();
        }

        return this.readStream(response.body, callback);
    }

    async readStream(stream, callback) {
        const reader = stream.getReader();
        let buffer = '';
        let inBrackets = 0;
        let lastProcessedIndex = 0;
        let isCompleteObject = false;
        const OPEN_BRACKET = '{';
        const CLOSE_BRACKET = '}';

        // eslint-disable-next-line no-constant-condition
        while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { done, value } = await reader.read();
            if (done) break;

            buffer += new TextDecoder().decode(value, { stream: true });

            for (let position = lastProcessedIndex; position < buffer.length; position++) {
                const char = buffer[position];

                if (char === OPEN_BRACKET) {
                    inBrackets += 1;
                } else if (char === CLOSE_BRACKET) {
                    inBrackets -= 1;

                    // si les accolades sont équilibrées, on a un objet JSON complet
                    if (inBrackets === 0) {
                        let jsonStr = '';
                        const firstBrace = buffer.indexOf(OPEN_BRACKET);
                        const lastBrace = position;

                        if (firstBrace !== -1 && lastBrace !== -1) {
                            jsonStr = buffer.substring(firstBrace, lastBrace + 1);
                        }

                        buffer = buffer.substring(position + 1);
                        lastProcessedIndex = 0;

                        let event = null;

                        try {
                            event = JSON.parse(jsonStr);
                        } catch (e) {
                            console.warn('Partial or malformed JSON, waiting for more data...', jsonStr);
                        }

                        if (event) {
                            this.order += 1;

                            isCompleteObject = true;

                            callback(event);
                        }
                    }
                }
            }

            if (isCompleteObject) {
                lastProcessedIndex = 0;
                isCompleteObject = false;
            } else {
                lastProcessedIndex = buffer.length;
            }
        }
        return buffer;
    }
}
