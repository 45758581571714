import Vue from 'vue';
import api from '@/store/api';

/**
 * @typedef {Object} Email
 * @property {number} id
 * @property {number} sender_user_id
 * @property {number} sender_role_id
 * @property {User|null} sender_user
 * @property {Role|null} sender_role
 * @property {boolean} is_locked
 * @property {{subject: Object<string, string>, content: Object<string, string>, signature: Object<string, string>}} translations
 * @property {Object<string, FileTranslation|null>} file_translations
 * @property {Resource} resource
 */

/**
 * @typedef {{data: {data: Email}}} EmailResourceResponse
 * @typedef {{data: {data: Email[]}}} EmailCollectionResponse
 */

export default {
    /**
     * @param {any} data
     * @returns {Promise<EmailCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.post('configuration/emails/list', data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<EmailResourceResponse>}
     */
    get(id, config = {}) {
        return Vue.prototype.$http.get(`configuration/emails/${id}`, config);
    },

    /**
     * @param {any} data
     * @returns {Promise<EmailResourceResponse>}
     */
    store(data = {}) {
        return Vue.prototype.$http.post('configuration/emails', data);
    },

    /**
     * @param {number|string} id
     * @param {any} data
     * @returns {Promise<EmailResourceResponse>}
     */
    update(id, data = {}) {
        return Vue.prototype.$http.post(`configuration/emails/${id}`, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<{}>}
     */
    delete(id, config = {}) {
        return Vue.prototype.$http.delete(`configuration/emails/${id}`, config);
    },

    /**
     * @param {number|string} emailId
     * @param {number|string} fileId
     * @returns {Promise<{CompanyFileHT}>}
     */
    duplicateFile(emailId, fileId) {
        const params = { file: fileId, email: emailId };

        const endpoint = api.utils.replaceVariables(api.endpoints.CONFIGURATION_EMAILS_DUPLICATION_FILE, params);

        return Vue.prototype.$http.post(endpoint);
    },

    /**
     * @param {number|string} id
     * @param {any} config
     * @returns {Promise<EmailResourceResponse>}
     */
    replicate(id, config = {}) {
        return Vue.prototype.$http.post(`configuration/emails/${id}/replicate`, config);
    },
};
