<template>
    <div
        class="widget-wrapper"
        :class="{'with-shadow': clickable}"
    >
        <div class="widget-header">
            <slot name="header" />
        </div>
        <div>
            <div class="title">
                <div
                    class="title-label"
                    :title="title"
                >
                    {{ title }}
                </div>
                <slot
                    name="title-extra"
                />
            </div>
            <div
                class="subtitle"
                :title="subtitle"
            >
                <slot
                    v-if="$slots.subtitle"
                    name="subtitle"
                />
                <template v-else>
                    {{ subtitle }}
                </template>
            </div>
        </div>
        <div class="widget-footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>

export default {
    name: 'UtilsTemplate',
    props: {
        title: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        clickable: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.widget-wrapper{
    background-color: $neutral-white;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    justify-content: space-evenly;
    margin-bottom: 16px;
    border: 1px solid $neutral-300;
    border-radius: 16px;

    &.with-shadow{
        cursor: pointer;

        &:hover {
            box-shadow: 0px 4px 6px rgba(153, 153, 153, 0.14);
        }
    }

    .widget-header{
        display: flex;
        align-items: center;
        justify-content: left;
        white-space: nowrap;
        overflow-x: auto;
        padding-bottom: 4px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .widget-footer{
        height: 20px;
        display: flex;
        align-items: center;
    }

    .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-label{
            font-size: 20px;
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .subtitle{
        color: $neutral-500;
        font-size: 14px;
        margin-top: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

}
</style>
