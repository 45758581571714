<template>
    <div
        class="requirement-category-card-wrapper"
        data-cy="requirement-card"
        @click="() => $emit('on-click-requirement-card', companyUserRequirementCategory)"
    >
        <div class="requirement-category-card-wrapper-title mb-4">
            <t v-if="companyUserRequirementCategory.is_heyteam">
                {{ companyUserRequirementCategory.name }}
            </t>
            <span v-else>{{ companyUserRequirementCategory.name }}</span>
        </div>

        <div
            v-for="group in companyUserRequirementCategory.company_user_requirement_group.$.models"
            :key="group.id"
            class="requirement-category-card-wrapper-requirementCard"
        >
            <RequirementCard
                v-for="companyUserRequirement in group.company_user_requirement.models"
                :key="companyUserRequirement.id"
                :requirement="companyUserRequirement"
                class="clickable"
            />
        </div>
        <div class="edit-icon">
            <FontAwesomeIcon
                :icon="['fal', 'edit']"
            />
        </div>
    </div>
</template>

<script>
import CompanyUserRequirementCategory from '@/models/CompanyUserRequirementCategory';
import RequirementCard from './RequirementCard.vue';

export default {
    name: 'RequirementCategoryCard',
    components: {
        RequirementCard,
    },

    props: {
        companyUserRequirementCategory: { type: CompanyUserRequirementCategory },
    },

};

</script>

<style lang="scss" src="./RequirementCategoryCard.scss" scoped> </style>
