import makeDirective from './directive';
import makeMixin from './mixin';
import Validator from './validator';
import ErrorBag from './errorBag';
// eslint-disable-next-line
const install = (Vue, options) => {
    // const config = Object.assign({}, defaultOptions, options);

    const config = {};
    Vue.mixin(makeMixin(Vue, config));
    Vue.directive('fvalidate', makeDirective(config));
};

export default {
    install,
    Validator,
    ErrorBag,
    /* install,
	mapFields,
	Validator,
	ErrorBag,
	Rules, */
    version: '__VERSION__',
};
