import fromPairs from 'lodash.frompairs';
import clonedeep from 'lodash.clonedeep';

function _() {
    return {

        callback: {},

        /**
		 * Open an oauth window and callback the result.
		 * @param {Object} parameter - Auth's Parameters
		 * @param {string} parameter.url - Authorize url
		 * @param {string} parameter.client_id -  The app's key.
		 * @param {string} parameter.scope - Scope permission.
		 * @param {string} parameter.redirect_uri - Where to redirect the user after authorization has completed.
		 * @param {string} parameter.state - Data that will be passed back to the redirect URI.
		 * @param {string} parameter.response_type - The grant type requested, either token or code.
		 * @param {string} parameter... - Other custom param who will be passed through the query.
		 * @param {Object} callback - User's callback error's code
		 * @param {Object} callback.403 - User's callback for unauthorized access
		 * @param {Object} callback.200 - User's callback for authorized access
		 */
        auth(parameter, callback) {
            const uid = this.uniqId();
            const { finalUrl } = this.prepareUrl(parameter, uid);
            this.registerCallBack({
                uid,
                internal_callback: function (e) { this.onMessage(e); }.bind(this),
                user_callback: callback || {},
            });

            window.open(finalUrl).focus();
        },

        prepareUrl(_parameter, uid) {
            const parameter = clonedeep(_parameter);

            const aReturn = {};
            aReturn.url = parameter.url;
            delete parameter.url;

            aReturn.state = parameter.state;

            // Add an UID to the state. This uid will be used to map the user_callback
            parameter.state = this.concatUid(parameter.state ? parameter.state : '', uid);

            // Transform parameters to query string
            aReturn.finalUrl = `${aReturn.url}?${Object.keys(parameter).map((v) => {
                if (encodeURIComponent(v) == 'return_url') { // oAuth1
                    return `${encodeURIComponent(v)}=${encodeURIComponent(parameter[v])}?state=${parameter.state}`;
                }
                return `${encodeURIComponent(v)}=${encodeURIComponent(parameter[v])}`;
            }).join('&')}`;

            return aReturn;
        },

        registerCallBack(parameter) {
            this.addEventListener(parameter.internal_callback);

            this.callback[parameter.uid] = {
                uid: parameter.uid,
                internal_callback: parameter.internal_callback,
                user_callback: parameter.user_callback,
            };
        },

        unRegisterCallBack(uid) {
            if (this.callback[uid]) {
                this.removeEventListener(this.callback[uid].internal_callback);
                delete this.callback[uid];
            }
        },

        concatUid(state, uid) {
            const tmp = {
                old_state: state,
                uid,
            };

            const stmp = Object.keys(tmp).map((v) => `${encodeURIComponent(v)}=${encodeURIComponent(tmp[v])}`).join('&');

            return encodeURIComponent(stmp);
        },

        addEventListener(internal_callback) {
            window.addEventListener('message', internal_callback);
        },

        removeEventListener(internal_callback) {
            window.removeEventListener('message', internal_callback);
        },

        onMessage(event) {
            if (this.isAuthMessageEvent(event)) {
                const state = decodeURIComponent(event.data.state);

                let tmp = state.split('&').map((value) => value.split('='));
                tmp = fromPairs(tmp);

                event.data.state = tmp.old_state ? tmp.old_state : '';

                if (tmp.uid && this.callback[tmp.uid]) {
                    const { user_callback } = this.callback[tmp.uid];

                    try {
                        if (event.data.error) {
                            this.callUserCallback(user_callback, '403', event.data);
                        } else {
                            this.callUserCallback(user_callback, '200', event.data);
                        }
                    } catch (e) {

                    }

                    this.unRegisterCallBack(tmp.uid);
                }
            }
        },

        callUserCallback(callback, code, data) {
            if (callback[code]) {
                callback[code](data);
            }
        },

        isAuthMessageEvent(event) {
            return event && event.data && event.data.state !== undefined;
        },

        uniqId() {
            return (new Date().getTime() + Math.floor((Math.random() * 10000) + 1)).toString(16);
        },

    };
}

export default new _();
