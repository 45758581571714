<template>
    <HtModal
        ref="modal"
        :size="'small'"
    >
        <template #header>
            <span class="title modal-header">
                {{ companyUser.fullname }}
            </span>
        </template>
        <template #default>
            <div class="mt-5 mb-5 d-flex justify-content-center email-sentence">
                <span><t>This collaborator has been invited on</t> {{ companyUser.email }}</span>
            </div>

            <HtFormSwitch
                :id="'use-sso'"
                v-model="companyUser.has_sso"
                :tooltip="translate('SSO login can only be enabled if the company has an active SSO configuration and the user\'s email is compatible with the configured domains.')"
                :disabled="!canActiveSso"
                :label="translate('This user can use SSO')"
                :name="'use-sso'"
                :show-optional="false"
                :class="'my-5'"
            />

            <HtFormSelect
                :id="'userEditOffice'"
                v-model="companyUser.company_office_id"
                :placeholder="translate('All sites...')"
                :options="officeOptionsModal"
                :name="'userEditOffice'"
                :label="translate('Site')"
                :show-optional="false"
                class="mb-10"
            />
            <HtFormSelect
                :id="'userEditDepartment'"
                v-model="companyUser.company_department_id"
                :placeholder="translate('All department...')"
                :options="departmentOptionsModal"
                :name="'userEditDepartment'"
                :label="translate('Department')"
                :show-optional="false"
                class="mb-10"
            />
            <div>
                <Button
                    v-if="isActive"
                    class="negative"
                    @click="disableUser"
                >
                    <t class="branding-color">
                        Disable user
                    </t>
                </Button>
                <Button
                    v-else-if="isInactive"
                    class="negative branding-color"
                    @click="enableUser"
                >
                    <t class="branding-color">
                        Enable user
                    </t>
                </Button>

                <ResendInviteButton
                    v-if="isInvited && companyUser"
                    :user-id="companyUser.id"
                    class="ml-4"
                />
            </div>

            <div
                v-if="shared.session.company.security.is_two_factor_auth_enabled"
                class="modal-two-factor-auth mt-3"
            >
                <Button
                    v-if="!isTwoFactorAuthEnabled"
                    class="negative"
                    @click="enableTwoFactorAuth"
                >
                    <t class="branding-color">
                        Enable 2FA
                    </t>
                </Button>
                <Button
                    v-else
                    class="negative branding-color"
                    @click="disableTwoFactorAuth"
                >
                    <t class="branding-color">
                        Disable 2FA
                    </t>
                </Button>
            </div>

            <ShowRolesWithPerimeters
                :data="companyUser.company_role_user.models"
                class="roles-perimeters-container"
            />

            <HtFormSwitch
                :id="'is-ghost'"
                v-model="isTeamPageVisible"
                :label="translate('This user is visible on team page')"
                :name="'is-ghost'"
                :show-optional="false"
                :class="'my-5'"
            />

            <div class="mb-5 d-flex justify-content-center">
                <Button
                    class="negative mr-4"
                    @click="goToProfile(companyUser.id)"
                >
                    <t>Go to profile</t>
                </Button>

                <Button
                    class="negative mr-4"
                    :state="deleteButtonState"
                    @click="deleteUser"
                >
                    <t :class="deleteButtonState==='idle' ? 'error-color' : ''">
                        Delete
                    </t>
                </Button>

                <Button @click="saveUser">
                    <t>Save</t>
                </Button>
            </div>
        </template>
    </HtModal>
</template>

<script>
import debounce from 'lodash.debounce';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import Button from '@/components/globals/Button.vue';
import ResendInviteButton from '@/components/Buttons/ResendInviteButton.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import api from '@/store/api';
import modalMixin from '@/components/globals/modals/modalMixin';
import Company from '@/models/Company';
import CompanyUser from '../../../../models/CompanyUser';
import Modalable from '../../../../Modalable';
import ShowRolesWithPerimeters from '../../../permissions/ShowRolesWithPerimeters.vue';

export default {
    name: 'UserInfosModal',

    components: {
        Button,
        HtFormSelect,
        HtFormInput,
        ResendInviteButton,
        ShowRolesWithPerimeters,
        HtFormSwitch,
    },
    mixins: [
        modalMixin,
    ],

    shared: {
        session: {
            company: {
                type: Company,
                default: null,
            },
        },
    },

    props: {
        departmentOptions: { type: Array },
        officeOptions: { type: Array },
    },

    data() {
        return {
            show: null,
            companyUser: null,
            deleteButtonState: 'idle',
            userPermissionColumns: [
                {
                    label: this.translate('Type'),
                    key: 'type',
                },
                {
                    label: this.translate('Access'),
                    key: 'access',
                },
                {
                    label: this.translate('Perimeter'),
                    key: 'perimeter',
                },
            ],
        };
    },

    computed: {
        canActiveSso() {
            if (this.companyUser.company?.company_sso?.is_enabled) {
                const isContainingValidSsoDomain = this.companyUser.isContainingValidSsoDomain(
                    this.companyUser.email,
                    this.companyUser.company.company_sso.domains,
                );

                if (isContainingValidSsoDomain) {
                    return true;
                }
            } else if (this.companyUser.has_sso) {
                return true;
            }

            return false;
        },
        // Specifics options for modal, needs id and text as keys
        departmentOptionsModal() {
            return this.departmentOptions.map((item) => ({
                id: item.id,
                name: item.name_translated,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
        officeOptionsModal() {
            return this.officeOptions.map((item) => ({
                id: item.id,
                name: item.name_translated,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
        isDevelopment() {
            return process.env.NODE_ENV === 'development';
        },
        isActive() {
            return this.companyUser.status === 'active';
        },
        isTwoFactorAuthEnabled() {
            return this.companyUser.is_two_factor_auth_enabled === true;
        },
        isInactive() {
            return this.companyUser.status === 'inactive';
        },
        isInvited() {
            return this.companyUser.status === 'invited';
        },
        getUserModal() {
            return this.companyUser;
        },
        isTeamPageVisible: {
            get() {
                return !this.companyUser.is_ghost;
            },
            set(val) {
                this.companyUser.is_ghost = !val;
            },
        },
    },
    watch: {
        querySearch: {
            handler: debounce(function () { this.setCompanyUserCollection(); }, 1500),
            deep: true,
        },
    },

    methods: {
        goToProfile(id) {
            this.$router.push({
                name: 'Profile',
                params: { company_user_id: id },
            });
        },

        async deleteUser() {
            const didUserClickDelete = await Modalable.openRegisteredWindow('Deletewindow', {
                content: this.translate(
                    "Confirm deleting {fullname}'s account?",
                    { fullname: this.getUserModal.fullname },
                ),
            });

            if (didUserClickDelete === true) {
                this.deleteButtonState = 'loading';
                this.getUserModal.delete().then(() => {
                    this.$Notifier('App').showInfo(this.translate('The account of {fullname} has been deleted', { fullname: this.getUserModal.fullname }));
                    this.$emit('onReload');
                    this.$refs.modal.close();
                }).finally(() => {
                    this.deleteButtonState = 'idle';
                });
            }
        },

        enableUser() {
            if (!this.isActive) {
                this.getUserModal.save({ method: 'enableUser' }).then((data) => {
                    this.companyUser.status = data.status;
                    this.$emit('onReload');
                    this.$refs.modal.close();
                });
            }
        },

        disableUser() {
            if (this.isActive) {
                this.getUserModal.save({ method: 'disableUser' }).then((data) => {
                    this.companyUser.status = data.status;
                    this.$emit('onReload');
                    this.$refs.modal.close();
                });
            }
        },

        enableTwoFactorAuth() {
            this.genericConfirm(
                this.translate('Reactivation of dual authentication'),
                `
                    ${this.translate("This function, recommended by the CNIL, requires the use of your employees' phone numbers (if not entered, the function will remain deactivated for the next login).")} <br>
                    ${this.translate('Are you sure you want to activate double authentication for this employee?')}
                    `,
                null,
                this.translate('Activate'),
                this.translate('Cancel'),
            ).then(async () => {
                await api.users.toggleTwoFactorAuth(this.companyUser.id).then((user) => {
                    this.companyUser.is_two_factor_auth_enabled = user.is_two_factor_auth_enabled;

                    this.$emit('onReload');
                    this.$refs.modal.close();
                });
            });
        },

        disableTwoFactorAuth() {
            this.genericConfirm(
                this.translate('Deactivation of dual authentication'),
                `
                    ${this.translate('Deactivating this feature will allow {fullname} to access the platform via simple authentication (i.e. email / password), which is not at all recommended by the CNIL.', { fullname: this.companyUser.fullname })} <br>
                    ${this.translate('Are you sure you want to disable double authentication?')}
                    `,
                null,
                this.translate('Desactivate'),
                this.translate('Cancel'),
            ).then(async () => {
                await api.users.toggleTwoFactorAuth(this.companyUser.id).then((user) => {
                    this.companyUser.is_two_factor_auth_enabled = user.is_two_factor_auth_enabled;
                    this.$emit('onReload');
                    this.$refs.modal.close();
                });
            });
        },

        saveUser() {
            this.companyUser.save().then(() => {
                this.$emit('onReload');
                this.$refs.modal.close();
            });
        },

        async open(userId) {
            if (!Number.isInteger(userId)) {
                this.$Notifier('App').showError(this.translate('This user is unavailable for edition'));
                return;
            }
            this.companyUser = await this.initCompanyUser(userId).get();
            this.$refs.modal.open();
        },

        initCompanyUser(userId) {
            return new CompanyUser([
                'id',
                'firstname',
                'lastname',
                'image',
                'email',
                'fullname',
                'company_department_id',
                'company_office_id',
                'status',
                'is_ghost',
                'has_sso',
                'is_two_factor_auth_enabled',
            ]).with({
                company: (query) => {
                    query.select(['name']).with({
                        companySso: (query) => {
                            query.select([
                                'domains',
                                'is_enabled',
                            ]);
                        },
                    });
                },
                companyRoleUser: (query) => {
                    query
                        .select([
                            'id',
                            'company_role_id',
                            'company_user_id',
                        ])
                        .with({
                            role: (query) => {
                                query.select(['id', 'name', 'alias', 'is_heyteam']);
                            },

                            perimeters: (query) => {
                                query
                                    .select([
                                        'id',
                                        'company_entity_id',
                                        'company_role_user_id',
                                        'company_entity_value_id',
                                    ])
                                    .with({
                                        entity: (query) => {
                                            query.select([
                                                'id',
                                                'name',
                                                'slug',
                                                'is_heyteam',
                                            ]);
                                        },
                                        value: (query) => {
                                            query.select([
                                                'id',
                                                'company_entity_id',
                                                'resourceable_type',
                                                'resourceable_id',
                                            ]).with({
                                                jobPosition: (query) => {
                                                    query.select([
                                                        'id',
                                                        'name',
                                                        'is_heyteam',
                                                    ]);
                                                },
                                                contract: (query) => {
                                                    query.select([
                                                        'id',
                                                        'name',
                                                        'is_heyteam',
                                                    ]);
                                                },
                                                office: (query) => {
                                                    query.select([
                                                        'id',
                                                        'name',
                                                        'is_heyteam',
                                                    ]);
                                                },
                                                department: (query) => {
                                                    query.select([
                                                        'id',
                                                        'name',
                                                        'is_heyteam',
                                                    ]);
                                                },
                                                custom: (query) => {
                                                    query.select([
                                                        'id',
                                                        'key',
                                                        'name',
                                                    ]);
                                                },
                                            });
                                        },
                                    });
                            },
                            employees: (query) => {
                                query
                                    .select([
                                        'id',
                                        'company_role_user_id',
                                        'company_user_id',
                                    ])
                                    .with({
                                        user: (query) => {
                                            query.select([
                                                'id',
                                                'firstname',
                                                'lastname',
                                                'fullname',
                                            ]);
                                        },
                                    });
                            },
                        });
                },
            }).where(['id', '=', userId]);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "~@/styles/var";

    .error-color {
        color: $red-1;
    }
    .roles-perimeters-container {
        margin-top: 30px;
    }
    .email-sentence {
        font-size: 1.4rem;
        color: $color-text;
    }
</style>
