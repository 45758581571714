<template>
    <div>
        <div class="search-field-container">
            <HtSearchField
                id="search"
                v-model.trim="search"
                type="text"
                class="chat-search-input"
                :placeholder="translate('Find a user to talk with !')"
                name="search"
            />
        </div>
        <div class="search-result-container">
            <template v-if="search.length === 0">
                <ChatListChannelItemHtml
                    v-for="user in selectedUsers"
                    :key="user.id"
                    :image="user.image"
                    :image-firstname="user.firstname"
                    :image-lastname="user.lastname"
                    :name="user.fullname"
                    :subname="user.professional_email"
                    :is-connected="user.isConnected"
                    @mousedown.native="toggleUser(user)"
                >
                    <HtCheckbox
                        :id="'checkbox_'+user.id"
                        class="seearch-result-container-checkbox"
                        :name="'checkbox_'+user.id"
                        :value="isSelected(user.id)"
                    />
                </ChatListChannelItemHtml>
            </template>
            <template v-else>
                <ChatListChannelItemHtml
                    v-for="user in users"
                    :key="user.id"
                    :image="user.image"
                    :image-firstname="user.firstname"
                    :image-lastname="user.lastname"
                    :name="user.fullname"
                    :subname="user.professional_email"
                    :is-connected="user.isConnected"
                    @mousedown.native="toggleUser(user)"
                >
                    <HtCheckbox
                        :id="'checkbox_'+user.id"
                        class="seearch-result-container-checkbox"
                        :name="'checkbox_'+user.id"
                        :value="isSelected(user.id)"
                    />
                </ChatListChannelItemHtml>
            </template>
        </div>
    </div>
</template>
<script>
import ChatSearchMixin from '@/components/chat/mixins/ChatSearchMixin';
import HtCheckbox from '@/components/globals/HtCheckbox.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import ChatListChannelItemHtml from '@/components/chat/ChatListChannelItemHtml.vue';

export default {
    name: 'ChatGroupManagerVUserList',
    components: {
        HtCheckbox,
        HtSearchField,
        ChatListChannelItemHtml,
    },
    mixins: [ChatSearchMixin],
    props: {
        selectedUsers: {
            type: Array,
            required: true,
        },

    },
    methods: {
        toggleUser(user) {
            if (!this.isSelected(user.id)) {
                this.$emit('add', user);
            } else {
                this.$emit('remove', user);
            }
        },
        isSelected(id) {
            return this.selectedUsers.map((u) => u.id).includes(id);
        },
        resetSearch() {
            this.users = [];
            this.search = '';
        },
    },
};
</script>
<style lang="scss" scoped>
.search-field-container {
    background-color: #F9F9F9;
    padding: 10px;
}
</style>
