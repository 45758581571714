<template>
    <div>
        <IconLoading v-if="!entitiesLoaded" />
        <div v-else>
            <HtFormGroup
                :label="translate('Filters selection')"
                is-required
                class="mb-0"
            >
                <div
                    v-for="entity in entities"
                    :key="entity.id"
                >
                    <HtFormSelector
                        :id="`entity${entity.slug}`"
                        :value="selectedValuesByEntityId[entity.id]"
                        :options="valuesByEntityId[entity.id]"
                        :placeholder="entity.is_heyteam ? translate(entity.name) : entity.name"
                        track-by="company_entity_value_id"
                        :name="`entity${entity.slug}`"
                        label-options="name"
                        :show-optional="false"
                        @input="values => updateEntitiesFilter(entity, values)"
                        class="mb-3"
                    >
                        <div slot="noOptions">
                            <t>All filters already selected</t>
                        </div>
                    </HtFormSelector>
                </div>
            </HtFormGroup>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import groupBy from 'lodash.groupby';
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import api from '@/store/api';

export default {
    name: 'AdditionByFilters',

    components: {
        IconLoading,
        HtFormSelector,
        HtFormGroup,
    },

    props: {
        programId: {
            type: Number,
            required: false,
            default: () => null,
        },

        excludedUsersId: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            filters: [],
        };
    },

    created() {
        this.$store.dispatch('entities/fetchEntities');
    },

    methods: {
        updateEntitiesFilter(entity, values) {
            const currentFilters = this.filters.filter((e) => e.company_entity_id !== entity.id);

            this.filters = [...currentFilters, ...values];

            this.findUsersByFilters();
        },

        findUsersByFilters() {
            this.$emit('enable-loading-users');
            this.$emit('update-users', []);

            if (this.hasEmptyFilters) {
                this.$emit('change', false);
                this.$emit('disable-loading-users');

                return;
            }

            api.user
                .enrolling
                .multipleEnrolling
                .findUsersByFilters({
                    program_id: this.programId,
                    excluded_users_id: this.excludedUsersId,
                    filters: this.filters,
                })
                .then((res) => {
                    this.$emit('update-users', res.data.users);
                    this.$emit('change', res.data.users.length > 0);
                    this.$emit('disable-loading-users');
                });
        },
    },

    computed: {
        ...mapState('entities', [
            'entitiesLoaded',
        ]),

        ...mapGetters('entities', [
            'valuesByEntityId',
        ]),

        entities() {
            return this.$store.state.entities.entities;
        },

        selectedValuesByEntityId() {
            const entities = {};

            const valuesByEntityId = groupBy(this.filters, 'company_entity_id');

            this.entities.forEach((entity) => {
                entities[entity.id] = (valuesByEntityId[entity.id] || []).map((value) => ({
                    ...value,
                    name: this.valuesByEntityId[value.company_entity_id].find((v) => v.company_entity_value_id === value.company_entity_value_id).name,
                }));
            });

            return entities;
        },

        hasEmptyFilters() {
            return this.filters.length === 0;
        },
    },
};
</script>
