import Model from '@tony.caron/node-model-proxy/Model';

import Company from './Company';
import CompanySurveyQuestionLocaleCollection from './CompanySurveyQuestionLocaleCollection';

export default class CompanySurveyQuestionLocale extends Model {
    modelConfig() {
        return {
            collection: CompanySurveyQuestionLocaleCollection,
        };
    }

    modelRelations() {
        return {
            company: () => this.belongsTo(Company, 'company_id', 'id'),
        };
    }
}
