<template>
    <HtFormGroup
        :label="label"
        :error="error"
        :is-required="isRequired"
        :show-asterisk="showAsterisk"
    >
        <!-- On n'affiche le selecteur des variables uniquement si le formulaire n'est pas disabled -->
        <HtFormVariables
            v-if="variables.length > 0 && !disabled"
            :variables="variables"
            :cypress="cypress + '-variables'"
            @addVariable="addVariable"
        />
        <Editor
            ref="editor"
            :key="`version-${editVersion}`"
            class="ht-form-editor"
            :value="value"
            :init="init"
            :disabled="disabled"
            tinymce-script-src="/static/tinymce/5/tinymce.min.js"
            :class="{'no-toolbar': !hasToolbar}"
            :data-cy="cypress"
            @input="$emit('input', $event)"
        />
        <div
            v-if="hasExtraSlot"
            class="mt-3"
        >
            <slot name="extra" />
        </div>
    </HtFormGroup>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import DefaultEditorMixin from '@/components/mixins/DefaultEditorMixin';
import EditorCustomStyleMixin from '@/components/mixins/EditorCustomStyleMixin';
import HtFormMixin from './HtFormMixin';
import HtFormVariables from './HtFormVariables.vue';

export default {

    components: {
        Editor, HtFormVariables,
    },
    mixins: [
        HtFormMixin,
        DefaultEditorMixin,
        EditorCustomStyleMixin,
    ],

    props: {
        value: {
            type: String,
            default: () => ' ',
        },
        height: {
            type: Number,
            default: () => 300,
        },
        hasToolbar: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            editVersion: 0, // Incrementing this data allows you to force the updating of the Editor component
        };
    },

    computed: {
        hasExtraSlot() {
            return 'extra' in this.$slots;
        },
    },

    methods: {
        reload() {
            this.editVersion++;
        },

        addVariable(variable) {
            const { editor } = this.$refs.editor;

            editor.execCommand('mceInsertContent', false, variable);
        },
    },
};
</script>

<style lang="scss" src="./HtFormEditor.scss"></style>
