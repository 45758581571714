<template>
    <div
        class="draggable-item"
        @click.stop="$emit('click')"
    >
        <div class="draggable-item-info">
            <template v-if="isExtraResource">
                <div class="draggable-item-info-title pb-10">
                    <t>Additional information</t>
                </div>

                <div
                    v-if="extraInfo"
                    class="draggable-item-info-type"
                >
                    <img
                        v-if="extraInfo.icon"
                        width="16"
                        height="16"
                        class="mr-10"
                        :src="extraInfo.icon"
                    >

                    <div>
                        <t>{{ extraInfo.label }}</t>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="draggable-item-info-title pb-10">
                    {{ content.question.translations.text[currentLanguage.key] }}
                </div>

                <div
                    v-if="extraInfo"
                    class="draggable-item-info-type"
                >
                    <img
                        v-if="extraInfo.icon"
                        width="16"
                        height="16"
                        class="mr-10"
                        :src="extraInfo.icon"
                    >

                    <div>
                        <t>{{ extraInfo.label }}</t>
                    </div>
                </div>
            </template>
        </div>

        <div
            v-if="!isCompleted"
            class="draggable-item-icon"
        >
            <FontAwesomeIcon
                size="2x"
                icon="bars"
            />
        </div>
    </div>
</template>

<script>
import CompanyLanguage from '@/models/CompanyLanguage';
import CompanySurveyContent from '@/models/CompanySurveyContent';
import { SurveyQuestionType } from '@/models/common/SurveyQuestionType';

export default {
    name: 'SurveyFormQuestionDraggableItem',

    props: {
        content: {
            type: Object,
            required: true,
        },
        currentLanguage: {
            type: CompanyLanguage,
            required: true,
        },
        isCompleted: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        isExtraResource() {
            return this.content.resource === CompanySurveyContent.RESOURCE_EXTRA;
        },

        extraInfo() {
            if (this.isExtraResource) {
                return {
                    name: 'text',
                    label: 'Text',
                    icon: '/static/icons/survey/type/AdditionalInfo.svg',
                };
            }
            return Object.values(SurveyQuestionType).find((item) => item.name === this.content.question.type);
        },
    },
};
</script>

<style lang="scss" scoped src="./SurveyFormQuestionsDraggable.scss"></style>
