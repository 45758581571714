<template>
    <div class="container">
        <HtFormSwitch
            id="isRecurrent"
            v-model="isRecurrent"
            :label="translate('Recurrence of the resource')"
            name="isRecurrent"
            :show-optional="false"
            @input="toggleRecurrent"
        />
        <br>
        <div class="container-row">
            <HtInputText
                id="recurrence_frequence_value"
                :disabled="!isRecurrent"
                class="container-row-input"
                name="recurrence_frequence_value"
                :value="innerRecurrence.recurrence_frequence_value"
                type="number"
                @input="(value) => updateRecurrence('recurrence_frequence_value', value)"
            />
            <HtFormSelector
                id="recurrence_frequence_unit"
                :disabled="!isRecurrent"
                class="container-row-input"
                name="recurrence_frequence_unit"
                :is-single="true"
                :options="frequence_unit_options"
                track-by="value"
                specific-key="value"
                label-options="label"
                :value="innerRecurrence.recurrence_frequence_unit"
                @input="(value) => updateRecurrence('recurrence_frequence_unit', value)"
            />
        </div>
    </div>
</template>

<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtInputText from '@/components/globals/HtInputText.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';

export default {
    name: 'RecurrenceUserConfig',
    components: {
        HtFormSwitch,
        HtInputText,
        HtFormSelector,
    },
    props: {
        recurrence: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            innerRecurrence: this.recurrence || {
                company_id: null,
                recurrence_frequence_value: null,
                recurrence_frequence_unit: null,
            },
            isRecurrent: !!this.recurrence,
            haveRecurrenceEnd: false,
            frequence_unit_options: [
                { label: this.translate('days'), value: 'days' },
                { label: this.translate('weeks'), value: 'weeks' },
                { label: this.translate('months'), value: 'months' },
                { label: this.translate('years'), value: 'years' },
            ],
        };
    },

    watch: {
        recurrence(newRecurrence) {
            this.innerRecurrence = newRecurrence || {
                company_id: null,
                recurrence_frequence_value: null,
                recurrence_frequence_unit: null,
            };
            this.isRecurrent = !!newRecurrence;
        },
    },
    methods: {
        updateRecurrence(key, value) {
            this.innerRecurrence[key] = value;
            this.$emit('update', this.innerRecurrence);
        },

        toggleRecurrent() {
            if (!this.isRecurrent) {
                this.$emit('update', null);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.container-row {

    display: flex;
    gap: 5px;

    &-input {
        flex: 3 1 0;
        height: 100%;
    }

    &-input:first-child {
        flex: 1 1 0;
    }

}

</style>
