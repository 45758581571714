<template>
    <div
        v-if="is_loaded"
        class="preboarding-row"
        :class="{'reverse': reversed}"
    >
        <div :class="{'show-image' : showTyping || showContent, 'image-bubble': !reversed, 'image-bubble-right': reversed}">
            <div
                v-if="useHeyteamBubble"
                v-user-image="$Utils.getCompanyComponentRoleInitials('heyteam', 1.25, {})"
                class="item-image"
            />
            <div
                v-else-if="companyUser"
                v-user-image="$Utils.getCompanyComponentRoleInitials('user', 1.25, {
                    firstname: companyUser.firstname,
                    lastname: companyUser.lastname,
                    image: companyUser.image,
                })"
                class="item-image"
            />
        </div>

        <div
            class="row-content"
            :style="{'width': width + 'em'}"
            :class="{'full': full, 'show-typing': showTyping, 'show-text': showText, 'no-bg': noBg}"
        >
            <div class="typing-gif">
                <span><span /></span>
            </div>
            <div class="txt-row">
                <slot name="content" />
                <slot />
                <transition
                    v-if="reversed"
                    name="slide"
                >
                    <div
                        v-if="showButtons && !isSkipped && !isCompleted && !isNotConcerned"
                        class="button-wrapper multiple"
                    >
                        <Button
                            v-if="canReject"
                            class="negative"
                            @click="$emit('onNotConcerned')"
                        >
                            <t>I am not concerned</t>
                        </Button>
                        <Button
                            v-if="canSkip"
                            class="negative"
                            @click="$emit('onCancel')"
                        >
                            <t>Skip</t>
                        </Button>
                        <Button
                            :state="canValidate === true ? 'idle' : 'disabled'"
                            @click="$emit('onValidate')"
                        >
                            <t>Validate</t>
                        </Button>
                    </div>
                    <div
                        v-else-if="showButtons && isSkipped"
                        class="button-wrapper"
                    >
                        <strong><t>Skipped</t></strong>
                    </div>
                    <div
                        v-else-if="showButtons && isCompleted"
                        class="button-wrapper"
                    >
                        <strong><t>Saved</t></strong>
                    </div>
                    <div
                        v-else-if="showButtons && isNotConcerned"
                        class="button-wrapper"
                    >
                        <strong><t>Not concerned</t></strong>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '../globals/Button.vue';

export default {
    name: 'FlowRow',
    components: { Button },
    inject: ['$validator'],

    props: {
        full: { type: Boolean, default: false },
        companyUser: { type: Object, required: false },
        isLoaded: { type: Boolean, default: true },
        timeout: { type: Number, default: 0 },
        noBg: { type: Boolean, default: false },
        showButtons: { type: Boolean, default: false },
        reversed: { type: Boolean, default: false },
        width: { type: Number, default: null },
        isSkipped: { type: Boolean, default: false },
        isCompleted: { type: Boolean, default: false },
        canReject: { type: Boolean, default: false },
        canValidate: { type: Boolean, default: true },
        canSkip: { type: Boolean, default: true },
        useHeyteamBubble: { type: Boolean, default: false },
        isNotConcerned: { type: Boolean, default: false },
    },

    data() {
        return {
            is_loaded: false,
            showTyping: false,
            showContent: false,
            showImage: false,
            showText: false,
            globalTimeout: null,
        };
    },

    computed: {
        disabledButton() {
            return (!this.showButtons) ? 'disabled' : 'idle';
        },
    },

    created() {
        if (this.isLoaded) this.show(this.timeout);
        else this.is_loaded = this.isLoaded;
    },

    methods: {
        show() {
            return new Promise((resolve) => {
                this.is_loaded = true;
                setTimeout(() => {
                    this.showTyping = true;
                    this.$emit('onLoadRow');
                    setTimeout(() => {
                        this.showTyping = false;
                        this.showContent = true;
                        setTimeout(() => {
                            this.showText = true;
                            this.$emit('onLoad');
                            resolve();
                        }, 300);
                    }, 1000);
                }, 100);
            });
        },
    },
};
</script>
