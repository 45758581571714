<template>
    <div class="card-title-bar-header">
        <div class="card-title-bar-left">
            <div>
                <Skeleton
                    width="150px"
                    height="20px"
                    :loading="loading"
                >
                    {{ title }}
                </Skeleton>
            </div>
            <div
                v-if="subtitle"
                class="subtitle"
            >
                {{ subtitle }}
            </div>
        </div>
        <div class="card-title-bar-right">
            <slot name="card-title-bar-right" />

            <HtSearchField
                v-if="showSearch"
                :id="_uid.toString()"
                v-model.trim="search"
                class="mr-4"
                :class="{'hide-mobile': !showSearchOnMobile}"
                :name="_uid.toString()"
            />
            <Button
                v-if="showButton"
                cypress="button-action-add"
                :state="getState"
                @click="$emit('onAdd')"
            >
                <slot name="buttonText">
                    <span class="button-label">{{ buttonText }}</span>
                </slot>
            </Button>
            <slot name="default" />
            <HtIconClose
                v-if="showNewClose"
                class="ml-4"
                @click="$emit('on-close')"
            />
            <div
                v-if="showClose"
                class="card-title-bar-right-close ml-4"
                @click="$emit('on-close')"
            >
                <FontAwesomeIcon
                    class="icon"
                    :icon="['fal', 'times']"
                />
            </div>
        </div>
    </div>
</template>

<script>
import HtSearchField from '@/components/globals/HtSearchField.vue';
import I18n from '@/modules/i18n/I18n';
import { Skeleton } from 'vue-loading-skeleton';
import HtIconClose from '@/components/icons/HtIconClose.vue';

export default {
    name: 'HtCardTitleBar',
    components: {
        HtIconClose,
        HtSearchField,
        Skeleton,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
        },
        showSearch: {
            type: Boolean,
            default: true,
        },
        showSearchOnMobile: {
            type: Boolean,
            default: true,
        },
        showButton: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        buttonText: {
            type: String,
            default: () => I18n.translate('Add'),
        },
        showClose: {
            type: Boolean,
            default: false,
        },
        showNewClose: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            search: null,
        };
    },

    computed: {
        getState() {
            if (this.disabled) {
                return 'disabled';
            }

            return 'idle';
        },
    },

    watch: {
        search(val) {
            this.$emit('search', val);
        },
    },

};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.card-title-bar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%
}

.card-title-bar-right {
    display: flex;
    align-items: center;
    gap: 10px;

    &-close {
        border-radius: 50%;
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color-lightblue-grey;
        cursor: pointer;
        transition: all 0.3s;

        .icon {
            font-size: 2rem;
        }

        &:hover {
            transform: scale(1.1);
        }
    }
}

.ht-form {
    margin-bottom: 0.8rem;
}

.subtitle {
    font-weight: normal;
    font-size: 1.4rem;
    color: $grey-3;
}

@media (max-width: $phone) {
    .card-title-bar-header {
        margin: 1.6rem 0;

        flex-direction: column;
        align-items: flex-start;
        gap: 1.6rem;
    }

    .card-title-bar-search-field {
        display: none;
    }

    .button-label {
        font-size: 1.4rem;
    }

    .hide-mobile {
        display: none;
    }
}
</style>
