<template>
    <div>
        <div>
            {{ getFormatedValue | empty }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'UserRequirementDateRead',
    props: {
        UserRequirement: {
            type: Object,
            required: true,
        },
    },

    computed: {
        getFormatedValue() {
            return (this.UserRequirement.value) ? this.$Utils.moment(this.UserRequirement.value).format('L') : null;
        },
    },
};
</script>

<style lang='scss' src='./UserRequirementDateEdit.scss' scoped />
