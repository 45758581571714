import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyEntity from './CompanyEntity';

export default class CompanyEntityCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyEntity,
        };
    }
}
