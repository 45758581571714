<template>
    <div>
        <ResultSimpleChoiceItem
            v-for="choice in choices"
            :key="choice.id"
            class="item"
            v-bind="propsToPass(choice)"
        />
    </div>
</template>

<script>
import choiceResultsProps from '../choiceResultsProps';
import ResultSimpleChoiceItem from './ResultSimpleChoiceItem.vue';

export default {
    name: 'ResultsSimpleChoice',
    components: { ResultSimpleChoiceItem },

    mixins: [
        choiceResultsProps,
    ],
};
</script>
<style lang="scss" scoped>
.item:not(:last-child) {
    margin-bottom: 2%;
}
</style>
