import validationFormEventBus from '@/eventBus/validationFormEventBus';

const LIMIT_ANSWERS = 5;

export default {
    data() {
        return {
            limitAnswers: LIMIT_ANSWERS,
            isCollapsed: false,
        };
    },

    computed: {
        isDefaultLangSelected() {
            return this.shared.session.company.company_language.key === this.currentLanguage.key;
        },
    },

    methods: {
        deleteAnswer(index) {
            this.value.splice(index, 1);

            for (let i = index; i < this.value.length; i++) {
                this.value[i].order = i + 1;
            }

            if (this.value.length <= LIMIT_ANSWERS) {
                this.toggleAnswers(false);
            }
        },

        createAnswer() {
            this.value.push({
                order: this.value.length,
                translations: {
                    text: {},
                },
            });
        },

        addAnswer() {
            this.createAnswer();
            this.openAnswers();
        },

        openAnswers() {
            if (this.value.length > LIMIT_ANSWERS) {
                this.toggleAnswers(true);
            }
        },

        toggleAnswers(toggle) {
            this.isCollapsed = toggle;
        },

        toggleIfErrors() {
            const hasErrors = this.value.some((answer, index) => this.errors.has(`${this.fieldNameChoice}-${this.questionIndex}-${index}`));

            if (hasErrors) {
                this.openAnswers();
            }
        },
    },

    beforeDestroy() {
        validationFormEventBus.$off('validate-form', this.toggleIfErrors);
    },

    created() {
        if (this.value.length === 0) {
            this.createAnswer();
        }

        validationFormEventBus.$on('validate-form', this.toggleIfErrors);
    },
};
