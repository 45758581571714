import CompanyUser from '@/models/CompanyUser';

export default {
    props: {
        companyUser: {
            type: CompanyUser,
            required: false,
        },
    },

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: {},
            },
        },
    },

    filters: {
        empty(value) {
            return value || '-';
        },
    },

    computed: {
        isOwnProfile() {
            return this.shared.session.companyUser.id === this.companyUser.id;
        },

        canUpdate() {
            return this.$canUpdate('ModelCompanyUser', { id: this.companyUser.id });
        },

        canShowEntities() {
            return (
                (!this.isOwnProfile && this.canUpdate)
                || (this.isOwnProfile
                    && this.$canRead('AbstractProfileEntities', {
                        company_user_id: this.companyUser.id,
                    })
                )
            );
        },

        canShowPersonalInformation() {
            return (
                this.$canRead('AbstractProfileUserPersonalInformation', { company_user_id: this.companyUser.id })
                || this.$canRead('AbstractProfilePersonal', { company_user_id: this.companyUser.id })
            );
        },

        canModifyEntities() {
            return (
                (this.isOwnProfile
                    && this.$canUpdate('AbstractProfileEntities', {
                        company_user_id: this.companyUser.id,
                    }))
                || (!this.isOwnProfile && this.canUpdate)
            );
        },

        canModifyPersonalInformation() {
            return this.$canUpdate('AbstractProfileUserPersonalInformation', { company_user_id: this.companyUser.id })
                || this.$canRead('AbstractProfilePersonal', { company_user_id: this.companyUser.id });
        },
    },

    methods: {
        async onUpdateUser() {
            try {
                await this.companyUser.save();
            } finally {
                this.buttonState = 'idle';
                this.isEdit = false;
                this.renderKey++;
            }
        },
    },

    data() {
        return {
            buttonState: 'idle',
            isEdit: false,
            renderKey: 0,
        };
    },
};
