<template>
    <div class="chat-notification">
        <template v-if="isDisplay">
            <ChatNotificationItem
                v-for="channel in channels.slice(0, channels.length > limit ? limit - 1 : limit)"
                :key="channel.id"
                :company-chat-channel="channel"
                @click.native="setActive(channel.id)"
            />
            <div
                v-if="channels.length > limit"
                class="chat-notification-more branding-bg"
                @click="openChat()"
            >
                +{{ channels.length-limit+1 }}
            </div>
        </template>
    </div>
</template>

<script>
import ChatNotificationItem from './ChatNotificationItem.vue';

export default {
    name: 'ChatNotification',
    shared: {
        socket: {},
        session: {},
    },
    components: {
        ChatNotificationItem,
    },
    computed: {
        channels() {
            return this.shared.socket.channelCollection.models
                .filter((channel) => channel.company_chat_channel_user.channelUserMe.count > 0)
                .sort((a, b) => new Date(b.last_message_at || b.created_at) - new Date(a.last_message_at || a.created_at));
        },
        limit() {
            return 3;
        },
        isDisplay() {
            const route = this.$route.matched.find((r) => r.name === 'Flow');

            return this.shared.socket.channelCollection && route === undefined;
        },
    },
    methods: {
        openChat() {
            this.shared.socket.chatOpened = true;
        },
        setActive(id) {
            this.shared.socket.chatListChannelComponent.setActive(id).then(() => {
                this.shared.socket.chatOpened = true;
            });
        },
    },
};
</script>

<style lang="scss" scoped src="./ChatNotification.scss" />
