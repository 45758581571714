<template>
    <div v-if="this.loading">
        <HtPageTitle :back-route="{ name: 'SurveyResults' }">
            <t>Surveys results</t>
        </HtPageTitle>

        <HtCard>
            <SurveyFormResults></SurveyFormResults>
        </HtCard>
    </div>
</template>

<script>
import api from '@/store/api';
import HtPageTitle from '@/components/globals/HtPageTitle.vue';
import SurveyFormResults from '@/components/pages/resources/surveys/form/SurveyFormResults.vue';

export default {
    permissions: ['PageSurveyResultDetail'],
    components: {
        SurveyFormResults,
        HtPageTitle,
    },
    props: {
        surveyId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            if (this.surveyId) {
                this.loading = false;
                api.configuration.surveys.get(this.surveyId).then((res) => {
                    this.$store.dispatch('stepperform/setItem', res.data.data);
                    this.loading = true;
                });
            }
        },
    },
};
</script>
