<template>
    <HtTabStepper
        :item.sync="section"
        :tabs="tabs"
    >
        <template #headerRightContent>
            <HtButton
                type="secondary"
                icon-only
                @click.native="onDelete"
            >
                <FontAwesomeIcon
                    :icon="['far', 'trash-alt']"
                    :title="translate('Delete section')"
                />
            </HtButton>
        </template>

        <template #footer>
            <HtButton
                type="secondary"
                :loading="isSaving"
                :disabled="isSaving"
                @click.native="onCancel"
            >
                <t>Cancel</t>
            </HtButton>

            <HtButton
                :loading="isSaving"
                :disabled="isSaving"
                @click.native="onSave"
            >
                <t>Save</t>
            </HtButton>
        </template>
    </HtTabStepper>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import HtTabStepper from '@/layouts/HtTabStepper.vue';
import SectionFormContent from '@/components/settings/EnterprisePage/Form/Section/SectionFormContent.vue';
// import SectionFormTarget from '@/components/settings/EnterprisePage/Form/Section/SectionFormTarget.vue';
import HtButton from '@/components/globals/HtButton.vue';
import api from '@/store/api';
import Modalable from '@/Modalable';

export default {
    name: 'SectionItem',

    components: {
        HtButton,
        HtTabStepper,
    },

    props: {
        pageId: {
            type: Number,
            required: true,
        },

        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isSaving: false,
            section: cloneDeep(this.item),
            tabs: [
                {
                    ref: 'content',
                    title: this.translate('Content'),
                    component: SectionFormContent,
                    disabled: false,
                    noBody: false,
                },
                // {
                //     ref: 'target',
                //     title: this.translate('Target'),
                //     component: SectionFormTarget,
                //     disabled: false,
                //     noBody: false,
                // },
            ],
        };
    },

    methods: {
        onCancel() {
            this.section = cloneDeep(this.item);
        },

        onSave() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.isSaving = true;

                    if (this.item.id) { // update
                        api
                            .settings
                            .enterprisePages
                            .sections
                            .update(this.pageId, this.item.id, this.section)
                            .then((res) => {
                                this.$emit('update:item', res.data.data);

                                this.$Notifier('App')
                                    .showInfo(this.translate('Successfully saved !'));
                            })
                            .finally(() => {
                                this.isSaving = false;
                            });
                    } else { // store
                        api
                            .settings
                            .enterprisePages
                            .sections
                            .store(this.pageId, this.section)
                            .then((res) => {
                                this.$emit('update:item', res.data.data);

                                this.$Notifier('App')
                                    .showInfo(this.translate('Successfully saved !'));
                            })
                            .finally(() => {
                                this.isSaving = false;
                            });
                    }
                }
            });
        },

        onDelete() {
            Modalable
                .openRegisteredWindow('Deletewindow', { content: this.translate('Are you sure you want to delete this section ?') })
                .then((confirmDeletion) => {
                    if (confirmDeletion) {
                        if (this.item.id) {
                            api
                                .settings
                                .enterprisePages
                                .sections
                                .destroy(this.pageId, this.item.id)
                                .then(() => {
                                    this.$emit('on-delete');
                                });
                        } else {
                            this.$emit('on-delete');
                        }
                    }
                });
        },
    },

    watch: {
        item: {
            deep: true,
            handler() {
                this.section = cloneDeep(this.item);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
    .ht-card {
        border: 1px solid #E1E1E1;
        padding: 0;

        ::v-deep .ht-card-title, ::v-deep .ht-card-body {
            padding: 24px;
        }

        ::v-deep .ht-card-body {
            background-color: #FBFBFB;
        }

        ::v-deep .ht-card-footer {
            display: flex;
            justify-content: end;
            gap: 8px;
            padding: 16px 24px;
        }
    }
</style>
