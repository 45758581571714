<template>
    <div
        v-if="companyUserProgramTaskCollection"
        class="box-tabs user-program-resource-status mb-4"
    >
        <div class="container-tabs">
            <ul class="tabs-wrapper">
                <li
                    class="menu-item"
                    :class="{'active': currentTab === 1}"
                    @click="onChangeTab(1)"
                >
                    <t>{{ programLabel }}</t>
                </li>
                <li
                    class="menu-item"
                    :class="{'active': currentTab === 2}"
                    @click="onChangeTab(2)"
                >
                    <t>Elements</t>
                </li>
            </ul>
        </div>
        <div id="userProgramResourceStatusContainer">
            <div v-show="currentTab === 1">
                <UserProgramStatus
                    ref="userProgramStatus"
                    :company_user_id="company_user_id"
                    :company-user-program-id="companyUserProgramId"
                    :company-user-program-task-collection="companyUserProgramTaskCollection"
                    :program="program"
                />
            </div>

            <div v-show="currentTab === 2">
                <UserResourceStatus
                    ref="resourceStatus"
                    :company_user_id="company_user_id"
                    :company-user-program-task-collection="companyUserProgramTaskCollection"
                    :see-more="seeMore"
                />
            </div>
        </div>
        <div
            v-show="currentTab === 2"
            :class="['collapse-button cursor-pointer', {'open': seeMore}]"
            @click.stop="seeMore = !seeMore"
        >
            <t v-show="seeMore">
                See less
            </t>
            <t v-show="!seeMore">
                See more
            </t>
        </div>
    </div>
</template>

<script>
import UserProgramStatus from '@/components/profile/UserProgramStatus.vue';
import UserResourceStatus from '@/components/profile/UserResourceStatus.vue';
import CompanyUserProgramTaskCollection from '@/models/CompanyUserProgramTaskCollection';
import resourceEventBus from '@/eventBus/resourceEventBus';

export default {
    name: 'UserProgramAndResourceStatus',
    components: {
        UserProgramStatus,
        UserResourceStatus,
    },

    props: {
        company_user_id: {
            type: [String, Number],
            required: true,
        },
        companyUserProgramId: {
            type: [String, Number],
            required: true,
        },
        companyUserProgramTaskCollection: {
            type: CompanyUserProgramTaskCollection,
        },
        programLabel: {
            type: String,
            default: '',
        },
        program: {
            type: Object,
            default: () => null,
        },
    },

    data() {
        return {
            currentTab: 1,
            seeMore: false,
        };
    },

    computed: {
        getIcon() {
            return (this.seeMore) ? ['fal', 'chevron-up'] : ['fal', 'chevron-down'];
        },
    },

    created() {
        resourceEventBus.$on('survey-done', () => {
            this.onReload();
        });
    },

    methods: {
        onChangeTab(tab) {
            this.currentTab = tab;
            this.seeMore = false;
        },

        onReload() {
            this.$refs.userProgramStatus.init();
            this.$refs.resourceStatus.init();
        },
    },
};
</script>

<style lang="scss">
	@import '~@/styles/var';

    .user-program-resource-status {
        border-radius: 10px;
        position: relative;

        &-container {
            overflow: hidden;
            max-height: 250px;
        }

        .items-status {
           width: 100%;
        }
        .item-status {
            font-size: 0.95em;
            margin-top: 0.5em;
            margin-bottom: 1.75em;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .item-content {
            color: $color-text-grey;
        }
        .item-top {
            display: flex;
            justify-content: space-between;
        }
        .item-bottom {
            margin-top: 0.5em;
        }
    }

    .collapse-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        padding-bottom: 15px;
        text-align: center;

        >span {
            text-decoration: underline;
        }

        &::after {
            content: '›';
            flex: 0 0 auto;
            position: relative;
            top: 1px;
            display: block;
            margin-left: 7px;
            transform: rotate(90deg);
            transform-origin: 50% 50%;
        }

        &.open {
            &::after {
                transform: rotate(270deg) translate3d(0, -4px, 0);
            }
        }
    }
</style>
