import Model from '@tony.caron/node-model-proxy/Model';
import I18n from '@/modules/i18n/I18n';
import CompanyField from './CompanyField';
import CompanyContractCollection from './CompanyContractCollection';

export default class CompanyContract extends Model {
    modelConfig() {
        return {
            collection: CompanyContractCollection,
        };
    }

    modelDefaultValues() {
        return {
            is_heyteam: false,
            has_probation: false,
        };
    }

    modelCustomAttributes() {
        return {
            name_translated: null,
        };
    }

    modelAccessors() {
        return {
            name_translated: () => (this.is_heyteam ? I18n.translate(this.name) : this.name),
        };
    }

    modelRelations() {
        return {
            customFields: () => this.hasMany(CompanyField),
        };
    }
}
