<template>
    <div v-if="isLoaded">
        <HtFormSwitch
            id="isBusinessDay"
            :value="isBusinessDay"
            :label="translate('Use working days')"
            name="isBusinessDay"
            cypress="is-business-day"
            :disabled="isDisabled"
            @input="$emit('update:is-business-day', $event)"
        />

        <HtKeyDatesInputOrderSelect
            :label="startLabel"
            :tooltip="startTooltip"
            :has-tooltip="startHasTooltip"
            :key-dates="keyDates"
            :offset-key-date="offsetKeyDate"
            :offset-number="offsetAvailabilityNumber"
            :offset-unit="offsetAvailabilityUnit"
            :offset-specific-date="offsetAvailabilitySpecificDate"
            :can-select-specific-date="canSelectSpecificDate"
            :use-working-days="isBusinessDay"
            :dependents="dependents"
            :dependencies="dependencies"
            :filters="filters"
            :program-id="programId"
            :user-program-id="userProgramId"
            :resource-id="resourceId"
            :resource-type="resourceType"
            :disabled="isDisabled"
            :open-select-direction="openSelectDirection"
            @update:offset-key-date="$emit('update:offset-key-date', $event)"
            @update:offset-number="$emit('update:offset-availability-number', $event)"
            @update:offset-unit="$emit('update:offset-availability-unit', $event)"
            @update:offset-specific-date="$emit('update:offset-availability-specific-date', $event)"
            @update:dependents="$emit('update:dependents', $event)"
            @update:dependencies="$emit('update:dependencies', $event)"
        />

        <HtKeyDatesInputOrderSelect
            :label="endLabel"
            :tooltip="endTooltip"
            :has-tooltip="endHasTooltip"
            :key-dates="keyDates"
            :offset-key-date="offsetKeyDate"
            :offset-number="offsetEndNumber"
            :offset-unit="offsetEndUnit"
            :offset-specific-date="offsetEndSpecificDate"
            :can-select-specific-date="canSelectSpecificDate"
            :specific-min-date="offsetAvailabilitySpecificDate"
            :use-working-days="isBusinessDay"
            :program-id="programId"
            :user-program-id="userProgramId"
            :resource-id="resourceId"
            :can-select-key-date="offsetAvailabilityUnit === 'immediately'"
            :has-immediately-unit-option="false"
            :disabled="isDisabled"
            :open-select-direction="openSelectDirection"
            @update:offset-key-date="$emit('update:offset-key-date', $event)"
            @update:offset-number="$emit('update:offset-end-number', $event)"
            @update:offset-unit="$emit('update:offset-end-unit', $event)"
            @update:offset-specific-date="$emit('update:offset-end-specific-date', $event)"
        />
    </div>
</template>

<script>
import I18n from '@/modules/i18n/I18n';
import api from '@/store/api';
import CompanyProgramResourceFilterCollection from '@/models/CompanyProgramResourceFilterCollection';
import HtKeyDatesInputOrderSelect from '@/components/globals/HtKeyDatesInputOrderSelect.vue';
import HtFormSwitch from './HtFormSwitch.vue';

export default {
    name: 'HtKeyDatesInputOrder',

    components: {
        HtKeyDatesInputOrderSelect,
        HtFormSwitch,
    },

    props: {
        startLabel: {
            type: String,
            default: () => I18n.translate('Availability date'),
        },
        startTooltip: {
            type: String,
            default: () => I18n.translate('Date on which the resource will be made available and visible in the program'),
        },
        startHasTooltip: {
            type: Boolean,
            default: true,
        },
        endLabel: {
            type: String,
            default: () => I18n.translate('Completion date'),
        },
        endTooltip: {
            type: String,
            default: () => I18n.translate('The action created in the program has to be done by this date'),
        },
        endHasTooltip: {
            type: Boolean,
            default: true,
        },
        openSelectDirection: {
            type: String,
            default: 'auto',
        },
        programId: {
            type: Number,
            default: null,
        },
        userProgramId: {
            type: Number,
            default: null,
        },
        isBusinessDay: {
            type: Boolean,
            required: true,
        },
        offsetKeyDate: {
            type: Number,
            default: null,
        },
        offsetAvailabilityNumber: {
            type: Number,
            default: 0,
        },
        offsetAvailabilityUnit: {
            type: String,
            default: 'immediately',
        },
        offsetAvailabilitySpecificDate: {
            type: String,
            default: null,
        },
        offsetEndNumber: {
            type: Number,
            default: 0,
        },
        offsetEndUnit: {
            type: String,
            default: 'day_of',
        },
        offsetEndSpecificDate: {
            type: String,
            default: null,
        },
        canSelectSpecificDate: {
            type: Boolean,
            default: false,
        },
        dependents: {
            type: [Object, Array],
            default: () => ([]),
        },
        dependencies: {
            type: [Object, Array],
            default: () => ([]),
        },
        filters: {
            type: [CompanyProgramResourceFilterCollection, Object, Array],
            default: () => ({}),
        },
        resourceId: {
            type: Number,
            default: 0,
        },
        resourceType: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            isLoaded: false,
            userProgramKeyDates: [],
        };
    },

    computed: {
        keyDates() {
            if (this.programId) {
                const program = this.$store.state.programs.programs
                    .find((p) => p.id === this.programId);
                if (program) {
                    return program.key_dates;
                }

                window.console.error(`Cannot find program with id [${this.programId}] in programs store`);
            } else if (this.userProgramId) {
                return this.userProgramKeyDates.map(({ ...keyDate }) => ({
                    id: keyDate.user_program_pivot.id,
                    name: keyDate.name,
                    is_main: keyDate.is_main,
                }));
            }

            return [];
        },

        isDisabled() {
            return !this.isLoaded || this.disabled || !(this.programId || this.userProgramId);
        },
    },

    watch: {
        programId() {
            this.$emit('update:offset-key-date', null);
        },

        keyDates() {
            if (this.offsetKeyDate === null) {
                const keyDate = this.keyDates.find((kd) => kd.is_main);
                if (keyDate) {
                    this.$emit('update:offset-key-date', keyDate.id);
                }
            }
        },
    },

    mounted() {
        if (this.userProgramId) {
            api.userPrograms
                .getProgramKeyDates(this.userProgramId)
                .then((res) => {
                    this.userProgramKeyDates = res.data.data;
                })
                .finally(() => this.isLoaded = true);
        } else {
            this.$store
                .dispatch('programs/fetchPrograms')
                .finally(() => this.isLoaded = true);
        }
    },
};
</script>
