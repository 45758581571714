import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';

import CompanySurveyQuestionAnswerLocale from './CompanySurveyQuestionAnswerLocale';

export default class CompanySurveyQuestionAnswerLocaleCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanySurveyQuestionAnswerLocale,
        };
    }
}
