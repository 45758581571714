<template>
    <div
        @click.stop="$emit('goto')"
        class="survey-navigation-item"
    >
        <div class="survey-navigation-item-header">
            <span v-if="contentIsQuestion"><t>Question</t> {{ number }}</span>
            <span v-else><t>Extra</t></span>

            <div class="survey-navigation-item-header-actions">
                <HtTag
                    v-if="status"
                    :color="statusColor"
                >
                    {{ statusText }}
                </HtTag>

                <HtButton size="small">
                    <FontAwesomeIcon
                        icon="arrow-right"
                        class="icon-times"
                    />
                </HtButton>
            </div>
        </div>

        <div class="survey-navigation-item-content">
            <template v-if="contentIsQuestion">
                <span>{{ localize(content.question.template.translations.text) }}</span>

                <component
                    class="mt-10"
                    :is="contentHistoryComponent.name"
                    v-bind="contentHistoryComponent.props"
                    :answers="content.question.answers"
                    :templateAnswers="content.question.template.answers"
                    is-navigation
                />
            </template>
        </div>
    </div>
</template>

<script>
import HtTag from '@/components/globals/HtTag.vue';
import HtButton from '@/components/globals/HtButton.vue';
import CompanySurveyContent from '@/models/CompanySurveyContent';
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import SurveyChoiceHistory from '@/components/resources/user/surveys/questions/Choice/SurveyChoiceHistory.vue';
import SurveyTextHistory from '@/components/resources/user/surveys/questions/Text/SurveyTextHistory.vue';
import SurveyFileUploadHistory from '@/components/resources/user/surveys/questions/FileUpload/SurveyFileUploadHistory.vue';
import SurveySatisfactionScaleHistory from '@/components/resources/user/surveys/questions/SatisfactionScale/SurveySatisfactionScaleHistory.vue';

export default {
    name: 'UserSurveyNavigationItem',

    components: {
        HtButton,
        HtTag,
    },

    props: {
        content: {
            type: Object,
            required: true,
        },

        number: {
            type: Number,
            required: true,
        },

        mandatoryQuestionWithoutAnswers: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        contentHistoryComponent() {
            if (!this.contentIsQuestion) {
                return null;
            }

            switch (this.content.question.template.type) {
            case CompanySurveyQuestion.TYPE_YES_NO:
            case CompanySurveyQuestion.TYPE_SIMPLE_CHOICE:
            case CompanySurveyQuestion.TYPE_MULTIPLE_CHOICE:
                return {
                    name: SurveyChoiceHistory,
                    props: {},
                };
            case CompanySurveyQuestion.TYPE_TEXT:
                return {
                    name: SurveyTextHistory,
                    props: {},
                };
            case CompanySurveyQuestion.TYPE_FILE_UPLOAD:
                return {
                    name: SurveyFileUploadHistory,
                    props: {},
                };
            case CompanySurveyQuestion.TYPE_SATISFACTION_SCALE:
                return {
                    name: SurveySatisfactionScaleHistory,
                    props: {
                        isSmileySubType: this.content.question.template.subtype === CompanySurveyQuestion.SUBTYPE_SATISFACTION_SCALE_SMILEY,
                    },
                };
            }
        },

        contentIsQuestion() {
            return this.content.type === CompanySurveyContent.TYPE_QUESTION;
        },

        status() {
            if (!this.contentIsQuestion) {
                return null;
            }

            if (this.content.question.answers.length > 0) {
                return 'answered';
            }

            if (this.content.question.template.is_mandatory) {
                if (this.mandatoryQuestionWithoutAnswers.find((c) => c.template.id === this.content.template.id)) {
                    return 'mandatory_error';
                }

                return 'mandatory';
            }

            return null;
        },

        statusColor() {
            switch (this.status) {
            case 'answered':
                return 'green';
            case 'mandatory':
                return 'blue';
            case 'mandatory_error':
                return 'red';
            }
        },

        statusText() {
            switch (this.status) {
            case 'answered':
                return this.translate('Answered');
            case 'mandatory':
            case 'mandatory_error':
                return this.translate('Mandatory');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import '~@/styles/design-system-colors.scss';

.survey-navigation-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border: 1px solid $neutral-300;
    border-radius: 8px;
    cursor: pointer;

    &-header {
        display: flex;
        justify-content: space-between;

        span {
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            color: $neutral-900;
        }

        &-actions {
            display: flex;
            gap: 8px;

            .ht-button {
                background-color: $neutral-100;
                border: 1px solid $neutral-300;
                border-radius: 4px;
                color: $neutral-900;
                align-items: center;

                svg {
                    margin: 0 2px;
                    width: 8px;
                }
            }
        }
    }

    &-content {
        span {
            font-size: 14px;
            line-height: 24px;
            color: $neutral-800;
        }
    }
}
</style>
