<template>
    <div class="h-100">
        <HtCard
            v-if="isLoaded"
            class="mb-5"
        >
            <template #title>
                <HtCardTitleBar
                    :title="title"
                    :show-button="true"
                    :button-text="translate('Add')"
                    @search="value => search = value"
                    @onAdd="onAdd"
                />
            </template>
            <template #default>
                <div class="mb-5 mt-2">
                    <t>All custom fields will be displayed on the user "Profile" page.</t>
                </div>
                <HtTable
                    :items="items"
                    :columns="columns"
                    :external-search="search"
                    :empty-message="'No custom fields...'"
                    @onCellClicked="onCellClicked"
                />
            </template>
        </HtCard>
        <CustomFieldEditModal
            ref="modal"
            @on-close="onClose"
        />
    </div>
</template>

<script>
import HtCard from '../../../components/globals/HtCard.vue';
import HtTable from '../../../components/globals/HtTable.vue';
import HtCardTitleBar from '../../../components/globals/HtCardTitleBar.vue';
import CompanyFieldTemplateCollection from '../../../models/CompanyFieldTemplateCollection';
import CustomFieldEditModal from '../../../components/pages/settings/customFields/CustomFieldEditModal.vue';

export default {
    components: {
        HtCard,
        HtTable,
        HtCardTitleBar,
        CustomFieldEditModal,
    },

    data() {
        return {
            templates: new CompanyFieldTemplateCollection([
                'id',
                'name',
                'type',
            ]),
            search: null,
        };
    },

    computed: {
        title() {
            return this.translate('{count} custom field | {count} custom fields', {
                count: this.templates.models.length,
            });
        },
        isLoaded() {
            return this.templates.isLoaded();
        },
        items() {
            return this.templates.models.map((template) => ({
                id: template.id,
                name: template.name,
                type: this.translate(template.type),
            }));
        },
        columns() {
            return [
                {
                    key: 'name',
                    label: this.translate('Name'),
                    search: true,
                },
                {
                    key: 'type',
                    label: this.translate('Type'),
                    search: true,
                },
            ];
        },
    },

    created() {
        this.templates.get();
    },

    methods: {
        onCellClicked(row) {
            this.$refs.modal.open(row.item.id);
        },
        onAdd() {
            this.$refs.modal.open();
        },
        onClose() {
            this.templates.get();
        },
    },
};
</script>
