<template>
    <div class="question-completion">
        <div class="list-answer">
            <div
                v-for="choice in question.template.answers"
                class="list-answer-item"
                :class="{'selected': choiceIsSelected(choice), 'read-only': isReadOnly}"
                @click="selectChoice(choice)"
            >
                <span
                    v-show="choiceIsSelected(choice)"
                    class="list-answer-item-icon"
                >
                    <FontAwesomeIcon
                        icon="check-circle"
                    />
                </span>

                {{ localize(choice.translations.text) }}
            </div>
        </div>

        <SurveyAnswerHistory
            v-if="hasPreviousOccurrenceSection"
        >
            <SurveyChoiceHistory
                :answers="previousOccurrence.question.answers"
                :template-answers="question.template.answers"
            />
        </SurveyAnswerHistory>
    </div>
</template>

<script>
import SurveyQuestionMixin from '@/components/resources/user/surveys/questions/SurveyQuestionMixin';
import SurveyAnswerHistory from '@/components/resources/user/surveys/questions/SurveyAnswerHistory.vue';
import SurveyChoiceHistory from '@/components/resources/user/surveys/questions/Choice/SurveyChoiceHistory.vue';

export default {
    name: 'SurveyChoiceCompletion',

    components: {
        SurveyAnswerHistory,
        SurveyChoiceHistory,
    },

    mixins: [
        SurveyQuestionMixin,
    ],

    props: {
        isMultiple: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        selectChoice(choice) {
            if (this.isReadOnly) {
                return;
            }

            this.question.is_edited = true;

            if (!this.isMultiple) {
                this.$emit('input', [
                    {
                        id: this.value[0]?.id || null,
                        company_survey_question_answer_id: choice.id,
                    },
                ]);
            } else {
                let values = [...this.value];

                if (!this.value.find((v) => v.company_survey_question_answer_id === choice.id)) {
                    values.push({
                        id: null,
                        company_survey_question_answer_id: choice.id,
                    });
                } else {
                    values = values.filter((v) => v.company_survey_question_answer_id !== choice.id);
                }

                this.$emit('input', values.map((answer) => {
                    answer.id = null;

                    return answer;
                }));
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../SurveyQuestion';

.question-completion {
    .list-answer {
        &-item {
            &-icon {
                color: var(--branding-color);
            }
        }
    }
}
</style>
