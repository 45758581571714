<template>
    <div
        class="ht-status"
        :class="status"
    >
        <span
            class="status"
            :class="status"
        />
        <span :class="status">{{ label }}</span>
    </div>
</template>

<script>
export default {
    props: {
        status: {
            required: true,
            validator: (value) => ['success', 'warning', 'danger', 'neutral'].includes(value),
        },
        label: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.ht-status {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    border-radius: 10rem;
    border-width: 0.1rem;
    border-style: solid;
    padding: 0.4rem 0.8rem;
    font-size: 1.2rem;
    line-height: 1.2rem;
    box-sizing: border-box;
}

.status {
    display: block;
    border-radius: 50%;
    /* Chrome doesn't respect the specified width when the parent flex is on nowrap and needs some space, but it respects the padding */
    padding: 0.4rem;
}

.status.success {
    background-color: #3fbc95;
}
.status.warning {
    background-color: #ffb858;
}
.status.danger {
    background-color: #ff504f;
}
.status.neutral {
    background-color: #d8d8d8;
}

.ht-status.success {
    background: #def8f0;
    border-color: #5bddb4;
    color: #3fbc95;
}
.ht-status.warning {
    background: #fff1dd;
    border-color: #ffb858;
    color: #d89741;
}
.ht-status.danger {
    background: #fedadf;
    border-color: #f7485e;
    color: #ff504f;
}
.ht-status.neutral {
    background: white;
    border-color: #6c757d;
    color: #6c757d;
}
</style>
