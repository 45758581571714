<template>
    <div class="pocmodal">
        <div class="content">
            <div class="title">
                {{ title }}
            </div>
            <div
                class="description"
                v-html="description"
            />
            <div class="component">
                <slot />
            </div>
        </div>
        <div class="buttons">
            <HtButton
                type="secondary"
                @click.native="cancel"
            >
                <t>{{ cancelLabel }}</t>
            </HtButton>
            <HtButton
                v-if="acceptLabel"
                type="primary"
                @click.native="accept"
            >
                <t>{{ acceptLabel }}</t>
            </HtButton>
        </div>
    </div>
</template>
<script>
import HtButton from '../../HtButton.vue';

export default {
    name: 'CentralConfirmation',
    components: { HtButton },
    inject: ['$validator'],
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        cancelLabel: {
            type: String,
            required: true,
        },
        acceptLabel: {
            type: String,
            required: false,
        },
    },
    methods: {
        accept() {
            this.$emit('accept');
        },
        cancel() {
            this.$emit('cancel');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
.pocmodal {
    padding: 40px;
    & .content {
        flex-grow: 1;
        line-height: 24px;
        & .title {
            color: $neutral-black;
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 8px;
        }
        & .description {
            color: $neutral-800;
            font-size: 14px;
        }
        & .component {
            padding-top: 2.4rem;
            padding-bottom: 2.4rem;
        }
    }
    & .buttons {
        display: flex;
        gap: 16px;

        button {
            flex-grow: 1;
        }
    }
}
</style>
