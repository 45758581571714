import Model from '@tony.caron/node-model-proxy/Model';
import CompanyProgramEntityCollection from '@/models/CompanyProgramEntityCollection';
import CompanyProgram from '@/models/CompanyProgram';
import CompanyEntity from '@/models/CompanyEntity';

export default class CompanyProgramEntity extends Model {
    modelConfig() {
        return {
            collection: CompanyProgramEntityCollection,
        };
    }

    modelRelations() {
        return {
            program: () => this.belongsTo(CompanyProgram, 'company_program_id', 'id'),
            entity: () => this.belongsTo(CompanyEntity, 'company_entity_id', 'id'),
        };
    }
}
