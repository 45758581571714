export default {

    data() {
        return {
            hasConditionalBlock: true,
            cbPropertyChoosen: '',
            cbValueChoosen: '',
            cbPropertiesValues: [],
            cbAllowedProperty: [
                'user_civility',
                'company_name',
                'program_name',
                'entity_company_office',
                'entity_company_office_address',
                'entity_company_contract',
                'entity_company_department',
                'entity_company_job_position',
            ],
            // At present, this array is the same as cbAllowedProperty,
            // but it will be needed to add keydates, which will not be selects!
            cbPropertiesSelect: [
                'user_civility',
                'company_name',
                'program_name',
                'entity_company_office',
                'entity_company_office_address',
                'entity_company_contract',
                'entity_company_department',
                'entity_company_job_position',
            ],
        };
    },

    methods: {
        // Our custom function for setting up the Web Component.
        // https://developer.mozilla.org/en-US/docs/Web/Web_Components
        setupWebComponent(component, win, doc, editor) {
            // the shadow root gets it's HTML content from the template element.
            // We do not need to inject the template element into the content,
            // we can simply create it in memory and attach it to the shadow root
            const template = doc.createElement('template');

            template.innerHTML = `
                <style>
                    /* The host selector targets the shadow DOM host element
                    * https://developer.mozilla.org/en-US/docs/Web/CSS/:host() */
                    :host {
                        display: block; /* Required to get block behavior inside TinyMCE */
                        background-color: rgba(240, 210, 140, .20);
                        border-radius: 6px;
                    }

                    header {
                        display: flex;
                        padding: 4px 6px;
                        margin: 0;
                        background-color: rgba(240, 210, 140, .20);
                        border-radius: 6px 6px 0 0;
                    }

                    header p {
                        margin: 0;
                        line-height: 24px;
                        font-size: 14px;
                        color: #B7974C;
                    }

                    header > svg {
                        fill: #B7974C;
                        margin-right: 6px;
                    }

                    span#property {
                        font-weight: bold;
                    }

                    span#value {
                        font-weight: bold;
                    }

                    button {
                        background: rgba(240, 210, 140, .5);
                        border: 0;
                        outline: 0;
                        -webkit-tap-highlight-color: rgba(0,0,0,0);
                        -webkit-user-select: none;
                        user-select: none;
                        font-weight: normal;
                        padding: 6px;
                        margin: 0 0 0 10px;
                        border-radius: 6px;
                    }

                    button svg {
                        fill: #B7974C;
                        display: block;
                    }

                    button:hover {
                        background-color: rgba(240, 210, 140, .75);
                    }

                    .content {
                        margin: 0 6px;
                        box-sizing: border-box;
                        padding-bottom: 2px;
                    }
                </style>

                <header>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M19 4a2 2 0 1 1-1.854 2.751L15 6.75c-1.239 0-1.85.61-2.586 2.31l-.3.724c-.42 1.014-.795 1.738-1.246 2.217.406.43.751 1.06 1.12 1.92l.426 1.018c.704 1.626 1.294 2.256 2.428 2.307l.158.004h2.145a2 2 0 1 1 0 1.501L15 18.75l-.219-.004c-1.863-.072-2.821-1.086-3.742-3.208l-.49-1.17c-.513-1.163-.87-1.57-1.44-1.614L9 12.75l-2.146.001a2 2 0 1 1 0-1.501H9c.636 0 1.004-.383 1.548-1.619l.385-.92c.955-2.291 1.913-3.382 3.848-3.457L15 5.25h2.145A2 2 0 0 1 19 4z" fill-rule="evenodd"/></svg>

                    <p>${component.translate('Show block if')} <span id="property"></span>&nbsp;<span id="operator">&nbsp;</span>&nbsp;<span id="value"></span></p>

                    <button type="button" id="btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"><path d="M0 9.502v2.5h2.5l7.373-7.374-2.5-2.5L0 9.502zm11.807-6.807c.26-.26.26-.68 0-.94l-1.56-1.56a.664.664 0 0 0-.94 0l-1.22 1.22 2.5 2.5 1.22-1.22z"/></svg>
                    </button>
                </header>
                <div class="content">
                    <slot></slot>
                </div>
            `;

            class ConditionalBlock extends win.HTMLElement {
                constructor() {
                    super();

                    // During the creation of the web component we set contenteditable false on the web
                    // component to make it behave like a noneditable-but-selectable element inside TinyMCE.
                    this.setAttribute('contenteditable', false);

                    // Attach the shadow DOM to the element
                    // https://developer.mozilla.org/en-US/docs/Web/API/Element/attachShadow
                    this.attachShadow({ mode: 'open' });

                    // Attach the html template to the web components shadow DOM
                    this.shadowRoot.appendChild(
                        template.content.cloneNode(true),
                    );
                }

                connectedCallback() {
                    // Make the content within <conditional-block> editable by wrapping the content in a <div> with contenteditable on it.
                    const cleanupContentEditable = () => {
                        if (this.firstChild.contentEditable !== 'true') {
                            const editableWrapper = document.createElement('div');
                            editableWrapper.setAttribute(
                                'contenteditable',
                                true,
                            );

                            while (this.firstChild) {
                                editableWrapper.appendChild(this.firstChild);
                            }

                            this.appendChild(editableWrapper);
                        }
                    };
                    cleanupContentEditable();

                    // Open the edit dialog
                    const editConditionalBlock = () => {
                        component.dialogManager(this, editor);
                        return false;
                    };
                    this.shadowRoot
                        .getElementById('btn')
                        .addEventListener('click', editConditionalBlock);
                }

                // Everytime a custom element's attributes is added, changed or removed
                // the `attributeChangedCallback` method is invoked. Which attributes are
                // observed is defined by the `observedAttributes` method.
                attributeChangedCallback(name, oldValue, newValue) {
                    if (name === 'data-property') {
                        this.shadowRoot.getElementById('property').textContent = component.getCbPropertyByValue(newValue).text;
                    } else if (name === 'data-operator') {
                        this.shadowRoot.getElementById('operator').textContent = component.getCbOperatorsByValue(newValue).text;
                    } else if (name === 'data-value') {
                        this.shadowRoot.getElementById('value').textContent = newValue;
                    }
                }

                static get observedAttributes() {
                    return ['data-property', 'data-operator', 'data-value'];
                }
            }

            // Register our web component to the tag we want to use it as
            // https://developer.mozilla.org/en-US/docs/Web/API/CustomElementRegistry/define
            win.customElements.define('conditional-block', ConditionalBlock);
        },

        // Custom function that manages the Insert/edit dialog
        dialogManager(conditionalBlock, editor) {
            // Open a TinyMCE modal where the user can set the badge's background and text color.
            // https://www.tiny.cloud/docs/ui-components/dialog/
            if (conditionalBlock) {
                this.cbPropertyChoosen = conditionalBlock.dataset.property.replace(/{|}/g, '');
                this.cbValueChoosen = conditionalBlock.dataset.operator;
            } else {
                this.cbPropertyChoosen = this.cbProperties[0].value.replace(/{|}/g, '');
                this.cbValueChoosen = 'is_equal';
            }
            editor.windowManager.open(this.getDialogManagerBody(conditionalBlock, editor));
        },

        getDialogManagerBody(conditionalBlock, editor) {
            return {
                title: this.translate('Conditional block'),
                body: {
                    type: 'panel',
                    items: this.getConditionalBlockConfigurations,
                },
                buttons: [
                    {
                        type: 'cancel',
                        name: 'closeButton',
                        text: 'Cancel',
                    },
                    {
                        type: 'submit',
                        name: 'submitButton',
                        text: 'Save',
                        primary: true,
                    },
                ],
                initialData: {
                    property: conditionalBlock
                        ? conditionalBlock.dataset.property
                        : `{${this.cbPropertyChoosen}}`,
                    operator: conditionalBlock
                        ? conditionalBlock.dataset.operator
                        : this.cbValueChoosen,
                    value: conditionalBlock
                        ? conditionalBlock.dataset.value
                        : '',
                },
                onChange: (dialogApi, details) => {
                    if (details.name === 'property' || details.name === 'operator') {
                        const data = dialogApi.getData();
                        this.cbPropertyChoosen = data.property.replace(/{|}/g, '');
                        this.cbValueChoosen = data.operator;

                        // If switching from 'is_filled'/'is_not_filled' to 'is_equal'/'is_not_equal', ensure value is reset
                        if (details.name === 'operator' && (data.operator === 'is_equal' || data.operator === 'is_not_equal')) {
                            if (!data.value) {
                                // Set the value field to an empty string if it was missing
                                dialogApi.setData({ value: '' });
                            }
                        }

                        dialogApi.redial(this.getDialogManagerBody(null, editor));
                    }
                },

                onSubmit: (dialog) => {
                    const data = dialog.getData();

                    const variable = this.variables.find((item) => item.key === data.property.slice(1, -1));
                    if (!this.dynamicDocument.variables.find((v) => v.key === variable.key)) {
                        this.dynamicDocument.variables.push(variable);
                    }

                    // Check if a block is edited or a new block is to be inserted
                    if (!conditionalBlock) {
                        // Insert content at the location of the cursor.
                        let insertContent = `
                            <conditional-block data-property="${data.property}" data-operator="${data.operator}"`;
                        if (data.operator !== 'is_filled' && data.operator !== 'is_not_filled') {
                            insertContent += ` data-value="${data.value}"`;
                        }
                        insertContent += `>
                                <p>${this.translate('Write conditional text here')}</p>
                            </conditional-block>`;
                        editor.insertContent(insertContent);
                    } else {
                        // Working directly with the DOM often requires manually adding the actions to the undo stack.
                        editor.undoManager.transact(() => {
                            // Update the data-attributes on the conditional-block element
                            conditionalBlock.dataset.property = data.property;
                            conditionalBlock.dataset.operator = data.operator;
                            if (data.operator !== 'is_filled' && data.operator !== 'is_not_filled') {
                                conditionalBlock.dataset.value = data.value;
                            } else {
                                delete conditionalBlock.dataset.value;
                            }
                        });

                        // Tell TinyMCE that the ui has been updated.
                        editor.nodeChanged();
                    }

                    dialog.close();
                },
            };
        },

        getCbPropertyByValue(value) {
            return this.cbProperties.find((item) => item.value === value);
        },

        getCbOperatorsByValue(value) {
            return this.cbOperators.find((item) => item.value === value);
        },
    },

    computed: {
        setup() {
            return (editor) => {
                // Instead of defining a custom icon pack, we can register individual icons using the API.
                editor.ui.registry.addIcon(
                    'conditional-block',
                    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M19 4a2 2 0 1 1-1.854 2.751L15 6.75c-1.239 0-1.85.61-2.586 2.31l-.3.724c-.42 1.014-.795 1.738-1.246 2.217.406.43.751 1.06 1.12 1.92l.426 1.018c.704 1.626 1.294 2.256 2.428 2.307l.158.004h2.145a2 2 0 1 1 0 1.501L15 18.75l-.219-.004c-1.863-.072-2.821-1.086-3.742-3.208l-.49-1.17c-.513-1.163-.87-1.57-1.44-1.614L9 12.75l-2.146.001a2 2 0 1 1 0-1.501H9c.636 0 1.004-.383 1.548-1.619l.385-.92c.955-2.291 1.913-3.382 3.848-3.457L15 5.25h2.145A2 2 0 0 1 19 4z" fill-rule="evenodd"/></svg>',
                );

                // The preinit event is fired after the editor is loaded but before the content is loaded
                // https://www.tiny.cloud/docs/advanced/events/#editorcoreevents
                editor.on('PreInit', () => {
                    // Get the iframe window object and the iframes document object and call our setup function that creates the web component
                    const win = editor.getWin();
                    const doc = editor.getDoc();
                    this.setupWebComponent(this, win, doc, editor);

                    // During the creation of the web component we set contenteditable false
                    // on the web component to make it behave like a noneditable but selectable
                    // element inside TinyMCE. But we don't want the contenteditable attribute
                    // to be saved with the content. We therefore need to filter out the attribute
                    // upon serlialization (which happens on "save", view sourcecode and preview
                    // among others).
                    editor.serializer.addNodeFilter(
                        'conditional-block',
                        (nodes) => {
                            // Iterate through all filtered nodes and remove the contenteditable attribute
                            // as well as the wrapper <div> we add when creating the custom element
                            nodes.forEach((node) => {
                                if (node.attr('contenteditable')) {
                                    // Remove the contenteditable attribute on the <conditional-block>
                                    node.attr('contenteditable', null);
                                    // Traverse into the first child which would be the <div contenteditable="true">
                                    // and use unwrap to remove the <div> but keep the children.
                                    node.firstChild.unwrap();
                                }
                            });
                        },
                    );
                });

                // Register a custom toolbar menu button to insert the conditional block
                // https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#menubutton
                editor.ui.registry.addButton('conditionalblock', {
                    icon: 'conditional-block',
                    tooltip: this.translate('Insert conditional block'),
                    onAction: () => {
                        this.dialogManager(null, editor);
                    },
                });
            };
        },

        getConditionalBlockConfigurations() {
            const isValueFieldVisible = (operator) => operator !== 'is_filled' && operator !== 'is_not_filled';

            return [
                {
                    type: 'selectbox',
                    name: 'property',
                    label: this.translate('Property'),
                    items: this.cbProperties,
                },
                {
                    type: 'selectbox',
                    name: 'operator',
                    label: this.translate('Operator'),
                    items: this.cbOperators,
                },
                ...(isValueFieldVisible(this.cbValueChoosen) ? [this.cbValue] : []),
            ];
        },

        cbProperties() {
            return this.variables
                .filter((item) => this.cbAllowedProperty.includes(item.key) || item.key.includes('entity_') || item.key.includes('requirement_'))
                .map((item) => ({
                    value: `{${item.key}}`,
                    text: this.translate(item.label),
                })).sort((a, b) => a.text.localeCompare(b.text));
        },

        cbOperators() {
            return [
                {
                    value: 'is_equal',
                    text: this.translate('is equal to'),
                },
                {
                    value: 'is_not_equal',
                    text: this.translate('is not equal to'),
                },
                {
                    value: 'is_filled',
                    text: this.translate('is filled'),
                },
                {
                    value: 'is_not_filled',
                    text: this.translate('is not filled'),
                },
            ];
        },

        cbValue() {
            if (this.cbPropertyChoosen === '') {
                this.cbPropertyChoosen = this.cbProperties[0].value.replace(/{|}/g, '');
            }

            if (this.cbPropertyChoosen.includes('requirement_')) {
                let requirementType = '';

                Object.keys(this.categories.requirement).forEach((categoryName) => {
                    this.categories.requirement[categoryName].forEach((requirement) => {
                        if (requirement.key === this.cbPropertyChoosen) {
                            requirementType = requirement.meta_data.company_requirement_type;
                        }
                    });
                });

                if (requirementType === 'file') {
                    return {
                        type: 'selectbox',
                        name: 'value',
                        label: this.translate('Value'),
                        placeholder: this.translate('Value'),
                        items: this.cbPropertiesValues.requirement_type_file,
                    };
                }
            }

            if (this.cbPropertiesSelect.includes(this.cbPropertyChoosen) || this.cbPropertyChoosen.includes('entity_')) {
                return {
                    type: 'selectbox',
                    name: 'value',
                    label: this.translate('Value'),
                    placeholder: this.translate('Value'),
                    items: this.cbPropertiesValues[this.cbPropertyChoosen],
                };
            }

            return {
                type: 'input',
                name: 'value',
                label: this.translate('Value'),
                placeholder: this.translate('Value'),
            };
        },
    },
};
