/* eslint-disable class-methods-use-this */

/**
 * @property {HtProgramType[]} programTypes
 * @property {HtProgramKeyDate[]} keyDates
 * @property {Object} roles
 * @property {int} employeeDepartmentGroupId
 */
export default class AbstractTemplateProvider {
    constructor(programTypes, keyDates, roles, employeeDepartmentGroupId) {
        if (this.constructor === AbstractTemplateProvider) {
            throw new TypeError('Abstract class "AbstractTemplateProvider" cannot be instantiated directly');
        }

        this.programTypes = programTypes;
        this.keyDates = keyDates;
        this.roles = roles;
        this.employeeDepartmentGroupId = employeeDepartmentGroupId;
        this.programData = {};
    }

    get baseProgramData() {
        return {};
    }

    get actions() {
        return [];
    }

    get emails() {
        return [];
    }

    get events() {
        return [];
    }

    /**
     *
     * @returns {Object} the program data
     */
    build() {
        this.addBasics();
        this.addActions();
        this.addEmails();
        this.addEvents();

        return this.programData;
    }

    addBasics() {
        this.programData = this.baseProgramData;
    }

    addActions() {
        this.programData.actions = this.actions.filter(
            (task) => task.resource.programs[0].participants.every(
                (participant) => participant.company_role_id,
            ),
        );
    }

    addEmails() {
        this.programData.emails = this.emails.filter(
            (mail) => mail.sender_role_id,
        );
    }

    addEvents() {
        this.programData.events = this.events.filter(
            (e) => e.organiser_role.id
            && e.resource.programs[0].participants.every(
                (participant) => participant.company_role_id,
            ),
        );
    }
}
