import Model from '@tony.caron/node-model-proxy/Model';
import Company from './Company';
import CompanyUser from './CompanyUser';
import CompanySurvey from './CompanySurvey';
import CompanyUserSurveySupervisor from './CompanyUserSurveySupervisor';
import CompanyUserSurveyQuestion from './CompanyUserSurveyQuestion';
import CompanyUserSurveyCollection from './CompanyUserSurveyCollection';
import CompanyUserProgram from './CompanyUserProgram';
import CompanyUserProgramTask from './CompanyUserProgramTask';

export default class CompanyUserSurvey extends Model {
    static STATUS_DONE = 'done';

    modelConfig() {
        return {
            collection: CompanyUserSurveyCollection,
        };
    }

    modelCustomAttributes() {
        return {
            active: false,
            company_user_survey_participant: [],
            watchers: [],
            name: '',
        };
    }

    modelOutConvertor() {
        return {
            company_user_survey_participant: (n) => ((n.length > 0) ? n.map((participant) => ({ company_user_participant_id: participant.company_user_id })) : []),
        };
    }

    modelRelations() {
        return {
            companyUserProgram: () => this.belongsTo(CompanyUserProgram, 'company_user_program_id', 'id'),
            company: () => this.belongsTo(Company, 'company_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyUserParticipant: () => this.belongsTo(CompanyUser, 'company_user_participant_id', 'id'),
            companyUserSurveySupervisor: () => this.hasMany(CompanyUserSurveySupervisor, 'company_user_survey_id', 'id'),
            companySurvey: () => this.belongsTo(CompanySurvey, 'company_survey_id', 'id'),
            companyUserSurveyQuestion: () => this.hasMany(CompanyUserSurveyQuestion, 'company_user_survey_id', 'id'),
            companyUserProgramTask: () => this.belongsTo(CompanyUserProgramTask, 'resource_id', 'id'),
        };
    }

    modelEvents() {
        return {
            saved: () => {},
            updated: () => {},
        };
    }

    modelAccessors() {
        return {
            name: () => {
                if (!this.company_survey || !this.company_user?.company_language) return '';
                return this
                    .company_survey.resource.translations
                    .name[this.company_user.company_language.key]
                    ?? this.company_survey.resource.name;
            },
        };
    }
}
