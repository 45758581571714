import axios from 'axios';
import { config } from '@/helpers/env';
import { getCsrfToken, updateCsrfToken } from '@/helpers/csrf';
import Utils from '@/Utils';

export default class API {
    static instance = {};

    constructor(_options = {}) {
        const options = {
            name: '',
            base_url: '',
            ..._options,
        };

        if (!options.name.length) throw "name can't be empty";

        if (undefined === API.instance[options.name]) {
            if (!options.base_url.length) throw "base_url can't be empty";

            // eslint-disable-next-line new-cap
            API.instance[options.name] = new api_create(options);
        }

        return API.instance[options.name];
    }

    static getInstance(name) {
        if (undefined === API.instance[name]) throw `Instance ${name} doesn't exists`;

        return API.instance[name];
    }

    static install(Vue, options) {
        API.Vue = Vue;

        // Set default connection from main.js
        const _api = new API(options);

        Vue.mixin({

            data() {
                return {

                    API: {
                        get: (...arg) => this.apiCall('get', arg),
                        post: (...arg) => this.apiCall('post', arg),
                        put: (...arg) => this.apiCall('put', arg),
                        delete: (...arg) => this.apiCall('delete', arg),
                        getInstance: (...arg) => API.getInstance(arg),
                    },
                };
            },

            methods: {
                apiCall(fname, arg) {
                    _api[fname](arg[0], arg[1] || undefined, arg[2] || undefined, this);
                },
            },
        });
    }
}

export function api_create(options = {}) {
    const _ = this;

    _.client = axios.create({
        baseURL: options.base_url,
        withCredentials: true,
        validateStatus: () => true,
        headers: {
            'Cache-Control': 'no-cache',
            pragma: 'no-cache',
            'cache-control': 'no-store',
            'x-front-version': config('VERSION'),
        },
    });

    _.client.interceptors.request.use(
        (config) => {
            config.headers['X-XSRF-TOKEN'] = getCsrfToken();
            return config;
        },
        (error) => Promise.reject(error),
    );

    return {

        catchReturnCodeCallback: {},
        queryId: 0,

        callInterceptor: [],
        responseInterceptor: [],

        addHeader(header) {
            _.client.defaults.headers.common = Object.assign(_.client.defaults.headers.common, header);
        },

        get(path, _parameter, _callBack, _caller) {
            const method = 'get';

            const { parameter, callBack, caller } = this._cleanUserEntry(_parameter, _callBack, _caller);

            Object.keys(parameter).map((v) => {
                path = path.replace(`{${v}}`, parameter[v]);
            });

            return this._apiCall(path, method, parameter, callBack, caller);
        },

        post(path, _parameter, _callBack, _caller) {
            const method = 'post';

            const { parameter, callBack, caller } = this._cleanUserEntry(_parameter, _callBack, _caller);

            return this._apiCall(path, method, parameter, callBack, caller);
        },

        put(path, _parameter, _callBack, _caller) {
            const method = 'put';

            const { parameter, callBack, caller } = this._cleanUserEntry(_parameter, _callBack, _caller);

            return this._apiCall(path, method, parameter, callBack, caller);
        },

        delete(path, _parameter, _callBack, _caller) {
            const method = 'delete';

            const { parameter, callBack, caller } = this._cleanUserEntry(_parameter, _callBack, _caller);

            return this._apiCall(path, method, parameter, callBack, caller);
        },

        // Allow to get callback if no parameters requested
        _cleanUserEntry(_parameter, _callBack, _caller) {
            let hasParameter = true;

            const callBack = (() => {
                if (undefined !== _callBack) return _callBack;
                if (undefined === _parameter) return {};

                hasParameter = false;
                return _parameter;
            })();
            const parameter = undefined === _parameter || !hasParameter ? {} : _parameter;
            const caller = undefined === _caller ? _callBack : _caller;

            return { callBack, parameter, caller };
        },

        _apiCall(resource, method, parameter, callBack, caller) {
            const url = `${options.base_url}/${resource}`;

            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();
            ++this.queryId;

            const responsePromise = { response: '' };

            this._callCallInterceptorCallback({
                id: this.queryId, api: this, caller, callBack, resource, method, parameter, response: responsePromise,
            });

            _.client({
                url,
                method,
                data: parameter,
                id: 1,
                startTime: Date.now(),
                duration: 0,
                onUploadProgress: (progressEvent) => this._callUserOnProgressCallback(progressEvent, callBack),
                cancelToken: source.token,
            })
                .then((response) => {
                    const csrfToken = Utils.getCsrfTokenFromHeaders(response.headers);

                    if (csrfToken) {
                        updateCsrfToken(csrfToken);
                    }

                    response.config.endtime = Date.now();
                    response.config.duration = Date.now() - response.config.startTime;
                    responsePromise.response = response;
                    this._callResponseInterceptorCallback(callBack, response.status, response.data);
                    this._callUserCallback(callBack, response.status, response.data);
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                    } else {
                        console.error(error);
                        this._callUserCallback(callBack, 500, {});
                    }
                });

            const _return = {
                cancel: () => { source.cancel(); },
                id: this.queryId,
                resource,
                method,
                parameter,
                response: responsePromise,

            };

            return _return;
        },

        _callUserCallback(callback, code, data) {
            if (undefined === callback[code] && code != 200) {
                if (callback.onError != undefined) {
                    callback.onError(data, code);
                }
            } else if (callback[code] != undefined) {
                callback[code](data);
            }
        },

        _callUserOnProgressCallback(progressEvent, callback) {
            if (callback.onUploadProgress != undefined) {
                callback.onUploadProgress(progressEvent);
            }
        },

        _callCallInterceptorCallback(data) {
            for (let i = 0; i < this.callInterceptor.length; i++) {
                this.callInterceptor[i](data);
            }
        },

        _callResponseInterceptorCallback(callback, code, data) {
            for (let i = 0; i < this.responseInterceptor.length; i++) {
                if (this.responseInterceptor[i].code === null || code == this.responseInterceptor[i].code) this.responseInterceptor[i].callback(data, code);
            }
        },

        // Allow external code to intercept response
        interceptResponse(callback, code = null) {
            this.responseInterceptor.push({
                callback,
                code,
            });

            return this;
        },

        // Allow external code to intercept request & response
        interceptCall(callback) {
            this.callInterceptor.push(callback);
        },
    };
}
