import Vue from 'vue';
import Model from '@tony.caron/node-model-proxy/Model';
import I18n from '../modules/i18n/I18n';
import CompanyField from './CompanyField';
import CompanyJobPositionCollection from './CompanyJobPositionCollection';

export default class CompanyJobPosition extends Model {
    modelConfig() {
        return {
            collection: CompanyJobPositionCollection,
        };
    }

    modelCustomAttributes() {
        return {
            name_translated: null,
        };
    }

    modelDefaultValues() {
        return {
            is_heyteam: 0,
        };
    }

    modelAccessors() {
        return {
            name_translated: () => (this.is_heyteam ? I18n.translate(this.name) : this.name),
        };
    }

    modelRelations() {
        return {
            customFields: () => this.hasMany(CompanyField),
        };
    }

    modelEvents() {
        return {
            loaded: () => {
                Vue.prototype.$modelUtils.createCustomFields(this, 'company_job_position');
            },
        };
    }
}
