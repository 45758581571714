<template>
    <HtLayoutModal :can-close="false">
        <template #header>
            <p class="font-weight-bold">
                <t>{{ title }}</t>
            </p>
        </template>

        <template #main>
            <HtToast
                type="info"
                :title="translate('General informations of template')"
                message=""
                :closable="true"
            >
                <p>
                    <t>The basic authorizations correspond to employee role</t>
                </p>

                <p>
                    <t>The roles and the entities which does not exist on HeyTeam platform will be considered as error</t>
                </p>
            </HtToast>

            <ol class="bulk-adding-modal-list">
                <li class="bulk-adding-modal-list-element">
                    <p>
                        <t>Add codes into template</t>
                    </p>
                    <HtFormSwitch
                        id="add-codes-into-template"
                        v-model="shouldAddCodes"
                        name="add-codes-into-template"
                        :label="translate('Associate codes to users')"
                        :description="translate('That\'s useful for connectors or collaborators bulk update')"
                        :show-optional="false"
                    />
                </li>
                <li class="bulk-adding-modal-list-element">
                    <p>
                        <t>Download the sample template</t>
                    </p>
                    <HtLink
                        :title="getLinkTitle"
                        type="csv"
                        @click="generateExampleCsv"
                    />
                </li>
                <li class="bulk-adding-modal-list-element">
                    <p>
                        <t>Import the template</t>
                    </p>

                    <UploadBar
                        v-model="importedCsvFile"
                        :media="['csv']"
                        cypress="edit-import-file"
                        :empty-label="translate('Import the file containing collaborators')"
                        @onUploadProgress="isUploadingCsvFile = true"
                        @onUploadEnd="isUploadingCsvFile = false"
                        @file-deleted="onFileDeleted"
                    />
                </li>
            </ol>
        </template>

        <template #footer>
            <HtButton
                type="secondary"
                @click.native="$emit('close')"
            >
                <t>Close</t>
            </HtButton>

            <HtButton
                :disabled="importedCsvFile.id === null"
                @click.native="importUsers"
            >
                {{ getSubmitLabel }}
            </HtButton>
        </template>
    </HtLayoutModal>
</template>

<script>
import HtLayoutModal from '@/components/globals/modals/layouts/HtLayoutModal.vue';
import HtToast from '@/components/globals/HtToast.vue';
import HtButton from '@/components/globals/HtButton.vue';
import Uploadable from '@/components/globals/Uploadable.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtLink from '@/components/globals/HtLink.vue';
import UploadBar from '@/components/globals/UploadBar/UploadBar.vue';
import { downloadFile } from '@/helpers/file';
import { parseCsvString } from '@/helpers/string';

export default {
    name: 'BulkAddingModal',
    components: {
        UploadBar,
        HtLink,
        HtFormSwitch,
        Uploadable,
        HtButton,
        HtToast,
        HtLayoutModal,
    },
    props: {
        title: {
            type: String,
            default: 'Users bulk adding',
        },
    },
    data() {
        return {
            shouldAddCodes: false,
            isUploadingCsvFile: false,
            importedCsvFile: {
                id: null,
                content: [],
            },
        };
    },
    computed: {
        getParsedContent() {
            return parseCsvString(this.importedCsvFile.content);
        },
        getSubmitLabel() {
            if (this.isUploadingCsvFile) {
                return this.translate('Importing...');
            }

            if (this.importedCsvFile.id === null) {
                return this.translate('Follow instructions');
            }

            return this.translate('Add {count} users', { count: this.getParsedContent.length });
        },
        getLinkTitle() {
            return this.translate(this.shouldAddCodes ? 'Template with codes' : 'Template');
        },
    },
    methods: {
        onFileDeleted() {
            this.importedCsvFile = {
                id: null,
                content: [],
            };
        },
        generateExampleCsv() {
            const rows = [
                ['lastname', 'firstname', 'email', 'office', 'job_position', 'roles'].concat(this.shouldAddCodes ? ['code'] : []),
                ['Anderson', 'John', 'john.anderson@heyteam.com', 'HeyTeam (Paris)', 'Software developper', 'Manager,Buddy,Equipment Responsible'].concat(this.shouldAddCodes ? ['e761d33c'] : []),
            ];

            downloadFile('Template', `data:text/csv;charset=utf-8,${rows.map((row) => row.join(';')).join('\n')}`);
        },
        importUsers() {
            if (this.importedCsvFile.id === null) {
                return;
            }

            this.$emit('close', { name: 'submit', value: this.importedCsvFile, shouldAddCodeColumn: this.shouldAddCodes });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.bulk-adding-modal-list {
  margin-top: 1rem;
}

.bulk-adding-modal-list-element {
  margin: 2.5rem 0;
}
.bulk-adding-modal-list-element > p,  .bulk-adding-modal-list-element::marker {
  color: $neutral-black;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.ht-switch {
  padding: 1rem 1.6rem;
  border: 1px solid $neutral-300;
  background-color: $bg-white;
  border-radius: $border-radius-8;
}

.ht-link {
  background-color: $bg-white;
}
.ht-link-end {
  color: $neutral-black;
}

.bulk-adding-modal-list {
  padding-left: 1.5rem;
}
.file-upload-wrapper, .ht-switch, .ht-link {
  margin-left: -1.5rem;
}
</style>
