<template>
    <div>
        <Button
            :type="'clear'"
            class="mb-3"
            :class="{disabled: loading}"
            :disabled="loading"
            @click="refresh"
        >
            <FontAwesomeIcon
                v-show="loading"
                icon="sync"
                class="reload-button fa-spin mr-1"
                transform="shrink-4"
            />
            <t>Refresh</t>
        </Button>

        <div class="row">
            <div class="col-lg-3 col-md-4">
                <HtFormInput
                    :id="'user'"
                    :name="'user'"
                    :placeholder="translate('Search by user')"
                    @input="user => debounceSearchBy('user', user)"
                />
            </div>
            <div class="col-lg-3 col-md-4">
                <HtFormFlatPickr
                    :id="'fromDate'"
                    :name="'fromDate'"
                    :placeholder="translate('Search from date')"
                    enable-time
                    :is-working-day="false"
                    :is-with-calendar-icon="true"
                    @change="dates => searchDates('fromDate', dates)"
                />
            </div>
            <div class="col-lg-3 col-md-4">
                <HtFormFlatPickr
                    :id="'toDate'"
                    :name="'toDate'"
                    :placeholder="translate('Search to date')"
                    enable-time
                    :is-working-day="false"
                    :is-with-calendar-icon="true"
                    @change="dates => searchDates('toDate', dates)"
                />
            </div>
            <div class="col-lg-3 col-md-4">
                <HtFormSelect
                    id="status"
                    name="status"
                    :options="getStatuses"
                    :allow-empty="true"
                    :placeholder="translate('Search by job status')"
                    @input="status => searchBy('status', status)"
                />
            </div>
            <div class="col-lg-3 col-md-4">
                <HtFormInput
                    :id="'payload'"
                    :name="'payload'"
                    :placeholder="translate('Search inside payload')"
                    @input="payload => debounceSearchBy('payload', payload)"
                />
            </div>
            <div class="col-lg-3 col-md-4">
                <HtFormInput
                    :id="'message'"
                    :name="'message'"
                    :placeholder="translate('Search inside message')"
                    @input="message => debounceSearchBy('message', message)"
                />
            </div>
            <div class="col-lg-3 col-md-4">
                <HtFormInput
                    :id="'label'"
                    :name="'label'"
                    :placeholder="translate('Search by label')"
                    @input="label => debounceSearchBy('label', label)"
                />
            </div>
        </div>
        <div>
            <HTable
                :columns="columns"
                :data="formattedLogs"
                :loading="loading"
                @on-row-click="onRowClick"
                @on-sort="onSort"
            >
                <template #cell(status)="item">
                    <JobLogIcon
                        :icon="item.value"
                    />
                </template>
                <template #cell(message)="item">
                    {{ item.value }}
                    <ul
                        v-show="expanded(item.row.id)"
                        class="messages-list"
                        :data-job-log-id="item.row.id"
                    >
                        <li
                            v-for="(message, index) in item.row.messages"
                            :key="index"
                        >
                            <LogMessage :data="message" />
                        </li>
                    </ul>
                </template>
                <template #cell(user)="item">
                    <HTableCellUser
                        v-if="item.value"
                        :user="item.value"
                        :should-link-to-profile="item.value.company_user_id !== null"
                    />
                </template>
                <template #cell(date)="item">
                    <div
                        class="table-string"
                        :title="item.row.dateRaw"
                    >
                        {{ item.value }}
                    </div>
                </template>
                <template #cell(actions)="item">
                    <HTableActions v-if="item.row.canRetry">
                        <HTableActionsItem
                            :label="translate('Rerun')"
                            @click="rerun(item.row.id)"
                        />
                    </HTableActions>
                </template>
            </HTable>
        </div>
        <div>
            <HtPagination
                v-model="page"
                :last-page="lastPage"
                @input="loadPage"
            />
        </div>
    </div>
</template>
<script>

import {
    HTable, HTableActions, HTableActionsItem, HTableCellUser,
} from '@/components/globals/table';

import JobLogIcon from '@/pages/logs/JobLogIcon.vue';
import HtFormFlatPickr from '@/components/globals/HtFormFlatPickr.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';

import debounce from 'lodash.debounce';
import job from '@/store/api/configuration/job';
import LogMessage from '@/router/pages/settings/Logs/LogMessage.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';

export default {
    components: {
        HtFormMultiSelect,
        HtFormSelect,
        HTable,
        HTableCellUser,
        JobLogIcon,
        HtFormFlatPickr,
        HtFormInput,
        HTableActions,
        HTableActionsItem,
        LogMessage,
    },
    data() {
        return {
            logs: [],
            total: 0,
            toggle: null,
            page: 1,
            lastPage: 2,
            filters: {
                user: '',
                fromDate: null,
                toDate: null,
                payload: '',
                status: '',
                message_status: '',
                label: '',
                message: '',
            },
            loading: true,
        };
    },
    computed: {
        columns() {
            return [
                {
                    key: 'status',
                    thStyle: {
                        width: '50px',
                    },
                    tdStyle: {
                        textAlign: 'center',
                    },
                    showSkeleton: false,
                },
                {
                    key: 'message',
                    label: this.translate('Message'),
                },
                {
                    key: 'user',
                    label: this.translate('User'),

                    sortable: true,
                    sort(a, b) {
                        return (`${a.firstname} ${a.lastname}`).localeCompare(`${b.firstname} ${b.lastname}`);
                    },
                    thStyle: {
                        width: '300px',
                    },
                },
                {
                    key: 'date',
                    label: this.translate('Date'),
                    sortable: true,
                    thStyle: {
                        width: '130px',
                    },
                },
                {
                    key: 'actions',
                    thStyle: {
                        width: '50px',
                    },
                },
            ];
        },
        formattedLogs() {
            return this.logs.map((log) => ({
                id: log.id,
                status: log.status,
                user: log.user,
                message: log.label ?? log.script_title,
                date: this.$Utils.moment(log.updated_at).fromNow(),
                dateRaw: this.$Utils.moment(log.updated_at).format('L'),
                canRetry: log.status === 'error',
                messages: log.messages.map((message) => ({
                    id: log.id,
                    status: message.type,
                    message: message.message,
                    context: Object.keys(message.context).length > 0 ? message.context : null,
                    date: message.created_at ? this.$Utils.moment(message.created_at).format('YYYY-MM-DD HH:mm') : null,
                })),
            }));
        },
        getStatuses() {
            return [
                {
                    id: 'error',
                    name: this.translate('Error'),
                },
                {
                    id: 'success',
                    name: this.translate('Success'),
                },
            ];
        },
    },
    mounted() {
        this.refresh();
    },
    methods: {
        formattedObject(context) {
            return JSON.stringify(context, null, 8);
        },
        async rerun(jobLogId) {
            await job.rerun(jobLogId);
            this.refresh();
        },
        loadPage: debounce(async function (page) {
            this.loading = true;
            const { data } = await job.getLogs(page, this.filters);
            this.logs = data.data;
            this.page = data.meta.current_page;
            this.lastPage = data.meta.last_page;
            this.total = data.meta.total;
            this.loading = false;
        }, 500),
        refresh() {
            this.loadPage(this.page);
        },
        searchDates(field, date) {
            this.filters[field] = (date.length && typeof date[0] === 'object') ? this.$Utils.moment(date[0]).format('YYYY-MM-DD HH:mm:ss') : null;
            this.page = 1;
            this.refresh();
        },
        searchBy(field, value) {
            this.filters[field] = value;
            this.page = 1;
            this.refresh();
        },
        debounceSearchBy: debounce(async function (field, value) {
            if (value.length >= 3 || value.length === 0) {
                this.searchBy(field, value);
            }
        }, 1000),
        expanded(rowId) {
            return this.toggle === rowId;
        },
        onRowClick(row) {
            if (row.messages.length === 0) {
                this.toggle = null;
                return;
            }

            this.toggle = this.toggle === row.id ? null : row.id;
        },

        onSort() {
        },
    },
};
</script>
<style lang="scss" scoped>
.messages-list {
  padding-top: 2rem;
}

.row-pointer td {
  vertical-align: baseline;
}
</style>
