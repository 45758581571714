<template>
    <div>
        <ParametersBox :title="translate('Team')" />
        <HtToast
            v-if="hasOtherActivePrograms"
            :title="
                translate('Updating a supervisor will impact other programs')
            "
            :message="
                translate(
                    'If you update a supervisor being used in other programs, the modification will be applied to all programs using this supervisor'
                )
            "
            type="warning"
            closable
            class="mb-20"
        />
        <HtCard class="team-wrapper">
            <HtItemSelectCard
                v-for="(supervisor, index) in supervisors"
                :key="index"
                :label="supervisor.role.alias_translated"
                :button-label="translate('Add a user')"
                :item="supervisor"
                :is-empty="isSupervisorEmpty"
                :can-delete="canDeleteSupervisor"
                :cypress="'role-'+index"
                @on-change="onChange"
                @on-delete="onDelete"
            >
                <template #item-icon="data">
                    <div
                        v-user-image="{
                            image: data.value.user.image,
                            firstname: data.value.user.firstname,
                            lastname: data.value.user.lastname,
                        }"
                        class="user-image mr-3"
                    />
                </template>
                <template #item-label="data">
                    {{ getFullname(data.value.user) }}
                </template>
            </HtItemSelectCard>
            <Button
                cypress="foc-add-role"
                @click="openRoleModal"
            >
                <t>Add optional roles</t>
            </Button>
        </HtCard>

        <Modalable
            ref="modalableSearchRole"
            class="modalable-1 small no-padding-bottom"
        >
            <SearchRolePanel
                ref="searchRolePanel"
                :multiple-mode="true"
                :allow-user-search="false"
                :has-roles="true"
                @onValidate="onValidateRoleModal"
            />
        </Modalable>

        <SetSupervisorModal
            v-if="clickedRoleUser"
            ref="modal"
            :role-name="clickedRoleUser.role.alias_translated"
            :role-id="clickedRoleUser.role.id"
            :is-guest-role="clickedRoleUser.role.is_guest"
            :suggestion="getSuggestion"
            @user-selected="onUserSelected"
            @user-invited="onUserInvited"
        />

        <HtToast
            :title="
                translate('Resources linked to unset roles won\'t be generated')
            "
            :message="
                translate(
                    'You could always add coworker on this role later but resources should be added manually'
                )
            "
            type="info"
            closable
            class="mb-20"
        />

        <EnrollingFooter>
            <Button
                cypress="foc-previous-team"
                class="
					inner-box
					branding-color
					branding-border-color
					branding-border-color-darken-hover
				"
                @click="$emit('on-prev')"
            >
                <t>Previous</t>
            </Button>
            <Button
                cypress="foc-next-team"
                :state="buttonState"
                @click="onSave"
            >
                <t>Next</t>
            </Button>
        </EnrollingFooter>
    </div>
</template>

<script>
import HtItemSelectCard from '@/components/globals/HtItemSelectCard.vue';
import ParametersBox from '@/components/globals/ParametersBox.vue';
import SetSupervisorModal from '@/components/globals/modals/SetSupervisorModal.vue';
import EnrollingApprenticeshipSupervisorEligibilityCheck from '@/components/pages/program/enrolling/EnrollingApprenticeshipSupervisorEligibilityCheck.vue';
import HtToast from '@/components/globals/HtToast.vue';
import CompanyRoleCollection from '@/models/CompanyRoleCollection';
import EnrollingFooter from './EnrollingFooter.vue';

export default {
    name: 'EnrollingTeam',
    permissions: ['ModelCompanyUser', 'ModelCompanyRoleUser'],
    components: {
        EnrollingApprenticeshipSupervisorEligibilityCheck,
        HtItemSelectCard,
        ParametersBox,
        EnrollingFooter,
        SetSupervisorModal,
        HtToast,
    },
    props: {
        userId: {
            type: Number,
            required: true,
        },
        userProgramId: {
            type: Number,
            required: true,
        },
        programType: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            clickedRoleUser: null,
            supervisors: [],
            suggestion: [],
            buttonState: 'idle',
            isProgramGenerated: false,
            apprenticeshipSupervisorIsEligible: false,
            hasOtherActivePrograms: false,
            companyRoleCollection: new CompanyRoleCollection([
                'alias',
                'id',
                'is_assignable_program',
                'is_assignable_resource',
                'is_editable',
                'is_enabled',
                'is_guest',
                'is_heyteam',
                'is_removable',
                'name',
            ]).where([['is_assignable_program', '=', true]]),
        };
    },

    computed: {
        /**
         * Retourne la suggestion pour le role selectionné
         * @returns {Array}
         */
        getSuggestion() {
            return this.suggestion
                .filter(
                    (s) => s.company_role_id === this.clickedRoleUser.role.id,
                )
                .map((s) => s.company_user);
        },

        canDeleteSupervisor() {
            return !this.isProgramGenerated;
        },
    },

    created() {
        this.$http
            .get(`foc/enrolling/team/${this.userProgramId}`)
            .then(({ data }) => {
                this.supervisors = data.supervisors.map((supervisor) => {
                    supervisor.role.alias_translated = supervisor.role.is_heyteam
                        ? this.translate(supervisor.role.alias)
                        : supervisor.role.alias;
                    supervisor.is_mandatory = true;

                    return supervisor;
                });

                this.suggestion = data.suggestion;
                this.isProgramGenerated = data.is_program_generated;
                this.hasOtherActivePrograms = data.has_other_active_programs;
            });
    },

    methods: {
        onDelete(item) {
            item.user = null;
            item.company_user_id = null;
        },

        async onChange(item) {
            this.clickedRoleUser = item;
            await this.$nextTick();
            this.$refs.modal.open();
        },

        onUserSelected(user) {
            this.$refs.modal.close();
            this.clickedRoleUser.company_user_id = user.id;
            this.clickedRoleUser.user = user;
        },

        onUserInvited(user) {
            this.clickedRoleUser.company_user_id = user.id;
            this.clickedRoleUser.user = user;
            this.$refs.modal.close();
        },

        async openRoleModal() {
            await this.$refs.searchRolePanel.init({
                title: this.translate('Add optional roles'),
                selected: this.supervisors
                    .map((supervisor) => ({ ...supervisor })),
            });
            this.$refs.modalableSearchRole.open();
        },

        async onValidateRoleModal(roles) {
            // Fist we get raw list of roles
            await this.companyRoleCollection.get();
            // Then we get all roles wich id is contained in selected roles from the modal
            // AND id is not already contained in supervisors
            const newSupervisors = this.companyRoleCollection.models
                .filter((model) => (
                    roles
                        .map((role) => role.company_role_id)
                        .includes(model.id)
                    && !this.supervisors
                        .map((sup) => sup.company_role_id)
                        .includes(model.id)
                ))
                // And we map it to match supervisor object shape
                .map((r) => {
                    // clean role object to avoid circular json
                    const role = { ...r };
                    delete role._parent;
                    delete role._state;

                    return {
                        company_role_id: r.id,
                        company_user_id: null,
                        role,
                        user: null,
                    };
                });

            // Add optional roles to supervisors
            this.supervisors = this.supervisors.filter((supervisor) => roles
                .map((role) => role.company_role_id)
                .includes(supervisor.company_role_id)).concat(newSupervisors);

            // Close the modal
            this.$refs.modalableSearchRole.close();
        },

        onSave() {
            this.buttonState = 'loading';

            const supervisors = this.supervisors.filter((supervisor) => Number.isInteger(supervisor.company_user_id));

            this.$http
                .put(`foc/enrolling/team/${this.userProgramId}`, {
                    supervisors,
                })
                .then(() => {
                    this.$emit('on-next');
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    this.buttonState = 'idle';
                });
        },

        getFullname(user) {
            if ('fullname' in user) {
                return user.fullname;
            }

            return `${user.firstname} ${user.lastname}`;
        },

        isSupervisorEmpty(item) {
            return Boolean(!item.user || Object.keys(item.user).length === 0);
        },
    },
};
</script>
<style lang="scss" scoped>
.team-wrapper {
    margin-bottom: 16px;
}
</style>
