import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUser from './CompanyUser';

export default class CompanyUserCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUser,
        };
    }
}
