<template>
    <HtFormGroup
        :label="label"
        :error="error"
        :is-required="isRequired"
    >
        <div class="ht-checkbox">
            <div
                v-for="(option, index) in options"
                :key="index"
                class="ht-checkbox-item"
            >
                <input
                    :id="id+'-'+index"
                    v-model="items"
                    class="ht-checkbox-item-input"
                    :name="name"
                    :value="option.value"
                    type="checkbox"
                >
                <label
                    class="ht-checkbox-item-name"
                    :for="id+'-'+index"
                >{{ option.text }}</label>
            </div>
        </div>
    </HtFormGroup>
</template>

<script>
import HtFormMixin from './HtFormMixin';

export default {
    name: 'HtFormCheckbox',
    mixins: [
        HtFormMixin,
    ],

    props: {
        value: {
            type: Array,
        },
        options: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            items: [],
        };
    },

    watch: {
        items() {
            this.$emit('input', this.items);
        },
    },

    created() {
        this.items = this.value ? this.value : [];
    },
};
</script>

<style lang="scss" scope src="./HtFormCheckbox.scss"></style>
