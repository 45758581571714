<template>
    <div class="ai-suggestion-list">
        <div
            v-for="(suggestion, index) in allSuggestions"
            :key="index"
            class="ai-suggestion-item"
            @click="$emit('on-suggestion-click', suggestion)"
        >
            {{ suggestion }}
        </div>
        <ThinkingLoader
            v-if="isLoading"
            class="thinking-loader"
        />
    </div>
</template>

<script>
import ThinkingLoader from '@/components/chat/message/ThinkingLoader.vue';

export default {
    name: 'ChatAIListSuggestion',
    components: { ThinkingLoader },
    data() {
        return {
            isLoading: true,
            globalSuggestions: [
                this.translate('Do I have any overdue tasks?'),
            ],
            knowledgeBaseSuggestions: [],
        };
    },
    computed: {
        allSuggestions() {
            return this.globalSuggestions.concat(this.knowledgeBaseSuggestions);
        },
    },
    created() {
        this.getKnowledgeBaseSuggestions();
    },
    methods: {
        getKnowledgeBaseSuggestions() {
            this.isLoading = true;

            this.$http.get('company/knowledge/suggestions').then((response) => {
                if (response.status < 300) {
                    if (!response.data?.questions) {
                        throw new Error('Server Error');
                    }

                    this.knowledgeBaseSuggestions = this.knowledgeBaseSuggestions.concat(response.data.questions);
                }
            }).catch((error) => {
                console.error(error);
            }).finally(() => {
                this.isLoading = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
.ai-suggestion-list {
    display: flex;
    gap: 10px;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: auto;
    padding: 40px 0;
    align-items: center;
    justify-content: center;
}

.ai-suggestion-item {
    padding: 8px 10px;
    min-height: 30px;
    height: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
    border: 1px solid $primary;
    border-radius: 100px;
    background-color: white;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &:hover {
        background-color: #f5f5f5;
    }
}

.thinking-loader {
    background-color: transparent;
}
</style>
