<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM9.984 18H10C14.4167 17.9956 17.9942 14.4127 17.992 9.996C17.9898 5.57929 14.4087 2 9.992 2C5.57528 2 1.99421 5.57929 1.992 9.996C1.98979 14.4127 5.56729 17.9956 9.984 18Z"
            :fill="fillColor"
        />
        <path
            d="M14.295 5.705C13.9056 5.31564 13.2744 5.31564 12.885 5.705L10 8.59L7.115 5.705C6.72564 5.31564 6.09436 5.31564 5.705 5.705C5.31564 6.09436 5.31564 6.72564 5.705 7.115L8.59 10L5.705 12.885C5.31564 13.2744 5.31564 13.9056 5.705 14.295C6.09436 14.6844 6.72564 14.6844 7.115 14.295L10 11.41L12.885 14.295C13.2744 14.6844 13.9056 14.6844 14.295 14.295C14.6844 13.9056 14.6844 13.2744 14.295 12.885L11.41 10L14.295 7.115C14.6844 6.72564 14.6844 6.09436 14.295 5.705Z"
            :fill="fillColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'DSIconError',
    props: {
        width: {
            type: String,
            default: '20',
        },
        height: {
            type: String,
            default: '20',
        },
        fillColor: {
            type: String,
            default: '#DC3D1B',
        },
    },
};
</script>

<style scoped>

</style>
