<template>
    <div class="uploadable-zone-wrapper-form">
        <form
            ref="fileSelectorForm"
            class="fileSelectorForm"
            @click.self="open"
            @drop.prevent="handleDrop"
            @dragover.prevent
        >
            <input
                :id="`file-selector-${uniqueId}`"
                ref="fileSelector"
                type="file"
                :accept="accept"
                :style="{ display: 'none' }"
                :multiple="multiple"
                @click.stop
            >
            <label
                :for="`file-selector-${uniqueId}`"
                class="custom-file-upload"
            >
                <FontAwesomeIcon
                    :icon="['fal', 'plus-circle']"
                    class="mr-3 branding-color"
                    size="lg"
                />
                <p><t>Drag and drop or</t> <span class="import-link"><t>Import</t></span> <t>a file</t></p>
            </label>
        </form>
    </div>
</template>

<script>
import DownloadUploadFileMixin from '@/components/mixins/DownloadUploadFileMixin';

export default {
    name: 'UploadableZone',

    mixins: [DownloadUploadFileMixin],

    props: {
        isRequirement: {
            type: Boolean,
            default: false,
        },
        maxSize: {
            type: Number,
            default: 512,
        },
        media: {
            type: Array,
            default() {
                return [];
            },
        },
        extension: {
            type: Array,
            default() {
                return null;
            },
        },
        ressource: {
            type: String,
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            mimeType: [],
            uploadObj: null,
            uniqueId: this.$Utils.uniqId(),
        };
    },

    computed: {
        accept() {
            return this.mimeType.join(',');
        },
    },

    watch: {
        media: {
            deep: true,
            handler() {
                this.initMimeTypes();
            },
        },
    },

    mounted() {
        this.$refs.fileSelector.addEventListener('change', this.onFileChange);
        this.initMimeTypes();
    },

    beforeDestroy() {
        this.$refs.fileSelector.removeEventListener('change', this.onFileChange);
    },

    methods: {
        initMimeTypes() {
            this.mimeType = [];

            for (const i in this.media) {
                if (Object.prototype.hasOwnProperty.call(this.media, i)) {
                    switch (this.media[i]) {
                    case 'office':
                        this.mimeType.push(
                            'application/msword',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                            'application/vnd.ms-word.document.macroEnabled.12',
                            'application/vnd.ms-word.template.macroEnabled.12',
                            'application/vnd.ms-excel',
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
                            'application/vnd.ms-excel.sheet.macroEnabled.12',
                            'application/vnd.ms-excel.template.macroEnabled.12',
                            'application/vnd.ms-excel.addin.macroEnabled.12',
                            'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
                            'application/vnd.ms-powerpoint',
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                            'application/vnd.openxmlformats-officedocument.presentationml.template',
                            'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
                            'application/vnd.ms-powerpoint.addin.macroEnabled.12',
                            'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
                            'application/vnd.ms-powerpoint.template.macroEnabled.12',
                            'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
                            'application/x-iwork-pages-sffpages',
                            'text/*',
                        );
                        break;
                    case 'docx':
                        this.mimeType.push(
                            'application/msword',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                            'application/vnd.ms-word.document.macroEnabled.12',
                            'application/vnd.ms-word.template.macroEnabled.12',
                        );
                        break;
                    case 'csv':
                        this.mimeType.push('text/*', 'application/vnd.ms-excel');
                        break;
                    case 'pdf':
                        this.mimeType.push('application/pdf');
                        break;
                    case 'image':
                        this.mimeType.push('image/*');
                        break;
                    case 'video':
                        this.mimeType.push('video/mp4', 'video/ogg', 'video/webm');
                        break;
                    }
                }
            }
        },

        open() {
            this.$refs.fileSelector.click();
        },

        validateFile(file) {
            const max = this.maxSize || 512;
            if (this.$Utils.convertByteToMegabyte(file.size) > max) {
                this.$Notifier('App').showError(this.translate('The file is too large, it is over the {size} Mo max size.', { size: this.maxSize }));
                return false;
            }
            if (file.size === 0) {
                this.$Notifier('App').showError(this.translate('The file is empty!'));
                return false;
            }
            if (
                (file.type.startsWith('text/') && !this.media.includes('office') && !this.media.includes('csv'))
                || (file.type.startsWith('image/') && !this.media.includes('image'))
                || (!file.type.startsWith('image/') && !file.type.startsWith('text/') && !this.mimeType.includes(file.type))
            ) {
                this.$Notifier('App').showError(this.translate('The file format or file extension is not valid!'));
                return false;
            }

            if (
                this.extension
                && !this.extension.includes(file.name.split('.').pop())
            ) {
                return false;
            }
            return true;
        },

        uploadFile(file) {
            return this.uploadCompanyFile(file, this.isRequirement);
        },

        async processFiles(files) {
            if (files.length === 0 || !files[0].name) {
                return;
            }
            for (let i = 0; i < files.length; i += 1) {
                if (!this.validateFile(files[i])) continue;

                this.cancelUpload();

                this.$emit('onUploadStart');
                if (this.multiple) {
                    if (!this.uploadObj) this.uploadObj = [];
                    this.uploadObj.push(this.uploadFile(files[i]));
                } else {
                    this.uploadObj = this.uploadFile(files[i]);
                }
            }

            try {
                await Promise.all(this.uploadObj);
                this.$emit('onUploadComplete');
            } catch (error) {
                console.error('An error occurred while uploading the files.');
            }
        },

        onFileChange(e) {
            this.processFiles(e.target.files);
        },

        handleDrop(event) {
            this.processFiles(event.dataTransfer.files);
        },

        cancelUpload(name = null) {
            if (this.uploadObj) {
                // if only one file, we cancel the upload
                if (!this.multiple) {
                    this.uploadObj.cancel();
                    // if multiple files and name provided
                    // we cancel the upload with the corresponding name
                } else if (name) {
                    const uploadObjToCancel = this.uploadObj
                        .find((uploadObj) => uploadObj.name === name);
                    uploadObjToCancel?.call?.cancel?.();
                    // if multiple files and no name provided
                    // we cancel all uploads
                } else if (this.uploadObj.length) {
                    for (let i = 0; i < this.uploadObj.length; i += 1) {
                        this.uploadObj[i].cancel?.();
                    }
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.uploadable-zone-wrapper-form {
    width: 100%;

    * {
        cursor: pointer;
    }
}

.fileSelectorForm {
    position: relative;
    padding: 20px;
    text-align: center;
}

.upload-zone {
    padding: 20px;
}

.upload-zone p {
    margin: 0;
}

.import-link {
    color: $primary;
}
</style>
