import Model from '@tony.caron/node-model-proxy/Model';
import CompanyField from '@/models/CompanyField';
import CompanyOfficeCollection from './CompanyOfficeCollection';
import CompanyUser from './CompanyUser';
import I18n from '../modules/i18n/I18n';

export default class CompanyOffice extends Model {
    static DEFAULT_TIMEZONE = 'Europe/Paris';

    modelConfig() {
        return {
            collection: CompanyOfficeCollection,
        };
    }

    modelCustomAttributes() {
        return {
            name_translated: null,
        };
    }

    modelDefaultValues() {
        return {
            is_heyteam: 0,
        };
    }

    modelAccessors() {
        return {
            name_translated: () => (this.is_heyteam ? I18n.translate(this.name) : this.name),
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser),
            customFields: () => this.hasMany(CompanyField),
        };
    }
}
