/**
 * Slugify a string
 * @param {string} stringToSlugify
 * @returns {string}
 */
export function slugify(stringToSlugify) {
    return normalize(stringToSlugify)
        .replace(/^\s+|\s+$/g, '') // Remove spaces at start and enf of word
        .toLowerCase()
        .replace(/[^a-z0-9\-]/g, '-') // Replace special chars by hyphen
        .replace(/-+/g, '-'); // Remove consecutive hyphens
}

/**
 * Normalize a string
 * @param {string} stringToNormalize
 * @returns {string}
 */
export function normalize(stringToNormalize) {
    return stringToNormalize.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * Search if "searchedString" is included in "text"
 * @param {string} searchedString
 * @param {string} text
 * @returns {boolean}
 */
export function contains(searchedString, text) {
    const searchedStringNormalized = normalize(searchedString).toLowerCase();
    const textNormalized = normalize(text).toLowerCase();

    return textNormalized.includes(searchedStringNormalized);
}

/**
 * @param {String} csvString
 * @return {Object<string, any>[]}
 */
export function parseCsvString(csvString) {
    const lines = csvString.split('\n');
    const headers = lines[0].split(';').map((header) => header.toLowerCase());

    return lines.slice(1).map((line) => {
        const values = line.split(';');

        return line.trim() !== ''
            ? Object.fromEntries(headers.map((header, index) => [header, values[index]]))
            : null;
    }).filter((item) => item !== null);
}
