import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUserSurveySupervisorCollection from './CompanyUserSurveySupervisorCollection';
import CompanyUser from './CompanyUser';
import CompanyRole from './CompanyRole';

export default class CompanyUserSurveySupervisor extends Model {
    modelConfig() {
        return {
            collection: CompanyUserSurveySupervisorCollection,
        };
    }

    modelRelations() {
        return {
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyRole: () => this.belongsTo(CompanyRole, 'company_role_id', 'id'),
        };
    }
}
