<template>
    <div :class="['box has-padding', {'text-center': noActions}]">
        <div class="items-status">
            <div
                v-for="(tasks, periodLabel) in tasksGroupedByPeriod"
                :key="periodLabel"
                class="item-status"
            >
                <div class="item-top">
                    <div class="item-name">
                        <t>{{ periodLabel }}</t>
                    </div>

                    <div class="item-content">
                        {{ getNumberDoneTasks(tasks) }}/{{ tasks.length }}
                    </div>
                </div>

                <div class="item-bottom">
                    <div class="progress-bar-container">
                        <div
                            class="progress-bar"
                            :class="determineProgressBarColor(tasks)"
                            :style="'width: '+(getNumberDoneTasks(tasks) / tasks.length)*100+'%'"
                        />
                    </div>
                </div>
            </div>
            <span
                v-if="noActions"
                class="item-status"
            >
                <t>You don't have any action to do</t>
            </span>
            <br>
        </div>
    </div>
</template>

<script>
import CompanyUserProgramTaskCollection from '@/models/CompanyUserProgramTaskCollection';
import groupBy from 'lodash.groupby';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';

export default {
    name: 'UserProgramStatus',
    props: {
        company_user_id: {
            type: [String, Number],
            required: true,
        },
        companyUserProgramId: {
            type: [String, Number],
            required: true,
        },
        companyUserProgramTaskCollection: {
            type: CompanyUserProgramTaskCollection,
        },
        program: {
            type: Object,
            default: () => null,
        },
    },

    shared: {
        program: {
            currentTabActive: {
                type: Number,
                default: 1,
            },
            validateBulk: {
                type: Boolean,
                default: false,
            },
            deleteBulk: {
                type: Boolean,
                default: false,
            },
            removeIncrementTask: {
                type: Number,
                default: 0,
            },
            updateIncrementTask: {
                type: Number,
                default: 0,
            },
        },
    },

    computed: {
        tasksGroupedByPeriod() {
            let tasks = this.companyUserProgramTaskCollection.models;
            switch (this.shared.program.currentTabActive) {
            case 1:
                tasks = tasks.filter((task) => task.company_user_program_task_participant.models.some((participant) => participant.company_user_id === this.shared.session.companyUser.id));
                break;
            case 2:
                tasks = tasks.filter((task) => task.company_user_program_task_participant.models.some((participant) => participant.company_user_id === this.company_user_id));
                break;
            case 3:
                tasks = tasks.filter((task) => task.company_user_id === this.company_user_id);
                break;
            }
            return groupBy(tasks, 'period.label');
        },
        noActions() {
            return Object.values(this.tasksGroupedByPeriod).length === 0;
        },
    },
    methods: {
        determineProgressBarColor(tasks) {
            if (this.getNumberDoneTasks(tasks) === tasks.length) return 'green';
            return 'red';
        },

        /**
			* @param {array} tasks
			* @returns {Number}
			*/
        getNumberDoneTasks(tasks) {
            return tasks.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE).length;
        },
    },
};
</script>
