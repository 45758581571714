import configurations from '@/store/modules/configuration/configurations.store';
import emailTemplates from '@/store/modules/configuration/emailTemplate.store';

export default {
    namespaced: true,

    modules: {
        configurations,
        emailTemplates,
    },
};
