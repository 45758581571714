<template>
    <div class="page-content">
        <form
            class="form-2 spaced"
            @submit.prevent=""
        >
            <div class="preboarding-section-title">
                <InputField
                    v-model="collectionCopy['bloc_signup_title']['content']['messages']['message_1'][`message_${lang}`]"
                    :label="translate('Section title')"
                    type="text"
                    name="bloc_signup_title"
                    validate="required"
                    :validate-as="translate('section title')"
                />
            </div>

            <div class="preboarding-container-text">
                <HtFormSwitch
                    id="first-message"
                    v-model="collectionCopy['bloc_signup_a']['is_enabled']"
                    name="first-message"
                    :disabled="!collectionCopy['bloc_signup_a']['is_enabled_editabled']"
                    :label="translate('First message')"
                    :show-optional="false"
                />
                <InputField
                    v-model="collectionCopy['bloc_signup_a']['content']['messages']['message_1'][`message_${lang}`]"
                    type="text"
                    name="bloc_signup_a"
                    validate="required"
                    :validate-as="translate('A message here')"
                />

                <InputField
                    type="text"
                    name="login"
                    validate="required"
                    :placeholder="translate('Email here')"
                    value=""
                    disabled
                />

                <InputField
                    v-model="collectionCopy['bloc_signup_b']['content']['messages']['message_1'][`message_${lang}`]"
                    type="text"
                    name="bloc_signup_b"
                    validate="required"
                    :validate-as="translate('A message here')"
                />

                <InputField
                    type="text"
                    name="password"
                    value=""
                    :placeholder="translate('Password')"
                    disabled
                />

                <InputField
                    value=""
                    type="text"
                    name="confirm_password"
                    :placeholder="translate('Confirm password')"
                    disabled
                />
            </div>
        </form>
    </div>
</template>
<script>
import CompanyFlowCollection from '@/models/CompanyFlowCollection';
import FlowHelper from '@/components/mixins/FlowHelper.vue';
import FlowVariable from '@/components/mixins/FlowVariable.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'CustomSign',
    components: {
        HtFormSwitch,
    },

    mixins: [FlowHelper, FlowVariable],
    props: {
        chatbotSlug: {
            type: String,
            required: true,
        },
        collection: {
            type: CompanyFlowCollection,
            required: true,
        },
        lang: {
            type: String,
            default: 'fr',
        },
        maxStep: {
            type: Number,
            default: 6,
        },
        step: {
            type: Number,
            default: 2,
        },
    },
};
</script>
<style lang="scss" scoped>
	@import "~@/styles/var";
	@import "~@/styles/customflow";
</style>
