<template>
    <div
        v-if="carouselLoaded"
        class="full-width"
    >
        <SectionBar
            v-if="editingLang === defaultLanguageKey"
            :title="translate('Carousel')"
            :button-class="'button-plus'"
            :button-text="'+'"
            :has-add-button="true"
            @click="openCarousel()"
        />
        <SectionBar
            v-else
            :title="translate('Carousel')"
            :has-add-button="false"
        />

        <template v-if="carouselLoaded && carousels.length">
            <div
                v-for="(carousel, index) in carousels"
                :key="index"
                class="container-carousel"
            >
                <div class="items-bar small no-mt no-middle">
                    <div class="bar-item left bold">
                        {{ getTitle(carousel) }}
                    </div>
                    <div class="bar-item right">
                        <button
                            class="button button-icon"
                            @click="openCarousel(index)"
                        >
                            <svg class="icon  icon-edit-fill">
                                <use xlink:href="#icon-edit-fill" />
                            </svg>
                        </button>
                        <span
                            v-if="editingLang === defaultLanguageKey"
                            class="button button-plus"
                            @click="openMedia(index)"
                        >+</span>
                    </div>
                </div>
                <div class="full-width">
                    <div class="component_item flex-container card-1 media">
                        <div
                            v-for="item in carousel.fullItems"
                            :key="item.id"
                            :class="(item.is_enabled_editable ? '' : 'not-editable')"
                            class="item roll-icon"
                            @click="openMedia(index, item.id)"
                        >
                            <div class="item-content">
                                <div
                                    v-if="item.type === 'file' && item.company_files"
                                    :data-vv-scope="item.id"
                                >
                                    <img :src="getLink(item)">
                                </div>
                                <div
                                    v-if="item.type === 'link'"
                                    class="image-media video"
                                >
                                    <iframe
                                        width="420"
                                        height="315"
                                        :src="$Utils.embedVideo(getUrl(item))"
                                    />
                                    <div class="overlay" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div
            v-else
            class="empty-component"
        >
            <p><strong><t>No carousel added</t></strong></p>
        </div>

        <Modalable
            ref="modalableCarousel"
            class="modalable-1 medium"
            :mode="2"
            :element-delete-label="translate('this carousel')"
        >
            <CarouselEdit
                v-model="currentCarousel"
                :collection="carousels"
                :editing-lang="editingLang"
                @onReload="onReload"
            />
        </Modalable>
        <Modalable
            ref="modalableCarouselMedia"
            class="modalable-1 medium"
            :mode="2"
            :element-delete-label="translate('this media')"
        >
            <CarouselMediaEdit
                v-model="currentCarouselItem"
                :collection="currentCarouselItemsList"
                :editing-lang="editingLang"
                @onReload="onReload"
            />
        </Modalable>
    </div>
</template>

<script>
import api from '@/store/api';
import CarouselEdit from './CarouselEdit.vue';
import CarouselMediaEdit from './CarouselMediaEdit.vue';

export default {
    name: 'CarouselList',
    components: {
        CarouselEdit,
        CarouselMediaEdit,
    },

    props: {
        editingLang: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            carouselLoaded: false,
            carousels: [],
            currentCarousel: null,
            currentCarouselItem: null,
        };
    },

    computed: {
        defaultLanguageKey() {
            return this.shared.session.company.company_languages.models
                .find((language) => language.is_default).key;
        },
        currentCarouselItemsList() {
            return this.currentCarousel?.fullItems;
        },
    },

    async created() {
        await this.loadCarousel();
    },

    methods: {
        async loadCarousel() {
            this.carouselLoaded = false;
            const carouselResponse = await this.$http.get(api.endpoints.CAROUSELS_BY_COMPANY);
            this.carousels = carouselResponse.data.carousels;
            this.carouselLoaded = true;
        },

        onReload() {
            this.carousels = [];
            this.loadCarousel();
        },

        getLink(item) {
            let file = item.fileTranslations[this.editingLang];
            // If not found, get default one
            if (!file) {
                file = item.fileTranslations[this.defaultLanguageKey] || {};
            }
            return this.$Utils.resolveS3PublicImage(file.path + file.name);
        },

        getUrl(item) {
            let url = item.translations.url[this.editingLang];
            // If not found, get default one
            if (!url) {
                url = item.translations.url[this.defaultLanguageKey] ||  '';
            }
            return url;
        },

        getTitle(carousel) {
            let title = carousel.translations.title[this.editingLang];
            if (!title) {
                title = carousel.translations.title[this.defaultLanguageKey];
            }
            return title;
        },

        openMedia(index, id = null) {
            this.currentCarousel = this.carousels[index];
            this.currentCarouselItem = id
                ? this.adaptedFileTranslations(this.currentCarousel.fullItems.find((item) => item.id === id))
                : {
                    company_carousel_id: this.currentCarousel.id,
                    type: 'file',
                    is_enabled: true,
                    url: null,
                    fileTranslations: {},
                    translations: { url: {} },
                };
            this.$refs.modalableCarouselMedia.open();
        },

        adaptedFileTranslations(item) {
            if (item.type === 'file') {
                // eslint-disable-next-line no-restricted-syntax, guard-for-in
                for (const lang in item.fileTranslations) {
                    item.fileTranslations[lang].link = this.$Utils.resolveS3PublicImage(item.fileTranslations[lang].path + item.fileTranslations[lang].name);
                }
            }
            return item;
        },

        openCarousel(index) {
            this.currentCarousel = (typeof index === 'number') ? this.carousels[index] : null;
            this.$refs.modalableCarousel.open();
        },
    },
};
</script>
<style lang="scss" scoped>
    .items-bar {
        .bold {
            font-size: 1.4rem;
            font-weight: bold
        }
    }

    .container-carousel {
        flex-flow: column;
        position: relative;
        align-items: center;
        border: 1px solid #ECE9E9;
        border-radius: 5px;
        justify-content: flex-start;
        padding: 15px 25px;
        margin-bottom: 10px;
        font-size: 1.4rem;
    }
</style>
