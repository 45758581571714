<template>
    <MessageTextTemplate
        :text="text"
        :is-me="isMe"
        :is-html="isHtml"
    />
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyChatChannelMessage from '@/models/CompanyChatChannelMessage';
import { ListQuestionSubtype, ListQuestionType } from '@/models/common/SurveyQuestionType';
import MessageTextTemplate from './MessageTextTemplate.vue';

const FILE_UPLOAD_ICON = `
<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 20H2C0.89543 20 0 19.1046 0 18V2C0 0.89543 0.89543 0 2 0H9C9.0109 0.000472319 9.02167 0.00249256 9.032 0.006C9.04177 0.00901724 9.05182 0.0110277 9.062 0.012C9.15019 0.0176532 9.23726 0.0347982 9.321 0.063L9.349 0.072C9.37167 0.079682 9.39373 0.0890412 9.415 0.1C9.52394 0.148424 9.62321 0.216185 9.708 0.3L15.708 6.3C15.7918 6.38479 15.8596 6.48406 15.908 6.593C15.918 6.615 15.925 6.638 15.933 6.661L15.942 6.687C15.9699 6.77039 15.9864 6.85718 15.991 6.945C15.9926 6.95418 15.9949 6.96322 15.998 6.972C15.9998 6.98122 16.0004 6.99062 16.0001 7V18C16.0001 19.1046 15.1046 20 14 20ZM2 2V18H14V8H9C8.44772 8 8 7.55228 8 7V2H2ZM10 3.414V6H12.586L10 3.414Z" fill="white"/>
</svg>
`;

export default {
    name: 'MessageResourceUserSurveyQuestionAnswer',
    components: {
        MessageTextTemplate,
    },
    props: {
        companyChatChannelMessage: {
            type: CompanyChatChannelMessage,
            required: true,
        },
    },
    computed: {
        companyUserSurveyQuestionAnswer() {
            return this.companyChatChannelMessage.company_user_survey_question_answer;
        },
        companySurveyQuestionAnswer() {
            return this.companyUserSurveyQuestionAnswer.company_survey_question_answer;
        },
        companySurveyQuestion() {
            return this.companyUserSurveyQuestionAnswer.company_survey_question;
        },
        text() {
            switch (this.companySurveyQuestion.type) {
            case ListQuestionType.QUESTION_TYPE_TEXT:
                return this.companyUserSurveyQuestionAnswer.text;
            case ListQuestionType.QUESTION_TYPE_FILE_UPLOAD:
                const filename = this.companyUserSurveyQuestionAnswer.company_file.original_name;
                if (filename.length <= 20) {
                    return FILE_UPLOAD_ICON + filename;
                }
                const re = /(?:\.([^.]+))?$/;
                const ext = re.exec(filename)[1];
                return `${FILE_UPLOAD_ICON + filename.substr(0, 20 - ext.length)}[...].${ext}`;
            case ListQuestionType.QUESTION_TYPE_REQUIREMENT:
                return this.translate('Requirement completed');
            case ListQuestionType.QUESTION_TYPE_SATISFACTION_SCALE:
                switch (this.companySurveyQuestion.subtype) {
                case ListQuestionSubtype.QUESTION_SUBTYPE_SMILEY:
                    const img = document.createElement('img');
                    img.style.display = 'block';
                    img.style.width = '30px';
                    img.src = this.companySurveyQuestionAnswer.iconPath;
                    return img.outerHTML;
                }

            default:
                if (this.companySurveyQuestionAnswer.company_entity_value_id) {
                    return this.companySurveyQuestionAnswer.value.custom.name;
                }
                return this.companySurveyQuestionAnswer.text;
            }
        },
        isMe() {
            return this.shared.session.companyUser.id === this.companyChatChannelMessage.company_user_id;
        },
        isHtml() {
            return (this.companySurveyQuestion.type === ListQuestionType.QUESTION_TYPE_SATISFACTION_SCALE
                && this.companySurveyQuestion.subtype === ListQuestionSubtype.QUESTION_SUBTYPE_SMILEY)
                || this.companySurveyQuestion.type === ListQuestionType.QUESTION_TYPE_FILE_UPLOAD
                || this.companySurveyQuestion.type === ListQuestionType.QUESTION_TYPE_REQUIREMENT;
        },
    },
    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },
};
</script>
