<template>
    <HtBanner v-show="show">
        <div class="ht-banner-select-wrapper">
            <div class="ht-banner-select-count">
                <t :count="count">
                    {count} Element selected | {count} Elements selected
                </t>
            </div>
            <div
                class="ht-banner-select-cancel"
                data-cy="cancel-selection"
                @click="onCancel"
            >
                <t>Cancel selection</t>
            </div>
            <div
                v-if="canSelectAll"
                class="ht-banner-select-cancel ht-banner-select-all"
                data-cy="select-all"
                @click="onSelectAll"
            >
                <t>Select all</t>
            </div>
        </div>
        <div class="ht-banner-select-button-wrapper">
            <Button
                v-if="canDelete"
                :state="getStateDelete"
                class="negative mr-20"
                data-cy="delete-selected"
                @click="onDelete"
            >
                <t>Delete</t>
            </Button>
            <Button
                :state="getStateValidate"
                data-cy="validate-selected"
                @click="onValidate"
            >
                <t>Validate</t>
            </Button>
        </div>
    </HtBanner>
</template>
<script>
import HtBanner from './HtBanner.vue';

export default {
    name: 'HtBannerSelect',
    components: {
        HtBanner,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        count: {
            type: Number,
            required: true,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
        canSelectAll: {
            type: Boolean,
            default: true,
        },
        buttonStateDelete: {
            type: String,
            default: 'idle',
        },
        buttonStateValidate: {
            type: String,
            default: 'idle',
        },
    },

    computed: {
        getStateDelete() {
            return this.count > 0 ? this.buttonStateDelete : 'disabled';
        },

        getStateValidate() {
            return this.count > 0 ? this.buttonStateValidate : 'disabled';
        },
    },

    methods: {
        onCancel() {
            this.$emit('onCancel');
        },

        onSelectAll() {
            this.$emit('onSelectAll');
        },

        onDelete() {
            this.$emit('onDelete');
        },

        onValidate() {
            this.$emit('onValidate');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.ht-banner-select {
    &-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &-count {
        color: $white;
        font-weight: 700;
        margin-left: 50px;
    }
    &-cancel {
        color: $white;
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 30px;
    }

    &-button-wrapper {
        margin-right: 120px;
    }
}

@media (max-width: $tablet) {
    .ht-banner-select {
        &-all {
            display: none;
        }
    }
}
</style>
