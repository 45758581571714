<template>
    <StepperTabLayout>
        <template #main>
            <HtFormCard>
                <h5><t>Download all of the information by program</t></h5>
                <ul class="mt-3 mb-5">
                    <li>
                        <t>Profile information (name, office, department, job position, contract, etc...)</t>
                    </li>
                    <li>
                        <t>The key dates (date of arrival, date of creation of the program, etc...)</t>
                    </li>
                    <li>
                        <t>The list of participants in the program (manager, HR manager, etc...)</t>
                    </li>
                </ul>
                <HtFormSelect
                    :id="'program'"
                    v-model="form.program_id"
                    :name="'program'"
                    :show-optional="false"
                    :label="translate('Program concerned')"
                    :options="programs"
                />
                <HtFormSelect
                    :id="'period'"
                    v-model="form.date_period"
                    :name="'period'"
                    :show-optional="false"
                    :label="translate('Select a period')"
                    :options="dates"
                    :description="translate('The period is calculated from the employee\'s key date.')"
                />
                <div v-show="showSpecificPeriod">
                    <div class="row">
                        <div class="col-md-6">
                            <HtFormFlatPickr
                                :id="'date_start'"
                                v-model="form.date_start"
                                :name="'date_start'"
                                :label="translate('Start date')"
                                :show-optional="false"
                                :max-date="form.date_end"
                                :date-format="'Y-m-d'"
                                :is-working-day="false"
                            />
                        </div>
                        <div class="col-md-6">
                            <HtFormFlatPickr
                                :id="'date_end'"
                                v-model="form.date_end"
                                :name="'date_end'"
                                :label="translate('End date')"
                                :show-optional="false"
                                :min-date="form.date_start"
                                :date-format="'Y-m-d'"
                                :is-working-day="false"
                            />
                        </div>
                    </div>
                </div>
                <HtFormSwitch
                    :id="'show_filters'"
                    v-model="form.show_filters"
                    :name="'show_filters'"
                    :show-optional="false"
                    :label="translate('Apply filters')"
                    :class="'mb-0'"
                />
                <div v-show="showFilters">
                    <HtFormFilters
                        v-model="form.filters"
                        :class="'mt-3 mb-0'"
                    />
                </div>
                <HtFormSwitch
                    :id="'show_statuses'"
                    v-model="specificForm.show_statuses"
                    :name="'show_statuses'"
                    :show-optional="false"
                    :label="translate('Choose the status of employees')"
                    :class="'mb-0 mt-5'"
                />
                <div v-show="specificForm.show_statuses">
                    <HtFormCheckbox
                        :id="'statuses'"
                        v-model="specificForm.statuses"
                        :name="'statuses'"
                        :options="statuses"
                        :show-optional="false"
                        :class="'mt-3 mb-0'"
                    />
                </div>
                <HtFormRadio
                    :id="'format'"
                    v-model="form.format"
                    :name="'format'"
                    :show-optional="false"
                    :label="translate('Export type')"
                    :options="types"
                    :class="'mt-5 mb-0'"
                />
            </HtFormCard>
        </template>
        <template #buttonsFooter>
            <Button
                :class="buttonState"
                :state="buttonState"
                @click="download"
            >
                <t>Download</t>
            </Button>
        </template>
    </StepperTabLayout>
</template>

<script>
import HtFormRadio from '@/components/globals/HtFormRadio.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtFormFilters from '@/components/globals/HtFormFilters.vue';
import ExportMixin from '@/components/pages/settings/dataAnalysis/mixins/ExportMixin';
import api from '@/store/api';

export default {
    name: 'ExportUsersForm',
    components: {
        HtFormRadio,
        HtFormSelect,
        HtFormSwitch,
        HtFormFilters,
    },
    mixins: [
        ExportMixin,
    ],

    data() {
        return {
            specificForm: {
                show_statuses: false,
                statuses: [],
            },
        };
    },

    computed: {
        statuses() {
            return [
                {
                    value: 'active',
                    text: this.translate('Active'),
                },
                {
                    value: 'invited',
                    text: this.translate('Invited'),
                },
                {
                    value: 'inactive',
                    text: this.translate('Inactive'),
                },
            ];
        },
    },

    methods: {
        download() {
            const data = { ...this.form, ...this.specificForm };

            this.send(api.miscellaneous.exportUsers, data);
        },
    },
};
</script>

<style lang="scss" scoped>
li {
    margin: 10px 0;
    list-style-type: disc;
    list-style-position: inside;

    &:last-child {
        margin-bottom: 0;
    }

    &:first-child {
        margin-top: 0;
    }
}
</style>
