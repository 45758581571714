<template>
    <div class="team-directory">
        <div
            v-for="user in users"
            :key="user.id"
            class="team-directory-item"
            @click="goToProfile(user.id)"
        >
            <div
                v-if="user.image !== $Utils.getDefaultUserImage()"
                class="team-user-image"
                :style="{'background-image': `url(${$Utils.resolveS3PublicImage(user.image)})`}"
            />
            <div
                v-else
                v-user-image="{
                    image: user.image,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    size: 5
                }"
                class="team-user-image full"
            />

            <div class="team-user-wrapper">
                <div class="team-user-name">
                    {{ user.firstname }} {{ user.lastname }}
                </div>

                <div
                    v-if="user.company_job_position"
                    class="team-user-job-title"
                >
                    {{ user.company_job_position.name }}
                </div>

                <div
                    class="team-user-email branding-color"
                    :title="user.professional_email"
                >
                    {{ user.professional_email }}
                </div>
            </div>
        </div>

        <div
            v-if="users.length === 0"
            :key="0"
            class="empty-component"
        >
            <p>
                <strong>
                    <t>No results for your search / selection</t>
                </strong>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TeamList',

    props: {
        users: {
            type: Array,
            required: true,
        },
    },

    methods: {
        goToProfile(id) {
            this.$router.push({
                name: 'Profile',
                params: { company_user_id: id },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/styles/var';

    .team-directory{
        display: grid;
        column-gap: 16px;

        @media (max-width: $phone) {
            grid-template-columns: minmax(0, 1fr);
        }
        @media (min-width: $phone) and (max-width: $tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @media (min-width: $tablet) and (max-width: $tablet-large) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        @media (min-width: $tablet-large) and (max-width: $desktop-medium) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
        @media (min-width: $desktop-medium) and (max-width: $desktop-large) {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }
        @media (min-width: $desktop-large) {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }

        .team-directory-item {
            cursor: pointer;
            margin-bottom: 20px;

            .team-user-image {
                aspect-ratio: 1;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                border-radius: 20px;
                border-top-left-radius: 0;
            }

            .team-user-wrapper {
                padding-top: 1em;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                .team-user-name {
                    font-family: $lato-bold;
                }

                .team-user-job-title {
                    color: $color-text-grey;
                    padding-top: 0.5em;
                }

                .team-user-email {
                    font-size: 0.8em;
                    padding-top: 0.7em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
</style>
