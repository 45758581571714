<template>
    <HtCard>
        <template #title>
            <div>
                <DropDown
                    :options="companiesOptions"
                    :value="selectedCompanyId"
                    @input="$emit('select-company', $event)"
                />
                <div class="nb-collaborators">
                    <t :count="usersCount">
                        <template v-if="countAdmin">
                            {count} admin | {count} admins
                        </template>
                        <template v-else>
                            {count} collaborator | {count} collaborators
                        </template>
                    </t>
                </div>
            </div>
            <div class="buttons">
                <Button
                    v-for="button in buttons"
                    :key="button.id"
                    @click="onButtonClick(button.id)"
                >
                    {{ button.label }}
                </Button>
            </div>
        </template>
        <template #default>
            <slot />
        </template>
    </HtCard>
</template>

<script>
import { mapState } from 'vuex';
import HtCard from './HtCard.vue';
import DropDown from '../holding/DropDown.vue';

export default {
    name: 'HoldingDataList',
    components: {
        HtCard,
        DropDown,
    },

    props: {
        /**
         * if true show amount of admin, else of collaborators
         */
        countAdmin: {
            type: Boolean,
            default: false,
        },
        /**
         * options for dropdown
         */
        companiesOptions: {
            type: Array,
            required: true,
        },
        /**
         * company selected in the dropdown
         */
        selectedCompanyId: {
            type: Number,
            required: true,
        },
        buttons: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            search: null,
            selectedProgramId: null,
        };
    },

    computed: {
        ...mapState('companies', [
            'companies',
        ]),

        /**
         * @returns {number}
         */
        usersCount() {
            const currentCompany = this.companies
                .find((company) => company.id === this.selectedCompanyId);
            return currentCompany.users_count;
        },
    },

    methods: {
        onButtonClick(id) {
            this.$emit('onButtonClick', id);
        },
    },
};
</script>

<style lang="scss" scoped>
.nb-collaborators {
    font-size: 1.4rem;
    font-weight: 400;
}
.buttons {
    display: flex;
    gap:1rem;
}
</style>
