export default {
    props: {
        defaultOrderOnboardingStart: {
            type: Number,
            default: 0,
        },
        defaultOrderOnboardingEnd: {
            type: Number,
            default: 0,
        },
        addDefaultOrderCompletion: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        // TAKE CURRENT ITEM AS DEFAULT MODEL
        setDefaultOrderCompletion(item = this.item) {
            if (item && item.isLoaded() && this.addDefaultOrderCompletion === true) {
                if ('order_onboarding_start' in item) {
                    item.order_onboarding_start = this.defaultOrderOnboardingStart;
                }
                if ('order_onboarding_end' in item) {
                    item.order_onboarding_end = this.defaultOrderOnboardingEnd;
                }
            }
        },
    },
};
