<template>
    <div class="formatted-text">
        <div
            v-for="line in text.replace(/\&nbsp\;/g, ' ').trim().split('\n')"
            class="formatted-text--line"
        >
            {{ line }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'HtFormattedText',
    props: {
        text: { type: String, required: true },
    },
};
</script>

<style scoped>
    .formatted-text--line {
        min-height: 1.6rem;
    }
</style>
