<template>
    <div>
        <HtFormInput
            :id="companyUserRequirement.name"
            v-model="companyUserRequirement.value"
            v-validate.disable="'length:15'"
            :data-vv-as="translate('social security number')"
            :class="errorMessage ? 'mb-2' : ''"
            :name="companyUserRequirement.name"
            :is-required="companyUserRequirement.is_mandatory"
            type="text"
            :cypress="companyUserRequirement.name"
            :disabled="!isParticipant || isDisabled"
            @input="onChanged"
        />
        <small
            v-if="errorMessage"
            class="ht-form-error mt-3"
        >
            {{ errorMessage }}
        </small>
    </div>
</template>
<script>
import UserRequirementEditMixin from './UserRequirementEditMixin';

export default {
    name: 'UserRequirementSocialSecurityNumberEdit',

    mixins: [UserRequirementEditMixin],

    inject: ['$validator'],
    props: {
        isParticipant: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            errorMessage: null,
        };
    },

    methods: {
        async onChanged() {
            this.errorMessage = null;
            await this.$validator.validateAll().then((isValid) => {
                if (isValid) {
                    const checkChanged = this.companyUserRequirement.value !== null && this.companyUserRequirement.value.trim() !== '';
                    this.updateRequirementWithDebounce(checkChanged);
                }
            });
        },
    },
};
</script>
