<template>
    <div class="ht-sidebar">
        <div
            v-if="hasDefaultSlot"
            class="ht-sidebar-default"
        >
            <slot />
        </div>
        <div
            v-if="hasFooterSlot"
            class="ht-sidebar-footer"
        >
            <slot :name="'footer'" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'HtSidebar',
    computed: {
        hasDefaultSlot() {
            return 'default' in this.$slots;
        },
        hasFooterSlot() {
            return 'footer' in this.$slots;
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.ht-sidebar {
    min-width: 25rem;
    background-color: white;
    border-radius: $border-radius-8;

    &-default {
        padding: 16px;
    }

    &-footer {
        border-top: 1px solid $grey-light;
        padding: 16px;
    }
}

@media (max-width: $tablet) {
    .ht-sidebar {
        width: 100%;
        margin: 0 0 2rem;
        display: flex;
        justify-content: center;
        border-radius: 0;

        &-default {
            padding: 0;
            overflow-y: scroll;
            border-bottom: #E2E2E2 1px solid;

            // Hiding the scrollbar
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
            &::-webkit-scrollbar {
                display: none; /* for Chrome, Safari, and Opera */
            }
        }

        &.ht-stepper-sticky {
            .ht-sidebar-default {
                display: flex;
                justify-content: center;
                transform: rotate(-90deg);
                border-bottom: none;
                margin-top: -15rem;
                margin-bottom: -15rem;
            }

            .ht-sidebar-footer {
                display: none;
            }
        }
    }
}

@media (max-width: $phone) {
    .ht-sidebar.ht-stepper-sticky {
        width: 360px;

        .ht-sidebar-default {
            display: flex;
            justify-content: center;
            transform: rotate(-90deg);
            border-bottom: none;
            margin-top: -5rem;
            margin-bottom: -5rem;
        }
    }
}
</style>
