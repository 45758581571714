<template>
    <div class="ht-input">
        <input
            :id="id"
            ref="input"
            v-validate.continues="rules"
            :class="{'errors': errors.collect(name).length}"
            :data-vv-as="type"
            data-vv-validate-on=""
            :name="name"
            :value="value"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :data-cy="cypress"
            @input="$emit('input', $event.target.value)"
            @change="$emit('change', $event.target.value)"
            @blur="$emit('blur',$event)"
            @touchstart.stop
        >
        <div
            v-if="actionButton != ''"
            class="action"
            @click="$emit('action')"
        >
            {{ actionButton }}
        </div>
        <div
            v-if="errors.has(name)"
            class="field-errors"
        >
            <p
                v-for="(error, index) in errors.collect(name)"
                :key="index"
            >
                {{ error }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HtInputText',
    props: {
        value: {
            type: [Number, String],
            default: () => '',
        },

        id: {
            type: String,
            required: true,
        },

        name: {
            type: String,
            required: true,
        },

        disabled: {
            type: Boolean,
            default: () => false,
        },

        cypress: {
            type: String,
            default: '',
        },

        placeholder: {
            type: String,
            default: () => null,
        },

        actionButton: {
            type: String,
            default: '',
        },

        type: {
            type: String,
            default: 'text',
        },
        rules: {
            type: String,
            default: '',
        },
    },
    computed: {
        selfErrors() {
            return this.errors.items.filter((error) => error.field === this.name);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

.ht-input {
    width: 100%;
    font-size: 1.4rem;
    border: 1px solid $grey-ultra-light;
    border-radius: 5px;
    transition: $transition;
    display: flex;
    align-content: center;

    & input:focus {
        border-color: $primary-light;
        outline: 0;
    }

    & input:disabled {
        color: $color-grey;
        background: #f7f7f7;
    }

    & input {
        height:100%;
        border: 0;
        flex-grow:1;
    }

    & input, & .action {
        padding: 15px;
    }

    & .action {
        cursor: pointer;
    }

    & .errors {
        background-color: rgba(255, 0, 0, 0.66);
    }

    .field-errors {
        position: absolute;
        width: 500px;
        margin-left: 3px;
        z-index: 100;
        top: 100%;
        margin-top: 3px;
        p {
            color: red;
            font-size: 14px;
            font-style: italic;
            &::before {
                content: '*';
            }
        }
    }
}
</style>
