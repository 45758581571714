import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import CompanyEquipment from './CompanyEquipment';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import CompanyUserEquipmentCollection from './CompanyUserEquipmentCollection';
import CompanyUserProgram from './CompanyUserProgram';
import I18n from '../modules/i18n/I18n';

export default class CompanyUserEquipment extends Model {
    static STATUS_DONE = 'done';

    static STATUS_DRAFT = 'draft';

    static STATUS_PENDING_ADMIN = 'pending_admin';

    modelConfig() {
        return {
            controller: 'CompanyUserEquipmentController',
            collection: CompanyUserEquipmentCollection,
        };
    }

    modelCustomAttributes() {
        return {
            statusText: null,
            name: '',
        };
    }

    modelDefaultValues() {
        return {
            order_onboarding_start: 0,
	        order_onboarding_end: 0,
        };
    }

    modelRelations() {
        return {
            companyUserProgram: () => this.belongsTo(CompanyUserProgram, 'company_user_program_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyEquipment: () => this.belongsTo(CompanyEquipment, 'company_equipment_id', 'id'),
            companyUserProgramTask: () => this.belongsTo(CompanyUserProgramTask, 'company_user_program_task_id', 'id'),
        };
    }

    modelAccessors() {
        return {
            statusText: () => {
                switch (this.status) {
                case CompanyUserEquipment.STATUS_DONE:
                    return I18n.translate('Done');
                case CompanyUserEquipment.STATUS_PENDING_ADMIN:
                    return I18n.translate('Pending');
                case CompanyUserEquipment.STATUS_DRAFT:
                    return I18n.translate('Draft');
                default:
                    return '';
                }
            },
            name: () => {
                if (!this.company_equipment || !this.company_user?.company_language) return '';
                return this
                    .company_equipment.resource.translations
                    .name[this.company_user.company_language.key]
                    ?? this.company_equipment.resource.name;
            },
        };
    }
}
