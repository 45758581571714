<template>
    <div v-if="companyUserPersonal.isLoaded()">
        <ProfileBloc
            :is-edit.sync="isEdit"
            :can-edit="canModifyPersonalInformation"
            :button-state="buttonState"
            @on-save="onUpdate"
        >
            <div class="profile-rows">
                <div class="profile-row">
                    <div class="profile-section-title">
                        <t>Personal information</t>
                    </div>
                </div>
                <div class="profile-row grid-2-col">
                    <!-- PERSONAL EMAIL -->
                    <div
                        class="profile-item"
                        :class="!isEdit ? 'no-margin-compensation' : ''"
                    >
                        <div class="profile-item-title d-flex align-items-center">
                            <t>Personal E-mail</t>
                            <div
                                v-if="companyUserPersonal.main_email === 'personal_email'"
                                v-tooltip.bottom="translate('This is the login connexion')"
                                class="cursor-help d-flex align-items-center justify-content-center tooltip-wrapper ml-10"
                            >
                                <FontAwesomeIcon
                                    :icon="['fal', 'question-circle']"
                                    size="1x"
                                    class="icon cursor-help fa-w-10"
                                />
                            </div>
                        </div>

                        <div
                            v-show="!isEdit"
                            class="profile-item-content"
                        >
                            {{ companyUserPersonal.$.personal_email | empty }}
                        </div>

                        <HtFormInput
                            v-show="isEdit"
                            :id="'personal_email'"
                            v-model.trim="companyUserPersonal.personal_email"
                            v-validate.disable="'required|email'"
                            :name="'personal_email'"
                            :data-vv-as="translate('personal email')"
                        />
                        <div
                            v-if="errorInfo['email_personal']"
                            class="error-message"
                        >
                            <span><t>{{ errorInfo['email_personal'] }}</t></span>
                        </div>
                    </div>

                    <!-- BIRTHDAY -->
                    <div
                        class="profile-item"
                        :class="!isEdit ? 'no-margin-compensation' : ''"
                    >
                        <div class="profile-item-title">
                            <t>Birthday</t>
                        </div>

                        <div
                            v-if="!isEdit"
                            class="profile-item-content"
                        >
                            {{ companyUserPersonal.$.date_of_birth ? $Utils.moment(companyUserPersonal.$.date_of_birth).format('L') : null | empty }}
                        </div>
                        <fieldset
                            v-show="isEdit"
                            :class="{ error: errors.has('dob') }"
                            class="dob"
                        >
                            <HtFormFlatPickr
                                :id="'dob'"
                                v-model.trim="companyUserPersonal.date_of_birth"
                                :name="'dob'"
                                :is-working-day="false"
                            />
                        </fieldset>
                    </div>
                </div>
                <div class="profile-row grid-2-col">
                    <!-- PROFESSIONAL EMAIL -->
                    <div
                        class="profile-item"
                        :class="!isEdit ? 'no-margin-compensation' : ''"
                    >
                        <div class="profile-item-title d-flex align-items-center">
                            <t>Professional E-mail</t>
                            <div
                                v-if="companyUserPersonal.main_email === 'professional_email'"
                                v-tooltip.bottom="translate('This is the login connexion')"
                                class="cursor-help d-flex align-items-center justify-content-center tooltip-wrapper ml-10"
                            >
                                <FontAwesomeIcon
                                    :icon="['fal', 'question-circle']"
                                    size="1x"
                                    class="icon cursor-help fa-w-10"
                                />
                            </div>
                        </div>

                        <div
                            v-show="!isEdit"
                            class="profile-item-content"
                        >
                            {{ companyUserPersonal.$.professional_email | empty }}
                        </div>
                        <HtFormInput
                            v-show="isEdit"
                            :id="'email_professional'"
                            v-model.trim="companyUserPersonal.professional_email"
                            v-validate.disable="professionalEmailRules"
                            :name="'email_professional'"
                            :data-vv-as="translate('professional email')"
                        />
                        <div
                            v-if="errorInfo['email_professional']"
                            class="error-message"
                        >
                            <span><t>{{ errorInfo['email_professional'] }}</t></span>
                        </div>
                    </div>
                    <!-- NATIONALITY -->
                    <div
                        class="profile-item"
                        :class="!isEdit ? 'no-margin-compensation' : ''"
                    >
                        <div class="profile-item-title">
                            <t>Nationality</t>
                        </div>
                        <div
                            v-show="!isEdit"
                            class="profile-item-content"
                        >
                            {{ nationality | empty }}
                        </div>
                        <HtFormSelect
                            v-if="isEdit && getNationalityOption.length > 0"
                            :id="'nationality'"
                            v-model="companyUserPersonal.ht_nationality_id"
                            :name="'nationality'"
                            :options="getNationalityOption"
                        />
                    </div>
                </div>
                <div class="profile-row grid-2-col">
                    <!-- GENDER -->
                    <div
                        class="profile-item form-2"
                        :class="!isEdit ? 'no-margin-compensation' : ''"
                    >
                        <div class="profile-item-title">
                            <t>Gender</t>
                        </div>
                        <div
                            v-show="!isEdit"
                            class="profile-item-content"
                        >
                            {{ translate($Utils.capitalize(companyUserPersonal.$.gender || '')) }}
                        </div>
                        <GenderSelection
                            v-show="isEdit"
                            :company-user="companyUserPersonal"
                        />
                    </div>

                    <!-- PHONE NUMBER -->
                    <div
                        class="profile-item"
                        :class="{'error': errorBag.hasShowable('phone_number')}"
                    >
                        <div class="profile-item-title">
                            <t>Phone number</t>
                        </div>
                        <div
                            v-show="!isEdit"
                            class="profile-item-content"
                        >
                            <span v-if="companyUserPersonal.$.phone_number">{{ companyUserPersonal.$.phone_country_code }}{{ companyUserPersonal.$.phone_number }}</span>
                            <span v-else>-</span>
                        </div>
                        <div
                            v-show="isEdit"
                            class="row"
                        >
                            <HtFormMultiSelect
                                :id="'phoneFlag'"
                                v-model="companyUserPersonal.phone_country_code"
                                :options="countryCodeList"
                                :name="'phoneFlag'"
                                :track-by="'name'"
                                :is-single="true"
                                :specific-key="'dial_code'"
                                class="col-md-6"
                            >
                                <template
                                    slot="singleLabel"
                                    slot-scope="{ option }"
                                >
                                    <div class="d-flex align-items-center">
                                        <country-flag
                                            :country="option.code"
                                            size="small"
                                        />
                                        <span class="single-ellipsis ml-10">{{ option.dial_code }} ({{ option.name }})</span>
                                    </div>
                                </template>
                                <template
                                    slot="option"
                                    slot-scope="{ option }"
                                >
                                    <div class="d-flex align-items-center">
                                        <country-flag
                                            :country="option.code"
                                            size="small"
                                        />
                                        <span class="ml-10">{{ option.dial_code }} ({{ option.name }})</span>
                                    </div>
                                </template>
                            </HtFormMultiSelect>

                            <HtFormInput
                                :id="'phone_number'"
                                v-model.trim="companyUserPersonal.phone_number"
                                v-validate.disable="phoneRules"
                                :name="'phone_number'"
                                :data-vv-as="translate('phone number')"
                                :placeholder="'6********'"
                                class="col-md-6"
                            />
                        </div>
                        <div
                            v-if="errorInfo['phone']"
                            class="error-message"
                        >
                            <span><t>{{ errorInfo['phone'] }}</t></span>
                        </div>
                    </div>
                </div>
                <!-- Custom entities -->
                <ProfilCustomEntities
                    :company-user="companyUserPersonal"
                    :entity-ids="entityIds"
                    :is-edit="isEdit"
                />
            </div>
        </ProfileBloc>
    </div>
</template>
<script>
import HtNationalityCollection from '@/models/HtNationalityCollection';
import CountryCodeList from '@/CountryCode';
import CompanyUser from '@/models/CompanyUser';
import ProfileBlocMixin from '@/components/mixins/ProfileBlocMixin';
import GenderSelection from '@/components/globals/GenderSelection.vue';
import profileEventBus from '@/eventBus/profileEventBus';
import ProfileBloc from '../ProfileBloc.vue';
import ProfilCustomEntities from '../profile/ProfilCustomEntities/ProfilCustomEntities.vue';

export default {
    name: 'ProfileAdministrativePersonnal',
    components: {
        ProfileBloc,
        GenderSelection,
        ProfilCustomEntities,
    },
    mixins: [
        ProfileBlocMixin,
    ],

    shared: {
        data: {
            htNationalityCollection: {
                type: HtNationalityCollection,
                default: null,
            },
        },
        queries: {
            htNationalityCollection: {
                type: HtNationalityCollection,
                default: null,
            },
        },
    },

    data() {
        return {
            countryCodeList: [],
            errorInfo: {
                phone: null,
                email_professionnal: null,
                email_personnal: null,
            },
            /**
             * Utilisateur avec ses données personnelles
             */
            companyUserPersonal: new CompanyUser([
                'id',
                'date_of_birth',
                'ht_nationality_id',
                'main_email',
                'personal_email',
                'phone_country_code',
                'phone_number',
                'professional_email',
                'gender',
            ]).where([
                ['id', '=', this.companyUser.id],
            ]).with({
                defaultEntities: (query) => query.select([
                    'id',
                    'company_entity_value_id',
                    'company_entity_id',
                ]).with({
                    value: (query) => {
                        query.select([
                            'id',
                            'company_entity_id',
                            'resourceable_id',
                        ]).with({
                            custom: (query) => query.select(['id', 'key', 'name']),
                        });
                    },
                    entity: (query) => {
                        query.select([
                            'id',
                            'name',
                            'slug',
                            'is_private',
                            'is_heyteam',
                        ]);
                    },
                }),
            }),
        };
    },

    computed: {
        phoneRules() {
            const validation = `numeric|unique_phone:${this.companyUserPersonal.phone_country_code},${this.companyUserPersonal.id}`;

            return validation;
        },

        professionalEmailRules() {
            const rules = {
                required: false,
                email: true,
            };

            if (this.companyUserPersonal.main_email === 'professional_email') {
                rules.required = true;
            }

            return rules;
        },

        getGenderOption() {
            return [
                {
                    name: null,
                    id: null,
                },
                {
                    name: this.translate('Male'),
                    id: 'male',
                },
                {
                    name: this.translate('Female'),
                    id: 'female',
                },
            ];
        },

        getNationalityOption() {
            return this.shared.queries.htNationalityCollection.isLoaded() && this.shared.data.htNationalityCollection
                ? this.shared.data.htNationalityCollection.models.map((nationality) => ({
                    name: nationality[`name_${(this.currentUserLang === 'fr') ? 'fr' : 'en'}`],
                    id: nationality.id,
                })).sort((a, b) => a.name.localeCompare(b.name)) : [];
        },

        currentDateFormat() {
            return (this.currentUserLang === 'fr') ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
        },

        currentUserLang() {
            return this.shared.session.companyUser.isLoaded()
                ? this.shared.session.companyUser.company_language.key
                : 'en';
        },

        nationality() {
            if (
                this.companyUserPersonal.ht_nationality_id === null
                || this.shared.data.htNationalityCollection === null
            ) {
                return null;
            }

            const htNationality = this.shared.data.htNationalityCollection.models.find((item) => this.companyUserPersonal.ht_nationality_id === item.id);

            return htNationality ? htNationality[`name_${this.currentUserLang === 'fr' ? 'fr' : 'en'}`] : null;
        },

        entityIds() {
            return this.companyUser.default_entities.models.filter((entity) => Boolean(entity.entity.is_private) && !entity.entity.is_heyteam).map((entity) => entity.entity.id);
        },
    },

    created() {
        this.companyUserPersonal.setLookupMethod('lookupPersonal');
        this.companyUserPersonal.get();

        if (this.shared.queries.htNationalityCollection.isLoaded() === false) {
            this.shared.queries.htNationalityCollection.get().then((data) => {
                this.shared.data.htNationalityCollection = data;
            });
        }

        this.countryCodeList = CountryCodeList.sort((a, b) => a.name.localeCompare(b.name));
    },

    methods: {
        onUpdate() {
            this.errorBag.showErrors();
            this.errorInfo = {
                phone: null,
                email_professionnal: null,
                email_personnal: null,
            };

            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.buttonState = 'loading';

                    if (!this.companyUserPersonal.phone_number || !this.companyUserPersonal.phone_country_code) {
                        this.companyUserPersonal.phone_country_code = null;
                        this.companyUserPersonal.phone_number = null;
                    }

                    const hasProfessionalChanged = this.companyUserPersonal.$.professional_email !== this.companyUserPersonal.professional_email;

                    this.companyUserPersonal.save().then(() => {
                        this.isEdit = false;
                        this.buttonState = 'idle';
                        if (hasProfessionalChanged) {
                            profileEventBus.$emit('reload-user');
                        }
                    }, ({ error }) => {
                        if (error.includes('phone')) this.errorInfo.phone = error;
                        if (error.includes('professional')) this.errorInfo.email_professional = error;
                        if (error.includes('personnal')) this.errorInfo.email_personnal = error;

                        this.buttonState = 'idle';
                    }).catch(() => {
                        this.buttonState = 'idle';
                    });
                } else {
                    this.buttonState = 'idle';
                }
            });
        },
    },

};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.profile-item:not(.no-margin-compensation) {
    // Compensating ht-form bottom margin
    margin-bottom: -2.5rem;
}

.profile-item {
    margin-top: 0;
}

.profile-row {
    margin-top: 3.2rem;
}

.profile-row:first-child {
    margin-top: 0;
}

.profile-row.grid-2-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3.2rem 2.1rem;
}

.profile-item {
    padding: 0;
}

.profile-item-title {
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
}

.profile-item-content {
    padding-top: 0.4rem;
}

.dob {
    .ht-form {
        margin-bottom: 0.5rem;
    }
}
.tooltip-wrapper {
    width: 12px;
    height: 12px;
    border-radius: 50%;

    .icon {
        cursor: help;
        font-size: 12px;
        color: $color-text-grey;
    }
}

.cursor-help {
    cursor: help !important;
}
</style>
