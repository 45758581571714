<template>
    <HoldingResourceList
        :route-list="'HoldingResourcesSoftwareList'"
        :collection="software"
        :use-resource-program="false"
    >
        <template #table="holdingResourceList">
            <SoftwareListModels
                :items="holdingResourceList.items"
                :external-search="holdingResourceList.search"
                :columns-to-hide="['filters']"
                @onCellClicked="holdingResourceList.onCellClicked"
            />
        </template>
    </HoldingResourceList>
</template>
<script>
import HoldingResourceList from '@/components/globals/HoldingResourceList.vue';
import SoftwareListModels, { softwareConfig } from '@/resourcesList/SoftwareListModels.vue';
import CompanySoftwareCollection from '@/models/CompanySoftwareCollection';

export default {
    name: 'PageSoftwareList',
    components: {
        HoldingResourceList,
        SoftwareListModels,
    },

    data() {
        return {
            software: new CompanySoftwareCollection(softwareConfig.fields).with(softwareConfig.relations),
        };
    },
};
</script>
