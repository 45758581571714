<template>
    <div class="wrapper-user">
        <div class="wrapper-user-info">
            <div
                v-user-image="image"
                class="wrapper-user-img"
            />

            <div class="ml-10">
                {{ label }}
            </div>
        </div>

        <div
            class="wrapper-user-delete"
            @click.stop="$emit('on-remove')"
        >
            <FontAwesomeIcon
                v-if="!disabled"
                size="2x"
                class="times"
                icon="times"
            />
        </div>
    </div>
</template>

<script>
import I18n from '@/modules/i18n/I18n';

export default {
    name: 'InputParticipantItem',

    props: {
        participant: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        label() {
            if (this.participant.user) {
                return `${this.participant.user.firstname} ${this.participant.user.lastname}`;
            }

            return this.participant.role.is_heyteam ? I18n.translate(this.participant.role.alias) : this.participant.role.alias;
        },
        image() {
            let user = null;

            if (this.participant.user) {
                user = {
                    firstname: this.participant.user.firstname,
                    lastname: this.participant.user.lastname,
                    image: this.participant.user.image,
                };
            }

            return this.$Utils.getCompanyComponentRoleInitials(
                this.participant.user ? 'user' : this.participant.role.alias,
                1,
                user,
            );
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/styles/var';

    .input-wrapper {
        .wrapper-user {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: $border-radius-16;
            background: $black;
            padding-left: 10px;
            height: calc(48px - 12px);
            margin-top: 6px;

            &:not(:last-child) {
                margin-right: 6px;
            }

            &:last-child {
                margin-bottom: 6px;
            }

            &-img {
                min-width: 20px;
                padding: 5px;
                border-radius: $border-radius-8;
                background: $grey;
            }

            &-info {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $white;
            }

            &-delete {
                padding: 0 15px;

                .times {
                    font-size: 10px;
                    color: $white;
                }
            }
        }
    }
</style>
