<template>
    <div v-if="companyUserSurveys.isLoaded()">
        <HtAccordion
            :title="translate('Surveys')"
            :count="companyUserSurveys.$.models.length"
        >
            <HtFormInput
                :id="'surveySearch'"
                v-model.trim="searchValue"
                :name="'surveySearch'"
                :placeholder="translate('Search')"
            >
                <template #extra>
                    <div class="px-4">
                        <FontAwesomeIcon
                            size="1x"
                            :icon="['far', 'search']"
                        />
                    </div>
                </template>
            </HtFormInput>

            <HtTable
                v-if="companyUserSurveys.$.models.length > 0"
                :items="companyUserSurveys.$.models"
                :columns="columns"
                cypress="user-activities"
                :external-search="searchValue"
                @onCellClicked="openItemModal"
            >
                <template #cell(name)="scope">
                    {{ scope.item.name }}
                </template>
                <template #cell(display_status)="scope">
                    <HtTag :color="color(scope.item.company_user_program_task.status)">
                        {{ scope.item.company_user_program_task.statusText }}
                    </HtTag>
                </template>
                <template #cell(participant)="scope">
                    <ParticipantList :users="getParticipant(scope.item)" />
                </template>
            </HtTable>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No Survey added</t>
                </strong>
            </div>
        </HtAccordion>

        <modalable
            ref="modalableItem"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserSurveyItem
                :id="currentId"
                ref="userItem"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </modalable>
    </div>
</template>

<script>
import HtAccordion from '@/components/globals/HtAccordion.vue';
import UserSurveyItem from '@/components/pages/dashboard/modals/survey/UserSurveyItem.vue';
import CompanyUserSurveyCollection from '@/models/CompanyUserSurveyCollection';
import resourceEventBus from '@/eventBus/resourceEventBus';

export default {
    name: 'UserSurveyProfile',
    components: {
        HtAccordion,
        UserSurveyItem,
    },
    props: {
        companyUserId: {
            type: Number,
            required: false,
        },
    },

    data() {
        return {
            currentId: null,
            searchValue: null,
            columns: [
                {
                    label: this.translate('status'),
                    key: 'display_status',
                    sortable: false,
                },
                {
                    label: this.translate('Name'),
                    search: true,
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                },
                {
                    label: this.translate('Participants'),
                    key: 'participant',
                    sortable: false,
                },
                {
                    label: this.translate('Program linked'),
                    key: 'company_user_program.company_program.name_translated',
                    sortable: false,
                },
            ],
            companyUserSurveys: new CompanyUserSurveyCollection([
                'id', 'company_user_program_id', 'company_user_id', 'company_survey_id', 'permissions',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserProgramTask: (query) => {
                    query.select([
                        'status',
                        'datetime_availability',
                    ]);
                },
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
                companyUserParticipant: (query) => {
                    query
                        .select(['id', 'firstname', 'lastname', 'gender', 'image', 'company_language_id'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companySurvey: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            resource: (query) => {
                                query.select([
                                    'id',
                                    'name',
                                ]);
                            },
                        });
                },
            })
                .where([
                    ['company_user_id', '=', this.companyUserId],
                ]),
        };
    },

    created() {
        this.companyUserSurveys.get().then(() => {
            this.$emit('on-load', this.companyUserSurveys.$.models.length);
        });
        resourceEventBus.$on('survey-done', () => {
            this.companyUserSurveys.get();
        });
    },

    methods: {
        color(status) {
            const colors = {
                draft: 'orange',
                in_progress: 'purple',
                pending: 'purple',
                done: 'green',
            };

            return colors[status];
        },

        getSurveyRelatedUser(item) {
            return [{ ...item.company_user, company_user: item.company_user }];
        },

        getParticipant(item) {
            return [{ ...item.company_user_participant, company_user: item.company_user_participant }];
        },

        onUpdate() {
            this.companyUserSurveys.get();
        },

        onDelete() {
            this.companyUserSurveys.get();
        },

        openItemModal(row) {
            this.currentId = row.item.id;
            this.$refs.modalableItem.open();
        },
    },
};
</script>

<style lang="scss" scoped>
    .item-image {
        height: 40px;
        width: 40px;
    }

    .items-bar {
        display: flex;
        justify-content: center;
        h2 {
            font-size: 2.8rem;
            text-transform: none;
        }
    }

    .small-image {
        position: relative;
        background: none !important;
    }

    .survey-related-user {
        position: absolute;
        // right: -12px;
        bottom: -25px;
    }

    .title {
        margin-left: -40px;
    }
</style>
