<template>
    <div>
        <form
            class="form-2 spaced"
            @submit.prevent=""
        >
            <HtFormSelect
                v-show="editingLang === shared.session.company.company_languages.default.key"
                v-if="!item.is_heyteam"
                id="requirement-type"
                v-model="item.type"
                v-validate.disable="'required'"
                :label="translate('Type of requirement')"
                name="requirement-type"
                :data-vv-as="translate('requirement type')"
                :options="requirementTypeOptions"
            />
            <HtLanguageSelect
                id="company-language"
                :value="shared.session.company.company_languages.enabled.find(language => language.key === editingLang)"
                name="company-language"
                :custom-option-label="customLanguageOptionLabel"
                :label="''"
                @input="v => editingLang = v.key"
            />
            <InputField
                ref="nameInput"
                v-model="item.translations.name[editingLang]"
                v-validate.disable="{
                    'translation_default': [
                        item.translations.name,
                        shared.session.company.company_languages.default.key
                    ],
                    'max': 125,
                }"
                :type="'text'"
                :name="'name'"
                :validate-as="translate('name')"
                :disabled="(item.is_heyteam === 1)"
                :placeholder="translate('Requirement label')"
                :cypress="'label'"
            />

            <template v-if="hasTypeOfFileToUpload">
                <transition name="fade">
                    <fieldset :class="{'error': errors.has('files')}">
                        <TranslatableFiles
                            :label="false"
                            :files="item.file_translations"
                            :item="item"
                            :errors-messages="errors"
                            :editing-lang="editingLang"
                            :button-state.sync="button_state"
                            :validation-rules="getFileValidationRules"
                            @input="v => item.file_translations = v"
                            @file-deleted="uploadFileDeleted"
                        />
                        <template v-if="isFileExtensionImageorPdf && item.type === 'file_to_fill'">
                            <HtFormSwitch
                                :id="'toggle-has-variable'"
                                v-model="item.can_fill_document"
                                :name="'toggle-has-variable'"
                                :label="translate('Enable document editing directly on HeyTeam')"
                                :description="translate('Make sure you have edited the document before selecting this option')"
                                :on-label="translate('On')"
                                :off-label="translate('Off')"
                                :show-optional="false"
                                class="mt-5"
                                @input="resetCanFillDocument"
                            />

                            <div
                                v-if="canEditPdf"
                                class="d-flex justify-content-between"
                            >
                                <div
                                    class="d-flex justify-content-center align-items-center edit-requirement branding-color branding-color-darken-hover"
                                    @click="onOpenDocumentEditor()"
                                >
                                    <t>Edit this pdf</t>
                                    <FontAwesomeIcon
                                        class="icon ml-3"
                                        :icon="['fal', 'chevron-right']"
                                    />
                                </div>
                                <span
                                    v-tooltip.right="translate('By clicking here, you can add variables and fields to complete the document')"
                                    class="cursor-help"
                                >
                                    <FontAwesomeIcon icon="info-circle" />
                                </span>
                            </div>
                        </template>
                        <HtFormSwitch
                            v-if="isFileToFill"
                            :id="'toggle-sign-after-complete'"
                            v-model="item.sign_after_complete"
                            :name="'toggle-sign-after-complete'"
                            :on-label="translate('On')"
                            :off-label="translate('Off')"
                            :label="translate('Sign once requirement completed')"
                            :show-optional="false"
                            class="mt-20"
                        />
                        <HtFormGroup
                            v-if="isDelegated"
                            :description="delegatedText"
                            class="mt-5 mb-0"
                        />
                    </fieldset>
                </transition>
            </template>

            <div class="hr" />

            <template v-if="hasTypeOfSelect">
                <label class="mt-4 text-center"><t>List of options</t></label>
                <fieldset
                    v-for="(row, index) in item.translations.question_multiple_value[editingLang]"
                    :key="index"
                >
                    <div class="input-removable">
                        <input
                            v-if="has_key_choice"
                            :ref="`keychoice${index}`"
                            v-model="row.key"
                            v-validate="{ required: true, max: '1000' }"
                            type="text"
                            :name="`keychoice${index}`"
                            class="col-3"
                            :placeholder="translate('Key')"
                            :data-vv-as="translate('Key')"
                        >
                        <input
                            :ref="`choice${index}`"
                            v-model="row.value"
                            v-validate="{ required: true, max: '1000' }"
                            type="text"
                            :name="`choice${index}`"
                            :class="getClassInputMultipleChoice"
                            :placeholder="translate('Please specify')"
                            :data-vv-as="translate('choice')"
                        >
                        <div
                            v-if="item.translations.question_multiple_value[editingLang].length > 2"
                            class="chevron-toggle"
                            @click="deleteInputAndErrors(item.translations.question_multiple_value[editingLang], index,`choice${index}`)"
                        >
                            <svg class="icon icon-trash">
                                <use xlink:href="#icon-trash" />
                            </svg>
                        </div>
                    </div>
                    <div
                        v-for="(errorMessage, errorIndex) in errors.collect(`choice${index}`)"
                        :key="errorIndex"
                        class="error-message"
                    >
                        <t>{{ errorMessage }}</t>
                    </div>
                    <div
                        v-for="(errorMessage, errorIndex) in errors.collect(`keychoice${index}`)"
                        :key="errorIndex"
                        class="error-message"
                    >
                        <t>{{ errorMessage }}</t>
                    </div>
                </fieldset>
                <fieldset class="d-flex justify-content-between add-options">
                    <div
                        class="branding-color clickable"
                        @click="onOpenImportAnswers"
                    >
                        <t>Import answers</t>
                    </div>
                    <div
                        class="clickable"
                        @click="addOptionsToSelect"
                    >
                        <t>Add more options</t>
                    </div>
                </fieldset>
                <HtFormSwitch
                    v-show="editingLang === shared.session.company.company_languages.default.key"
                    :id="'toggle-key-choice'"
                    v-model="has_key_choice"
                    :name="'toggle-key-choice'"
                    :on-label="translate('On')"
                    :off-label="translate('Off')"
                    :label="translate('Show the key')"
                    :show-optional="false"
                />
                <HtFormSwitch
                    v-show="editingLang === shared.session.company.company_languages.default.key"
                    :id="'toggle-multiple-choice'"
                    v-model="is_multiple_choice"
                    :name="'toggle-multiple-choice'"
                    :on-label="translate('On')"
                    :off-label="translate('Off')"
                    :label="translate('User can choose multiple answer')"
                    :show-optional="false"
                />
                <div
                    v-show="editingLang === shared.session.company.company_languages.default.key"
                    class="hr"
                />
            </template>

            <template v-else-if="hasTypeOfFile">
                <div
                    v-show="editingLang === shared.session.company.company_languages.default.key"
                    class="my-4 w-100"
                >
                    <HtFormCheckbox
                        :id="'media'"
                        v-model="item.media"
                        v-validate.disable="'required'"
                        :name="'media'"
                        :label="translate('Document format')"
                        :options="[
                            { value: 'pdf', text: 'PDF' },
                            { value: 'office', text: 'Office (word, excel, txt, csv, etc...)' },
                            { value: 'image', text: 'Image (png, jpg, etc...)' },
                        ]"
                        :data-vv-as="translate('document format')"
                    />
                </div>

                <div
                    v-show="editingLang === shared.session.company.company_languages.default.key"
                    class="hr"
                />

                <fieldset
                    v-show="editingLang === shared.session.company.company_languages.default.key"
                    class="mt-4"
                >
                    <HtFormSelect
                        id="file-max-size"
                        v-model="item.file_max_size"
                        :label="translate('File size')"
                        name="file-max-size"
                        :options="[
                            {id: null, name: translate('Any')},
                            {id: 2, name: '2 ' + translate('MB')},
                            {id: 5, name: '5 ' + translate('MB')},
                            {id: 10, name: '10 ' + translate('MB')},
                        ]"
                    />
                </fieldset>
                <div
                    v-show="editingLang === shared.session.company.company_languages.default.key"
                    class="hr"
                />
            </template>

            <template v-if="hasTypeDynamicDocument">
                <DynamicDocument
                    class="mb-4"
                    :program-id="programId"
                    :display-survey="displaySurvey"
                    :value="dynamicDocument"
                    :editing-language="editingLang"
                    @input="value => dynamicDocument = value"
                />

                <HtFormSwitch
                    :id="'toggle-sign-after-complete'"
                    v-model="item.sign_after_complete"
                    :name="'toggle-sign-after-complete'"
                    :on-label="translate('On')"
                    :off-label="translate('Off')"
                    :label="translate('Sign once requirement completed')"
                    :show-optional="false"
                    class="mt-20"
                />
            </template>

            <template v-if="hasTypeTextWithControl">
                <HtFormSelect
                    id="file-max-size"
                    v-model="item.config_value.control"
                    v-validate.disable="'required'"
                    :data-vv-as="translate('main control')"
                    :label="translate('Main control')"
                    name="main-control"
                    :options="[
                        {id: 'alphabetical', name: translate('Alphabetical')},
                        {id: 'numerical', name: translate('Numerical')},
                        {id: 'decimal', name: translate('Decimal digit')},
                        {id: 'alphanumerical', name: translate('Alphanumerical')},
                    ]"
                    :show-optional="false"
                    :show-asterisk="true"
                />

                <div v-if="item.config_value.control === 'decimal'">
                    <InputField
                        ref="max-decimal"
                        v-model="item.config_value.max_decimal"
                        v-validate="'integer'"
                        :type="'number'"
                        :name="'max-decimal'"
                        :data-vv-as="translate('Maximum decimal')"
                        :label="translate('Maximum decimal')"
                    />
                </div>

                <div v-if="['numerical', 'decimal'].includes(item.config_value.control)">
                    <InputField
                        ref="min-value"
                        v-model="item.config_value.min_value"
                        v-validate="getRuleMinOrMaxValue"
                        :type="'number'"
                        :name="'min-value'"
                        :data-vv-as="translate('minimum value')"
                        :label="translate('Minimum value')"
                    />

                    <InputField
                        ref="max-value"
                        v-model="item.config_value.max_value"
                        v-validate="getRuleMinOrMaxValue"
                        :type="'number'"
                        :name="'max-value'"
                        :data-vv-as="translate('maximum value')"
                        :label="translate('Maximum value')"
                    />
                </div>

                <InputField
                    ref="min-character"
                    v-model="item.config_value.min_character"
                    v-validate="'integer'"
                    :type="'number'"
                    :name="'min-character'"
                    :data-vv-as="translate('minimum number of characters')"
                    :label="translate('Minimum number of characters')"
                />
                <InputField
                    ref="max-character"
                    v-model="item.config_value.max_character"
                    v-validate="getRuleMaxCharacter"
                    :type="'number'"
                    :name="'max-character'"
                    :data-vv-as="translate('maximum number of characters')"
                    :label="translate('Maximum number of characters')"
                />
            </template>

            <HtFormSwitch
                v-show="editingLang === shared.session.company.company_languages.default.key"
                :id="'toggle-is-mandatory'"
                v-model="item.is_mandatory"
                :name="'toggle-is-mandatory'"
                :on-label="translate('On')"
                :off-label="translate('Off')"
                :label="translate('Mandatory field')"
                :show-optional="false"
            />
            <div
                v-show="editingLang === shared.session.company.company_languages.default.key"
                class="hr"
            />

            <fieldset
                v-show="editingLang === shared.session.company.company_languages.default.key"
                class="mt-4"
            >
                <HtFormSelect
                    v-if="optionsAskAfter.length > 0"
                    :id="'askAfter'"
                    v-model.number="item.order"
                    :name="'askAfter'"
                    :label="translate('Ask after')"
                    :options="optionsAskAfter"
                />
            </fieldset>

            <HtFormMultiSelect
                v-if="tagTemplates.length > 0"
                id="tags"
                v-model="currentItemTags"
                :options="tagTemplates"
                name="tags"
                :label="translate('Tags')"
            />
        </form>

        <ModalableLarge
            ref="documentEditorModal"
            class="modalable-1 small"
            :mode="2"
        >
            <AdminEditor
                :document-id.sync="item.company_pdf_form_template_id"
                :company-file="item.file_translations[editingLang]"
                :editing-lang="editingLang"
            />
        </ModalableLarge>
        <ImportAnswersModal
            ref="modalImportAnswers"
            @on-add-answers="onAddAnswers"
        />
        <div class="text-right buttons">
            <Button
                class="negative mt-3"
                :state="button_state"
                @click="cancel"
            >
                Cancel
            </Button>
            <Button
                v-if="!item.isDirty"
                :state="item.is_use_in_dynamic_document ? 'disabled' : button_state"
                class="negative ml-5 mt-3"
                @click="onOpenDelete()"
            >
                <t>Delete</t>
                <span
                    v-if="item.is_use_in_dynamic_document"
                    v-tooltip.right-end="getDeleteTooltip"
                    class="cursor-help ml-3"
                >
                    <FontAwesomeIcon :icon="['fas', 'info-circle']" />
                </span>
            </Button>
            <Button
                :state="button_state"
                class="ml-3 mt-3"
                @click="onValidate()"
            >
                {{ labelButton }}
            </Button>
        </div>
    </div>
</template>

<script>
import CompanyRequirement from '@/models/CompanyRequirement';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import TranslatableFiles from '@/components/form/TranslatableFiles.vue';
import AdminEditor from '@/components/DocumentEditor/AdminEditor.vue';
import HtLanguageSelect from '@/components/globals/HtLanguageSelect.vue';
import get from 'lodash.get';
import ImportAnswersModal from '@/components/importCsv/ImportAnswersModal.vue';
import DynamicDocument from '@/components/globals/DynamicDocument.vue';
import cloneDeep from 'lodash.clonedeep';
import SingleRequirementDelete from '@/components/pages/resources/requirements/form/singleRequirement/singleRequirementDelete/SingleRequirementDelete.vue';

export default {
    name: 'SingleRequirementEdit',
    components: {
        ImportAnswersModal,
        DynamicDocument,
        HtLanguageSelect,
        AdminEditor,
        HtFormSwitch,
        TranslatableFiles,
        SingleRequirementDelete,
    },

    props: {
        currentItem: {
            type: Object,
            default: null,
        },
        companyRequirementCategory: {
            type: Object,
            default: null,
        },
        companyRequirementCollection: {
            type: Array,
            default: null,
        },
        currentItemIndex: {
            type: Number,
            default: 0,
        },
        preparerLabel: {
            type: String,
            default: '',
        },
        displaySurvey: {
            type: Boolean,
            default: false,
        },
        programId: {
            type: Number,
            default: 0,
        },
        requirement: {
            type: CompanyRequirement,
            required: true,
        },
    },

    data() {
        return {
            item: cloneDeep(this.requirement),
            editingLang: this.shared.session.company.company_language.key,
            button_state: 'idle',
            is_multiple_choice: true,
            has_key_choice: false,
            types: [],
        };
    },

    computed: {
        getRuleMaxCharacter() {
            if (this.item.config_value.min_character) {
                return `integer|min_value:${this.item.config_value.min_character}`;
            }

            return 'integer';
        },

        getRuleMinOrMaxValue() {
            const rules = [];
            const configValue = this.item.config_value;

            if (configValue.control === 'decimal') {
                rules.push(configValue.max_decimal ? `decimal:${configValue.max_decimal}` : 'decimal');
            } else {
                rules.push('integer');
            }

            const rulesMoreOrLess = this.getRulesMoreOrLess(
                configValue.min_value,
                configValue.max_value,
                configValue.min_character,
                configValue.max_character,
            );

            return rules.concat(rulesMoreOrLess).join('|');
        },

        getClassInputMultipleChoice() {
            if (!this.has_key_choice) {
                return 'full-width';
            }

            return this.item.translations.question_multiple_value[this.editingLang].length > 2 ? 'col-7' : 'col-8';
        },

        getFileValidationRules() {
            if (this.item.type === 'file_to_fill' || this.displaySurvey) {
                return {
                    translation_default: [
                        this.item.file_translations,
                        this.shared.session.company.company_languages.default.key,
                    ],
                };
            }

            return {};
        },

        isFileToFill() {
            return this.item.type == 'file_to_fill';
        },

        isFileToSign() {
            return this.item.type == 'file_to_sign';
        },

        hasTypeOfFile() {
            return this.item.type == 'file';
        },

        hasTypeOfFileToUpload() {
            return this.isFileToFill || this.isFileToSign;
        },

        hasTypeOfSelect() {
            return this.item.type == 'single_select' || this.item.type == 'multiple_select';
        },

        hasTypeDynamicDocument() {
            return this.item.type == 'dynamic_document';
        },

        hasTypeTextWithControl() {
            return this.item.type == 'text_with_control';
        },

        isFileExtensionImageorPdf() {
            let uploadeFileExtension;
            const lang = this.currentLang();
            const files = this.translatedFiles;

            if (Object.entries(files).length === 0 || !(lang in files)) {
                return false;
            }
            const [, file] = Object.entries(files).find(([key]) => key === lang);
            if (file) {
                uploadeFileExtension = file.original_name.split('.').pop().toLowerCase();
            }
            return uploadeFileExtension ? uploadeFileExtension === 'pdf' || uploadeFileExtension === 'png' || uploadeFileExtension === 'jpg' || uploadeFileExtension === 'jpeg' : false;
        },

        translatedFiles() {
            return this.item.file_translations;
        },

        currentTranslatedFile() {
            const lang = this.currentLang();
            const files = this.translatedFiles;
            const [, file] = Object.entries(files).find(([key]) => key === lang);
            return file;
        },

        labelButton() {
            if (!this.currentItem.isDirty) {
                return this.translate('Save');
            }
            return this.translate('Add');
        },

        requirementTypeOptions() {
            let currentTypes = this.types;

            const hasAnyRequirementThatIsNotDD = this.companyRequirementCollection.length > 1 && this.companyRequirementCollection.some((req) => req.type !== 'dynamic_document');
            const categoryHasAntiDDSetup = (!this.programId && !this.companyRequirementCategory.display_survey)
                || this.companyRequirementCategory.display_chatbot
                || this.companyRequirementCategory.is_duplicable
                || this.companyRequirementCategory.resource.programs.length > 1;

            if (hasAnyRequirementThatIsNotDD || categoryHasAntiDDSetup) {
                currentTypes = currentTypes.filter((type) => type.value !== 'dynamic_document');
            }

            return currentTypes.map((type) => ({
                id: type.value,
                name: this.translate(type.label),
            })).sort((a, b) => a.name.localeCompare(b.name));
        },

        canEditPdf() {
            return this.item.can_fill_document;
        },

        optionsAskAfter() {
            return this.companyRequirementCollection
                .filter((companyRequirement) => (companyRequirement.order !== this.item.order))
                .map((companyRequirement) => {
                    const name = get(companyRequirement.translations.name, `${this.editingLang}`, `${this.shared.session.company.company_language.key}`);
                    return {
                        id: (companyRequirement.order + 1),
                        name,
                    };
                })
                .sort((a, b) => a.text > b.text);
        },

        isDelegated() {
            const lang = this.currentLang();
            const files = this.translatedFiles;
            const correctFile = Object.entries(files).find((file) => file[0] === lang);

            return this.item.type === 'file_to_sign' && !correctFile;
        },

        delegatedText() {
            const role = this.preparerLabel.toLowerCase();
            return this.translate("If you don't upload any files, it will be up to the {role} to deposit the corresponding document on the user's program", {
                role,
            });
        },

        tagTemplates() {
            return this.$store.state.companies.companyTagTemplates;
        },

        currentItemTags: {
            set(tags) {
                this.item.tags = [];

                tags.forEach((tag) => {
                    this.item.tags.push(tag);
                });
            },
            get() {
                return this.item.tags.map((item) => this.tagTemplates.find((t) => t.id === item.id));
            },
        },

        dynamicDocument: {
            set(document) {
                if (!this.item.dynamic_documents.find((doc) => doc.language_key === this.editingLang)) {
                    this.item.dynamic_documents.push({
                        language_key: this.editingLang,
                        html_content: document.html_content,
                        variables: document.variables,
                        advancedSetting: document.advancedSetting,
                    });
                } else {
                    this.item.dynamic_documents.forEach((doc) => {
                        if (doc.language_key === this.editingLang) {
                            this.$set(doc, 'html_content', document.html_content);
                            this.$set(doc, 'variables', document.variables);
                            this.$set(doc, 'advancedSetting', document.advancedSetting);
                        }
                    });
                }
            },
            get() {
                const documentsWithoutAdvancedSetting = this.item.dynamic_documents.filter((doc) => doc.advancedSetting === null);
                documentsWithoutAdvancedSetting.forEach((doc) => {
                    doc.advancedSetting = {
                        margin_top: '0cm',
                        margin_bottom: '0cm',
                        margin_left: '0cm',
                        margin_right: '0cm',
                    };
                });

                if (this.item.dynamic_documents.find((doc) => doc.language_key === this.editingLang)) {
                    return this.item.dynamic_documents.find((doc) => doc.language_key === this.editingLang);
                }
                return {
                    html_content: '',
                    variables: [],
                    advancedSetting: {
                        margin_top: '1cm',
                        margin_bottom: '1cm',
                        margin_left: '1cm',
                        margin_right: '1cm',
                    },
                };
            },
        },
        getDeleteTooltip() {
            const base = this.item.use_in_dynamic_document_list_name.length > 1
                ? this.translate('This requirement cannot be deleted because it is used in the dynamic document of the following requirement categories')
                : this.translate('This requirement cannot be deleted because it is used in the dynamic document of the following requirement category');

            const categoryNames = this.item.use_in_dynamic_document_list_name
                .map((name) => name[this.editingLang])
                .join(', ');

            return `${base} : ${categoryNames}.`;
        },
    },

    watch: {
        'item._state.isSaving': function (val) {
            this.button_state = val ? 'loading' : 'idle';
        },
        'item.type': function () {
            this.item.config_value = this.hasTypeTextWithControl ? this.initConfigValueTextControl() : null;

            if (this.hasTypeOfSelect) {
                const lang = !this.$refs.translatedForm
                    ? this.shared.session.company.company_language.key
                    : this.$refs.translatedForm.editingLang;

                if (!(lang in this.item.translations.question_multiple_value)) {
                    this.$set(this.item.translations.question_multiple_value, lang, []);
                }

                while (this.item.translations.question_multiple_value[lang].length < 2) {
                    this.addOptionsToSelect();
                }
            } else {
                this.resetOptionsToSelect();
            }
        },
        is_multiple_choice(checkbox) {
            this.types[this.types.length - 1].value = (checkbox) ? 'multiple_select' : 'single_select';
            this.types[this.types.length - 1].label = (checkbox) ? 'Question with multiple choices' : 'Question with a single select';
            this.item.type = this.types[this.types.length - 1].value;
        },
    },

    created() {
        this.has_key_choice = false;

        if (!this.requirement) {
            this.item.order = this.companyRequirementCollection.length > 0
                ? this.getMaxOrder(this.companyRequirementCollection) + 1 : 0;
        }

        if (!this.requirement && this.hasTypeOfSelect) {
            this.setDefaultOptions();
        }

        this.getTypes();
        this.initMultiple();
    },

    methods: {
        getRulesMoreOrLess(minValue, maxValue, minChar, maxChar) {
            const rules = [];
            const isGreater = Number(minValue) > Number(maxValue);

            if (minValue && maxValue && isGreater) {
                rules.push(`max_value:${maxValue}`);
            } else if (minChar && maxChar) {
                const zeros = '0'.repeat(minChar - 1);
                const min = `1${zeros}`;

                const nine = '9'.repeat(maxChar - 1);
                const max = `9${nine}`;

                rules.push(`between:${min},${max}`);
            } else if (minChar) {
                const zeros = '0'.repeat(minChar - 1);
                const min = `1${zeros}`;

                rules.push(`min_value:${min}`);
            } else if (maxChar) {
                const nine = '9'.repeat(maxChar - 1);
                const max = `9${nine}`;

                rules.push(`max_value:${max}`);
            }

            return rules;
        },

        initConfigValueTextControl() {
            return {
                control: null,
                min_character: null,
                max_character: null,
                min_value: null,
                max_value: null,
                max_decimal: 2,
            };
        },

        cancel() {
            this.$emit('on-cancel', (this.item.isDirty ? this.currentItemIndex : null));
        },

        onOpenImportAnswers() {
            this.$refs.modalImportAnswers.open();
        },

        onAddAnswers(answers) {
            this.has_key_choice = answers.withKey;

            for (const lang of this.shared.session.company.company_languages.enabled) {
                this.$set(this.item.translations.question_multiple_value, lang.key, []);

                answers.list.forEach((answer) => {
                    this.item.translations.question_multiple_value[lang.key].push({ key: answer.key, value: answer.value });
                });
            }
        },

        getTypes() {
            this.types = CompanyRequirement.getRequirementTypes().filter(
                (requirementType) => requirementType.value !== 'single_select'
                    && requirementType.value !== 'multiple_select',
            );
            this.types.push({
                label: 'Question with multiple choices',
                value: 'multiple_select',
            });
            return this.types;
        },

        initMultiple() {
            if (this.item.type === 'single_select') {
                this.types[this.types.length - 1].value = 'single_select';
                this.types[this.types.length - 1].label = 'Question with a single select';
                this.is_multiple_choice = false;
                this.item.type = this.types[this.types.length - 1].value;
            }
        },

        customLanguageOptionLabel(language) {
            return language.name;
        },
        currentLang() {
            return !this.$refs.translatedForm
                ? this.shared.session.company.company_language.key
                : this.$refs.translatedForm.editingLang;
        },
        addOptionsToSelect() {
            for (const lang of this.shared.session.company.company_languages.enabled) {
                if (!(lang.key in this.item.translations.question_multiple_value)) {
                    this.$set(this.item.translations.question_multiple_value, lang.key, []);
                }
                this.item.translations.question_multiple_value[lang.key].push({ key: '', value: '' });
            }
        },
        setDefaultOptions() {
            for (const lang of this.shared.session.company.company_languages.enabled) {
                if (!(lang.key in this.item.translations.question_multiple_value)) {
                    this.$set(this.item.translations.question_multiple_value, lang.key, []);
                    const sizeOptions = this.item.translations.question_multiple_value[this.shared.session.company.company_languages.default.key].length;

                    for (let index = 0; index < sizeOptions; index++) {
                        this.item.translations.question_multiple_value[lang.key].push({ value: '' });
                    }
                }
            }
        },
        resetOptionsToSelect() {
            for (const lang of this.shared.session.company.company_languages.enabled) {
                if (!(lang.key in this.item.translations.question_multiple_value)) {
                    this.$set(this.item.translations.question_multiple_value, lang.key, []);
                }
                this.item.translations.question_multiple_value[lang.key] = [];
            }
        },
        uploadFileDeleted() {
            if (this.item.can_fill_document && Object.values(this.item.file_translations).filter((i) => i).length === 0) {
                this.$set(this.item, 'company_pdf_form_template_id', null);
            }
        },
        resetCanFillDocument(isAllowed) {
            if (isAllowed === false) {
                this.item.company_pdf_form_template_id = null;
            }
        },

        onValidate() {
            this.$validator.validateAll().then((isValid) => {
                if (isValid) {
                    if (this.item.can_fill_document && !this.item.company_pdf_form_template_id) {
                        this.$Notifier('App').showError(this.translate('You need to edit the pdf!'));
                        return;
                    }

                    if (this.hasTypeDynamicDocument
                        && (this.item.dynamic_documents.length === 0 || this.item.dynamic_documents[0].html_content.length <= 1)
                    ) {
                        this.$Notifier('App').showError(this.translate('You need to edit the document!'));
                        return;
                    }

                    if (this.hasTypeTextWithControl) {
                        if (this.item.config_value?.control !== 'decimal') {
                            this.item.config_value.max_decimal = null;
                        }

                        if (!['numerical', 'decimal'].includes(this.item.config_value?.control)) {
                            this.item.config_value.min_value = null;
                            this.item.config_value.max_value = null;
                        }
                    }

                    this.$emit('on-save-requirement', this.item, this.currentItemIndex);
                    this.item.isDirty = false;
                }
            });
        },

        onOpenDelete() {
            // this.$refs.modalableRequirementDelete.open();
            this.$modal.show(
                SingleRequirementDelete,
                { companyRequirement: this.currentItem, editingLang: this.editingLang },
                {
                    styles: 'border-radius: 12px; position: absolute; z-index: 99999',
                },
                {
                    'before-close': (event) => {
                        if (event.params?.confirm) {
                            this.onDelete(event.params.hardDeletedRequirementId);
                        }
                    },
                },
            );
        },

        onOpenDocumentEditor() {
            if (!this.$Utils.isMobileDevice()) {
                this.$refs.documentEditorModal.open();
            } else {
                this.$Notifier('App').showError(this.translate('You cannot edit file on phone!'));
            }
        },

        onDelete(hardDeletedRequirementId) {
            this.$emit('on-delete-requirement', this.item, this.currentItemIndex, hardDeletedRequirementId);
            this.$refs.modalableRequirementDelete.close();
        },

        async deleteInputAndErrors(inputs, index) {
            for (const languageKey in this.item.translations.question_multiple_value) {
                if (Object.prototype.hasOwnProperty.call(this.item.translations.question_multiple_value, languageKey)) {
                    const options = this.item.translations.question_multiple_value[languageKey];
                    this.item.translations.question_multiple_value[languageKey] = [];
                    this.item.translations.question_multiple_value[languageKey] = options.filter((item, i) => index !== i);
                    await this.$validator.validateAll();
                }
            }
        },

        /**
         * retourne l'order maximal d'une liste de company_requirement
         * @param {CompanyRequirementCollection} companyRequirementCollection
         * @returns {number}
         */
        getMaxOrder(companyRequirementCollection) {
            let maxOrder = null;
            const orderArray = companyRequirementCollection.map(
                (companyRequirement) => companyRequirement.order,
            );
            maxOrder = Math.max(...orderArray);
            return maxOrder;
        },
    },
};
</script>

<style lang="scss" scoped src="./SingleRequirementEdit.scss"></style>
