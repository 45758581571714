<template>
    <div>
        <h3 class="small label-form-special">
            <div class="label-form double">
                <span class="first">{{ label }}</span>
                <span><t :count="characterLimit">{count} characters left</t></span>
            </div>
        </h3>
        <fieldset>
            <div class="textarea">
                <RichEditable
                    :value.sync="value"
                    :max-length="maxLength"
                    @input="v => $emit('update:value', v)"
                />
                <div
                    v-if="value"
                    class="save-button"
                >
                    <div class="modal-actions right">
                        <Button @click="$emit('on-update')">
                            <t>Save</t>
                        </Button>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
import RichEditable from '@/components/globals/RichEditable.vue';

export default {
    name: 'CompanyTranslatable',
    components: { RichEditable },
    props: {
        value: {
            type: [Array, String],
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        maxLength: {
            type: Number,
            required: true,
        },
    },
    computed: {
        characterLimit() {
            if (!this.value) {
                return this.maxLength;
            }
            return this.value.length >= this.maxLength ? 0 : this.maxLength - this.value.length;
        },
    },
};
</script>

<style scoped>
.save-button {
    padding: 0.75em 0;
    text-align: center;
    width: 100%;
}
</style>
