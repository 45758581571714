<template>
    <div class="search-wrapper">
        <div
            class="container-search no-margin"
            :class="searchInputClasses"
        >
            <HtSearchField
                id="search"
                name="search"
                @input="onInput"
                @blur="onBlur"
                @focus="onFocus"
            />
        </div>
        <div
            class="search-content"
            :class="searchContentClasses"
        >
            <transition-group name="fade">
                <template v-if="showResults">
                    <HtDivider
                        v-if="absoluteResults"
                        :key="'divider'"
                    />
                    <Results
                        key="results"
                        :results="results"
                        @item-select="onSelect"
                    />
                </template>
            </transition-group>
        </div>
    </div>
</template>

<script>
import Results from '@/components/miscellaneous/SearchInput/Results.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import HtDivider from '@/components/globals/HtDivider.vue';

export default {
    name: 'SearchInput',

    components: {
        Results,
        HtSearchField,
        HtDivider,
    },

    props: {
        results: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        forceHideResults: {
            type: Boolean,
            default: false,
        },
        absoluteResults: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            bluring: false,
        };
    },
    computed: {
        searchInputClasses() {
            return {
                'input-loading': this.loading,
                'input-no-result': this.results && this.results.length === 0 && !this.loading,
            };
        },
        searchContentClasses() {
            return {
                absolute: this.absoluteResults,
            };
        },
        showResults() {
            if (this.absoluteResults && this.bluring) {
                return false;
            }
            return this.forceHideResults === false;
        },
    },
    methods: {
        /**
         * @param {string} search
         */
        onInput(search) {
            this.$emit('on-search', search);
        },
        onBlur() {
            this.bluring = true;
        },
        onFocus() {
            this.bluring = false;
        },
        onSelect(v) {
            this.$emit('item-selection', v);
        },
    },
};
</script>

<style scoped lang="scss">
.search-wrapper {
    position: relative;
}

.search-content {
    &.absolute {
        margin-top: 0;
        position: absolute;
        border-top: none;
    }

    z-index: 99999;
    width: 100%;
}
</style>
