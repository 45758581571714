<template>
    <div
        v-if="showBloc"
        class="profile-block"
    >
        <div
            v-if="canEdit"
            class="d-flex justify-content-between align-items-center profile-block-icon branding-color branding-color-darken-hover"
            @click="onEdit"
        >
            <FontAwesomeIcon
                size="2x"
                icon="pencil-alt"
                transform="shrink-10"
            />
            <t>Edit</t>
        </div>

        <slot :name="'default'" />
        <div
            v-show="isEdit"
            class="action-buttons"
        >
            <Button
                class="negative mr-20"
                @click="onCancel"
            >
                <t>Cancel</t>
            </Button>
            <Button
                :state="buttonState"
                @click="onSave"
            >
                <t>Save modifications</t>
            </Button>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ProfileBloc',
    inject: ['$validator'],
    props: {
        isEdit: {
            type: Boolean,
            required: true,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
        buttonState: {
            type: String,
            default: 'idle',
        },
        showBloc: {
            type: Boolean,
            default: true,
        },
    },

    methods: {
        onCancel() {
            this.$emit('update:isEdit', false);
            this.$emit('on-cancel');
        },

        onSave() {
            this.$emit('on-save');
        },

        onEdit() {
            this.$emit('update:isEdit', !this.isEdit);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.profile-block-icon {
    position: absolute;
    right: 32px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    max-width: 70px;
}

.action-buttons {
    text-align: center;
    margin-top: 4.1rem;
}

.profile-block {
    position: relative;
    background-color: $white;
}

@media (max-width: $phone) {
    .profile-block-icon {
        right: 15px;
    }
}
</style>
