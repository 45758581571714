<template>
    <svg
        class="img-inline--fa"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="mandatorize"
    >
        <path
            v-if="mandatory"
            d="M11.99 22C6.46846 21.9945 1.99632 17.5149 2 11.9933C2.00368 6.47179 6.48179 1.99816 12.0033 2C17.5249 2.00184 22 6.47845 22 12C21.9967 17.5254 17.5154 22.0022 11.99 22ZM4 12.172C4.04732 16.5732 7.64111 20.1095 12.0425 20.086C16.444 20.0622 19.9995 16.4875 19.9995 12.086C19.9995 7.6845 16.444 4.10977 12.0425 4.08599C7.64111 4.06245 4.04732 7.59876 4 12V12.172ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
            fill="#1B1B1C"
        />
        <path
            v-if="!mandatory"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.16595 5.78425C2.81135 7.48924 2.00157 9.64665 2 11.9933C1.99632 17.5149 6.46846 21.9945 11.99 22C14.3422 22.0009 16.5052 21.1902 18.214 19.8323L16.8387 18.457C15.506 19.4709 13.8452 20.0763 12.0425 20.086C7.64111 20.1095 4.04732 16.5732 4 12.172V12C4.01922 10.2128 4.62318 8.56831 5.62851 7.24681L4.16595 5.78425ZM11 12.6183V13H11.3817L11 12.6183ZM13 8.95716L11.0428 7H13V8.95716ZM19.3323 15.2895C19.7615 14.3085 19.9995 13.2249 19.9995 12.086C19.9995 7.6845 16.444 4.10977 12.0425 4.08599C10.8884 4.07982 9.78977 4.31843 8.79606 4.75322L7.24404 3.20119C8.65942 2.43452 10.2806 1.99943 12.0033 2C17.5249 2.00184 22 6.47845 22 12C21.999 13.7211 21.5635 15.3405 20.7972 16.7544L19.3323 15.2895ZM11 17H13V15H11V17Z"
            fill="#1B1B1C"
        />
        <rect
            v-if="!mandatory"
            x="4.20996"
            y="3"
            width="24.0199"
            height="2"
            transform="rotate(45 4.20996 3)"
            fill="#1B1B1C"
        />
    </svg>
</template>

<script>
export default {
    name: 'MandatoryIcon',
    props: {
        mandatory: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    computed: {
        imgSrc() {
            return this.mandatory ? '/static/icons/error_outline.svg' : '/static/icons/error_outline_crossed.svg';
        },
    },
    methods: {
        mandatorize() {
            this.$emit('mandatorize');
        },
    },
};
</script>

<style scoped lang="scss">
@import '~@/styles/var';
.img-inline--fa{
    position: absolute;
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    right: -30px;
}
@media (max-width: $tablet) {
    .img-inline--fa{
        right: -28px;
        height: 1.15em;
    }
}
@media (max-width: $phone) {
    .img-inline--fa{
        right: -20px;
        height: 0.75em;
    }
}
</style>
