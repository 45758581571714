// export const getDataAttribute = (el, name) => el.getAttribute(`data-vv-${name}`);

export const getProperty = (propertyName, object) => {
    const parts = propertyName.split('.');

    let property = object || this;

    for (let i = 0; i < parts.length; i++) {
        property = property[parts[i]];
    }

    return property;
};

/**
 * Converts an array-like object to array.
 * Simple polyfill for Array.from
 */
export const toArray = (arrayLike) => {
    if (Array.from) {
        return Array.from(arrayLike);
    }

    const array = [];
    const { length } = arrayLike;
    for (let i = 0; i < length; i++) {
        array.push(arrayLike[i]);
    }

    return array;
};
