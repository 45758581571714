<template>
    <div class="question-history">
        <FilesUpload
            :value="answers.map((v) => v.company_file)"
            display="horizontal"
            preview-only
        />
    </div>
</template>

<script>
import FilesUpload from '@/components/resources/user/surveys/questions/FilesUpload.vue';
import SurveyQuestionHistoryMixin from '@/components/resources/user/surveys/questions/SurveyQuestionHistoryMixin';

export default {
    name: 'SurveyFileUploadHistory',

    components: {
        FilesUpload,
    },

    mixins: [
        SurveyQuestionHistoryMixin,
    ],
};
</script>

<style lang="scss" scoped>
@import '../SurveyQuestion';
</style>
