import api from '@/store/api';

const SET_ROLES = 'set_roles';
const SET_USER_ROLES = 'set_user_roles';

export default {
    namespaced: true,

    state() {
        return {
            roles: [],
            userRoles: [],
            rolesLoaded: false,
            userRolesLoaded: false,
        };
    },

    getters: {
        sortedRoles(state) {
            return [...state.roles].sort((role1, role2) => role1.alias.localeCompare(role2.alias));
        },
        sortedUserRoles(state) {
            return [...state.userRoles].sort((role1, role2) => role1.alias.localeCompare(role2.alias));
        },
        isExistingRole: (state) => (alias) => !!state.roles.find((role) => role.alias === alias),
    },

    actions: {
        async fetchRoles({ commit, state }, force = false) {
            if (state.rolesLoaded && !force) {
                return;
            }

            return api.configuration.roles.getRoles().then(({ data }) => {
                commit(SET_ROLES, data.resources);
            });
        },

        /**
         *
         * @param {*} param0
         * @param {Object} data
         * @property {Number} data.userId
         * @property {Boolean} data.force
         * @returns
         */
        async fetchUserRoles({ commit, state }, data) {
            if (state.userRolesLoaded && !data.force) {
                return;
            }

            return api.configuration.roles.getUserRoles(data.userId).then(({ data }) => {
                commit(SET_USER_ROLES, data.resources);
            });
        },
    },

    mutations: {
        [SET_ROLES](state, roles) {
            state.roles = roles;
            state.rolesLoaded = true;
        },
        [SET_USER_ROLES](state, roles) {
            state.userRoles = roles;
            state.userRolesLoaded = true;
        },
    },
};
