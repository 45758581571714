import api from '@/store/api';

const SET_COMPANIES = 'set_companies';
const SET_COMPANY_LIST = 'set_company_list';
const SET_COMPANY_TAG_TEMPLATES = 'set_company_tag_templates';
const CLEAR_COMPANIES = 'clear_companies';

export default {
    namespaced: true,

    state() {
        return {
            companiesLoaded: false,
            companies: [],
            companyListLoaded: false,
            companyList: [],
            companyTagTemplates: [],
        };
    },

    getters: {
        holding(state) {
            return state.companies.find((company) => company.is_holding);
        },
        childCompanies(state) {
            return state.companies.filter((company) => !company.is_holding);
        },
        companies(state) {
            return state.companies;
        },
    },

    actions: {
        fetchCompanies({ commit, state }) {
            if (state.companiesLoaded) {
                return;
            }
            return api.holding.getCompanies().then((response) => {
                commit(SET_COMPANIES, response.data.data);
            });
        },
        fetchCompanyList({ commit, state }) {
            if (state.companyListLoaded) {
                return;
            }
            return api.holding.getCompanyList().then(({ data: { data: list } }) => {
                commit(SET_COMPANY_LIST, list);
            });
        },
        setCompanyTagTemplates({ commit }, tagTemplates) {
            commit(SET_COMPANY_TAG_TEMPLATES, tagTemplates);
        },
        clearCompanies({ commit }) {
            commit(CLEAR_COMPANIES);
        },
    },

    mutations: {
        [SET_COMPANIES](state, companies) {
            state.companies = companies;
            state.companiesLoaded = true;
        },
        [SET_COMPANY_LIST](state, companyList) {
            state.companyList = companyList;
            state.companyListLoaded = true;
        },
        [SET_COMPANY_TAG_TEMPLATES](state, tagTemplates) {
            state.companyTagTemplates = tagTemplates;
        },
        [CLEAR_COMPANIES](state) {
            state.companies = [];
            state.companyList = [];
            state.companiesLoaded = false;
            state.companyListLoaded = false;
        },
    },
};
