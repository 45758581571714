import UnauthorizedError from '@/services/Errors/UnauthorizedError';
import AIChatService from '@/services/AIChatService';

export default {
    data() {
        return {
            streamingMessage: null,
        };
    },
    methods: {
        onSuggestionClick(suggestion) {
            this.$refs.chatFormMessage.messages[this.channelActiveId] = suggestion.trim();
            this.$refs.chatFormMessage.sendMessage();
        },

        processAIResponse(text, payload) {
            if (this.streamingMessage && this.streamingMessage.subtype === 'streaming') {
                this.streamingMessage.text += text;

                this.scrollToBottom();

                return;
            }

            const { messages } = this.shared.socket.channelMessageCollection[this.channelActiveId];
            let thinkingMessageFound = false;

            // Ajoute 2 secondes pour afficher le message après celui de l'utilisateur
            const createdAt = new Date(payload.ts);
            createdAt.setSeconds(createdAt.getSeconds() + 2);

            for (const message of messages) {
                if (message.type === 'thinking') {
                    this.streamingMessage = message;

                    if (!this.streamingMessage.text) {
                        this.streamingMessage.id = Math.floor(Math.random() * 90000000000) + 1000000000;
                        this.streamingMessage.text = text;
                        this.streamingMessage.subtype = 'streaming';
                        this.streamingMessage.type = 'text';
                        this.streamingMessage.created_at = createdAt.toISOString();
                    }

                    thinkingMessageFound = true;
                    break;
                }
            }

            if (!thinkingMessageFound) {
                console.error('Thinking message not found');
            }

            this.scrollToBottom();
        },

        clearThinkingMessages() {
            this.shared.socket.channelMessageCollection[this.channelActiveId].messages = this.shared.socket.channelMessageCollection[this.channelActiveId].messages.filter((message) => message.type !== 'thinking');
        },

        clearStreamingMessages() {
            this.streamingMessage.subtype = 'normal';
        },

        async sendToCapi(message) {
            try {
                const payload = await this.generateCapiPayload(message);

                const aiService = new AIChatService(this.$env, this.shared.session.capiToken, payload);
                await aiService.ask(payload, this.processAIResponse);

                this.clearStreamingMessages();
            } catch (error) {
                if (error instanceof UnauthorizedError) {
                    this.$Auth.logOut();

                    this.$router.push('/login');

                    return;
                }

                console.error('Error', error);
            } finally {
                this.clearThinkingMessages();
            }
        },

        async generateCapiPayload(userMessage) {
            const { capiUser } = this.shared.session;

            if (!capiUser) {
                throw new UnauthorizedError();
            }

            const companyId = this.shared.session.company.id;
            const { channelActive } = this;

            return {
                ts: userMessage.created_at,
                from: {
                    id: userMessage.company_user.id,
                    firstname: userMessage.company_user.firstname,
                    lastname: userMessage.company_user.lastname,
                    company_id: companyId,
                    is_virtual: true,
                },
                to: {
                    channelId: this.channelActiveId,
                    userList: [
                        {
                            ...capiUser,
                            company_id: companyId,
                        },
                    ],
                },
                message: {
                    id: userMessage.id,
                    company_id: companyId,
                    type: userMessage.type,
                    text: userMessage.text,
                    created_at: userMessage.created_at,
                    company_user_id: userMessage.company_user.id,
                    company_chat_channel_id: channelActive.id,
                    channel: {
                        id: channelActive.id,
                        company_id: companyId,
                        created_by_company_user_id: channelActive.created_by_company_user_id,
                        name: channelActive.name,
                        type: channelActive.type,
                        subname: channelActive.subname,
                        resource: null,
                        resource_id: null,
                        enabled_notification: false,
                        last_message_at: channelActive.last_message_at,
                        created_at: channelActive.created_at,
                        deleted_at: null,
                        avatar_image_id: null,
                    },
                    enabled_notification: false,

                },
                last5Messages: this.getLast5MessagesWithin10Minutes(userMessage),
                backUrl: this.$env.getFromEnv('COMPANY_API_URL'),
            };
        },

        getLast5MessagesWithin10Minutes(lastUserMessage) {
            const { messages } = this.shared.socket.channelMessageCollection[this.channelActiveId];

            return messages
                .filter((message) => {
                    // exclude current, thinking and streaming messages
                    if (lastUserMessage.id === message.id || message.type === 'thinking' || message.subtype === 'streaming') {
                        return false;
                    }

                    const date = new Date(message.created_at);
                    const now = new Date();
                    const diff = now - date;
                    const minutes = Math.floor(diff / 60000);

                    return minutes < 10;
                })
                .slice(-5)
                .map((message) => ({
                    is_virtual: message.company_user_id === this.shared.session.capiUser.id,
                    text: message.text,
                    created_at: message.created_at,
                    company_user_id: message.company_user_id,
                }));
        },
    },
};
