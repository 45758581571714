<template>
    <div>
        <div
            v-for="(answer, index) in getUserAnswersText"
            :key="index"
            :class="['user-survey-result-item-content-end', {
                'pb-3': (index < getUserAnswersText.length - 1)
            }]"
        >
            <div class="user-survey-result-item-content-right-text-right">
                {{ answer }}
            </div>
        </div>
    </div>
</template>
<script>
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import CompanyUserSurveyQuestionAnswerCollection from '@/models/CompanyUserSurveyQuestionAnswerCollection';
import get from 'lodash.get';

export default {
    name: 'ResultMultipleChoice',
    props: {
        surveyQuestion: {
            type: CompanySurveyQuestion,
            required: true,
        },
        userSurveyQuestionAnswer: {
            type: CompanyUserSurveyQuestionAnswerCollection,
            required: true,
        },
    },

    computed: {
        getUserAnswersText() {
            const answers = [];

            this.userSurveyQuestionAnswer.models.forEach((element) => {
                let answer = null;
                const answerLocaleByKey = get(element.company_survey_question_answer, 'locales_by_key', null);
                const answerFromEntity = get(element.company_survey_question_answer, 'company_entity_value_id');

                if (answerFromEntity) {
                    answer = element.company_survey_question_answer.value.custom.name;
                } else if (answerLocaleByKey) {
                    if (answerLocaleByKey[this.shared.session.companyUser.company_language.key].text !== null) {
                        answer = answerLocaleByKey[this.shared.session.companyUser.company_language.key].text;
                    } else {
                        answer = answerLocaleByKey[this.shared.session.company.company_language.key].text;
                    }
                }

                if (answer) {
                    answers.push(answer);
                }
            });

            return answers;
        },
    },
};
</script>
