<template>
    <div>
        <div class="survey-results-title">
            <HtButton
                type="secondary"
                size="title"
                icon-only
                :icon="['fal', 'chevron-left']"
                @click.native="goToListing()"
            />

            <h2>{{ item.resource.name }}</h2>
        </div>

        <SurveyFormResultsTopBar
            :is-exporting="isExporting"
            :selected-filters="selectedFilters"
            :show-export="participants.length > 0"
            @on-change-filter="onChangeFilter"
            @on-click-export="onExport"
        />

        <div class="row">
            <div class="col-md-4">
                <HtSearchField
                    id="participants-search"
                    v-model.trim="participantsSearch"
                    name="participants-search"
                    class="mb-3"
                />

                <SurveyFormResultsParticipants
                    :loading="loading"
                    :result-index="resultIndex"
                    :participants="participantsFiltered"
                    :is-targeting-program="item.resource.programs[0].program.type.slug !== 'default'"
                    @on-change-participant="onChangeParticipant"
                />
            </div>

            <div class="col-md-6 offset-md-1">
                <SurveyFormResultsEmptyState
                    v-if="noResults"
                    class="empty-state"
                />

                <template v-else>
                    <IconLoading v-if="loading || resultsLoading" />

                    <keep-alive>
                        <SurveyListResult
                            v-show="'global' === resultIndex"
                            class="survey-list-result"
                            key="global_results"
                            :results="globalResults"
                        />
                    </keep-alive>

                    <template v-for="(result, index) in participantResults">
                        <keep-alive>
                            <SurveyListResult
                                v-show="index.toString() === resultIndex"
                                class="survey-list-result"
                                :key="`${index}_results`"
                                is-user-view
                                :results="result.results"
                                :occurrences="result.occurrences"
                            />
                        </keep-alive>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/store/api';
import HtButton from '@/components/globals/HtButton.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import SurveyListResult from './SurveyListResult.vue';
import SurveyFormResultsTopBar from './SurveyFormResultsTopBar.vue';
import SurveyFormResultsParticipants from './result/Participants/SurveyFormResultsParticipants.vue';
import SurveyFormResultsEmptyState from './SurveyFormResultsEmptyState.vue';

export default {
    name: 'SurveyFormResults',

    components: {
        HtButton,
        IconLoading,
        SurveyListResult,
        SurveyFormResultsTopBar,
        SurveyFormResultsParticipants,
        SurveyFormResultsEmptyState,
        HtSearchField,
    },

    data() {
        return {
            resultIndex: 'global',
            globalResults: [],
            participants: [],
            selectedFilters: [],
            participantResults: {},
            /**
             * Does this survey contains results
             */
            noResults: false,
            loading: false,
            resultsLoading: false,
            isExporting: false,
            participantsSearch: null,
        };
    },

    created() {
        this.fetchData();
    },

    methods: {
        fetchData() {
            this.loading = true;

            api
                .surveyResults
                .globalResults(this.item.id, { entities: this.mapSelectedFilters })
                .then((res) => {
                    this.globalResults = res.data.questions_results;
                    this.participants = res.data.participants;
                    this.noResults = this.participants.length === 0;
                })
                .finally(() => this.loading = false);
        },

        async onChangeParticipant(index) {
            if (index !== 'global' && !this.participantResults[index]) {
                this.resultsLoading = true;

                api
                    .surveyResults
                    .globalUserResults(this.item.id, index)
                    .then((res) => {
                        this.participantResults[index] = {
                            results: res.data.questions_results,
                            occurrences: res.data.occurrences,
                        };
                    })
                    .finally(() => this.resultsLoading = false);
            }

            this.resultIndex = index;
        },

        onChangeFilter(filters) {
            this.selectedFilters = filters;
            this.globalResults = [];
            this.participants = [];
            this.participantResults = {};
            this.resultIndex = 'global';
            this.fetchData();
        },

        async onExport(format) {
            this.isExporting = true;
            api.miscellaneous.exportSurveys({
                survey_ids: [this.item.id],
                filters: this.mapSelectedFilters,
                format,
            }).then((data) => {
                window.location = data.data.link;
            }).finally(() => this.isExporting = false);
        },

        goToListing() {
            this.$router.push({ name: 'SurveyResults' });
        },
    },

    computed: {
        participantsFiltered() {
            if (!this.participantsSearch) {
                return this.participants;
            }

            return this.participants.filter((p) => (
                this.$Utils.isSubstringWithoutDiacritics(this.participantsSearch, `${p.enrollee.firstname} ${p.enrollee.lastname}`)
                || this.$Utils.isSubstringWithoutDiacritics(this.participantsSearch, `${p.participant.firstname} ${p.participant.lastname}`)
            ));
        },
        item() {
            return this.$store.state.stepperform.item;
        },

        /**
         * @returns {[{company_entity_id: number, company_entity_value_id: number}]}
         */
        mapSelectedFilters() {
            return this
                .selectedFilters
                .map(({ company_entity_id, company_entity_value_id }) => ({ company_entity_id, company_entity_value_id }));
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.survey-results-title {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 16px;
    gap: 16px;

    h2 {
        margin: 0;
    }
}

@media (max-width: $tablet) {
    .survey-list-result {
        margin-top: 48px;
    }
}
</style>
