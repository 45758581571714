<template>
    <div
        v-if="companyBenefits.isLoaded()"
        class="full-width"
    >
        <SectionBar
            :title="configs.title"
            :button-class="'button-plus'"
            :button-text="'+'"
            :has-add-button="true"
            @click="openModal()"
        />

        <CardList
            v-if="companyBenefits.$.length"
            :options="{
                class:'no-justify'
            }"
        >
            <Card
                v-for="(item, index) in companyBenefits.$.models"
                :key="index"
                :options="{
                    type: 'small',
                    perRow: 'auto',
                }"
                @click="openModal(item.active)"
            >
                <div slot="title">
                    {{ item.translations.name[editingLang] }}
                </div>
            </Card>
        </CardList>
        <div
            v-else
            :key="0"
            class="empty-component"
        >
            <p> <strong> <t>No Benefit added</t> </strong> </p>
        </div>

        <Modalable
            ref="modalableBenefitEdit"
            class="modalable-1 medium"
            :mode="2"
        >
            <BenefitEdit
                v-model="currentItem"
                :editing-lang="editingLang"
                :collection="companyBenefits"
            />
        </Modalable>
    </div>
</template>

<script>
import CompanyBenefitCollection from '@/models/CompanyBenefitCollection';
import BenefitEdit from '@/components/resources/benefit/BenefitEdit.vue';

export default {
    name: 'BenefitList',
    components: {
        BenefitEdit,
    },
    props: {
        editingLang: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            configs: {
                title: 'Benefits',	// Title of SectionBar
                showAddButton: true,			// Show add button of SectionBar,
                perRow: 3,						// How many cards per row
            },
            currentItem: null,
            companyBenefits: new CompanyBenefitCollection(
                ['id', 'name', 'editable', 'is_heyteam', 'translations'],
            ),
        };
    },

    async created() {
        await this.companyBenefits.get();
    },

    methods: {
        openModal(item) {
            this.currentItem = item;
            this.$refs.modalableBenefitEdit.open();
        },
    },
};
</script>
