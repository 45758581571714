var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-content-center pt-5 pb-5"},[_c('t',[_vm._v("Add a resource")])],1),_c('CardList',{attrs:{"count-empty":1,"options":{
            type: 'long',
            perRow: 4
        }}},_vm._l((_vm.resources),function(item,index){return _c('Card',{key:index,attrs:{"options":{
                type: 'long',
                perRow: 4,
            }},on:{"click":function($event){return _vm.openListResource(item)}}},[_c('div',{attrs:{"slot":"image"},slot:"image"},[_c('div',{staticClass:"item-image image-small"},[_c('div',{staticClass:"small-image",style:({'background-image': `url(/static/icons/${item.image})`})})])]),_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('t',[_vm._v(_vm._s(item.label))])],1)])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }