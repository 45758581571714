<template>
    <div />
</template>

<script>
import CompanySurvey from '@/models/CompanySurvey';

export default {
    name: 'DeepAssign',

    data() {
        return {
            survey: new CompanySurvey([
                'id',
                'datetime_start',
                'datetime_end',
                'company_program_id',
                'include_already_registered_users',
                'status',
                'number_of_responses',
                'order_onboarding_start',
                'order_onboarding_end',
                'order_availability',
                'is_enable',
            ]).with({
                program: (query) => query.select(['id', 'ht_program_type_id']).with({
                    htProgramType: (query) => query.select(['id', 'slug']),
                }),
                companySurveySupervisor: (query) => {
                    query
                        .select(['id', 'company_user_id', 'company_role_id'])
                        .with({
                            companyUser: (query) => {
                                query.select([
                                    'id',
                                    'firstname',
                                    'lastname',
                                    'image',
                                ]);
                            },
                            companyRole: (query) => {
                                query.select([
                                    'id',
                                    'alias',
                                    'name',
                                    'is_heyteam',
                                ]);
                            },
                        });
                },
                companySurveyContent: (query) => {
                    query
                        .select(['id', 'order', 'resource', 'resource_id'])
                        .with({
                            companySurveyExtra: (query) => {
                                query.select(['id']).with({
                                    companySurveyExtraLocale: (query) => {
                                        query.select([
                                            'id',
                                            'text',
                                            'company_survey_extra_id',
                                            'language_key',
                                        ]);
                                    },
                                });
                            },
                            companySurveyQuestion: (query) => {
                                query
                                    .select([
                                        'id',
                                        'type',
                                        'params',
                                        'subtype',
                                        'is_mandatory',
                                    ])
                                    .with({
                                        companySurveyQuestionLocale: (query) => {
                                            query.select([
                                                'id',
                                                'text',
                                                'company_survey_question_id',
                                                'language_key',
                                            ]);
                                        },
                                        companySurveyQuestionAnswer: (query) => {
                                            query.select(['id', 'order']).with({
                                                companySurveyQuestionAnswerLocale: (query) => {
                                                    query.select([
                                                        'id',
                                                        'text',
                                                        'company_survey_question_answer_id',
                                                        'language_key',
                                                    ]);
                                                },
                                            });
                                        },
                                    });
                            },
                        })
                        .orderBy([['order', 'asc']]);
                },
                companyProgramResourceFilter: (query) => {
                    query.select([
                        'id',
                        'company_entity_id',
                        'company_entity_value_id',
                        'component_name',
                        'resource_id',
                        'group',
                        'name',
                    ]);
                    query.with({
                        value: (query) => {
                            query
                                .select([
                                    'id',
                                    'company_entity_id',
                                    'resourceable_type',
                                    'resourceable_id',
                                ])
                                .with({
                                    jobPosition: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    contract: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    office: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    department: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    custom: (query) => {
                                        query.select(['id', 'key', 'name']);
                                    },
                                });
                        },
                        entity: (query) => {
                            query.select([
                                'id',
                                'name',
                                'slug',
                                'is_enabled',
                                'is_heyteam',
                            ]);
                        },
                    });
                },
            }),
        };
    },

    created() {
        this.survey.get().then(() => this.shared.queries.companyProgramCollection.get().then(() => {
            this.survey.company_program = {
                ...this.shared.queries.companyProgramCollection.models.find(
                    (program) => program.id === 2,
                ),
            };

            this.survey.company_program_id = 1;

            this.survey.save();
        }));
    },
};
</script>
