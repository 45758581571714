<template>
    <div>
        <div class="mb-5">
            <HistoryWidget />
        </div>
    </div>
</template>

<script>
import HistoryWidget from '@/components/pages/dashboard/history/HistoryWidget.vue';

export default {
    name: 'History',
    components: {
        HistoryWidget,
    },
};
</script>
