<template>
    <div class="logical">
        <div class="logical-icon">
            <FontAwesomeIcon
                :icon="'arrow-right'"
            />
        </div>

        <div class="logical-form">
            <HtFormSelect
                :id="'logical-jump-' + _uid"
                :value="value"
                :name="'logical-jump-' + _uid"
                :options="getFormattedQuestionTitles"
                @input="selection"
            />
        </div>
    </div>
</template>

<script>
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import CompanySurveyContent from '@/models/CompanySurveyContent';

export default {
    name: 'LogicalJump',
    components: {
        HtFormSelect,
    },

    props: {
        value: {
            type: Number,
        },
        contents: {
            type: Array,
            required: true,
        },
        language: {
            type: String,
            required: true,
        },
        questionIndex: {
            type: Number,
            required: true,
        },
    },

    computed: {
        options() {
            return this.contents
                .filter((content) => content.order > this.questionIndex)
                .map((content) => {
                    const contentResourceKey = content.resource === 'company_survey_question' ? 'question' : 'extra';

                    return {
                        id: content.order,
                        // name: '#' + (content.order + 1) + ' - ' + (Vue.prototype.$modelUtils.localize(content[content.resource].translations, 'text', this.language) ?? '')
                        name: `#${content.order + 1} - ${content[contentResourceKey].translations.text[this.language] ?? ''}`,
                    };
                }).concat([{
                    id: CompanySurveyContent.ORDER_END_SURVEY,
                    name: this.translate('End survey'),
                }]);
        },
        getFormattedQuestionTitles() {
            this.options.map((question) => {
                if (question.name.length > 60) {
                    return Object.assign(question, {
                        name: `${question.name.substr(0, 57)}...`,
                    });
                }
                return question;
            });

            return this.options;
        },
    },

    watch: {
        options: {
            handler() {
                if (this.value === null && this.options.length === 2) {
                    this.$emit('input', this.options[0].id);
                }
            },
            immediate: true,
        },
    },

    methods: {
        selection(value) {
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" scoped>
    .logical {
        display: flex;
        align-items: center;
    }

    .logical-icon {
        margin-right: 10px;
        margin-bottom: 2.5rem;
    }

    .logical-form {
        flex-grow: 1;
    }
</style>
