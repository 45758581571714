import Model from '@tony.caron/node-model-proxy/Model';
import CompanyRequirementCategory from './CompanyRequirementCategory';
import CompanyProgram from './CompanyProgram';
import CompanyResourceProgramFilter from './CompanyResourceProgramFilter';
import CompanyResourceProgramCollection from './CompanyResourceProgramCollection';
import CompanyResourceProgramParticipant from './CompanyResourceProgramParticipant';

export default class CompanyResourceProgram extends Model {
    static UNIT_DAYS = 'days';

    static UNIT_WEEKS = 'weeks';

    static UNIT_MONTHS = 'months';

    static UNIT_IMMEDIATELY = 'immediately';

    static UNIT_SPECIFIC_DATE = 'specific_date';

    modelConfig() {
        return {
            collection: CompanyResourceProgramCollection,
        };
    }

    modelDefaultValues() {
        return {
            company_program_key_date_id: null,
            offset_availability_number: 0,
            offset_availability_unit: 'immediately',
            offset_end_number: 0,
            offset_end_unit: 'days',
            is_business_day: true,
            is_enabled: true,

            // Deprecated
            order_start: 0,
            order_end: 0,
        };
    }

    modelRelations() {
        return {
            program: () => this.belongsTo(CompanyProgram, 'company_program_id', 'id'),
            participants: () => this.hasMany(CompanyResourceProgramParticipant),
            dependencies: () => this.hasMany(CompanyResourceProgram, 'company_resource_program_id', 'id'),
            dependents: () => this.hasMany(CompanyResourceProgram, 'dependency_id', 'id'),
            requirementCategory: () => this.belongsTo(CompanyRequirementCategory, 'resourceable_id', 'id'),
            filters: () => this.hasMany(CompanyResourceProgramFilter),
        };
    }
}
