<template>
    <div>
        <BulkEnrollingHeader
            :has-errors="hasCellErrors"
            :title="translate('Team')"
        >
            <template v-if="hasSelectedItems">
                <HtButton
                    :left-icon="['fal', 'user-minus']"
                    type="secondary"
                    :disabled="allItemsAreSelected"
                    @click.native="deleteSelection"
                >
                    <t>Remove from list</t>
                </HtButton>
            </template>
            <template v-else>
                <HtSearchField
                    id="search_user"
                    v-model.trim="searchInputUser"
                    name="search-user"
                    :placeholder="translate('Search')"
                />
            </template>
        </BulkEnrollingHeader>

        <HtTablePaginated
            ref="usersTable"
            :url="`foc/enrolling/bulk/${bulk.id}/team/paginate`"
            :columns="columns"
            :external-search="searchInputUser"
            :cell-errors="cellErrors"
            has-checkboxes
            @has-selected-items="hasSelectedItems = $event"
            @on-row-clicked="onRowClicked"
        >
            <template #cell(user.fullname)="scope">
                <TableCellUser
                    :user="scope.item.user"
                />
            </template>

            <template v-for="role in assignableRoles" v-slot:[`cell(roles.${role.id})`]="scope">
                <TableCellUser
                    v-if="roleUser = scope.item.user.employee_supervisors.find((i) => i.company_role_id === role.id)"
                    :user="roleUser.user"
                />
                <span v-else>-</span>
            </template>
        </HtTablePaginated>

        <EnrollingFooter>
            <HtButton
                cypress="foc-previous-program"
                type="secondary"
                :disabled="!hasPrev"
                @click.native="$emit('on-prev')"
            >
                <t>Previous</t>
            </HtButton>

            <HtButton
                cypress="foc-next-program"
                :loading="loading"
                :disabled="!hasNext || loading"
                @click.native="validate"
            >
                <t>Next</t>
            </HtButton>
        </EnrollingFooter>
    </div>
</template>

<script>
import api from '@/store/api';
import HtTablePaginated from '@/components/globals/HtTable/HtTablePaginated.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import BulkEnrollingHeader from '@/router/pages/programs/enrolling/bulk/BulkEnrollingHeader.vue';
import ModalMixin from '@/components/globals/modals/modalMixin';
import HtButton from '@/components/globals/HtButton.vue';
import TableCellUser from '@/components/globals/table/TableCellUser.vue';
import BulkEditTeamModal from '@/router/pages/programs/enrolling/bulk/Team/BulkEditTeamModal.vue';
import EnrollingFooter from '@/router/pages/programs/enrolling/EnrollingFooter.vue';
import BulkEnrollingMixin from '@/router/pages/programs/enrolling/bulk/BulkEnrollingMixin';

export default {
    name: 'BulkEnrollingTeam',

    mixins: [
        ModalMixin,
        BulkEnrollingMixin,
    ],

    components: {
        EnrollingFooter,
        TableCellUser,
        HtButton,
        BulkEnrollingHeader,
        HtSearchField,
        HtTablePaginated,
    },

    data() {
        return {
            assignableRoles: [],
        };
    },

    created() {
        api
            .user
            .enrolling
            .bulk
            .getAssignableRoles(this.bulk.id)
            .then((res) => {
                this.assignableRoles = res.data.data;
            });
    },

    methods: {
        validate() {
            if (this.isReadOnly) {
                this.$emit('on-next');

                return;
            }

            this.loading = true;

            api
                .user
                .enrolling
                .bulk
                .validateUserTeam(this.bulk.id)
                .then(() => {
                    this.$emit('on-next');
                })
                .catch(({ response }) => {
                    this.cellErrors = {};

                    Object.keys(response.data.errors)
                        .forEach((key) => {
                            const id = key.substring(0, key.indexOf('.'));
                            const rest = key.substring((key.indexOf('.') + 1));
                            const values = this.cellErrors[id] || [];

                            values.push(rest);

                            this.$set(this.cellErrors, id, values);
                        });

                    if (this.hasCellErrors) {
                        this.$nextTick(() => {
                            this.$refs.usersTable.loadItems(1);
                        });
                    }

                    this.loading = false;
                });
        },

        onRowClicked({ item }) {
            if (this.isReadOnly) {
                return;
            }

            this.openCenterModal(
                BulkEditTeamModal,
                {
                    bulk: this.bulk,
                    user: item.user,
                    assignableRoles: this.assignableRoles,
                },
                {
                    'before-close': (event) => {
                        if (event.params && event.params.name && event.params.name === 'on-update') {
                            const user = event.params.value;
                            this.$refs.usersTable.replaceItemById(item, 'user', user);
                            this.cellErrors = {};
                        }
                    },
                },
            );
        },
    },

    computed: {
        columns() {
            const columns = [
                {
                    label: this.translate('Name'),
                    key: 'user.fullname',
                },
            ];

            this.assignableRoles
                .forEach((role) => {
                    columns.push({
                        label: role.alias,
                        key: `roles.${role.id}`,
                    });
                });

            return columns;
        },
    },
};
</script>
