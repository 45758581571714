import Model from '@tony.caron/node-model-proxy/Model';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import CompanyProgram from '@/models/CompanyProgram';
import CompanyProgramResourceFilter from '@/models/CompanyProgramResourceFilter';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';
import CompanyDocumentPublicCollection from './CompanyDocumentPublicCollection';
import CompanyFile from './CompanyFile';

export default class CompanyDocumentPublic extends Model {
    modelConfig() {
        return {
            collection: CompanyDocumentPublicCollection,
        };
    }

    modelDefaultValues() {
        return {
            translations: {
                name: {},
                url: {},
                description: {},
            },
            file_translations: {},
        };
    }

    modelInConvertor() {
        return {
            is_tos: (n) => Boolean(n),
        };
    }

    modelAccessors() {
        return {
            name: () => (this.translations.name[RtwSharedSpace.store.session.companyUser.company_language.key]
                ?? this.translations.name[RtwSharedSpace.store.session.company.company_languages.default.key]),
            url: () => (this.translations.url[RtwSharedSpace.store.session.companyUser.company_language.key]
                ?? this.translations.url[RtwSharedSpace.store.session.company.company_languages.default.key]),
            description: () => (this.translations.description[RtwSharedSpace.store.session.companyUser.company_language.key]
                ?? this.translations.description[RtwSharedSpace.store.session.company.company_languages.default.key]),
        };
    }

    modelRelations() {
        return {
            resourceProgram: () => this.hasMany(CompanyResourceProgram, 'resourceable_id', 'id'),
            dependencies: () => this.belongsToMany(CompanyResourceProgram, 'company_resource_program_dependencies', 'company_resource_program_id', 'dependency_id'),
            companyFiles: () => this.hasMany(CompanyFile),
            companyFilesFromLocale: () => this.hasMany(CompanyFile),
            companyProgram: () => this.belongsTo(CompanyProgram, 'company_program_id', 'id'),
            companyProgramResourceFilter: () => this.hasMany(CompanyProgramResourceFilter, 'resource_id', 'id').where(['component_name', '=', 'company_shared_document']),
        };
    }
}
