<template>
    <QuestionSimpleChoice
        v-model="value"
        :current-language="currentLanguage"
        :question-index="questionIndex"
        :is-completed="isCompleted"
        :control-answer="false"
        :show-next-question-select="showNextQuestionSelect"
        :question="question"
        :content="content"
        :contents="contents"
    />
</template>

<script>
import CompanyLanguage from '@/models/CompanyLanguage';
import QuestionSimpleChoice from '@/components/pages/resources/surveys/form/question/list/QuestionSimpleChoice.vue';

export default {
    name: 'QuestionYesNo',
    components: {
        QuestionSimpleChoice,
    },
    inject: ['$validator'],

    props: {
        value: {
            type: Array,
            required: true,
        },
        question: {
            type: Object,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
        contents: {
            type: Array,
            required: true,
        },
        currentLanguage: {
            type: CompanyLanguage,
            required: true,
        },
        questionIndex: {
            type: Number,
            required: true,
        },
        isCompleted: {
            type: Boolean,
            required: false,
            default: false,
        },
        showNextQuestionSelect: {
            type: Boolean,
            required: true,
        },
    },

    created() {
        if (this.value.length === 0) {
            this.createAnswers();
        }
    },

    methods: {
        createAnswers() {
            const newAnswerYes = {
                order: 1,
                placeholder: this.translate('Yes'),
                translations: {
                    text: {
                        [this.currentLanguage.key]: (this.currentLanguage.key === 'fr') ? 'Oui' : 'Yes',
                    },
                },
            };

            const newAnswerNo = {
                order: 2,
                placeholder: this.translate('No'),
                translations: {
                    text: {
                        [this.currentLanguage.key]: (this.currentLanguage.key === 'fr') ? 'Non' : 'No',
                    },
                },
            };

            this.value.push(newAnswerYes);
            this.value.push(newAnswerNo);
        },
    },
};
</script>
