<template>
    <div
        ref="message"
        class="message"
        :class="customStyle"
    >
        <div class="message-block">
            <div
                v-if="deletable"
                class="message-block-actions-wrapper"
            >
                <HtButton
                    size="small"
                    type="secondary"
                    @click.native="deleteMessage"
                >
                    <FontAwesomeIcon :icon="['far', 'trash-alt']" />
                </HtButton>
            </div>
            <div class="message-block-avatar">
                <div
                    v-if="displayAvatar"
                    v-user-image="image"
                    class="message-block-avatar-image"
                />
            </div>
            <div
                class="message-block-body"
                :class="{'has-date':displayDate}"
            >
                <div
                    v-if="displayDate"
                    class="message-block-body-useranddate"
                >
                    {{ messageDateFrom }}
                </div>
                <component
                    :is="component"
                    :company-chat-channel-message="companyChatChannelMessage"
                    :all-channel-message="allChannelMessage"
                    @focus="focus"
                    @blur="blur"
                />
            </div>
        </div>
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyChatChannelMessage from '@/models/CompanyChatChannelMessage';
import moment from 'moment';
import I18n from '@/modules/i18n/I18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ThinkingLoader from '@/components/chat/message/ThinkingLoader.vue';
import MessageText from './message/MessageText.vue';
import MessageOpentok from './message/MessageOpentok.vue';
import MessageResourceUserNudge from './message/MessageResourceUserNudge.vue';
import MessageResourceUserSurvey from './message/MessageResourceUserSurvey.vue';
import MessageResourceUserSurveyExtra from './message/MessageResourceUserSurveyExtra.vue';
import MessageResourceUserSurveyQuestionAnswer from './message/MessageResourceUserSurveyQuestionAnswer.vue';
import MessageResourceUserSurveyQuestionTemplate from './message/MessageResourceUserSurveyQuestionTemplate.vue';
import MessageFile from './message/MessageFile.vue';
import HtActionMenu from '../globals/HtActionMenu.vue';
import modalMixin from '../globals/modals/modalMixin';
import HtButton from '../globals/HtButton.vue';

export default {
    name: 'ChatListMessageItem',
    components: {
        MessageText,
        MessageOpentok,
        MessageResourceUserNudge,
        MessageResourceUserSurvey,
        MessageResourceUserSurveyQuestionTemplate,
        MessageResourceUserSurveyQuestionAnswer,
        MessageResourceUserSurveyExtra,
        MessageFile,
        HtActionMenu,
        FontAwesomeIcon,
        HtButton,
    },

    mixins: [
        modalMixin,
    ],
    props: {
        companyChatChannelMessage: {
            type: CompanyChatChannelMessage,
            required: true,
        },
        allChannelMessage: {
            type: Array,
            required: true,
        },
        displayAvatar: {
            type: Boolean,
            default: true,
        },
        displayDate: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isFocusing: false,
            isFocused: false,
        };
    },
    computed: {
        messageDateFrom() {
            const date = moment.tz(this.companyChatChannelMessage.created_at, moment.tz.guess());
            const now = this.$Utils.moment();
            const rawDiff = now.diff(date, 'seconds');
            const diff = rawDiff < 60
                ? I18n.translate('Just now')
                : rawDiff < 3600
                    ? Math.floor(rawDiff / 60) + I18n.translate('min')
                    : rawDiff < 3600 * 24
                        ? Math.floor(rawDiff / 3600) + I18n.translate('h')
                        : Math.floor(rawDiff / (3600 * 24)) + I18n.translate('days');

            return `${this.companyChatChannelMessage.company_user.firstname} - ${diff}`;
        },
        component() {
            switch (this.companyChatChannelMessage.type) {
            case 'text':
                return MessageText;
            case 'thinking':
                return ThinkingLoader;
            case 'capi-payload':
                return '';
            case 'opentok':
                return MessageOpentok;
            case 'file':
                return MessageFile;
            case 'resource':
                switch (this.companyChatChannelMessage.resource) {
                case 'company_user_nudge':
                    return MessageResourceUserNudge;
                case 'company_user_survey':
                    return MessageResourceUserSurvey;
                case 'company_user_survey_question':
                    return MessageResourceUserSurveyQuestionTemplate;
                case 'company_user_survey_question_answer':
                    return MessageResourceUserSurveyQuestionAnswer;
                case 'company_user_survey_extra':
                    return MessageResourceUserSurveyExtra;
                default:
                    return '';
                }
            default:
                return '';
            }
        },
        isMe() {
            return this.companyUser.id === this.shared.session.companyUser.id;
        },
        deletable() {
            // Checks whether the message was send by user & it isn't in a bot channel
            return this.isMe && this.companyChatChannel.created_by_company_user_id;
        },
        companyUser() {
            return this.companyChatChannelMessage.company_user;
        },
        customStyle() {
            const customStyle = [];

            if (this.deletable) {
                customStyle.push('editable');
            }

            if (this.isMe) {
                customStyle.push('right');
            } else {
                customStyle.push('left');
            }

            if (this.displayDate) {
                customStyle.push('mt-4');
                if (!this.displayAvatar) {
                    customStyle.push('first-message');
                }
            } else {
                customStyle.push('mt-2');
                if (this.displayAvatar) {
                    customStyle.push('last-message');
                } else {
                    customStyle.push('middle-message');
                }
            }

            if (this.isFocusing) {
                customStyle.push('focusing');
            }

            if (this.isFocused) {
                customStyle.push('focused');
            }

            return customStyle;
        },
        image() {
            return {
                firstname: this.companyUser.firstname,
                lastname: this.companyUser.lastname,
                image: this.companyUser.image,
            };
        },
        companyChatChannel() {
            if (!this.shared.socket.channelCollection) return null;
            return this.shared.socket.channelCollection.channelActive;
        },
        channelMessageObject() {
            return this.shared.socket.channelMessageCollection[this.companyChatChannel.id];
        },
    },

    shared: {
        session: {
            companyUser: {
                type: CompanyUser,
                default: {},
            },
        },
    },

    methods: {
        deleteMessage() {
            this.genericConfirm(
                this.translate('Confirmation needed'),
                this.translate("You're about to delete this message permanently."),
            ).then(() => {
                this.companyChatChannelMessage.delete().then(() => {
                    const indexDeletedMessage = this.channelMessageObject.messages
                        .findIndex((message) => message.id === this
                            .companyChatChannelMessage.id);
                    if (indexDeletedMessage !== -1) {
                        this.channelMessageObject.messages.splice(indexDeletedMessage, 1);
                    }
                });
            });
        },

        focus() {
            this.isFocusing = true;
            this.isFocused = false;
            this.$nextTick().then(() => {
                this.isFocusing = false;
                this.isFocused = true;
                this.$emit('scrollToTop');
            });
        },
        blur() {
            this.isFocusing = false;
            this.isFocused = false;
            this.$nextTick(() => {
                this.$emit('scrollToBottom');
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.message {
    &.focusing > .message-block,
    &.focused > .message-block {
        position: absolute;
        width: calc(100% - 40px);
        transition: bottom 0.2s ease 0s;
    }

    &.focusing > .message-block {
        bottom: calc(30px + 1.15rem);
    }

    &.focused > .message-block {
        bottom: 60%;
    }

    &.focused:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #0000004D;
    }

    &.editable {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        &:hover {
            cursor: pointer;
            position: relative;
            &::before{
                content: " ";
                position: absolute;
                inset: -4px -20px;
                background-color: $neutral-200;
                border-top: 1px solid $neutral-300;
                border-bottom: 1px solid $neutral-300;
            }
            .message-block-actions-wrapper {
                display: flex;

                .ht-button {
                    background: $neutral-white;
                }
            }

        }
    }

    &-block {
        position: relative;
        display: flex;

        &-avatar {
            width: 30px;
            height: 30px;
            align-self: end;

            &-image {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .message-block-actions-wrapper{
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            gap: 4px;
            display: none;
        }

        &-body {
            width: 80%;
            font-size: 15px;

            &.has-date
            {
                margin-top: 15px;
            }

            &-useranddate {
                font-size: 10px;
                font-weight: bold;
            }
        }
    }

    &-date {
        margin-top: 10px;
        font-size: 10px;
        font-weight: bold;
    }

    &.left {
        .message-block-body {
            margin-left: 10px;
        }

        &.first-message {
            .message-block {
                &-body {
                    .item-text {
                        border-bottom-left-radius: 0;
                    }
                }
            }
        }

        &.middle-message {
            .message-block {
                &-body {
                    .item-text {
                        border-bottom-left-radius: 0;
                        border-top-left-radius: 0;
                    }
                }
            }
        }

        &.last-message {
            .message-block {
                &-body {
                    .item-text {
                        border-top-left-radius: 0;
                    }
                }
            }
        }
    }

    &.right {
        align-items: flex-end;

        &.first-message {
            .message-block {
                &-body {
                    .item-text {
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }

        &.middle-message {
            .message-block {
                &-body {
                    .item-text {
                        border-bottom-right-radius: 0;
                        border-top-right-radius: 0;
                    }
                }
            }
        }

        &.last-message {
            .message-block {
                &-body {
                    .item-text {
                        border-top-right-radius: 0;
                    }
                }
            }
        }

        .message-block {
            width: 100%;
            flex-direction: row-reverse;
        }

        .message-block-body {
            position: relative;
            display: flex;
            justify-content: flex-end;
            margin-right: 10px;

            &-useranddate {
                position: absolute;
                top: -15px;
                text-align: right;
                font-size: 10px;
                font-weight: bold;
            }
        }

        .message-date {
            text-align: right;
        }
    }
}

</style>
