<template>
    <div
        class="parameters-box-wrapper"
        :class="{'parameters-box-wrapper-with-title': title,'parameters-box-wrapper-without-title': !title}"
    >
        <h2
            v-if="title"
            class="branding-color"
        >
            {{ title }}
        </h2>
        <HtFormCard
            v-if="hasDefaultSlot"
            class="parameters-box"
        >
            <slot />
        </HtFormCard>
    </div>
</template>

<script>
import HtFormCard from './HtFormCard.vue';

export default {
    name: 'ParametersBox',
    components: { HtFormCard },
    inject: ['$validator'],

    props: {
        title: {
            type: String,
        },
    },

    computed: {
        hasDefaultSlot() {
            return 'default' in this.$slots;
        },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

    h2 {
        margin: 0 0 1.6rem 0;
    }

    .parameters-box {
        color: $grey-ultra-dark;
    }
</style>
<style lang="scss">
    .parameters-box .ht-form-optional {
        color: #C4C4C4;
    }

    .parameters-box-wrapper ~ .parameters-box-wrapper-with-title {
        margin-top: 3.2rem;
    }

    .parameters-box-wrapper-with-title ~ .parameters-box-wrapper-without-title {
        margin-top: 1.6rem;
    }

    .parameters-box-wrapper .item {
        background-color: transparent;
    }
</style>
