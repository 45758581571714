<template>
    <div
        class="ht-bubble"
        :class="color"
    >
        <slot>{{ item }}</slot>
    </div>
</template>

<script>
export default {
    name: 'HtBubble',
    props: {
        item: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped src="./HtBubble.scss"></style>
