<template>
    <div v-if="companyUserSoftwares.isLoaded()">
        <HtAccordion
            :title="translate('Software')"
            :count="companyUserSoftwares.$.models.length"
            :has-button="companyUserSoftwares.$.models.length === 0"
            :button-label="addButtonLabel"
            :is-branding="false"
            cypress="software"
            @buttonClick="openSelectModal()"
        >
            <template v-if="companyUserSoftwares.$.models.length > 0">
                <HtTable
                    :items="companyUserSoftwares.$.models"
                    :columns="columns"
                    cypress="software-table"
                    @onCellClicked="openEditModal"
                >
                    <template #cell(name)="scope">
                        <div>{{ scope.item.company_software.resource.name }}</div>
                        <div
                            v-if="scope.item.company_software.resource.description"
                            v-dompurify-html="scope.item.company_software.resource.description"
                        />
                    </template>
                </HtTable>
                <div class="mt-4 text-center">
                    <Button
                        type="clear"
                        cypress="software-add"
                        @click="openSelectModal()"
                    >
                        <slot :name="'buttonText'">
                            {{ translate(addButtonLabel) }}
                        </slot>
                    </Button>
                </div>
            </template>

            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No software added</t>
                </strong>
            </div>
        </HtAccordion>

        <modalable
            ref="modalableSelect"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserSoftwareSelect
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                :selected-ids="selectedItemIds"
                @onUpdate="onUpdate(...arguments)"
            />
        </modalable>

        <modalable
            ref="modalableEdit"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserSoftwareEdit
                :id="currentId"
                :company-user-id="companyUserId"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </modalable>
    </div>
</template>
<script>
import CompanyUserSoftwareCollection from '@/models/CompanyUserSoftwareCollection';
import UserSoftwareEdit from '@/components/pages/dashboard/modals/software/UserSoftwareEdit.vue';
import UserSoftwareSelect from '@/components/pages/dashboard/modals/software/UserSoftwareSelect.vue';
import HtAccordion from '@/components/globals/HtAccordion.vue';

export default {
    name: 'UserSoftwareFlow',
    components: {
        UserSoftwareSelect,
        UserSoftwareEdit,
        HtAccordion,
    },
    props: {
        companyUserId: {
            type: Number,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                },
            ],
            currentId: null,
            companyUserSoftwares: new CompanyUserSoftwareCollection([
                'id',
                'company_user_id',
                'company_user_program_id',
                'company_software_id',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select(['id', 'datetime_start', 'datetime_end']);
                },
                companySoftware: (query) => {
                    query.select(['id', 'company_configuration_id']).with({
                        resource: (query) => {
                            query.select([
                                'name', 'description',
                            ]);
                        },
                    }).withTrashed(true);
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image']);
                },
            }).where([
                ['company_user_program_id', '=', this.companyUserProgramId],
            ]).orderBy([['name', 'asc']]),

            addButtonLabel: 'Add software',
        };
    },

    computed: {
        selectedItemIds() {
            return this.companyUserSoftwares.$.models.map((item) => item.company_software_id);
        },
    },

    created() {
        this.companyUserSoftwares.get().then(() => {
            this.$emit('on-load', this.companyUserSoftwares.$.models.length);
        });
    },

    methods: {
        onUpdate() {
            this.companyUserSoftwares.get();
        },

        onDelete() {
            this.companyUserSoftwares.get();
        },

        openEditModal(row) {
            this.currentId = row.item.id;
            this.$refs.modalableEdit.open();
        },

        openSelectModal() {
            this.$refs.modalableSelect.open();
        },
    },
};
</script>
<!-- TODO: check if needed -->
<style lang="scss" scoped>
    @import '~@/styles/var';

    .item-content.limit-width {
        max-width: 420px;

        span {
            word-wrap: break-word !important;
        }
    }

    .head {
        margin-top: 0.5em;
        font-family: $lato-bold;
    }
    .item-resource-icon {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .image-wrapper {
        height: 100%;
    }
</style>
