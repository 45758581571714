import Vue from 'vue';

/**
 * @typedef {"email" | "sms"} NotificationTypes
 */

export default {
    /**
     * @param {any} data
     * @returns {Promise}
     */
    fetchAll() {
        return Vue.prototype.$http.get('configuration/email-templates/list');
    },

    update(data = {}) {
        return Vue.prototype.$http.post('configuration/email-templates/update', data);
    },

    /**
     *
     * @param {Object} data the set of data
     * @param {Number} data.id Id of the notification
     * @param {NotificationTypes} data.type the type of notification to toggle
     * @param {Boolean} data.isActive the value to pass
     */
    toggle(data) {
        return Vue.prototype.$http.post('configuration/email-templates/toggle', data);
    },
};
