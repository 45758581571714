<template>
    <div v-if="companyUserNotification.isLoaded()">
        <HtTable
            v-if="companyUserNotification.models.length > 0"
            :items="companyUserNotification.models"
            :columns="columns"
            cypress="user-activities"
            :empty-message="'You have no emails yet...'"
            @onCellClicked="displayEmail"
        >
            <template #cell(subject)="scope">
                <div
                    class="subject"
                    :style="scope.size"
                >
                    <template v-if="scope.item.generated_subject !== null">
                        {{ scope.item.generated_subject }}
                    </template>
                    <template v-else>
                        {{ scope.item.subject }}
                    </template>
                </div>
            </template>

            <template #cell(status)="scope">
                <div
                    v-if="scope.item.status !== undefined"
                    :style="scope.size"
                    style="text-align: center"
                >
                    <div v-if="scope.item.status === 'to_deliver'">
                        <t>Not delivered yet</t>
                    </div>
                    <div v-else>
                        <t>{{ scope.item.status }}</t>
                    </div>
                </div>
                <div
                    v-else
                    :style="scope.size"
                    style="text-align: center"
                >
                    <t>No result</t>
                </div>
            </template>

            <template #cell(sent_to)="scope">
                <div v-if="scope.item.email_sent_to === null || scope.item.email_sent_to === undefined">
                    -
                </div>
                <div
                    v-else
                    style="max-width:$desktop-large"
                >
                    <div class="overflow-ellipsis">
                        {{ scope.item.email_sent_to }}
                    </div>
                </div>
            </template>

            <template #cell(delivered_at)="scope">
                <div v-if="scope.item.delivered_at === null">
                    <t>Undelivered</t>
                </div>
                <div
                    v-else
                    :style="scope.size"
                >
                    <FontAwesomeIcon
                        :icon="['far', 'clock']"
                        size="1x"
                    />
                    {{ $Utils.moment(scope.item.delivered_at).format(`DD/MM [${translate('at')}] HH:mm`) }}
                </div>
            </template>
        </HtTable>
        <EmailNotificationDisplayModal
            v-if="selectedEmailNotification"
            ref="emailModal"
            :email="selectedEmailNotification"
        />
    </div>
</template>

<script>
import CompanyUserNotificationCollection from '@/models/CompanyUserNotificationCollection';
import EmailNotificationDisplayModal from './EmailNotificationDisplayModal.vue';

export default {
    name: 'EmailsActivities',
    components: {
        EmailNotificationDisplayModal,
    },

    props: {
        company_user_id: { type: Number, required: true },
    },

    data() {
        return {
            companyUserNotification: new CompanyUserNotificationCollection([
                'id',
                'company_id',
                'to_company_user_id',
                'related_company_user_id',
                'media',
                'template',
                'schedule_at',
                'subject',
                'generated_subject',
                'generated_content',
                'status',
                'delivered_at',
                'email_sent_to',
                'permissions',
            ])
                .where([
                    ['related_company_user_id', '=', this.company_user_id],
                    ['to_company_user_id', '=', this.company_user_id, 'or'],
                ])
                .where([
                    ['status', '=', 'delivered'],
                    ['status', '=', 'to_deliver', 'or'],
                ])
                .where([
                    ['media', '!=', 'web_ui'],
                ])
                .orderBy([
                    ['id', 'desc'],
                ])
                .limit(20),
            selectedEmailNotification: null,
            columns: [
                {
                    label: this.translate('Subject'),
                    key: 'subject',
                },
                {
                    label: this.translate('Status'),
                    key: 'status',
                },
                {
                    label: this.translate('Sent To'),
                    key: 'sent_to',
                },
                {
                    label: this.translate('Date'),
                    key: 'delivered_at',
                    class: 'col-2 text-right',
                },
            ],
        };
    },

    created() {
        if (this.companyUserNotification.models.length === 0) {
            this.companyUserNotification.get();
        }
    },

    methods: {
        displayEmail({ item }) {
            this.selectedEmailNotification = item;
            this.$nextTick(() => this.$refs.emailModal.open());
        },
    },
};
</script>

<style lang="scss" scoped>
.subject {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
</style>
