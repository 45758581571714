<template>
    <PageWithSidebar v-if="permissionsLoaded && companyProgram.isLoaded()">
        <template #title>
            <HtPageTitle :back-route="{name: 'Programs'}">
                <t>{{ companyProgram.name_translated }}</t>
            </HtPageTitle>
        </template>

        <template #sidebar>
            <HtPageSidebar :pages="items" />
        </template>
        <router-view
            :program-type="companyProgram.ht_program_type.slug"
            :company-program="companyProgram"
        />
    </PageWithSidebar>
</template>

<script>
import HtPageSidebar from '@/components/globals/HtPageSidebar.vue';
import HtPageTitle from '@/components/globals/HtPageTitle.vue';
import CompanyProgram from '@/models/CompanyProgram';
import PageWithSidebar from '@/layouts/PageWithSidebar.vue';

const pages = [
    {
        routeName: 'ProgramDetailLive',
        label: 'Ongoing',
    },
    {
        routeName: 'ProgramDetailDraft',
        label: 'Draft',
    },
    {
        routeName: 'ProgramDetailDone',
        label: 'Completed',
    },
    {
        routeName: 'ProgramDetailProbation',
        label: 'Probationary periods',
    },
    {
        routeName: 'ProgramDetailSimulation',
        label: 'Simulate a program',
    },
    {
        routeName: 'ProgramDetailInvitations',
        label: 'Invitations',
    },
];

export default {
    name: 'ProgramDetail',
    permissions: pages.map((page) => `Page${page.routeName}`),

    components: {
        PageWithSidebar,
        HtPageSidebar,
        HtPageTitle,
    },

    props: {
        companyProgramId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            companyProgram: new CompanyProgram(['id', 'tasks'])
                .with({
                    locales: (query) => query.select(['language_key', 'name']),
                    htProgramType: (query) => query.select(['id', 'slug']),
                    keyDate: (query) => {
                        query.select(['id', 'company_program_id']).with({
                            locales: (query) => query
                                .select(['id', 'name', 'language_key']),
                        });
                    },
                    entities: (query) => query.select(['company_entity_id']),

                })
                .where(['id', '=', this.companyProgramId]),
        };
    },

    computed: {
        items() {
            const params = { program_id: this.companyProgramId };
            if (this.permissionsLoaded) {
                return pages
                    .filter((page) => {
                        let options = {};
                        if (page.routeName === 'ProgramDetailProbation') {
                            options = params;
                        }
                        return this.$can(`Page${page.routeName}`, options);
                    })
                    .map((page) => ({
                        name: page.routeName,
                        label: this.translate(page.label),
                        params,
                    }));
            }

            return [];
        },
    },

    watch: {
        $route() {
            this.redirect();
        },

        items() {
            this.redirect();
        },
    },

    created() {
        this.redirect();

        const iconAction = () => { this.$router.push({ name: 'Programs' }); };
        this.setPage({
            title: this.translate('All Programs'),
            icon: ['far', 'arrow-left'],
            iconAction,
        });
        this.companyProgram.get();
    },

    methods: {
        redirect() {
            if (this.items.length > 0 && this.$route.name === 'ProgramDetail') {
                this.$router.push({ name: this.items[0].name, params: { program_id: this.companyProgramId } });
            }
        },
    },
};
</script>
