import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import Resource from '@/models/Resource';

export default class ResourceCollection extends ModelCollection {
    modelConfig() {
        return {
            model: Resource,
        };
    }
}
