<template>
    <div v-if="value">
        <form>
            <HtFormInput
                id="name"
                ref="nameInput"
                v-model="value.name"
                v-validate.disable="'required|max:255'"
                class="reset-ht-form-bottom-margin"
                name="name"
                :label="translate('Department name')"
                :data-vv-as="translate('department name')"
                :show-optional="false"
            />
            <div
                v-if="errors.collect()['name']"
                class="ht-form-error"
            >
                {{ errors.collect()['name'][0] }}
            </div>
            <HtFormInput
                id="description"
                ref="descriptionInput"
                v-model="value.description"
                v-validate.disable="'max:255'"
                class="reset-ht-form-bottom-margin"
                name="description"
                :label="translate('Description')"
                :data-vv-as="translate('description')"
                :show-optional="false"
            />
            <div
                v-if="errors.collect()['description']"
                class="ht-form-error"
            >
                {{ errors.collect()['description'][0] }}
            </div>
        </form>

        <EntityModelCustomFieldGroup :value="value" />
    </div>
</template>

<script>
import EntityModelCustomFieldGroup from '../EntityModelCustomFieldGroup.vue';

export default {
    name: 'EditDepartment',
    components: { EntityModelCustomFieldGroup },
    props: {
        /**
         * @type {import('vue').PropOptions<{
         *     id: number,
         *     name: string,
         *     description: string,
         *     is_heyteam: boolean,
         *     custom_fields: {
         *         id: number,
         *         company_field_template_id: number,
         *         name: string,
         *         key: string,
         *         type: string,
         *         value: any,
         *         is_editable: boolean
         *     }[]
         * }>}
         */
        value: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
form {
    display: flex;
    flex-direction: column;
}

.ht-form-error {
    margin-top: 0.8rem;
}

.reset-ht-form-bottom-margin + .reset-ht-form-bottom-margin,
.ht-form-error + .reset-ht-form-bottom-margin {
    margin-top: 3.2rem;
}

.reset-ht-form-bottom-margin {
    margin-bottom: 0;
}

.button-container {
    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 0.8rem;

    margin-bottom: 3.2rem;
}
</style>
