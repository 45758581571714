<template>
    <div
        v-if="companyMedias.isLoaded()"
        class="page-content"
    >
        <h1 class="small">
            {{ getTranslatedMessage(companyFlowCopy['bloc_welcome_title']['content']['messages']['message_1']) }}
        </h1>

        <FlowRow
            v-if="canChooseLanguage"
            :company-user="bubbleUser"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <HtFormattedText :text="getTranslatedMessage(companyFlowCopy['bloc_welcome_language']['content']['messages']['message_1'])" />
            </template>
        </FlowRow>

        <FlowRow
            v-if="canChooseLanguage"
            :company-user="companyUser"
            :show-buttons="true"
            :reversed="true"
            :full="true"
            :can-skip="false"
            @onLoadRow="$emit('onLoadRow')"
            @onValidate="onValidateLanguageChange"
        >
            <template slot="content">
                <HtLanguageSelect
                    id="language"
                    name="language"
                    class="mt-3"
                    :value="companyUser.company_language"
                    :custom-option-label="customLanguageOptionLabel"
                    :label="false"
                    @input="userLanguageChanged"
                />
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_welcome_a'] === true"
            :company-user="bubbleUser"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <HtFormattedText :text="getTranslatedMessage(companyFlowCopy['bloc_welcome_a']['content']['messages']['message_1'])" />
            </template>
        </FlowRow>

        <template v-if="messages['bloc_welcome_b'] === true && value.chatbot.slug === 'full'">
            <FlowRow
                v-if="companyMedias.$.models.length"
                :company-user="bubbleUser"
                :full="true"
                @onLoadRow="$emit('onLoadRow')"
            >
                <template slot="content">
                    <template v-if="currentMedia">
                        <div
                            v-if="currentMedia.type === 'link'"
                            class="video-wrapper"
                        >
                            <iframe
                                :src="$Utils.embedVideo(currentMedia.url)"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            />
                        </div>
                        <video
                            v-else
                            controls
                            controlsList="nodownload"
                            width="100%"
                            @click.stop
                        >
                            <source :src="getLink()">
                        </video>
                    </template>
                </template>
            </FlowRow>

            <FlowRow
                v-else
                :company-user="bubbleUser"
                @onLoadRow="$emit('onLoadRow')"
            >
                <template slot="content">
                    <img src="/static/images/good-job2.png">
                </template>
            </FlowRow>
        </template>

        <FlowRow
            v-if="messages['bloc_welcome_c'] === true"
            :company-user="bubbleUser"
            :full="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <HtFormattedText :text="getTranslatedMessage(companyFlowCopy['bloc_welcome_c']['content']['messages']['message_1'])" />
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_welcome_d'] === true"
            :company-user="bubbleUser"
            :full="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <HtFormattedText :text="getTranslatedMessage(companyFlowCopy['bloc_welcome_d']['content']['messages']['message_1'])" />
                <div class="content-bloc">
                    <div class="content-item">
                        <div class="wrapper-img">
                            <img src="/static/images/flow/user.svg">
                        </div>
                        <span>
                            <HtFormattedText :text="getTranslatedMessage(companyFlowCopy['bloc_welcome_d']['content']['messages']['message_2'])" />
                        </span>
                    </div>
                    <div class="content-item">
                        <div class="wrapper-img">
                            <img src="/static/images/flow/book.svg">
                        </div>
                        <span>
                            <HtFormattedText :text="getTranslatedMessage(companyFlowCopy['bloc_welcome_d']['content']['messages']['message_3'])" />
                        </span>
                    </div>
                    <div class="content-item">
                        <div class="wrapper-img">
                            <img src="/static/images/flow/landscape.svg">
                        </div>
                        <span>
                            <HtFormattedText :text="getTranslatedMessage(companyFlowCopy['bloc_welcome_d']['content']['messages']['message_4'])" />
                        </span>
                    </div>
                    <div class="content-item">
                        <div class="wrapper-img">
                            <img src="/static/images/flow/files.svg">
                        </div>
                        <span>
                            <HtFormattedText :text="getTranslatedMessage(companyFlowCopy['bloc_welcome_d']['content']['messages']['message_5'])" />
                        </span>
                    </div>
                </div>
            </template>
        </FlowRow>

        <FlowRow
            v-if="messages['bloc_welcome_e'] === true"
            :company-user="bubbleUser"
            :full="true"
            @onLoadRow="$emit('onLoadRow')"
        >
            <template slot="content">
                <HtFormattedText :text="getTranslatedMessage(companyFlowCopy['bloc_welcome_e']['content']['messages']['message_1'])" />
            </template>
        </FlowRow>
    </div>
</template>

<script>
import CompanyMediaCollection from '@/models/CompanyMediaCollection';
import FlowMixin from '@/components/mixins/FlowMixin.vue';
import HtFormattedText from '@/components/globals/HtFormattedText.vue';

import FlowRow from '@/components/flow/FlowRow.vue';
import HtLanguageSelect from '@/components/globals/HtLanguageSelect.vue';

export default {
    name: 'FlowHello',
    components: {
        HtLanguageSelect,
        FlowRow,
        HtFormattedText,
    },
    mixins: [FlowMixin],

    data() {
        return {
            rowLabel: [],
            globalTimeout: null,
            iMessageIndex: 0,
            companyFlowCopy: {},
            messages: {},
            messagesEnabled: [],
            src: null,
            currentMedia: null,
            companyMedias: new CompanyMediaCollection(
                ['id', 'is_landing', 'url', 'type'],
            ).with({
                companyFile: (query) => {
                    query.select(['id', 'original_name', 'path', 'name', 'uuid', 'detected_extension', 'detected_mime_type']);
                },
            }).where([['is_landing', '=', 1]]),
        };
    },

    computed: {
        canChooseLanguage() {
            return this.messages.bloc_welcome_language === true && this.shared.session.company.company_languages.enabled.length > 1;
        },
    },

    async created() {
        this.contents.sort((a, b) => (a.order > b.order ? 1 : -1));

        this.contents.map((model) => {
            this.companyFlowCopy[model.subtype] = model;

            if (!model.subtype.includes('_title')) {
                this.rowLabel.push(model.subtype);
                this.$set(this.messages, model.subtype, false);
                if (model.is_enabled) this.messagesEnabled.push(model.subtype);
            }
        });

        if (!this.messagesEnabled.length) this.$emit('onNextPage');

        await this.companyMedias.get();
        if (this.companyMedias.$.models.length) this.currentMedia = this.companyMedias.$.models[0];

        this.loadRows();
        this.runLoadRows();
    },

    methods: {
        runLoadRows() {
            this.globalTimeout = setInterval(() => {
                this.loadRows();
            }, 1500);
        },

        loadRows() {
            while (this.iMessageIndex < this.rowLabel.length && this.companyFlowCopy[this.rowLabel[this.iMessageIndex]].is_enabled === false) {
                this.iMessageIndex++;
            }

            if (this.iMessageIndex < this.rowLabel.length) {
                if (this.companyFlowCopy[this.rowLabel[this.iMessageIndex]].is_enabled) this.messages[this.rowLabel[this.iMessageIndex]] = true;

                if (this.messagesEnabled.indexOf(this.rowLabel[this.iMessageIndex]) === (this.messagesEnabled.length - 1)) this.$emit('onLoadPage', true);

                this.iMessageIndex++;
            } else clearInterval(this.globalTimeout);
        },

        getLink() {
            return this.$Utils.resolveS3PublicImage(this.currentMedia.company_file.path + this.currentMedia.company_file.name);
        },

        userLanguageChanged(language) {
            this.companyUser.company_language_id = language.id;
        },

        onValidateLanguageChange() {
            this.companyUser.save().then(() => {
                this.shared.session.companyUser.fill(this.companyUser);
                this.$I18n.getTranslationPack(this.companyUser.company_language.key).then(() => {
                    window.localStorage.setItem('language', this.companyUser.company_language.key);
                    this.$emit('reloadPage');
                });
            });
        },

        customLanguageOptionLabel(language) {
            return language.name;
        },
    },
};
</script>
