<template>
    <div v-if="item">
        <div class="planning-view-modal">
            <dl>
                <div
                    v-if="item.type === 'file'"
                    class="item roll-icon image"
                    :style="'background-image:url('+
                        $Utils.resolveS3PublicImage(item.file.path + item.file.name)
                        +')'"
                />
                <iframe
                    v-if="item.type === 'link'"
                    :src="$Utils.embedVideo(item.url)"
                />
            </dl>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CarouselItem',
    data() {
        return {
            item: null,
        };
    },

    methods: {
        init(company_media) {
            this.item = company_media;
        },

        onClose() {
            this.$emit('onClose');
        },
    },
};
</script>

<style lang="scss" scoped>
    iframe {
        min-height: 500px !important;
        max-height: 550px !important;
        width: 100%;
    }

    .image {
        width: 100%;
        height: 100%;
        min-height: 500px;

        background-repeat: no-repeat;
        background-size: contain;
    }

    h2 {
        font-size: 2.7rem;
        text-align: center;
        text-transform: none;
    }

    dl {
        margin-bottom: 3em;
        dt {
            font-weight: bold;
            margin-bottom: 0.5rem;
        }

        dd {
            margin: 15px 0 2.5rem;
            &.participants {
                display: inline-flex;
                > .item-image {
                    height: 32px;
                    width: 32px;
                    border-style: none;
                    border-radius: 1rem;
                    margin-right: 1rem;
                    background-size: cover;
                }
            }
        }
    }

    .check {
        align-items: center;
        border: 1px solid #e5e6e6;
        border-radius: 50%;
        display: inline-flex;
        height: 2.7rem;
        justify-content: center;
        margin-right: 0.6rem;
        width: 2.7rem;

        &:before {
            color: #fff;
            content: '\002714';
            opacity: 0;
            text-shadow: 0 1px darken(#61dcb4, 7%);
            transform: translateX(1px);
        }

        &.checked {
            background: #61dcb4;
            border-color: transparent;

            &:before {
                opacity: 1;
            }
        }
    }

    .edit, .delete {
        cursor: pointer;
        font-size: 0.95em;
        font-weight: bold;
        margin-top: 2rem;
        text-decoration: underline;
    }

</style>
