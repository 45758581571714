import Vue from 'vue';
import RtwSharedSpace from '@tony.caron/rtw-shared-space';

export default {
    async update(userId, id, data = {}) {
        let client = Vue.prototype.$http;

        if (RtwSharedSpace.store.session.storageMode === 'numspot') {
            const responseData = await Vue.prototype.$http.get('multi-cloud/get-token');

            if (responseData.status === 200) {
                client = Vue.prototype.$numspotHttp;

                delete client.defaults.headers['X-Numspot-Token'];

                data.jwt = responseData.data.message;
            } else {
                console.error('Fail to fetch multi cloud token');
            }
        }

        return client.put(`users/${userId}/requirements/${id}`, data);
    },

    generate(userId, id, data = {}) {
        return Vue.prototype.$http.post(`users/${userId}/requirements/${id}/generate`, data);
    },
};
