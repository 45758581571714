export default {

    // Default image name in database are considered as no image
    getImage(binding, vnode) {
        const image = binding.value && binding.value.image ? vnode.context.$Utils.resolveS3PublicImage(binding.value.image) : '';

        if (
            image.length == 0
			|| image.search(/default-user\.png$/) !== -1
			|| image.search(/member\.png$/) !== -1
        ) {
            return null;
        }

        return image;
    },

    /* Three cases
		1/ We don't have an image and we want to show the default image represent an user
		2/ We don't have an image and we want to show the initial
		3/ We have an user image and we want to show this image
	*/
    bind(el, binding, vnode) {
        const fontSize = binding.value && binding.value.size ? binding.value.size : 1.5;

        const image = binding.def.getImage(binding, vnode);

        el.title = (`${binding.value.firstname} ${binding.value.lastname}`).trim();

        let initials = binding.value.firstname[0].toUpperCase();

        if (binding.value.lastname && binding.value.lastname !== '') {
            initials += binding.value.lastname[0].toUpperCase();
        }

        if (image) {
            el.style.backgroundImage = `url(${image})`;
            el.style.backgroundSize = 'cover';
            el.style.backgroundPosition = 'center center';
            el.innerHTML = '';
        } else {
            el.className += ' user-initials';
            el.style.fontSize = `${fontSize}rem`;
            el.innerHTML = `<span>${initials}</span>`;
            el.style.backgroundImage = '';
        }
    },

    update(el, binding, vnode) {
        binding.def.bind(el, binding, vnode);
    },
};
