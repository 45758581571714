<template>
    <div class="layout">
        <div class="layout__title">
            <slot name="title" />
        </div>

        <div class="layout__sidebar">
            <slot name="sidebar" />
        </div>

        <div class="layout__container">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageWithSidebar',
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.layout {
    display: flex;
    height: 100%;

    .layout__container {
        flex-grow: 1;
        margin-left: 20px;
        overflow-x: auto;
    }
}

@media (max-width: $tablet) {
    .layout {
        flex-flow: column;

        .layout__sidebar {
            display: flex;
            justify-content: center;
        }

        .layout__container {
            margin: 0 16px 20px;
            overflow: hidden;
        }
    }
}
</style>
