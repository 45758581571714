<template>
    <HtCard>
        <CompanySettingsInformation :editing-lang.sync="editingLang" />

        <CarouselList :editing-lang="editingLang" />

        <BenefitList :editing-lang="editingLang" />

        <ValueList :editing-lang="editingLang" />

        <DocumentPublicList :editing-lang="editingLang" />
    </HtCard>
</template>
<script>

import CompanySettingsInformation from '@/components/CompanySettingsInformations.vue';

import ValueList from '@/components/resources/value/ValueList.vue';
import BenefitList from '@/components/resources/benefit/BenefitList.vue';
import DocumentPublicList from '@/components/resources/documentPublic/DocumentPublicList.vue';
import CarouselList from '@/components/resources/carousel/CarouselList.vue';

export default {
    name: 'PageCompany',
    components: {
        CompanySettingsInformation,
        CarouselList,
        ValueList,
        BenefitList,
        DocumentPublicList,
    },
    data() {
        return {
            editingLang: this.shared.session.company.company_language.key,
        };
    },
};

</script>
