<template>
    <div>
        <template v-if="item && item.isLoaded() && !hasError && companyUserProgram.isLoaded()">
            <form
                class="form-2"
                data-cy="email-form"
            >
                <HtFormInput
                    id="name"
                    v-model.trim="item.name"
                    v-validate.disable="'required'"
                    name="name"
                    cypress="name"
                    :label="translate('Name')"
                    :data-vv-as="translate('name')"
                />

                <HtFormInput
                    id="subject"
                    v-model.trim="item.subject"
                    v-validate.disable="'required'"
                    name="subject"
                    cypress="subject"
                    :label="translate('Subject')"
                    :data-vv-as="translate('e-mail subject')"
                    :variables="variables"
                />

                <HtFormUserOrRoleSelect
                    id="sender"
                    v-model="item.company_user_sender"
                    v-validate.disable="'required'"
                    :data-vv-as="translate('sender')"
                    :label="translate('Sender')"
                    name="sender"
                    cypress="sender"
                    show-asterisk
                    @on-add="openAddSenderModal"
                    @on-remove="onRemoveSender"
                />

                <fieldset :class="{ error: errors.has('recipients') }">
                    <label>
                        <t>Recipients</t>
                        *</label>
                    <ParticipantList
                        can-add
                        type="stacked"
                        has-group
                        cypress="recipients"
                        :users="item.company_user_email_custom_recipient.models"
                        @onAdd="openAddRecipientsModal"
                    />

                    <input
                        v-model="item.company_user_email_custom_recipient.models"
                        v-validate.disable="'array'"
                        type="hidden"
                        name="participants"
                        :data-vv-as="translate('recipients')"
                    >
                </fieldset>

                <HtUserProgramList
                    v-model="item.company_user_program_id"
                    :company-user-id="companyUserId"
                    disabled
                />

                <HtKeyDatesInputOrder
                    v-if="companyUser.isLoaded() && userTask"
                    :end-label="translate('Sending date')"
                    :end-tooltip="translate('This email will be sent on the selected timeline')"
                    :resource-id="item.id"
                    resource-type="company_email_custom"
                    :user-program-id="item.company_user_program_id"
                    :is-business-day.sync="userTask.is_business_day"
                    :offset-key-date.sync="userTask.company_user_program_key_date_id"
                    :offset-availability-specific-date.sync="userTask.datetime_availability"
                    :offset-availability-number.sync="userTask.offset_availability_number"
                    :offset-availability-unit.sync="userTask.offset_availability_unit"
                    :offset-end-specific-date.sync="userTask.datetime_end"
                    :offset-end-number.sync="userTask.offset_end_number"
                    :offset-end-unit.sync="userTask.offset_end_unit"
                    can-select-specific-date
                    :dependents.sync="userTask.dependents.models"
                    :dependencies.sync="userTask.dependencies.models"
                />

                <fieldset :class="{ error: errors.has('content') }">
                    <HtFormEditor
                        id="content"
                        v-model.trim="item.content"
                        v-validate.disable="'required'"
                        name="content"
                        cypress="content"
                        :label="translate('Message')"
                        :data-vv-as="translate('content')"
                        :variables="variables"
                    />
                </fieldset>

                <fieldset class="medium">
                    <HtFormEditor
                        id="signature"
                        ref="signatureInput"
                        v-model="item.signature"
                        name="signature"
                        cypress="signature"
                        :label="translate('Signature')"
                        :data-vv-as="translate('signature')"
                        :variables="variables"
                        minimal
                    />
                </fieldset>

                <fieldset style="margin: 15px 0">
                    <HtFormSwitch
                        id="file-join"
                        v-model="is_file_box_checked"
                        :label="translate('Attach a file')"
                        :off-label="translate('No')"
                        :on-label="translate('Yes')"
                        name="file-join"
                        cypress="file-join"
                    />
                </fieldset>

                <transition name="fade">
                    <div v-if="is_file_box_checked">
                        <fieldset>
                            <label>
                                <t>Upload file</t>
                                <span style="font-size:1rem; color:grey">(<t>Up to 20 MB</t>)</span></label>

                            <UploadBar
                                v-model="item.company_file"
                                :media="['office', 'pdf', 'image']"
                                cypress="company-file"
                                @onUploadProgress="buttonState['validate'] = 'loading'"
                                @onUploadEnd="buttonState['validate'] = 'idle'"
                            />

                            <input
                                v-validate.disable="'required'"
                                :value="item.company_file && item.company_file.original_name"
                                :data-vv-as="translate('file')"
                                type="hidden"
                                name="original_name"
                            >
                        </fieldset>
                    </div>
                </transition>

                <div
                    v-if="!isCancelled"
                    class="modal-actions right"
                >
                    <Button
                        v-if="canDelete"
                        :state="buttonState['delete']"
                        class="negative"
                        cypress="email-delete-button"
                        @click="onDelete()"
                    >
                        <t>Remove</t>
                    </Button>
                    <Button
                        v-if="canCreate"
                        cypress="email-add-button"
                        :state="buttonState['validate']"
                        @click="onValidate()"
                    >
                        <t>Add</t>
                    </Button>
                    <Button
                        v-if="canUpdate"
                        cypress="email-save-button"
                        :state="buttonState['validate']"
                        @click="onValidate()"
                    >
                        <t>Save</t>
                    </Button>
                </div>
            </form>

            <modalable
                ref="modalableSearchUser"
                class="modalable-1 small no-padding-bottom"
                :options="{ title: translate('Add recipient')}"
            >
                <SearchRolePanel
                    ref="searchRolePanel"
                    :has-roles="false"
                    :multiple-mode="true"
                    :is_assignable_onboardee="true"
                    :has-company-group="true"
                />
            </modalable>

            <modalable
                ref="modalableSearchUserSender"
                class="modalable-1 small no-padding-bottom"
                :options="{ title: translate('Add sender')}"
            >
                <SearchRolePanel
                    ref="searchRolePanelSender"
                    :has-roles="false"
                    :multiple-mode="false"
                    :is_assignable_onboardee="false"
                />
            </modalable>
        </template>
        <template v-if="hasError">
            <ErrorDisplay :append-errors="'This resource is not accessible'" />
        </template>
    </div>
</template>

<script>
import HtUserProgramList from '@/components/globals/HtUserProgramList.vue';
import CompanyUser from '@/models/CompanyUser';
import CompanyUserEmailCustom from '@/models/CompanyUserEmailCustom';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import CompanyUserEmailCustomCollection from '@/models/CompanyUserEmailCustomCollection';
import HtFormUserOrRoleSelect from '@/components/globals/HtFormUserOrRoleSelect.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import TaskDependencyMixin from '@/mixins/TaskDependencyMixin';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';

export default {
    name: 'UserEmailCustomEdit',
    components: {
        HtKeyDatesInputOrder,
        HtUserProgramList,
        HtFormUserOrRoleSelect,
        HtFormSwitch,
    },

    mixins: [
        TaskDependencyMixin,
    ],
    inject: ['modal'],

    props: {
        companyUserId: {
            type: [String, Number],
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
        id: {
            type: [String, Number],
            required: false,
        },
        from: {
            type: String,
            required: true,
        },
        intelligentSelection: {
            type: Array,
            default: () => [],
        },
        hasMissingVariables: {
            type: Boolean,
            default: false,
        },
        variables: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            userTask: null,
            hasError: false,
            // FOR LAZY LOADING
            itemFields: [
                'id',
                'company_user_id',
                'company_user_program_id',
                'name',
                'subject',
                'content',
                'signature',
                'value',
                'sender_company_user_id',
                'company_file_id',
                'is_sendable',
                'permissions',
            ],
            itemRelations: {
                companyUserEmailCustomRecipient: (query) => {
                    query.select(['id', 'company_user_email_custom_id', 'company_role_id', 'company_group_id', 'company_user_id']).with({
                        companyUser: (query) => {
                            query.select(['id', 'firstname', 'lastname', 'email', 'image', 'professional_email']);
                        },
                        companyGroup: (query) => {
                            query.select(['id', 'name', 'alias']);
                        },
                    });
                },
                companyUserSender: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'email', 'image', 'professional_email']);
                },
                companyFile: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
                companyUserProgramTask: (query) => query.select([
                    'id',
                    'company_user_program_id',
                    'company_user_program_key_date_id',
                    'offset_availability_number',
                    'offset_availability_unit',
                    'offset_end_number',
                    'offset_end_unit',
                    'is_business_day',
                    'datetime_availability',
                    'datetime_end',
                    'status',
                ]).with({
                    dependents: (query) => {
                        query.select(['id']);
                    },
                    dependencies: (query) => {
                        query.select(['id']);
                    },
                    companyUserProgram: (query) => query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => query.select(['id']).with({
                            keyDate: (query) => query.select(['id', 'company_program_id']).with({
                                locales: (query) => query
                                    .select(['id', 'name', 'language_key']),
                            }),
                        }),
                    }),
                }),
            },
            companyUserProgram: new CompanyUserProgram([
                'id',
            ]).with({
                companyProgram: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            keyDate: (query) => {
                                query.select(['id', 'company_program_id']).with({
                                    locales: (query) => query.select(['id', 'name', 'language_key']),
                                });
                            },
                        });
                },
                mainKeyDate: (query) => {
                    query.select('starts_at');
                },
            }).where([
                ['id', '=', this.companyUserProgramId],
            ]),
            companyUser: new CompanyUser(
                ['id', 'firstname', 'arrival_date'],
            ),
            is_file_box_checked: false,
            item: null,
            buttonState: {
                delete: 'idle',
                validate: 'idle',
            },
        };
    },

    computed: {
        labelTitle() {
            return this.translate(this.item.isNew() ? 'Add program e-mail' : 'Update e-mail');
        },

        minDate() {
            return 'today';
        },

        missingVariables() {
            const has = this.item.content.includes('{') && this.item.content.includes('}');
            this.$emit('update:hasMissingVariables', has);
            return has;
        },

        canCreate() {
            return this.item.isNew();
        },

        canUpdate() {
            if (this.item.isNew()) {
                return false;
            }
            return this.item.permissions.update;
        },

        canDelete() {
            if (this.item.isNew()) {
                return false;
            }
            return this.item.permissions.delete;
        },

        isCancelled() {
            return (this.item.company_user_program_task?.status === CompanyUserProgramTask.STATUS_CANCELLED);
        },
    },

    created() {
        if (this.id) {
            this.item = this.getModel();
            this.item.where([['id', '=', this.id]]);
            this.item.company_user_id = this.companyUserId;
            this.item.get().then(
                () => {
                    if (this.item.company_file) { this.is_file_box_checked = true; }

                    this.userTask = this.item.company_user_program_task;
                    this.modal.setTitle(this.labelTitle);
                },
                () => { this.hasError = true; },
            );
        } else {
            this.item = this.getModel(true);
            this.createTaskWithDependents(this.item);

            this.item.company_user_id = this.companyUserId;
            this.item.company_user_program_id = this.companyUserProgramId;
            this.userTask = this.item.company_user_program_task;

            this.modal.setTitle(this.labelTitle);
        }

        this.companyUser.id = this.companyUserId;
        this.companyUser.get();
        this.companyUserProgram.get();
    },

    methods: {
        getModel(fromCollection = false) {
            if (fromCollection) {
                return new CompanyUserEmailCustomCollection(this.itemFields).with(this.itemRelations).new();
            }
            return new CompanyUserEmailCustom(this.itemFields).with(this.itemRelations);
        },

        // SENDER / RECIPIENTS
        openAddRecipientsModal() {
            this.$refs.searchRolePanel.init({
                onValidate: this.addRecipients,
                selected: this.item.company_user_email_custom_recipient.models,
                intelligentSelection: this.intelligentSelection,
            });
            this.$refs.modalableSearchUser.open();
        },

        openAddSenderModal() {
            this.$refs.searchRolePanelSender.init({
                selected: this.item.company_user_sender ? [this.item.company_user_sender] : [],
                intelligentSelection: this.intelligentSelection,
                onSelect: (item) => {
                    this.item.sender_company_user_id = item.id;
                    this.item.company_user_sender = item;
                    this.$refs.modalableSearchUserSender.close();
                },
                onUnselect: () => {
                    this.onRemoveSender();
                    this.$refs.modalableSearchUserSender.close();
                },
            });
            this.$refs.modalableSearchUserSender.open();
        },

        addRecipients(participants) {
            this.item.company_user_email_custom_recipient = participants;
            this.$refs.modalableSearchUser.close();
        },

        onRemoveSender() {
            this.item.sender_company_user_id = null;
            this.item.company_user_sender = null;
        },

        async onValidate() {
            await this.$validator.validateAll();

            if (this.errors.any() || this.is_saving) { return; }

            this.buttonState.validate = 'loading';

            if (this.canCreate) {
                if (this.from === 'cop') { this.item.is_sendable = 1; }
            }

            this.item.save().then(() => {
                this.buttonState.validate = 'idle';
                this.$emit('onUpdate');
                this.modal.close();
            }).catch(() => {
                this.buttonState.validate = 'idle';
            });
        },

        async onDelete() {
            this.buttonState.delete = 'loading';
            if (await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this e-mail? Once you click on delete, you will no longer be able to access this e-mail.') })) {
                this.item.delete().then(() => {
                    this.buttonState.delete = 'idle';
                    this.$emit('onDelete');
                    this.modal.close();
                }).catch(() => {
                    this.buttonState.delete = 'idle';
                });
            } else {
                this.buttonState.delete = 'idle';
            }
        },
    },
};
</script>

<style lang="scss" scoped>

    label {
        font-weight: bold;
    }
</style>
