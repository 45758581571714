<template>
    <div style="position: relative">
        <div
            v-for="(page, pageKey) in files"
            :key="`page-${pageKey}`"
        >
            <div
                v-if="(pageKey === selectedPage - 1)"
                class="drop-zone"
                style="position:relative;font-size: 10px;"
                @drop="(event) => $emit('on-drop', event, pageKey)"
                @dragover.prevent
                @dragenter.prevent
                @dragstart.prevent
            >
                <DraggableItem
                    v-for="(field, key) in documentFields[pageKey]"
                    v-if="parentHeight && !startResize"
                    :key="`field-${key}`"
                    :field.sync="field"
                    :parent-height="parentHeight"
                    :start-resize="startResize"
                    :is-admin-component="isAdminComponent"
                    :field-validator="fieldValidator"
                    @delete="remove({pageKey, key})"
                    @mandatorize="mandatorize({pageKey, key})"
                    @setTitle="setTitle($event, {pageKey, key})"
                    @setRole="setRole($event, {pageKey, key})"
                    @setRule="setRule($event, {pageKey, key})"
                    @setSpecificRule="setSpecificRule($event, {pageKey, key})"
                />
                <img
                    :src="$Utils.resolveS3PublicImage(files[pageKey])"
                    width="100%"
                    @load="$emit('on-image-load')"
                >
            </div>
        </div>
    </div>
</template>

<script>
import DraggableItem from '@/components/DocumentEditor/DraggableItem.vue';
import FieldValidator from './FieldValidator';

export default {
    name: 'Dropzone',
    components: {
        DraggableItem,
    },
    props: {
        resize: {
            type: Function,
            required: true,
        },
        files: {
            type: Array,
            required: true,
        },
        documentFields: {
            type: Array,
            required: true,
        },
        parentHeight: {
            type: Number,
            required: true,
        },
        selectedPage: {
            type: Number,
            default() {
                return 1;
            },
        },
        startResize: {
            type: Boolean,
            default() {
                return true;
            },
        },
        isAdminComponent: {
            type: Boolean,
            default() {
                return false;
            },
        },
        fieldValidator: {
            type: FieldValidator,
            default: () => {},
        },
    },
    methods: {
        remove({ pageKey, key }) {
            this.$emit('delete', { pageKey, key });
        },
        mandatorize({ pageKey, key }) {
            this.$emit('mandatorize', { pageKey, key });
        },
        setSpecificRule(v, { pageKey, key }) {
            this.$emit('setSpecificRule', { v, pageKey, key });
        },
        setTitle(v, { pageKey, key }) {
            this.$emit('setTitle', { v, pageKey, key });
        },
        setRole(roleId, { pageKey, key }) {
            this.$emit('setRole', { roleId, pageKey, key });
        },
        setRule(ruleInfos, { pageKey, key }) {
            this.$emit('setRule', { ruleInfos, pageKey, key });
        },
    },
};
</script>

<style scoped>

</style>
