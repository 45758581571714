<template>
    <div>
        <slot />
    </div>
</template>
<script>
export default {
    name: 'HtStepperVertical',
    provide() {
        return {
            stepper: this,
        };
    },

    props: {
        value: {
            type: Number,
            default: undefined,
        },
        steps: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            activeIndex: this.value,
        };
    },

    watch: {
        value(val) {
            this.steps.forEach((step, index) => {
                if (Boolean(step.disabled) && index <= val) {
                    step.disabled = false;
                }
            });
            this.activeIndex = val;
        },
    },

    methods: {
        setActive(step) {
            const newStep = step.step;
            this.$emit('input', newStep);
        },
    },
};

</script>
