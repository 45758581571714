<template>
    <HtTag
        :color="status.color"
        size="small"
    >
        <strong><t>{{ status.label.toUpperCase() }}</t></strong>
    </HtTag>
</template>

<script>
import HtTag from '@/components/globals/HtTag.vue';

const statuses = [
    {
        slug: 'refused',
        label: 'refused',
        color: 'red',
    },
    {
        slug: 'done',
        label: 'done',
        color: 'green',
    },
    {
        slug: 'to_complete',
        label: 'To complete',
        color: 'grey-dark',
    },
    {
        slug: 'available_soon',
        label: 'Available soon',
        color: 'blue',
    },
    {
        slug: 'not_concerned',
        label: 'Not concern',
        color: 'grey',
    },
    {
        slug: 'display_survey',
        label: 'Via survey',
        color: 'grey',
    },
    {
        slug: 'pending',
        label: 'Pending',
        color: 'orange',
    },
    {
        slug: 'cancelled',
        label: 'Cancelled',
        color: 'grey',
    },
];
export default {
    name: 'StatusTag',
    components: { HtTag },
    props: {
        statusSlug: {
            type: String,
            required: true,
            validator(value) {
                return statuses.map((status) => status.slug).includes(value);
            },
        },
    },
    computed: {
        /**
         * @returns {{slug: string, label:string, color:string}}
         */
        status() {
            return statuses.find((status) => status.slug === this.statusSlug);
        },
    },
};
</script>
