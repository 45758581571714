import IBAN from 'iban';
import { Rules, Validator } from 'vee-validate';

import moment from 'moment';

export default {
    install(Vue) {
        Validator.extend('iban', {
            getMessage: (field) => Vue.prototype.$I18n.translate('The IBAN is invalid.', {
                fieldName: field,
            }),
            validate: (value) => IBAN.isValid(value),
        });

        Validator.extend('bic', {
            getMessage: (field) => Vue.prototype.$I18n.translate('The BIC format is invalid.', {
                fieldName: field,
            }),
            validate: (value) => {
                const regex = RegExp(/^[a-z]{6}[2-9a-z][0-9a-np-z]([a-z0-9]{3}|x{3})?$/i);
                return regex.test(value);
            },
        });
        Validator.extend('decimal', {
            ...Rules.decimal,
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} field must be numeric and may contain {number} decimal points.',
                { fieldName: field, number: args[0] },
            ),
        });
        Validator.extend('regex', {
            getMessage: (field) => Vue.prototype.$I18n.translate('The {fieldName} format is invalid.', {
                fieldName: field,
            }),
            paramNames: ['expression'],
            validate: (value, args) => Rules.regex.validate(value, args),
        });
        Validator.extend('required', {
            getMessage: (field) => Vue.prototype.$I18n.translate('The {fieldName} is required.', {
                fieldName: field,
            }),
            validate: (value, args) => Rules.required.validate(value, args),
        });
        Validator.extend('numeric', {
            getMessage: (field) => Vue.prototype.$I18n.translate(
                'The {fieldName} may only contain numeric characters.',
                {
                    fieldName: field,
                },
            ),
            validate: (value, args) => value == '' || Rules.numeric.validate(value, args),
        });
        Validator.extend('alpha', {
            getMessage: (field) => Vue.prototype.$I18n.translate(
                'The {fieldName} may only contain alphabetic characters.',
                {
                    fieldName: field,
                },
            ),
            validate: (value, args) => value == '' || Rules.alpha.validate(value, args),
        });
        Validator.extend('object', {
            getMessage: (field) => Vue.prototype.$I18n.translate('The {fieldName} is required.', {
                fieldName: field,
            }),
            validate: (value) => (
                Object.keys(value).length > 0
                && Object.values(value)[0] !== ''
                && Object.values(value)[0] !== null
            ),
        });
        Validator.extend('array', {
            getMessage: (field) => Vue.prototype.$I18n.translate('The {fieldName} is required.', {
                fieldName: field,
            }),
            validate: (value) => value != null && value.length > 0,
        });
        Validator.extend('date_format', {
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be in the format {format}.',
                { fieldName: field, format: args[0] },
            ),
            validate: (value, args) => {
                if (args[0] === 'yyyy-MM-dd') {
                    args[0] = 'YYYY-MM-DD'; // Fix an issue with default value provided by veeValidate for date inputs.
                }
                return value == '' || moment(value, args[0], true).isValid();
            },
        });
        Validator.extend('phone_number', {
            getMessage: (field) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be like this (+33|0)*********.',
                { fieldName: field },
            ),
            validate: (value) => {
                const regex_phone_number = /^((\+)33|0)[1-9](\d{2}){4}$/;
                return regex_phone_number.test(String(value));
            },
        });
        Validator.extend('email', {
            getMessage: (field) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be a valid email.',
                { fieldName: field },
            ),
            validate: (value) => {
                const regex_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return regex_email.test(String(value).toLowerCase());
                // return Rules.email.validate(value, args);
            },
        });
        Validator.extend('min', {
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be at least {number} characters.',
                { fieldName: field, number: args[0] },
            ),
            validate: (value, args) => Rules.min.validate(value, args),
        });
        Validator.extend('min_or_empty', {
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be at least {number} characters.',
                { fieldName: field, number: args[0] },
            ),
            validate: (value, args) => value === '' || Rules.min.validate(value, args),
        });
        Validator.extend('integer', {
            ...Rules.integer,
            getMessage: (field) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be an integer.',
                { fieldName: field },
            ),
        });
        Validator.extend('length', {
            ...Rules.length,
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} length must be {number}.',
                { fieldName: field, number: args[0] },
            ),
        });
        Validator.extend('max', {
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} may not be greater than {number} characters.',
                { fieldName: field, number: args[0] },
            ),
            validate: (value, args) => Rules.max.validate(value, args),
        });
        Validator.extend('max_or_empty', {
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} may not be greater than {number} characters.',
                { fieldName: field, number: args[0] },
            ),
            validate: (value, args) => value == '' || Rules.max.validate(value, args),
        });
        Validator.extend('max_without_html', {
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} may not be greater than {number} characters.',
                { fieldName: field, number: args[0] },
            ),
            validate: (value, args) => Rules.max.validate(value.replace(/<[^>]*>?/gm, ''), args),
        });
        Validator.extend('min_value', {
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be {number} or more.',
                { fieldName: field, number: args[0] },
            ),
            validate: (value, args) => Rules.min_value.validate(value, args),
        });
        Validator.extend('min_value_or_empty', {
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be {number} or more.',
                { fieldName: field, number: args[0] },
            ),
            validate: (value, args) => value == '' || Rules.min_value.validate(value, args),
        });
        Validator.extend('max_value', {
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be {number} or less.',
                { fieldName: field, number: args[0] },
            ),
            validate: (value, args) => Rules.max_value.validate(value, args),
        });
        Validator.extend('max_value_or_empty', {
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be {number} or less.',
                { fieldName: field, number: args[0] },
            ),
            validate: (value, args) => value == '' || Rules.max_value.validate(value, args),
        });
        Validator.extend('between', {
            getMessage: (field, args) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be between {min} and {max}.',
                { fieldName: field, min: args[0], max: args[1] },
            ),
            validate: (value, args) => Rules.between.validate(value, { min: args[0], max: args[1] }),
        });
        Validator.extend(
            'confirmed',
            {
                getMessage: (field, args) => Vue.prototype.$I18n.translate(
                    'The {fieldName} and the {confirmedName} do not match.',
                    { fieldName: field, confirmedName: args[0] },
                ),
                paramNames: ['targetValue'],
                validate: (value, args) => Rules.confirmed.validate(value, args),
            },
            { hasTarget: true },
        );

        // at least one option from a list of checkbox options should be checked
        Validator.extend(
            'oneChecked',
            {
                getMessage: () => Vue.prototype.$I18n.translate('At least one answer should be selected'),
                validate: (value) => {
                    // check if at least one value is selected
                    const atLeastOneChecked = value.some((option) => option.selected === true);
                    return atLeastOneChecked;
                },
            },
        );

        Validator.extend('unique_email', {
            getMessage: () => Vue.prototype.$I18n.translate('This e-mail is already in use, please check your drafts or use another e-mail.'),
            validate: (email, [userId]) => new Promise((resolve) => {
                const params = {};

                if (userId) {
                    params.exclude = userId;
                }

                Vue.prototype.$http.get(`rules/email-exists/${email}`, { params }).then((response) => {
                    resolve({ valid: !response.data.exists });
                });
            }),
        }, {
            immediate: false,
        });

        Validator.extend('unique_phone', {
            getMessage: () => Vue.prototype.$I18n.translate('This phone number is already taken.'),
            validate: (phone, [countryCode, userId]) => new Promise((resolve) => {
                const params = {};

                if (countryCode) {
                    params.country_code = countryCode;
                }

                if (userId) {
                    params.exclude = userId;
                }

                Vue.prototype.$http.get(`rules/phone-exists/${phone}`, { params }).then((response) => {
                    resolve({ valid: !response.data.exists });
                });
            }),
        });

        Validator.extend('timeslot_after', {
            getMessage: (field, args) => {
                const target = args[1];
                return Vue.prototype.$I18n.translate('The {field} must be after {target}', {
                    field,
                    target,
                });
            },
            validate: (value, args) => {
                if (!value.trim()) {
                    return true;
                }

                const [hour, minute] = args[0].split(':');
                const to = moment();
                to.hours(hour);
                to.minutes(minute);

                const [fromHour, fromMinute] = value.split(':');
                const from = moment();
                from.hours(fromHour);
                from.minutes(fromMinute);

                return to.isBefore(from);
            },
        });

        Validator.extend('translations', {
            getMessage: () => Vue.prototype.$I18n.translate('Some translations are missing.'),
            validate: (value, args) => {
                const [translations, availableLang] = args;
                const translationKeys = Object.keys(translations);
                const valid = availableLang.every((v) => translationKeys.includes(v) && translations[v] !== '');
                return {
                    valid,
                    data: {
                        required: true,
                    },
                };
            },
        }, {
            computesRequired: true,
        });
        Validator.extend('translation_default', {
            getMessage: () => Vue.prototype.$I18n.translate('Default translation is missing.'),
            validate: (value, args) => {
                const [translations, companyLanguageDefault] = args;
                const valid = translations?.[companyLanguageDefault];
                return {
                    valid, // or false
                    data: {
                        required: !valid,
                    },
                };
            },
        }, {
            computesRequired: true,
        });

        Validator.extend('file_translation_required', {
            getMessage: () => Vue.prototype.$I18n.translate('Some translations are missing.'),
            validate: (stringifiedFiles, args) => {
                const files = JSON.parse(stringifiedFiles);
                const availableLang = args[0];
                const translationKeys = Object.keys(files);
                return availableLang.every((v) => translationKeys.includes(v) && files[v]);
            },
        }, {
            computesRequired: true,
        });

        Validator.extend('alpha_dash_nullable', {
            getMessage: (field) => Vue.prototype.$I18n.translate('The {fieldName} may only contain alphabetic characters, dashes and spaces.', {
                fieldName: field,
            }),
            validate: (value) => {
                const regex = RegExp(/^[\p{L}\s\-]+$/u);
                return value == '' || regex.test(value);
            },
        });

        Validator.extend('alphanum_spaces', {
            getMessage: (field) => Vue.prototype.$I18n.translate('The {fieldName} may only contain alphabetic or numeric characters and spaces.', {
                fieldName: field,
            }),
            validate: (value) => {
                const regex = RegExp(/^[\p{L}0-9\s]+$/u);
                return value == '' || regex.test(value);
            },
        });

        Validator.extend('domain_check', {
            getMessage: () => Vue.prototype.$I18n.translate('The domain contains invalid characters or reserved words.'),
            validate: (value) => {
                const reservedWord = ['heyteam', 'api'];
                return /^[a-zA-Z0-9][a-zA-Z0-9_-]+[a-zA-Z0-9]$/.test(value) && reservedWord.filter((word) => value.indexOf(word) > -1).length === 0;
            },

        });
        Validator.extend('zip_code', {
            getMessage: (field) => Vue.prototype.$I18n.translate('The ZIP code format is invalid.', {
                fieldName: field,
            }),
            validate: (value) => {
                const regex = RegExp(/^\d{5}$/);
                return value == '' || regex.test(value);
            },
        });
        Validator.extend('num_secu', {
            getMessage: (field) => Vue.prototype.$I18n.translate('The Security social number is invalid.', {
                fieldName: field,
            }),
            validate: (value) => {
                const regex = RegExp(/^(1|2)\d{2}(0[1-9]|1[0-2]|[2-9][0-9])\d{10}$/);
                return value == '' || regex.test(value);
            },
        });
        Validator.extend('phone_number_nullable', {
            getMessage: (field) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be like this (+33|0)*********.',
                { fieldName: field },
            ),
            validate: (value) => {
                const regex_phone_number = /^((\+)33|0)[1-9](\d{2}){4}$/;
                return value == '' || regex_phone_number.test(String(value));
            },
        });
        Validator.extend('email_nullable', {
            getMessage: (field) => Vue.prototype.$I18n.translate(
                'The {fieldName} must be a valid email.',
                { fieldName: field },
            ),
            validate: (value) => {
                const regex_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return value == '' || regex_email.test(String(value).toLowerCase());
            },
        });
        Validator.extend('valid_link', {
            getMessage: (field) => Vue.prototype.$I18n.translate(
                'The {fieldName} is not a valid link (e.g: http(s)://...)',
                { fieldName: field },
            ),
            validate: (value) => {
                const linkRegex = /(https?:\/\/)([a-zA-Z0-9\-_]+(?:\.[a-zA-Z0-9\-_]+)*)(?::\d{1,5})?(?:\/[^\s]*)?$/;
                return linkRegex.test(String(value));
            },
        });
        Validator.extend('alpha_spaces_hyphen', {
            getMessage: (field) => Vue.prototype.$I18n.translate(
                'The {fieldName} may only contain alphabetic characters as well as spaces or hyphens',
                { fieldName: field },
            ),
            validate: (value) => {
                const regex = /^[a-zA-Z\s-]+$/;
                return regex.test(String(value));
            },
        });

        Validator.extend('cm_auto', {
            getMessage: (field) => Vue.prototype.$I18n.translate(
                'The {fieldName} field must be a number followed by \'cm\'',
                { fieldName: field },
            ),
            validate: (value) => /^-?\d*\.?\d+cm$/.test(value),
        });
    },
};
