<template>
    <HtFormGroup
        :label="label"
        :error="error"
        :is-required="isRequired"
        :class="{'ht-form-loading': !!loading}"
    >
        <masked-input
            v-model="getMaskedInput"
            :name="name"
            :type="type"
            :placeholder="placeholder"
            :mask="mask"
            :guide="guide"
            :show-mask="showMask"
            :placeholder-char="placeholderChar"
        />
    </HtFormGroup>
</template>

<script>
import HtFormMixin from './HtFormMixin';

export default {
    name: 'HtFormMaskedInput',
    mixins: [
        HtFormMixin,
    ],

    props: {
        value: {
            type: String,
            default: () => '',
        },
        type: {
            type: String,
            default: () => 'text',
        },
        // if we are making api call while user is inputting
        loading: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        mask: {
            type: Array,
            required: true,
        },
        guide: {
            type: Boolean,
            default: true,
        },

        showMask: {
            type: Boolean,
            default: false,
        },
        placeholderChar: {
            type: String,
            default: () => ' ',
        },
    },

    data() {
        return {
            maskedInput: this.value,
        };
    },

    computed: {
        getMaskedInput: {
            get() {
                return this.maskedInput;
            },
            set(val) {
                this.$emit('input', val);
                this.maskedInput = val;
            },
        },
    },
};
</script>

<style lang="scss" src="./HtFormInput.scss" scoped></style>
