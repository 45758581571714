<template>
    <div>
        <template v-if="companyUserTraining.isLoaded() && !hasError">
            <form
                class="form-2 spaced label-underline"
                data-cy="training-form"
            >
                <fieldset>
                    <div class="icon-wrapper">
                        <svg
                            v-if="companyUserTraining.type === 'link'"
                            class="image"
                        >
                            <use xlink:href="#icon-link" />
                        </svg>
                        <svg
                            v-else
                            class="image"
                        >
                            <use xlink:href="#icon-file" />
                        </svg>
                    </div>
                </fieldset>

                <fieldset v-if="companyUserTraining.type === 'link'">
                    <label>
                        <t>Url</t>
                        :</label>
                    <div class="item-content limit-width">
                        <a
                            :href="companyUserTraining.url"
                            target="_blank"
                            @click.prevent="onTrainingLinkClick(companyUserTraining.url)"
                        >{{ companyUserTraining.url }}</a>
                    </div>
                </fieldset>

                <UserTrainingVideo
                    v-if="isVideo"
                    class="mb-20 video"
                    :company_user_document_id="id"
                />

                <fieldset v-if="companyUserTraining.type === 'file' && companyUserTraining.company_file && isVideo === false">
                    <label>
                        <t>File</t>
                        :</label>
                    <div class="item-content">
                        <Downloadable :company-file="companyUserTraining.company_file" />
                    </div>
                </fieldset>

                <fieldset v-if="companyUserTraining.description">
                    <label>
                        <t :firstname="companyUserTraining.description">Description</t>
                        :</label>
                    <p v-dompurify-html="companyUserTraining.description" />
                </fieldset>

                <fieldset v-if="companyUserTraining.specific_information">
                    <label>
                        <t :firstname="companyUserTraining.company_user.firstname">Specific information for {firstname}</t>
                        :</label>
                    <p v-dompurify-html="companyUserTraining.specific_information" />
                </fieldset>

                <fieldset v-if="shouldShowAvailabilityDate">
                    <label>
                        <t>To complete by</t>
                        : </label>
                    <p>
                        {{
                            $Utils
                                .moment(companyUserTraining.company_user_program_task.datetime_end)
                                .format(
                                    (shared.session.companyUser.company_language.key === 'fr') ?
                                        'DD/MM/YYYY' : 'MM/DD/YYYY'
                                )
                        }}
                    </p>
                </fieldset>

                <fieldset>
                    <HtStatusLabel
                        :status="companyUserTraining.company_user_program_task.status"
                    />
                </fieldset>

                <div
                    v-if="!isCancelled"
                    class="modal-actions right"
                >
                    <Button
                        v-if="companyUserTraining.permissions.delete"
                        class="negative"
                        cypress="traing-delete-button"
                        @click="onDelete()"
                    >
                        <t>Remove</t>
                    </Button>
                    <Button
                        v-if="companyUserTraining.permissions.update"
                        cypress="training-edit-button"
                        @click="edit()"
                    >
                        <t>Edit</t>
                    </Button>
                    <Button
                        v-if="companyUserTraining.company_user_program_task.permissions.can_validate && canValidate"
                        :state="buttonState"
                        cypress="training-toggle-status-button"
                        @click="toggleStatus()"
                    >
                        <t>{{ isDone ? 'Mark as undone' : 'Mark as done' }}</t>
                    </Button>
                </div>
            </form>

            <Modalable
                ref="modal"
                class="modalable-1 medium"
                :mode="2"
            >
                <UserTrainingEdit
                    :id="id"
                    :company-user-id="companyUserId"
                    :company-user-program-id="companyUserTraining.company_user_program_id"
                    :should-show-availability-date="shouldShowAvailabilityDate"
                    @onUpdate="onUpdate"
                    @onDelete="onRemove"
                />
            </Modalable>
        </template>
        <template v-if="hasError">
            <ErrorDisplay :append-errors="'This resource is not accessible'" />
        </template>
    </div>
</template>

<script>
import CompanyUserTraining from '@/models/CompanyUserTraining';
import UserTrainingVideo from '@/components/resources/training/UserTrainingVideo.vue';
import HtStatusLabel from '@/pages/HtStatusLabel.vue';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import CompanyUserProgramTask from '../../../../../models/CompanyUserProgramTask';
import UserTrainingEdit from './UserTrainingEdit.vue';

export default {
    name: 'UserTrainingItem',
    components: {
        UserTrainingEdit,
        UserTrainingVideo,
        HtStatusLabel,
    },
    inject: ['modal'],

    props: {
        id: {
            type: Number,
            required: true,
        },
        companyUserId: {
            type: Number,
            required: true,
        },
        shouldShowAvailabilityDate: {
            type: Boolean,
            required: false,
            default: () => true,
        },
        canValidate: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            hasError: false,
            buttonState: 'idle',

            companyUserTraining: new CompanyUserTraining([
                'id',
                'company_user_id',
                'company_user_program_id',
                'name',
                'description',
                'type',
                'specific_information',
                'company_file_id',
                'url',
                'permissions',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'arrival_date', 'firstname']);
                },
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'arrival_date',
                        'status',
                        'permissions',
                        'company_user_id',
                        'resource',
                        'validator_type',
                    ]);
                },
                companyFile: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
            }).where([
                ['id', '=', this.id],
            ]),
        };
    },

    computed: {
        isVideo() {
            return this.companyUserTraining
                && this.companyUserTraining.company_file
                && this.companyUserTraining.company_file.detected_mime_type.startsWith('video');
        },

        /**
             * @returns {Boolean}
             */
        isDone() {
            return this.companyUserTraining.company_user_program_task.status === CompanyUserProgramTask.STATUS_DONE;
        },

        isCancelled() {
            return this.companyUserTraining.company_user_program_task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },
    },

    watch: {
        'companyUserTraining._state.isSaving': function (value) {
            this.buttonState = value ? 'loading' : 'idle';
        },
    },

    created() {
        this.companyUserTraining.company_user_id = this.companyUserId;
        this.companyUserTraining.get().then(
            (companyUserTraining) => {
                this.modal.setTitle(companyUserTraining.$.name);
            },
            () => this.hasError = true,
        );
    },

    methods: {
        edit() {
            this.$refs.modal.open();
        },

        async toggleStatus() {
            await this.$handleValidationTasks({
                id: this.companyUserTraining.company_user_program_task.id,
                status: this.isDone ? CompanyUserProgramTask.STATUS_PENDING : CompanyUserProgramTask.STATUS_DONE,
            });
            this.modal.close();
            this.$emit('onUpdate');
        },

        onDelete() {
            this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this training? Once you click on delete, you will no longer be able to access this training.') }).then((result) => {
                if (result) {
                    this.companyUserTraining.delete().then(() => {
                        this.onRemove();
                    });
                }
            });
        },

        onUpdate() {
            this.modal.close();
            this.$emit('onUpdate');
        },

        onRemove() {
            this.modal.close();
            this.$emit('onDelete');
        },

        onTrainingLinkClick(urlToParse) {
            const url = new URL(urlToParse);
            const stg = url.searchParams.get('stg');

            if (stg) {
                this.$http.get(`users/${this.companyUserTraining.company_user_id}/trainings/mos-redirect?stg=${stg}`).then((response) => {
                    window.open(response.data.url, '_blank');
                });
            } else {
                window.open(url, '_blank');
            }
        },
    },
};
</script>

<style lang="scss" src="./UserTrainingItem.scss" scoped />
