<template>
    <div
        v-show="hasTargetedParticipant"
        class="offset-md-3 col-md-6 target-number mb-20"
    >
        <FontAwesomeIcon
            v-if="isLoading"
            icon="spinner"
            spin
            class="spinner"
        />
        <template v-else-if="count !== null">
            <t
                v-if="isEnrollProgram"
                :count="count"
            >
                {count} planned survey | {count} planned surveys
            </t>
            <t
                v-else
                :count="count"
            >
                {count} people targeted | {count} people targeted
            </t>
        </template>
    </div>
</template>

<script>
import api from '@/store/api';

export default {
    name: 'SurveyFormTargetsCount',
    props: {
        companySurveyId: {
            type: Number,
            required: true,
        },
        resourceProgram: {
            type: Object,
            required: true,
        },
        isRetroactiveOnPastEnroll: {
            type: Boolean,
            required: true,
        },
        isEnrollProgram: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: false,
            count: null,
        };
    },

    computed: {
        hasTargetedParticipant() {
            return (
                this.isEnrollProgram
                && this.isRetroactiveOnPastEnroll === true
                && this.resourceProgram.participants.length > 0
                && !!this.resourceProgram.company_program_id
            ) || !this.isEnrollProgram;
        },
    },

    created() {
        this.loadTarget();
        this.$eventHub.$on('filter-change', this.loadTargetLater);
        this.$eventHub.$on('program-change', this.loadTargetLater);
    },

    beforeDestroy() {
        this.$eventHub.$off('filter-change', this.loadTargetLater);
        this.$eventHub.$off('program-change', this.loadTargetLater);
    },

    methods: {
        loadTarget() {
            if (this.hasTargetedParticipant) {
                this.isLoading = true;

                const body = {
                    company_program_id: this.resourceProgram.company_program_id,
                    filters: this.resourceProgram.filters,
                    participants: this.resourceProgram.participants,
                    include_already_registered_users: this.isRetroactiveOnPastEnroll,
                };

                api.configuration.surveys
                    .count(this.companySurveyId, body)
                    .then(({ data }) => {
                        this.count = data.data;
                        this.isLoading = false;
                    });
            }
        },

        loadTargetLater() {
            this.isLoading = true;

            setTimeout(() => {
                this.loadTarget();
            }, 200);
        },
    },
};
</script>

<style lang="scss" scoped src="./SurveyFormTargets.scss"></style>
