import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanySecurity from './CompanySecurity';

export default class CompanySecurityCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanySecurity,
        };
    }
}
