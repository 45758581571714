<template>
    <div>
        <div class="question-input">
            <span class="question-input-title">
                <t>Additional information</t>
            </span>

            <FontAwesomeLayers
                v-if="!isCompleted"
                class="question-input-button"
                @click="deleteContent"
            >
                <FontAwesomeIcon
                    size="2x"
                    icon="circle"
                    class="circle"
                />
                <FontAwesomeIcon
                    size="2x"
                    :icon="['far', 'trash-alt']"
                    transform="right-1 shrink-8"
                    class="trash-icon"
                />
            </FontAwesomeLayers>
        </div>

        <div class="mt-20">
            <component
                :is="component"
                v-model="extra"
                :current-language="currentLanguage"
                :question-index="questionIndex"
            />
        </div>
    </div>
</template>

<script>
import CompanyLanguage from '@/models/CompanyLanguage';
import ExtraText from './extra/ExtraText.vue';

export default {
    name: 'SurveyListExtraItem',
    inject: ['$validator'],

    props: {
        value: {
            type: Object,
            required: true,
        },
        currentLanguage: {
            type: CompanyLanguage,
            required: true,
        },
        questionIndex: {
            type: Number,
            required: true,
        },
        isCompleted: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        extra() {
            return this.value.extra;
        },
        component() {
            return ExtraText;
        },
    },

    methods: {
        deleteContent() {
            this.$emit('on-delete-content', this.questionIndex);
        },
    },
};
</script>

<style lang="scss" scoped src="./SurveyListContentItem.scss"></style>
