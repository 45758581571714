<template>
    <div
        v-show="visible"
        class="toast"
        :class="getBorderClass"
    >
        <div
            class="toast-left"
            :class="type"
        >
            <FontAwesomeIcon
                class="toast-left-icon"
                :icon="icon"
            />
        </div>
        <div class="toast-main">
            <div
                v-if="title"
                class="toast-main-title"
            >
                {{ title }}
            </div>
            <div
                class="toast-main-message"
                :class="{'message-alone': !title}"
            >
                {{ message }}
                <slot />
            </div>
        </div>
        <div
            v-show="closable"
            class="toast-right"
        >
            <FontAwesomeIcon
                class="toast-right-icon"
                :icon="['fal', 'times']"
                @click="close"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'HtToast',
    props: {
        title: {
            type: String,
            default: null,
        },
        message: {
            type: String,
            required: true,
        },
        closable: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'info',
        },
    },
    data() {
        return {
            visible: true,
        };
    },
    computed: {
        getBorderClass() {
            return `toast-border-${this.type}`;
        },
        icon() {
            switch (this.type) {
            case 'notification':
                return ['far', 'bell'];
            case 'success':
                return ['far', 'check-circle'];
            case 'error':
            case 'danger':
                return ['far', 'times-circle'];
            case 'warning':
                return ['far', 'exclamation-circle'];
            case 'locked':
                return ['fa', 'lock'];
            case 'info':
            default:
                return ['far', 'info-circle'];
            }
        },
    },
    methods: {
        close() {
            this.visible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.toast {
    position: relative;
    display: flex;
    gap: 15px;
    border-radius: 8px;
    background-color: #ffffff;

    &-left {
        display: flex;
        padding: 1.1rem;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;

        &-icon {
            align-self: center;
            width: 20px;
            height: 20px;
        }
    }

    &-main {
        flex-grow: 1;
        padding: 1rem 1rem 1rem 0;

        &-title {
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
        }

        &-message {
            font-size: 12px;
            line-height: 140%;
            margin: auto;
        }

        .message-alone {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-right {
        padding: 0.5rem 1rem 0 0;

        &-icon {
            cursor: pointer;
        }
    }

    &-border-success {
        border: 1px solid #25B771;
    }
    .success {
        background-color: #E7FFE1;
        color: #25B771;

    }

    &-border-info {
        border: 1px solid #0033FF;
    }
    .info {
        background-color: #E6F0FF;
        color: #105DCF;
    }

    &-border-warning {
        border: 1px solid #F4B615;
    }
    .warning {
        background-color: #FFF9DC;
        color: #F4B615;
    }

    &-border-danger, &-border-error {
        border: 1px solid #DC3D1B;
    }
    .danger, .error {
        background-color: #FFE5E3;
        color: #DC3D1B;
    }

    &-border-notification {
        border: 1px solid var(--branding-color);
    }
    .notification {
        background-color: #FFF;
        color: var(--branding-color);
    }

    &-border-locked {
        border: 1px solid #AEAEAE;
    }
    .locked {
        background-color: #F0F0F0;
        color: #AEAEAE;
    }
}
</style>
