<template>
    <ChoiceResultItem
        :label="answerLabel"
        :percent="percent"
        :is-selected="isSelected"
    />
</template>

<script>
import ChoiceResultItem from '../ChoiceResultItem.vue';
import choiceResultItemProps from '../choiceResultItemProps';

export default {
    name: 'ResultSatisfactionScaleNpsItem',
    components: { ChoiceResultItem },
    mixins: [
        choiceResultItemProps,
    ],
};
</script>
