<template>
    <div>
        <HtCard>
            <template #title>
                <HtCardTitleBar
                    :title="title"
                    :show-button="true"
                    :show-search="false"
                    :button-text="translate('Invite user')"
                    @onAdd="openInviteUserForm"
                />
            </template>
            <template #default>
                <UsersList
                    :roles-options="rolesOptions"
                    :departments-options="departmentsOptions"
                    :offices-options="officesOptions"
                    @on-user-clicked="onUserClicked"
                    @pagination-refreshed="amountOfUser = $event._pagination.total"
                />
            </template>
        </HtCard>
        <UserInfosModal
            ref="modal"
            :office-options="companyOfficeCollection.models"
            :department-options="companyDepartmentCollection.models"
            @onReload="refreshCollection"
        />
    </div>
</template>
<script>

import CompanyRoleCollection from '@/models/CompanyRoleCollection';
import CompanyDepartmentCollection from '@/models/CompanyDepartmentCollection';
import CompanyOfficeCollection from '@/models/CompanyOfficeCollection';
import Button from '@/components/globals/Button.vue';
import UsersList from '@/usersList/UsersList.vue';
import htTablePaginationEventBus from '@/eventBus/htTablePagination';
import HtCard from '@/components/globals/HtCard.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import UserInfosModal from '../../../components/pages/settings/users/UserInfosModal.vue';

export default {
    name: 'SettingsUserPage',
    components: {
        HtCardTitleBar,
        HtCard,
        Button,
        UserInfosModal,
        UsersList,
    },

    data() {
        return {
            amountOfUser: 0,
            companyDepartmentCollection: new CompanyDepartmentCollection([
                'id',
                'name',
                'is_heyteam',
            ]),
            companyOfficeCollection: new CompanyOfficeCollection([
                'id',
                'name',
            ]),
            companyRoleCollection: new CompanyRoleCollection([
                'id',
                'name',
                'alias',
                'is_heyteam',
            ]),
        };
    },

    computed: {
        title() {
            return this.translate('{count} user | {count} users', {
                count: this.amountOfUser,
            });
        },
        rolesOptions() {
            return this.companyRoleCollection.models.map((item) => ({
                id: item.id,
                name: item.alias_translated,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
        departmentsOptions() {
            return this.companyDepartmentCollection.models.map((item) => ({
                id: item.id,
                name: item.name_translated,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
        officesOptions() {
            return this.companyOfficeCollection.models.map((item) => ({
                id: item.id,
                name: item.name,
            })).sort((a, b) => a.name.localeCompare(b.name));
        },
    },
    created() {
        this.companyRoleCollection.get();
        this.companyDepartmentCollection.get();
        this.companyOfficeCollection.get();
    },

    methods: {
        /**
         * @param {CompanyUser} user
         * @returns {void}
         */
        onUserClicked(user) {
            this.$refs.modal.open(user.id);
        },
        refreshCollection() {
            htTablePaginationEventBus.$emit('refresh');
        },

        openInviteUserForm() {
            return this.$router.push({ name: 'InviteManuallyUser' });
        },
    },
};
</script>
