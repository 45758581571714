import Model from '@tony.caron/node-model-proxy/Model';
import CompanyResourceProgramParticipantCollection from './CompanyResourceProgramParticipantCollection';
import CompanyRole from './CompanyRole';
import CompanyUser from './CompanyUser';

export default class CompanyResourceProgramParticipant extends Model {
    static TYPE_EXECUTOR = 'executor';

    static TYPE_VALIDATOR = 'validator';

    static TYPE_SIGNER = 'signer';

    static TYPE_PREPARER = 'preparer';

    static TYPES = {
        executor: this.TYPE_EXECUTOR,
        validator: this.TYPE_VALIDATOR,
        signer: this.TYPE_SIGNER,
        preparer: this.TYPE_PREPARER,
    };

    modelConfig() {
        return {
            collection: CompanyResourceProgramParticipantCollection,
        };
    }

    modelRelations() {
        return {
            role: () => this.belongsTo(CompanyRole),
            companyRole: () => this.belongsTo(CompanyRole), // sinon tout le front plante
            user: () => this.belongsTo(CompanyUser),
        };
    }
}
