<template>
    <div>
        <div v-show="!is_animation_done">
            <div>
                <div class="animation">
                    <img
                        src="/static/images/hourglass.svg"
                        alt=""
                    >
                    <div class="animation-title">
                        <t>Please wait</t>...
                    </div>
                    <p><t>We are selecting the packages according to the onboardee's parameters (contract type, department, etc...)</t></p>
                    <div class="animation-bar">
                        <div
                            class="inside-bg branding-bg"
                            :style="'width: '+ animation_progress + 'em;'"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-show="is_animation_done">
            <ParametersBox :title="translate('Resources')" />
            <HtCard>
                <div>
                    <UserDocumentFlow
                        ref="docList"
                        :company-user-id="userId"
                        :company-user-program-id="userProgramId"
                        :status-on-create="'draft'"
                        :status-on-toggle="'draft'"
                        class="mb-4"
                    />

                    <UserSoftwareFlow
                        :company-user-id="userId"
                        :company-user-program-id="userProgramId"
                        :options="{
                            type: 'small',
                            perRow: 'auto',
                        }"
                        class="mb-4"
                    />

                    <UserEquipmentFlow
                        :company-user-id="userId"
                        :company-user-program-id="userProgramId"
                        class="mb-4"
                    />

                    <UserTrainingFlow
                        :is-edit="true"
                        :company-user-id="userId"
                        :company-user-program-id="userProgramId"
                        class="mb-4"
                    />

                    <UserRequirementFlow
                        class="mb-4"
                        :company-user-id="userId"
                        :company-user-program-id="userProgramId"
                        :force-update-requirement-flow="forceUpdateRequirementFlow"
                    />

                    <UserSurveyFlow
                        :company-user-id="userId"
                        :company-user-program-id="userProgramId"
                        :intelligent-selection="intelligentSelection"
                        :options="{ perRow: 2 }"
                        class="mb-4"
                        @on-update="onSurveyChange()"
                        @on-delete="onSurveyChange()"
                    />

                    <UserQuizFlow
                        :company-user-id="userId"
                        :company-user-program-id="userProgramId"
                        :intelligent-selection="intelligentSelection"
                        :options="{ perRow: 2 }"
                        class="mb-4"
                    />

                    <UserPlanningEventFlow
                        ref="planningEventList"
                        :company-user-id="userId"
                        :company-user-program-id="userProgramId"
                        :intelligent-selection="intelligentSelection"
                        :missing.sync="hasMissingEvents"
                        class="mb-4"
                        @bottomModalOpened="isBottomModalOpen = true"
                        @bottomModalClosed="isBottomModalOpen = false"
                    />

                    <UserEmailCustomFlow
                        ref="emailCustomList"
                        :company-user-id="userId"
                        :company-user-program-id="userProgramId"
                        :intelligent-selection="intelligentSelection"
                        :button-as-text="true"
                        :options="{ perRow: 2 }"
                        :missing.sync="hasMissingEmails"
                        class="mb-4"
                    />

                    <UserNudgeFlow
                        :company-user-id="userId"
                        :company-user-program-id="userProgramId"
                        :intelligent-selection="intelligentSelection"
                        :options="{ perRow: 2 }"
                        class="mb-4"
                    />

                    <div class="info-text">
                        <svg class="icon"><use xlink:href="#icon-info" /></svg>
                        <t>To facilitate the readability of the program, similar actions will be grouped together and displayed as one</t>
                    </div>
                </div>
            </HtCard>
            <EnrollingFooter v-if="!isBottomModalOpen">
                <Button
                    cypress="foc-previous-package"
                    class="inner-box branding-color branding-border-color branding-border-color-darken-hover"
                    @click="$emit('on-prev')"
                >
                    <t>Previous</t>
                </Button>
                <Button
                    cypress="foc-next-package"
                    :state="buttonState"
                    @click="onSave"
                >
                    <t>Next</t>
                </Button>
            </EnrollingFooter>
        </div>
    </div>
</template>

<script>
import UserDocumentFlow from '@/components/flow/resources/document/UserDocumentFlow.vue';
import UserTrainingFlow from '@/components/flow/resources/training/UserTrainingFlow.vue';
import UserEquipmentFlow from '@/components/flow/resources/equipment/UserEquipmentFlow.vue';
import UserSoftwareFlow from '@/components/flow/resources/software/UserSoftwareFlow.vue';
import UserEmailCustomFlow from '@/components/flow/resources/email/UserEmailCustomFlow.vue';
import UserRequirementFlow from '@/components/flow/resources/requirement/UserRequirementFlow.vue';
import UserPlanningEventFlow from '@/components/flow/resources/event/UserPlanningEventFlow.vue';
import UserSurveyFlow from '@/components/flow/resources/survey/UserSurveyFlow.vue';
import UserQuizFlow from '@/components/flow/resources/quiz/UserQuizFlow.vue';
import UserNudgeFlow from '@/components/flow/resources/nudge/UserNudgeFlow.vue';
import ParametersBox from '@/components/globals/ParametersBox.vue';
import EnrollingFooter from './EnrollingFooter.vue';

export default {
    name: 'EnrollingPackage',
    components: {
        UserDocumentFlow,
        UserSurveyFlow,
        UserTrainingFlow,
        UserEquipmentFlow,
        UserSoftwareFlow,
        UserEmailCustomFlow,
        UserRequirementFlow,
        UserPlanningEventFlow,
        UserNudgeFlow,
        UserQuizFlow,
        ParametersBox,
        EnrollingFooter,
    },

    props: {
        userId: {
            type: Number,
            required: true,
        },
        userProgramId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            hasMissingEvents: false,
            hasMissingEmails: false,
            intelligentSelection: null,
            isBottomModalOpen: false,
            is_animation_done: false,
            animation_progress: 0,
            buttonState: 'idle',
            forceUpdateRequirementFlow: 1,
        };
    },

    created() {
        this.animate();
        this.fetchIntelligentSelection();
    },

    methods: {
        animate() {
            const interval = setInterval(() => {
                if (this.animation_progress < 16) {
                    this.animation_progress += Math.random() * 2;
                } else {
                    setTimeout(() => {
                        this.is_animation_done = true;
                    }, 1000);
                    clearInterval(interval);
                }
            }, 100);
        },

        async fetchIntelligentSelection() {
            await this.$http.get(`foc/enrolling/package/${this.userProgramId}`).then((response) => {
                this.intelligentSelection = response.data.intelligent_selection;
            });
        },

        onSave() {
            if (this.hasMissingEvents) {
                this.$Notifier('App').showError(this.translate('You need to set the organizers of all events!'));
                this.$refs.planningEventList.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                this.$refs.planningEventList.openAccordion();
            }

            if (this.hasMissingEmails) {
                this.$Notifier('App').showError(this.translate('You need to set the sender of all emails!'));
                this.$refs.emailCustomList.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                this.$refs.emailCustomList.openAccordion();
            }

            if (!this.hasMissingEvents && !this.hasMissingEmails) {
                this.$emit('on-next');
            }
        },

        onSurveyChange() {
            this.forceUpdateRequirementFlow += 1;
        },
    },
};
</script>
<style lang="scss" scoped>
.animation {
    max-width: 430px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 1s;
    margin: 0 auto 20px;

    &-title {
        font-size: 2.2rem;
        font-weight: bold;
        margin: 40px 0 15px;
    }

    &-bar {
        border-radius: 10px;
        height: 0.4em;
        width: 16em;
        margin-top: 20px;
    }
}
</style>
