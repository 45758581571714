import { render, staticRenderFns } from "./DSIconSuccess.vue?vue&type=template&id=ef45243a&scoped=true&"
import script from "./DSIconSuccess.vue?vue&type=script&lang=js&"
export * from "./DSIconSuccess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef45243a",
  null
  
)

export default component.exports