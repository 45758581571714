import axios from 'axios';
import Notifier from '@/Notifier';

export default class WrikeApi {
    static client_id = 'BhGqaKLJ';

    static client_secret = 'nOiyenvEKV4iSxO6taRmzwFbHc7TbjbQlziBcUT9NyUhGaRjX1CkuHL4sRoUeE5i';

    static permanentToken = 'eyJ0dCI6InAiLCJhbGciOiJIUzI1NiIsInR2IjoiMSJ9.eyJkIjoie1wiYVwiOjM0Mzg3ODAsXCJpXCI6ODI4NDMxOSxcImNcIjo0NjMxMjM2LFwidVwiOjEyNjIyNDczLFwiclwiOlwiVVNcIixcInNcIjpbXCJXXCIsXCJGXCIsXCJJXCIsXCJVXCIsXCJLXCIsXCJDXCIsXCJEXCIsXCJNXCIsXCJBXCIsXCJMXCIsXCJQXCJdLFwielwiOltdLFwidFwiOjB9IiwiaWF0IjoxNjU2NjA2NTY5fQ.WwA01xjnFQbszh3y1Y30Gi2M1CHVXsZn9XJw06RY4Nc';

    static token_uri = 'https://login.wrike.com/oauth2/token';

    static auth_uri = 'https://login.wrike.com/oauth2/authorize/v4';

    static getConfig() {
        return {
            url: WrikeApi.auth_uri,
            client_id: WrikeApi.client_id,
            response_type: 'code',
            redirect_uri: 'https://qa.heyteam.com/openid',
        };
    }

    constructor(requestCode) {
        this.requestCode = requestCode;
    }

    initAxios(host, token) {
        this.api = axios.create({
            baseURL: `https://${host}/api/v4/`,
            timeout: 1000,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    getToken(usePermanentToken = false) {
        return new Promise((resolve, reject) => {
            if (usePermanentToken === true) {
                this.initAxios('www.wrike.com', WrikeApi.permanentToken);
                resolve();
            } else {
                axios.post(WrikeApi.token_uri, {
                    client_id: WrikeApi.client_id,
                    client_secret: WrikeApi.client_secret,
                    grant_type: 'authorization_code',
                    code: this.requestCode,
                    redirect_uri: 'https://qa.heyteam.com/openid',
                }).then((data) => {
                    this.refresh_token = data.refresh_token;

                    this.initAxios(data.host, data.access_token);

                    resolve(data);
                }).catch((data) => {
                    Notifier.getInstance('App').showError(data);
                    reject(data);
                });
            }
        });
    }

    getWorkflows() {
        return new Promise((resolve, reject) => {
            this.api.get('workflows').then(({ data }) => {
                resolve(data.data);
            }).catch((data) => {
                Notifier.getInstance('App').showError('Impossible de récupérer les workflows !');
                reject(data);
            });
        });
    }

    createTask(folderId, task) {
        return new Promise((resolve, reject) => {
            this.api.post(`/folders/${folderId}/tasks`, task).then(({ data }) => {
                Notifier.getInstance('App').showInfo('Ticket créé avec succès');
                resolve(data.data);
            }).catch((data) => {
                Notifier.getInstance('App').showError('Impossible de créer le ticket sur Wrike !');
                reject(data);
            });
        });
    }

    getMultipleFolders(folderIds) {
        const joinFolders = folderIds.join(',');

        return new Promise((resolve, reject) => {
            this.api.get(`/folders/${joinFolders}`).then(({ data }) => {
                resolve(data.data);
            }).catch((data) => {
                Notifier.getInstance('App').showError('Impossible de créer le custom field !');
                reject(data);
            });
        });
    }

    addTaskAttachment(taskId, attachment) {
        return new Promise((resolve, reject) => {
            this.api.post(`/tasks/${taskId}/attachments`, attachment, {
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            }).then(({ data }) => {
                Notifier.getInstance('App').showInfo('Pièce-jointe ajouté avec succès');
                resolve(data.data);
            }).catch((data) => {
                Notifier.getInstance('App').showError('Impossible d\'ajouter la pièce-jointe au ticket !');
                reject(data);
            });
        });
    }

    getFolders(folderId) {
        return new Promise((resolve, reject) => {
            this.api.get(`folders/${folderId}/folders`, {
                params: {
                    descendants: false,
                },
            }).then(({ data }) => {
                resolve(data.data);
            }).catch((data) => {
                Notifier.getInstance('App').showError('Impossible de récupérer les dossiers !');
                reject(data);
            });
        });
    }

    getSpaces() {
        return new Promise((resolve, reject) => {
            this.api.get('spaces').then(({ data }) => {
                resolve(data.data);
            }).catch((data) => {
                Notifier.getInstance('App').showError('Impossible de récupérer les espaces !');
                reject(data);
            });
        });
    }

    getUsers() {
        return new Promise((resolve, reject) => {
            this.api.get('/contacts').then(({ data }) => {
                resolve(data.data);
            }).catch((data) => {
                Notifier.getInstance('App').showError('Impossible de récupérer les utilisateurs wrike !');
                reject(data);
            });
        });
    }
}
