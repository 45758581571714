<template>
    <div>
        <div
            v-if="value.isNew() && !isLoaded"
            class="sentence-filter"
        >
            <t>Select a program to display associated filters</t>
        </div>
        <div v-else>
            <div
                v-for="entity in entityList"
                :key="entity.id"
            >
                <HtFormSelector
                    :id="`programResourceFilter${entity.slug}`"
                    v-model="selectedEntityValueList
                        .find((element) => element.slug === entity.slug)
                        .selected"
                    :options="availableEntityValueList(entity.slug)"
                    :placeholder="translate(entity.name)"
                    :name="`programResourceFilter${entity.slug}`"
                    :label="translate(entity.name)"
                    :disabled="disabled"
                    :show-optional="false"
                    :is-deletable="isDefaultProgram && isCustomEntity(entity)"
                    :cypress="`filter-${entity.slug}`"
                    @onSelect="onChange(...arguments, entity)"
                    @onRemove="onRemove(...arguments, entity)"
                    @onDeleteParent="onDeleteParent(entity)"
                >
                    <div slot="noOptions">
                        <t>All filters already selected</t>
                    </div>
                </HtFormSelector>
            </div>

            <Button
                v-if="isDefaultProgram && isLoaded"
                type="rounded"
                class="negative mb-5"
                @click="$refs.customEntitiesList.open(entityList.filter((entity) => isCustomEntity(entity)))"
            >
                <t>Add filter</t>
            </Button>
            <div
                v-if="!hideSummary"
                class="sentence-filter"
            >
                {{ translateSentenceFilter }}
            </div>

            <CustomEntitiesList
                ref="customEntitiesList"
                class="mt-3"
                :on-save="onAddNewFilters"
            />
        </div>
    </div>
</template>

<script>
// TODO: est-ce que ce composant est utilisé ?
import CompanyProgramResourceFilterCollection from '@/models/CompanyProgramResourceFilterCollection';
import CompanyEntity from '@/models/CompanyEntity';
import CompanyProgram from '@/models/CompanyProgram';
import CustomEntitiesList from '@/components/pages/resources/filters/CustomEntitiesList.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';

export default {
    name: 'ProgramResourceFilter',
    components: {
        HtFormSelector,
        CustomEntitiesList,
    },

    props: {
        value: { required: true },
        disabled: { type: Boolean, default: false },
        useToggle: { type: Boolean, default: false },
        hideSummary: { type: Boolean, default: false },
    },

    data() {
        return {
            item: null,
            isLoaded: false,
            resourceFilter: null,
            companyProgramId: null,
            isDefaultProgram: false,
            selectedEntityValueList: [],
            entityList: [],
            companyProgram: new CompanyProgram(),
        };
    },

    computed: {
        // TODO: REWORK computeds needed to construct translateSentenceFilter
        selectedOfficeList() {
            return this.getSelectedEntityValueList(CompanyEntity.SLUG_COMPANY_OFFICE);
        },
        selectedContractList() {
            return this.getSelectedEntityValueList(CompanyEntity.SLUG_COMPANY_CONTRACT);
        },
        selectedDepartmentList() {
            return this.getSelectedEntityValueList(CompanyEntity.SLUG_COMPANY_DEPARTMENT);
        },
        selectedJobPositionList() {
            return this.getSelectedEntityValueList(CompanyEntity.SLUG_COMPANY_JOB_POSITION);
        },
        translateSentenceFilter() {
            let sentence = this.translate('This resource will apply to all programs');

            if (this.entityList.length > 0) {
                if (this.selectedOfficeList.length > 0
                    && this.selectedDepartmentList.length > 0
                    && this.selectedContractList.length > 0
                    && this.selectedJobPositionList.length > 0) {
                    sentence = this.translate('This resource will apply to {job_position} in {contract} from {department} department, located in {office}', {
                        job_position: this.nameJobPositions,
                        contract: this.nameContracts,
                        department: this.nameDepartments,
                        office: this.nameOffices,
                    });
                }
                if (this.selectedOfficeList.length > 0
                    && this.selectedDepartmentList.length > 0
                    && this.selectedContractList.length > 0
                    && this.selectedJobPositionList.length === 0) {
                    sentence = this.translate('This resource will apply to people in {contract} working in the {department} department, located in {office}', {
                        contract: this.nameContracts,
                        department: this.nameDepartments,
                        office: this.nameOffices,
                    });
                }
                if (this.selectedOfficeList.length > 0
                    && this.selectedDepartmentList.length > 0
                    && this.selectedJobPositionList.length > 0
                    && this.selectedContractList.length === 0) {
                    sentence = this.translate('This resource will apply to {job_position} working in the {department} department, located in {office}', {
                        job_position: this.nameJobPositions,
                        department: this.nameDepartments,
                        office: this.nameOffices,
                    });
                }
                if (this.selectedOfficeList.length > 0
                    && this.selectedContractList.length > 0
                    && this.selectedJobPositionList.length > 0
                    && this.selectedDepartmentList.length === 0) {
                    sentence = this.translate('This resource will apply to {job_position} in {contract}, located in {office}', {
                        job_position: this.nameJobPositions,
                        contract: this.nameContracts,
                        office: this.nameOffices,
                    });
                }
                if (this.selectedDepartmentList.length > 0
                    && this.selectedContractList.length > 0
                    && this.selectedJobPositionList.length > 0
                    && this.selectedOfficeList.length === 0) {
                    sentence = this.translate('This resource will apply to {job_position} in {contract}, working in the {department} department', {
                        job_position: this.nameJobPositions,
                        department: this.nameDepartments,
                        contract: this.nameContracts,
                    });
                }
                if (this.selectedDepartmentList.length > 0
                    && this.selectedJobPositionList.length > 0
                    && this.selectedContractList.length === 0
                    && this.selectedOfficeList.length === 0) {
                    sentence = this.translate('This resource will apply to {job_position} working in the {department} department', {
                        job_position: this.nameJobPositions,
                        department: this.nameDepartments,
                    });
                }
                if (this.selectedDepartmentList.length > 0
                    && this.selectedContractList.length > 0
                    && this.selectedJobPositionList.length === 0
                    && this.selectedOfficeList.length === 0) {
                    sentence = this.translate('This resource will apply to people in {contract} working in the {department} department', {
                        contract: this.nameContracts,
                        department: this.nameDepartments,
                    });
                }
                if (this.selectedDepartmentList.length > 0
                    && this.selectedOfficeList.length > 0
                    && this.selectedJobPositionList.length === 0
                    && this.selectedContractList.length === 0) {
                    sentence = this.translate('This resource will apply to the {department} department located in {office}', {
                        office: this.nameOffices,
                        department: this.nameDepartments,
                    });
                }
                if (this.selectedOfficeList.length > 0
                    && this.selectedJobPositionList.length > 0
                    && this.selectedDepartmentList.length === 0
                    && this.selectedContractList.length === 0) {
                    sentence = this.translate('This resource will apply to {job_position} working in {office}', {
                        office: this.nameOffices,
                        job_position: this.nameJobPositions,
                    });
                }
                if (this.selectedOfficeList.length > 0
                    && this.selectedContractList.length > 0
                    && this.selectedDepartmentList.length === 0
                    && this.selectedJobPositionList.length === 0) {
                    sentence = this.translate('This resource will apply to people in {contract} working in {office}', {
                        office: this.nameOffices,
                        contract: this.nameContracts,
                    });
                }
                if (this.selectedJobPositionList.length > 0
                    && this.selectedContractList.length > 0
                    && this.selectedOfficeList.length === 0
                    && this.selectedDepartmentList.length === 0) {
                    sentence = this.translate('This resource will apply to {job_position} in {contract}', {
                        job_position: this.nameJobPositions,
                        contract: this.nameContracts,
                    });
                }
                if (this.selectedJobPositionList.length > 0
                    && this.selectedContractList.length === 0
                    && this.selectedOfficeList.length === 0
                    && this.selectedDepartmentList.length === 0) {
                    sentence = this.translate('This resource will apply to the job position : {job_position}', {
                        job_position: this.nameJobPositions,
                    });
                }
                if (this.selectedContractList.length > 0
                    && this.selectedDepartmentList.length === 0
                    && this.selectedOfficeList.length === 0
                    && this.selectedJobPositionList.length === 0) {
                    sentence = this.translate('This resource will apply to the contract : {contract}', {
                        contract: this.nameContracts,
                    });
                }
                if (this.selectedOfficeList.length > 0
                    && this.selectedDepartmentList.length === 0
                    && this.selectedContractList.length === 0
                    && this.selectedJobPositionList.length === 0) {
                    sentence = this.translate('This resource will apply to the site : {office}', {
                        office: this.nameOffices,
                    });
                }
                if (this.selectedDepartmentList.length > 0
                    && this.selectedOfficeList.length === 0
                    && this.selectedContractList.length === 0
                    && this.selectedJobPositionList.length === 0) {
                    sentence = this.translate('This resource will apply to the department : {department}', {
                        department: this.nameDepartments,
                    });
                }
                if (this.customEntityEntries.length > 0
                    && this.selectedDepartmentList.length === 0
                    && this.selectedOfficeList.length === 0
                    && this.selectedContractList.length === 0
                    && this.selectedJobPositionList.length === 0) {
                    sentence = this.translate('This resource will apply to the following custom filters : {customEntityEntries}', {
                        customEntityEntries: this.customEntityEntries,
                    });
                } else if (this.customEntityEntries.length > 0
                    && (this.selectedDepartmentList.length > 0
                        || this.selectedOfficeList.length > 0
                        || this.selectedContractList.length > 0
                        || this.selectedJobPositionList.length > 0)) {
                    sentence += this.translate(', with the following additional filters : {customEntityEntries}', {
                        customEntityEntries: this.customEntityEntries,
                    });
                }

                return sentence;
            }
        },
        nameContracts() {
            const nameContracts = this.selectedContractList.map((item) => item.name);
            return nameContracts.join(', ');
        },
        nameDepartments() {
            const nameDepartments = this.selectedDepartmentList.map((item) => item.name);
            return nameDepartments.join(', ');
        },
        nameJobPositions() {
            const nameJobPositions = this.selectedJobPositionList.map((item) => item.name);
            return nameJobPositions.join(', ');
        },
        nameOffices() {
            const nameOffices = this.selectedOfficeList.map((item) => item.name);
            return nameOffices.join(', ');
        },

        customEntityEntries() {
            let customEntityEntries = [];
            this.selectedEntityValueList.filter((filteredEntity) => this.isCustomEntity(this.entityList.find((entity) => filteredEntity.slug === entity.slug))).forEach((customEntity) => {
                customEntityEntries = [].concat(customEntityEntries, customEntity.selected.map((filter) => filter.name));
            });

            return customEntityEntries.join(', ');
        },

        availableEntityValueList() {
            return (entitySlug) => {
                const entity = this.entityList.filter((entity) => entity.slug === entitySlug)[0];
                return [...entity.options].sort((a, b) => a.name > b.name);
            };
        },
    },

    watch: {
        translateSentenceFilter: {
            immediate: false,
            handler() {
                this.$emit('newTranslatedFilterSentence', this.translateSentenceFilter);
            },
        },
    },

    async created() {
        if (this.value.resource_program) {
            if (this.value.resource_program && this.value.resource_program.models[0].company_program_id) {
                this.loadResourceFilterValues(this.value.resource_program.models[0].company_program_id);
            }
            this.$watch(() => this.value.resource_program.models[0].company_program_id, this.loadResourceFilterValues);
        } else {
            if (this.value.company_program_id) {
                this.loadResourceFilterValues(this.value.company_program_id);
            }
            this.$watch(() => this.value.company_program_id, this.loadResourceFilterValues);
        }
    },

    methods: {
        onChange(option, id, entity) {
            this.resourceFilter.add({
                company_entity_id: entity.id,
                company_entity_value_id: option.id,
            });

            this.resourceFilter.models.sort((a, b) => a.order - b.order);
            this.$eventHub.$emit('filter-change');
        },

        // TODO revoir la sélection des filtres et homogénéiser avec le onChange
        setFromDefaultFilters(filter) {
            this.resourceFilter.add({
                company_entity_id: filter.company_entity_id,
                company_entity_value_id: filter.company_entity_value_id,
            });

            this.resourceFilter.models.sort((a, b) => a.order - b.order);
        },

        onRemove(option) {
            const toRemove = this.resourceFilter.models.find((item) => item.company_entity_value_id === option.id);

            this.resourceFilter.remove(toRemove);
            this.$eventHub.$emit('filter-change');
        },

        onAddNewFilters(selectedFilters) {
            selectedFilters.forEach((selectedEntity) => {
                if (!this.entityList.find((entity) => entity.id === selectedEntity.id)) {
                    this.entityList.push({
                        ...selectedEntity,
                    });
                }
            });

            const diff = this.entityList.filter((existingEntity) => !selectedFilters.some((selectedEntity) => existingEntity.id === selectedEntity.id));

            diff.forEach((entity) => {
                if (entity !== undefined) {
                    if (this.isCustomEntity(entity)) {
                        this.onDeleteParent(entity);
                    }
                }
            });

            this.formatEntityList(this.entityList);
        },

        onDeleteParent(entity) {
            this.entityList = this.entityList.filter((filteredEntity) => entity.id !== filteredEntity.id);
            this.selectedEntityValueList = this.selectedEntityValueList.filter((filteredEntity) => entity.slug !== filteredEntity.slug);
            const filtersToRemove = this.resourceFilter.models.filter((filter) => filter.company_entity_id === entity.id);

            filtersToRemove.forEach((filter) => {
                this.resourceFilter.remove(filter);
            });

            this.$eventHub.$emit('filter-change');
        },

        isCustomEntity(entity) {
            return entity.is_custom;
        },

        getSelectedEntityValueList(entitySlug) {
            return this.selectedEntityValueList.find((entity) => entity.slug === entitySlug) ? this.selectedEntityValueList.find((entity) => entity.slug === entitySlug).selected : [];
        },

        formatEntityList(entityList) {
            const entityDefaultList = entityList.filter((entity) => !this.isCustomEntity(entity));
            const entityCustomList = entityList.filter((entity) => this.isCustomEntity(entity));

            entityCustomList.forEach((entity) => {
                this.selectedEntityValueList.push({
                    slug: entity.slug,
                    selected: [],
                });
            });

            this.entityList = [...entityDefaultList, ...entityCustomList.sort((a, b) => a.company_entity_id > b.company_entity_id)].map((entity) => {
                const valuesArray = this.isDefaultProgram ? entity.resources : entity.values.models;
                const optionList = valuesArray.map((value) => ({
                    id: value.id,
                    name: (value[this.getRelationNameFromEntityWithDefault(entity)].is_heyteam)
                        ? this.translate(value[this.getRelationNameFromEntityWithDefault(entity)].name) : value[this.getRelationNameFromEntityWithDefault(entity)].name,
                })).sort((a, b) => a.name.localeCompare(b.name));

                return {
                    ...entity,
                    options: optionList,
                };
            });
        },

        loadResourceFilterValues(programId) {
            const empty = this.value.company_program_resource_filter == null;

            const promises = []; // we want to dynamically add an async function in case filter tags are not loaded beforehand

            this.selectedEntityValueList = [
                {
                    slug: CompanyEntity.SLUG_COMPANY_OFFICE,
                    selected: [],
                },
                {
                    slug: CompanyEntity.SLUG_COMPANY_CONTRACT,
                    selected: [],
                },
                {
                    slug: CompanyEntity.SLUG_COMPANY_DEPARTMENT,
                    selected: [],
                },
                {
                    slug: CompanyEntity.SLUG_COMPANY_JOB_POSITION,
                    selected: [],
                },
            ];

            if (!(this.value.company_program_resource_filter instanceof CompanyProgramResourceFilterCollection)) {
                // Inject relation à la volée
                this.value.withFilled({
                    companyProgramResourceFilter: (query) => {
                        query.select([
                            'id',
                            'company_entity_id',
                            'company_entity_value_id',
                            'component_name',
                            'resource_id',
                            'group',
                            'name',
                        ]);
                        query.with({
                            value: (query) => {
                                query.select([
                                    'id',
                                    'company_entity_id',
                                    'resourceable_type',
                                    'resourceable_id',
                                ]).with({
                                    jobPosition: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    contract: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    office: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    department: (query) => {
                                        query.select([
                                            'id',
                                            'name',
                                            'is_heyteam',
                                        ]);
                                    },
                                    custom: (query) => {
                                        query.select([
                                            'id',
                                            'key',
                                            'name',
                                        ]);
                                    },
                                });
                            },
                            entity: (query) => {
                                query.select([
                                    'id',
                                    'name',
                                    'slug',
                                    'is_enabled',
                                    'is_heyteam',
                                ]);
                            },
                        });
                    },
                });
                this.resourceFilter = this.value.company_program_resource_filter;

                // fixme: THIS IS A PATCH FOR LEGACY MODELS
                if (empty) {
                    promises.push(this.value.company_program_resource_filter.get());
                }
            } else {
                this.resourceFilter = this.value.company_program_resource_filter;
                promises.push(this.value.company_program_resource_filter.get());

                this.companyProgram = new CompanyProgram()
                    .with({
                        entities: (query) => query.select(['id', 'company_entity_id'])
                            .with({
                                entity: (query) => {
                                    query.select(['id', 'name', 'slug', 'is_heyteam']).with({
                                        values: (query) => {
                                            query.select([
                                                'id',
                                                'company_entity_id',
                                                'resourceable_type',
                                                'resourceable_id',
                                            ]).with({
                                                jobPosition: (query) => {
                                                    query.select([
                                                        'id',
                                                        'name',
                                                        'is_heyteam',
                                                    ]);
                                                },
                                                contract: (query) => {
                                                    query.select([
                                                        'id',
                                                        'name',
                                                        'is_heyteam',
                                                    ]);
                                                },
                                                office: (query) => {
                                                    query.select([
                                                        'id',
                                                        'name',
                                                        'is_heyteam',
                                                    ]);
                                                },
                                                department: (query) => {
                                                    query.select([
                                                        'id',
                                                        'name',
                                                        'is_heyteam',
                                                    ]);
                                                },
                                                custom: (query) => {
                                                    query.select([
                                                        'id',
                                                        'key',
                                                        'name',
                                                    ]);
                                                },
                                            });
                                        },
                                    });
                                },
                            }),
                        htProgramType: (query) => {
                            query.select([
                                'id',
                                'slug',
                            ]);
                        },
                    });

                this.companyProgram.where(['id', '=', programId]).get().then(() => {
                    if (this.companyProgram.ht_program_type && this.companyProgram.ht_program_type.slug === 'default') {
                        this.isDefaultProgram = true;

                        promises.push(this.$http.get('configuration/entities'));
                    } else {
                        this.isDefaultProgram = false;
                    }

                    Promise.all(promises).then((values) => {
                        let entityList = [];
                        if (this.isDefaultProgram) {
                            const defaultEntityList = values[1].data.resources.filter((entity) => entity.is_heyteam);

                            this.resourceFilter.models.forEach((resourceFilter) => {
                                if (!entityList.find((entity) => entity.id === resourceFilter.entity.id)
                                    && resourceFilter.value.custom) {
                                    entityList.push({
                                        ...resourceFilter.entity,
                                    });
                                }
                            });

                            const customAddedEntityList = entityList.map((entity) => {
                                const resourceFiltersByEntity = this.resourceFilter.models.filter((resourceFilter) => entity.id === resourceFilter.entity.id);
                                const values = [];

                                resourceFiltersByEntity.forEach((resourceFiltersByEntity) => {
                                    values.push({
                                        ...resourceFiltersByEntity.value,
                                        resourceable: resourceFiltersByEntity.value.custom,
                                    });
                                });

                                return {
                                    ...entity,
                                    resources: values,
                                };
                            });

                            entityList = [...defaultEntityList, ...customAddedEntityList];
                        } else {
                            entityList = this.companyProgram.entities.models.map((programEntity) => ({
                                ...programEntity.entity,
                            }));
                        }
                        this.formatEntityList(entityList);

                        this.resourceFilter.models.sort((a, b) => a.order - b.order);

                        this.resourceFilter.models.map((item) => {
                            const relationName = this.getRelationNameFromEntity(item.entity);

                            if (item.value[relationName] && item.value[relationName].id) {
                                this.selectedEntityValueList.filter((entity) => item.entity.slug === entity.slug)[0].selected.push({
                                    id: item.value.id,
                                    name: item.name_translated,
                                });
                            }
                        });

                        this.isLoaded = true;
                        this.$emit('onLoad');
                    });
                });
            }
        },

        getRelationNameFromEntityWithDefault(entity) {
            if (this.isDefaultProgram) {
                return 'resourceable';
            }

            return this.getRelationNameFromEntity(entity);
        },

        getRelationNameFromEntity(entity) {
            return this.isCustomEntity(entity) ? 'custom' : entity.slug.replace('company_', '');
        },
    },
};
</script>
