<template>
    <IndicatorTemplate
        :icon="icon"
        :label="label"
        :description="description"
        :loading="loading"
        @click="goToPrograms"
    />
</template>

<script>
import IndicatorTemplate from '@/components/pages/dashboard/widgets/templates/IndicatorTemplate.vue';

export default {
    name: 'CompletionRatePercentageWidget',
    components: {
        IndicatorTemplate,
    },
    computed: {
        description() {
            const percentage = this.$store.getters['dashboard/statistics/completionRatePercentage'];

            return `${percentage.toFixed(2)}%`;
        },
        label() {
            return this.translate('Average completion rate');
        },
        icon() {
            return 'check';
        },
        loading() {
            return !this.$store.state.dashboard.statistics.completionRatePercentageLoaded;
        },
    },
    watch: {
        loading: {
            handler(val) {
                if (val) {
                    this.$store.dispatch('dashboard/statistics/fetchCompletionRatePercentage');
                }
            },
            immediate: true,
        },
    },
    methods: {
        goToPrograms() {
            this.$store.dispatch('dashboard/programs/clearAllFilters');
            this.$store.dispatch('dashboard/transferFiltersToPrograms');
            this.$store.dispatch('dashboard/programs/setSorting', { column: 'user', direction: 'asc' });
            this.$router.push({ name: 'DashboardPrograms' });
        },
    },
};
</script>
