<template>
    <div class="otp-container">
        <div
            class="otp-input-container"
            :style="{ '--input-focus-border-color': branding }"
        >
            <input
                v-for="(digit, index) in otp"
                :key="index"
                class="otp-input"
                type="text"
                maxlength="1"
                :value="digit"
                @input="onInput($event, index)"
                @paste.prevent="onPaste"
                @keydown="onKeyDown($event, index)"
                @keypress="isNumber($event)"
            >
        </div>
        <HtFormInput
            id="otp-code"
            v-validate.disable="{
                required: true,
                min:6
            }"
            :value="getOtp"
            type="hidden"
            name="otp-code"
            :data-vv-as="translate('code')"
        />
    </div>
</template>

<script>
export default {
    inject: ['$validator'],

    props: {
        branding: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            otp: Array(6).fill(''),
        };
    },
    computed: {
        getOtp() {
            const code = this.otp.join('');
            if (code) {
                return parseInt(code, 10);
            }
            return null;
        },

        inputStyles() {
            return {
                border: `1px solid ${this.branding}`,
            };
        },
    },
    methods: {
        isNumber(event) {
            // Empêche la saisie de tout ce qui n'est pas un chiffre
            const char = String.fromCharCode(event.keyCode);
            if (!/[0-9]/.test(char)) {
                event.preventDefault();
            }
        },
        onInput(event, index) {
            const { value } = event.target;
            this.$set(this.otp, index, value);
            if (value && index < this.otp.length - 1) {
                event.target.nextElementSibling.focus();
            }
            this.checkCompleteOTP();
        },
        checkCompleteOTP() {
            if (this.otp.every((digit) => digit.trim() !== '') && this.otp.join('').length === 6) {
                this.$emit('otp-complete', parseInt(this.otp.join(''), 10));
            }
        },
        onPaste(event) {
            const paste = (event.clipboardData || window.clipboardData).getData('text');
            const characters = paste.slice(0, this.otp.length).split('');

            this.otp.splice(0, this.otp.length, ...characters); // replace all elements in otp with characters

            // Focus the last input if all characters were pasted, otherwise focus the next empty input
            this.$nextTick(() => {
                const inputs = this.$el.querySelectorAll('.otp-input');
                const emptyInputIndex = this.otp.findIndex((value, index) => !value && index > characters.length - 1);
                const inputToFocus = emptyInputIndex !== -1 ? inputs[emptyInputIndex] : inputs[this.otp.length - 1];
                if (inputToFocus) {
                    inputToFocus.focus();
                }
            });
        },
        onKeyDown(event, index) {
            if (event.key === 'Backspace' && !this.otp[index]) {
                const prevInput = event.target.previousElementSibling;
                if (prevInput) {
                    prevInput.focus();
                    this.$set(this.otp, index - 1, '');
                }
            } else if (event.key === 'ArrowRight' && index < this.otp.length - 1) {
                event.preventDefault();
                const nextInput = event.target.nextElementSibling;
                if (nextInput) nextInput.focus();
            } else if (event.key === 'ArrowLeft' && index > 0) {
                event.preventDefault();
                const prevInput = event.target.previousElementSibling;
                if (prevInput) prevInput.focus();
            }
        },
    },
};
</script>

<style lang="scss" scoped>

.otp-container {
    .ht-form {
        display: flex;
        justify-content: center;
        align-self: center;
    }
}
.otp-input-container {
    display: flex;
    justify-content: center;

    .otp-input {
        text-align: center;
        margin: 0 2px;
        font-size: 18px;
        width: 36px;
        height: 48px;
        border: 1px solid #ddd;
        border-radius: 4px;
        transition: border 0.1s ease-in-out;

        &:focus,
        &:focus-visible {
            outline: none;
            border: 1px solid var(--input-focus-border-color);
        }
    }

}
</style>
