<template>
    <div class="ht-collapse" :class="{'blank': blank}">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'HtCollapse',

    props: {
        blank: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.ht-collapse:not(.blank) {
    background-color: $neutral-white;
    border: 1px solid $neutral-500;
    border-radius: 8px;
}
</style>
