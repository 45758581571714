<template>
    <div class="lever">
        <div class="lever__image">
            <img :src="leverConfig.image">
        </div>
        <div class="lever__description">
            <t>
                By activating this connector, you will be able, once you validated a new hire on Lever,
                to create automatically an onboarding draft on the HeyTeam platform.
            </t>
        </div>
    </div>
</template>

<script>
import OAuth from '@/OAuth';

export default {
    name: 'LeverEdit',
    props: {
        value: {
            type: Object,
            required: true,
        },
    },

    computed: {
        integration() {
            return this.value;
        },
        leverConfig() {
            return this.$store.getters['config/getLeverConfig'];
        },
    },

    methods: {
        async onValidate() {
            OAuth.auth({
                scope: this.leverConfig.scope,
                url: this.leverConfig.auth_url,
                client_id: this.leverConfig.client_id,
                redirect_uri: this.leverConfig.redirect_uri,
                response_type: this.leverConfig.response_type,
                state: this.leverConfig.state,
                audience: this.leverConfig.audience,
                prompt: this.leverConfig.prompt,
            }, {
                200: ({ code }) => {
                    this.integration.config.code = code;
                    this.integration.is_enabled = true;

                    this.$emit('on-save');
                },
                403: () => {
                    this.$Notifier('App').showError(this.translate('Sorry we are unable to connect to this api'));
                },
            });
        },
        async onCancel() {
            this.integration.is_enabled = false;

            this.$emit('on-save');
        },
    },
};
</script>

<style lang="scss" scoped>
.lever__image {
    text-align: center;
    margin-bottom: 20px;

    > img {
        width: 100px;
    }
}
</style>
