<template>
    <HtTable
        :items="items"
        :columns="columns"
        cypress="action-resource-table"
        :empty-message="'There are no email to display yet...'"
        :external-search="externalSearch"
        @onCellClicked="$emit('onCellClicked', $event)"
    >
        <template #cell(participants)="scope">
            <div class="task-participants">
                <ParticipantList
                    :users="getParticipants(scope.item)"
                    has-roles
                    has-group
                />
            </div>
        </template>

        <template #cell(program_name)="scope">
            {{ getProgramName(scope.item) }}
        </template>
    </HtTable>
</template>
<script>
import HtTable from '@/components/globals/HtTable.vue';
import ParticipantList from '@/components/globals/ParticipantList.vue';
import ResourceListMixin from '@/mixins/ResourceListMixin';
import CompanyEmailCustomTemplate from '@/models/CompanyEmailCustomTemplate';

export default {
    name: 'EmailsList',
    components: { HtTable, ParticipantList },
    mixins: [
        ResourceListMixin,
    ],
    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'resource.name',
                    class: 'font-weight-bold',
                    sortDirection: 'asc',
                    search: true,
                },
                {
                    label: this.translate('Participants'),
                    key: 'participants',
                    class: 'd-none d-md-table-cell',
                },
                {
                    label: this.translate('Program'),
                    key: 'program_name',
                    sortable: false,
                    class: 'hide-mobile',
                    search: false,
                },
            ],
        };
    },

    methods: {
        getProgramName(item) {
            return item instanceof CompanyEmailCustomTemplate
                ? item.resource.programs.models[0].program.name_translated
                : item.resource.programs[0].program.name;
        },
        getParticipants(item) {
            return item instanceof CompanyEmailCustomTemplate
                ? item.resource.programs.models[0].participants.models
                : item.resource.programs[0].participants;
        },
    },
};

export const emailConfig = {
    fields: [
        'id',
    ],
    relations: {
        resource: (query) => {
            query.select(['id', 'name', 'translations']).with({
                programs: (query) => {
                    query.select(['id', 'resourceable_id', 'resourceable_type', 'company_program_id', 'is_enabled'])
                        .with({
                            program: (query) => {
                                query.select(['id']).with({
                                    keyDate: (query) => query.with({
                                        locales: (query) => query.select(['id', 'language_key', 'name']),
                                    }),
                                    locales: (query) => query.select(['id', 'name', 'description', 'language_key']),
                                });
                            },
                            participants: (query) => {
                                query.select(['id', 'company_user_id', 'company_role_id'])
                                    .with({
                                        user: (query) => {
                                            query.select(['id', 'firstname', 'lastname', 'image']);
                                        },
                                        role: (query) => {
                                            query.select(['id', 'name', 'alias', 'is_removable', 'is_heyteam']);
                                        },
                                    });
                            },
                        });
                },
            });
        },
    },
};

</script>
