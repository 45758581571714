<template>
    <ul class="search-result-list flex-container flex-column">
        <template v-if="results && results.length > 0">
            <ResultItem
                v-for="(item, index) in results"
                :key="`user-${index}`"
                class="item"
                :item="item"
                :label-key="resultKey"
                :image-key="imageKey"
                @select="$emit('item-select', {item})"
            />
        </template>
        <li
            v-else
            class="search-no-results"
        >
            <t>No results</t>
        </li>
    </ul>
</template>

<script>
import ResultItem from '@/components/miscellaneous/SearchInput/ResultItem.vue';

export default {
    name: 'Results',
    components: {
        ResultItem,
    },
    props: {
        results: {
            type: Array,
            required: true,
        },
        resultKey: {
            type: [String, Function],
            required: false,
        },
        imageKey: {
            type: [String, Boolean],
            required: false,
            default: 'image',
        },
    },
};
</script>

<style scoped lang="scss">
.search-result-list {
    border: 1px solid #E5E6E6;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 0 6px 0 #ccc;
}
.search-no-results {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    background: white;
}
.item {
    &:first-child{
        border: none;
    }
    &:last-child{
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom: none;
    }
    &:nth-child(2n+1) {
        background: #F7F7F7;
    }
    &:hover {
        z-index: 2;
        cursor: pointer;
        &:before {
            opacity: 1;
        }
    }
}
</style>
