<template>
    <div v-if="companyPlanningEvent">
        <HtAccordion
            :title="translate('Events')"
            :count="getEventSorted.length"
        >
            <!-- LIST CURRENT WEEK PLANNING EVENTS (PAGINATED) -->
            <div class="planning-week-span">
                <button
                    type="button"
                    class="load-week-button previous"
                    @click="weekOffset--"
                >
                    <svg class="icon icon-arrow-left">
                        <use xlink:href="#icon-arrow-left" />
                    </svg>
                </button>

                <t>Week</t>
                {{ $Utils.moment().add(weekOffset, 'week').format('WW') }} ({{ $Utils.moment().add(weekOffset, 'week').startOf('isoWeek').format('DD MMM') }} - {{ $Utils.moment().add(weekOffset,
                                                                                                                                                                                       'week').endOf('isoWeek').format('DD MMM') }})

                <button
                    type="button"
                    class="load-week-button next"
                    @click="weekOffset++"
                >
                    <svg class="icon icon-arrow-right">
                        <use xlink:href="#icon-arrow-right" />
                    </svg>
                </button>
            </div>
            <HtTable
                v-if="getEventSorted.length > 0"
                :items="getEventSorted"
                :columns="columns"
                cypress="user-events"
                @onCellClicked="openItemModal"
            >
                <template #cell(display_status)="scope">
                    <HtStatusLabel
                        :status="scope.item.task.status"
                        :tag="true"
                    />
                </template>
                <template #cell(date)="scope">
                    {{ getDateTime(scope.item) }}
                </template>
                <template #cell(participant)="scope">
                    <ParticipantList :users="scope.item.attendees" />
                </template>
            </HtTable>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No events added</t>
                </strong>
            </div>
        </HtAccordion>

        <UserPlanningEventItem
            ref="userItem"
            :from="'profile'"
            @onUpdate="onUpdate(...arguments)"
            @onDelete="onDelete(...arguments)"
        />
    </div>
</template>

<script>
import HtAccordion from '@/components/globals/HtAccordion.vue';
import UserPlanningEventItem from '@/components/pages/dashboard/modals/planningEvent/UserPlanningEventItem.vue';
import CompanyUser from '@/models/CompanyUser';
import HtStatusLabel from '@/pages/HtStatusLabel.vue';
import api from '@/store/api';

export default {
    name: 'UserPlanningEentProfile',
    components: {
        HtAccordion,
        HtStatusLabel,
        UserPlanningEventItem,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            columns: [
                {
                    label: this.translate('status'),
                    key: 'display_status',
                    sortable: false,
                },
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                    search: true,
                },
                {
                    label: this.translate('Slot'),
                    key: 'date',
                    sortable: false,
                },
                {
                    label: this.translate('Participants'),
                    key: 'participant',
                    sortable: false,
                },
                {
                    label: this.translate('Program linked'),
                    key: 'company_user_program.company_program.name_translated',
                    sortable: false,
                },
            ],
            currentId: null,
            companyUser: new CompanyUser(
                ['id', 'firstname', 'arrival_date', 'is_onboardee'],
            ),
            companyPlanningEvent: null,
            weekOffset: 0,
        };
    },

    computed: {
        getEventSorted() {
            return this.companyPlanningEvent ? this.companyPlanningEvent : [];
        },

        currentYearWeek() {
            const current = this.$Utils.moment().add(this.weekOffset, 'w').startOf('isoWeek');
            const weekOfYear = current.isoWeek();

            return weekOfYear < 10
                ? `${current.isoWeekYear()}0${weekOfYear}`
                : `${current.isoWeekYear()}${weekOfYear}`;
        },
    },

    watch: {
        currentYearWeek(val) {
            this.loadPlanningWeekly(val);
        },
    },

    created() {
        this.companyUser.id = this.companyUserId;
        this.companyUser.get().then(() => {
            this.loadPlanningWeekly();
        });
    },

    methods: {
        onUpdate() {
            this.loadPlanningWeekly();
        },

        onDelete() {
            this.loadPlanningWeekly();
        },

        openItemModal(row) {
            this.$refs.userItem.open(row.item.id, this.companyUserId);
        },

        add() {
            this.weekOffset += 1;
        },

        sub() {
            this.weekOffset -= 1;
        },

        loadPlanningWeekly(yearWeek = this.currentYearWeek) {
            const data = {
                yearWeek,
                withoutDelegated: false,
            };

            if (this.companyUser.is_onboardee) {
                data.withoutDelegated = true;
            }

            api.user.planningEvents.getAllByUser(this.companyUserId, data).then((response) => {
                this.companyPlanningEvent = response.data.data;
                this.$emit('on-load', this.companyPlanningEvent.length);
            });

            return this.companyPlanningEvent;
        },

        getDateTime(item) {
            if (!item.datetime_start) {
                return '';
            }
            const language = this.shared.session.companyUser.company_language.key;

            if (!item.time_start) {
                const format = language === 'fr' ? 'Do MMM' : 'MMM Do';
                return this.$Utils.moment(item.datetime_start).format(format);
            }

            const format = language === 'fr' ? 'Do MMM -- HH:mm' : 'MMM Do -- HH:mm';
            return `${this.$Utils.moment(item.datetime_start).format(format)}-${this.$Utils.moment(item.datetime_end).format('HH:mm')} (${this.getEventTimeZone(item)})`;
        },
        getEventTimeZone(item) {
            if (!item.timezone) {
                return item.company_user.timezone;
            }

            return item.timezone;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.planning-week-span {
    margin-top: 20px;
    margin-bottom: 1.5em;
    position: relative;
    font-weight: bold;
    text-align: center;
}

.load-week-button {
    width: 30px;
    height: 30px;
    margin: 0 2em;
    top: 0;
    background: transparent;

    &.previous {
        left: 0;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
    }

    &.next {
        right: 0;
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
    }
}
</style>
