import Model from '@tony.caron/node-model-proxy/Model';
import CompanyQuiz from './CompanyQuiz';
import CompanyQuizQuestion from './CompanyQuizQuestion';
import CompanyUser from './CompanyUser';
import CompanyUserProgramTask from './CompanyUserProgramTask';
import CompanyUserQuizQuestionAnswer from './CompanyUserQuizQuestionAnswer';
import CompanyUserQuizCollection from './CompanyUserQuizCollection';
import CompanyUserProgram from './CompanyUserProgram';

export default class CompanyUserQuiz extends Model {
    modelConfig() {
        return {
            collection: CompanyUserQuizCollection,
        };
    }

    modelCustomAttributes() {
        return {
            company_user_quiz_participant: [],
            name: '',
        };
    }

    modelRelations() {
        return {
            companyUserProgram: () => this.belongsTo(CompanyUserProgram, 'company_user_program_id', 'id'),
            companyQuiz: () => this.belongsTo(CompanyQuiz, 'company_quiz_id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id'),
            companyUserParticipant: () => this.belongsTo(CompanyUser, 'company_user_participant_id'),
            companyUserProgramTask: () => this.belongsTo(CompanyUserProgramTask, 'company_user_program_task_id'),
            companyQuizQuestion: () => this.belongsTo(CompanyQuizQuestion, 'company_quiz_question_id'),
            companyUserQuizQuestionAnswer: () => this.hasMany(CompanyUserQuizQuestionAnswer, 'company_quiz_question_answer_id'),
        };
    }

    modelAccessors() {
        return {
            name: () => {
                if (!this.company_quiz || !this.company_user?.company_language) return '';
                return this
                    .company_quiz.resource.translations
                    .name[this.company_user.company_language.key]
                    ?? this.company_quiz.resource.name;
            },
        };
    }
}
