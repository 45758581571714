<template>
    <div>
        <HtModalBottom
            ref="modalBottom"
            :size="'large'"
            @onClose="$emit('on-close')"
        >
            <component
                :is="componentResource"
                v-if="modalType === htModalTypes.BOTTOM"
                :id="resourceId"
                :default-filters="defaultFilters"
                :company-program-id="companyProgramId"
                :default-order-onboarding-start="defaultOrderOnboardingStart"
                :default-order-onboarding-end="defaultOrderOnboardingEnd"
                :add-default-order-completion="true"
                :hided-tabs="hidedTabs"
                :show-list-on-end="false"
                :is-duplicable-model="isDuplicable"
                :disabled-program-list="true"
                :show-toggle-survey="false"
                @onSave="closeAndEmitOnSave"
                @on-close-stepper="closeHtModalBottom"
            />
        </HtModalBottom>
        <Modalable
            ref="modalResource"
            class="modalable-1"
            :class="modalSize"
            :mode="2"
            :options="{title: modalTitle}"
            @onClose="$emit('on-close')"
        >
            <component
                :is="componentResource"
                v-if="modalType === htModalTypes.RIGHT"
                :id="resourceId"
                :default-filters="defaultFilters"
                :default-order-onboarding-start="defaultOrderOnboardingStart"
                :default-order-onboarding-end="defaultOrderOnboardingEnd"
                :add-default-order-completion="canAddDefaultOrderCompletion"
                :company-program-id="companyProgramId"
                :value="resourceModel"
                :collection="resourceCollection"
                :is-duplicable-model="isDuplicable"
                :disabled-program-list="true"
                @onSave="$emit('on-add')"
            />
        </Modalable>
    </div>
</template>
<script>
// Edit Modals
import PlanningEventTemplateEdit from '@/components/resources/planningEvent/PlanningEventTemplateEdit.vue';
import EquipmentEdit from '@/components/resources/equipment/EquipmentEdit.vue';
import TrainingEdit from '@/components/resources/training/TrainingEdit.vue';
import SoftwareEdit from '@/components/resources/software/SoftwareEdit.vue';
import TaskEdit from '@/components/resources/task/TaskEdit.vue';
import SharedDocumentEdit from '@/components/resources/sharedDocument/SharedDocumentEdit.vue';
import ResourceEmailEdit from '@/components/resources/emails/ResourceEmailEdit.vue';
import QuizEdit from '@/router/pages/resources/quizzes/QuizEdit.vue';
import NudgeEdit from '@/components/resources/nudge/NudgeEdit.vue';
import SurveyEdit from '@/components/pages/resources/surveys/form/SurveyForm.vue';
import RequirementCategoryEdit from '@/components/pages/resources/requirements/form/RequirementForm.vue';

import DefaultFiltersMixin from '@/components/mixins/DefaultFiltersMixin';
import DefaultOrderCompletionMixin from '@/components/mixins/DefaultOrderCompletionMixin';

// Models & Collection
import CompanyResourceProgramCollection from '@/models/CompanyResourceProgramCollection';
import CompanySurveyCollection from '@/models/CompanySurveyCollection';
import CompanySharedDocumentCollection from '@/models/CompanySharedDocumentCollection';
import CompanyTrainingCollection from '@/models/CompanyTrainingCollection';
import CompanyQuizCollection from '@/models/CompanyQuizCollection';
import CompanyEmailCustomTemplateCollection from '@/models/CompanyEmailCustomTemplateCollection';
import CompanyProgramTaskCollection from '@/models/CompanyProgramTaskCollection';
import CompanyNudgeCollection from '@/models/CompanyNudgeCollection';
import CompanySoftwareCollection from '@/models/CompanySoftwareCollection';
import CompanyPlanningEventTemplateCollection from '@/models/CompanyPlanningEventTemplateCollection';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import CompanySurvey from '@/models/CompanySurvey';
import CompanySharedDocument from '@/models/CompanySharedDocument';
import CompanyTraining from '@/models/CompanyTraining';
import CompanyQuiz from '@/models/CompanyQuiz';
import CompanyEmailCustomTemplate from '@/models/CompanyEmailCustomTemplate';
import CompanyProgramTask from '@/models/CompanyProgramTask';
import CompanyNudge from '@/models/CompanyNudge';
import CompanySoftware from '@/models/CompanySoftware';
import CompanyPlanningEventTemplate from '@/models/CompanyPlanningEventTemplate';

const htModalTypes = {
    BOTTOM: 'HtModalBottom',
    RIGHT: 'HtModal',
};

export default {
    name: 'AddResourceModal',
    components: {
        TrainingEdit,
        PlanningEventTemplateEdit,
        EquipmentEdit,
        SoftwareEdit,
        TaskEdit,
        SharedDocumentEdit,
        ResourceEmailEdit,
        QuizEdit,
        NudgeEdit,
        SurveyEdit,
        RequirementCategoryEdit,
    },
    mixins: [
        DefaultFiltersMixin,
        DefaultOrderCompletionMixin,
    ],

    props: {
        componentResource: {
            type: String,
            required: true,
        },
        hidedTabs: {
            type: Array,
            default: () => [],
        },
        companyProgramId: {
            type: Number,
            required: true,
        },
        resourceModel: {
            type: [
                CompanyResourceProgram,
                CompanySurvey,
                CompanySharedDocument,
                CompanyTraining,
                CompanyQuiz,
                CompanyEmailCustomTemplate,
                CompanyProgramTask,
                CompanyNudge,
                CompanySoftware,
                CompanyPlanningEventTemplate,
                Object,
            ],
            required: false,
        },
        resourceCollection: {
            type: [
                CompanyResourceProgramCollection,
                CompanySurveyCollection,
                CompanySharedDocumentCollection,
                CompanyTrainingCollection,
                CompanyQuizCollection,
                CompanyEmailCustomTemplateCollection,
                CompanyProgramTaskCollection,
                CompanyNudgeCollection,
                CompanySoftwareCollection,
                Object,
                CompanyPlanningEventTemplateCollection,
            ],
            required: true,
        },
        modalSize: {
            type: String,
            default: 'medium',
        },
        modalType: {
            type: String,
            required: true,
        },
        modalTitle: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            htModalTypes,
        };
    },

    computed: {
        canAddDefaultOrderCompletion() {
            return !(this.resourceModel instanceof CompanySharedDocument);
        },

        isDuplicable() {
            return this.resourceModel && !(this.resourceModel instanceof CompanyResourceProgram);
        },

        resourceId() {
            if (this.resourceModel instanceof CompanyResourceProgram) {
                return this.resourceModel.resourceable_id;
            }

            return this.resourceModel ? this.resourceModel.id : null;
        },
    },

    created() {
        this.$nextTick(() => {
            if (this.modalType === this.htModalTypes.BOTTOM) {
                this.$refs.modalBottom.open();
            } else {
                this.$refs.modalResource.open();
            }
        });
    },

    methods: {
        closeAndEmitOnSave(args) {
            if (args?.close !== false) {
                this.$refs.modalBottom.close();
            }
            this.$emit('on-add', args);
        },

        closeHtModalBottom() {
            this.$refs.modalBottom.close();
        },
    },
};
</script>
