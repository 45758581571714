<template>
    <form class="form-2">
        <HtFormCheckbox
            :id="'media-' + _uid"
            v-model="question.params"
            v-validate.disable="'required'"
            :name="'media-' + _uid"
            :label="translate('Document format')"
            :options="[
                { value: 'pdf', text: 'PDF' },
                { value: 'office', text: 'Office (word, excel, txt, csv, etc...)' },
                { value: 'image', text: 'Image (png, jpg, etc...)' },
            ]"
            :data-vv-as="translate('document format')"
        />
    </form>
</template>

<script>
import HtFormCheckbox from '@/components/globals/HtFormCheckbox.vue';

export default {
    name: 'QuestionFileUpload',
    components: {
        HtFormCheckbox,
    },
    inject: ['$validator'],

    props: {
        value: {
            type: Array,
            required: true,
        },
        question: {
            type: Object,
            required: true,
        },
        questionIndex: {
            type: Number,
            required: true,
        },
        isCompleted: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>
