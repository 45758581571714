var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-content"},[_c('span',[_vm._v(" "+_vm._s(_vm.participantInfo.firstname)+" "+_vm._s(_vm.participantInfo.lastname))]),(!_vm.isTargetingProgram)?_c('span'):(_vm.isParticipantTheEnrollee)?_c('span',[_c('t',[_vm._v("Enrollee")])],1):_c('div',{staticClass:"wrapper-enrollee"},[_c('div',{directives:[{name:"user-image",rawName:"v-user-image",value:(_vm.$Utils.getCompanyComponentRoleInitials(
                'user',
                1,
                {
                    firstname: _vm.enrolleeInfo.firstname,
                    lastname: _vm.enrolleeInfo.lastname,
                    image: _vm.enrolleeInfo.image,
                }
            )),expression:"$Utils.getCompanyComponentRoleInitials(\n                'user',\n                1,\n                {\n                    firstname: enrolleeInfo.firstname,\n                    lastname: enrolleeInfo.lastname,\n                    image: enrolleeInfo.image,\n                }\n            )"}],staticClass:"enrollee-img"}),_c('span',[_vm._v(" "+_vm._s(_vm.enrolleeInfo.firstname)+" "+_vm._s(_vm.enrolleeInfo.lastname)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }