<template>
    <div class="enterprise-page-parameters">
        <div class="enterprise-page-parameters-form">
            <TranslatedForm class="mb-5" v-slot="{editingLang}">
                <HtFormInput
                    id="name"
                    name="name"
                    v-model="page.translations.name[editingLang]"
                    v-validate.disable="'required|max:255'"
                    :label="translate('Name')"
                    :data-vv-as="translate('name')"
                    :show-optional="false"
                />

                <HtFormTextarea
                    id="description"
                    name="description"
                    v-model="page.translations.description[editingLang]"
                    :label="translate('Description')"
                    :placeholder="translate('Write here your description...')"
                    :maxlength="255"
                    class="full-width no-margin"
                />
            </TranslatedForm>

            <div
                v-if="entitiesLoaded"
                v-for="entity in entities"
                :key="entity.id"
            >
                <HtFormSelector
                    :id="`entity${entity.slug}`"
                    :value="selectedValuesByEntityId[entity.id]"
                    :options="valuesByEntityId[entity.id]"
                    :placeholder="entity.is_heyteam ? translate(entity.name) : entity.name"
                    track-by="company_entity_value_id"
                    :name="`entity${entity.slug}`"
                    :label="entity.is_heyteam ? translate(entity.name) : entity.name"
                    :is-deletable="false"
                    label-options="name"
                    :show-optional="false"
                    :enable-selector="true"
                    @input="values => updateEntitiesFilter(entity, values)"
                >
                    <div slot="noOptions">
                        <t>All filters already selected</t>
                    </div>
                </HtFormSelector>
            </div>
        </div>

        <div class="enterprise-page-parameters-buttons">
            <HtButton
                :loading="isSaving"
                type="secondary"
                @click.native="$router.push({ name: 'SettingsEnterprisePage' })"
            >
                <t>Cancel</t>
            </HtButton>

            <HtButton
                :loading="isSaving"
                @click.native="onSave"
            >
                <t>Save</t>
            </HtButton>
        </div>
    </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import groupBy from 'lodash.groupby';
import { mapGetters, mapState } from 'vuex';
import api from '@/store/api';
import EnterprisePageTemplates from '@/components/settings/EnterprisePage/Templates/templates';
import StepperTabLayout from '@/components/globals/StepperTabLayout.vue';
import HtButton from '@/components/globals/HtButton.vue';
import HtFormCard from '@/components/globals/HtFormCard.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtFormTextarea from '@/components/globals/HtFormTextarea.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import TranslatedForm from '@/components/form/TranslatedForm.vue';

export default {
    name: 'EnterprisePageFormParameters',

    components: {
        TranslatedForm,
        HtFormSelector,
        StepperTabLayout,
        HtButton,
        HtFormCard,
        HtFormInput,
        HtFormTextarea,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isSaving: false,
            page: cloneDeep(this.item),
        };
    },

    created() {
        this.$store.dispatch('entities/fetchEntities');

        if (!this.page.id && this.$route.query.template) {
            const enterprisePageTemplate = EnterprisePageTemplates[this.$route.query.template];
            if (enterprisePageTemplate) {
                this.page.sections = enterprisePageTemplate.sections.map((section) => ({
                    content: section.content,
                    custom_style: section.css,
                    is_full_page: section.fullPageBorder,
                }));
            }
        }
    },

    computed: {
        ...mapState('entities', ['entitiesLoaded', 'entities']),
        ...mapGetters('entities', ['valuesByEntityId']),
        selectedValuesByEntityId() {
            const entities = {};

            const valuesByEntityId = groupBy(this.page.filters, 'company_entity_id');

            this.entities.forEach((entity) => {
                entities[entity.id] = (valuesByEntityId[entity.id] || []).map((value) => ({
                    ...value,
                    name: this.valuesByEntityId[value.company_entity_id].find((v) => v.company_entity_value_id === value.company_entity_value_id).name,
                }));
            });

            return entities;
        },
    },

    methods: {
        updateEntitiesFilter(entity, values) {
            const currentFilters = this.page.filters.filter((e) => e.company_entity_id !== entity.id);

            this.page.filters = [...currentFilters, ...values];
        },

        onSave() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.isSaving = true;

                    if (this.item.id) { // update
                        api
                            .settings
                            .enterprisePages
                            .pages
                            .update(this.item.id, this.page)
                            .then((res) => {
                                this.$emit('update:item', res.data.data);

                                this.$Notifier('App')
                                    .showInfo(this.translate('Successfully updated !'));

                                this.$nextTick(() => {
                                    this.$emit('goTo', 'content');
                                });
                            })
                            .finally(() => {
                                this.isSaving = false;
                            });
                    } else { // store
                        api
                            .settings
                            .enterprisePages
                            .pages
                            .store(this.page)
                            .then((res) => {
                                this.$emit('update:item', res.data.data);

                                this.$Notifier('App')
                                    .showInfo(this.translate('Successfully created !'));

                                this.$nextTick(() => {
                                    this.$emit('goTo', 'content');
                                });
                            })
                            .finally(() => {
                                this.isSaving = false;
                            });
                    }
                }
            });
        },
    },

    watch: {
        item: {
            deep: true,
            handler() {
                this.page = cloneDeep(this.item);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.enterprise-page-parameters {
    width: 100%;
    max-width: 880px;
    margin: 0 auto;

    &-buttons {
        display: flex;
        gap: 16px;
        justify-content: end;
    }
}
</style>
