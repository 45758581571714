<template>
    <HtModal
        ref="modal"
        @beforeOpen="beforeOpen"
        @beforeClose="beforeClose"
    >
        <template #header>
            <div v-if="isLoaded">
                <div v-if="companyPlanningEvent.id">
                    <t>Event details</t>
                </div>
                <div v-else>
                    <t>Event deleted</t>
                </div>
            </div>
        </template>
        <template #default>
            <div v-if="isLoaded">
                <div v-if="companyPlanningEvent.id">
                    <fieldset v-if="companyPlanningEvent.is_delegated">
                        <ErrorDisplay
                            :append-errors="'This event has not been planned yet'"
                        />
                    </fieldset>
                    <form
                        class="form-2 spaced label-underline"
                        data-cy="planning-event-form"
                        @submit.prevent
                    >
                        <div class="mb-20">
                            <div class="mb-10 event-item-title">
                                {{ companyPlanningEvent.name }}
                            </div>
                            <div
                                v-if="companyPlanningEvent.datetime_start"
                                class="mb-10 event-item-other"
                            >
                                <span>{{ eventDateTime }}</span>
                                <span v-if="companyPlanningEvent.place">| {{ companyPlanningEvent.place }}</span>
                            </div>
                            <div class="mb-10 d-flex align-items-center event-item-other">
                                <span class="mr-10"><t>Organized by</t> :</span>
                                <div
                                    v-if="companyPlanningEvent.owner"
                                    class="d-flex align-items-center"
                                >
                                    <UserBlock
                                        :key="companyPlanningEvent.owner_id"
                                        :item="{ company_user: companyPlanningEvent.owner }"
                                        :size="25"
                                    />
                                    <span>{{ companyPlanningEvent.owner.firstname }} {{ companyPlanningEvent.owner.lastname }}</span>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </div>
                            <div
                                v-if="companyPlanningEvent.place"
                                class="mb-10 d-flex align-items-center event-item-other"
                            >
                                <span class="mr-10"><t>Event location</t> :</span>
                                <div class="status-icon">
                                    {{ companyPlanningEvent.place }}
                                </div>
                            </div>
                            <div
                                v-if="companyPlanningEvent.link"
                                class="mb-10 d-flex align-items-center event-item-other"
                            >
                                <span class="mr-10"><t>Link</t> :</span>
                                <div class="status-icon">
                                    {{ companyPlanningEvent.link }}
                                </div>
                            </div>
                            <div class="mb-10 d-flex align-items-center event-item-other">
                                <span class="mr-10"><t>Status</t> :</span>
                                <div class="status-icon">
                                    {{ statusText }}
                                </div>
                            </div>
                            <div
                                v-if="companyPlanningEvent.companyFile && companyPlanningEvent.companyFile.original_name"
                                class="d-flex align-items-center event-item-other"
                            >
                                <span class="mr-10"><t>Attachment</t> :</span>
                                <Downloadable
                                    class="flex-grow-1"
                                    :company-file="companyPlanningEvent.companyFile"
                                />
                            </div>
                        </div>

                        <template v-if="computedDescription">
                            <HtDivider class="my-4" />

                            <div class="mb-20">
                                <div v-dompurify-html="computedDescription" />
                            </div>
                        </template>

                        <div class="mb-20">
                            <span class="event-item-title">Participants</span>
                            <HtDivider class="my-4" />
                            <div class="card-6 flex-container">
                                <UserBlock
                                    v-for="(participant, index) in companyPlanningEvent.attendees"
                                    :key="index"
                                    :item="{company_user: participant.company_user}"
                                    :size="45"
                                />
                            </div>
                        </div>
                        <div>
                            <span class="event-item-title"><t>Meeting notes</t></span>
                            <HtDivider class="my-4" />
                            <div
                                v-if="companyPlanningEvent.notes.length > 0"
                                class="mb-20"
                            >
                                <div
                                    v-for="(note, index) in companyPlanningEvent.notes"
                                    :key="index"
                                    class="event-item-note-list d-flex align-items-center justify-content-between"
                                    :data-cy="`note-${index}`"
                                    @click="openNoteModal(note)"
                                >
                                    <div class="d-flex flex-column">
                                        <span class="event-item-note-list-title">{{
                                            note.name
                                        }}</span>
                                        <div class="event-item-note-list-subtitle">
                                            <span><t>created by</t></span>
                                            <span>{{ note.owner.firstname }}
                                                {{ note.owner.lastname }}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <FontAwesomeIcon
                                            v-if="note.companyFile"
                                            :icon="['far', 'file-alt']"
                                            class="mr-10 event-item-note-list-icon-file"
                                            @click.stop="onDownloadNote(note)"
                                        />
                                        <FontAwesomeIcon :icon="['far', 'eye']" />
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else
                                class="d-flex justify-content-center event-item-note-empty"
                            >
                                <t>No recorded notes</t>
                            </div>
                            <div
                                v-if="
                                    $canCreate('ModelCompanyUserNote', {
                                        resource_id: companyPlanningEvent.id,
                                        resource_name: 'company_planning'
                                    })
                                "
                                class="mt-20 event-item-note-button"
                            >
                                <Button
                                    class="negative"
                                    cypress="note-add-button"
                                    @click="openNoteModal(null)"
                                >
                                    <t>Create note</t>
                                </Button>
                            </div>
                        </div>
                    </form>

                    <modalable
                        ref="modalableUserEdit"
                        class="modalable-1 medium"
                        :mode="2"
                    >
                        <template #header>
                            <div class="edit-header">
                                <FontAwesomeIcon
                                    icon="chevron-left"
                                    class="back-button"
                                    @click="$refs.modalableUserEdit.close()"
                                />
                                <div class="labels">
                                    <h2><t>Edit</t> “{{ companyPlanningEvent.name }}”</h2>
                                    <div
                                        v-if="eventDateTime !== '-'"
                                        class="datetime"
                                    >
                                        {{ eventDateTime }}
                                    </div>
                                    <div
                                        v-if="companyPlanningEvent.owner"
                                        class="organizer"
                                    >
                                        <span><t>Organized by</t> :</span>
                                        <UserBlock
                                            :key="companyPlanningEvent.owner_id"
                                            class="avatar"
                                            :item="{ company_user: companyPlanningEvent.owner }"
                                            :size="16"
                                        />
                                        <span>{{ companyPlanningEvent.owner.firstname }}
                                            {{ companyPlanningEvent.owner.lastname }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <UserPlanningEventEdit
                            :id="companyPlanningEvent.id"
                            ref="userEdit"
                            :value="companyPlanningEvent"
                            :from="from"
                            :intelligent-selection="intelligentSelection"
                            :company-user-id="companyUserId"
                            :company-user-program-id="companyPlanningEvent.company_user_program_id"
                            @onUpdate="onUpdate(...arguments)"
                            @onDelete="onListenDelete(...arguments)"
                        />
                    </modalable>

                    <modalable
                        ref="modalableUserNote"
                        class="modalable-1 small"
                        :mode="2"
                    >
                        <UserNoteModal
                            v-model="currentNote"
                            :collection="companyPlanningEvent.notes"
                            :resource-name="'company_planning'"
                            :resource-id="companyPlanningEvent.id"
                            @onCreateNote="(note) => onCreateNote(note)"
                            @onDeleteNote="(note) => onDeleteNote(note)"
                        />
                    </modalable>
                </div>
                <div v-else>
                    <div class="empty-component">
                        <strong>
                            <t>This event does not exist anymore</t>
                        </strong>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div
                v-if="isLoaded && !isCancelled"
                class="button-container"
            >
                <Button
                    v-if="companyPlanningEvent.permissions.delete"
                    :state="buttonState['delete']"
                    class="negative"
                    cypress="event-delete-button"
                    @click="onDelete()"
                >
                    <t>Delete</t>
                </Button>
                <Button
                    v-if="companyPlanningEvent.permissions.update"
                    :state="buttonState['modify']"
                    cypress="event-edit-button"
                    @click.stop="openEditModal()"
                >
                    <t>Edit</t>
                </Button>
            </div>
        </template>
    </HtModal>
</template>

<script>
import CompanyPlanningEventCollection from '@/models/CompanyPlanningEventCollection';
import Modalable from '@/Modalable';
import moment from 'moment';
import 'moment-timezone';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import api from '@/store/api';
import UserNoteModal from './UserNoteModal.vue';
import UserPlanningEventEdit from './UserPlanningEventEdit.vue';
import HtDivider from '../../../../globals/HtDivider.vue';

export default {
    name: 'UserPlanningEventItem',
    permissions: ['ModelCompanyUserNote'],

    components: {
        UserPlanningEventEdit,
        UserNoteModal,
        HtDivider,
    },

    props: {
        from: { type: String, required: true },
        intelligentSelection: { type: Array, required: false },
        collection: { type: CompanyPlanningEventCollection, required: false },
    },

    data() {
        return {
            currentNote: null,
            companyPlanningEventId: null,
            companyUserId: null,
            companyPlanningEvent: null,
            buttonState: {
                modify: 'idle',
                delete: 'idle',
            },
        };
    },

    computed: {
        getEventTimeZone() {
            if (!this.companyPlanningEvent.timezone) {
                return this.companyPlanningEvent.user.timezone;
            }

            return this.companyPlanningEvent.timezone;
        },

        eventDateTime() {
            if (this.companyPlanningEvent.task.datetime_start && this.companyPlanningEvent.task.datetime_end) {
                const date = moment(this.companyPlanningEvent.task.datetime_start).format('Do MMMM YYYY');
                const startTime = this.companyPlanningEvent.time_start;
                const endTime = this.companyPlanningEvent.time_end;
                return `${date} | ${startTime} - ${endTime} ${this.getEventTimeZone}`;
            }
            return '-';
        },

        isLoaded() {
            return this.companyPlanningEvent;
        },

        isCancelled() {
            return this.companyPlanningEvent.task.status === CompanyUserProgramTask.STATUS_CANCELLED;
        },

        statusText() {
            switch (this.companyPlanningEvent.task.status) {
            case 'done':
                return this.translate('Done');
            case 'on_error':
                return this.translate('Error');
            default:
                if (moment(this.companyPlanningEvent.task.datetime_start) > moment()) {
                    return this.translate('Forthcoming');
                }
                return this.translate('Event passed');
            }
        },

        computedDescription() {
            let job = this.translate('No job');
            if (this.companyPlanningEvent.user.job) {
                job = this.companyPlanningEvent.user.job.is_heyteam
                    ? this.translate(this.companyPlanningEvent.user.job.name)
                    : this.companyPlanningEvent.user.job.name;
            }

            let department = this.translate('No department');
            if (this.companyPlanningEvent.user.department) {
                department = this.companyPlanningEvent.user.department.is_heyteam
                    ? this.translate(this.companyPlanningEvent.user.department.name)
                    : this.companyPlanningEvent.user.department.name;
            }

            let contract = this.translate('No contract');
            if (this.companyPlanningEvent.user.contract) {
                contract = this.companyPlanningEvent.user.contract.is_heyteam
                    ? this.translate(this.companyPlanningEvent.user.contract.name)
                    : this.companyPlanningEvent.user.contract.name;
            }

            const variables = [
                ['{enrollee_firstname}', this.companyPlanningEvent.user.firstname],
                ['{enrollee_lastname}', this.companyPlanningEvent.user.lastname],
                [
                    '{enrollee_starting_date}',
                    this.$Utils
                        .moment(this.companyPlanningEvent.user.arrival_date)
                        .format(
                            (this.companyPlanningEvent.user.company_language.key === 'fr')
                                ? 'DD/MM/YYYY' : 'MM/DD/YYYY',
                        ),
                ],
                ['{enrollee_job_title}', job],
                ['{enrollee_department}', department],
                ['{enrollee_contract}', contract],
            ];

            return variables.reduce(
                // fixme: replaceAll function arrived in ES12+, we need to use replace with "/g" regex to be compatible with chrome browser <=v79 (used by Kiloutou)
                (description, [key, value]) => {
                    const regex = new RegExp(key, 'g');
                    return description.replace(regex, value);
                },
                this.companyPlanningEvent.description || '',
            );
        },
    },

    methods: {
        /**
         * @param {CompanyUserNote} note
         * @returns {void}
         */
        onDownloadNote(note) {
            document.location.href = note.companyFile.link;
        },

        openNoteModal(note) {
            this.currentNote = note;
            this.$refs.modalableUserNote.open();
        },

        onCreateNote(note) {
            this.companyPlanningEvent.notes.push(note);
        },

        onDeleteNote(noteId) {
            const index = this.companyPlanningEvent.notes.findIndex((item) => item.id === noteId);
            this.companyPlanningEvent.notes.splice(index, 1);
        },

        beforeOpen(resolve) {
            api.user.planningEvents.get(this.companyPlanningEventId).then((response) => {
                this.companyPlanningEvent = response.data.data;
            });

            resolve();
        },

        beforeClose(resolve) {
            this.companyPlanningEvent = null;
            resolve();
        },

        onUpdate() {
            this.$refs.modal.close();
            this.$emit('onUpdate');
        },

        /**
     * Quand on clique sur delete depuis la modale enfant
     * @returns {void}
     */
        onListenDelete() {
            this.$refs.modal.close();
            this.$emit('onDelete');
        },

        openEditModal() {
            this.$refs.modalableUserEdit.open();
        },

        open(event_id, companyUserId) {
            this.companyUserId = companyUserId;
            this.companyPlanningEventId = event_id;
            this.$refs.modal.open();
        },

        async onDelete() {
            this.buttonState.delete = 'loading';
            const didUserClickDelete = await Modalable.openRegisteredWindow(
                'Deletewindow',
                {
                    content: this.translate(
                        'Are you sure you want to delete this event ?',
                    ),
                },
            );
            if (didUserClickDelete === true) {
                await api.user.planningEvents.delete(this.companyPlanningEventId);

                this.buttonState.delete = 'idle';
                this.$refs.modal.close();
                this.$emit('onDelete');
            } else this.buttonState.delete = 'idle';
        },
    },
};
</script>

<style lang="scss" scoped src="./UserPlanningEventItem.scss"></style>
