<template>
    <div class="enterprise-page-item">
        <div class="enterprise-page-item-left">
            <FontAwesomeIcon
                :icon="['fas', 'bars']"
                class="handler"
            />

            <span>{{ page.translations.name[defaultLanguageKey] }}</span>
        </div>

        <div class="enterprise-page-item-middle">
            <HtEntitiesFiltersChips
                :filters="page.filters"
            />
        </div>

        <div class="enterprise-page-item-right">
            <HtTag
                v-for="language in availableLanguages"
                color="grey"
            >
                <span class="country-wrapper">
                    <span class="country">
                        <country-flag
                            :country="language.flag"
                        />
                    </span>
                </span>

                {{ language.name }}
            </HtTag>

            <HtActionMenu
                :actions="actions"
                @action-clicked="onActionClicked(page.id, ...arguments)"
            />
        </div>
    </div>
</template>

<script>
import api from '@/store/api';
import Modalable from '@/Modalable';
import HtTag from '@/components/globals/HtTag.vue';
import HtActionMenu from '@/components/globals/HtActionMenu.vue';
import HtEntitiesFiltersChips from '@/components/globals/filters/HtEntitiesFiltersChips.vue';

export default {
    name: 'EnterprisePageItem',

    components: {
        HtEntitiesFiltersChips,
        HtTag,
        HtActionMenu,
    },

    props: {
        page: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            company: this.shared.session.company,
            defaultLanguageKey: this.shared.session.company.company_language.key,
            actions: [
                {
                    id: 'edit',
                    label: this.translate('Edit'),
                },
                {
                    id: 'delete',
                    label: this.translate('Delete'),
                },
            ],
        };
    },

    computed: {
        availableLanguages() {
            const languageKeys = Object.keys(this.page.translations.name);

            return this.company.company_languages.models
                .filter((language) => language.is_enabled && languageKeys.includes(language.key))
                .map((language) => ({ key: language.key, name: language.name, flag: language.codeFlag }));
        },
    },

    methods: {
        onActionClicked(id, actionId) {
            if (actionId === 'edit') {
                this.$router.push({
                    name: 'SettingsEnterprisePageForm',
                    params: { id },
                });
            } else if (actionId === 'delete') {
                Modalable
                    .openRegisteredWindow('Deletewindow', { content: this.translate('Are you sure you want to delete this enterprise page ?') })
                    .then((confirmDeletion) => {
                        if (confirmDeletion) {
                            api
                                .settings
                                .enterprisePages
                                .pages
                                .destroy(id)
                                .then(() => {
                                    this.$emit('on-reload');
                                });
                        }
                    });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.enterprise-page-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    padding: 16px;

    &-left, &-middle, &-right {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 8px;
        width: 25%;
    }

    &-middle {
        flex: 2;
        width: 100%;
    }

    &-right {
        justify-content: end;

        .ht-tag {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .country-wrapper {
                display: flex;
                align-items: center;
                border-radius: 100%;
                width: 16px;
                height: 16px;
                overflow: hidden;

                .country {
                    transform: scale(0.6, 0.8);
                    -ms-transform: scale(0.6, 0.8);
                    -webkit-transform: scale(0.6, 0.8);
                    -moz-transform: scale(0.6, 0.8);
                    margin-top: 3px;
                    margin-left: -8px;
                }
            }
        }
    }

    .handler {
        cursor: move;
    }
}
</style>
