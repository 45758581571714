import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import HtProgramKeyDate from './HtProgramKeyDate';

export default class HtProgramKeyDateCollection extends ModelCollection {
    modelConfig() {
        return {
            model: HtProgramKeyDate,
        };
    }
}
