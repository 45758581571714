export default {
    props: {
        defaultFilters: {
            type: Object,
            default: () => ({
                value: {
                    office: [],
                    contract: [],
                    department: [],
                    job_position: [],
                },
            }),
        },
    },

    methods: {
        // TAKE CURRENT ITEM AS DEFAULT MODEL
        setDefaultFilter(item = this.item) {
            const defaultEntityNameList = Object.keys(this.defaultFilters.value);

            if (this.isDuplicableModel) {
                this.value.company_program_resource_filter.models.forEach((filter) => {
                    this.$refs.programResourceFilter.setFromDefaultFilters(filter);
                    this.$refs.programResourceFilter.getSelectedEntityValueList(filter.entity.slug).push(filter);
                });
            } else if (item && item.isLoaded()) {
                Object.values(this.defaultFilters.value).forEach((currentList, index) => {
                    currentList.forEach((filter) => {
                        this.$refs.programResourceFilter.setFromDefaultFilters(filter);
                        this.$refs.programResourceFilter.getSelectedEntityValueList(`company_${defaultEntityNameList[index]}`).push(filter);
                    });
                });
            }
        },
    },
};
