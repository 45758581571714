var render = function render(){var _vm=this,_c=_vm._self._c;return _c('UtilsTemplate',{attrs:{"title":_vm.programs[_vm.currentProgramIndex].name,"subtitle":_vm.getDescription},nativeOn:{"click":function($event){return _vm.$router.push({
        name: 'ProfileProgramDetail',
        params: {
            company_user_id: _vm.shared.session.companyUser.id,
            company_user_program_id: _vm.programs[_vm.currentProgramIndex].id,
        },
    })}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"test"},[_c('div',{staticClass:"progress-container"},_vm._l((_vm.programs),function(program,index){return _c('div',{key:program.id,ref:`program-percent-${index}`,refInFor:true,on:{"mouseover":function($event){return _vm.setActiveProgram(index)}}},[_c('ProgressTemplate',{attrs:{"percent":program.enrollee_completion,"is-active":_vm.currentProgramIndex === index}})],1)}),0)])]},proxy:true},(_vm.programs.length > 1)?{key:"title-extra",fn:function(){return [_c('div',{staticClass:"prev-next"},[_c('div',{staticClass:"prev-next-button",on:{"click":function($event){$event.stopPropagation();return _vm.changeActiveProgram(false)}}},[_c('font-awesome-icon',{staticClass:"prev-next-button-icon",attrs:{"icon":['fal', 'chevron-left']}})],1),_c('div',{staticClass:"prev-next-button",on:{"click":function($event){$event.stopPropagation();return _vm.changeActiveProgram(true)}}},[_c('font-awesome-icon',{staticClass:"prev-next-button-icon",attrs:{"icon":['fal', 'chevron-right']}})],1)])]},proxy:true}:null,{key:"footer",fn:function(){return [_c('div',[_c('HtButton',{attrs:{"type":"tertiary","size":"small","icon":"arrow-right"}},[_c('t',[_vm._v("Consult my program")])],1)],1)]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }