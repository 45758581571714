import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';

import CompanyUserSurveyQuestion from './CompanyUserSurveyQuestion';

export default class CompanyUserSurveyQuestionCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserSurveyQuestion,
        };
    }
}
