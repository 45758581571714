<template>
    <ChoiceResultItem
        :label="answerLabel"
        :percent="percent"
        :is-selected="isSelected"
    />
</template>

<script>
import choiceResultItemProps from '../choiceResultItemProps';
import ChoiceResultItem from '../ChoiceResultItem.vue';

export default {
    name: 'ResultYesNoItem',
    components: { ChoiceResultItem },
    mixins: [
        choiceResultItemProps,
    ],
};
</script>
