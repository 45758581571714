<template>
    <form class="form-2">
        <InputField
            v-model="value.translations.text[currentLanguage.key]"
            type="textarea"
            :name="`additional-${questionIndex}`"
            :placeholder="getPlaceholder"
            :validate-as="translate('additional information')"
            :validate="isDefaultLangSelected ? 'required' : ''"
            class="extra-text-textarea"
            cypress="survey-additional-information"
        />
    </form>
</template>

<script>
import CompanyLanguage from '@/models/CompanyLanguage';
import InputField from '@/components/globals/InputField.vue';

export default {
    name: 'ExtraText',
    components: {
        InputField,
    },
    inject: ['$validator'],

    props: {
        value: {
            type: Object,
            required: true,
        },
        currentLanguage: {
            type: CompanyLanguage,
            required: true,
        },
        questionIndex: {
            type: Number,
            required: true,
        },
    },

    shared: {
        session: {},
    },

    computed: {
        getPlaceholder() {
            if (this.isDefaultLangSelected === false && this.value.translations.text[this.shared.session.company.company_language.key] !== null) {
                return this.value.translations.text[this.shared.session.company.company_language.key];
            }
            return '';
        },

        isDefaultLangSelected() {
            return this.shared.session.company.company_language.key === this.currentLanguage.key;
        },
    },
};
</script>
