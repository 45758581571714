<template>
    <HtLayoutModal @close="$emit('close')">
        <template #header>
            <t>Edit team</t>
        </template>

        <template #main>
            <form
                class="form-2 spaced"
                @submit.prevent
            >
                <HtFormGroup
                    :label="translate('Selected user')"
                    is-required
                >
                    <TableCellUser :user="selectedUser" />
                </HtFormGroup>

                <HtFormUserSelector
                    v-for="(supervisor, index) in selectedSupervisors"
                    :id="`supervisor-${index}`"
                    :name="`supervisor-${index}`"
                    :label="supervisor.role.alias"
                    v-model="supervisor.user"
                    v-validate.disable="'required'"
                    :data-vv-as="supervisor.role.alias"
                    :allow-empty="false"
                    :show-asterisk="true"
                    :show-optional="false"
                    :options="defaultOptions[supervisor.role.name]"
                    @input="(value) => onSelectSupervisor(value, index)"
                    is-single
                    from-api
                />
            </form>
        </template>

        <template #footer>
            <HtButton
                type="secondary"
                @click.native="close"
            >
                <t>Cancel</t>
            </HtButton>

            <HtButton
                :loading="loading"
                @click.native="save"
            >
                <t>Save</t>
            </HtButton>
        </template>
    </HtLayoutModal>
</template>

<script>
import api from '@/store/api';
import HtLayoutModal from '@/components/globals/modals/layouts/HtLayoutModal.vue';
import HtButton from '@/components/globals/HtButton.vue';
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import TableCellUser from '@/components/globals/table/TableCellUser.vue';
import HtFormUserOrRoleSelect from '@/components/globals/HtFormUserOrRoleSelect.vue';
import HtFormUserSelector from '@/components/globals/Selectors/HtFormUserSelector.vue';

export default {
    name: 'BulkEditTeamModal',

    components: {
        HtFormUserSelector,
        HtFormUserOrRoleSelect,
        TableCellUser,
        HtFormGroup,
        HtButton,
        HtLayoutModal,
    },

    props: {
        bulk: {
            type: Object,
            required: true,
        },

        user: {
            type: Object,
            required: true,
        },

        assignableRoles: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            loading: false,
            selectedUser: { ...this.user },
            selectedSupervisors: [],
            defaultOptions: {},
        };
    },

    created() {
        this.assignableRoles
            .forEach((assignableRole) => {
                const userRole = this.user.employee_supervisors.find((supervisor) => supervisor.company_role_id === assignableRole.id);

                this.defaultOptions[assignableRole.name] = [];

                if (userRole?.user) {
                    this.defaultOptions[assignableRole.name] = [userRole.user];
                }

                this.selectedSupervisors.push({
                    id: userRole?.id || null,
                    company_role_id: assignableRole.id,
                    company_user_id: userRole?.company_user_id || null,
                    is_mandatory: true,
                    role: assignableRole,
                    user: userRole?.user || null,
                });
            });
    },

    methods: {
        close() {
            this.$emit('close');
        },

        save() {
            this.$validator
                .validateAll()
                .then((result) => {
                    if (!result) {
                        return;
                    }

                    this.loading = true;

                    api
                        .user
                        .enrolling
                        .bulk
                        .updateUserTeam(this.bulk.id, {
                            user_id: this.selectedUser.id,
                            supervisors: this.selectedSupervisors,
                        })
                        .then((res) => {
                            this.$emit('close', { name: 'on-update', value: res.data.data });
                        });
                });
        },

        onSelectSupervisor(value, index) {
            this.selectedSupervisors[index].company_user_id = value.id;
        },
    },
};
</script>
