import ErrorBag from './errorBag';
import Rules from './rules';
import ValidatorException from './validatorException';

import { getProperty } from './utils';

export default class Validator {
 	constructor() {
 		this.errorBag = new ErrorBag();
 	}

 	// Called from directive on node inserted to the dom
 	createNode(el, binding, vnode) {
        const node = this._createNode();

        node.name = el.getAttribute('name') || this._throwExeption('Field doesn\'t have name attribute.');

        node.el = el;
        node.context = vnode.context;
        node.scope = this._getElScope(vnode);

        node.errorBag = this.errorBag;
        node.onBlur = () => this._onBlur(node);

        this.errorBag._addNode(node);

        const validateModel = el.getAttribute('fvalidate-model');
        const validateMessage = el.getAttribute('fvalidate-message');

        this._addRules(node, binding);
        this._addMessage(node, validateMessage);

        this._attachValidateModel(node, validateModel);

        this._validate(node);

        node.el.addEventListener('blur', node.onBlur, false);
        node.el.addEventListener('change', node.onBlur, false); // Checkbox

        if (node.el.tagName != 'SELECT') node.el.addEventListener('input', node.onBlur, false);

        // window.toto = this;
 	}

 	_throwExeption(m) {
 		throw new ValidatorException(m);
 	}

 	_getElScope(vnode) {
 		const scope = vnode.data.attrs['vv-scope'];

 		if (scope === null || undefined === scope) return ['__global__'];

 		if (Array.isArray(scope)) return scope;

 		return [scope];
 	}

 	// Attach a rule to a node
 	_addRules(node, binding) {
 		binding.value.split('|').forEach((rule) => {
            const [name, value] = rule.split(':');

            this._checkRuleExist(name);
            node.rules[name] = { name, value: (value || '') };
        });
 	}

 	// Attach message to a node
 	_addMessage(node, message) {
 		if (undefined !== message) node.message = message;
 	}

 	// Called from directive on node updated from the dom
 	updateNode(el, vnode) {
 		const scope = this._getElScope(vnode);
 		const node = this.errorBag._getNode(el.name, scope);

 		// PAtch that allow to update when the value is changed from js
 		if (node && node.oldValue != node.getValue()) {
 			if (node.oldValue != null) node.showError = true;

 			this.errorBag.updateObserverCache();
 			node.oldValue = node.getValue();
 		}

 		if (node && node.hasOwnProperty('vueDirectiveUpdateAllowed') && node.vueDirectiveUpdateAllowed) this._validate(node);
 	}

 	// Called from directive on node updated from the dom
 	removeNode(el, vnode) {
 		const scope = this._getElScope(vnode);

        const node = this.errorBag._getNode(el.name, scope);

 		if (node) {
 			node.el.removeEventListener('blur', node.onBlur);
            node.el.removeEventListener('input', node.onBlur);
            node.el.removeEventListener('change', node.onBlur);
            this.errorBag.removeNode(node);
 		}
 	}

 	_checkRuleExist(name) {
 		if (!Rules[name]) {
            throw new ValidatorException(
	        `Rule doesn't exist '${name}'.`,
            );
        }
 	}

 	// When we want to watch another value than the Val of input
 	_attachValidateModel(node, validateModel) {
 		if (validateModel) {
            node.outerValue = getProperty(validateModel, node.context);

            node.context.$watch(validateModel, (value) => {
                node.outerValue = value;
                this._validate(node);
            });
        }
 	}

 	_onBlur(node) {
 		node.showError = true;
 		this._validate(node);
 		this.errorBag.updateObserverCache();

 		// node.el.removeEventListener('blur', node.onBlur);
 	}

 	_validate(node) {
 		const oldNodeValid = node.valid;

        const _rules = Object.keys(node.rules).map((k) => node.rules[k]);

 		// TODO EACH RULE
 		node.valid = _rules.every((rule) => Rules[rule.name](node));

 		this._refreshErrorCounter(node, oldNodeValid);
 		// this.errorBag.updateObserverCache();
 	}

 	_refreshErrorCounter(node, oldNodeValid) {
 		// !!
 		// const errorAdd = node.valid == oldNodeValid ? 0 : (node.valid && !oldNodeValid ? 1 : -1);
 		const errorAdd = node.valid && !oldNodeValid ? -1 : (!node.valid && oldNodeValid ? 1 : 0);
 		this.errorBag.nb_error += errorAdd;

 		this.errorBag.scopedError[node.scope] += errorAdd;

 		// this.errorBag.nb_error = node.valid ? --this.errorBag.nb_error : node.valid;
 		// 0 0 => + 0
 		// 1 0 => + 1
 		// 0 1 => + -1
 		// 1 1 => 0
 	}

 	_getValue(node) {
 		return node.outerValue != null ? node.outerValue : node.el.value;
 	}

 	_createNode() {
 		return {

            el: null,
            name: null,
            context: null,
            outerValue: null,
            oldValue: null,
            message: '',
            type: null,
            rules: [],
            attrs: {},
            valid: false,
            scope: '__global__',
            showError: false,
            errorBag: null,
            vueDirectiveUpdateAllowed: true,
            getValue() { return this.outerValue != null ? this.outerValue.toString() : this.el.value.toString(); },
        };
 	}

 	init() {
 	}
}
