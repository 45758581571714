<template>
    <div>
        {{ UserRequirement.value | empty }}
    </div>
</template>

<script>

export default {
    name: 'UserRequirementTextRead',
    props: {
        UserRequirement: {
            type: Object,
            required: true,
        },
    },
};
</script>
