<template>
    <ListTasksByUser is-history />
</template>

<script>
import ListTasksByUser from '../listTasks/ByUser/ListTasksByUser.vue';

export default {
    name: 'HistoryByUser',
    components: { ListTasksByUser },
};
</script>
