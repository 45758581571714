var render = function render(){var _vm=this,_c=_vm._self._c;return (Object.keys(_vm.item).length)?_c('div',{directives:[{name:"user-image",rawName:"v-user-image",value:(_vm.$Utils.getCompanyComponentRoleInitials(
        _vm.item.company_user ? 'user' : _vm.item.company_role.alias,
        1.25,
        _vm.item.company_user ? {
            firstname: _vm.item.company_user.firstname,
            lastname: _vm.item.company_user.lastname,
            image: _vm.item.company_user.image,
        } : null,
        null)
    ),expression:"$Utils.getCompanyComponentRoleInitials(\n        item.company_user ? 'user' : item.company_role.alias,\n        1.25,\n        item.company_user ? {\n            firstname: item.company_user.firstname,\n            lastname: item.company_user.lastname,\n            image: item.company_user.image,\n        } : null,\n        null)\n    "}],staticClass:"item-image role-only",class:_vm.item.company_user
        ? 'user'
        : (
            _vm.item.company_role.is_removable
                ? ''
                : _vm.item.company_role.name
        ),style:({
        'height': `${_vm.size}px`,
        'width': `${_vm.size}px`,
    })}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }