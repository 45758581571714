import Vue from 'vue';

export default {
    get(id, config = {}) {
        return Vue.prototype.$http.get(`users/surveys/${id}`, config);
    },

    save(id, data = {}) {
        return Vue.prototype.$http.post(`users/surveys/${id}/answers`, data);
    },

    saveDraftQuestion(id, questionId, data = {}) {
        return Vue.prototype.$http.post(`users/surveys/${id}/questions/${questionId}/draft`, data);
    },
};
