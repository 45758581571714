<template>
    <div :class="'tag ' + color">
        <span
            v-if="color != 'neutral'"
            class="color-circle"
        />
        <span class="label">{{ isUppercase ? label.toUpperCase() : label }}</span>
    </div>
</template>
<script>
export default {
    name: 'TableCellTag',
    props: {
        color: {
            type: String,
            default: 'neutral',
        },
        label: {
            type: String,
            required: true,
        },
        isUppercase: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';
.tag {
    border-radius: 2px;
    width: fit-content;
    height: 2rem;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    justify-content: start;
    align-items: center;
    font-weight: bold;
    border: solid .2px $grey-ultra-light;
    border-radius: .5rem;
    background-color: #FFFFFF;

    & .label {
        margin-right: .5rem;
    }

    &.neutral {
        background-color: $grey-lighter;
        & .label {
            margin-left: .5rem;
        }
    }

    & .color-circle {
        width: .75rem;
        height: .75rem;
        border-radius: 50%;
        margin-left: .5rem;
        margin-right: .5rem;
    }

    &.orange .color-circle {
        background-color: #F4B615;
    }
    &.purple .color-circle {
        background-color: #661EAD;
    }
    &.red .color-circle {
        background-color: #DC3D1B;
    }
    &.green .color-circle {
        background-color: #25B771;
    }
    &.blue .color-circle {
        background-color: $dashboard-blue;
    }
    &.grey .color-circle {
        background-color: #6E6E6F;
    }
}
</style>
