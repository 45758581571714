<template>
    <div class="survey-navigation-list">
        <HtToast
            v-if="mandatoryQuestionWithoutAnswers.length > 0"
            type="danger"
            :title="translate('Answers are required')"
            :message="translate('Answer all the mandatory questions to be able to complete the survey.')"
        />

        <UserSurveyNavigationItem
            v-for="(content, index) in contents"
            :key="index"
            :number="index + 1"
            :content="content"
            :mandatory-question-without-answers="mandatoryQuestionWithoutAnswers"
            @goto="$emit('goto', content)"
        />

        <HtToast
            v-if="!isReadOnly && nextLocalJumpContentIndex !== -1"
            type="locked"
            :title="translate('Waiting for your answer')"
            :message="translate('By answering the previous question you will unlock the rest of the survey.')"
        />
    </div>
</template>

<script>
import UserSurveyNavigationItem from '@/components/resources/user/surveys/navigation/UserSurveyNavigationItem.vue';
import HtToast from '@/components/globals/HtToast.vue';

export default {
    name: 'UserSurveyNavigation',

    components: {
        HtToast,
        UserSurveyNavigationItem,
    },

    props: {
        contents: {
            type: Array,
            required: true,
        },

        mandatoryQuestionWithoutAnswers: {
            type: Array,
            default: () => [],
        },

        nextLocalJumpContentIndex: {
            type: Number,
            required: true,
        },

        isReadOnly: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.survey-navigation-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
</style>
