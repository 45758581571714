<template>
    <TableTemplate
        :items="items"
        :title="title"
        :placeholder-image="placeholderImage"
        :placeholder-text="placeholderText"
        :placeholder-title="placeholderTitle"
        :loading="loading"
    />
</template>

<script>
import TableTemplate from './templates/TableTemplate.vue';

export default {
    name: 'DeadlinesWidget',
    components: {
        TableTemplate,
    },
    computed: {
        items() {
            return this.$store.state.dashboard.statistics.deadlines.map((line) => ({
                id: line.id,
                image: line.image,
                name: `${line.firstname} ${line.lastname.toUpperCase()}`,
                detail: `${this.dateTypeFromToken(line.date_type)} : ${this.$Utils.moment(line.key_date).format('L')}`,
            }));
        },
        title() {
            return this.translate('Probation and contract end dates');
        },
        placeholderTitle() {
            return this.translate('Nothing planned !');
        },
        placeholderText() {
            return this.translate('No end of contract or probation is planned currently.');
        },
        placeholderImage() {
            return '/static/images/dashboard/widgets/deadlines.svg';
        },
        loading() {
            return !this.$store.state.dashboard.statistics.deadlinesLoaded;
        },
    },
    watch: {
        loading: {
            handler(val) {
                if (val) {
                    this.$store.dispatch('dashboard/statistics/fetchDeadlines');
                }
            },
            immediate: true,
        },
    },
    methods: {
        dateTypeFromToken(token) {
            const mapping = {
                end_date: this.translate('Contract'),
                first_probation_end_date: this.translate('Probation'),
                second_probation_end_date: this.translate('Probation'),
            };

            return mapping[token];
        },
    },
};
</script>
