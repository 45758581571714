<template>
    <TableTemplate
        :items="items"
        :title="title"
        :placeholder-image="placeholderImage"
        :placeholder-text="placeholderText"
        :placeholder-title="placeholderTitle"
        :loading="loading"
        :help="help"
    />
</template>
<script>
import TableTemplate from './templates/TableTemplate.vue';

export default {
    name: 'MostActiveMembersWidget',
    components: {
        TableTemplate,
    },
    computed: {
        items() {
            return this.$store.state.dashboard.statistics.mostActiveMembers.map((line) => ({
                id: line.participant_id,
                image: line.participant_image,
                name: `${line.participant_firstname} ${line.participant_lastname.toUpperCase()}`,
                detail: this.translate('{count} resource | {count} resources', { count: line.tasks }),
            }));
        },
        title() {
            return this.translate('Most active members the last 30 days');
        },
        placeholderTitle() {
            return this.translate('No active collaborator');
        },
        placeholderText() {
            return this.translate('Feel free to motivate or help your collaborators with their tasks');
        },
        placeholderImage() {
            return '/static/images/dashboard/widgets/mostActiveMembers.svg';
        },
        loading() {
            return !this.$store.state.dashboard.statistics.mostActiveMembersLoaded;
        },
        help() {
            return this.translate('Ranking of employees who have completed the most resources in the last 30 days');
        },
    },
    watch: {
        loading: {
            handler(val) {
                if (val) {
                    this.$store.dispatch('dashboard/statistics/fetchMostActiveMembers');
                }
            },
            immediate: true,
        },
    },
};
</script>
