import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserTraining from './CompanyUserTraining';

export default class CompanyUserTrainingCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserTraining,
        };
    }

    modelCustomAttributes() {
        return {
            company_user_id: null,
        };
    }
}
