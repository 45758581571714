import Model from '@tony.caron/node-model-proxy/Model';
import HtPermissionCollection from './HtPermissionCollection';
import HtPermissionGroup from './HtPermissionGroup';

export default class HtPermission extends Model {
    static TYPE_UNIQUE = 'unique';

    static TYPE_SPLITTED = 'splitted';

    static TYPE_SELECT = 'select';

    static COMPONENT_MODEL_COMPANY_SURVEY = 'ModelCompanySurvey';

    modelConfig() {
        return {
            controller: 'HtPermissionController',
            collection: HtPermissionCollection,
        };
    }

    modelRelations() {
        return {
            htPermissionGroup: () => this.belongsTo(HtPermissionGroup, 'ht_permission_group_id', 'id'),
        };
    }
}
