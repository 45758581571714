<template>
    <div class="box has-padding">
        <div class="items-status mb-10">
            <div
                v-for="(resource, index) in getAvailableRessource"
                :key="index"
                class="item-status"
            >
                <div class="item-top">
                    <div class="item-name">
                        <t>{{ resource.label }}</t>
                    </div>

                    <div class="item-content">
                        {{ company_user_resource_stats[resource.key].done }}/{{ company_user_resource_stats[resource.key].total }}
                    </div>
                </div>

                <div class="item-bottom">
                    <div class="progress-bar-container">
                        <div
                            class="progress-bar green"
                            :style="'width: '+(company_user_resource_stats[resource.key].done / company_user_resource_stats[resource.key].total)*100+'%'"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Elements } from '@/models/common/Elements';
import CompanyUserProgramTaskCollection from '@/models/CompanyUserProgramTaskCollection';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';

export default {
    name: 'UserResourceStatus',
    props: {
        company_user_id: {
            type: [String, Number],
            required: true,
        },
        companyUserProgramTaskCollection: {
            type: CompanyUserProgramTaskCollection,
        },
        seeMore: {
            type: Boolean,
            default: true,
        },
    },

    shared: {
        program: {
            validateBulk: {
                type: Boolean,
                default: false,
            },
            deleteBulk: {
                type: Boolean,
                default: false,
            },
            removeIncrementTask: {
                type: Number,
                default: 0,
            },
            updateIncrementTask: {
                type: Number,
                default: 0,
            },
        },
    },

    data() {
        return {
            is_loaded: false,
            company_user_resource_stats: this.$Utils.clone(Elements, true),
            installationProperties: [
                { key: 'company_user_requirement_stats', label: 'Administrative' },
                { key: 'company_user_training_stats', label: 'Trainings' },
                { key: 'company_user_software_stats', label: 'Software' },
                { key: 'company_user_planning_stats', label: 'Events' },
                { key: 'company_user_equipment_stats', label: 'Equipment' },
                { key: 'company_user_doc_stats', label: 'Documents' },
                { key: 'company_user_survey_stats', label: 'Surveys' },
                { key: 'company_user_nudge_stats', label: 'Nudges' },
                { key: 'company_user_quiz_stats', label: 'Quiz' },
            ],
        };
    },

    computed: {
        getAvailableRessource() {
            return this.seeMore ? this.installationProperties : this.installationProperties.slice(0, 5);
        },
    },

    watch: {
        'shared.program.removeIncrementTask': function () {
            this.init();
        },

        'shared.program.updateIncrementTask': function () {
            this.init();
        },
    },

    created() {
        this.init();
    },

    methods: {
        init() {
            this.is_loaded = false;
            this.setResourceStats();
            this.is_loaded = true;
        },

        setResourceStats() {
            this.company_user_resource_stats.company_user_requirement_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_REQUIREMENT_CATEGORY).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_REQUIREMENT_CATEGORY).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_REQUIREMENT_CATEGORY).length,
            };
            this.company_user_resource_stats.company_user_training_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_DOCUMENT).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_DOCUMENT).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_DOCUMENT).length,
            };
            this.company_user_resource_stats.company_user_software_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_SOFTWARE).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_SOFTWARE).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_SOFTWARE).length,
            };
            this.company_user_resource_stats.company_user_planning_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_PLANNING_EVENT).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_PLANNING_EVENT).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_PLANNING_EVENT).length,
            };
            this.company_user_resource_stats.company_user_equipment_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_EQUIPMENT).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_EQUIPMENT).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_EQUIPMENT).length,
            };
            this.company_user_resource_stats.company_user_doc_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_DOC).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_DOC).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_DOC).length,
            };
            this.company_user_resource_stats.company_user_survey_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_SURVEY).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_SURVEY).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_SURVEY).length,
            };
            this.company_user_resource_stats.company_user_nudge_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_NUDGE).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_NUDGE).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_NUDGE).length,
            };
            this.company_user_resource_stats.company_user_quiz_stats = {
                pending: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_PENDING
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_QUIZ).length,
                done: this.companyUserProgramTaskCollection.models.filter((task) => task.status === CompanyUserProgramTask.STATUS_DONE
                    && task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_QUIZ).length,
                total: this.companyUserProgramTaskCollection.models.filter((task) => task.resource === CompanyUserProgramTask.TASK_COMPANY_USER_QUIZ).length,
            };
        },
    },

};
</script>
