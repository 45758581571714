<template>
    <div
        v-if="item && item.isLoaded()"
        class="user-survey-result"
    >
        <div class="no-print">
            <Button
                v-if="hasAnswer"
                class="mr-3"
                cypress="survey-export-results-button"
                @click="onExport('csv')"
            >
                <t>Export this form in CSV</t>
            </Button>
            <Button
                v-if="hasAnswer"
                class="mr-3"
                cypress="survey-export-results-button"
                @click="onExport('xlsx')"
            >
                <t>Export this form in Excel</t>
            </Button>
            <Button
                v-if="hasAnswer"
                cypress="survey-on-print-button"
                @click="onPrint"
            >
                <t>Print this form</t>
            </Button>
            <template v-if="hasAnswer">
                <div class="user-survey-result-item ">
                    <h2 class="mb-20">
                        Infos
                    </h2>
                    <div
                        v-if="relatedProgram"
                        class="user-survey-result-item-content pb-20"
                    >
                        <div><t>Program</t></div>
                        <div class="user-survey-result-item-content-right">
                            <div class="ml-20">
                                {{ relatedProgram.name_translated }}
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="hasRelatedUser"
                        class="user-survey-result-item-content pb-20"
                    >
                        <div><t>Program recipient</t></div>
                        <div class="user-survey-result-item-content-right">
                            <div
                                v-user-image="{
                                    image: item.company_user.image,
                                    firstname: item.company_user.firstname,
                                    lastname: item.company_user.lastname
                                }"
                                class="user-image clickable-image"
                            />
                            <div class="ml-20">
                                {{ item.company_user.firstname }} {{ item.company_user.lastname }}
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="hasParticipant"
                        class="user-survey-result-item-content pb-20"
                    >
                        <div>Participant</div>
                        <div class="user-survey-result-item-content-right">
                            <div
                                v-user-image="{
                                    image: item.company_user_participant.image,
                                    firstname: item.company_user_participant.firstname,
                                    lastname: item.company_user_participant.lastname
                                }"
                                class="user-image clickable-image"
                            />
                            <div class="ml-20">
                                {{ item.company_user_participant.firstname }} {{ item.company_user_participant.lastname }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="user-survey-result-item">
                    <h2 class="mb-20">
                        <t>Answers to question</t>
                    </h2>
                    <UserSurveyResultList v-model="item.company_user_survey_question" />
                </div>
            </template>
            <div
                v-else
                class="user-survey-result-item"
            >
                <t>No result yet</t>...
            </div>
        </div>
        <div ref="pdf" />
    </div>
</template>

<script>
import CompanyUserSurveyCollection from '@/models/CompanyUserSurveyCollection';
import CompanyUserSurvey from '@/models/CompanyUserSurvey';
import Vue from 'vue';
import api from '@/store/api';
import UserSurveyResultPdf from './UserSurveyResultPdf.vue';
import UserSurveyResultList from './UserSurveyResultList.vue';

export default {
    name: 'UserSurveyResult',
    components: {
        UserSurveyResultList,
    },
    inject: ['modal'],

    props: {
        companyUserId: { type: [String, Number], required: true },
        value: { type: CompanyUserSurvey, required: false },
        collection: { type: CompanyUserSurveyCollection, required: false },
        id: { type: [String, Number], required: false },
    },

    data() {
        return {
            companyUserSurvey: new CompanyUserSurvey([
                'id',
                'name',
                'company_user_id',
                'company_user_participant_id',
            ]).with({
                companyUserProgramTask: (query) => {
                    query
                        .select([
                            'id', 'status',
                        ]);
                },
                companySurvey: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            resource: (query) => {
                                query
                                    .select([
                                        'id', 'name',
                                    ]);
                            },
                            resourceProgram: (query) => {
                                query
                                    .with({
                                        program: (query) => {
                                            query
                                                .with({
                                                    locales: (query) => {
                                                        query.select(['id', 'name', 'language_key']);
                                                    },
                                                });
                                        },
                                    });
                            },
                        });
                },
                companyUserSurveyQuestion: (query) => {
                    query.select(['id', 'company_survey_question_id', 'company_user_survey_id'])
                        .with({
                            companySurveyQuestion: (query) => {
                                query.select([
                                    'id', 'order', 'type', 'params', 'subtype',
                                ]).with({
                                    companySurveyQuestionLocale: (query) => {
                                        query.select([
                                            'id', 'text', 'company_survey_question_id', 'language_key',
                                        ]);
                                    },
                                    companySurveyQuestionAnswer: (query) => {
                                        query.select([
                                            'id', 'order', 'company_entity_value_id',
                                        ]).with({
                                            value: (query) => {
                                                query.select([
                                                    'id',
                                                    'resourceable_id',
                                                    'resourceable_type',
                                                ]).with({
                                                    custom: (query) => {
                                                        query.select([
                                                            'id',
                                                            'name',
                                                        ]);
                                                    },
                                                });
                                            },
                                            companySurveyQuestionAnswerLocale: (query) => {
                                                query.select([
                                                    'id', 'text', 'company_survey_question_answer_id', 'language_key',
                                                ]);
                                            },
                                        });
                                    },
                                }).orderBy([['order', 'asc']]);
                            },
                            companyUserSurveyQuestionAnswer: (query) => {
                                query.select(['id', 'company_user_survey_question_id', 'company_survey_question_answer_id', 'text', 'company_file_id']).with({
                                    companySurveyQuestionAnswer: (query) => {
                                        query.select([
                                            'id', 'order', 'company_entity_value_id',
                                        ]).with({
                                            value: (query) => {
                                                query.select([
                                                    'id',
                                                    'resourceable_id',
                                                    'resourceable_type',
                                                ]).with({
                                                    custom: (query) => {
                                                        query.select([
                                                            'id',
                                                            'name',
                                                        ]);
                                                    },
                                                });
                                            },
                                            companySurveyQuestionAnswerLocale: (query) => {
                                                query.select([
                                                    'id', 'text', 'company_survey_question_answer_id', 'language_key',
                                                ]);
                                            },
                                        });
                                    },
                                    companyFile: (query) => {
                                        query.select([
                                            'id',
                                            'original_name',
                                            'uuid',
                                            'link',
                                            'link_preview',
                                            'path',
                                            'detected_extension',
                                            'detected_mime_type',
                                            'name',
                                            'url',
                                            'fullpath',
                                        ]);
                                    },
                                });
                            },
                        });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserParticipant: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'image']);
                },
                companyUserSurveySupervisor: (query) => {
                    query.select(['id', 'company_survey_id', 'company_user_participant_id', 'company_user_id']).with({
                        companyUser: (query) => {
                            query.select(['id', 'firstname', 'lastname', 'image']);
                        },
                    });
                },
            }).where([['id', '=', this.id]]),
            item: null,
            instancePdf: null,
        };
    },

    computed: {
        hasAnswer() {
            return this.item.company_user_program_task.status === 'done';
        },

        hasRelatedUser() {
            return this.item.company_user;
        },

        hasParticipant() {
            return this.item.company_user_participant;
        },
        hasWatchers() {
            return this.item.company_user_survey_supervisor.models.length;
        },

        filterWatchers() {
            return this.item.company_user_survey_supervisor.models.filter((item) => item.company_user);
        },
        relatedProgram() {
            return this.item.company_survey.resource_program.models[0]?.program;
        },
    },

    created() {
        if (this.value) {
            this.item = this.value.fromSaveState();
        } else {
            this.companyUserSurvey.get().then(() => {
                this.item = this.companyUserSurvey;
                this.modal.setTitle(this.item.name);
            });
        }
    },

    methods: {
        onExport(format) {
            api.miscellaneous.exportSurveys({
                user_survey_ids: [this.id],
                format,
            }).then((data) => {
                window.location = data.data.link;
            }).catch(() => {
                this.$Notifier('App').showError(
                    this.translate('An error occured when trying to export the data!'),
                );
            });
        },

        onPrint() {
            if (this.$refs.pdf.childElementCount === 0) {
                const ComponentResultPdf = Vue.extend(UserSurveyResultPdf);
                this.instancePdf = new ComponentResultPdf({
                    propsData: { item: this.item },
                });
                this.instancePdf.$mount();
                this.$refs.pdf.appendChild(this.instancePdf.$el);
            } else {
                this.instancePdf.onPrint();
            }
        },
    },
};
</script>
<style lang="scss" scoped src="./UserSurveyResult.scss"></style>
