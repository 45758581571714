<template>
    <div>
        <transition leave-active-class="leave-fade-dashboard">
            <ListActionsItemHtml
                v-show="nbDisplayTasks > 0"
                class="mb-10"
                :title="title"
                :is-late="isLate"
                :date="date"
                :show-wrapper="showWrapper"
                :is-toggled="isToggled"
                :can-validate="false"
                :is-selectable="isSelectable"
                :is-selected="isWrapperSelected"
                :show-multi-select="showMultiSelect"
                :is-history="isHistory"
                @onSelectedAction="onSelectedAction"
                @onClick="onCollapse"
            >
                <template slot="extra-right">
                    <div class="extra-right branding-bg">
                        {{ nbDisplayTasks }}
                    </div>
                </template>
                <template
                    v-if="isToggled"
                    slot="subItems"
                >
                    <div class="mb-20">
                        <transition-group name="slide">
                            <ListActionsByActionWrapperItem
                                v-for="a in actions"
                                :key="a.company_user_program_task.id"
                                :action="a.company_user_program_task"
                                :show-multi-select="showMultiSelect"
                                :selected-action-list="selectedActionList"
                                :is-history="isHistory"
                                class="mb-10"
                            />
                        </transition-group>
                    </div>
                </template>
            </ListActionsItemHtml>
        </transition>
    </div>
</template>

<script>
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import ListActionsItemHtml from '@/components/pages/dashboard/actions/ListActionsItemHtml.vue';
import ListActionsByActionWrapperItem from './ListActionsByActionWrapperItem.vue';

export default {
    name: 'ListActionsByActionWrapper',
    components: {
        ListActionsByActionWrapperItem,
        ListActionsItemHtml,
    },
    props: {
        resource: {
            type: String,
            required: true,
        },
        actions: {
            type: Array,
            required: true,
        },
        showWrapper: {
            type: Boolean,
            default: true,
        },
        showMultiSelect: {
            type: Boolean,
            required: true,
        },
        selectedActionList: {
            type: Array,
            required: true,
        },
        isHistory: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            isToggled: false,
        };
    },

    computed: {
        aActionIds() {
            return this.actions.map((action) => action.company_user_program_task.id);
        },

        title() {
            return this.actions[0].company_user_program_task.title;
        },

        isLate() {
            return this.$Utils.moment(this.date).isBefore(this.$Utils.moment(), 'day')
                && this.resource !== 'company_planning';
        },

        date() {
            return this.actions.reduce((min, action) => (min < action.company_user_program_task.datetime_end ? min : action.company_user_program_task.datetime_end), this.actions[0].company_user_program_task.datetime_end);
        },

        isSelectable() {
            return this.resource !== 'company_survey' && this.resource !== 'company_requirement_category' && this.resource !== 'company_user_quiz';
        },

        isWrapperSelected() {
            let ret = 0;
            if (this.aActionIds.every((actionId) => this.selectedActionList.includes(actionId))) {
                ret = 1;
            } else if (this.aActionIds.some((actionId) => this.selectedActionList.includes(actionId))) {
                ret = 2;
            }
            return ret;
        },

        nbDisplayTasks() {
            if (this.isHistory) {
                return this.actions.filter((t) => t.company_user_program_task.status === CompanyUserProgramTask.STATUS_DONE).length;
            }
            return this.actions.filter((t) => t.company_user_program_task.status === CompanyUserProgramTask.STATUS_PENDING).length;
        },
    },

    watch: {
        'shared.dashboard.removeIncrementTask': function () {
            this.isToggled = false;
        },
    },

    created() {
        if (this.resource != 'company_requirement_category' && this.actions.length === 1) this.isToggled = true;
    },

    methods: {
        onCollapse() {
            this.isToggled = !this.isToggled;
        },

        onSelectedAction() {
            if (this.aActionIds.every((actionId) => this.selectedActionList.includes(actionId))) {
                this.aActionIds.forEach((actionId) => {
                    this.selectedActionList.splice(this.selectedActionList.indexOf(actionId), 1);
                });
            } else {
                this.aActionIds.forEach((actionId) => {
                    if (!this.selectedActionList.includes(actionId)) {
                        this.selectedActionList.push(actionId);
                    }
                });
            }
        },
    },
};
</script>
<style lang="scss" src="./ListActionsByActionItem.scss" scoped></style>
