<template>
    <video
        v-if="src"
        controls
        controlsList="nodownload"
        width="100%"
        @click.stop
    >
        <source :src="src">
    </video>
</template>

<script>
import CompanyUserTraining from '@/models/CompanyUserTraining';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';

export default {
    name: 'UserTrainingVideo',
    props: {
        company_user_document_id: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            src: null,
        };
    },

    created() {
        const UserDocument = new CompanyUserTraining([
            'id',
            'company_file_id',
        ]).with({
            companyFile: (query) => {
                query.select(UtilsCompanyFile.allowedMinimumFields());
            },
        }).where([
            ['id', '=', this.company_user_document_id],
        ]);

        UserDocument.get().then(() => {
            this.src = UserDocument.company_file.link;
        });
    },
};
</script>
