<template>
    <StepperTabLayout>
        <template #main>
            <div
                class="main"
            >
                <AIForm
                    v-if="isAIEnabled"
                    ref="aiForm"
                    :settings="{hasToneMenu: false, hasMaxContentMenu: false}"
                    :suggestions="suggestions"
                    class="ai-form"
                    :is-loading="isAILoading"
                    @onAskAI="askAI"
                />
                <template v-if="requirementCategory.company_requirement.length === 0">
                    <img
                        src="/static/illustrations/Vision.svg"
                        class="mb-5 mx-auto d-block"
                    >
                    <div class="mb-2">
                        <strong><t>Nothing to display right now...</t></strong>
                    </div>
                    <div><t>To begin, add a new requirement</t> 😀</div>
                </template>
                <template v-else>
                    <SingleRequirement
                        v-for="(companyRequirement, index) in sortedByOrderCompanyRequirements"
                        :key="companyRequirement.uuid"
                        :index="index"
                        :company-requirement="companyRequirement"
                        :company-requirement-category="requirementCategory"
                        :dynamic-document-tooltip="getTooltip(companyRequirement)"
                        :disabled="!requirementCategory.resource.is_editable"
                        @on-cancel="cancel"
                        @on-save-requirement="onSaveRequirement"
                        @on-delete-requirement="onDeleteRequirement"
                    />
                </template>
                <Button
                    v-if="requirementCategory.resource.is_editable && !hasDynamicDocument && !hasDirtyRequirement"
                    class="my-5 button-container negative"
                    @click="addRequirement"
                >
                    <t>Add a requirement</t>
                </Button>
            </div>
        </template>
        <template
            v-if="requirementCategory.company_requirement.length > 0"
            #buttonsFooter
        >
            <div class="button-container">
                <Button
                    class="negative"
                    :state="buttonState"
                    @click="$emit('goTo', 'targets')"
                >
                    <t>Back</t>
                </Button>
                <Button
                    v-if="canSave"
                    :state="buttonState"
                    @click="onSave"
                >
                    <t>Save</t>
                </Button>
            </div>
        </template>
    </StepperTabLayout>
</template>

<script>
import StepperTabLayout from '@/components/globals/StepperTabLayout.vue';
import cloneDeep from 'lodash.clonedeep';
import api from '@/store/api';
import { mapState } from 'vuex';
import AIForm from '@/components/globals/AI/AIForm.vue';
import AIRequirementService from '@/services/AIRequirementService';
import SingleRequirement from './singleRequirement/SingleRequirement.vue';

export default {
    name: 'RequirementFormContent',
    components: {
        AIForm,
        SingleRequirement,
        StepperTabLayout,
    },
    props: {
        showListOnEnd: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            mode: null,
            isAILoading: false,
            currentItem: null,
            currentItemIndex: null,
            currentItemOrder: null,
            editingLang: this.shared.session.company.company_language.key,
            currentLanguage: this.shared.session.company.company_language,
            companyLanguage: this.shared.session.company.company_languages,
            suggestions: [
                { value: this.translate('Arrival of a new employee') },
            ],
        };
    },

    computed: {
        ...mapState('stepperform', ['loading']),

        isAIEnabled() {
            const settings = this.shared.session.company.company_knowledge_settings;

            return settings.is_ai_feature_enabled === true
                && settings.is_ai_bot_enabled === true;
        },

        requirementCategory: {
            set(requirementCategory) {
                this.$store.dispatch('stepperform/setItem', requirementCategory);
            },
            get() {
                const category = cloneDeep(this.$store.state.stepperform.item);
                this.fixOrders(category.company_requirement);

                return category;
            },
        },

        canSave() {
            const haveDirtyRequirement = this.requirementCategory.company_requirement.some((requirement) => requirement.isDirty);
            return this.requirementCategory.resource.is_editable && !haveDirtyRequirement;
        },

        hasDynamicDocument() {
            return this.sortedByOrderCompanyRequirements.some((requirement) => requirement.type === 'dynamic_document');
        },

        hasDirtyRequirement() {
            return this.requirementCategory.company_requirement.some((r) => r.isDirty);
        },

        sortedByOrderCompanyRequirements() {
            return this.requirementCategory.company_requirement
                .sort((a, b) => a.order - b.order)
                .map((r) => ({ ...r, uuid: Math.random(9999) }));
        },

        buttonState() {
            return this.loading ? 'loading' : 'idle';
        },
    },

    mounted() {
        if (!this.isAIEnabled) {
            this.mode = 'builder';
        }
    },

    methods: {
        getTooltip(requirement) {
            if (!requirement.use_in_dynamic_document_list_name?.length) {
                return null;
            }

            const base = requirement.use_in_dynamic_document_list_name.length > 1
                ? this.translate("This requirement it's used in the dynamic document of the following requirement categories")
                : this.translate("This requirement it's used in the dynamic document of the following requirement category");

            const categoryNames = requirement.use_in_dynamic_document_list_name
                .map((name) => name[this.editingLang])
                .join(', ');

            return `${base} : ${categoryNames}.`;
        },
        addRequirement() {
            if (!this.requirementCategory.resource.is_editable) {
                return;
            }

            const item = this.initRequirementObject();

            const maxExistingOrder = this.requirementCategory.company_requirement.reduce((max, current) => {
                const currentOrder = current.order;
                return Math.max(max, currentOrder);
            }, -1);

            item.order = maxExistingOrder + 1;

            this.requirementCategory.company_requirement.push(item);
            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        processAIResponse(item) {
            const maxExistingOrder = this.requirementCategory.company_requirement.reduce((max, current) => {
                const currentOrder = current.order;
                return Math.max(max, currentOrder);
            }, -1);

            item.order = maxExistingOrder + 1;

            this.requirementCategory.company_requirement.push(item);

            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        async askAI(params) {
            this.requirementCategory.company_requirement = [];
            this.isAILoading = true;

            try {
                const aiService = new AIRequirementService(this.$env, this.shared.session.capiToken);

                await aiService.ask(params.input, params.tone, params.maxContent, params.languages, this.processAIResponse);
            } catch (error) {
                console.error('Error', error);
            } finally {
                if (this.$refs.aiForm) {
                    this.isAILoading = false;
                }
            }
        },

        initRequirementObject() {
            return {
                isDirty: true,
                tags: [],
                question_multiple_value: [],
                type: 'text',
                is_heyteam: 0,
                is_mandatory: true,
                media: ['image', 'pdf', 'office'],
                file_max_size: null,
                order: 0,
                can_fill_document: false,
                dynamic_documents: [],
                translations: {
                    name: {},
                    question_multiple_value: {},
                    image: {},
                },
                file_translations: {},
                config_value: null,
            };
        },

        async onSave() {
            this.$store.dispatch('stepperform/enableLoading');

            api.configuration.requirementCategory
                .update(this.requirementCategory.id, this.requirementCategory)
                .then((res) => {
                    this.$store.dispatch('stepperform/setItem', res.data.data);
                    this.$store.dispatch('stepperform/disableLoading');

                    if (this.requirementCategory.display_survey) {
                        if (this.showListOnEnd) {
                            this.$router.push({ name: 'ResourcesRequirements' });
                            return;
                        }

                        this.$emit('onSave');
                    }

                    this.$emit('goTo', 'planning');
                }).catch((error) => {
                    this.$Notifier('App').showError(this.translate(error.response.data.message));
                    this.$store.dispatch('stepperform/disableLoading');
                });
        },

        cancel(index) {
            this.requirementCategory.company_requirement.splice(index, 1);
            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        onSaveRequirement(item, index) {
            if (typeof index === 'number') {
                item.order = this.processOrdersUpdate(
                    item.order,
                    this.currentItemOrder,
                    this.requirementCategory.company_requirement,
                );

                this.requirementCategory.company_requirement.splice(index, 1);
                this.requirementCategory.company_requirement.push(item);
                this.$store.dispatch('stepperform/setItem', this.requirementCategory);
            } else {
                item.order = this.processOrdersStore(
                    item.order,
                    this.requirementCategory.company_requirement,
                );

                this.requirementCategory.company_requirement.push(item);
                this.$store.dispatch('stepperform/setItem', this.requirementCategory);
            }
        },

        onDeleteRequirement(item, index, hardDeletedRequirementId) {
            // adjust orders
            const requirements = this.requirementCategory.company_requirement.filter(
                (companyRequirement) => companyRequirement.order > item.order,
            );

            requirements.forEach((requirement) => {
                requirement.order -= 1;
            });

            // set current item to null
            this.currentItem = null;

            // Si le requirement est hardDelete, alors on le push dans le tableau hard_deleted_requirements_ids.
            if (hardDeletedRequirementId) {
                this.requirementCategory.hard_deleted_requirements_ids.push(
                    hardDeletedRequirementId,
                );
            }

            // delete item from array
            this.requirementCategory.company_requirement.splice(index, 1);
            this.$store.dispatch('stepperform/setItem', this.requirementCategory);
        },

        /**
         * Process orders when updating a new requirement, and return the new order of the companyRequirement
         * @param {number} toOrder
         * @param {number} fromOrder
         * @param {CompanyRequirementCollection} companyRequirementCollection
         * @param {CompanyRequirement} item
         * @returns {number}
         */
        processOrdersUpdate(
            toOrder,
            fromOrder,
            companyRequirementCollection,
        ) {
            let newOrder = null;
            if (toOrder === fromOrder) {
                newOrder = toOrder;
            } else {
                // requirements where order >= new order
                let requirements = companyRequirementCollection
                    .filter((companyRequirement) => companyRequirement.order >= toOrder)
                    .filter((companyRequirement) => companyRequirement.order < fromOrder);

                requirements.forEach((requirement) => {
                    requirement.order += 1;
                });

                // requirements where order < new order
                requirements = companyRequirementCollection
                    .filter((companyRequirement) => companyRequirement.order > fromOrder)
                    .filter((companyRequirement) => companyRequirement.order < toOrder);

                requirements.forEach((requirement) => {
                    requirement.order -= 1;
                });

                newOrder = fromOrder < toOrder ? toOrder - 1 : toOrder;
            }

            return newOrder;
        },

        /**
         * Ensure that every order is unique and follow the previous one strictly by 1
         */
        fixOrders(requirements) {
            let previousOrder = 0;
            for (const index in requirements.sort((a, b) => a.order - b.order)) {
                if (index > 0) {
                    if (requirements[index].order != previousOrder + 1) {
                        requirements[index].order = previousOrder + 1;
                    }
                    previousOrder = requirements[index].order;
                }
            }
        },

        /**
         * Process orders when adding a new requirement, and return the new order of the companyRequirement
         * @param {number} toOrder
         * @param {CompanyRequirement[]} companyRequirements
         * @returns {number} newOrder: le nouvel order du requirement
         */
        processOrdersStore(toOrder, companyRequirements) {
            let newOrder = null;

            // requirements where order >= new order
            const requirements = companyRequirements.filter(
                (companyRequirement) => companyRequirement.order >= toOrder,
            );

            requirements.forEach((requirement) => {
                requirement.order += 1;
            });

            newOrder = toOrder;

            return newOrder;
        },
    },
};
</script>

<style lang="scss" scoped src="./RequirementFormContent.scss"></style>
