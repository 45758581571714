import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUserProgramTaskValidation from '@/models/CompanyUserProgramTaskValidation';
import CompanyUser from './CompanyUser';
import CompanyUserProgramTaskCollection from './CompanyUserProgramTaskCollection';
import CompanyUserProgramTaskParticipant from './CompanyUserProgramTaskParticipant';
import CompanyUserTraining from './CompanyUserTraining';
import CompanyUserDoc from './CompanyUserDoc';
import CompanyUserSoftware from './CompanyUserSoftware';
import CompanyUserEquipment from './CompanyUserEquipment';
import CompanyPlanningEvent from './CompanyPlanningEvent';
import CompanyUserRequirementCategory from './CompanyUserRequirementCategory';
import I18n from '../modules/i18n/I18n';
import CompanyUserProgram from './CompanyUserProgram';
import CompanyUserNudge from './CompanyUserNudge';
import CompanyUserQuiz from './CompanyUserQuiz';
import CompanyUserEmailCustom from './CompanyUserEmailCustom';
import CompanyUserSurvey from './CompanyUserSurvey';
import CompanyUserRecurrence from './CompanyUserRecurrence';

export default class CompanyUserProgramTask extends Model {
    // status
    static STATUS_DONE = 'done';

    static STATUS_DRAFT = 'draft';

    static STATUS_PENDING = 'pending';

    static STATUS_CANCELLED = 'cancelled';

    static STATUS_AWAITING = 'awaiting';

    static VALIDATOR_TYPE_HUMAN = 'human';

    static VALIDATOR_TYPE_SYSTEM = 'system';

    // Resource types
    static TASK_COMPANY_PLANNING_EVENT = 'company_planning';

    static TASK_COMPANY_USER_DOC = 'company_doc'; // documents

    static TASK_COMPANY_USER_DOCUMENT = 'company_document'; // training

    static TASK_COMPANY_USER_EMAIL_CUSTOM = 'company_email_custom';

    static TASK_COMPANY_USER_EQUIPMENT = 'company_equipment';

    static TASK_COMPANY_USER_NUDGE = 'company_nudge';

    static TASK_COMPANY_USER_QUIZ = 'company_user_quiz';

    static TASK_COMPANY_USER_REQUIREMENT_CATEGORY = 'company_requirement_category';

    static TASK_COMPANY_USER_SOFTWARE = 'company_software';

    static TASK_COMPANY_USER_SURVEY = 'company_survey';

    static TASK_CUSTOM = 'custom';

    modelConfig() {
        return {
            collection: CompanyUserProgramTaskCollection,
        };
    }

    modelDefaultValues() {
        return {
            company_user_program_key_date_id: null,
            offset_availability_number: 0,
            offset_availability_unit: 'immediately',
            offset_end_number: 0,
            offset_end_unit: 'days',
            is_business_day: false,
            datetime_availability: null,
            datetime_end: null,
        };
    }

    modelCustomAttributes() {
        return {
            is_grouped: false,
            is_checked: false,
            groupedTasks: [],
            dependenciesTasks: [],
            dependentId: null,
            statusText: null,
        };
    }

    modelAccessors() {
        return {
            statusText: () => {
                if (this.status) {
                    switch (this.status) {
                    case CompanyUserProgramTask.STATUS_DONE:
                        return I18n.translate('Done');
                    case CompanyUserProgramTask.STATUS_PENDING:
                        return I18n.translate('Pending');
                    case CompanyUserProgramTask.STATUS_DRAFT:
                        return I18n.translate('Draft');
                    default:
                        return '';
                    }
                }
            },
        };
    }

    modelRelations() {
        return {
            companyPlanningEvent: () => this.belongsTo(CompanyPlanningEvent, 'resource_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            companyUserDoc: () => this.belongsTo(CompanyUserDoc, 'resource_id', 'id'),
            companyUserDocument: () => this.belongsTo(CompanyUserTraining, 'resource_id', 'id'),
            companyUserEmailCustom: () => this.belongsTo(CompanyUserEmailCustom, 'resource_id', 'id'),
            companyUserEquipment: () => this.belongsTo(CompanyUserEquipment, 'resource_id', 'id'),
            companyUserNudge: () => this.belongsTo(CompanyUserNudge, 'resource_id', 'id'),
            companyUserProgram: () => this.belongsTo(CompanyUserProgram, 'company_user_program_id', 'id'),
            companyUserProgramTaskParticipant: () => this.hasMany(CompanyUserProgramTaskParticipant, 'company_user_program_task_id', 'id'),
            companyUserQuiz: () => this.belongsTo(CompanyUserQuiz, 'resource_id', 'id'),
            companyUserRequirementCategory: () => this.belongsTo(CompanyUserRequirementCategory, 'resource_id', 'id'),
            companyUserSoftware: () => this.belongsTo(CompanyUserSoftware, 'resource_id', 'id'),
            companyUserSurvey: () => this.belongsTo(CompanyUserSurvey, 'resource_id', 'id'),
            dependencies: () => this.hasMany(CompanyUserProgramTask, 'company_user_program_task_id', 'id'),
            dependents: () => this.hasMany(CompanyUserProgramTask, 'dependency_id', 'id'),
            validation: () => this.hasOne(CompanyUserProgramTaskValidation),
            participants: () => this.hasMany(CompanyUserProgramTaskParticipant, 'company_user_program_task_id', 'id'),
            recurrence: () => this.belongsTo(CompanyUserRecurrence, 'company_user_recurrence_id', 'id'),
        };
    }

    getWrapTaskTitle() {
        switch (this.resource) {
        case CompanyUserProgramTask.TASK_COMPANY_USER_DOCUMENT:
            return I18n.translate('Complete all of the following training');

        case CompanyUserProgramTask.TASK_COMPANY_USER_EQUIPMENT:
            return I18n.translate('Install all of the following equipment');

        case CompanyUserProgramTask.TASK_COMPANY_USER_SOFTWARE:
            return I18n.translate('Install all of the following software');

        case CompanyUserProgramTask.TASK_COMPANY_USER_REQUIREMENT_CATEGORY:
            return I18n.translate('Administrative file to be completed');
        }
    }
}
