<template>
    <MessageTextTemplate
        :text="text"
        :is-me="isMe"
    />
</template>

<script>
import CompanyChatChannelMessage from '@/models/CompanyChatChannelMessage';
import MessageTextTemplate from './MessageTextTemplate.vue';

export default {
    name: 'MessageResourceUserSurveyExtra',
    components: {
        MessageTextTemplate,
    },
    props: {
        companyChatChannelMessage: {
            type: CompanyChatChannelMessage,
            required: true,
        },
    },
    computed: {
        text() {
            return this.companyChatChannelMessage.company_user_survey_extra.company_survey_extra.text;
        },
        isMe() {
            return this.shared.session.companyUser.id === this.companyChatChannelMessage.company_user_id;
        },
    },
};
</script>
