<template>
    <div
        class="cursor-pointer"
        @click.stop="downloadFile"
    >
        <DSIconDownload />
    </div>
</template>

<script>
import DSIconDownload from '@/components/design-system/icons/System/DSIconDownload.vue';

export default {
    name: 'DownloadIntegrationReport',
    components: { DSIconDownload },
    mixins: [],
    props: {
        fileUrl: {
            type: String,
            required: true,
        },
    },
    methods: {
        downloadFile() {
            window.open(this.fileUrl);
            // When CORS will be dealt with :
            // axios.get(this.fileUrl, {
            //     responseType: 'blob',
            //     headers: { Accept: '*/*' },
            // })
            //     .then((response) => {
            //         const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            //         const link = document.createElement('a');
            //         link.href = URL.createObjectURL(blob);
            //         link.download = this.fileName;
            //         link.click();
            //         URL.revokeObjectURL(link.href);
            //     }).catch(console.error);
        },
    },
};
</script>

<style scoped>

</style>
