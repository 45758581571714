import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUser from './CompanyUser';
import CompanyRole from './CompanyRole';
import CompanyPlanningEventTemplateCollection from './CompanyPlanningEventTemplateCollection';
import CompanyResource from './CompanyResource';

export default class CompanyPlanningEventTemplate extends Model {
    modelConfig() {
        return {
            collection: CompanyPlanningEventTemplateCollection,
        };
    }

    status() {
        if (!this.resource.programs.models[0].is_enabled) {
            return 'draft';
        }

        if (this.is_delegated) {
            return 'delegated';
        }

        return 'scheduled';
    }

    modelDefaultValues() {
        return {
            email_sending_offset: 0,
            timezone: 'Europe/Paris',
        };
    }

    modelRelations() {
        return {
            resource: () => this.hasOne(CompanyResource),
            organiserUser: () => this.belongsTo(CompanyUser, 'organiser_user_id', 'id'),
            organiserRole: () => this.belongsTo(CompanyRole, 'organiser_role_id', 'id'),
        };
    }
}
