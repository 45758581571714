<template>
    <div>
        {{ getPhone }}
    </div>
</template>

<script>

export default {
    name: 'UserRequirementPhoneRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
    },
    computed: {
        getPhone() {
            if (!this.userRequirement.value) {
                return null;
            }

            const phone = JSON.parse(this.userRequirement.value);

            return phone.phone_country_code + phone.phone_number;
        },
    },
};
</script>
