<template>
    <div>
        <div>{{ userRequirement.value.owner }}</div>
        <div>{{ userRequirement.value.iban }}</div>
        <div>{{ userRequirement.value.bic }}</div>
    </div>
</template>

<script>

export default {
    name: 'UserRequirementIbanRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" src="./UserRequirementIbanEdit.scss" />
