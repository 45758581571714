<template>
    <div
        class="notifier"
        :class="[alignment, {'silent': silent}]"
    >
        <transition-group name="fade">
            <div
                v-for="(item, index) in notification"
                v-show="item.visible"
                :key="index"
                class="notification"
                :class="item.type"
            >
                <IconChecked
                    v-if="item.type === 'info'"
                    class="margin-right"
                />
                <IconError
                    v-else-if="item.type === 'error'"
                    class="margin-right"
                />
                {{ item.message }}

                <FontAwesomeIcon
                    v-if="item.type === 'error'"
                    size="2x"
                    :icon="['fal', 'times']"
                    transform="shrink-9"
                    class="branding-color close"
                    @click="item.visible = false"
                />
            </div>
        </transition-group>
    </div>
</template>

<script>
import NotifierMixin from '@/components/tools/Notifications/NotifierMixin';

export default {
    name: 'Notifier',

    mixins: [NotifierMixin],

    data() {
        return {
            alignment: 'left',
        };
    },
};
</script>

<style lang="scss" scoped>
	.silent {
		display: none !important;
	}
	.notifier{
		position: fixed;
        bottom: 10vh;
		z-index: 9999;
		display: flex;
		flex-direction: column;
		.notification{
            border-radius: 8px;
            height: 5vh;
            box-shadow: 0 1px 2px rgba(60,64,67, 0.3), 0 1px 3px 1px rgba(60,64,67, 0.15);
			position: relative;
			display: flex;
			align-items: center;
			padding: 25px;
            padding-right: 40px;
			margin-bottom: 0.5em;

            .icon-valid, .icon-error{
				margin: 0 30px 0 0;
				height: 37px;
				width: 37px;
			}
			&.info{
				background: white;
				color: black;
			}
			&.error {
				background: white;
				color: black;
			}
			&.warning {
				background: white;
				color: black;
			}
		}
	}

	.close {
		position: absolute;
        right: 0;
	    left: unset !important;
		width: 42px;
		height: 42px;
        cursor: pointer;
	}

    .margin-right {
        margin-right: 10px;
    }
    .right {
        right: 4vw;
    }

    .left {
        left: 4vw;
    }

</style>
