<template>
    <ParticipantList
        :users="participants"
        has-roles
        :limit="3"
    />
</template>

<script>
import ParticipantList from '@/components/globals/ParticipantList.vue';

export default {
    name: 'TableCellParticipants',

    components: {
        ParticipantList,
    },

    props: {
        participants: {
            type: Array,
            required: true,
        },
    },
};
</script>
