<template>
    <HtModal ref="modal">
        <template #header>
            <t>Transfer ownership</t>
        </template>
        <template #default>
            <p class="mb-20 align-center">
                <t>Transferring ownership is a one-way street.</t><br>
                <t>You cannot undo this, and the transfer is immediate.</t><br>
                <t
                    :url="shared.session.company.url"
                    :fullname="newOwner.fullname"
                >
                    {fullname} will have ultimate authority over {url}
                </t>
            </p>

            <HtFormInput
                :id="'newPassword'"
                v-model="password"
                :name="'newPassword'"
                :label="translate('Your password')"
                :class="'full'"
                :type="'password'"
                :validate="'required'"
                :validate-as="translate('password')"
            />
        </template>
        <template #footer>
            <div class="d-flex justify-content-center">
                <Button
                    style="width: 70%"
                    @click="onTransferOwnership()"
                >
                    <t>Transfer</t>
                </Button>
            </div>
        </template>
    </HtModal>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';

export default {
    name: 'TransfertOwnershipModal',
    props: {
        newOwner: {
            type: CompanyUser,
            required: true,
        },
    },

    data() {
        return {
            password: '',
        };
    },

    methods: {
        open() {
            this.$refs.modal.open();
        },

        onTransferOwnership() {
            const companyUser = new CompanyUser(['id', 'password']);

            companyUser.fill({
                id: this.newOwner.id,
                password: this.password,
            });

            companyUser.save({ method: 'transferOwnership' }).then(() => {
                this.$emit('on-transfer-ownership');
                this.$refs.modal.close();
            });
        },
    },
};

</script>
