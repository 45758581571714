<template>
    <div
        :class="['item', {'selected': selected}]"
        @click="onClick"
    >
        <slot />
    </div>
</template>
<script>
export default {
    name: 'ListItem',
    props: {
        selected: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        onClick() {
            this.$emit('on-click');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.item {
    height: 80px;
    cursor: pointer;
    background: $white;
    border: 1px solid $grey-ultra-light;
    border-radius: $border-radius-5;
    padding-left: 20px;
    padding-right: 20px;

    &:hover, &.selected{
        border: 1px solid $primary;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    }
}
</style>
