import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyRoleUser from './CompanyRoleUser';

export default class CompanyRoleUserCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyRoleUser,
        };
    }
}
