<template>
    <span
        class="tag"
        :class="resource"
    >
        <FontAwesomeIcon
            :icon="getResourceIcon"
            class="icon"
        />
        <t v-if="label"> {{ label }} </t>
    </span>
</template>

<script>
import ResourcesInfo from '@/ResourcesInfo';

export default {
    name: 'HtResourceTag',
    props: {
        resource: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
    },

    computed: {
        getResourceIcon() {
            return ResourcesInfo.getResourceIcon(this.resource);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/ressources';
.tag {
    font-size: 12px;
    line-height: 100%;
    padding: 6px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .icon{
        height: 12px;
        width: 12px;
    }

    &.company_program_task {
        color: $mustard-base;
        background-color: $mustard-light;
    }

    &.company_document {
        color: $orange-base;
        background-color: $orange-light;
    }

    &.company_nudge {
        color: $pink-base;
        background-color: $pink-light;
    }

    &.company_shared_document {
        color: $purple-base;
        background-color: $purple-light;
    }

    &.company_survey {
        color: $olive-base;
        background-color: $olive-light;
    }

    &.company_quiz {
        color: $teal-base;
        background-color: $teal-light;
    }
    &.company_software {
        color: $sand-base;
        background-color: $sand-light;
    }
    &.company_equipment {
        color: $blue-base;
        background-color: $blue-light;
    }
    &.company_requirement_category {
        color: $red-base;
        background-color: $red-light;
    }
    &.company_planning {
        color: $brown-base;
        background-color: $brown-light;

    }
    &.company_email_custom_template {
        color: $mint-base;
        background-color: $mint-light;

    }
}

</style>
