<template>
    <div>
        <form
            v-if="item.isLoaded()"
            class="form-2 spaced"
            @submit.prevent=""
        >
            <div class="modal-box">
                <CardList
                    :options="{
                        perRow: 2,
                    }"
                >
                    <Card
                        v-for="(type, index) in typeFiles"
                        :key="index"
                        :options="{
                            type: 'long',
                            perRow: 2,
                        }"
                        :class="{ selected: item.type === type.type }"
                        @click="changeTo(type.type)"
                    >
                        <div slot="image">
                            <div class="item-image image-small">
                                <div
                                    class="small-image"
                                    :style="{'background-image': `url(${type.img})`}"
                                />
                            </div>
                        </div>
                        <div slot="title">
                            <t>{{ type.label }}</t>
                        </div>
                    </Card>
                </CardList>

                <!-- FILE -->
                <div v-if="item.type === 'file'">
                    <transition name="fade">
                        <div>
                            <fieldset>
                                <div class="bg-only training-type">
                                    <div class="description-text">
                                        <t>
                                            Upload any document you want, high
                                            resolution image (PNG, JPG, GIF), video (mp4, ogg, webm) or
                                            text document (doc,dox,pdf...)
                                        </t>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset :class="{ error: errors.has('file') }">
                                <label><t>Upload file</t> <span style="font-size:1rem; color:grey">(<t>Up to 250 MB</t>)</span></label>

                                <UploadBar
                                    v-model="item.company_file"
                                    :media="['office', 'pdf', 'image', 'video']"
                                    :max-size="250"
                                    @onUploadProgress="button_state = 'loading'"
                                    @onUploadEnd="button_state = 'idle'"
                                />

                                <input
                                    v-validate.disable="'required'"
                                    type="hidden"
                                    name="file"
                                    :value="item.company_file && item.company_file.original_name"
                                    :data-vv-as="translate('file')"
                                >

                                <div
                                    v-for="(errorMessage, index) in errors.collect('file')"
                                    :key="index"
                                    class="error-message"
                                >
                                    <t>{{ errorMessage }}</t>
                                </div>
                            </fieldset>
                        </div>
                    </transition>
                </div>

                <!-- LINK -->
                <div v-if="item.type === 'link'">
                    <transition name="fade">
                        <div>
                            <InputField
                                v-model="item.url"
                                type="text"
                                :label="translate('Url Youtube or Vimeo')"
                                :name="'url'"
                                :placeholder="translate('Write the document url')"
                                :validate="'required|url'"
                                :validate-as="translate('link')"
                            />
                        </div>
                    </transition>
                </div>

                <HtFormSwitch
                    v-if="item.type && (item.type === 'link' || item.type === 'file' && item.company_file && videoExtension.includes(item.company_file.detected_extension))"
                    :id="'toggle-is-landing'"
                    v-model="item.is_landing"
                    :label="translate('Video intro')"
                    :on-label="translate('On')"
                    :off-label="translate('Off')"
                    :name="'toggle-is-landing'"
                    :show-optional="false"
                />
            </div>
        </form>

        <div class="modal-actions right">
            <Button
                v-show="!item.isNew()"
                class="negative"
                @click="onDelete()"
            >
                <t>Delete</t>
            </Button>
            <Button
                v-if="item.type"
                :state="button_state"
                @click="onValidate()"
            >
                {{ labelButton }}
            </Button>
        </div>
    </div>
</template>

<script>
import CompanyMediaCollection from '@/models/CompanyMediaCollection';
import CompanyMedia from '@/models/CompanyMedia';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';

export default {
    name: 'MediaEdit',
    components: { HtFormSwitch },

    inject: ['modal'],

    props: {
        value: { type: CompanyMedia },
        collection: { type: CompanyMediaCollection, required: true },
    },

    data() {
        return {
            item: null,
            videoExtension: ['mp4', 'ogg', 'webm'],
            button_state: 'idle',
            typeFiles: [
                {
                    img: '/static/icons/photo.svg',
                    type: 'file',
                    label: this.translate('File'),
                },
                {
                    img: '/static/icons/video.svg',
                    type: 'link',
                    label: 'Url',
                },
            ],
        };
    },

    computed: {
        CompanyMedia: () => CompanyMedia,

        labelTitle() {
            return this.translate(this.item.isNew() ? 'Add Photo or Video' : 'Edit Photo or Video');
        },

        labelButton() {
            return this.translate(this.item.isNew() ? 'Add' : 'Save');
        },
    },

    watch: {
        'item._state.isSaving': function (val) {
            this.button_state = val ? 'loading' : 'idle';
        },
    },

    created() {
        if (this.value) this.item = this.value.fromSaveState();
        else this.item = this.collection.new();

        this.modal.setTitle(this.labelTitle);
    },

    methods: {
        changeTo(type) {
            this.item.type = type;

            if (type != 'file') this.item.company_file_id = null;
            else this.item.url = '';
        },

        async onValidate() {
            if (!await this.$validator.validateAll()) return;

            await this.item.save();

            this.modal.close();
        },

        async onDelete() {
            if (await this.modal.deleteWindow({ content: this.translate('Are you sure you want to delete this media? Once you click on delete, you will no longer be able to access this media.') })) {
                await this.item.delete();
                this.modal.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
	@import "~@/styles/var";

	.input-removable {
		display: flex;
		justify-content: space-between;
		align-items: center;

		input {
			width: calc(100% - 35px);
		}
	}

	.add-options {
		text-align: right;
		text-decoration: underline;
		font-family: $lato-bold;
	}

</style>
