import CompanyEmailCustomTemplate from '@/models/CompanyEmailCustomTemplate';
import CompanyEquipment from '@/models/CompanyEquipment';
import CompanyNudge from '@/models/CompanyNudge';
import CompanyPlanningEventTemplate from '@/models/CompanyPlanningEventTemplate';
import CompanyProgramTask from '@/models/CompanyProgramTask';
import CompanyQuiz from '@/models/CompanyQuiz';
import CompanyRequirementCategory from '@/models/CompanyRequirementCategory';
import CompanySharedDocument from '@/models/CompanySharedDocument';
import CompanySoftware from '@/models/CompanySoftware';
import CompanySurvey from '@/models/CompanySurvey';
import CompanyTraining from '@/models/CompanyTraining';

export default {
    props: {
        id: {
            type: [String, Number],
            required: false,
        },
        companyProgramId: {
            type: Number,
            required: false,
        },
        showButtonDelete: {
            type: Boolean,
            default: true,
        },
        isDuplicableModel: {
            type: Boolean,
            default: false,
        },
        disabledProgramList: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        modelName() {
            return '';
        },

        modalResourceName() {
            return '';
        },

        hasChildren() {
            return this.item.resource_program.models[0].dependencies && this.item.resource_program.models[0].dependencies.models.length > 0;
        },

        hasParent() {
            return this.item.resource_program.models[0].dependents && this.item.resource_program.models[0].dependents.models.length > 0;
        },

        labelTitle() {
            return this.translate(this.item.isNew() ? `Add ${this.modalResourceName}` : `Edit ${this.modalResourceName}`);
        },

        labelButton() {
            return this.translate(this.item.isNew() ? 'Add' : 'Save');
        },

        canDelete() {
            return this.showButtonDelete === true && !this.item.isNew();
        },
    },

    watch: {
        'item._state.isSaving': function (val) {
            this.buttonState = val ? 'loading' : 'idle';
        },
    },

    methods: {
        getClassName() {
            const modelClasses = {
                CompanyRequirementCategory,
                CompanySurvey,
                CompanySharedDocument,
                CompanyTraining,
                CompanyQuiz,
                CompanyEmailCustomTemplate,
                CompanyProgramTask,
                CompanyNudge,
                CompanySoftware,
                CompanyEquipment,
                CompanyPlanningEventTemplate,
            };

            if (this.modelName in modelClasses) {
                return modelClasses[this.modelName];
            }
            throw 'wrong model name';
        },

        createModelFromCollection() {
            this.item = this.collection.new();
            this.item.company_program_id = this.companyProgramId;
        },

        createModelFromId() {
            const className = this.getClassName();
            // eslint-disable-next-line new-cap
            this.item = new className(this.itemFields).with(this.itemRelations);
            this.item.where([
                ['id', '=', this.id],
            ]);

            return new Promise((resolve, reject) => {
                this.item.get().then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
            });
        },

        duplicateModel() { },
    },

    async created() {
        if (this.isDuplicableModel) {
            this.createModelFromCollection();
            this.duplicateModel();
        } else if (this.value && !this.isDuplicableModel) {
            this.item = this.value.fromSaveState();
        } else if (this.id) {
            await this.createModelFromId();
        } else {
            this.createModelFromCollection();
            this.setDefaultOrderCompletion();

            if ('resource_program' in this.item) {
                const resourceProgram = this.item.resource_program.new();
                resourceProgram.company_program_id = this.companyProgramId;
                this.item.resource_program.add(resourceProgram);
            }
        }

        this.modal.setTitle(this.labelTitle);
    },

    data() {
        return {
            item: null,
            buttonState: 'idle',
            itemFields: [],
            itemRelations: {},
        };
    },
};
