<template>
    <div class="message-item">
        <div class="message-item-title">
            <t>New survey</t>
        </div>
        <div
            class="message-item-button"
            :class="customClass"
            @click="start"
        >
            <div class="message-item-button-icon branding-color">
                <img
                    width="32"
                    src="/static/icons/survey/chat/feedback.svg"
                >
            </div>
            <div class="message-item-button-name">
                {{ userSurvey.name }}
            </div>
        </div>
    </div>
</template>

<script>

import CompanyChatChannelMessage from '../../../models/CompanyChatChannelMessage';

export default {
    name: 'MessageResourceUserSurvey',
    props: {
        companyChatChannelMessage: {
            type: CompanyChatChannelMessage,
            required: true,
        },
        allChannelMessage: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isInProgress: false,
        };
    },
    computed: {
        userSurvey() {
            return this.companyChatChannelMessage.company_user_survey;
        },
        customClass() {
            return {
                'cursor-pointer': this.userSurvey && this.userSurvey.company_user_program_task.status === 'pending' && !this.isInProgress && !this.hasStarted,
            };
        },
        hasStarted() {
            const channelMessageQuestion = this.allChannelMessage.filter((m) => m.resource === 'company_user_survey_question');
            return channelMessageQuestion.length > 0;
        },
    },
    methods: {
        start() {
            if (this.isInProgress) return;

            if (this.hasStarted) {
                this.isInProgress = true;
                return;
            }

            if (this.userSurvey.company_user_program_task.status !== 'pending') return;

            this.isInProgress = true;

            this.userSurvey.save({ method: 'startSurvey' });
        },
    },
};
</script>

<style lang="scss" scoped src="./MessageResourceUserSurvey.scss"></style>
