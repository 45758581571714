<template>
    <div>
        <HtTabs class="ai-page">
            <HtTab :title="translate('Knowledge Custom Content')">
                <KnowledgeContent />
            </HtTab>
            <HtTab :title="translate('Knowledge Documents')">
                <KnowledgeDocument />
            </HtTab>
            <HtTab :title="translate('Settings')">
                <KnowledgeSettings />
            </HtTab>
        </HtTabs>
    </div>
</template>

<script>
import HtCard from '@/components/globals/HtCard.vue';
import HtTab from '@/components/globals/HtTab.vue';
import HtTabs from '@/components/globals/HtTabs.vue';
import KnowledgeContent from '@/router/pages/settings/AI/KnowledgeContent.vue';
import KnowledgeSettings from '@/router/pages/settings/AI/KnowledgeSettings.vue';
import KnowledgeDocument from '@/router/pages/settings/AI/KnowledgeDocument.vue';

export default {
    components: {
        KnowledgeDocument,
        KnowledgeSettings,
        KnowledgeContent,
        HtTabs,
        HtTab,
        HtCard,
    },
};
</script>
<style lang="scss">
.ai-page {
    .ht-card-body {
        padding-top: 0!important;
    }
}
</style>
