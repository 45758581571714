<template>
    <div class="list-answer">
        <div
            v-for="item in companySurveyQuestionAnswerCollection.models"
            :key="item.id"
            class="list-answer-item"
            @click="sendAnswer(item.id)"
        >
            <div
                class="list-answer-item-name"
                :class="customClass(item)"
            >
                {{ item.text }}
            </div>
        </div>
    </div>
</template>

<script>
import MessageResourceUserSurveyQuestionMixin from './mixin/MessageResourceUserSurveyQuestionMixin';

export default {
    name: 'MessageResourceUserSurveyQuestionYesNo',
    mixins: [
        MessageResourceUserSurveyQuestionMixin,
    ],
    methods: {
        customClass(companySurveyQuestionAnswer) {
            return {
                active: this.isActive(companySurveyQuestionAnswer.id),
                hover: this.readOnly === false,
            };
        },
    },
};
</script>

<style lang="scss" scoped src="./MessageResourceUserSurveyQuestionYesNo.scss"></style>
