import Model from '@tony.caron/node-model-proxy/Model';
import CompanyEntity from '@/models/CompanyEntity';
import CompanyEntityValue from '@/models/CompanyEntityValue';
import CompanyRoleUserPerimeterCollection from '@/models/CompanyRoleUserPerimeterCollection';

export default class CompanyRoleUserPerimeter extends Model {
    modelConfig() {
        return {
            collection: CompanyRoleUserPerimeterCollection,
        };
    }

    modelRelations() {
        return {
            entity: () => this.belongsTo(CompanyEntity, 'company_entity_id', 'id'),
            value: () => this.belongsTo(CompanyEntityValue, 'company_entity_value_id', 'id'),
        };
    }

    modelCustomAttributes() {
        return {
		   name: null,
		   name_translated: null,
        };
    }

    modelAccessors() {
        return {
            name: () => {
                if (this.value) {
                    switch (this.value.resourceable_type) {
                    case 'App\\CompanyOffice':
                        return this.value.office.name;
                    case 'App\\CompanyDepartment':
                        return this.value.department.name;
                    case 'App\\CompanyContract':
                        return this.value.contract.name;
                    case 'App\\CompanyJobPosition':
                        return this.value.job_position.name;
                    case 'App\\Models\\Company\\Entity\\Value\\Custom':
                        return this.value.custom.name;
                    }
                }

                return '';
            },
            name_translated: () => {
                if (this.value) {
                    switch (this.value.resourceable_type) {
                    case 'App\\CompanyOffice':
                        return this.value.office.name_translated;
                    case 'App\\CompanyDepartment':
                        return this.value.department.name_translated;
                    case 'App\\CompanyContract':
                        return this.value.contract.name_translated;
                    case 'App\\CompanyJobPosition':
                        return this.value.job_position.name_translated;
                    case 'App\\Models\\Company\\Entity\\Value\\Custom':
                        return this.value.custom.name;
                    }
                }

                return '';
            },
        };
    }
}
