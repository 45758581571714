import api from '@/store/api';
import I18n from '@/modules/i18n/I18n';
import get from 'lodash.get';

const SET_PAGINATOR = 'set_paginator';
const SET_HAS_FILTER_OFFICE = 'set_has_filter_office';
const SET_HAS_FILTER_DEPARTMENT = 'set_has_filter_department';
const SET_ENTITIES_FILTER = 'set_entities_filter';
const SET_DATES_FILTER = 'set_dates_filter';
const SET_QUERY_FILTER = 'set_query_filter';
const SET_STATUS_FILTER = 'set_status_filter';
const SET_LOADING = 'set_loading';
const CLEAR = 'clear';

const USER_STATUS = [
    { name: 'Active', slug: 'active' },
    { name: 'Draft', slug: 'draft' },
    { name: 'Cancelled', slug: 'cancelled' },
    { name: 'Inactive', slug: 'inactive' },
    { name: 'Invited', slug: 'invited' },
];

export default {
    namespaced: true,

    state() {
        return {
            filters: {
                query: null,
                entities: [],
                dates: {
                    start: null,
                    end: null,
                },
                status: [],
            },
            paginator: null,
            hasFilterOffice: false,
            hasFilterDepartment: false,
            loading: false,
        };
    },

    getters: {
        users(state) {
            return get(state.paginator, 'data', []);
        },
        paginator(state) {
            return state.paginator;
        },
        userStatus() {
            return USER_STATUS.map((status) => ({ ...status, name: I18n.translate(status.name) }));
        },
    },

    actions: {
        fetchUsers({ commit, state }, page) {
            commit(SET_LOADING, true);

            api.users.all({
                filters: state.filters,
                orderBy: 'arrival_date',
                orderDirection: 'DESC',
                useCompanyCustomize: true,
                page,
            }).then(({ data }) => {
                commit(SET_PAGINATOR, data.paginator);
                commit(SET_HAS_FILTER_OFFICE, data.hasFilterOffice);
                commit(SET_HAS_FILTER_DEPARTMENT, data.hasFilterDepartment);
            }).finally(() => {
                commit(SET_LOADING, false);
            });
        },
        setEntitiesFilter({ commit, state, dispatch }, { company_entity_id, values }) {
            const entities = state.filters.entities.filter((e) => e.company_entity_id !== company_entity_id);
            commit(SET_ENTITIES_FILTER, [...entities, ...values]);
            dispatch('fetchUsers', 1);
        },
        setDatesFilter({ commit, dispatch }, dates) {
            commit(SET_DATES_FILTER, dates);
            dispatch('fetchUsers', 1);
        },
        setQueryFilter({ commit, dispatch }, query) {
            commit(SET_QUERY_FILTER, query);
            dispatch('fetchUsers', 1);
        },
        setStatusFilter({ commit, dispatch }, status) {
            commit(SET_STATUS_FILTER, status);
            dispatch('fetchUsers', 1);
        },
    },

    mutations: {
        [SET_PAGINATOR](state, paginator) {
            state.paginator = paginator;
        },
        [SET_HAS_FILTER_OFFICE](state, hasFilterOffice) {
            state.hasFilterOffice = hasFilterOffice;
        },
        [SET_HAS_FILTER_DEPARTMENT](state, hasFilterDepartment) {
            state.hasFilterDepartment = hasFilterDepartment;
        },
        [SET_ENTITIES_FILTER](state, entities) {
            state.filters.entities = entities;
        },
        [SET_DATES_FILTER](state, dates) {
            state.filters.dates = dates;
        },
        [SET_QUERY_FILTER](state, query) {
            state.filters.query = query;
        },
        [SET_STATUS_FILTER](state, status) {
            state.filters.status = status;
        },
        [SET_LOADING](state, loading) {
            state.loading = loading;
        },
        [CLEAR](state) {
            // eslint-disable-next-line
            state = {
                filters: {
                    query: null,
                    entities: [],
                    dates: {
                        start: null,
                        end: null,
                    },
                    status: [],
                },
                paginator: null,
                hasFilterOffice: false,
                hasFilterDepartment: false,
                loading: false,
            };
        },
    },
};
