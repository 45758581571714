<template>
    <div>
        {{ getValues | empty }}
    </div>
</template>

<script>

export default {
    name: 'UserRequirementMultipleSelectRead',
    props: {
        userRequirement: {
            type: Object,
            required: true,
        },
    },

    computed: {
        getValues() {
            if (!this.userRequirement.value) {
                return [];
            }
            const items = this.userRequirement.value.filter((v) => v.selected);
            return items.length ? items.map((v) => v.value).join(', ') : '-';
        },
    },
};
</script>

<style lang='scss' src='./UserRequirementMultipleSelectEdit.scss' scoped />
