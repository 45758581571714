import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';

export default {
    methods: {
        createTaskWithDependents(item) {
            item.company_user_program_task = new CompanyUserProgramTask([
                'id',
                'resource',
                'resource_id',
                'company_user_program_key_date_id',
                'offset_availability_number',
                'offset_availability_unit',
                'offset_end_number',
                'offset_end_unit',
                'is_business_day',
            ]).with({
                dependents: (query) => {
                    query.select(['id']);
                },
            });
        },
    },
};
