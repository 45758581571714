import Vue from 'vue';

/**
 * @typedef {Object} Action
 * @property {number} id
 * @property {Resource} resource
 */

/**
 * @typedef {{data: {data: Action}}} ActionResourceResponse
 * @typedef {{data: {data: Action[]}}} ActionCollectionResponse
 */

export default {
    /**
     * @param {any} data
     * @returns {Promise<ActionCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.post('configuration/actions/list', data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<ActionResourceResponse>}
     */
    get(id, config = {}) {
        return Vue.prototype.$http.get(`configuration/actions/${id}`, config);
    },

    /**
     * @param {number|string} id
     * @param {any} config
     * @returns {Promise<ActionResourceResponse>}
     */
    replicate(id, config = {}) {
        return Vue.prototype.$http.post(
            `configuration/actions/${id}/replicate`,
            config,
        );
    },

    /**
     * @param {any} data
     * @returns {Promise<ActionResourceResponse>}
     */
    create(data = {}) {
        return Vue.prototype.$http.post('configuration/actions', data);
    },

    /**
     * @param {number|string} id
     * @param {any} data
     * @returns {Promise<ActionResourceResponse>}
     */
    update(id, data = {}) {
        return Vue.prototype.$http.post(`configuration/actions/${id}`, data);
    },

    createExport(data = {}) {
        return Vue.prototype.$http.post(
            'configuration/actions/export/create',
            data,
        );
    },
    downloadExport(data = {}) {
        return Vue.prototype.$http.post(
            'configuration/actions/export/download',
            data,
        );
    },
    import(data = {}) {
        return Vue.prototype.$http.post(
            'configuration/actions/import',
            data,
        );
    },
};
