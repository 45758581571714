import Model from '@tony.caron/node-model-proxy/Model';
import I18n from '@/modules/i18n/I18n';
import Notifier from '@/Notifier';
import CompanyProgramResourceFilter from './CompanyProgramResourceFilter';
import CompanyProgram from './CompanyProgram';
import CompanySoftwareCollection from './CompanySoftwareCollection';
import CompanyResource from './CompanyResource';

export default class CompanySoftware extends Model {
    modelConfig() {
        return {
            primaryKey: 'id',
            controller: 'CompanySoftwareController',
            collection: CompanySoftwareCollection,
        };
    }

    modelCustomAttributes() {
        return {
            api_data: {},
        };
    }

    modelRelations() {
        return {
            resource: () => this.hasOne(CompanyResource),
            program: () => this.belongsTo(CompanyProgram, 'company_program_id', 'id'),
            companyProgramResourceFilter: () => this.hasMany(CompanyProgramResourceFilter, 'resource_id', 'id').where(['component_name', '=', 'company_software']),
        };
    }

    modelEvents() {
        return {
            failed: (code, data) => {
                const message = data.message
                    ? I18n.translate(data.message)
                    : I18n.translate('Sorry we are unable to connect to this api');

                if (code === 403) {
                    const errorMessage = data.error ? I18n.translate(data.error) : message;
                    Notifier.getInstance('App').showError(errorMessage);
                    return;
                }

                Notifier.getInstance('App').showError(message);
            },
        };
    }
}
