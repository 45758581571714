<template>
    <HtModal ref="currentModal">
        <template #header>
            {{ title }}
        </template>
        <template #default>
            <template v-if="currentValue">
                <HtFormGroup
                    class="mb-2"
                    :label="currentValue.id ? '' : translate('Simple adding')"
                    :is-required="true"
                />
                <div class="component-edit-wrapper">
                    <component
                        :is="getComponentEdit"
                        ref="componentEdit"
                        v-model="currentValue"
                        :entity-id="entityId"
                    />
                </div>
            </template>
            <IconLoading v-else />

            <template v-if="multipleAddingComponent">
                <component
                    :is="multipleAddingComponent"
                    :entity-id="entityId"
                    :entities-value-edit-modal="$refs.currentModal"
                    :relation-name="relationName"
                    @on-close="$emit('on-close')"
                />
            </template>
        </template>
        <template #footer>
            <div class="text-right">
                <Button
                    :state="buttonState"
                    @click="onSave"
                >
                    <t>Save</t>
                </Button>
            </div>
        </template>
    </HtModal>
</template>

<script>
import api from '@/store/api';
import CompanyEntityValue from '@/models/CompanyEntityValue';
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import Notifier from '@/Notifier';
import I18n from '@/modules/i18n/I18n';
import CustomValues from '@/components/pages/settings/entities/form/modals/MultipleAdding/CustomValues.vue';
import BasicValues from '@/components/pages/settings/entities/form/modals/MultipleAdding/BasicValues.vue';
import EditDepartment from './edit/EditDepartment.vue';
import EditOffice from './edit/EditOffice.vue';
import EditJobPosition from './edit/EditJobPosition.vue';
import EditContract from './edit/EditContract.vue';
import EditCustom from './edit/EditCustom.vue';
import ListingEntries from './ListingEntries.vue';

export default {
    name: 'EntitiesValueEditModal',
    components: {
        EditCustom,
        EditContract,
        EditJobPosition,
        EditOffice,
        EditDepartment,
        HtFormGroup,
        ListingEntries,
    },
    inject: ['$validator'],

    props: {
        relationName: {
            type: String,
            default: 'custom',
        },
        entityId: {
            type: Number,
            required: true,
        },
        apiResource: {
            type: String,
            required: true,
            validator(value) {
                const validValues = [
                    'jobPositions',
                    'departments',
                    'offices',
                    'contracts',
                    'customs',
                ];
                return validValues.includes(value);
            },
        },
    },

    data() {
        return {
            currentValue: null,
            companyFile: null,
            buttonState: 'idle',
        };
    },

    computed: {
        title() {
            if (this.currentValue && this.currentValue.id) {
                const labelMap = {
                    [CompanyEntityValue.RELATION_JOB_POSITION]: 'Edit a job',
                    [CompanyEntityValue.RELATION_OFFICE]: 'Edit a site',
                    [CompanyEntityValue.RELATION_DEPARTMENT]: 'Edit a department',
                    [CompanyEntityValue.RELATION_CONTRACT]: 'Edit a contract',
                };

                const defaultLabel = 'Edit an entry';
                const labelEntity = labelMap[this.relationName] || defaultLabel;

                return this.translate(labelEntity);
            }

            return this.translate('Add an entry');
        },

        getComponentEdit() {
            const componentsMap = {
                [CompanyEntityValue.RELATION_JOB_POSITION]: 'EditJobPosition',
                [CompanyEntityValue.RELATION_OFFICE]: 'EditOffice',
                [CompanyEntityValue.RELATION_DEPARTMENT]: 'EditDepartment',
                [CompanyEntityValue.RELATION_CONTRACT]: 'EditContract',
            };

            return componentsMap[this.relationName] || 'EditCustom';
        },
        multipleAddingComponent() {
            const isAddMode = this.currentValue && this.currentValue.id === undefined;
            if (!isAddMode) {
                return undefined;
            }

            const multipleAddingComponentsByRelationName = {
                custom: CustomValues,
                [CompanyEntityValue.RELATION_DEPARTMENT]: BasicValues,
                [CompanyEntityValue.RELATION_JOB_POSITION]: BasicValues,
                [CompanyEntityValue.RELATION_OFFICE]: BasicValues,
            };

            return multipleAddingComponentsByRelationName[this.relationName];
        },
    },

    methods: {
        async open(entityValue) {
            this.companyFile = null;
            if (entityValue) {
                this.currentValue = entityValue;
            } else {
                this.currentValue = {};
            }

            this.$refs.currentModal.open();
        },

        async onSave() {
            const isValidated = await this.$validator.validateAll();

            if (!isValidated) {
                return;
            }

            this.buttonState = 'loading';

            if (typeof this.$refs.componentEdit.beforeSave === 'function') {
                this.$refs.componentEdit.beforeSave();
            }

            const saveApiService = api.configuration[this.apiResource];
            const saveFunction = this.currentValue.id ? saveApiService.update : saveApiService.create;
            const data = this.currentValue.id || this.currentValue;

            saveFunction
                .call(saveApiService, data, this.currentValue)
                .then(() => {
                    this.$refs.currentModal.close();
                    this.$emit('saved');

                    const messageType = this.currentValue.id
                        ? 'Successfully updated'
                        : 'Successfully added';
                    Notifier.getInstance('App').showInfo(I18n.translate(messageType));
                    this.$store.dispatch('entities/forceEntitiesExpiration');
                })
                .finally(() => {
                    this.buttonState = 'idle';
                });
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.component-edit-wrapper {
    padding: 3.2rem;
    background: $neutral-200;
    border-radius: 8px;
}
.import-answers-format {
    color: $color-text;
    font-size: 12px;
    opacity: 0.5;
}
.import-answers-format p {
  margin: 0.75rem 0;
}

.table-example td {
  border: 1px solid $neutral-800;
  padding: 0.5rem;
}
</style>
