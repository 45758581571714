<template>
    <div v-if="shared.session.company.isLoaded()">
        <SectionBar
            :title="translate('Language')"
            :has-add-button="false"
        />
        <p class="title-text">
            <t>You can create the resource in one or more languages that you have selected. It will always be possible to add a translation later.</t>
        </p>
        <template v-if="companyLangByPriority.length < 4">
            <CardList
                v-if="companyLangByPriority.length > 1"
                :options="{
                    class:'no-justify'
                }"
            >
                <Card
                    v-for="(item, index) in companyLangByPriority"
                    :key="index"
                    :options="{
                        type: 'small',
                        perRow: 3,
                    }"
                    :class="{'selected-red':(item.id == value.id)}"
                    @click="onClick(item)"
                >
                    <div slot="title">
                        <div class="wrapper-title">
                            <country-flag :country="item.codeFlag" />
                            <t class="margin-l-1">
                                {{ item.name }}
                            </t>
                        </div>
                    </div>
                </Card>
            </CardList>
        </template>
        <template v-else>
            <HtFormMultiSelect
                :id="'langueSelector'"
                :value="value"
                :options="companyLangByPriority"
                :name="'langueSelector'"
                :is-single="true"
                :is-searchable="false"
                :allow-empty="false"
                class="mb-20"
                @input="onClick"
            >
                <template
                    slot="singleLabel"
                    slot-scope="{ option }"
                >
                    <div class="d-flex align-items-center">
                        <country-flag
                            :country="option.codeFlag"
                            size="small"
                        />
                        <span class="ml-10">{{ option.name }}</span>
                    </div>
                </template>
                <template
                    slot="option"
                    slot-scope="{ option }"
                >
                    <div class="d-flex align-items-center">
                        <country-flag
                            :country="option.codeFlag"
                            size="small"
                        />
                        <span class="ml-10">{{ option.name }}</span>
                    </div>
                </template>
            </HtFormMultiSelect>
        </template>
    </div>
</template>

<script>
import CompanyLanguage from '@/models/CompanyLanguage';
import Company from '../../models/Company';

export default {
    props: {
        value: { type: CompanyLanguage, required: true },
    },

    computed: {

        companyLangByPriority() {
            const tmp = [];

            this.shared.session.company.company_languages.enabled.forEach((item) => {
                if (item.id === this.shared.session.company.company_language_id) tmp.unshift(item);
                else tmp.push(item);
            });

            return tmp;
        },
    },

    methods: {
        onClick(item) {
            this.$emit('input', item);
        },
    },
    shared: {
        session: {
            company: {
                type: Company,
                default: {},
            },
        },
    },
};
</script>

<style lang="scss" scoped>
    .lang-container{
        display:flex;
    }

    .lang-item{
        display:flex;

        &:hover, &.selected{
            color:blue;
        }
    }

	.title-text {
        margin-bottom: 1em;
	}

    .wrapper-title {
        display:flex;
        align-items:center;
    }

    .margin-l-1 {
        margin-left: 1em;
    }
</style>
