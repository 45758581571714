<template>
    <div
        ref="text_ellipsis"
        v-tooltip="tooltipOptions"
        class="text_ellipsis"
        :style="{'max-width': width}"
    >
        {{ text }}
    </div>
</template>

<script>
export default {
    name: 'TableCellTextEllipsis',

    props: {
        text: {
            type: String,
            required: true,
        },

        width: {
            type: String,
            default: '256px',
        },
    },

    data() {
        return {
            tooltipIsEnabled: false,
        };
    },

    computed: {
        tooltipOptions() {
            if (!this.tooltipIsEnabled) {
                return null;
            }

            return {
                content: this.text,
                placement: 'bottom-start',
            };
        },
    },

    mounted() {
        this.tooltipIsEnabled = this.$refs.text_ellipsis.scrollWidth > this.$refs.text_ellipsis.clientWidth;
    },
};
</script>

<style scoped>
    .text_ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
    }
</style>
