<template>
    <div class="input-order">
        <input
            v-show="!local_currentTimeline.is_timeline"
            ref="inputDisplayedOrder"
            v-model.number="local_displayedOrderEnd"
            v-next-field
            :disabled="disabled"
            type="number"
            min="1"
            name="order"
            @keypress="onlyNumber"
            @default.prevent
            @input="updateDisplayedOrderEnd()"
        >

        <HtFormSelect
            id="order-timeline"
            v-model="local_currentTimeline"
            v-validate.disable="'required'"
            class="timeline-select"
            name="order-timeline"
            :options="timelineOptions"
            :data-vv-as="translate('date')"
            @input="changeTimeline"
        />
    </div>
</template>

<script>
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';

export default {
    name: 'HtSelectDateWithSelect',
    components: {
        HtFormMultiSelect,
    },
    props: {
        currentTimeline: { type: Object, default: null },
        disabled: { type: Boolean, default: false },
        displayedOrderEnd: { type: Number, default: 0 },
        displayedOrderStart: { type: Number, default: 0 },
        allTimeline: { type: Object, default: null },
    },
    data() {
        return {
            local_currentTimeline: null,
            local_displayedOrderEnd: 0,
            local_displayedOrderStart: 0,
        };
    },
    computed: {
        /**
         * this computed property allow to render properly the <select> tag.
         * the render key should be reload everytime 'displayedOrderEnd' changes
         * @returns {number}
         */
        renderKey() {
            return this.local_displayedOrderEnd;
        },
        timelineOptions() {
            return Object.values(this.allTimeline).map((timelineItem) => ({
                id: timelineItem,
                name: this.translate(timelineItem.label, { count: this.local_displayedOrderEnd }),
            }));
        },
    },
    watch: {
        displayedOrderStart() {
            this.sendValue();
        },

        displayedOrderEnd() {
            this.sendValue();
        },
    },
    created() {
        this.local_currentTimeline = this.currentTimeline;
        this.local_displayedOrderEnd = this.displayedOrderEnd;
        this.local_displayedOrderStart = this.displayedOrderStart;
    },
    methods: {
        onlyNumber(event) {
            const regex = '^[0-9]*$';

            if (
                this.$refs.inputDisplayedOrder.value.length > 2
                || !event.key.match(regex)
            ) event.preventDefault();
        },

        changeTimeline() {
            this.local_displayedOrderStart = Math.abs(this.local_currentTimeline.start);
            this.local_displayedOrderEnd = Math.abs(this.local_currentTimeline.end);
            this.$emit('update:displayedOrderStart', this.local_displayedOrderStart);
            this.$emit('update:displayedOrderEnd', this.local_displayedOrderEnd);
            this.$emit('update:currentTimeline', this.local_currentTimeline);
        },

        updateDisplayedOrderEnd() {
            this.$emit('update:displayedOrderEnd', this.local_displayedOrderEnd);
        },

        updateCurrentTimeline() {
            this.$emit('update:currentTimeline', this.local_currentTimeline);
        },

        sendValue() {
            if (this.local_displayedOrderEnd === '' || this.local_displayedOrderEnd === '0') {
                this.local_displayedOrderEnd = 1;
            }

            const orderStartSigned = this.local_displayedOrderStart * this.local_currentTimeline.sign;
            const orderEndSigned = this.local_displayedOrderEnd * this.local_currentTimeline.sign;

            this.$emit('update:orderEnd', orderEndSigned);
            this.$emit('update:orderStart', orderStartSigned);
        },
    },
};
</script>

<style lang="scss" scoped>
.input-order {
  display: flex;
  justify-content: space-between;
  input {
    width: 125px;
    margin-right: 2.5rem;
  }

  .timeline-select {
      width: 100%;
  }
}
</style>
