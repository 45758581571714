<template>
    <div>
        <div
            id="main-container"
            class="centered_content"
        >
            <div id="main-page">
                <transition name="fade">
                    <div
                        v-show="step == 'default'"
                        style="position:relative;margin-top: 22%;zoom: 0.8;max-width: 664px;"
                    >
                        <div
                            v-show="errorBag.anyShowable() && errorBag.allShowable()"
                            class="notif notif-error"
                        >
                            <svg class="icon icon-error"><use xlink:href="#icon-error" /></svg>
                            <p><t>Wrong email/password combination</t></p>
                        </div>

                        <div
                            v-show="error"
                            class="notif notif-error"
                        >
                            <svg class="icon icon-error"><use xlink:href="#icon-error" /></svg>
                            <p>{{ error }}</p>
                        </div>
                        <div class="bg-home">
                            <div class="wrapper-over-bg">
                                <h1 class="align-center">
                                    <t>Login</t>
                                </h1>
                                <div class="content-over-bg">
                                    <!-- NORMAL LOGGIN -->
                                    <form class="form">
                                        <fieldset :class="{'error': errorBag.hasShowable('email')}">
                                            <label for="email-login"><t>Email</t></label>
                                            <input
                                                id="email-login"
                                                v-model.trim="user.email"
                                                v-next-field
                                                v-fvalidate="'required|email'"
                                                type="text"
                                                name="email"
                                                :placeholder="translate('Type email here')"
                                            >
                                        </fieldset>
                                        <fieldset :class="{'error': errorBag.hasShowable('password')}">
                                            <label for="password-login"><t>Password</t></label>
                                            <input
                                                id="password-login"
                                                v-model.trim="user.password"
                                                v-next-field
                                                v-fvalidate="'required'"
                                                type="password"
                                                name="password"
                                                :placeholder="translate('Type your password here')"
                                            >
                                        </fieldset>
                                        <div class="wrapper-button-form">
                                            <input
                                                type="button"
                                                class="button button-7"
                                                value="Login"
                                                @keyup.13="onLogin"
                                                @click="onLogin"
                                            >
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginMaster',
    data() {
        return {
            loaded: false,
            companynotfound: false,
            ssoLoading: false,

            step: 'default',
            error: '',
            saving: false,
            user: {
                email: '',
                password: '',
                domain: '',
            },

            sign: {
                email: '',
                firstname: '',
                lastname: '',
                password: '',
                company_name: '',
                domain: '',
                checked: false,

            },
        };
    },

    methods: {
        onLogin() {
            this.login();
        },

        login() {
            this.errorBag.showErrors();
            if (this.saving || this.errorBag.any()) return;
            this.error = '';
            this.API.post('admin/login', { email: this.user.email, password: this.user.password, domain: this.$env.getCompanyDomain(document.location.href) }, {

                200: () => {
                    if (this.$router.historyTrack[0].path !== '/') this.$router.push(this.$router.historyTrack[0].path);
                    else {
                        this.$router.push('/Autoconnect');
                    }
                },
                404: (data) => {
                    this.error = this.translate(data.message);
                },

                403: (data) => {
                    this.error = this.translate(data.message);
                },

                onError: () => {

                },
            });
        },

    },
};
</script>

<style scoped>

#main-container{
	background: url(/static/images/login/bg-login-master.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.bg-home:before{
	    background: rgba(255, 255, 255, 0.32);
    border-radius: 2rem;
}

#main-page {

    max-width: 664px;
 }

 .bg-home h1, .bg-home .form label, .bg-home .form .label-form {

    color: #868686;
    text-shadow: -4px 4px 20px #ede6e6;
}

@-webkit-keyframes lds-ellipsis3 {
  0%, 25% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    left: 100px;
  }
  100% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0%, 25% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    left: 100px;
  }
  100% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis {
  0% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    left: 100px;
  }
  75% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    left: 168px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes lds-ellipsis {
  0% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    left: 100px;
  }
  75% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    left: 168px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
.lds-ellipsis {
  position: relative;
  margin: 0 25%;
  height: 13rem;
}
.lds-ellipsis > div {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
}
.lds-ellipsis div > div {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f00;
  position: absolute;
  top: 20px;
  left: 32px;
  -webkit-animation: lds-ellipsis 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation: lds-ellipsis 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
}
.lds-ellipsis div:nth-child(1) div {
  -webkit-animation: lds-ellipsis2 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation: lds-ellipsis2 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  background: #FF6058;
}
.lds-ellipsis div:nth-child(2) div {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: #FF6058;
}
.lds-ellipsis div:nth-child(3) div {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
  background: #FF6058;
}
.lds-ellipsis div:nth-child(4) div {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  background: #FF6058;
}
.lds-ellipsis div:nth-child(5) div {
  -webkit-animation: lds-ellipsis3 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation: lds-ellipsis3 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  background: #FF6058;
}
</style>
