<template>
    <div>
        <div class="filters">
            <HtFormInput
                :id="'user-name'"
                v-model.trim="querySearch.fullname"
                :name="'user-name'"
                :placeholder="translate('User')"
            />
            <HtFormSelect
                :id="'role-name'"
                v-model="querySearch.role"
                :name="'role-name'"
                :is-single="true"
                :placeholder="translate('Roles')"
                :options="rolesOptions"
                :allow-empty="true"
            />
            <HtFormSelect
                :id="'department-name'"
                v-model="querySearch.department"
                :name="'department-name'"
                :placeholder="translate('Departments')"
                :options="departmentsOptions"
                :allow-empty="true"
            />
            <HtFormSelect
                :id="'office-name'"
                v-model="querySearch.office"
                :name="'office-name'"
                :placeholder="translate('Sites')"
                :options="officesOptions"
                :allow-empty="true"
            />
        </div>
        <HtTableModel
            :enable-pagination="true"
            :per-page="15"
            :collection="companyUserCollection"
            :columns="userColumns"
            :empty-message="'No users'"
            @onCellClicked="onUserClicked"
            @pagination-refreshed="$emit('pagination-refreshed', $event)"
        >
            <template #cell(fullname)="scope">
                <div class="user-container">
                    <HtStatusLight
                        :status="getStatusClass(scope.item.status)"
                        :label="translate($Utils.capitalize(scope.item.status))"
                    />
                    <div
                        v-user-image="scope.item"
                        class="user-image"
                    />
                    <span class="user-name">{{ scope.item.fullname }}</span>
                </div>
            </template>
            <template #cell(company_role_id)="scope">
                <div class="role-list">
                    <div class="short-role-list">
                        {{ getUserListDisplay(scope.item.company_role_user.models) }}
                    </div>
                    <div
                        v-if="scope.item.company_role_user.models.length > 2"
                        class="overflowing-role-list"
                    >
                        {{ overflowingRoles(scope.item.company_role_user.models) }}
                    </div>
                </div>
            </template>
            <template #cell(department)="scope">
                {{ getDepartmentName(scope.item.company_department) }}
            </template>

            <template #responsive="scope">
                <div class="user-container mb-3">
                    <HtStatusLight
                        :status="getStatusClass(scope.item.status)"
                        :label="translate($Utils.capitalize(scope.item.status))"
                    />
                    <div
                        v-user-image="scope.item"
                        class="user-image"
                    />
                    <span class="user-name">{{ scope.item.fullname }}</span>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="role-list">
                            <div class="short-role-list">
                                {{ getUserListDisplay(scope.item.company_role_user.models) }}
                            </div>
                            <div
                                v-if="scope.item.company_role_user.models.length > 2"
                                class="overflowing-role-list"
                            >
                                {{ overflowingRoles(scope.item.company_role_user.models) }}
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        {{ getDepartmentName(scope.item.company_department) }}
                    </div>
                    <div class="col">
                        {{ scope.item.company_office ? scope.item.company_office.name_translated : '-' }}
                    </div>
                </div>
            </template>
        </HtTableModel>
    </div>
</template>
<script>
import HtFormInput from '@/components/globals/HtFormInput.vue';
import HtStatusLight from '@/components/globals/HtStatusLight.vue';
import HtTableModel from '@/components/globals/HtTableModel.vue';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import CompanyUserCollection from '@/models/CompanyUserCollection';
import CompanyDepartmentCollection from '@/models/CompanyDepartmentCollection';
import CompanyOfficeCollection from '@/models/CompanyOfficeCollection';
import CompanyRoleCollection from '@/models/CompanyRoleCollection';
import debounce from 'lodash.debounce';
import CompanyUser from '@/models/CompanyUser';

export default {
    name: 'UsersList',
    components: {
        HtFormInput,
        HtStatusLight,
        HtTableModel,
        HtFormSelect,
    },

    props: {
        rolesOptions: {
            required: true,
        },
        officesOptions: {
            required: true,
        },
        departmentsOptions: {
            required: true,
        },
        /**
         * used on holding mode
         */
        companyId: {
            type: Number,
        },
    },

    data() {
        return {
            userColumns: [
                {
                    label: this.translate('User'),
                    key: 'fullname',
                    sortable: false,
                },
                {
                    label: this.translate('Role'),
                    key: 'company_role_id',
                    sortable: false,
                },
                {
                    label: this.translate('Department'),
                    key: 'department',
                    sortable: false,
                },
                {
                    label: this.translate('Site'),
                    key: 'company_office.name',
                    sortable: false,
                },
            ],
            querySearch: {
                department: null,
                office: null,
                role: null,
                fullname: null,
            },
            companyDepartmentCollection: new CompanyDepartmentCollection([
                'id',
                'name',
                'is_heyteam',
            ]),
            companyOfficeCollection: new CompanyOfficeCollection([
                'id',
                'name',
            ]),
            companyRoleCollection: new CompanyRoleCollection([
                'id',
                'name',
                'alias',
                'is_heyteam',
            ]),
            companyUserCollection: null,
        };
    },

    methods: {
        setCompanyUserCollection() {
            const userCollection = new CompanyUserCollection([
                'id',
                'firstname',
                'lastname',
                'image',
                'fullname',
                'company_department_id',
                'company_office_id',
                'status',
            ]).with({
                companyRoleUser: (query) => {
                    query.select(['id', 'company_role_id']).with({
                        role: (query) => {
                            query.select(['id', 'name', 'alias', 'is_heyteam']);
                        },
                    });
                },
                companyDepartment: (query) => {
                    query.select(['id', 'name', 'is_heyteam']);
                },
                companyOffice: (query) => {
                    query.select(['id', 'name']);
                },
            });
            // Add all wheres from the selects/input search. The syntax is double array where([[x],[x],[x]])
            const wheres = [];
            if (this.querySearch.fullname) {
                wheres.push(['fullname', 'like', `%${this.querySearch.fullname}%`]);
            }
            if (this.querySearch.department) {
                wheres.push(['company_department_id', '=', this.querySearch.department]);
            }
            if (this.querySearch.office) {
                wheres.push(['company_office_id', '=', this.querySearch.office]);
            }
            // Add all wheres for fullname, office and department
            if (wheres.length > 0) {
                userCollection.where(wheres);
            }
            // Where Has for Roles
            if (this.querySearch.role) {
                userCollection.whereHas({
                    roleUser: (query) => {
                        query.where(['company_role_id', '=', this.querySearch.role]);
                    },
                });
            }
            if (this.companyId) {
                userCollection.where([['company_id', '=', this.companyId]]);
                userCollection.setEndPointPrefix('holding/lookup/');
            } else {
                userCollection.setLookupMethod('lookupPageSettingsUsers');
            }
            this.companyUserCollection = userCollection;
        },

        getStatusClass(status) {
            switch (status) {
            case CompanyUser.STATUS_ACTIVE:
                return 'success';
            case CompanyUser.STATUS_INVITED:
                return 'warning';
            case CompanyUser.STATUS_INACTIVE:
                return 'danger';
            case CompanyUser.STATUS_DRAFT:
            case CompanyUser.STATUS_CANCELLED:
                return 'neutral';
            }
        },
        onUserClicked(cell) {
            this.$emit('on-user-clicked', cell.item);
        },
        getUserListDisplay(roleList) {
            return this.$Utils.listToTruncatedString(roleList.map((role) => role.role.alias_translated), ' | ', 2, 'role | roles');
        },
        overflowingRoles(roles) {
            return roles
                .map((role) => role.role.alias)
                .join(', ');
        },
        /**
         * @param {CompanyDepartment|undefined}
         * @returns {string}
         */
        getDepartmentName(department) {
            return department?.name_translated || '-';
        },
    },

    watch: {
        querySearch: {
            deep: true,
            handler: debounce(function () { this.setCompanyUserCollection(); }, 250),
        },
        companyId() {
            this.setCompanyUserCollection();
        },
    },

    created() {
        this.setCompanyUserCollection();
    },
};
</script>
<style lang="scss" scoped>
	@import '~@/styles/var';

    .hover-permission:hover {
        cursor: pointer;
    }
    .permissions>h2[class='permissions-title'] {
        margin-top: 2em;
    }

    .user-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        gap: 1rem;
        position: relative;
    }
    .user-image {
        height: 3rem;
        width: 3rem;
        margin-top: auto;
        margin-bottom: auto;
    }
    .user-name {
        font-family: "lato", sans-serif;
        font-weight: 400;
        font-size: 14px;
        margin: 0;
    }

    .status {
        border-radius: 50%;
        /* Chrome doesn't respect the specified width when the parent flex is on nowrap and needs some space, but it respects the padding */
        padding: 0.4rem;
    }
    .status-active {
        background-color: #3FBC95;
    }
    .status-inactive {
        background-color: #FF504F;
    }
    .status-invited {
        background-color: #FFB858;
    }
    .status-default {
        background-color: #D8D8D8;
    }

    .status-label {
        display: none;
        position: absolute;
        transform:
            translateX(0.4rem) /* half the status icon width */
            translateX(3rem) /* the avatar width */
            translateX(-50%) /* half the label width to center it */
            translateY(1.2rem) /* half the label height */
            translateY(1.5rem) /* half the avatar height */
            translateY(-0.7rem); /* a couple pixels back up to fit the figma mockup */

        padding: 4px 8px;
        box-sizing: border-box;
        border-radius: 100px;
        border: 1px solid #5BDDB4;
        border-width: 1px;
        border-style: solid;
    }
    .status-active-label {
        background: #DEF8F0;
        border-color: #5BDDB4;
        color: #3FBC95;
    }
    .status-inactive-label {
        background: #FEDADF;
        border-color:  #F7485E;
        color: #FF504F;
    }
    .status-invited-label {
        background: #FFF1DD;
        border-color: #FFB858;
        color: #D89741;
    }
    .status-default-label {
        background: white;
        border-color: #6C757D;
        color: #6C757D;
    }

    .status-hoverable {
        position: absolute;
        width: 3rem;
        height: 3rem;
        border-radius: 1rem;
        left: -1.2rem; /* (width (3rem) - icon width (0.8rem)) / 2 */
    }

    .status-hoverable:hover ~ .status-label, .status-label:hover {
        display: block;
    }

    .filters {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 0.8rem
    }

    .role-list {
        position: relative;

        .overflowing-role-list {
            display: none;
        }

        .short-role-list:hover + .overflowing-role-list, .overflowing-role-list:hover {
            position: absolute;
            display: block;
            z-index: 1;
            transform: translateX(-16.67%);
            width: 150%;

            padding: 1.6rem;
            background: white;
            border: 1px solid $grey-ultra-light;
            border-radius: 5px;

            font-family: $lato;
        }
    }
</style>
