import Vue from 'vue';

export default {
    all(pageId, config = {}) {
        return Vue.prototype.$http.get(`company/settings/enterprise-pages/${pageId}/sections`, config);
    },

    store(pageId, data = {}) {
        return Vue.prototype.$http.post(`company/settings/enterprise-pages/${pageId}/sections`, data);
    },

    update(pageId, id, data = {}) {
        return Vue.prototype.$http.post(`company/settings/enterprise-pages/${pageId}/sections/${id}`, data);
    },

    destroy(pageId, id, config = {}) {
        return Vue.prototype.$http.delete(`company/settings/enterprise-pages/${pageId}/sections/${id}`, config);
    },

    saveOrders(pageId, data = {}) {
        return Vue.prototype.$http.post(`company/settings/enterprise-pages/${pageId}/sections/save-orders`, data);
    },
};
