<template>
    <div
        class="checkbox-wrapper"
        @click.stop
    >
        <div
            :class="classes()"
            class="checkbox"
            @click="change"
        >
            <div class="checkbox__box">
                <FontAwesomeIcon
                    v-if="indeterminate"
                    icon="minus"
                />
                <FontAwesomeIcon
                    v-else-if="value"
                    icon="check"
                />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'HtTableCellCheckbox',
    directives: {
        indeterminate(el, binding) {
            el.indeterminate = Boolean(binding.value);
        },
    },
    props: {
        value: {
            type: Boolean,
            default: () => true,
        },
        indeterminate: {
            type: Boolean,
            default: () => false,
        },
        isHeader: {
            type: Boolean,
            default: () => false,
        },
    },
    methods: {
        change() {
            this.$emit('input', !this.value);
        },
        classes() {
            const classes = [];

            if (this.indeterminate && this.isHeader) {
                classes.push('indeterminate-header');
            }

            if (this.value) {
                classes.push(this.isHeader ? 'active-header' : 'active-child');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss" scoped>
$checkboxSize: 16px;

.checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .checkbox {
        display: flex;
        width: $checkboxSize;
        height: $checkboxSize;
        background: white;
        cursor: pointer;

        &.indeterminate-header {
            > .checkbox__box {
                color: #ABADAE;
            }
        }

        &.active-header, &.active-child {
            > .checkbox__box {
                color: #FFFFFF;
                background-color:  var(--branding-color);
                border-color: var(--branding-color);
            }
        }
    }
    .checkbox__box {
        width: $checkboxSize;
        height: $checkboxSize;
        border-radius: 2px;
        border: 1px solid var(--branding-color);
        cursor: pointer;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
