export default {
    methods: {
        getVariablesOfField(variables, field, notificationType = 'mail') {
            return variables.filter((variable) => variable.field === field && variable[this.notificationTypes[notificationType]] === true).map((variable) => ({
                name: variable.name,
                label: variable.label,
            }));
        },
    },

    data() {
        return {
            notificationTypes: {
                mail: 'is_used_in_mail',
                sms: 'is_used_in_sms',
                slack: 'is_used_in_slack',
            },
        };
    },
};
