<template>
    <div class="question-completion">
        <div class="list-answer">
            <div
                v-for="choice in question.template.answers"
                class="list-answer-item"
                :class="{'selected': choiceIsSelected(choice), 'read-only': isReadOnly, 'smiley': isSmileySubType}"
                @click="selectChoice(choice)"
            >
                <div
                    v-if="isSmileySubType"
                    class="list-answer-item-icon"
                >
                    <img :src="smileys[choice.order]">
                </div>

                <div class="list-answer-item-text">
                    {{ localize(choice.translations.text) }}
                </div>
            </div>
        </div>

        <SurveyAnswerHistory
            v-if="hasPreviousOccurrenceSection"
        >
            <SurveySatisfactionScaleHistory
                :answers="previousOccurrence.question.answers"
                :template-answers="question.template.answers"
                :is-smiley-sub-type="isSmileySubType"
            />
        </SurveyAnswerHistory>
    </div>
</template>

<script>
import SurveyQuestionMixin from '@/components/resources/user/surveys/questions/SurveyQuestionMixin';
import SurveyAnswerHistory from '@/components/resources/user/surveys/questions/SurveyAnswerHistory.vue';
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import SurveySatisfactionScaleHistory from '@/components/resources/user/surveys/questions/SatisfactionScale/SurveySatisfactionScaleHistory.vue';

export default {
    name: 'SurveySatisfactionScaleCompletion',

    components: {
        SurveyAnswerHistory,
        SurveySatisfactionScaleHistory,
    },

    mixins: [
        SurveyQuestionMixin,
    ],

    computed: {
        isSmileySubType() {
            return this.question.template.subtype === CompanySurveyQuestion.SUBTYPE_SATISFACTION_SCALE_SMILEY;
        },

        smileys() {
            return {
                1: '/static/icons/survey/smiley/SmileyNotGood.svg',
                2: '/static/icons/survey/smiley/SmileyMedium.svg',
                3: '/static/icons/survey/smiley/SmileyGood.svg',
                4: '/static/icons/survey/smiley/SmileyVeryGood.svg',
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../SurveyQuestion';

.question-completion {
    .list-answer {

        &-item {
            padding: 10px;
            margin-bottom: 10px;

            &.smiley {
                height: auto;
            }

            &-icon {
                flex-basis: 30px;
                flex-grow: 0;
                flex-shrink: 0;
                margin-right: 10px;

                img {
                    display: block;
                    width: 100%;
                }
            }

            &:hover {
                background-color: #f5f3f3;
            }
        }
    }
}
</style>
