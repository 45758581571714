import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import get from 'lodash.get';
import ViewCompanyUserTask from './ViewCompanyUserTask';
import CompanyUserProgramTask from './CompanyUserProgramTask';

export default class ViewCompanyUserTaskCollection extends ModelCollection {
    modelConfig() {
        return {
            model: ViewCompanyUserTask,
        };
    }

    modelCustomAttributes() {
        return {
            tasksGrouped: [],
        };
    }

    modelAccessors() {
        return {
            tasksGrouped: () => this.models.map((model) => {
                const dependent = get(model, 'company_user_program_task.dependents.models[0]', null);
                const dependentId = get(dependent, 'id', null);
                const statusDependent = get(dependent, 'status', false);

                return {
                    ...model,
                    dependentId: (statusDependent === CompanyUserProgramTask.STATUS_DONE) ? null : dependentId,
                };
            }),
        };
    }
}
