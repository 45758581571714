import Env from '@/Env';
import { datadogRum } from '@datadog/browser-rum';

let environment;

try {
    environment = Env.get('APP_ENV');
} catch (exception) {
    environment = null;
}

if (environment === 'production') {
    datadogRum.init({
        applicationId: '2cb792f0-4acb-4202-963d-afeb2bcf747e',
        clientToken: 'pub3549669e869b38479966b26d36aa1627',
        site: 'datadoghq.eu',
        service: 'heyteam-front',
        env: environment,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: Env.get('VERSION'),
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask',
    });

    datadogRum.startSessionReplayRecording();
}
