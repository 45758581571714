<template>
    <div class="recurrence-modal">
        <div class="recurrence-modal-icon">
            <span class="recurrence-modal-icon-span">&#127881;</span>
        </div>
        <div class="recurrence-modal-title">
            {{ title }}
        </div>
        <div class="recurrence-modal-subtitle">
            {{ subTitle }}
        </div>
        <div class="recurrence-modal-input">
            <HtFormInput
                id="datetime_end"
                v-model="formattedNextOccurenceDate"
                type="date"
                name="datetime_end"
                @input="setModified"
            />
        </div>
        <div class="recurrence-modal-actions">
            <HtButton
                id="validate"
                :loading="loading"
                name="validate"
                @click.native="rePlan"
            >
                <t>Plan</t>
            </HtButton>
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';
import HtFormInput from '@/components/globals/HtFormInput.vue';
import api from '@/store/api';

export default {
    name: 'NextOccurencePreviewModal',
    components: {
        HtFormInput,
        HtButton,
    },
    props: {
        userProgramTask: {
            type: Object,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        subTitle: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            loading: false,
            modified: false,
            newDate: null,
            nextOccurrenceDate: this.userProgramTask.datetime_end,
        };
    },

    computed: {
        formattedNextOccurenceDate: {
            get() {
                return new Date(this.nextOccurrenceDate).toJSON().substring(0, 10);
            },
            set(value) {
                this.nextOccurrenceDate = value;
            },

        },
    },

    methods: {
        async rePlan() {
            if (this.modified) {
                this.loading = true;
                await api.tasks.updateNextOccurencesDates({ taskId: this.userProgramTask.id, date: new Date(this.nextOccurrenceDate).toJSON() });
                this.loading = false;
            }
            this.$emit('close');
        },
        setModified() {
            this.modified = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.recurrence-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 20px;

    &-icon {
        font-size: 2em;
        background-color: #EBFFE7;
        padding: 15px;
        border-radius: 50%;
        margin-bottom: 15px;
    }

    &-title {
        font-weight: 700;
        font-size: 18px;
        color: #1B1B1C;
    }

    &-subtitle {
        font-weight: 400;
        font-size: 14px;
        color: #4B4B4B;
        margin-bottom: 20px;
    }

    &-input {
        width: 100%;
        ::v-deep .ht-form {
            margin-bottom: unset;
        }
    }

    &-actions {
        width: 100%;
        ::v-deep button {
            width: 100%;
        }
    }
}
</style>
