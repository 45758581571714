<template>
    <svg
        :height="height"
        :width="width"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.266 17.998H1.73301C1.37575 17.998 1.04563 17.8074 0.867 17.498C0.688374 17.1886 0.688376 16.8074 0.867007 16.498L10.133 0.497992C10.3118 0.189104 10.6416 -0.00109863 10.9985 -0.00109863C11.3554 -0.00109863 11.6852 0.189104 11.864 0.497992L21.13 16.498C21.3085 16.8072 21.3086 17.1882 21.1303 17.4975C20.9519 17.8069 20.6221 17.9976 20.265 17.998H20.266ZM11 2.99799L3.46901 15.998H18.533L11 2.99799ZM11.995 11.999H9.99501V6.99799H11.995V11.999Z"
            fill="orange"
        />
        <path
            d="M10 13H12V15H10V13Z"
            :fill="fillColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'DSIconWarning',
    props: {
        width: {
            type: String,
            default: '22',
        },
        height: {
            type: String,
            default: '18',
        },
        fillColor: {
            type: String,
            default: '#E46E00',
        },
    },
};
</script>

<style scoped>

</style>
