<template>
    <div v-if="isLoaded">
        <HtCard class="mb-5">
            <template #title>
                <HtCardTitleBar
                    :title="title"
                    :show-close="true"
                    :show-button="true"
                    :button-text="translate('Create')"
                    @onAdd="onCreate"
                    @search="setSearchValue"
                    @on-close="
                        $emit('on-change-step', 'SelectResourceTypeModal')
                    "
                />
            </template>
            <template #default>
                <RequirementsList
                    :items="getRequirement"
                    :external-search="search"
                    @onCellClicked="onCreate"
                />
            </template>
        </HtCard>
    </div>
</template>

<script>
import groupBy from 'lodash.groupby';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import ProgramBuilderListResourceMixin from '@/mixins/ProgramBuilderListResourceMixin';
import RequirementsList, { requirementConfig } from '@/resourcesList/RequirementsList.vue';
import CompanyRequirementCategory from '@/models/CompanyRequirementCategory';

export default {
    name: 'RequirementCategory',
    components: {
        HtCardTitleBar,
        RequirementsList,
    },

    mixins: [ProgramBuilderListResourceMixin],

    data() {
        return {
            collectionName: 'CompanyResourceProgramCollection',
            resourceTitleSingular: 'requirement',
            resourceTitlePlural: 'requirements',
            itemFields: requirementConfig.fields,
            itemRelations: requirementConfig.relations,
        };
    },

    computed: {
        title() {
            if (this.getRequirement.length === 0) {
                return this.translate(`No ${this.resourceTitleSingular}`);
            }

            return this.translate(`{count} ${this.resourceTitleSingular} | {count} ${this.resourceTitlePlural}`, {
                count: this.getRequirement.length,
            });
        },

        getRequirement() {
            if (this.isLoaded) {
                const requirementToShow = [];
                const groupedRequirement = groupBy(this.resourceCollection.$.models, 'requirement_category.id');

                const aRequirementIdGrouped = Object.keys(groupedRequirement);
                Object.values(groupedRequirement).forEach((currentRequirement, index) => {
                    if (currentRequirement.every((program) => program.company_program_id !== this.companyProgramId)) {
                        requirementToShow.push(Number(aRequirementIdGrouped[index]));
                    }
                });

                const requirementShowed = [];
                return this.resourceCollection.$.models.filter((resourceProgram) => {
                    if (requirementToShow.includes(resourceProgram.resourceable_id) && !requirementShowed.includes(resourceProgram.resourceable_id)) {
                        requirementShowed.push(resourceProgram.resourceable_id);
                        return true;
                    }
                    return false;
                });
            }

            return [];
        },
    },

    methods: {
        AddCollectionWhere() {
            this.resourceCollection.where([
                ['resourceable_type', '=', CompanyRequirementCategory.RESOURCEABLE_CLASS],
            ]);
        },

        AddCollectionWhereHas() {
            this.resourceCollection.whereHas({
                requirementCategory: (query) => query.where(['deleted_at', '=', null]),
            });
        },
    },
};
</script>
