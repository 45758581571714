export default {
    name: 'Social Networks',
    image: 'social-networks.png',
    fullPageBorder: true,
    content: `
        <section class="ht-section-social-networks">
            <h2 class="title">Lorem ipsum dolor sit amet, consectetur adipisicing elit</h2>

            <div class="social-networks">
                <a class="social-networks-item" href="#" target="_blank" rel="noopener">
                    <img class="social-icon" src="https://s3.eu-west-1.amazonaws.com/prod.heyteam.com-company-file/public/998/wysiwyg/26c98100-1990-11ef-87a7-0517bd3c0ed6.png" alt="Website Icon" />
                    <span class="social-label">Lorem ipsum</span>
                </a>
                <a class="social-networks-item" href="#" target="_blank" rel="noopener">
                    <img class="social-icon" src="https://s3.eu-west-1.amazonaws.com/prod.heyteam.com-company-file/public/998/wysiwyg/0d216370-1991-11ef-b00f-51a17599f59c.png" alt="YouTube Icon" />
                    <span class="social-label">YouTube</span>
                </a>
                <a class="social-networks-item" href="#" target="_blank" rel="noopener">
                    <img class="social-icon" src="https://s3.eu-west-1.amazonaws.com/prod.heyteam.com-company-file/public/998/wysiwyg/2366ee50-1991-11ef-8f10-87fc019dd11c.png" alt="Instagram Icon" />
                    <span class="social-label">Instagram</span>
                </a>
                <a class="social-networks-item" href="#" target="_blank" rel="noopener">
                    <img class="social-icon" src="https://s3.eu-west-1.amazonaws.com/prod.heyteam.com-company-file/public/998/wysiwyg/3a622540-1991-11ef-9f95-217aa58da224.png" alt="TikTok Icon" />
                    <span class="social-label">TikTok</span>
                </a>
                <a class="social-networks-item" href="#" target="_blank" rel="noopener">
                    <img class="social-icon" src="https://s3.eu-west-1.amazonaws.com/prod.heyteam.com-company-file/public/998/wysiwyg/4808ca10-1991-11ef-be47-679d74611eb6.png" alt="X Icon" />
                    <span class="social-label">X (Twitter)</span>
                </a>
                <a class="social-networks-item" href="#" target="_blank" rel="noopener">
                    <img class="social-icon" src="https://s3.eu-west-1.amazonaws.com/prod.heyteam.com-company-file/public/998/wysiwyg/58482d80-1991-11ef-bced-d9c6b7b38ed5.png" alt="LinkedIn Icon" />
                    <span class="social-label">LinkedIn</span>
                </a>
            </div>
        </section>
    `,
    css: `
        .ht-section-social-networks {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            padding: 40px 0;
        }

        .title {
            font-size: 32px;
            line-height: 36px;
            font-weight: bold;
            color: var(--primary-base);
            margin-bottom: 0;
        }

        .social-networks {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            gap: 32px;
            background-color: var(--primary-base);
            padding: 32px 16px;
            border-radius: 12px;
            width: 100%
        }

        .social-networks-item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            font-size: 16px;
            text-decoration: none;
            color: var(--primary-lighter);
            transition: transform 0.3s ease;
        }

        .social-label {
            font-size: 16px;
            line-height: 21px;
            font-weight: regular;
        }

        .social-icon {
            width: 32px;
            height: 32px;
            border-radius: 20%;
            transition: transform 0.3s ease;
        }

        .social-networks-item:hover .social-icon {
            transform: scale(1.1);
        }
    `,
};
