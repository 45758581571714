<template>
    <HtTabs>
        <HtTab :title="translate('Job Logs')">
            <JobLogs/>
        </HtTab>
        <HtTab :title="translate('Integration Logs')">
            <IntegrationLogs/>
        </HtTab>
    </HtTabs>
</template>

<script>
import JobLogs from '@/router/pages/settings/Logs/JobLogs.vue';
import IntegrationLogs from '@/router/pages/settings/Logs/IntegrationLogs.vue';

export default {
    components: {
        JobLogs,
        IntegrationLogs,
    },
};
</script>
