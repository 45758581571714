import Vue from 'vue';

export default {
    findUsersByFilters(params = {}) {
        return Vue.prototype.$http.get('foc/multi-enrolling/users/find-by-filters', { params });
    },

    inviteUsers(programId, data = {}) {
        return Vue.prototype.$http.post(`foc/multi-enrolling/users/invite/${programId}`, data);
    },
};
