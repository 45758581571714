import Model from '@tony.caron/node-model-proxy/Model';
import CompanyFile from './CompanyFile';
import CompanyEmailCustomTemplateLocaleCollection from './CompanyEmailCustomTemplateLocaleCollection';
import CompanyLanguage from './CompanyLanguage';

export default class CompanyEmailCustomTemplateLocale extends Model {
    modelConfig() {
        return {
            collection: CompanyEmailCustomTemplateLocaleCollection,
        };
    }

    modelCustomAttributes() {
        return {
            selected: false,
        };
    }

    modelRelations() {
        return {
            companyFile: () => this.belongsTo(CompanyFile, 'company_file_id', 'id'),
            companyLanguage: () => this.belongsTo(CompanyLanguage, 'company_file_id', 'id'),
        };
    }
}
