export const Elements = {
    company_user_requirement_stats: {},
    company_user_training_stats: {},
    company_user_software_stats: {},
    company_user_planning_stats: {},
    company_user_equipment_stats: {},
    company_user_doc_stats: {},
    company_user_survey_stats: {},
    company_user_nudge_stats: {},
    company_user_quiz_stats: {},
};
