export default class BugType {
    folder;

    status;

    workflow;

    constructor(folder, status) {
        this.folder = folder;
        this.status = status;
        this.workflow = 'IEADI6F4K4BPFA7G';
    }
}
