<template>
    <div
        class="table-string"
        :title="string"
    >
        {{ string }}
    </div>
</template>

<script>
export default {
    props: {
        string: {
            type: [String, Number],
            default: () => '',
        },
    },
};
</script>

<style lang="scss" scoped>
.table-string {
    width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
