import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserEquipment from './CompanyUserEquipment';

export default class CompanyUserEquipmentCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserEquipment,
        };
    }

    modelCustomAttributes() {
        return {
            company_user_id: null,
        };
    }
}
