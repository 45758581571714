<template>
    <div v-if="companyUserNudgeCollection.isLoaded()">
        <HtAccordion
            :title="translate('Nudge | Nudges', {count: filteredUserNudges.length})"
            :count="filteredUserNudges.length"
            :has-button="filteredUserNudges.length === 0"
            :button-label="addButtonLabel"
            :is-branding="false"
            cypress="nudge"
            @buttonClick="openSelectModal()"
        >
            <HtSearchField
                v-if="hasNudge"
                id="searchNudge"
                v-model.trim="searchValue"
                name="searchNudge"
                cypress="searchNudge"
            />

            <template v-if="filteredUserNudges.length > 0">
                <HtTable
                    :items="filteredUserNudges"
                    :columns="columns"
                    cypress="nudge-table"
                    :external-search="searchValue"
                    @onCellClicked="openEditModal"
                >
                    <template #cell(name)="scope">
                        {{ scope.item.name }}
                    </template>
                    <template #cell(participant)="scope">
                        <ParticipantList :users="[{company_user: scope.item.company_user_recipient}]" />
                    </template>
                </HtTable>
                <div class="mt-4 text-center">
                    <Button
                        type="clear"
                        cypress="nudge-add-button"
                        @click="openSelectModal()"
                    >
                        <slot name="buttonText">
                            {{ translate(addButtonLabel) }}
                        </slot>
                    </Button>
                </div>
            </template>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No nudge added</t>
                </strong>
            </div>
        </HtAccordion>

        <modalable
            ref="modalableSelect"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserNudgeSelect
                :company-user-id="companyUserId"
                :intelligent-selection="intelligentSelection"
                :company-user-program-id="companyUserProgramId"
                @onUpdate="onUpdate(...arguments)"
            />
        </modalable>

        <modalable
            ref="modalableEdit"
            class="modalable-1 medium"
            :mode="2"
        >
            <UserNudgeEdit
                :id="currentId"
                :company-user-id="companyUserId"
                :intelligent-selection="intelligentSelection"
                @onUpdate="onUpdate(...arguments)"
                @onDelete="onDelete(...arguments)"
            />
        </modalable>
    </div>
</template>

<script>
import HtAccordion from '@/components/globals/HtAccordion.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import UserNudgeSelect from '@/components/pages/dashboard/modals/nudge/UserNudgeSelect.vue';
import UserNudgeEdit from '@/components/pages/dashboard/modals/nudge/UserNudgeEdit.vue';
import CompanyUserNudgeCollection from '@/models/CompanyUserNudgeCollection';

export default {
    name: 'UserNudgeFlow',
    components: {
        HtAccordion,
        HtSearchField,
        UserNudgeSelect,
        UserNudgeEdit,
    },

    props: {
        companyUserId: {
            type: [String, Number],
            required: false,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
        intelligentSelection: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                    search: true,
                },
                {
                    label: this.translate('Participants'),
                    key: 'participant',
                    sortable: false,
                },
            ],
            addButtonLabel: 'Add nudge',
            currentId: null,
            companyUserNudgeCollection: new CompanyUserNudgeCollection([
                'id', 'company_user_id', 'status', 'company_user_program_id', 'datetime_availability', 'time_to_complete',
            ]).with({
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image', 'company_language_id'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyUserInitiator: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image']);
                },
                companyUserRecipient: (query) => {
                    query.select(['id', 'firstname', 'lastname', 'gender', 'image', 'company_language_id'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyNudge: (query) => {
                    query.select(['id']);
                    query.with({
                        resource: (query) => {
                            query.select(['name']);
                        },
                    });
                },
            }).where([
                ['company_user_program_id', '=', this.companyUserProgramId],
            ]),
            searchValue: null,
        };
    },

    computed: {
        /**
         * Nudges filtrés par la barre de recherches
         */
        filteredUserNudges: {
            get() {
                return this.companyUserNudgeCollection.$.models;
            },

            set(val) {
                this.companyUserNudgeCollection.$.models = val;
            },
        },

        hasNudge() {
            return this.companyUserNudgeCollection.$.models.length > 0;
        },
    },

    created() {
        this.companyUserNudgeCollection.get().then(() => {
            this.$emit('on-load', this.companyUserNudgeCollection.$.models.length);
        });
    },

    methods: {
        onUpdate() {
            this.companyUserNudgeCollection.get();
        },
        onDelete() {
            this.companyUserNudgeCollection.get();
        },
        openEditModal(row) {
            this.currentId = row.item.id;
            this.$refs.modalableEdit.open();
        },
        openSelectModal() {
            this.$refs.modalableSelect.open();
        },
    },
};
</script>
