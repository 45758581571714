import Model from '@tony.caron/node-model-proxy/Model';

import Company from './Company';
import CompanyUserSurvey from './CompanyUserSurvey';
import CompanyChatChannelUser from './CompanyChatChannelUser';
import CompanyChatChannelMessage from './CompanyChatChannelMessage';
import CompanyChatChannelCollection from './CompanyChatChannelCollection';
import CompanyFile from './CompanyFile';

export default class CompanyChatChannel extends Model {
    modelConfig() {
        return {
            collection: CompanyChatChannelCollection,
        };
    }

    modelCustomAttributes() {
        return {
            active: false,
        };
    }

    modelRelations() {
        return {
            company: () => this.belongsTo(Company, 'company_id', 'id'),
            companyChatChannelUser: () => this.hasMany(CompanyChatChannelUser),
            companyChatChannelMessage: () => this.hasMany(CompanyChatChannelMessage),
            companyUserSurvey: () => this.belongsTo(CompanyUserSurvey, 'resource_id', 'id'),
            avatarImage: () => this.belongsTo(CompanyFile, 'avatar_image_id', 'id'),
        };
    }

    modelEvents() {
        return {
            saved: () => {},
        };
    }
}
