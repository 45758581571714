import Color from 'color';

export default class Branding {
    static primaryBranding = {
        background: '#FF6058',
    };

    static color = '';

    static darkenColor = '';

    static lightColor = '';

    static highlightColor = '';

  	static setPrimaryBranding(branding = {}) {
        Branding.primaryBranding = { ...Branding.primaryBranding, ...branding.content };

        Branding.lightColor = Color(Branding.primaryBranding.background).lighten(3).rgb();
        Branding.color = Branding.primaryBranding.background;
        Branding.darkenColor = Color(Branding.primaryBranding.background).darken(0.2).hex();
        Branding.highlightColor = Color(Branding.primaryBranding.background).saturationv(10).value(100).hex();

        Branding.setCss();
    }

    static setCss() {
        addCssRules([
            ...buttonRules(),
            ...modalRules(),
            ...listRules(),
            ...tabsRules(),
            ...miscellaneousRules(),
            ...focRules(),
            ...formRules(),
            ...datePickerRules(),
            ...wysiwygRules(),
            ...headerRules(),
        ]);
    }

    static install(Vue) {
        Vue.prototype.$Branding = Branding;
    }
}

const wysiwygRules = function () {
    return [
        {
            selector: '.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected',
            properties: [
                ['color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter',
            properties: [
                ['stroke', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill',
            properties: [
                ['fill', `${Branding.color} !important`],
            ],
        },
    ];
};

const datePickerRules = function () {
    return [
        {
            selector: '.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay',
            properties: [
                ['background', `${Branding.color} !important`],
                ['border-color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.flatpickr-next-month:hover svg, .flatpickr-prev-month:hover svg',
            properties: [
                ['fill', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.flatpickr-day:before',
            properties: [
                ['background', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.flatpickr-day.today',
            properties: [
                ['border-color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.flatpickr-event',
            properties: [
                ['background', `${Branding.color} !important`],
            ],
        },
    ];
};

const formRules = function () {
    return [
        {
            selector: '.item-content .input-has-value + label',
            properties: [
                ['color', `${Branding.color} !important`],
            ],
        },
        {
            selector: 'input:focus, textarea:focus, select:focus, .select:focus, .ht-form-input-wrapper:focus-within',
            properties: [
                ['border-color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.radio .input',
            properties: [
                ['border-color', `${Branding.color} !important`],
            ],
        },

        // permet de rendre la bg-color du component "Checkable" à la couleur de l'entreprise
        {
            selector: '.branding-bg-checked input:checked + .labels, .branding-bg-checked.ht-switch input:checked + .switch .switch-visual',
            properties: [
                ['background', `${Branding.color} !important`],
                ['color', '#FFF'],
            ],
        },
        {
            selector: '.radio input:checked + .input:after',
            properties: [
                ['background', `${Branding.color} !important`],
            ],
        },
        // Multiselect
        {
            selector: '.ht-form-selector .multiselect.multiselect--active .multiselect__tags, .checkbox-label .checkbox-display.checked, .ht-form-selector:not(.multiple) .multiselect__option.multiselect__option--selected',
            properties: [
                ['border-color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.checkbox-label .checkbox-display.checked',
            properties: [
                ['background', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.ht-form-selector .multiselect__content-wrapper .multiselect__content .multiselect__option--highlight, .ht-form-selector .multiselect__content-wrapper .multiselect__content .multiselect__option--highlight .checkbox-wrapper',
            properties: [
                ['background', `${Branding.highlightColor} !important`],
            ],
        },
        {
            selector: '.ht-form-selector .multiselect .multiselect__input',
            properties: [
                ['caret-color', `${Branding.color} !important`],
            ],
        },
    ];
};

const focRules = function () {
    return [
        {
            selector: '.card-onboarding.flex-container .item-content .icon-plus',
            properties: [
                ['color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.card-onboarding.flex-container',
            properties: [
                ['color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.menu .menu-options a.branding.active',
            properties: [
                ['color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.headbar-menu .headbar-menu-links-item a.active',
            properties: [
                ['color', `${Branding.color}`],
            ],
        },
        {
            selector: '.menu .menu-options a.branding.active:before',
            properties: [
                ['border-top-color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.wrapper-user-progression .user-progression',
            properties: [
                ['background', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.validate-footer .btn-back',
            properties: [
                ['background', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.validate-footer .btn-next',
            properties: [
                ['background', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.step-infos span',
            properties: [
                ['color', `${Branding.color} !important`],
            ],
        },
    ];
};

const tabsRules = function () {
    return [
        {
            selector: '.tabs-wrapper .menu-item.active',
            properties: [
                ['color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.tabs-wrapper .menu-item.active:before',
            properties: [
                ['border-bottom', `8px solid ${Branding.color} !important`],
                ['box-shadow', `0px 0px 20px ${Branding.color} !important`],
            ],
        },
    ];
};

const miscellaneousRules = function () {
    return [
        {
            selector: '.progress',
            properties: [
                ['background-color', `${Branding.color}`],
            ],
        },
        {
            selector: '.selected-red',
            properties: [
                ['border', `2px solid ${Branding.color} !important`],
            ],
        },
        {
            selector: '.user-panel .user-dropdown-menu a:hover',
            properties: [
                ['color', `${Branding.color}`],
            ],
        },
        {
            selector: '.lang-container .one-side.selected',
            properties: [
                ['background', `${Branding.color}`],
            ],
        },
        {
            selector: '.user-progression .container-number div > span.red',
            properties: [
                ['color', `${Branding.color}`],
                ['border-color', `${Branding.color}`],
            ],
        },
        {
            selector: '.branding-icon',
            properties: [
                ['fill', `${Branding.color}`],
            ],
        },
        {
            selector: '.branding-fill',
            properties: [
                ['fill', `${Branding.color}`],
            ],
        },
        {
            selector: '.light-branding-fill',
            properties: [
                ['fill', `${Branding.highlightColor}`],
            ],
        },
        {
            selector: '.branding-stroke',
            properties: [
                ['stroke', `${Branding.color}`],
            ],
        },
        {
            selector: '.branding-bg',
            properties: [
                ['background', `${Branding.color}`],
                ['color', '#FFF'],
            ],
        },
        {
            selector: '.branding-bg-light',
            properties: [
                ['background', `${Branding.lightColor} !important`],
            ],
        },
        {
            selector: '.branding-bg-important',
            properties: [
                ['background', `${Branding.color} !important`],
                ['color', '#FFF !important'],
            ],
        },
        {
            selector: '.branding-bg-darken-hover:hover',
            properties: [
                ['background', `${Branding.darkenColor}`],
            ],
        },
        {
            selector: '.branding-bg-lighten-important',
            properties: [
                ['background', `${Branding.lightColor} !important`],
            ],
        },
        {
            selector: '.branding-bg-lighten-hover:hover',
            properties: [
                ['background', `${Branding.lightColor}`],
            ],
        },
        {
            selector: '.branding-bg-lighten-active:active',
            properties: [
                ['background', `${Branding.lightColor}`],
            ],
        },
        {
            selector: '.branding-color',
            properties: [
                ['color', `${Branding.color}`],
            ],
        },
        {
            selector: '.branding-color-hover:hover',
            properties: [
                ['color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.branding-color-darken-hover:hover',
            properties: [
                ['color', `${Branding.darkenColor}`],
            ],
        },
        {
            selector: '.branding-color-lighten-active:active',
            properties: [
                ['color', `${Branding.lightColor}`],
            ],
        },
        {
            selector: '.branding-border-bottom',
            properties: [
                ['border-bottom', `1px solid ${Branding.color}`],
            ],
        },
        {
            selector: '.branding-bold-border-bottom',
            properties: [
                ['border-bottom', `3px solid ${Branding.color}`],
            ],
        },
        {
            selector: '.branding-bg-hover:hover',
            properties: [
                ['background', `${Branding.color}`],
            ],
        },
        {
            selector: '.branding-bg-darken-hover:hover',
            properties: [
                ['background', `${Branding.darkenColor}`],
            ],
        },
        {
            selector: '.branding-bg-active:active',
            properties: [
                ['background', `${Branding.color}`],
            ],
        },
        {
            selector: '.branding-border-color-hover:hover',
            properties: [
                ['border', `1px solid ${Branding.color}`],
            ],
        },
        {
            selector: '.branding-border-color',
            properties: [
                ['border', `1px solid ${Branding.color}`],
            ],
        },
        {
            selector: '.branding-border-color-darken-hover:hover',
            properties: [
                ['border', `1px solid ${Branding.darkenColor}`],
            ],
        },
        {
            selector: '.branding-bg-highlight',
            properties: [
                ['background', `${Branding.highlightColor} !important`],
            ],
        },
    ];
};

const headerRules = function () {
    return [
        {
            selector: '#main_menu a:before',
            properties: [
                ['background', `${Branding.color}`],
            ],
        },
    ];
};

const listRules = function () {
    return [
        {
            selector: '.branding-list a:before',
            properties: [
                ['color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.branding-list a.active',
            properties: [
                ['color', `${Branding.color} !important`],
                ['border-bottom-color', Branding.color],
            ],
        },
        {
            selector: '.branding-list a:hover',
            properties: [
                ['color', `${Branding.darkenColor} !important`],
            ],
        },
    ];
};

const modalRules = function () {
    return [
        {
            selector: '.modalable-1 .modal .header-modal',
            properties: [
                ['background', `${Branding.color}`],
            ],
        },
    ];
};

const buttonRules = function () {
    return [
        {
            selector: '.icon-close',
            properties: [
                ['--color1', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.button-plus',
            properties: [
                ['background', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.button-1',
            properties: [
                // ['background', `${Branding.color} !important`]
            ],
        },
        {
            selector: '.button-1:hover',
            properties: [
                ['background', `${Branding.darkenColor} !important`],
            ],
        },
        {
            selector: '.button-4',
            properties: [
                ['background', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.button-4:hover',
            properties: [
                ['background', `${Branding.darkenColor} !important`],
            ],
        },
        {
            selector: '.button.button-icon:hover',
            properties: [
                ['background', `${Branding.color} !important`],
                ['border', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.button.button-icon:active',
            properties: [
                ['background', `${Branding.color} !important`],
                ['border', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.button.button-8:hover',
            properties: [
                ['color', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.button.button-8:hover .text-btn:before',
            properties: [
                ['background', `${Branding.color} !important`],
            ],
        },
        {
            selector: '.button.button-secondary:hover',
            properties: [
                ['color', `${Branding.color}`],
            ],
        },
        {
            selector: '.add-component-item:hover',
            properties: [
                ['color', `${Branding.color}`],
            ],
        },
        {
            selector: '.add-component-item:focus',
            properties: [
                ['color', `${Branding.color}`],
            ],
        },
    ];
};

const addCssRules = function (rules) {
    const $style = document.createElement('style');
    document.querySelector(':root').style.setProperty('--branding-color', Branding.color);
    document.querySelector(':root').style.setProperty('--branding-color-light', Branding.lightColor);
    document.querySelector(':root').style.setProperty('--branding-highlight', Branding.highlightColor);

    // Design system
    document.querySelector(':root').style.setProperty('--primary-base', Branding.color);
    document.querySelector(':root').style.setProperty(
        '--primary-darker',
        Color(Branding.primaryBranding.background).darken(0.1).hex(),
    );
    document.querySelector(':root').style.setProperty(
        '--primary-lighter',
        Color(Branding.primaryBranding.background).lighten(1).hex(),
    );
    document.head.appendChild($style);

    const { sheet } = $style;

    rules.forEach((rule) => {
        let string = '';

        rule.properties.forEach((p) => {
            string += `${p[0]}: ${p[1]};\n`;
        });

        sheet.insertRule(`${rule.selector}{${string}}\n`, sheet.cssRules.length);
    });
};
