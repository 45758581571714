<template>
    <transition name="modal-fade">
        <div
            v-if="isVisible"
            class="modal"
            :class="[position, {'sub-modal': isSubModal}]"
            @click.self="close()"
        >
            <transition name="modal-slide">
                <div
                    v-if="isVisible"
                    class="modal-wrapper"
                    :class="size"
                >
                    <div
                        v-show="hasMediaSlot"
                        class="modal-wrapper-media"
                    >
                        <slot name="media" />
                    </div>

                    <div class="modal-wrapper-content">
                        <slot name="full-header">
                            <div
                                v-show="hasHeaderSlot"
                                class="modal-wrapper-content-header"
                            >
                                <div class="modal-wrapper-content-header-title">
                                    <slot name="header" />
                                </div>

                                <HtButton
                                    @click.native="close()"
                                >
                                    <FontAwesomeIcon
                                        :icon="['fal', 'times']"
                                        class="icon-times"
                                    />
                                </HtButton>
                            </div>
                        </slot>

                        <div
                            v-show="hasDefaultSlot"
                            class="modal-wrapper-content-main"
                        >
                            <slot name="default" />
                        </div>

                        <div
                            v-show="hasFooterSlot && showFooter"
                            class="modal-wrapper-content-footer"
                        >
                            <div
                                v-show="hasExtraFooterSlot && showExtraFooter"
                                class="extra-footer"
                            >
                                <slot name="extra-footer" />
                            </div>

                            <div class="footer-container">
                                <slot name="footer" />
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'HtGenericModal',

    components: {
        HtButton,
    },

    inject: ['$validator'],

    shared: {
        modals: {},
    },

    props: {
        id: {
            type: String,
            default: () => null,
        },
        size: {
            type: String,
            default: () => 'small',
        },
        position: {
            type: String,
            default: () => 'right',
        },
        isSubModal: {
            type: Boolean,
            default: () => false,
        },
        showExtraFooter: {
            type: Boolean,
            default: () => true,
        },
        showFooter: {
            type: Boolean,
            default: () => true,
        },
    },

    data() {
        return {
            isVisible: false,
        };
    },

    computed: {
        hasMediaSlot() {
            return 'media' in this.$slots;
        },
        hasHeaderSlot() {
            return 'header' in this.$slots;
        },
        hasDefaultSlot() {
            return 'default' in this.$slots;
        },
        hasExtraFooterSlot() {
            return 'extra-footer' in this.$slots;
        },
        hasFooterSlot() {
            return 'footer' in this.$slots;
        },
    },

    mounted() {
        this.register();
        document.getElementById('app').appendChild(this.$el);
    },

    destroyed() {
        this.unregister();
    },

    methods: {
        sendEvent(name, callback) {
            if (name in this.$listeners) {
                return new Promise((resolve) => {
                    this.$emit(name, resolve);
                }).then(() => {
                    callback();
                });
            }

            callback();
        },
        open() {
            this.sendEvent('beforeOpen', () => {
                this.isVisible = true;
                this.$emit('onOpen');
            });
        },
        close() {
            this.sendEvent('beforeClose', () => {
                this.isVisible = false;
                this.$emit('onClose');
            });
        },
        register() {
            if (this.id !== null && this.shared.modals[this.id] === undefined) {
                this.shared.modals[this.id] = this;
            }
        },
        unregister() {
            this?.$el?.parentNode?.removeChild(this.$el);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import '~@/styles/design-system-colors.scss';

.modal {
    display: flex;
    justify-content: flex-end;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 998;
    position: fixed;
    background: #0000004D;

    &.left {
        justify-content: flex-start;
    }

    &-wrapper {
        height: 100%;
        display: flex;
        overflow: hidden;
        flex-direction: row;
        background-color: #fff;

        &.large {
            width: 100%;
        }

        &.medium {
            width: 700px;
        }

        &.small {
            width: 450px;
        }

        &-media {
            max-width: 32%;
            min-width: 350px;
        }

        &-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            width: 100%;

            &-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 40px;
                background-color: white;
                z-index: 5;

                &-title {
                    font-family: $lato;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    color: $neutral-600;
                }

                & .ht-button {
                    height: 40px;
                    width: 40px;
                    background-color: $neutral-100;
                    border: none;
                    border-radius: 8px;
                    color: $neutral-black;
                    align-items: center;
                }
            }

            &-main {
                flex: 1;
                width: 100%;
                padding: 0 40px 40px 40px;
                overflow-y: auto;
            }

            &-footer {
                .footer-container {
                    padding: 40px;
                    background-color: white;
                    border-top: 1px solid $neutral-200;
                }
            }
        }
    }
}

.modal-fade-enter-active, .modal-fade-leave-active {
    transition: all 0.3s;
    transition-timing-function: cubic-bezier(0.04, 1.15, 0.98, 0.98);
}

.modal-fade-enter, .modal-fade-leave-to {
    opacity: 0;
}

.modal-slide-enter-active, .modal-slide-leave-active {
    transition: all 0.3s;
    transition-timing-function: cubic-bezier(0.04, 1.15, 0.98, 0.98);
    transform: translate3d(0, 0, 0);
}

.modal-slide-enter, .modal-slide-leave-to {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
}

// responsive
@media (max-width: $tablet) {
    .modal {
        &-wrapper {
            &.medium {
                width: 100%;
            }
        }
    }
}

@media (max-width: $phone) {
    .modal {
        &-wrapper {
            flex-direction: column;
            overflow-y: auto;

            &.small {
                width: 100%;
            }

            &-media {
                max-width: 100%;
                max-height: 120px;
            }

            &-content {
                &-header {
                    position: sticky;
                    top: 0;
                    padding: 24px 20px 24px 20px;
                }

                &-main {
                    padding: 0 20px 20px 20px;
                    overflow-y: visible;
                }

                &-footer {
                    position: sticky;
                    bottom: 0;

                    .footer-container {
                        padding: 20px;
                    }
                }
            }
        }
    }
}
</style>
