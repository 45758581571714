<template>
    <div v-if="companyUserNotificationSettings.isLoaded()">
        <div class="row">
            <HtFormInput
                :id="'search_user'"
                v-model.trim="search"
                :name="'search-user'"
                :placeholder="translate('Search...')"
                class="col-md-4"
            >
                <template #extra>
                    <div class="px-4">
                        <FontAwesomeIcon
                            size="1x"
                            :icon="['far', 'search']"
                        />
                    </div>
                </template>
            </HtFormInput>
        </div>
        <HtTable
            :items="companyUserNotificationSettings.models"
            :columns="columns"
            :empty-message="'You have no notifications yet...'"
            :external-search="search"
        >
            <template #cell(is_enabled_email)="scope">
                <div class="d-flex">
                    <Checkable
                        v-model="scope.item.is_enabled_email"
                        simple
                    />
                </div>
            </template>
            <template #cell(is_enabled_sms)="scope">
                <div class="d-flex">
                    <Checkable
                        v-model="scope.item.is_enabled_sms"
                        simple
                    />
                </div>
            </template>
        </HtTable>
        <div class="row-line">
            <Button
                :state="buttonState"
                @click="onSave"
            >
                <t>Save</t>
            </Button>
        </div>
    </div>
</template>
<script>
import CompanyUserNotificationSettings from '@/models/CompanyUserNotificationSettings';
import CompanyUserNotificationSettingsCollection from '@/models/CompanyUserNotificationSettingsCollection';
import HtTable from '@/components/globals/HtTable.vue';

export default {
    name: 'UserNotificationList',
    components: { HtTable },
    props: {
        company_user_id: {
            type: [String, Number],
            required: true,
        },
        type: {
            type: String,
            default: () => CompanyUserNotificationSettings.TYPE_SYSTEM,
        },
    },

    data() {
        return {
            companyUserNotificationSettings: new CompanyUserNotificationSettingsCollection([
                'id',
                'company_user_id',
                'title',
                'is_enabled_email',
                'is_enabled_sms',
                'type',
                'user_resourceable_type',
            ]).where([
                ['company_user_id', '=', this.company_user_id],
                ['type', '=', this.type],
                ['is_viewable', '=', 1],
            ]),
            filterColumn: [],
            filtered: [],
            search: null,
        };
    },

    computed: {
        buttonState() {
            return this.companyUserNotificationSettings._state.isSaving ? 'loading' : 'idle';
        },

        columns() {
            if (this.type === CompanyUserNotificationSettings.TYPE_SYSTEM) {
                return [
                    {
                        label: this.translate('Name'),
                        key: 'title_translated',
                        search: true,
                    },
                    {
                        label: this.translate('E-mail'),
                        key: 'is_enabled_email',
                        sortable: false,
                    },
                    {
                        label: this.translate('SMS'),
                        key: 'is_enabled_sms',
                        sortable: false,
                    },
                ];
            }
            return [
                {
                    label: this.translate('Name'),
                    key: 'resource_translated',
                },
                {
                    label: this.translate('E-mail'),
                    key: 'is_enabled_email',
                    sortable: false,
                },
                {
                    label: this.translate('SMS'),
                    key: 'is_enabled_sms',
                    sortable: false,
                },
            ];
        },
    },

    created() {
        this.companyUserNotificationSettings.get();
    },

    methods: {
        onSave() {
            this.companyUserNotificationSettings.save();
        },

        setFiltered(val) {
            this.filtered = val;
        },
    },
};
</script>
