<template>
    <form class="form-2">
        <div class="mb-4 font-weight-bold">
            <t>Select the requirement</t>
        </div>

        <div class="mb-4">
            <t>The requirements identified as linked to a survey are available</t>
        </div>

        <HtFormSelect
            :id="fieldName"
            v-model="question.resourceable_id"
            v-validate.disable="'required'"
            :name="fieldName"
            :options="availableRequirements"
            :data-vv-as="translate('requirement')"
            @input="onChange"
        />
    </form>
</template>

<script>
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import CompanyRequirementCategory from '@/models/CompanyRequirementCategory';

export default {
    name: 'QuestionRequirement',

    components: {
        HtFormSelect,
    },

    inject: ['$validator'],

    props: {
        value: {
            type: Array,
            required: true,
        },
        question: {
            type: Object,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
        contents: {
            type: Array,
            required: true,
        },
        questions: {
            type: Array,
            required: true,
        },
        isMandatory: {
            type: Boolean,
            required: false,
        },
    },

    data() {
        return {
            fieldName: this.$Utils.getUniqName('requirement-select'),
            requirementCategories: [],
        };
    },

    computed: {
        requirementIds() {
            return this.questions.filter((question) => question.resourceable_id !== null).map((question) => question.resourceable_id);
        },

        availableRequirements() {
            return this.filterRequirement(this.requirementCategories);
        },
    },

    created() {
        this.question.resourceable_type = CompanyRequirementCategory.RESOURCEABLE_CLASS;

        this.$http.post('miscellaneous/requirement-without-survey', {
            question_id: this.question.id,
        }).then(({ data }) => {
            const mappingRequirement = data.resources.map((requirement) => ({
                name: requirement.name,
                id: requirement.id,
                is_mandatory: requirement.is_mandatory,
            }));

            this.requirementCategories = this.filterRequirement(mappingRequirement);

            if (!this.question.resourceable_id) {
                this.onChange(this.requirementCategories[0].id);
                this.question.resourceable_id = this.requirementCategories[0].id;
            }
        });
    },

    methods: {
        filterRequirement(requirements) {
            return requirements.filter((requirement) => {
                if (requirement.id === this.question.resourceable_id || this.question.id) {
                    return true;
                }
                return !this.requirementIds.includes(requirement.id);
            });
        },
        onChange(requirementId) {
            const requirement = this.requirementCategories.find((requirement) => requirement.id === requirementId);
            this.$emit('update:is-mandatory', requirement.is_mandatory);
        },
    },
};
</script>
