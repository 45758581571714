<template>
    <a
        href="#"
        class="ht-card-link"
    >
        <div
            class="icon"
            :style="`background-color:${customColor}4d!important;color:${customColor}!important;`"
        >
            <slot name="icon" />
        </div>
        <div class="title">
            <slot name="title" />
        </div>
        <div class="description">
            <slot name="description" />
        </div>
        <div
            class="action"
            :style="`color:${customColor}!important;`"
        >
            <slot name="action" /> &gt;
        </div>
    </a>
</template>

<script>
export default {
    name: 'HtActionCard',

    inject: ['$validator'],

    props: {
        title: {
            type: String,
            default: '',
        },
        customColor: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.ht-card-link {
   position: relative;
   display: flex;
   flex-direction: column;
   color: inherit;
   padding: 15px 20px 30px;
   background-color: #f9f9f8;
   border-radius: 10px;
   min-height: 200px;
   border: 1px solid transparent;
   text-decoration: none!important;
   align-content: stretch;
   transition: box-shadow .3s;
   cursor: pointer;

  &:hover {
    box-shadow: 0 0 20px rgba(33,33,33,.1);
  }

  .icon {
    height: 35px;
    width: 35px;
    max-height: 35px;
    max-width: 35px;
    margin-bottom: 10px;
    background-color: rgba($color-primary, 0.3);
    color: $color-primary;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;

    > svg {
      max-width: 16px;
      max-height: 16px;
      background: transparent;
      margin: 0;
    }
  }

  .title {
    flex: 0;
    font-size: 1.3em;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .description {
    flex: 1;
    margin-bottom: 10px;
  }

  .action {
    height: 30px;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 20px;
    flex: 0;
    display: flex;
    align-items: end;
    justify-content: end;
    justify-items: end;
    color: $color-primary;
    font-weight: 600;
  }
}
</style>
