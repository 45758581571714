<template>
    <div>
        <HtTable
            v-if="isLoaded"
            :items="configurations"
            :columns="columns"
            class="mt-5"
            @onCellClicked="onClick"
        >
            <template #cell(is_enabled)="scope">
                <t>{{ scope.item.is_enabled ? 'Yes' : 'No' }}</t>
            </template>
        </HtTable>

        <IntegrationEditModal
            ref="integration-edit-modal"
            @on-fetch-config="fetchConfigurations"
        />
    </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';
import IntegrationEditModal from './IntegrationEditModal.vue';

export default {
    name: 'IntegrationsTable',
    components: {
        IntegrationEditModal,
    },
    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'key',
                },
                {
                    label: this.translate('Enabled'),
                    key: 'is_enabled',
                },
            ],
        };
    },
    computed: {
        configurations() {
            return clonedeep(this.$store.getters['configuration/configurations/getConfigurations']);
        },
        isLoaded() {
            return this.$store.getters['configuration/configurations/isLoaded'];
        },
        isExpired() {
            return this.isLoaded === false
                || this.$store.getters['configuration/configurations/getLoadedAt'] < this.$moment().subtract(5, 'minutes');
        },
    },
    created() {
        if (this.isExpired) {
            this.fetchConfigurations();
        }
    },
    methods: {
        onClick(row) {
            this.$refs['integration-edit-modal'].open(row.item.id);
        },
        fetchConfigurations() {
            this.$store.dispatch('configuration/configurations/fetchConfigurations');
        },
        onSave() {
            this.fetchConfigurations();
        },
    },
};
</script>
