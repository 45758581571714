import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyProgramNotificationTemplate from './CompanyProgramNotificationTemplate';

export default class CompanyProgramNotificationTemplateCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyProgramNotificationTemplate,
        };
    }
}
