import Vue from 'vue';

/**
 * @typedef {Object} Software
 * @property {number} id
 * @property {number} ht_software_type_id
 * @property {{id: number, name: string, image: string, resource: string}} type
 * @property {{enable_account_creation: boolean}|undefined} gsuite
 * @property {Resource} resource
 */

/**
 * @typedef {{data: {data: Software}}} SoftwareResourceResponse
 * @typedef {{data: {data: Software[]}}} SoftwareCollectionResponse
 */

export default {
    /**
     * @param {any} data
     * @returns {Promise<SoftwareCollectionResponse>}
     */
    getAll(data = {}) {
        return Vue.prototype.$http.post('configuration/softwares/list', data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<SoftwareResourceResponse>}
     */
    get(id, config = {}) {
        return Vue.prototype.$http.get(`configuration/softwares/${id}`, config);
    },

    /**
     * @param {any} data
     * @returns {Promise<SoftwareResourceResponse>}
     */
    create(data = {}) {
        return Vue.prototype.$http.post('configuration/softwares', data);
    },

    /**
     * @param {number|string} id
     * @param {any} data
     * @returns {Promise<SoftwareResourceResponse>}
     */
    update(id, data = {}) {
        return Vue.prototype.$http.post(`configuration/softwares/${id}`, data);
    },

    /**
     * @param {number|string} id
     * @param {import("axios").AxiosRequestConfig} config
     * @returns {Promise<{}>}
     */
    delete(id, config = {}) {
        return Vue.prototype.$http.delete(`configuration/softwares/${id}`, config);
    },

    /**
     * @param {number|string} id
     * @param {any} config
     * @returns {Promise<SoftwareResourceResponse>}
     */
    replicate(id, config = {}) {
        return Vue.prototype.$http.post(`configuration/softwares/${id}/replicate`, config);
    },
};
