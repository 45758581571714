<template>
    <transition name="fade">
        <div
            v-show="errors.any(scope) || appendErrors"
            class="notif notif-error"
        >
            <svg class="icon icon-error"><use xlink:href="#icon-error" /></svg>
            <div>
                <p
                    v-for="errorMessage in errors.all()"
                    class="full-width"
                >
                    <span>{{ errorMessage }}</span>
                </p>

                <p class="full-width">
                    <t>{{ appendErrors }}</t>
                </p>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'ErrorDisplay',
    inject: ['$validator'],

    props: {
        scope: { type: String, required: false },
        appendErrors: { type: String, required: false },
    },

    watch: {
        'errors.items': function (errors) {
            if (errors && errors.length) this.$Utils.firstParentScrollableToTop(this.$el);
        },
    },

    created() {
        this.errors.clear();
    },
};
</script>

// TODO: readd scopeddari
<style lang="scss">
	@import '~@/styles/var';

	.notif{
		display: flex;
		align-items: center;
	    padding: 0.5em 1em;
    	margin-bottom: 2em;
		border-radius: 5px;

		&.show-notif{
			display: flex;
		}
		&.notif-error{
			background: $primary-darker;
		}
		&.notif-valid{
			background: $valid;
		}
		p{
			color: #fff;
		}
		.icon{
			width: 56px;
			height: 56px;
			margin-right: 30px;
		}
	}
</style>
