<template>
    <HtFormMultiSelect
        :id="id"
        :name="id"
        :label="label"
        :is-single="true"
        :is-searchable="false"
        :allow-empty="false"
        :specific-key="'value'"
        :track-by="'value'"
        :options="options"
        :value="selectedLanguage"
        :disabled="disabled"
        class="language-select"
        @input="onSelect"
    >
        <template
            slot="singleLabel"
            slot-scope="{ option }"
        >
            <div class="option">
                <span class="rounded-flag">
                    <country-flag
                        :country="option.codeFlag"
                        size="big"
                    />
                </span>
                <span>{{ option.text }}</span>
            </div>
        </template>
        <template
            slot="option"
            slot-scope="{ option }"
        >
            <div class="option">
                <span class="rounded-flag">
                    <country-flag
                        :country="option.codeFlag"
                        size="big"
                    />
                </span>
                <span>{{ option.text }}</span>
            </div>
        </template>
    </HtFormMultiSelect>
</template>

<script>
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import I18n from '@/modules/i18n/I18n';
import HtFormMixin from './HtFormMixin';

export default {
    name: 'HtLanguageWithOptionsSelect',

    components: {
        HtFormMultiSelect,
    },

    mixins: [
        HtFormMixin,
    ],

    props: {
        value: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default() {
                return I18n.translate('Language');
            },
        },
        options: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            selectedLanguage: null,
        };
    },

    created() {
        this.selectedLanguage = this.value;
    },

    methods: {
        onSelect(selectedLanguage) {
            this.selectedLanguage = selectedLanguage;
            this.$emit('input', selectedLanguage);
        },
    },
};
</script>

<style lang="scss">

.language-select {

    .multiselect__single {
        padding: 0;
    }

    .multiselect__tags {
        padding-left: 15px;
    }

    .multiselect__option {
        padding: 0.7rem;
        padding-left: 15px;
    }

    .multiselect--active {
        .multiselect__tags {
            padding-left: 15px;
        }

        .multiselect__single {
            padding: 0;
        }
    }

    .option {
        display: flex;
        align-items: center;
        gap: 1rem;

        .rounded-flag {
            transform: scale(0.75);
            -ms-transform: scale(0.75);
            -webkit-transform: scale(0.75);
            -moz-transform: scale(0.75);
        }
    }
}

</style>
