<template>
    <StepperTabLayout>
        <template #main>
            <HtTablePaginator
                :url="url"
                :filters="formattedfilters"
                :columns="columns"
                :enable-pagination="true"
                :per-page="pageSize"
                :external-search="search"
                :clickable="entity.is_editable"
                :empty-message="translate('No entries.')"
                @onCellClicked="onCellClicked"
            >
                <template #cell(trash)="{ item }">
                    <div
                        v-if="entity.is_editable && $canDelete('ModelCompanyEntity', { id: entity.id })"
                        data-cy="delete-entity-value"
                        @click.stop="showDestroyModal(item)"
                    >
                        <FontAwesomeIcon :icon="['far', 'trash-alt']" />
                    </div>
                </template>
            </HtTablePaginator>
        </template>
        <template #modalables>
            <EntitiesValueEditModal
                ref="modalableEdit"
                :entity-id="entity.id"
                :relation-name="relationName"
                :api-resource="apiResource"
                @saved="updateValues"
            />
            <modalable
                ref="modalableConfirmDeletion"
                class="modal modalable-4"
            >
                <p v-if="currentItem">
                    <t :value="currentItem.name">
                        Do you really want to permanently delete {value}?
                    </t>
                </p>
                <div class="row-line middle">
                    <Button
                        class="black"
                        cypress="cancel-delete"
                        @click="$refs.modalableConfirmDeletion.close()"
                    >
                        <t>Cancel</t>
                    </Button>
                    <Button
                        class="red"
                        :state="buttonState"
                        cypress="confirm-delete"
                        @click="onDestroy()"
                    >
                        <t>Delete</t>
                    </Button>
                </div>
            </modalable>
        </template>
        <template #buttonsFooter>
            <Button
                class="mr-4 negative"
                @click="$emit('on-back')"
            >
                <t>Back</t>
            </Button>
            <Button
                v-if="entity.is_editable"
                @click="openAddEntryModal"
            >
                <t>Add an entry</t>
            </Button>
        </template>
    </StepperTabLayout>
</template>

<script>
import api from '@/store/api';
import Notifier from '@/Notifier';
import I18n from '@/modules/i18n/I18n';
import HtTablePaginator from '@/components/globals/HtTablePaginator.vue';
import htTablePaginationEventBus from '@/eventBus/htTablePagination';
import EntitiesValueEditModal from '../EntitiesValueEditModal.vue';

export default {
    name: 'ListEntityValues',
    components: {
        HtTablePaginator,
        EntitiesValueEditModal,
    },
    props: {
        entity: {
            type: Object,
            required: true,
        },
        relationName: {
            type: String,
            default: () => '',
        },
        apiResource: {
            type: String,
            required: true,
            validator(value) {
                const validValues = [
                    'jobPositions',
                    'departments',
                    'offices',
                    'contracts',
                    'customs',
                ];
                return validValues.includes(value);
            },
        },
        filters: {
            type: Object,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            search: null,
            currentItem: null,
            pageSize: 20,
            isDeleting: false,
            defaultValues: {
                company_office: {
                    is_heyteam: false,
                },
                company_contract: {
                    is_heyteam: false,
                    has_probation: false,
                },
                company_job_position: {
                    is_heyteam: false,
                },
            },
        };
    },

    computed: {
        url() {
            return `${api.endpoints.CONFIGURATION_ENTITIES}/${this.entity.id}/values`;
        },
        buttonState() {
            return this.isDeleting ? 'loading' : 'idle';
        },
        formattedfilters() {
            const newFilters = {};
            Object.keys(this.filters).forEach((key) => {
                newFilters[`filter[${key}]`] = this.filters[key];
            });
            return newFilters;
        },
    },
    methods: {
        openAddEntryModal() {
            this.currentItem = {
                ...(this.defaultValues[this.entity.slug] ?? {}),
            };

            this.$refs.modalableEdit.open(this.currentItem);
        },
        onCellClicked(row) {
            if (!this.entity.is_editable) {
                return;
            }

            this.currentItem = { ...row.item };
            this.$refs.modalableEdit.open(this.currentItem);
        },

        /**
         * Ouvre la modale de suppression
         *
         * @param {object} currentItem Value a supprimer
         * @returns {void}
         */
        showDestroyModal(currentItem) {
            this.currentItem = currentItem;
            this.$refs.modalableConfirmDeletion.open();
        },
        updateValues() {
            htTablePaginationEventBus.$emit('refresh');
        },
        onDestroy() {
            if (!this.isDeleting) {
                this.isDeleting = true;
                api.configuration[this.apiResource]
                    .delete(this.currentItem.id)
                    .then(() => {
                        Notifier.getInstance('App').showInfo(
                            I18n.translate('Successfully deleted'),
                        );
                        htTablePaginationEventBus.$emit('refresh');
                        // obligé de forcer l'expiration, sinon le store contient des entités périmées
                        this.$store.dispatch('entities/forceEntitiesExpiration');
                    })
                    .catch(() => {
                        this.$Notifier('App').showError(
                            I18n.translate('A problem occurred while trying to delete this value'),
                        );
                    })
                    .finally(() => {
                        this.$refs.modalableConfirmDeletion.close();
                        this.currentItem = null;
                        this.isDeleting = false;
                    });
            }
        },
    },
};
</script>
