import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyCustomize from './CompanyCustomize';

export default class CompanyCustomizeCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyCustomize,
        };
    }
}
