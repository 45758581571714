<template>
    <div class="h-100 event-form">
        <HtTabs>
            <HtTab
                v-for="(tab, index) in tabs"
                :key="index"
                :ref="tab.ref"
                :title="tab.title"
                :disabled="tab.disabled"
                :no-body="tab.noBody"
            >
                <div class="empty">
                    <AIModeSelector
                        v-if="isAIEnabled && !mode"
                        v-model="mode"
                    />

                    <AIModeSwitcher
                        v-if="isAIEnabled && mode"
                        v-model="mode"
                    />

                    <PlanningEventTemplateEdit
                        v-if="mode === 'builder'"
                        :id="Number($route.params.id) || null"
                        @onSave="routeToEventList"
                        @onDelete="routeToEventList"
                    />

                    <PlanningEventTemplateAIGenerator
                        v-if="mode === 'ai'"
                        @onSave="routeToEventList"
                        @onDelete="routeToEventList"
                    />
                </div>
            </HtTab>
            <template #headerRightContent>
                <slot name="headerRightContent">
                    <HtIconClose @click="routeToEventList" />
                </slot>
            </template>
        </HtTabs>
    </div>
</template>

<script>
import HtIconClose from '@/components/icons/HtIconClose.vue';
import PlanningEventTemplateEdit from '@/components/resources/planningEvent/PlanningEventTemplateEdit.vue';
import PlanningEventTemplateAIGenerator
    from '@/components/resources/planningEvent/PlanningEventTemplateAIGenerator.vue';
import AIModeSelector from '@/components/globals/AI/AIModeSelector.vue';
import AIModeSwitcher from '@/components/globals/AI/AIModeSwitcher.vue';

export default {
    components: {
        AIModeSwitcher,
        AIModeSelector,
        PlanningEventTemplateAIGenerator,
        HtIconClose,
        PlanningEventTemplateEdit,
    },

    data() {
        return {
            mode: null,
            pageSubtitles: [this.translate('Resources'), this.translate('Events')],
            tabs: [
                {
                    ref: 'settings',
                    title: this.translate('Event parameters'),
                    disabled: false,
                    noBody: false,
                },
            ],
        };
    },

    computed: {
        isAIEnabled() {
            const settings = this.shared.session.company.company_knowledge_settings;

            return !this.$route.params.id
                && settings.is_ai_feature_enabled === true
                && settings.is_ai_bot_enabled === true;
        },
    },

    mounted() {
        if (!this.isAIEnabled || this.$route.params.id) {
            this.mode = 'builder';
        }
    },

    methods: {
        setMode(mode) {
            this.mode = mode;
        },

        routeToEventList() {
            this.$router.push({ name: 'ResourcesEvents' });
        },
    },
};
</script>

<style lang="scss" scoped>
.event-form {
    .ht-card-title {
        background-color: white;
    }

    .empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    .subtitle {
        display: flex;
        align-items: center;
        height: 100%;
    }
}
</style>
