<template>
    <div v-if="companyUserEquipmentCollection.isLoaded()">
        <HtAccordion
            :title="translate('Equipment | Equipments', {count: companyUserEquipmentCollection.models.length})"
            :count="companyUserEquipmentCollection.models.length"
            :has-button="companyUserEquipmentCollection.models.length === 0"
            :button-label="addButtonLabel"
            :is-branding="false"
            cypress="equipment"
            @buttonClick="openSelectModal()"
        >
            <template v-if="companyUserEquipmentCollection.models.length > 0">
                <HtTable
                    :items="companyUserEquipmentCollection.models"
                    :columns="columns"
                    cypress="equipment-table"
                    @onCellClicked="openEditModal"
                />
                <div class="mt-4 text-center">
                    <Button
                        type="clear"
                        cypress="equipment-add-button"
                        @click="openSelectModal()"
                    >
                        <slot :name="'buttonText'">
                            {{ translate(addButtonLabel) }}
                        </slot>
                    </Button>
                </div>
            </template>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No Equipment added</t>
                </strong>
            </div>
        </HtAccordion>

        <Modalable
            ref="modalableSelect"
            :mode="2"
            class="modalable-1 medium"
        >
            <UserEquipmentSelect
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                :selected-ids="selectedItemIds"
                @onNew="onNew()"
            />
        </Modalable>

        <Modalable
            ref="modalableEdit"
            :mode="2"
            class="modalable-1 medium"
        >
            <UserEquipmentEdit
                :id="id"
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                @onUpdate="onUpdate()"
                @onDelete="onDelete()"
            />
        </Modalable>
    </div>
</template>

<script>
import CompanyUserEquipmentCollection from '@/models/CompanyUserEquipmentCollection';
import UserEquipmentSelect from '@/components/pages/dashboard/modals/equipment/UserEquipmentSelect.vue';
import UserEquipmentEdit from '@/components/pages/dashboard/modals/equipment/UserEquipmentEdit.vue';
import HtAccordion from '@/components/globals/HtAccordion.vue';

export default {
    name: 'UserEquipmentFlow',
    components: {
        UserEquipmentSelect,
        UserEquipmentEdit,
        HtAccordion,
    },

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
    },

    data() {
        return {
            id: null,
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                },
            ],
            addButtonLabel: this.translate('Add equipment'),
            companyUserEquipmentCollection: new CompanyUserEquipmentCollection([
                'id', 'company_user_id', 'company_user_program_id', 'company_equipment_id',
            ]).with({
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'lastname'])
                        .with({
                            companyLanguage: (query) => {
                                query.select(['id', 'key']);
                            },
                        });
                },
                companyEquipment: (query) => {
                    query
                        .select(['id'])
                        .with({
                            resource: (query) => {
                                query.select(['id', 'name']).withTrashed();
                            },
                        }).withTrashed();
                },
            }).where([
                ['company_user_program_id', '=', this.companyUserProgramId],
            ]),
        };
    },

    computed: {
        selectedItemIds() {
            return this.companyUserEquipmentCollection.$.models.map((item) => item.company_equipment_id);
        },
    },

    created() {
        this.companyUserEquipmentCollection.get().then(() => {
            this.$emit('on-load', this.companyUserEquipmentCollection.$.models.length);
        });
    },

    methods: {
        onNew() {
            this.companyUserEquipmentCollection.get();
        },
        onDelete() {
            this.companyUserEquipmentCollection.get();
        },
        onUpdate() {
            this.companyUserEquipmentCollection.get();
        },
        openEditModal(row) {
            this.id = row.item.id;
            this.$refs.modalableEdit.open();
        },
        openSelectModal() {
            this.$refs.modalableSelect.open();
        },
    },
};
</script>
