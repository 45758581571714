<template>
    <div class="general-loading-modal-container animation">
        <div class="animation-title">
            <t>Please wait</t>...
        </div>
        <p><t>{{ message }}</t></p>
        <div
            class="animation-bar"
            :style="`width:${total_bar_size}em;`"
        >
            <div
                class="inside-bg branding-bg"
                :class="{completed: is_animation_done}"
                :style="'width: '+ animation_progress + 'em;'"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'GenericLoadingModal',
    props: {
        message: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            is_animation_done: false,
            total_bar_size: 20,
            animation_progress: 0,
        };
    },
    created() {
        this.animate();
    },
    methods: {
        animate() {
            const interval = setInterval(() => {
                if (this.animation_progress < this.total_bar_size) {
                    const update = Math.random() * 2;
                    if (this.animation_progress + update <= this.total_bar_size) {
                        this.animation_progress += update;
                    // To prevent bar to exceed container size
                    } else {
                        this.animation_progress = this.total_bar_size;
                        this.is_animation_done = true;
                    }
                } else {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                    clearInterval(interval);
                }
            }, 100);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";

.animation {
    max-width: 430px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 1s;
    margin: 0 auto 20px;

    &-title {
        font-size: 2.2rem;
        font-weight: bold;
        margin: 40px 0 15px;
    }

    &-bar {
        border-radius: 10px;
        height: 0.4em;
        margin-top: 2em;
        margin-bottom: 1em;

        .completed {
            background: $semantic-success;
        }
    }
}
</style>
