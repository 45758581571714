import Vue from 'vue';
import Model from '@tony.caron/node-model-proxy/Model';
import CompanyField from './CompanyField';
import CompanyDepartmentCollection from './CompanyDepartmentCollection';

export default class CompanyDepartment extends Model {
    modelConfig() {
        return {
            collection: CompanyDepartmentCollection,
        };
    }

    modelCustomAttributes() {
        return {
            name_translated: null,
        };
    }

    modelAccessors() {
        return {
            name_translated: () => (this.is_heyteam ? Vue.prototype.$I18n.translate(this.name) : this.name),
        };
    }

    modelRelations() {
        return {
            customFields: () => this.hasMany(CompanyField),
        };
    }

    modelEvents() {
        return {
            loaded: () => {
                Vue.prototype.$modelUtils.createCustomFields(this, 'company_department');
            },
        };
    }
}
