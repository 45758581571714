import Vue from 'vue';

export default {
    store(data = {}) {
        return Vue.prototype.$http.post('users/planning-events/notes', data);
    },

    update(data = {}) {
        return Vue.prototype.$http.put('users/planning-events/notes', data);
    },

    delete(id) {
        return Vue.prototype.$http.delete(`users/planning-events/notes/${id}`);
    },
};
