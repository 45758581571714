export default {
    name: 'Statistics',
    image: 'statistics.png',
    fullPageBorder: true,
    content: `
        <section class="ht-section-statistics">
            <div class="container">
                <div class="image">
                    <img src="https://via.placeholder.com/400x600" alt="Placeholder" width="400" height="600" />
                </div>
                <div class="content">
                    <h2 class="title">Lorem ipsum dolor sit amet</h2>
                    <div class="stats">
                        <div class="stat">
                            <span class="number">3214</span>
                            <span class="subtitle">Lorem ipsum</span>
                        </div>
                        <div class="stat">
                            <span class="number">847</span>
                            <span class="subtitle">Lorem</span>
                        </div>
                        <div class="stat">
                            <span class="number">+654</span>
                            <span class="subtitle">Lorem</span>
                        </div>
                        <div class="stat">
                            <span class="number">958</span>
                            <span class="subtitle">Lorem ipsum dolor</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    `,
    css: `
        .ht-section-statistics {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 50px 40px;
            background: url('https://media.istockphoto.com/id/1952253409/fr/photo/skyline-paris.jpg?s=2048x2048&w=is&k=20&c=KSo6bS3smUcELz8-sRoMcWfMFcZr0E6Oy75j4I5uIyg=') no-repeat center center fixed;
            background-size: cover;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 1000px;
        }

        .image img {
            display: block;
            width: 100%;
            max-width: 400px;
            height: auto;
            border-radius : 12px;
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 20px;
            flex: 1;
        }

        .title {
            font-size: 32px;
            line-height: 36px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 100px;
        }

        .stats {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
        }

        .stat {
            background: white;
            padding: 20px;
            text-align: center;
            border-radius : 12px;
        }

        .number {
            display: block;
            font-size: 3em;
            font-weight: bold;
            color: var(--primary-base);
        }

        .subtitle {
            font-size: 1em;
            color: #666;
        }

        @media (max-width: 768px) {
            .container {
                flex-direction: column;
                text-align: center;
            }

            .content {
                align-items: center;
                padding: 20px 0;
            }

            .stats {
                grid-template-columns: 1fr; /* Single column on mobile */
            }

            .stat {
                width: 80%; /* Full width in mobile view */
                margin: 10px auto; /* Centers stats */
            }
        }
    `,
};
