<template>
    <div class="d-flex">
        <div
            v-tooltip.right-end="translate('Program recipient')"
            class="bloc-completion"
        >
            <FontAwesomeIcon
                :icon="['fal', 'user']"
                class="icon"
            />

            {{ enrolleeCompletion }} %
        </div>
        <div
            v-tooltip.right-end="translate('Team')"
            class="bloc-completion"
        >
            <FontAwesomeIcon
                :icon="['fal', 'user-friends']"
                class="icon"
            />

            {{ participantsCompletion }} %
        </div>
    </div>
</template>

<script>
export default {
    name: 'HtPercentageCompletionProgram',
    props: {
        enrolleeCompletion: {
            type: Number,
            required: true,
        },
        participantsCompletion: {
            type: Number,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.bloc-completion {
    width: 70px;
    white-space: nowrap;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    padding: 6px;
    margin-right: 5px;
    gap: 4px;

    background: #FAFAFA;
    border: 1px solid #EAECEC;
    border-radius: 4px;
}
</style>
