<template>
    <div class="wrapper">
        <div class="item mb-3">
            {{ firstname }} {{ lastname }}
        </div>
        <Downloadable
            class="item"
            :company-file="file"
        />
    </div>
</template>

<script>
import Downloadable from '@/components/globals/Downloadable.vue';
import CompanyFile from '@/models/CompanyFile';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';

export default {
    name: 'ResultFileUploadItem',
    components: { Downloadable },

    props: {
        fileId: {
            type: Number,
            required: true,
        },
        fileLink: {
            type: String,
            required: true,
        },
        fileName: {
            type: String,
            required: true,
        },
        fileLinkPreview: {
            type: String,
            required: true,
        },
        fileDetectedMimeType: {
            type: String,
            required: true,
        },
        firstname: {
            type: String,
            required: true,
        },
        lastname: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
    },

    computed: {
        /**
         * @returns {CompanyFile}
         */
        file() {
            const file = new CompanyFile(UtilsCompanyFile.allowedFullFields());
            file.id = this.fileId;
            file.link = this.fileLink;
            file.original_name = this.fileName;
            file.link_preview = this.fileLinkPreview;
            file.detected_mime_type = this.fileDetectedMimeType;

            return file;
        },
    },
};
</script>
<style lang="scss" scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
</style>
