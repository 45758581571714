<template>
    <div>
        <div class="holding-dashboard-title">
            <t>Welcome to your account manager</t>
        </div>
        <div
            v-if="companiesLoaded"
            class="row"
        >
            <div class="col-md-4">
                <DashboardBlock>
                    <HoldingInfo />
                </DashboardBlock>
            </div>
            <div class="col-md-4">
                <div class="widget-header">
                    <t>Collaborators</t>
                </div>
                <DashboardBlock
                    v-for="company in childCompanies"
                    :key="company.id"
                    class="company"
                >
                    <HoldingWidget>
                        <template #miniblock>
                            <img :src="getImage(company)">
                        </template>
                        <template #title>
                            <t :count="company.users_count">
                                {count} collaborator | {count} collaborators
                            </t>
                        </template>
                        <template #subtitle>
                            {{ company.name }}
                        </template>
                    </HoldingWidget>
                </DashboardBlock>
            </div>
            <div class="col-md-4">
                <div class="widget-header">
                    <t>Companies</t>
                </div>
                <DashboardBlock>
                    <HoldingWidget>
                        <template #miniblock>
                            <span class="company-counter">
                                {{ getCompaniesAmount }}
                            </span>
                        </template>
                        <template #title>
                            <t :count="getCompaniesAmount">
                                Company | Companies
                            </t>
                        </template>
                        <template #subtitle>
                            {{ holding.name }}
                        </template>
                    </HoldingWidget>
                </DashboardBlock>
            </div>
        </div>
    </div>
</template>

<script>
import HoldingWidget from '@/components/holding/HoldingWidget.vue';
import HoldingInfo from '@/components/holding/HoldingInfo.vue';
import DashboardBlock from '@/components/holding/DashboardBlock.vue';
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'HoldingDashboard',

    created() {
        this.$store.dispatch('companies/fetchCompanies');
    },
    computed: {
        ...mapState('companies', [
            'companiesLoaded',
        ]),
        ...mapGetters('companies', [
            'holding',
            'childCompanies',
        ]),
        getCompaniesAmount() {
            return this.childCompanies.length.toString().padStart(2, '0');
        },
    },
    components: {
        DashboardBlock,
        HoldingWidget,
        HoldingInfo,
    },
    methods: {
        getImage(company) {
            return this.$Utils.resolveS3PublicImage(company.image_square);
        },
    },
};
</script>

<style lang="scss" scoped>
.holding-dashboard-title {
    font-size: 32px;
    margin-bottom: 76px;
}
.widget-header {
    font-size: 1.4rem;
    line-height: 1.7rem;
    text-transform: uppercase;
    color: #A2B0B5;
    margin-bottom: 1rem;
}
.company {
    margin-bottom: 1rem;
}
</style>
