<template>
    <div>
        <ResultFileUploadItem
            v-for="answer in answers"
            :key="answer.id"
            class="item"
            :file-id="answer.file.id"
            :file-link="answer.file.link"
            :file-name="answer.file.name"
            :file-link-preview="answer.file.link_preview"
            :file-detected-mime-type="answer.file.detected_mime_type"
            :firstname="answer.user.firstname"
            :lastname="answer.user.lastname"
            :image="answer.user.image"
        />
    </div>
</template>

<script>
import freeResultsProps from '../freeResultsProps';
import ResultFileUploadItem from './ResultFileUploadItem.vue';

export default {
    name: 'ResultsFileUpload',
    components: { ResultFileUploadItem },
    mixins: [
        freeResultsProps,
    ],
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.item {
    &:not(:last-child) {
        margin-bottom: 2%;
        border-bottom: 1px dashed $grey-ultra-light;
    }
}
</style>
