import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';

export default class CompanyUserProgramTaskValidation extends Model {
    modelRelations() {
        return {
            task: () => this.belongsTo(CompanyUserProgramTask),
        };
    }
}
