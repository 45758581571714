<template>
    <div>
        <div class="user-survey-result-item-content-end">
            <div class="user-survey-result-item-content-right-text-right">
                {{ getUserAnswerText }}
            </div>
        </div>
    </div>
</template>
<script>
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import CompanyUserSurveyQuestionAnswerCollection from '@/models/CompanyUserSurveyQuestionAnswerCollection';
import get from 'lodash.get';

export default {
    name: 'ResultSimpleChoice',
    props: {
        surveyQuestion: {
            type: CompanySurveyQuestion,
            required: true,
        },
        userSurveyQuestionAnswer: {
            type: CompanyUserSurveyQuestionAnswerCollection,
            required: true,
        },
    },

    shared: {
        session: {
        },
    },

    computed: {
        getUserAnswerText() {
            const answerLocaleByKey = get(this.userSurveyQuestionAnswer.models[0].company_survey_question_answer, 'locales_by_key', null);
            const answerFromEntity = get(this.userSurveyQuestionAnswer.models[0].company_survey_question_answer, 'company_entity_value_id');

            if (answerFromEntity) {
                return this.userSurveyQuestionAnswer.models[0].company_survey_question_answer.value.custom.name;
            } if (answerLocaleByKey) {
                if (answerLocaleByKey[this.shared.session.companyUser.company_language.key].text !== null) {
                    return answerLocaleByKey[this.shared.session.companyUser.company_language.key].text;
                }

                return answerLocaleByKey[this.shared.session.company.company_language.key].text;
            }

            return null;
        },
    },
};
</script>
