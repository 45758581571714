<template>
    <HoldingResourceList
        :route-list="'HoldingResourcesEquipmentsList'"
        :collection="equipments"
        :use-resource-program="true"
    >
        <template #table="holdingResourceList">
            <EquipmentsListModels
                :items="holdingResourceList.items"
                :external-search="holdingResourceList.search"
                :columns-to-hide="['filters']"
                @onCellClicked="holdingResourceList.onCellClicked"
            />
        </template>
    </HoldingResourceList>
</template>
<script>
import HoldingResourceList from '@/components/globals/HoldingResourceList.vue';
import CompanyEquipmentCollection from '@/models/CompanyEquipmentCollection';
import EquipmentsListModels, { equipmentConfig } from '@/resourcesList/EquipmentsListModels.vue';

export default {
    name: 'PageEquipmentsList',
    components: {
        HoldingResourceList,
        EquipmentsListModels,
    },

    data() {
        return {
            equipments: new CompanyEquipmentCollection(equipmentConfig.fields).with(equipmentConfig.relations),
        };
    },
};
</script>
