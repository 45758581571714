<template>
    <div>
        <HtModalBottom
            ref="modal"
            :size="'small'"
            @onClose="resetStep"
        >
            <HtCard>
                <template #title>
                    <div class="w-100 d-flex justify-content-end">
                        <div
                            class="title-button"
                            @click="close"
                        >
                            <FontAwesomeIcon
                                :icon="['fal', 'times']"
                            />
                        </div>
                    </div>
                </template>
                <template #default>
                    <SelectResourceTypeModal
                        v-if="currentStep === 'SelectResourceTypeModal'"
                        :resources="resources"
                        @on-change-step="changeStep"
                    />
                    <ListResourceModal
                        v-else-if="currentStep === 'ListResourceModal'"
                        :component-resource="currentResource.componentList"
                        :company-program-id="companyProgramId"
                        @on-open-add="onOpenAdd"
                        @on-change-step="changeStep"
                    />
                </template>
            </HtCard>
        </HtModalBottom>
        <AddResourceModal
            v-if="isOpenAdd"
            :component-resource="currentResource.componentAdd"
            :company-program-id="companyProgramId"
            :hided-tabs="currentResource.hidedTabs"
            :modal-size="'medium'"
            :modal-type="currentResource.ht_modal_type"
            :modal-title="getModalTitle"
            :resource-model="currentResourceModel"
            :resource-collection="currentResourceCollection"
            @on-add="onAdd"
            @on-close="resetIsOpenAdd"
        />
    </div>
</template>
<script>
import SelectResourceTypeModal from './step/SelectResourceTypeModal.vue';
import ListResourceModal from './step/ListResourceModal.vue';
import AddResourceModal from './step/AddResourceModal.vue';

export default {
    name: 'AddElementModal',
    components: {
        SelectResourceTypeModal,
        ListResourceModal,
        AddResourceModal,
    },
    props: {
        resources: {
            type: Array,
            default: () => [],
        },
        companyProgramId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            listSteps: [
                'SelectResourceTypeModal',
                'ListResourceModal',
            ],
            currentStep: null,
            currentResource: null,
            currentResourceModel: null,
            currentResourceCollection: null,
            isOpenAdd: false,
        };
    },

    computed: {
        getModalTitle() {
            return this.currentResource && 'title' in this.currentResource ? this.currentResource.title : '';
        },
    },

    created() {
        this.resetStep();
    },

    methods: {
        open() {
            this.$refs.modal.open();
        },

        close() {
            this.$refs.modal.close();
        },

        changeStep(step, resource) {
            if (this.listSteps.includes(step)) {
                this.currentStep = step;
                this.currentResource = resource;
            }
        },

        resetResource() {
            this.currentResource = null;
            this.currentResourceModel = null;
            this.currentResourceCollection = null;
        },

        resetIsOpenAdd() {
            this.isOpenAdd = false;
            this.resetResource();
        },

        resetStep() {
            this.currentStep = this.listSteps[0];

            if (this.isOpenAdd === false) {
                this.resetResource();
            }
        },

        onOpenAdd(resourceModel, resourceCollection) {
            this.isOpenAdd = true;
            this.currentResourceModel = resourceModel;
            this.currentResourceCollection = resourceCollection;
            this.close();
        },

        onAdd(args) {
            if (args?.close !== false) {
                this.resetIsOpenAdd();
            }
            this.$emit('onAdd');
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.title {
    &-button {
        cursor: pointer;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: $color-lightblue-grey;
        display: flex;
        justify-content: center;
        align-items:center;
        transition: all 0.3s;
        font-size: 16px;

        &:hover {
            transform: scale(1.1);
        }
    }

    &-label {
        font-size: 16px;
        font-weight: 700;
        color: $color-text-grey;
    }
}
</style>
