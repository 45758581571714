<template>
    <div class="full-width">
        <template v-if="isAllLoaded">
            <div
                v-if="areDocumentsLoaded"
                class="modal-documents-import"
            >
                <fieldset
                    class="modal-documents-import-fieldset"
                >
                    <HtFormSelect
                        id="document-to-copy"
                        :label="translate('Import from library')"
                        name="document-to-copy"
                        cypress="document-to-copy"
                        :options="documentOptions"
                        @input="onSelectedDocument(...arguments)"
                    />

                    <HtFormSwitch
                        :id="'are-displayed-personal-library'"
                        v-model="areDisplayedPersonalLibrary"
                        :name="'are-displayed-personal-library'"
                        :show-optional="false"
                        :label="translate('Display only my personal library')"
                    />
                </fieldset>
            </div>

            <form
                class="form-2 has-padding"
                data-cy="document-add-form"
            >
                <fieldset
                    v-if="!selectedDocument"
                    :class="{ error: errors.has('original_name') }"
                >
                    <label>
                        <t>Upload file</t>
                        <span class="sub-text">(<t>Up to 20 MB</t>)</span>
                    </label>

                    <UploadBar
                        v-model="newCompanyUserDocument.company_file"
                        :media="['office', 'pdf', 'image']"
                        cypress="new-document"
                        @onUploadProgress="buttonState = 'loading'"
                        @onUploadEnd="buttonState = 'idle'"
                    />

                    <input
                        v-validate.disable="'required'"
                        type="hidden"
                        name="original_name"
                        data-cy="original_name"
                        :value="newCompanyUserDocument.company_file && newCompanyUserDocument.company_file.original_name"
                        :data-vv-as="translate('file')"
                    >
                </fieldset>

                <div v-if="selectedDocument || newCompanyUserDocument.company_file">
                    <InputField
                        v-model="newCompanyUserDocument.name"
                        :label="translate('Document name')"
                        type="text"
                        name="name"
                        cypress="name"
                        validate="required"
                        :validate-as="translate('name')"
                    />

                    <InputField
                        v-model="newCompanyUserDocument.specific_information"
                        :label="translate('Specific information for {firstname}', { firstname: companyUserProgram.company_user.firstname })"
                        type="textarea"
                        name="info"
                        cypress="info"
                    />

                    <HtUserProgramList
                        v-model="newCompanyUserDocument.company_user_program_id"
                        :company-user-id="companyUserId"
                        :disabled="true"
                    />

                    <HtKeyDatesInputOrder
                        :end-label="translate('Expected reading date')"
                        :user-program-id="companyUserProgramId"
                        :is-business-day.sync="userTask.is_business_day"
                        :offset-key-date.sync="userTask.company_user_program_key_date_id"
                        :offset-availability-specific-date.sync="userTask.datetime_availability"
                        :offset-availability-number.sync="userTask.offset_availability_number"
                        :offset-availability-unit.sync="userTask.offset_availability_unit"
                        :offset-end-specific-date.sync="userTask.datetime_end"
                        :offset-end-number.sync="userTask.offset_end_number"
                        :offset-end-unit.sync="userTask.offset_end_unit"
                        can-select-specific-date
                    />
                </div>

                <div class="modal-actions right">
                    <Button
                        :state="buttonState"
                        cypress="document-add-button"
                        @click="onNew()"
                    >
                        {{ translate('Add') }}
                    </Button>
                </div>
            </form>
        </template>
        <template v-else>
            <IconLoading />
        </template>
    </div>
</template>

<script>
import CompanyUserDoc from '@/models/CompanyUserDoc';
import CompanyUserProgram from '@/models/CompanyUserProgram';
import HtDivider from '@/components/globals/HtDivider.vue';
import HtUserProgramList from '@/components/globals/HtUserProgramList.vue';
import TaskDependencyMixin from '@/mixins/TaskDependencyMixin';
import CompanyUserProgramTask from '@/models/CompanyUserProgramTask';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import api from '@/store/api';
import HtFormSelect from '@/components/globals/HtFormSelect.vue';
import UploadBar from '@/components/globals/UploadBar/UploadBar.vue';
import InputField from '@/components/globals/InputField.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';

export default {
    name: 'UserDocSelect',
    components: {
        IconLoading,
        InputField,
        UploadBar,
        HtFormSelect,
        HtDivider,
        HtUserProgramList,
        HtKeyDatesInputOrder,
        HtFormSwitch,
    },

    mixins: [
        TaskDependencyMixin,
    ],

    inject: ['modal'],

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
        statusOnCreate: {
            type: String,
            default: CompanyUserProgramTask.STATUS_PENDING,
        },
    },

    data() {
        return {
            userTask: null,
            selectedDocument: null,
            areDisplayedPersonalLibrary: false,
            documents: [],
            areDocumentsLoaded: false,
            buttonState: 'idle',
            dependents: [],
            newCompanyUserDocument: new CompanyUserDoc([
                'id',
                'company_user_program_id',
                'company_user_id',
                'company_user_id',
                'company_file_id',
                'company_doc_id',
                'name',
                'specific_information',
            ]).with({
                companyUserProgramTask: (query) => {
                    query.select([
                        'id',
                        'resource',
                        'resource_id',
                        'status',
                        'is_business_day',
                        'company_user_program_key_date_id',
                        'offset_availability_number',
                        'offset_availability_unit',
                        'offset_end_number',
                        'offset_end_unit',
                        'datetime_availability',
                        'datetime_end',
                    ]).with({
                        dependents: (query) => {
                            query.select(['id']);
                        },
                    });
                },
                companyFile: (query) => {
                    query.select(UtilsCompanyFile.allowedFullFields());
                },
            }),
            companyUserProgram: new CompanyUserProgram([
                'id',
            ]).with({
                mainKeyDate: (query) => {
                    query.select(['id', 'starts_at']);
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname']);
                },
                companyProgram: (query) => {
                    query
                        .select([
                            'id',
                        ])
                        .with({
                            keyDate: (query) => {
                                query.with({
                                    locales: (query) => query.select(['id', 'name', 'language_key']),
                                });
                            },
                        });
                },
            }).where([
                ['id', '=', this.companyUserProgramId],
            ]),
        };
    },

    computed: {
        allDocumentOptions() {
            return this.documents.map((action) => ({
                id: action,
                name: action.resource.name,
            }));
        },

        documentOptions() {
            return this.allDocumentOptions.filter(
                (documentOption) => documentOption.id.resource.is_private === this.areDisplayedPersonalLibrary,
            );
        },

        isAllLoaded() {
            return this.companyUserProgram.isLoaded()
                && this.areDocumentsLoaded;
        },
    },

    watch: {
        'item._state.isSaving': function (value) {
            this.buttonState = value ? 'loading' : 'idle';
        },

        areDisplayedPersonalLibrary() {
            this.selectedDocument = null;
        },
    },

    created() {
        this.companyUserProgram.get();

        api.configuration
            .documents
            .getAll()
            .then((res) => {
                this.documents = res.data.data;
                this.areDocumentsLoaded = true;
            });

        this.newCompanyUserDocument.company_user_program_id = this.companyUserProgramId;
        this.createTaskWithDependents(this.newCompanyUserDocument);
        this.userTask = this.newCompanyUserDocument.company_user_program_task;

        this.modal.setTitle(this.translate('Add document'));
    },

    methods: {
        onSelectedDocument(value) {
            this.selectedDocument = value;

            const firstResourceProgram = value.resource.programs[0] || {
                offset_availability_number: 0,
                offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                offset_end_number: 0,
                offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                is_business_day: true,
            };

            this.newCompanyUserDocument.company_user_program_task.is_business_day = firstResourceProgram.is_business_day;
            this.newCompanyUserDocument.company_user_program_task.offset_availability_number = firstResourceProgram.offset_availability_number;
            this.newCompanyUserDocument.company_user_program_task.offset_availability_unit = firstResourceProgram.offset_availability_unit;
            this.newCompanyUserDocument.company_user_program_task.offset_end_number = firstResourceProgram.offset_end_number;
            this.newCompanyUserDocument.company_user_program_task.offset_end_unit = firstResourceProgram.offset_end_unit;

            this.newCompanyUserDocument.company_file_id = value.company_file.id;
            this.newCompanyUserDocument.company_doc_id = value.id;
            this.newCompanyUserDocument.name = value.resource.name;
        },

        onNew() {
            this.$validator.validateAll().then((result) => {
                if (result === true) {
                    this.newCompanyUserDocument.company_user_program_task.status = this.statusOnCreate;
                    this.newCompanyUserDocument.company_user_id = this.companyUserId;
                    this.newCompanyUserDocument.save().then(() => {
                        this.$emit('onUpdate');
                        this.modal.close();
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.separator-container {
    position: relative;
    text-align: center;
    margin-top: 40px;

    .hr-span {
        position: absolute;
        transform: translate(-50%, -100%);
        font-size: 2.7rem;
        background: white;
        text-transform: uppercase;
        width: 70px;
    }
}

.modal-documents-import {
    border-radius: $border-radius-8;
    background: $grey-8;
    padding: 1.6rem;
    margin-bottom: 2rem;

    .ht-form.ht-form-multiselect {
        margin-bottom: 1rem;
    }
}
</style>
