var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-cy":`${_vm.cypress}-item`}},[_c('div',{staticClass:"d-flex mb-3"},[(_vm.hasDependent)?_c('div',{staticClass:"d-flex justify-content-center align-items-center depend-icon"},[_c('FontAwesomeIcon',{staticClass:"branding-color",attrs:{"size":"lg","icon":['fal', 'level-up']}})],1):_vm._e(),_c('div',{class:['list-actions-item', {'bg-white': _vm.showWrapper }],on:{"mouseenter":function($event){_vm.isHoveringTask = true},"mouseleave":function($event){_vm.isHoveringTask = false}}},[_c('div',{class:['list-actions-item-wrapper d-flex justify-content-between', {'cursor-pointer': _vm.isClickable, 'list-actions-item-hover': _vm.isClickable && !_vm.showWrapper}],attrs:{"data-cy":`${_vm.cypress}-click`},on:{"click":function($event){$event.stopPropagation();return _vm.onClick()}}},[(_vm.showMultiSelect && _vm.isSelectable)?_c('div',{staticClass:"multiselect-checkbox",attrs:{"data-cy":`${_vm.cypress}-select`},on:{"click":function($event){$event.stopPropagation();return _vm.onSelectedAction.apply(null, arguments)}}},[_c('div',{staticClass:"multiselect-checkbox-wrapper"},[_c('div',{class:{'checked': _vm.isSelected == true, 'half': _vm.isSelected == 2}})])]):_vm._e(),_c('div',{staticClass:"content-left"},[_c('div',[(!_vm.showWrapper)?_c('div',{staticClass:"d-flex align-items-center show-mobile mobile-action-date"},[_c('div',{class:[{'list-actions-item-late': _vm.isLate && _vm.isPending, 'list-actions-item-clock': _vm.isPending || _vm.isDone, 'list-actions-item-done': _vm.isDone}, 'mr-2']},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'clock'],"size":"1x"}})],1),_c('div',{class:[
                                    'list-actions-item-date',
                                    {
                                        'color-error': _vm.isLate && _vm.isPending,
                                        'list-actions-item-done': _vm.isDone
                                    }
                                ]},[_vm._v(" "+_vm._s(_vm.$Utils.moment(_vm.date).format("Do MMM"))+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center"},[_vm._t("title",function(){return [(_vm.showIconResource)?_c('div',{staticClass:"mr-10"},[_c('FontAwesomeIcon',{staticClass:"branding-color",attrs:{"icon":_vm.getIconResource}})],1):_vm._e(),_c('div',{staticClass:"list-actions-item-title-1"},[(_vm.tagLabel)?_c('div',{staticClass:"tag-label"},[_vm._v(" "+_vm._s(_vm.tagLabel)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")])]})],2),(_vm.$slots.file)?_c('div',{staticClass:"mt-2"},[_vm._t("file")],2):_vm._e(),(_vm.$slots.subtitle)?_c('div',{staticClass:"mt-2"},[_vm._t("subtitle")],2):_vm._e()])]),(_vm.hasMiddleContent)?_c('div',{staticClass:"middle-content"},[(_vm.participants.length > 0)?_c('div',{staticClass:"d-flex align-items-center mr-20"},[_c('div',{staticClass:"participant-list"},[_c('ParticipantList',{staticClass:"mr-10",attrs:{"users":_vm.participants,"cypress":`${_vm.cypress}-participants`,"limit":3}})],1)]):_vm._e(),(_vm.availableDate !== '' && !_vm.hasDependent)?_c('div',{staticClass:"available-container mr-4"},[_c('div',{staticClass:"available-container-icon"},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'eye']}})],1),_c('div',{staticClass:"available-container-label"},[_vm._v(" "+_vm._s(`${_vm.translate('Available at: {date}', { date: _vm.localizedDate })}`)+" ")])]):_vm._e(),(_vm.$slots['middle-content'])?_c('div',{staticClass:"mr-4"},[_vm._t("middle-content")],2):_vm._e()]):_vm._e(),(_vm.shouldShowAvailabilityDate)?_c('div',{staticClass:"content-right d-flex align-items-center justify-content-flex-end mr-20"},[(_vm.hasDependent && _vm.shared.program.isLoading)?_c('FontAwesomeIcon',{staticClass:"mr-5",attrs:{"icon":['fal', 'spinner-third'],"size":"2x","spin":""}}):_c('div',{staticClass:"d-flex align-items-center mr-20",class:{'hide-mobile': !_vm.showWrapper}},[(_vm.showWrapper)?[_vm._t("extra-right")]:[_c('div',{class:[
                                    {
                                        'list-actions-item-late': _vm.isLate && _vm.isPending,
                                        'list-actions-item-clock': !_vm.isLate || _vm.isDone,
                                        'list-actions-item-done': _vm.isDone
                                    },
                                    'mr-2'
                                ]},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'clock'],"size":"1x"}})],1),_c('div',{class:[
                                    'list-actions-item-date',
                                    {
                                        'color-error': _vm.isLate && _vm.isPending,
                                        'list-actions-item-done': _vm.isDone
                                    }
                                ]},[_vm._v(" "+_vm._s(_vm.$Utils.moment(_vm.date).format("Do MMM"))+" ")])]],2),(_vm.showButtonRight)?_c('div',{staticClass:"interaction",attrs:{"data-cy":`${_vm.cypress}-buttons`}},[(_vm.canValidate || _vm.isDone || _vm.isCancelled)?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.onToggle(!_vm.isDone)}}},[(_vm.isCancelled)?_c('CheckCancelledIcon'):(_vm.isDone)?_c('CheckGreenIcon'):(!_vm.isTogglable)?_c('RoundEmptyBackgroundWithCursorNotAllowed'):(_vm.isHoveringTask && _vm.isTogglable)?_c('CheckGreyIcon'):_c('RoundEmptyBackground')],1):(!_vm.showWrapper)?_c('div',{staticClass:"icon-validate"},[_c('FontAwesomeIcon',{staticClass:"branding-color",attrs:{"size":"1x","icon":"chevron-right"}})],1):(_vm.showWrapper)?_c('div',{staticClass:"icon-validate grey"},[_c('FontAwesomeIcon',{staticClass:"icon",attrs:{"size":"1x","icon":_vm.getChevronDirection}})],1):_vm._e()]):_vm._e()],1):_vm._e()]),(_vm.$slots.subItems)?_c('div',{staticClass:"ml-20 mr-20 subitems"},[_vm._t("subItems")],2):_vm._e()])]),(_vm.$slots['dependency'])?_c('div',{staticClass:"children-wrapper"},[_vm._t(`dependency`)],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }