export default {
    inject: ['$validator'],

    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: () => null,
        },
        placeholder: {
            type: String,
            default: () => null,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        variables: {
            type: Array,
            default: () => [],
        },
        cypress: {
            type: String,
            default: '',
        },
        showOptional: {
            type: Boolean,
            default: true,
        },
        showAsterisk: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: () => null,
        },
        tooltip: {
            type: String,
            default: () => null,
        },
        errorAppend: {
            type: String,
            default: () => null,
        },
    },

    computed: {
        error() {
            if (this.errorAppend) {
                return this.errorAppend;
            }

            return this.hasError ? this.errors.first(this.name) : null;
        },
        hasError() {
            return this.errors.has(this.name) || !!this.errorAppend;
        },
        isRequired() {
            const field = this.fields[this.name];

            return field && field.required === true || this.showOptional === false;
        },
    },
};
