<template>
    <section class="mt-5">
        <HtFormGroup
            class="mb-2"
            :label="translate('Multiple adding')"
            :is-required="true"
        />
        <UploadBar
            ref="uploadBar"
            v-model="companyFile"
            :media="['csv']"
            @onUploadProgress="$emit('update-button-state', 'loading')"
            @onUploadEnd="onUploadedEnd"
        />
        <div class="mb-5 mt-2 import-values-format">
            <p><t>Accepted file format: CSV (UTF-8 format) with columns delimited by ";"</t></p>
            <p><t>You must fill the first row by setting the title of columns and from 2nd row you must set the values</t></p>
            <p><t>This is how you must do it:</t></p>
            <div class="mt-3">
                <table class="table-example">
                    <tr>
                        <td>label</td>
                        <td>key (optional column)</td>
                    </tr>
                    <tr>
                        <td>label #1</td>
                        <td>key #1</td>
                    </tr>
                    <tr>
                        <td>label #2</td>
                        <td>key #2</td>
                    </tr>
                    <tr>
                        <td>etc.</td>
                        <td>etc.</td>
                    </tr>
                </table>
            </div>
        </div>

        <ListingEntries
            ref="listingEntriesModal"
            should-display-key
            must-keys-be-filled
            :on-save="onImport"
            @on-close="$refs.uploadBar.destroy()"
            @update-button-state="(buttonState) => $emit('update-button-state', buttonState)"
        />
    </section>
</template>

<script>
import HtFormGroup from '@/components/globals/HtFormGroup.vue';
import ListingEntries from '@/components/pages/settings/entities/form/ListingEntries.vue';
import api from '@/store/api';
import htTablePaginationEventBus from '@/eventBus/htTablePagination';
import { getExtractedCSVFileFromId } from '@/helpers/file';

export default {
    components: {
        ListingEntries,
        HtFormGroup,
    },
    props: {
        entityId: {
            type: Number,
            required: true,
        },
        entitiesValueEditModal: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            companyFile: null,
        };
    },
    methods: {
        onImport(selectedEntries) {
            const hasTooLongValue = selectedEntries.some((entry) => entry.length > 255);

            if (hasTooLongValue) {
                this.$Notifier('App').showError(
                    this.translate('There is a data too long on your values.'),
                );
                return;
            }

            return api.configuration.entities.importCustomValues(this.entityId, { values: selectedEntries })
                .then(() => {
                    this.entitiesValueEditModal.close();
                    this.$emit('on-close');

                    htTablePaginationEventBus.$emit('refresh');
                });
        },

        onUploadedEnd() {
            if (this.companyFile?.detected_extension !== 'txt') {
                this.$emit('update-button-state', 'idle');
                return;
            }

            getExtractedCSVFileFromId(this.companyFile.id).then(({ file_content }) => {
                this.$emit('update-button-state', 'idle');

                const importContent = file_content.map((content) => ({
                    key: content.key,
                    name: content.label,
                }));

                this.$refs.listingEntriesModal.open(importContent);
            }).catch(() => {
                this.$Notifier('App').showError(
                    this.translate('Unable to complete this operation ! Probably, the file is badly formatted'),
                );
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
.import-values-format {
  font-size: 1.3rem;
  p {
    color: $neutral-600;
    margin: 0.75rem 0;
  }
}

.table-example td {
  border: 1px solid $neutral-600;
  padding: 0.5rem;
}
</style>
