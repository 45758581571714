<template>
    <div class="ht-search-field">
        <HtFormInput
            :id=" id ? id : 'search-input'"
            v-model="search"
            :name="name ? name : 'search-input'"
            :cypress="cypress ? cypress : 'input-search'"
            :placeholder="inputPlaceholder"
            type="text"
            :size="inputSize"
            @input="onInput"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
        >
            <template #extra>
                <FontAwesomeIcon
                    class="search-icon"
                    :icon="['far', 'search']"
                />
            </template>
        </HtFormInput>
    </div>
</template>

<script>
import HtFormInput from './HtFormInput.vue';
import HtFormMixin from './HtFormMixin';

export default {
    name: 'HtSearchField',
    components: { HtFormInput },
    mixins: [
        HtFormMixin,
    ],
    props: {
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            search: this.value,
            minimumSize: 30,
        };
    },
    computed: {
        inputPlaceholder() {
            return this.placeholder !== undefined ? this.placeholder : this.translate('Search');
        },
        inputSize() {
            if (this.search && this.search.length > this.inputPlaceholder.length) return `${Math.min(this.search.length, this.minimumSize)}`;
            if (this.inputPlaceholder) return `${this.inputPlaceholder.length}`;
            return '1';
        },
    },
    methods: {
        onInput() {
            this.$emit('input', this.search);
            this.$emit('update:value', this.search);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var";
.ht-search-field{
    position: relative;
    width: auto;
    min-width: 180px;
    display: flex;

    .search-icon{
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        padding: 2px;
    }

    ::v-deep .ht-form {
        margin: 0;
        width: 100%;
    }
    ::v-deep .ht-form-input-wrapper-field {
        padding-left: 36px;
        border-radius: 8px;
    }

    ::v-deep input::placeholder {
        color: $neutral-black;
    }
}
</style>
