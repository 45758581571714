<template>
    <div
        id="historyWidget"
        class="history"
        data-cy="history"
    >
        <div class="history-header">
            <HtH3>
                <t>Actions History</t>
            </HtH3>
            <div class="history-sort">
                <HtSelectSimple
                    :value="sortingType"
                    :label="translate('Sort by')"
                    :options="sortingTypes"
                    @input="onChangeView"
                />
            </div>
        </div>
        <div>
            <Component
                :is="`HistoryBy${$Utils.capitalize(sortingType.value)}`"
                v-if="sortTypeLoaded"
            />
        </div>
    </div>
</template>
<script>
import HtH3 from '@/components/design-system/typography/header/HtH3.vue';
import HtSelectSimple from '@/components/globals/HtSelectSimple.vue';
import HistoryByDate from './HistoryByDate.vue';
import HistoryByAction from './HistoryByAction.vue';
import HistoryByUser from './HistoryByUser.vue';
import tasksSortingMixin from '../listTasks/tasksSortingMixin';

export default {
    name: 'HistoryWidget',
    components: {
        HtH3,
        HistoryByDate,
        HistoryByAction,
        HistoryByUser,
        HtSelectSimple,
    },
    mixins: [tasksSortingMixin],
    data() {
        return {
            sortKeyLocalStorage: 'defaultSortedHistory',
        };
    },
};
</script>
<style lang="scss" scoped>
.history-header{
    display: flex;
    justify-content: space-between;
}
</style>
