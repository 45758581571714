<template>
    <div class="chat-group-manager-see-images">
        <template v-if="!loading">
            <div class="images">
                <img
                    v-for="image in images"
                    :key="`image-${image.id}`"
                    :src="image.link"
                    class="image"
                    @click="openPreview"
                >
            </div>
            <FilePreviewer
                ref="filePreviewer"
                :files="images"
            />
        </template>
        <IconLoading v-else />
    </div>
</template>

<script>
import filePreviewerMixin from '@/components/globals/FilePreviewer/filePreviewerMixin';
import FilePreviewer from '@/components/globals/FilePreviewer/FilePreviewer.vue';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import CompanyChatChannelMessageCollection from '@/models/CompanyChatChannelMessageCollection';

export default {
    name: 'ChatGroupManagerSeeImages',
    components: { FilePreviewer },
    mixins: [filePreviewerMixin],
    props: {
        channelId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            messages: [],
            loading: true,
        };
    },
    computed: {
        images() {
            return this.messages.flatMap?.((message) => message.files.models
                .filter((file) => this.isImage(file.detected_mime_type)));
        },
    },
    created() {
        new CompanyChatChannelMessageCollection(['id', 'type']).with({
            files: (query) => {
                query.select(UtilsCompanyFile.allowedMinimumFields().concat(['detected_mime_type']))
                    .where([['detected_mime_type', 'LIKE', 'image%']]);
            },
        })
            .where([['company_chat_channel_id', '=', this.channelId], ['type', '=', 'file']])
            .orderBy([
                ['created_at', 'desc'], ['id', 'desc'],
            ])
            .get()
            .then((data) => {
                this.messages = data?.models || [];
                this.loading = false;
                this.$emit('fileLoaded', this.images.length);
            });
    },
    methods: {
        openPreview() {
            this.$refs.filePreviewer?.togglePreviewer?.();
        },
    },
};
</script>

<style lang="scss" scoped>
.chat-group-manager-see-images{
    padding: 10px;
    .images{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 8px;
        .image{
            object-fit: cover;
            aspect-ratio: 1;
            border-radius: 4px;
        }
    }
}
</style>
