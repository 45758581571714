<template>
    <div
        v-if="isLoaded && shared.socket.channelCollection !== null"
        class="list-channel"
    >
        <ChatListChannelItem
            v-for="channel in sortChannelCollection"
            :key="channel.id"
            :active="channel.active"
            :company-chat-channel="channel"
            @click.native="setActive(channel.id)"
        />
    </div>
</template>

<script>
import CompanyUser from '@/models/CompanyUser';
import CompanyChatChannelCollection from '@/models/CompanyChatChannelCollection';
import UtilsCompanyFile from '@/models/common/utils/UtilsCompanyFile';
import ChatListChannelItem from './ChatListChannelItem.vue';

export default {
    name: 'ChatListChannel',
    shared: {
        socket: {
            count: {
                type: Number,
                default: 0,
            },
            chatOpened: {
                type: Boolean,
                default: false,
            },
            channelCollection: {
                type: CompanyChatChannelCollection,
                default: null,
            },
            connectedUsers: {
                type: Object,
                Object: {},
            },
            chatListChannelComponent: {
                type: Object,
                Object: null,
            },
        },
        session: {
            companyUser: {
                type: CompanyUser,
                default: null,
            },
        },
    },
    components: {
        ChatListChannelItem,
    },
    data() {
        return {
            ids: [],
            isPageFocus: true,
        };
    },
    computed: {
        channelActive() {
            if (!this.shared.socket.channelCollection) return null;
            return this.shared.socket.channelCollection.channelActive;
        },
        channelActiveId() {
            if (!this.channelActive) return null;
            return this.channelActive.id;
        },
        channelUserMe() {
            if (!this.channelActive) return null;
            return this.channelActive.company_chat_channel_user.channelUserMe;
        },
        socket() {
            return this.$RtwWhiteSocket.getInstance('default');
        },
        sortChannelCollection() {
            return this.shared.socket.channelCollection.models.sort((a, b) => new Date(b.last_message_at || b.created_at) - new Date(a.last_message_at || a.created_at));
        },
        isLoaded() {
            return this.shared.socket.channelCollection !== null && this.shared.socket.channelCollection.isLoaded();
        },
    },
    watch: {
        isPageFocus() {
            this.updateChannelUser();
        },
        'shared.socket.chatOpened': function () {
            this.updateChannelUser();
        },
    },
    created() {
        this.shared.socket.chatListChannelComponent = this;

        window.addEventListener('focus', () => {
            this.isPageFocus = true;
        });

        window.addEventListener('blur', () => {
            this.isPageFocus = false;
        });

        this.socket.connect();
    },
    methods: {
        getCompanyChatChannelCollection() {
            const companyChatChannelCollection = new CompanyChatChannelCollection([
                'id', 'type', 'name', 'subname', 'last_message_at', 'created_at', 'resource', 'resource_id', 'created_by_company_user_id', 'avatar_image_id',
            ]).with({
                companyChatChannelUser: (query) => {
                    query.select([
                        'id', 'company_user_id', 'read_at', 'count', 'is_notified',
                    ]);
                    query.with({
                        companyUser: (query) => {
                            query.select([
                                'id', 'firstname', 'lastname', 'fullname', 'is_virtual', 'image', 'company_job_position_id',
                            ]);
                            query.with({
                                companyJobPosition: (query) => {
                                    query.select([
                                        'id', 'name',
                                    ]);
                                },
                            });
                            query.withTrashed();
                        },
                    });
                },
                companyUserSurvey: (query) => {
                    query
                        .select([
                            'id', 'company_survey_id', 'company_user_id', 'company_user_participant_id',
                        ])
                        .with({
                            companyUserProgramTask: (query) => {
                                query.select('status');
                            },
                            companyUser: (query) => {
                                query.select([
                                    'id', 'fullname',
                                ]);
                            },
                            companySurvey: (query) => {
                                query
                                    .select([
                                        'id',
                                    ])
                                    .with({
                                        resource: (query) => {
                                            query
                                                .select([
                                                    'id', 'name',
                                                ]);
                                        },
                                    });
                            },
                        });
                },
                companyChatChannelMessage: (query) => query.select(['id']),
                avatarImage: (query) => query.select(UtilsCompanyFile.allowedFullFields()),
            });

            const where = [
                ['resource', '=', null],
            ];

            this.ids.forEach((id) => {
                where.push(['id', '=', id, 'or']);
            });

            companyChatChannelCollection.where(where);

            return companyChatChannelCollection;
        },
        loadChannelCollection(id = null) {
            return new Promise((resolve) => {
                this.getCompanyChatChannelCollection().get().then((data) => {
                    data.models = data.models.filter((channel) => !channel.created_by_company_user_id
                        || channel.created_by_company_user_id === this.shared.session.companyUser.id
                        || channel.company_chat_channel_message.length > 0);

                    this.shared.socket.channelCollection = data;

                    this.setCount();

                    if (data.models.length > 0) {
                        // Activation du channel
                        this.shared.socket.channelCollection.setActive(id || this.sortChannelCollection[0].id);

                        // Mise à jour du compteur de notification
                        this.updateChannelUser();
                    }

                    this.socket.emit('clientList');

                    resolve();
                });
            });
        },
        setActive(id) {
            return new Promise((resolve) => {
                if (!this.shared.socket.channelCollection) {
                    if (id) {
                        this.ids.push(id);
                    }

                    resolve();
                }

                if (this.shared.socket.channelCollection.models.some((c) => c.id === id) === true) {
                    // Activation du channel
                    this.shared.socket.channelCollection.setActive(id);

                    // Mise à jour du compteur de notification
                    this.updateChannelUser();

                    resolve();
                }

                // Ajout du channel à charger en plus des channels classiques
                this.ids.push(id);

                // Rechargement des channels
                this.loadChannelCollection(id).then(() => {
                    resolve();
                });

                this.$emit('onSwitchPanel');
            });
        },
        setCount() {
            if (!this.shared.socket.channelCollection) return;

            this.shared.socket.count = this.shared.socket.channelCollection.models.reduce((count, channel) => count + channel.company_chat_channel_user.channelUserMe.count, 0);
        },
        updateChannelUser() {
            if (!this.isPageFocus || !this.shared.socket.chatOpened || !this.channelUserMe || !this.channelUserMe.count > 0) return;

            this.channelUserMe.count = 0;
            this.channelUserMe.is_notified = 0;
            this.channelUserMe.read_at = this.$Utils.moment().format('YYYY-MM-DD HH:mm:ss');
            this.channelUserMe.save().then((companyChatChannelUser) => {
                if (companyChatChannelUser.count > 0) {
                    this.updateChannelUser();
                }
            });

            this.setCount();
        },
        setUserConnected(companyUserId, status) {
            if (this.shared.socket.channelCollection === null) return;

            const channel = this.shared.socket.channelCollection.models.find((c) => c.company_chat_channel_user.channelUserInterlocutor.company_user_id === companyUserId);

            if (channel) {
                channel.company_chat_channel_user.channelUserInterlocutor.company_user.isConnected = status;
            }
        },
    },
    sockets: {
        default: {
            onRegister() {
                this.socket.emit('register', this.shared.session.companyUser.toObject());
            },
            onRegistered() {
                this.loadChannelCollection();
            },
            onDirectMessage(data) {
                const channel = this.shared.socket.channelCollection.models.find((c) => c.id === data.message.company_chat_channel_id);

                if (channel) {
                    channel.last_message_at = data.message.created_at;

                    if (data.message.company_user_id !== this.shared.session.companyUser.id) {
                        channel.company_chat_channel_user.channelUserMe.count++;
                        this.setCount();
                    }
                } else {
                    this.loadChannelCollection(this.channelActiveId);
                }

                if (data.message.company_chat_channel_id === this.channelActiveId) {
                    this.updateChannelUser();
                }
            },
            onUserConnected(data) {
                if (this.shared.socket.connectedUsers === undefined) return;

                this.shared.socket.connectedUsers[data.id] = 1;

                this.setUserConnected(data.id, true);
            },
            onUserDisconnected(data) {
                if (this.shared.socket.connectedUsers === undefined) return;

                if (data.id in this.shared.socket.connectedUsers) {
                    delete this.shared.socket.connectedUsers[data.id];
                }

                this.setUserConnected(data.id, false);
            },
            onClientList(data) {
                this.shared.socket.connectedUsers = {};

                Object.keys(data).forEach((id) => {
                    this.shared.socket.connectedUsers[id] = 1;
                });

                this.shared.socket.channelCollection.models.forEach((c) => {
                    const interlocutor = c.company_chat_channel_user.channelUserInterlocutor;
                    interlocutor.company_user.isConnected = interlocutor.company_user_id in data;
                });
            },
            onDeleteChannel(data) {
                if (this.shared.socket.channelCollection.models.some((m) => m.id === data.channel.id)) {
                    this.loadChannelCollection(this.channelActiveId);
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped src="./ChatListChannel.scss" />
