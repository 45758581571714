<template>
    <div>
        <FocBlock title="Apprentice informations">
            <div class="container synthesis">
                <div class="row">
                    <div class="col-md-5 p-0">
                        <t>Firstname</t>
                    </div>
                    <div class="col-md-5 p-0">
                        {{ recap.firstname }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 p-0">
                        <t>Lastname</t>
                    </div>
                    <div class="col-md-5 p-0">
                        {{ recap.lastname }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 p-0">
                        <t>Age</t>
                    </div>
                    <div class="col-md-5 p-0">
                        {{ recap.age }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 p-0">
                        <t>Course title</t>
                    </div>
                    <div class="col-md-5 p-0">
                        {{ recap.course_title }}
                    </div>
                </div>
                <div
                    v-if="recap.year_of_entry_in_cycle"
                    class="row"
                >
                    <div class="col-md-5 p-0">
                        <t>Year of entry in the cycle</t>
                    </div>
                    <div class="col-md-5 p-0">
                        {{ recap.year_of_entry_in_cycle }}
                    </div>
                </div>
            </div>
        </FocBlock>
        <FocBlock title="Remuneration and support">
            <div class="container synthesis">
                <div class="row">
                    <div class="col-md-5 p-0">
                        <t>Percentage of SMIC or SMC</t>
                    </div>
                    <div class="col-md-5 p-0">
                        {{ percentage }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 p-0 synthesisEditField">
                        <t>Gross salary per month</t>
                    </div>
                    <div class="col-md-5 p-0">
                        <div v-if="!editingSalary">
                            {{ recap.salary }} &euro;
                        </div>
                        <HtInputText
                            v-else
                            id="salary"
                            v-model="newSalary"
                            name="salary"
                            :action-button="translate('Cancel')"
                            @action="editingSalary = false"
                        />
                    </div>
                    <div class="col synthesisEditField">
                        <div
                            v-if="!editingSalary"
                            class="synthesisEditLink"
                            @click="editSalary"
                        >
                            <FontAwesomeIcon
                                :icon="['fas','pencil']"
                                class="icon"
                                size="1x"
                                :title="translate('Edit')"
                                @click="$emit('on-edit')"
                            />
                            <t>Modify</t>
                        </div>
                        <div v-else>
                            <Button @click="setNewSalary">
                                <t>Submit</t>
                            </Button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 p-0">
                        <t>Training cost</t>
                    </div>
                    <div class="col-md-5 p-0">
                        {{ recap.cost }} &euro;
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 p-0 synthesisEditField">
                        <t>Support by the organization</t>
                    </div>
                    <div class="col-md-5 p-0">
                        <div v-if="recap.support">
                            {{ recap.support }} &euro;
                        </div>
                        <HtInputText
                            v-else
                            id="support"
                            v-model="newSupport"
                            name="support"
                        />
                    </div>
                    <div class="col synthesisEditField">
                        <div v-if="!recap.support">
                            <Button @click="setNewSupport">
                                <t>Submit</t>
                            </Button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5 p-0 synthesisEditField">
                        <t>Remainder</t>
                    </div>
                    <div class="col-md-5 p-0">
                        <div v-if="!editingRemainder">
                            {{ recap.remainder }} &euro;
                        </div>

                        <HtInputText
                            v-else
                            id="remainder"
                            v-model="newRemainder"
                            name="remainder"
                            :action-button="translate('Cancel')"
                            @action="editingRemainder = false"
                        />
                    </div>

                    <div class="col synthesisEditField">
                        <div
                            v-if="!editingRemainder"
                            class="synthesisEditLink"
                            @click="editRemainder"
                        >
                            <FontAwesomeIcon
                                :icon="['fas','pencil']"
                                class="icon"
                                size="1x"
                                :title="translate('Edit')"
                                @click="$emit('on-edit')"
                            />
                            <t>Modify</t>
                        </div>
                        <div v-else>
                            <Button @click="setNewRemainder">
                                <t>Submit</t>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="remainder > 0"
                class="container mt-3"
            >
                <div class="row remainder">
                    <div class="col p-0">
                        <div><t>The information indicated implies a remainder to be paid</t></div>
                        <div>
                            <t>
                                You can try to reduce the cost of training
                                near the school or continue recruitment with a remainder to be paid.
                            </t>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mt-3">
                <div class="row">
                    <div class="col-auto p-0">
                        <HtCheckbox v-model="checkbox" />
                    </div>
                    <div class="col">
                        <t>
                            I have taken note of the remainder and
                            I want to continue recruiting this apprentice
                        </t>
                    </div>
                </div>
            </div>
        </FocBlock>
        <EnrollingFooter>
            <Button
                class="
                    inner-box
                    branding-color
                    branding-border-color
                    branding-border-color-darken-hover
                "
                @click="$emit('on-prev')"
            >
                <t>Previous</t>
            </Button>
            <Button
                :state="buttonState"
                @click="next"
            >
                <t>Next</t>
            </Button>
        </EnrollingFooter>
    </div>
</template>

<script>
import FocBlock from '@/components/pages/foc/FocBlock.vue';
import HtCheckbox from '@/components/globals/HtCheckbox.vue';
import HtInputText from '@/components/globals/HtInputText.vue';
import EnrollingFooter from './EnrollingFooter.vue';

export default {
    name: 'EnrollingSynthese',
    components: {
        EnrollingFooter,
        FocBlock,
        HtCheckbox,
        HtInputText,
    },
    props: {
        userProgramId: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            recap: {
                firstname: null,
                lastname: null,
                age: null,
                course_title: null,
                year_of_entry_in_cycle: null,
                percentage: null,
                salary: null,
                cost: null,
                support: null,
                remainder: null,
            },
            editingSalary: false,
            editingRemainder: false,
            checkbox: null,
            newSalary: 0,
            newSupport: 0,
            newRemainder: 0,
        };
    },

    computed: {
        buttonState() {
            if (!this.checkbox || !(this.recap.support)) {
                return 'disabled';
            }
            return 'idle';
        },
        percentage() {
            return `${this.recap.percentage}%`;
        },
    },

    async created() {
        const { data } = await this.$http.get(`foc/enrolling/synthesis/${this.userProgramId}`);
        Object.keys(this.recap).forEach((key) => {
            if (data[key]) {
                this.recap[key] = data[key];
            }
        });
    },

    methods: {
        next() {
            this.$emit('on-next', this.userProgramId);
        },
        editSalary() {
            this.newSalary = this.recap.salary;
            this.editingSalary = true;
        },
        editRemainder() {
            this.newRemainder = this.recap.remainder;
            this.editingRemainder = true;
        },
        setNewSalary() {
            this.$http.post(`foc/enrolling/synthesis/${this.userProgramId}`, {
                salary: this.newSalary,
            }).then(({ data: { salary } }) => {
                this.recap.salary = Math.round(salary * 100) / 100;
                this.editingSalary = false;
            });
        },
        setNewSupport() {
            this.$http.post(`foc/enrolling/synthesis/${this.userProgramId}`, {
                support: this.newSupport,
            }).then(({ data: { support } }) => {
                this.recap.support = Math.round(support * 100) / 100;
            });
        },
        setNewRemainder() {
            this.$http.post(`foc/enrolling/synthesis/${this.userProgramId}`, {
                remainder: this.newRemainder,
            }).then(({ data: { remainder } }) => {
                this.recap.remainder = Math.round(remainder * 100) / 100;
                this.editingRemainder = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.synthesis {
    .row {
        margin-bottom: 0.8rem;
        &:last-child {
            margin-bottom: 0;
        }
        .col-md-5 {
            &:first-child {
                font-weight: bold;
            }
        }
    }
}
.remainder {
    background: #FFF9DC;
    color: #F4B615;
    padding: 0.8rem;
    font-size: 1.2rem;
    & .col div:first-child {
        font-size: 1.4rem;
        font-weight: bold;
    }
}
.synthesisEditLink {
    cursor: pointer;
    display: flex;
    gap: 5px;
}
.synthesisEditField {
    display: flex;
    align-items: center;
}
</style>
