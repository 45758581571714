import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyUserSoftware from './CompanyUserSoftware';

export default class CompanyUserSoftwareCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyUserSoftware,
        };
    }

    modelCustomAttributes() {
        return {
            company_user_id: null,
        };
    }
}
