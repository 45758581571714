import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyChatChannel from './CompanyChatChannel';

export default class CompanyChatChannelCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyChatChannel,
        };
    }

    modelCustomAttributes() {
        return {
            channelActive: null,
        };
    }

    modelAccessors() {
        return {
            channelActive: () => this.models.find((c) => c.active),
        };
    }

    setActive(id) {
        this.models.map((c) => {
            c.active = c.id === id;
            return c;
        });

        if (this.models.length > 0 && this.models.every((c) => c.active === false) === true) {
            this.models[0].active = true;
        }
    }
}
