<template>
    <Portal to="footer">
        <div class="bar container">
            <div class="bar__title">
                <slot name="title">
                    {{ title }}
                </slot>
            </div>
            <div class="bar__actions">
                <slot name="actions" />
            </div>
        </div>
    </Portal>
</template>

<script>
export default {
    name: 'DashboardFooterBar',
    props: {
        title: {
            type: String,
            default: () => '',
        },
    },
};
</script>

<style lang="scss">
.bar {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>

<style lang="scss">
@import "~@/styles/var";
.bar__actions {
    > * {
        margin-left: 20px;

        &:first-child {
            margin-left: 0;
        }
    }
}
@media (max-width: $phone) {
    .bar.container {
        position: relative;
        z-index: 100;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #FFFFFF;
        padding: .5rem;
        margin-bottom: 1rem;

        & .bar__actions {
            width: 100%;
            padding: .5rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            display: grid;
            gap: .5rem;
            grid-template-columns: repeat(3, 1fr);
            justify-content: space-evenly;

            > * {
                margin-left: 0;
                margin-bottom: .5rem;
                display: flex;
                justify-content: center;
                align-content: center;
            }
        }
    }
}
</style>
