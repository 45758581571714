/* eslint-disable no-useless-constructor */
/* eslint-disable class-methods-use-this */

import HtProgramType from '@/models/HtProgramType';
import CompanyLanguage from '@/models/CompanyLanguage';
import CompanyRole from '@/models/CompanyRole';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import AbstractTemplateProvider from './AbstractTemplateProvider';

export default class NewManagerTemplateProvider extends AbstractTemplateProvider {
    static TEMPLATE_SLUG = 'new-manager';

    constructor(programTypes, keyDates, roles, employeeDepartmentGroupId) {
        super(programTypes, keyDates, roles, employeeDepartmentGroupId);
    }

    get baseProgramData() {
        return {
            slug: NewManagerTemplateProvider.TEMPLATE_SLUG,
            ht_program_type_id: this.programTypes.find(
                (type) => type.slug === HtProgramType.SLUG_ONBOARDING,
            ).id,
            locales: [
                {
                    name: 'Parcours nouveau manager',
                    description:
                        'Accompagnez vos nouveaux managers via un parcours facilitant la prise de poste !',
                    language_key: CompanyLanguage.KEY_FR,
                },
                {
                    name: 'New manager program',
                    description:
                        'Assist your new managers with a program that makes it easier for them to take up their position!',
                    language_key: CompanyLanguage.KEY_EN,
                },
            ],
            key_dates: [
                {
                    ht_program_key_date: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Arrival date')),
                    ht_program_key_date_id: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Arrival date')).id,
                    is_main: true,
                },
            ],
            actions: [],
            surveys: [],
            emails: [],
            events: [],
        };
    }

    get actions() {
        return [
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 1,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Organiser une 1ère réunion d’équipe pour faire les présentations',
                            [CompanyLanguage.KEY_EN]: 'Organize a 1st team meeting to make the presentations',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 5,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Prévoir une formation dédiée au management et aux soft skills',
                            [CompanyLanguage.KEY_EN]: 'Plan a training session dedicated to management and soft skills',
                        },
                        description: {
                            [CompanyLanguage.KEY_FR]: 'Prévoir un moment avec le salarié pour le former.',
                            [CompanyLanguage.KEY_EN]: 'Schedule a moment with the employee to train him/her.',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 8,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Organiser une réunion avec son équipe pour mieux comprendre les missions de chacun',
                            [CompanyLanguage.KEY_EN]: "Organize a meeting with your team to better understand each other's missions",
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 10,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Organiser des 1:1 meetings pour faire le point avec chaque collaborateur',
                            [CompanyLanguage.KEY_EN]: 'Organize 1:1 meetings to review the situation with each employee',
                        },
                    },
                },
            },
        ];
    }

    get emails() {
        return [
            {
                template: 'custom',
                translations: [
                    {
                        subject: 'Les indispensables du 1:1 meeting',
                        content:
                            `<p>Bonjour {recipient_firstname}</p>
<p>Vous allez devoir organiser des 1:1 meetings pour faire le point avec chaque collaborateur. Voici les bonnes pratiques pour assurer le bon déroulement de ces meetings :</p>
<ul>
<li>Envoyer une invitation au collaborateur</li>
<li>Programmer un rappel 2h avant le meeting</li>
<li>Fournir une grille d’évaluation avec les points-clés à aborder</li>
<li>Demander au salarié de rédiger un compte-rendu de l’entretien</li>
</ul>
<p>N'hésitez pas à revoir la formation dédiée au management et aux soft skills</p>
`,
                        signature: `Bien cordialement,
                                    {sender_firstname} {sender_lastname}`,
                        language_key: CompanyLanguage.KEY_FR,
                    },
                    {
                        subject: 'Organize 1:1 meetings',
                        content:
                            `<p>Hello {enrollee_firstname} {enrollee_lastname}
<p>You will have to set up 1:1 meetings to review each employee's progress. Here are the best practices to ensure these meetings run smoothly:</p>
<ul>
<li>Remind the employee of the purpose of the meeting</li>
<li>Congratulate the employee on his or her work and initiatives</li>
<li>Define the evaluation criteria</li>
<li>Define what is expected of the employee</li>
<li>Remain attentive to the employee's needs</li>
</ul>
<p>Do not hesitate to review the training dedicated to management and soft skills</p>`,
                        signature: `Best regards,
                                    {sender_firstname} {sender_lastname}`,
                        language_key: CompanyLanguage.KEY_EN,
                    },
                ],
                file_translations: [],
                resource: {
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Les indispensables du 1:1 meeting',
                            [CompanyLanguage.KEY_EN]: '1:1 meeting essentials',
                        },
                    },
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 7,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                },
                sender_role_id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
            },
        ];
    }
}
