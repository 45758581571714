export default {
    props: {
        isAdminComponent: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    data() {
        return {
            canEdit: false,
        };
    },
    mounted() {
        if (this.isAdminComponent) {
            this.canEdit = true;
        }
        switch (this.field.type) {
        case 'text':
        case 'textonly':
        case 'numeric':
        case 'date':
            this.canEdit = this.canEditText;
            break;
        case 'checkbox':
            this.canEdit = this.canEditCheckBox;
            break;
        default:
            this.canEdit = false;
            break;
        }
    },
    computed: {
        canDelete() {
            return this.isAdminComponent || this.field.owner_id === this.shared.session.companyUser.id;
        },
        canMandatorize() {
            return this.canDelete;
        },
        canMove() {
            return this.canDelete;
        },
        canEditText() {
            if (this.fieldIsEditable()) {
                return true;
            }

            if (('admin' in this.field)) {
                return true;
            }

            return this.field.owner_id === this.shared.session.companyUser.id;
        },
        canEditCheckBox() {
            if (
                this.fieldIsEditable()
                || (!this.checkboxHasValueField() && this.checkboxHasValueSet())
            ) {
                return true;
            }

            if (('admin' in this.field) && !this.field.value) {
                return true;
            }

            return this.field.owner_id === this.shared.session.companyUser.id;
        },
    },
    methods: {
        checkboxHasValueField() {
            return 'value' in this.field;
        },
        fieldIsEditable() {
            return (this.field.type !== 'variable');
        },
        checkboxHasValueSet() {
            return this.checkboxHasValueField() && (this.field.value !== false && this.field.value !== '');
        },
    },
};
