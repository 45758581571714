<template>
    <div>
        <form
            class="form-2 spaced"
            @submit.prevent=""
        >
            <HtFormRange
                id="session-time"
                v-model="item.session_time"
                :label="translate('Maximum session time (in minutes)')"
                name="session-item"
                cypress="session-item"
                :min="30"
                :max="600"
                :step="1"
            />
        </form>
        <div class="modal-actions right">
            <Button
                :state="buttonState"
                @click="onSave()"
            >
                <t>Save</t>
            </Button>
        </div>
    </div>
</template>

<script>
import CompanySecurity from '@/models/CompanySecurity';

export default {
    name: 'SecuritySessionEdit',
    inject: ['modal'],

    props: {
        item: {
            type: CompanySecurity,
            required: true,
        },
    },

    data() {
        return {
            buttonState: 'idle',
        };
    },
    created() {
        this.item.fromSaveState();
    },

    methods: {
        async onSave() {
            try {
                this.buttonState = 'loading';
                await this.item.save();
                this.modal.close();
            } finally {
                this.buttonState = 'idle';
            }
        },
    },
};
</script>
