<template>
    <HtFormGroup
        :label="label"
        :error="error"
        :is-required="isRequired"
        :show-asterisk="showAsterisk"
    >
        <HtFormVariables
            v-if="variables.length > 0"
            :variables="variables"
            @addVariable="addVariable"
        />
        <textarea
            :id="id"
            ref="input"
            class="ht-form-textarea"
            :value="value"
            :name="name"
            :rows="rows"
            :placeholder="placeholder"
            :maxlength="maxlength"
            :disabled="disabled"
            @input="$emit('input', $event.target.value)"
            @click.stop
            @paste="$emit('paste', $event.target.value)"
        />
    </HtFormGroup>
</template>

<script>
import HtFormMixin from './HtFormMixin';
import HtFormVariables from './HtFormVariables.vue';

export default {
    name: 'HtFormTextarea',
    components: {
        HtFormVariables,
    },
    mixins: [
        HtFormMixin,
    ],

    props: {
        value: {
            type: String,
            default: () => '',
        },
        rows: {
            type: Number,
            default: () => 5,
        },
        maxlength: {
            type: Number,
            default: null,
        },
    },

    methods: {
        addVariable(variable) {
            const position = this.$refs.input.selectionStart;

            const text = (this.value) ? this.value.substring(0, position) + variable + this.value.substring(position) : variable;

            this.$emit('input', text);

            this.$nextTick(() => {
                this.$refs.input.setSelectionRange(position + variable.length, position + variable.length);
                this.$refs.input.focus();
            });
        },
    },
};
</script>

<style lang="scss" src="./HtFormTextarea.scss"></style>
