<template>
    <TableTemplate
        :items="items"
        :title="title"
        :placeholder-image="placeholderImage"
        :placeholder-text="placeholderText"
        :placeholder-title="placeholderTitle"
        :loading="loading"
        :view-all-link="true"
        @on-link-click="onClick"
    />
</template>
<script>
import TableTemplate from './templates/TableTemplate.vue';

export default {
    name: 'NotJoinedWidget',
    components: {
        TableTemplate,
    },
    computed: {
        items() {
            return this.$store.state.dashboard.statistics.notJoined.map((line) => ({
                id: line.id,
                image: line.image,
                name: `${line.firstname} ${line.lastname.toUpperCase()}`,
                detail: this.translate('Invitation on {date}', {
                    date: this.$Utils.moment(line.mail_schedule).format('L'),
                }),
            }));
        },
        title() {
            return this.translate('They haven\'t joined Heyteam yet');
        },
        placeholderTitle() {
            return this.translate('Stay zen !');
        },
        placeholderText() {
            return this.translate('Everyone invited has already joined HeyTeam.');
        },
        placeholderImage() {
            return '/static/images/dashboard/widgets/notJoined.svg';
        },
        loading() {
            return !this.$store.state.dashboard.statistics.notJoinedLoaded;
        },
    },
    watch: {
        loading: {
            handler(val) {
                if (val) {
                    this.$store.dispatch('dashboard/statistics/fetchNotJoined');
                }
            },
            immediate: true,
        },
    },
    methods: {
        onClick() {
            this.$store.dispatch('dashboard/programs/clearAllFilters');
            this.$store.dispatch('dashboard/transferFiltersToPrograms');
            this.$store.dispatch('dashboard/programs/setStatusesFilter', ['invited']);
            this.$router.push({ name: 'DashboardPrograms' });
        },
    },
};
</script>
