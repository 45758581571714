<template>
    <PageWithSidebar>
        <template #sidebar>
            <HtPageSidebar :pages="items" />
        </template>

        <router-view />
    </PageWithSidebar>
</template>

<script>
import HtPageSidebar from '@/components/globals/HtPageSidebar.vue';
import PageWithSidebar from '@/layouts/PageWithSidebar.vue';

export default {
    name: 'HoldingSettings',
    components: {
        HtPageSidebar,
        PageWithSidebar,
    },

    data() {
        return {
            items: [
                {
                    label: this.translate('Users'),
                    name: 'HoldingSettingsUsersCount',
                },
            ],
        };
    },
};
</script>
