<template>
    <div
        class="ht-card"
        :class="{'no-title': !hasTitleSlot && !title, 'ht-card-transparent': isWithoutBackground}"
    >
        <template v-if="hasTitleSlot || title">
            <div class="ht-card-title">
                <template v-if="hasTitleSlot">
                    <slot name="title" />
                </template>
                <template v-else>
                    {{ title }}
                </template>
            </div>
            <HtDivider v-if="!isWithoutBackground" />
        </template>

        <div
            class="ht-card-body"
            :class="customClassBody"
        >
            <slot name="default" />
        </div>

        <HtDivider v-if="hasFooterSlot" />

        <div
            v-if="hasFooterSlot"
            class="ht-card-footer"
        >
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
import HtDivider from './HtDivider.vue';

export default {
    name: 'HtCard',
    components: { HtDivider },

    inject: ['$validator'],

    props: {
        title: {
            type: String,
            default: '',
        },
        noBody: {
            type: Boolean,
            default: false,
        },
        isWithoutBackground: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasTitleSlot() {
            return 'title' in this.$slots;
        },
        hasFooterSlot() {
            return 'footer' in this.$slots;
        },
        customClassBody() {
            return {
                'no-padding': this.noBody,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~@/styles/bootstrap-custom';

@import '~bootstrap/scss/mixins';

.ht-card {
    background-color: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: $border-radius-8;
    padding: 0 4.8rem;
    &.no-title{
        padding: 32px;
        .ht-card-body {
            padding: 0;
        }
    }

    &-title {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        font-size: 24px;
        font-weight: bold;
        justify-content: space-between;
        height: 8rem;
    }

    &-body {
        height: 100%;
        flex-grow: 1;
        padding: 2.4rem 0;
        &.no-padding {
            padding: 0;
        }
        &.small-padding {
            padding: 35px 30px;
        }
    }

    &-footer {
        padding: 2.4rem 0;
    }
}

@media (max-width: $phone) {
    .ht-card {
        padding: 0 1.9rem;

        &-body {
            padding: 1.5rem;
        }
    }
}

@include media-breakpoint-up(xl) {
    .ht-card {
        padding: 1rem 10rem 0 10rem
    }
}

.ht-card-transparent {
    background: transparent;
    padding-top: 0;
    padding-bottom: 0;
}
</style>
