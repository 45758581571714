export default {
    props: {
        answers: {
            type: Array,
            required: true,
        },

        templateAnswers: {
            type: Array,
            default: () => [],
        },

        isNavigation: {
            type: Boolean,
            default: () => false,
        },
    },
};
