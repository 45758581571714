<template>
    <HtModal
        ref="modal"
        @beforeClose="beforeClose"
    >
        <template #header>
            <div v-if="shouldBypassPreviewMode()">
                <t>File dispatch</t>
            </div>
            <div v-else>
                <t>DMS</t>
            </div>
        </template>
        <template #default>
            <div v-if="shouldBypassPreviewMode()">
                <t>Please click on the "send" button to send your administrative files.</t>
            </div>
            <div v-else>
                <div
                    v-if="loading === false"
                    class="dms-list"
                >
                    <div
                        v-for="(item, index) in items"
                        :key="index"
                        class="dms-list-item"
                    >
                        <div class="dms-list-item-name">
                            {{ item.category }}
                        </div>
                        <div class="dms-list-item-subname">
                            {{ item.requirement }}
                        </div>
                        <div
                            v-if="item.status === 1"
                            class="dms-list-item-status"
                        >
                            <font-awesome-icon :icon="['far', 'check-circle']" /> <t>ready to send</t>
                        </div>
                        <div
                            v-else-if="item.status === 0"
                            class="dms-list-item-status"
                        >
                            <font-awesome-icon :icon="['fa', 'check-circle']" /> <t>already sent</t>
                        </div>
                        <div
                            v-else-if="item.status === 2"
                            class="dms-list-item-status"
                        >
                            <font-awesome-icon :icon="['far', 'check-circle']" /> <t>to complete</t>
                        </div>
                    </div>
                </div>
                <div v-else-if="loading === true">
                    <IconLoading />
                </div>
                <div v-else-if="items.length === 0">
                    <t>No data to send</t>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="d-flex justify-content-end">
                <Button
                    class="negative mr-3"
                    @click="close()"
                >
                    <t>Exit</t>
                </Button>
                <Button
                    :state="buttonState"
                    @click="loadDms('post')"
                >
                    <t>Send</t>
                </Button>
            </div>
        </template>
    </HtModal>
</template>

<script>
import Notifier from '@/Notifier';
import I18n from '@/modules/i18n/I18n';

function data() {
    return {
        dms: [],
        loading: false,
        companyUserId: null,
    };
}

export default {
    name: 'UserRequirementDmsModal',
    data() {
        return data();
    },

    computed: {
        buttonState() {
            if (this.shouldBypassPreviewMode()) {
                return 'idle';
            }

            if (this.items.length === 0) {
                return 'disabled';
            }

            if (this.loading === true) {
                return 'loading';
            }

            if (!this.dms.filter((item) => item.dms_status !== 0).length) {
                return 'disabled';
            }

            return 'idle';
        },
        items() {
            return this.dms.map((d) => ({
                requirement: d.company_user_requirement_name,
                category: d.company_user_requirement_category_name,
                status: d.dms_status,
            }));
        },
    },

    methods: {
        open(companyUserId) {
            this.companyUserId = companyUserId;

            this.loadDms('get');

            this.$refs.modal.open();
        },
        close() {
            this.$refs.modal.close();
        },
        beforeClose(resolve) {
            Object.assign(this.$data, data());

            resolve();
        },
        loadDms(method) {
            this.loading = true;

            this.$http[method](`miscellaneous/dms/${this.companyUserId}`).then((response) => {
                this.dms = response.data.data;
                this.loading = false;
            });

            if (method === 'post' && !this.shouldBypassPreviewMode()) {
                this.close();
                this.$Notifier('App').showInfo(this.translate('Upload completed'));
            }

            if (this.shouldBypassPreviewMode()) {
                this.close();

                Notifier.getInstance('App').showInfo(
                    I18n.translate('DMS Document successfully sent!'),
                );
            }
        },
        // eslint-disable-next-line max-len
        // This method is used to bypass the preview mode for specific DMS types (for instance, Sodexo company).
        // Please refer to this JIRA ticket https://heyt.atlassian.net/browse/IN-1246
        shouldBypassPreviewMode() {
            return this.dms.some((dmsItem) => dmsItem?.bypass_preview_mode === true);
        },
    },
};
</script>

<style lang="scss" src="./UserRequirementDmsModal.scss" scoped />
