var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ListItem',{staticClass:"wrapper",attrs:{"selected":_vm.selected},on:{"on-click":_vm.onClick}},[_c('div',{directives:[{name:"user-image",rawName:"v-user-image",value:(
            _vm.$Utils.getCompanyComponentRoleInitials('user', 1.5, {
                firstname: _vm.participantInfo.firstname,
                lastname: _vm.participantInfo.lastname,
                image: _vm.participantInfo.image,
            })
        ),expression:"\n            $Utils.getCompanyComponentRoleInitials('user', 1.5, {\n                firstname: participantInfo.firstname,\n                lastname: participantInfo.lastname,\n                image: participantInfo.image,\n            })\n        "}],staticClass:"participant-img"}),_c('ParticipantItemRightContent',{attrs:{"enrollee-info":_vm.enrolleeInfo,"participant-info":_vm.participantInfo,"is-targeting-program":_vm.isTargetingProgram}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }