const SET_FAVICON = 'set_favicon';
const SET_TITLE = 'set_title';

export default {
    namespaced: true,

    state() {
        return {
            favicon: {
                href: '/favicon.ico',
                type: 'image/x-icon',
            },
            title: 'Heyteam',
        };
    },

    actions: {
        setTitle({ commit }, title) {
            commit(SET_TITLE, title);
        },
        setFavicon({ commit }, favicon) {
            commit(SET_FAVICON, favicon);
        },
    },

    mutations: {
        [SET_TITLE](state, title) {
            if (title == null || title == '') {
                state.title = 'Heyteam';
                return;
            }
            state.title = title;
        },
        [SET_FAVICON](state, favicon) {
            if (favicon == null) {
                return;
            }
            state.favicon = favicon;
        },
    },
};
