<template>
    <HtModal
        ref="modal"
        @onClose="$emit('on-close')"
    >
        <template #header>
            {{ title }}
        </template>
        <template #default>
            <IconLoading v-if="loadingData" />
            <div v-else>
                <ParametersBox>
                    <HtFormInput
                        :id="'name'"
                        v-model="customField.name"
                        v-validate.disable="'required'"
                        :name="'name'"
                        :label="translate('Name')"
                        :data-vv-as="translate('name')"
                        :show-optional="false"
                        :disabled="nameDisabled"
                        @input="setCustomFieldKey"
                    />
                    <HtFormSelect
                        :id="'type'"
                        v-model="customField.type"
                        v-validate.disable="'required'"
                        :name="'type'"
                        :label="translate('Type')"
                        :data-vv-as="translate('type')"
                        :show-optional="false"
                        :options="typeOptions"
                        :disabled="typeDisabled"
                    />
                    <HtFormSelect
                        v-if="customField.type === 'boolean'"
                        :id="'type'"
                        v-model="customField.boolean"
                        v-validate.disable="'required'"
                        :name="'type'"
                        :label="translate('Default Value')"
                        :data-vv-as="translate('type')"
                        :show-optional="false"
                        :options="booleanDefaultOptions"
                        :disabled="typeDisabled"
                    />
                    <HtFormInput
                        :id="'key'"
                        :value="customField.key"
                        :name="'key'"
                        :disabled="true"
                        :show-optional="false"
                        :label="translate('Technical key')"
                    />
                </ParametersBox>
                <p
                    v-if="customField.is_linked_to_program"
                    class="description mt-4"
                >
                    <t>The field</t> "{{ customField.name }}" <t>cannot be deleted, as it is involved in conditioning invitations to at least one program</t>
                </p>
            </div>
        </template>
        <template #footer-actions>
            <HtButton
                v-if="canRemove"
                :loading="loadingAction"
                class="negative"
                :disabled="customField.is_linked_to_program"
                @click.native="remove()"
            >
                <t>Delete</t>
            </HtButton>
            <HtButton
                v-if="canSave"
                :loading="loadingAction"
                @click.native="save()"
            >
                <t>Save</t>
            </HtButton>
        </template>
    </HtModal>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';
import { slugify } from '@/helpers/string';
import api from '@/store/api';
import modalMixin from '@/components/globals/modals/modalMixin';
import HtModal from '../../../globals/HtModal.vue';
import HtFormInput from '../../../globals/HtFormInput.vue';
import ParametersBox from '../../../globals/ParametersBox.vue';

export default {
    name: 'CustomFieldEditModal',
    components: {
        HtModal,
        HtFormInput,
        ParametersBox,
        HtButton,
    },
    mixins: [
        modalMixin,
    ],
    data() {
        return {
            customField: {},
            loadingData: false,
            loadingAction: false,
        };
    },

    computed: {
        title() {
            return this.customField.id ? this.customField.name : this.translate('Add a custom field');
        },
        typeOptions() {
            return [
                {
                    id: 'text',
                    name: this.translate('Text'),
                },
                {
                    id: 'boolean',
                    name: this.translate('Yes / No'),
                },
            ];
        },
        booleanDefaultOptions() {
            return [
                {
                    id: false,
                    name: this.translate('No'),
                },
                {
                    id: true,
                    name: this.translate('Yes'),
                },
            ];
        },
        canRemove() {
            return Number.isInteger(this.customField.id) && this.isRemovable;
        },
        canSave() {
            return this.isEditable;
        },
        nameDisabled() {
            return this.isNotEditable;
        },
        typeDisabled() {
            return this.isNotEditable || Number.isInteger(this.customField.id);
        },
        isEditable() {
            return this.customField.is_editable;
        },
        isNotEditable() {
            return this.isEditable === false;
        },
        isRemovable() {
            return this.customField.is_removable;
        },
    },

    methods: {
        async open(id = null) {
            this.$refs.modal.open();
            this.loadingData = true;
            if (id) {
                await this.getCustomField(id);
                this.loadingData = false;
                return;
            }

            this.customField = {
                is_editable: true,
                table_name: 'company_user',
                boolean: false,
                text: '',
                options: {
                    field_is_editable: true,
                    is_showable: true,
                    is_removable: true,
                },
            };

            this.loadingData = false;
            this.$refs.modal.open();
        },
        getCustomField(id) {
            return api
                .settings
                .customFields
                .findById(id)
                .then((res) => {
                    this.customField = res.data.data;
                });
        },
        getTypeNameById(id) {
            const typeOption = this.typeOptions.find((option) => option.id === id);
            return typeOption ? typeOption.name : null;
        },
        getDefaultValueNameById(id) {
            const typeOption = this.booleanDefaultOptions.find((option) => option.id === id);
            return typeOption ? typeOption.name : null;
        },
        async save() {
            const ok = await this.$validator.validateAll();

            if (ok) {
                this.loadingAction = true;
                this.genericConfirm(
                    this.translate('Checking before saving '),
                    `
                    ${this.translate('Please check your entries, as they will not be modifiable once saved ?')} <br><br>

                    ${this.translate('Name')} : ${this.customField.name} <br>
                    ${this.translate('Type')} : ${this.getTypeNameById(this.customField.type)}
                    ${this.customField.type === 'boolean' ? `<br>${this.translate('Default Value')} : ${this.getDefaultValueNameById(this.customField.boolean)}` : ''}
                    `,
                    null,
                    this.translate('Validate'),
                    this.translate('Cancel'),
                ).then(async () => {
                    api
                        .settings
                        .customFields
                        .store(this.customField)
                        .then(() => {
                            this.$refs.modal.close();
                        });
                }).finally(() => {
                    this.loadingAction = false;
                });
            }
        },
        remove() {
            this.loadingAction = true;
            api
                .settings
                .customFields
                .delete(this.customField.id)
                .then(() => {
                    this.$refs.modal.close();
                }).finally(() => {
                    this.loadingAction = false;
                });
        },
        setCustomFieldKey() {
            if (!this.customField.id) this.customField.key = slugify(this.customField.name);
        },
    },
};
</script>
