<template>
    <div
        class="actions"
        tabindex="-1"
        @blur="hideMenu"
        @click.stop="toggleMenu"
    >
        <div class="actions__button">
            <FontAwesomeIcon :icon="['far', 'ellipsis-v']" />
        </div>
        <div
            v-if="open"
            class="actions__menu"
        >
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableActions',
    data() {
        return {
            open: false,
        };
    },
    methods: {
        hideMenu() {
            this.open = false;
        },
        toggleMenu() {
            this.open = !this.open;
        },
    },
};
</script>

<style lang="scss" scoped>
.actions {
  text-align: center;
  position: relative;
  width: 24px;

  & .actions__button {
    &:hover {
      color: var(--branding-color);
      background-color: rgba(0,0,0, 0.05);
    }
    cursor: pointer;
  }

  & .actions__menu {
      right: 0;
      z-index: 1000;
      position: absolute;
      border-radius: 8px;
      background-color: #FFFFFF;
      border: 1px solid #E0E2E2;
      margin-top: 3px;
  }

  & .actions__menu > .link {
    padding: 1rem;
  }
}
</style>
