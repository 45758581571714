<script>
export default {
    name: 'AutoLogin',
    computed: {
        token() {
            return this.$route.params.token;
        },
    },
    created() {
        this.$http.post(`auth/autologin/${this.token}`)
            .then(() => {
                if (this.$route.query.redirect) {
                    const path = this.$route.query.redirect;

                    delete this.$route.query.redirect;

                    const { query } = this.$route;

                    this.$router.push({ path, query });

                    return;
                }

                this.$router.push({ name: 'Dashboard' });
            })
            .catch(() => {
                this.$router.push({ name: 'Login' });
            });
    },

    render() {

    },
};
</script>
