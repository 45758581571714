<!-- ToDo: DEPRECATE -->
<template>
    <div class="invite-user has-padding">
        <p><t>Users will be invited to the platform but won’t be able to see or change anything until you grant them permissions (By adding them as a manager or as a responsible of a resource)</t></p>

        <SearchInputAjax
            v-if="customManagerEndpoint"
            style="margin-top: 20px;"
            :endpoint="customManagerEndpoint"
            @item-selection="itemSelection"
        />
        <i v-if="customManagerTooltip">{{ customManagerTooltip }}</i>

        <form
            class="form-2"
            @submit.prevent=""
        >
            <ErrorDisplay
                v-if="error"
                :append-errors="error"
            />
            <InputField
                v-model="user.email"
                :label="translate('Email')"
                :type="'text'"
                :name="'email'"
                :validate="'required|email'"
                :validate-as="translate('email')"
                :trim="true"
            />
            <InputField
                v-model="user.firstname"
                :label="translate('First Name')"
                :type="'text'"
                :name="'firstname'"
                :validate="'required'"
                :validate-as="translate('first name')"
                :trim="true"
            />
            <InputField
                v-model="user.lastname"
                :label="translate('Last Name')"
                :type="'text'"
                :name="'lastname'"
                :validate="'required'"
                :validate-as="translate('last name')"
                :trim="true"
            />

            <HtFormSelector
                v-if="!isGuestRole"
                id="job_position"
                :value="user.company_job_position_id"
                name="job_position"
                :label="translate(jobPositionEntity.name)"
                :placeholder="translate(jobPositionEntity.name)"
                :options="companyJobPositionCollection"
                label-options="name"
                :is-single="true"
                :allow-empty="false"
                track-by="value_resourceable_id"
                :data-vv-as="translate(jobPositionEntity.name)"
                :search-value.sync="searchJobPosition"
                :cypress="`entity-select-${jobPositionEntity.slug}`"
                :taggable="canCreateEntityValue"
                @createOption="(value) => {createOption(jobPositionEntity, value)}"
                @input="value => user.company_job_position_id = value.value_resourceable_id"
            />
        </form>
        <div class="modal-actions right">
            <Button
                :state="buttonState"
                @keyup.13="onInvite"
                @click="onInvite"
            >
                <t>Invite</t>
            </Button>
        </div>
    </div>
</template>

<script>
import ErrorDisplay from '@/components/globals/ErrorDisplay.vue';
import InputField from '@/components/globals/InputField.vue';
import SearchInputAjax from '@/components/miscellaneous/SearchInput/SearchInputAjax.vue';
import HtFormSelector from '@/components/globals/Selectors/HtFormSelector.vue';
import modalMixin from '@/components/globals/modals/modalMixin';

export default {
    name: 'InviteUser',
    components: {
        SearchInputAjax,
        ErrorDisplay,
        InputField,
        HtFormSelector,
    },

    mixins: [modalMixin],

    inject: ['modal'],
    props: {
        isGuestRole: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            error: '',
            searchJobPosition: null,
            bodyJobPosition: {
                mode: 'get',
                field: ['id', 'name', 'description'],
            },
            user: {
                id: null,
                firstname: '',
                lastname: '',
                email: '',
                professional_email: '',
                image: '/static/images/member.png',
                company_job_position_id: 0,
                is_guest: false,
            },
            email: {
                en: 'Hello there\n\nWe are using HeyTeam to create an amazing onboarding experience for our new hires.\n\nWe need you to create your profile on HeyTeam so that our new hires would know who you are and what you do in the company!\n\nThanks for your help :)',
                fr: 'Bonjour,\n\nNous utilisons HeyTeam pour créer une la meilleure expérience d’onboarding possible pour nos nouveaux collaborateurs.\n\nNous avons besoin que vous créiez un compte sur HeyTeam pour que nos nouvelles recrues sachent qui vous êtes et votre poste dans l’entreprise !\n\nMerci pour votre aide :)',
            },
            customManagerEndpoint: null,
            customManagerTooltip: null,
        };
    },

    computed: {
        message() {
            return this.email[this.$Auth.company_user.language];
        },
        buttonState() {
            return this.loading ? 'loading' : 'idle';
        },
        jobPositionEntity() {
            return this.$store.getters['entities/jobEntity'];
        },
        canCreateEntityValue() {
            return this.$can('PageSettingsEntities');
        },
        companyJobPositionCollection() {
            return this.$store.getters['entities/jobValuesForFilter'];
        },
    },

    created() {
        this.modal.setTitle(this.translate('Invite a new user'));
        this.$store.dispatch('entities/fetchEntities');
        this.$http
            .get('configuration/companies/external-supervisors')
            .then((response) => {
                if ('data' in response.data && 'endpoint' in response.data.data) {
                    this.customManagerEndpoint = response.data.data.endpoint;
                    this.customManagerTooltip = response.data.data.tooltip;
                }
            });
    },

    methods: {
        init() {
            this.error = '';
            this.errors.clear();
        },
        itemSelection({ item }) {
            this.user = item;
        },
        async createOption(entity, value) {
            const newValue = await this.openEntityModal('addEntityModal', entity, value, { style: 'z-index: 9999;' });
            await this.$store.dispatch('entities/forceFetchEntities');
            this.$set(this.user, 'company_job_position_id', newValue.id);
        },

        async onInvite() {
            await this.$validator.validateAll();

            if (this.errors.any()) return;

            this.user.professional_email = this.user.email;
            this.user.is_guest = this.isGuestRole;

            this.loading = true;

            this.$http
                .post('foc/invite-user', {
                    resource: this.user,
                })
                .then((res) => {
                    const user = res.data.data;

                    this.$Notifier('App').showInfo(this.translate('Invitations sent to {email} !', { email: user.email }));
                    this.$emit('onCreate', user);
                })
                .finally(() => this.loading = false);
        },
    },
};

</script>

<style lang="scss" scoped>
	.padding-block {
		padding: 10px 0;
	}

	.small-button {
		padding: 3px 8px !important;
		border-radius: 32px !important;
	}

	.form-2 {
		text-align:left;
		margin-top:4rem;
	}

</style>
