<template>
    <div v-if="loaded">
        <div :class="'box-tabs'">
            <ul class="tabs-wrapper">
                <li
                    class="menu-item"
                    :class="{'active': page === 1}"
                    @click="changeTab(1)"
                >
                    <t>E-mail</t>
                </li>
                <li
                    class="menu-item"
                    :class="{'active': page === 2}"
                    @click="changeTab(2)"
                >
                    <t>SMS</t>
                </li>
            </ul>

            <form
                class="menu-content form-2 no-bg"
                :class="{'box-tabs has-padding': item}"
            >
                <EmailEdit
                    v-show="page === 1"
                    ref="emailEdit"
                    v-model="item"
                    :slack-channels="slackChannels"
                />

                <SmsEdit
                    v-show="page === 2"
                    ref="smsEdit"
                    v-model="item"
                />

                <div class="row-line middle">
                    <Button
                        :state="button_state"
                        @click="onValidate"
                    >
                        <t>Update</t>
                    </Button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import CompanyEmailTemplate from '@/models/CompanyEmailTemplate';
import EmailEdit from '@/components/resources/emails/EmailEdit.vue';
import SmsEdit from '@/components/resources/sms/SmsEdit.vue';
import api from '@/store/api';

export default {
    name: 'NotificationEdit',
    components: {
        EmailEdit,
        SmsEdit,
    },

    inject: ['modal'],

    props: {
        slackChannels: { type: [Array], required: false },
        value: { type: CompanyEmailTemplate },
    },

    data() {
        return {
            page: 1,
            item: null,
            button_state: 'idle',
            loaded: false,
        };
    },

    async created() {
        const value = this.value.fromSaveState();

        this.item = {
            id: value.id,
            title: value.title,
            section: value.section,
            type: value.type,
            category: value.category,
            subject_en: value.subject_en,
            content_en: value.content_en,
            content_slack_en: value.content_slack_en,
            content_sms_en: value.content_sms_en,
            button_en: value.button_en,
            footer_en: value.footer_en,
            subject_fr: value.subject_fr,
            content_fr: value.content_fr,
            content_slack_fr: value.content_slack_fr,
            content_sms_fr: value.content_sms_fr,
            button_fr: value.button_fr,
            footer_fr: value.footer_fr,
            template_slug: value.template_slug,
            slack_channel: value.slack_channel,
            is_enabled: value.is_enabled,
            is_enabled_slack: value.is_enabled_slack,
            is_enabled_sms: value.is_enabled_sms,
            is_editable_enabled: value.is_editable_enabled,
            is_editable_enabled_slack: value.is_editable_enabled_slack,
            is_editable_enabled_sms: value.is_editable_enabled_sms,
            is_editable_subject: value.is_editable_subject,
            is_editable_content: value.is_editable_content,
            is_editable_date: value.is_editable_date,
            is_editable_recipient: value.is_editable_recipient,
            is_modified: value.is_modified,
            is_onboardee_email: value.is_onboardee_email,
            is_viewable: value.is_viewable,
            show_in_group: value.show_in_group,
            order: value.order,
            time: value.time,
            trigger_event: value.trigger_event,
            company_file_id: value.company_file_id,
            company_file: value.company_file,
            company_sender_id: value.company_sender_id,
            company_sender: value.company_sender,
            variables: value.variables,
            isOrderMinus: value.isOrderMinus,
            company_email_template_recipient: value.company_email_template_recipient.models,
        };

        this.modal.setTitle(`${this.translate(this.item.title)}`);

        this.loaded = true;
    },

    methods: {
        changeTab(page) {
            this.page = page;
        },

        async onValidate() {
            this.button_state = 'loading';

            const errorEmail = await this.$refs.emailEdit.validateEmail();

            const errorSms = this.item.is_enabled_sms && (!this.item.content_sms_en || !this.item.content_sms_fr);

            if (errorEmail || errorSms) {
                this.button_state = 'idle';
                if (errorEmail) this.$Notifier('App').showError(this.translate('Some required information is missing'));
                if (errorSms) this.$Notifier('App').showError(this.translate('Both languages need to be completed'));
                return;
            }

            api
                .configuration
                .emailTemplate
                .update({
                    method: 'update',
                    controller: 'CompanyEmailTemplateController',
                    resource: {
                        ...this.item,
                        time: parseInt(this.item.time, 10),
                        order: this.item.order * (this.item.isOrderMinus ? -1 : 1),
                        company_sender: null,
                        company_file: null,
                        company_email_template_recipient: this.item.company_email_template_recipient.map((p) => {
                            if (p.company_user_id) {
                                return {
                                    company_user_id: p.company_user_id,
                                };
                            }

                            if (p.company_role_id) {
                                return {
                                    company_role_id: p.company_role_id,
                                };
                            }

                            if (p.company_group_id) {
                                return {
                                    company_group_id: p.company_group_id,
                                };
                            }
                        }),
                    },
                })
                .then(() => {
                    this.button_state = 'idle';
                    this.modal.close();
                    this.$emit('on-close');
                });
        },
    },
};
</script>

<style lang="scss" scoped>
	@import '~@/styles/var';

	.box-tabs {
		margin-top: 0;
	}

	.menu-item {
		width: 50%;
		text-align:center;

		&:last-child {
			border-right: 0;
		}
	}

	.no-bg {
		background-color: $white;
	}
</style>
