<template>
    <div
        v-if="companyChatChannelMessage.isLoaded()"
        class="chat-message-item-nudge"
    >
        <div class="chat-message-item-nudge-title">
            <t>New Challenge</t>
        </div>
        <div
            v-dompurify-html="userNudge.message"
            class="chat-message-item-nudge-message"
        />
        <div class="chat-message-item-nudge-initiator">
            <div
                v-user-image="
                    $Utils.getCompanyComponentRoleInitials(
                        'user',
                        1.25,
                        userNudge.company_user_initiator
                    )"
                class="chat-message-item-nudge-initiator-image"
                :class="'user'"
            />
            <div class="chat-message-item-nudge-initiator-right">
                <div class="chat-message-item-nudge-initiator-right-nudge-name">
                    {{ userNudge.name }}
                </div>
                <div class="chat-message-item-nudge-initiator-right-name">
                    {{ userNudge.company_user_initiator.firstname }}
                    {{ userNudge.company_user_initiator.lastname }}
                </div>
            </div>
        </div>
        <div
            class="chat-message-item-nudge-bottom"
            :class="state"
        >
            <template v-if="userNudge._state.isSaving === false">
                <template
                    v-if="userNudge.company_nudge.is_deleted
                        || userNudge.company_nudge.is_enable === false"
                >
                    <div class="chat-message-item-nudge-bottom-full-width">
                        <t>This challenge is no longer available</t>
                    </div>
                </template>
                <template v-else>
                    <div
                        v-if="userNudge.company_user_program_task.status === 'cancelled'
                            || userNudge.status === 'refuse'
                            || ['accepted', 'success'].includes(userNudge.status)"
                        class="chat-message-item-nudge-bottom-full-width"
                    >
                        <t v-if="userNudge.status === 'refuse'">
                            Challenge refused
                        </t>
                        <t
                            v-else-if="['accepted', 'success'].includes(userNudge.status)"
                        >
                            Challenge accepted
                        </t>
                        <t
                            v-else-if="userNudge.company_user_program_task.status === 'cancelled'"
                        >
                            This challenge is no longer available
                        </t>
                    </div>
                    <template
                        v-if="userNudge.status === 'proposed'
                            && userNudge.company_user_program_task.status === 'pending'"
                    >
                        <div
                            class="chat-message-item-nudge-bottom-left-link"
                            @click="onUpdateStatus('refuse')"
                        >
                            <t>Refuse the challenge</t>
                        </div>
                        <div
                            class="chat-message-item-nudge-bottom-right-link"
                            @click="onUpdateStatus('accepted')"
                        >
                            <t>Accept the challenge</t>
                        </div>
                    </template>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import CompanyChatChannelMessage from '@/models/CompanyChatChannelMessage';
import resourceEventBus from '@/eventBus/resourceEventBus';
import CompanyUserNudge from '../../../models/CompanyUserNudge';

export default {
    name: 'MessageResourceUserNudge',
    props: {
        companyChatChannelMessage: {
            type: CompanyChatChannelMessage,
            required: true,
        },
    },
    data() {
        return {
            state: '',
        };
    },
    computed: {
        userNudge() {
            return this.companyChatChannelMessage.company_user_nudge;
        },
    },
    watch: {
        'userNudge._state.isSaving': function (value) {
            this.state = value ? 'loading' : '';
        },
    },

    methods: {
        async onUpdateStatus(status) {
            try {
                this.isLoading = true;
                this.userNudge.status = status;
                await this.userNudge.save({ method: CompanyUserNudge.STORE_ANSWER });
                this.$emit('onUpdate');

                if (status === CompanyUserNudge.STATUS_ACCEPTED) {
                    resourceEventBus.$emit(
                        'nudge-accepted',
                        this.userNudge.company_user_program_task_id,
                    );
                }

                this.isLoading = false;
            } catch (e) {
                this.userNudge.status = this.userNudge.$.status;
                this.isLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" src="./MessageResourceUserNudge.scss"></style>
