<template>
    <div class="row">
        <div class="col-9">
            <div class="mb-2">
                <strong>
                    <t>The Tutor / Apprenticeship supervisor meets the eligibility criteria</t>
                </strong>
            </div>
            <div class="description">
                <div v-if="isTermsShown">
                    <ul class="text-secondary">
                        <li><t>Prove at least 2 years of professional experience on missions related to those assigned to the student</t></li>
                        <li><t>Prove at least 1 year of professional experience on missions related to those assigned to the student + a diploma in the same field and at the same level as the one prepared by the student</t></li>
                        <li><t>Not to be an Tutor / Apprenticeship supervisor for more than two students at the same time</t></li>
                        <li><t>To have enough time to follow up and support the student</t></li>
                        <li><t>Ensure the practical training and assist the trainee in obtaining his or her diploma</t></li>
                    </ul>
                </div>

                <small
                    class="d-flex align-items-center branding-color branding-color-darken-hover cursor-pointer"
                    @click.stop="toggleTerms"
                >
                    <t v-if="isTermsShown">Hide terms</t>
                    <t v-else>Show terms</t>

                    <FontAwesomeIcon
                        size="1x"
                        :icon="['fal', isTermsShown ? 'chevron-up' : 'chevron-down']"
                        class="ml-1"
                    />
                </small>
            </div>
        </div>
        <div class="col-3">
            <div class="d-flex justify-content-end">
                <HtCheckbox
                    id="apprenticeship_master_is_eligible"
                    v-model="isChecked"
                    class="seearch-result-container-checkbox"
                    name="apprenticeship_master_is_eligible"
                />
                <div class="ml-2">
                    <t>I commit</t>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import HtCheckbox from '@/components/globals/HtCheckbox.vue';

export default {
    name: 'EnrollingApprenticeshipSupervisorEligibilityCheck',
    components: {
        HtFormSwitch,
        HtCheckbox,
    },
    props: {
        value: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            isChecked: this.value,
            isTermsShown: false,
        };
    },
    watch: {
        isChecked(val) {
            this.$emit('input', val);
        },
        value(val) {
            this.isChecked = val;
        },
    },
    methods: {
        toggleTerms() {
            this.isTermsShown = !this.isTermsShown;
        },
    },
};
</script>

<style scoped>
ul {
    padding: 16px 32px;
    margin: inherit;
    list-style: inherit;
}

.cursor-pointer {
    cursor: pointer !important;
}
</style>
