<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C19.9939 15.5203 15.5203 19.9939 10 20ZM9.984 18H10C14.4167 17.9956 17.9942 14.4127 17.992 9.996C17.9898 5.57929 14.4087 2 9.992 2C5.57528 2 1.99421 5.57929 1.992 9.996C1.98979 14.4127 5.56729 17.9956 9.984 18ZM8 15L4 11L5.41 9.59L8 12.17L14.59 5.58L16 7L8 15Z"
            :fill="fillColor"
        />
    </svg>
</template>

<script>
export default {
    name: 'DSIconSuccess',
    props: {
        width: {
            type: String,
            default: '18',
        },
        height: {
            type: String,
            default: '18',
        },
        fillColor: {
            type: String,
            default: '#25B771',
        },
    },
};
</script>

<style scoped>

</style>
