<template>
    <div
        class="timeline-menu"
        :style="{ background: color }"
    >
        <div class="d-flex align-items-center">
            <HtCheckbox
                v-show="showMultiSelect"
                class="mr-3"
                :value="isSelected"
                @input="onChange"
            />
            <div class="timeline-menu-title">
                {{ translatedTitle }}
            </div>
        </div>
        <div class="timeline-menu-subtitle">
            <t :count="countTask">
                {count} action to do | {count} actions to do
            </t>
        </div>
    </div>
</template>

<script>
import HtCheckbox from '@/components/globals/HtCheckbox.vue';

export default {
    name: 'UserProgramTimelineTitle',
    components: {
        HtCheckbox,
    },
    props: {
        color: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        countTask: {
            type: Number,
            default: 0,
        },
        showMultiSelect: {
            type: Boolean,
            default: false,
        },
        isSelected: {
            type: Boolean,
            required: true,
        },
        programId: {
            type: Number,
            required: true,
        },
    },

    computed: {
        translatedTitle() {
            return this.translate(this.title, {
                key_date_name: this.mainKeyDateName,
            });
        },
        mainKeyDateName() {
            const storePrograms = this.$store.state.programs.programs;
            const keyDates = storePrograms.find((program) => program.id === this.programId).key_dates;
            const mainKeyDate = keyDates.find((keyDate) => keyDate.is_main);
            return mainKeyDate.name;
        },
    },

    methods: {
        onChange(el) {
            this.$emit('onSelectAll', el);
        },
    },
};

</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.timeline-menu {
    position: relative;
    padding: 24px 30px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #6d58ff;
    color: #fff;
    transition: all 0.3s;
    min-height: 81px;

    &-title {
        font-size: 18px;
        font-weight: 700;
    }

    &-subtitle {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.59);
    }
}
</style>
