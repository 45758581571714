<template>
    <div class="question-completion">
        <div class="question-header">
            <div class="title">
                {{ localize(question.template.translations.text) }}
            </div>

            <div class="sub-title">
                <template v-if="hasSeveralAnswers">
                    <t>Several answers possible</t>
                </template>
            </div>
        </div>

        <div class="question-content">
            <slot name="question" />
        </div>

        <div
            v-if="hasCommentarySection"
            class="question-comment"
        >
            <div class="title">
                <t>Comment</t>
            </div>

            <HtFormTextarea
                id="comment"
                class="mb-0"
                :value="value"
                name="comment"
                placeholder="Comment..."
                :disabled="isReadOnly"
                @input="onInput"
            />

            <SurveyAnswerHistory
                v-if="hasPreviousOccurrenceSection"
            >
                <span>{{ previousOccurrence.question.comment }}</span>
            </SurveyAnswerHistory>
        </div>
    </div>
</template>

<script>
import HtFormTextarea from '@/components/globals/HtFormTextarea.vue';
import SurveyAnswerHistory from '@/components/resources/user/surveys/questions/SurveyAnswerHistory.vue';

export default {
    name: 'SurveyQuestionCompletion',

    components: {
        SurveyAnswerHistory,
        HtFormTextarea,
    },

    props: {
        value: {
            type: String,
            default: null,
        },

        questionNumber: {
            type: Number,
            default: 0,
        },

        question: {
            type: Object,
            required: true,
        },

        previousOccurrence: {
            type: Object,
            required: false,
            default: null,
        },

        isReadOnly: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        hasCommentarySection() {
            return this.question.template.has_comment;
        },

        hasPreviousOccurrenceSection() {
            return this.question.template.show_previous_occurrence && this.previousOccurrence;
        },

        hasSeveralAnswers() {
            return ['multiple_choice'].includes(this.question.template.type);
        },
    },

    methods: {
        onInput(value) {
            this.question.is_edited = true;

            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import '~@/styles/design-system-colors.scss';

.title {
    font-family: $lato;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: $neutral-black;
}

.question-completion {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 530px;
    margin: 0 auto;

    .question-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .sub-title {
            color: $neutral-800;
            font-family: $lato;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
        }
    }

    .question-comment {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
</style>
