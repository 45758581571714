<template>
    <ResourceListing
        ref="listing"
        api-name="quizzes"
        :title="translate('Quizzes')"
        :columns="columns"
        :formatter="formatter"
        permission="ModelCompanyQuiz"
        empty-message="There are no quiz to display yet..."
        has-quick-actions
        @on-action-clicked="onActionClicked"
    >
        <template #modalable="{ selectedResourceId }">
            <QuizEdit
                :id="selectedResourceId"
                :disabled-program-list="false"
                @onClose="fetchData"
                @onDelete="fetchData"
                @onSave="fetchData"
            />
        </template>
    </ResourceListing>
</template>

<script>
import api from '@/store/api';
import ResourceListing from '@/components/resources/generic/ResourceListing.vue';
import QuizEdit from './quizzes/QuizEdit.vue';

export default {
    permissions: [
        'ModelCompanyQuiz',
    ],

    components: {
        ResourceListing,
        QuizEdit,
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('Status'),
                    key: 'status',
                    sortable: false,
                },
            ],
        };
    },

    methods: {
        fetchData() {
            this.$refs.listing.fetchData();
        },

        formatter(items) {
            return items.map((item) => {
                const isEnabledResource = item.resource.programs[0]?.is_enabled;
                const quickActions = [];
                if (this.$canCreate('ModelCompanyQuiz', { id: -1 })) {
                    quickActions.push({ id: 'replicate', label: 'Replicate', icon: ['fal', 'copy'] });
                }

                return {
                    ...item,
                    status_label: this.translate(isEnabledResource ? 'Active' : 'Inactive'),
                    status_color: isEnabledResource ? 'green' : 'grey',
                    quick_actions: quickActions,
                };
            });
        },

        replicateQuiz(quiz) {
            api.configuration.quizzes.replicate(quiz.id)
                .then(() => {
                    this.$Notifier('App').showInfo(this.translate('The resource << {resource_name} >> is replicated', {
                        resource_name: quiz.resource.name,
                    }));
                    this.fetchData();
                })
                .catch(() => {
                    this.$Notifier('App').showError(this.translate('The resource << {resource_name} >> is not replicated!', {
                        resource_name: quiz.resource.name,
                    }));
                });
        },

        onActionClicked({ item, actionId: action }) {
            switch (action) {
            case 'replicate':
                this.replicateQuiz(item);
                break;
            }
        },
    },
};
</script>
