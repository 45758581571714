import Vue from 'vue';

export default {
    all(body = {}) {
        return Vue.prototype.$http.post('users', body);
    },

    get(id) {
        return Vue.prototype.$http.get(`users/${id}`);
    },

    toggleTwoFactorAuth(id) {
        return Vue.prototype.$http.post(`company/settings/users/${id}/toggle-two-factor-auth`);
    },
};
