<template>
    <HtTable
        :items="items"
        :columns="columns"
        cypress="action-resource-table"
        :empty-message="'There are no survey to display yet...'"
        :external-search="externalSearch"
        @onCellClicked="$emit('onCellClicked', $event)"
    >
        <template #cell(name)="scope">
            {{ scope.item.resource.name }}
        </template>
        <template #cell(display_status)="scope">
            <HtTag :color="color(scope.item.status)">
                {{ scope.value }}
            </HtTag>
        </template>
        <template #cell(number_of_responses)="scope">
            <span v-if="scope.item.number_of_target_users > 0">
                {{ scope.item.number_of_responses }} / {{ scope.item.number_of_target_users }}
            </span>
            <span v-if="scope.item.number_of_target_users === 0 || !scope.item.number_of_target_users">
                -
            </span>
        </template>
        <template #cell(launch_date)="scope">
            <span class="text-nowrap">{{ scope.item.display_launch_date | empty }}</span>
        </template>
    </HtTable>
</template>
<script>
import HtTable from '@/components/globals/HtTable.vue';
import ParticipantList from '@/components/globals/ParticipantList.vue';
import ResourceListMixin from '@/mixins/ResourceListMixin';

export default {
    name: 'SurveysList',
    components: { HtTable, ParticipantList },
    mixins: [
        ResourceListMixin,
    ],
    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortDirection: 'asc',
                    search: true,
                },
                {
                    label: this.translate('Status'),
                    key: 'display_status',
                    search: true,
                },
                {
                    label: this.translate('Answers'),
                    key: 'number_of_responses',
                    class: 'd-none d-md-table-cell',
                    sortable: false,
                },
                {
                    label: this.translate('Launch date'),
                    key: 'launch_date',
                    class: 'd-none d-md-table-cell',
                },
                {
                    label: this.translate('Program'),
                    key: 'resource_program.models[0].program.name_translated',
                    sortable: false,
                    class: 'hide-mobile',
                    search: false,
                },
            ],
        };
    },
    methods: {
        color(status) {
            const colors = {
                draft: 'orange',
                scheduled: 'purple',
                in_progress: 'green',
                completed: 'grey',
            };
            return colors[status];
        },
    },
};

export const surveyConfig = {
    fields: [
        'id',
        'company_id',
        'datetime_start',
        'status',
        'launch_date',
        'number_of_target_users',
        'number_of_responses',
    ],
    relations: {
        resource: (query) => {
            query
                .select([
                    'id',
                    'name',
                ])
                .with({
                    programs: (query) => {
                        query
                            .select([
                                'id',
                                'company_program_id',
                            ])
                            .with({
                                program: (query) => {
                                    query.with({
                                        htProgramType: (query) => {
                                            query.select([
                                                'id',
                                                'slug',
                                            ]);
                                        },
                                    });
                                },
                            });
                    },
                });
        },
    },
};

</script>
