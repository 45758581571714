<template>
    <div class="question-history">
        <div
            v-for="choice in templateAnswers"
            v-if="answers.find((v) => parseInt(v.company_survey_question_answer_id, 10) === parseInt(choice.id, 10))"
            class="question-history-item"
        >
            {{ localize(choice.translations.text) }}
        </div>
    </div>
</template>

<script>
import SurveyQuestionHistoryMixin from '@/components/resources/user/surveys/questions/SurveyQuestionHistoryMixin';

export default {
    name: 'SurveyChoiceHistory',

    mixins: [
        SurveyQuestionHistoryMixin,
    ],
};
</script>

<style lang="scss" scoped>
@import '../SurveyQuestion';

.question-history {
    max-height: 64px;
    overflow: hidden;
}
</style>
