import Vue from 'vue';

export default {
    getAllByUser(id, data = {}) {
        return Vue.prototype.$http.get(`users/planning-events/user/${id}`, { params: data });
    },

    getAllByUserProgram(id) {
        return Vue.prototype.$http.get(`users/planning-events/user-program/${id}`);
    },

    get(id) {
        return Vue.prototype.$http.get(`users/planning-events/${id}`);
    },

    create(data) {
        return Vue.prototype.$http.post('users/planning-events', data);
    },

    update(id, data) {
        return Vue.prototype.$http.put(`users/planning-events/${id}`, data);
    },

    delete(id) {
        return Vue.prototype.$http.delete(`users/planning-events/${id}`);
    },
};
