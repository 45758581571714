import Model from '@tony.caron/node-model-proxy/Model';
import CompanyRoleProgram from './CompanyRoleProgram';
import I18n from '../modules/i18n/I18n';
import CompanyRolePermission from './CompanyRolePermission';
import CompanyRoleCollection from './CompanyRoleCollection';

export default class CompanyRole extends Model {
    static ROLE_DELILMITER_IN_CSV = ',';

    static NAME_BUDDY = 'buddy';

    static NAME_ADMIN = 'admin';

    static NAME_MANAGER = 'manager';

    static NAME_EMPLOYEE = 'enrollee';

    static NAME_GUEST = 'guest';

    static NAME_SOFTWARE_MANAGER = 'software_responsible';

    static NAME_EQUIPMENT_MANAGER = 'equipment_responsible';

    static NAME_ADMINISTRATIVE_MANAGER = 'administrative_responsible';

    static ALIAS_BUDDY = 'Buddy';

    static ALIAS_ADMIN = 'Admin';

    static ALIAS_MANAGER = 'Manager';

    static ALIAS_EMPLOYEE = 'Employee';

    static ALIAS_SOFTWARE_MANAGER = 'Software Responsible';

    static ALIAS_EQUIPMENT_MANAGER = 'Equipment Responsible';

    static ALIAS_ADMINISTRATIVE_MANAGER = 'Administrative Responsible';

    modelConfig() {
        return {
            collection: CompanyRoleCollection,
        };
    }

    modelCustomAttributes() {
        return {
            alias_translated: '',
            description_translated: '',
        };
    }

    modelDefaultValues() {
        return {
            description: '',
            default_sort_dashboard: 'date',
        };
    }

    modelRelations() {
        return {
            companyRolePermission: () => this.hasMany(CompanyRolePermission, 'company_role_id', 'id'),
            companyRoleProgram: () => this.hasMany(CompanyRoleProgram, 'company_role_id', 'id'),
        };
    }

    modelAccessors() {
        return {
            alias_translated: () => (this.is_heyteam ? I18n.translate(this.alias) : this.alias),
            alias: (n) => (this.is_heyteam ? I18n.translate(n) : n),
            description_translated: () => (this.is_heyteam ? I18n.translate(this.description) : this.description),
        };
    }

    modelInConvertor() {
        return {
            is_guest: (value) => Boolean(value),
        };
    }
}
