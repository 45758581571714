<template>
    <div>
        <form
            v-if="companyUserProgramTask.isLoaded()"
            class="form-2 spaced"
        >
            <div
                v-if="areActionsLoaded"
                class="modal-actions-import"
            >
                <fieldset
                    v-if="allActionOptions.length > 0"
                    class="modal-actions-import-fieldset"
                >
                    <HtFormSelect
                        id="action"
                        :label="translate('Import from library')"
                        class="mb-2"
                        :placeholder="translate('Actions')"
                        name="action"
                        :options="actionOptions"
                        @input="onSelectedAction(...arguments)"
                    />

                    <HtFormSwitch
                        :id="'are-displayed-personal-library'"
                        v-model="areDisplayedPersonalLibrary"
                        :name="'are-displayed-personal-library'"
                        :show-optional="false"
                        :label="translate('Display only my personal library')"
                    />
                </fieldset>

                <div
                    v-else
                    class="empty-component mb-20 text-center"
                >
                    <strong><t>All available tasks already selected for this timeline !</t> </strong>
                </div>
            </div>

            <HtFormInput
                v-model.trim="item.title"
                v-validate.disable="required"
                :label="translate('Title')"
                :data-vv-as="translate('action title')"
                name="title"
                cypress="title"
                show-asterisk
            />

            <fieldset class="medium">
                <HtFormEditor
                    :id="'description'"
                    v-model="item.description"
                    :name="'description'"
                    :label="translate('Description')"
                    :has-toolbar="false"
                />
            </fieldset>

            <fieldset>
                <InputParticipants
                    v-model="item.company_user_program_task_participant"
                    :company-user-id="companyUserId"
                />
            </fieldset>

            <HtUserProgramList
                v-model="item.company_user_program_id"
                :company-user-id="companyUserId"
                :disabled="true"
            />

            <HtKeyDatesInputOrder
                :end-label="translate('To deliver')"
                :user-program-id="companyUserProgramId"
                :resource-id="item.id"
                :resource-type="'custom'"
                :is-business-day.sync="item.is_business_day"
                :offset-key-date.sync="item.company_user_program_key_date_id"
                :offset-availability-specific-date.sync="item.datetime_availability"
                :offset-availability-number.sync="item.offset_availability_number"
                :offset-availability-unit.sync="item.offset_availability_unit"
                :offset-end-specific-date.sync="item.datetime_end"
                :offset-end-number.sync="item.offset_end_number"
                :offset-end-unit.sync="item.offset_end_unit"
                can-select-specific-date
                :dependents="item.dependents.models"
            />

            <div class="modal-actions right">
                <Button
                    class="negative"
                    @click="onRemove()"
                >
                    <t>Remove</t>
                </Button>
                <Button
                    :state="buttonState"
                    @click="onUpdate()"
                >
                    <t>Save</t>
                </Button>
            </div>
        </form>
        <IconLoading v-else />
    </div>
</template>

<script>
import CompanyUserProgram from '@/models/CompanyUserProgram';
import CompanyUserProgramTaskCollection from '@/models/CompanyUserProgramTaskCollection';
import CompanyProgramTaskCollection from '@/models/CompanyProgramTaskCollection';
import CompanyUserProgramCollection from '@/models/CompanyUserProgramCollection';
import HtUserProgramList from '@/components/globals/HtUserProgramList.vue';
import HtKeyDatesInputOrder from '@/components/globals/HtKeyDatesInputOrder.vue';
import HtFormEditor from '@/components/globals/HtFormEditor.vue';
import api from '@/store/api';
import HtFormSwitch from '@/components/globals/HtFormSwitch.vue';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';

export default {
    name: 'UserActionSelect',
    components: {
        HtFormSwitch,
        HtFormEditor,
        HtUserProgramList,
        HtKeyDatesInputOrder,
    },
    inject: ['modal'],

    props: {
        companyUserId: {
            type: Number,
            required: true,
        },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
        exhaustiveSelection: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            item: null,
            selectedAction: null,
            fetchedSelectedIds: [],
            companyUserProgram: new CompanyUserProgram([
                'id',
                'company_program_id',
            ]).with({
                mainKeyDate: (query) => {
                    query.select(['id', 'starts_at']);
                },
                companyUser: (query) => {
                    query.select(['id']);
                },
            }).where([
                ['id', '=', this.companyUserProgramId],
            ]),
            actions: [],
            areActionsLoaded: false,
            areDisplayedPersonalLibrary: false,
            companyProgramTasks: new CompanyProgramTaskCollection([
                'id',
                'company_program_id',
                'description',
            ]).with({
                resource: (query) => {
                    query
                        .select(['id', 'name', 'description'])
                        .with({
                            programs: (query) => {
                                query
                                    .select([
                                        'id',
                                        'is_business_day',
                                        'offset_availability_number',
                                        'offset_availability_unit',
                                        'offset_end_number',
                                        'offset_end_unit',
                                    ])
                                    .with({
                                        participants: (query) => {
                                            query
                                                .select(['id', 'company_user_id', 'company_role_id'])
                                                .with({
                                                    user: (query) => {
                                                        query.select(['id', 'image', 'fullname', 'firstname', 'lastname']);
                                                    },
                                                });
                                        },
                                    });
                            },
                        });
                },
            }),
            companyUserProgramTask: new CompanyUserProgramTaskCollection([
                'id',
                'company_user_program_id',
                'title',
                'description',
                'company_user_id',
                'status',
                'company_program_task_id',
                'resource',
                'is_business_day',
                'company_user_program_key_date_id',
                'offset_availability_number',
                'offset_availability_unit',
                'offset_end_number',
                'offset_end_unit',
                'datetime_availability',
                'datetime_end',
            ]).with({
                dependents: (query) => {
                    query.select(['id']);
                },
                companyUser: (query) => {
                    query.select(['id', 'firstname', 'arrival_date']);
                },
                companyUserProgramTaskParticipant: (query) => {
                    query.select(['company_user_id']).with({
                        companyUser: (query) => {
                            query.select(['id', 'firstname', 'lastname', 'image']);
                        },
                    });
                },
                companyUserProgram: (query) => {
                    query
                        .select(['id'])
                        .with({
                            companyProgram: (query) => query.with({
                                keyDate: (query) => query.with({
                                    locales: (query) => query.select(['id', 'name', 'language_key']),
                                }),
                            }),
                        });
                },
            }).where([
                ['company_user_id', '=', this.companyUserId],
            ]),
            companyUserPrograms: new CompanyUserProgramCollection(['id', 'company_program_id']),
        };
    },

    computed: {
        allActionOptions() {
            return this.actions.map((action) => ({
                id: action,
                name: action.resource.name,
            }));
        },

        actionOptions() {
            return this.allActionOptions.filter(
                (actionOption) => actionOption.id.resource.is_private === this.areDisplayedPersonalLibrary,
            );
        },

        buttonState() {
            return this.item && this.item._state.isSaving ? 'loading' : 'idle';
        },
    },

    watch: {
        areDisplayedPersonalLibrary() {
            this.selectedAction = null;
        },
    },

    created() {
        this.companyUserProgram.get().then(() => {
            api.configuration.actions
                .getAll()
                .then((res) => {
                    this.actions = res.data.data;
                    this.areActionsLoaded = true;
                });

            this.companyUserProgramTask.get().then(() => {
                this.fetchedSelectedIds = this.companyUserProgramTask.$.models.filter((item) => Boolean(item.company_program_task_id)).map((item) => item.company_program_task_id);
            });
        });

        this.item = this.companyUserProgramTask.new();
        this.item.company_user_id = this.companyUserId;
        this.item.resource = 'custom';

        this.companyUserProgramTask.company_user_id = this.companyUserId;

        if (this.companyUserProgramId) {
            this.item.company_user_program_id = this.companyUserProgramId;
        }

        this.modal.setTitle(this.translate('Add program action'));
    },

    methods: {
        onUpdate() {
            this.$validator.validateAll().then(() => {
                if (this.errors.any()) { return; }

                this.item.save().then(() => {
                    this.modal.close();
                    this.$emit('onUpdate');
                });
            });
        },

        onSelectedAction(value) {
            this.selectedAction = value;

            const firstResourceProgram = value.resource.programs[0] || {
                offset_availability_number: 0,
                offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                offset_end_number: 0,
                offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                is_business_day: true,
                participants: [],
            };

            this.item.company_program_task_id = value.id;
            this.item.title = value.resource.name;
            this.item.description = value.resource.description;

            this.item.is_business_day = firstResourceProgram.is_business_day;
            this.item.offset_availability_number = firstResourceProgram.offset_availability_number;
            this.item.offset_availability_unit = firstResourceProgram.offset_availability_unit;
            this.item.offset_end_number = firstResourceProgram.offset_end_number;
            this.item.offset_end_unit = firstResourceProgram.offset_end_unit;

            this.item.company_user_program_task_participant = this.convertParticipants(firstResourceProgram.participants);
            this.item.company_user_program_id = this.companyUserProgramId;
        },

        convertParticipants(participants) {
            const converted = participants.reduce((acc, user) => {
                if (typeof acc === 'undefined') { acc = []; }

                const supervisor = this.exhaustiveSelection.find((supervi) => (supervi.company_role && supervi.company_role.id === user.company_role_id)
                    || (supervi.company_user && supervi.company_user.id === user.company_user_id));

                if (supervisor) {
                    acc.push({
                        ...user,
                        ...(supervisor && { company_user_id: supervisor.company_user.id, company_user: supervisor.company_user }),
                    });
                }

                return acc;
            }, []);

            // FILTER OUT DUPLICATES
            return this.$Utils.getArrayUniqueByProperty((x) => x.company_user_id, converted);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.modal-actions-import {
    border-radius: $border-radius-8;
    background: $grey-8;
    padding: 1.6rem;
    margin-bottom: 2rem;

    &-fieldset {
        margin-bottom: 0 !important;
    }

    .ht-form.ht-switch {
        margin-bottom: 0;
    }
}
</style>
