<template>
    <div class="list-answer">
        <div
            v-if="companySurveyQuestionAnswerCollection.models.length > 5"
            class="form-2"
        >
            <HtFormMultiSelect
                :id="`${companyChatChannelMessage.id}-simple-choice`"
                v-model="selected"
                class="list-answer-select"
                :disabled="readOnly"
                :is-single="true"
                :placeholder="translate('Select answers')"
                :options="getOptionsAnswers"
                :name="`${companyChatChannelMessage.id}-simple-choice`"
                @open="$emit('focus')"
                @close="$emit('blur')"
            />
        </div>
        <div
            v-for="item in companySurveyQuestionAnswerCollection.models"
            v-else
            :key="item.id"
            :class="customClass(item)"
            class="list-answer-item"
            @click="setId(item.id)"
        >
            <div class="list-answer-item-checkbox">
                <input
                    type="radio"
                    name="answer-item"
                    :checked="isActive(item.id) || isChecked(item.id)"
                    :disabled="readOnly"
                >
            </div>
            <div class="list-answer-item-name">
                {{ getAnswerChoice(item) }}
            </div>
        </div>
        <div
            v-if="readOnly === false"
            class="list-answer-actions"
        >
            <div
                v-if="isNotMandatory"
                class="list-answer-actions-skip"
                @click="skip()"
            >
                <t>Skip</t>
            </div>
            <Button
                :state="buttonState"
                @click="validateAnswer()"
            >
                <t>Validate</t>
            </Button>
        </div>
    </div>
</template>

<script>
import MessageResourceUserSurveyQuestionMixin from './mixin/MessageResourceUserSurveyQuestionMixin';

export default {
    name: 'MessageResourceUserSurveyQuestionSimpleChoice',
    mixins: [
        MessageResourceUserSurveyQuestionMixin,
    ],
    data() {
        return {
            selected: null,
            id: null,
        };
    },
    computed: {
        buttonState() {
            return this.id ? 'idle' : 'disabled';
        },

        getOptionsAnswers() {
            return this.companySurveyQuestionAnswerCollection.models.map((answer) => {
                const name = (answer.company_entity_value_id) ? answer.value.custom.name : answer.text;
                return {
                    id: answer.id,
                    name,
                };
            });
        },
    },
    watch: {
        selected() {
            this.id = (this.selected) ? this.selected.id : null;
        },
    },
    created() {
        if (this.companyUserSurveyQuestionAnswerCollection.models.length > 0) {
            this.selected = this.companySurveyQuestionAnswerCollection.models.find((item) => item.id === this.companyUserSurveyQuestionAnswerCollection.models[0].company_survey_question_answer_id);
        }
    },
    methods: {
        isChecked(companySurveyQuestionAnswerId) {
            return this.id === companySurveyQuestionAnswerId;
        },
        validateAnswer() {
            if (this.id !== null) {
                this.sendAnswer(this.id);
            }
        },
        customClass(companySurveyQuestionAnswer) {
            return {
                disabled: this.isActive(companySurveyQuestionAnswer.id) && this.readOnly,
                'cursor-pointer': this.readOnly === false,
            };
        },
        setId(id) {
            if (this.readOnly) return;

            this.id = id;
        },

        getAnswerChoice(answer) {
            if (answer.company_entity_value_id) {
                return answer.value.custom.name;
            }

            return answer.text;
        },
    },
};
</script>

<style lang="scss" scoped src="./MessageResourceUserSurveyQuestionSimpleChoice.scss"></style>

<style lang="scss">
.list-answer .multiselect__content-wrapper {
    max-height: 300px !important;
}
</style>
