<template>
    <div class="statistics-wrapper">
        <iframe
            :src="iframeUrl"
            frameborder="0"
            allowtransparency
            class="iframe"
            height="100%"
            width="100%"
        />
    </div>
</template>
<script>
export default {
    name: 'Report',
    data() {
        return {
            iframeUrl: '',
        };
    },

    created() {
        this.$http.get('dashboard/report/metabase-iframe').then((response) => {
            this.iframeUrl = response.data.iframe;
        });
    },
};
</script>
<style lang="scss" scoped>
.statistics-wrapper{
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;

    .iframe {
        overflow: hidden;
        height: 100%;
        width: 100%;
        position: relative;
        inset: 0px;
    }
}
</style>
