<template>
    <div>
        <HtAccordion
            :title="translate('Training | Trainings', {count: filteredUserTraining.length})"
            :count="filteredUserTraining.length"
            :has-button="filteredUserTraining.length === 0"
            :button-label="addButtonLabel"
            :is-branding="false"
            cypress="training"
            @buttonClick="openSelectModal()"
        >
            <template v-if="filteredUserTraining.length > 0">
                <HtTable
                    :items="filteredUserTraining"
                    :columns="columns"
                    cypress="training-table"
                    @onCellClicked="openEditModal"
                />
                <div class="mt-4 text-center">
                    <Button
                        type="clear"
                        cypress="training-add-button"
                        @click="openSelectModal()"
                    >
                        <slot :name="'buttonText'">
                            {{ translate(addButtonLabel) }}
                        </slot>
                    </Button>
                </div>
            </template>
            <div
                v-else
                class="empty-component"
            >
                <strong>
                    <t>No training added</t>
                </strong>
            </div>
        </HtAccordion>

        <Modalable
            ref="modalableSelect"
            :mode="2"
            class="modalable-1 medium"
        >
            <UserTrainingSelect
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                @onNew="onNew()"
            />
        </Modalable>

        <Modalable
            ref="modalableEdit"
            :mode="2"
            class="modalable-1 medium"
        >
            <UserTrainingEdit
                :id="id"
                :company-user-id="companyUserId"
                :company-user-program-id="companyUserProgramId"
                @onUpdate="onUpdate()"
                @onDelete="onDelete()"
            />
        </Modalable>
    </div>
</template>

<script>
import CompanyUserTrainingCollection from '@/models/CompanyUserTrainingCollection';
import HtAccordion from '@/components/globals/HtAccordion.vue';
import UserTrainingEdit from '@/components/pages/dashboard/modals/training/UserTrainingEdit.vue';
import UserTrainingSelect from '@/components/pages/dashboard/modals/training/UserTrainingSelect.vue';

export default {
    name: 'UserTrainingFlow',
    components: {
        UserTrainingSelect,
        UserTrainingEdit,
        HtAccordion,
    },

    props: {
        isEdit: { type: Boolean, default: false },
        companyUserId: { type: Number, required: true },
        companyUserProgramId: {
            type: Number,
            required: false,
        },
    },

    data() {
        return {
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    sortable: true,
                },
            ],
            addButtonLabel: 'Add training',
            id: null,
            companyUserTrainingCollection: new CompanyUserTrainingCollection([
                'id', 'company_user_id', 'name', 'status', 'company_user_program_id', 'order',
            ]).with({
                companyUserProgram: (query) => {
                    query.select(['id', 'company_program_id']).with({
                        companyProgram: (query) => {
                            query.select(['id']).with({
                                locales: (query) => {
                                    query.select(['id', 'name', 'language_key']);
                                },
                            });
                        },
                    });
                },
                companyUserProgramTask: (query) => {
                    query.select(['id', 'resource_id', 'resource', 'status', 'datetime_availability']);
                },
            }).where([
                ['company_user_program_id', '=', this.companyUserProgramId],
            ]).orderBy([
                ['order', 'asc'],
            ]),
        };
    },

    computed: {
        filteredUserTraining() {
            return this.companyUserTrainingCollection.$.models.filter((item) => ((this.shared.session.companyUser.id == this.companyUserId)
                ? this.$Utils.moment().isSameOrAfter(this.$Utils.moment(item.company_user_program_task.datetime_availability))
                : true));
        },
    },

    created() {
        this.companyUserTrainingCollection.company_user_id = this.companyUserId;
        this.companyUserTrainingCollection.get().then(() => {
            this.$emit('on-load', this.companyUserTrainingCollection.$.models.length);
        });
    },

    methods: {
        onNew() {
            this.companyUserTrainingCollection.get();
        },

        onDelete() {
            this.companyUserTrainingCollection.get();
        },

        onUpdate() {
            this.companyUserTrainingCollection.get();
        },

        openEditModal(row) {
            this.id = row.item.id;
            this.$refs.modalableEdit.open();
        },

        openSelectModal() {
            this.$refs.modalableSelect.open();
        },
    },
};
</script>
