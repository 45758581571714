<template>
    <div class="enrolling-header">
        <span v-if="hasErrors" class="error">
            <FontAwesomeIcon
                :icon="['far', 'exclamation-circle']"
            />

            <t>Information must be provided to continue</t>
        </span>

        <div class="enrolling-header-content">
            <h2
                v-if="title"
                class="enrolling-header-content-title"
            >
                {{ title }}
            </h2>

            <div class="enrolling-header-content-extra">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import HtButton from '@/components/globals/HtButton.vue';

export default {
    name: 'BulkEnrollingHeader',

    components: {
        HtButton,
    },

    props: {
        title: {
            type: String,
            default: () => null,
        },

        hasErrors: {
            type: Boolean,
            default: () => false,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.enrolling-header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .error {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $semantic-error;
    }

    &-content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        align-items: center;

        &-title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 500;
            color: $neutral-black;
            margin: 0;
        }

        &-extra {
            display: flex;
            gap: 16px;
        }
    }
}
</style>
