<template>
    <div class="link-list">
        <a
            v-for="answer in userSurveyQuestionAnswer.models"
            target="_blank"
            :href="answer.company_file.link"
        >
            {{ answer.company_file.original_name }}
        </a>
    </div>
</template>

<script>
import CompanyUserSurveyQuestionAnswerCollection from '@/models/CompanyUserSurveyQuestionAnswerCollection';

export default {
    name: 'ResultFileUpload',
    props: {
        userSurveyQuestionAnswer: {
            type: CompanyUserSurveyQuestionAnswerCollection,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.link-list {
    display: flex;
    flex-direction: column;
}
</style>
