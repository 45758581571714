<template>
    <div
        class="timezone-selector"
        :class="{errors: errorMessages.length > 0}"
    >
        <HtFormMultiSelect
            :id="id"
            :name="name"
            :options="availableTimezones"
            :is-single="true"
            :allow-empty="false"
            :track-by="trackBy"
            description=""
            :label="fieldLabel"
            :label-options="itemLabel"
            :placeholder="`${translate('Timezone selection')}*`"
            :v-validate.disable="isRequired"
            :value="fullValue"
            @input="updateValue"
        />
        <template v-if="errorMessages">
            <div
                v-for="error in errorMessages"
                :key="error"
                class="ht-form-error mt-3"
            >
                {{ error }}
            </div>
        </template>
    </div>
</template>

<script>
import HtFormMultiSelect from '@/components/globals/HtFormMultiSelect.vue';
import timezoneMixin from '@/components/globals/timezone/timezoneMixin';
import HtFormMixin from '@/components/globals/HtFormMixin';

export default {
    name: 'TimezoneSelector',
    components: {
        HtFormMultiSelect,
    },
    mixins: [HtFormMixin, timezoneMixin],
    props: {
        id: {
            type: String,
            default() {
                return 'timezone';
            },
        },
        name: {
            type: String,
            default() {
                return 'timezone';
            },
        },
        itemLabel: {
            type: String,
            default() {
                return 'displayName';
            },
        },
        fieldLabel: {
            type: String,
        },
        trackBy: {
            type: String,
            default() {
                return 'name';
            },
        },
        value: {
            type: String,
            default() {
                return null;
            },
        },
        defaultValue: {
            type: String,
            default() {
                return 'Europe/Paris';
            },
        },
        rules: {
            type: String,
            default() {
                return '';
            },
        },
        errorMessages: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    computed: {
        fullValue() {
            let { value } = this;
            if (!this.value) {
                value = this.defaultValue;
            }
            return this.returnNewTzWithDisplayableName(value);
        },
        availableTimezones() {
            return this.getAvailableTimeZones().map((timezone) => this.returnNewTzWithDisplayableName(timezone));
        },
    },
    methods: {
        returnNewTzWithDisplayableName(timezone) {
            return {
                name: timezone,
                displayName: this.formatTimezoneName(timezone),
            };
        },
        updateValue(value) {
            // Update with empty string to trigger backend validation,
            // if null is send timezone property of model don't be send to api
            this.$emit('update:value', value?.name || '');
        },
    },
};
</script>

<style scoped style="">
>>>.ht-form .ht-form-error {
    display: none;
}
.timezone-selector .ht-form-multiselect {
    margin-bottom: 0;
}
.errors >>> .multiselect__tags {
    border-color: #EA2A35!important;  /* var(--error-color)*/
}
.errors >>> .multiselect__placeholder {
    color: #EA2A35!important;  /* var(--error-color)*/
}
.errors >>> .icon {
    color: #EA2A35!important;  /* var(--error-color)*/
}
</style>
