<template>
    <HtFormGroup
        :label="label"
        :error="error"
        :is-required="isRequired"
    >
        <div class="ht-checkbox" :class="{disabled}">
            <div
                v-for="(option, index) in options"
                :key="index"
                class="ht-checkbox-item"
            >
                <input
                    :id="id+'-'+index"
                    v-model="text"
                    class="ht-checkbox-item-input"
                    :name="name"
                    :value="option.value"
                    type="radio"
                    :disabled="disabled"
                >
                <label
                    class="ht-checkbox-item-name"
                    :for="id+'-'+index"
                >
                    <span>{{ option.text }}</span>
                    <span v-if="option.description" class="ht-checkbox-item-name-description">{{ option.description }}</span>
                </label>
            </div>
        </div>
    </HtFormGroup>
</template>

<script>
import HtFormMixin from './HtFormMixin';

export default {
    name: 'HtFormRadio',
    mixins: [
        HtFormMixin,
    ],

    props: {
        value: {
            type: [String, Number],
            default: () => null,
        },
        options: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            text: null,
        };
    },

    watch: {
        text() {
            this.$emit('input', this.text);
        },
    },

    created() {
        this.text = this.value;
    },
};
</script>

<style lang="scss" scope src="./HtFormCheckbox.scss"></style>
