import ValidatorException from './validatorException';

export default {

    required: (node) => {
        node.message = 'This field is required';

        if (node.el.getAttribute('type') == 'checkbox') return node.el.checked;

        return node.getValue().trim().length > 0;
    },
    email: (node) => {
        node.message = 'This field must been an email';

        const value = node.getValue().trim();
        // return value.length == 0 ||/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(value);
        return value.length == 0 || /^([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,})$/i.test(value);
    },
    date: (node) => {
        node.message = 'This field must been a valid date';

        const value = node.getValue().trim();
        return value.length == 0 || /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(value);
    },
    min: (node) => {
        node.message = `This field must have at least ${node.rules.min.value} characters`;
        return node.getValue().length >= node.rules.min.value;
    },
    max: (node) => {
        node.message = `This field must have a maximum of ${node.rules.max.value} characters`;
        return node.getValue().length <= node.rules.max.value;
    },
    confirm: (node) => {
        let confirmNode;
        const confirmNodeName = node.rules.confirm.value;

        const aConfirmNodeName = confirmNodeName.split('.');

        if (aConfirmNodeName[1]) confirmNode = node.errorBag._getNode(aConfirmNodeName[1], aConfirmNodeName[0]);
        else confirmNode = node.errorBag._getNode(confirmNodeName);

        return node.getValue() == confirmNode.getValue();
    },
    numeric: (node) => {
        node.message = 'This field must been numeric';
        return /^[0-9]+$/.test(String(node.getValue()));
    },

    // http://stackoverflow.com/questions/8667070/javascript-regular-expression-to-validate-url
    // TODO https://github.com/logaretm/vee-validate/blob/master/dist/vee-validate.js : function isURL(url, options) {
    url: (node) => {
        node.message = 'This field need to be an url';

        const value = node.getValue().trim();

        return value.length == 0 || /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(String(value));
    },

    // Works, but actually the only way is to disable the update directive with:
    // vueDirectiveUpdateAllowed => false
    func: (node) => {
        node.message = '';

        const funcStr = node.rules.func.value;

        if (!node.context[funcStr]) throw new ValidatorException('Custom promise not found');

        // Disable directive update to avoid loop
        node.vueDirectiveUpdateAllowed = false;

        node.context[funcStr](node.getValue()).then(([valid, message = '']) => {
            node.valid = valid;
            node.message = message;

            node.errorBag.updateObserverCache();

            /* setTimeout(() => {
				node.vueDirectiveUpdateAllowed = true;
			}, 100) */
        });

        // Validate by default to not throw error
        return true;
    },
};
