import Model from '@tony.caron/node-model-proxy/Model';
import I18n from '../modules/i18n/I18n';

export default class CompanyUserNotificationSettings extends Model {
    static TYPE_SYSTEM = 'system';

    static TYPE_RESOURCE = 'resource';

    static RESOURCEABLE_EVENT = 'App\\CompanyPlanningEvent';

    modelConfig() {
        return {
            controller: 'CompanyUserNotificationSettingsController',
        };
    }

    modelDefaultValues() {
        return {
        };
    }

    modelCustomAttributes() {
        return {
            title_translated: null,
            resource_translated: null,
        };
    }

    modelAccessors() {
        return {
            title_translated: () => ((this.title !== null) ? I18n.translate(this.title) : null),
            resource_translated: () => {
                let resource_label = null;
                switch (this.user_resourceable_type) {
                case CompanyUserNotificationSettings.RESOURCEABLE_EVENT:
                    resource_label = 'Event';
                    break;
                default:
                }
                return (resource_label) ? I18n.translate(resource_label) : null;
            },
        };
    }

    modelInConvertor() {
        return {
            is_enabled_email: (n) => Boolean(n),
            is_enabled_sms: (n) => Boolean(n),
        };
    }
}
