/* eslint-disable no-useless-constructor */
/* eslint-disable class-methods-use-this */

import HtProgramType from '@/models/HtProgramType';
import CompanyLanguage from '@/models/CompanyLanguage';
import CompanyRole from '@/models/CompanyRole';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import AbstractTemplateProvider from './AbstractTemplateProvider';

export default class TalentTemplateProvider extends AbstractTemplateProvider {
    static TEMPLATE_SLUG = 'talent-acquisition';

    constructor(programTypes, keyDates, roles, employeeDepartmentGroupId) {
        super(programTypes, keyDates, roles, employeeDepartmentGroupId);
    }

    get baseProgramData() {
        return {
            slug: TalentTemplateProvider.TEMPLATE_SLUG,
            ht_program_type_id: this.programTypes.find(
                (type) => type.slug === HtProgramType.SLUG_PREBOARDING,
            ).id,
            locales: [
                {
                    name: 'Attirer des talents',
                    description:
                        'Attirez vos candidats via un parcours spécifique afin de les convaincre de vous rejoindre !',
                    language_key: CompanyLanguage.KEY_FR,
                },
                {
                    name: 'Attract talents',
                    description:
                        'Attract your candidates with a specific path in order to convince them to join you!',
                    language_key: CompanyLanguage.KEY_EN,
                },
            ],
            key_dates: [
                {
                    ht_program_key_date: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Program creation date')),
                    ht_program_key_date_id: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Program creation date')).id,
                    is_main: true,
                },
            ],
            actions: [],
            emails: [],
            surveys: [],
            events: [],
        };
    }

    get actions() {
        return [
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Envoyer un mot de bienvenue en vidéo',
                            [CompanyLanguage.KEY_EN]: 'Send a welcome video',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 5,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Organiser une rencontre entre le talent et un collaborateur exerçant le même métier',
                            [CompanyLanguage.KEY_EN]: 'Set up a meeting between the talent and a colleague working on the same job',
                        },
                    },
                },
            },
        ];
    }

    get emails() {
        return [
            {
                template: 'custom',
                translations: [
                    {
                        subject: 'Bienvenue chez {company_name} !',
                        content: `<p>Bonjour {enrollee_firstname}</p>
                            <p>Nous sommes ravis de t'accueillir chez {company_name}, et impatient à l'idée de te rencontrer.</p>
                            <p>Nous t'avons préparer quelques surprises afin que tu puisses découvrir {company_name} de manière plus approfondie durant les prochains jours.</p>
                            <p>Nous espérons que cela te plaira !</p>
                            <p>Toute l'équipe te souhaite la bienvenue.</p>
                            <p>À très vite.</p>`,
                        signature: '{sender_firstname} {sender_lastname}',
                        language_key: CompanyLanguage.KEY_FR,
                    },
                    {
                        subject: 'Welcome at {company_name} !',
                        content: `<p>Hello {enrollee_firstname}</p>
                            <p>We are excited to welcome you to {company_name}, and look forward to meeting you.</p>
                            <p>We have prepared some surprises for you so that you can further discover {company_name} over the next few days.</p>
                            <p>We hope you will enjoy it!</p>
                            <p>The whole team welcomes you.</p>
                            <p>See you soon.</p>`,
                        signature: '{sender_firstname} {sender_lastname}',
                        language_key: CompanyLanguage.KEY_EN,
                    },
                ],
                file_translations: [],
                resource: {
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Mail de bienvenue',
                            [CompanyLanguage.KEY_EN]: 'Welcome email',
                        },
                    },
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 0,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                },
                sender_role_id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
            },
        ];
    }

    get events() {
        return [
            {
                time_start: '10:00',
                time_end: '11:00',
                is_delegated: false,
                timezone: 'Europe/Paris',
                organiser_role: {
                    id: this.roles[CompanyRole.NAME_MANAGER],
                },
                email_sending_offset: 2,
                translations: {
                    content: {
                        [CompanyLanguage.KEY_FR]: `<p>Bonjour {enrollee_firstname}</p>
                        <p>Prenons un moment ensemble afin d'échanger sur le poste et de présenter l'entreprise.</p>`,
                        [CompanyLanguage.KEY_EN]: `<p>Hello {enrollee_firstname}</p>
                        <p>Let's take a moment together to discuss the position and introduce you the company.</p>`,
                    },
                },
                file_translations: {},
                resource: {
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Echange avec le manager',
                            [CompanyLanguage.KEY_EN]: 'Meeting with the talent',
                        },
                        description: {
                            [CompanyLanguage.KEY_FR]: 'Premier échange avec le manager',
                            [CompanyLanguage.KEY_EN]: 'First discussion with the manager',
                        },
                    },
                    programs: [
                        {
                            is_enabled: true,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_availability_number: 0,
                            offset_end_number: 3,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                            filters: [],
                        },
                    ],
                },
            },
        ];
    }
}
