<template>
    <v-popover trigger="hover">
        <div
            class="status-infos tooltip-target b3"
        >
            <div class="statuses-container">
                <div
                    class="task-status"
                    :class="{'zero-value': doneTasks === 0}"
                >
                    <span class="color-round done" />
                    {{ doneTasks }}
                </div>
                <div
                    class="task-status"
                    :class="{'zero-value': pendingTasks === 0}"
                >
                    <span class="color-round pending" />
                    {{ pendingTasks }}
                </div>
                <div
                    class="task-status"
                    :class="{'zero-value': awaitingTasks === 0}"
                >
                    <span class="color-round awaiting" />
                    {{ awaitingTasks }}
                </div>
                <div
                    class="task-status"
                    :class="{'zero-value': lateTasks === 0}"
                >
                    <span class="color-round late" />
                    {{ lateTasks }}
                </div>
                <div
                    class="task-status"
                    :class="{'zero-value': remindedTasks === 0}"
                >
                    <span class="color-round reminded" />
                    {{ remindedTasks }}
                </div>
            </div>
        </div>
        <template #popover>
            <div class="tooltip-content">
                <div>
                    {{ translate('From ongoing programs :') }}
                </div>
                <div>
                    <span class="color-round done" />
                    {{ doneTasks }} {{ translate('Done tasks') }}
                </div>
                <div>
                    <span class="color-round pending" />
                    {{ pendingTasks }} {{ translate('Pending tasks') }}
                </div>
                <div>
                    <span class="color-round awaiting" />
                    {{ awaitingTasks }} {{ translate('Awaiting tasks') }}
                </div>
                <div>
                    <span class="color-round late" />
                    {{ lateTasks }} {{ translate('Late tasks') }}
                </div>
                <div>
                    <span class="color-round reminded" />
                    {{ remindedTasks }} {{ translate('Reminded tasks') }}
                </div>
            </div>
        </template>
    </v-popover>
</template>

<script>
import { VPopover } from 'v-tooltip';

export default {
    name: 'TableStatusInfos',
    components: {
        VPopover,
    },
    props: {
        doneTasks: {
            type: Number,
            default: 0,
        },
        pendingTasks: {
            type: Number,
            default: 0,
        },
        awaitingTasks: {
            type: Number,
            default: 0,
        },
        lateTasks: {
            type: Number,
            default: 0,
        },
        remindedTasks: {
            type: Number,
            default: 0,
        },
        totalTasks: {
            type: Number,
            default: 0,
        },
    },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/var";

.status-infos {
    display: flex;
    align-items: center;

    .statuses-container {
        display: flex;
        align-items: center;
        gap: .5em;
        margin-right: 1em;
        padding: .5em;
        border: solid $grey-light .5px;
        border-radius: 4px;
        min-width: 170px;

        .task-status {
            display: flex;
            align-items: center;
            min-width: 46px;

            &.zero-value {
                opacity: .5;
            }
        }
    }
}
.color-round {
    width: .75em;
    height: .75em;
    border-radius: 50%;
    display: inline-block;
    margin-right: .5em;

    &.done {
        background-color: $green;
    }

    &.awaiting {
        background-color: $grey;
    }

    &.pending {
        background-color: $dashboard-blue;
    }

    &.late {
        background-color: $red;
    }

    &.reminded {
        background-color: $mustard;
    }
}
.tooltip-content div {
    margin-top: .2em;

    &:first-child {
        font-weight: bold;
        margin-bottom: .5em;
    }
}
</style>
