<template>
    <div class="user">
        <div
            v-for="user in users"
            :key="user.id"
            v-user-image="userImage(user)"
            class="image"
        />
    </div>
</template>

<script>
export default {
    props: {
        users: {
            type: Array,
            required: true,
        },
    },
    methods: {
        userImage(user) {
            return {
                id: user.id,
                image: user.image,
                lastname: user.lastname,
                firstname: user.firstname,
                size: 1,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.user {
    display: flex;

    .image {
        &:first-child {
            margin-left: 0;
        }
        margin-left: -6px;
        height: 2.4rem;
        min-height: 2.4rem;
        width: 2.4rem;
        min-width: 2.4rem;
        border-radius: 1000px;
    }
}
</style>
