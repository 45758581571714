import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyEmailCustomTemplateRecipient from './CompanyEmailCustomTemplateRecipient';

export default class CompanyEmailCustomTemplateRecipientCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyEmailCustomTemplateRecipient,
        };
    }
}
