import Vue from 'vue';

export default {
    fetchCurrentTasks(body = {}) {
        return Vue.prototype.$http.get('dashboard/my-tasks/current/sort-by-date', body);
    },
    fetchCurrentUsersAndEmployees(body = {}) {
        return Vue.prototype.$http.get('dashboard/my-tasks/current/sort-by-user', body);
    },
    fetchCurrentUserTasks(id, body = {}) {
        return Vue.prototype.$http.get(`dashboard/my-tasks/current/sort-by-user/${id}`, body);
    },
    fetchHistoryTasks(body = {}) {
        return Vue.prototype.$http.get('dashboard/my-tasks/history/sort-by-date', body);
    },
    fetchHistoryUsersAndEmployees(body = {}) {
        return Vue.prototype.$http.get('dashboard/my-tasks/history/sort-by-user', body);
    },
    fetchHistoryUserTasks(id, body = {}) {
        return Vue.prototype.$http.get(`dashboard/my-tasks/history/sort-by-user/${id}`, body);
    },
    fetchTask(id, body = {}) {
        return Vue.prototype.$http.get(`dashboard/my-tasks/${id}`, body);
    },
    updateNextOccurencesDates(body = {}) {
        return Vue.prototype.$http.put('occurrences/datetime_end', body);
    },
};
