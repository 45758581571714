import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'flatpickr/dist/flatpickr.css';

import Vue from 'vue';
import '@/bootstrap/set-csrf-token';
import '@/bootstrap/init-sentry';
import '@/bootstrap/deactivate-warning-in-console';
import '@/bootstrap/declare-directives';
import '@/bootstrap/declare-global-components';
import '@/bootstrap/declare-global-variables';
import '@/bootstrap/declare-jquery-extend';
import '@/bootstrap/fix-ie-issues';
import '@/bootstrap/setup-api';
import '@/bootstrap/setup-fontawesome';
import '@/bootstrap/update-moment-locales';
import '@/bootstrap/use-mixin';
import '@/bootstrap/use-plugins';
import '@/bootstrap/datadog';

import router from '@/bootstrap/declare-router';
import store from '@/bootstrap/declare-store';
import App from './App.vue';

window.App = new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>',
});
