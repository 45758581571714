<template>
    <div>
        <HtFormInput
            :id="companyUserRequirement.name"
            v-model="companyUserRequirement.value"
            v-validate.disable="getValidationRules"
            :name="companyUserRequirement.name"
            :is-required="companyUserRequirement.is_mandatory"
            :type="getType"
            :cypress="companyUserRequirement.name"
            :disabled="!isParticipant || isDisabled"
            @input="onChanged"
        />
    </div>
</template>
<script>
import UserRequirementEditMixin from './UserRequirementEditMixin';

export default {
    name: 'UserRequirementTextWithControlEdit',

    mixins: [UserRequirementEditMixin],

    inject: ['$validator'],
    props: {
        isParticipant: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            errorMessage: null,
        };
    },

    computed: {
        getType() {
            const configValue = this.companyUserRequirement.config_value;
            if (configValue.control === 'numerical') {
                return 'number';
            }

            return 'text';
        },

        getValidationRules() {
            let rule = '';
            const configValue = this.companyUserRequirement.config_value;

            switch (configValue.control) {
            case 'alphabetical':
                rule = 'alpha_spaces';
                break;
            case 'alphanumerical':
                rule = 'alphanum_spaces';
                break;
            case 'numerical':
            case 'decimal':
                if (configValue.control === 'decimal') {
                    rule = configValue.max_decimal ? `decimal:${configValue.max_decimal}` : 'decimal';
                } else {
                    rule = 'integer';
                }

                if (configValue.min_value) {
                    rule += `|min_value:${configValue.min_value}`;
                }

                if (configValue.max_value) {
                    rule += `|max_value:${configValue.max_value}`;
                }
                break;
            default:
            }

            if (configValue.min_character) {
                rule += `|min:${configValue.min_character}`;
            }

            if (configValue.max_character) {
                rule += `|max:${configValue.max_character}`;
            }

            return rule;
        },
    },

    methods: {
        onChanged() {
            const configValue = this.companyUserRequirement.config_value;
            if (configValue.control === 'decimal') {
                this.companyUserRequirement.value = this.companyUserRequirement.value.replace(',', '.');
            }

            this.$validator.validateAll().then((isValid) => {
                if (isValid === true) {
                    if (this.companyUserRequirement.value !== this.requirement.value) {
                        const checkChanged = this.companyUserRequirement.value !== null && this.companyUserRequirement.value.trim() !== '';
                        this.updateRequirementWithDebounce(checkChanged);
                    }
                }
            });
        },
    },
};
</script>
