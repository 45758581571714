<template>
    <div class="ht-generic-tag" :class="type">
        <slot name="image" />

        <slot>
            <span class="label" v-html="label" />
        </slot>

        <div
            v-if="count"
            class="count-label"
        >
            {{ count }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'HtGenericTag',

    props: {
        label: {
            type: String,
            default: () => null,
        },

        type: {
            type: String,
            default: () => null,
        },

        count: {
            type: Number,
            default: () => null,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
@import '~@/styles/ressources';

.ht-generic-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 4px 8px;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    background-color: #FBFBFB;

    .image {
        height: 16px;
        width: 16px;
        border-radius: 100%;
        overflow: hidden;

        ::v-deep span {
            font-size: 10px;
        }
    }

    .label {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        color: #575757;
    }

    .count-label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 16px;
        border: 1px solid #E1E1E1;
        border-radius: 100%;
        font-size: 10px;
        color: #E1E1E1;
        background-color: #FBFBFB;
    }

    &.company_program_task {
        color: $mustard-base;
        background-color: $mustard-light;
    }

    &.company_document {
        color: $orange-base;
        background-color: $orange-light;
    }

    &.company_nudge {
        color: $pink-base;
        background-color: $pink-light;
    }

    &.company_shared_document {
        color: $purple-base;
        background-color: $purple-light;
    }

    &.company_survey {
        color: $olive-base;
        background-color: $olive-light;
    }

    &.company_quiz {
        color: $teal-base;
        background-color: $teal-light;
    }

    &.company_software {
        color: $sand-base;
        background-color: $sand-light;
    }

    &.company_equipment {
        color: $blue-base;
        background-color: $blue-light;
    }

    &.company_requirement_category {
        color: $red-base;
        background-color: $red-light;
    }

    &.company_planning {
        color: $brown-base;
        background-color: $brown-light;
    }

    &.company_planning_event_template {
        color: $brown-base;
        background-color: $brown-light;
    }

    &.company_email_custom_template {
        color: $mint-base;
        background-color: $mint-light;
    }
}
</style>
