var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.companyChatChannelMessage.isLoaded())?_c('div',{staticClass:"chat-message-item-nudge"},[_c('div',{staticClass:"chat-message-item-nudge-title"},[_c('t',[_vm._v("New Challenge")])],1),_c('div',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.userNudge.message),expression:"userNudge.message"}],staticClass:"chat-message-item-nudge-message"}),_c('div',{staticClass:"chat-message-item-nudge-initiator"},[_c('div',{directives:[{name:"user-image",rawName:"v-user-image",value:(
                _vm.$Utils.getCompanyComponentRoleInitials(
                    'user',
                    1.25,
                    _vm.userNudge.company_user_initiator
                )),expression:"\n                $Utils.getCompanyComponentRoleInitials(\n                    'user',\n                    1.25,\n                    userNudge.company_user_initiator\n                )"}],staticClass:"chat-message-item-nudge-initiator-image",class:'user'}),_c('div',{staticClass:"chat-message-item-nudge-initiator-right"},[_c('div',{staticClass:"chat-message-item-nudge-initiator-right-nudge-name"},[_vm._v(" "+_vm._s(_vm.userNudge.name)+" ")]),_c('div',{staticClass:"chat-message-item-nudge-initiator-right-name"},[_vm._v(" "+_vm._s(_vm.userNudge.company_user_initiator.firstname)+" "+_vm._s(_vm.userNudge.company_user_initiator.lastname)+" ")])])]),_c('div',{staticClass:"chat-message-item-nudge-bottom",class:_vm.state},[(_vm.userNudge._state.isSaving === false)?[(_vm.userNudge.company_nudge.is_deleted
                    || _vm.userNudge.company_nudge.is_enable === false)?[_c('div',{staticClass:"chat-message-item-nudge-bottom-full-width"},[_c('t',[_vm._v("This challenge is no longer available")])],1)]:[(_vm.userNudge.company_user_program_task.status === 'cancelled'
                        || _vm.userNudge.status === 'refuse'
                        || ['accepted', 'success'].includes(_vm.userNudge.status))?_c('div',{staticClass:"chat-message-item-nudge-bottom-full-width"},[(_vm.userNudge.status === 'refuse')?_c('t',[_vm._v(" Challenge refused ")]):(['accepted', 'success'].includes(_vm.userNudge.status))?_c('t',[_vm._v(" Challenge accepted ")]):(_vm.userNudge.company_user_program_task.status === 'cancelled')?_c('t',[_vm._v(" This challenge is no longer available ")]):_vm._e()],1):_vm._e(),(_vm.userNudge.status === 'proposed'
                        && _vm.userNudge.company_user_program_task.status === 'pending')?[_c('div',{staticClass:"chat-message-item-nudge-bottom-left-link",on:{"click":function($event){return _vm.onUpdateStatus('refuse')}}},[_c('t',[_vm._v("Refuse the challenge")])],1),_c('div',{staticClass:"chat-message-item-nudge-bottom-right-link",on:{"click":function($event){return _vm.onUpdateStatus('accepted')}}},[_c('t',[_vm._v("Accept the challenge")])],1)]:_vm._e()]]:_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }