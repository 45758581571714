<template>
    <div class="chrono">
        <span id="chronotime">{{ timer }}</span>
    </div>
</template>
<script>
export default {
    name: 'Chronometer',
    data() {
        return {
            timer: '00:00',
            startTime: 0,
            endTime: 0,
            diff: 0,
            timerID: 0,
        };
    },
    methods: {
        chrono() {
            this.timerID = setInterval(() => {
                this.endTime = new Date();
                this.diff = this.endTime - this.startTime;
                this.diff = new Date(this.diff);
                let sec = this.diff.getSeconds();
                let min = this.diff.getMinutes();

                if (min < 10) {
                    min = `0${min}`;
                }

                if (sec < 10) {
                    sec = `0${sec}`;
                }

                this.timer = `${min}:${sec}`;
            }, 1000);
        },

        start() {
            this.startTime = new Date();
            this.chrono();
        },

        reset() {
            this.timer = '00:00';
            this.startTime = new Date();
        },

        stop() {
            clearTimeout(this.timerID);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/var';

.chrono {
    font-size: 16px;
    color: $color-text;
}
</style>
