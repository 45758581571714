<template>
    <div class="d-flex flex-column px-5 pt-5">
        <div class="mb-5">
            <h1>AutoConnect</h1>
        </div>

        <HtCard>
            <template #title>
                <div class="card-title-bar-header">
                    <div class="card-title-bar-left">
                        <div>{{ getTranslatedTitle }}</div>
                    </div>
                    <div class="card-title-bar-right">
                        <HtSearchField
                            :id="'search_user'"
                            v-model.trim="searchInputUser"
                            class="mr-4"
                            :name="'search-user'"
                            :placeholder="getPlaceholderSearchUser"
                        />
                        <HtSearchField
                            :id="'search_company'"
                            v-model.trim="searchInputCompany"
                            class="mr-4"
                            :name="'search-company'"
                            :placeholder="'Company'"
                        />
                        <Button
                            v-if="!$env.isProd()"
                            class="red"
                            cypress="button-action-add"
                            @click="onAddCompany()"
                        >
                            {{ getTranslatedAddCompany }}
                        </Button>
                    </div>
                </div>
            </template>
            <template #default>
                <div v-show="loading">
                    <FontAwesomeIcon
                        :icon="['fal', 'spinner-third']"
                        size="2x"
                        spin
                    />
                </div>
                <template v-if="!loading && company.length > 0 && !searchByUser">
                    <HtTable
                        :items="company"
                        :columns="columnsCompany"
                        cypress="action-table"
                        :external-search="searchInputCompany"
                        @onCellClicked="onSearchByCompanyId"
                    >
                        <template #cell(name)="scope">
                            <span>[{{ scope.item.id }}] {{ scope.item.name }}</span>
                        </template>
                        <template #cell(is_test)="scope">
                            <span v-if="scope.item.is_test === 0">{{ getTranslatedNo }}</span>
                            <span v-else>{{ getTranslatedYes }}</span>
                        </template>
                        <template #cell(notification_enabled)="scope">
                            <span v-if="scope.item.notification_enabled === 0">{{ getTranslatedNo }}</span>
                            <span v-else>{{ getTranslatedYes }}</span>
                        </template>
                        <template #cell(language)="scope">
                            <span>{{ scope.item.language }}</span>
                        </template>
                        <template #cell(created_at)="scope">
                            <span>{{ scope.item.created_at | formatted }}</span>
                        </template>
                        <template #cell(action)="scope">
                            <div class="d-flex align-items-center">
                                <Button
                                    class="red"
                                    style="font-size: 1rem;padding: 6px 9px;"
                                    @click.stop="autolog(null, scope.item.id)"
                                >
                                    Superadmin
                                </Button>
                                <div
                                    v-if="canDelete"
                                    :class="['button-delete', 'ml-3', {disabled: isDeletingCompany === true}]"
                                    @click.stop="deleteCompany(scope.item.id, scope.item.name)"
                                >
                                    <FontAwesomeIcon
                                        class="button-delete-icon"
                                        :icon="['far', 'trash-alt']"
                                    />
                                </div>
                            </div>
                        </template>
                    </HtTable>
                </template>

                <template v-else-if="!loading && company.length && searchByUser == true">
                    <div
                        v-for="(current, index) in company"
                        :key="index"
                    >
                        <div :class="['company-head', 'd-flex', 'align-items-center', {'mt-3': index > 0}]">
                            <div
                                v-show="!loading && company.length === 1"
                                class="company-head-back clickable"
                                @click="firstLoad()"
                            >
                                <FontAwesomeIcon :icon="['fal', 'long-arrow-left']" />
                            </div>
                            <div class="ml-3">
                                <div class="mb-1 company-head-title d-flex align-items-center">
                                    <div class="mr-1">
                                        [{{ current.id }}]
                                    </div>
                                    <div>
                                        {{ current.name }}
                                    </div>
                                </div>
                                <div
                                    v-if="current.company_user"
                                    class="company-head-subtitle"
                                >
                                    {{ current.company_user.length }} utilisateur(s)
                                </div>
                            </div>
                        </div>
                        <HtTable
                            :items="current.company_user"
                            :columns="getColumnsUser"
                            cypress="action-table"
                            @onCellClicked="autolog(...arguments, current.id)"
                        >
                            <template #cell(name)="scope">
                                <div class="d-flex align-items-center">
                                    <div
                                        v-user-image="{
                                            image: scope.item.image,
                                            firstname: scope.item.firstname,
                                            lastname: scope.item.lastname
                                        }"
                                        class="user-image"
                                    />
                                    <span
                                        :style="[scope.item.role=='superadmin' ? {'color': 'red'} : {}]"
                                        class="user-name ml-3"
                                    >
                                        [{{ scope.item.id }}] {{ scope.item.firstname }} {{ scope.item.lastname }}
                                    </span>
                                </div>
                            </template>
                            <template #cell(state)="scope">
                                <div :style="[scope.item.role=='superadmin' ? {'color': 'red'} : {}]">
                                    <template v-if="scope.item.state">
                                        {{ scope.item.state }}
                                    </template>
                                    <template v-else>
                                        {{ getTranslatedNone }}
                                    </template>
                                </div>
                            </template>
                            <template #cell(email)="scope">
                                <div :style="[scope.item.role=='superadmin' ? {'color': 'red'} : {}]">
                                    {{ scope.item.email }}
                                </div>
                            </template>
                            <template #cell(role)="scope">
                                <div :style="[scope.item.role=='superadmin' ? {'color': 'red'} : {}]">
                                    {{ scope.item.role }}
                                </div>
                            </template>
                            <template #cell(status)="scope">
                                <div :style="[scope.item.role=='superadmin' ? {'color': 'red'} : {}]">
                                    {{ scope.item.status }}
                                </div>
                            </template>
                            <template #cell(is_onboardee)="scope">
                                <div :style="[scope.item.role=='superadmin' ? {'color': 'red'} : {}]">
                                    {{ scope.item.is_onboardee }}
                                </div>
                            </template>
                            <template #cell(is_virtual)="scope">
                                <div :style="[scope.item.role=='superadmin' ? {'color': 'red'} : {}]">
                                    {{ scope.item.is_virtual }}
                                </div>
                            </template>
                            <template #cell(arrival_date)="scope">
                                <div :style="[scope.item.role=='superadmin' ? {'color': 'red'} : {}]">
                                    <template v-if="scope.item.arrival_date">
                                        {{ scope.item.arrival_date | formatted }}
                                    </template>
                                    <template v-else>
                                        {{ getTranslatedNone }}
                                    </template>
                                </div>
                            </template>
                            <template #cell(action)="scope">
                                <HtCheckbox
                                    v-if="scope.item.is_onboardee === true && scope.item.status === 'invited'"
                                    v-model="skipChatbot[scope.item.id]"
                                />
                            </template>
                        </HtTable>
                    </div>
                </template>
                <div v-else-if="!loading && company.length === 0">
                    <p>{{ getTranslatedNone }}</p>
                </div>
            </template>
        </HtCard>
    </div>
</template>

<script>
import moment from 'moment';
import CompanyUser from '@/models/CompanyUser';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import modalMixin from '@/components/globals/modals/modalMixin';
import HtCheckbox from '@/components/globals/HtCheckbox.vue';
import HtTable from '../../components/globals/HtTable.vue';

export default {
    name: 'Autoconnect',

    components: {
        HtTable,
        HtSearchField,
        HtCheckbox,
    },

    filters: {
        moment(date) {
            return moment(date).format('MMMM Do YYYY');
        },
        formatted(date) {
            return moment(date).format('D MMMM YYYY');
        },
    },
    mixins: [
        modalMixin,
    ],

    data() {
        return {
            columnsCompany: [
                {
                    label: navigator.language.indexOf('fr') > -1 ? 'Nom' : 'Name',
                    key: 'name',
                    class: 'action-title',
                    search: true,
                },
                {
                    label: navigator.language.indexOf('fr') > -1 ? 'Domaine' : 'Domain',
                    key: 'domain',
                    class: 'd-none d-md-table-cell',
                },
                {
                    label: 'Test',
                    key: 'is_test',
                    class: 'd-none d-md-table-cell',
                    sortable: false,
                },
                {
                    label: 'Notification',
                    key: 'notification_enabled',
                    class: 'd-none d-xl-table-cell',
                    sortable: false,
                },
                {
                    label: navigator.language.indexOf('fr') > -1 ? 'Langue' : 'Language',
                    key: 'language',
                    class: 'd-none d-md-table-cell',
                    sortable: false,
                },
                {
                    label: navigator.language.indexOf('fr') > -1 ? 'Date de création' : 'Creation date',
                    key: 'created_at',
                    sortable: false,
                    class: 'd-none d-md-table-cell',
                },
                {
                    label: '',
                    key: 'action',
                    sortable: false,
                    class: 'd-none d-md-table-cell',
                },
            ],
            columnsUser: [
                {
                    label: navigator.language.indexOf('fr') > -1 ? 'Nom' : 'Name',
                    key: 'name',
                    class: 'action-title',
                    sortKey: 'firstname',
                },
                {
                    label: 'Email',
                    key: 'email',
                    class: 'd-none d-lg-table-cell',
                },
                {
                    label: 'Role',
                    key: 'role',
                    sortable: false,
                    class: 'd-none d-lg-table-cell',
                },
                {
                    label: 'Status',
                    key: 'status',
                    sortable: false,
                    class: 'd-none d-md-table-cell',
                },
                {
                    label: navigator.language.indexOf('fr') > -1 ? 'État' : 'State',
                    key: 'state',
                    sortable: false,
                    class: 'd-none d-lg-table-cell',
                },
                {
                    label: 'Onboardee',
                    key: 'is_onboardee',
                    sortable: false,
                    class: 'd-none d-md-table-cell',
                },
                {
                    label: navigator.language.indexOf('fr') > -1 ? 'Virtuel' : 'Virtual',
                    key: 'is_virtual',
                    sortable: false,
                    class: 'd-none d-xl-table-cell',
                },
                {
                    label: navigator.language.indexOf('fr') > -1 ? 'Date d\'arrivé' : 'Arrival date',
                    key: 'arrival_date',
                    sortable: false,
                    class: 'd-none d-xl-table-cell',
                },
                {
                    label: navigator.language.indexOf('fr') > -1 ? 'Passer le chatbot' : 'Skip chatbot',
                    key: 'action',
                    sortable: false,
                    class: 'd-none d-md-table-cell',
                },
            ],
            company: [],
            /**
             * input de recherche de l'entreprise
             */
            searchInputCompany: null,

            /**
             * input de recherche de l'utilisateur
             */
            searchInputUser: null,
            min_time: 500,
            loading: false,
            interval_user_id: null,
            min_char_user: 3,
            min_time_user: 500,
            searchByUser: false,
            searchInputUserError: false,
            currentDomain: null,
            isDeletingCompany: false,
            skipChatbot: {},
        };
    },

    computed: {
        getTranslatedNo() {
            return navigator.language.indexOf('fr') > -1 ? 'Non' : 'No';
        },

        getTranslatedYes() {
            return navigator.language.indexOf('fr') > -1 ? 'Oui' : 'Yes';
        },

        getTranslatedTitle() {
            if (this.searchInputUser && this.searchInputUser !== '') {
                return navigator.language.indexOf('fr') > -1 ? 'Résultat de votre recherche' : 'Result of your research';
            }
            return navigator.language.indexOf('fr') > -1 ? 'Liste des entreprises' : 'Company list';
        },

        getTranslatedNone() {
            return navigator.language.indexOf('fr') > -1 ? 'Aucun' : 'None';
        },

        getTranslatedAddCompany() {
            return navigator.language.indexOf('fr') > -1 ? 'Ajouter une entreprise' : 'Add a company';
        },

        getPlaceholderSearchUser() {
            return navigator.language.indexOf('fr') > -1 ? 'Utilisateur' : 'User';
        },

        canDelete() {
            return this.$env.get('APP_ENV') !== 'production';
        },

        getColumnsUser() {
            return this.columnsUser.filter((column) => {
                if (this.$env.get('APP_ENV') === 'production') {
                    return Boolean(column.key !== 'action');
                }

                return true;
            });
        },
    },

    watch: {
        searchInputUser(input) {
            clearInterval(this.interval_user_id);

            if (input.length == 0) return this.firstLoad();
            if (input.length < this.min_char_user) return this.searchInputUserError = 1;

            this.searchInputUserError = 0;

            this.interval_user_id = setInterval(() => {
                clearInterval(this.interval_user_id);
                // eslint-disable-next-line no-unused-expressions
                input.length >= this.min_char_user ? this.onSearchUser(input.trim()) : this.firstLoad();
            }, this.min_time_user);
        },
    },

    created() {
        this.$Auth.logOut();
        this.currentDomain = window.location.host.split('.')[0];

        if (this.$route.params.id) {
            const url = this.$route.query ? this.$route.query.url : '/Dashboard';
            const isAutoconnectSuperAdmin = this.$route.query?.['is-autoconnect-superadmin'] || false;

            if (isAutoconnectSuperAdmin) {
                this.$Auth.setAutoConnectAdmin();
            }

            this.autolog({ item: { id: this.$route.params.id } }, null, url);
        } else {
            this.firstLoad();
        }
    },

    methods: {
        onAddCompany() {
            this.$router.push('/sign');
        },

        onAutologInvited(userId, companyId, uuid) {
            this.getAutolog(userId, companyId).then(({ domain }) => {
                if (userId) {
                    if (this.$env.isDev() || domain === this.currentDomain) {
                        this.$router.push(`/invite/${uuid}`);
                    } else {
                        window.location.href = `${this.$env.getFromEnv('COMPANY_WEB_URL', { company_name: domain })}/invite/${uuid}`;
                    }
                } else {
                    this.$Notifier('App').showError('This user does not exist.');
                }
            });
        },

        autolog(row = null, companyId = null, url = '/Dashboard') {
            const skipChatbot = row ? this.skipChatbot[row.item.id] : false;

            if (skipChatbot === false && row && row.item.status === 'invited') {
                this.onAutologInvited(row.item.id, row.item.company_id, row.item.uuid);
                return;
            }

            const isAutoconnectSuperAdmin = row === null || row.item.role === CompanyUser.ROLE_SUPERADMIN;
            const companyUserId = row ? row.item.id : null;

            if (isAutoconnectSuperAdmin) {
                this.$Auth.setAutoConnectAdmin();
            }

            this.getAutolog(companyUserId, companyId).then(async ({ domain }) => {
                const userId = companyUserId || this.company.find((company) => company.id === companyId).company_superadmin.id;

                if (userId) {
                    if (this.$env.isDev() || `${userId}_${domain}` === this.currentDomain) {
                        this.$router.push(url);
                    } else {
                        window.location.href = `${this.$env.getFromEnv('COMPANY_WEB_URL', { company_name: `${userId}_${domain}` })}/autoconnect/${userId}?url=${url}&is-autoconnect-superadmin=${isAutoconnectSuperAdmin}`;
                    }
                } else {
                    this.$Notifier('App').showError('This company is broken.');
                }
            });
        },

        getAutolog(userId, companyId) {
            return new Promise((resolve) => {
                if (userId) {
                    const skipChatbot = this.skipChatbot[userId];

                    this.API.get(`admin/autoconnect/user/${userId}/${skipChatbot}`, {
                        200: (data) => {
                            resolve(data);
                        },
                    });
                } else {
                    this.API.get(`admin/autoconnect/company/${companyId}`, {
                        200: (data) => {
                            resolve(data);
                        },
                    });
                }
            });
        },

        firstLoad() {
            this.loading = true;
            this.searchByUser = false;

            this.API.post('admin/company/search', {
                mode: 'get',
                field: ['name', 'domain', 'is_test', 'notification_enabled', 'language', 'created_at', 'id'],
                with: {
                    companySuperadmin: {
                        field: ['id'],
                    },
                },
            }, {
                200: ({ company }) => {
                    this.company = company;
                    this.sortbyName();
                    this.loading = false;
                },
                403: () => {
                    this.$router.push('LoginMaster');
                },
            });
        },

        onSearchByCompanyId(row) {
            this.loading = true;
            this.API.post('admin/company/search', {
                mode: 'get',
                field: ['name', 'domain', 'is_test', 'notification_enabled', 'language', 'created_at', 'id'],
                where: [['id', '=', row.item.id]],
                with: {
                    companyUser: {
                        orderby: [['created_at', 'DESC']],
                        field: ['id', 'firstname', 'lastname', 'image', 'email', 'role', 'arrival_date', 'status', 'uuid', 'is_onboardee', 'created_at'],
                    },
                },

            }, {
                200: ({ company }) => {
                    this.company = company;
                    this.searchByUser = true;
                    this.sortbyName();
                    this.setSkipChatbot(company[0].company_user);
                    this.loading = false;
                },
            });
        },

        onSearchUser(v) {
            this.loading = true;

            this.API.post('admin/company/search', {
                mode: 'get',
                field: [
                    'name', 'domain', 'is_test', 'notification_enabled', 'language',
                    'created_at', 'id',
                ],
                with: {
                    companyUser: {
                        field: [
                            'id', 'company_id', 'firstname', 'lastname', 'image', 'is_virtual',
                            'email', 'role', 'arrival_date', 'status', 'uuid', 'is_onboardee', 'state',
                        ],
                        where: [
                            ['fullname', 'like', `%${v}%`],
                            ['email', 'like', `%${v}%`, 'or'],
                        ],
                    },
                },
                whereHas: {
                    companyUser: {
                        where: [
                            ['fullname', 'like', `%${v}%`],
                            ['email', 'like', `%${v}%`, 'or'],
                        ],
                    },
                },
            }, {
                200: ({ company }) => {
                    this.company = company.filter((company) => company.company_user.length > 0);
                    this.searchByUser = true;
                    this.sortbyName();
                    this.company.forEach((company) => {
                        this.setSkipChatbot(company.company_user);
                    });
                    this.loading = false;
                },
            });
        },

        setSkipChatbot(users) {
            this.skipChatbot = {};

            users.map((user) => {
                this.$set(this.skipChatbot, user.id, false);
            });
        },

        toggleSkipChatbot(userId) {
            if (userId in this.skipChatbot) {
                this.skipChatbot[userId] = !this.skipChatbot[userId];
            }
        },

        sortbyName() {
            this.company.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
        },

        deleteCompany(companyId, companyName) {
            if (!this.isDeletingCompany) {
                this.genericConfirm(
                    'Confirmation requise',
                    `Vous êtes en train de supprimer la compagnie ${companyName}, êtes-vous sûr ?`,
                ).then(() => {
                    this.isDeletingCompany = true;
                    this.API.delete(`admin/company/${companyId}`, {
                        200: () => {
                            this.firstLoad();
                            this.isDeletingCompany = false;
                            this.$Notifier('App').showInfo(`${companyName} est en cours de suppression. Cela peut prendre quelques minutes.`);
                        },
                        onError: () => {
                            this.isDeletingCompany = false;
                            this.$Notifier('App').showError(`Impossible de supprimer ${companyName}`);
                        },
                    });
                }).catch(() => {});
            }
        },
    },

};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

.card-title-bar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.card-title-bar-right {
    display: flex;
    align-items: center;

    &-close {
        border-radius: 50%;
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color-lightblue-grey;
        cursor: pointer;
        transition: all 0.3s;

        .icon {
            font-size: 2rem;
        }

        &:hover {
            transform: scale(1.1);
        }
    }
}

.ht-form {
    margin-bottom: 0.8rem;
}

h1 {
    margin: 0;
}

.company-head {
    &-back {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $grey-dark;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-title {
        div {
            &:nth-child(1) {
                font-size: 1em;
            }

            &:nth-child(2) {
                font-size: 1.4em;
                font-weight: bold;
            }
        }
    }

    &-subtitle {
        font-weight: normal;
        font-size: 1.2rem;
        color: $grey-3;
    }
}

.button-delete {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        .button-delete-icon {
            color: $black;
        }
    }

    &.disabled {
        .button-delete-icon {
            color: $grey-dark;
        }

        &:hover {
            cursor: not-allowed;

            .button-delete-icon {
                color: $grey-dark;
            }
        }
    }

    &-icon {
        color: $text;
        transition: color .5s;
    }
}

@media (max-width: $phone) {
    .card-title-bar-header {
        margin: 1.6rem 0;

        flex-direction: column;
        align-items: flex-start;
        gap: 1.6rem;
    }

    .card-title-bar-search-field {
        display: none;
    }

    .button-label {
        font-size: 1.4rem;
    }

    .hide-mobile {
        display: none;
    }
}
</style>
