<template>
    <div>
        <phone-number-input
            v-model="companyUserRequirement.value"
            :is-required="companyUserRequirement.is_mandatory"
            @input="onChanged"
        />
    </div>
</template>
<script>
import PhoneNumberInput from '@/components/globals/inputs/PhoneNumberInput.vue';
import UserRequirementEditMixin from './UserRequirementEditMixin';

export default {
    name: 'UserRequirementPhoneEdit',

    components: {
        PhoneNumberInput,
    },

    mixins: [UserRequirementEditMixin],

    inject: ['$validator'],

    props: {
        isParticipant: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            initPhone: {
                phone_country_code: '+33',
                phone_number: null,
            },
        };
    },

    created() {
        if (!this.companyUserRequirement.value) {
            this.companyUserRequirement.value = { ...this.initPhone };
        } else {
            this.companyUserRequirement.value = JSON.parse(this.companyUserRequirement.value);
            this.initPhone = { ...this.companyUserRequirement.value };
        }
    },

    methods: {
        onChanged(value) {
            const hasDialChanged = value.phone_country_code !== this.initPhone.phone_country_code;
            const hasNumberChanged = value.phone_number !== this.initPhone.phone_number;

            if (hasDialChanged || hasNumberChanged) {
                this.updateRequirementWithDebounce(true);
            }
        },
    },
};
</script>
