<template>
    <div>
        <div>{{ hash }}</div>
        <div>{{ token }}</div>
        <div v-for="(item, key) in parsed_hash">
            <label>{{ key }}</label> <div>
                <input
                    type="text"
                    :value="item"
                >
            </div>
        </div>
    </div>
</template>

<script>
import fromPairs from 'lodash.frompairs';

export default {
    name: 'Oauth2',

    data() {
        return {
            hash: '',
            token: '',
            parsed_hash: [],
        };
    },

    mounted() {
        // alert(window.parent.name)
        if (this.$route.hash) {
            this.hash = this.$route.hash.substr(1);

            try {
                // this.parsed_hash = this.hash.split('&').map((value, key) => {let a = value.split('='); let b ={}; b[a[0]] = a[1]; return b; });
                this.parsed_hash = this.hash.split('&').map((value) => value.split('='));
                this.parsed_hash = fromPairs(this.parsed_hash);

                // To support oAuth1 with state passed within the redirect
                this.parsed_hash.state = decodeURIComponent(this.parsed_hash.state) !== 'undefined' ? decodeURIComponent(this.parsed_hash.state) : `${this.$route.query.state}&uid=${this.$route.query.uid}`;
                opener.postMessage(this.parsed_hash, '*');
            } catch (e) {

            }
        } else {
            opener.postMessage(this.$route.query, '*');
        }
        window.close();
    },
};
</script>
