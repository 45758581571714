<template>
    <div>
        <div class="question-wrapper">
            <HtChoiceNextButton
                v-for="(type, index) in getQuestions"
                :key="`question-type-${index}`"
                :data-cy="'survey-question-add-'+type.name"
                :icon="type.icon"
                :label="type.label"
                @click="chooseContent(companySurveyContent.RESOURCE_QUESTION, type.name)"
            />

            <div class="question-wrapper-title">
                <t>Add an additional information</t>
            </div>

            <HtChoiceNextButton
                v-for="(type, index) in extraType"
                :key="`question-extra-${index}`"
                :data-cy="'survey-question-add-'+type.name"
                :icon="type.icon"
                :label="type.label"
                @click="chooseContent(companySurveyContent.RESOURCE_EXTRA, type.name)"
            />
        </div>

        <Modalable
            ref="satisfactionModal"
            class="satisfaction-modal modalable-1 small"
            :mode="2"
        >
            <QuestionAddSatisfaction @onValidate="addContent" />
        </Modalable>
    </div>
</template>

<script>
import CompanySurveyContent from '@/models/CompanySurveyContent';
import { ListQuestionType, SurveyQuestionType } from '@/models/common/SurveyQuestionType';
import HtChoiceNextButton from '@/components/globals/HtChoiceNextButton.vue';
import CompanySurveyQuestion from '@/models/CompanySurveyQuestion';
import Modalable from '@/components/globals/Modalable.vue';
import QuestionAddSatisfaction from './question/add/QuestionAddSatisfaction.vue';

export default {
    name: 'SurveyFormQuestionsAdd',
    components: {
        Modalable,
        QuestionAddSatisfaction,
        HtChoiceNextButton,
    },
    inject: ['modal'],

    props: {
        isConversational: {
            type: Boolean,
            required: true,
        },

        isEnrollProgram: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            buttonState: 'idle',
            companySurveyContent: CompanySurveyContent,
            questionType: Object.values(SurveyQuestionType),
            extraType: [
                {
                    name: 'company_survey_extra',
                    label: 'Text',
                    icon: '/static/icons/survey/type/AdditionalInfo.svg',
                },
            ],
            listQuestionType: ListQuestionType,
        };
    },

    computed: {
        getQuestions() {
            return this.questionType.filter((type) => {
                if (!CompanySurveyQuestion.NON_CONVERSATIONAL_TYPES.includes(type.name)) {
                    return this.isConversational;
                }

                if (type.name === CompanySurveyQuestion.TYPE_REQUIREMENT) {
                    return this.isConversational && this.isEnrollProgram;
                }

                return true;
            });
        },
    },

    created() {
        this.modal.setTitle(this.translate('Add a question'));
    },

    methods: {
        openSatisfaction() {
            this.$refs.satisfactionModal.open();
        },

        chooseContent(content, type) {
            if (type === this.listQuestionType.QUESTION_TYPE_SATISFACTION_SCALE) {
                this.openSatisfaction();
            } else {
                this.addContent(content, type);
            }
        },

        addContent(content, type, subtype = null, params = []) {
            this.$emit('onAddContent', content, type, subtype, params);
            this.modal.close();
        },
    },
};
</script>
<style lang="scss" scoped src="./SurveyFormQuestionsAdd.scss"></style>
