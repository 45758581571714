import RtwSharedSpace from '@tony.caron/rtw-shared-space';
import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';

import CompanySurveyQuestionLocale from './CompanySurveyQuestionLocale';

export default class CompanySurveyQuestionLocaleCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanySurveyQuestionLocale,
        };
    }

    modelCustomAttributes() {
        return {
            defaultLanguage: null,
            currentLanguage: null,
            text: null,
        };
    }

    modelAccessors() {
        return {
            currentLanguage: () => this.models.find((model) => model.language_key === RtwSharedSpace.store.session.companyUser.company_language.key),
            defaultLanguage: () => this.models.find((model) => model.language_key === RtwSharedSpace.store.session.company.company_language.key),
            text: () => {
                if (this.currentLanguage) {
                    return this.currentLanguage.name;
                }

                if (this.defaultLanguage) {
                    return this.defaultLanguage.name;
                }

                return null;
            },
        };
    }
}
