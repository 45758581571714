import { render, staticRenderFns } from "./QuestionSimpleChoice.vue?vue&type=template&id=6c63e9f9&scoped=true&"
import script from "./QuestionSimpleChoice.vue?vue&type=script&lang=js&"
export * from "./QuestionSimpleChoice.vue?vue&type=script&lang=js&"
import style0 from "./QuestionSimpleChoice.scss?vue&type=style&index=0&id=6c63e9f9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c63e9f9",
  null
  
)

export default component.exports