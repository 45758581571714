<template>
    <HtCard
        v-if="permissionsLoaded"
        class="mb-5"
    >
        <template #title>
            <HtCardTitleBar
                :title="title"
                @search="setSearchValue"
            />
        </template>
        <template #default>
            <IconLoading v-if="isLoading" />

            <div
                v-else
                class="ht-table-extended"
            >
                <HtTableExtended
                    :items="items"
                    :columns="columns"
                    empty-message="There are no results to display yet..."
                    :external-search="search"
                    :has-quick-actions="hasQuickActions"
                    front-filter
                    front-sort
                    @on-row-clicked="onRowClicked"
                >
                    <template #cell(name)="scope">
                        <TableCellTextEllipsis
                            :text="localize(scope.item.names)"
                        />
                    </template>

                    <template #cell(nb_of_responses)="scope">
                        {{ getNumberOfResponsesLabel(scope.item.nb_of_responses, scope.item.nb_of_target_users) }}
                    </template>

                    <template #cell(status)="scope">
                        <HtTag :color="getStatusColor(scope.item.status)">
                            {{ getStatusLabel(scope.item.status) }}
                        </HtTag>
                    </template>

                    <template #cell(recurrence)="scope">
                        <HtTag :color="getRecurrenceColor(scope.item.recurrence)">
                            {{ getRecurrenceLabel(scope.item.recurrence) }}
                        </HtTag>
                    </template>

                    <template #cell(is_locked)="scope">
                        <FontAwesomeIcon
                            v-if="scope.item.is_locked"
                            icon="lock"
                        />
                        <span v-else />
                    </template>

                    <template #cell(program_names)="scope">
                        {{ localize(scope.item.program_names) }}
                    </template>
                </HtTableExtended>
            </div>
        </template>
    </HtCard>
</template>

<script>
import api from '@/store/api';
import HtActionMenu from '@/components/globals/HtActionMenu.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import HtTableExtended from '@/components/globals/HtTable/HtTableExtended.vue';
import HtCardTitleBar from '@/components/globals/HtCardTitleBar.vue';
import IconLoading from '@/components/icons/IconLoading.vue';
import TableCellTextEllipsis from '@/components/globals/table/TableCellTextEllipsis.vue';
import I18n from '@/modules/i18n/I18n';

export default {
    name: 'SurveyResults',
    permissions: ['PageSurveyResults'],

    components: {
        HtCardTitleBar,
        HtActionMenu,
        HtSearchField,
        HtTableExtended,
        IconLoading,
        TableCellTextEllipsis,
    },

    props: {
        hasQuickActions: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            search: null,
            show_icon: false,
            isLoading: false,
            items: [],
            columns: [
                {
                    label: this.translate('Name'),
                    key: 'name',
                    class: 'font-weight-bold',
                    search: true,
                    style: {
                        'max-width': '256px',
                    },
                },
                {
                    label: this.translate('Status'),
                    key: 'status',
                    sortable: false,
                    search: false,
                },
                {
                    label: this.translate('Number of responses'),
                    key: 'nb_of_responses',
                    sortable: false,
                    search: false,
                },
                {
                    label: this.translate('Recurrence'),
                    key: 'recurrence',
                    sortable: false,
                    search: false,
                },
                {
                    label: this.translate('Program'),
                    key: 'program_names',
                    sortable: false,
                    search: false,
                },
            ],
        };
    },
    computed: {
        title() {
            if (this.items.length === 0) {
                return this.translate('No survey');
            }

            return this.translate('{count} surveys result | {count} surveys results', {
                count: this.items.length,
            });
        },
    },

    created() {
        this.fetchData();
        this.setPage({
            title: this.translate('Surveys Results'),
        });
    },

    methods: {
        async canReadPageSurveyResultDetail() {
            await this.$store.dispatch('permissions/load', { names: ['PageSurveyResultDetail'] });
            return this.$canRead('PageSurveyResultDetail');
        },
        fetchData() {
            this.isLoading = false;
            api.configuration
                .surveyResults
                .getAll()
                .then((res) => {
                    this.items = res.data.data;

                    this.isLoading = false;
                });
        },
        setSearchValue(val) {
            this.search = val;
        },

        async onRowClicked(row) {
            if (!(await this.canReadPageSurveyResultDetail)) {
                return;
            }
            this.$router.push({ name: 'SurveyResultDetail', params: { surveyId: row.item.id } });
        },

        getStatusColor(status) {
            const statusMap = {
                in_progress: 'green',
                completed: 'grey',
                scheduled: 'yellow',
            };
            return statusMap[status] || 'grey-dark';
        },
        getStatusLabel(status) {
            const statusMap = {
                draft: I18n.translate('Draft'),
                scheduled: I18n.translate('Scheduled'),
                in_progress: I18n.translate('In progress'),
                completed: I18n.translate('Closed'),
            };
            return statusMap[status] || 'Undefined';
        },
        getRecurrenceColor(status) {
            const statusMap = {
                false: 'grey',
                true: 'green',
            };
            return statusMap[status] || 'grey-dark';
        },
        getRecurrenceLabel(status) {
            const statusMap = {
                false: I18n.translate('Inactive'),
                true: I18n.translate('Active'),
            };
            return statusMap[status] || 'Undefined';
        },
        getNumberOfResponsesLabel(nbOfResponses, nbOfTargetUsers) {
            return this.translate('{nb_of_responses}/{nb_of_target_users} response | {nb_of_responses}/{nb_of_target_users} responses', {
                nb_of_responses: nbOfResponses,
                nb_of_target_users: nbOfTargetUsers,
                count: nbOfTargetUsers,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/design-system-colors.scss';
.ht-table-extended {
    font-size: 14px;
    color: $neutral-800;
    border: 1px solid $neutral-300;
    border-radius: 8px;
}
</style>
