import Model from '@tony.caron/node-model-proxy/Model';
import CompanyUserProgramTaskParticipantCollection from './CompanyUserProgramTaskParticipantCollection';
import CompanyUser from './CompanyUser';
import CompanyRole from './CompanyRole';

export default class CompanyUserProgramTaskParticipant extends Model {
    modelConfig() {
        return {
            collection: CompanyUserProgramTaskParticipantCollection,
        };
    }

    modelRelations() {
        return {
            companyRole: () => this.belongsTo(CompanyRole, 'company_role_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
        };
    }
}
