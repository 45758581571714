<template>
    <TableTemplate
        :items="items"
        :title="title"
        :placeholder-image="placeholderImage"
        :placeholder-text="placeholderText"
        :placeholder-title="placeholderTitle"
        :loading="loading"
        :view-all-link="true"
        @on-link-click="onClick"
    />
</template>

<script>
import TableTemplate from '@/components/pages/dashboard/widgets/templates/TableTemplate.vue';

export default {
    name: 'NextKeyDatesWidget',
    components: {
        TableTemplate,
    },
    computed: {
        items() {
            const keyDates = this.$store.getters['config/getKeyDatesBySlug'];

            return this.$store.state.dashboard.statistics.nextKeyDates.map((line) => ({
                id: line.id,
                image: line.image,
                name: `${line.firstname} ${line.lastname.toUpperCase()}`,
                detail: `${this.translate(keyDates[line.ht_program_key_date_slug].name)} : ${this.$Utils.moment(line.starts_at).format('L')}`,
            }));
        },
        title() {
            return this.translate('Next key dates');
        },
        placeholderTitle() {
            return this.translate('Everyone is here !');
        },
        placeholderText() {
            return this.translate('No future key date planned yet.');
        },
        placeholderImage() {
            return '/static/images/dashboard/widgets/nextKeyDates.svg';
        },
        loading() {
            return !this.$store.state.dashboard.statistics.nextKeyDatesLoaded;
        },
    },
    watch: {
        loading: {
            handler(val) {
                if (val) {
                    this.$store.dispatch('dashboard/statistics/fetchNextKeyDates');
                }
            },
            immediate: true,
        },
    },
    methods: {
        onClick() {
            this.$store.dispatch('dashboard/programs/clearAllFilters');
            this.$store.dispatch('dashboard/transferFiltersToPrograms');
            this.$store.dispatch('dashboard/programs/setNextKeyDatesFilter', true);
            this.$router.push({ name: 'DashboardPrograms' });
        },
    },
};
</script>
