import ModelCollection from '@tony.caron/node-model-proxy/ModelCollection';
import CompanyRoleUserEmployee from './CompanyRoleUserEmployee';

export default class CompanyRoleUserEmployeeCollection extends ModelCollection {
    modelConfig() {
        return {
            model: CompanyRoleUserEmployee,
        };
    }
}
