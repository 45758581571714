import Vue from 'vue';

/**
 * @typedef {id: int, firstname: string, lastname: string, fullname: string} Employee
 * @typedef { company_delegation_id: int, employee_id: int, employee: Employee } DelegationEmployee
 *
 * @typedef {Object} Delegation
 * @property {Number} id
 * @property {Number} company_id
 * @property {Number} company_role_id
 * @property {Array<DelegationEmployee>} employees
 * @property {Number} from_user_id
 * @property {CompanyUser} from_user
 * @property {Number} to_user_id
 * @property {CompanyUser} to_user
 * @property {{id: Number, alias: string, is_heyteam: boolean}} role
 * @property {string} start_date
 * @property {string} end_date
 *
 */

export default {

    /**
     * @param {Object} data
     * @param {Number} data.user_id
     * @param {Number} data.perPage
     * @param {Number} data.page
     * @param {Object} data.filters
     * @param {string} data.filters.delegationType
     * @returns {Promise<{data: {data: Array<Delegation>, last_page: number}}>}
     */
    get(data) {
        return Vue.prototype.$http.get(`users/${data.user_id}/delegations`, {
            params: data,
        });
    },

    /**
     *
     * @param {Object} data
     * @param {Number} data.user_id
     * @param {Number} data.role_id
     * @returns {Promise<{data: {data: Array<{id: int, fullname: string, company_id: int}>}}}
     */
    getSupervised(data) {
        return Vue.prototype.$http.get(`users/${data.user_id}/delegations/supervised`, {
            params: data,
        });
    },

    /**
     * @param {Object} data
     * @param {Number} data.from_user_id
     * @param {Number} data.to_user_id
     * @param {Number} data.role_id
     * @param {array} data.employee_ids
     * @param {date} data.start_date
     * @param {date} data.end_date
     */
    create(data) {
        return Vue.prototype.$http.post(`users/${data.from_user_id}/delegations`, data);
    },

    /**
     *
     * @param {Object} data
     * @param {Number} data.id
     * @param {Number} data.from_user_id
     * @param {Number} data.to_user_id
     * @param {Number} data.role_id
     * @param {array} data.employee_ids
     * @param {date} data.start_date
     * @param {date} data.end_date
     */
    update(data) {
        return Vue.prototype.$http.put(`users/${data.from_user_id}/delegations`, data);
    },

    /**
     * @param {Object} data
     * @param {Number} data.delegation_id
     * @param {Number} data.from_user_id
     */
    delete(data) {
        return Vue.prototype.$http.delete(`users/${data.from_user_id}/delegations/${data.delegation_id}`);
    },
};
