import Model from '@tony.caron/node-model-proxy/Model';

import Company from './Company';
import CompanyUser from './CompanyUser';
import CompanyFile from './CompanyFile';
import CompanyUserNudge from './CompanyUserNudge';
import CompanyUserSurvey from './CompanyUserSurvey';
import CompanyChatChannel from './CompanyChatChannel';
import CompanyUserSurveyExtra from './CompanyUserSurveyExtra';
import CompanyUserSurveyQuestion from './CompanyUserSurveyQuestion';
import CompanyUserOpentokArchive from './CompanyUserOpentokArchive';
import CompanyUserSurveyQuestionAnswer from './CompanyUserSurveyQuestionAnswer';
import CompanyChatChannelMessageCollection from './CompanyChatChannelMessageCollection';

export default class CompanyChatChannelMessage extends Model {
    modelConfig() {
        return {
            collection: CompanyChatChannelMessageCollection,
        };
    }

    modelCustomAttributes() {
        return {
            sendByMail: false,
            enabledHeyChat: false,
            enabledCapiChat: false,
        };
    }

    modelRelations() {
        return {
            company: () => this.belongsTo(Company, 'company_id', 'id'),
            companyUser: () => this.belongsTo(CompanyUser, 'company_user_id', 'id'),
            files: () => this.hasMany(CompanyFile),
            companyUserNudge: () => this.belongsTo(CompanyUserNudge, 'resource_id', 'id'),
            companyUserSurvey: () => this.belongsTo(CompanyUserSurvey, 'resource_id', 'id'),
            companyChatChannel: () => this.belongsTo(CompanyChatChannel, 'company_chat_channel_id', 'id'),
            companyUserSurveyExtra: () => this.belongsTo(CompanyUserSurveyExtra, 'resource_id', 'id'),
            companyUserSurveyQuestion: () => this.belongsTo(CompanyUserSurveyQuestion, 'resource_id', 'id'),
            companyUserSurveyQuestionAnswer: () => this.belongsTo(CompanyUserSurveyQuestionAnswer, 'resource_id', 'id'),
            companyUserOpentokArchive: () => this.belongsTo(CompanyUserOpentokArchive, 'company_user_opentok_archive_id', 'id'),
        };
    }

    modelEvents() {
        return {
            saved: () => {

            },
        };
    }
}
