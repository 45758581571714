<template>
    <div
        v-if="is_loaded"
        class="rich-editable"
        :class="{
            'no-background': !hasBackground,
            'errors': errors.has(`quill-editor-${pKey}`)
        }"
    >
        <quillEditor
            :ref="'quillEditor' + pKey"
            v-model="val"
            :options="editorOption"
            :data-cy="cypress"
            @change="onContentChanged"
            @ready="onEditorReady"
            @keydown.native="onKeydown"
        />
        <template v-if="validate">
            <input
                v-model="value"
                v-validate.disable="validate"
                :name="`quill-editor-${pKey}`"
                :data-vv-as="validateAs"
                type="hidden"
            >
            <div
                v-for="(errorMessage, index) in errors.collect(`quill-editor-${pKey}`)"
                :key="index"
                class="error-message"
            >
                {{ errorMessage }}
            </div>
        </template>
        <div
            v-if="hasCounter"
            class="counter-container"
        >
            <div>
                <t :count="countCharacter">
                    {count}/160 character | {count}/160 characters
                </t>
            </div>
            <div>
                <t :count="countSms">
                    {count} text message | {count} text messages
                </t>
            </div>
        </div>

        <div
            v-if="inHighLightWhiteList.length"
            class="email-desc"
        >
            <br>
            <div>
                <t>The following is a list of accepted variables to use in the {{ resourceName }}</t>
                :
            </div>

            <div ref="hightlight">
                <div
                    v-for="(item, index) in inHighLightWhiteList"
                    :key="index"
                    class="hlWords"
                    @click="onHlClick(item)"
                >
                    {{ "&#123;"+item.key+"&#125;" }}&nbsp;
                </div>
            </div>
            <div class="email-nb">
                <t>N.B : to insert a dynamic variable, you must use the syntax with brackets just like above</t>
            </div>
        </div>
    </div>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
import { quillEditor } from 'vue-quill-editor';
import 'highlight.js/styles/monokai-sublime.css';
import hljs from 'highlight.js';
import InputField from './InputField.vue';

Quill.register('modules/imageResize', ImageResize);

hljs.configure({
    languages: ['javascript', 'ruby', 'python'],
});

export default {
    name: 'RichEditable',
    components: {
        InputField,
        quillEditor,
    },
    inject: ['$validator'],

    props: {
        value: { required: true },
        inHighLightWhiteList: { type: Array, default: () => [] },
        isAuto: { type: Boolean, default: false },
        height: { type: Number, default: 250 },
        configs: {
            type: Object,
            default: () => ({ modules: {} }),
        },
        validate: { type: [String, Object], required: false },
        validateAs: { type: String, required: false },
        resourceName: { type: String, default: 'email template' },
        hasToolbar: { type: Boolean, default: true },
        hasCounter: { type: Boolean, default: false },
        hasBackground: { type: Boolean, default: true },
        pKey: { type: Number, default: 0 },
        maxLength: { type: Number },
        cypress: { type: String, default: '' },
    },

    data() {
        return {
            is_loaded: false,
            val: '',
            editor: null,
            editorEl: null,
            editorOverlayEl: null,

            highLightWhiteList: {},
            dynamicVariableList: {},

            editorOption: {
                theme: 'snow',
                placeholder: this.translate('Write something...'),
                modules: {
                    toolbar: {
                        container: [
                            ['bold', 'strike'],
                            [{ header: 1 }, { header: 2 }],
                            [{ list: 'bullet' }],
                            ['link', 'image'],
                        ],
                        handlers: {
                            image: async () => {
                                const { quill } = this.$refs[`quillEditor${this.pKey}`];

                                const range = quill.getSelection(true);

                                const input = document.createElement('input');
                                input.setAttribute('type', 'file');
                                input.setAttribute('accept', '.png, .jpg, .jpeg, .gif, .webp');
                                input.click();

                                input.onchange = () => {
                                    const file = input.files[0];

                                    const form = new FormData();
                                    form.append('file', file);

                                    this.$http.post('company_file/wysiwyg', form).then(({ data }) => {
                                        quill.insertEmbed(range.index, 'image', data.link);
                                    }).catch(({ response }) => {
                                        this.$Notifier('App').showError(response.data.message);
                                    });
                                };
                            },
                        },
                    },
                    imageResize: {
                        modules: ['Resize', 'DisplaySize'],
                    },
                    syntax: {
                        highlight: (text) => hljs.highlightAuto(text).value,
                    },
                },
            },
        };
    },

    computed: {
        countCharacter() {
            if (this.val) {
                const stripedHtml = this.val.replace(/<[^>]*>?/gm, '');
                return stripedHtml.length;
            }
            return 0;
        },

        countSms() {
            if (this.val) {
                const stripedHtml = this.val.replace(/<[^>]*>?/gm, '');
                return Math.floor(stripedHtml.length / 161) + 1;
            }
            return 1;
        },
    },

    watch: {
        val(v) {
            this.$emit('input', v);
            this.$emit('change', v);
        },

        value(v) {
            this.val = v;
            this.$nextTick(() => {
                this.highLight();
            });
        },
    },

    mounted() {
        this.initVariables();
        if (!this.hasToolbar) this.editorOption.modules = { toolbar: false };
    },

    beforeDestroy() {
        this.removeEventListener();
    },

    methods: {
        onKeydown(event) {
            const whiteList = ['Backspace', 'Delete', 'ArrowUp', 'ArrowLeft', 'ArrowDown', 'ArrowRight'];

            if (!whiteList.includes(event.key) && this.val && this.val.length >= this.maxLength) {
                event.preventDefault();
            }
        },
        initVariables() {
            this.is_loaded = true;
            this.$nextTick(() => {
                let tempValue = this.value;

                this.inHighLightWhiteList.map((item) => {
                    if (item.key !== item.value) {
                        tempValue = tempValue.replace(new RegExp(`{${item.key}}`, 'g'), item.value);
                    }

                    this.highLightWhiteList[item.key] = 1;
                });

                this.val = tempValue;

                this.editorOption = {
                    ...this.editorOption,
                    ...this.configs,
                    modules: {
                        ...this.editorOption.modules,
                        ...this.configs.modules,
                    },
                };

                this.$nextTick(() => {
                    this.editorOverlayEl = this.$refs[`quillEditor${this.pKey}`].$el.querySelector('.ql-editor-overlay');
                    this.highLight();
                });
            });
        },

        onEditorReady(quill) {
            quill.clipboard.dangerouslyPasteHTML = function (index, html, source) {
                if (typeof index === 'string') {
                    this.quill.setContents(this.convert(index), html);
                    // this.quill.setSelection(0, "api", "api");
                } else {
                    const paste = this.convert(html);
                    this.quill.updateContents(new Delta().retain(index).concat(paste), source);
                    this.quill.setSelection(index + paste.length(), Quill.sources.SILENT);
                }
            };

            quill.on('text-change', () => {
                if (this.val && this.val.length > this.maxLength) {
                    quill.deleteText(quill.getLength() - (this.val.length - this.maxLength), quill.getLength());
                }
            });

            this.editor = quill;

            this.editorEl = this.$refs[`quillEditor${this.pKey}`].$el.querySelector('.ql-editor');

            if (!this.isAuto) {
                this.editorEl.style.height = `${this.height}px`;
                this.editorEl.style.padding = '20px';
            }

            if (!this.$refs[`quillEditor${this.pKey}`].$el.querySelector('.ql-editor-overlay')) {
                const oNode = document.createElement('DIV');
                oNode.className = 'ql-editor-overlay';

                this.editorOverlayEl = this.editorEl.parentNode.appendChild(oNode);
            } else {
                this.editorOverlayEl = this.$refs[`quillEditor${this.pKey}`].$el.querySelector('.ql-editor-overlay');
            }

            this.addEventListener();
        },

        addEventListener() {
            this.editorEl.addEventListener('scroll', this.highLight);
        },

        removeEventListener() {
            this.editorEl.removeEventListener('scroll', this.highLight);
        },

        onContentChanged() {
            this.highLight();
            this.$emit('onChange');
        },

        highLight() {
            this.editorOverlayEl.innerHTML = '';
            this._highLightNode(this.$refs[`quillEditor${this.pKey}`].$el.querySelector('.ql-editor'));
        },

        _highLightNode(o) {
            const reg = new RegExp('[{][^}]+[}]', 'g');
            let range; let aboluteRects = {};

            for (let i = 0; i < o.childNodes.length; i++) {
                const textNode = o.childNodes[i];

                // If we are over a text node
                if (textNode.nodeType == 3) {
                    // Basic check if the string contain the brakets
                    if (!reg.test(textNode.textContent)) continue;

                    // Loop over the chars
                    let startIndex = null;
                    for (let j = 0; j < textNode.textContent.length; j++) {
                        const chr = textNode.textContent[j];

                        // found the start char
                        if (chr == '{') {
                            startIndex = j;
                        } else if (chr == '}' && startIndex != null) {
                            // found the end char (and first founded)
                            const variable = textNode.textContent.substring(startIndex + 1, j);
                            if (!this.highLightWhiteList[variable] && !this.dynamicVariableList[variable]) continue;

                            range = document.createRange();
                            range.setStart(textNode, startIndex);
                            range.setEnd(textNode, j + 1);
                            aboluteRects = range.getClientRects();

                            if (!aboluteRects[0]) continue;

                            //							let absoluteContainer = $(this.editorEl).offset();
                            const absoluteContainer = this.editorEl.getBoundingClientRect();

                            const relativeTop = aboluteRects[0].y - absoluteContainer.top;
                            const relativeLeft = aboluteRects[0].x - (absoluteContainer.left);

                            if (aboluteRects.length > 0) {
                                const oNode = document.createElement('DIV');
                                oNode.style.position = 'absolute';
                                oNode.style.left = `${relativeLeft}px`;
                                oNode.style.top = `${relativeTop}px`;
                                oNode.style.height = `${aboluteRects[0].height}px`;
                                oNode.style.width = `${aboluteRects[0].width}px`;
                                oNode.style.position = 'absolute';
                                oNode.style.background = 'rgba(0, 143, 255, 0.2)';
                                oNode.style.boxSizing = 'content-box';
                                oNode.style.padding = '1px';
                                oNode.style.zIndex = 999;
                                this.editorOverlayEl.appendChild(oNode);
                            }
                        }
                    }
                }

                this._highLightNode(o.childNodes[i]);
            }
        },

        onHlClick(item) {
            const range = this.editor.getSelection(true);

            if (item.key && item.key === item.value) {
                this.editor.insertText(range.index, `{${item.value}} `);
            } else if (item.key && item.key !== item.value) {
                this.editor.insertText(range.index, `{${item.key}} `);
            } else {
                this.editor.insertText(range.index, `{${item}} `);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/styles/var';

    .counter-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 5px;
        font-size: .9em;
        color: $grey-dark
    }

    .email-nb {
        font-style: italic;
        color: #a2a0a0;
    }

    .hlWords {
        display: inline;
        cursor: pointer;

        &:hover {
            color: purple;
        }

        // padding: 1em 0;
        color: #9a4db9;
        font-size: 1.75rem;
    }

    .rich-editable {
        background: #fff;
        // margin-bottom: 6rem;

        &.no-background {
            background: none;
        }

        &.no-bottom-margin {
            margin-bottom: 0;
        }

        .quill-editor {
            height: 100%;
        }
    }

</style>
<style lang="scss">
    @import '~@/styles/var';

    .errors {
        .ql-container {
            border-color: $error!important;

            .ql-editor.ql-blank {
                &:before {
                    color: rgba($error, 0.6);
                }
            }
        }
    }
    .ql-editor {
        height: auto;
        max-width: 930px;
        padding: 15px!important;
        font-size: 1.4rem;
    }

    .ql-container {
        background: #fff;
        padding-bottom: 1px;

        &.ql-snow {
            margin-top: 5px;
            border-radius: 5px;
            border-color: #E5E6E6;
        }
    }

    .ql-editor-overlay {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        height: 100%;
        width: 100%;
        pointer-events: none;
    }

    .ql-toolbar.ql-snow {
        position: relative;
        z-index: 1;
        display: inline-block;
        border-color: #E5E6E6;
        border-radius: 5px;
        // box-shadow: 0 20px 60px 0 rgba(#000, 0.1);
        .ql-formats {
            &:last-child {
                margin-right: 0;
            }
        }

        + .ql-container.ql-snow {
            border-top: 1px solid #E5E6E6 !important;
        }
    }

    .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
        color: $primary;
    }

    .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
        stroke: $primary;
    }

    .ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
        fill: $primary;
    }

    .error {
        .ql-toolbar, .ql-container {
            border-color: $primary-darker !important;
        }
    }
</style>
