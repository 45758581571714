<template>
    <div class="wrapper">
        <ProgramDetailSimulation
            :company-program-id="item.id"
            :use-key-date="false"
            @updateValidateButton="updateValidateButton"
        />

        <Button
            v-show="canValidate"
            class="button"
            :state="buttonState"
            @click="onClick"
        >
            <t>Continue</t>
        </Button>
    </div>
</template>

<script>
import CompanyProgram from '../../../../models/CompanyProgram';
import ProgramDetailSimulation from '../../../../router/pages/programs/ProgramDetailSimulation.vue';
import Button from '../../../globals/Button.vue';

export default {
    name: 'ProgramFormProgram',
    components: {
        Button,
        ProgramDetailSimulation,
    },
    props: {
        item: {
            type: CompanyProgram,
            required: true,
        },
    },

    data() {
        return {
            canValidate: false,
        };
    },

    computed: {
        buttonState() {
            return this.item.isSaving() ? 'loading' : 'idle';
        },
    },

    methods: {
        updateValidateButton(canValidate) {
            this.canValidate = canValidate;
        },

        onClick() {
            this.$emit('goTo', 'email');
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    flex-direction: column;
}

.button {
    align-self: center;
    margin-bottom: 1rem;
}
</style>
