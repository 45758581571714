<template>
    <IndicatorTemplate
        :icon="icon"
        :label="label"
        :loading="loading"
        :description="description"
        @click="goToPrograms"
    />
</template>

<script>
import IndicatorTemplate from '@/components/pages/dashboard/widgets/templates/IndicatorTemplate.vue';

export default {
    name: 'LateProgramsCountWidget',
    components: {
        IndicatorTemplate,
    },
    computed: {
        description() {
            return this.translate('{count} Program | {count} Programs', {
                count: this.$store.getters['dashboard/statistics/lateProgramsCount'],
            });
        },
        label() {
            return this.translate('Number of late programs');
        },
        icon() {
            return ['far', 'stopwatch'];
        },
        loading() {
            return !this.$store.state.dashboard.statistics.lateProgramsCountLoaded;
        },
    },
    watch: {
        loading: {
            handler(val) {
                if (val) {
                    this.$store.dispatch('dashboard/statistics/fetchLateProgramsCount');
                }
            },
            immediate: true,
        },
    },
    methods: {
        goToPrograms() {
            this.$store.dispatch('dashboard/programs/clearAllFilters');
            this.$store.dispatch('dashboard/transferFiltersToPrograms');
            this.$router.push({
                name: 'DashboardPrograms',
                query: {
                    status: ['late'],
                },
            });
        },
    },
};
</script>
