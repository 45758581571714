<template>
    <transition name="modal-fade">
        <div
            v-show="isVisible"
            class="modal"
            @click.self="close()"
        >
            <transition :name="transitionName">
                <div
                    v-show="isVisible"
                    :class="[wrapperClass, size]"
                >
                    <div
                        v-show="hasHeaderSlot"
                        :class="`${wrapperClass}-header`"
                    >
                        <slot name="header" />

                        <HtIconClose
                            data-cy="close-chat"
                            @click="close()"
                        />
                    </div>
                    <div
                        v-show="hasMainSlot"
                        :class="`${wrapperClass}-main`"
                    >
                        <slot name="main" />
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import HtIconClose from '@/components/icons/HtIconClose.vue';

export default {
    name: 'ModalablePersist',
    components: {
        HtIconClose,
    },

    props: {
        size: {
            type: String,
            default: 'medium',
        },
        position: {
            type: String,
            default: 'right',
        },
    },
    data() {
        return {
            transitionName: null,
            wrapperClass: null,
            isVisible: false,
        };
    },
    computed: {
        hasHeaderSlot() {
            return !!this.$slots.header;
        },
        hasMainSlot() {
            return !!this.$slots.main;
        },
    },
    created() {
        switch (this.position) {
        case 'center':
            this.transitionName = 'modal-fade';
            this.wrapperClass = 'modal-wrapper-center';
            break;
        default:
            this.transitionName = 'modal-slide';
            this.wrapperClass = 'modal-wrapper-right';
            break;
        }
    },
    methods: {
        open() {
            this.isVisible = true;
            this.$emit('onOpen');
        },
        close() {
            this.isVisible = false;
            this.$emit('onClose');
        },
    },
};
</script>

<style lang="scss" src="./ModalablePersist.scss" scoped />
