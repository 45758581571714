/* eslint-disable no-useless-constructor */
/* eslint-disable class-methods-use-this */

import HtProgramType from '@/models/HtProgramType';
import CompanyLanguage from '@/models/CompanyLanguage';
import CompanyRole from '@/models/CompanyRole';
import CompanyResourceProgram from '@/models/CompanyResourceProgram';
import AbstractTemplateProvider from './AbstractTemplateProvider';

export default class DepartureTemplateProvider extends AbstractTemplateProvider {
    static TEMPLATE_SLUG = 'departure';

    constructor(programTypes, keyDates, roles, employeeDepartmentGroupId) {
        super(programTypes, keyDates, roles, employeeDepartmentGroupId);
    }

    get baseProgramData() {
        return {
            slug: DepartureTemplateProvider.TEMPLATE_SLUG,
            ht_program_type_id: this.programTypes.find(
                (type) => type.slug === HtProgramType.SLUG_OFFBOARDING,
            ).id,
            locales: [
                {
                    name: "Départ d'un collaborateur",
                    description:
                            'Quelles que soient les raisons du départ du salarié, l’entreprise doit pourtant faire en sorte qu’il se déroule dans les meilleures conditions !',
                    language_key: CompanyLanguage.KEY_FR,
                },
                {
                    name: 'Offboarding',
                    description:
                            "Regardless of the reasons for the employee's departure, the company must ensure that it takes place under the best possible conditions!",
                    language_key: CompanyLanguage.KEY_EN,
                },
            ],
            key_dates: [
                {
                    ht_program_key_date: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Departure date')),
                    ht_program_key_date_id: this.keyDates.find((keyDate) => keyDate.locales.models.some((locale) => locale.name === 'Departure date')).id,
                    is_main: true,
                },
            ],
            actions: [],
            surveys: [],
            emails: [],
            events: [],
        };
    }

    get actions() {
        return [
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -30,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: "Rencontrer le collaborateur suite à l'annonce de son départ",
                            [CompanyLanguage.KEY_EN]: 'Meeting the employee following the announcement of their departure',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -30,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Remercier et féliciter le salarié pour son investissement dans l’entreprise',
                            [CompanyLanguage.KEY_EN]: 'Thank and congratulate the employee for their investment in the company',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -25,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: "Rencontrer le manager pour prévenir d'autres départs",
                            [CompanyLanguage.KEY_EN]: 'Meet with the manager to prevent other departures',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -20,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_EMPLOYEE],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Préparer un livret de passation pour votre successeur',
                            [CompanyLanguage.KEY_EN]: 'Prepare a transition booklet for your successor',
                        },
                        description: {
                            [CompanyLanguage.KEY_FR]: 'Afin de garantir le bon déroulement de la passation, pouvez-vous préparer un livret de passation pour votre successeur ? Indiquez-lui les accès, les process à respecter, les process en cours... Bref, tout ce qui pourra lui être utile',
                            [CompanyLanguage.KEY_EN]: 'In order to ensure a smooth transition, can you prepare a handover booklet for your successor? Show them the accesses, the processes to be respected, the processes in progress... In short, everything that could be useful',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -20,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Préparer la passation',
                            [CompanyLanguage.KEY_EN]: 'Prepare the handover process',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -10,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Envoyer au salarié les documents administratifs et légaux',
                            [CompanyLanguage.KEY_EN]: 'Send the employee the administrative and legal documents',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -2,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Proposer au salarié de lui rédiger une lettre de recommandation',
                            [CompanyLanguage.KEY_EN]: 'Offer the employee to write them a recommendation letter',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -8,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Organiser un pot de départ',
                            [CompanyLanguage.KEY_EN]: 'Organize a leaving party',
                        },
                    },
                },
            },
            {
                resource: {
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: 7,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_role_id: this.roles[CompanyRole.NAME_MANAGER],
                                },
                            ],
                        },
                    ],
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: 'Organiser des challenges et défis pour maintenir la cohésion de l’équipe',
                            [CompanyLanguage.KEY_EN]: 'Organise challenges to maintain team cohesion',
                        },
                    },
                },
            },
        ];
    }

    get emails() {
        return [
            {
                template: 'custom',
                translations: [
                    {
                        subject: "Départ de l'entreprise",
                        content: `<p>Bonjour à tous,</p>
                            <p>Nous vous informons que {enrollee_firstname} partira de l’entreprise le {key_date}.</p>
                            <p>Nous tenons à le remercier et à le féliciter pour son implication à son poste et dans la vie de l’entreprise !</p>
                            <p>Nous travaillons ensemble à la passation et à l’organisation de son départ.</p>
                            <p>Nous comptons sur vous pour accompagner son départ comme il se doit !</p>
                            <p>Merci à tous</p>`,
                        signature: '{sender_firstname} {sender_lastname}',
                        language_key: CompanyLanguage.KEY_FR,
                    },
                    {
                        subject:
                            'Leaving the company',
                        content: `<p>Hello everyone,</p>
                            <p>We would like to inform you that {enrollee_firstname} will be leaving the company on {key_date}.</p>
                            <p>We would like to thank and congratulate them for their investment in their job and in the company life!</p>
                            <p>We are working together on their handover and on organizing their departure.</p>
                            <p>We are counting on you to make their departure a success!</p>
                            <p>Thank you all</p>`,
                        signature: '{sender_firstname} {sender_lastname}',
                        language_key: CompanyLanguage.KEY_EN,
                    },
                ],
                file_translations: [],
                resource: {
                    translations: {
                        name: {
                            [CompanyLanguage.KEY_FR]: "Informer l'équipe du départ du salarié",
                            [CompanyLanguage.KEY_EN]: 'Inform the team of the employee\'s departure',
                        },
                    },
                    programs: [
                        {
                            offset_availability_number: 0,
                            offset_availability_unit: CompanyResourceProgram.UNIT_IMMEDIATELY,
                            offset_end_number: -15,
                            offset_end_unit: CompanyResourceProgram.UNIT_DAYS,
                            is_business_day: true,
                            participants: [
                                {
                                    company_group_id: this.employeeDepartmentGroupId,
                                },
                            ],
                        },
                    ],
                },
                sender_role_id: this.roles[CompanyRole.NAME_ADMINISTRATIVE_MANAGER],
            },
        ];
    }
}
